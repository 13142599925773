import { isValidIBAN, electronicFormatIBAN } from 'ibantools';
import { z } from 'zod';

/**
 * Zod schema to validate IBAN using `ibantools`
 *
 * Allows IBANs in both “electronic format” and “print format”
 */
export const ibanSchema = z
  .string()
  .min(1)
  .refine(value => {
    const electronicFormatted = electronicFormatIBAN(value);

    return electronicFormatted ? isValidIBAN(electronicFormatted) : false;
  });
