import { Flex, Grid, Heading } from '@candisio/design-system';
import { useSSO } from 'orgConfig/sso/sso';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { Role } from 'views/Settings/TeamMembers/hooks/useGetRolesForUser';
import { RolesField } from './RolesField';
import {
  RolesFormOutput,
  RolesFormSchemaOptions,
  RolesFormValues,
  rolesFormErrorMessages,
  rolesFormSchema,
} from './utils';

interface RolesFormProps {
  defaultValues: RolesFormValues;
  onSubmit: (formData: RolesFormOutput) => void;
  roles: Role[];
}

export const RolesForm = ({
  defaultValues,
  roles,
  onSubmit,
}: RolesFormProps) => {
  const sso = useSSO();
  const context: RolesFormSchemaOptions = {
    hiddenFields: { roles: sso.provides.roles },
  };

  const [t] = useTranslation();
  const form = useForm<RolesFormOutput>({
    mode: 'onChange',
    context,
    defaultValues,
    resolver: zodResolver({
      zodSchema: rolesFormSchema,
      errorMessages: rolesFormErrorMessages,
    }),
  });

  const handleChange = async () => {
    await form.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...form}>
      <Grid alignContent="space-between" height="100%">
        <Flex
          direction="column"
          gap="space8"
          as="form"
          onSubmit={form.handleSubmit(onSubmit)}>
          <Heading as="h3">
            {t('settings.teamMembers.form.rolesForm.heading')}
          </Heading>

          <RolesField
            roles={roles}
            name="allAppliedRoles"
            isReadOnly={sso.provides.roles}
            onChange={handleChange}
          />
        </Flex>
      </Grid>
    </FormProvider>
  );
};
