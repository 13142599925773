import { PdfViewerProps } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import {
  useGetDocumentForPreviewQuery,
  useGetEcmDocumentForPreviewQuery,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { isEcmDocumentId } from 'views/ManageRelationships/useManageRelationshipsData';

interface UseDocumentPreviewDataOptions {
  documentId: string;
}

export const useDocumentPreviewData = ({
  documentId,
}: UseDocumentPreviewDataOptions) => {
  const isEcmDocument = isEcmDocumentId(documentId);
  const { data: getDocumentData, loading: documentLoading } =
    useGetDocumentForPreviewQuery({
      variables: {
        id: documentId,
      },
      skip: isEcmDocument,
    });

  const { data: getEcmDocumentData, loading: ecmDocumentLoading } =
    useGetEcmDocumentForPreviewQuery({
      variables: {
        id: documentId,
      },
      skip: !isEcmDocument,
    });

  const invoiceDocumentFile = getDocumentData?.getDocument?.documentFile;
  const ecmDocumentFile =
    getEcmDocumentData?.getAggregatedEcmDocument.fileSource;

  const documentFile: PdfViewerProps['documentFile'] = useMemo(() => {
    if (!isEcmDocument) {
      return {
        name: invoiceDocumentFile?.name ?? '',
        url: invoiceDocumentFile?.url ?? '',
        id: invoiceDocumentFile?.id,
      } satisfies PdfViewerProps['documentFile'];
    }

    return {
      name: ecmDocumentFile?.name ?? '',
      url: ecmDocumentFile?.url ?? '',
      id: ecmDocumentFile?.url,
    } satisfies PdfViewerProps['documentFile'];
  }, [
    isEcmDocument,
    ecmDocumentFile?.name,
    ecmDocumentFile?.url,
    invoiceDocumentFile?.name,
    invoiceDocumentFile?.url,
    invoiceDocumentFile?.id,
  ]);

  const attachments: PdfViewerProps['attachments'] = useMemo(() => {
    if (isEcmDocument) {
      // todo double check if ecm docs can have attachments?
      return [];
    }

    return getDocumentData?.getDocument?.attachments?.map(
      a =>
        ({
          name: a.name,
          url: a.url,
          id: a.id,
        } satisfies NonNullable<PdfViewerProps['attachments']>[number])
    );
  }, [isEcmDocument, getDocumentData?.getDocument?.attachments]);

  return {
    ecmDocumentLoading,
    documentLoading,
    documentFile,
    attachments,
  };
};
