import { Avatar } from '@candisio/design-system';
import { PaginationWithSearchFilterHook } from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  CardIssuerMerchantSortField,
  MerchantNamesInfinteScrollPaginationQuery,
  MerchantNamesInfinteScrollPaginationQueryVariables,
  SortDirection,
  useMerchanNamePageBasedPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { sortByStringProp } from 'utils/sorting';
import { merchantNamesInfinteScrollPagination } from '../CreditCardsInsights/gql';
import { CCFilterOption } from '../CreditCardsInsights/utils/paginationFilterHooks';

const defaultSort = {
  direction: SortDirection.Asc,
  field: CardIssuerMerchantSortField.MerchantName,
};

const sortForInputString = {
  direction: SortDirection.Desc,
  field: CardIssuerMerchantSortField.Query,
};

export const useMerchantnameFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const computeVariables = useCallback(
    (
      inputString?: string
    ): MerchantNamesInfinteScrollPaginationQueryVariables => ({
      queries: {
        query: inputString,
      },
      input: {},
      sortBy: inputString ? sortForInputString : defaultSort,
    }),
    []
  );

  const {
    data: allMerchantNames,
    loading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    MerchantNamesInfinteScrollPaginationQuery,
    MerchantNamesInfinteScrollPaginationQueryVariables
  >({
    query: merchantNamesInfinteScrollPagination,
    queryRootKey: 'getPageBasedCardIssuerMerchants',
    computeVariables,
  });

  const { data: selectedMerchantNames } =
    useMerchanNamePageBasedPaginationQuery({
      variables: {
        input: { page: 0, limit: filteredValues.length },
        filters: {
          names: filteredValues,
        },
        sortBy: defaultSort,
      },
      fetchPolicy: 'cache-and-network',
      skip: filteredValues.length === 0 || Boolean(searchStr),
    });

  const unselectedRecords =
    allMerchantNames?.getPageBasedCardIssuerMerchants.records || [];

  const selectedRecords =
    selectedMerchantNames?.getPageBasedCardIssuerMerchants.records || [];

  const sortedUnselectedRecords: CCFilterOption[] = [...unselectedRecords]
    .map(rec => ({
      id: rec.name,
      label: rec.name,
      icon: (
        <Avatar size="small" name={rec.name} img={rec.logoUrl ?? undefined} />
      ),
    }))
    .sort(sortByStringProp('label'));

  const sortedSelectedRecord: CCFilterOption[] = [...selectedRecords]
    .map(rec => ({
      id: rec.name,
      label: rec.name,
      icon: (
        <Avatar size="small" name={rec.name} img={rec.logoUrl ?? undefined} />
      ),
    }))
    .sort(sortByStringProp('label'));

  return {
    filterOptions: uniqBy(
      [...sortedSelectedRecord, ...sortedUnselectedRecords],
      'id'
    ),
    handleDebounceSearch,
    loading,
    loadMore,
  };
};
