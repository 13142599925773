import { Popover, usePopover } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { Routes } from 'models';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
// TODO: Move the files to components/NavigationSidebar/UserProfile after removing new navigation FF
import userProfileMenuQRCode from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/media/user-profile-menu-QR-code.jpg';
import { MobileAppPromotionModal } from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/MobileAppPromotionModal';
import { scrollToTargetParam } from 'views/AppContainer/components/Header/components/UserProfile/AccountSecurity/PhoneNumberMfa/PhoneNumberCard';
import { LegalDocumentsModal } from 'views/AppContainer/components/Header/components/UserProfile/LegalDocuments/LegalDocumentsModal';
import { UserProfileUpdateContainer } from 'views/AppContainer/components/Header/components/UserProfile/UserProfileUpdateContainer';
import { IconLink } from '../../IconLink/IconLink';
import { UserProfileMenuPopover } from './UserProfileMenuPopover/UserProfileMenuPopover';

export const UserProfile = ({ expanded }: { expanded: boolean }) => {
  const currentUser = useCurrentUser();
  const { hash: currentHash, search } = useLocation();
  const [legalModalVisible, setLegalModalVisible] = useState(false);
  const [mobileAppPromotionModalVisible, setMobileAppPromotionModalVisible] =
    useState(false);

  const navigate = useNavigate();
  const history = useHistory();
  const location = useLocation();
  const organizationSlug = useOrganizationId();

  const userLabel = `${currentUser?.firstName} ${currentUser?.lastName}`;

  const {
    close: closeUserProfileMenu,
    isOpen: isUserProfileMenuOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({
    placement: 'right',
    onOpenChange: isOpen => {
      if (isOpen) {
        history.push({
          pathname: `/${organizationSlug}${Routes.USER_PROFILE}`,
        });
      }
    },
  });

  const onProfileClose = useCallback(() => {
    const updatedSearchParams = new URLSearchParams(search);

    updatedSearchParams.delete(scrollToTargetParam);

    navigate(
      { hash: undefined, search: updatedSearchParams.toString() },
      { replace: true }
    );
  }, [navigate, search]);

  const isProfileOpen = currentHash === '#userProfile';

  const openMobilAppPromotionModal = () =>
    setMobileAppPromotionModalVisible(true);

  const openLegalModal = () => setLegalModalVisible(true);

  return (
    <>
      <IconLink
        as="button"
        avatar={
          <AvatarWithStatusContainer
            hasTooltip={false}
            img={currentUser?.avatarUrl ?? undefined}
            name={currentUser?.name}
            size="medium"
            userId={currentUser?.id}
            {...triggerProps}
          />
        }
        expanded={expanded}
        description={currentUser?.email}
        ref={triggerRef}
        label={userLabel}
        showLabelInTooltip={false}
        isActive={location.pathname.includes(Routes.USER_PROFILE)}
        {...triggerProps}
      />
      {isUserProfileMenuOpen && (
        <Popover
          padding="0"
          autoFocus={false}
          {...popoverProps}
          ref={popoverRef}
          dismissWithOutsideClick>
          <UserProfileMenuPopover
            closeUserProfileMenu={closeUserProfileMenu}
            openMobilAppPromotionModal={openMobilAppPromotionModal}
            openLegalModal={openLegalModal}
          />
        </Popover>
      )}

      <UserProfileUpdateContainer
        isOpen={isProfileOpen}
        onClose={onProfileClose}
      />
      <LegalDocumentsModal
        onCancel={() => setLegalModalVisible(false)}
        visible={legalModalVisible}
      />
      <MobileAppPromotionModal
        qrCode={userProfileMenuQRCode}
        visible={mobileAppPromotionModalVisible}
        onCancel={() => setMobileAppPromotionModalVisible(false)}
      />
    </>
  );
};
