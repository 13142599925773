import { Card } from '@candisio/design-system';
import { useStickyCardRefContext } from 'providers/StickyCardRefProvider';
import { ReactNode } from 'react';

type Status = 'default' | 'warning' | 'error';
const statusBorderColorMap: Record<Status, string> = {
  warning: 'yellow500',
  error: 'red500',
  default: 'transparent',
};

interface FormActionProps {
  children: ReactNode;
  status?: Status;
}

export const FormAction = ({
  children,
  status = 'default',
}: FormActionProps) => {
  const cardRef = useStickyCardRefContext();

  return (
    <Card
      corners="top"
      background="gray0"
      border={`2px solid ${statusBorderColorMap[status]}`}
      borderRadius="medium"
      bottom="0"
      boxShadow="elevatedShadow3"
      left="0"
      padding="space20"
      position="sticky"
      right="space8"
      zIndex="1"
      ref={cardRef}>
      {children}
    </Card>
  );
};
