import { Grid, Heading, Link, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { DATEV_EXPORT_TYPES } from '../consts';
import { DATEV_SETTINGS_WIDTH } from '../DatevSettings';

export const ExportTypesDescription = () => {
  const [t] = useTranslation();

  return (
    <Grid id={DATEV_EXPORT_TYPES} gap="space16" maxWidth={DATEV_SETTINGS_WIDTH}>
      <Heading as="h3">{t('settings.datev.connect.exportTypes.title')}</Heading>
      <Paragraph fontSize="small" color="gray600" lineHeight="space20">
        {t('settings.datev.connect.exportTypes.info')}{' '}
        <Link external onClick={() => window.Intercom('showNewMessage')}>
          {t('settings.datev.connect.exportTypes.infoLinkLabel')}
        </Link>
      </Paragraph>
    </Grid>
  );
};
