import { DocumentNode, useQuery } from '@apollo/client';
import { queryParameter } from 'components/Table/consts';
import {
  ApprovalsDocumentNavigationDataQuery,
  ArchiveDocumentNavigationDataQuery,
  BasicDocumentConnectionDataFragment,
  EcmDocumentConnectionDataFragment,
  EcmDocumentType,
  InboxDocumentNavigationDataQuery,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { AppRouteParams, Routes } from 'models';
import { useCallback, useMemo } from 'react';
import { Path, generatePath } from 'react-router-dom-v5-compat';
import { useFiltersAndSortFromUrl } from 'utils/url-helper';
import { DOCUMENT_PROCESSING_SEARCH_PARAMS } from 'views/Inbox/DocumentProcessing/consts';
import {
  getApprovalDocumentsNavigationQueries,
  getArchiveDocumentsNavigationQueries,
  getArchiveDocumentsNavigationWithSearchQueries,
  getEcmDocumentsNavigationQueries,
  getInboxDocumentNavigationQueries,
} from './queries';

type ListViews =
  | Routes.APPROVALS
  | Routes.ARCHIVE
  | Routes.INBOX
  | Routes.ECM_DOCUMENTS
  | Routes.ECM_CONTRACTS
  | Routes.ECM_SENSITIVE_CONTRACTS;

type ListViewQuery =
  | ApprovalsDocumentNavigationDataQuery
  | ArchiveDocumentNavigationDataQuery
  | InboxDocumentNavigationDataQuery;

const getListViewQueries = ({
  isWithSearch,
}: {
  isWithSearch?: boolean;
}): Record<ListViews, DocumentNode> => ({
  [Routes.APPROVALS]: getApprovalDocumentsNavigationQueries,
  [Routes.ARCHIVE]: isWithSearch
    ? getArchiveDocumentsNavigationWithSearchQueries
    : getArchiveDocumentsNavigationQueries,
  [Routes.INBOX]: getInboxDocumentNavigationQueries,
  [Routes.ECM_DOCUMENTS]: getEcmDocumentsNavigationQueries,
  // We reuse the query of 'ECM_DOCUMENTS' for contracts, because the data is the same.
  [Routes.ECM_CONTRACTS]: getEcmDocumentsNavigationQueries,
  [Routes.ECM_SENSITIVE_CONTRACTS]: getEcmDocumentsNavigationQueries,
});

interface UseFetchDocNavigationDataOptions {
  cursor: string | null;
  organizationSlug: string;
  route: ListViews;
}

interface UseFetchDocNavigationDataReturn {
  prevDocumentLink?: Partial<Path>;
  prevDocumentId?: string;
  nextDocumentLink?: Partial<Path>;
  nextDocumentId?: string;
  documentListCount?: number;
  navigationLoading: boolean;
  linkBackToList: Partial<Path>;
}

export const useFetchDocNavigationData = ({
  cursor,
  organizationSlug,
  route,
}: UseFetchDocNavigationDataOptions): UseFetchDocNavigationDataReturn => {
  const {
    loading: navigationLoading,
    count: documentListCount,
    nextDocumentEdge,
    prevDocumentEdge,
  } = useFetchListData({ cursor, route });

  const filtersAndSortFromUrl = useFiltersAndSortFromUrl();

  const getDocumentLink = useCallback(
    (documentEdge: typeof nextDocumentEdge | typeof prevDocumentEdge) => {
      if (!documentEdge) return undefined;

      const documentType =
        documentEdge.node.__typename === 'EcmDocument'
          ? documentEdge.node.documentType ?? undefined
          : undefined;

      const invoiceId =
        documentEdge.node.__typename === 'EcmDocument'
          ? documentEdge.node.invoiceId ?? undefined
          : undefined;

      return generateDocumentLink({
        cursor: documentEdge.cursor,
        documentId: documentEdge.node.id,
        filtersAndSortFromUrl,
        organizationSlug,
        route,
        documentType,
        invoiceId,
      });
    },
    [filtersAndSortFromUrl, organizationSlug, route]
  );

  const nextDocumentId = nextDocumentEdge?.node.id;
  const nextDocumentLink = useMemo(
    () => getDocumentLink(nextDocumentEdge),
    [getDocumentLink, nextDocumentEdge]
  );

  const prevDocumentId = prevDocumentEdge?.node.id;
  const prevDocumentLink = useMemo(
    () => getDocumentLink(prevDocumentEdge),
    [getDocumentLink, prevDocumentEdge]
  );

  const linkBackToList = generateLinkBackToList({
    organizationSlug,
    route,
    filtersAndSortFromUrl,
  });

  return {
    navigationLoading,
    nextDocumentId,
    nextDocumentLink,
    prevDocumentId,
    prevDocumentLink,
    documentListCount,
    linkBackToList,
  };
};

type Unpacked<T> = T extends Array<infer U> ? U : T;
type BasicDocumentEdge = Unpacked<BasicDocumentConnectionDataFragment['edges']>;
type EcmDocumentEdge = Unpacked<EcmDocumentConnectionDataFragment['edges']>;

interface FetchListDataResult {
  nextDocumentEdge?: BasicDocumentEdge | EcmDocumentEdge;
  prevDocumentEdge?: BasicDocumentEdge | EcmDocumentEdge;
  loading: boolean;
  count?: number;
}

export const useFetchListData = ({
  cursor,
  route,
}: {
  cursor: string | null;
  route: ListViews;
}): FetchListDataResult => {
  const { searchParams } = useMutateSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? undefined;

  const gqlQuery = getListViewQueries({ isWithSearch: !!searchQuery })[route];

  const shouldExcludeSearch = route === Routes.ARCHIVE && !searchQuery;

  const { data: navigationData, loading } = useQuery<ListViewQuery>(gqlQuery, {
    skip: !cursor,
    variables: {
      cursor,
      query: shouldExcludeSearch ? undefined : searchQuery,
    },
    // we always want the latest data and it won’t be used elsewhere so caching
    // the response would be redundant
    fetchPolicy: 'no-cache',
  });

  const { edges: prevEdges } = navigationData?.prevDocument ?? {};
  const { edges: nextEdges, pageInfo } = navigationData?.nextDocument ?? {};

  return {
    nextDocumentEdge: nextEdges?.[0],
    prevDocumentEdge: prevEdges?.[0],
    loading,
    count: pageInfo?.totalCount ?? undefined,
  };
};

const generateDocumentLink = ({
  cursor,
  documentId,
  documentType,
  filtersAndSortFromUrl,
  invoiceId,
  organizationSlug,
  route,
}: {
  cursor: string;
  documentId: string;
  filtersAndSortFromUrl: URLSearchParams;
  invoiceId?: string;
  documentType?: string;
  organizationSlug: string;
  route: ListViews;
}) => {
  const isInvoice = documentType === EcmDocumentType.Invoice && invoiceId;

  const pathname = generatePath(
    `/:${AppRouteParams.organizationSlug}${route}/:documentId`,
    {
      organizationSlug,
      documentId: isInvoice ? invoiceId : documentId,
    }
  );

  const searchParams = new URLSearchParams(filtersAndSortFromUrl);
  searchParams.set(DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR, cursor);

  searchParams.delete('isInvoice');
  if (isInvoice) {
    searchParams.set('isInvoice', 'true');
  }

  return { pathname, search: searchParams.toString() };
};

const generateLinkBackToList = ({
  organizationSlug,
  route,
  filtersAndSortFromUrl,
}: {
  organizationSlug: string;
  route: ListViews;
  filtersAndSortFromUrl: URLSearchParams;
}) => {
  const pathname = generatePath(
    `/:${AppRouteParams.organizationSlug}${route}`,
    { organizationSlug }
  );

  return { pathname, search: filtersAndSortFromUrl.toString() };
};
