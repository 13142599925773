import {
  CardIssuerAmountInput,
  RequestSingleUseVirtualCardInput,
} from 'generated-types/graphql.types';
import { RequestSingleUseVirtualCard } from '../hooks/useRequestSingleUseVirtualCard';

export const sanitiseRequestSingleUseCard = ({
  purpose,
  transactionLimit,
  selectedCategory,
  accountingData,
}: RequestSingleUseVirtualCard): RequestSingleUseVirtualCardInput => {
  const requestedTransactionLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: transactionLimit ?? 0,
  };

  return {
    purpose: purpose ?? '',
    requestedTransactionLimit,
    acceptedCardAgreement: true,
    category: selectedCategory,
    accountingData,
  };
};
