import { useGetVendorCardholdersSuggestionsForRecurringPaymentsQuery } from 'generated-types/graphql.types';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';

interface UseGetVendorCardholderSuggestionParams {
  skip?: boolean;
}

export const useGetVendorCardholderSuggestion = (
  props?: UseGetVendorCardholderSuggestionParams
) => {
  const { vendorId } = useParams<{ vendorId: string }>();
  const { data, loading } =
    useGetVendorCardholdersSuggestionsForRecurringPaymentsQuery({
      variables: { id: vendorId },
      skip: !vendorId || props?.skip,
    });

  const suggestedCardholders = (
    data?.getVendorCardholdersSuggestionsForRecurringPayments.suggestions || []
  ).map(suggestion => ({
    membershipId: suggestion.cardholder.membershipId ?? undefined,
    name:
      (suggestion.cardholder.firstName ?? '') +
      ' ' +
      (suggestion.cardholder.lastName ?? ''),
  }));

  return {
    suggestedCardholder: suggestedCardholders.length
      ? suggestedCardholders[0]
      : undefined,
    loading,
  };
};
