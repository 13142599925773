import { DocumentFilterInput } from 'generated-types/graphql.types';
import { Filters } from 'react-table';
import { DocumentsTableData } from '../types';

/**
 * @todo Filters<DocumentsTableData> are not tightly coupled with DocumentsTableData, hence we
 * have to do this 'if' checks with magic strings. It would be nice to refactor it so that we
 * are explicitly stating which column are we filtering
 */

export const mapToFilterInput = (
  filters: Filters<DocumentsTableData>
): DocumentFilterInput => {
  return filters.reduce<DocumentFilterInput>((documentFilterInput, filter) => {
    if (filter.id === 'status') {
      return { ...documentFilterInput, status: filter.value };
    }

    if (filter.id === 'contact') {
      return { ...documentFilterInput, contactIds: filter.value };
    }

    return documentFilterInput;
  }, {});
};
