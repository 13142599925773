import { mergeProps } from '@candisio/design-system';
import { HookFormNumberFieldProps } from 'components/HookFormFields/HookFormNumberField';
import { HookFormPercentField } from 'components/HookFormFields/HookFormPercentField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

/** A `HookFormPercentField` with added field metadata */
export const ProcessingFormPercentField = ({
  name,
  readOnly,
  ...restProps
}: HookFormNumberFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormPercentField
      name={name}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
