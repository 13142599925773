import { Validity } from 'containers/credit-cards/CreditCardDrawer/Tabs/Request/utils/validityFieldOptions';
import { CardType } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useGetVendorForRecurringPaymentsById } from 'views/CreditCards/hooks/useGetVendorForRecurringPaymentsById';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from '../CreateCreditCardModalDataProvider';
import { CardUsageForm } from './CardUsageForm';
import {
  CardUsageFormFormValues,
  CardUsageFormOutput,
} from './cardUsageFormSchema';

const maxCharSupported = 40;

export type ReadOnlyFields = keyof CardUsageFormFormValues;

export const CardUsageFormContainer = () => {
  const setModalState = useCreateCreditCardModalSetState();
  const { cardType, cardDetails } = useCreateCreditCardModalState();

  const { vendorId } = useParams<{ vendorId: string }>();

  const { vendorDetails } = useGetVendorForRecurringPaymentsById({
    vendorId,
    fetchPolicy: 'cache-first',
  });

  const handleSubmit = (formValues: CardUsageFormOutput) => {
    const { cardLabel, validity, purpose } = formValues;
    setModalState(prevState => ({
      ...prevState,
      formStep: 'AccountingInfo',
      cardDetails: {
        ...prevState.cardDetails,
        cardLabel,
        validity,
        purpose,
      },
    }));
  };

  const suggestedLabel = vendorDetails.name?.substring(0, maxCharSupported);

  const validity =
    cardType === CardType.SingleUse ? Validity.months12 : Validity.months36;

  const defaultValues: CardUsageFormFormValues = {
    cardLabel: cardDetails?.cardLabel ?? suggestedLabel,
    purpose: cardDetails?.purpose,
    validity: cardDetails?.validity ?? validity,
  };

  const readOnlyFields: Partial<Record<ReadOnlyFields, boolean>> = {
    validity: cardType === CardType.SingleUse || cardType === CardType.Physical,
  };

  const isSuggestionVisible = isNil(cardDetails?.cardLabel);

  return (
    <CardUsageForm
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      readOnlyFields={readOnlyFields}
      suggestedLabel={isSuggestionVisible ? suggestedLabel : undefined}
    />
  );
};
