import { Item } from '@candisio/design-system';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { nohistoryData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TransactionDownloadItem } from './hooks/useTransactionDownloadHistoryData';
import { TransactionDownloadHistoryItem } from './TransactionDownloadHistoryItem';

interface TransactionDownloadHistoryListProps {
  loading: boolean;
  queryString: string;
  onResetSearch: () => void;
  onSearchString: (filter: string) => void;
  onLoadMore: () => void;
  items: TransactionDownloadItem[];
}

export const TransactionDownloadHistoryList = ({
  items,
  loading,
  onLoadMore,
  onSearchString,
  onResetSearch,
  queryString,
}: TransactionDownloadHistoryListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <FilterableList
      children={items.map((item: TransactionDownloadItem) => (
        <Item key={item.id} textValue={item.id}>
          <TransactionDownloadHistoryItem
            item={item}
            queryString={queryString}
          />
        </Item>
      ))}
      onEndReached={onLoadMore}
      emptyDataState={
        <EmptyState animationData={nohistoryData}>
          {t('table.toolbar.emptyHistory')}
        </EmptyState>
      }
      emptySearchState={<EmptySearchState searchReset={onResetSearch} />}
      isLoading={loading}
      searchField={{
        onSearchFilter: onSearchString,
        placeholder: t('table.toolbar.searchPlaceholder'),
        searchQuery: queryString,
      }}
      width="50rem"
    />
  );
};
