import { Grid } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { getTranslationContext } from 'orgConfig';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProcessingFormFieldOptions,
  ProcessingFormProps,
} from './ProcessingForm';
import { ProcessingFormComboBoxField } from './ProcessingFormComboBoxField';
import { ProcessingFormPaginatedComboBoxField } from './ProcessingFormPaginatedComboBoxField';
import { ProcessingFormTaxCodeField } from './ProcessingFormTaxCodeField';

export interface ProcessingFormAccountingDataFieldItem {
  key: string;
  children: ReactNode;
  code?: string;
  name?: string;
}

export interface ProcessingFormAccountingFieldsProps {
  /** Individual field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /** Integration that the organization is using */
  integration: ProcessingFormProps['integration'];
  /** Display fields as read only */
  readOnly?: boolean;
}

/**
 * Displays the following accounting data fields (first booking only):
 * - General ledger account
 * - Tax code
 * - Cost center
 * - Cost object
 * - Artist social insurance code
 * - Extra cost info
 *
 * Individual fields can be configured or hidden with the `fieldOptions` prop.
 */
export const ProcessingFormAccountingFields = ({
  fieldOptions,
  integration,
  readOnly,
  ...restProps
}: ProcessingFormAccountingFieldsProps) => {
  const [netAndTaxAmountFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.netAndTaxAmount,
  ]);

  const [t] = useTranslation();

  return (
    <Grid gap="space16">
      {!fieldOptions?.generalLedgerAccount?.hidden && (
        <ProcessingFormPaginatedComboBoxField
          name="bookings.0.generalLedgerAccount"
          label={t('document.requestApproval.inputs.generalLedger.label')}
          placeholder={t(
            'document.requestApproval.inputs.generalLedger.placeholder'
          )}
          {...fieldOptions?.generalLedgerAccount?.props}
          readOnly={
            readOnly || fieldOptions?.generalLedgerAccount?.props?.readOnly
          }
          {...restProps}
        />
      )}
      {!netAndTaxAmountFF && !fieldOptions?.taxCode?.hidden && (
        <ProcessingFormTaxCodeField
          name="bookings.0.taxCode"
          label={t('document.requestApproval.inputs.bookingKey.label')}
          placeholder={t(
            'document.requestApproval.inputs.bookingKey.placeholder'
          )}
          integration={integration}
          typeFieldItems={fieldOptions?.type?.items}
          {...fieldOptions?.taxCode?.props}
          readOnly={readOnly || fieldOptions?.taxCode?.props?.readOnly}
          {...restProps}
        />
      )}
      {!fieldOptions?.costCenter?.hidden && (
        <ProcessingFormPaginatedComboBoxField
          name="bookings.0.costCenter"
          label={t('document.requestApproval.inputs.costCenter.label', {
            context: getTranslationContext(),
          })}
          placeholder={t(
            'document.requestApproval.inputs.costCenter.placeholder'
          )}
          {...fieldOptions?.costCenter?.props}
          readOnly={readOnly || fieldOptions?.costCenter?.props?.readOnly}
          {...restProps}
        />
      )}
      {!fieldOptions?.costObject?.hidden && (
        <ProcessingFormPaginatedComboBoxField
          name="bookings.0.costObject"
          label={t('document.requestApproval.inputs.costObject.label', {
            context: getTranslationContext(),
          })}
          placeholder={t(
            'document.requestApproval.inputs.costObject.placeholder'
          )}
          {...fieldOptions?.costObject?.props}
          readOnly={readOnly || fieldOptions?.costObject?.props?.readOnly}
          {...restProps}
        />
      )}
      {!fieldOptions?.artistSocialInsuranceCode?.hidden && (
        <ProcessingFormComboBoxField
          name="bookings.0.artistSocialInsuranceCode"
          label={t(
            'document.requestApproval.inputs.artistSocialInsuranceCode.label'
          )}
          placeholder={t(
            'document.requestApproval.inputs.artistSocialInsuranceCode.placeholder'
          )}
          {...fieldOptions?.artistSocialInsuranceCode?.props}
          readOnly={
            readOnly || fieldOptions?.artistSocialInsuranceCode?.props?.readOnly
          }
          {...restProps}
        />
      )}
      {!fieldOptions?.extraCostInfo?.hidden && (
        <ProcessingFormPaginatedComboBoxField
          name="bookings.0.extraCostInfo"
          label={t('document.requestApproval.inputs.extraCostInfo.label')}
          placeholder={t(
            'document.requestApproval.inputs.extraCostInfo.placeholder'
          )}
          {...fieldOptions?.extraCostInfo?.props}
          readOnly={readOnly || fieldOptions?.extraCostInfo?.props?.readOnly}
          {...restProps}
        />
      )}
    </Grid>
  );
};
