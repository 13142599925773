import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useWatch } from 'react-hook-form';

export const useProcessingFormLengthWarning = ({
  name,
  maxLength,
}: {
  name: 'bookings.0.note' | 'bookings.0.postingText';
  maxLength: number;
}) => {
  const [field] = useWatch<
    ProcessingFormValues,
    ['bookings.0.note' | 'bookings.0.postingText']
  >({
    name: [name],
  });

  return { lengthExceeded: (field?.length ?? 0) >= maxLength };
};
