import { CardLimitRenewFrequency } from 'generated-types/graphql.types';

export const limitPeriodOptions = [
  {
    key: CardLimitRenewFrequency.Monthly,
    children: 'ccRequestForm.fields.limitInterval.monthly.label',
  },
  {
    key: CardLimitRenewFrequency.Quarterly,
    children: 'ccRequestForm.fields.limitInterval.quarterly.label',
  },
  {
    key: CardLimitRenewFrequency.Annual,
    children: 'ccRequestForm.fields.limitInterval.annual.label',
  },
  {
    key: CardLimitRenewFrequency.Total,
    children: 'ccRequestForm.fields.limitInterval.total.label',
  },
];
