import {
  Box,
  Flex,
  Grid,
  Item,
  Text,
  TruncatedText,
  Tag,
  TagProps,
} from '@candisio/design-system';
import { space } from '@candisio/design-system/src/Theme/themeValues';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { FilterableList } from 'components/FilterableList/FilterableList';
import {
  EmailInboxMessage,
  EmailInboxMessageStatus,
  EmailInboxSortField,
  useEmailInboxMessageWithAttachmentsQuery,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import DateFmt from 'utils/date_formatter';
import { escapeRegex } from 'utils/regex';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { Cards } from './components/Cards/Cards';
import { MailDetails } from './components/MailDetails';
import { useMailSyncData } from './hooks/useMailSyncData';

const EMAIL_INBOX_WIDTH = '50rem';

export enum FilterBy {
  All = 'ALL',
  Failed = EmailInboxMessageStatus.Failed,
  Warn = EmailInboxMessageStatus.Warn,
  NoInvoice = 'NO_INVOICE',
}

export enum SearchParam {
  Status = 'status',
}

enum SortBy {
  receivedAt = EmailInboxSortField.Receivedat,
  sender = EmailInboxSortField.Sender,
  subject = EmailInboxSortField.Subject,
}

const queryEmails = (emails: EmailInboxMessage[], queryString: string) => {
  const regExp = new RegExp(escapeRegex(queryString), 'i');

  return emails.filter(emails => {
    if (emails.subject?.match(regExp) || emails.from?.match(regExp)) {
      return true;
    }

    return null;
  });
};

const filterEmails = (emails: EmailInboxMessage[], filterBy: FilterBy) => {
  if (filterBy === FilterBy.NoInvoice) {
    return emails.filter(email => email.numberOfGeneratedDocuments === 0);
  }

  return emails;
};

export const sortEmails = (
  emails: EmailInboxMessage[],
  sortBy: EmailInboxSortField
) => {
  const collator = new Intl.Collator([], { numeric: true });

  switch (sortBy) {
    case EmailInboxSortField.Sender: {
      return emails?.sort((a: EmailInboxMessage, b: EmailInboxMessage) =>
        collator.compare(a.from ?? '', b.from ?? '')
      );
    }

    case EmailInboxSortField.Subject: {
      return emails?.sort((a: EmailInboxMessage, b: EmailInboxMessage) =>
        collator.compare(a.subject ?? '', b.subject ?? '')
      );
    }

    default: {
      return emails;
    }
  }
};

const isEmailInboxMessage = (
  emailInboxMessage: unknown
): emailInboxMessage is EmailInboxMessage =>
  !!emailInboxMessage &&
  typeof emailInboxMessage === 'object' &&
  '__typename' in emailInboxMessage &&
  emailInboxMessage.__typename === 'EmailInboxMessage';

export const MailSync = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const { updateSearchParam } = useMutateSearchParams();
  const { mailId } = useParams();
  const [queryString, setQueryString] = useState('');
  const [filterBy, setFilterBy] = useState<FilterBy>(FilterBy.All);

  const [sortBy, setSortBy] = useState<EmailInboxSortField>(
    EmailInboxSortField.Receivedat
  );

  const { hasMore, isLoading, mailSyncData, onEndReached } =
    useMailSyncData(sortBy);

  const filterItems = [
    {
      id: FilterBy.All,
      label: t('settings.mailSync.filterItems.all'),
    },
    {
      id: FilterBy.Failed,
      label: t('settings.mailSync.filterItems.failed'),
    },
    {
      id: FilterBy.Warn,
      label: t('settings.mailSync.filterItems.warn'),
    },
    {
      id: FilterBy.NoInvoice,
      label: t('settings.mailSync.filterItems.noInvoice'),
    },
  ];

  const sortItems = [
    {
      id: SortBy.receivedAt,
      label: t('settings.mailSync.sortItems.receivedAt'),
    },
    {
      id: SortBy.sender,
      label: t('settings.mailSync.sortItems.sender'),
    },
    {
      id: SortBy.subject,
      label: t('settings.mailSync.sortItems.subject'),
    },
  ];

  const filterButtonText = filterItems.find(
    item => filterBy.toString() === item.id
  )?.label;

  const sortButtonText = sortItems.find(
    item => sortBy.toString() === item.id
  )?.label;

  const handleFilterBy = (value: Key[]) => {
    const filterValue = value.length ? (value[0] as FilterBy) : filterBy;

    setFilterBy(filterValue);
    updateSearchParam(SearchParam.Status, filterValue);
  };

  const handleSortBy = (value: Key[]) => {
    // TODO: pass sort value to search params when query supports it
    setSortBy(value.length ? (value[0] as EmailInboxSortField) : sortBy);
  };

  const onSearchFilter = (filter: string) => {
    // TODO: add debounceSearch when query supports it
    setQueryString(filter);
  };

  const handleSearchReset = () => {
    setQueryString('');
  };

  const [emailId, setEmailId] = useState(mailId ?? '');

  const handleSelect = useCallback(
    (item?: EmailInboxMessage) => {
      const existingSearchParams = new URLSearchParams(
        location.search
      ).toString();

      navigate({
        pathname: `/${organizationId}${Routes.SETTINGS}${Routes.MAIL_SYNC}${
          item ? `/${item?.id}` : ''
        }`,
        search: existingSearchParams,
      });

      setEmailId(item?.id ?? '');
    },
    [navigate, organizationId]
  );

  const { data } = useEmailInboxMessageWithAttachmentsQuery({
    skip: !emailId,
    variables: { id: emailId },
  });

  const email = isEmailInboxMessage(data?.emailInboxMessage)
    ? data?.emailInboxMessage
    : null;

  const queriedEmails = queryEmails(mailSyncData, queryString);
  const filteredEmails = filterEmails(queriedEmails, filterBy);
  const sortedEmails = sortEmails(filteredEmails, sortBy);

  const onDrawerClose = useCallback(() => handleSelect(), [handleSelect]);

  const statuses: Record<
    Uppercase<EmailInboxMessageStatus>,
    TagProps['color']
  > = {
    RECEIVED: 'green',
    WARN: 'yellow',
    FAILED: 'red',
  };

  const statusTranslationMap: Record<
    Uppercase<EmailInboxMessageStatus>,
    string
  > = {
    RECEIVED: 'settings.mailSync.messageStatus.received',
    WARN: 'settings.mailSync.messageStatus.warn',
    FAILED: 'settings.mailSync.messageStatus.failed',
  };

  return (
    <SettingsLayout
      title={t('settings.mailSync.title')}
      width={EMAIL_INBOX_WIDTH}>
      <FilterableList
        children={sortedEmails.map((email: EmailInboxMessage) => (
          <Item key={email.id} textValue={email.subject || email.id}>
            <FilterableListItem
              itemId={email.id}
              onSelect={() => handleSelect(email)}>
              <Grid
                gap="space16"
                templateColumns={`${space.space96} auto auto`}>
                <Flex
                  color="gray500"
                  fontSize="small"
                  fontWeight="regular"
                  alignItems="center"
                  gap="space32">
                  <Tag color={statuses[email.status]} variant="secondary">
                    {t(statusTranslationMap[email.status])}
                  </Tag>
                </Flex>
                <Grid>
                  <Text fontWeight="semibold" fontSize="basic">
                    {renderQueryHighlight({
                      value: isNil(email.subject)
                        ? t('settings.mailSync.noSubject')
                        : email.subject,
                      queryString: queryString,
                    })}
                  </Text>
                  {/* @ts-ignore */}
                  <TruncatedText
                    as="p"
                    color="gray500"
                    fontSize="small"
                    fontWeight="regular">
                    {renderQueryHighlight({
                      value: email.from ?? '',
                      queryString: queryString,
                    })}
                  </TruncatedText>
                </Grid>
                <Flex
                  color="gray500"
                  fontSize="small"
                  fontWeight="regular"
                  alignItems="center"
                  justifySelf="end"
                  gap="space32">
                  <Box>
                    {email.numberOfGeneratedDocuments === 0 ? (
                      <Text>-</Text>
                    ) : (
                      <Flex alignItems="center" gap="space4">
                        <Tag
                          color="gray"
                          icon="inbox"
                          size="small"
                          variant="secondary">
                          {email.numberOfGeneratedDocuments}
                        </Tag>
                      </Flex>
                    )}
                  </Box>
                  <Box>
                    {DateFmt.compactDatetime(
                      new Date(email.createdAt as string)
                    )}
                  </Box>
                </Flex>
              </Grid>
            </FilterableListItem>
          </Item>
        ))}
        emptyDataState={
          !isLoading && (
            <EmptyDataState translation="settings.mailSync.nodata.title" />
          )
        }
        emptySearchState={<EmptySearchState searchReset={handleSearchReset} />}
        hasMore={hasMore}
        isLoading={isLoading}
        menuButtons={[
          {
            actionValue: [filterBy],
            onClick: handleFilterBy,
            text: filterButtonText ?? '',
            menuButtonItems: filterItems,
          },
          {
            actionValue: [sortBy],
            onClick: handleSortBy,
            text: sortButtonText ?? '',
            menuButtonItems: sortItems,
          },
        ]}
        onEndReached={onEndReached}
        searchField={{
          onSearchFilter,
          placeholder: t('settings.mailSync.searchPlaceholder'),
          searchQuery: queryString,
        }}
        width={EMAIL_INBOX_WIDTH}
      />
      <Cards />
      <MailDetails onClose={onDrawerClose} mail={email} isLoading={isLoading} />
    </SettingsLayout>
  );
};
