import { Color, Tag, Tooltip, useTooltip } from '@candisio/design-system';

type TagWithTooltipProps = {
  color: Color;
  label: string;
  description: string;
};

export const TagWithTooltip = ({
  color,
  label,
  description,
}: TagWithTooltipProps) => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
      delay: 500,
    });

  return (
    <>
      <Tag color={color} {...triggerProps} ref={triggerRef}>
        {label}
      </Tag>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {description}
        </Tooltip>
      )}
    </>
  );
};
