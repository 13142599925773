import gql from 'graphql-tag';

export const listCandisAPISessionsQuery = gql`
  query listCandisAPISessions {
    listCandisAPISessions {
      sessionId
      clientName
      authorizationUser {
        id
        name
      }
      connectionDate
    }
  }
`;

export const revokeCandisAPISessionMutation = gql`
  mutation revokeCandisAPISession($sessionId: String!) {
    revokeCandisAPISession(sessionId: $sessionId) {
      ... on RevokeCandisAPISessionSuccessful {
        isRevoked
      }
      ... on RevokeCandisAPISessionError {
        kind
        message
      }
    }
  }
`;
