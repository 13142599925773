import { MenuItem } from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { ComponentProps, ElementRef, forwardRef } from 'react';

type ActionsMenuProps = {
  isActionPending: boolean;
  items: MenuItem[];
} & Omit<ComponentProps<typeof KebabMenu>, 'items'>;

export const ActionsMenu = forwardRef<
  ElementRef<typeof KebabMenu>,
  ActionsMenuProps
>(({ items, isActionPending, ...rest }, ref) => (
  <KebabMenu
    {...rest}
    ref={ref}
    items={items}
    disabled={!items?.length}
    loading={isActionPending}
    menuPlacement="right top"
  />
));
