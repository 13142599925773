import {
  useContactsImportedForCompanyQuery,
  useNextFreeAccountsPayableNumberQuery,
} from 'generated-types/graphql.types';

interface UseNextFreeAccountsPayableNumberReturn {
  loading: boolean;
  nextFreeAccountsPayableNumber?: string;
}

/**
 * Gets the next free accounts payable number when contacts have previously been
 * imported for the current organization
 */
export const useNextFreeAccountsPayableNumber =
  (): UseNextFreeAccountsPayableNumberReturn => {
    const {
      data: contactsImportedForCompanyData,
      loading: loadingContactsImportedForCompany,
    } = useContactsImportedForCompanyQuery();

    const contactsImportedForCompany =
      contactsImportedForCompanyData?.contactsImportedForCompany ?? false;

    const {
      loading: loadingNextFreeAccountsPayableNumber,
      data: nextFreeAccountsPayableNumberData,
    } = useNextFreeAccountsPayableNumberQuery({
      skip: !contactsImportedForCompany,
    });

    return {
      loading:
        loadingContactsImportedForCompany ||
        loadingNextFreeAccountsPayableNumber,
      nextFreeAccountsPayableNumber:
        nextFreeAccountsPayableNumberData?.nextFreeAccountsPayableNumber?.toString() ??
        undefined,
    };
  };
