import {
  CardBrand,
  CardIssuerCard,
  CardIssuerCardDataFragment,
  CardIssuerCardSortField,
  CardStatus,
  CardType,
  GetCardsInfiniteScrollPaginationQuery,
  GetCardsInfiniteScrollPaginationQueryVariables,
  SortDirection,
  useGetPageBasedCardIssuerCardsQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { getCardsInfiniteScrollPaginationQuery } from 'views/CreditCards/CreditCardsInsights/gql';

interface UseCreditCardOptionsParams {
  selectedValues: string[];
  searchStr: string;
  teamMemberIds: string[];
}

export interface CreditCardOption {
  id: string;
  label: string | undefined;
  cardNumber: string | undefined;
  purpose: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  isAssignedToTeam: boolean;
  type: CardType;
  brand: CardBrand;
}

const defaultSort = {
  direction: SortDirection.Asc,
  field: CardIssuerCardSortField.LabelAndRefNumQuery,
};

const sortForInputString = {
  direction: SortDirection.Desc,
  field: CardIssuerCardSortField.LabelAndRefNumQuery,
};

type Team = Pick<CardIssuerCardDataFragment, 'team'>;

const mapToCreditCardOption = (
  cards: (Pick<
    CardIssuerCard,
    'id' | 'label' | 'refNum' | 'purpose' | 'brand' | 'cardholder' | 'type'
  > &
    Team)[],
  teamId: string | undefined
) =>
  cards
    .map(cc => {
      return {
        id: cc.id,
        label: cc.label ?? undefined,
        cardNumber: cc.refNum ? `•••• ${cc.refNum}` : undefined,
        purpose: cc.purpose ?? undefined,
        brand: cc.brand ?? CardBrand.Candis,
        firstName: cc.cardholder.firstName ?? undefined,
        type: cc.type,
        lastName: cc.cardholder.lastName ?? undefined,
        isAssignedToTeam: cc.team?.id && cc.team?.id !== teamId ? true : false,
      };
    })
    .sort((a, b) =>
      `${a.cardNumber} ${a.label}`.localeCompare(`${b.cardNumber} ${b.label}`)
    )
    .filter(cc => cc.cardNumber);

export const useCreditCardOptions = ({
  searchStr,
  selectedValues,
  teamMemberIds,
}: UseCreditCardOptionsParams) => {
  const { id } = useParams<{ id: string }>();
  const computeVariables = useCallback(
    (inputString?: string): GetCardsInfiniteScrollPaginationQueryVariables => ({
      input: {
        limit: 20,
      },
      queries: {
        query: inputString,
      },
      filters: {
        membershipIds: teamMemberIds,
        statuses: [
          CardStatus.Active,
          CardStatus.Locked,
          CardStatus.LockedPin,
          CardStatus.Pending,
        ],
      },
      sortBy: inputString ? sortForInputString : defaultSort,
    }),
    [teamMemberIds]
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<
      GetCardsInfiniteScrollPaginationQuery,
      GetCardsInfiniteScrollPaginationQueryVariables
    >({
      query: getCardsInfiniteScrollPaginationQuery,
      queryRootKey: 'getPageBasedCardIssuerCards',
      computeVariables,
      options: {
        skip: teamMemberIds.length === 0,
      },
    });

  const unselectedEntries = data?.getPageBasedCardIssuerCards?.records ?? [];

  const { data: selectedData, loading: isLoadingSelected } =
    useGetPageBasedCardIssuerCardsQuery({
      variables: {
        input: { page: 0, limit: selectedValues.length },
        filters: {
          ids: selectedValues,
          statuses: [
            CardStatus.Active,
            CardStatus.Locked,
            CardStatus.LockedPin,
            CardStatus.Pending,
          ],
        },
        sortBy: defaultSort,
      },
      skip:
        selectedValues.length === 0 ||
        Boolean(searchStr) ||
        teamMemberIds.length === 0,
      fetchPolicy: 'cache-and-network',
    });

  const selectedEntries =
    selectedData?.getPageBasedCardIssuerCards?.records ?? [];

  const mappedSelectedCreditCards = mapToCreditCardOption(selectedEntries, id);

  const mappedUnselectedCreditCards = mapToCreditCardOption(
    unselectedEntries,
    id
  );

  const creditCardOptions: CreditCardOption[] = [
    ...mappedSelectedCreditCards,
    ...mappedUnselectedCreditCards,
  ];

  return {
    creditCardOptions: uniqBy(creditCardOptions, 'id'),
    loading: isLoadingSelected || loading,
    loadMore,
    handleDebounceSearch,
  };
};
