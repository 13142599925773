import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { DocumentFormValues } from 'components/Form/DocumentForm/toolkit/schema';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useMemo } from 'react';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';

interface UseSumOfBookingsAmountsProps {
  bookings:
    | ProcessingFormValues['bookings']
    | DocumentFormValues['bookings']
    | DocumentApprovalFormValues['bookings'];
}

export const useSumOfBookingsAmounts = ({
  bookings,
}: UseSumOfBookingsAmountsProps) => {
  const memoizedSumOfBookingsAmounts = useMemo(() => {
    return bookings.reduce(
      (sum, { amount }) => roundToCurrencyPrecision(sum + (amount ?? 0)),
      0
    );
  }, [bookings]);

  return memoizedSumOfBookingsAmounts;
};
