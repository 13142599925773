import { EcmDocumentsTableData } from './types';

type DocumentFields = keyof Omit<EcmDocumentsTableData, 'cursor' | 'id'>;

export const availableEcmCommonFilters = [
  'contact',
  'contractStatus',
  'contractType',
  'costCenter',
  'documentStatus',
  'documentType',
  'notifyPerson',
  'responsiblePerson',
  'tags',
] as const satisfies Array<DocumentFields>;

export const availableEcmDateFilters = [
  'documentDate',
  'endDate',
  'startDate',
  'terminationDate',
  'terminationReminderDate',
] as const satisfies Array<DocumentFields>;

export const availableEcmDocumentFilters = [
  ...availableEcmCommonFilters,
  ...availableEcmDateFilters,
];

export type AvailableEcmDocumentFiltersKeys =
  (typeof availableEcmDocumentFilters)[number];

export const sortableEcmFields = [
  'documentDate',
  'documentStatus',
  'documentType',
  'endDate',
  'startDate',
  'terminationDate',
  'terminationReminderDate',
] as const satisfies Array<DocumentFields>;

export const ecmDocumentFieldsToGQLFieldsNameMap = {
  contact: 'contactId',
  contractStatus: 'contractStatus',
  contractType: 'documentSubCategory',
  costCenter: 'costCenterIds',
  documentDate: 'documentDate',
  documentStatus: 'documentStatus',
  documentType: 'documentType',
  endDate: 'endDate',
  notifyPerson: 'notifyPerson',
  responsiblePerson: 'responsiblePerson',
  startDate: 'startDate',
  tags: 'tags',
  terminationDate: 'terminationDate',
  terminationReminderDate: 'terminationReminderDate',
} as const;
