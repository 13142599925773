import { Card, Flex } from '@candisio/design-system';
import { SessionActionCardHeader } from 'views/Settings/ApiSettings/elements/Card/SessionActionCardHeader';
import { ApiSessionList } from 'views/Settings/ApiSettings/elements/List/ApiSessionList';

export const SessionActionCard = () => {
  return (
    <Card
      borderRadius="8px"
      background="white"
      padding="space16"
      height="max-content">
      <Flex direction="column" gap="space16">
        <SessionActionCardHeader />
        <ApiSessionList />
      </Flex>
    </Card>
  );
};
