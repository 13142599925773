interface UseGetFilterItemsParams {
  year: number;
}

const startingCreditCardsDate = '1/1/22';

export const useGetFilterItems = ({ year }: UseGetFilterItemsParams) => {
  const maxYear = year;

  const minYear = new Date(startingCreditCardsDate).getFullYear();

  const filterItems = [...Array(maxYear - minYear + 1)].map((_, b) => ({
    id: year - b,
    label: String(year - b),
  }));

  return { filterItems };
};
