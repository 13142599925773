import { useApprovePhysicalCardRequestMutation } from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { ModifiedLimitDetails } from '../../types';

interface UseApprovePhysicalCardRequestParams {
  cardId: string;
}

export const useApprovePhysicalCardRequest = ({
  cardId,
}: UseApprovePhysicalCardRequestParams) => {
  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  const [approvePhysicalCardRequest, { loading }] =
    useApprovePhysicalCardRequestMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        ...refetchQueries.cardIssuerCards(cardId),
        ...refetchQueries.cardIssuerCardsCardManager(),
      ],
      onCompleted() {
        evictPaginationResults();
      },
    });

  return {
    approvePhysicalCard: async (
      cardRequestId: string,
      updatedCardDeatils?: ModifiedLimitDetails
    ) => {
      const { errors } = await approvePhysicalCardRequest({
        variables: { input: { cardRequestId, ...updatedCardDeatils } },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    approvingPhysicalCard: loading,
  };
};
