import {
  Card,
  Image,
  Flex,
  Grid,
  Separator,
  TruncatedText,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';
import { BaseHospitalityValues } from 'views/Reimbursement/toolkit/expensesFormSchema';
import recieptFormIcon from '../../media/receiptFormIcon.svg';

interface ExpenseFormWrapperProps {
  totalAmount: BaseHospitalityValues['totalAmount'];
  reason: BaseHospitalityValues['reason'];
  children: ReactNode;
  expenseType: 'hospitality' | 'general';
  expenseId: string;
}

export const ExpenseFormWrapper = ({
  totalAmount,
  expenseType,
  reason,
  children,
  expenseId,
}: ExpenseFormWrapperProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const formattedReason = reason ? `| ${reason}` : undefined;
  const formattedAmount = totalAmount
    ? amountFormatNonNull(totalAmount, 'EUR')
    : amountFormatNonNull(0, 'EUR');

  const titleKey =
    expenseType === 'hospitality'
      ? 'reimbursementView.middleSection.form.hospitality.headerTitle'
      : 'reimbursementView.middleSection.form.general.headerTitle';

  return (
    <Card background="gray100" padding="space24 space40" id={expenseId}>
      <Grid paddingBottom="space24">
        <Grid templateColumns="2fr 1fr" gap="space16" alignItems="baseline">
          <Flex gap="space16">
            <Card
              background="pink100"
              opacity="50%"
              padding="space4"
              bottom={5}>
              <Image src={recieptFormIcon} alt="reciept icon" />
            </Card>

            <TruncatedText
              fontSize="large"
              fontWeight="semibold"
              height="fit-content">
              {t(titleKey, {
                reason: formattedReason,
              })}
            </TruncatedText>
          </Flex>
          <TruncatedText fontSize="large" justifySelf="end">
            {t(
              'reimbursementView.middleSection.form.hospitality.totalAmountTitle',
              {
                totalAmount: formattedAmount,
              }
            )}
          </TruncatedText>
        </Grid>
        <Separator />
      </Grid>
      {children}
    </Card>
  );
};
