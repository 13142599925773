import {
  DocumentStatus,
  useDocumentWorkflowStatusQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useUserInActiveFirstWFStep } from 'utils/isUserInActiveFirstWFStep';

export const useCanRemoveAttachment = (documentId: string | undefined) => {
  const { data } = useDocumentWorkflowStatusQuery({
    variables: { id: documentId ?? '' },
    skip: !documentId,
  });

  const { isUserInActiveFirstWFStep } = useUserInActiveFirstWFStep();

  const currentUser = useCurrentUser();
  const { isAdmin, isRequester, isAccountant } = useUserRoles();

  const status = data?.getDocument?.status;

  const userHasAccess = isAdmin || isRequester || isAccountant;

  const documentIsExported =
    status === DocumentStatus.Exporting || status === DocumentStatus.Exported;

  const canAttachmentBeRemoved = (attachedById?: string) => {
    if (!documentId) {
      return false;
    }

    if (userHasAccess && !documentIsExported) {
      return true;
    }

    if (
      status === DocumentStatus.Open &&
      isUserInActiveFirstWFStep(data?.getDocument, currentUser?.id) &&
      Boolean(attachedById) &&
      attachedById === currentUser?.id
    ) {
      return true;
    }

    return false;
  };

  return canAttachmentBeRemoved;
};
