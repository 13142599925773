import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ExportableDocumentConnection } from 'generated-types/graphql.types';
import { paginationWithRecordConnectionsMerge } from '../pagination-results-merge';

const exportPagination: FieldPolicy<ExportableDocumentConnection> = {
  keyArgs: ['sort', 'filters'],
  merge: paginationWithRecordConnectionsMerge('ExportableDocumentConnection'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      documentsForExport: exportPagination,
    },
  },
};
