import { Switch, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { DETAILS_MAX_WIDTH } from '../../consts';
import { DcsContainer } from '../../DcsContainers/DcsContainer/DcsContainer';
import { EXPORT_TYPE_IMAGES } from '../../images';
import { getChecks } from '../states';
import { InfoList } from './InfoList';

interface AccountingDocumentsProps {
  language: string;
}

// Document Images
export const AccountingDocuments = ({ language }: AccountingDocumentsProps) => {
  const [t] = useTranslation();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  return (
    <DcsContainer
      icon={EXPORT_TYPE_IMAGES.accountingDocuments[language]}
      iconAlt={t('settings.datev.imageAltTexts.documentImages')}
      iconHeight="108px"
      iconWidth="79.2px"
      title={t('settings.datev.connect.exportTypes.accountingDocuments.title')}
      details={
        <>
          <Text maxWidth={DETAILS_MAX_WIDTH}>
            {t('settings.datev.connect.exportTypes.accountingDocuments.info')}
          </Text>
          <InfoList checks={[getChecks(t).connected.success]} />
        </>
      }
      actions={
        <>
          {/* @ts-expect-error  https://candis.atlassian.net/browse/DS-735 */}
          <Switch
            {...triggerProps}
            ref={triggerRef}
            name={t(
              'settings.datev.connect.exportTypes.accountingDocuments.nameLabel'
            )}
            label={t(
              'settings.datev.connect.exportTypes.accountingDocuments.accountingDocumentsSwitchLabel'
            )}
            checked={true}
            disabled
          />

          {isOpen && (
            <Tooltip {...tooltipProps} ref={tooltipRef}>
              <Text>
                {t(
                  'settings.datev.connect.exportTypes.accountingDocuments.disabledExplanation'
                )}
              </Text>
            </Tooltip>
          )}
        </>
      }
    />
  );
};
