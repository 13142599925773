import { Flex, Grid, TabItem, Tabs } from '@candisio/design-system';
import { useTabs } from 'components/Tabs/useTabs';
import { ProvisionEntityType } from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { AvailableEntity, TableType, useExportContext } from '../../Context';
import { useGetColumnIds } from '../../hooks/useGetColumnIds';
import { useExportContactsFactory } from '../../toolkit/hooks/useExportContactsFactory';
import { useExportDataGigaFactory } from '../../toolkit/hooks/useExportDataGigaFactory';
import { useExportPaymentConditionsFactory } from '../../toolkit/hooks/useExportPaymentConditionsFactory';
import { Entity, Table, View } from '../../types';
import { ConfigurationsMenuContainer } from './ConfigurationsMenuContainer';

type TableTabItem = TabItem & {
  showFor: AvailableEntity[];
};

export const TableTabs = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const [tCommon] = useTranslation();
  const state = useExportContext();
  const {
    table,
    setTable,
    entity,
    exportId,
    view,
    availableTables,
    tableId,
    includeAllPaymentConditions,
    includeAllContacts,
  } = state;

  const hasInfiniteScroll = !exportId;

  const { bdsBought } = useDatev(); // BDS-checked
  const creditCards = useCreditCardsSetup();

  const {
    paginatedDocumentsTableData,
    exportRowEntities: {
      ready: {
        documentEntities,
        transactionEntities,
        provisionEntities,
        reversalEntities,
        settlementEntities,
      },
      notReady: {
        notReadyTransactionsAsExportRows,
        notReadySettlementEntitiesAsExportRows,
      },
    },
  } = useExportDataGigaFactory(state);

  const contacts = useExportContactsFactory();
  const paymentConditions = useExportPaymentConditionsFactory();

  const getApprovalsCount = () => {
    if (view === View.NOT_READY && entity === Entity.DOCUMENT) {
      return 0;
    } else if (view === View.NOT_READY && entity === Entity.TRANSACTION) {
      return notReadyTransactionsAsExportRows.length;
    } else if (entity === Entity.DOCUMENT) {
      return hasInfiniteScroll
        ? paginatedDocumentsTableData.totalCount
        : documentEntities.length;
    } else {
      // entity === Entity.TRANSACTION
      return transactionEntities.length;
    }
  };

  const getProvisionsCount = () => {
    if (view === View.NOT_READY) {
      return 0;
    }

    const entityMap = {
      [Entity.DOCUMENT]: ProvisionEntityType.Document,
      [Entity.TRANSACTION]: ProvisionEntityType.Transaction,
      [Entity.CORE_DATA]: null,
    };

    const relevantProvisions = provisionEntities.filter(
      p => p.provisionEntityType === entityMap[state.entity]
    );

    return relevantProvisions.length;
  };

  const getReversalsCount = () => {
    if (view === View.NOT_READY) {
      return 0;
    }

    const entityMap = {
      [Entity.DOCUMENT]: ProvisionEntityType.Document,
      [Entity.TRANSACTION]: ProvisionEntityType.Transaction,
      [Entity.CORE_DATA]: null,
    };

    const relevantReversals = reversalEntities.filter(
      p => p.provisionEntityType === entityMap[state.entity]
    );

    return relevantReversals.length;
  };

  const getSettlementsCount = () => {
    if (view === View.NOT_READY) {
      return notReadySettlementEntitiesAsExportRows.length;
    }

    return settlementEntities.length;
  };

  const getContactsCount = () => {
    if (view === View.NOT_READY) {
      return 0;
    }

    return contacts.count.newAndUpdated;
  };

  const getPaymentConditionsCount = () => {
    if (view === View.NOT_READY) {
      return 0;
    }

    return paymentConditions.count.newAndUpdated;
  };

  const countToBadge = (c: number) => {
    return c !== 0 ? c + '' : '';
  };

  const { bdsConnected } = useDatev();

  const tableItemMap: Record<TableType, TableTabItem> = {
    [Table.APPROVALS]: {
      key: Table.APPROVALS,
      title: t('tableTypes.invoices', { count: getApprovalsCount() }),
      ...(entity === Entity.TRANSACTION && creditCards.showPromo
        ? {
            icon: 'arrowUpCircleFilled',
            iconColor: 'purple500',
            iconTooltip: tCommon('tag.addOn'),
          }
        : {
            badge: countToBadge(getApprovalsCount()),
            badgeColor: 'darkGray',
          }),
      showFor: [Entity.DOCUMENT, Entity.TRANSACTION],
    },
    [Table.PROVISIONS]: {
      key: Table.PROVISIONS,
      title: t('tableTypes.provisions', {
        count: getProvisionsCount(),
      }),
      ...(bdsBought
        ? {
            badge: bdsConnected ? countToBadge(getProvisionsCount()) : '',
            badgeColor: 'darkGray',
          }
        : {
            icon: 'arrowUpCircleFilled',
            iconColor: 'purple500',
            iconTooltip: tCommon('tag.addOn'),
          }),
      showFor: [Entity.DOCUMENT],
    },
    [Table.SETTLEMENTS]: {
      key: Table.SETTLEMENTS,
      title: t('tableTypes.settlements', {
        count: getSettlementsCount(),
      }),
      ...(creditCards.showPromo
        ? {
            icon: 'arrowUpCircleFilled',
            iconColor: 'purple500',
            iconTooltip: tCommon('tag.addOn'),
          }
        : {
            badge: countToBadge(getSettlementsCount()),
            badgeColor: 'darkGray',
          }),
      showFor: [Entity.TRANSACTION],
    },
    [Table.REVERSALS]: {
      key: Table.REVERSALS,
      title: t('tableTypes.reversals', {
        count: getReversalsCount(),
      }),
      badge: countToBadge(getReversalsCount()),
      showFor: [Entity.DOCUMENT],
    },
    [Table.CONTACTS]: {
      key: Table.CONTACTS,
      title: t('tableTypes.contacts', {
        count: getContactsCount(),
      }),
      badge: countToBadge(getContactsCount()),
      showFor: [Entity.CORE_DATA],
    },
    [Table.PAYMENT_CONDITIONS]: {
      key: Table.PAYMENT_CONDITIONS,
      title: t('tableTypes.paymentConditions', {
        count: getPaymentConditionsCount(),
      }),
      badge: countToBadge(getPaymentConditionsCount()),
      showFor: [Entity.CORE_DATA],
    },
  };

  const { tabsProps } = useTabs({
    selectedKey: table,
    items: availableTables
      .map(t => tableItemMap[t])
      .filter(({ showFor }) => showFor.includes(entity)),
    onSelectionChange: key => {
      setTable(key as TableType);
    },
  });

  const isConfigurationMenuVisible = view !== View.HISTORY;

  const columnIds = useGetColumnIds({
    tableId,
  });

  return (
    <Flex gap="space24" paddingBottom="space8">
      <Grid
        borderBottom="1px solid gray300"
        width="100%"
        autoFlow="column"
        alignItems="center"
        justifyContent="space-between">
        <Tabs {...tabsProps} size="small" />
        {/** The state inside this component needs to be reset each time we change a table
         *  hence we force the component to remount by adding a key
         */}
        {isConfigurationMenuVisible && (
          <ConfigurationsMenuContainer
            key={`${tableId}-${includeAllPaymentConditions}-${includeAllContacts}-${columnIds.join(
              '-'
            )}`}
          />
        )}
      </Grid>
    </Flex>
  );
};
