import { getOperationName } from '@apollo/client/utilities';
import gql from 'graphql-tag';
import { documentHistoryQuery } from 'views/queries';
import { pageInfoDataFragment } from 'views/queries/fragments';

const exportFragment = gql`
  fragment Export on Export {
    id
    status
    type
    exportableEntityInfo {
      id
      type
      status
      __typename
    }
  }
`;

const provisionFragment = gql`
  fragment Provision on Provision {
    id
    deliveryDate
    glAccount
    contact {
      name
    }
    entity {
      status
      id
      type
    }
    netAmount {
      currency
      amount
      precision
    }
    bookings {
      glAccount
      account
    }
  }
`;

const reversalFragment = gql`
  fragment Reversal on Reversal {
    id
    deliveryDate
    invoiceDate
    glAccount
    contact {
      name
    }
    entity {
      status
      id
      type
    }
    netAmount {
      currency
      amount
      precision
    }
    bookings {
      glAccount
      account
    }
  }
`;

export const getPaginatedExportsQuery = gql`
  query _exports($input: ExportFilters, $limit: Int, $after: String) {
    _exports(input: $input, limit: $limit, after: $after)
      @connection(key: "exportsHistory", filter: ["input"]) {
      node {
        ...Export
        createdAt
        creator {
          id
          name
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${exportFragment}
`;

const exportableDocumentFragment = gql`
  fragment AnExportableDocument on ExportableDocument {
    id
    documentGuid
    contact {
      id
      name {
        value
      }
    }
    amount {
      amount
      currency
      precision
    }
    paymentState {
      __typename
      ... on PaidDocumentState {
        isPaid
        paidAt
        method
      }
      ... on UnpaidDocumentState {
        isPaid
      }
    }
    invoiceDate
    invoiceNumber
    transactionStatus
    direction
    documentType
  }
`;

const exportableTransactionFragment = gql`
  fragment AnExportableTransaction on CardIssuerTransaction {
    id
    invoiceAssociationStatus
    billingAmount {
      value
      precision
      currency
    }
    transactionCreatedAt
    merchantLegalName
    accountingData {
      contact {
        id
        name {
          value
        }
      }
    }
    status
    member {
      firstName
      lastName
    }
    type
    cardName
  }
`;

export const documentsForExportQuery = gql`
  query documentsForExport(
    $limit: Int
    $after: String
    $filters: DocumentFilterInput
    $sort: SortInput
  ) {
    documentsForExport(
      limit: $limit
      after: $after
      filters: $filters
      sort: $sort
    )
      @connection(
        key: "documentsForExportPagination"
        filter: ["filters", "sort"]
      ) {
      node {
        ...AnExportableDocument
      }
      edges {
        cursor
        node {
          ...AnExportableDocument
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${exportableDocumentFragment}
`;

export const documentsForExportContactsQuery = gql`
  query documentsForExportContacts($limit: Int, $filters: DocumentFilterInput) {
    documentsForExportContacts: documentsForExport(
      limit: $limit
      filters: $filters
    ) {
      node {
        id
        contact {
          id
          name {
            value
          }
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
`;

export const cardTransactionsReadyForExportQuery = gql`
  query cardTransactionsReadyForExport {
    cardTransactionsReadyForExport {
      associatedCardTransactions {
        ...AnExportableDocument
      }
      unassociatedCardTransactions {
        ...AnExportableTransaction
      }
    }
  }
  ${exportableDocumentFragment}
  ${exportableTransactionFragment}
`;

export const cardTransactionsNotReadyForExportQuery = gql`
  query cardTransactionsNotReadyForExport {
    cardTransactionsNotReadyForExport {
      associatedCardTransactions {
        ...AnExportableDocument
      }
      unassociatedCardTransactions {
        ...AnExportableTransaction
      }
    }
  }
  ${exportableDocumentFragment}
  ${exportableTransactionFragment}
`;

export const cardSettlementsReadyForExportQuery = gql`
  query cardSettlementsReadyForExport(
    $before: String
    $after: String
    $limit: Int
    $statuses: [PaymentSettlementStatus!]!
    $paidSettlements: CardIssuerSettlementPaidSettlementsFilter
  ) {
    cardSettlementsReadyForExport(
      before: $before
      after: $after
      limit: $limit
      statuses: $statuses
      paidSettlements: $paidSettlements
    ) {
      node {
        id
        status
        amount {
          value
          precision
          currency
        }
        actualPaymentDate
        paymentCreatedAt
        plannedPaymentDate
        publicPaymentId
        statement {
          id
          hasFile
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
`;

export const provisionsReadyForExportQuery = gql`
  query provisionsReadyForExport(
    $before: String
    $after: String
    $limit: Int
    $filters: ExportablePovisionFilters!
  ) {
    provisionsReadyForExport(
      before: $before
      after: $after
      limit: $limit
      filters: $filters
    ) {
      node {
        ...Provision
        __typename
      }
      edges {
        id
      }
      pageInfo {
        startCursor
      }
    }
  }
  ${provisionFragment}
`;

export const reversalsReadyForExportQuery = gql`
  query reversalsReadyForExport(
    $before: String
    $after: String
    $limit: Int
    $filters: ExportableReversalFilters!
  ) {
    reversalsReadyForExport(
      before: $before
      after: $after
      limit: $limit
      filters: $filters
    ) {
      node {
        ...Reversal
        __typename
      }
      edges {
        id
      }
      pageInfo {
        startCursor
      }
    }
  }
  ${reversalFragment}
`;

const refetchDocumentsForExport = getOperationName(documentsForExportQuery) || {
  query: documentsForExportQuery,
};

const refetchTransactionsReadyForExport = getOperationName(
  cardTransactionsReadyForExportQuery
) || {
  query: cardTransactionsReadyForExportQuery,
};

const refetchTransactionsNotReadyForExport = getOperationName(
  cardTransactionsNotReadyForExportQuery
) || {
  query: cardTransactionsNotReadyForExportQuery,
};

const refetchSettlementsReadyForExport = getOperationName(
  cardSettlementsReadyForExportQuery
) || {
  query: cardSettlementsReadyForExportQuery,
};

const refetchProvisionsReadyForExport = getOperationName(
  provisionsReadyForExportQuery
) || {
  query: provisionsReadyForExportQuery,
};

const refetchReversalsReadyForExport = getOperationName(
  reversalsReadyForExportQuery
) || {
  query: reversalsReadyForExportQuery,
};

export const LIMIT = 10;
export const getRefetchExportsQueries = ({
  documentIds,
}: {
  documentIds?: string[];
}) => {
  const res = [
    {
      query: getPaginatedExportsQuery,
      variables: {
        limit: LIMIT,
      },
    },
    refetchDocumentsForExport,
    refetchTransactionsReadyForExport,
    refetchTransactionsNotReadyForExport,
    refetchSettlementsReadyForExport,
    refetchProvisionsReadyForExport,
    refetchReversalsReadyForExport,
  ];

  if (documentIds) {
    res.push(
      ...documentIds.map(id => ({
        query: documentHistoryQuery,
        variables: { id },
      }))
    );
  }

  return res;
};

export const exportNotifications = gql`
  query exportNotifications($input: ExportFilters) {
    exports(input: $input) {
      ...Export
      hash
      integration {
        details
      }
    }
  }
  ${exportFragment}
`;

export const datevStatusQuery = gql`
  query datevStatus {
    datevStatus {
      isOperational
      hasIncidentOnCandis
      hasIncidentOnDatev
    }
  }
`;

export const contactsForExportQuery = gql`
  query contactsForExport($fetchAllRecords: Boolean!) {
    contactsForExport(fetchAllRecords: $fetchAllRecords) {
      id
      name
      status
    }
  }
`;

export const paymentConditionsForExportQuery = gql`
  query paymentConditionsForExport($fetchAllRecords: Boolean!) {
    paymentConditionsForExport(fetchAllRecords: $fetchAllRecords) {
      id
      paymentConditionNumber
      dueDateOffset
      discountOffset
      discountPercentage
      status
    }
  }
`;

export const countExportableContactQuery = gql`
  query countExportableContact {
    evaluateCoreDataExport {
      exportableCoreDataCount {
        exportableContactCount {
          count
        }
        newAndUpdatedExportableContactCount {
          count
        }
      }
    }
  }
`;

export const countExportablePaymentConditionsQuery = gql`
  query countExportablePaymentConditions {
    evaluateCoreDataExport {
      exportableCoreDataCount {
        exportablePaymentConditionCount {
          count
        }
        newAndUpdatedExportablePaymentConditionCount {
          count
        }
      }
    }
  }
`;

export const getCoreDataExportStatusQuery = gql`
  query getCoreDataExportStatus {
    evaluateCoreDataExport {
      coreDataExportStatus {
        hasExportedContacts
        hasExportedPaymentConditions
      }
    }
  }
`;

export const getAvailableMonthsQuery = gql`
  query getAvailableMonths($originalExportId: String) {
    getAvailableMonths(originalExportId: $originalExportId) {
      month
      year
      numberOfTxnBasedDocuments
      numberOfDocuments
      numberOfTxnWithoutDocuments
      numberOfSettlements
      numberOfDocumentProvisions
      numberOfTxnProvisions
      numberOfDocumentReversals
      numberOfTxnReversals
    }
  }
`;

export const exportQuery = gql`
  query export($id: ID!) {
    export(id: $id) {
      id
      type
      clientNumber
      consultantNumber
      creator {
        id
        firstName
        lastName
        avatarUrl
      }
      documents {
        document {
          id
          contact {
            value {
              id
              name {
                value
              }
            }
          }
          status
          amount {
            value
          }
          category {
            direction {
              value
            }
          }
          invoiceDate {
            value
          }
          currency {
            value
          }
          invoiceNumber {
            value
          }
          documentFile {
            id
            name
          }
          datevGuid {
            value
          }
          paymentState {
            __typename
            ... on PaidDocumentState {
              isPaid
              paidAt
              method
            }
            ... on UnpaidDocumentState {
              isPaid
            }
          }
        }
        docStatus {
          type
          details
          warnings
          exportMethod
          exportError {
            message
            errorCode
            errorDescription
            errorUri
          }
        }
      }
      transactions {
        id
        invoiceAssociationStatus
        accountingData {
          contact {
            id
            name {
              value
            }
          }
        }
        billingAmount {
          value
          precision
          currency
        }
        transactionCreatedAt
        merchantLegalName
        status
        member {
          firstName
          lastName
        }
        type
        cardName
      }
      cardSettlements {
        id
        status
        amount {
          value
          currency
          precision
        }
        actualPaymentDate
        paymentCreatedAt
        plannedPaymentDate
        publicPaymentId
        statement {
          id
          hasFile
        }
      }
      provisions {
        ...Provision
        __typename
      }
      reversals {
        ...Reversal
        __typename
      }
      reversals {
        ...Reversal
        __typename
      }
      status
      client
      integration {
        type
        details
      }
      timeFrame {
        year
        month
      }
      originExportId
      createdAt
      updatedAt
      exportableEntities {
        id
        type
        __typename
      }
      exportableEntityInfo {
        id
        type
        status
        details
        warnings
        exportMethod
        exportError {
          message
          errorCode
          errorDescription
          errorUri
          __typename
        }
        __typename
      }
    }
  }
  ${provisionFragment}
  ${reversalFragment}
`;

export const getInitialBdsExportQuery = gql`
  query getInitialBdsExports($limit: Int!) {
    getInitialBdsExports(limit: $limit) {
      id
      type
      clientNumber
      consultantNumber
      creator {
        id
        firstName
        lastName
        avatarUrl
      }
      documents {
        document {
          id
          contact {
            value {
              id
              name {
                value
              }
            }
          }
          status
          amount {
            value
          }
          category {
            direction {
              value
            }
          }
          invoiceDate {
            value
          }
          currency {
            value
          }
          invoiceNumber {
            value
          }
          documentFile {
            id
            name
          }
          datevGuid {
            value
          }
          paymentState {
            __typename
            ... on PaidDocumentState {
              isPaid
              paidAt
              method
            }
            ... on UnpaidDocumentState {
              isPaid
            }
          }
        }
        docStatus {
          type
          details
          warnings
          exportMethod
          exportError {
            message
            errorCode
            errorDescription
            errorUri
          }
        }
      }
      transactions {
        id
        billingAmount {
          value
          precision
          currency
        }
        transactionCreatedAt
        merchantName
        status
        member {
          firstName
          lastName
        }
        type
        cardName
      }
      cardSettlements {
        id
        status
        amount {
          value
          currency
          precision
        }
        actualPaymentDate
        paymentCreatedAt
        plannedPaymentDate
        publicPaymentId
      }
      provisions {
        ...Provision
        __typename
      }
      reversals {
        ...Reversal
        __typename
      }
      reversals {
        ...Reversal
        __typename
      }
      status
      client
      integration {
        type
        details
      }
      timeFrame {
        year
        month
      }
      originExportId
      createdAt
      updatedAt
      exportableEntities {
        id
        type
        __typename
      }
      exportableEntityInfo {
        id
        type
        status
        details
        warnings
        exportMethod
        exportError {
          message
          errorCode
          errorDescription
          errorUri
          __typename
        }
        __typename
      }
    }
  }
  ${provisionFragment}
  ${reversalFragment}
`;
