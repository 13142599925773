import { RecurringPaymentVendorSource } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface Props {
  linkedVendors: string[];
  source: RecurringPaymentVendorSource | undefined;
}

export const useDeleteConfrimationText = ({ source, linkedVendors }: Props) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  if (!source) return;

  const vendors = linkedVendors.join(', ');

  const text: Record<
    RecurringPaymentVendorSource,
    { noVendor: string; oneOrMoreVendors: string }
  > = {
    FROM_SUGGESTIONS: {
      noVendor: t(
        'recurringPaymentsModal.unlinkCard.popover.noSuggestedVendor'
      ),
      oneOrMoreVendors: t(
        'recurringPaymentsModal.unlinkCard.popover.suggestedVendorDesc',
        {
          vendors,
        }
      ),
    },
    MANUALLY_ADDED: {
      noVendor: t('recurringPaymentsModal.unlinkCard.popover.noCustomVendor'),
      oneOrMoreVendors: t(
        'recurringPaymentsModal.unlinkCard.popover.customVendorDesc',
        {
          vendors,
        }
      ),
    },
  };

  if (!linkedVendors.length) {
    return text[source].noVendor;
  }

  return text[source].oneOrMoreVendors;
};
