import { useToastMessage } from 'components/Toast/useToastMessage';
import { useAcceptUsageAgreement } from 'containers/credit-cards/CreditCardCarousel/hooks/useAcceptUsageAgreement';
import { useActivateCard } from 'containers/credit-cards/CreditCardCarousel/hooks/useActivateCard';
import { useCheckPendingStatus } from 'containers/credit-cards/hooks/useCheckPendingStatus';
import { CardBrand } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NotAcceptedUsageAgreement } from '../Processing/NotAcceptedUsageAgreement';
import { ActivateForm } from './ActivateForm/ActivateForm';

export const ActivateContainer = ({ cardId }: { cardId: string }) => {
  const {
    cardBrand,
    isLoadingCard,
    isActivatingCard,
    hasError,
    setHasError,
    onActivateCard,
  } = useActivateCard({ cardId });

  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();

  const isCandisCardBrand = cardBrand === CardBrand.Candis;

  const { availablePendingActions, shippingDate, loading } =
    useCheckPendingStatus(cardId);

  const { acceptUsageAgreement, isAccepting } = useAcceptUsageAgreement();

  if (loading) {
    return null;
  }

  const handleAcceptUsageAgreement = async () => {
    if (isNil(availablePendingActions.notAcceptedUsageAgreement)) {
      return;
    }

    const response = await acceptUsageAgreement({
      cardId,
      cardPendingActionId:
        availablePendingActions.notAcceptedUsageAgreement?.actionId,
    });

    if (response.status === 'success') {
      success(t('carousel.processing.successToastMessage'));
    } else {
      error(t('carousel.processing.errorToastMessage'));
    }
  };

  if (!isNil(availablePendingActions.notAcceptedUsageAgreement)) {
    return (
      <NotAcceptedUsageAgreement
        createdAt={availablePendingActions.notAcceptedUsageAgreement.createdAt}
        onAcceptUsageAgreement={handleAcceptUsageAgreement}
        isAccepting={isAccepting}
      />
    );
  }

  return (
    <ActivateForm
      onActivate={onActivateCard}
      hasError={hasError}
      setHasError={setHasError}
      isLoading={isLoadingCard}
      isCandisCardBrand={isCandisCardBrand}
      isActivatingCard={isActivatingCard}
      shippingDate={shippingDate}
    />
  );
};
