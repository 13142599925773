import { Button, Grid, useTheme } from '@candisio/design-system';
import { ImportHistoryItem } from 'components/HistoryList/HistoryList';
import { isNil } from 'lodash';
import { ReactElement, useState } from 'react';
import { FilterTags } from 'views/DocumentDownloadHistory/FilterTags';
import { ButtonWrapper } from '../ButtonWrapper';
import { DownloadTitle } from '../DownloadTitle';
import { FileData } from '../FileData';

interface DownloadHistoryProps {
  item: ImportHistoryItem;
  queryString: string;
}

export const DownloadHistory = ({
  item,
  queryString,
}: DownloadHistoryProps): ReactElement => {
  const [isHovered, setIsHovered] = useState(false);
  const { colors } = useTheme();

  const hasFilters =
    !isNil(item.filters) ||
    !isNil(item.dateRangeFilters) ||
    !isNil(item.ecmFilters) ||
    !isNil(item.query);

  const isDownloadable = item.file?.url;

  return (
    <Grid
      gap="space32"
      padding="space16"
      alignItems="center"
      templateColumns="minmax(50ch, 1fr) auto auto"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div>
        <DownloadTitle item={item} queryString={queryString} />
        <FileData item={item} isDownloadVariant />
      </div>

      {hasFilters && (
        <FilterTags
          filters={item.filters}
          ecmFilters={item.ecmFilters}
          query={item.query}
          dateRangeFilters={item.dateRangeFilters}
        />
      )}

      {isHovered && isDownloadable && (
        <ButtonWrapper>
          <Button
            as="a"
            zIndex={1}
            right={8}
            size="small"
            icon="arrowDown"
            alignSelf="center"
            position="absolute"
            href={item.file?.url}
            style={{ color: colors.gray0 }}
          />
        </ButtonWrapper>
      )}
    </Grid>
  );
};
