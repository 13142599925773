import { useContext } from 'react';
import { useExportQuery } from '../../../../../../generated-types/graphql.types';
import { usePolling } from '../../../../../../hooks/usePolling';
import { ExportContext } from '../../../Context';
import { ApiExport } from '../../../toolkit/components/ApiExport';
import { FileExport } from '../../../toolkit/components/FileExport';
import { useExportDataGigaFactory } from '../../../toolkit/hooks/useExportDataGigaFactory';
import { useIsExporting } from '../../../toolkit/hooks/useIsExporting';
import { ExportType } from '../../../types';

const EXPORT_STATUS_POLLING_INTERVAL = 3000;

export const exportTypesWithAutomaticFileTransfer = [
  ExportType.DATEV,
  ExportType.DATEV_BDS,
  ExportType.SAP_B1,
  ExportType.API,
].map(String);

export const ExportHistoryDetails = () => {
  const state = useContext(ExportContext);
  const { isExporting } = useIsExporting();

  const { exportDetails } = useExportDataGigaFactory(state);

  const { exportId } = state;

  const { startPolling: startPollingExport, stopPolling: stopPollingExport } =
    useExportQuery({
      variables: { id: exportId || '' },
      skip: !exportId,
    });

  usePolling({
    pollInterval: EXPORT_STATUS_POLLING_INTERVAL,
    startPolling: startPollingExport,
    stopPolling: stopPollingExport,
    skip: !isExporting,
    pollOnlyOnFocus: false,
  });

  if (exportDetails) {
    const isExportTypeWithAutomaticFileTransfer =
      exportTypesWithAutomaticFileTransfer.includes(exportDetails.type);

    return isExportTypeWithAutomaticFileTransfer ? (
      <ApiExport exportDetails={exportDetails} />
    ) : (
      <FileExport exportDetails={exportDetails} />
    );
  }

  return null;
};
