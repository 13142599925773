import {
  CardIssuerTransactionView,
  DateFilterOption,
  InvoiceAssociationStatus,
  useCountCardIssuerTransactionsQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { getLastSevenDaysDateRange } from './utils';

export const useAutoMatchCountData = () => {
  const creditCardsSetup = useCreditCardsSetup();
  const creditCardsAutomatedMatchingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsAutomatedMatching
  );

  const currentDate = new Date();

  const { dateFrom, dateTo } = getLastSevenDaysDateRange({ currentDate });

  const skipQuery =
    !creditCardsSetup.isInUse && !creditCardsAutomatedMatchingFF;

  const { data, loading: loadingCount } = useCountCardIssuerTransactionsQuery({
    skip: skipQuery,
    variables: {
      input: {
        view: CardIssuerTransactionView.Archive,
      },
      filters: {
        invoiceAssociationStatus: [InvoiceAssociationStatus.AutoMatched],
        dateRangeFilters: {
          automatchingLinkedAt: {
            dateFrom,
            dateTo,
            filterOption: DateFilterOption.Custom,
          },
        },
      },
    },
  });

  const autoMatchCount = data?.countCardIssuerTransactions.count ?? 0;

  const loading = loadingCount;

  return { loading, autoMatchCount };
};
