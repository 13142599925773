import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useTrackConfigurations } from 'components/Table/Configurations/hooks/useTrackConfigurations';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { CreditCardsTableData } from 'views/CreditCards/types';

interface Params {
  filters: Filters<CreditCardsTableData>;
  sortBy: SortingRule<CreditCardsTableData>[];
}

interface GetDefaultColumnsConfigurationsOptions {
  isAccountingDataEnabled: boolean;
}

export const creditCardsTableId = 'credit-cards-table-configurations';

export const getDefaultColumnsConfigurations = ({
  isAccountingDataEnabled = false,
}: GetDefaultColumnsConfigurationsOptions): Configuration[] => [
  {
    id: 'cardNumber',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.cardNumber',
    isFixed: true,
  },
  {
    id: 'cardType',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.cardType',
  },
  {
    id: 'status',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.status',
  },

  {
    id: 'cardholderName',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.cardholderName',
  },
  {
    id: isAccountingDataEnabled ? 'cardAccounting' : 'category',
    isVisible: true,
    label: isAccountingDataEnabled
      ? 'credit-cards:cardManagerView.table.columns.cardAccounting'
      : 'credit-cards:cardManagerView.table.columns.category',
  },
  {
    id: 'issued',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.issued',
  },
  {
    id: 'validUntil',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.validUntil',
  },
  {
    id: 'lastTransactionCreatedAt',
    isVisible: true,
    label:
      'credit-cards:cardManagerView.table.columns.lastTransactionCreatedAt',
  },
  {
    id: 'transactionLimit',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.transactionLimit',
  },
  {
    id: 'availableBalance',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.availableBalance',
  },
  {
    id: 'pendingRequest',
    isVisible: true,
    label: 'credit-cards:cardManagerView.table.columns.pendingRequest',
  },
];

export const useGetCreditCardsTableConfigs = ({ filters, sortBy }: Params) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();
  const { showAllExtraFeatures } = useCreditCardsSetup();
  const { canManageCards } = usePermissionsForCreditCards();

  const { handleTrackHiddenColumns } = useTrackConfigurations();

  const defaultColumnsConfigurations = useMemo(() => {
    return getDefaultColumnsConfigurations({
      isAccountingDataEnabled: showAllExtraFeatures,
    });
  }, [showAllExtraFeatures]);

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { configurations } = useGetTableConfigurations({
    tableId: creditCardsTableId,
    defaultColumnsConfigurations,
  });

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: creditCardsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: creditCardsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
      handleTrackHiddenColumns(configurations, creditCardsTableId);
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  const updatedConfigsBasedOnUserPermissions = configurations.map(config => {
    if (config.id === 'cardholderName' && !canManageCards) {
      return { ...config, isAvailable: false };
    } else if (config.id === 'cardholderName' && canManageCards) {
      return { ...config, isAvailable: true };
    }

    return config;
  });

  const visibleColumnIds = updatedConfigsBasedOnUserPermissions
    .filter(config => config.isVisible && config.isAvailable !== false)
    .map(config => config.id as keyof CreditCardsTableData);

  useUpdateColumnsBE({
    tableId: creditCardsTableId,
    defaultColumnsConfigurations,
  });

  return {
    availableCreditCardsColumnIds: visibleColumnIds,
    configurationsTable: updatedConfigsBasedOnUserPermissions,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    isLoadingConfigs: isSavingConfigurations,
    isResetPending,
  };
};
