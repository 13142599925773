import { Skeleton } from '@candisio/design-system';
import { useInsightsPreferences } from 'components/Insights/hooks/useInsightsPreferences';
import {
  getIdsFromFilters,
  getWidgetType,
  mappedErrorsToTranslations,
  validStatusesForAggregate,
} from 'components/Insights/Modals/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DateFilterOption,
  DocumentStatus,
  FinancialInsightsQueryVariables,
  InsightsErrorCodes,
  InsightsWidget,
  useDeleteInsightsWidgetsMutation,
  useFinancialInsightsQuery,
  useUnsubscribeInsightsWidgetMutation,
  useUpdateInsightsWidgetPreferencesMutation,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { useShowError } from 'utils/error_message';
import { amountFormat } from 'utils/format';
import { validationErrorsExtractor } from 'utils/forms';
import { refetchInsightsWidgets } from 'views/InsightsWidgets/queries';
import {
  InsightsWidgetsFiltersFromUrl,
  WidgetModalTab,
} from 'views/InsightsWidgets/utils';
import { DOCUMENT_QUERY_PROPS } from '../../../DocumentsTable/Filters/filters';
import { FormattedAmountWithCurrencyConversion } from '../CurrencyConversion/CurrencyConversion';
import { extractInvoiceDateFromUrl } from '../DateRangePicker/util';
import { determineBudgetForDateRange, sourceTranslations } from '../utils';
import { BudgetInsightsWithProgressBar } from './BudgetInsightsWithProgressBar';
import { Sum } from './Sum';

export const SumContainer = ({
  budget,
  creator,
  filters,
  id: widgetId,
  isOwnedByCurrentUser,
  preferences: { onDashboard },
  sharedWithUsers,
  title,
  unsubscribedUsers,
}: InsightsWidget) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { success, error } = useToastMessage();
  const showGqlError = useShowError();
  const { preferences, loading: loadingPreferences } = useInsightsPreferences();

  const widgetType = getWidgetType(filters);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const source = t(sourceTranslations[widgetType!]);

  const currentUser = useCurrentUser();
  const { isAdmin, isRequester, isAccountant } = useUserRoles();

  const { costCenterIds, costObjectIds, contactIds, generalLedgerAccountIds } =
    getIdsFromFilters(filters);

  const organizationSlug = useOrganizationId();
  const history = useHistory();

  const invoiceDate = extractInvoiceDateFromUrl({
    search: window.location.search,
    userId: currentUser?.id,
    forceDefault: false,
  });

  const budgetAmount = determineBudgetForDateRange(invoiceDate, budget);
  const { dateFrom, dateTo } = invoiceDate.values;

  const variables: FinancialInsightsQueryVariables = {
    userId: creator?.id,
    filters: {
      costCenterIds: costCenterIds.length ? costCenterIds : null,
      costObjectIds: costObjectIds.length ? costObjectIds : null,
      contactIds: contactIds.length ? contactIds : null,
      generalLedgerAccountIds: generalLedgerAccountIds.length
        ? generalLedgerAccountIds
        : null,
      status: validStatusesForAggregate,
    },
    dateRangeFilters: {
      invoiceDate: {
        dateFrom,
        dateTo,
        filterOption: DateFilterOption.Custom,
      },
    },
    input: {
      preferences: {
        unit: preferences.unit,
      },
    },
  };

  const sumQuery = useFinancialInsightsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const [onDeleteWidgetMutation] = useDeleteInsightsWidgetsMutation();
  const [onUnsubscribeWidgetMutation] = useUnsubscribeInsightsWidgetMutation();
  const [onUpdateWidgetPreferencesMutation] =
    useUpdateInsightsWidgetPreferencesMutation();

  const { amount, currency } =
    sumQuery?.data?.financialInsights.sum?.aggregate ?? {};

  const formattedSum = amountFormat(amount, currency, {
    maximumFractionDigits: 0,
  });

  const onDeleteWidget = async () => {
    try {
      const result = await onDeleteWidgetMutation({
        variables: { ids: [widgetId] },
        refetchQueries: refetchInsightsWidgets,
      });

      if (result.data?.deleteInsightsWidgets) {
        success(t('widgets.contextMenu.deleteSuccess', { title }));
      } else if (result.errors?.length) {
        error(t('widgets.contextMenu.deleteFailure', { title }));
      }
    } catch (err) {
      showGqlError(err as GQLError);

      return validationErrorsExtractor(err);
    }
  };

  const onUnsubscribeWidget = async () => {
    try {
      const result = await onUnsubscribeWidgetMutation({
        variables: { id: widgetId },
        refetchQueries: refetchInsightsWidgets,
      });

      if (result.data?.unsubscribeInsightsWidget) {
        success(t('widgets.contextMenu.deleteSuccess', { title }));
      } else if (result.errors?.length) {
        error(t('widgets.contextMenu.deleteFailure', { title }));
      }
    } catch (err) {
      showGqlError(err as GQLError);

      return validationErrorsExtractor(err);
    }
  };

  const onMoveWidget = async () => {
    try {
      const result = await onUpdateWidgetPreferencesMutation({
        variables: { id: widgetId, input: { onDashboard: !onDashboard } },
        refetchQueries: refetchInsightsWidgets,
      });

      if (result.data?.updateInsightsWidgetPreferences) {
        success(t('widgets.contextMenu.moveSuccess', { title }));
      } else if (result.errors?.length) {
        result.errors.forEach(err => {
          const toastMessage =
            mappedErrorsToTranslations[
              err.extensions?.code as InsightsErrorCodes
            ];

          if (toastMessage) {
            error(t(toastMessage));
          }
        });
      }
    } catch (err) {
      showGqlError(err as GQLError);

      return validationErrorsExtractor(err);
    }
  };

  const filtersForUrl = {
    [DOCUMENT_QUERY_PROPS.costCenterIds]: costCenterIds,
    contact: contactIds,
    [DOCUMENT_QUERY_PROPS.costObjectIds]: costObjectIds,
    [DOCUMENT_QUERY_PROPS.generalLedgerAccountIds]: generalLedgerAccountIds,
    [DOCUMENT_QUERY_PROPS.invoiceDate]: `${dateFrom}-${dateTo}`,
    [DOCUMENT_QUERY_PROPS.status]: [
      DocumentStatus.Approved,
      DocumentStatus.New,
      DocumentStatus.Exported,
      DocumentStatus.Open,
    ],
  };

  const toFilteredDocumentsList = `/${organizationSlug}${
    Routes.ARCHIVE
  }?${qs.stringify(filtersForUrl)}`;

  const filtersFromUrl = qs.parse(
    window.location.search
  ) as InsightsWidgetsFiltersFromUrl;

  const onEditWidgetOnDashboardView = () => {
    history.replace({
      pathname: `/${organizationSlug}${Routes.DASHBOARD_WIDGET}/${widgetId}`,
      search: qs.stringify({
        ...filtersFromUrl,
        onDashboard: 'true',
        landingTab: WidgetModalTab.DETAILS,
      }),
    });
  };

  const onEditWidgetOnAllWidgetsView = () => {
    history.replace({
      pathname: `/${organizationSlug}${Routes.INSIGHTS_WIDGETS}/${widgetId}`,
      search: qs.stringify({
        ...filtersFromUrl,
        onDashboard,
        landingTab: WidgetModalTab.DETAILS,
      }),
    });
  };

  const isUserOnDashboard = history.location.pathname.includes(
    Routes.DASHBOARD
  );

  if (sumQuery.loading || !currentUser || loadingPreferences) {
    return (
      <Sum
        source={source}
        name={title}
        loading={sumQuery.loading}
        progressBar={<Skeleton width="100%" height="77.4px" />}
      />
    );
  }

  const readOnly =
    !isOwnedByCurrentUser && !isAdmin && !isRequester && !isAccountant;

  const openBudgetTabOnDashboard = {
    pathname: `/${organizationSlug}${Routes.DASHBOARD_WIDGET}/${widgetId}`,
    search: qs.stringify({
      ...filtersFromUrl,
      onDashboard: 'true',
      landingTab: WidgetModalTab.BUDGET,
    }),
  };

  const openBudgetTabOnAllWidgetsView = {
    pathname: `/${organizationSlug}${Routes.INSIGHTS_WIDGETS}/${widgetId}`,
    search: qs.stringify({
      ...filtersFromUrl,
      onDashboard,
      landingTab: WidgetModalTab.BUDGET,
    }),
  };

  return (
    <Sum
      creatorName={creator?.name}
      source={source}
      name={title}
      progressBar={
        <BudgetInsightsWithProgressBar
          budget={budgetAmount}
          aggregateSum={amount ?? 0}
          currency={currency}
          isOwnedByCurrentUser={isOwnedByCurrentUser}
          openBudgetTab={
            isUserOnDashboard
              ? openBudgetTabOnDashboard
              : openBudgetTabOnAllWidgetsView
          }
        />
      }
      formattedSum={
        formattedSum ? (
          <FormattedAmountWithCurrencyConversion
            formattedAmount={formattedSum}
            details={sumQuery?.data?.financialInsights.sum?.details ?? []}
          />
        ) : undefined
      }
      toFilteredDocumentsList={toFilteredDocumentsList}
      readOnly={readOnly}
      unsubscribedUsers={unsubscribedUsers ?? []}
      sharedWithUsers={
        sharedWithUsers && sharedWithUsers.length > 0 && creator
          ? [
              ...(isOwnedByCurrentUser ? [creator] : [creator, currentUser]),
              ...sharedWithUsers?.filter(x => x.id !== currentUser.id),
            ]
          : []
      }
      contextMenu={{
        isOwnedByCurrentUser,
        isOnDashboard: onDashboard,
        onDeleteWidget,
        onUnsubscribeWidget,
        onEditWidget: isUserOnDashboard
          ? onEditWidgetOnDashboardView
          : onEditWidgetOnAllWidgetsView,
        onMoveWidget,
      }}
    />
  );
};
