import { Link } from '@candisio/design-system';

interface IntercomLinkProps {
  translation: string;
  id?: string;
}

export const IntercomLink = ({ translation, id }: IntercomLinkProps) => {
  const handleIntercomClick = () => {
    window.Intercom('showSpace', 'messages');
  };

  return (
    <Link
      as="button"
      icon="mail"
      id={id}
      iconPosition="left"
      onClick={!id ? handleIntercomClick : undefined}
      textAlign="left"
      external>
      {translation}
    </Link>
  );
};
