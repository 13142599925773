import { Tag } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const LockedTag = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Tag color="blue" variant="secondary">
      {t('recurringPaymentList.locked')}
    </Tag>
  );
};
