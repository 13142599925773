import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useGetTeamById } from '../../toolkit/hooks/useGetTeamById';
import { useGetTeamSettingsForCreditCards } from '../../toolkit/hooks/useGetTeamSettingsForCreditCards';
import { TEAM_ID_URL_PARAM } from '../../toolkit/hooks/useModalVisibility';
import { SuccessModal } from './SuccessModal';

interface SuccessModalContainerProps {
  isOpen: boolean;
}

export const SuccessModalContainer = ({
  isOpen,
}: SuccessModalContainerProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const id = urlParams.get(TEAM_ID_URL_PARAM) ?? undefined;
  const { team } = useGetTeamById({ id });
  const { settingsForCreditCards } = useGetTeamSettingsForCreditCards({
    teamId: id,
  });

  const hasSetupCreditCards =
    settingsForCreditCards.creditCards.length > 0 ||
    settingsForCreditCards.isTeamAdminAllowedToManageCards;

  const handleClose = () => {
    navigate({ pathname: '..' }, { relative: 'path' });
  };

  return (
    <SuccessModal
      team={team}
      isOpen={isOpen}
      onClose={handleClose}
      hasSetupCreditCards={hasSetupCreditCards}
    />
  );
};
