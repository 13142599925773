import { EmptyDataText, EmptyDataWrapper } from 'components/EmptyViews/styles';
import { LottieAnimation, nocontactData } from 'components/Lottie/Lottie';
import { useTranslation } from 'react-i18next';

interface NoActiveDataOtherIntegrationProps {
  noTaxCodesYet?: boolean;
}

export const NoActiveDataOtherIntegration = ({
  noTaxCodesYet,
}: NoActiveDataOtherIntegrationProps) => {
  const [t] = useTranslation();

  return (
    <EmptyDataWrapper>
      <LottieAnimation options={{ animationData: nocontactData }} />
      <EmptyDataText>
        {noTaxCodesYet
          ? t('booking-keys:table.nodata.otherIntegration')
          : t('booking-keys:table.nodata.noActiveRecords')}
      </EmptyDataText>
    </EmptyDataWrapper>
  );
};
