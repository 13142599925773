import {
  Cell,
  CustomEmptyStateProps,
  Filter,
  FilterOptionsAccessor,
  Flex,
  SelectionOptions,
  Table,
  TableProps,
  useTheme,
} from '@candisio/design-system';
import { TagsCell } from 'components/DocumentsTable/Cells/TagsCell';
import { UserWithStatusCell } from 'components/DocumentsTable/Cells/UserWithStatusCell';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { DateRangeFilter } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import { paginationFiltersHooks } from 'components/Table/Filters/FilterWithSearchAndPagination/hooks/paginationFiltersHook';
import { EcmDocumentType } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Filters, Row, SortingRule, TableState } from 'react-table';
import { useMemoizedState } from 'utils/useMemoizedState';
import { useEcmContractTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';
import { TagsHeader } from '../DocumentsTable/TagsHeader';
import { ContractStatusCell } from './Cells/ContractStatusCell';
import { DateCell } from './Cells/DateCell';
import { DocumentNameCell } from './Cells/DocumentNameCell';
import { RelativeDateCell } from './Cells/RelativeDateCell';
import { StatusCell } from './Cells/StatusCell';
import {
  TruncatedTextCell,
  TruncatedTextCellProps,
} from './Cells/TruncatedTextCell';
import {
  EcmDocumentsTableToolbar,
  EcmDocumentsTableToolbarProps,
} from './EcmDocumentsTableToolbar';
import { Header } from './Header';
import { useEcmContractStatusOptions } from './hooks/useEcmContractStatusOptions';
import { useEcmDocumentStatusOptions } from './hooks/useEcmDocumentStatusOptions';
import { EcmDocumentsTableData } from './types';

export interface EcmDocumentsTableProps {
  data: Array<EcmDocumentsTableData>;
  isSingleSelect?: boolean;
  isTableFiltered: boolean;
  columns?: Array<keyof EcmDocumentsTableData>;
  selectedDocumentsCount: number;
  configurationsTable: Configuration[];
  searchQuery: string;
  onEndReached?: (index: number) => void;
  onSort?: (sortBy: SortingRule<EcmDocumentsTableData>[]) => void;
  onFilter?: (filters: Filters<EcmDocumentsTableData>) => void;
  onSearchChange: (searchQuery: string) => void;
  onUpdateConfigurations: (values: Configuration[]) => void;
  onResetTableConfigurations?: () => void;
  onRowClick?: (
    id: string,
    documentType: EcmDocumentType,
    cursor?: string
  ) => void;
  selectionOptions?: SelectionOptions<EcmDocumentsTableData>;
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  defaultFilters?: TableState<EcmDocumentsTableData>['filters'];
  defaultSortBy?: TableState<EcmDocumentsTableData>['sortBy'];
  isLoading: boolean;
  isLoadingConfigurations?: boolean;
  tableFooter?: ReactNode;
  defaultEmptyStateContent?: ReactNode;
  context: EcmDocumentsTableToolbarProps['context'];
  rowOverlay?: TableProps<EcmDocumentsTableData>['rowOverlay'];
}

export const EcmDocumentsTable = ({
  data,
  columns: columnsProp,
  onEndReached,
  onFilter,
  onRowClick,
  onSort,
  onSearchChange,
  onUpdateConfigurations,
  onResetTableConfigurations,
  selectionOptions,
  customEmptyState: CustomEmptyState,
  tableFooter,
  defaultFilters,
  isSingleSelect = false,
  defaultSortBy,
  isLoading,
  isLoadingConfigurations,
  isTableFiltered,
  configurationsTable,
  searchQuery,
  selectedDocumentsCount,
  defaultEmptyStateContent,
  context,
  ...restProps
}: EcmDocumentsTableProps) => {
  const { showDocumentTags } = useEcm();
  const [documentTagsEcmFiltersFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.documentTagsEcmFilters,
  ]);

  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { space } = useTheme();
  const {
    ecmDocumentTypeTranslationMap,
    ecmDocumentTypeFilterItems: ecmDocumentTypeFilterOptions,
  } = useEcmDocumentTypeOptions();

  const { getContractTypeLabel, ecmContractTypeFilterItems } =
    useEcmContractTypeOptions();

  const { ecmContractStatusFilterOptions } = useEcmContractStatusOptions();
  const { ecmDocumentStatusFilterOptions } = useEcmDocumentStatusOptions();

  // Cache `columns` prop value to prevent the table state from getting
  // reinitialized on each render.
  const shownColumns = useMemoizedState(columnsProp);

  const handleRowClick = onRowClick
    ? (row: Row<EcmDocumentsTableData>) => {
        const { cursor, id, documentType, invoiceId } = row.original;

        // If the document is an invoice, we need to use `invoiceId` to fetch correct data
        const documentId =
          invoiceId && documentType === EcmDocumentType.Invoice
            ? invoiceId
            : id;

        onRowClick(documentId, documentType as EcmDocumentType, cursor);
      }
    : undefined;

  const filterOptions = useMemo(
    (): FilterOptionsAccessor<EcmDocumentsTableData> => ({
      documentDate: true,
      documentType: {
        data: ecmDocumentTypeFilterOptions,
      },
      documentStatus: {
        data: ecmDocumentStatusFilterOptions,
      },
      contractStatus: {
        data: ecmContractStatusFilterOptions,
      },
      contractType: {
        data: ecmContractTypeFilterItems,
      },
    }),
    [
      ecmDocumentTypeFilterOptions,
      ecmDocumentStatusFilterOptions,
      ecmContractStatusFilterOptions,
      ecmContractTypeFilterItems,
    ]
  );

  const defaultColumn = useMemo(
    (): Partial<Column<EcmDocumentsTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: ({ value, ...restProps }: TruncatedTextCellProps) => {
        return <TruncatedTextCell value={value} {...restProps} />;
      },
      disableFilters: !Boolean(filterOptions),
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof EcmDocumentsTableData];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              const isLoading = filterOption.isLoading;

              return (
                <Filter<EcmDocumentsTableData>
                  column={column}
                  options={filterOption.data}
                  isLoading={isLoading}
                  applyFilterButton={t('filters.apply')}
                  resetFilterButton={t('filters.reset')}
                  searchFieldPlaceholder={t('filters.searchPlaceholder')}
                  filterLabel={t('filters.label')}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            if (paginationFiltersHooks[column.id]) {
              return (
                <FilterWithSearchAndPagination
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
    }),
    [filterOptions, t]
  );

  const columns: Array<Column<EcmDocumentsTableData>> = useMemo(() => {
    const allColumns: Array<Column<EcmDocumentsTableData>> = [
      {
        accessor: 'documentStatus',
        Cell: StatusCell,
      },
      {
        accessor: 'contractType',
        Cell: ({ value, ...restProps }) => {
          return (
            <Cell value={getContractTypeLabel(value?.id)} {...restProps} />
          );
        },
        disableSortBy: true,
        width: space.space200,
      },
      {
        accessor: 'contractStatus',
        Cell: ContractStatusCell,
        disableSortBy: true,
      },
      {
        accessor: 'contact',
        disableSortBy: true,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        width: space.space200,
      },
      {
        accessor: 'costCenter',
        disableSortBy: true,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      {
        accessor: 'createdAt',
        Cell: DateCell,
        sortType: 'datetime',
        disableFilters: true,
        disableSortBy: true,
        width: space.space48,
      },
      {
        accessor: 'documentDate',
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        sortType: 'datetime',
      },
      {
        accessor: 'documentName',
        Cell: DocumentNameCell,
        disableFilters: true,
        disableSortBy: true,
        width: space.space200,
      },
      {
        accessor: 'documentNumber',
        disableSortBy: true,
        disableFilters: true,
        width: space.space200,
      },
      {
        accessor: 'documentType',
        Cell: ({ value, ...restProps }) => {
          return (
            <Cell
              value={ecmDocumentTypeTranslationMap[value as EcmDocumentType]}
              {...restProps}
            />
          );
        },
        width: '200px',
      },
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      ...(showDocumentTags
        ? [
            {
              accessor: 'tags',
              /** @ts-expect-error TODO: React upgrade props types mismatch */
              Cell: TagsCell,
              disableSortBy: true,
              disableFilters: !documentTagsEcmFiltersFF,
              /** @ts-expect-error TODO: React upgrade props types mismatch */
              Header: TagsHeader,
              width: 260,
              Filter: documentTagsEcmFiltersFF
                ? ({ column, handleUpdateIsFilterBeingUsed }) => (
                    <FilterWithSearchAndPagination
                      column={column}
                      onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                    />
                  )
                : undefined,
            } satisfies Column<EcmDocumentsTableData>,
          ]
        : []),
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      {
        accessor: 'notes',
        disableSortBy: true,
        disableFilters: true,
      },
      {
        accessor: 'startDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      {
        accessor: 'endDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      {
        accessor: 'terminationDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      {
        accessor: 'terminationReminderDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: RelativeDateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      {
        accessor: 'responsiblePerson',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: UserWithStatusCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: true,
      },
      {
        accessor: 'notifyPerson',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: UserWithStatusCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: true,
      },
    ];

    if (!shownColumns) {
      return allColumns;
    }

    return shownColumns
      .map(columnId => allColumns.find(col => col.accessor === columnId))
      .filter(Boolean) as Array<Column<EcmDocumentsTableData>>;
  }, [
    space.space200,
    space.space48,
    showDocumentTags,
    documentTagsEcmFiltersFF,
    shownColumns,
    getContractTypeLabel,
    ecmDocumentTypeTranslationMap,
  ]);

  const initialState = useMemo(
    () => ({
      filters: defaultFilters ?? [],
      sortBy: defaultSortBy ?? [],
    }),
    [defaultFilters, defaultSortBy]
  );

  return (
    <Flex
      height="100%"
      overflow="hidden"
      direction="column"
      // Do not remove this please. Unfortunately we need to make sure that when we remove or add columns
      // we unmount and re-mount the component so that the filter/sort state inside the table is reset.
      // If we do not do that we will end up left with filters/sorts inside the table state for columns that are not rendered.
      key={`ecm-documents-table-${(columnsProp ?? []).join('-')}`}>
      <EcmDocumentsTableToolbar
        context={context}
        isLoading={isLoading}
        isLoadingConfigurations={isLoadingConfigurations}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        searchQuery={searchQuery}
        selectedDocumentsCount={selectedDocumentsCount}
        onSearchChange={onSearchChange}
        onUpdateConfigurations={onUpdateConfigurations}
        onResetTableConfigurations={onResetTableConfigurations}
      />

      <Table<EcmDocumentsTableData>
        columns={columns}
        width="max-content"
        minWidth="100%"
        data={data}
        defaultColumn={defaultColumn}
        onEndReached={onEndReached}
        initialState={initialState}
        onRowClick={handleRowClick}
        onRowSelected={selectionOptions?.onSelectionRowChanged}
        selectedRowIds={selectionOptions?.selectedRowsIds}
        customEmptyState={CustomEmptyState}
        onFilter={onFilter}
        isSingleSelect={isSingleSelect}
        onSort={onSort}
        selectionOptions={selectionOptions}
        isLoading={isLoading}
        defaultEmptyStateContent={defaultEmptyStateContent}
        borderTopRadius="unset"
        {...restProps}
      />
    </Flex>
  );
};
