import { mergeProps } from '@candisio/design-system';
import {
  HookFormTextField,
  HookFormTextFieldProps,
} from 'components/HookFormFields/HookFormTextField';
import { useReadOnlyTransactionFormField } from '../hooks/useReadOnlyTransactionFormField';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';

export const ReadOnlyTransactionFormTextField = ({
  name,
  ...restProps
}: HookFormTextFieldProps<ReadOnlyTransactionFormValues>) => {
  const { fieldProps } = useReadOnlyTransactionFormField(name);

  return (
    <HookFormTextField name={name} {...mergeProps(restProps, fieldProps)} />
  );
};
