import { CreditCardProps } from 'containers/credit-cards/CreditCard/types';
import { CardBrand } from 'generated-types/graphql.types';
import { extractCardDetails, useGetCardById } from '../utils';
import { CandisLayout } from './Candis/CandisLayout';
import { CreditCardLoading } from './CreditCardLoading';
import { PliantLayout } from './Pliant/PliantLayout';

export const CreditCard = (props: CreditCardProps) => {
  return props.isCandisCreditCard ? (
    <CandisLayout
      showFooter
      cardType={props.creditCardType}
      initialState={props.initialState}
      cardDetails={props.details}
    />
  ) : (
    <PliantLayout
      cardType={props.creditCardType}
      initialState={props.initialState}
      cardDetails={props.details}
    />
  );
};

export const CreditCardContainer = ({ cardId }: { cardId: string }) => {
  const { card, loading: loadingCardDetails } = useGetCardById({
    cardId,
    fetchPolicy: 'cache-and-network',
  });

  if (loadingCardDetails) {
    return <CreditCardLoading />;
  }

  if (!card) {
    // TODO error state
    return null;
  }

  const { status, type: cardType, brand } = card;

  const details = extractCardDetails(card);

  const key = `${cardId}-${status}`;

  const creditCardProps = {
    initialState: status,
    creditCardType: cardType,
    // TODO restore updating the label while the form value changes?
    isCandisCreditCard: brand === CardBrand.Candis ? true : false,
    details,
  };

  return <CreditCard key={key} {...creditCardProps} />;
};
