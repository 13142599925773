import { ContactType } from 'generated-types/graphql.types';
import DateFormatters from 'utils/date_formatter';

export const CONTACT_TABS = {
  details: 'details',
  history: 'history',
};

export const CONTACT_HISTORY_START_DATE = new Date('2024-08-01');
export const CONTACT_HISTORY_START_ABSOLUTE_DATE = DateFormatters.compact(
  CONTACT_HISTORY_START_DATE
);

export const SUPPORTED_CONTACT_TYPES = new Map<ContactType, number>([
  [ContactType.NotSpecified, 0],
  [ContactType.Individual, 1],
  [ContactType.Company, 2],
]);

export const SUPPORTED_CONTACT_TYPES_NUMBERS = Array.from(
  SUPPORTED_CONTACT_TYPES.values()
);

export const DEFAULT_CONTACT_TYPE_NUMBER = Number(
  SUPPORTED_CONTACT_TYPES.get(ContactType.Company)
);

export const TAX_NUMBER_REGEX = /^[0-9]+(\/?[0-9])*$/;

export const SPECIAL_CHARACTERS_VAT_ID = ['.', '/', ' ', '-'];
export const SPECIAL_CHARACTERS_TAX_NUMBER = ['.', ' ', '-'];

export const SPECIAL_CHARACTERS_REGEX = new RegExp(
  `[${SPECIAL_CHARACTERS_VAT_ID.join('|')}]`,
  'g'
);
