import {
  DocumentStatus,
  Maybe,
  Money,
  PurchaseOrder,
} from 'generated-types/graphql.types';
import { ReactNode } from 'react';

export type PurchaseOrderCardData = {
  id: string;
  contact?: Maybe<string>;
  grossAmount?: Maybe<Money>;
  orderNumber?: Maybe<string>;
  postingDate?: Maybe<Date>;
  contactName?: Maybe<string>;
  accountsPayableNumber?: Maybe<string>;
};
export interface PurchaseOrderDetailsCardProps {
  purchaseOrder?: PurchaseOrderCardData;
  contextMenu?: ReactNode;
}

export type AcceptedDocumentStatus = Exclude<
  DocumentStatus,
  'ARCHIVED' | 'EXPORTED' | 'PROCESSING' | 'EXPORTING'
>;

export const popoverDescription: Record<AcceptedDocumentStatus, string> = {
  NEW: 'purchaseOrderCardContextMenu.popoverDescription.new',
  REJECTED: 'purchaseOrderCardContextMenu.popoverDescription.rejected',
  APPROVED: 'purchaseOrderCardContextMenu.popoverDescription.approved',
  OPEN: 'purchaseOrderCardContextMenu.popoverDescription.open',
};

export const dataToPurchaseOrderCardDetails = (
  data?: Maybe<Omit<PurchaseOrder, 'purchaseOrderBookingAssociations'>>
): PurchaseOrderCardData | undefined => {
  if (!data) return;

  return {
    id: data._id ?? undefined,
    contact: data.contactName ?? undefined,
    grossAmount: data.amount ?? undefined,
    orderNumber: data.orderNumber ?? undefined,
    postingDate: data.postingDate ? new Date(data.postingDate) : null,
  };
};
