import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';
import { DEFAULT_DEBOUNCE_TIME } from './useDebouncedSearch';

export const useDebouncedValue = <T = any>(
  value: T,
  wait = DEFAULT_DEBOUNCE_TIME,
  options = { leading: false }
) => {
  const [debouncedValue, setValue] = useState(value);
  const debouncedSetValue = useRef(
    debounce((val: T) => setValue(val), wait, options)
  ).current;

  useEffect(() => {
    debouncedSetValue(value);
  }, [value, debouncedSetValue]);

  useEffect(() => {
    return () => {
      debouncedSetValue.cancel();
    };
  }, [debouncedSetValue]);

  return [debouncedValue, debouncedSetValue.cancel] as const;
};
