import { Row } from 'react-table';
import { ImportPreviewTableData } from './types';

export const filterByValue = (
  rows: Row<ImportPreviewTableData>[],
  id: string[],
  filterArray: string[]
) => {
  const remainingRows: Array<Row<ImportPreviewTableData>> = [];

  rows.forEach(row => {
    filterArray.forEach(appliedFilter => {
      const columnSelector = id[0];
      const value = row.values[columnSelector];

      if (appliedFilter === value) {
        remainingRows.push(row);
      }
    });
  });

  return remainingRows;
};

/** use when the value key is inside an object */
export const filterByObject = (
  rows: Row<ImportPreviewTableData>[],
  id: string[],
  filterArray: string[]
) => {
  const remainingRows: Array<Row<ImportPreviewTableData>> = [];

  rows.forEach(row => {
    filterArray.forEach(appliedFilter => {
      const columnSelector = id[0];
      const value = row.values[columnSelector]['value'];

      if (appliedFilter === value) {
        remainingRows.push(row);
      }
    });
  });

  return remainingRows;
};
