import gql from 'graphql-tag';

export const setCurrentUserAbsenceMutation = gql`
  mutation setCurrentUserAbsence($input: SetCurrentUserAbsenceInput!) {
    setCurrentUserAbsence(input: $input) {
      ... on Absence {
        fromDate
        toDate
        status
        note
        substituteMembershipId
      }
      ... on SetAbsenceError {
        kind
        message
      }
    }
  }
`;

export const setAbsenceForMemberMutation = gql`
  mutation setAbsenceForMember($input: SetAbsenceForTeamMemberInput!) {
    setAbsenceForMember(input: $input) {
      ... on Absence {
        fromDate
        toDate
        status
        note
        membershipId
        substituteMembershipId
      }
      ... on SetAbsenceError {
        kind
        message
      }
    }
  }
`;

export const getCurrentUserAbsenceQuery = gql`
  query getCurrentUserAbsence {
    getCurrentUserAbsence {
      fromDate
      toDate
      status
      note
      substituteMembershipId
    }
  }
`;

export const getMemberAbsenceQuery = gql`
  query getMemberAbsence($id: String!) {
    getMemberAbsence(id: $id) {
      fromDate
      toDate
      status
      note
      membershipId
      substituteMembershipId
    }
  }
`;
