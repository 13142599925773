import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useContext } from 'react';
import { ExportContext } from 'views/Integrations/Export/Context';
import { Table } from 'views/Integrations/Export/types';

export const useShowEntitlementPromo = () => {
  const state = useContext(ExportContext);
  const { ft, promo } = useFeatureToggle();

  const isProvisionsTable = state.table === Table.PROVISIONS;
  const isReversalsTable = state.table === Table.REVERSALS;

  const provisionFeaturePromo = promo(ft.PROVISIONS);

  return provisionFeaturePromo && (isProvisionsTable || isReversalsTable);
};
