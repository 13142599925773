import {
  Button,
  Flex,
  Icon,
  IconKey,
  Paragraph,
  Skeleton,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { DOCUMENT_QUERY_PROPS } from 'components/DocumentsTable/Filters/filters';
import { CheckmarkIcon } from 'components/Icons/DefaultIcons';
import { useNewDashboardFF } from 'components/NewPromotions/hooks/useNewDashboardFF';
import { useInboxTransactionCount } from 'hooks/useCounterQueries';
import { compiledRoutes, Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useCardholderId } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { TRANSACTION_COLUMNS } from 'views/Inbox/Transactions/useTransactionListFilters';
import { OrganizationDetailProps, OrganizationDetailsProps } from './types';

const LOADING_BUTTON_HEIGHT = '36px';
const LOADING_BUTTON_WIDTH = '58px';

const renderIcon = (icon: IconKey, color?: string) => (
  <Icon icon={icon} color={color ? color : 'gray800'} size="space16" />
);

const OverDueIcon = renderIcon('bell', 'red500');
const ApproveIcon = renderIcon('approvals');
const MonitorIcon = renderIcon('view');
const ExportIcon = renderIcon('exports');
const ProcessIcon = renderIcon('inbox');
const TransactionsIcon = renderIcon('payments');

const OrganizationDetailsLoader = () => {
  return (
    <>
      {[1, 2].map(i => (
        <Skeleton
          key={i}
          height={LOADING_BUTTON_HEIGHT}
          width={LOADING_BUTTON_WIDTH}
        />
      ))}
    </>
  );
};

export const OrganizationDetail = ({
  loading,
  title,
  readonly,
  infoIcon,
  counts: { overdue, total, transactions },
}: OrganizationDetailProps) => {
  const areAllTasksCompleted =
    (total?.count === undefined || total.count === 0) &&
    (transactions?.count === undefined || transactions.count === 0);

  const overdueCounter = overdue?.count ?? 0;
  const totalCounter = total?.count ?? 0;
  const transactionsCounter = transactions?.count ?? 0;

  return (
    <Flex direction="column" gap="space8">
      <Flex gap="space4" alignItems="center">
        <Text
          fontSize="basic"
          fontWeight={readonly ? 'regular' : 'semibold'}
          color={readonly ? 'gray500' : 'gray800'}>
          {title}
        </Text>
        {infoIcon && infoIcon}
      </Flex>
      {/* Icon 'checkCircle' and Button parent Flex should have the same height to ensure
          they're vertically aligned. If we change the size of Button, this height should
          also be updated to match the size of the bigger element of these 2 */}
      <Flex gap="space4" height="36px" alignItems="center">
        {loading ? (
          <OrganizationDetailsLoader />
        ) : areAllTasksCompleted ? (
          <CheckmarkIcon size="space24" />
        ) : (
          <>
            {overdueCounter > 0 && (
              <Button
                label={overdue?.label}
                size="small"
                variant="secondary"
                color="red"
                onClick={overdue?.onClick}>
                <Flex justifyContent="center" gap="space4" alignItems="center">
                  <TruncatedText
                    maxWidth="4.5ch"
                    lineHeight="space20"
                    whiteSpace="normal"
                    overflowWrap="break-word">
                    {overdueCounter.toString()}
                  </TruncatedText>
                  {overdue?.icon}
                </Flex>
              </Button>
            )}
            {totalCounter > 0 && (
              <Button
                label={total?.label}
                size="small"
                variant="secondary"
                onClick={total?.onClick}>
                <Flex justifyContent="center" gap="space4" alignItems="center">
                  <TruncatedText
                    maxWidth="4.5ch"
                    lineHeight="space20"
                    whiteSpace="normal"
                    overflowWrap="break-word">
                    {totalCounter.toString()}
                  </TruncatedText>
                  {total?.icon}
                </Flex>
              </Button>
            )}

            {transactions && transactionsCounter > 0 && (
              <Button
                label={transactions.label}
                size="small"
                variant="secondary"
                onClick={transactions?.onClick}>
                <Flex justifyContent="center" gap="space4" alignItems="center">
                  <TruncatedText
                    maxWidth="4.5ch"
                    lineHeight="space20"
                    whiteSpace="normal"
                    overflowWrap="break-word">
                    {transactionsCounter.toString()}
                  </TruncatedText>
                  {transactions?.icon}
                </Flex>
              </Button>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export const OrganizationDetails = ({
  loading,
  membershipId,
  onClickTask,
  organizationId,
  showTransactionCounts,
  taskBreakdown,
}: OrganizationDetailsProps) => {
  const [tInsights] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const creditCardsSetup = useCreditCardsSetup();
  const { showNewDashboardPromotions } = useNewDashboardFF();
  const { cardholderId } = useCardholderId();

  const { inboxTransactionCount: cardholderInboxTransactionCount } =
    useInboxTransactionCount({
      cardholderIds: cardholderId ? [cardholderId] : undefined,
      skip: !cardholderId,
      fetchPolicy: 'cache-and-network',
    });

  const { inboxTransactionCount: totalInboxTransactionCount } =
    useInboxTransactionCount({
      skip: !creditCardsSetup.isInUse,
      fetchPolicy: 'cache-and-network',
    });

  const processingTitle = 'multiOrganization.table.taskBreakdown.inbox.title';

  const processingLabel = 'multiOrganization.table.taskBreakdown.inbox';

  const tasks: OrganizationDetailProps[] = [
    {
      title: tInsights(processingTitle),
      readonly: false,
      counts: {
        overdue: {
          count: taskBreakdown?.toProcess?.overdue,
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          label: tInsights(`${processingLabel}.overdue`),
          icon: OverDueIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.INBOX}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.status]: 'OVERDUE',
              })}`
            );
          },
        },
        total: {
          count: taskBreakdown?.toProcess?.total,
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          label: tInsights(`${processingLabel}.total`),
          icon: ProcessIcon,
          onClick: () => {
            onClickTask(`/${organizationId}${Routes.INBOX}`);
          },
        },
        ...(creditCardsSetup.isInUse &&
          showTransactionCounts &&
          cardholderId && {
            transactions: {
              count: cardholderInboxTransactionCount,
              // eslint-disable-next-line candis/no-template-strings-inside-translation
              label: tInsights(`${processingLabel}.transactions`),
              icon: TransactionsIcon,
              onClick: () => {
                onClickTask(
                  `/${organizationId}${compiledRoutes.inboxTransactions}?${TRANSACTION_COLUMNS.cardholderName}=${cardholderId}`
                );
              },
            },
          }),
      },
    },
    {
      title: tInsights(
        'multiOrganization.table.taskBreakdown.monitoring.title'
      ),
      infoIcon: <InfoMonitoring />,
      readonly: true,
      counts: {
        overdue: {
          count: taskBreakdown?.monitoringMyRequests?.toApprove?.overdue,
          label: tInsights(
            'multiOrganization.table.taskBreakdown.monitoring.overdue'
          ),
          icon: OverDueIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.APPROVALS}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.status]: 'OVERDUE',
                [DOCUMENT_QUERY_PROPS.requester]: membershipId,
              })}`
            );
          },
        },
        total: {
          count: taskBreakdown?.monitoringMyRequests?.toApprove?.total,
          label: tInsights(
            'multiOrganization.table.taskBreakdown.monitoring.total'
          ),
          icon: MonitorIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.APPROVALS}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.requester]: membershipId,
              })}`
            );
          },
        },
        ...(creditCardsSetup.isInUse &&
          showTransactionCounts && {
            transactions: {
              count: totalInboxTransactionCount,
              // eslint-disable-next-line candis/no-template-strings-inside-translation
              label: tInsights(`${processingLabel}.transactions`),
              icon: TransactionsIcon,
              onClick: () => {
                onClickTask(
                  `/${organizationId}${compiledRoutes.inboxTransactions}`
                );
              },
            },
          }),
      },
    },
    {
      title: tInsights('multiOrganization.table.taskBreakdown.approving.title'),
      readonly: false,
      counts: {
        overdue: {
          count: taskBreakdown?.toApprove?.overdue,
          label: tInsights(
            'multiOrganization.table.taskBreakdown.approving.overdue'
          ),
          icon: OverDueIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.APPROVALS}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.status]: 'OVERDUE',
                [DOCUMENT_QUERY_PROPS.approvers]: membershipId,
              })}`
            );
          },
        },
        total: {
          count: taskBreakdown?.toApprove?.total,
          label: tInsights(
            'multiOrganization.table.taskBreakdown.approving.total'
          ),
          icon: ApproveIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.APPROVALS}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.approvers]: membershipId,
              })}`
            );
          },
        },
      },
    },
    {
      title: tInsights('multiOrganization.table.taskBreakdown.exporting.title'),
      readonly: false,
      counts: {
        overdue: {
          count: taskBreakdown?.toExport?.overdue,
          label: tInsights(
            'multiOrganization.table.taskBreakdown.exporting.overdue'
          ),
          icon: OverDueIcon,
          onClick: () => {
            onClickTask(`/${organizationId}${Routes.EXPORTS}`);
          },
        },
        total: {
          count: taskBreakdown?.toExport?.total,
          label: tInsights(
            'multiOrganization.table.taskBreakdown.exporting.total'
          ),
          icon: ExportIcon,
          onClick: () => {
            onClickTask(`/${organizationId}${Routes.EXPORTS}`);
          },
        },
      },
    },
  ];

  return (
    <Flex
      gap="space12"
      padding="21px space16"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="basic"
      background={showNewDashboardPromotions ? 'gray0' : 'gray50'}
      wrap="wrap">
      {tasks.map(task => (
        <OrganizationDetail {...task} key={task.title} loading={loading} />
      ))}
    </Flex>
  );
};

const InfoMonitoring = () => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const [tInsights] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return (
    <Flex {...triggerProps} ref={triggerRef}>
      <Icon icon="infoOutline" color="gray500" size="space12" />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef} zIndex={4}>
          <Flex direction="column" width="350px" gap="space4">
            <Text fontWeight="semibold" color="gray800">
              {tInsights(
                'multiOrganization.table.column.monitoringInfoTooltip.title'
              )}
            </Text>
            <Paragraph>
              {tInsights(
                'multiOrganization.table.column.monitoringInfoTooltip.content'
              )}
            </Paragraph>
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};
