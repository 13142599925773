import { Color, Tag } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderStatus } from '../../../generated-types/graphql.types';

export const mapPurchaseOrderStatusToColors: {
  [key in PurchaseOrderStatus]?: Color;
} = {
  [PurchaseOrderStatus.Cancelled]: 'red',
  [PurchaseOrderStatus.Closed]: 'blue',
  [PurchaseOrderStatus.Delivered]: 'yellow',
  [PurchaseOrderStatus.Open]: 'gray',
  [PurchaseOrderStatus.Paid]: 'green',
};

export const mapPurchaseOrderStatusToTranslationKeys: {
  [key in PurchaseOrderStatus]?: string;
} = {
  [PurchaseOrderStatus.Cancelled]: 'purchaseOrderStatus.cancelled',
  [PurchaseOrderStatus.Delivered]: 'purchaseOrderStatus.delivered',
  [PurchaseOrderStatus.Closed]: 'purchaseOrderStatus.closed',
  [PurchaseOrderStatus.Open]: 'purchaseOrderStatus.open',
  [PurchaseOrderStatus.Paid]: 'purchaseOrderStatus.paid',
};

export const PurchaseOrderStatusTag = ({
  status,
}: {
  status: PurchaseOrderStatus;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <Tag
      color={mapPurchaseOrderStatusToColors[status] || 'gray'}
      variant="secondary">
      {t(mapPurchaseOrderStatusToTranslationKeys[status] || status)}
    </Tag>
  );
};
