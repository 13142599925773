import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useCleanupFieldOnUnmount } from '../hooks/useCleanupFieldOnUnmount';

export const VatId = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  // This hook will reset the state of vatId field because if the user types in a vatId
  // with an error and will select no in the radioGroup, the vatId field will be hidden
  // but the form will still contain an error making it impossible to the user to understand what is happening
  useCleanupFieldOnUnmount('vatId');

  return (
    <HookFormTextField
      name="vatId"
      label={t('insights.organizationSettingsDrawer.form.fields.vatId.label')}
      placeholder={t(
        'insights.organizationSettingsDrawer.form.fields.vatId.placeholder'
      )}
    />
  );
};
