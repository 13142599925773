import {
  CardCategory,
  DocumentCurrency,
  DocumentStatus,
  GetVendorSuggestionsForRecurringPaymentsQueryVariables,
  useGetVendorSuggestionsForRecurringPaymentsQuery,
} from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';

export interface Vendor {
  details: {
    id: string;
    vendorName: string;
    logoUrl?: string;
    isIgnored: boolean;
    category?: CardCategory;
    hasTransactions: boolean;
    contactIds: string[];
  };
  aggregate: {
    value: {
      amount: number;
      currency: DocumentCurrency;
      precision: number;
    };
    count: number;
    exchangeRate: {
      rate?: number;
      valueInBaseCurrency: {
        amount?: number;
        currency?: DocumentCurrency;
        precision?: number;
      };
    };
  };
  maxMonthlyInvoiceAmount: {
    amount: number;
    currency: DocumentCurrency;
    precision: number;
  };
}

export const vendorSuggestionsQueryVariables: GetVendorSuggestionsForRecurringPaymentsQueryVariables =
  {
    input: {
      limit: 50,
    },
    filters: {
      documentStatuses: [
        DocumentStatus.Approved,
        DocumentStatus.Exporting,
        DocumentStatus.Exported,
      ],
      includeIgnoredVendors: false,
    },
  };

export const useVendorSuggestionsData = () => {
  const creditCardsSetup = useCreditCardsSetup();

  const { data, loading } = useGetVendorSuggestionsForRecurringPaymentsQuery({
    variables: vendorSuggestionsQueryVariables,
    skip: !creditCardsSetup.showAllExtraFeatures,
  });

  const linkedCount =
    data?.getVendorSuggestionsForRecurringPayments.linkedCount ?? 0;

  const totalCount =
    data?.getVendorSuggestionsForRecurringPayments.pageInfo.totalCount ?? 0;

  const vendors: Vendor[] = (
    data?.getVendorSuggestionsForRecurringPayments.records ?? []
  ).map(v => ({
    details: {
      id: v.vendor.id,
      vendorName: v.vendor.name,
      logoUrl: v.vendor.logoUrl ?? undefined,
      isIgnored: v.vendor.isIgnored,
      category: v.vendor.category ?? undefined,
      hasTransactions: v.hasTransactions ?? false,
      contactIds: v.contactIds,
    },
    aggregate: {
      value: {
        amount: v.sum.aggregate.value.amount,
        currency: v.sum.aggregate.value.currency,
        precision: v.sum.aggregate.value.precision,
      },
      count: v.sum.aggregate.count,
      exchangeRate: {
        rate: v.sum.aggregate.exchangeRate?.rate,
        valueInBaseCurrency: {
          amount: v.sum.aggregate.exchangeRate?.valueInBaseCurrency.amount,
          currency: v.sum.aggregate.exchangeRate?.valueInBaseCurrency.currency,
          precision:
            v.sum.aggregate.exchangeRate?.valueInBaseCurrency.precision,
        },
      },
    },
    maxMonthlyInvoiceAmount: {
      amount: v.maxMonthlyInvoiceAmount.amount,
      precision: v.maxMonthlyInvoiceAmount.precision,
      currency: v.maxMonthlyInvoiceAmount.currency,
    },
  }));

  const vendorDetails = {
    vendors,
    convertedVendors: linkedCount,
    vendorsToConvert: totalCount,
  };

  return { loading, vendorDetails };
};
