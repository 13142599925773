import gql from 'graphql-tag';
import {
  transactionListFragment,
  pageInfoDataFragment,
} from 'views/queries/fragments';

export const inboxCardIssuerTransactionsQuery = gql`
  query inboxCardIssuerTransactions(
    $input: CardIssuerTransactionPaginationInput!
    $filters: InboxCardIssuerTransactionFiltersInput
    $sortBy: CardIssuerTransactionSortInput
    $queries: CardIssuerTransactionsQueryInput
  ) {
    inboxCardIssuerTransactions(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    ) {
      edges {
        cursor
        node {
          ...transactionListData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${transactionListFragment}
`;
