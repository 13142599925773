import { Card, Flex, Grid, Icon } from '@candisio/design-system';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ReactNode } from 'react';
import { ASSOCIATION } from '../types';
import { ApproverInfo } from './TopInfoBoxState/ApproverInfo';
import { LoadingInfoBoxCard } from './TopInfoBoxState/LoadingState';

interface RightInformationContentProps {
  associationState?: ASSOCIATION;
  bottomActionBox?: ReactNode;
  isLoadingCurrentCardTransaction: boolean;
  isInvoiceNeeded?: boolean;
}

export const RightInformationContent = ({
  associationState,
  bottomActionBox,
  isLoadingCurrentCardTransaction,
  isInvoiceNeeded,
}: RightInformationContentProps) => {
  const organizationSlug = useOrganizationId();

  if (!isInvoiceNeeded) {
    return null;
  }

  return (
    <Flex
      height="100%"
      paddingTop="space32"
      paddingRight="space16"
      direction="column"
      justifyContent="end"
      justifySelf="end">
      <Card
        background="bluebg"
        padding="space8"
        boxShadow="noShadow"
        style={{ marginBottom: '16px' }}>
        <Grid alignItems="start" gap="space8" templateColumns="20px auto">
          {isLoadingCurrentCardTransaction ? (
            <LoadingInfoBoxCard />
          ) : (
            <>
              <Icon icon="infoCircle" color="blue500" size="space20" />
              <Flex direction="column" gap="space5">
                <ApproverInfo
                  associationState={associationState}
                  organizationSlug={organizationSlug}
                />
              </Flex>
            </>
          )}
        </Grid>
      </Card>

      {bottomActionBox ?? null}
    </Flex>
  );
};
