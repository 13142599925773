import { useApolloClient } from '@apollo/client';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useMergeTagsMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CUSTOM_NAME } from '../../elements/Modal/Form/MergeTagsForm';
import { MergeTagsFormOutput } from '../../elements/Modal/Form/mergeTagsFormSchema';
import { DocumentTags } from './useDocumentTags';
import { hasDuplicateNameError } from './useMutateDocumentTags';

export const useMergeDocumentTags = (
  tags: DocumentTags,
  onMergeSuccess: () => void
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { error, success } = useToastMessage();
  const client = useApolloClient();

  const [mergeTags, { loading: isMergeActionPending }] = useMergeTagsMutation();

  const handleMergeTags = async (formState: MergeTagsFormOutput) => {
    const targetTag: { id: string | null; name: string | null } =
      formState.targetTagId === CUSTOM_NAME
        ? { id: null, name: formState.customName }
        : tags.find(({ id }) => id === formState.targetTagId) ?? {
            id: null,
            name: null,
          };

    const ids = tags
      .map(({ id }) => id)
      .filter(id =>
        formState.targetTagId === CUSTOM_NAME
          ? id
          : id !== formState.targetTagId
      );

    const { errors } = await mergeTags({
      variables: {
        input: {
          ids,
          targetTag: {
            id: targetTag.id,
            name: targetTag.name,
            description: '',
          },
        },
      },
      onCompleted: () => {
        client.cache.evict({ fieldName: 'tags' });
      },
    });

    if (typeof errors === 'object' && errors.length !== 0) {
      let errorMessage = t('documentTags.drawer.toast.errorMerge');

      if (errors.find(hasDuplicateNameError)) {
        errorMessage = t('documentTags.drawer.toast.duplicateNameError', {
          tagName: targetTag.name,
        });
      }

      return error(errorMessage);
    }

    success(t('documentTags.drawer.toast.merged'));
    onMergeSuccess();
  };

  return { handleMergeTags, isMergeActionPending };
};
