import { ComponentType } from 'react';
import { GetProps } from 'utils/extractors';
import { ActivityType } from '../types';
import { BaseItem } from './BaseItem';
import { Neutral } from './Neutral';
import { System } from './System';
import { User } from './User';

type Props = {
  type: ActivityType;
  initial: boolean;
} & GetProps<typeof BaseItem>;

type ItemComponents = {
  [P in ActivityType]: ComponentType<GetProps<typeof BaseItem>>;
};

const items: ItemComponents = {
  System,
  Neutral,
  User,
};

const isNeutral = (data: any): data is typeof Neutral => data === Neutral;

export const RenderItem = ({ type, children, initial, ...props }: Props) => {
  const WrappedComponent = items[type];
  /**
   * Neutral item has different style when it's beginning of the history
   */
  if (initial && isNeutral(WrappedComponent)) {
    return (
      <WrappedComponent isFilled={initial} {...props}>
        {children}
      </WrappedComponent>
    );
  }

  return <WrappedComponent {...props}>{children}</WrappedComponent>;
};
