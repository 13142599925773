import { EcmDocumentType } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface StorageFormDocumentTypeFieldItem {
  key: EcmDocumentType;
  children: string;
}

interface FilterItem {
  id: EcmDocumentType;
  label: string;
}

export type StorageFormDocumentTypeList = [StorageFormDocumentTypeFieldItem];

interface StorageFormDocumentTypeReturn {
  /** A map of the EcmDocumentType enum to the translated string */
  ecmDocumentTypeTranslationMap: Record<EcmDocumentType, string>;
  /** A list of select items used in the select component */
  ecmDocumentTypeSelectItems: Array<StorageFormDocumentTypeFieldItem>;
  /** A list of filter items used in the table */
  ecmDocumentTypeFilterItems: Array<FilterItem>;
}

/**
 * Document types that should be available for SAP users
 */
export const documentTypesForSapUsers = [
  EcmDocumentType.Contract,
  EcmDocumentType.Empty,
  EcmDocumentType.Invoice,
  EcmDocumentType.Offer,
  EcmDocumentType.OrderConfirmation,
  EcmDocumentType.Other,
];

/**
 * A custom hook to get the translation map and filter items for the ECM
 * document type based on the EcmDocumentType enum
 */
export const useEcmDocumentTypeOptions = (): StorageFormDocumentTypeReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { isActive: isSapActive } = useSap();

  const ecmDocumentTypeTranslationMap: Record<EcmDocumentType, string> = {
    [EcmDocumentType.Offer]: t('storageForm.fields.documentType.options.offer'),
    [EcmDocumentType.OrderConfirmation]: t(
      'storageForm.fields.documentType.options.orderConfirmation'
    ),
    [EcmDocumentType.PurchaseOrder]: t(
      'storageForm.fields.documentType.options.purchaseOrder'
    ),
    [EcmDocumentType.DeliveryNote]: t(
      'storageForm.fields.documentType.options.deliveryNote'
    ),
    [EcmDocumentType.Invoice]: t(
      'storageForm.fields.documentType.options.invoice'
    ),
    [EcmDocumentType.Contract]: t(
      'storageForm.fields.documentType.options.contract'
    ),
    [EcmDocumentType.Other]: t('storageForm.fields.documentType.options.other'),
    [EcmDocumentType.Empty]: '',
  };

  // Filter out "Other" and "Empty" first to reduce the size of the array
  // Check if SAP is active to filter out document types that are irrelevant to SAP users
  // Sort list alphabetically, then append "Other" to the end of the list
  const ecmDocumentTypeList = Object.entries(ecmDocumentTypeTranslationMap)
    .filter(
      ([key, _]) =>
        key !== EcmDocumentType.Other &&
        key !== EcmDocumentType.Empty &&
        (isSapActive
          ? documentTypesForSapUsers.includes(key as EcmDocumentType)
          : true)
    )
    .sort(([_a, translationA], [_b, translationB]) =>
      translationA.localeCompare(translationB)
    )
    .concat([[EcmDocumentType.Other, ecmDocumentTypeTranslationMap.OTHER]]);

  // Transform list to match the format of the Select component
  const ecmDocumentTypeSelectItems = ecmDocumentTypeList.map(
    ([key, children]) => {
      return {
        key,
        children,
      } as StorageFormDocumentTypeFieldItem;
    }
  );

  // Transform list to match the format of the table filter
  const ecmDocumentTypeFilterItems = ecmDocumentTypeList.map(([id, label]) => {
    return {
      id,
      label,
    } as FilterItem;
  });

  return {
    ecmDocumentTypeTranslationMap,
    ecmDocumentTypeSelectItems,
    ecmDocumentTypeFilterItems,
  };
};
