import { Grid } from '@candisio/design-system';
import {
  DocumentStatus,
  GetDocumentForDraftQuery,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { userCanApprove, userIsMonitoring } from 'views/Approvals';
import { Approved } from 'views/DocumentDetails/components/Approved/Approved';
import { Exported } from 'views/DocumentDetails/components/Exported/Exported';
import { Monitoring } from 'views/DocumentDetails/components/Monitoring';
import { New } from 'views/DocumentDetails/components/New';
import { Open } from 'views/DocumentDetails/components/Open';
import { Rejected } from 'views/DocumentDetails/components/Rejected';

interface DocumentTransactionDetailsProps {
  documentId: string;
  cycleTransactions: () => void;
  organizationSlug: string;
  onToggleEditDocumentMode: () => void;
}

export const DocumentTransactionDetails = ({
  documentId,
  organizationSlug,
  cycleTransactions,
  onToggleEditDocumentMode,
}: DocumentTransactionDetailsProps) => {
  const { data, loading } = useGetDocumentForDraftQuery({
    variables: {
      id: documentId,
    },
    skip: !documentId,
  });

  const history = useHistory();
  const currentUser = useCurrentUser();
  const redirectTo = (url: string) => history.push(url);

  const document = data?.getDocument;

  const componentArgs = {
    key: documentId,
    redirectTo,
    document,
    organizationSlug,
    loading,
    toggleEditMode: onToggleEditDocumentMode,
    cycleDocument: cycleTransactions,
  };

  const renderComponent = (doc?: GetDocumentForDraftQuery['getDocument']) => {
    if (!doc) {
      return null;
    }

    if (
      doc.status === DocumentStatus.Open &&
      (userIsMonitoring(doc, currentUser) || !userCanApprove(doc, currentUser))
    ) {
      return <Monitoring {...componentArgs} />;
    } else if (doc.status === DocumentStatus.New) {
      return <New {...componentArgs} />;
    } else if (doc.status === DocumentStatus.Open) {
      return <Open {...componentArgs} />;
    } else if (doc.status === DocumentStatus.Approved) {
      return <Approved {...componentArgs} isLoading={loading} />;
    } else if (doc.status === DocumentStatus.Exported) {
      return <Exported {...componentArgs} isLoading={loading} />;
    } else if (doc.status === DocumentStatus.Rejected) {
      return <Rejected {...componentArgs} />;
    }

    return null;
  };

  return (
    <Grid as="section" overflow="hidden" height="100%">
      {renderComponent(document)}
    </Grid>
  );
};
