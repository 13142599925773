import { Grid, Text } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  ProvisionExportedEventDataFragment,
  UserRole,
} from 'generated-types/graphql.types';
import i18next from 'i18next';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useDefineExportView } from 'views/Integrations/Export/Manifest';
import { Sidebar, Table, View } from 'views/Integrations/Export/types';

export const ProvisionExportedComponent = ({
  exportType,
  exportId,
}: ProvisionExportedEventDataFragment) => {
  const [t] = useTranslation();
  const orgId = useOrganizationId();

  const exportTypeTitleKey = `document.compact.history.provisionExported.exportTypes.${exportType?.toLowerCase()}`;
  const exportTypeTitle = i18next.exists(exportTypeTitleKey)
    ? t(exportTypeTitleKey)
    : t('document.compact.history.provisionExported.title');

  const viewDef = useDefineExportView({
    exportId,
    sidebar: Sidebar.HISTORY,
    view: View.HISTORY,
    table: Table.PROVISIONS,
  });

  const exportLink = `/${orgId}${viewDef.url}`;

  return (
    <Grid justifyItems="start">
      <Text>{exportTypeTitle}</Text>
      <UserRoleAccessWrapper
        allowedRoles={[UserRole.Admin, UserRole.Accountant]}>
        {exportId && orgId && (
          <RouterLink to={exportLink}>
            {t('document.compact.history.provisionExported.openExportHistory')}
          </RouterLink>
        )}
      </UserRoleAccessWrapper>
    </Grid>
  );
};
