import {
  TeamsQuery,
  TeamsQueryVariables,
  useSelectedTeamsOptionsQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useTeams } from 'orgConfig/teams/useTeams';
import { useCallback, useState } from 'react';
import { teamFieldOptionsQuery } from '../queries';

export interface Member {
  id: string;
  fullName: string;
  avatarUrl: string | undefined;
}

export interface Team {
  id: string | undefined;
  name: string;
  isArchived?: boolean;
  members: Member[] | undefined;
  teamAdmin: Partial<Member> | undefined;
}

export const useGetTeamFieldOptions = () => {
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const { isTeamsFeatureEnabled } = useTeams();

  const computeVariables = useCallback(
    (name?: string): TeamsQueryVariables => ({
      input: {
        page: 1,
        limit: 20,
      },
      queries: {
        name: (name ?? []).length > 0 ? name : undefined,
      },
      filters: {
        isArchived: false,
      },
    }),
    []
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<TeamsQuery, TeamsQueryVariables>({
      query: teamFieldOptionsQuery,
      queryRootKey: 'teams',
      options: { skip: !isTeamsFeatureEnabled },
      computeVariables,
    });

  const teams = data?.teams?.records ?? [];

  const mappedTeamOptions = teams.map(team => {
    return {
      id: team.id,
      name: team.name,
    };
  });

  const { data: selectedTeamsData } = useSelectedTeamsOptionsQuery({
    variables: {
      input: { page: 1, limit: selectedTeams.length },
      filters: { ids: selectedTeams },
    },
    skip: selectedTeams.length === 0 || !isTeamsFeatureEnabled,
  });

  const selectedMappedTeams = (selectedTeamsData?.teams?.records ?? [])
    .map(team => ({ id: team.id, name: team.name }))
    // Sort options based on selection order of user and not alphabetically
    .sort((a, b) => selectedTeams.indexOf(a.id) - selectedTeams.indexOf(b.id));

  const handleSetSelectedTeams = (selectedOptions: string[]) => {
    setSelectedTeams(selectedOptions);
  };

  return {
    teamOptions: mappedTeamOptions,
    loading,
    loadMore,
    handleDebounceSearch,
    selectedMappedTeams: selectedMappedTeams,
    handleSetSelectedTeams,
  };
};
