import { DocumentDirection } from 'views/utils/DocumentDirection';
import { taxCodeExportValidationSchema } from 'views/utils/taxCodeExportValidationSchema';

export const validateTaxCode = (
  taxCode: string,
  documentDirection?: DocumentDirection | string
): boolean => {
  if (
    !(taxCode in taxCodeExportValidationSchema) ||
    (documentDirection !== DocumentDirection.invoices_received &&
      documentDirection !== DocumentDirection.outgoing_invoices)
  ) {
    return true;
  }

  return taxCodeExportValidationSchema[taxCode][documentDirection];
};
