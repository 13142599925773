import { MenuItem, useModal } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useDownloadCSVFile } from 'views/Archive/Documents/hooks/useDownloadCSVFile';
import { DownloadedCSVModal } from './DownloadedCSVModal';
import { DownloadMenuButton } from './DownloadMenuButton';
import { DownloadPdfModal } from './DownloadPdfModal';
import { useDownloadPDFFiles } from './hooks/useDownloadPDFFiles';

interface DownloadMenuButtonContainerProps {
  selectedDocumentsCount: number;
  isTableLoading: boolean;
  isTableFiltered: boolean;
}

export const DownloadMenuButtonContainer = ({
  isTableLoading,
  selectedDocumentsCount,
  isTableFiltered,
}: DownloadMenuButtonContainerProps) => {
  const [searchParams] = useSearchParams();
  const { handleCSVDownload, isLoading } = useDownloadCSVFile(isTableLoading);
  const { modalProps, close, open } = useModal();

  const {
    modalProps: CSVmodalProps,
    close: closeCSVModal,
    open: openCSVModal,
  } = useModal();

  const { onPrepareDownloadPdfs, createPdfZipFileLoading } =
    useDownloadPDFFiles({ isTableLoading, handleClosePdfDownloadModal: close });

  const [t] = useTranslation();

  const handleCSVAction = useCallback(async () => {
    const result = await handleCSVDownload();

    if (result?.status !== 'error') {
      openCSVModal();
    }
  }, [handleCSVDownload, openCSVModal]);

  const items: Array<MenuItem> = [
    {
      id: 'download-CSV',
      label: t('archive:download.menuButton.options.downloadCSV'),
      onAction: !isLoading ? handleCSVAction : undefined,
    },
    {
      id: 'download-PDFs',
      label: t('archive:download.menuButton.options.downloadPDF'),
      onAction: open,
    },
  ];

  const isMenuButtonDisabled =
    isLoading ||
    isTableLoading ||
    !selectedDocumentsCount ||
    createPdfZipFileLoading;

  const hasSearchQuery = searchParams.has(queryParameter);

  return (
    <>
      <DownloadMenuButton
        items={items}
        isDisabled={isMenuButtonDisabled}
        isLoading={isTableLoading}
        isTableFiltered={isTableFiltered}
        isTableSearched={hasSearchQuery}
        selectedDocumentsCount={selectedDocumentsCount}
      />
      <DownloadPdfModal
        {...modalProps}
        isTableLoading={isLoading}
        selectedDocumentsCount={selectedDocumentsCount}
        createPdfZipFileLoading={createPdfZipFileLoading}
        onPrepareDownload={onPrepareDownloadPdfs}
      />
      <DownloadedCSVModal {...CSVmodalProps} onClose={closeCSVModal} />
    </>
  );
};
