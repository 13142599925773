import { SvgIcon, SvgProps } from '../SvgIcon';

export const EmailImportErrorIcon = ({ ...props }: SvgProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.25 20.25C17.0429 20.25 16.875 20.4179 16.875 20.625C16.875 20.8321 17.0429 21 17.25 21C17.4571 21 17.625 20.8321 17.625 20.625C17.625 20.4179 17.4571 20.25 17.25 20.25"
        stroke="#F44034"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 18V15"
        stroke="#F44034"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.063 20.682C23.3383 21.232 23.309 21.8854 22.9857 22.4086C22.6623 22.9318 22.0911 23.2502 21.476 23.25H13.024C12.4089 23.2502 11.8377 22.9318 11.5143 22.4086C11.191 21.8854 11.1617 21.232 11.437 20.682L15.663 12.231C15.9634 11.6297 16.5778 11.2498 17.25 11.2498C17.9222 11.2498 18.5366 11.6297 18.837 12.231L23.063 20.682Z"
        stroke="#F44034"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 15.75H2.25C1.42157 15.75 0.75 15.0784 0.75 14.25V2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H20.25C21.0784 0.75 21.75 1.42157 21.75 2.25V10.5"
        stroke="#9E9E9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.411 1.29999L13.267 7.56399C12.0779 8.47873 10.4221 8.47873 9.23299 7.56399L1.08899 1.29999"
        stroke="#9E9E9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
