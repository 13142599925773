import { ParseError } from 'papaparse';
import Papa, { ParseResult } from 'papaparse';

export class CSVFileParseError extends Error {
  errors: ParseError[];

  constructor(errors: ParseError[]) {
    super('Failed to parse csv file');
    this.errors = errors;
  }
}

type ParsedCSV = string[][];

interface ParseCsvArgs {
  file: File;
  encoding?: string;
}

interface RemoveRowsArgs {
  file: File;
  indexStart: number;
  deleteCount: number;
}

/**
 * CsvParser is a class that provides an API for parsing and manipulating csv files.
 * It provides methods for parsing csv files,removing rows from csv file,
 * and checking the value at specific cell in the csv file.
 */
export class CsvParser {
  /**
   * Parse csv file using Papa parse library
   *
   * @param {Object} options - The options object
   * @param {File} options.file - The file to parse
   * @param {string} [options.encoding='windows-1252'] - The encoding of the file
   *
   * @returns {Promise<ParseResult<string[]>>} Returns a promise that resolves with the parsed csv file, containing the data and meta information.
   */
  public async parseCsv({ file, encoding = 'windows-1252' }: ParseCsvArgs) {
    return new Promise<ParseResult<string[]>>((resolve, reject) =>
      Papa.parse<string[]>(file, {
        skipEmptyLines: 'greedy',
        complete: result => {
          if (result.errors.length) {
            return reject(new CSVFileParseError(result.errors));
          }

          return resolve(result);
        },
        encoding,
        error: reject,
      })
    );
  }

  /**
   * Remove certain number of rows from given file
   *
   * @param {Object} params - The parameter object
   * @param {File} params.file - The file to remove rows from
   * @param {number} params.indexStart - The start index for rows to remove
   * @param {number} params.deleteCount - The number of rows to delete
   *
   * @returns {Promise<ParsedCSV>} Returns a promise that resolves with new parsed csv after removing the rows
   */
  public async removeRows({
    file,
    indexStart,
    deleteCount,
  }: RemoveRowsArgs): Promise<ParsedCSV> {
    const { data } = await this.parseCsv({ file });

    return data
      .slice(0, indexStart)
      .concat(data.slice(indexStart + deleteCount));
  }

  /**
   * Check if value under given cell is equal to value param
   *
   * @param {Object} params - The parameter object
   * @param {File} params.file - The file to parse
   * @param {number} params.rowIndex - The row index of the value to check
   * @param {number} params.columnIndex - The column index of the value to check
   * @param {string} params.value - The value to compare
   *
   * @returns {Promise<boolean>} Returns a promise that resolves with a boolean indicating if the value is equal to the given value
   */
  public isCellValueEqual = async ({
    file,
    rowIndex,
    columnIndex,
    value,
  }: {
    file: File;
    rowIndex: number;
    columnIndex: number;
    value: string;
  }) => {
    // check if value under given row index and column index is equal to value param
    const parsedCsv = await this.parseCsv({
      file: file,
      encoding: 'windows-1252',
    });

    if (parsedCsv.data[rowIndex][columnIndex] === value) {
      return true;
    }

    return false;
  };
}
