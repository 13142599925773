import { Grid, Link } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Routes } from 'models';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

interface ContactProps {
  contactId?: string;
}

export const Contact = ({ contactId }: ContactProps) => {
  const [t] = useTranslation();
  const organization = useFullOrganization();
  const { slug } = organization || {};

  return (
    <Grid gap="space8">
      <HookFormTextField
        name="contact"
        label={t('document.requestApproval.inputs.contact.label')}
        readOnly
      />
      {contactId && (
        <Link href={`/${slug}${Routes.ARCHIVE}?contact=${contactId}`} external>
          {t('document.requestApproval.inputs.contact.documentListLink')}
        </Link>
      )}
    </Grid>
  );
};
