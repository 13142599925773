import { ContactSortField, SortDirection } from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';
import { ContactsTableData } from '../ContactTableDS';

const mapColumnIdsToSortInput: Record<string, ContactSortField> = {
  accountsPayableNumber: ContactSortField.AccountsPayableNumber,
  accountsReceivableNumber: ContactSortField.AccountsReceivableNumber,
  contact: ContactSortField.Name,
  createdAt: ContactSortField.CreatedAt,
};

export const mapToContactsSortInput = (
  sort: Array<SortingRule<ContactsTableData>>
) => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  const firstSortId = firstSort.id;

  return {
    field: mapColumnIdsToSortInput[firstSortId],
    direction: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};
