import { Checkbox, Text } from '@candisio/design-system';
import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWaitingForClarificationCheckbox } from './useWaitingForClarificationCheckbox';

type WaitingForClarificationCheckboxProps = {
  isSelected?: boolean;
  handleChange?: (isSelected: boolean) => void;
};

export const WaitingForClarificationCheckbox = ({
  isSelected = false,
  handleChange = _.noop,
}: WaitingForClarificationCheckboxProps) => {
  const [t] = useTranslation();

  return (
    <Checkbox
      isSelected={isSelected}
      name="isWaitingForClarification"
      onChange={handleChange}>
      <Text fontSize="xsmall" color="gray600">
        {t('document.isWaitingForClarificationCheckboxLabel')}
      </Text>
    </Checkbox>
  );
};

type WaitingForClarificationProps = {
  documentId?: string;
  isWaitingForClarification?: boolean;
};

export const WaitingForClarification = ({
  documentId = '',
  isWaitingForClarification = false,
}: WaitingForClarificationProps) => {
  const { selected, setSelected, handleChange } =
    useWaitingForClarificationCheckbox(documentId, isWaitingForClarification);

  useEffect(() => {
    setSelected(isWaitingForClarification);
  }, [setSelected, isWaitingForClarification]);

  return (
    <WaitingForClarificationCheckbox
      isSelected={selected}
      handleChange={handleChange}
    />
  );
};
