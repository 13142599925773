import { Button, Grid, Icon, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useRef, MouseEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDragAndDrop } from 'views/TransactionAssociation/component/InvoiceUploadContainer/useDragAndDrop';
import { getColorCode } from 'views/TransactionAssociation/component/InvoiceUploadContainer/util';
import { useDraggedFileStatus } from 'views/TransactionAssociation/hooks/useDraggedFileStatus';

const ACCEPTED_FILE_TYPE = 'application/pdf';

interface ExpenseInvoiceInputFieldProps {
  id: string;
  name: string;
  onChange: (file: File) => void;
  onIvoiceUpdate: () => void;
}

export const ExpenseInvoiceInputField = ({
  id,
  name,
  onChange,
  onIvoiceUpdate,
}: ExpenseInvoiceInputFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const onFileSelected = useCallback(
    (files: File[]) => {
      files.forEach(onChange);
    },
    [onChange]
  );

  const {
    draggedFileCount,
    isAcceptedFileType,
    isFileDraggable,
    onFileDrop,
    onDragLeave,
    onDragOver,
    onDropZoneClick,
    onFilesChanged,
  } = useDragAndDrop({ fileUploadRef, onFileSelected });

  const { statusText, canUserDropFile } = useDraggedFileStatus(
    draggedFileCount,
    isAcceptedFileType
  );

  const textColor = canUserDropFile ? 'gray500' : 'red500';
  const backgroundColor = isFileDraggable
    ? getColorCode('background', canUserDropFile)
    : 'gray0';

  const iconColor = isFileDraggable
    ? getColorCode('color', canUserDropFile)
    : 'gray500';

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onFilesChanged(evt);
    onIvoiceUpdate();
  };

  return (
    <Grid
      gap="space24"
      cursor="pointer"
      paddingY="space24"
      borderRadius="medium"
      placeItems="center"
      placeContent="center"
      draggable={isFileDraggable}
      onDrop={onFileDrop}
      onDragOver={onDragOver}
      onClick={onDropZoneClick}
      onDragLeave={onDragLeave}
      background={backgroundColor}>
      <Grid placeItems="center">
        <Icon
          size="60px"
          icon={canUserDropFile ? 'upload' : 'warning'}
          color={iconColor}
        />
        <Text
          as="label"
          htmlFor={id}
          lineHeight="paragraph"
          fontSize="basic"
          color={textColor}
          onClick={(e: MouseEvent<HTMLLabelElement>) => e.preventDefault()}>
          {statusText()}
        </Text>
      </Grid>
      <Button size="small">
        {t('reimbursementView.middleSection.form.uploadCta')}
      </Button>
      <input
        id={id}
        name={name}
        type="file"
        accept={ACCEPTED_FILE_TYPE}
        ref={fileUploadRef}
        onChange={handleChange}
        hidden
      />
    </Grid>
  );
};
