import { Maybe, OrganizationQuery } from 'generated-types/graphql.types';
import { useContext } from 'react';
import { FullOrganizationContext } from './FullOrganizationContext';
import { OrganizationContext } from './OrganizationContext';

export const useOrganizationId: () => string | null = () => {
  const { selectedOrganization } = useContext(OrganizationContext);

  return selectedOrganization;
};

export const useFullOrganization: () =>
  | Maybe<OrganizationQuery['organization']>
  | undefined = () => {
  const { currentOrganization } = useContext(FullOrganizationContext);

  return currentOrganization;
};
