// eslint-disable-next-line no-restricted-imports
import { useExpenseSectionComponent } from 'views/Reimbursement/hooks/useExpenseSectionComponent';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useReimbursementMenuButtonActions } from 'views/Reimbursement/hooks/useReimbursementMenuButtonActions';
import { LoadingExpenseSection } from './LoadingExpenseSection';
import { useExpenseFormsHelpers } from './utils/useExpenseFormsHelpers';

export interface ExpenseSectionContainerProps {
  loading: boolean;
  expenseCount: number;
  reimbursement?: Reimbursement;
  formHelpers?: ReturnType<typeof useExpenseFormsHelpers>;
}

export const ExpenseSectionContainer = ({
  loading,
  expenseCount,
  reimbursement,
  formHelpers,
}: ExpenseSectionContainerProps) => {
  const ExpenseSection = useExpenseSectionComponent(reimbursement);
  const {
    handleCreateGeneralExpense,
    handleCreateHospitalityExpense,
    isCreatingExpenseItem,
  } = useReimbursementMenuButtonActions({ formHelpers });

  if (loading) {
    return <LoadingExpenseSection />;
  }

  const { totalAmount } = reimbursement ?? {};

  return (
    <ExpenseSection
      totalAmount={totalAmount}
      expenseCount={expenseCount}
      creatingExpense={isCreatingExpenseItem}
      onGeneralExpenseClick={handleCreateGeneralExpense}
      onHospitalityExpenseClick={handleCreateHospitalityExpense}
    />
  );
};
