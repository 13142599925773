import { FilterOptionsAccessor } from '@candisio/design-system';
import { TransactionsTableData } from 'components/Transactions/Table/types';
import {
  InvoiceAssociationStatus,
  TransactionCategory,
  TransactionStatus,
  TransactionType,
  useGetCardIssuerCardsRefNumbersAndLabelsQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCardHolders } from 'providers/EntityLoader/EntityLoader';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cardsRefNumbersQueryVariables } from 'views/CreditCards/hooks/useCardIssuerCardsFilters';
import { useFilteredInvoiceStatusOptions } from './useFilteredInvoiceStatusOptions';

export enum TRANSACTION_COLUMNS {
  status = 'status',
  cardholderName = 'cardholderName',
  invoiceAssociationStatus = 'invoiceAssociationStatus',
  type = 'type',
  cardRefNum = 'cardRefNum',
  category = 'category',
}

export enum TRANSACTION_FILTER {
  status_inbox = 'status_inbox',
  status_archive = 'status_archive',
  cardholderName = 'cardholderName',
  invoiceAssociationStatus_inbox = 'invoiceAssociationStatus_inbox',
  invoiceAssociationStatus_archive = 'invoiceAssociationStatus_archive',
  category = 'category',
  type = 'type',
  cardRefNum = 'cardRefNum',
}

interface UseTransactionListFiltersParams {
  filters: TRANSACTION_FILTER[];
  fetchCardholdersWithMissingInvoices?: boolean;
  fetchCardholdersWithTransactions?: boolean;
}

export const useTransactionListFilters = ({
  filters,
  fetchCardholdersWithMissingInvoices,
  fetchCardholdersWithTransactions,
}: UseTransactionListFiltersParams) => {
  const [t] = useTranslation();
  const { cardHolders, loading: listHoldersLoading } = useCardHolders({
    fetchCardholdersWithMissingInvoices,
    fetchCardholdersWithTransactions,
  });

  const creditCardsAutomatedMatchingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsAutomatedMatching
  );

  const { data, loading: cardRefNumbersLoading } =
    useGetCardIssuerCardsRefNumbersAndLabelsQuery({
      ...cardsRefNumbersQueryVariables,
      fetchPolicy: 'no-cache',
    });

  const cardIssuerCardsRefNumbers =
    data?.getCardIssuerCardsRefNumbersAndLabels.edges;

  const { filteredInvoiceStatusOptions } = useFilteredInvoiceStatusOptions({
    showOption: creditCardsAutomatedMatchingFF,
  });

  const filterMap = useMemo(
    () => ({
      [TRANSACTION_FILTER.status_inbox]: {
        [TRANSACTION_COLUMNS.status]: {
          data: [
            {
              id: TransactionStatus.Confirmed,
              label: t('transactions:transactionDetailsCard.status.confirmed'),
            },
            {
              id: TransactionStatus.Pending,
              label: t('transactions:transactionDetailsCard.status.pending'),
            },
          ],
        },
      },
      [TRANSACTION_FILTER.status_archive]: {
        [TRANSACTION_COLUMNS.status]: {
          data: [
            {
              id: TransactionStatus.Confirmed,
              label: t('transactions:transactionDetailsCard.status.confirmed'),
            },
            {
              id: TransactionStatus.Declined,
              label: t('transactions:transactionDetailsCard.status.declined'),
            },
            {
              id: TransactionStatus.Pending,
              label: t('transactions:transactionDetailsCard.status.pending'),
            },
            {
              id: TransactionStatus.Reversed,
              label: t('transactions:transactionDetailsCard.status.reversed'),
            },
          ],
        },
      },
      [TRANSACTION_FILTER.cardholderName]: {
        [TRANSACTION_COLUMNS.cardholderName]: {
          data: (cardHolders || []).map(cardholder => ({
            id: cardholder.node.id,
            label: `${cardholder.node.firstName} ${cardholder.node.lastName}`,
          })),
        },
      },
      [TRANSACTION_FILTER.cardRefNum]: {
        [TRANSACTION_COLUMNS.cardRefNum]: {
          data: (cardIssuerCardsRefNumbers || [])
            .map(card => ({
              id: card.node.id,
              label: card.node.refNum || '',
            }))
            .filter(f => !!f.label),
        },
      },
      [TRANSACTION_FILTER.invoiceAssociationStatus_inbox]: {
        [TRANSACTION_COLUMNS.invoiceAssociationStatus]: {
          data: [
            {
              id: InvoiceAssociationStatus.Missing,
              label: t('transactions:documentStatus.missing'),
            },
            {
              id: InvoiceAssociationStatus.Uploaded,
              label: t('transactions:documentStatus.uploaded'),
            },
          ],
        },
      },
      [TRANSACTION_FILTER.invoiceAssociationStatus_archive]: {
        [TRANSACTION_COLUMNS.invoiceAssociationStatus]: {
          data: filteredInvoiceStatusOptions,
        },
      },
      [TRANSACTION_FILTER.type]: {
        [TRANSACTION_COLUMNS.type]: {
          data: [
            {
              id: TransactionType.Purchase,
              label: t('transactions:transactionDetailsCard.type.purchase'),
            },
            {
              id: TransactionType.StatusInquiry,
              label: t(
                'transactions:transactionDetailsCard.type.status_inquiry'
              ),
            },
            {
              id: TransactionType.Refund,
              label: t('transactions:transactionDetailsCard.type.refund'),
            },
            {
              id: TransactionType.Recharge,
              label: t('transactions:transactionDetailsCard.type.recharge'),
            },
            {
              id: TransactionType.CashWithdrawal,
              label: t(
                'transactions:transactionDetailsCard.type.cash_withdrawal'
              ),
            },
            {
              id: TransactionType.Chargeback,
              label: t('transactions:transactionDetailsCard.type.chargeback'),
            },
          ],
        },
      },
      [TRANSACTION_FILTER.category]: {
        [TRANSACTION_COLUMNS.category]: {
          data: [
            {
              id: TransactionCategory.AdvertisingAndMarketing,
              label: t(
                'credit-cards:insights.filters.category.list.AdvertisingAndMarketing'
              ),
            },
            {
              id: TransactionCategory.ComputingAndSoftware,
              label: t(
                'credit-cards:insights.filters.category.list.ComputingAndSoftware'
              ),
            },
            {
              id: TransactionCategory.EducationAndTraining,
              label: t(
                'credit-cards:insights.filters.category.list.EducationAndTraining'
              ),
            },
            {
              id: TransactionCategory.ElectronicsAndItEquipment,
              label: t(
                'credit-cards:insights.filters.category.list.ElectronicsAndItEquipment'
              ),
            },
            {
              id: TransactionCategory.EntertainmentAndWellness,
              label: t(
                'credit-cards:insights.filters.category.list.EntertainmentAndWellness'
              ),
            },
            {
              id: TransactionCategory.FoodAndDrinks,
              label: t(
                'credit-cards:insights.filters.category.list.FoodAndDrinks'
              ),
            },
            {
              id: TransactionCategory.GiftsAndVouchers,
              label: t(
                'credit-cards:insights.filters.category.list.GiftsAndVouchers'
              ),
            },
            {
              id: TransactionCategory.MaterialsAndPackaging,
              label: t(
                'credit-cards:insights.filters.category.list.MaterialsAndPackaging'
              ),
            },
            {
              id: TransactionCategory.OfficeSuppliesAndEquipment,
              label: t(
                'credit-cards:insights.filters.category.list.OfficeSuppliesAndEquipment'
              ),
            },
            {
              id: TransactionCategory.Other,
              label: t('credit-cards:insights.filters.category.list.Other'),
            },
            {
              id: TransactionCategory.Services,
              label: t('credit-cards:insights.filters.category.list.Services'),
            },
            {
              id: TransactionCategory.TravelAndAccommodation,
              label: t(
                'credit-cards:insights.filters.category.list.TravelAndAccommodation'
              ),
            },
          ],
        },
      },
    }),
    [cardHolders, cardIssuerCardsRefNumbers, filteredInvoiceStatusOptions, t]
  );

  const filterOptions: FilterOptionsAccessor<TransactionsTableData> =
    useMemo(() => {
      return filters.reduce(
        (allFilters, currentFilter) => ({
          ...allFilters,
          ...filterMap[currentFilter],
        }),
        {}
      );
    }, [filterMap, filters]);

  return {
    filterOptions,
    isFilterLoading: listHoldersLoading || cardRefNumbersLoading,
  };
};
