import { CustomEmptyStateProps } from '@candisio/design-system';
import { AccountsPayable } from './components/AccountsPayable';
import { FilteredAndEmpty } from './components/FilteredAndEmpty';
import { NoContactsYet } from './components/NoContactsYet';

interface ContactsEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  onCreate: () => void;
  showContactsWithoutAccountsPayableNumber: boolean;
}

export const ContactsEmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
  showContactsWithoutAccountsPayableNumber,
  onCreate,
}: ContactsEmptyStateProps) => {
  const isFilteredAndEmpty = isTableEmpty && isTableFiltered;

  if (isFilteredAndEmpty) {
    return <FilteredAndEmpty resetFilters={resetFilters} />;
  }

  if (showContactsWithoutAccountsPayableNumber) {
    return <AccountsPayable />;
  }

  return <NoContactsYet onCreate={onCreate} />;
};
