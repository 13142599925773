import * as Sentry from '@sentry/react';
// eslint-disable-next-line no-restricted-imports
import { Route as ReactRouterRoute } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { CompatRoute as ReactRouterCompatRoute } from 'react-router-dom-v5-compat';

/**
 * To get parameterized transaction names in the Sentry reporting, use this
 * component for any Routes created.
 *
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#parameterized-transaction-names
 */
export const Route = Sentry.withSentryRouting(ReactRouterRoute);

/**
 * To get parameterized transaction names in the Sentry reporting, use this
 * component for any CompatRoutes created.
 *
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#parameterized-transaction-names
 */
export const CompatRoute = Sentry.withSentryRouting(ReactRouterCompatRoute);
