import { queryParameter } from 'components/Table/consts';
import { GetCardIssuerCardsForCardholderQuery } from 'generated-types/graphql.types';
import { CardStatus } from 'generated-types/resolvers-types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { debounce } from 'lodash';
import { getCardIssuerCardsForCardholderQuery } from 'providers/EntityLoader/queries';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useMemo, useState } from 'react';
import { Filters, SortingRule } from 'react-table';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { ToolbarFilter } from '../CardManagerView/components/CreditCardsTable/CreditCardsTableToolbar';
import { CreditCardsTableData } from '../types';
import {
  mapToCreditCardsTableData,
  mapToFilterInput,
  mapToSortInput,
} from '../utils/utils';

const defaultSort: SortingRule<CreditCardsTableData>[] = [
  {
    id: 'pendingRequest',
    desc: true,
  },
];

export const activeStatuses = [
  CardStatus.Active,
  CardStatus.LockedPin,
  CardStatus.Locked,
  CardStatus.Pending,
  CardStatus.Processing,
  CardStatus.Requested,
];

export const inactiveStatuses = [CardStatus.Expired, CardStatus.Terminated];

interface UseCardIssuerCardsDataProps {
  sortBy: SortingRule<CreditCardsTableData>[];
  filters: Filters<CreditCardsTableData>;
  toolbarFilter: ToolbarFilter;
  skip?: boolean;
}

export const useCardIssuerCardsForCarholderData = ({
  filters,
  sortBy,
  toolbarFilter,
  skip,
}: UseCardIssuerCardsDataProps) => {
  const { searchParams } = useMutateSearchParams();
  const searchQueryParam = searchParams.get(queryParameter) ?? '';
  const [search, setSearch] = useState(searchQueryParam);

  const queryOptions = {
    variables: {
      input: {
        limit: getApproximateNumberOfRowForTable(),
      },
      queries: {
        labelAndRefNumQuery: search,
      },
      filters: mapToFilterInput(filters, toolbarFilter),
      sortBy: !sortBy.length
        ? mapToSortInput(defaultSort)
        : mapToSortInput(sortBy),
    },
    skip,
  };

  const computeVariables = (endCursor: string) => ({
    input: {
      limit: getApproximateNumberOfRowForTable(),
      after: endCursor,
    },
    queries: {
      labelAndRefNumQuery: search,
    },
    filters: mapToFilterInput(filters, toolbarFilter),
    sortBy: !sortBy.length
      ? mapToSortInput(defaultSort)
      : mapToSortInput(sortBy),
  });

  const { data, loading, onLoadMore } =
    usePagination<GetCardIssuerCardsForCardholderQuery>(
      getCardIssuerCardsForCardholderQuery,
      'getCardIssuerCardsForCardholder',
      queryOptions,
      {
        computeVariables,
      }
    );

  const creditCardEdges = data?.getCardIssuerCardsForCardholder.edges ?? [];
  const creditCardsInfoCount =
    data?.getCardIssuerCardsForCardholder.pageInfo?.totalCount;

  const hasMoreData =
    data?.getCardIssuerCardsForCardholder.pageInfo?.hasNextPage ?? false;

  const creditCardsTableData = mapToCreditCardsTableData(creditCardEdges);

  const isTableEmpty = creditCardEdges.length === 0 && !loading;

  const isTableFiltered = filters.length > 0;

  const handleDebounceSearch = useMemo(() => {
    return debounce(setSearch, DEFAULT_DEBOUNCE_TIME, { leading: true });
  }, []);

  return {
    creditCardsTableData,
    creditCardsInfoCount,
    hasMoreData,
    loading,
    onLoadMore,
    endCursor: data?.getCardIssuerCardsForCardholder.pageInfo?.endCursor,
    isTableEmpty,
    isTableFiltered,
    handleDebounceSearch,
  };
};
