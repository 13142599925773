import { isMobileClient } from 'components/DocumentHistory/history-components/utils';
import { useMobilePromotionDataQuery } from 'generated-types/graphql.types';

interface UseMobileAppPromotionBanner {
  documentId?: string;
}

export const useMobileAppPromoBanner = ({
  documentId,
}: UseMobileAppPromotionBanner) => {
  const { data } = useMobilePromotionDataQuery({
    variables: { id: documentId ?? '' },
    skip: !documentId,
  });

  const isTransactionLinked = data?.getDocument?.hasTransactionLinked;

  const isWebUpload = data?.getDocument?.timeline?.some(
    event =>
      event.__typename === 'FileUploadedEvent' &&
      // we see email uploader as web upload
      (!isMobileClient(event?.clientInfo?.name) || event?.uploaderEmail)
  );

  const isScannedDocument =
    // TODO: value type should be enum: NATIVE | SCANNED | SANDWICH
    data?.getDocument?.sourceClassification?.value === 'SCANNED';

  const showMobileAppPromoBanner =
    isTransactionLinked && isWebUpload && isScannedDocument;

  return { showMobileAppPromoBanner };
};
