import { FileUploadPostOption, Maybe } from 'generated-types/graphql.types';

interface UploadFile {
  url: string;
  file: File;
  options: Maybe<Array<Maybe<FileUploadPostOption>>>;
}

/**
 * Uploads a file to Amazon S3 with optional additional fields.
 *
 * @param {UploadFile} param - The upload parameters.
 * @returns {Promise<Response>} - The fetch response.
 */
export const uploadFileToS3 = async ({
  url,
  file,
  options,
}: UploadFile): Promise<Response> => {
  const newFormData = new FormData();

  if (options) {
    options.forEach(option => {
      if (option) {
        newFormData.append(option.field, option.value);
      }
    });
  }

  newFormData.append('file', file);

  return fetch(url, {
    method: 'POST',
    body: newFormData,
  });
};
