import { Maybe, PurchaseOrderStatus } from 'generated-types/graphql.types';
import { Filters } from 'react-table';
import { PurchaseOrdersTableData } from '../Table/types';

interface FiltersWithContactProps {
  accountsPayableNumber?: Maybe<string>;
  filters?: Filters<PurchaseOrdersTableData>;
}

export const getFiltersWithContact = ({
  filters,
  accountsPayableNumber,
}: FiltersWithContactProps) => {
  let filtersWithContact = [
    ...(filters ?? []),
    {
      id: 'status',
      value: [
        PurchaseOrderStatus.Closed,
        PurchaseOrderStatus.Open,
        PurchaseOrderStatus.Delivered,
        PurchaseOrderStatus.Paid,
      ],
    },
  ];

  if (accountsPayableNumber) {
    filtersWithContact.push({
      id: 'accountsPayableNumber',
      value: accountsPayableNumber,
    });
  }

  return filtersWithContact;
};
