import { useMemo } from 'react';
import { useReadableTransactionFilters } from './useReadableTransactionFilters';
import { TransactionDownloadItem } from './useTransactionDownloadHistoryData';

interface UseReadableFilterNamesParams {
  filters: TransactionDownloadItem['filters'];
}

export const useReadableFilterNames = ({
  filters,
}: UseReadableFilterNamesParams) => {
  const {
    readableTransactionCategory,
    readableTransactionStatus,
    readableTransactionType,
    readableInvoiceAssociationStatus,
  } = useReadableTransactionFilters();

  const allReadableFilterNames = useMemo(() => {
    const filterGroup: string[] = [];

    if (filters.status?.length) {
      filters.status.forEach(stat =>
        filterGroup.push(readableTransactionStatus(stat))
      );
    }

    if (filters.type?.length) {
      filters.type.forEach(type =>
        filterGroup.push(readableTransactionType(type))
      );
    }

    if (filters.category?.length) {
      filters.category.forEach(cat =>
        filterGroup.push(readableTransactionCategory(cat))
      );
    }

    if (filters.invoiceAssociationStatus?.length) {
      filters.invoiceAssociationStatus.forEach(inv =>
        filterGroup.push(readableInvoiceAssociationStatus(inv))
      );
    }

    if (filters.cards?.length) {
      filters.cards.forEach(card => {
        if (card.refNum) {
          filterGroup.push(card.refNum);
        }

        if (card.cardholder?.firstName && card.cardholder?.lastName) {
          filterGroup.push(
            `${card.cardholder.firstName} ${card.cardholder.lastName}`
          );
        }
      });
    }

    if (filters.merchantNames?.length) {
      filterGroup.push(...filters.merchantNames);
    }

    if (filters.dateFrom && filters?.dateTo) {
      filterGroup.push(`${filters.dateFrom} - ${filters.dateTo}`);
    }

    return filterGroup;
  }, [
    filters,
    readableInvoiceAssociationStatus,
    readableTransactionCategory,
    readableTransactionStatus,
    readableTransactionType,
  ]);

  return allReadableFilterNames;
};
