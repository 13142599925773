import { TruncatedText, useTheme } from '@candisio/design-system';
import { castArray, isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

type Value = (undefined | string)[];
export const SplitsCell = ({
  value,
}: CellProps<DocumentsTableData, Value>): JSX.Element => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const { colors } = useTheme();

  if (isNil(value) || !value[0]) {
    return t('emptyCellPlaceholder');
  }

  const values = castArray(value);

  if (values.every(v => !v)) {
    return t('emptyCellPlaceholder');
  }

  const allValuesAreTheSame = values.every(v => v === values?.[0]);

  if (values.length > 1 && !allValuesAreTheSame) {
    return (
      <TruncatedText
        color={colors.gray400}
        wordBreak="break-all"
        fontStyle="italic">
        {t('seeSplitBookings')}
      </TruncatedText>
    );
  }

  return <TruncatedText>{value[0]}</TruncatedText>;
};
