import { DocumentApprovalsFormContainer } from 'components/Form/DocumentApprovalsForm/DocumentApprovalsFormContainer';
import { GetDocumentForDraftQuery } from 'generated-types/graphql.types';

type Props = {
  document?: GetDocumentForDraftQuery['getDocument'];

  loading?: boolean;
  organizationSlug: string;
  cycleDocument: () => void;
  redirectTo: (url: string) => void;
  toggleEditMode: () => void;
};

export const Open = ({
  toggleEditMode,
  organizationSlug,
  loading,
  cycleDocument,
  document,
}: Props) => {
  if (loading) {
    // TODO loading state
    return null;
  }

  if (!document) {
    // TODO Add error state
    return null;
  }

  return (
    <DocumentApprovalsFormContainer
      document={document}
      cycleDocument={cycleDocument}
      toggleEditMode={toggleEditMode}
    />
  );
};
