import { Grid, Icon, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type VendorType = 'amazon' | 'specialVendors' | 'otherVendors';

interface VendorText {
  vendorName: string;
  estimatedAmount: string;
}

interface VendorToText {
  icon?: ReactNode;
  tooltip: ReactNode;
  invoiceAmount?: ReactNode;
}

export const useVendorText = ({ vendorName, estimatedAmount }: VendorText) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const mapVendorToText: Record<VendorType, VendorToText> = {
    amazon: {
      invoiceAmount: <Text fontSize="basic">{`~${estimatedAmount}`}*</Text>,
      tooltip: (
        <Grid gap="space12">
          <Text>{t('vendorListSuggestion.fxHelpText')}</Text>
          <Text>
            {t('vendorListSuggestion.optionalHelpText', {
              vendorName,
            })}
          </Text>
        </Grid>
      ),
    },
    specialVendors: {
      icon: <Icon icon="infoCircle" size="space16" />,
      tooltip: (
        <Text>
          {t('vendorListSuggestion.googleHelpText', {
            vendorName,
          })}
        </Text>
      ),
    },
    otherVendors: {
      invoiceAmount: <Text fontSize="basic">~{estimatedAmount}</Text>,
      tooltip: <Text>{t('vendorListSuggestion.fxHelpText')}</Text>,
    },
  };

  return { mapVendorToText };
};
