import { useMutation } from '@apollo/client';
import { createDocumentCommentMutation } from 'components/Comment/gql';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { DocumentCommentEvent, Query } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { documentHistoryQuery } from 'views/queries';

interface CommentContainerProps {
  documentId: string;
}
export const useCreateDocumentComment = ({
  documentId,
}: CommentContainerProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const currentUser = useCurrentUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createDocumentComment] = useMutation(createDocumentCommentMutation, {
    refetchQueries: [
      {
        query: documentHistoryQuery,
        variables: { id: documentId },
      },
    ],
  });

  const onCreateComment = useCallback(
    /** @ts-expect-error TODO: React upgrade props types mismatch */
    async ({ mention, mentionedUserIds }) => {
      try {
        if (!mention) {
          return null;
        }

        setIsSubmitting(true);

        const eventId = uuidV4();
        const commentId = uuidV4();
        const createdComment = {
          __typename: 'DocumentCommentEvent',
          createdAt: new Date().toISOString(),
          id: eventId,
          user: currentUser,
          comment: {
            id: commentId,
            text: mention,
            author: currentUser,
            __typename: 'Comment',
          },
        };

        await createDocumentComment({
          variables: {
            id: documentId,
            mentionedUserIds,
            text: mention,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createDocumentComment: createdComment,
          },
          update: proxy => {
            try {
              const docCacheData = proxy.readQuery<Pick<Query, 'getDocument'>>({
                query: documentHistoryQuery,
                variables: {
                  id: documentId,
                },
              });

              if (docCacheData?.getDocument) {
                docCacheData.getDocument.timeline = [
                  createdComment as DocumentCommentEvent,
                  ...(docCacheData.getDocument.timeline ?? []),
                ];

                proxy.writeQuery({
                  query: documentHistoryQuery,
                  variables: {
                    id: documentId,
                  },
                  data: docCacheData,
                });
              }
            } catch (e) {
              //
            }
          },
        });

        success(t('comment.created'));
        setIsSubmitting(false);
      } catch (e) {
        console.log('e', e);
        error(t('comment.creationFailed'));
        setIsSubmitting(false);
      }
    },
    [currentUser, createDocumentComment, documentId, success, error, t]
  );

  return { isSubmitting, onCreateComment };
};
