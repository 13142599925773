import {
  Booking,
  Document,
  GetDocumentForDraftQuery,
  GetDocumentQuery,
} from 'generated-types/graphql.types';
import { DeepPartial } from 'utility-types';

export type DocumentWithMultipleBookings = Omit<
  GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument'],
  'bookings'
> & {
  bookings: [Booking, Booking, ...Booking[]];
};

export const isDocWithMultipleBookings = (
  document?: DeepPartial<Document>
): document is DocumentWithMultipleBookings => {
  if ((document?.bookings?.length ?? 0) > 1) {
    return true;
  }

  return false;
};
