import { Flex, Spinner } from '@candisio/design-system';

export const Loader = () => {
  return (
    <Flex
      height="space256"
      alignItems="center"
      justifyContent="center"
      background="gray100"
      borderRadius="medium">
      <Spinner color="gray400" size="space64" />
    </Flex>
  );
};
