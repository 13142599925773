import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { isTestUserEmailAddress } from 'providers/utils';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { Analytics, Destination } from './types';
import { useCandisAppCookie } from './useCandisAppCookie';
import { useSegmentDestinations } from './useSegmentDestinations';
import { TagManager, TagManagerArgs } from './utils/TagManager';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export type TrackFunction = (event: string, properties?: object) => void;

export const AnalyticsContext = createContext<Analytics>(window.analytics);

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const currentUser = useCurrentUser();
  const organization = useFullOrganization();
  const destinations = useSegmentDestinations();

  // Initial analytics object is the stub one from public/segment.js
  const [analytics, setAnalytics] = useState(window.analytics);
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);

  const isTestUser =
    currentUser?.email &&
    isTestUserEmailAddress(currentUser?.email) &&
    import.meta.env.REACT_APP_STAGE === 'prod' &&
    !localStorage.getItem('look_ma_tracking');

  const membershipId = currentUser?.id;
  const analyticsId = currentUser?.analyticsId as string;
  const intercomIsEnabled = currentUser?.trackingConfiguration?.intercom;
  const trackingEnabled = currentUser?.trackingConfiguration
    ?.trackingEnabled as boolean;

  useCandisAppCookie(analyticsId, trackingEnabled);

  useEffect(() => {
    if (
      // Check is User is loaded
      membershipId &&
      // Check if destinations are loaded
      destinations &&
      !destinations.loading &&
      // Check analytics should be loaded
      analytics?.load &&
      import.meta.env.REACT_APP_SEGMENT_KEY &&
      !analyticsLoaded
    ) {
      const {
        marketingDestinations = [],
        advertisingDestinations = [],
        functionalDestinations = [{ id: 'Intercom' } as Destination],
      } = destinations.data || {};

      // load all analytics integrations
      // This loads the real analytics.js script into the DOM
      analytics.load(import.meta.env.REACT_APP_SEGMENT_KEY, {
        integrations: {
          'Segment.io': trackingEnabled,
          ...marketingDestinations.reduce(
            (acc, current) => ({
              ...acc,
              [current.id]: trackingEnabled,
            }),
            {}
          ),
          ...advertisingDestinations.reduce(
            (acc, current) => ({
              ...acc,
              [current.id]: trackingEnabled,
            }),
            {}
          ),
          ...functionalDestinations.reduce(
            (acc, current) => ({
              ...acc,
              [current.id]: intercomIsEnabled,
            }),
            {}
          ),
          ...(isTestUser
            ? {
                'Segment.io': false,
              }
            : {}),
        },
      });

      setAnalyticsLoaded(true);

      // Once the real analytics.js has finished loading, update the window.analytics
      // object reference in the consumer.
      analytics.ready(() => {
        setAnalytics(window.analytics);
      });

      analytics.identify({}, { integrations: { All: false } });
    }
  }, [
    analytics,
    analyticsLoaded,
    destinations,
    intercomIsEnabled,
    isTestUser,
    membershipId,
    trackingEnabled,
  ]);

  useEffect(() => {
    if (
      // Check is User is loaded
      membershipId &&
      // Check if destinations are loaded
      import.meta.env.REACT_APP_GTM_KEY
    ) {
      if (trackingEnabled) {
        const tagManagerArgs: TagManagerArgs = {
          gtmId: import.meta.env.REACT_APP_GTM_KEY,
          url:
            import.meta.env.REACT_APP_GTM_URL ||
            'https://www.googletagmanager.com',
        };

        TagManager.initialize(tagManagerArgs);
        TagManager.dataLayer({
          dataLayer: {
            event: 'initialised',
            eventModel: {
              membershipId,
              intercomIsEnabled,
            },
          },
        });
      }
    }
  }, [membershipId, trackingEnabled, intercomIsEnabled]);

  const trackWithoutProxy: TrackFunction = function (event, properties) {
    if (organization?.id && membershipId) {
      analytics.track(event, {
        timestamp: new Date().toISOString(),
        user_id: membershipId,
        organization_id: organization.id,
        ...properties,
      });

      TagManager.dataLayer({
        dataLayer: {
          event,
          eventModel: {
            timestamp: new Date().toISOString(),
            user_id: membershipId,
            organization_id: organization.id,
            ...properties,
          },
        },
      });
    }
  };

  const track: TrackFunction = function (event, properties) {
    if (
      import.meta.env.REACT_APP_STAGE !== 'prod' ||
      localStorage.getItem('show_tracking_logs')
    ) {
      // You need to also set the log level in your console to verbose to see it
      console.debug(`Track event ${event} was fired`, {
        properties,
      });
    }

    trackWithoutProxy(event, properties);
  };

  return (
    <AnalyticsContext.Provider value={{ ...analytics, track }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
