import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useWatch } from 'react-hook-form';
import { Team } from '../../toolkit/hooks/useGetTeams';
import { CreditCardSetupFormValues } from '../../toolkit/utils/creditCardSetupFormSchema';
import { CreditCardSetupDetails } from './CreditCardSetupDetails';
import { TeamDetailsInfoCard } from './TeamDetailsInfoCard';

interface CreditCardSetupDetailsCardProps {
  team: Team;
}

const limitFields = ['limit', 'oneTimeCardLimit'] as const;

export const CreditCardSetupDetailsCard = ({
  team,
}: CreditCardSetupDetailsCardProps) => {
  const { transactionAmountFormatter } = useMoneyFormatter();
  const [limit, oneTimeCardLimit] =
    useWatch<CreditCardSetupFormValues, typeof limitFields>({
      name: limitFields,
    }) ?? [];

  const formattedLimit = limit
    ? transactionAmountFormatter({
        currency: 'EUR',
        precision: 0,
        value: limit,
      })
    : undefined;

  const formattedOneTimeCardLimit = oneTimeCardLimit
    ? transactionAmountFormatter({
        currency: 'EUR',
        precision: 0,
        value: oneTimeCardLimit,
      })
    : undefined;

  const showAdditionalDetails = formattedLimit || formattedOneTimeCardLimit;

  return (
    <TeamDetailsInfoCard
      team={team}
      additionalDetails={
        showAdditionalDetails && (
          <CreditCardSetupDetails
            limit={formattedLimit}
            oneTimeCardLimit={formattedOneTimeCardLimit}
          />
        )
      }
    />
  );
};
