import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useFullOrganization } from 'providers/OrganizationProvider';

export const useShouldRequireGeneralLedgerAccounts = () => {
  const { shouldUseCandisApi } = useOtherIntegration();

  const { isActive: isSapActive } = useSap();

  const organization = useFullOrganization();

  const isOrganizationUsingGLAccounts = organization?.hasActiveBookingAccounts;

  return Boolean(
    isOrganizationUsingGLAccounts && (isSapActive || shouldUseCandisApi)
  );
};

export const useShouldRequireTaxCodes = () => {
  const { isActive: isSapActive } = useSap();
  const { shouldUseCandisApi } = useOtherIntegration();

  const organization = useFullOrganization();

  const isOrganizationUsingTaxCodes = organization?.hasActiveBookingKeys;

  return Boolean(
    isOrganizationUsingTaxCodes && (shouldUseCandisApi || isSapActive)
  );
};

export const useShouldRequireAccountsPayableNumber = () => {
  const { shouldUseCandisApi } = useOtherIntegration();

  return shouldUseCandisApi;
};
