import gql from 'graphql-tag';

export const accountsPayableNumberSettingsQuery = gql`
  query accountsPayableNumberSettings {
    accountsPayableNumberSettings {
      startNumber
      enabled
    }
  }
`;
