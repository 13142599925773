import {
  Avatar,
  Flex,
  Grid,
  Icon,
  Image,
  Link,
  ScrollBox,
  Skeleton,
  Text,
  Tooltip,
  TruncatedText,
  defaultTheme,
  useTooltip,
} from '@candisio/design-system';
import emptyStateImage from 'assets/dms/document-relations-empty-state.png';
import promoImage from 'assets/dms/document-relations-promo.png';
import { BellIcon } from 'components/Icons/DefaultIcons';
import { StatusTag } from 'components/StatusTag';
import { EcmDocumentType, User } from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import DateFormatters from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { useEcmContractTypeOptions } from './Ecm/useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from './Ecm/useEcmDocumentTypeItems';
import { RelatedDocument } from './hooks/useDocumentRelations';
import { FallbackStatusTag, DocumentInfoTag } from './utils';

const StyledGrid = styled(Grid)`
  list-style: none;
  padding-inline-start: 0;
  margin: 0;

  display: grid;

  /* each immediate child that has a sibling */
  /* 
    parent
    -x  child1
    -x    grandchild
    ->  child2
    -x    grandchild
    ->  child3
    -x    grandchild
  */
  > * + * {
    border-block-start: 1px solid ${defaultTheme.colors.gray200};
  }
`;

interface DocumentSummaryCardProps extends ComponentProps<typeof Grid> {
  document: RelatedDocument;
  onAction?: () => void;
  as?: keyof JSX.IntrinsicElements;
  action?: ReactNode;
  variant?: 'full' | 'short';
}

export const LoadingDocumentSummaryCard = ({
  variant = 'full',
  ...props
}: Partial<DocumentSummaryCardProps>) => (
  <DocumentSummaryCardContainer {...props}>
    <Skeleton height="space30" width="25%" />
    <Flex direction="column" rowGap="space8">
      <Flex alignItems="center" justifyContent="space-between">
        <Skeleton height="space20" width="35%" />
        <Skeleton height="space24" width="15%" />
      </Flex>
      {variant === 'full' && (
        <Flex
          wrap="wrap"
          columnGap="space16"
          rowGap="space4"
          alignItems="start"
          justifyContent="space-between">
          <Skeleton flex="3 2 10rem" height="space20" width="100%" />
          <Skeleton flex="1 0 3rem" height="space20" width="100%" />
        </Flex>
      )}
      <Flex columnGap="space16" alignItems="start" justifyContent="start">
        <Skeleton flex="0 0 10rem" height="space20" width="80%" />
      </Flex>
    </Flex>
  </DocumentSummaryCardContainer>
);

type FallbackTranslations = {
  documentNumber: string;
  date: string;
};

// Uses LOCALE_NAME_SPACE.ECM
export const fallbacksTransaltionMap: Record<
  EcmDocumentType,
  FallbackTranslations
> = {
  CONTRACT: {
    documentNumber: 'documentRelationship.fallback.contract.documentNumber',
    date: 'documentRelationship.fallback.contract.date',
  },
  DELIVERY_NOTE: {
    documentNumber:
      'documentRelationship.fallback.delivery_note.documentNumber',
    date: 'documentRelationship.fallback.delivery_note.date',
  },
  EMPTY: {
    documentNumber: 'documentRelationship.fallback.empty.documentNumber',
    date: 'documentRelationship.fallback.empty.date',
  },
  INVOICE: {
    documentNumber: 'documentRelationship.fallback.invoice.documentNumber',
    date: 'documentRelationship.fallback.invoice.date',
  },
  OFFER: {
    documentNumber: 'documentRelationship.fallback.offer.documentNumber',
    date: 'documentRelationship.fallback.offer.date',
  },
  ORDER_CONFIRMATION: {
    documentNumber:
      'documentRelationship.fallback.order_confirmation.documentNumber',
    date: 'documentRelationship.fallback.order_confirmation.date',
  },
  OTHER: {
    documentNumber: 'documentRelationship.fallback.other.documentNumber',
    date: 'documentRelationship.fallback.other.date',
  },
  PURCHASE_ORDER: {
    documentNumber:
      'documentRelationship.fallback.purchase_order.documentNumber',
    date: 'documentRelationship.fallback.purchase_order.date',
  },
} as const;

export const DocumentSummaryCardContainer = ({
  children,
  onAction,
  background = 'gray0',
  ...rest
}: ComponentProps<typeof Grid>) => (
  <Grid
    fontSize="basic"
    paddingX="space16"
    paddingY="space14"
    gap="space12"
    background={background}
    {...(onAction && {
      onPointerDown: onAction,
      cursor: 'pointer',
      hover: { background: 'gray100' },
    })}
    {...rest}>
    {children}
  </Grid>
);

export const DocumentSummaryCard = ({
  as = 'li',
  document,
  isLoading = false,
  action,
  ...rest
}: DocumentSummaryCardProps & {
  isLoading?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  const { getContractTypeLabel } = useEcmContractTypeOptions();

  const {
    amount,
    createdBy,
    date,
    isOverdue,
    isSensitive,
    name,
    notifyPerson,
    number,
    responsiblePerson,
    status,
    type,
    id,
    contractTypeId,
  } = document;

  const isContract = type === EcmDocumentType.Contract;

  const usersWithAccess = useMemo(() => {
    return Array(createdBy)
      .concat(notifyPerson, responsiblePerson)
      .filter(Boolean);
  }, [createdBy, notifyPerson, responsiblePerson]);

  if (isLoading)
    return (
      <Grid as={as} key={id}>
        <LoadingDocumentSummaryCard {...rest} />
      </Grid>
    );

  const documentField: string =
    number ?? t(fallbacksTransaltionMap[type].documentNumber);

  const dateField = date
    ? DateFormatters.compact(new Date(date))
    : t(fallbacksTransaltionMap[type].date);

  const amountField = amount
    ? amountFormat(amount?.amount, amount?.currency, {
        convertToBasicMonetaryUnit:
          type !== EcmDocumentType.Invoice && amount.precision === 2,
      })
    : t('documentRelationship.fallback.amount');

  const nameField: string =
    name ?? t('documentRelationship.fallback.contactName');

  const documentInfo =
    type === EcmDocumentType.Contract && contractTypeId
      ? getContractTypeLabel(contractTypeId)
      : ecmDocumentTypeTranslationMap[type];

  return (
    <Grid as={as} key={id}>
      <DocumentSummaryCardContainer background="gray0" {...rest}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={{ marginInlineEnd: '-.5rem' }}>
          <Flex gap="space4">
            <DocumentInfoTag>{documentInfo}</DocumentInfoTag>
            {isContract && (
              <ContractInfo
                isSensitive={isSensitive}
                users={usersWithAccess as UserWithAccessInfoProps['users']}
              />
            )}
          </Flex>
          {action}
        </Flex>
        <Flex direction="column" rowGap="space4">
          <Flex
            columnGap="space12"
            alignItems="center"
            justifyContent="space-between">
            <TruncatedText
              charsAfterEllipsis={5}
              flex="0 2 14rem"
              maxWidth="14rem"
              fontWeight="500">
              {documentField}
            </TruncatedText>
            <Flex alignItems="center">
              {isOverdue && <OverdueIcon />}
              {status ? <StatusTag status={status} /> : <FallbackStatusTag />}
            </Flex>
          </Flex>
          <Flex
            color="gray500"
            columnGap="space16"
            alignItems="start"
            justifyContent="space-between">
            <Text>{dateField}</Text>
            <Text flex="0 0 auto">{amountField}</Text>
          </Flex>
          <Flex color="gray500" columnGap="space16" alignItems="start">
            <TruncatedText charsAfterEllipsis={5}>{nameField}</TruncatedText>
          </Flex>
        </Flex>
      </DocumentSummaryCardContainer>
    </Grid>
  );
};

export const DocumentSummaryCardListContainer = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Grid rowGap="1px" overflow="hidden" height="100%">
    {children}
  </Grid>
);

export const DocumentSummaryCardList = ({
  children,
  background = 'gray0',
}: ComponentProps<typeof Grid>) => {
  return (
    <ScrollBox
      scrollDirection="y"
      scrollbarGutter="stable"
      background={background}>
      <StyledGrid as="ul" role="grid">
        {children}
      </StyledGrid>
    </ScrollBox>
  );
};

const StyledLink = styled(Link)`
  white-space: nowrap;

  > svg {
    top: 0;
    bottom: 0;
  }
`;

export const EmptyDocumentSummaryCardList = ({
  canAddTransaction,
}: {
  canAddTransaction: boolean;
}) => {
  const [t] = useTranslation();

  const helpCenterLink = t(
    'document.tabs.relationships.helpCenterArticle.helpCenterArticleUrl'
  );

  const helpCenterLabel = t(
    'document.tabs.relationships.helpCenterArticle.helpCenterArticleLabel'
  );

  let traslationContext: 'onlyDocuments' | undefined;
  if (!canAddTransaction) traslationContext = 'onlyDocuments';

  return (
    <Grid role="none">
      <DocumentSummaryCardContainer height="150px" padding="space8">
        <Grid
          gap="space16"
          alignItems="center"
          autoFlow="column"
          background="bluebg"
          padding="space16 space20"
          borderRadius="medium">
          <Image
            alt=""
            src={emptyStateImage}
            width="space80"
            height="space80"
          />
          <Grid autoFlow="row">
            <Text textAlign="start" fontSize="basic" color="gray600">
              {t('document.tabs.relationships.emptyList', {
                context: traslationContext,
              })}
            </Text>
            <StyledLink external href={helpCenterLink} fontWeight="regular">
              {helpCenterLabel}
            </StyledLink>
          </Grid>
        </Grid>
      </DocumentSummaryCardContainer>
    </Grid>
  );
};

const StyledText = styled(Text)`
  @media screen and (width < 1481px) {
    display: none;
  }
`;

export const PromoDocumentSummaryCardList = () => {
  const [t] = useTranslation();
  const { isInUse } = useCreditCardsSetup();

  return (
    <Grid role="none">
      <DocumentSummaryCardContainer height="150px" padding="space8">
        <Grid
          gap="space16"
          alignItems="center"
          autoFlow="column"
          background="purplebg"
          padding="space16 space20"
          borderRadius="medium">
          <Image alt="" src={promoImage} width="space80" height="space80" />
          <Grid autoFlow="row">
            <StyledText fontWeight="semibold">
              {t('document.tabs.relationships.promo.title')}
            </StyledText>
            <Text textAlign="start" fontSize="basic" color="gray600">
              {t('document.tabs.relationships.promo.text', {
                context: isInUse && 'withTransactions',
              })}
            </Text>
          </Grid>
        </Grid>
      </DocumentSummaryCardContainer>
    </Grid>
  );
};

export const ErrorDocumentSummaryCardList = () => {
  const [t] = useTranslation();

  return (
    <Grid role="none">
      <DocumentSummaryCardContainer paddingY="space24">
        <Flex rowGap="space16" direction="column" alignItems="center">
          <Icon icon="warning" size="3rem" color="red400" />
          <Text textAlign="center" fontSize="basic">
            {t('document.tabs.relationships.error')}
          </Text>
        </Flex>
      </DocumentSummaryCardContainer>
    </Grid>
  );
};

type UserWithAccessInfoProps = {
  users: Pick<User, 'name' | 'avatarUrl'>[];
};

const ContractInfo = ({
  isSensitive = false,
  users,
}: UserWithAccessInfoProps & {
  isSensitive?: boolean;
}) =>
  isSensitive ? (
    <SensitiveContractInfo users={users} />
  ) : (
    <RegularContractInfo />
  );

const RegularContractInfo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const { isOpen, tooltipProps, tooltipRef, triggerRef, triggerProps } =
    useTooltip();

  return (
    <>
      <DocumentInfoTag
        ref={triggerRef}
        {...triggerProps}
        paddingX="space5"
        paddingY="space4"
        height="space30">
        <Icon icon="infoOutline" color="gray500" size="space20" />
      </DocumentInfoTag>

      {isOpen && (
        <Tooltip ref={tooltipRef} {...tooltipProps} maxWidth="360px">
          <Text>{t('documentRelationship.contract.infoTooltip')}</Text>
        </Tooltip>
      )}
    </>
  );
};

const SensitiveContractInfo = ({ users }: UserWithAccessInfoProps) => {
  const { isOpen, tooltipProps, tooltipRef, triggerRef, triggerProps } =
    useTooltip();

  return (
    <>
      <DocumentInfoTag
        ref={triggerRef}
        {...triggerProps}
        paddingX="space5"
        paddingY="space4"
        height="space30">
        <Icon icon="lockFilled" color="gray500" size="space20" />
      </DocumentInfoTag>

      {isOpen && (
        <Tooltip ref={tooltipRef} {...tooltipProps}>
          <UserWithAccessInfo users={users} />
        </Tooltip>
      )}
    </>
  );
};

const UserWithAccessInfo = ({ users }: UserWithAccessInfoProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <Grid templateColumns="auto 1fr" alignItems="center" gap="space8">
      <Text gridColumnStart={1} gridColumnEnd={3}>
        {t('documentRelationship.contract.infoSensitiveTooltip')}
      </Text>

      {users.map(user => (
        <>
          <Avatar img={user.avatarUrl ?? undefined} name={user.name} />
          <Text>{user.name}</Text>
        </>
      ))}
    </Grid>
  );
};

const OverdueIcon = () => {
  const [t] = useTranslation();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'top' });

  return (
    <>
      <Grid
        {...triggerProps}
        ref={triggerRef}
        paddingY="space2"
        paddingX="space8">
        <BellIcon size="space16" />
      </Grid>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Text>{t('common.overdue')}</Text>
        </Tooltip>
      )}
    </>
  );
};
