import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const MultipleValueCell = ({ value }: { value?: string[] }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  if (!value || value.length === 0) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return (
    <TruncatedText wordBreak="break-word">
      {value.filter(v => !!v).join(', ')}
    </TruncatedText>
  );
};
