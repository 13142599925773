import { Drawer } from '@candisio/design-system';
import { MembershipStatus, Locale } from 'generated-types/graphql.types';
import { User } from '../hooks/useGetUsers';
import { ActiveFormLegacyRolesContainer } from './containers/active/ActiveFormLegacyRolesContainer';
import { InactiveLegacyRolesFormContainer } from './containers/inactive/InactiveLegacyRolesFormContainer';
import { InviteFormContainerDeprecated } from './containers/invite/InviteFormContainerDeprecated';
import { PendingFormLegacyRolesContainer } from './containers/pending/PendingFormLegacyRolesContainer';

export interface TeamMemberDetailsProps {
  isOpen: boolean;
  loading?: boolean;
  organizationLanguage: Locale | null;
  closeDrawer: () => void;
  allUsers: Array<User>;
  selectedUserId?: string;
  onOpenIssueCardModal: (createdUserId: string) => void;
}

export const TeamMemberDetails = (props: TeamMemberDetailsProps) => {
  const { allUsers, closeDrawer, isOpen, selectedUserId } = props;

  const selectedUser =
    allUsers.find(user => user.id === selectedUserId) || null;

  return (
    <Drawer isOpen={isOpen} onClose={closeDrawer}>
      {!selectedUser ? (
        <InviteFormContainerDeprecated {...props} />
      ) : !selectedUser.emailVerified ? (
        <PendingFormLegacyRolesContainer
          selectedUser={selectedUser}
          {...props}
        />
      ) : selectedUser.status === MembershipStatus.Active ? (
        <ActiveFormLegacyRolesContainer
          selectedUser={selectedUser}
          {...props}
        />
      ) : (
        <InactiveLegacyRolesFormContainer
          selectedUser={selectedUser}
          {...props}
        />
      )}
    </Drawer>
  );
};
