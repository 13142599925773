import { useAnalytics } from 'providers/AnalyticsProvider';
import { ConfigurationsTrackingEvents } from 'providers/AnalyticsProvider/events/ConfigurationsTrackingEvents';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Configuration } from '../ConfigurationsMenu';

/**
 *
 * @returns Handler functions to track different events related to the configurations
 * in the tables
 */
export const useTrackConfigurations = () => {
  const [t] = useTranslation();
  const { track } = useAnalytics();

  const handleTrackHiddenColumns = useCallback(
    (configurations: Configuration[], tableId: string) => {
      const hiddenColumns = configurations
        .filter(configuration => !configuration.isVisible)
        .map(configuration => t(configuration.label));

      track(ConfigurationsTrackingEvents.SAVE_BUTTON_CLICKED, {
        tableId,
        hiddenColumns,
      });
    },
    [t, track]
  );

  return { handleTrackHiddenColumns };
};
