import { FilterOptionsAccessor } from '@candisio/design-system';
import {
  CardStatus,
  CardType,
  TransactionCategory,
  useGetCardIssuerCardsRefNumbersAndLabelsQuery,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCardHolders } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ToolbarFilter,
  toolBarFilterParam,
} from '../CardManagerView/components/CreditCardsTable/CreditCardsTableToolbar';
import { CreditCardsTableData } from '../types';

const MAX_NUMBER_CARD_REF_NUMBERS_TO_FETCH = 1000;

export const cardsRefNumbersQueryVariables = {
  variables: { input: { limit: MAX_NUMBER_CARD_REF_NUMBERS_TO_FETCH } },
};

export const useCardIssuerCardsFilters = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { cardHolders, loading: listHoldersLoading } = useCardHolders();

  const { data, loading: cardRefNumbersLoading } =
    useGetCardIssuerCardsRefNumbersAndLabelsQuery({
      ...cardsRefNumbersQueryVariables,
      fetchPolicy: 'no-cache',
    });

  const { searchParams } = useMutateSearchParams();

  const toolbarFilterApplied: ToolbarFilter = (searchParams.get(
    toolBarFilterParam
  ) ?? 'InUse') as ToolbarFilter;

  const cardIssuerCardsRefNumbersAndLabels =
    data?.getCardIssuerCardsRefNumbersAndLabels.edges;

  const filterOptions: FilterOptionsAccessor<CreditCardsTableData> = useMemo(
    () => ({
      issued: true,
      validUntil: true,
      cardType: {
        data: [
          {
            id: CardType.Physical,
            label: t('cardManagerView.cardType.physical'),
          },
          {
            id: CardType.SingleUse,
            label: t('cardManagerView.cardType.virtualx1'),
          },
          {
            id: CardType.Virtual,
            label: t('cardManagerView.cardType.virtual'),
          },
        ],
      },
      status: {
        data:
          toolbarFilterApplied === 'InUse'
            ? [
                {
                  id: CardStatus.Active,
                  label: t('cardManagerView.status.active'),
                },
                {
                  id: CardStatus.Locked,
                  label: t('cardManagerView.status.locked'),
                },
                {
                  id: CardStatus.LockedPin,
                  label: t('cardManagerView.status.lockedPin'),
                },
                {
                  id: CardStatus.Pending,
                  label: t('cardManagerView.status.pending'),
                },
                {
                  id: CardStatus.Requested,
                  label: t('cardManagerView.status.requested'),
                },
                {
                  id: CardStatus.Processing,
                  label: t('cardManagerView.status.processing'),
                },
              ]
            : [
                {
                  id: CardStatus.Expired,
                  label: t('cardManagerView.status.expired'),
                },
                {
                  id: CardStatus.Terminated,
                  label: t('cardManagerView.status.terminated'),
                },
              ],
      },
      cardholderName: {
        data: (cardHolders || []).map(cardholder => ({
          id: cardholder.node.id,
          label: `${cardholder.node.firstName} ${cardholder.node.lastName}`,
        })),
      },
      cardNumber: {
        data: (cardIssuerCardsRefNumbersAndLabels || [])
          .map(card => ({
            id: card.node.id,
            label: card.node.refNum || '',
            optionalLabel: card.node.label || '',
          }))
          .filter(f => !!f.label),
      },
      category: {
        data: [
          {
            id: TransactionCategory.AdvertisingAndMarketing,
            label: t('insights.filters.category.list.AdvertisingAndMarketing'),
          },
          {
            id: TransactionCategory.ComputingAndSoftware,
            label: t('insights.filters.category.list.ComputingAndSoftware'),
          },
          {
            id: TransactionCategory.EducationAndTraining,
            label: t('insights.filters.category.list.EducationAndTraining'),
          },
          {
            id: TransactionCategory.ElectronicsAndItEquipment,
            label: t(
              'insights.filters.category.list.ElectronicsAndItEquipment'
            ),
          },
          {
            id: TransactionCategory.EntertainmentAndWellness,
            label: t('insights.filters.category.list.EntertainmentAndWellness'),
          },
          {
            id: TransactionCategory.FoodAndDrinks,
            label: t('insights.filters.category.list.FoodAndDrinks'),
          },
          {
            id: TransactionCategory.GiftsAndVouchers,
            label: t('insights.filters.category.list.GiftsAndVouchers'),
          },
          {
            id: TransactionCategory.MaterialsAndPackaging,
            label: t('insights.filters.category.list.MaterialsAndPackaging'),
          },
          {
            id: TransactionCategory.OfficeSuppliesAndEquipment,
            label: t(
              'insights.filters.category.list.OfficeSuppliesAndEquipment'
            ),
          },
          {
            id: TransactionCategory.Other,
            label: t('insights.filters.category.list.Other'),
          },
          {
            id: TransactionCategory.Services,
            label: t('insights.filters.category.list.Services'),
          },
          {
            id: TransactionCategory.TravelAndAccommodation,
            label: t('insights.filters.category.list.TravelAndAccommodation'),
          },
        ],
      },
      cardAccounting: {
        data: [
          {
            id: 'Active',
            label: t(
              'cardManagerView.table.columns.cardAccountingFilter.active'
            ),
          },
          {
            id: 'Not_Active',
            label: t(
              'cardManagerView.table.columns.cardAccountingFilter.notActive'
            ),
          },
        ],
      },
    }),
    [cardHolders, cardIssuerCardsRefNumbersAndLabels, t, toolbarFilterApplied]
  );

  return {
    filterOptions,
    isFilterLoading: listHoldersLoading || cardRefNumbersLoading,
  };
};
