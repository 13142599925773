import { mergeProps } from '@candisio/design-system';
import {
  HookFormPaginatedComboBoxField,
  HookFormPaginatedComboBoxFieldProps,
} from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { FieldPath } from 'react-hook-form';
import { StorageFormValues } from './storageFormSchema';
import { useStorageFormField } from './useStorageFormField';

export const StorageFormPaginatedComboBoxField = ({
  name,
  ...restProps
}: HookFormPaginatedComboBoxFieldProps<StorageFormValues>) => {
  const { fieldProps } = useStorageFormField(
    `${name}.value` as FieldPath<StorageFormValues>
  );

  return (
    <HookFormPaginatedComboBoxField
      name={name}
      {...mergeProps(fieldProps, restProps)}
    />
  );
};
