import { Link } from '@candisio/design-system';
import {
  CheckmarkIcon,
  InfoIcon,
  ErrorIcon,
} from 'components/Icons/DefaultIcons';
import { CostCenterTypes } from 'generated-types/graphql.types';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
import { InfoItem, InfoListWrapper } from './styles';

export interface InfoListProps {
  animate?: boolean;
  costCenterType: CostCenterTypes;
  costCentersCount: number;
  duplicateCount: number;
  errors: string[];
  isDatevFormat: boolean;
  onShowDuplicates?: () => void;
}

export const InfoList = ({
  animate = false,
  costCenterType,
  costCentersCount,
  duplicateCount,
  errors,
  isDatevFormat,
  onShowDuplicates,
}: InfoListProps) => {
  const [t] = useTranslation();
  const hasError = errors ? errors.length > 0 : false;

  const costCenterTypeMap = new Map<CostCenterTypes, string>([
    [CostCenterTypes.CostCenter, 'costCenter'],
    [CostCenterTypes.CostObject, 'costObject'],
    [CostCenterTypes.ExtraCostInfo, 'extraCostInfo'],
  ]);

  return (
    <InfoListWrapper initial={animate ? 'hidden' : false}>
      {hasError ? (
        errors.map(error => (
          <InfoItem key={error}>
            <ErrorIcon size="space20" />
            <span>{error}</span>
          </InfoItem>
        ))
      ) : (
        <>
          <InfoItem>
            <CheckmarkIcon size="space20" />
            <span>
              {t(
                'settings.costCenter.wizardModal.previewStep.foundCostCenters',
                {
                  numberOfItems: costCentersCount,
                  // eslint-disable-next-line candis/no-template-strings-inside-translation
                  costCenterType: t(
                    `settings.costCenter.wizardModal.previewStep.${costCenterTypeMap.get(
                      costCenterType
                    )}`,
                    {
                      context: getTranslationContext(),
                    }
                  ),
                }
              )}
            </span>
          </InfoItem>
          {duplicateCount ? (
            <InfoItem>
              <ErrorIcon size="space20" />
              <span>
                {t(
                  'settings.costCenter.wizardModal.previewStep.duplicationInfo',
                  { duplicationCount: duplicateCount, count: duplicateCount }
                )}
                {duplicateCount !== costCentersCount && onShowDuplicates && (
                  <Link
                    as="button"
                    onClick={onShowDuplicates}
                    style={{ marginLeft: '0.25rem' }}>
                    {t(
                      'settings.costCenter.wizardModal.previewStep.showDuplicate',
                      {
                        count: duplicateCount,
                      }
                    )}
                  </Link>
                )}
              </span>
            </InfoItem>
          ) : null}
          <InfoItem>
            <InfoIcon size="space20" />
            <span>
              {isDatevFormat
                ? t(
                    'settings.costCenter.wizardModal.fileSelectionStep.validation.datevCollectiveCostCenterOmitted'
                  )
                : t(
                    'settings.costCenter.wizardModal.fileSelectionStep.validation.nonDatevFormatWarning'
                  )}
            </span>
          </InfoItem>
        </>
      )}
    </InfoListWrapper>
  );
};
