import { Card } from '@candisio/design-system';
import {
  LayoutProps,
  StandardHTMLAttributes,
  TokenOrCSSValue,
} from '@candisio/design-system/dist/types';
import { forwardRef, useRef } from 'react';
import { AriaGridListOptions, useGridList } from 'react-aria';
import { ListProps as AriaListProps, useListState } from 'react-stately';
import { GridComponents, VirtuosoGrid } from 'react-virtuoso';
import { VirtuosoItem } from './VirtuosoItem';
import { VirtuosoList } from './VirtuosoList';
import { VirtuosoScroller } from './VirtuosoScroller';

// Ensure that this stays out of the component,
// Otherwise the grid will remount with each render due to new component instances.
const gridComponents: GridComponents = {
  Scroller: VirtuosoScroller,
  List: VirtuosoList,
  Item: VirtuosoItem,
};

/** @ts-expect-error TODO: React upgrade props types mismatch */
export interface GridViewProps<TListItem = any>
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement> {
  children?: AriaListProps<TListItem>['children'];
  items?: AriaListProps<TListItem>['items'];
  itemWidth?: TokenOrCSSValue<'space', 'width'>;
  listGap?: TokenOrCSSValue<'space', 'rowGap'>;
  onAction?: AriaGridListOptions<TListItem>['onAction'];
  /** Called when user scrolls to the end of the list */
  onEndReached?: (index: number) => void;
}

const DEFAULT_ITEM_WIDTH = '300px';

/**
 * Displays a list of interactive items in a grid format with support for keyboard navigation and row actions.
 */
export const GridView = forwardRef<HTMLDivElement, GridViewProps>(
  (
    {
      'aria-describedby': ariaDescribedBy,
      'aria-details': ariaDetails,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      children,
      id,
      itemWidth = DEFAULT_ITEM_WIDTH,
      listGap = 'space20',
      items = [],
      onAction,
      onEndReached,
      ...restProps
    },
    ref
  ) => {
    const state = useListState({ children, items });
    const listRef = useRef<HTMLUListElement | null>(null);
    const { gridProps } = useGridList(
      {
        'aria-describedby': ariaDescribedBy,
        'aria-details': ariaDetails,
        'aria-label':
          // suppress console warning
          ariaLabel || ariaLabelledBy ? ariaLabel : 'Listbox',
        'aria-labelledby': ariaLabelledBy,
        id,
        isVirtualized: true,
        items,
        onAction,
      },
      state,
      listRef
    );

    const options = Array.from(state.collection);

    return (
      <VirtuosoGrid
        totalCount={options.length}
        components={gridComponents}
        itemContent={(_, content) => (
          <Card padding="0px" border="1px solid gray250">
            {content.rendered}
          </Card>
        )}
        context={{
          listProps: {
            ...gridProps,
            itemWidth,
            listGap,
            ref: listRef,
          },
          scrollerProps: {
            ...restProps,
            ref,
          },
          state,
        }}
        data={options}
        endReached={onEndReached}
      />
    );
  }
);
