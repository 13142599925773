import { Box, Flex, Grid, useId } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { useOrganizationContactInformationUpdate } from '../hooks/useOrganizationContactInformationUpdate';
import { useOrganizationData } from '../hooks/useOrganizationData';
import {
  taxIdNumberErrorMessages,
  taxIdNumberFormData,
  taxIdNumberFormDataOutput,
  taxIdNumberSchema,
} from '../resolvers/taxNumberResolver';

export const TaxIdField = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { taxNumber } = useOrganizationData();
  const formId = useId();

  const form = useForm<taxIdNumberFormData>({
    values: { taxNumber: taxNumber },
    resolver: zodResolver({
      zodSchema: taxIdNumberSchema,
      errorMessages: {
        ...taxIdNumberErrorMessages,
      },
    }),
  });

  const { handleOrganizationContactInformationUpdate } =
    useOrganizationContactInformationUpdate();

  const handleSubmit = async (taxNumber: taxIdNumberFormDataOutput) => {
    const { isDirty } = form.getFieldState('taxNumber');

    if (isDirty) {
      await handleOrganizationContactInformationUpdate(taxNumber);
    }
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Grid onSubmit={form.handleSubmit(handleSubmit)} id={formId} as="form">
        <Flex gap="space16" alignItems="center">
          <HookFormTextField
            label={t('organisation.contactInformation.taxNumber.label')}
            name="taxNumber"
            placeholder={t(
              'organisation.contactInformation.taxNumber.placeholder'
            )}
            width="space200"
          />
          {/* centers buttons when next to a field with a label */}
          <Box paddingTop="space16">
            <QuickSaveButtons
              isFormDirty={isFormDirty}
              resetField={() => form.resetField('taxNumber')}
            />
          </Box>
        </Flex>
      </Grid>
    </FormProvider>
  );
};
