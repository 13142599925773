export const validateDimensions = (
  f: File,
  dimensions: { width: number; height: number }
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      const image = new Image();
      image.onload = function () {
        const { width, height } = this as any;
        if (dimensions.width >= width && dimensions.height >= height) {
          resolve(f);
        }

        reject({ message: 'uploads.errors.maxSizeImage' });
      };

      image.src = e.target?.result as string;
    };

    reader.readAsDataURL(f);
  });
};
