import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Link,
  Paragraph,
  Text,
} from '@candisio/design-system';
import { CheckmarkIcon } from 'components/Icons/DefaultIcons';
import {
  useActivatePaymentMutation,
  useDeactivatePaymentMutation,
  useUpdatePaymentStartDateMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDateConverter } from 'hooks/useDateConverter';
import { useUserRoles } from 'hooks/useUserRoles';
import i18next from 'i18next';
import { Trans } from 'providers/LocaleProvider';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateFmt from 'utils/date_formatter';
import { DatePickerForm } from './DatePickerForm';
import { StyledWrapper } from './styles';

const getLoadingText = ({
  tSettings,
  activationLoading,
  deactivationLoading,
}: {
  tSettings: i18next.TFunction;
  activationLoading: boolean;
  deactivationLoading: boolean;
}): string => {
  if (activationLoading) {
    return tSettings('payments.activation.button.activating');
  }

  if (deactivationLoading) {
    return tSettings('payments.activation.button.deactivating');
  }

  return tSettings('payments.activation.button.startDateUpdating');
};

interface PaymentActivationFormProps {
  isActive: boolean;
  startDate?: Date | null;
}

export const PaymentActivationForm = ({
  isActive,
  startDate,
}: PaymentActivationFormProps) => {
  const [tSettings] = useTranslation('settings');
  const [activating, setActivating] = useState<boolean>(false);
  const moreDetailsURL = tSettings('payments.activation.helpUrl');
  const { isAdmin } = useUserRoles();

  const { dateToDateString } = useDateConverter();

  const counterQueries = useCounterQueries();

  const [activatePayment, { loading: activationLoading }] =
    useActivatePaymentMutation({
      refetchQueries: [{ query: getFullOrganizationQuery }, ...counterQueries],
      awaitRefetchQueries: true,
    });

  const [deactivatePayment, { loading: deactivationLoading }] =
    useDeactivatePaymentMutation({
      refetchQueries: [{ query: getFullOrganizationQuery }, ...counterQueries],
      awaitRefetchQueries: true,
    });

  const [updatePaymentStartDate, { loading: updateLoading }] =
    useUpdatePaymentStartDateMutation({
      refetchQueries: [{ query: getFullOrganizationQuery }, ...counterQueries],
      awaitRefetchQueries: true,
    });

  const loading = activationLoading || deactivationLoading || updateLoading;

  const submitDeactivatePayment = async () => {
    await deactivatePayment();
  };

  return (
    <Card
      height={activating || isActive ? '15.15rem' : '9rem'}
      style={{ transition: 'height 0.5s cubic-bezier(0.87, 0, 0.13, 1)' }}>
      <Flex direction="column" height="100%" justifyContent="space-between">
        <Grid templateColumns="6fr 2fr" gap="space64" alignItems="start">
          <Grid gap="space16">
            <Heading as="h3">{tSettings('payments.activation.title')}</Heading>
            <Paragraph>
              <Trans t={tSettings} i18nKey="payments.activation.description">
                Bitte beachte, dass du Rechnungen nicht älter als
                <Text fontWeight="bold">60 Tage</Text> mit CANDIS bezahlen
                kannst. Die Funktion kann jederzeit deaktiviert werden.
              </Trans>
              <br />
              <Trans t={tSettings} i18nKey="payments.activation.moreDetails">
                Find out more.
                <Link external href={moreDetailsURL}>
                  Erfahre warum
                </Link>
              </Trans>
            </Paragraph>
            {startDate && (
              <Flex gap="space4" alignItems="center">
                <CheckmarkIcon size="space16" />
                <Text fontWeight="semibold">
                  {tSettings('payments.activation.serviceActivationStartDate', {
                    date: DateFmt.compact(startDate),
                  })}
                </Text>
              </Flex>
            )}
          </Grid>
          {isActive && !loading && (
            <Button
              disabled={!isAdmin}
              onClick={async () => {
                setActivating(false);
                await submitDeactivatePayment();
              }}
              variant="secondary">
              {tSettings('payments.activation.button.deactivation')}
            </Button>
          )}
          {/* only display Activate button when payment feature is fully deactivated */}
          {!isActive && !activating && !loading ? (
            <Button
              disabled={!isAdmin}
              onClick={() => setActivating(true)}
              variant="secondary">
              {tSettings('payments.activation.button.activation')}
            </Button>
          ) : (
            !isActive &&
            !loading && (
              <Button disabled variant="secondary">
                {tSettings('payments.activation.button.askForStartDate')}
              </Button>
            )
          )}
          {loading && (
            <Button variant="secondary" loading disabled>
              {getLoadingText({
                tSettings,
                activationLoading,
                deactivationLoading,
              })}
            </Button>
          )}
        </Grid>
        {(isActive || activating) && (
          <StyledWrapper>
            <Grid paddingTop="space8" paddingBottom="space8" alignContent="end">
              {!startDate && (
                <Text fontWeight="semibold">
                  {tSettings('payments.activation.startDate.description')}
                </Text>
              )}

              <DatePickerForm
                readOnly={!isAdmin}
                initialValues={{
                  startDate: startDate ? dateToDateString(startDate) : '',
                }}
                autoFocus={activating}
                updatePaymentStartDate={updatePaymentStartDate}
                isActive={isActive}
                activatePayment={activatePayment}
              />
            </Grid>
          </StyledWrapper>
        )}
      </Flex>
    </Card>
  );
};
