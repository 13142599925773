import { mergeProps } from '@candisio/design-system';
import {
  HookFormWorkflowField,
  HookFormWorkflowFieldProps,
} from 'components/HookFormFields/HookFormWorkFlowField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

export const ProcessingFormWorkFlowField = ({
  name,
  readOnly,
  ...restProps
}: HookFormWorkflowFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormWorkflowField
      name={name}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
