import { BuiltinRoleName, Locale } from 'generated-types/graphql.types';
import { ErrorMessages } from 'utils/zodFormValidation';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z } from 'zod';

export type ExcludeKeys = 'roles.0.id' | 'roles.0.name' | 'teams';
export interface TeamMemberFormSchemaOptions {
  allUserEmails?: string[];
  /** Fields that are hidden due to the organization using SSO should not be validated */
  hiddenFields?: {
    firstName?: boolean;
    lastName?: boolean;
    roles?: boolean;
  };
}

export const teamMemberFormSchema = ({
  allUserEmails,
  hiddenFields,
}: TeamMemberFormSchemaOptions = {}) => {
  return z.object({
    firstName: hiddenFields?.firstName ? z.string() : nonEmptyString,
    lastName: hiddenFields?.lastName ? z.string() : nonEmptyString,
    issueCreditCard: z.boolean().optional(),
    email: z
      .string()
      .email()
      .superRefine((value, context) => {
        const isEmailTaken = allUserEmails?.some(userEmail => {
          const emailsEqual = userEmail.localeCompare(value) === 0;

          return emailsEqual;
        });

        if (isEmailTaken) {
          context.addIssue({
            code: 'custom',
            params: {
              translationKey: 'settings.teamMembers.errors.duplicate',
            },
          });
        }
      }),
    locale: z.nativeEnum(Locale),
    teams: z.array(z.string()).optional(),
    roles: z
      .array(z.object({ id: z.string(), name: z.string() }))
      .min(hiddenFields?.roles ? 0 : 1)
      .superRefine((value, context) => {
        const roleNamesSelected = value.map(role => role.name);
        const cardManagerWithoutAdmin =
          roleNamesSelected.includes(BuiltinRoleName.CardManager) &&
          !roleNamesSelected.includes(BuiltinRoleName.Admin);

        if (cardManagerWithoutAdmin) {
          context.addIssue({
            code: 'custom',
            params: {
              translationKey: 'settings.teamMembers.errors.rolesCardManager',
            },
          });
        }
      }),
  });
};

export type TeamMemberFormOutput = z.infer<
  ReturnType<typeof teamMemberFormSchema>
>;
export type TeamMemberFormValues = Partial<TeamMemberFormOutput>;

export type TeamMemberFormErrorMessages = ErrorMessages<
  ReturnType<typeof teamMemberFormSchema>,
  ExcludeKeys
>;
