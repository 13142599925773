import { createContext, useContext } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { DocumentFormValues } from './toolkit/schema';

export type BookingsFormContextValue = UseFieldArrayReturn<
  DocumentFormValues,
  'bookings'
> | null;

export const DocumentFormContext =
  createContext<BookingsFormContextValue>(null);

export const useDocumentFormContext = () => {
  return useContext(DocumentFormContext);
};
