import { useNewDashboardFF } from 'components/NewPromotions/hooks/useNewDashboardFF';
import { Routes } from 'models';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  InsightsWidgetsFiltersFromUrl,
  WidgetModalTab,
} from 'views/InsightsWidgets/utils';
import { DashboardComponent } from './DashboardComponent';
import { NewDashboardComponent } from './NewDashboardComponent';

type Props = RouteComponentProps<{
  organizationSlug: string;
}>;

export const Dashboard = ({
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const history = useHistory();
  const { showNewDashboardPromotions } = useNewDashboardFF();

  const filtersFromUrl = qs.parse(
    window.location.search
  ) as InsightsWidgetsFiltersFromUrl;

  const { onDashboard, landingTab, ...remainingQueryParams } = filtersFromUrl;
  const onOpenWidgetModal = () => {
    history.push({
      pathname: `/${organizationSlug}${Routes.DASHBOARD_WIDGET}/create`,
      search: qs.stringify({
        ...filtersFromUrl,
        onDashboard: 'true',
        landingTab: WidgetModalTab.DETAILS,
      }),
    });
  };

  const onCloseWidgetModal = () => {
    history.replace({
      pathname: `/${organizationSlug}${Routes.DASHBOARD}`,
      search: qs.stringify({ ...remainingQueryParams }),
    });
  };

  return showNewDashboardPromotions ? (
    <NewDashboardComponent
      onCloseWidgetModal={onCloseWidgetModal}
      onOpenWidgetModal={onOpenWidgetModal}
    />
  ) : (
    <DashboardComponent
      onCloseWidgetModal={onCloseWidgetModal}
      onOpenWidgetModal={onOpenWidgetModal}
    />
  );
};
