import { Flex, Icon, Text } from '@candisio/design-system';
import { BellIcon, CheckmarkIcon } from 'components/Icons/DefaultIcons';
import { Maybe } from 'generated-types/graphql.types';
import { OrganizationsInsightsDetails } from 'generated-types/graphql.types';
import { i18n } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { Column, TableExpandedToggleProps } from 'react-table';

type TasksCountsInsight = {
  total: number;
  overdue: number;
};

export type TaskBreakdown = {
  toProcess: Maybe<TasksCountsInsight>;
  toApprove: Maybe<TasksCountsInsight>;
  toExport: Maybe<TasksCountsInsight>;
  monitoringMyRequests: Maybe<{
    toApprove: Maybe<TasksCountsInsight>;
  }>;
};

export interface TableData {
  expander: JSX.Element;
  membershipId: string;
  name: string;
  overdue: number;
  slug: string;
  totalTasks: number;
  taskBreakdown: TaskBreakdown;
}

export const columnWidths = {
  name: 390,
  overDue: 190,
  totalTasks: 190,
  expander: 100,
};

export const getColumnDefinition = (
  organizationSlug: string,
  t: i18n.TFunction
) => {
  const columns: Array<Column<TableData>> = [
    {
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: t('multiOrganization.table.column.name'),
      accessor: 'name',
      id: 'name',
      width: columnWidths.name,
      Cell: ({ cell }) => {
        const slugName = cell.row.original?.slug;

        return (
          <Flex
            alignItems="center"
            color="gray800"
            textDecoration="none"
            fontWeight={organizationSlug === slugName ? 'bold' : 'normal'}>
            {cell.value}
          </Flex>
        );
      },
    },
    {
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: t('multiOrganization.table.column.overdue'),
      accessor: 'overdue',
      width: columnWidths.overDue,
      Cell: ({ cell }) => {
        if (cell.value === 0) {
          return (
            <Flex alignItems="center">
              <CheckmarkIcon size="space18" />
            </Flex>
          );
        } else
          return (
            <Flex color="red500" alignItems="center">
              <Text fontSize="basic">{cell.value}</Text>
              <BellIcon size="space16" />
            </Flex>
          );
      },
    },
    {
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: t('multiOrganization.table.column.task'),
      accessor: 'totalTasks',
      width: columnWidths.totalTasks,
      Cell: ({ cell }) => {
        if (cell.value === 0) {
          return (
            <Flex alignItems="center">
              <CheckmarkIcon size="space18" />
            </Flex>
          );
        } else return <Text fontSize="basic">{cell.value}</Text>;
      },
    },
    {
      // Make an expander cell
      Header: () => null, // No header
      accessor: 'expander',
      disableSortBy: true,
      width: columnWidths.expander,
      id: 'expander', // It needs an ID
      Cell: ({ row }) => {
        const hasActionsToDo = row.original.totalTasks || row.original.overdue;
        if (hasActionsToDo) {
          const { onClick, ...rest } =
            row.getToggleRowExpandedProps() as TableExpandedToggleProps & {
              onClick: (e: MouseEvent) => void;
            };

          return (
            <Flex
              {...rest}
              onClick={
                // @TODO explicit MouseEvent<HTMLDivElement> type shoud not be
                // required.
                // See: https://github.com/microsoft/TypeScript/issues/44596
                (e: MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  onClick(e);
                }
              }>
              {row.isExpanded ? (
                row.original.expander
              ) : (
                <Icon icon="arrowRight" size="space20" color="blue500" />
              )}
            </Flex>
          );
        } else return null;
      },
    },
  ];

  return columns;
};

export const expandInfos = {
  visible: { height: 'auto', transition: { duration: 0.5 }, opacity: 1 },
  hidden: { height: 0, opacity: 0 },
  onExit: { height: 0, transition: { duration: 0.5 }, opacity: 0 },
};

export const calculateTotalTaskAndOverdue = (
  organization?: OrganizationsInsightsDetails
) => ({
  totalTasks:
    (organization?.toApprove?.total || 0) +
    (organization?.toExport?.total || 0) +
    (organization?.toProcess?.total || 0),
  overdue:
    (organization?.toApprove?.overdue || 0) +
    (organization?.toExport?.overdue || 0) +
    (organization?.toProcess?.overdue || 0),
  slug: organization?.organization?.slug ?? '',
  membershipId: organization?.membership?.id ?? '',
  taskBreakdown: {
    toProcess: organization?.toProcess,
    toApprove: organization?.toApprove,
    toExport: organization?.toExport,
    monitoringMyRequests: {
      toApprove: organization?.monitoringMyRequests?.toApprove,
    },
  },
  expander: <Icon icon="caretDown" size="space20" color="blue500" />,
});
