import { getMemberships } from 'components/Comment/gql';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  Locale,
  useForceUpdateMembershipMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { useSSO } from 'orgConfig/sso/sso';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import useRouter from 'use-react-router';
import { zodResolver } from 'utils/zodFormValidation';
import { absenceFormErrorMessages } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/absenceFormErrorMessages';
import {
  AbsenceFormSchemaOptions,
  absenceFormSchema,
} from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/absenceFormSchema';
import { useCheckCircularSubstitutionDependency } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/checkCircularSubstitutionDependency';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { usePermissionsForTeamMembers } from 'views/Settings/TeamMembers/hooks/usePermissionsForTeamMembers';
import { useTeamMemberAbsenceUpdate } from 'views/Settings/TeamMembers/hooks/useTeamMemberAbsenceUpdate';
import { getApproversQuery } from 'views/Settings/TeamMembers/queries';
import {
  isInvolvedResponse,
  isOnlyAdminResponse,
  isPendingApprovalsResponse,
  isSuccess,
} from 'views/Settings/TeamMembers/responses';
import { scrollToInvolvementInfo } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/components/MembershipInvolvement/MembershipInvolvement';
import { useDeactivateMembership } from 'views/Settings/TeamMembers/useDeactivateMembership';
import { useForceDeactivateMembership } from 'views/Settings/TeamMembers/useForceDeactivateMembership';
import { LocalMembershipStatus } from 'views/Settings/TeamMembers/UsersListView';
import { useUpdateMembership } from 'views/Settings/TeamMembers/useUpdateMembership';
import {
  generateTeamMembersPath,
  getUserInfo,
  sanitizeUpdateFormData,
} from 'views/Settings/TeamMembers/utils';
import { useGetTeams } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { useEcmDocumentsInvolvementCount } from '../../components/MembershipInvolvement/hooks/useEcmDocumentsInvolvement';
import { ToastMessage } from '../../components/ToastMessage';
import { RolesStep } from '../../invite/InviteNew/RolesStep/RolesStep';
import { RolesFormOutput } from '../../invite/InviteNew/RolesStep/utils';
import { teamMemberFormErrorMessages } from '../../teamMemberFormErrorMessages';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
  teamMemberFormSchema,
} from '../../teamMemberFormSchema';
import {
  ActiveTeamMemberForm,
  TeamMemberFormDirtyFields,
  TeamMemberWithAbsenceFormOutput,
} from './ActiveTeamMemberForm';

type Props = {
  closeDrawer: () => void;
  loading?: boolean;
  selectedUser: User;
};

const ACTIVE_TEAM_MEMBER_FORM_ID = 'active-team-member';

export const ActiveFormContainer = ({
  closeDrawer,
  loading,
  selectedUser,
}: Props) => {
  const {
    history,
    match: {
      url,
      path,
      params: { organizationSlug },
    },
  } = useRouter<{ organizationSlug: string }>();

  const sso = useSSO();
  const { canModifyPersonalInfo, canModifyMemberRoles } =
    usePermissionsForTeamMembers();

  const [step, setStep] = useState<'teamMemberDetails' | 'teamMemberRoles'>(
    'teamMemberDetails'
  );

  const {
    defaultValues: absenceInitialValues,
    handleSubmit: handleUpdateAbsence,
    loading: absenceInitialValuesLoading,
  } = useTeamMemberAbsenceUpdate(selectedUser.id);

  const { teams, loading: isLoadingTeams } = useGetTeams({
    membershipIds: [selectedUser.id],
  });

  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const [involvementErrorMessage, setInvolvementErrorMessage] = useState<
    string | undefined
  >();

  const checkCircularDependency = useCheckCircularSubstitutionDependency();

  const { hasEcmInvolvement } = useEcmDocumentsInvolvementCount({
    membershipId: selectedUser.id,
  });

  const {
    updateUser,
    mutationProps: { loading: isSubmittingUpdateMembership },
  } = useUpdateMembership();

  const [
    forceUpdateMembership,
    { loading: isSubmittingForceUpdateMembership },
  ] = useForceUpdateMembershipMutation();

  const [deactivateMembership, { loading: isSubmittingDeactivateMembership }] =
    useDeactivateMembership();

  const [
    forceDeactivateMembership,
    { loading: isSubmittingForceDeactivateMembership },
  ] = useForceDeactivateMembership();

  const [
    pendingApprovalsAcknowledgeMessage,
    setPendingApprovalsAcknowledgeMessage,
  ] = useState<string | undefined>();

  const onSubmit = useCallback(
    async (
      values: TeamMemberWithAbsenceFormOutput,
      dirtyFields?: TeamMemberFormDirtyFields
    ) => {
      const { issueCreditCard, ...userData } = values;
      const hasAbsenceChanged =
        dirtyFields?.fromDate ||
        dirtyFields?.toDate ||
        dirtyFields?.note ||
        dirtyFields?.substitute;

      if (hasAbsenceChanged) {
        await handleUpdateAbsence(userData);
      }

      const hasTeamMemberDataChanged =
        dirtyFields?.email ||
        dirtyFields?.firstName ||
        dirtyFields?.lastName ||
        dirtyFields?.locale ||
        dirtyFields?.roles;

      if (!hasTeamMemberDataChanged) {
        closeDrawer();

        return;
      }

      try {
        // update user
        const updateMembershipResult = await updateUser(selectedUser.id, {
          firstName: values.firstName,
          lastName: values.lastName,
          roles: values.roles,
          locale: values.locale,
        });

        if (isInvolvedResponse(updateMembershipResult)) {
          setInvolvementErrorMessage(
            t('settings.teamMembers.form.involvement.headerErrorUpdate')
          );
          setInitialValues(userData);
        } else if (isOnlyAdminResponse(updateMembershipResult)) {
          error(t('settings.teamMembers.errors.orgShouldHaveAdmin'));
          setInitialValues(userData);
        } else if (isPendingApprovalsResponse(updateMembershipResult)) {
          setPendingApprovalsAcknowledgeMessage(
            t(
              'settings.teamMembers.form.involvement.hasPendingApprovalsMessage',
              updateMembershipResult
            )
          );
          setInitialValues(userData);
        } else if (isSuccess(updateMembershipResult)) {
          // update success
          success(t('settings.teamMembers.actions.userUpdated'));

          closeDrawer();
        }
      } catch (e) {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        error(t(`${(e as Error).message}`));

        return e;
      }

      return;
    },
    [
      closeDrawer,
      error,
      handleUpdateAbsence,
      selectedUser.id,
      success,
      t,
      updateUser,
    ]
  );

  const resetForceUpdate = useCallback(() => {
    setPendingApprovalsAcknowledgeMessage(undefined);
  }, [setPendingApprovalsAcknowledgeMessage]);

  const counterQueries = useCounterQueries();

  const onForceUpdate = useCallback(
    async (values: TeamMemberFormOutput) => {
      const sanitizedInput = sanitizeUpdateFormData(values);
      try {
        await forceUpdateMembership({
          variables: {
            input: sanitizedInput,
            id: selectedUser.id,
          },
          refetchQueries: [
            ...counterQueries,
            {
              query: getApproversQuery,
              variables: { name: '' },
            },
            {
              query: getMemberships,
              variables: membershipsQueryFilter.Active,
            },
          ],
        });

        closeDrawer();
        resetForceUpdate();
        success(t('settings.teamMembers.actions.userUpdated'));
      } catch (e) {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        error(t(`${(e as Error).message}`));

        return e;
      }
    },
    [
      forceUpdateMembership,
      selectedUser.id,
      counterQueries,
      closeDrawer,
      resetForceUpdate,
      success,
      t,
      error,
    ]
  );

  const onDeactivateMembership = useCallback(async () => {
    try {
      const deactivateMembershipResult = await deactivateMembership(
        selectedUser.id
      );

      if (isInvolvedResponse(deactivateMembershipResult) || hasEcmInvolvement) {
        setInvolvementErrorMessage(
          t('settings.teamMembers.form.involvement.headerErrorDeactivate')
        );

        setTimeout(() => {
          scrollToInvolvementInfo();
        }, 250);
      } else if (isOnlyAdminResponse(deactivateMembershipResult)) {
        error(t('settings.teamMembers.errors.orgShouldHaveAdmin'));
      } else if (isPendingApprovalsResponse(deactivateMembershipResult)) {
        setPendingApprovalsAcknowledgeMessage(
          t(
            'settings.teamMembers.form.involvement.hasPendingApprovalsMessage',
            deactivateMembershipResult
          )
        );

        // HACK: we also set involvement error message in case the user has
        // involvement in addition to pending workflows.
        //
        // Reason: The API might not return the INVOLVED error code if the team
        // member has pending workflows but the user needs to resolve any
        // involvement *first*, before resetting any pending workflows.
        //
        // @TODO: TeamMemberDetails view should be refactored to avoid such hacks.
        setInvolvementErrorMessage(
          t('settings.teamMembers.form.involvement.headerErrorDeactivate')
        );

        setTimeout(() => {
          scrollToInvolvementInfo();
        }, 250);
      } else if (isSuccess(deactivateMembershipResult)) {
        const nameAndEmail = getUserInfo(selectedUser);
        const pathname = generateTeamMembersPath({
          organizationSlug,
          path,
          url,
          userId: selectedUser.id,
        });

        const content = (
          <ToastMessage
            history={history}
            nameAndEmail={nameAndEmail}
            pathname={pathname}
            status={LocalMembershipStatus.INACTIVE}
            translationKey="settings.teamMembers.actions.deactivatedMembership"
          />
        );

        info(content);

        closeDrawer();
      }
    } catch (e) {
      // eslint-disable-next-line candis/no-template-strings-inside-translation
      error(t(`${(e as Error).message}`));
    }
  }, [
    closeDrawer,
    deactivateMembership,
    error,
    hasEcmInvolvement,
    history,
    info,
    organizationSlug,
    path,
    selectedUser,
    t,
    url,
  ]);

  const onForceDeactivateMembership = useCallback(async () => {
    try {
      await forceDeactivateMembership(selectedUser.id);
      const nameAndEmail = getUserInfo(selectedUser);
      const pathname = generateTeamMembersPath({
        organizationSlug,
        path,
        url,
        userId: selectedUser.id,
      });

      const content = (
        <ToastMessage
          history={history}
          nameAndEmail={nameAndEmail}
          pathname={pathname}
          status={LocalMembershipStatus.INACTIVE}
          translationKey="settings.teamMembers.actions.deactivatedMembership"
        />
      );

      info(content);

      closeDrawer();
      setPendingApprovalsAcknowledgeMessage(undefined);
    } catch (err) {
      // eslint-disable-next-line candis/no-template-strings-inside-translation
      error(t(`${(err as Error).message}`));
    }
  }, [
    closeDrawer,
    error,
    forceDeactivateMembership,
    history,
    info,
    organizationSlug,
    path,
    selectedUser,
    t,
    url,
  ]);

  const userRoles = (selectedUser.membershipRoles ?? []).map(role => ({
    id: role.id,
    name: role.name,
  }));

  const [initialValues, setInitialValues] = useState<TeamMemberFormOutput>({
    firstName: selectedUser.firstName,
    lastName: selectedUser.lastName,
    locale: selectedUser.locale ?? Locale.De,
    email: selectedUser.email,
    roles: userRoles,
  });

  const isUpdatingMembership =
    isSubmittingUpdateMembership || isSubmittingForceUpdateMembership;

  const isDeactivatingMembership =
    isSubmittingDeactivateMembership || isSubmittingForceDeactivateMembership;

  const absenceFormContext: AbsenceFormSchemaOptions = {
    checkCircularSubstitutionDependency: checkCircularDependency,
    selectedUser: selectedUser,
  };

  const initialFormValues = { ...initialValues, ...absenceInitialValues };

  const form = useForm<
    TeamMemberWithAbsenceFormOutput,
    TeamMemberFormSchemaOptions
  >({
    mode: 'all',
    defaultValues: initialFormValues,
    values: initialFormValues,
    resolver: zodResolver({
      zodSchema: teamMemberFormSchema({}).and(
        absenceFormSchema(absenceFormContext)
      ),
      errorMessages: {
        ...teamMemberFormErrorMessages,
        ...absenceFormErrorMessages,
      },
    }),
  });

  const rolesValue = form.getValues('roles');
  const rolesFormDefaultValues: RolesFormOutput = {
    allAppliedRoles: rolesValue,
  };

  const handleSubmitRoles = (formData: RolesFormOutput) => {
    form.setValue('roles', formData.allAppliedRoles, {
      // It’s important to set shouldDirty to true, so that later we can tell
      // that the field has been changed
      shouldDirty: true,
      // We trigger validation to clear any existing error that the field
      // might have
      shouldValidate: true,
    });
  };

  if (step === 'teamMemberRoles') {
    return (
      <RolesStep
        defaultValues={rolesFormDefaultValues}
        onGoBack={() => setStep('teamMemberDetails')}
        onSubmit={handleSubmitRoles}
      />
    );
  }

  const showForm = !absenceInitialValuesLoading && !isLoadingTeams;

  return showForm ? (
    <FormProvider {...form}>
      <ActiveTeamMemberForm
        teams={teams}
        selectedUser={selectedUser}
        onSubmit={onSubmit}
        organizationSlug={organizationSlug}
        involvementErrorMessage={involvementErrorMessage}
        isSubmittingDeactivateMembership={isDeactivatingMembership}
        isSubmittingUpdateUser={isUpdatingMembership}
        onDeactivateMembership={onDeactivateMembership}
        onForceUpdate={onForceUpdate}
        pendingApprovalsAcknowledgeMessage={pendingApprovalsAcknowledgeMessage}
        loading={Boolean(loading)}
        onForceDeactivateMembership={onForceDeactivateMembership}
        absenceValuesLoading={absenceInitialValuesLoading}
        closeDrawer={closeDrawer}
        onShowRolesStep={() => setStep('teamMemberRoles')}
        formId={ACTIVE_TEAM_MEMBER_FORM_ID}
        readOnly={{
          firstName: sso.provides.firstName || !canModifyPersonalInfo,
          lastName: sso.provides.lastName || !canModifyPersonalInfo,
          locale: !canModifyPersonalInfo,
          roles: sso.provides.roles || !canModifyMemberRoles,
        }}
      />
    </FormProvider>
  ) : null;
};
