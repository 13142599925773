import { Box, Flex } from '@candisio/design-system';
import { ReactNode } from 'react';

export const InvoiceLayout = ({
  canUserLinkInvoice,
  children,
}: {
  canUserLinkInvoice: boolean;
  children: ReactNode;
}) => {
  return (
    <Box paddingTop="72px" paddingX="space24" height="100%">
      <Flex
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center">
        <Flex
          borderRadius="medium"
          height="100%"
          background="white"
          width="100%"
          gap="space20"
          direction="column"
          alignItems="center"
          paddingTop={canUserLinkInvoice ? 'space64' : 0}
          justifyContent={canUserLinkInvoice ? 'normal' : 'center'}>
          {children}
        </Flex>
      </Flex>
    </Box>
  );
};
