import { AppRouteParams, Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import {
  WIDGET_PREFERENCES_PROPS,
  WidgetModalTab,
} from 'views/InsightsWidgets/utils';

export const useNavigateToCreateReport = () => {
  const navigate = useNavigate();
  const orgId = useOrganizationId();
  const handleNavigateToCreateReport = useCallback(() => {
    const search = new URLSearchParams();
    search.set(WIDGET_PREFERENCES_PROPS.landingTab, WidgetModalTab.DETAILS);
    search.set(WIDGET_PREFERENCES_PROPS.onDashboard, 'true');
    navigate({
      pathname: generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.DASHBOARD_WIDGET}/:widgetId?`,
        { organizationSlug: orgId, widgetId: 'create' }
      ),
      search: search.toString(),
    });
  }, [navigate, orgId]);

  return { handleNavigateToCreateReport };
};
