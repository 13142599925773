import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation';
import { useGetReimbursementExpenseItems } from 'views/Reimbursement/hooks/useGetReimbursementExpenseItems';
import { useInitialGeneralExpenseFormValues } from 'views/Reimbursement/hooks/useInitialGeneralExpenseFormValues';
import { useInitialHospitalityExpenseFormValues } from 'views/Reimbursement/hooks/useInitialHospitalityExpenseFormValues';
import { expensesFormErrorMessages } from 'views/Reimbursement/toolkit/expensesFormErrorMessages';
import {
  ExpensesFormOutput,
  expensesFormSchema,
  ExpensesFormValues,
} from 'views/Reimbursement/toolkit/expensesFormSchema';

export const useExpenseFormsHelpers = () => {
  const { hospitalityExpenseItems, generalExpenseItems } =
    useGetReimbursementExpenseItems();

  const { defaultValues } = useInitialHospitalityExpenseFormValues({
    hospitalityExpenseItems,
  });

  const { defaultGeneralExpenses } = useInitialGeneralExpenseFormValues({
    generalExpenses: generalExpenseItems,
  });

  const defaultFormValues: ExpensesFormValues = {
    generalExpenses: defaultGeneralExpenses,
    hospitalityExpenses: defaultValues,
  };

  const formValues: ExpensesFormOutput = {
    generalExpenses: defaultGeneralExpenses,
    hospitalityExpenses: defaultValues,
  };

  const form = useForm<ExpensesFormOutput>({
    defaultValues: defaultFormValues,
    values: formValues,
    resetOptions: {
      keepErrors: true,
    },
    mode: 'all',
    shouldFocusError: false,
    resolver: zodResolver({
      zodSchema: expensesFormSchema,
      errorMessages: expensesFormErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
    }),
  });

  const { control } = form;

  const { fields, append, remove } = useFieldArray<
    ExpensesFormOutput,
    'hospitalityExpenses'
  >({
    control,
    name: 'hospitalityExpenses',
  });

  const {
    fields: generalExpensesFields,
    append: appendGeneralExpenseForm,
    remove: removeGeneralExpenseForm,
  } = useFieldArray<ExpensesFormOutput, 'generalExpenses'>({
    control,
    name: 'generalExpenses',
  });

  return {
    forms: fields,
    formMethods: form,
    onAppendForm: append,
    onRemoveForm: remove,
    onAppendGeneralExpense: appendGeneralExpenseForm,
    onRemoveGeneralExpenseForm: removeGeneralExpenseForm,
    generalExpenseForms: generalExpensesFields,
  };
};
