import {
  useCancelRequestChangeUserEmailMutation,
  usePendingChangeMembershipEmailQuery,
  useRequestChangeUserEmailMutation,
  useResendChangeEmailRequestVerificationEmailMutation,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { pendingChangeEmailRequestQuery } from './gql';

export const useChangeEmail = () => {
  const currentUser = useCurrentUser();

  const { data: pendingChangeMembershipEmailData } =
    usePendingChangeMembershipEmailQuery({
      variables: { id: currentUser?.id ?? '' },
    });

  const [requestChangeEmail, { loading: changeEmailLoading }] =
    useRequestChangeUserEmailMutation({
      refetchQueries: [
        {
          query: pendingChangeEmailRequestQuery,
          variables: { id: currentUser?.id },
        },
      ],
    });

  const [cancelChangeEmailRequest, { loading: cancelChangeEmailIsLoading }] =
    useCancelRequestChangeUserEmailMutation({
      refetchQueries: [currentUserQuery, pendingChangeEmailRequestQuery],
      variables: { membershipId: currentUser?.id ?? '' },
    });

  const [resendVerificationEmail, { loading: resendChangeEmailIsLoading }] =
    useResendChangeEmailRequestVerificationEmailMutation({
      variables: { membershipId: currentUser?.id ?? '' },
    });

  const newEmail =
    pendingChangeMembershipEmailData?.pendingChangeMembershipEmail?.newEmail;

  return {
    resendChangeEmailIsLoading,
    cancelChangeEmailIsLoading,
    requestChangeEmail,
    changeEmailLoading,
    cancelChangeEmailRequest,
    resendVerificationEmail,
    newEmail,
  };
};
