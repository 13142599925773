import { TruncatedText } from '@candisio/design-system';
import { TransactionCategory } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const mappedCategoriesToTranslations: Record<TransactionCategory, string> = {
  ADVERTISING_AND_MARKETING:
    'insights.filters.category.list.AdvertisingAndMarketing',
  COMPUTING_AND_SOFTWARE: 'insights.filters.category.list.ComputingAndSoftware',
  EDUCATION_AND_TRAINING: 'insights.filters.category.list.EducationAndTraining',
  ELECTRONICS_AND_IT_EQUIPMENT:
    'insights.filters.category.list.ElectronicsAndItEquipment',
  ENTERTAINMENT_AND_WELLNESS:
    'insights.filters.category.list.EntertainmentAndWellness',
  FOOD_AND_DRINKS: 'insights.filters.category.list.FoodAndDrinks',
  GIFTS_AND_VOUCHERS: 'insights.filters.category.list.GiftsAndVouchers',
  MATERIALS_AND_PACKAGING:
    'insights.filters.category.list.MaterialsAndPackaging',
  OFFICE_SUPPLIES_AND_EQUIPMENT:
    'insights.filters.category.list.OfficeSuppliesAndEquipment',
  OTHER: 'insights.filters.category.list.Other',
  SERVICES: 'insights.filters.category.list.Services',
  TRAVEL_AND_ACCOMMODATION:
    'insights.filters.category.list.TravelAndAccommodation',
};

export const CategoryCell = ({ value }: { value?: TransactionCategory }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  if (!value) {
    return <div>-</div>;
  }

  return (
    <TruncatedText wordBreak="break-word">
      {t(mappedCategoriesToTranslations[value])}
    </TruncatedText>
  );
};
