import { useFullOrganization } from 'providers/OrganizationProvider';
import { Key, useState } from 'react';
import { useGetFilterItems } from '../hooks/useGetFilterItems';
import { useGetStatements } from '../hooks/useGetStatements';
import { StatementsList } from './StatementsList';

export const StatementsListContainer = () => {
  const currentYear = new Date().getFullYear();
  const organization = useFullOrganization();
  const paymentFrequency = organization?.creditCardsDetails?.paymentFrequency;
  const [yearFilter, setYearFilter] = useState(currentYear);
  const { onLoadMore, statements, loading } = useGetStatements({
    filter: yearFilter,
  });

  const { filterItems } = useGetFilterItems({ year: currentYear });

  const handleFilterClick = (value: Key[]) => {
    setYearFilter(value.length ? (value[0] as number) : yearFilter);
  };

  const menuButton = {
    text: String(yearFilter),
    onClick: handleFilterClick,
    actionValue: [yearFilter],
    menuButtonItems: filterItems,
  };

  return (
    <StatementsList
      isLoading={loading}
      statements={statements}
      paymentFrequency={paymentFrequency}
      menuButtons={[menuButton]}
      onEndReached={onLoadMore}
    />
  );
};
