import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useSaveTableConfigurationsMutation } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { useCallback } from 'react';

interface SaveTableConfigurationsParams {
  configurations: Configuration[];
  tableId: string;
}

interface UseSaveTableConfigurationsReturn {
  saveTableConfigurations: ({
    configurations,
    tableId,
  }: SaveTableConfigurationsParams) => Promise<{
    status: 'error' | 'success';
  }>;
  isSavingConfigurations: boolean;
}

export const useSaveTableConfigurations =
  (): UseSaveTableConfigurationsReturn => {
    const user = useCurrentUser();

    const [saveTableConfigurations, { loading }] =
      useSaveTableConfigurationsMutation({
        refetchQueries: [
          {
            query: currentUserQuery,
            variables: {
              id: user?.id,
            },
          },
        ],
      });

    const handleSaveTableConfigurations: UseSaveTableConfigurationsReturn['saveTableConfigurations'] =
      useCallback(
        async ({ configurations, tableId }: SaveTableConfigurationsParams) => {
          const sanitisedData = configurations.map(config => ({
            id: config.id,
            label: config.label,
            ...(config.isFixed === true
              ? { isFixed: true, isVisible: true }
              : { isVisible: config.isVisible }),
          }));

          const response = await saveTableConfigurations({
            variables: { input: { id: tableId, columns: sanitisedData } },
          });

          if (response.errors?.length) {
            return { status: 'error' };
          }

          return { status: 'success' };
        },
        [saveTableConfigurations]
      );

    return {
      saveTableConfigurations: handleSaveTableConfigurations,
      isSavingConfigurations: loading,
    };
  };
