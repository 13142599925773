import { z } from 'zod';

const nonEmptyString = z.string().trim().min(1).max(100);

export const vendorNameFormSchema = z.object({
  vendorName: nonEmptyString,
});

export type VendorNameFormValues = z.infer<typeof vendorNameFormSchema>;

export const errorMessages = {
  vendorName: {
    label: 'createPaymentContainer.vendorName.label',
  },
};
