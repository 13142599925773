import { Button, Flex, Grid, Item, SelectField } from '@candisio/design-system';
import { useEffect, useState, Key } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { DateRangeProps, QUARTER_OPTION } from '../../types';
import { yearOptions } from './Year';

const optionsQuarters = [
  QUARTER_OPTION.Q1,
  QUARTER_OPTION.Q2,
  QUARTER_OPTION.Q3,
  QUARTER_OPTION.Q4,
];

export const SelectionQuarter = ({
  setReadableDateOption,
  invoiceDate,
}: DateRangeProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const currentYear = new Date().getFullYear();

  const [initialQuarter, initialYear] = invoiceDate?.values.readable
    ? invoiceDate?.values.readable.split(' ')
    : [QUARTER_OPTION.Q1, currentYear];

  const [selectedYear, setSelectedYear] = useState(String(initialYear));
  const [selectedQuarter, setSelectedQuarter] = useState<QUARTER_OPTION>(
    initialQuarter as QUARTER_OPTION
  );

  useEffect(() => {
    setReadableDateOption(`${selectedQuarter}-${selectedYear}`);
  }, [setReadableDateOption, selectedQuarter, selectedYear]);

  const handleSelectionChangeYear = (_selectedYear: Key) => {
    setSelectedYear(_selectedYear as string);
    setReadableDateOption(`${selectedQuarter}-${_selectedYear}`);
  };

  return (
    <Grid templateColumns="33% auto" zIndex={1}>
      <SelectField
        label={t('dateRangePicker.inputs.year')}
        select={{
          children: item => <Item>{item.name}</Item>,
          items: yearOptions,
          name: 'year',
          onSelectionChange: handleSelectionChangeYear,
          defaultSelectedKey: selectedYear,
        }}
      />
      <Flex
        gap="space8"
        alignItems="center"
        justifyContent="center"
        top="space8">
        {optionsQuarters.map(key => (
          <Button
            key={key}
            size="small"
            variant="secondary"
            isPressed={key === selectedQuarter}
            onClick={() => {
              setSelectedQuarter(key);
              setReadableDateOption(`${key}-${selectedYear}`);
            }}>
            {key}
          </Button>
        ))}
      </Flex>
    </Grid>
  );
};
