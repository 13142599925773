import { TruncatedText } from '@candisio/design-system';
import { ImportPreviewTableData } from 'components/ImportPreviewTable/types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { ValidationErrors } from 'views/Settings/BookingAccounts/components/BookingAccountImport/utils/parseBookingAccountCSV';

export const GeneralLedgerAccountsNumber = ({
  value,
}: CellProps<ImportPreviewTableData>) => {
  const { friendlyFormatGLA } = useAccountingNumberFormatters();
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const accountNumber = friendlyFormatGLA(value.value.toString());

  if (value?.errors.includes(ValidationErrors.IS_DUPLICATE)) {
    return (
      <TruncatedText>
        {t('import.preview.errors.duplicate.tableText', {
          accountNumber,
        })}
      </TruncatedText>
    );
  }

  if (value?.errors.includes(ValidationErrors.ACCOUNT_NUMBER_MISSING)) {
    return (
      <TruncatedText>
        {t('import.preview.errors.accountNumberMissing.tableText')}
      </TruncatedText>
    );
  }

  if (value?.errors.includes(ValidationErrors.TOO_LONG)) {
    return (
      <TruncatedText>
        {t('import.preview.errors.tooLong.tableText', {
          accountNumber,
        })}
      </TruncatedText>
    );
  }

  return <TruncatedText>{accountNumber}</TruncatedText>;
};
