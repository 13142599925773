import { ObservableQuery } from '@apollo/client';
import { delay } from 'lodash';

const REFETCH_DELAY = 500;

// We need to delay the refetching of the sensitive contracts query because
// the BE does not update the list immediately sometimes
export const delayRefetchedSensitiveContractsQueries = async (
  observableQuery: ObservableQuery
) => {
  // Every other query should be refetched immediately
  const shoudImmediatelyRefetch =
    observableQuery.queryName !== 'getEcmSensitiveDocuments' &&
    observableQuery.queryName !== 'getEcmSensitiveDocumentsCount';

  if (shoudImmediatelyRefetch) {
    await observableQuery.refetch();
  } else {
    // Try to refetch and see if the result is different
    await observableQuery.refetch();
    const result = await observableQuery.result();
    const hasNewResult = observableQuery.isDifferentFromLastResult(result);

    // If the result is the same, try to refetch again after a delay
    if (!hasNewResult) {
      delay(async () => {
        await observableQuery.refetch();
      }, REFETCH_DELAY);
    }
  }
};
