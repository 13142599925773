import {
  Button,
  Flex,
  Grid,
  Separator,
  TabPanel,
  Tabs,
  useTabListState,
} from '@candisio/design-system';
import { Item } from '@react-stately/collections';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';
import { DATE_RANGE_OPTION } from '../types';
import { SelectionCustom } from './Ranges/Custom';
import { SelectionMonth } from './Ranges/Month';
import { SelectionQuarter } from './Ranges/Quarter';
import { SelectionYear } from './Ranges/Year';
import { extractInvoiceDateFromUrl } from './util';

type DateRangePanelProps = {
  onApplyDateRange: (dateRange: string) => void;
};

export const DateRangePanel = ({ onApplyDateRange }: DateRangePanelProps) => {
  const currentUser = useCurrentUser();
  const invoiceDate = extractInvoiceDateFromUrl({
    search: window.location.search,
    userId: currentUser?.id,
    forceDefault: false,
  });

  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const currentYear = String(new Date().getFullYear());
  const [readableDateOption, setReadableDateOption] = useState(currentYear);

  const shared = { setReadableDateOption };
  const tabs = [
    {
      key: DATE_RANGE_OPTION.YEAR,
      title: t('dateRangePicker.options.year'),
      children: (
        <SelectionYear
          {...shared}
          invoiceDate={
            invoiceDate.type === DATE_RANGE_OPTION.YEAR
              ? invoiceDate
              : undefined
          }
        />
      ),
    },
    {
      key: DATE_RANGE_OPTION.QUARTER,
      title: t('dateRangePicker.options.quarter'),
      children: (
        <SelectionQuarter
          {...shared}
          invoiceDate={
            invoiceDate.type === DATE_RANGE_OPTION.QUARTER
              ? invoiceDate
              : undefined
          }
        />
      ),
    },
    {
      key: DATE_RANGE_OPTION.MONTH,
      title: t('dateRangePicker.options.month'),
      children: (
        <SelectionMonth
          {...shared}
          invoiceDate={
            invoiceDate.type === DATE_RANGE_OPTION.MONTH
              ? invoiceDate
              : undefined
          }
        />
      ),
    },
    {
      key: DATE_RANGE_OPTION.CUSTOM,
      title: t('dateRangePicker.options.custom'),
      children: (
        <SelectionCustom
          {...shared}
          invoiceDate={
            invoiceDate.type === DATE_RANGE_OPTION.CUSTOM
              ? invoiceDate
              : undefined
          }
        />
      ),
    },
  ];

  const state = useTabListState({
    defaultSelectedKey: invoiceDate.type || DATE_RANGE_OPTION.YEAR,
    items: tabs,
    children: item => (
      <Item key={item.key} textValue={item.title} title={item.title}>
        {item.children}
      </Item>
    ),
  });

  return (
    <Grid gap="space24" background="white" width="20rem">
      <Tabs aria-label={t('dateRangePicker.options.ariaLabel')} state={state}>
        <></>
      </Tabs>
      <TabPanel key={state.selectedItem?.key} state={state} />
      <Separator color="gray200" />
      <Flex justifyContent="center">
        <Button
          variant="tertiary"
          onClick={() => {
            onApplyDateRange(readableDateOption);
          }}>
          {t('dateRangePicker.applyChangeCTA')}
        </Button>
      </Flex>
    </Grid>
  );
};
