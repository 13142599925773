import { Flex, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { ImportHistoryItem } from 'components/HistoryList/HistoryList';
import { DocumentDataFileStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { getFileAvailableUntilDate } from 'views/DocumentDownloadHistory/utils';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';

interface FileNameProps {
  item: ImportHistoryItem;
  queryString: string;
}

export const DownloadTitle = ({ item, queryString }: FileNameProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ARCHIVE);
  const timezone = useOrganizationTimezone();
  const availableTo = getFileAvailableUntilDate(timezone, item.createdAt);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const { fileType, isExpired } = item;
  const fileName = item.file?.name;
  const status = item.status;

  const isDownloadProcessing = status === DocumentDataFileStatus.Processing;
  const hasFailed = status === DocumentDataFileStatus.Failed;

  if (hasFailed) {
    return (
      <>
        <Text
          width="max-content"
          fontSize="basic"
          color="red600"
          lineHeight="paragraph"
          {...triggerProps}
          ref={triggerRef}>
          {t('download.history.list.failed.title')}
        </Text>
        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {t('download.history.list.failed.tooltip')}
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <Flex
      as="p"
      color="gray800"
      fontSize="basic"
      fontWeight="semibold"
      lineHeight="paragraph"
      gap="space4">
      {fileName && !isDownloadProcessing && (
        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          style={{ textOverflow: 'ellipsis' }}>
          {renderQueryHighlight({
            value: fileName,
            queryString: queryString,
          })}
        </Text>
      )}

      {isDownloadProcessing && (
        <Text color="gray600" fontWeight="regular">
          {t('download.history.list.pending')}
        </Text>
      )}

      {isExpired && (
        <Text color="red700" fontWeight="regular">
          {t('download.history.list.fileExpired')}
        </Text>
      )}

      {fileType === 'ZIP' && !isExpired && !isDownloadProcessing && (
        <Text color="gray600" fontWeight="regular" whiteSpace="nowrap">
          {t('download.history.list.fileAvailableUntil', {
            date: availableTo.slice(0, 5),
          })}
        </Text>
      )}
    </Flex>
  );
};
