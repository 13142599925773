import { DocumentCurrency } from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { formatDecimalToIntegerAmount } from 'hooks/useMoneyFormatter';
import { BaseHospitalityOutput } from '../toolkit/expensesFormSchema';

export const sanitizeHospitalityFormInput = (
  values: BaseHospitalityOutput
) => ({
  fileIds: values.files.map(file => file.id),
  expenseDate: getDateConverted().dateStringToDateTimeString(
    values.dayOfExpense
  ),
  location: values.location,
  title: values.reason,
  externalGuests: values.externalGuests ? [values.externalGuests] : null,
  internalGuests: values.internalGuests ? [values.internalGuests] : null,
  totalAmount: {
    amount: formatDecimalToIntegerAmount(values.totalAmount),
    currency: DocumentCurrency.Eur,
    precision: 2,
  },
  receiptAmount: {
    amount: formatDecimalToIntegerAmount(values.receiptAmount),
    currency: DocumentCurrency.Eur,
    precision: 2,
  },
  tipAmount: values.tipAmount
    ? {
        amount: formatDecimalToIntegerAmount(values.tipAmount),
        currency: DocumentCurrency.Eur,
        precision: 2,
      }
    : null,
});
