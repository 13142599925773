import moment from 'moment/moment';
import { CONTACT_HISTORY_START_DATE } from 'views/Contacts/consts';
import { useContactFormDefaultValues } from 'views/Contacts/ContactDetails/useContactFormDefaultValues';

export const useShowContactHistoryInfoPanel = (contactId?: string) => {
  const { contact, loading } = useContactFormDefaultValues({ contactId });

  if (loading) {
    return false;
  }

  return moment(contact?.createdAt).isBefore(
    moment(CONTACT_HISTORY_START_DATE)
  );
};
