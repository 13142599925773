import { TFunction } from 'react-i18next';
import { z } from 'zod';

const DIGITS_REGEX = /^[0-9]*$/;
const ONLY_ZEROS_DIGITS = /^(0)\1{3,}$/;

export const creditCardIdSchema = ({ t }: { t: TFunction }) =>
  z.object({
    creditCardId: z
      .string()
      .regex(DIGITS_REGEX, {
        message: t('common:validation.fields.number.digit'),
      })
      .refine(val => !ONLY_ZEROS_DIGITS.test(val), {
        message: t('common:validation.fields.number.zeroRepeated'),
      }),
  });

export const errorMessages = {
  creditCardId: {
    label: 'creditCardExportingSap.creditCardId.textInput.ariaLabel',
  },
};
