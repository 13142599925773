import {
  Box,
  Button,
  Grid,
  Popover,
  Text,
  usePopover,
} from '@candisio/design-system';
import { PurchaseOrderType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export type CustomBookingItem = 'Freight' | 'Packaging';

interface AddNewSplitActionProps {
  onClick: (item: CustomBookingItem | undefined) => void;
  purchaseOrderType: PurchaseOrderType;
}

export const NewSplitForPurchaseOrders = ({
  onClick,
  purchaseOrderType,
}: AddNewSplitActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover({
      placement: 'top',
    });

  if (purchaseOrderType === PurchaseOrderType.Service) return null;

  return (
    <Box>
      <Button
        icon="plus"
        width="100%"
        variant="secondary"
        {...triggerProps}
        ref={triggerRef}>
        {t('actions.addNewSplitButton')}
      </Button>
      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="275px"
          padding="space16"
          paddingTop="space12"
          title={t('popoverTitle')}>
          <Grid gap="space16">
            <Grid templateColumns="1fr auto">
              <Text>{t('popoverTitle')}</Text>
              <Button
                onClick={close}
                icon="close"
                size="xsmall"
                variant="tertiary"
              />
            </Grid>
            <Grid gap="space8">
              <Button
                variant="secondary"
                onClick={() => {
                  onClick('Packaging');
                  close();
                }}>
                {t('packaging')}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  onClick('Freight');
                  close();
                }}>
                {t('freight')}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </Box>
  );
};
