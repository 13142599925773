import { Filters } from 'react-table';

export const prepareTableFiltersForUrl = <TableData extends object>(
  filters: Filters<TableData>
) => {
  if (!filters.length) {
    return null;
  }

  const mapFiltersForUrl = filters.reduce((obj, item) => {
    return { ...obj, [item.id]: item.value };
  }, {});

  return mapFiltersForUrl;
};
