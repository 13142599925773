import { Box, Flex, Grid, Text, TruncatedText } from '@candisio/design-system';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { PurchaseOrderDetailsCardProps } from './utils';

export const PurchaseOrderDetailsCard = ({
  purchaseOrder,
  contextMenu,
}: PurchaseOrderDetailsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { documentMoneyFormatter } = useMoneyFormatter();

  return (
    <Flex
      gap="space8"
      padding="space16 space12"
      borderRadius="medium"
      direction="column"
      background="gray0"
      boxShadow="noShadow">
      <Text>{t('detailsCard.title')}</Text>
      <Box position="absolute" top={0} right="space8">
        {contextMenu}
      </Box>
      <Grid gap="space8">
        <Flex
          gap="space2"
          borderRadius="medium"
          background="gray200"
          direction="column"
          paddingY="space8">
          <Flex direction="column" padding="space5" width="100%">
            {typeof purchaseOrder?.contact === 'string' && (
              <TruncatedText maxWidth="28ch" fontWeight="semibold">
                {purchaseOrder?.contact}
              </TruncatedText>
            )}
            <Text>{purchaseOrder?.orderNumber}</Text>
          </Flex>
        </Flex>
        <Flex direction="column" gap="space12">
          <Flex
            direction="row"
            gap="space12"
            justifyContent="space-between"
            fontWeight="semibold">
            {purchaseOrder?.postingDate && (
              <Text>{DateFormatters.compact(purchaseOrder?.postingDate)}</Text>
            )}
            {purchaseOrder?.grossAmount && (
              <Text>{documentMoneyFormatter(purchaseOrder?.grossAmount)}</Text>
            )}
          </Flex>
        </Flex>
      </Grid>
    </Flex>
  );
};
