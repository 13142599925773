import { WatchQueryFetchPolicy } from '@apollo/client';
import { useCreditCardLimitOrgQuery } from 'generated-types/graphql.types';

interface UseCreditCardLimitsParams {
  fetchPolicy?: WatchQueryFetchPolicy;
}

export const useCreditCardLimits = ({
  fetchPolicy = 'cache-first',
}: UseCreditCardLimitsParams = {}) => {
  const { data, loading } = useCreditCardLimitOrgQuery({ fetchPolicy });

  const total = data?.organization?.creditCardsDetails?.totalLimit.value;
  const available =
    data?.organization?.creditCardsDetails?.availableLimit.value;

  const currency =
    data?.organization?.creditCardsDetails?.availableLimit.currency;

  const precision =
    data?.organization?.creditCardsDetails?.availableLimit.precision;

  if (loading) {
    return { data: null, loading };
  }

  if (total === null || available === null) {
    console.error('Error when fetching credit card organization limits');

    return { data: null, loading };
  }

  return {
    data: {
      total,
      available,
      currency,
      precision,
    },
    loading,
  };
};
