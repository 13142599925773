import { Text, Icon, Grid } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CardAccounting } from 'views/CreditCards/types';

export const CardAccountingCell = ({
  cardAccounting,
}: {
  cardAccounting: CardAccounting;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid
      autoFlow="column"
      gap="space8"
      alignItems="center"
      width="fit-content">
      {cardAccounting === 'Active' && (
        <>
          <Icon icon="checkCircle" color="green500" size="space18" />
          <Text fontSize="basic">
            {t('cardManagerView.table.columns.cardAccountingFilter.active')}
          </Text>
        </>
      )}
      {cardAccounting === 'Not_Active' && <Text fontSize="basic">-</Text>}
    </Grid>
  );
};
