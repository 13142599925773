import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useAvailableDocumentCategoriesQuery } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useSap } from 'orgConfig/sap';
import { useWatch } from 'react-hook-form';

export const useProcessingFormTypeFieldWarning = () => {
  const { data: availableDocumentCategoriesData } =
    useAvailableDocumentCategoriesQuery();

  const sap = useSap();
  const datev = useDatev();

  const [type] = useWatch<ProcessingFormValues, ['type']>({ name: ['type'] });

  if (!type || sap.isActiveIntegration) {
    return null;
  }

  const availableDocumentCategories =
    availableDocumentCategoriesData?.availableDocumentCategories ?? [];

  const selectedDocumentCategory = availableDocumentCategories.find(
    c => c.documentType === type
  );

  const bdsConnected = datev.bdsConnected;

  const isSelectedDocumentCategoryRds1_0Exportable =
    selectedDocumentCategory?.isRds1_0Exportable;

  const isSelectedDocumentCategoryBdsExportable =
    selectedDocumentCategory?.isBdsExportable;

  const rdsConnected = datev.rdsConnected;

  return (
    (bdsConnected && !isSelectedDocumentCategoryBdsExportable) ||
    (rdsConnected && !isSelectedDocumentCategoryRds1_0Exportable)
  );
};
