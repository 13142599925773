import { defaultTheme, Grid } from '@candisio/design-system';
import { motion } from 'framer-motion';

export const MotionNavigation = motion(Grid);
export const MotionOrganizationDetails = motion(Grid);
export const MotionOrganizationSwitcher = motion(Grid);

export const navigationVariants = {
  open: {
    backgroundColor: defaultTheme.colors.gray0,
    width: '292px',
  },
  closed: {
    backgroundColor: 'transparent',
    width: '96px',
  },
};

export const navigationTransition = {
  type: 'easeInOut',
  duration: 0.3,
};

export const organizationSwitcherVariants = {
  open: {
    width: '260px',
  },
  closed: {
    width: '64px',
  },
};

export const organizationDetailsVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};
