import { Flex, Skeleton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CreditCardLoading } from '../../../CreditCard/CreditCardLoading';
import { Title, TopRowWrapper, Wrapper } from '../../styles';

export const Loading = () => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Wrapper>
      <Flex gap="1rem">
        <Flex direction="column">
          <TopRowWrapper>
            <Title>{tTransactions('carousel.title')}</Title>
          </TopRowWrapper>
          <CreditCardLoading />
        </Flex>
        <Flex direction="column" flexGrow={1}>
          <TopRowWrapper>
            <Flex
              justifyContent="space-between"
              width="100%"
              style={{ paddingTop: '0.5rem' }}>
              <Skeleton
                width="10rem"
                height="2rem"
                style={{ paddingTop: '0.5rem' }}
              />
              <Skeleton
                width="7rem"
                height="2rem"
                style={{ paddingTop: '0.5rem' }}
              />
            </Flex>
          </TopRowWrapper>
          <Flex direction="column" justifyContent="space-between" height="100%">
            <Skeleton width="100%" height="5rem" />
            <Flex direction="column" gap="0.5rem">
              <Skeleton width="100%" height="36px" />
              <Skeleton width="100%" height="36px" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
