import { FilterOptionsAccessor } from '@candisio/design-system';
import { ImportPreviewTableData } from 'components/ImportPreviewTable/types';
import { CostCenterTypes } from 'generated-types/graphql.types';
import { UseFormSetError } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { MAX_NUMBER_OF_COST_CENTERS } from './constants';
import { SelectFileFormValues } from './CostCenterImport.schema';
import { CSVMaxCostCentersError, CostCentersWithoutCodeError } from './errors';
import {
  CostCenterImportState,
  ImportSteps,
  SetCostCenters,
  ShowPreview,
  SetImportStep,
  UpdateFilters,
  Reset,
} from './types';

export const initialState: CostCenterImportState = {
  costCenterType: CostCenterTypes.CostCenter,
  importStep: ImportSteps.SelectFile,
  isDatevFormat: true,
  parsedCostCenters: [],
  previewFilters: null,
  selectedFile: null,
  didCheckDuplicates: false,
};

export const reducer = (
  currentState: CostCenterImportState,
  update: SetCostCenters | ShowPreview | SetImportStep | UpdateFilters | Reset
) => {
  switch (update.action) {
    case 'SET_COST_CENTERS':
      return {
        ...currentState,
        parsedCostCenters: update.payload.parsedCostCenters,
        costCenterType: update.payload.costCenterType,
        isDatevFormat: update.payload.isDatevFormat,
        selectedFile: update.payload.selectedFile,
        didCheckDuplicates: true,
      };
    case 'SHOW_PREVIEW':
      return {
        ...currentState,
        importStep: ImportSteps.Preview,
      };
    case 'UPDATE_FILTERS':
      return {
        ...currentState,
        previewFilters: update.payload.filters,
      };
    case 'BACK':
      return {
        ...currentState,
        importStep: ImportSteps.SelectFile,
      };
    case 'RESET':
      return initialState;
    default:
      return currentState;
  }
};

export const getFilterOptions = (
  costCentersImportData: any[]
): FilterOptionsAccessor<ImportPreviewTableData> => ({
  code: {
    data: costCentersImportData.map(item => ({
      id: item.code ?? '',
      label: item.code ?? '',
    })),
  },
  name: {
    data: costCentersImportData.map(item => ({
      id: item.name ?? '',
      label: item.name ?? '',
    })),
  },
});

export const handleResponseErrors = (
  error: any,
  setError: UseFormSetError<SelectFileFormValues>,
  t: TFunction<'settings'>
) => {
  if (error instanceof CSVMaxCostCentersError) {
    return setError('file', {
      message: t(
        'settings.costCenter.wizardModal.fileSelectionStep.validation.fileHasTooManyRecords',
        { recordsCountLimit: MAX_NUMBER_OF_COST_CENTERS }
      ),
    });
  }

  if (error instanceof CostCentersWithoutCodeError) {
    return setError('file', {
      message: t(
        'settings.costCenter.wizardModal.fileSelectionStep.validation.fileHasEmptyCostCenterCodes'
      ),
    });
  }

  return setError('file', {
    message: t(
      'settings.costCenter.wizardModal.fileSelectionStep.validation.csvParseError'
    ),
  });
};
