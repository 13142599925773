import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';

type FieldNames = Record<
  keyof ExpensesFormOutput['hospitalityExpenses'][number],
  string
>;

export const generateFieldNames = (index: number): FieldNames => ({
  expenseId: `hospitalityExpenses.${index}.expenseId`,
  reason: `hospitalityExpenses.${index}.reason`,
  totalAmount: `hospitalityExpenses.${index}.totalAmount`,
  receiptAmount: `hospitalityExpenses.${index}.receiptAmount`,
  files: `hospitalityExpenses.${index}.files`,
  location: `hospitalityExpenses.${index}.location`,
  tipAmount: `hospitalityExpenses.${index}.tipAmount`,
  dayOfExpense: `hospitalityExpenses.${index}.dayOfExpense`,
  externalGuests: `hospitalityExpenses.${index}.externalGuests`,
  internalGuests: `hospitalityExpenses.${index}.internalGuests`,
});
