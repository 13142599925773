import { useCurrentUser } from 'providers/CurrentUserProvider';

export const qaUtilsOverwrites = {
  integration: 'CANDIS_INTEGRATION_OVERWRITE',
  sso: {
    isActive: 'CANDIS_SSO_IS_ACTIVE',
    provides: {
      firstName: 'CANDIS_SSO_PROVIDES_FIRST_NAME',
      lastName: 'CANDIS_SSO_PROVIDES_LAST_NAME',
      roles: 'CANDIS_SSO_PROVIDES_ROLES',
    },
  },
};

export const shouldUseQaUtils = () => {
  return localStorage.getItem('CANDIS_QA_UTILS') === 'true';
};

export const auditorEmails: string[] = [];

const whiteListedUsers = [
  ...auditorEmails,
  'christoph+tcp@candis.io',
  'christoph+cc@candis.io',
  'christoph@candis.io',
];

export const useShowQaUtils = () => {
  const user = useCurrentUser();
  const isNotProd = import.meta.env.REACT_APP_STAGE !== 'prod';
  const isCandisUser = user?.email.endsWith('@candis.io');

  const isWhiteListedUser =
    user?.email && whiteListedUsers.includes(user?.email);

  if (isWhiteListedUser && isNotProd) {
    return true;
  }

  return shouldUseQaUtils() && isCandisUser && isNotProd;
};
