import { CommentCreateErrorKind } from 'generated-types/graphql.types';

export const getCommentCreateErrorTranslationKey = (
  errorKind: Partial<CommentCreateErrorKind>
) => {
  switch (errorKind) {
    case CommentCreateErrorKind.Unauthorized:
      return 'comment.creation.errors.unauthorized';
    case CommentCreateErrorKind.ResourceNotFound:
      return 'comment.creation.errors.resourceNotFound';
    case CommentCreateErrorKind.InvalidInput:
      return 'comment.creation.errors.invalidInput';
    case CommentCreateErrorKind.UnexpectedError:
      return 'comment.creation.errors.unexpected';
    default:
      return 'comment.creation.errors.unexpected';
  }
};

export const WAIT_BEFORE_REFETCHING_DOCUMENT_HISTORY_MS = 1000;

export const sendWithDelay = (
  ms: number,
  resolve: () => Promise<unknown> | void
) =>
  new Promise(res =>
    setTimeout(async () => {
      await resolve();
      res(null);
    }, ms)
  );
