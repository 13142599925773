import { Box, Button, Grid, Separator } from '@candisio/design-system';
import { Stepper } from 'components/Stepper/Stepper';
import { useUserRoles } from 'hooks/useUserRoles';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from './CreateCreditCardModalDataProvider';
import { useModalStepTitles } from './utils';
import { useModalInitialState } from './utils/utils';

interface CreateCreditCardModalHeaderProps {
  onClose: () => void;
}

export const CreateCreditCardModalHeader = ({
  onClose,
}: CreateCreditCardModalHeaderProps) => {
  const { isCardManager } = useUserRoles();
  const initialState = useModalInitialState();
  const setModalState = useCreateCreditCardModalSetState();
  const { formStep } = useCreateCreditCardModalState();
  const modal = useModalStepTitles({
    formStep,
    isCardManager,
  });

  if (formStep === 'Success') {
    return (
      <Box justifySelf="end" paddingX="space16" height="max-content">
        <Button
          icon="close"
          justifySelf="end"
          alignSelf="center"
          variant="tertiary"
          maxHeight="max-content"
          onClick={() => {
            onClose();
            setModalState(initialState);
          }}
        />
      </Box>
    );
  }

  return (
    <Grid templateRows="164px auto 1fr">
      <Grid paddingX="space24">
        <Button
          icon="close"
          justifySelf="end"
          alignSelf="center"
          variant="tertiary"
          onClick={() => {
            onClose();
            setModalState(initialState);
          }}
        />

        <Stepper
          steps={modal?.stepperTitles}
          currentStep={modal?.indexOfCurrentStep}
        />
      </Grid>
      <Separator color="gray250" />
    </Grid>
  );
};
