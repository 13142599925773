import { Flex, Text } from '@candisio/design-system';
import {
  FilterOption,
  PaginationWithSearchFilterHook,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import {
  CardIssuerCardSortField,
  CardStatus,
  GetCardsInfiniteScrollPaginationQuery,
  GetCardsInfiniteScrollPaginationQueryVariables,
  SortDirection,
  useGetPageBasedCardIssuerCardsQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { uniqBy } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCallback } from 'react';
import {
  ToolbarFilter,
  toolBarFilterParam,
} from '../CardManagerView/components/CreditCardsTable/CreditCardsTableToolbar';
import { getCardsInfiniteScrollPaginationQuery } from '../CreditCardsInsights/gql';
import { mapRecordsToFilterItem } from '../utils/utils';

const defaultSort = {
  direction: SortDirection.Asc,
  field: CardIssuerCardSortField.LabelAndRefNumQuery,
};

const sortForInputString = {
  direction: SortDirection.Desc,
  field: CardIssuerCardSortField.LabelAndRefNumQuery,
};

export const activeStatuses = [
  CardStatus.Active,
  CardStatus.LockedPin,
  CardStatus.Locked,
  CardStatus.Pending,
  CardStatus.Processing,
  CardStatus.Requested,
];

export const inactiveStatuses = [CardStatus.Expired, CardStatus.Terminated];

const mappedCardStatuses: Record<string, Array<CardStatus>> = {
  Inactive: inactiveStatuses,
  InUse: activeStatuses,
};

export const useCardNumberFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const { searchParams } = useMutateSearchParams();
  const toolbarFilter = (searchParams.get(toolBarFilterParam) ??
    'InUse') as ToolbarFilter;

  const currentUser = useCurrentUser();
  const { canManageCards } = usePermissionsForCreditCards();
  const isUserAllowedToSeeAllCards = canManageCards;

  const cardStatuses = mappedCardStatuses[toolbarFilter];

  const computeVariables = useCallback(
    (inputString?: string): GetCardsInfiniteScrollPaginationQueryVariables => ({
      input: {},
      queries: {
        labelAndRefNumQuery: inputString,
      },
      filters: {
        statuses: cardStatuses,
        membershipIds: isUserAllowedToSeeAllCards
          ? undefined
          : [currentUser?.id ?? ''],
      },
      sortBy: inputString ? sortForInputString : defaultSort,
    }),
    [cardStatuses, currentUser?.id, isUserAllowedToSeeAllCards]
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<
      GetCardsInfiniteScrollPaginationQuery,
      GetCardsInfiniteScrollPaginationQueryVariables
    >({
      query: getCardsInfiniteScrollPaginationQuery,
      queryRootKey: 'getPageBasedCardIssuerCards',
      computeVariables,
    });

  const unselectedEntries = data?.getPageBasedCardIssuerCards?.records || [];

  const { data: selectedData } = useGetPageBasedCardIssuerCardsQuery({
    variables: {
      input: { page: 0, limit: filteredValues.length },
      filters: {
        ids: filteredValues,
        statuses: cardStatuses,
      },
      sortBy: defaultSort,
    },
    skip: filteredValues.length === 0 || Boolean(searchStr),
    fetchPolicy: 'cache-and-network',
  });

  const selectedEntries =
    selectedData?.getPageBasedCardIssuerCards?.records || [];

  const mappedSelectedCreditCards = mapRecordsToFilterItem(selectedEntries);

  const mappedUnselectedCreditCards = mapRecordsToFilterItem(unselectedEntries);

  const filterOptions: FilterOption[] = [
    ...mappedSelectedCreditCards,
    ...mappedUnselectedCreditCards,
  ].map(({ cardNumber, id, label, purpose }) => {
    const labelOrPurpose = !!label ? label : purpose;

    return {
      id: id,
      label: (
        <Flex gap="space4">
          <Text>{cardNumber}</Text>
          {!!labelOrPurpose ? (
            <Text color="gray600">{`(${labelOrPurpose})`}</Text>
          ) : null}
        </Flex>
      ),
    };
  });

  return {
    filterOptions: uniqBy(filterOptions, 'id'),
    loading,
    loadMore,
    handleDebounceSearch,
  };
};
