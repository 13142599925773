import { CostCenterTypes } from 'generated-types/graphql.types';
import { ParsedCostCenter } from './parseCostCenterCSV';

export type FormData = {
  selectedFile?: File;
  costCenterType?: CostCenterTypes;
};

export interface FilterState {
  name: string[] | null;
  code: string[] | null;
}

export enum ImportSteps {
  SelectFile,
  Preview,
}

export interface CostCenterImportState {
  costCenterType: CostCenterTypes;
  didCheckDuplicates: boolean;
  importStep: ImportSteps;
  isDatevFormat: boolean;
  parsedCostCenters: ParsedCostCenter[];
  previewFilters: FilterState | null;
  selectedFile: File | null;
}

export type SetCostCenters = {
  action: 'SET_COST_CENTERS';
  payload: {
    costCenterType: CostCenterTypes;
    isDatevFormat: boolean;
    parsedCostCenters: ParsedCostCenter[];
    selectedFile: File;
  };
};

export type ShowPreview = {
  action: 'SHOW_PREVIEW';
};

export type UpdateFilters = {
  action: 'UPDATE_FILTERS';
  payload: { filters: FilterState };
};

export type SetImportStep = {
  action: 'BACK';
};

export type Reset = {
  action: 'RESET';
};

export interface CostCenterImportProps {
  previewDelay?: number;
  errorPreviewDelay?: number;
}
