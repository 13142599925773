import { ObservableQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useWindowFocus } from './useWindowFocus';

export interface UsePollingOptions {
  pollInterval?: number;
  skip?: boolean;
  startPolling: ObservableQuery['startPolling'];
  stopPolling: ObservableQuery['stopPolling'];
  /** Poll only if current tab has focus */
  pollOnlyOnFocus?: boolean;
}

/**
 * Poll for updates, but be considerate to the API.
 *
 * Only polls when the browser tab has focus.
 */
export const usePolling = ({
  pollInterval = 1000,
  skip = false,
  startPolling,
  stopPolling,
  pollOnlyOnFocus = true,
}: UsePollingOptions) => {
  const isFocused = useWindowFocus();
  const [isPolling, setIsPolling] = useState(false);

  useEffect(() => {
    if (pollOnlyOnFocus) {
      if (isFocused && !skip) {
        startPolling(pollInterval);
        setIsPolling(true);
      }
    } else if (!skip) {
      startPolling(pollInterval);
      setIsPolling(true);
    }

    return () => {
      stopPolling();
      setIsPolling(false);
    };
  }, [
    isFocused,
    pollInterval,
    pollOnlyOnFocus,
    skip,
    startPolling,
    stopPolling,
  ]);

  return { isPolling };
};
