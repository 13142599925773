import {
  Item,
  TabItem,
  TabsProps,
  useTabListState,
} from '@candisio/design-system';
import { TabListProps } from '@react-types/tabs';

export interface UseTabsOptions
  extends Omit<TabListProps<TabItem>, 'children'> {
  renderItem?: TabsProps['children'];
}

/**
 * Temporary `useTabs` hook until the design system provides something similar.
 *
 * Accepts an array of TabItems, corresponding the tabs, as well as TabListProps
 *
 * @example
 * const { tabsProps, tabPanelProps } = useTabs({
 *   items: [
 *     {
 *       key: 'first-tab',
 *       title: 'First tab',
 *     },
 *     ...
 *   ],
 *   defaultSelectedkey: 'first-tab',
 * })
 *
 * @todo Improve design system tabs API
 */
export const useTabs = ({
  renderItem = defaultRenderItem,
  items = [],
  ...rest
}: UseTabsOptions) => {
  const children = renderItem;
  const state = useTabListState({ children, items, ...rest });

  return {
    tabsProps: { children, items, state, ...rest },
    tabPanelProps: { key: state.selectedItem?.key, state },
  };
};

export const defaultRenderItem = (item: TabItem) => (
  <Item key={item.key} title={item.title} textValue={item.title}>
    {item.children}
  </Item>
);
