import { CardId } from './useLatestTransactionsByCardId';

export const getCardIds = ({ linkedCardId, replacedCardId }: CardId) => {
  if (linkedCardId && replacedCardId) {
    return [linkedCardId, replacedCardId];
  }

  if (linkedCardId) {
    return [linkedCardId];
  }

  if (replacedCardId) {
    return [replacedCardId];
  }

  return undefined;
};
