import { Grid, Text } from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { FormBlock } from 'views/Contacts/ContactDetails/ContactForm/FormBlock';
import { z } from 'zod';
import { contactFormErrorMessages } from './contactFormErrorMessages';
import {
  ContactFormOutput,
  ContactFormSchemaOptions,
  ContactFormValues,
} from './contactFormSchema';
import {
  PaymentConditionItem,
  PaymentConditionItemProps,
} from './PaymentConditionItem';

export interface SapContactFormProps {
  /** Initial form values */
  defaultValues?: ContactFormValues;
  paymentConditionItems?: PaymentConditionItemProps[];
  /** Whether the contact is active or not (archived) */
  isActive?: boolean;
}

const sapContactSchema = () => {
  const commonSchemaFields = {
    name: z.string(),
    accountsPayableNumber: z.string(),
    accountsReceivableNumber: z.string(),
    taxNumber: z.string(),
    vatId: z.string(),
    iban: z.string(),
    swiftCode: z.string(),
  };

  return z.object(commonSchemaFields);
};

export const SapContactForm = ({
  defaultValues,
  paymentConditionItems = [],
  isActive,
}: SapContactFormProps) => {
  const [t] = useTranslation();

  const form = useForm<ContactFormOutput, ContactFormSchemaOptions>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver({
      zodSchema: sapContactSchema,
      errorMessages: contactFormErrorMessages,
    }),
  });

  const readOnlyProps = {
    readOnly: true,
  };

  return (
    <FormProvider {...form}>
      <Grid as="form" gap="space32">
        {isActive && (
          <Grid
            gap="space8"
            borderRadius="medium"
            padding="space16"
            background="bluebg">
            <Text fontSize="small">
              {t('settings.contacts.details.readOnlySyncHint')}
            </Text>
          </Grid>
        )}

        <FormBlock headingKey="settings.contacts.details.edit.commonHeader">
          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="name"
            label={t('settings.contacts.details.edit.internalName.label')}
          />

          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="accountsPayableNumber"
            label={t(
              'settings.contacts.details.edit.accountsPayableNumber.label'
            )}
          />

          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="accountsReceivableNumber"
            label={t(
              'settings.contacts.details.edit.accountsReceivableNumber.label'
            )}
          />
        </FormBlock>

        <FormBlock headingKey="settings.contacts.details.edit.paymentHeader">
          <HookFormSelectField
            {...readOnlyProps}
            name="paymentCondition"
            label={t('settings.contacts.details.edit.paymentCondition.label')}
            items={[
              ...paymentConditionItems.map(
                (item: PaymentConditionItemProps) => ({
                  key: item.id,
                  children: <PaymentConditionItem {...item} />,
                  textValue: String(item.conditionNumber),
                })
              ),
            ]}
          />

          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="iban"
            label={t('settings.contacts.details.edit.iban.label')}
          />

          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="swiftCode"
            label={t('settings.contacts.details.edit.bankInfo.swiftCode')}
          />
        </FormBlock>

        <FormBlock headingKey="settings.contacts.details.edit.taxHeader">
          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="taxNumber"
            label={t('settings.contacts.details.edit.taxNumber.label')}
          />

          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="vatId"
            label={t('settings.contacts.details.edit.vatId.label')}
          />
        </FormBlock>
      </Grid>
    </FormProvider>
  );
};
