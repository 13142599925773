import { ParsedCostCenter } from '../parseCostCenterCSV';

export const normalizeCostCenters = (
  costCenters: ParsedCostCenter[],
  existingCostCenterCodes: string[]
): ParsedCostCenter[] => {
  const normalizedCostCenterFileDuplicates: string[] = [];
  const normalizedExistingCostCenterCodes =
    existingCostCenterCodes?.map(cc => cc.toLocaleLowerCase()) ?? [];

  return costCenters.map(costCenter => {
    let isDuplicate: boolean;
    let includeInImport = costCenter.includeInImport;

    if (
      normalizedExistingCostCenterCodes.includes(
        costCenter.code.toLocaleLowerCase()
      )
    ) {
      isDuplicate = true;
      includeInImport = false;
    } else if (
      normalizedCostCenterFileDuplicates.includes(
        costCenter.code.toLocaleLowerCase()
      )
    ) {
      isDuplicate = true;
      includeInImport = false;
    } else {
      isDuplicate = false;
      normalizedCostCenterFileDuplicates.push(
        costCenter.code.toLocaleLowerCase()
      );
    }

    return {
      ...costCenter,
      code: costCenter.code,
      includeInImport,
      isDuplicate,
    };
  });
};
