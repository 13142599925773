import { Box, Card, Flex, Image } from '@candisio/design-system';
import nomembersFilled from 'assets/illustration-no-member-filled.svg';
import nomembers from 'assets/illustration-no-member.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import { useExportContactsFactory } from 'views/Integrations/Export/toolkit/hooks/useExportContactsFactory';
import { View } from 'views/Integrations/Export/types';

const EmptyDataTitle = ({ children }: { children: ReactNode }) => (
  <Box fontSize="large" fontWeight="500" color="gray800" paddingY="space16">
    {children}
  </Box>
);

const EmptyDataDescription = ({ children }: { children: ReactNode }) => (
  <Box fontSize="basic" color="gray500">
    {children}
  </Box>
);

export const CenteredCard = ({
  imageSrc,
  title,
  description,
  withHeading = false,
}: {
  imageSrc: string;
  title: string;
  description: string;
  withHeading?: boolean;
}) => {
  // HACK: This makes Card appear in the center for History view instade of Table only
  const centeringAdjustment = withHeading
    ? { marginTop: '-33%' }
    : { marginTop: '-7%' };

  return (
    <Flex
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      direction="column">
      <Card style={centeringAdjustment} maxWidth="53.5ch" textAlign="center">
        <Image src={imageSrc} alt="" />
        <EmptyDataTitle>{title}</EmptyDataTitle>
        <EmptyDataDescription>{description}</EmptyDataDescription>
      </Card>
    </Flex>
  );
};

export const ContactsEmptyState = () => {
  const { view } = useContext(ExportContext);
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const titleKey =
    view === View.READY
      ? 'contacts.table.emptyState.title'
      : 'contacts.table.emptyHistory.title';

  const descriptionKey =
    view === View.READY
      ? 'contacts.table.emptyState.description'
      : 'contacts.table.emptyHistory.description';

  const title = t(titleKey);
  const description = t(descriptionKey);

  return (
    <CenteredCard
      imageSrc={nomembers}
      title={title}
      description={description}
    />
  );
};

export const MaskedContactsOverlay = () => {
  const { count } = useExportContactsFactory();
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  const title = t('contacts.table.historyOverlay.title', {
    count: count.newAndUpdated,
  });

  const description = t('contacts.table.historyOverlay.description');

  return (
    <Box
      width="100%"
      height="100%"
      position="absolute"
      zIndex={1}
      style={{
        top: '3rem',
        backdropFilter: 'blur(4px)',
        WebkitBackdropFilter: 'blur(4px)',
      }}>
      <CenteredCard
        imageSrc={nomembersFilled}
        title={title}
        description={description}
        withHeading
      />
    </Box>
  );
};
