import { Cell, CellProps } from '@candisio/design-system';
import DateFormatters from 'utils/date_formatter';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

export const DateCell = ({
  value,
  ...restProps
}: CellProps<ReimbursementTableRow, Date>) => {
  return (
    <Cell
      {...restProps}
      value={value ? DateFormatters.compact(value) : undefined}
    />
  );
};
