export const downloadFile = (url: string, name?: string) => {
  const parsedUrl = new URL(url);

  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = parsedUrl.toString();
  link.download = name || '';

  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};
