export enum MAX_DIGITS_DEPRECATED {
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
}

export const MAX_FILE_SIZE_MB = 20;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
export const MAX_NUMBER_OF_BOOKING_ACCOUNTS = 1000;
export const MAX_DIGITS_DEFAULT = MAX_DIGITS_DEPRECATED.FOUR;

export enum MAX_DIGITS {
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
}
