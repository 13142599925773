import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const useTeams = () => {
  const teamsManagementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.teamsManagement
  );

  return { isTeamsFeatureEnabled: teamsManagementFF };
};
