import { Button, Flex, Grid, Heading } from '@candisio/design-system';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../providers/LocaleProvider';
import { useOrganizationsByMembershipList } from './hooks/useOrganizationsByMembershipList';
import { useToggleNotificationRead } from './hooks/useToggleNotificationRead';
import { InAppNotificationData } from './NotificationToast';
import { OrganizationSelector } from './OrganizationSelector';
import { ALL_ORGANIZATIONS } from './utils';

export interface NotificationSummaryHeaderProps {
  selectedOrganization: string;
  onSelectionChange: (key: Key[]) => void;
  notifications: InAppNotificationData[];
}

export const NotificationSummaryHeader = ({
  notifications,
  selectedOrganization,
  onSelectionChange,
}: NotificationSummaryHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { markNotificationsAsRead } = useToggleNotificationRead();
  const currentOrgId = useOrganizationId() ?? '';

  const { loading, organizations, hasMoreThanOneOrganization } =
    useOrganizationsByMembershipList();

  const getOrganizationIds = () => {
    return selectedOrganization === ALL_ORGANIZATIONS
      ? organizations.map(({ id }) => id)
      : [selectedOrganization];
  };

  const handleMarkAllAsRead = () => {
    markNotificationsAsRead({
      organizationIds: hasMoreThanOneOrganization
        ? getOrganizationIds()
        : [currentOrgId],
    });
  };

  const showMarkAllAsReadToggle = notifications.some(({ isRead }) => !isRead);

  return (
    <Grid paddingTop="space16" gap="space4" paddingX="space16">
      <Flex
        justifyContent="space-between"
        alignItems="baseline"
        paddingLeft="space12">
        <Heading as="h2">{t('popover.title')}</Heading>
        {showMarkAllAsReadToggle && !hasMoreThanOneOrganization && (
          <Button
            icon="check"
            autoFocus
            variant="tertiary"
            onClick={handleMarkAllAsRead}
            justifySelf="end">
            {t('popover.markAllAsRead')}
          </Button>
        )}
      </Flex>
      {hasMoreThanOneOrganization && (
        <OrganizationSelector
          loading={loading}
          organizations={organizations}
          selectedOrganization={selectedOrganization}
          onMarkAllAsRead={handleMarkAllAsRead}
          onSelectionChange={onSelectionChange}
          hasMoreThanOneOrganization={hasMoreThanOneOrganization}
          showMarkAllAsReadToggle={showMarkAllAsReadToggle}
        />
      )}
    </Grid>
  );
};
