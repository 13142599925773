import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { useTranslation } from 'react-i18next';

interface TaxAmountProps {
  currency?: string;
  hasMultipleBookings?: boolean;
}

export const TaxAmount = ({
  currency,
  hasMultipleBookings,
}: TaxAmountProps) => {
  const [t] = useTranslation();

  return (
    <HookFormAmountField
      name={hasMultipleBookings ? 'taxAmount' : 'bookings.0.taxAmount'}
      currency={currency}
      label={t('document.requestApproval.inputs.taxAmount.label')}
      placeholder={t('document.requestApproval.inputs.taxAmount.placeholder')}
      readOnly
    />
  );
};
