import { AttachmentDetachedFromDocumentEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const AttachmentDetachedComponent = ({
  fileName,
}: AttachmentDetachedFromDocumentEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <div>
      {t('document.compact.history.documentAttachment.detach.success', {
        fileName: fileName,
      })}
    </div>
  );
};
