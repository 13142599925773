import {
  Avatar,
  Grid,
  Heading,
  Icon,
  Tooltip,
  useTheme,
  useTooltip,
} from '@candisio/design-system';
import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Locale } from 'generated-types/graphql.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { UserTeamsWithLinks } from 'views/AppContainer/components/Header/components/UserProfile/UserTeams/UserTeamsWithLinks';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { TeamMemberRolesFieldLegacyRoles } from '../active/TeamMemberRolesFieldLegacyRoles';
import { teamMemberFormErrorMessagesDeprecated } from '../teamMemberFormErrorMessagesDeprecated';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
  TeamMemberFormValues,
  teamMemberFormSchemaDeprecated,
} from '../teamMemberFormSchemaDeprecated';
import { PendingStatusLegacyRolesBar } from './PendingStatusLegacyRolesBar';

export interface PendingTeamMemberFormLegacyRolesProps {
  initialValues: TeamMemberFormValues;
  isSubmittingCancelInvite: boolean;
  isSubmittingResendInvite: boolean;
  isSubmittingUpdateUser: boolean;
  loading: boolean;
  onSubmit: (data: TeamMemberFormOutput) => Promise<any>;
  resendInvite: () => void;
  cancelInvite: () => void;
  selectedUser: User | null;
  formId: string;
  hide?: { firstName?: boolean; lastName?: boolean; roles?: boolean };
  teams: Team[];
}

export const PendingTeamMemberFormLegacyRoles = ({
  initialValues,
  isSubmittingCancelInvite,
  isSubmittingResendInvite,
  isSubmittingUpdateUser,
  loading,
  onSubmit,
  resendInvite,
  selectedUser,
  formId,
  hide,
  cancelInvite,
  teams,
}: PendingTeamMemberFormLegacyRolesProps) => {
  const [t] = useTranslation();
  const { colors } = useTheme();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const isSubmitting =
    isSubmittingCancelInvite ||
    isSubmittingResendInvite ||
    isSubmittingUpdateUser;

  const disabled = loading || isSubmitting;
  const locales = Object.values(Locale).map(value => ({
    // eslint-disable-next-line candis/no-template-strings-inside-translation
    children: t(`locales.${value}`),
    key: value,
  }));

  const form = useForm<TeamMemberFormOutput, TeamMemberFormSchemaOptions>({
    defaultValues: initialValues,
    context: {
      hiddenFields: hide,
    },
    mode: 'onBlur',
    resolver: zodResolver({
      zodSchema: teamMemberFormSchemaDeprecated,
      errorMessages: teamMemberFormErrorMessagesDeprecated,
    }),
  });

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        id={formId}
        onSubmit={form.handleSubmit(onSubmit)}
        gap="space24"
        templateRows="1fr auto">
        <PendingStatusLegacyRolesBar
          disabled={disabled}
          onSendInviteAgain={resendInvite}
          onCancelInvite={cancelInvite}
          sendInviteLoading={isSubmittingResendInvite}
          cancelInviteLoading={isSubmittingCancelInvite}
        />
        <Grid gap="space24">
          <AvatarUpload readonly>
            <Avatar
              size="large"
              name={selectedUser?.name}
              img={selectedUser?.avatarUrl ?? undefined}
              hasTooltip={false}
            />
          </AvatarUpload>
          <Grid
            background="gray100"
            borderRadius="8px"
            padding="space16"
            rowGap="space12"
            columnGap="space32"
            templateColumns="1fr 1fr 1fr 1fr"
            templateRows="auto auto auto">
            {hide?.firstName ? null : (
              <Grid gridRow="1" gridColumn="1/3">
                <HookFormTextField
                  label={t('settings.teamMembers.form.firstName.label')}
                  name="firstName"
                  autoFocus
                />
              </Grid>
            )}
            {hide?.lastName ? null : (
              <Grid gridRow="1" gridColumn="3/5">
                <HookFormTextField
                  name="lastName"
                  label={t('settings.teamMembers.form.lastName.label')}
                />
              </Grid>
            )}
            <Grid
              gridRow="2"
              gridColumn="1/4"
              autoFlow="column"
              gap="space20"
              alignItems="center">
              <HookFormTextField
                name="email"
                disabled
                readOnly
                label={t('settings.teamMembers.form.email.label')}
                inputProps={{
                  type: 'email',
                  style: {
                    color: colors.gray500,
                  },
                }}
              />

              <Icon
                icon="infoOutline"
                size="space18"
                {...triggerProps}
                ref={triggerRef}
              />
              {isOpen && (
                <Tooltip {...tooltipProps} ref={tooltipRef}>
                  {t('settings.teamMembers.form.email.description')}
                </Tooltip>
              )}
            </Grid>
            <Grid gridRow="3" gridColumn="1/3">
              <HookFormSelectField
                name="locale"
                label={t('settings.teamMembers.form.locale')}
                items={locales}
              />
            </Grid>
          </Grid>
          {hide?.roles ? null : (
            <Grid gap="space8">
              <Heading as="h3">{t('settings.teamMembers.form.roles')}</Heading>
              <Grid background="gray100" borderRadius="8px" padding="space16">
                <TeamMemberRolesFieldLegacyRoles />
              </Grid>
            </Grid>
          )}
          <UserTeamsWithLinks teams={teams} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};
