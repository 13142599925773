import { Color, Theme } from '@candisio/design-system';

type Variant = 'AlmostFull' | 'MedFull' | 'AlmostEmpty';

export const mapVariantToColors: Record<
  Variant,
  { tag: Color; progressBar: keyof Theme['colors'] }
> = {
  AlmostFull: {
    tag: 'green',
    progressBar: 'green500',
  },
  MedFull: {
    tag: 'yellow',
    progressBar: 'yellow500',
  },
  AlmostEmpty: {
    tag: 'red',
    progressBar: 'red500',
  },
};

const minThreshold = 25;
const medThreshold = 50;
const maximum = 100;

export const getColor: (p: number) => {
  tag: Color;
  progressBar: keyof Theme['colors'];
} = (percentage: number) => {
  if (percentage >= medThreshold && percentage <= maximum) {
    return mapVariantToColors['AlmostFull'];
  } else if (percentage >= minThreshold && percentage <= medThreshold) {
    return mapVariantToColors['MedFull'];
  } else {
    return mapVariantToColors['AlmostEmpty'];
  }
};
