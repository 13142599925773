import { Link } from '@candisio/design-system';
import { FileUploadedByEmailEventDataFragment } from 'generated-types/graphql.types';

export const FileUploadByEmailComponent = ({
  uploaderEmail,
  file,
}: FileUploadedByEmailEventDataFragment) => {
  // way to get name and email from generic "Nice Guy <nice.guy@candis.io>" value
  const [name, email] = (uploaderEmail?.[0] ?? '').split(/[<>]/g);

  return (
    <>
      {name} <Link href={`mailto:${email}`}>{email}</Link>
      <br />
      {file?.url && file.name && (
        <Link external href={file.url} download={file.name}>
          {file.name}
        </Link>
      )}
    </>
  );
};
