import { QueryResult } from '@apollo/client';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CardCredentialByCardIdQuery,
  Exact,
  GetCardIssuerCardUniqueTokenByIdQuery,
  useCardCredentialByCardIdLazyQuery,
  useGetCardIssuerCardUniqueTokenByIdLazyQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useReauthenticationDispatch } from 'providers/ReauthenticationProvider/ReauthenticationProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CardCredentialsData {
  token?: string | null;
  uniqueToken?: string | null;
}

export const useCardCredentialQuery = () => {
  const [t] = useTranslation();
  const { error } = useToastMessage();

  const reauthenticateBeforeRevealCardCredentialsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.reauthenticateBeforeRevealCardCredentials
  );

  const dispatch = useReauthenticationDispatch();

  const [cardCredentialWithReauth, responseWithReauth] =
    useCardCredentialByCardIdLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [getTokenToShowSensitiveData, responseWithoutReauth] =
    useGetCardIssuerCardUniqueTokenByIdLazyQuery({
      fetchPolicy: 'no-cache',
    });

  // We need to keep the same object identities to avoid unwwanted re-rendering and triggering of the useEffect in useCardCredentialQuery

  const tokenDetailsWithReauth = useMemo(
    () => getTokenDetails(responseWithReauth),
    [responseWithReauth]
  );

  const tokenDetailsWithoutReauth = useMemo(
    () => getTokenDetails(responseWithoutReauth),
    [responseWithoutReauth]
  );

  const sensitiveDataToken = reauthenticateBeforeRevealCardCredentialsFF
    ? tokenDetailsWithReauth
    : tokenDetailsWithoutReauth;

  const isFetchingSensitiveData = reauthenticateBeforeRevealCardCredentialsFF
    ? responseWithReauth.loading
    : responseWithoutReauth.loading;

  const fetchCardCredentials = async (cardId: string) => {
    const response = await (reauthenticateBeforeRevealCardCredentialsFF
      ? cardCredentialWithReauth({ variables: { cardId } })
      : getTokenToShowSensitiveData({ variables: { id: cardId } }));

    if (response.error?.message.length) {
      if (response?.error?.message.includes('MFA')) {
        error(t('credit-cards:dashboard.drawer.form.failureMfa'));
      } else error(t('uploads.errors.baseError'));

      return;
    }

    if (
      isGetCardIssuerCardCredentialResponse(response) &&
      response.data?.getCardIssuerCardCredential.__typename ===
        'RecentAuthnRequiredError'
    ) {
      const { requiredAuthType, validForSeconds } =
        response.data.getCardIssuerCardCredential;

      dispatch({
        action: 'showModal',
        authType: requiredAuthType,
        validForSeconds,
        onReauthenticated: fetchCardCredentials.bind(null, cardId),
      });

      return;
    }
  };

  return {
    getTokenToShowSensitiveData: fetchCardCredentials,
    sensitiveDataToken,
    isFetchingSensitiveData,
  };
};

function getTokenDetails(
  response?:
    | QueryResult<GetCardIssuerCardUniqueTokenByIdQuery, Exact<{ id: string }>>
    | QueryResult<CardCredentialByCardIdQuery, Exact<{ cardId: string }>>
): CardCredentialsData {
  if (isGetCardIssuerCardCredentialResponse(response)) {
    const result = response.data?.getCardIssuerCardCredential;

    return result?.__typename === 'CardIssuerCardCredential'
      ? result
      : { token: undefined, uniqueToken: undefined };
  }

  return {
    token: response?.data?.getCardIssuerCardById.token,
    uniqueToken: response?.data?.getCardIssuerCardById.uniqueToken,
  };
}

function isGetCardIssuerCardCredentialResponse(
  response?:
    | QueryResult<GetCardIssuerCardUniqueTokenByIdQuery, Exact<{ id: string }>>
    | QueryResult<CardCredentialByCardIdQuery, Exact<{ cardId: string }>>
): response is QueryResult<
  CardCredentialByCardIdQuery,
  Exact<{ cardId: string }>
> {
  return 'getCardIssuerCardCredential' in (response?.data ?? {});
}
