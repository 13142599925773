// default values for cache are no longer supported so we define typePolicies instead
// https://stackoverflow.com/questions/63155983/i-am-migrating-from-apollo-link-state-to-apollo-client-how-do-i-write-default
// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-read-function

export const typePolicies = {
  Query: {
    fields: {
      fileUploadModal: {
        read: (
          fileUploadModal = {
            __typename: 'FileUploadModal',
            uploadModalVisible: false,
          }
        ) => fileUploadModal,
      },
      fileUploads: { read: (fileUploads = []) => fileUploads },
    },
  },
};
