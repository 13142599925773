import { useApolloClient } from '@apollo/client';
import {
  ContactDataFragment,
  ContactQuery,
  ContactQueryVariables,
  useCreateContactMutation,
} from 'generated-types/graphql.types';
import { useState } from 'react';
import { ContactFormOutput } from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';
import { useContactMutationInput } from 'views/Contacts/ContactDetails/useContactMutationInput';
import { getContactQuery } from 'views/Contacts/queries';
import { useContactRefetchQueries } from 'views/queries/contacts';

type CreateContactResult =
  | {
      status: 'success';
      contact: ContactDataFragment;
    }
  | { status: 'error' };

type CreateContact = (
  formValues: ContactFormOutput
) => Promise<CreateContactResult>;

interface UseCreateContactReturn {
  /** Is creating contact */
  creating: boolean;
  /**  Creates a new contact and returns it */
  createContact: CreateContact;
}

/**
 * Returns a `createContact` function that creates a contact from
 * ContactFormOutput and returns the newly-created ContactDataFragment.
 */
export const useCreateContact = (): UseCreateContactReturn => {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);

  const { queries: contactRefetchQueries, evictPaginationResults } =
    useContactRefetchQueries();

  const [mutation, { loading: creating }] = useCreateContactMutation({
    refetchQueries: contactRefetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      evictPaginationResults();
    },
  });

  const { getMutateContactInput } = useContactMutationInput();

  const createContact: CreateContact = async formOutput => {
    const input = getMutateContactInput(formOutput);

    const { data: createContactData } = await mutation({
      variables: {
        input,
      },
    });

    if (createContactData?.createContact.__typename === 'ContactCreated') {
      // @TODO `createContact` mutation should return the newly-created contact.
      // For now, we maunally fetch it.
      setLoading(true);
      const { data: contactData } = await apolloClient.query<
        ContactQuery,
        ContactQueryVariables
      >({
        query: getContactQuery,
        variables: { input: { id: createContactData.createContact.id } },
      });

      setLoading(false);

      if (contactData.contact?.__typename === 'Contact') {
        return {
          status: 'success',
          contact: contactData.contact,
        };
      }
    }

    return { status: 'error' };
  };

  return { createContact, creating: creating || loading };
};
