import { Grid } from '@candisio/design-system';
import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { z } from 'zod';
import {
  ConfirmationPopoverDeprecated,
  ConfirmationPopoverDeprecatedProps,
} from '../ConfirmationPopover/ConfirmationPopoverDeprecated';

export interface AcknowledgePopoverProps
  extends Omit<
    ConfirmationPopoverDeprecatedProps,
    'confirmText' | 'closeText'
  > {
  onAcknowledged: () => void;
  title: string;
  acknowledgeText: string;
}

const schema = z.object({
  confirm: z.literal(true),
});

type AcknowledgeFormValues = z.infer<typeof schema>;

const errorMessages = {
  confirm: {
    label: 'confirmation',
  },
};

export const AcknowledgePopover = ({
  onAcknowledged,
  title,
  acknowledgeText,
  placement,
  trigger,
  closeButtonProps,
  confirmButtonProps,
}: AcknowledgePopoverProps) => {
  const [t] = useTranslation();

  const form = useForm<AcknowledgeFormValues>({
    mode: 'all',
    resolver: zodResolver({
      errorMessages,
      zodSchema: schema,
    }),
  });

  return (
    <FormProvider<AcknowledgeFormValues> {...form}>
      <Grid as="form">
        <ConfirmationPopoverDeprecated
          title={title}
          onConfirm={async () => {
            await form
              .trigger(['confirm'], { shouldFocus: true })
              .then(isValid => {
                if (isValid) {
                  form.clearErrors();
                  onAcknowledged();
                }
              });
          }}
          closeText={t('no')}
          closeButtonProps={closeButtonProps}
          confirmText={t('yes')}
          confirmButtonProps={confirmButtonProps}
          placement={placement || 'top'}
          trigger={trigger}>
          <HookFormCheckboxField name="confirm" label={acknowledgeText} />
        </ConfirmationPopoverDeprecated>
      </Grid>
    </FormProvider>
  );
};
