import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';

export const getLastSevenDaysDateRange = ({
  currentDate,
}: {
  currentDate: Date;
}) => {
  return {
    dateFrom: moment(currentDate).subtract(7, 'd').format(DATE_FORMAT),
    dateTo: moment(currentDate).format(DATE_FORMAT),
  };
};
