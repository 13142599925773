import gql from 'graphql-tag';

export const getPaginatedInsightsWidgets = gql`
  query getPaginatedInsightsWidgets {
    getPaginatedInsightsWidgets(input: {}) {
      records {
        id
        title
        preferences {
          onDashboard
          widgetId
        }
        budget {
          year
          type
          monthlyBudgets {
            january
            february
            march
            april
            may
            june
            july
            august
            september
            october
            november
            december
          }
        }
        isOwnedByCurrentUser
        filters {
          costCenters {
            id
          }
          contacts {
            id
            name {
              value
            }
          }
          costObjects {
            id
          }
          generalLedgerAccounts {
            id
          }
        }
        creator {
          id
          name
          avatarUrl
        }
        unsubscribedUsers {
          id
          name
          avatarUrl
        }
        sharedWithUsers {
          id
          name
          avatarUrl
        }
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const getInsightsWidget = gql`
  query getInsightsWidget($id: ID!) {
    getInsightsWidget(id: $id) {
      id
      title
      isOwnedByCurrentUser
      budget {
        year
        type
        monthlyBudgets {
          january
          february
          march
          april
          may
          june
          july
          august
          september
          october
          november
          december
        }
      }
      filters {
        costCenters {
          id
          readableName
        }
        contacts {
          id
          name {
            value
          }
        }
        costObjects {
          id
          readableName
        }
        generalLedgerAccounts {
          id
          readableName
        }
      }
      creator {
        id
        name
        avatarUrl
      }
      sharedWithUsers {
        id
        name
        avatarUrl
      }
    }
  }
`;

export const deleteInsightsWidgets = gql`
  mutation deleteInsightsWidgets($ids: [ID!]!) {
    deleteInsightsWidgets(ids: $ids) {
      deletedCount
    }
  }
`;

export const unsubscribeInsightsWidget = gql`
  mutation unsubscribeInsightsWidget($id: ID!) {
    unsubscribeInsightsWidget(id: $id)
  }
`;

export const updateInsightsWidgetPreferencesMutation = gql`
  mutation updateInsightsWidgetPreferences(
    $id: ID!
    $input: InsightsWidgetPreferenceInput!
  ) {
    updateInsightsWidgetPreferences(id: $id, input: $input)
  }
`;

export const refetchInsightsWidgets = [{ query: getPaginatedInsightsWidgets }];
