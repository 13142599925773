import {
  DatevConnectionType,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

export const useDatev = () => {
  const [
    bdsExportEnabledFF,
    exportProvisionsFF,
    exportDatevBdsViaIntegrationSvcFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.bdsExportEnabled,
    FEATURE_FLAGS.exportProvisions,
    FEATURE_FLAGS.exportDatevBdsViaIntegrationSvc,
  ]);

  const { data: settingsData, loading: isLoadingDatev } =
    useNew_DatevSettingsQuery();

  const sap = useSap();
  const { ft, show } = useFeatureToggle();

  const { shouldUseCandisApi } = useOtherIntegration();

  const { client, connectionType } = settingsData?.new_datevSettings ?? {};
  const hasActiveClient = Boolean(client);

  const bdsBought = bdsExportEnabledFF;

  const bdsConnected =
    hasActiveClient &&
    connectionType === DatevConnectionType.DatevRewe &&
    show(ft.BDS);

  const bdsBoughtButNotConnected = bdsBought && !bdsConnected;

  const bdsSetupCompleted = client?.isExtfsFilesAvailable ?? false;

  const rdsConnected =
    hasActiveClient && connectionType === DatevConnectionType.DatevDuo;

  const isCoreDataAvailable =
    exportDatevBdsViaIntegrationSvcFF && bdsBought && bdsConnected;

  if (sap.isActive) {
    return {
      client: null,
      isCoreDataAvailable: false,
      isLoadingDatev: false,
      bdsConnected: false,
      bdsBought: false,
      bdsSetupCompleted: false,
      bdsBoughtButNotConnected: false,
      exportProvisionsFF: false,
      exportDatevBdsViaIntegrationSvcFF: false,
      rdsConnected: false,
      hide: true,
    };
  }

  if (shouldUseCandisApi) {
    return {
      client: null,
      isCoreDataAvailable: false,
      isLoadingDatev: false,
      bdsConnected: false,
      bdsBought: false,
      bdsSetupCompleted: false,
      bdsBoughtButNotConnected: false,
      exportProvisionsFF: false,
      exportDatevBdsViaIntegrationSvcFF: false,
      rdsConnected: false,
      hide: true,
    };
  }

  return {
    client,
    isCoreDataAvailable,
    isLoadingDatev,
    bdsBought,
    bdsBoughtButNotConnected,
    bdsConnected,
    bdsSetupCompleted,
    exportProvisionsFF,
    exportDatevBdsViaIntegrationSvcFF,
    rdsConnected,
    hide: false,
  };
};
