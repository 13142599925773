import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
import { useFormContext } from 'react-hook-form';
import { TeamDetailsFormOutput } from '../../toolkit/utils/teamDetailsFormSchema';
import { Step, TeamAdmin } from './CreateTeamModalForm';
import { TeamDetails } from './TeamDetails';

interface TeamDetailsContainerProps {
  onChangeStep: (step: Step) => void;
  onSetTeamAdmin: (admin: TeamAdmin) => void;
}

export const TeamDetailsContainer = ({
  onChangeStep,
  onSetTeamAdmin,
}: TeamDetailsContainerProps) => {
  const { trigger } = useFormContext<TeamDetailsFormOutput>();

  const { users, loading } = useUsersFieldOptions({ status: 'Active' });

  const handleClick = async () => {
    const areTeamFieldsValid = await trigger(['teamName', 'teamAdmin']);

    if (!areTeamFieldsValid) {
      return;
    }

    onChangeStep(Step.TeamMembers);
  };

  return (
    <TeamDetails
      activeUsers={users}
      isLoadingActiveUsers={loading}
      onClick={handleClick}
      onSetTeamAdmin={onSetTeamAdmin}
    />
  );
};
