const filenameRegex =
  /(?<filename>.*)(-[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12})(?<extension>\..*)\s-\s(?<details>.*)/i;

export const parseExportStatusDetails = (statusDetails: string) => {
  const regexMatch = RegExp(filenameRegex).exec(statusDetails);

  if (!regexMatch?.groups) {
    return { details: statusDetails };
  }

  const { filename, extension, details } = regexMatch.groups;

  return {
    filename: `${filename}${extension}`,
    details,
  };
};
