import { Flex, Grid, Heading, Link } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { AuthenticatorAppCard } from './AuthenticatorAppMfa/AuthenticatorAppCard';
import { PhoneNumberCard } from './PhoneNumberMfa/PhoneNumberCard';
import { PhoneNumberFormProps } from './PhoneNumberMfa/PhoneNumberForm';

export interface AccountSecurityProps {
  isPhoneRemovalAllowed: PhoneNumberFormProps['isPhoneRemovalAllowed'];
  currentPhoneNumber?: PhoneNumberFormProps['currentPhoneNumber'];
  onCloseModal: () => void;
}

export const AccountSecurity = ({
  currentPhoneNumber,
  isPhoneRemovalAllowed,
  onCloseModal,
}: AccountSecurityProps) => {
  const [t] = useTranslation();

  return (
    <Grid gap="space8">
      <Flex columnGap="space16" alignItems="baseline" wrap="wrap">
        <Heading as="h3">
          {t('header.profile.tabs.update.accountSecurity.title')}
        </Heading>
        <Link
          external
          href={t('header.profile.tabs.update.accountSecurity.learnMore.url')}>
          {t('header.profile.tabs.update.accountSecurity.learnMore.text')}
        </Link>
      </Flex>

      <AuthenticatorAppCard onCloseModal={onCloseModal} />

      <PhoneNumberCard
        isPhoneRemovalAllowed={isPhoneRemovalAllowed}
        currentPhoneNumber={currentPhoneNumber}
      />
    </Grid>
  );
};
