import { Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface CreditCardSetupDetailsProps {
  limit: string | undefined;
  oneTimeCardLimit: string | undefined;
}

export const CreditCardSetupDetails = ({
  limit,
  oneTimeCardLimit,
}: CreditCardSetupDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const hasAnyLimit = Boolean(limit || oneTimeCardLimit);

  return (
    <Grid
      as="ul"
      paddingLeft={0}
      style={{ listStyle: 'none' }}
      gap="space4"
      fontSize="basic"
      color="gray600"
      lineHeight="paragraph">
      {limit && (
        <Text as="li">
          {t('teams.createTeam.creditCardSetup.bulletPoints.limitText', {
            limit,
          })}
        </Text>
      )}
      {oneTimeCardLimit && (
        <Text as="li">
          {t(
            'teams.createTeam.creditCardSetup.bulletPoints.transactionLimitText',
            {
              transactionLimit: oneTimeCardLimit,
            }
          )}
        </Text>
      )}
      {hasAnyLimit && (
        <Text as="li">
          {t('teams.createTeam.creditCardSetup.bulletPoints.cardsText')}
        </Text>
      )}
    </Grid>
  );
};
