import { Routes } from 'models';
import { TabView } from 'views/Inbox/models';

export const tabViewToUrlMap: Record<TabView, string> = {
  ARCHIVE_DOCUMENTS: Routes.ARCHIVE,
  ARCHIVE_ECM_DOCUMENTS: Routes.ECM_DOCUMENTS,
  ARCHIVE_ECM_CONTRACTS: Routes.ECM_CONTRACTS,
  INBOX_ECM_SENSITIVE_DOCUMENTS: Routes.ECM_SENSITIVE_CONTRACTS,
  ARCHIVE_TRANSACTIONS: `${Routes.ARCHIVE}${Routes.TRANSACTIONS}`,
  ARCHIVE_REIMBURSEMENTS: `${Routes.ARCHIVE}${Routes.REIMBURSEMENTS}`,
  ARCHIVE_PURCHASE_ORDERS: `${Routes.ARCHIVE}${Routes.PURCHASE_ORDERS}`,
  INBOX_DOCUMENTS: Routes.INBOX,
  INBOX_TRANSACTIONS: `${Routes.INBOX}${Routes.TRANSACTIONS}`,
  INBOX_PURCHASE_ORDERS: `${Routes.INBOX}${Routes.PURCHASE_ORDERS}`,
  ARCHIVE_GOODS_RECEIPTS: `${Routes.ARCHIVE}${Routes.GOODS_RECEIPTS}`,
  INBOX_REIMBURSEMENTS: `${Routes.INBOX}${Routes.REIMBURSEMENTS}`,
};
