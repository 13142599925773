import {
  Box,
  CustomEmptyStateProps,
  FilterOptionsAccessor,
} from '@candisio/design-system';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { noop } from 'lodash';
import { OnLoadMore } from 'providers/GraphQLProvider/Pagination/usePagination';
import { Filters, SortingRule } from 'react-table';
import { TabView } from 'views/Inbox/models';
import { ReimbursementsTable } from 'views/Reimbursement/elements/Table/ReimbursementsTable';
import { ReimbursementsTableEmptyState } from 'views/Reimbursement/elements/Table/ReimbursementsTableEmptyState';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';

interface ArchiveReimbursementsProps {
  reimbursementsCount: number;
  reimbursements: ReimbursementTableRow[];
  filters: Filters<ReimbursementTableRow>;
  sortBy: SortingRule<ReimbursementTableRow>[];
  isLoadingReimbursements?: boolean;
  isLoadingConfigurations?: boolean;
  visibleColumnIds?: ReimbursementTableColumnKeys[];
  filterOptions?: FilterOptionsAccessor<ReimbursementTableRow>;
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  configurations: Configuration[];
  onFilter: (filters: Filters<ReimbursementTableRow>) => void;
  onSort: (sortBy: SortingRule<ReimbursementTableRow>[]) => void;
  onDebounceSearch: (search: string) => void;
  onResetTableConfigurations: () => void;
  onUpdateConfigurations: (newConfigurations: Configuration[]) => void;
  onLoadMore: OnLoadMore;
}

export const ArchiveReimbursements = ({
  reimbursementsCount,
  reimbursements,
  filters,
  sortBy,
  isLoadingReimbursements,
  isLoadingConfigurations,
  filterOptions,
  configurations,
  visibleColumnIds,
  isTableEmpty,
  isTableFiltered,
  onDebounceSearch,
  onFilter,
  onSort,
  onLoadMore,
  onResetTableConfigurations,
  onUpdateConfigurations,
}: ArchiveReimbursementsProps) => {
  const { updateSearchParam, searchParams } = useMutateSearchParams();
  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const handleSearch = (search: string) => {
    onDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <ReimbursementsTableEmptyState
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    ) : null;

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_REIMBURSEMENTS}>
      <Box
        height="100%"
        overflow="hidden"
        paddingRight="space32"
        paddingLeft="space32">
        <ReimbursementsTable
          data={reimbursements}
          visibleColumns={visibleColumnIds}
          initialFilters={filters}
          initialSortBy={sortBy}
          isLoading={isLoadingReimbursements}
          filterOptions={filterOptions}
          configurations={configurations}
          search={queryStringFilter}
          isLoadingConfigurations={isLoadingConfigurations}
          onEndReached={onLoadMore}
          onFilter={onFilter}
          onRowClick={noop}
          onResetTableConfigurations={onResetTableConfigurations}
          onSearch={handleSearch}
          onSort={onSort}
          onUpdateTableConfigurations={onUpdateConfigurations}
          customEmptyState={customEmptyState}
        />
      </Box>
    </ArchiveViewLayout>
  );
};
