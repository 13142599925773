import { Flex } from '@candisio/design-system';
import { ReactNode } from 'react';

export interface BannerProps {
  label: string;
  children: ReactNode;
  ctaButtons: ReactNode;
}

export const Banner = ({ label, children, ctaButtons }: BannerProps) => {
  return (
    <Flex padding="space16 space8">
      <Flex
        direction="column"
        gap="space8"
        textAlign="left"
        borderRadius="8px"
        aria-label={label}
        boxShadow="elevatedShadow4"
        height="100%"
        padding="space16 space16"
        background="gray0">
        {children}
        <Flex gap="space16" paddingTop="space8" justifyContent="right">
          {ctaButtons}
        </Flex>
      </Flex>
    </Flex>
  );
};
