import { Box, Skeleton } from '@candisio/design-system';
import { ActionBar } from 'components/Sidebar/ActionBar';
import {
  New_DatevSettings,
  useDatevStatusQuery,
  useDisconnectDatevClientMutation,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { refetchDatevDataWithNoToken } from 'views/Settings/Integrations/DATEV/gql';
import { getFullOrganizationQuery } from '../../../../../providers/OrganizationProvider/queries';
import { DatevStatusCard } from './DatevStatusCard';
import { DatevStatusCardState } from './datevStatusCardState';

export interface Props {
  datevSettings?: New_DatevSettings;
  additionalActions?: ReactElement[];
  loading?: boolean;
  nonIncidentElement: ReactElement;
  onExport: () => void;
}

export const DatevPanel = ({
  datevSettings,
  additionalActions,
  loading,
  nonIncidentElement,
  onExport,
}: Props) => {
  const [t] = useTranslation('exports');
  const history = useHistory();
  const organizationSlug = useOrganizationId();

  const isMisconfiguredClient =
    datevSettings && !datevSettings.hasAuthTokenForReadClients;

  const [disconnectDatevClient] = useDisconnectDatevClientMutation({
    refetchQueries: [
      ...refetchDatevDataWithNoToken,
      { query: getFullOrganizationQuery },
    ],
  });

  const onReconnectDatev = useCallback(async () => {
    // 1. Disconnect the client
    await disconnectDatevClient();
    // 2. Redirect to datev setting page
    history.push(`/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}`);
  }, [history, organizationSlug, disconnectDatevClient]);

  const datevStatusQuery = useDatevStatusQuery();

  const { hasIncidentOnCandis, hasIncidentOnDatev } =
    datevStatusQuery.data?.datevStatus || {};

  const onShowMoreInfo = useCallback(() => {
    if (hasIncidentOnCandis) {
      const link = t('datevAvailabilityPanel.infoLinks.candis');
      window.open(link, '_blank');
    } else if (hasIncidentOnDatev) {
      const link = t('datevAvailabilityPanel.infoLinks.datev');
      window.open(link, '_blank');
    } else if (isMisconfiguredClient) {
      const link = t('datevMisconfigurationPanel.infoLink');
      window.open(link, '_blank');
    }
  }, [hasIncidentOnCandis, hasIncidentOnDatev, isMisconfiguredClient, t]);

  const isLoading = datevStatusQuery.loading;

  if (isLoading) {
    return (
      <ActionBar>
        <Skeleton height="36px" width="100%" />
      </ActionBar>
    );
  }

  const hasStatusIssue =
    datevStatusQuery.data?.datevStatus?.isOperational !== true;

  return isMisconfiguredClient ? (
    <Box paddingY="space16" position="sticky" bottom={0} alignSelf="end">
      <DatevStatusCard
        loading={loading}
        state={DatevStatusCardState.MISCONFIGURED}
        primaryAction={onReconnectDatev}
        secondaryAction={onShowMoreInfo}
        additionalActions={additionalActions}
      />
    </Box>
  ) : hasStatusIssue ? (
    <Box paddingY="space16" position="sticky" bottom={0} alignSelf="end">
      <DatevStatusCard
        loading={loading}
        state={DatevStatusCardState.UNAVAILABLE}
        primaryAction={onExport}
        secondaryAction={onShowMoreInfo}
        additionalActions={additionalActions}
      />
    </Box>
  ) : (
    nonIncidentElement
  );
};
