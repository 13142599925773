import {
  useAccountingAreasQuery,
  useCountAccountingAreasQuery,
} from 'generated-types/graphql.types';

export const useAccountingAreasData = () => {
  const { data: activeAccountingAreas, loading: loadingActiveAccountingAreas } =
    useAccountingAreasQuery({
      variables: {
        filter: {
          isArchived: false,
        },
      },
    });

  const {
    data: archivedAccountingAreas,
    loading: loadingArchivedAccountingAreas,
  } = useAccountingAreasQuery({
    variables: {
      filter: {
        isArchived: true,
      },
    },
  });

  const {
    data: activeAccountingAreasCount,
    loading: loadingActiveAccountingAreasCount,
  } = useCountAccountingAreasQuery({
    variables: {
      filter: {
        isArchived: false,
      },
    },
  });

  const {
    data: archivedAccountingAreasCount,
    loading: loadingArchivedAccountingAreasCount,
  } = useCountAccountingAreasQuery({
    variables: {
      filter: {
        isArchived: true,
      },
    },
  });

  const accountingAreasDataLoading =
    loadingActiveAccountingAreas ||
    loadingArchivedAccountingAreas ||
    loadingActiveAccountingAreasCount ||
    loadingArchivedAccountingAreasCount;

  const activeCount =
    activeAccountingAreasCount?.countAccountingAreas.count ?? 0;

  const archivedCount =
    archivedAccountingAreasCount?.countAccountingAreas.count ?? 0;

  const countLoading =
    loadingActiveAccountingAreasCount || loadingArchivedAccountingAreasCount;

  return {
    activeAccountingAreas,
    loadingActiveAccountingAreas,
    archivedAccountingAreas,
    loadingArchivedAccountingAreas,
    accountingAreasDataLoading,
    activeCount,
    archivedCount,
    countLoading,
  };
};
