export const fadeUsers = {
  visible: { height: 'auto', transition: { duration: 0.5 }, opacity: 1 },
  hidden: { height: 0, opacity: 0 },
  onExit: { height: 0, transition: { duration: 0.5 }, opacity: 0 },
};

export const slideInformations = {
  visible: { transition: { duration: 1.3 }, opacity: 1 },
  hidden: { opacity: 0 },
  onExit: { height: 0, transition: { duration: 0.5 }, opacity: 0 },
};
