import { PurchaseOrdersTableData } from 'components/PurchaseOrders/Table/types';
import { PurchaseOrderStatus } from 'generated-types/graphql.types';

export const purchaseOrderTableColumns: Array<keyof PurchaseOrdersTableData> = [
  'contact',
  'grossAmount',
  'orderNumber',
  'postingDate',
  'status',
  // 'invoiceAssociationStatus',
  'connectedInvoices',
  'remainingAmount',
  'lineItems',
];

export const statusInSapFilters: Array<{
  id: PurchaseOrderStatus;
  label: string;
}> = [
  {
    id: PurchaseOrderStatus.Open,
    label: 'statusesInSap.open',
  },
  {
    id: PurchaseOrderStatus.Cancelled,
    label: 'statusesInSap.canceled',
  },
  {
    id: PurchaseOrderStatus.Delivered,
    label: 'statusesInSap.delivered',
  },

  {
    id: PurchaseOrderStatus.Paid,
    label: 'statusesInSap.paid',
  },
  {
    id: PurchaseOrderStatus.Exported,
    label: 'statusesInSap.exported',
  },
  {
    id: PurchaseOrderStatus.Closed,
    label: 'statusesInSap.closed',
  },
  {
    id: PurchaseOrderStatus.PartiallyFulfilled,
    label: 'statusesInSap.partiallyFulfilled',
  },
];
