import {
  Table,
  FilterOptionsAccessor,
  SelectionOptions,
  Cell,
  BaseTableDataType,
  CustomEmptyStateProps,
  Flex,
  TableProps,
} from '@candisio/design-system';
import {
  PaymentInfoCell,
  PaymentInfoCellValue,
} from 'components/DocumentsTable/Cells/PaymentInfoCell';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import {
  ContactPaymentMedium,
  ContactRelationshipType,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { ReactNode, useMemo } from 'react';
import { Column, Filters, Row, SortingRule, TableState } from 'react-table';
import { PaymentConditionCell } from 'views/Settings/PaymentConditions/components/PaymentConditionCell';
import { PaymentConditionCellValue } from 'views/Settings/PaymentConditions/types';
import { DateCell } from '../Cell/DateCell';
import { ContactTableToolbar } from './ContactTableToolbar';
import { Header } from './Header/Header';

export interface ContactsTableData extends BaseTableDataType {
  createTransfer: string;
  id: string;
  contact: string;
  accountsPayableNumber?: string | number;
  accountsReceivableNumber?: string;
  relationshipType?: ContactRelationshipType;
  createdAt?: Date;
  dueDateOffset?: string;
  iban?: string;
  taxNumber?: string;
  vatId?: string;
  paymentMedium?: ContactPaymentMedium;
  paymentCondition: PaymentConditionCellValue;
  paymentInfo: PaymentInfoCellValue;
  isArchived?: boolean;
}

export type ContactTableColumnKeys = Exclude<
  keyof ContactsTableData,
  | 'id'
  | 'relationshipType'
  | 'iban'
  | 'highlighted'
  | 'isDisabled'
  | 'canBeSelectedTooltipText'
>;

export type ContactTableColumns = {
  [K in ContactTableColumnKeys]: Column<ContactsTableData>;
};

export interface ContactsTableProps {
  columns: ContactTableColumnKeys[];
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  data: Array<ContactsTableData>;
  defaultFilters?: TableState<ContactsTableData>['filters'];
  defaultSortBy?: TableState<ContactsTableData>['sortBy'];
  filterOptions?: FilterOptionsAccessor<ContactsTableData>;
  isLoading?: boolean;
  onCreate: () => void;
  onEndReached?: any;
  onFilter?: (filters: Filters<ContactsTableData>) => void;
  onRowClick?: (id: string) => void;
  onSort?: (sortBy: SortingRule<ContactsTableData>[]) => void;
  onSearch: (search: string) => void;
  search: string;
  selectionOptions?: SelectionOptions<ContactsTableData>;
  showContactsWithoutAccountsPayableNumber: boolean;
  configurationsTable: Configuration[];
  isLoadingConfigs?: boolean;
  onUpdateConfigurations: (newConfigurations: Configuration[]) => void;
  onResetConfigurations: () => void;
  additionalContent?: ReactNode;
  borderBottomRadius?: TableProps<ContactsTableData>['borderBottomRadius'];
}

export const ContactTableDS = ({
  isLoadingConfigs,
  columns: shownColumns,
  customEmptyState,
  data,
  defaultFilters,
  defaultSortBy,
  filterOptions,
  isLoading,
  onEndReached,
  onFilter,
  onRowClick,
  onSearch,
  onSort,
  search,
  selectionOptions,
  configurationsTable,
  onUpdateConfigurations,
  onResetConfigurations,
  additionalContent,
  borderBottomRadius,
}: ContactsTableProps) => {
  const { friendlyFormatAccountsNumber } = useAccountingNumberFormatters();

  const defaultColumn = useMemo(
    (): Partial<Column<ContactsTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      Cell,
    }),
    []
  );

  const columns = useMemo(() => {
    const allColumns: ContactTableColumns = {
      contact: {
        accessor: 'contact',
        Cell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        width: 250,
      },
      paymentInfo: {
        accessor: 'paymentInfo',
        Cell: PaymentInfoCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: true,
        width: '15rem',
      },
      taxNumber: {
        accessor: 'taxNumber',
        Cell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: true,
      },
      vatId: {
        accessor: 'vatId',
        Cell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: true,
      },
      createTransfer: {
        accessor: 'createTransfer',
        Cell,
        disableSortBy: true,
        disableFilters: true,
      },
      paymentMedium: {
        accessor: 'paymentMedium',
        Cell,
        disableSortBy: true,
      },
      dueDateOffset: {
        accessor: 'dueDateOffset',
        Cell,
        disableSortBy: true,
      },
      accountsPayableNumber: {
        accessor: 'accountsPayableNumber',
        Cell: props => (
          <Cell
            {...props}
            value={friendlyFormatAccountsNumber(`${props.value ?? ''}` ?? '')}
          />
        ),
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      accountsReceivableNumber: {
        accessor: 'accountsReceivableNumber',
        Cell: props => (
          <Cell
            {...props}
            value={friendlyFormatAccountsNumber(`${props.value ?? ''}` ?? '')}
          />
        ),
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      createdAt: {
        accessor: 'createdAt',
        Cell: DateCell,
        sortType: 'datetime',
      },
      paymentCondition: {
        accessor: 'paymentCondition',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: PaymentConditionCell,
        disableSortBy: true,
        disableFilters: true,
      },
    };

    if (!shownColumns.length) {
      return Object.values(allColumns);
    }

    return shownColumns.map(accessor => allColumns[accessor]);
  }, [friendlyFormatAccountsNumber, shownColumns]);

  const handleRowClick = useMemo(
    () =>
      onRowClick
        ? (row: Row<ContactsTableData>) => {
            const { id } = row.original;
            onRowClick(id);
          }
        : undefined,
    [onRowClick]
  );

  return (
    <Flex height="100%" overflow="hidden" direction="column">
      <ContactTableToolbar
        configurations={configurationsTable}
        onSearch={onSearch}
        onUpdateConfigurations={onUpdateConfigurations}
        onResetConfigurations={onResetConfigurations}
        search={search}
        isLoadingConfigs={isLoadingConfigs}
        additionalContent={additionalContent}
      />

      <Table
        columns={columns}
        data={data}
        defaultColumn={defaultColumn}
        filterOptions={filterOptions}
        isLoading={isLoading}
        onEndReached={onEndReached}
        onAllRowsSelected={selectionOptions?.onSelectionRowChanged}
        onFilter={onFilter}
        onRowClick={handleRowClick}
        onRowSelected={selectionOptions?.onSelectionRowChanged}
        onSort={onSort}
        selectedRowIds={selectionOptions?.selectedRowsIds}
        initialState={{
          filters: defaultFilters ?? [],
          sortBy: defaultSortBy ?? [],
        }}
        customEmptyState={customEmptyState}
        borderTopRadius="none"
        borderBottomRadius={borderBottomRadius}
      />
    </Flex>
  );
};
