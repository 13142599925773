import { useImportPaymentConditionsMutation } from 'generated-types/graphql.types';
import { refetchPaymentConditionQueries } from 'views/Settings/PaymentConditions/gql';
import { PaymentConditionsQueryParams } from './types';

interface ImportPaymentConditionsOptions {
  paymentConditionsQueryParams: PaymentConditionsQueryParams;
  upload: () => Promise<{
    fileId: string | null;
  }>;
  importPaymentConditionsMutation: ReturnType<
    typeof useImportPaymentConditionsMutation
  >[0];
}

export const importPaymentConditions = async ({
  paymentConditionsQueryParams,
  importPaymentConditionsMutation,
  upload,
}: ImportPaymentConditionsOptions) => {
  let successCount = 0;
  const { fileId } = await upload();

  if (!fileId) {
    throw new Error('File is missing');
  }

  const result = await importPaymentConditionsMutation({
    variables: {
      fileId,
      input: paymentConditionsQueryParams,
    },
    refetchQueries: refetchPaymentConditionQueries,
  });

  successCount =
    result.data?.importPaymentConditionsWithUpdate?.filter(
      paymentCondition =>
        paymentCondition.newValue.__typename === 'PaymentCondition'
    ).length || 0;

  if (result.errors) {
    throw new Error('Import failed');
  }

  return { successCount };
};
