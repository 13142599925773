import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { BookingInput } from 'generated-types/graphql.types';
import { ValuesType } from 'utility-types';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';

/**
 * Calculate the discount amount for `BookingInput`
 *
 * Discount amount for individual split booking is calculated based on the split
 * amount and the root-level cash discount percentage.
 *
 * The sum of the cash discount amounts of the bookings may or may not add up to
 * the root-level discount amount we show to the user. This is also the case in
 * the Datev Unternehmen Online UI so we’re OK with it...
 */
export const getBookingInputDiscountAmount = (
  values: ProcessingFormValues,
  booking: ValuesType<ProcessingFormValues['bookings']>
): BookingInput['discountAmount'] => {
  if (
    typeof values.discountAmount !== 'number' ||
    typeof values.discountPercentage !== 'number'
  ) {
    return null;
  }

  return roundToCurrencyPrecision(
    (booking.amount / 100) * values.discountPercentage
  );
};
