import { get } from 'lodash';
import { FieldPath } from 'react-hook-form';
import {
  ProcessingFormFieldMetadata,
  useProcessingFormMetadata,
} from './ProcessingFormMetadataContext';
import { ProcessingFormValues } from './processingFormSchema';

/** Returns field metadata for the passed field */
export const useProcessingFormFieldMetadata = (
  name: FieldPath<ProcessingFormValues>
) => {
  const metadata = useProcessingFormMetadata();

  const fieldMetadata = get(metadata, name) as
    | ProcessingFormFieldMetadata
    | undefined;

  return fieldMetadata;
};
