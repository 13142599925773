import { ExportContextProps } from '../../Context';
import { TimeFrameOption } from '../../types';
import { isExportTypeWithTimeframe } from '../utils';

export const useExcludeTransactionsEnabled = ({
  selectedTimeFrame,
  exportEntitiesIds,
  context,
}: {
  selectedTimeFrame?: TimeFrameOption;
  exportEntitiesIds?: {
    [key: string]: string[];
  };
  context: ExportContextProps;
}) => {
  const { exportType, exportId, isReExport } = context;

  const isExportTypeWithTimeframeSelected =
    exportType && isExportTypeWithTimeframe(exportType);

  const hasOptionalExportableEntities =
    exportEntitiesIds?.transactionIds.length === 0 ||
    exportEntitiesIds?.cardSettlementIds.length === 0;

  const hasOptionslExportableEntitiesInTimeframe =
    selectedTimeFrame?.transactionIds?.length === 0 ||
    selectedTimeFrame?.cardSettlementIds?.length === 0;

  const hasOptionalExportableEntity = isExportTypeWithTimeframeSelected
    ? hasOptionslExportableEntitiesInTimeframe
    : hasOptionalExportableEntities;

  const isAlreadyExported = !!exportId || isReExport;

  const excludeTransactionsEnabled =
    isAlreadyExported && hasOptionalExportableEntity;

  return {
    excludeTransactionsEnabled,
  };
};
