import { Card, Grid, Skeleton } from '@candisio/design-system';

export const VendorDetailsLoader = () => {
  return (
    <Card background="gray100">
      <Grid autoFlow="column" justifyContent="space-between">
        <Grid autoFlow="column" gap="space12" alignItems="center">
          <Skeleton height="space36" width="space36" borderRadius="full" />
          <Skeleton height="30px" width="170px" />
        </Grid>

        <Skeleton height="space36" width="158px" borderRadius="full" />
      </Grid>
    </Card>
  );
};
