import { Button, Link, Paragraph } from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Banner } from './Banner';

export interface CookieBannerProps {
  onOpenPreferences: () => void;
  onAcceptAll: () => void;
}

export const CookieBanner = ({
  onOpenPreferences,
  onAcceptAll,
}: CookieBannerProps) => {
  const [t] = useTranslation('consent-manager');

  return (
    <Banner
      label={t('cookieBanner.title')}
      ctaButtons={
        <>
          <Button
            aria-label={t('cookieBanner.actions.showDetails')}
            size="small"
            variant="secondary"
            onClick={onOpenPreferences}>
            {t('cookieBanner.actions.showDetails')}
          </Button>
          <Button
            aria-label={t('cookieBanner.actions.acceptAll')}
            size="small"
            onClick={onAcceptAll}>
            {t('cookieBanner.actions.acceptAll')}
          </Button>
        </>
      }>
      <Paragraph fontSize="small">
        <Trans t={t} i18nKey="cookieBanner.text.main" parent="span">
          We use cookies (and other similar technologies) to collect data to
          improve your experience on our site. By using our website, you are
          agreeing to the collection of data as described in our
          <Link href={t('termsAndConditions.url')} external>
            privacy policy
          </Link>
          .
        </Trans>
      </Paragraph>
      <Paragraph fontSize="small">
        <Trans t={t} i18nKey="cookieBanner.text.openPreferences" parent="span">
          You can manage your preferences any time from the user profile.
        </Trans>
      </Paragraph>
    </Banner>
  );
};
