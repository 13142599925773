import { InfoPanel } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const EmptyMembershipInvolvement = () => {
  const [t] = useTranslation();

  return (
    <InfoPanel
      variant="information"
      message={t('settings.teamMembers.form.involvement.headerEmpty')}
      size="large"
    />
  );
};
