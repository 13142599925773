import { get } from 'lodash';
import { useMemo } from 'react';
import {
  FieldPath,
  FieldPathValue,
  UseFormReturn,
  useFormContext,
} from 'react-hook-form';
import {
  ProcessingFormFieldMetadata,
  useProcessingFormMetadata,
} from './ProcessingFormMetadataContext';
import { ProcessingFormValues } from './processingFormSchema';
import { useSetProcessingFormMetadata } from './ProcessingFormSetMetadataContext';

interface UseUpdateProcessingFormFieldOptions {
  /**
   * (Optional) processing form instance. We get the form instance from context
   * if this prop is not set.
   */
  form?: UseFormReturn<ProcessingFormValues>;
}

/**
 * Returns a function that updates a processing form field value and metadata,
 * marking the field as dirty and triggering validation.
 *
 * Skips fields whose source is transaction.
 */
export const useUpdateProcessingFormField = ({
  form: formProp,
}: UseUpdateProcessingFormFieldOptions = {}) => {
  const formContext = useFormContext<ProcessingFormValues>() as
    | UseFormReturn<ProcessingFormValues>
    | undefined;

  const form = formProp ?? formContext;

  const metadata = useProcessingFormMetadata();
  const setMetadata = useSetProcessingFormMetadata();

  const updateField = useMemo(() => {
    if (!form) {
      return;
    }

    return <
      TFieldPath extends FieldPath<ProcessingFormValues>,
      TValue extends FieldPathValue<ProcessingFormValues, TFieldPath>
    >(
      field: TFieldPath,
      value: TValue,
      meta: ProcessingFormFieldMetadata | undefined
    ) => {
      const prevMeta = get(metadata, field) as
        | ProcessingFormFieldMetadata
        | undefined;

      if (prevMeta?.source === 'transaction') {
        // don’t update fields whose field source is transaction (such fields
        // are always read only)
        return;
      }

      form.setValue(field, (value as any) ?? null, {
        // It’s important to set shouldDirty to true, so that later we can tell
        // that the field has been changed
        shouldDirty: true,
        // We trigger validation to clear any existing error that the field
        // might have
        shouldValidate: true,
      });
      setMetadata(field, value ? meta : undefined);
    };
  }, [form, metadata, setMetadata]);

  return updateField;
};
