import { DocumentFormContainer } from 'components/Form/DocumentForm/DocumentFormContainer';
import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DocumentStatus } from 'generated-types/graphql.types';
import { DocumentStatusFrontend } from 'models';
import { isPaidDocument } from 'utils/document_payment_data';

interface ApprovedProps {
  document?: ProcessedDocument;
  isLoading?: boolean;
  toggleEditMode: () => void;
}

export const Approved = ({
  document,
  isLoading,
  toggleEditMode,
}: ApprovedProps) => {
  const documentId = document?.id;

  if (!document) {
    // TODO Add error state
    return null;
  }

  return (
    <ProcessSidebar
      documentId={documentId}
      documentStatus={
        isPaidDocument(document || {})
          ? DocumentStatusFrontend.ApprovedAndPaid
          : DocumentStatus.Approved
      }>
      {/* TODO add loading state */}
      {isLoading ? null : (
        <DocumentFormContainer
          document={document}
          allowInlineEditing
          reRequestApproval={toggleEditMode}
        />
      )}
    </ProcessSidebar>
  );
};
