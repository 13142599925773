import { OrganizationSettingsFormErrorMessages } from './organizationSettingsFormSchema';

export const organizationSettingsFormErrorMessages: OrganizationSettingsFormErrorMessages =
  {
    vatId: {
      label: 'insights.organizationSettingsDrawer.form.fields.vatId.label',
    },
    billingEmailAddress: {
      label:
        'insights.organizationSettingsDrawer.form.fields.billingEmailAddress.label',
    },
    deliveryAddressAdditionalInfo: {
      label:
        'insights.organizationSettingsDrawer.form.fields.deliveryAddressAdditionalInfo.label',
    },
    deliveryAddressCity: {
      label:
        'insights.organizationSettingsDrawer.form.fields.deliveryAddressCity.label',
    },
    deliveryAddressCountry: {
      label:
        'insights.organizationSettingsDrawer.form.fields.deliveryAddressCountry.label',
    },
    deliveryAddressNumber: {
      label:
        'insights.organizationSettingsDrawer.form.fields.deliveryAddressNumber.label',
    },
    deliveryAddressStreet: {
      label:
        'insights.organizationSettingsDrawer.form.fields.deliveryAddressStreet.label',
    },
    deliveryAddressZipcode: {
      label:
        'insights.organizationSettingsDrawer.form.fields.deliveryAddressZipCode.label',
    },
    tradeName: {
      label:
        'insights.organizationSettingsDrawer.form.fields.tradeName.errorLabel',
    },
    vatIdAvailable: {
      label: 'insights.organizationSettingsDrawer.form.fields.vatId.label',
    },
    deliveryAddressSameAsBilling: {
      label:
        'insights.organizationSettingsDrawer.form.fields.deliveryAddressAdditionalInfo.label',
    },
  };
