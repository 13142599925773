import { UserRole } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { ReactNode } from 'react';
import { hasRole } from 'utils/authorization';
import { AccessModeWrapper } from './AccessModeWrapper';
import { AccessMode } from './types';

type Props = {
  allowedRoles?: UserRole[];
  mode?: AccessMode;
  renderProps?: any;
  children: ReactNode;
};

export const UserRoleAccessWrapper = ({
  children,
  mode = 'hidden',
  allowedRoles,
  renderProps,
}: Props) => {
  const currentUser = useCurrentUser();
  if (allowedRoles && currentUser) {
    const hasAccess = allowedRoles.some(role => hasRole(currentUser, role));

    if (!hasAccess) {
      return mode === 'hidden' ? null : (
        <AccessModeWrapper renderProps={renderProps} mode={mode}>
          {children}
        </AccessModeWrapper>
      );
    }
  }

  return <>{children}</>;
};
