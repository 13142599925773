import { useGetCostCenterQuery } from 'generated-types/graphql.types';

interface UseGetCostCenterByIdParams {
  id: string | undefined;
  skip?: boolean;
}

export const useGetCostCenterById = ({
  id,
  skip,
}: UseGetCostCenterByIdParams) => {
  const { data, loading } = useGetCostCenterQuery({
    variables: { input: { id: id as string } },
    skip: skip || !id,
  });

  const costCenter = {
    readableName: data?.costCenter?.readableName,
  };

  return { costCenter, costCenterLoading: loading };
};
