import { Grid } from '@candisio/design-system';
import { Vendor } from 'views/CreditCards/hooks/useVendorSuggestionsData';
import { CollapsibleHeader } from './CollapsibleHeader';
import { VendorList } from './VendorList';

export interface VendorListSuggestionsProps {
  onSetExpandibleCards: (
    toogle: 'vendorSuggestions' | 'recurringPayments'
  ) => void;
  showVendors: boolean;
  onSetSuggestedAmount: React.Dispatch<React.SetStateAction<number>>;
  vendorDetails: {
    vendors: Vendor[];
    convertedVendors: number;
    vendorsToConvert: number;
  };
}

export const VendorListSuggestions = ({
  onSetExpandibleCards,
  showVendors,
  vendorDetails,
  onSetSuggestedAmount,
}: VendorListSuggestionsProps) => {
  const { vendors, vendorsToConvert, convertedVendors } = vendorDetails;

  if (!vendorsToConvert) {
    return null;
  }

  return (
    <Grid>
      <CollapsibleHeader
        onClick={onSetExpandibleCards}
        showVendors={showVendors}
        convertedVendors={convertedVendors}
        vendorsToConvert={vendorsToConvert}
      />
      <VendorList
        vendors={vendors}
        showVendors={showVendors}
        onSetSuggestedAmount={onSetSuggestedAmount}
      />
    </Grid>
  );
};
