import {
  HospitalityExpenseItem,
  ReimbursementError,
  ReimbursementItemStatus,
  ReimbursementItemType,
  File,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import DateFormatters from 'utils/date_formatter';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';

export interface HospitalityExpense {
  id: string;
  reason?: string;
  type: ReimbursementItemType;
  status: ReimbursementItemStatus;
  createdAt: string;
  files: File[];
  totalAmount: number | null;
  tipAmount: number | null;
  receiptAmount: number | null;
  location?: string;
  errors: ReimbursementError[];
  externalGuests?: string;
  internalGuests?: string;
  expenseDate?: string;
}

export const useFormattedHospitalityExpense = () => {
  const { dateTimeStringToDateString } = useDateConverter();

  const hospitalityExpenseItem = (
    data: HospitalityExpenseItem
  ): HospitalityExpense => ({
    id: data.id,
    errors: data.errors,
    status: data.status,
    type: ReimbursementItemType.HospitalityExpense,
    reason: data.title ?? undefined,
    location: data.location ?? undefined,
    externalGuests: data.externalGuests?.[0] ?? undefined,
    internalGuests: data.internalGuests?.[0] ?? undefined,
    createdAt: DateFormatters.compact(new Date(data.createdAt)),
    expenseDate: data.expenseDate
      ? dateTimeStringToDateString(data.expenseDate)
      : undefined,
    totalAmount: data.totalAmount
      ? formatFromCents(data.totalAmount.currency, data.totalAmount.amount)
      : 0,
    tipAmount: data.tipAmount
      ? formatFromCents(data.tipAmount.currency, data.tipAmount.amount)
      : 0,
    receiptAmount: data.receiptAmount
      ? formatFromCents(data.receiptAmount?.currency, data.receiptAmount.amount)
      : 0,
    files: data.files?.length
      ? data.files.map(file => ({
          id: file.id,
          url: file.url,
          name: file.name,
        }))
      : [],
  });

  return hospitalityExpenseItem;
};
