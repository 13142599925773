import { Box, Button, Popover, usePopover } from '@candisio/design-system';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { CardStatus, CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TerminationReasonsForm } from './TerminationReasonsForm';

export interface TerminateCardProps {
  cardId: string;
  cardholderName: string;
  cardType: CardType;
  cardStatus: CardStatus;
  onClose: () => void;
  onSetMode: (mode: DrawerMode) => void;
}

export const TerminateCard = ({
  cardId,
  cardType,
  cardholderName,
  cardStatus,
  onClose,
  onSetMode,
}: TerminateCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover({
      placement: 'bottom',
    });

  return (
    <Box>
      <Button
        color="red"
        icon="trash"
        size="small"
        variant="tertiary"
        {...triggerProps}
        ref={triggerRef}>
        {t('dashboard.drawer.terminateCard')}
      </Button>
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} width="345px" padding={0}>
          <TerminationReasonsForm
            onSetMode={onSetMode}
            close={close}
            cardId={cardId}
            cardType={cardType}
            cardholder={cardholderName}
            afterTerminate={onClose}
            cardStatus={cardStatus}
          />
        </Popover>
      )}
    </Box>
  );
};
