import gql from 'graphql-tag';

export const builtInRoleData = gql`
  fragment BuiltInRoleData on BuiltinRole {
    builtInRoleName: name
    id
    isLegacyRole
    __typename
  }
`;

export const customRoleData = gql`
  fragment CustomRoleData on CustomRole {
    customRoleName: name
    id
    updatedAt
    updatedBy {
      id
      name
    }
    __typename
  }
`;

export const permissionData = gql`
  fragment PermissionData on Permission {
    id
    name
    description
    category
    isActive
  }
`;

export const getRolesQuery = gql`
  query getRoles(
    $input: RolesPageBasedPaginationInput!
    $queries: RolesQueryInput
    $filters: RolesFiltersInput
    $sortBy: RolesSortInput
  ) {
    roles(
      input: $input
      queries: $queries
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        __typename
        id
        description
        members {
          id
        }
        isArchived
        permissions {
          id
        }
        ...BuiltInRoleData
        ...CustomRoleData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${builtInRoleData}
  ${customRoleData}
`;

export const teamsByNameQuery = gql`
  query roleByName($input: RolesPageBasedPaginationInput!, $name: String) {
    roleByName: roles(input: $input, filters: { name: $name })
      @connection(key: "roleByName", filter: ["filters"]) {
      records {
        __typename
        id
        ...BuiltInRoleData
        ...CustomRoleData
      }
    }
    ${builtInRoleData}
    ${customRoleData}
  }
`;

export const roleByIdQuery = gql`
  query getRoleById($id: ID!) {
    role(id: $id) {
      id
      description
      isArchived
      members {
        id
        name
        avatarUrl
      }
      permissions {
        ...PermissionData
      }
      ...BuiltInRoleData
      ...CustomRoleData
    }
  }
  ${builtInRoleData}
  ${customRoleData}
  ${permissionData}
`;

export const getPermissionsForNewRole = gql`
  query getPermissionsForNewRole($filters: PermissionFilterInput) {
    permissionsForRole(filters: $filters) {
      permissions {
        ...PermissionData
      }
    }
  }
  ${permissionData}
`;
