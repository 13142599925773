import { Text } from '@candisio/design-system';
import { DocumentTagUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const DocumentTagUpdatedEventComponent = ({
  tags,
}: DocumentTagUpdatedEventDataFragment) => {
  const [t] = useTranslation();
  if (!tags) return null;

  if (tags.length === 0)
    return (
      <Text>{t('document.compact.history.documentTagUpdated.allRemoved')}</Text>
    );

  return (
    <Text>
      {t('document.compact.history.documentTagUpdated.tagsAdded', {
        count: tags.length,
        commaDelimitedTags: `“${tags.map(t => t.name).join('”, “')}”`,
      })}
    </Text>
  );
};
