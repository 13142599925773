import { Avatar } from '@candisio/design-system';
import { AbsenceInfo } from 'components/AvatarWithStatus/AbsenceInfo';
import {
  AvatarStatus,
  AvatarWithStatus,
  AvatarWithStatusProps,
} from 'components/AvatarWithStatus/AvatarWithStatus';
import { AbsenceStatus } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import moment from 'moment';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';
import { useGetUserAbsence } from './useGetUserAbsence';

const mapAbsenceStatusToAvatarStatus: Record<AbsenceStatus, AvatarStatus> = {
  AWAY: 'AWAY',
  AWAY_SOON: 'AWAY_SOON',
};

export interface AvatarWithStatusContainerProps
  extends Omit<AvatarWithStatusProps, 'status'> {
  userId?: string;
  showAbsenceTooltip?: boolean;
}

export const AvatarWithStatusContainer = ({
  userId,
  showAbsenceTooltip = true,
  ...restProps
}: AvatarWithStatusContainerProps) => {
  const organizationTimezone = useOrganizationTimezone();

  const { getUserAbsence, getUsersSubstitute } = useGetUserAbsence();

  const absence = getUserAbsence(userId);

  if (isNil(absence)) {
    return <Avatar {...restProps} />;
  }

  const { status, fromDate, note, toDate } = absence;

  if (isNil(status) || isNil(fromDate) || isNil(toDate)) {
    return <Avatar {...restProps} />;
  }

  const avatarStatus = status
    ? mapAbsenceStatusToAvatarStatus[status]
    : undefined;

  const from = fromDate
    ? moment.tz(fromDate, organizationTimezone).toDate()
    : undefined;

  const to = toDate
    ? moment.tz(toDate, organizationTimezone).toDate()
    : undefined;

  const isAbsent = from && to && avatarStatus;

  const substitute = getUsersSubstitute(
    absence.substituteMembershipId ?? undefined
  );

  const customTooltip = isAbsent && (
    <AbsenceInfo
      fromDate={from}
      toDate={to}
      note={note ?? undefined}
      name={restProps.name}
      status={avatarStatus}
      substitute={substitute}
    />
  );

  return (
    <AvatarWithStatus
      status={avatarStatus}
      tooltipContent={isAbsent ? customTooltip : undefined}
      {...restProps}
      hasTooltip={isAbsent ? showAbsenceTooltip : restProps.hasTooltip}
      tooltipDelay={isAbsent ? 500 : 0}
    />
  );
};
