export enum TAX_CODES_SEARCH_PARAM {
  bookingKey = 'taxCode',
  archive = 'archive',
  description = 'description',
  sort = 'sort',
}

export enum TAX_CODES_ROUTE_HASH {
  defaultImport = '#defaultImport',
}
