import { Box, Button, Grid } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { InAppNotificationSubscription } from 'generated-types/graphql.types';
import { MouseEvent } from 'react';
import { useHover } from 'react-aria';
import { NotificationInfo } from './getNotificationInfo';
import { useToggleNotificationRead } from './hooks/useToggleNotificationRead';
export interface NotificationToastProps {
  notificationInfo: NotificationInfo;
  notificationData: InAppNotificationData | undefined;
  onDismiss: () => void;
}

export type InAppNotificationData = NonNullable<
  NonNullable<InAppNotificationSubscription['inAppNotification']>
>;

export const NotificationToast = ({
  onDismiss,
  notificationInfo: {
    icon: NotificationIcon,
    toast: NotificationToast,
    getLinkTo,
  },
  notificationData,
}: NotificationToastProps) => {
  const { isHovered, hoverProps } = useHover({});

  const { link } = getLinkTo(notificationData);

  const { markNotificationsAsRead } = useToggleNotificationRead();

  if (!notificationData) {
    return null;
  }

  const handlToastClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    markNotificationsAsRead({
      ids: [notificationData.id],
    });
    onDismiss?.();
  };

  return (
    <RouterLink to={link} onClick={handlToastClick}>
      <Box position="relative" background="white" {...hoverProps}>
        <Grid templateColumns="auto 1fr auto" gap="space16" alignItems="start">
          <Grid alignItems="center" height="100%">
            <NotificationIcon {...notificationData} />
          </Grid>
          <NotificationToast {...notificationData} />

          <Grid
            justifyContent="center"
            alignItems="center"
            width="space32"
            height="100%">
            {!notificationData?.isRead && isHovered && (
              <Button
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onDismiss?.();
                }}
                icon="close"
                size="small"
                variant="tertiary"
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </RouterLink>
  );
};
