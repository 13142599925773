import { New_DatevSettingsQuery } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useDatev } from 'orgConfig/datev';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useState } from 'react';
import { ConnectedClientDetailsContainer } from '../ConnectedClientDetailsContainer/ConnectedClientDetailsContainer';
import { AccountingCoreDataCheck } from './components/AccountingCoreDataCheck';
import { BDSPermissionCheck } from './components/BDSPermissionCheck';

export const BDSTestConnectionContainer = ({
  settingsData,
}: {
  settingsData?: New_DatevSettingsQuery;
}) => {
  const [bdsPermissionCheckFlowFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.bdsPermissionCheckFlow,
  ]);

  const [accountCoreDateChecked, setAccountCoreDateChecked] =
    useState<boolean>(false);

  const { client } = useDatev();

  if (
    !bdsPermissionCheckFlowFF ||
    (bdsPermissionCheckFlowFF && client?.isBdsPermissionVerified)
  ) {
    return <ConnectedClientDetailsContainer settingsData={settingsData} />;
  }

  if (accountCoreDateChecked) {
    return <BDSPermissionCheck />;
  }

  return <AccountingCoreDataCheck onSubmit={setAccountCoreDateChecked} />;
};
