import { Text } from '@candisio/design-system';
import { DocumentTransactionStatusUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const translationKeys: Record<string, string> = {
  CONFIRMED: 'transactionDetailsCard.status.confirmed',
  PENDING: 'transactionDetailsCard.status.pending',
  DECLINED: 'transactionDetailsCard.status.declined',
  REVERSED: 'transactionDetailsCard.status.reversed',
};

export const DocumentTransactionStatusUpdatedComponent = ({
  oldStatus,
  newStatus,
}: DocumentTransactionStatusUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const newStatusTranslation = t(translationKeys[newStatus]);

  const oldStatusTranslation = oldStatus
    ? t(translationKeys[oldStatus])
    : undefined;

  if (!oldStatus) {
    return (
      <Text>
        {t('documentHistoryCCEvents.transactionStatusUpdatedWithoutOldStatus', {
          newStatus: newStatusTranslation,
        })}
      </Text>
    );
  }

  return (
    <Text>
      {t('documentHistoryCCEvents.transactionStatusUpdatedWithOldStatus', {
        oldStatus: oldStatusTranslation ?? '',
        newStatus: newStatusTranslation,
      })}
    </Text>
  );
};
