import { useCreatePaymentConditionMutation } from 'generated-types/graphql.types';
import { paymentConditionsCountQuery, paymentConditionsQuery } from '../gql';
import { PaymentConditionsFormOutput } from './PaymentConditionsForm';

type CreateReturn =
  | {
      status: 'success';
      id: string;
    }
  | { status: 'error' };

export interface UseCreatePaymentConditionReturn {
  create: (values: PaymentConditionsFormOutput) => Promise<CreateReturn>;
  creating: boolean;
}

/**
 * Returns a callback that creates a payment condition from
 * PaymentConditionsFormOutput
 */
export const useCreatePaymentCondition =
  (): UseCreatePaymentConditionReturn => {
    const [createPaymentCondition, { loading: creating }] =
      useCreatePaymentConditionMutation({
        refetchQueries: [
          {
            query: paymentConditionsQuery,
            variables: { filter: { isArchived: false } },
          },
          {
            query: paymentConditionsCountQuery,
            variables: { filter: { isArchived: false } },
          },
        ],
      });

    return {
      create: async values => {
        const { data } = await createPaymentCondition({
          variables: {
            input: {
              dueDateOffset: values.dueDateOffset,
              discountOffset: values.discountOffset,
              discountPercentage: values.discountPercentage,
            },
          },
        });

        switch (data?.createPaymentCondition.__typename) {
          case 'PaymentCondition': {
            return { status: 'success', id: data.createPaymentCondition.id };
          }

          case 'PaymentConditionError': {
            return { status: 'error' };
          }

          default: {
            // unknown error
            return { status: 'error' };
          }
        }
      },
      creating,
    };
  };
