import { Button, Card, Grid, Icon, Modal, Text } from '@candisio/design-system';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { AnimatePresence, motion } from 'framer-motion';
import {
  DocumentRelationshipsQuery,
  EcmDocumentStatus,
  useDocumentRelationshipsQuery,
} from 'generated-types/graphql.types';
import { noop } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentRelations } from 'views/Inbox/DocumentProcessing/components/hooks/useDocumentRelations';
import { useEcmDocumentsTableData } from './useEcmDocumentsTableData';

const MotionGrid = motion(Grid);

type LinkedDocument =
  DocumentRelationshipsQuery['documentRelationships']['relationships'][number]['linkedDocument'];
type RelatedDocument = Extract<
  NonNullable<LinkedDocument>,
  { __typename?: 'RelatedDocument' }
>['document'];

type DocumentRelationship = Extract<
  RelatedDocument,
  { __typename?: 'Document' }
>;

type AggregatedEcmDocumentRelationship = Extract<
  RelatedDocument,
  { __typename?: 'AggregatedEcmDocument' }
>;

export const mapFromDocumentType = (
  document: DocumentRelationship,
  id?: string | null
): EcmDocumentsTableData[] => [
  {
    id: id ?? '',
    documentType: 'INVOICE',
    contact: document.contact?.value.name.value ?? '',
    invoiceId: id ?? '',
    notes: document.note?.value ?? '',
    documentName: document.documentFile?.name ?? '',
    documentNumber: document.invoiceNumber?.value ?? '',
    documentDate: document.createdAt ? new Date(document.createdAt) : undefined,
    documentStatus: {
      status: (document.status as unknown as EcmDocumentStatus) ?? undefined,
      isSensitive: false,
    },
    tags: document.tags ?? [],
  },
];

export const mapFromAggregatedEcmDocumentType = (
  document: AggregatedEcmDocumentRelationship,
  id?: string | null
): EcmDocumentsTableData[] => [
  {
    id: id ?? '',
    documentType: document.type ?? '',
    contact: document.contactName ?? '',
    invoiceId: id ?? '',
    notes: document?.notes ?? '',
    documentName: document?.documentName ?? '',
    documentNumber: document?.documentNumber ?? '',
    documentDate: document?.documentDate
      ? new Date(document?.documentDate)
      : undefined,
    documentStatus: {
      status: document?.documentStatus ?? undefined,
      isSensitive: document?.isSensitive ?? false,
    },
    tags: document?.tags ?? [],
  },
];

export const mapRelationshipToTableRows = (
  r: DocumentRelationshipsQuery['documentRelationships']['relationships'][number]
): EcmDocumentsTableData[] => {
  if (r.linkedDocument?.__typename !== 'RelatedDocument') return [];

  switch (r.linkedDocument.document?.__typename) {
    case 'Document':
      return mapFromDocumentType(
        r.linkedDocument.document,
        r.linkedDocument.id
      );
    case 'AggregatedEcmDocument':
      return mapFromAggregatedEcmDocumentType(
        r.linkedDocument.document,
        r.linkedDocument.id
      );
    default:
      return [];
  }
};

export interface DocumentRelationshipsModalProps {
  onClose?: () => void;
  documentId: string;
  documentName: string;
  handleLinkDocuments: ReturnType<
    typeof useDocumentRelations
  >['handleLinkDocuments'];
  isLinkingDocumentsPending: ReturnType<
    typeof useDocumentRelations
  >['isLinkingDocumentsPending'];
  existingRelationshipsDocIds?: string[];
}

export const DocumentRelationshipsModal = ({
  onClose = noop,
  documentId,
  documentName,
  handleLinkDocuments,
  isLinkingDocumentsPending,
  existingRelationshipsDocIds = [],
}: DocumentRelationshipsModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const { data: existingRelationshipsData } = useDocumentRelationshipsQuery({
    variables: {
      input: {
        ecmDocumentId: documentId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data,
    handleSearchChange,
    handleUpdateConfigurations,
    isLoading,
    onLoadMore,
    searchQuery,
    selectedDocuments,
    setSelectedDocuments,
    totalCount,
    availableDocumentColumnIds,
    configurationsTable,
    customEmptyState,
    filters,
    onFilter,
    onSort,
    sortBy,
  } = useEcmDocumentsTableData({ filterParamsSource: 'local' });

  const tableData: EcmDocumentsTableData[] = useMemo(() => {
    const existingRelationships =
      existingRelationshipsData?.documentRelationships?.relationships.flatMap(
        mapRelationshipToTableRows
      ) ?? [];

    const documentsToHide = [documentId, ...existingRelationshipsDocIds];

    const dataWithoutExistingRelationships = data.filter(d => {
      // To be on the safe side, we check both invoiceId and id. We cannot guarantee that if invoiceId is present, the id will be different.
      // This should be fixed once we use globalDocumentId everywhere
      const isExistingRelationship =
        (d?.invoiceId && documentsToHide.includes(d.invoiceId)) ||
        documentsToHide.includes(d.id);

      return !isExistingRelationship;
    });

    return [...existingRelationships, ...dataWithoutExistingRelationships];
  }, [
    data,
    documentId,
    existingRelationshipsData?.documentRelationships?.relationships,
    existingRelationshipsDocIds,
  ]);

  const linkDocuments = async () => {
    await handleLinkDocuments({
      documentId,
      documentName,
      selectedDocuments,
    });

    onClose();
  };

  const canBeSelected = (row: EcmDocumentsTableData): boolean => {
    return (
      !existingRelationshipsDocIds.includes(row.id) ||
      !existingRelationshipsDocIds.includes(row.invoiceId ?? '')
    );
  };

  return (
    <Modal
      data-testid="documents-relationships-table-modal"
      background="gray200"
      closeLabel={t('common:common.close')}
      isOpen={true}
      onClose={onClose}
      padding={0}
      scrollDirection="none"
      title={t('documentRelationship.modal.title', { documentName })}
      overflow="hidden"
      width="80vw"
      height="100%">
      <Grid height="100%" templateRows="1fr auto" paddingTop="space14">
        <Grid padding="0 space32 space64 space32">
          <EcmDocumentsTable
            context="documentRelationships"
            columns={availableDocumentColumnIds}
            data={tableData}
            isLoading={isLoading}
            defaultFilters={filters}
            isTableFiltered={filters.length > 0}
            searchQuery={searchQuery}
            selectedDocumentsCount={totalCount}
            configurationsTable={configurationsTable}
            onUpdateConfigurations={handleUpdateConfigurations}
            defaultSortBy={sortBy}
            onSearchChange={handleSearchChange}
            onFilter={onFilter}
            onEndReached={onLoadMore}
            selectionOptions={{
              selectedRowsIds: selectedDocuments.map(doc => doc.id),
              onSelectionRowChanged: setSelectedDocuments,
              canBeSelected: canBeSelected,
              canBeSelectedTooltipText: t(
                'documentRelationship.modal.existingRelationshipHint'
              ),
            }}
            onSort={onSort}
            customEmptyState={customEmptyState}
          />
        </Grid>
        <AnimatePresence>
          {selectedDocuments.length > 0 && (
            <CreateDocumentRelationAction
              onClick={linkDocuments}
              isLinkingDocuments={isLinkingDocumentsPending}
              selectedDocuments={selectedDocuments}
              totalCount={totalCount}
            />
          )}
        </AnimatePresence>
      </Grid>
    </Modal>
  );
};

export const CreateDocumentRelationAction = ({
  selectedDocuments,
  onClick,
  isLinkingDocuments,
  totalCount,
}: {
  selectedDocuments: EcmDocumentsTableData[];
  totalCount: number;
  onClick: () => void;
  isLinkingDocuments: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <MotionGrid
      variants={{
        visible: { height: 'auto' },
        hidden: { height: 0 },
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      width="100%"
      position="absolute"
      bottom={0}>
      <Card
        background="blue100"
        corners="all"
        boxShadow="elevatedShadow5"
        paddingX="space36"
        maxHeight="space80"
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}>
        <Grid
          placeContent="space-between"
          placeItems="center"
          autoFlow="column">
          <Text fontSize="small" lineHeight="paragraph">
            {t('documentRelationship.modal.selectedInfo', {
              selectedCount: selectedDocuments.length,
              totalCount,
            })}
          </Text>
          <Button onClick={onClick} loading={isLinkingDocuments}>
            <Grid placeContent="center" placeItems="center" autoFlow="column">
              {t('documentRelationship.modal.linkButton')}
              <Icon icon="arrowRight" size="space20" />
            </Grid>
          </Button>
        </Grid>
      </Card>
    </MotionGrid>
  );
};
