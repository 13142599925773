import { DocumentNode, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { documentHistoryQuery } from 'views/queries';
import { documentQueries } from '../queries';

type Props = {
  prevDocumentId?: string;
  nextDocumentId?: string;
};

const queries: DocumentNode[] = [
  documentQueries.forDraftForm,
  documentHistoryQuery,
];

export const usePrefetchQueries = ({
  prevDocumentId,
  nextDocumentId,
}: Props) => {
  const client = useApolloClient();

  useEffect(() => {
    const idleCallback = requestIdleCallback(() => {
      if (prevDocumentId) {
        queries.forEach(query =>
          client.query({ query, variables: { id: prevDocumentId } })
        );
      }

      if (nextDocumentId) {
        queries.forEach(query =>
          client.query({ query, variables: { id: nextDocumentId } })
        );
      }
    });

    return () => cancelIdleCallback(idleCallback);
  }, [client, nextDocumentId, prevDocumentId]);
};
