import { Box } from '@candisio/design-system';
import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom-v5-compat';
import { usePurchaseOrdersImportHistoryPaginationData } from '../hooks/usePurchaseOrdersImportHistoryPaginationData';
import { PurchaseOrderImportHistoryList } from './PurchaseOrdersImportHistoryList';

export const PurchaseOrdersImportHistory = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const organizationSlug = useOrganizationId();
  const { items, loading, onLoadMore } =
    usePurchaseOrdersImportHistoryPaginationData();

  const purchaseOrdersLink = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}${Routes.PURCHASE_ORDERS}`,
    { organizationSlug }
  );

  return (
    <Box height="100%" padding="space40 space32 space32">
      <ImportHistoryLayout
        titleAs="h1"
        title={t('history.title')}
        backButtonLink={purchaseOrdersLink}
        backButtonLabel={t('history.back')}>
        <PurchaseOrderImportHistoryList
          data={items}
          isLoading={loading}
          onEndReached={onLoadMore}
        />
      </ImportHistoryLayout>
    </Box>
  );
};
