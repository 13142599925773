import {
  DatevClientExportType,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';

export const useDatevSettings = () => {
  const { data } = useNew_DatevSettingsQuery();

  const exportType = data?.new_datevSettings?.client?.exportType;
  const connectionType = data?.new_datevSettings?.connectionType;

  const isDatevConnected = Boolean(
    exportType === DatevClientExportType.DxsoJobs ||
      exportType === DatevClientExportType.AccountingDocuments
  );

  return {
    data,
    connectionType,
    isDatevConnected,
  };
};

export const useDatevSettings_v2 = () => {
  const { data } = useNew_DatevSettingsQuery();

  const settings = data?.new_datevSettings;

  return {
    creditCards: {
      accountNumbers: {
        // XXX we need to find an intuitive name for these
        bookingAccount: settings?.creditCardsLedger?.bookingAccount,
        transitAccount: settings?.creditCardsLedger?.transitAccount,
      },
    },
  };
};
