import { Routes } from 'models';
import { useLocation, useMatch, useNavigate } from 'react-router-dom-v5-compat';
import { ContactDetailsContainer } from './ContactDetailsContainer';

/** Handles routing for ContactDetails component  */
export const ContactDetailsRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const match = useMatch(`:organizationSlug${Routes.CONTACTS}/:id`);
  const contactId = match?.params.id;

  return (
    <ContactDetailsContainer
      contactId={contactId !== 'create' ? contactId : undefined}
      isOpen={match !== null}
      onClose={() => {
        navigate({
          pathname: `${location.pathname}/..`,
          search: location.search,
        });
      }}
    />
  );
};
