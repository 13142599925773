import { Flex, Grid, InfoPanel } from '@candisio/design-system';
import { History } from 'components/History/History';
import { NoHistoryData } from 'components/History/NoData/NoHistoryData';
import { ContactHistoryQuery } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { CONTACT_HISTORY_START_DATE } from 'views/Contacts/consts';
import { ContactHistoryItem } from 'views/Contacts/ContactDetails/ContactHistory/elements/ContactHistoryItem';

interface ContactHistoryProps {
  contactHistoryItems: ContactHistoryQuery['entityAuditLogs'];
  showInfoPanel?: boolean;
}

export const ContactHistory = ({
  contactHistoryItems,
  showInfoPanel,
}: ContactHistoryProps) => {
  const [t] = useTranslation();

  const contactHistoryStartDate = DateFormatters.compact(
    CONTACT_HISTORY_START_DATE
  );

  const noHistoryMessage = showInfoPanel
    ? t('common:historyTimeline.infoPanel.description', {
        date: contactHistoryStartDate,
      })
    : 'historyTimeline.emptyState';

  if (!contactHistoryItems.length) {
    return (
      <Flex justifyContent="center" width="100%">
        <Grid width="space256">
          <NoHistoryData message={noHistoryMessage} />
        </Grid>
      </Flex>
    );
  }

  return (
    <>
      <History>
        {contactHistoryItems.map((historyItem, index) => {
          const isLastItem = index === contactHistoryItems.length - 1;

          return (
            <ContactHistoryItem
              key={historyItem.uniqueId}
              historyItem={historyItem}
              hideTail={Boolean(isLastItem && !showInfoPanel)}
            />
          );
        })}
      </History>
      {showInfoPanel && (
        <InfoPanel
          variant="information"
          message={t('common:historyTimeline.infoPanel.description', {
            date: contactHistoryStartDate,
          })}
        />
      )}
    </>
  );
};
