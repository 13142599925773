import closeImage from 'assets/icon-close-x-large-black.svg';
import { css } from './themes';

/**
 * @deprecated Please use the design system instead
 * @see https://candisio.github.io/design-system
 */
export const notificationStyles = css`
  .ant-notification-notice-closable .ant-notification-notice-close {
    &:hover i::after {
      opacity: 1;
    }
    i {
      svg {
        display: none;
      }
      &::after {
        top: 0;
        right: 0;
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        opacity: 0.4;
        transition: 0.2s;
        background-image: url(${closeImage});
        position: absolute;
      }
    }
  }
`;
