import { Flex, Tag, Text } from '@candisio/design-system';
import { Promotion } from 'components/NewPromotions/types';
import { motion } from 'framer-motion';

const MotionText = motion(Text);

const textVariants = {
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
};

export const PromotionLabel = ({ label }: { label: Promotion['label'] }) => {
  if (typeof label === 'object' && label !== null && 'text' in label)
    return (
      <Tag color={label.color ?? 'purple'} variant="secondary">
        {label.text}
      </Tag>
    );

  return label;
};

export const PromotionHeader = ({ promotion }: { promotion: Promotion }) => {
  const { label, title } = promotion;

  return (
    <Flex padding="0 space20" direction="column" gap="space8">
      {label && <PromotionLabel label={label} />}
      <MotionText
        color="gray800"
        fontSize="xlarge"
        lineHeight="space24"
        width="100%"
        overflowWrap="break-word"
        variants={textVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.6, ease: 'easeInOut' }}>
        {title}
      </MotionText>
    </Flex>
  );
};
