import { Box, Flex, Paragraph } from '@candisio/design-system';
import { WarningIcon } from 'components/Icons/DefaultIcons';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const WarningBox = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return (
    <Box background="yellowbg" borderRadius="medium" padding="space8">
      <Flex alignItems="center" gap="space8">
        <WarningIcon size="space32" />
        <Paragraph color="yellow700" fontSize="small">
          {t('dashboard.createNewWidget.tabs.infoBox.noContentSetup')}
        </Paragraph>
      </Flex>
    </Box>
  );
};
