import { noop } from 'lodash';
import { createContext } from 'react';
import { UserRealm } from 'utils/authorization-helper';

export const OrganizationContext = createContext<{
  selectedOrganization: string | null;
  selectedUser: string | null;
  setSelectedOrganization: (realm: UserRealm | null) => void;
}>({
  selectedOrganization: null,
  selectedUser: null,
  setSelectedOrganization: noop,
});
