import { FilterOption } from '@candisio/design-system';
import { ArtistSocialInsuranceCode } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ArtistSocialInsuranceCodes = Object.values(ArtistSocialInsuranceCode);

const translationMap: Record<ArtistSocialInsuranceCode, string> = {
  BK: 'document.requestApproval.inputs.artistSocialInsuranceCode.codes.BK',
  DK: 'document.requestApproval.inputs.artistSocialInsuranceCode.codes.DK',
  M: 'document.requestApproval.inputs.artistSocialInsuranceCode.codes.M',
  W: 'document.requestApproval.inputs.artistSocialInsuranceCode.codes.W',
};

export const useArtistSocialInsuranceCodeFilters = () => {
  const [t] = useTranslation();

  const artistSocialInsuranceCodeFilters = useMemo(
    () => ({
      data: Array<FilterOption>().concat(
        ArtistSocialInsuranceCodes.map(code => ({
          id: code,
          label: t(translationMap[code]),
        }))
      ),
    }),
    [t]
  );

  return artistSocialInsuranceCodeFilters;
};
