import { useCallback, useEffect, useState } from 'react';
import { useCardCredentialQuery } from './useCardCredentialQuery';

let timeout: NodeJS.Timeout | null;
// the data disappears after 60s automatically, so we discard the data a bit earlier
const TIME_BEFORE_SENSITIVE_DATA_DISAPPEARS = 55_000;

export const useShowSensitiveData = () => {
  const {
    getTokenToShowSensitiveData,
    isFetchingSensitiveData,
    sensitiveDataToken,
  } = useCardCredentialQuery();

  const [uniqueToken, setUniqueToken] = useState<null | undefined | string>(
    null
  );

  const [token, setToken] = useState<null | undefined | string>(null);

  const resetTokens = useCallback(() => {
    setToken(null);
    setUniqueToken(null);
    timeout && clearTimeout(timeout);
    timeout = null;
  }, []);

  useEffect(() => {
    const uniqueToken = sensitiveDataToken.uniqueToken;
    setToken(sensitiveDataToken.token);
    setUniqueToken(uniqueToken);
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      resetTokens();
    }, TIME_BEFORE_SENSITIVE_DATA_DISAPPEARS);

    // We want to keep sensitiveDataToken in the dependecy array and not compare the properties inside. The unique token in sensitiveDataToken will not change for 40-60s and
    // . . . if we compare the properties inside sensitiveDataToken this useEffect won't get triggered if the user clicks fast on show/hide sensitive data.
  }, [resetTokens, sensitiveDataToken]);

  const sensitiveData = {
    isFetchingSensitiveData,
    onShowCardNumbers: getTokenToShowSensitiveData,
    resetTokens,
    sensitiveDataIsShown: Boolean(token && uniqueToken),
  };

  return { sensitiveData, uniqueToken, token };
};
