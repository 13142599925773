import { Filter, Table } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { useMemoizedState } from 'utils/useMemoizedState';
import { GenericCell as Cell } from './GenericCell/GenericCell';
import { PurchaseOrdersTableData, PurchaseOrdersTableProps } from './types';
import { Header, purchaseOrderColumnDefinition } from './util';

export const PurchaseOrdersTable = ({
  data,
  columnWidths: columnWidthsProp = {},
  customEmptyState: CustomEmptyState,
  onEndReached,
  columns: shownColumns,
  selectionOptions,
  filterOptions,
  defaultFilters,
  defaultSortBy,
  borderTopRadius,
  tableFooter,
  documentAmount,
  ...restProps
}: PurchaseOrdersTableProps) => {
  const columnWidths = useMemoizedState(columnWidthsProp);
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const defaultColumn = useMemo(
    (): Partial<Column<PurchaseOrdersTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof PurchaseOrdersTableData];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              const isLoading = filterOption.isLoading;

              return (
                <Filter<PurchaseOrdersTableData>
                  column={column}
                  options={filterOption.data}
                  isLoading={isLoading}
                  applyFilterButton={t('filterApply')}
                  resetFilterButton={t('filterReset')}
                  searchFieldPlaceholder={t('filterSearchPlaceholder')}
                  filterLabel={t('filterLabel')}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
      disableFilters: !filterOptions,
    }),
    [filterOptions, t]
  );

  const columns: Array<Column<PurchaseOrdersTableData>> = useMemo(() => {
    if (shownColumns && shownColumns.length > 0) {
      return shownColumns.reduce<Array<Column<PurchaseOrdersTableData>>>(
        (result, columnId) => {
          const column = purchaseOrderColumnDefinition({
            documentAmount,
            columnWidths,
          }).find(col => col.accessor === columnId);

          if (column) {
            result.push(column);
          }

          return result;
        },
        []
      );
    }

    return purchaseOrderColumnDefinition({
      documentAmount,
      columnWidths,
    });
  }, [columnWidths, documentAmount, shownColumns]);

  return (
    <Table<PurchaseOrdersTableData>
      columns={columns}
      data={data}
      defaultColumn={defaultColumn}
      initialState={{
        filters: defaultFilters ?? [],
        sortBy: defaultSortBy ?? [],
      }}
      onRowSelected={selectionOptions?.onSelectionRowChanged}
      isSingleSelect
      selectedRowIds={selectionOptions?.selectedRowsIds}
      customEmptyState={CustomEmptyState}
      onEndReached={onEndReached}
      overscan={100}
      selectionOptions={selectionOptions}
      borderTopRadius={borderTopRadius}
      tableFooter={tableFooter}
      filterOptions={filterOptions}
      {...restProps}
    />
  );
};
