import { Paragraph, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { Collapse } from 'components/History/items/Collapse';
import { useTranslation } from 'react-i18next';
import { TransactionReconciledEventDataFragment } from '../../../../generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';
import DateFormatters from '../../../../utils/date_formatter';
import { amountFormat } from '../../../../utils/format';

export const TransactionReconciled = ({
  createdAt,
  properties,
}: TransactionReconciledEventDataFragment) => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const title = tTransactions(
    'documentHistoryCCEvents.transactionDataChanged.text'
  );

  if (!properties || !properties.amount || !createdAt) {
    return null;
  }

  const { value, currency } = properties.amount;
  const formattedAmount = amountFormat(value, currency);

  const formattedDate = DateFormatters.compact(new Date(createdAt));

  return (
    <>
      <Text fontSize="small" lineHeight="paragraph">
        {title}
      </Text>
      <Collapse>
        <MetaDataWrapper>
          <div>
            <Text fontWeight="regular" color="gray500">
              {tTransactions(
                'documentHistoryCCEvents.transactionDataChanged.contact'
              )}
            </Text>
            <Paragraph>{properties.merchantName}</Paragraph>
          </div>
          <div>
            <Text fontWeight="regular" color="gray500">
              {tTransactions(
                'documentHistoryCCEvents.transactionDataChanged.amount'
              )}
            </Text>
            <Paragraph>{formattedAmount}</Paragraph>
          </div>
          <div>
            <Text fontWeight="regular" color="gray500">
              {tTransactions(
                'documentHistoryCCEvents.transactionDataChanged.date'
              )}
            </Text>
            <Paragraph>{formattedDate}</Paragraph>
          </div>
        </MetaDataWrapper>
      </Collapse>
    </>
  );
};
