import {
  PurchaseOrderFieldItem,
  PurchaseOrderItem,
} from 'components/PurchaseOrders/PurchaseOrderItem';

interface GetFormattedPurchaseOrderItemProps {
  items: PurchaseOrderFieldItem[] | undefined;
}

export const getFormattedPurchaseOrderItem = ({
  items,
}: GetFormattedPurchaseOrderItemProps) => {
  return (items || []).map(({ key, ...props }: PurchaseOrderFieldItem) => ({
    key,
    textValue: props.orderNumber,
    children: <PurchaseOrderItem key={key} {...props} />,
  }));
};
