import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { queryParameter } from 'components/Table/consts';
import { useEcmDocumentsTableData } from 'containers/document-relationships/useEcmDocumentsTableData';
import { EcmDocumentType } from 'generated-types/graphql.types';
import { AppRouteParams, Routes } from 'models';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';

export const EcmDocuments = () => {
  const { organizationSlug } = useParams<AppRouteParams>();

  const navigate = useNavigate();

  const {
    availableDocumentColumnIds,
    searchQuery,
    searchParams,
    data,
    isLoading,
    filters,
    isTableFiltered,
    configurationsTable,
    totalCount,
    isSavingConfigurations,
    isResetPending,
    customEmptyState,
    sortBy,
    handleSearchChange,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    onFilter,
    onLoadMore,
    onSort,
  } = useEcmDocumentsTableData({ filterParamsSource: 'url' });

  const handleRowClick = (
    documentId: string,
    documentType: EcmDocumentType,
    cursor?: string
  ) => {
    if (!organizationSlug) {
      return;
    }

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:documentId`,
      { organizationSlug, documentId }
    );

    const isInvoice = documentType === EcmDocumentType.Invoice;

    if (isInvoice) {
      searchParams.set('isInvoice', 'true');
    }

    if (cursor) {
      searchParams.set('cursor', cursor);
    }

    if (searchQuery) {
      searchParams.set(queryParameter, searchQuery);
    }

    navigate({ pathname, search: searchParams.toString() });
  };

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}>
      <EcmDocumentsTable
        context="documents"
        columns={availableDocumentColumnIds}
        data={data}
        isLoading={isLoading}
        defaultFilters={filters}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        searchQuery={searchQuery}
        selectedDocumentsCount={totalCount}
        defaultSortBy={sortBy}
        isLoadingConfigurations={isSavingConfigurations || isResetPending}
        onRowClick={handleRowClick}
        onSearchChange={handleSearchChange}
        onUpdateConfigurations={handleUpdateConfigurations}
        onFilter={onFilter}
        onEndReached={onLoadMore}
        onSort={onSort}
        customEmptyState={customEmptyState}
        onResetTableConfigurations={handleResetTableConfigurations}
      />
    </ArchiveViewLayout>
  );
};
