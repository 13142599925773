import { ErrorMessages } from 'utils/zodFormValidation';
import { accountingInfoFormSchema } from './accountingInfoFormSchema';

type ExcludeKeys =
  | 'approvalMode'
  | 'artistSocialInsuranceCode'
  | 'contact'
  | 'contact.inputValue'
  | 'contact.value'
  | 'costCenter'
  | 'costCenter.inputValue'
  | 'costCenter.value'
  | 'workflow'
  | 'taxCode'
  | 'generalLedgerAccount.value'
  | 'costObject'
  | 'generalLedgerAccount.inputValue'
  | 'generalLedgerAccount'
  | 'extraCostInfo.value'
  | 'extraCostInfo.inputValue'
  | 'extraCostInfo'
  | 'costObject.value'
  | 'costObject.inputValue'
  | 'noInvoiceNeeded';

export const getAccountingInfoFormErrorMessages = (): ErrorMessages<
  ReturnType<typeof accountingInfoFormSchema>,
  ExcludeKeys
> => ({
  'approvers.0.approvers': {
    label: 'document.requestApproval.inputs.approvalStep.errorLabel',
  },
  approvers: {
    label: 'document.requestApproval.inputs.approvalStep.errorLabel',
  },
});
