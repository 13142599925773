import { LocationListener, Location } from 'history';
import { snakeCase } from 'lodash';
import { compiledRoutes } from 'models';
import { matchRoutes } from 'react-router-dom-v5-compat';

const pathRegExp = /\/(?<organization>[^/]+)\/(?<category>[^/]+)/;

let prevPathname: string;

/**
 * Listen and notify Segment of client-side page updates.
 *
 * Based on: https://www.pullrequest.com/blog/adding-segment-for-react/
 */
export const segmentLocationListener: LocationListener = location => {
  const { pathname } = location;
  if (prevPathname === pathname) {
    return;
  }

  const { category, pathnameWithoutOrganization, organization } =
    extractPathnameWithoutOrganization(pathname);

  const routeInfo = getRouteInfoFromLocation({
    organizationSlug: organization,
    location,
  });

  window.analytics.page(category, routeInfo?.path ?? undefined, {
    path: pathnameWithoutOrganization,
    title: '', // skip title as it contains organization id which is not useful in `title` property
    organization_id: organization,
    ...routeInfo?.params,
  });

  prevPathname = pathname;
};

export const extractPathnameWithoutOrganization = (pathname: string) => {
  const groups = pathname.match(pathRegExp)?.groups;
  const category = groups?.category;
  const organization = groups?.organization;

  const pathnameWithoutOrganization = pathname.replace(`/${organization}`, '');

  return { category, pathnameWithoutOrganization, organization };
};

export const getRouteInfoFromLocation = ({
  organizationSlug,
  location,
}: {
  organizationSlug?: string;
  location: Location;
}) => {
  const normalizedRoutes = Object.values(compiledRoutes).map(route => ({
    path: `${organizationSlug}${route}`,
  }));

  const [match] = matchRoutes(normalizedRoutes, location) ?? [];
  if (!match) return null;

  const path = organizationSlug
    ? match.route.path.replace(organizationSlug, '')
    : match.route.path;

  const hasParams = Object.keys(match.params).length > 0;
  let snakeCaseParams = {};

  if (hasParams)
    snakeCaseParams = Object.entries(match.params).reduce(
      (prev, [key, value]) => {
        if (value) prev[snakeCase(key)] = value;

        return prev;
      },
      {} as Record<string, string>
    );

  return { path, params: snakeCaseParams };
};
