import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';

type FieldNames = Record<
  keyof ExpensesFormOutput['generalExpenses'][number],
  string
>;

export const generateGeneralExpenseFormFieldNames = (
  index: number
): FieldNames => ({
  expenseDate: `generalExpenses.${index}.expenseDate`,
  expenseId: `generalExpenses.${index}.expenseId`,
  files: `generalExpenses.${index}.files`,
  reason: `generalExpenses.${index}.reason`,
  totalAmount: `generalExpenses.${index}.totalAmount`,
});
