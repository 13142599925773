import { Box } from '@candisio/design-system';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { ExportDetails } from '../../types';

interface ExportProtocolProps {
  exportDetails: ExportDetails;
}

const getTimeFrameMonth = (month: number) => {
  const monthString = String(month).padStart(2, '0');

  return moment(monthString, 'MM').format('MMMM');
};

export const ExportProtocol = ({ exportDetails }: ExportProtocolProps) => {
  const [t] = useTranslation();

  return (
    <Box padding="0 1rem 2rem" color="gray700">
      {exportDetails.timeFrame && (
        <p>
          {t('export.exportDetails.exportedTimeFrame', {
            ...exportDetails.timeFrame,
            month: getTimeFrameMonth(exportDetails.timeFrame.month),
          })}
        </p>
      )}
    </Box>
  );
};
