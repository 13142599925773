import { PDFDetails } from 'components/DocumentViewer/utils';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import {
  useGetDocumentForDraftQuery,
  DocumentStatus,
  DocumentCurrency,
  EcmDocumentStatus,
  Maybe,
  GetDocumentForDraftQuery,
  TransactionDetailsDataFragment,
  Money,
} from 'generated-types/graphql.types';
import { useAttachments } from 'hooks/useCanAddAttachments/useAttachments';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { noop } from 'lodash';
import { AppRouteParams, DocumentProcessingRouteParams, Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useDocumentFile } from './hooks/useDocumentFile';
import { useDocumentNavigation } from './hooks/useDocumentNavigation';
import { useUnlinkTransaction } from './hooks/useUnlinkTransaction';

export interface RouteParams {
  [AppRouteParams.organizationSlug]: string;
  [DocumentProcessingRouteParams.documentId]: string;
  [DocumentProcessingRouteParams.type]:
    | Routes.APPROVALS
    | Routes.INBOX
    | Routes.ARCHIVE;
}
const OPEN_DOCUMENT_STATUSES = [
  EcmDocumentStatus.Open,
  DocumentStatus.Open,
] as const;

const EDITABLE_DOCUMENT_STATUSES = [
  EcmDocumentStatus.New,
  EcmDocumentStatus.Open,
  EcmDocumentStatus.Approved,
  EcmDocumentStatus.Rejected,
] as const;

type ProcessingDocument = GetDocumentForDraftQuery['getDocument'];

interface DocumentProcessingContextResult {
  documentId: string;
  document: ProcessingDocument | undefined;
  documentStatus: EcmDocumentStatus | DocumentStatus;
  documentAmount: number | undefined;
  documentCurrency: DocumentCurrency | undefined;
  invoiceDate: string | undefined;
  invoiceNumber: string | undefined;
  amount: Money | null;
  isWaitingForClarification: boolean;
  contactItem: ProcessingFormContactFieldItem | undefined;
  transaction: TransactionDetailsDataFragment | undefined;
  mainDocumentFile: PDFDetails;
  transactionId: string | undefined;
  contactName: string | undefined;
  accountsPayableNumber: Maybe<string>;
  isDocumentDataLoading: boolean;
  isDocumentFileLoading: boolean;
  useAttachmentsResult: ReturnType<typeof useAttachments>;
  canAddTransaction: boolean;
  handleGetContactItem: (value?: ProcessingFormContactFieldItem) => void;
  useDocumentNavigationResult: Omit<
    ReturnType<typeof useDocumentNavigation>,
    'editRequestedDocumentMode' | 'gotoDocumentListView'
  >;
  isUnlinkPending: boolean;
  unlinkTransaction: () => Promise<void>;
  selectedContactEmail?: string;
  canUseStoredContactInfo: boolean;
  hasContextMenu: boolean;
  showPurchaseOrderSection: boolean;
  showTransactionSection: boolean;
  showWaitingForClarification: boolean;
  showDocumentRelations: boolean;
}

const initialState: DocumentProcessingContextResult = {
  transaction: undefined,
  mainDocumentFile: {
    name: '',
    url: '',
  },
  documentId: '',
  document: undefined,
  documentStatus: EcmDocumentStatus.New,
  documentAmount: undefined,
  documentCurrency: undefined,
  invoiceDate: undefined,
  invoiceNumber: undefined,
  amount: null,
  isWaitingForClarification: false,
  contactItem: undefined,
  transactionId: undefined,
  contactName: undefined,
  accountsPayableNumber: undefined,
  isDocumentDataLoading: false,
  isDocumentFileLoading: false,
  useAttachmentsResult: {
    canAttach: false,
    canOnlyAttachFromCandis: false,
    canOnlyAttachFromDisk: false,
    isAttachActionDisabled: true,
    attachments: [],
    attachmentListItems: [],
    attachPermissions: { fromCandis: false, fromDisk: false },
    selectedPdf: { name: '', url: '' },
    setSelectedPdf: noop,
  },
  canAddTransaction: false,
  handleGetContactItem: noop,
  useDocumentNavigationResult: {
    listNavigatorTitle: '',
    isNavigationLoading: false,
    gotoPrevDocument: undefined,
    gotoNextDocument: undefined,
    enableEditMode: noop,
    disableEditMode: noop,
    cycleDocument: noop,
    handleBackToList: noop,
    getOpenDocumentLink: (..._args: any[]) => '',
    navigate: noop,
  },
  isUnlinkPending: false,
  unlinkTransaction: Promise.resolve,
  selectedContactEmail: undefined,
  canUseStoredContactInfo: false,
  hasContextMenu: false,
  showPurchaseOrderSection: false,
  showTransactionSection: false,
  showWaitingForClarification: false,
  showDocumentRelations: false,
} as const;

export const DocumentProcessingContext =
  createContext<DocumentProcessingContextResult>(initialState);

interface DocumentProcessingContextProviderProps {
  children: ReactNode;
}

export const InboxContextProvider = ({
  children,
}: DocumentProcessingContextProviderProps) => {
  const { isOnlyApprover } = useUserRoles();
  const creditCardsSetup = useCreditCardsSetup();

  const [visualiseWaitingForClarificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.visualiseWaitingForClarification,
  ]);

  const { showDocumentRelations } = useEcm();

  const { shouldUseSapPurchaseOrder } = useSap();

  const { documentId } = useParams<RouteParams>();

  const [contactItem, setContactItem] =
    useState<ProcessingFormContactFieldItem>();

  const selectedContactEmail = contactItem?.email;

  const { data: documentQuery, loading: isDocumentDataLoading } =
    useGetDocumentForDraftQuery({ variables: { id: documentId } });

  const document: ProcessingDocument | undefined =
    documentQuery?.getDocument ?? undefined;

  const invoiceNumber = document?.invoiceNumber?.value ?? undefined;
  const invoiceDate = document?.invoiceDate?.value ?? undefined;
  const transaction = document?.transactions?.[0] ?? undefined;
  const transactionId = transaction?.id;
  const documentAmount = document?.amount?.value ?? undefined;
  const documentCurrency = document?.currency?.value ?? undefined;
  const contactName =
    document?.contact?.value?.name?.value ??
    document?.extractedContact?.name?.value;

  const accountsPayableNumber =
    document?.contact?.value?.accountsPayableNumber ?? undefined;

  const documentStatus: EcmDocumentStatus | DocumentStatus =
    document?.status ?? EcmDocumentStatus.New;

  const purchaseOrderId = document?.purchaseOrderData?.purchaseOrderId;
  const isWaitingForClarification =
    visualiseWaitingForClarificationFF && !!document?.isWaitingForClarification;

  const { documentFile, isDocumentFileLoading, documentAttachments } =
    useDocumentFile(documentId);

  const useAttachmentsResult = useAttachments({
    documentFile,
    documentId,
    documentAttachments,
  });

  const {
    editRequestedDocumentMode,
    gotoDocumentListView,
    ...useDocumentNavigationResult
  } = useDocumentNavigation();

  // we somehow ended up here without a valid document id selected for the view
  useEffect(() => {
    if (document || isDocumentDataLoading || editRequestedDocumentMode) return;

    gotoDocumentListView();
  }, [
    document,
    isDocumentDataLoading,
    editRequestedDocumentMode,
    gotoDocumentListView,
  ]);

  const isTransactionEditable =
    !!documentStatus &&
    EDITABLE_DOCUMENT_STATUSES.includes(documentStatus as any);

  const canAddTransaction =
    creditCardsSetup.isInUse && isTransactionEditable && !transaction;

  const isDocumentOpen =
    !!documentStatus && OPEN_DOCUMENT_STATUSES.includes(documentStatus as any);

  const hasContextMenu =
    isTransactionEditable && !isOnlyApprover && !!documentStatus;

  const handleGetContactItem = useCallback(
    (value?: ProcessingFormContactFieldItem) => {
      setContactItem(value);
    },
    []
  );

  const { isUnlinkPending, unlinkTransaction } = useUnlinkTransaction(
    transactionId,
    documentId
  );

  const canUseStoredContactInfo = isDocumentOpen && !editRequestedDocumentMode;

  const hasDocumentAmount =
    documentCurrency !== undefined && documentAmount !== undefined;

  const amount = useMemo((): Money | null => {
    if (!hasDocumentAmount) return null;

    return {
      __typename: 'Money',
      amount: documentAmount,
      currency: documentCurrency,
      precision: 2,
    };
  }, [documentAmount, documentCurrency, hasDocumentAmount]);

  const showPurchaseOrderSection = shouldUseSapPurchaseOrder && !transactionId;
  const showTransactionSection =
    !showDocumentRelations && creditCardsSetup.isInUse && !purchaseOrderId;

  const context: DocumentProcessingContextResult = useMemo(
    () => ({
      documentId,
      document,
      amount,
      documentStatus,
      documentAmount,
      documentCurrency,
      invoiceDate,
      invoiceNumber,
      mainDocumentFile: documentFile,
      isWaitingForClarification,
      transaction,
      transactionId,
      contactItem,
      contactName,
      accountsPayableNumber,
      isDocumentDataLoading,
      isDocumentFileLoading,
      useAttachmentsResult,
      canAddTransaction,
      handleGetContactItem,
      useDocumentNavigationResult,
      isUnlinkPending,
      unlinkTransaction,
      selectedContactEmail,
      canUseStoredContactInfo,
      hasContextMenu,
      showPurchaseOrderSection,
      showTransactionSection,
      showWaitingForClarification: visualiseWaitingForClarificationFF,
      showDocumentRelations,
    }),
    [
      accountsPayableNumber,
      amount,
      canAddTransaction,
      canUseStoredContactInfo,
      contactItem,
      contactName,
      document,
      documentAmount,
      documentCurrency,
      documentFile,
      documentId,
      documentStatus,
      handleGetContactItem,
      hasContextMenu,
      invoiceDate,
      invoiceNumber,
      isDocumentDataLoading,
      isDocumentFileLoading,
      isUnlinkPending,
      isWaitingForClarification,
      selectedContactEmail,
      showDocumentRelations,
      showPurchaseOrderSection,
      showTransactionSection,
      transaction,
      transactionId,
      unlinkTransaction,
      useAttachmentsResult,
      useDocumentNavigationResult,
      visualiseWaitingForClarificationFF,
    ]
  );

  return (
    <DocumentProcessingContext.Provider value={context}>
      {children}
    </DocumentProcessingContext.Provider>
  );
};

export const useInboxContext = () => useContext(DocumentProcessingContext);
