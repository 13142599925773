import { Card, Text, Grid, Button, Image } from '@candisio/design-system';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { useCreateReimbursement } from '../hooks/useCreateReimbursement';
import recieptIcon from '../media/receipt.svg';

export const CreateReimbursementCard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  const { createReimbursement, createReimbursementPending } =
    useCreateReimbursement();

  const handleCreate = async () => {
    const reimbursementId = await createReimbursement();

    if (!reimbursementId) {
      return;
    }

    const reimbursementLink = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.REIMBURSEMENT}/:reimbursementId?`,
      { organizationSlug, reimbursementId }
    );

    navigate(reimbursementLink);
  };

  return (
    <Grid
      background="gray0"
      padding="space16"
      gap="space24"
      placeItems="center"
      placeContent="center"
      borderRadius="medium">
      <Grid gap="space12" templateColumns="repeat(3, auto)">
        <Card background="pink100" opacity="50%">
          <Image src={recieptIcon} alt="reciept icon" />
        </Card>
        <Card background="pink100">
          <Image src={recieptIcon} alt="reciept icon" />
        </Card>
        <Card background="pink100" opacity="50%">
          <Image src={recieptIcon} alt="reciept icon" />
        </Card>
      </Grid>
      <Text textAlign="center">{t('dashboard.createExpense.text')}</Text>
      <Button onClick={handleCreate} loading={createReimbursementPending}>
        {t('dashboard.createExpense.createCTA')}
      </Button>
    </Grid>
  );
};
