import { Flex, Tag, Text } from '@candisio/design-system';
import { usePromotionsContext } from 'components/NewPromotions/context/PromotionsProvider';
import { PromotionBenefit } from 'components/NewPromotions/types';

type PromotionBenefitItemProps = {
  benefit: PromotionBenefit;
  index: number;
};

const inlinePaddingPixel = 20;
const borderPixel = 2;

export const PromotionBenefitItem = ({
  benefit,
  index,
}: PromotionBenefitItemProps) => {
  const { text, tags } = benefit;
  const { currentBenefitIndex, goToBenefit } = usePromotionsContext();

  const isActive = currentBenefitIndex === index;

  return (
    <Flex
      paddingRight="space12"
      alignItems="center"
      {...(isActive && {
        borderLeft: `${borderPixel}px solid purple500`,
        background: 'purplebg',
      })}
      {...(goToBenefit && {
        cursor: 'pointer',
        hover: { background: 'purplebg' },
        onClick: () => goToBenefit?.(index),
      })}>
      <Flex
        justifyContent="start"
        alignItems="center"
        gap="space8"
        paddingY="space4"
        paddingLeft={
          isActive ? inlinePaddingPixel - borderPixel : inlinePaddingPixel
        }>
        <Text
          style={{ width: '100%', wordBreak: 'break-word', hyphens: 'auto' }}>
          {text}
        </Text>
        {tags &&
          tags.map(tag => (
            <Tag color="purple" key={tag}>
              {tag}
            </Tag>
          ))}
      </Flex>
    </Flex>
  );
};
