import { Grid, Icon, Text } from '@candisio/design-system';
import { DocumentFormContainer } from 'components/Form/DocumentForm/DocumentFormContainer';
import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DocumentStatus } from 'generated-types/graphql.types';
import { DocumentStatusFrontend } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { useTranslation } from 'react-i18next';
import { isPaidDocument } from 'utils/document_payment_data';

interface ExportedProps {
  isLoading?: boolean;
  document?: ProcessedDocument;
}

export const ExportedDocumentFormFooter = ({
  translation,
}: {
  translation: string;
}) => (
  <Grid autoFlow="column" padding="space4 space8" gap="space4">
    <Icon icon="infoCircle" color="blue500" size="space24" />
    <Text color="gray700">{translation}</Text>
  </Grid>
);

export const Exported = ({ document, isLoading }: ExportedProps) => {
  const [t] = useTranslation();

  const { shouldUseCoreDataApi } = useOtherIntegration();

  if (!document) {
    // TODO Add error state
    return null;
  }

  return (
    <ProcessSidebar
      documentId={document?.id}
      documentStatus={
        isPaidDocument(document || {})
          ? DocumentStatusFrontend.ExportedAndPaid
          : DocumentStatus.Exported
      }>
      {/* TODO add loading state */}
      {isLoading ? null : (
        <DocumentFormContainer
          document={document}
          allowInlineEditing
          footer={
            !shouldUseCoreDataApi && (
              <ExportedDocumentFormFooter
                translation={t('document.details.exported.info')}
              />
            )
          }
        />
      )}
    </ProcessSidebar>
  );
};
