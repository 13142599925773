import { useApolloClient } from '@apollo/client';
import debounce from 'debounce-promise';
import {
  ContactByNameQuery,
  ContactByNameQueryVariables,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { contactByNameQuery } from 'views/queries/contacts';
import { CheckContactName } from './ContactForm/contactNameSchema';

const DEBOUNCE_MS = 500;

export interface UseCheckContactNameOptions {
  originalValue?: string;
  contactId?: string;
}

/** Returns a (debounced) function that checks if contact name is available. */
export const useCheckContactName = ({
  originalValue,
  contactId,
}: UseCheckContactNameOptions = {}) => {
  const apolloClient = useApolloClient();

  const checkContactName = useMemo(() => {
    const callback: CheckContactName = async value => {
      if (typeof value === 'string' && value !== originalValue) {
        const { data } = await apolloClient.query<
          ContactByNameQuery,
          ContactByNameQueryVariables
        >({
          query: contactByNameQuery,
          variables: { name: value },
        });

        const contact = data.contactByName;

        if (contact?.__typename === 'Contact' && contact.id !== contactId) {
          return { isAvailable: false };
        }
      }

      return { isAvailable: true };
    };

    return debounce(callback, DEBOUNCE_MS);
  }, [apolloClient, contactId, originalValue]);

  return checkContactName;
};
