import {
  WidgetFormAnnualBudget,
  WidgetFormQuarterBudget,
} from 'components/Insights/Modals/types';
import {
  GetInsightsWidgetQuery,
  InsightBudgetType,
  InsightsWidgetBudgets,
  InsightsWidgetBudgetsInput,
  InsightsWidgetMonthlyBudgetInput,
} from 'generated-types/graphql.types';
import { Month, QUARTER, QuarterBudget, YearlyBudget } from '../types';

//Takes budget mapped in months and translate it into quarters
export const translateMonthlyBudgetsToQuarters = (
  budget: InsightsWidgetBudgetsInput
) => {
  const manifest = [
    { key: Month.JANUARY, quarter: QUARTER.Q1 },
    { key: Month.FEBRUARY, quarter: QUARTER.Q1 },
    { key: Month.MARCH, quarter: QUARTER.Q1 },
    { key: Month.APRIL, quarter: QUARTER.Q2 },
    { key: Month.MAY, quarter: QUARTER.Q2 },
    { key: Month.JUNE, quarter: QUARTER.Q2 },
    { key: Month.JULY, quarter: QUARTER.Q3 },
    { key: Month.AUGUST, quarter: QUARTER.Q3 },
    { key: Month.SEPTEMBER, quarter: QUARTER.Q3 },
    { key: Month.OCTOBER, quarter: QUARTER.Q4 },
    { key: Month.NOVEMBER, quarter: QUARTER.Q4 },
    { key: Month.DECEMBER, quarter: QUARTER.Q4 },
  ];

  let q1: number = 0;
  let q2: number = 0;
  let q3: number = 0;
  let q4: number = 0;

  manifest.forEach(({ key, quarter }) => {
    const monthlyBudget =
      budget.monthlyBudgets[key as keyof InsightsWidgetMonthlyBudgetInput] || 0;

    if (quarter === QUARTER.Q1) {
      q1 += monthlyBudget;
    } else if (quarter === QUARTER.Q2) {
      q2 += monthlyBudget;
    } else if (quarter === QUARTER.Q3) {
      q3 += monthlyBudget;
    } else if (quarter === QUARTER.Q4) {
      q4 += monthlyBudget;
    }
  });

  const quarterBudgets = {
    year: budget.year,
    quarters: {
      q1: q1 || undefined,
      q2: q2 || undefined,
      q3: q3 || undefined,
      q4: q4 || undefined,
    },
  };

  return quarterBudgets;
};

//Takes budget mapped in months and translate it into year budget
export const translateMonthlyBudgetsToYear = (
  budget: InsightsWidgetBudgets
) => {
  const { __typename, ...restProps } = budget.monthlyBudgets;
  const budgetvalues = Object.values(restProps);
  const annualBudgetAmount = budgetvalues.reduce((acc, value) => {
    return (acc || 0) + (value || 0);
  }, 0);

  return {
    year: budget.year,
    budget: annualBudgetAmount,
  };
};

export const mapQuarterBudgetsToFormFields = (
  quarterBudgets: QuarterBudget
) => {
  return {
    [`q1_${quarterBudgets.year}`]: quarterBudgets.quarters.q1,
    [`q2_${quarterBudgets.year}`]: quarterBudgets.quarters.q2,
    [`q3_${quarterBudgets.year}`]: quarterBudgets.quarters.q3,
    [`q4_${quarterBudgets.year}`]: quarterBudgets.quarters.q4,
  };
};

export const mapYearsToFormFields = (yearlyBudget: YearlyBudget) => {
  return { [`year${yearlyBudget.year}`]: yearlyBudget.budget };
};

//It maps the budgets into the form fields

export const getInitialBudgetFields = (
  widgetData: GetInsightsWidgetQuery | undefined
) => {
  const budgetsType = widgetData?.getInsightsWidget?.budget?.[0]?.type;
  let mapBudgetsToFieldNames: WidgetFormAnnualBudget | WidgetFormQuarterBudget =
    {};

  if (budgetsType === InsightBudgetType.Yearly) {
    const yearlyBudgets = (widgetData?.getInsightsWidget?.budget || []).map(
      translateMonthlyBudgetsToYear
    );

    mapBudgetsToFieldNames = Object.assign(
      {},
      ...yearlyBudgets.map(mapYearsToFormFields)
    );
  } else if (budgetsType === InsightBudgetType.Quarterly) {
    const quarterlyBudgets = (widgetData?.getInsightsWidget?.budget || []).map(
      translateMonthlyBudgetsToQuarters
    );

    mapBudgetsToFieldNames = Object.assign(
      {},
      ...quarterlyBudgets.map(mapQuarterBudgetsToFormFields)
    );
  }

  return mapBudgetsToFieldNames;
};
