import { Checkbox, Grid } from '@candisio/design-system';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TeamMemberFormValues } from '../teamMemberFormSchemaDeprecated';

export const IssueCreditCardField = () => {
  const [t] = useTranslation();
  const { isInUse } = useCreditCardsSetup();

  return (
    <Controller<TeamMemberFormValues, 'issueCreditCard'>
      name="issueCreditCard"
      render={({ field: { onChange, value } }) => {
        return (
          <Grid templateColumns="auto 1fr" gap="space8">
            <Checkbox
              id="issue-credit-card"
              name="issue-credit-card"
              value="CreditCard"
              isDisabled={!isInUse}
              isSelected={value}
              onChange={isSelected => onChange(isSelected)}>
              {t('settings.teamMembers.form.issueCreditCard.field.text')}
            </Checkbox>
          </Grid>
        );
      }}
    />
  );
};
