import { AmountCell } from 'components/Table/Cells/Amount';
import { TransactionAmount } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { Row } from 'react-table';
import { amountFormatNonNull } from 'utils/format';
import { TransactionsTableData } from '../Table/types';

interface AmountCellProps {
  value: TransactionAmount;
  row: Row<TransactionsTableData>;
}

/**
 * Transaction amount is the real amount which could be in a different currency.
 * The prop value here corresponds to billingAmount which is always in Euro.
 * Both are always returned in cents from the BE and need to be converted into a readable format.
 **/

export const AmountCellTransaction = ({ value, row }: AmountCellProps) => {
  const { transactionAmountFormatter } = useMoneyFormatter();
  const transactionAmount = row.original.transactionAmount;

  if (!value && isNil(transactionAmount)) {
    return <AmountCell formattedAmount={null} />;
  }

  const formattedAmount = amountFormatNonNull(value.value, value.currency, {
    convertToBasicMonetaryUnit: true,
  });

  const isTransactionPaidInDifferentCurrency =
    transactionAmount?.currency !== 'EUR';

  if (isTransactionPaidInDifferentCurrency) {
    const formattedTransactionAmount = transactionAmountFormatter({
      currency: transactionAmount?.currency ?? 'EUR',
      precision: transactionAmount?.precision ?? 2,
      value: transactionAmount?.value ?? 0,
    });

    const text = `${formattedTransactionAmount} (${formattedAmount})`;

    return <AmountCell formattedAmount={text} />;
  }

  return <AmountCell formattedAmount={formattedAmount} />;
};
