import { cloneDeep, get, isEqual, set } from 'lodash';
import { ReactNode, useCallback, useState } from 'react';
import {
  StorageFormMetadata,
  StorageFormMetadataContext,
} from './StorageFormMetadataContext';
import {
  StorageFormSetFieldMetadata,
  StorageFormSetMetadataContext,
} from './StorageFormSetMetadataContext';

export interface StorageFormProviderProps {
  children: ReactNode;
  defaultMetadata?: StorageFormMetadata;
}

/** Provider for getting and setting storage form metadata */
export const StorageFormMetadataProvider = ({
  children,
  defaultMetadata = {},
}: StorageFormProviderProps) => {
  const [metadata, setMetadata] = useState(defaultMetadata);

  // useCallback gives us a stable reference to the setter function
  const setFieldMetadata: StorageFormSetFieldMetadata = useCallback(
    (path, meta) => {
      setMetadata(prevData => {
        if (isEqual(get(prevData, path), meta)) {
          // performance optimization: don’t update metadata state if new value
          // is equal to old according to Lodash isEqual
          return prevData;
        }

        const newData = cloneDeep(prevData);
        set(newData, path, meta);

        return newData;
      });
    },
    []
  );

  return (
    // We use two separate React contexts: one for the metadata itself and one
    // for the setter.
    // This is a necessary performance optimization. See this guide from the
    // React docs for more info:
    // https://react.dev/learn/scaling-up-with-reducer-and-context
    <StorageFormMetadataContext.Provider value={metadata}>
      <StorageFormSetMetadataContext.Provider value={setFieldMetadata}>
        {children}
      </StorageFormSetMetadataContext.Provider>
    </StorageFormMetadataContext.Provider>
  );
};
