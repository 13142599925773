import { useUpdateTeamMutation } from 'generated-types/graphql.types';
import { teamQuery } from '../queries';
import { sanitizeMembers } from '../utils/sanitizeMembers';
import { TeamDetailsFormOutput } from '../utils/teamDetailsFormSchema';

export const useUpdateTeam = () => {
  const [updateTeamMutation, { loading: isUpdateTeamPending, client }] =
    useUpdateTeamMutation({
      onCompleted: () => {
        client.cache.evict({ fieldName: 'teams' });
      },
    });

  return {
    isUpdateTeamPending,
    updateTeam: async (id: string, values: TeamDetailsFormOutput) => {
      const { teamName, teamAdmin, teamMembers } = values;
      const response = await updateTeamMutation({
        variables: {
          id,
          input: {
            name: teamName,
            members: sanitizeMembers({ teamAdmin, teamMembers }),
          },
        },
        refetchQueries: [{ query: teamQuery, variables: { id } }],
      });

      if ((response.errors ?? []).length > 0) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
  };
};
