import { Button, Grid, defaultTheme } from '@candisio/design-system';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MotionNavigation,
  navigationTransition,
  navigationVariants,
} from './animations';
import { useNavigationIcons } from './components/hooks/useNavigationIcons';
import { UserProfile } from './components/MenuItems/UserProfile/UserProfile';
import { NavigationIcon } from './components/NavigationIcon';
import { NavigationIcons } from './components/NavigationIcons';
import { OrganizationSwitcher } from './components/OrganizationSwitcher';

const SMALL_SCREEN_WIDTH = 1280;
export const MAIN_NAVIGATION_OPEN_ONLOAD = 'main-navigation-open-onload';

export const NavigationSidebar = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);

  // TODO: define windowWidth or windowHeight as default size
  const windowWidth = useRef(window.innerWidth);
  const isLargeDesktop = windowWidth.current > SMALL_SCREEN_WIDTH;

  const [isOpen, setIsOpen] = useLocalStorage(
    MAIN_NAVIGATION_OPEN_ONLOAD,
    isLargeDesktop
  );

  const toggleIsOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, [setIsOpen]);

  const [isNavigationOpen, setIsNavigationOpen] = useState(isOpen);
  const [isSwitcherHovered, setIsSwitcherHovered] = useState(false);

  const toggleSidebar = () => {
    setIsNavigationOpen(!isNavigationOpen);
    toggleIsOpen();
  };

  const toggleSwitcher = () => {
    setIsSwitcherHovered(!isSwitcherHovered);
  };

  const { icons, intercomIcon, notificationsIcon } = useNavigationIcons();

  return (
    <MotionNavigation
      id="menu"
      role="menu"
      aria-labelledby="menuButton"
      initial={false}
      variants={navigationVariants}
      animate={isNavigationOpen ? 'open' : 'closed'}
      transition={navigationTransition}
      as="aside"
      alignContent="space-between"
      gridArea="sidebar"
      padding="space24 space16 space24"
      position="relative"
      width="92px"
      gap="auto"
      height="100vh"
      style={{ marginRight: defaultTheme.space.space20 }}>
      <Button
        id="menuButton"
        aria-controls="menu"
        aria-haspopup="true"
        aria-label={
          isNavigationOpen ? t('toggleLabelClosed') : t('toggleLabelOpen')
        }
        aria-pressed={isNavigationOpen}
        color="gray"
        icon={isNavigationOpen ? 'menuExpanded' : 'menuCollapsed'}
        variant="tertiary"
        onClick={toggleSidebar}
        position="absolute"
        right="-20px"
        top="40px"
        zIndex={1}
      />
      <Grid as="nav" gap="space32">
        <OrganizationSwitcher
          isNavigationOpen={isNavigationOpen}
          toggleSwitcher={toggleSwitcher}
        />
        <NavigationIcons icons={icons} isNavigationOpen={isNavigationOpen} />
      </Grid>
      <Grid paddingLeft="space8" gap="space8">
        <NavigationIcon
          {...intercomIcon}
          key={intercomIcon.label}
          expanded={isNavigationOpen}
        />
        <NavigationIcon {...notificationsIcon} expanded={isNavigationOpen} />
        <UserProfile expanded={isNavigationOpen} />
      </Grid>
    </MotionNavigation>
  );
};
