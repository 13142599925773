import { FieldPolicy, TypePolicies } from '@apollo/client';
import {
  CardsConnection,
  CardsConnectionPageBased,
} from 'generated-types/graphql.types';
import {
  paginationWithRecordConnectionsMerge,
  simplePaginationMergeWithoutDuplicates,
} from '../pagination-results-merge';

const cardIssuerCards: FieldPolicy<CardsConnection> = {
  keyArgs: ['filters', 'sortBy', 'queries'],

  merge: paginationWithRecordConnectionsMerge('CardsConnection'),
};

const cardIssuerCardsRecords: FieldPolicy<CardsConnectionPageBased> = {
  keyArgs: ['filters', 'sortBy', 'queries'],
  merge: simplePaginationMergeWithoutDuplicates('CardsConnectionPageBased'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      getCardIssuerCardsForCardManager: cardIssuerCards,
      getPageBasedCardIssuerCards: cardIssuerCardsRecords,
      getCardIssuerCardsForCardholder: cardIssuerCards,
    },
  },
};
