import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';

export const getUpdatedConfigurationsTable = (
  configurations: Configuration[],
  availableColumnsBasedOnOrgSettings: string[]
) => {
  const updatedConfigBasedOnOrgSettings = configurations.map(config => {
    if (!availableColumnsBasedOnOrgSettings.includes(config.id)) {
      return {
        ...config,
        isAvailable: false,
      };
    }

    if (
      availableColumnsBasedOnOrgSettings.includes(config.id) &&
      config.isAvailable === false
    ) {
      return {
        ...config,
        isAvailable: true,
      };
    }

    return { ...config, isAvailable: true };
  });

  const visibleColumnIds = updatedConfigBasedOnOrgSettings
    .filter(
      configurationsTable =>
        configurationsTable.isVisible &&
        availableColumnsBasedOnOrgSettings.includes(configurationsTable.id)
    )
    .map(initialAvailableColumnsConfigs => initialAvailableColumnsConfigs.id);

  return { updatedConfigBasedOnOrgSettings, visibleColumnIds };
};
