import { defaultTheme } from '@candisio/design-system';
import 'antd/dist/antd.css';
// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components';
import { notificationStyles } from './notifications';
import { scrollbarsStyles } from './scrollbar';
import { DefaultTheme } from './themes/DefaultTheme';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

export const ROW_HEIGHT = 32;
export const HEADER_HEIGHT = '48px';
export const SIDEBAR_WIDTH = '8rem';
export const APP_MIN_WIDTH = '1280px';

/**
 * @deprecated Please use the design system instead
 * @see https://candisio.github.io/design-system
 */
const GlobalStyles = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: auto;
  }
  ::selection {
    color: ${defaultTheme.colors.gray0};
    background-color: ${defaultTheme.colors.gray800};
  }

  body {
    font-family: ${defaultTheme.fontFamilies.body};
    font-size: ${defaultTheme.fontSizes.small};
    color: ${defaultTheme.colors.gray800};
    box-sizing: border-box;
    background: ${defaultTheme.colors.gray200};
    min-width: ${APP_MIN_WIDTH};
    overflow: hidden;
  }

  #root, [data-overlay-container="true"] {
    height: 100%;
  }

  p {
    color: ${DefaultTheme.colors.defaultText}
  }
  body h1, h2, h3 {
    color: ${DefaultTheme.colors.defaultText};
  }

  body h1 {
    font-size: ${DefaultTheme.typography.headingFontSize};
    font-weight: 500;
  }

  body h2 {
    font-size: ${DefaultTheme.typography.headingFontSize};
    font-weight: normal;
  }

  body h3 {
      font-size: ${DefaultTheme.typography.headingSmallFontSize};
      font-weight: normal;
  }

  hr {
    border: 0;
    border-top: 1px solid ${DefaultTheme.colors.border};
  }

  ${notificationStyles}

  ${scrollbarsStyles}

  /* put Intercom icon below app banner */
  .intercom-lightweight-app {
    z-index: 99 !important;
  }

  /* https://github.com/ant-design/ant-design/issues/13825#issuecomment-452942830 */
  .ant-table-fixed {
    table-layout: fixed;
  }

  /* https://github.com/ant-design/ant-design/issues/13825#issuecomment-452942830 */
  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
  }

  .ant-table-tbody .ant-table-row.disabled td {
    background-color: ${DefaultTheme.colors.defaultBackground};
    border-bottom-color: ${DefaultTheme.colors.defaultBackground};
  }

  .ant-table-filter-dropdown-btns {
    border-top: 0 !important;
  }

  .ant-dropdown .ant-table-filter-dropdown {
    min-width: 170px;
    padding: 0.5rem !important;
    border-radius: 8px;
  }

  .ant-table-row.ant-table-row-level-0 > td {
    max-height: ${ROW_HEIGHT}px !important;
    height: ${ROW_HEIGHT}px;
  }

  .ant-picker {
    border: 0 !important;
  }

  .ant-picker.ant-picker-disabled {
    background: inherit !important;
    cursor: default !important;

    & input {
      color: ${DefaultTheme.colors.defaultText} !important;
      cursor: default !important;
    }
  }

  .ant-select-item-option:hover:not(.ant-select-item-option-disabled) {
    background-color: ${defaultTheme.colors.bluebg} !important;
     transition: background-color 300ms ease-in;
  }
`;

export { GlobalStyles };
