import { Button, Flex, Grid } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface WizardModalPreviewFooterProps {
  canImport?: boolean;
  onGoBack: () => void;
  importing: boolean;
  onImport: () => void;
  infoList?: ReactNode;
}

export const WizardModalPreviewFooter = ({
  canImport,
  importing,
  onGoBack,
  onImport,
  infoList,
}: WizardModalPreviewFooterProps) => {
  const [t] = useTranslation();

  return (
    <Grid templateColumns="1fr auto" gap="space16">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 1 } }}>
        {infoList}
      </motion.div>
      <Flex gap="0.5rem" alignItems="end" justifyContent="end">
        <motion.div layoutId="backButton">
          <Button
            disabled={importing}
            onClick={onGoBack}
            size="small"
            variant="secondary"
            icon="arrowLeft">
            {t('common.import.goBackToUpload')}
          </Button>
        </motion.div>
        {canImport && (
          <Button
            loading={importing}
            onClick={onImport}
            size="small"
            variant="primary"
            icon="arrowRight"
            iconPosition="right">
            {t('common.import.startImport')}
          </Button>
        )}
      </Flex>
    </Grid>
  );
};
