import { z } from 'zod';
import { CheckTeamName } from '../hooks/useCheckTeamNameAvailability';

export interface TeamDetailsSchemaOptions {
  checkTeamName: CheckTeamName;
}

const baseSchema = z.object({
  teamName: z.string().trim().nonempty(),
  teamAdmin: z.string().nonempty(),
  teamMembers: z.array(z.string()).min(1),
});

const validateTeamNameDuplicate = ({
  checkTeamName,
}: TeamDetailsSchemaOptions) => {
  return baseSchema
    .pick({ teamName: true })
    .superRefine(async ({ teamName }, ctx) => {
      const availability = await checkTeamName(teamName);

      if (!availability?.isAvailable) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['teamName'],
        });
      }
    });
};

const validateTeamAdminNotInMembers = (
  { teamAdmin, teamMembers }: TeamDetailsFormData,
  ctx: z.RefinementCtx
) => {
  if (teamMembers.includes(teamAdmin)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['teamMembers'],
    });
  }
};

export const teamDetailsFormSchema = ({
  checkTeamName,
}: TeamDetailsSchemaOptions) =>
  baseSchema
    .and(validateTeamNameDuplicate({ checkTeamName }))
    .superRefine(validateTeamAdminNotInMembers);

type TeamDetailsFormData = z.infer<typeof baseSchema>;

export type TeamDetailsFormOutput = z.infer<
  ReturnType<typeof teamDetailsFormSchema>
>;

export type TeamDetailsFormValues = Partial<TeamDetailsFormOutput>;
