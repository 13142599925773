import {
  EmptyDataS,
  EmptyDataText,
  EmptyDataWrapper,
} from 'components/EmptyViews/styles';
import { LottieAnimation, nocontactData } from 'components/Lottie/Lottie';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const NoContactsYet = ({ onCreate }: { onCreate: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);
  const { shouldUseSapContacts } = useSap();

  if (shouldUseSapContacts) {
    return (
      <EmptyDataWrapper>
        <LottieAnimation options={{ animationData: nocontactData }} />
        <EmptyDataText>{t('contacts:importHistory.noDataSap')}</EmptyDataText>
      </EmptyDataWrapper>
    );
  }

  return (
    <EmptyDataWrapper>
      <LottieAnimation options={{ animationData: nocontactData }} />
      <EmptyDataText>
        {t('settings.contacts.nodata.forShowAll.title')}
      </EmptyDataText>
      <EmptyDataText>
        <Trans i18nKey="settings.contacts.nodata.forShowAll.pleaseCreate">
          Please
          <EmptyDataS onClick={onCreate}>create</EmptyDataS>
        </Trans>
      </EmptyDataText>
    </EmptyDataWrapper>
  );
};
