import {
  Flex,
  Popover,
  Tag,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import {
  DocumentDateFilters,
  DownloadFilters,
  EcmDocumentFilters,
  Maybe,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { FilterNameContainer, FiltersPopoverContainer } from './styles';
import { useGetReadableEcmAppliedFilterNames } from './useGetReadableEcmFilterNames';
import { getReadableFilterNames, getReadableFiltersDateRanges } from './utils';

export const FilterTags = ({
  filters,
  ecmFilters,
  query,
  dateRangeFilters,
}: {
  filters?: DownloadFilters;
  ecmFilters?: EcmDocumentFilters;
  query?: Maybe<string>;
  dateRangeFilters?: DocumentDateFilters | null;
}) => {
  const [t] = useTranslation();

  const { readableAppliedEcmFilters } =
    useGetReadableEcmAppliedFilterNames(ecmFilters);

  const popoverPosition = 'top right';
  const allReadableFiltersDateRanges = getReadableFiltersDateRanges(
    t,
    dateRangeFilters
  );

  const allReadableFilterNames = getReadableFilterNames(t, filters, query);

  const allFiltersApplied = [
    ...allReadableFilterNames,
    ...allReadableFiltersDateRanges,
    ...readableAppliedEcmFilters,
  ];

  const {
    close,
    isOpen,
    open,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: popoverPosition });

  const NUMBER_OF_FILTERS_TO_SHOW = 5;
  const filtersWithinColumn = allFiltersApplied.slice(
    0,
    NUMBER_OF_FILTERS_TO_SHOW
  );

  const filtersWithinPopover = allFiltersApplied.slice(
    NUMBER_OF_FILTERS_TO_SHOW
  );

  const showEllipsis = filtersWithinPopover.length > 0;

  return (
    <Flex
      gap="space8"
      direction="row-reverse"
      alignItems="center"
      overflow="hidden">
      {filtersWithinColumn.map((filt, index) => (
        <Tag color="gray" key={index} variant="secondary">
          <TruncatedText>{filt}</TruncatedText>
        </Tag>
      ))}
      {showEllipsis ? (
        <Tag
          color="gray"
          onMouseEnter={open}
          {...triggerProps}
          ref={triggerRef}
          onMouseLeave={close}
          variant="secondary">
          ...
        </Tag>
      ) : null}
      {isOpen && (
        <Popover {...popoverProps} onMouseLeave={close} ref={popoverRef}>
          <ReadableFilterNamesPopover
            readableFilterNames={filtersWithinPopover}
          />
        </Popover>
      )}
    </Flex>
  );
};

export const ReadableFilterNamesPopover = ({
  readableFilterNames,
}: {
  readableFilterNames: string[];
}) => {
  return (
    <FiltersPopoverContainer>
      {readableFilterNames.map((filter, index) => {
        return (
          <FilterNameContainer key={index}>
            <Tag color="gray" variant="secondary">
              {filter}
            </Tag>
          </FilterNameContainer>
        );
      })}
    </FiltersPopoverContainer>
  );
};
