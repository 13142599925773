import { Flex, Text, TruncatedText } from '@candisio/design-system';
import { CreditCardDetailsProps } from 'containers/credit-cards/CreditCard/types';

interface DetailsProps {
  cardDetails: CreditCardDetailsProps;
}

export const Details = ({ cardDetails }: DetailsProps) => {
  const { cvv, expiration, name, number, label } = cardDetails;

  return (
    <Flex direction="column" gap="space20" justifyContent="end">
      <Flex direction="column" gap="space8">
        {label ? (
          <TruncatedText
            lineHeight="paragraph"
            fontSize="xsmall"
            fontWeight="semibold"
            maxWidth="144px">
            {label}
          </TruncatedText>
        ) : null}
        <TruncatedText
          lineHeight="paragraph"
          fontWeight="semibold"
          fontSize="small"
          maxWidth="144px">
          {name || ''}
        </TruncatedText>
      </Flex>

      <Flex direction="column" fontSize="small">
        <Text fontSize="12px">{number}</Text>
        <Flex alignSelf="start" gap="space8">
          <Text>{expiration}</Text>
          {cvv ? <Text>CVV {cvv}</Text> : null}
        </Flex>
      </Flex>
    </Flex>
  );
};
