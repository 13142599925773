import { Flex, Grid, Heading, Paragraph } from '@candisio/design-system';
import { Add } from 'components/Insights/Widgets/Add/Add';
import { SumContainer } from 'components/Insights/Widgets/Sum/SumContainer';
import { motion } from 'framer-motion';
import { InsightsWidget } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { MAX_WIDGETS_ON_DASHBOARD } from 'views/InsightsWidgets/utils';
import { fade } from '../../styles';

export interface DashboardWidgetsSectionProps {
  dashboardWidgets: InsightsWidget[];
  onAddWidget: () => void;
}
export const DashboardWidgetsSection = ({
  dashboardWidgets,
  onAddWidget,
}: DashboardWidgetsSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const hasFreeSpaceInDashboard =
    dashboardWidgets.length >= 0 &&
    dashboardWidgets.length < MAX_WIDGETS_ON_DASHBOARD;

  return (
    <Grid gap="space16" id="dashboard-widgets-section">
      <motion.div
        variants={fade}
        initial={fade.hidden}
        data-testid="dashboard-widgets-section">
        <Flex direction="row" gap="space16" alignItems="baseline">
          <Heading as="h3">{t('allWidgets.dashboardSection.title')}</Heading>
          <Paragraph color="gray500">
            {t('allWidgets.dashboardSection.counter', {
              count: dashboardWidgets.length,
            })}
          </Paragraph>
        </Flex>
        <Grid
          autoRows="1fr"
          gap="space16"
          templateColumns="repeat(auto-fill, minmax(250px, 1fr))">
          {dashboardWidgets.map(props => (
            <SumContainer {...props} key={props.id} />
          ))}
          {hasFreeSpaceInDashboard ? (
            <Add
              onAddWidget={() => {
                onAddWidget();
              }}
            />
          ) : null}
        </Grid>
      </motion.div>
    </Grid>
  );
};
