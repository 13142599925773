import { TFunction } from 'react-i18next';
import { z } from 'zod';
import { MAX_FILE_SIZE } from './constants';

export type SelectFileFormValues = z.infer<typeof formConfig.formSchema>;
export const formConfig = {
  formSchema: z.object({
    file: z.object({
      type: z.string().regex(new RegExp('text/csv')),
      size: z.number().lt(MAX_FILE_SIZE),
    }),
  }),
  formErrors: (t: TFunction<'settings'>) => ({
    file: {
      label: t('paymentConditions.import.input.label'),
    },
    'file.type': {
      label: t('paymentConditions.import.input.label'),
      invalid_string: {
        translationKey: t('paymentConditions.import.errors.wrongFileType'),
      },
    },
    'file.size': {
      label: t('paymentConditions.import.errors.fileTooLarge'),
    },
  }),
};
