import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Heading,
  Paragraph,
  ScrollBox,
  Skeleton,
  Text,
} from '@candisio/design-system';
import { History } from 'components/History/History';
import { HistoryLoading } from 'components/History/HistoryLoading';
import { Payment } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { UsePaginationResponse } from 'providers/GraphQLProvider/Pagination/usePagination';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { PaymentStatus } from 'views/Payments/types';
import { LoadMoreItemsButton } from './LoadMoreItemsButton';
import { PaymentsHistoryQuery } from './types';

const PAYMENT_HISTORY_LIMIT = 10;

export interface PaymentHistoryProps {
  selectedPaymentId?: string;
  numberOfDocumentsToBePaid?: number;
  paginationResponse: UsePaginationResponse<PaymentsHistoryQuery>;
  onPaymentSelected: (paymentId: string | null) => void;
  paymentHistoryLoading: boolean;
}

export const PaymentHistory = ({
  selectedPaymentId,
  numberOfDocumentsToBePaid,
  onPaymentSelected,
  paginationResponse,
  paymentHistoryLoading,
}: PaymentHistoryProps) => {
  const { data: payments } = paginationResponse;

  const paginatedPayments = (payments?.payments.records as Payment[]) || [];

  const { documentMoneyFormatter } = useMoneyFormatter();
  const loadingToBePaid = numberOfDocumentsToBePaid === undefined;
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  return (
    <Grid
      paddingTop="space16"
      gap="space8"
      templateRows="auto 1fr"
      overflow="hidden">
      <Heading as="h1">{tPayments('headers.main')}</Heading>
      <ScrollBox height="100%" data-cy="history-sidebar">
        <Grid gap="space16">
          <History>
            <History.Payment
              data-cy="history-main-item"
              size="big"
              header={tPayments('history.header')}
              onClick={() => onPaymentSelected(null)}
              status={PaymentStatus.UNPAID}
              active={!selectedPaymentId}>
              {loadingToBePaid ? (
                <Skeleton height="15px" width="50%" />
              ) : (
                <Text fontSize="small">
                  {tPayments('history.documentsToBePaid', {
                    count: numberOfDocumentsToBePaid,
                  })}
                </Text>
              )}
            </History.Payment>
            {paginatedPayments?.length &&
              paginatedPayments.map(
                ({
                  id,
                  numberOfPaidDocuments,
                  paymentInitiator: { name },
                  creationDate,
                  totalAmount,
                }) => {
                  return (
                    <History.Payment
                      key={id}
                      data-cy="history-main-item"
                      absoluteTime={DateFormatters.regularDatetime(
                        new Date(creationDate)
                      )}
                      header={DateFormatters.compact(new Date(creationDate))}
                      author={name}
                      onClick={() => onPaymentSelected(id)}
                      status={PaymentStatus.PAID}
                      active={selectedPaymentId === id}>
                      <Grid
                        autoFlow="column"
                        justifyContent="space-between"
                        paddingRight="space24">
                        <Text fontSize="small">
                          {tPayments('history.paidDocuments', {
                            count: numberOfPaidDocuments,
                          })}
                        </Text>
                        <Text>{documentMoneyFormatter(totalAmount)}</Text>
                      </Grid>
                    </History.Payment>
                  );
                }
              )}
            {paginatedPayments?.length === 0 ? (
              <>
                <History.Payment
                  nohover="true"
                  active={false}
                  data-cy="history-main-item"
                  status={PaymentStatus.PAID}>
                  <Paragraph width="14rem">
                    {tPayments('history.emptyStateMessage')}
                  </Paragraph>
                </History.Payment>
                <PlaceholderHistoryEntry />
                <PlaceholderHistoryEntry />
                <PlaceholderHistoryEntry />
                <PlaceholderHistoryEntry />
              </>
            ) : null}
            {paymentHistoryLoading ? (
              <HistoryLoading
                itemCount={PAYMENT_HISTORY_LIMIT - 1}
                props={{ paddingTop: 'space16', paddingLeft: '28px' }}
              />
            ) : null}
          </History>
          <LoadMoreItemsButton
            paginationLimit={PAYMENT_HISTORY_LIMIT}
            {...paginationResponse}
          />
        </Grid>
      </ScrollBox>
    </Grid>
  );
};

const PlaceholderHistoryEntry = () => (
  <History.Payment
    active={false}
    nohover="true"
    data-cy="history-main-item"
    status={PaymentStatus.PAID}>
    <Grid gap="space4">
      <Flex gap="space4">
        <Placeholder height="14px" width="40px" />
        <Placeholder height="14px" width="109px" />
      </Flex>
      <Placeholder height="19px" width="109px" />
    </Grid>
  </History.Payment>
);

const Placeholder = ({
  background = 'gray300',
  borderRadius = 'small',
  ...restProps
}: BoxProps) => (
  <Box background={background} borderRadius={borderRadius} {...restProps} />
);
