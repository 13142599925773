import { Grid, Skeleton } from '@candisio/design-system';

export const TopupSkeleton = () => {
  return (
    <Grid gap="space16" templateColumns="80% 20%">
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
      <Skeleton height="38px" width="auto" />
    </Grid>
  );
};
