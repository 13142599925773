import { isSuccessStateCashAccountAdded } from './states';
import {
  Props,
  OnToggleProps,
  SetupEmailTextProps,
  ComputeModalProperties,
} from './types';

export const checkRequirement = ({
  allChecksDone,
  shouldToggle = allChecksDone,
  onEnabled,
  onDisabled,
  isToggleActive,
  setIsToggleActive,
  setShowChecks,
  setShowSuccess,
}: Props) => {
  setShowChecks(!allChecksDone);
  setShowSuccess(allChecksDone);

  if (allChecksDone || shouldToggle) {
    onEnabled();
  } else {
    onDisabled();
    if (isToggleActive) setIsToggleActive(false);
  }
};

export const evaluateToggle = ({
  isActive,
  setIsToggleActive,
  setShowChecks,
  setShowSuccess,
  refetchDatevClientInfo,
  setIsLoading,
  delayForLoadingInMillSec,
  onDisabled,
  setChecks,
}: OnToggleProps) => {
  setShowChecks(isActive);
  setIsToggleActive(isActive);
  if (isActive) {
    void refetchDatevClientInfo();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, delayForLoadingInMillSec);
  } else {
    setShowSuccess(false);
    setChecks([]);
    onDisabled();
  }
};

export const setupEmailText = ({
  checksText,
  clientNumber,
  consultantNumber,
  currentUser,
  organizationName,
  t,
}: SetupEmailTextProps) => {
  const emailText = [
    t('settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.greetings'),
    t('settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.content'),
    t('settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.details', {
      checksText,
      onBehalfOf: currentUser?.email,
      organizationName,
      datevClientNumber: clientNumber,
      datevConsultantNumber: consultantNumber,
    }),
    t('settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.closing', {
      fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
    }),
  ].join('<br/><br/>');

  return emailText;
};

export const computeModalProperties = ({
  checks,
  organizationName,
  clientNumber,
  consultantNumber,
  currentUser,
  t,
}: ComputeModalProperties) => {
  const checksText = `${checks
    .filter(check => !isSuccessStateCashAccountAdded(check))
    .map(({ emailDescription }) => `<li>${emailDescription}</li>`)
    .join('')}`;

  const subjectText = t(
    'settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.subject',
    { organizationName }
  );

  const emailText = setupEmailText({
    t,
    checksText,
    clientNumber,
    consultantNumber,
    currentUser,
    organizationName,
  });

  const senderEmail = currentUser?.email;
  const senderName = `${currentUser?.firstName} ${currentUser?.lastName}`;

  return { subjectText, emailText, senderEmail, senderName };
};
