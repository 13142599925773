import { Box, TabPanel, Tabs } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { TeamDrawer } from './elements/Drawer/TeamDrawer';
import { TeamsListContainer } from './elements/List/TeamsListContainer';
import { CreateTeamContainer } from './elements/Modal/CreateTeamContainer';
import { SuccessModalContainer } from './elements/Modal/SuccessModalContainer';
import { TeamCreditCardSetupModalContainer } from './elements/Modal/TeamCreditCardSetupModal/TeamCreditCardSetupModalContainer';
import { useModalVisibility } from './toolkit/hooks/useModalVisibility';
import { useTeamsListTabItems } from './toolkit/hooks/useTeamsListTabItems';

const TEAMS_WIDTH = '50rem';

export const TEAM_STATUS_URL_PARAM = 'status';

export const Teams = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { tabPanelProps, tabsProps } = useTeamsListTabItems({
    teamsList: <TeamsListContainer />,
  });

  const { isCreateModalVisible, isSuccessModalVisible, isCCSetupModalVisible } =
    useModalVisibility();

  return (
    <SettingsLayout
      title={t('teams.title')}
      width={TEAMS_WIDTH}
      actions={<Tabs {...tabsProps} />}>
      <TabPanel {...tabPanelProps} />
      <TeamDrawer />
      {/* Modals are placed in the box to maintain the layout consistency of the team list */}
      <Box>
        {isCreateModalVisible && <CreateTeamContainer isOpen />}
        {isSuccessModalVisible && <SuccessModalContainer isOpen />}
        {isCCSetupModalVisible && <TeamCreditCardSetupModalContainer isOpen />}
      </Box>
    </SettingsLayout>
  );
};
