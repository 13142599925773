import {
  RequestChangeUserEmailInput,
  UpdateCurrentUserInput,
  User,
} from 'generated-types/graphql.types';
import { isEqual } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { UserProfileFormData } from './types';

export const useGetUserProfileUpdateInput = () => {
  const currentUser = useCurrentUser() as User;

  const hasChangedUserInfo = (formData: UserProfileFormData): boolean => {
    return !(
      [
        [currentUser.firstName, formData.firstName],
        [currentUser.lastName, formData.lastName],
        [currentUser.locale, formData.locale],
      ] as const
    ).every(([currentValue, formValue]) => isEqual(currentValue, formValue));
  };

  const getUserProfileUpdateInput = (
    formData: UserProfileFormData
  ): UpdateCurrentUserInput => ({
    firstName: formData.firstName,
    lastName: formData.lastName,
    locale: formData.locale,
  });

  const hasChangedEmail = (formData: UserProfileFormData): boolean => {
    return currentUser.email !== formData.email;
  };

  const getChangeEmailInput = (
    formData: UserProfileFormData
  ): RequestChangeUserEmailInput => ({
    membershipId: currentUser.id,
    newEmail: formData.email,
  });

  return {
    hasChangedUserInfo,
    getUserProfileUpdateInput,
    hasChangedEmail,
    getChangeEmailInput,
  };
};
