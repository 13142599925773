import { useGetAllUsersEmailsQuery } from 'generated-types/graphql.types';

export const useGetAllUsersEmails = () => {
  const { data } = useGetAllUsersEmailsQuery();

  const users = data?.organizationMemberships ?? [];

  const emails = users.map(user => user.email);

  return emails;
};
