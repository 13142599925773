import { Maybe, UploadPostOption } from 'generated-types/graphql.types';

export const uploadFile = async (
  file: File,
  options: Maybe<Array<Maybe<UploadPostOption>>>,
  url: string
) => {
  // append to formData file and all metaParams needed for upload
  const formData = new FormData();
  if (options) {
    options.forEach(option => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formData.append(option!.field, option!.value);
    });
  }

  formData.append('file', file);

  // upload file to S3 with all meta params
  return fetch(url, {
    method: 'POST',
    body: formData,
  });
};
