import { Drawer } from '@candisio/design-system';
import { ReactNode, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { Step } from '../../toolkit/utils/types';
import { CreditCardsSetupContainer } from './CreditCardSetup/CreditCardsSetupContainer';
import { UpdateTeamDetailsFormContainer } from './UpdateTeamDetails/UpdateTeamDetailsFormContainer';
import { TeamContainer } from './ViewTeam/TeamContainer';

export const TeamDrawer = () => {
  const { id, additionalSetup } = useParams<{
    id: string;
    additionalSetup: string;
  }>();

  const [step, setStep] = useState<Step>('viewTeam');

  const isOpen = Boolean(id) && !additionalSetup && id !== 'create';
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    navigate({ pathname: '..', search: location.search }, { relative: 'path' });
    setStep('viewTeam');
  };

  const handleSetStep = (step: Step) => {
    setStep(step);
  };

  const view: Record<Step, ReactNode> = {
    modifyTeamDetails: (
      <UpdateTeamDetailsFormContainer
        onGoBack={() => handleSetStep('viewTeam')}
      />
    ),
    viewTeam: <TeamContainer onClose={handleClose} onSetStep={handleSetStep} />,
    creditCardsSetup: (
      <CreditCardsSetupContainer
        onGoBack={() => handleSetStep('viewTeam')}
        onClose={handleClose}
      />
    ),
  };

  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      {view[step]}
    </Drawer>
  );
};
