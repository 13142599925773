import { Tooltip, useTooltip } from '@candisio/design-system';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import {
  CostCenterTypes,
  IntegrationName,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { getTranslationContext } from 'orgConfig';
import { useOtherIntegration } from 'orgConfig/other';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';

export const TypeField = ({ disabled }: { disabled: boolean }) => {
  const [t] = useTranslation();
  const integration = useIntegrationSettings();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const artistSocialInsuranceAndExtraCostInfoFF = useCandisFeatureFlags(
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo
  );

  const { isOpen, triggerRef, triggerProps, tooltipRef, tooltipProps } =
    useTooltip({
      placement: 'top',
      isDisabled: !disabled,
    });

  const options = [
    {
      value: CostCenterTypes.CostCenter,
      children: t('settings.costCenter.details.form.type.costCenter', {
        context: getTranslationContext(),
      }),
    },
    {
      value: CostCenterTypes.CostObject,
      children: t('settings.costCenter.details.form.type.costObject', {
        context: getTranslationContext(),
      }),
    },
  ];

  if (artistSocialInsuranceAndExtraCostInfoFF) {
    options.push({
      value: CostCenterTypes.ExtraCostInfo,
      children: t('settings.costCenter.details.form.type.extraCostInfo', {
        context: getTranslationContext(),
      }),
    });
  }

  const isSapIntegration = integration === IntegrationName.Sap;
  const defaultTooltip = 'settings.costCenter.details.form.type.disabled';
  const sapTooltip = 'settings.costCenter.readOnlySyncHint';
  const coreDataApiTooltip = shouldUseCoreDataApi
    ? 'settings.costCenter.readOnlyHintCoreData'
    : defaultTooltip;

  const tooltip = isSapIntegration ? sapTooltip : coreDataApiTooltip;

  return (
    <>
      <div ref={triggerRef} {...triggerProps} data-cy="typeTooltipTrigger">
        <HookFormRadioGroupField
          disabled={disabled}
          name="type"
          options={options}
          orientation="horizontal"
        />
      </div>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t(tooltip)}
        </Tooltip>
      )}
    </>
  );
};
