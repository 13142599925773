import moment from 'moment';

export const isAbsenceDateOverlap = ({
  currentUserFrom,
  currentUserTo,
  substituteFrom,
  substituteTo,
}: {
  currentUserFrom: string | undefined | null;
  currentUserTo: string | undefined | null;
  substituteFrom: string | undefined | null;
  substituteTo: string | undefined | null;
}): boolean => {
  if (!currentUserFrom || !currentUserTo || !substituteFrom || !substituteTo) {
    return false;
  }

  const currentUserStartDate = moment(currentUserFrom);
  const currentUserEndDate = moment(currentUserTo);
  const substituteStartDate = moment(substituteFrom);
  const substituteEndDate = moment(substituteTo);

  return (
    currentUserStartDate.isSameOrBefore(substituteEndDate) &&
    currentUserEndDate.isSameOrAfter(substituteStartDate)
  );
};
