import {
  Flex,
  Grid,
  Popover,
  Separator,
  Switch,
  Text,
  usePopover,
} from '@candisio/design-system';

import { MobileAppPromotionBanner } from 'components/MobileAppPromotionBanner/MobileAppPromotionBanner';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  TransactionAmount,
  useSetTransactionInvoiceNeededMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getRefetchCardIssuerTransaction,
  getRefetchTransactionHistory,
} from 'views/TransactionAssociation/gql';
import mobilePromoQrCode from '../../../../components/MobileAppPromotionBanner/media/Transaction_document_upload_banner_QR.jpg';
import { InvoiceDropZone } from './InvoiceDropZone';
import { NoInvoiceNeededContainer } from './NoInvoiceNeededContainer';

export interface InvoiceUploaderProps {
  isNoInvoiceNeededToggleActive: boolean;
  onFileSelected: (selectedFiles: File[]) => void;
  setDocumentsNoTxModalIsOpen?: (state: boolean) => void;
  noInvoiceNeededToggler: ReactNode;
}

export interface InvoiceUploaderContainerProps {
  txId?: string;
  isNoInvoiceNeededToggleActive?: boolean;
  cycleTransaction?: () => void;
  transactionAmount?: TransactionAmount;
  onChange: (file: File) => Promise<void>;
  setDocumentsNoTxModalIsOpen?: (state: boolean) => void;
}

export const InvoiceUploader = ({
  onFileSelected,
  isNoInvoiceNeededToggleActive,
  setDocumentsNoTxModalIsOpen,
  noInvoiceNeededToggler,
}: InvoiceUploaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { isOnlyApprover } = useUserRoles();

  const opacity = isNoInvoiceNeededToggleActive ? '30%' : 1;

  const title = isOnlyApprover
    ? 'transactionAssociation.invoiceEmptyState.approverTitle'
    : 'transactionAssociation.invoiceEmptyState.title';

  const description = isOnlyApprover
    ? 'transactionAssociation.invoiceEmptyState.approverHelpText'
    : 'transactionAssociation.invoiceEmptyState.helpText';

  return (
    <Grid gap="space24" width="75%">
      <Grid placeContent="center">
        <Text
          color="gray800"
          fontWeight="semibold"
          fontSize="xlarge"
          textAlign="center"
          opacity={opacity}>
          {t(title)}
        </Text>
        <Text
          opacity={opacity}
          color="gray500"
          fontSize="basic"
          textAlign="center">
          {t(description)}
        </Text>
      </Grid>
      {!isNoInvoiceNeededToggleActive && (
        <MobileAppPromotionBanner
          imageWidth="200px"
          imageHeight="150px"
          qrCode={mobilePromoQrCode}
          localStorageKey="mobile_app_promotion_banner_transaction_document_upload"
          trackingContext="transaction-document-upload"
        />
      )}
      <InvoiceDropZone
        id="invoice-upload"
        isSwitchToggled={isNoInvoiceNeededToggleActive}
        onFileSelected={onFileSelected}
        setDocumentsNoTxModalIsOpen={setDocumentsNoTxModalIsOpen}
      />
      {isOnlyApprover ? null : noInvoiceNeededToggler}
    </Grid>
  );
};

export const InvoiceUploaderContainer = ({
  txId,
  onChange,
  transactionAmount,
  cycleTransaction,
  setDocumentsNoTxModalIsOpen,
  isNoInvoiceNeededToggleActive,
}: InvoiceUploaderContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const creditCards = useCreditCardsSetup();

  const counterQueries = useCounterQueries();
  const transactionId = txId ?? '';

  const {
    isOpen,
    setOpen,
    close,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({
    placement: 'bottom',
  });

  const isSwitchToggled = isNoInvoiceNeededToggleActive || isOpen;

  const [setTransactionInvoiceNeeded, { loading }] =
    useSetTransactionInvoiceNeededMutation();

  const onFileSelected = useCallback(
    (selectedFiles: File[]) => {
      selectedFiles.forEach(onChange);
    },
    [onChange]
  );

  const handleSwitchChange = async () => {
    if (!isNoInvoiceNeededToggleActive) {
      return;
    }

    const response = await setTransactionInvoiceNeeded({
      variables: {
        input: {
          transactionId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        ...counterQueries,
        ...getRefetchTransactionHistory(transactionId),
        ...getRefetchCardIssuerTransaction(transactionId),
      ],
      onCompleted: () => setOpen(false),
    });

    if (!response.errors?.length) {
      success(
        t(
          'transactionAssociation.noInvoiceNeeded.popover.removedFromExportsuccessMsg'
        )
      );
    } else {
      error(
        t(
          'transactionAssociation.noInvoiceNeeded.popover.removedFromExportFailureMsg'
        )
      );
    }
  };

  return (
    <>
      <InvoiceUploader
        onFileSelected={onFileSelected}
        isNoInvoiceNeededToggleActive={isSwitchToggled}
        setDocumentsNoTxModalIsOpen={setDocumentsNoTxModalIsOpen}
        noInvoiceNeededToggler={
          creditCards.isInUse && (
            <Grid gap="space24">
              <Separator />
              <Flex justifyContent="center" alignItems="end" gap="space8">
                {/* @ts-expect-error  https://candis.atlassian.net/browse/DS-735 */}
                <Switch
                  {...triggerProps}
                  ref={triggerRef}
                  disabled={loading}
                  name="noInvoiceNeededSwitch"
                  label={t('transactionAssociation.noInvoiceNeeded.title')}
                  checked={isSwitchToggled}
                  onChange={handleSwitchChange}
                />
                <Text color="gray600">
                  {t('transactionAssociation.noInvoiceNeeded.title')}
                </Text>
              </Flex>
            </Grid>
          )
        }
      />
      {isOpen && !loading && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="460px"
          padding="space18">
          <NoInvoiceNeededContainer
            txId={txId}
            onClose={close}
            transactionAmount={transactionAmount}
            cycleTransaction={cycleTransaction}
          />
        </Popover>
      )}
    </>
  );
};
