import { useApolloClient } from '@apollo/client';
import { useOptimisticCommentCacheUpdate } from 'components/Comment/useOptimisticCommentCacheUpdate';
import { getCommentCreateErrorTranslationKey } from 'components/Comment/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useCreateCommentOnInvoiceMutation,
  CommentCreateErrorKind,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';

interface UseCreateCommentOnInvoiceProps {
  documentId: string;
}

export const useCreateCommentOnInvoice = ({
  documentId,
}: UseCreateCommentOnInvoiceProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const [createCommentOnInvoice, { loading }] =
    useCreateCommentOnInvoiceMutation({});

  const { cache } = useApolloClient();
  const { optimisticCommentCacheUpdate } = useOptimisticCommentCacheUpdate();

  const onCreateCommentOnInvoice = async ({
    mention,
    mentionedUserIds,
  }: {
    mention: string | undefined;
    mentionedUserIds?: string[];
  }) => {
    if (!mention) return null;

    const commentId = uuidV4();

    try {
      await createCommentOnInvoice({
        variables: {
          input: {
            text: mention,
            mentionedUserIds,
            entityId: documentId,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createCommentOnInvoice: {
            __typename: 'CommentCreated',
            commentId: commentId,
          },
        },
        update: optimisticCommentCacheUpdate({ documentId, mention }),
        onCompleted: data => {
          if (
            data?.createCommentOnInvoice.__typename === 'CommentCreatedError'
          ) {
            const errorMessage = getCommentCreateErrorTranslationKey(
              data.createCommentOnInvoice.kind ??
                CommentCreateErrorKind.UnexpectedError
            );

            cache.evict({ fieldName: 'getDocument' });
            error(t(errorMessage));
          } else {
            success(t('comment.created'));
          }
        },
      });
    } catch (e) {
      error(t('comment.creationFailed'));
    }

    return null;
  };

  return { onCreateCommentOnInvoice, isSubmitting: loading };
};
