import { ComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { SplitBookingsFormValues } from 'components/Form/SplitBookingsForm/schema';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';

export const ArtistSocial = ({
  readOnly,
  onChange,
  ...props
}: {
  onChange: (val: Key | null) => void;
} & ComboBoxFieldOptions &
  Partial<HookFormComboBoxFieldProps<SplitBookingsFormValues>>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  return (
    <HookFormComboBoxField
      name="artistSocialInsuranceCode"
      label={t('inputs.artistSocialInsuranceCode.label')}
      {...props}
      placeholder={
        readOnly
          ? t('inputs.artistSocialInsuranceCode.placeholder.notSet')
          : t('inputs.artistSocialInsuranceCode.placeholder.enabled')
      }
      readOnly={readOnly}
      onChange={onChange}
    />
  );
};
