import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CardAccountingDataInput,
  useUpdateCardAccountingDataMutation,
} from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface UpdateAccountingInfo {
  cardId: string;
  accountingData: CardAccountingDataInput;
}

export const useUpdateAccountingInfo = () => {
  let status: 'error' | 'success';
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();
  const [updateCardAccountingData, { loading }] =
    useUpdateCardAccountingDataMutation({
      onError: () => error(t('dashboard.genericErrorMessage')),
    });

  const { refetchQueries } = useCreditCardsRefetchQueries();

  return {
    loading,
    updateAccountingInfo: async (values: UpdateAccountingInfo) => {
      const { data, errors } = await updateCardAccountingData({
        variables: {
          input: {
            cardId: values.cardId,
            accountingData: values.accountingData,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: refetchQueries.cardIssuerCards(values.cardId),
      });

      if (errors?.length) {
        status = 'error';
      }

      if (data) {
        status = 'success';
      }

      return {
        status,
      };
    },
  };
};
