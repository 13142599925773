import { Grid, Tag, Text, TruncatedText } from '@candisio/design-system';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat';
import { Reimbursement } from '../hooks/useFormattedReimbursement';
import { useReimbursementTags } from '../hooks/useReimbursementTags';

interface ReimbursementItemProps extends Reimbursement {}

export const ReimbursementItem = ({
  id,
  status,
  createdAt,
  title,
  totalAmount,
}: ReimbursementItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const navigate = useNavigate();
  const { search } = useLocation();
  const organizationSlug = useOrganizationId();
  const tags = useReimbursementTags();

  const handleClick = () => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.REIMBURSEMENT}/:reimbursementId?`,
      { organizationSlug, reimbursementId: id }
    );

    navigate({
      pathname,
      search,
    });
  };

  return (
    <Grid
      onClick={handleClick}
      gap="space16"
      cursor="pointer"
      alignItems="center"
      padding="space16"
      borderBottom="1px solid gray200"
      templateColumns="90px 1fr auto">
      <Tag variant="secondary" color={tags[status].color}>
        {tags[status].text}
      </Tag>
      <Grid templateRows="auto auto" gap="space2">
        <TruncatedText fontSize="basic" fontWeight="semibold">
          {title ?? t('dashboard.list.reimbursementItemPlaceholder')}
        </TruncatedText>
        <TruncatedText color="gray500">
          {t('dashboard.list.createdAt', { createdAt })}
        </TruncatedText>
      </Grid>
      <Text fontSize="basic" fontWeight="semibold">
        {totalAmount}
      </Text>
    </Grid>
  );
};
