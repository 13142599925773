import { Grid, Link, Paragraph, Text } from '@candisio/design-system';
import { LottieAnimation, noPayableDocuments } from 'components/Lottie/Lottie';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { useCountOpenApprovalsQuery } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useRouteMatch } from 'react-router-dom';

export interface PaymentTableEmptyStateProps {
  hasProcessedPayment?: boolean;
}

export const PaymentTableEmptyState = ({
  hasProcessedPayment,
}: PaymentTableEmptyStateProps) => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const {
    params: { organizationSlug },
  } = useRouteMatch<{ organizationSlug: string }>();

  const { data: countOpenApprovalsData } = useCountOpenApprovalsQuery({
    fetchPolicy: 'cache-first',
  });

  const approvalsCount = countOpenApprovalsData?.countOpenApprovals.count ?? 0;

  return (
    <Grid
      gap="space8"
      placeContent="center"
      textAlign="center"
      position="absolute"
      top="50%"
      left="50%"
      style={{ transform: 'translate(-50%, -50%)' }}>
      <LottieAnimation
        options={{ animationData: noPayableDocuments }}
        height="126px"
        width="118px"
      />
      <Text as="h2" fontSize="large" fontWeight="semibold">
        {tPayments('table.emptyState.title')}
      </Text>
      <Paragraph color="gray500" width="19rem">
        {!hasProcessedPayment ? (
          <Trans
            t={tPayments}
            i18nKey="table.emptyState.descriptionWithNoDocumentspproved">
            Find out how invoices can be paid via
            <Link href={tPayments('table.emptyState.helpUrl')} external>
              SEPA XML
            </Link>
          </Trans>
        ) : approvalsCount > 0 ? (
          <Trans
            t={tPayments}
            i18nKey="table.emptyState.descriptionWithDocumentsToApprove">
            To be able to pay invoices
            <RouterLink to={`/${organizationSlug}${Routes.APPROVALS}/`}>
              start approving
            </RouterLink>
            .
          </Trans>
        ) : (
          <Trans
            t={tPayments}
            i18nKey="table.emptyState.descriptionWithNoDocumentsToApprove">
            To be able to pay invoices{' '}
            <RouterLink to={`/${organizationSlug}${Routes.INBOX}/`}>
              start processing
            </RouterLink>
            and{' '}
            <RouterLink to={`/${organizationSlug}${Routes.APPROVALS}/`}>
              approving
            </RouterLink>
            .
          </Trans>
        )}
      </Paragraph>
    </Grid>
  );
};
