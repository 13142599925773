// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';

const extractProp = (query: { [key: string]: any }, prop: string) => {
  return query[prop] ? { [prop]: query[prop] } : {};
};

const mergeProps = (o1: object, o2: object) => ({ ...o1, ...o2 });

export const getFilterFromUrl = <T>(
  search = window.location.search,
  availableFilters: string[] = []
): T => {
  const query: { [key: string]: any } = qs.parse(search);

  return availableFilters.reduce((filters, prop) => {
    const keepPlainProps = ['sort', 'query'];

    // if we encounter a plain property, we do not convert the value
    if (keepPlainProps.includes(prop)) {
      return mergeProps(filters, extractProp(query, prop));
    }

    // all other properties are converted to an array
    if (query[prop] && !Array.isArray(query[prop])) {
      query[prop] = [query[prop]];
    }

    return mergeProps(filters, extractProp(query, prop));
  }, {}) as T;
};
