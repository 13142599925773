import { CardIssuerTransactionInvoiceNotNeededReason } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export interface NoInvoiceNeededFormSchemaOption {
  otherReason?: string;
}

export const formSchema = (
  selectedValue: CardIssuerTransactionInvoiceNotNeededReason
) => {
  return z.object({
    otherReason:
      selectedValue === CardIssuerTransactionInvoiceNotNeededReason.Other
        ? z.string().min(1).max(400)
        : z.string().optional(),
  });
};

export type FormValues = z.infer<ReturnType<typeof formSchema>>;
export type FormErrorMessages = ErrorMessages<ReturnType<typeof formSchema>>;

export const formErrorMessages: (t: i18next.TFunction) => FormErrorMessages = (
  t: i18next.TFunction
) => {
  return {
    otherReason: {
      label: t('transactionAssociation.noInvoiceNeeded.popover.fieldLabel'),
    },
  };
};
