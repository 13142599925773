import {
  Box,
  Flex,
  Icon,
  Image,
  Paragraph,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from './styles';

export enum ACTIVITY_SOURCE {
  TRANSACTION = 'TRANSACTION',
}

export interface BaseItemProps {
  onClick?: () => void;
  header?: string;
  absoluteTime?: string;
  author?: string;
  source?: ACTIVITY_SOURCE;
  avatarUrl?: string;
  src?: string;
  isCollapsible?: boolean;
  defaultCollapse?: boolean;
  customDot?: ReactNode;
  type?: string;
  size?: 'big' | 'default';
  hideTail?: boolean;
  wasImpersonated?: boolean;
  children: ReactNode;
}

interface ItemTimeProps extends Pick<BaseItemProps, 'absoluteTime'> {
  children: ReactNode;
}

const ItemTime = ({ absoluteTime, children }: ItemTimeProps) => {
  const hasTooltip = absoluteTime !== undefined;
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ isDisabled: !hasTooltip });

  return (
    <>
      <Text
        {...triggerProps}
        tabIndex={hasTooltip ? 0 : undefined}
        ref={triggerRef}>
        {children}
      </Text>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {absoluteTime}
        </Tooltip>
      )}
    </>
  );
};

const sourceTranslationMap = {
  [ACTIVITY_SOURCE.TRANSACTION]: 'documentHistory.source.transaction',
};

export const BaseItem = ({
  absoluteTime,
  author,
  wasImpersonated = false,
  avatarUrl,
  children,
  customDot,
  defaultCollapse = false,
  header,
  onClick: defaultOnClick,
  source,
  src,
  ...rest
}: BaseItemProps) => {
  const [t] = useTranslation();
  const [opened, setOpen] = useState(defaultCollapse);

  const onClick = useCallback(() => {
    if (rest.isCollapsible) {
      setOpen(!opened);
    }

    if (defaultOnClick) {
      defaultOnClick();
    }
  }, [defaultOnClick, rest.isCollapsible, opened]);

  const { isCollapsible } = rest;

  return (
    <Flex>
      {isCollapsible ? (
        <Box position="absolute" top="space14" left="space4">
          {opened ? (
            <Icon size="space16" icon="caretUp" color="blue500" />
          ) : (
            <Icon size="space16" icon="caretDown" color="gray600" />
          )}
        </Box>
      ) : null}
      <Item
        dot={
          customDot || (
            <Image
              alt={src ?? ''}
              src={src ?? ''}
              width="space18"
              height="space18"
            />
          )
        }
        onClickCapture={onClick}
        /**
         * Item passes props down the tree, so we need to made it acceptable for a dom
         */
        opened={opened ? 'true' : undefined}
        {...rest}>
        {header !== undefined && (
          <Paragraph color="gray500" fontSize="small">
            <ItemTime absoluteTime={absoluteTime}>
              {header.charAt(0).toUpperCase() + header.slice(1)}
            </ItemTime>
            {author && (
              <>
                {' • '}
                <Text>{author}</Text>
                {source ? ` ${t(sourceTranslationMap[source])}` : ''}
              </>
            )}
            {wasImpersonated && (
              <>
                {' '}
                <Text>{t('common:historyTimeline.wasImpersonated')}</Text>
              </>
            )}
          </Paragraph>
        )}
        <Box color="gray800" fontSize="small" lineHeight="space16">
          {children}
        </Box>
      </Item>
    </Flex>
  );
};
