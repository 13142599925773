import {
  HookFormNumberField,
  HookFormNumberFieldProps,
} from 'components/HookFormFields/HookFormNumberField';
import { FieldValues } from 'react-hook-form';

/** A `HookFormNumberField` for entering whole numbers */
export const HookFormIntegerField = <TFormValues extends FieldValues>(
  props: HookFormNumberFieldProps<TFormValues>
) => {
  return (
    <HookFormNumberField
      formatOptions={{ maximumFractionDigits: 0 }}
      {...props}
    />
  );
};
