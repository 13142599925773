import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useDatev } from 'orgConfig/datev';
import { useSap } from 'orgConfig/sap';
import { useCheckDatevSetup } from 'views/Settings/Integrations/DATEV/setup-checks';

import {
  useNeedsSapConfig,
  useNeedsSapCreditCardConfig,
} from 'views/Settings/Integrations/SAP/utils';
import { useOrganizationData } from 'views/Settings/Organization/hooks/useOrganizationData';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';
import { NavigationIcon, NavigationIconType } from '../../NavigationIcon';

export const NavigationSettings = ({
  ...settingProps
}: Partial<NavigationIconType>) => {
  const {
    hasReadClientsButNotSelected,
    hasProvisionsAndRequiresSetup,
    accountingCoreDataMissing,
    indicateDatevCreditCardSetupNeeded,
  } = useCheckDatevSetup();

  const { hasOrgTaxDetails } = useOrganizationData();

  const { showMissingIBANIndicator } = useOrganizationOwnIbansData();

  const { shouldUseSapConfig } = useSap();
  const indicateSapSetupNeeded = useNeedsSapConfig();
  const indicateSapCreditCardSetupNeeded = useNeedsSapCreditCardConfig();

  const { bdsBoughtButNotConnected } = useDatev();

  const isSapOrDatevConfigured = shouldUseSapConfig
    ? indicateSapCreditCardSetupNeeded
    : indicateDatevCreditCardSetupNeeded;

  const showMissingConfigurationIndicator =
    isSapOrDatevConfigured ||
    hasReadClientsButNotSelected ||
    hasProvisionsAndRequiresSetup ||
    indicateSapSetupNeeded ||
    accountingCoreDataMissing ||
    bdsBoughtButNotConnected ||
    !hasOrgTaxDetails ||
    !showMissingIBANIndicator;

  return (
    <>
      <NavigationIcon
        icon="menuSettings"
        {...settingProps}
        pulseIndicator={
          showMissingConfigurationIndicator ? (
            <PulseIndicator status="default" source="menuSettings" />
          ) : undefined
        }
      />
    </>
  );
};
