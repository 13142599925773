import { Buffer } from 'buffer';
import { MutationResult } from '@apollo/client';
import {
  DatevConnectionType,
  useNew_BuildDatevAuthUrlForClientsMutation,
  useNew_BuildDatevAuthUrlForExportDocumentsMutation,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import useReactRouter from 'use-react-router';
import { useShowError } from 'utils/error_message';

type Response = [
  (connectionType?: DatevConnectionType) => Promise<void>,
  MutationResult
];

const formatState = (organizationId: string): string =>
  Buffer.from(JSON.stringify({ organizationId })).toString('base64');

export const useDatevAuthorizeForClients = (): Response => {
  const {
    match: {
      params: { organizationSlug },
    },
  } = useReactRouter<{ organizationSlug: string }>();

  const showError = useShowError();

  const [buildAuthUrl, mutationProps] =
    useNew_BuildDatevAuthUrlForClientsMutation();

  const authorize = useCallback(
    async (connectionType?: DatevConnectionType) => {
      try {
        const { data } = await buildAuthUrl({
          variables: {
            state: formatState(organizationSlug),
            connectionType,
          },
        });

        if (data) {
          window.location.href = data.new_buildDatevAuthUrlForClients;
        }
      } catch (e) {
        showError(e as GQLError);
      }
    },
    [buildAuthUrl, organizationSlug, showError]
  );

  return [authorize, mutationProps as MutationResult];
};

export const useDatevAuthorizeForExportDocuments = (): Response => {
  const {
    match: {
      params: { organizationSlug },
    },
  } = useReactRouter<{ organizationSlug: string }>();

  const showError = useShowError();

  const [buildAuthUrl, mutationProps] =
    useNew_BuildDatevAuthUrlForExportDocumentsMutation();

  const authorize = useCallback(async () => {
    try {
      const { data } = await buildAuthUrl({
        variables: { state: formatState(organizationSlug) },
      });

      if (data) {
        window.location.href = data.new_buildDatevAuthUrlForExportDocuments;
      }
    } catch (e) {
      showError(e as GQLError);
    }
  }, [buildAuthUrl, organizationSlug, showError]);

  return [authorize, mutationProps as MutationResult];
};
