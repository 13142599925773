import { useGetSpendOverviewInsights } from 'views/CreditCards/hooks/useGetSpendOverviewInsights';
import { SpentOverview } from './SpentOverview';

export const SpentOverviewContainer = () => {
  const {
    maxNumberReference,
    loading,
    overviewMonths,
    monthsWithCreditCards,
    isOverviewEmpty,
    hasFiltersApplied,
  } = useGetSpendOverviewInsights();

  return (
    <SpentOverview
      monthsWithCreditCards={monthsWithCreditCards}
      maxNumberReference={maxNumberReference}
      isOverviewEmpty={isOverviewEmpty}
      overviewMonths={overviewMonths}
      loading={loading}
      hasFiltersApplied={hasFiltersApplied}
    />
  );
};
