import { Card, Grid, Skeleton } from '@candisio/design-system';

export const FinancialOverviewLoading = () => {
  return (
    <Card background="gray50">
      <Grid height="100%">
        <Grid templateColumns="repeat(2, 1fr)">
          <Skeleton justifySelf="start" height="space40" width="space128" />
          <Skeleton justifySelf="end" height="space40" width="space80" />
        </Grid>
        <Skeleton width="100%" height="space12" alignSelf="end" />
      </Grid>
    </Card>
  );
};
