import { Paragraph, Text } from '@candisio/design-system';
import { DocumentTransactionReconciledEvent } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { amountFormatNonNull } from '../../../utils/format';
import { Collapse } from '../../History/items/Collapse';
import { MetaDataWrapper } from '../MetaDataWrapper';

export const DocumentTransactionReconciledComponent = ({
  properties: {
    merchantName,
    transactionCreatedAt,
    amount: { value, currency },
  },
}: DocumentTransactionReconciledEvent) => {
  const [t] = useTranslation();
  const title = t('document.compact.history.metaExtracted.title', {
    count: 3,
  });

  const formattedAmount = amountFormatNonNull(value, currency);
  const formattedDate = DateFormatters.compact(new Date(transactionCreatedAt));

  return (
    <>
      <Text fontSize="small" lineHeight="paragraph">
        {title}
      </Text>
      <Collapse>
        <MetaDataWrapper>
          <div>
            <Text fontWeight="regular" color="gray500">
              {t('document.compact.history.metaExtracted.labels.contact')}
            </Text>
            <Paragraph>{merchantName}</Paragraph>
          </div>
          <div>
            <Text fontWeight="regular" color="gray500">
              {t('document.compact.history.metaExtracted.labels.amount')}
            </Text>
            <Paragraph>{formattedAmount}</Paragraph>
          </div>
          <div>
            <Text fontWeight="regular" color="gray500">
              {t('document.compact.history.metaExtracted.labels.invoiceDate')}
            </Text>
            <Paragraph>{formattedDate}</Paragraph>
          </div>
        </MetaDataWrapper>
      </Collapse>
    </>
  );
};
