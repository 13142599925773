import { Grid, Image, Text } from '@candisio/design-system';
import checkImage from 'assets/icon-check.svg';
import closeImage from 'assets/icon-close-x-red.svg';
import pendingImage from 'assets/icon-pending.svg';
import skipImage from 'assets/icon-skip.svg';
import {
  DocumentApprovalStepFragment,
  Maybe,
  SystemApprovalStepFragment,
  UserApprovalStepFragment,
  WorkflowStepResolutionTypes,
} from 'generated-types/graphql.types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const getStatusIcon = (
  isApproverSkipped: boolean,
  matched: boolean,
  status?: Maybe<WorkflowStepResolutionTypes>
) => {
  if (isApproverSkipped) {
    return skipImage;
  }

  if (!matched && status !== WorkflowStepResolutionTypes.Skipped) {
    return pendingImage;
  }

  switch (status) {
    case WorkflowStepResolutionTypes.Approved:
      return checkImage;
    case WorkflowStepResolutionTypes.Skipped:
      return skipImage;
    case WorkflowStepResolutionTypes.Rejected:
      return closeImage;
    default:
      return pendingImage;
  }
};

export interface ApprovalStepProps {
  step:
    | DocumentApprovalStepFragment
    | SystemApprovalStepFragment
    | UserApprovalStepFragment;
  isActive: boolean;
  isShadowed: boolean;
  orderNumber: number;
}

export const ApprovalStep = ({
  step,
  isActive,
  isShadowed,
  orderNumber,
}: ApprovalStepProps) => {
  const [t] = useTranslation();

  const status = step.resolution?.type;
  const isSkipped = status === WorkflowStepResolutionTypes.Skipped;

  const approversIds = step.resolution?.approvers?.map(user => user.id);
  const skippedApproverIds = step.approvers
    ?.filter(user => approversIds && approversIds?.indexOf(user.id) < 0)
    .map(user => user.id);

  const skippedApproversWithSubstitutesIds = step.approvers
    ?.flatMap(approver => {
      return approver.substitute
        ? [approver.id, approver.substitute.id]
        : [approver.id];
    })
    .filter(id => approversIds && approversIds?.indexOf(id) < 0);

  return (
    <Grid
      gap="space8"
      templateColumns="auto 1fr"
      background={isShadowed ? 'gray100' : undefined}
      borderRadius="basic"
      fontSize="small"
      paddingX="space32"
      paddingY="space12"
      textDecoration={isSkipped ? 'line-through' : 'none'}>
      <Text color={isActive ? 'gray800' : 'gray600'} lineHeight="space24">
        {t('document.approveDocument.inputs.step')} {orderNumber}
      </Text>

      <Grid gap="space4">
        {step.approvers &&
          step.approvers.map((approver, i) => {
            const matched = !!(step.resolution?.approvers || []).find(
              a => a.id === approver.id
            );

            const isApproverSkipped =
              (skippedApproverIds &&
                skippedApproverIds.indexOf(approver.id) >= 0) ??
              false;

            const substitute = approver.substitute;
            const substituteMatched =
              (substitute &&
                !!(step.resolution?.approvers || []).find(
                  a => a.id === substitute.id
                )) ??
              false;

            const isSubstituteSkipped =
              (skippedApproversWithSubstitutesIds &&
                substitute &&
                skippedApproversWithSubstitutesIds.indexOf(substitute.id) >=
                  0) ??
              false;

            return (
              <Fragment key={approver.id}>
                <Grid
                  key={approver.id}
                  gap="space4"
                  templateColumns="auto 1fr"
                  alignItems="center"
                  textDecoration={isApproverSkipped ? 'line-through' : 'none'}
                  color={isActive ? 'gray800' : 'gray600'}
                  textAlign="left">
                  <Image
                    src={getStatusIcon(isApproverSkipped, matched, status)}
                    alt="status"
                    width="space24"
                    height="space24"
                  />
                  <Text>{approver.name}</Text>
                </Grid>
                {substitute && (
                  <Grid
                    key={`${approver.id}-substituted-by-${substitute.id}`}
                    gap="space4"
                    templateColumns="auto 1fr"
                    alignItems="center"
                    textDecoration={
                      isSubstituteSkipped ? 'line-through' : 'none'
                    }
                    color={isActive ? 'gray800' : 'gray600'}
                    textAlign="left">
                    <Image
                      src={getStatusIcon(
                        isSubstituteSkipped,
                        substituteMatched,
                        status
                      )}
                      alt="status"
                      width="space24"
                      height="space24"
                    />
                    <Text>
                      {t('document.approveDocument.inputs.substituteApprover', {
                        substituteApprover: substitute.name,
                        absentApprover: approver.name,
                      })}
                    </Text>
                  </Grid>
                )}
              </Fragment>
            );
          })}
      </Grid>
    </Grid>
  );
};
