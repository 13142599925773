import { Grid } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { Absence, Maybe, SetAbsenceError } from 'generated-types/graphql.types';
import { useCurrentUserMembershipRoles } from 'hooks/useCurrentUserMembershipRoles';
import { useSSO } from 'orgConfig/sso/sso';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation/zodResolver';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { absenceFormErrorMessages } from './AbsenceManagement/absenceFormErrorMessages';
import {
  AbsenceFormOutput,
  AbsenceFormValues,
  absenceFormSchema,
  AbsenceFormSchemaOptions,
} from './AbsenceManagement/absenceFormSchema';
import { AbsenceFormWrapper } from './AbsenceManagement/AbsenceFormWrapper';
import { useCheckCircularSubstitutionDependency } from './AbsenceManagement/checkCircularSubstitutionDependency';
import { useChangeAvatar } from './useChangeAvatar';
import { userProfileFormErrorMessages } from './userProfileFormErrorMessages';
import {
  UserProfileFormOutput,
  UserProfileFormValues,
  userProfileFormSchema,
} from './userProfileFormSchema';
import { UserProfileUpdateForm } from './UserProfileUpdateForm';
import { UserTeams } from './UserTeams/UserTeams';

type UserProfileWithAbsenceFormOutput = UserProfileFormOutput &
  AbsenceFormOutput;

export interface UserProfileFormsContainerProps {
  formId: string;
  handleUpdateCurrentUser: (values: UserProfileFormOutput) => Promise<{
    emailIsADuplicate: boolean;
  } | void>;
  handleSetAbsence: (
    values: AbsenceFormOutput
  ) => Promise<Maybe<Absence> | Maybe<SetAbsenceError>>;
  onNavigate: () => void;
  userProfileInitialValues: UserProfileFormValues;
  absenceInitialValues: AbsenceFormValues;
  newEmail?: string;
  cancelChangeEmailRequest: () => Promise<unknown>;
  resendVerificationEmail: () => Promise<unknown>;
  absenceInitialValuesLoading?: boolean;
  cancelChangeEmailIsLoading: boolean;
  resendChangeEmailIsLoading: boolean;
  onClose: () => void;
  teams: Team[];
}

export const UserProfileFormsContainer = ({
  formId,
  handleUpdateCurrentUser,
  onNavigate,
  userProfileInitialValues,
  cancelChangeEmailRequest,
  resendVerificationEmail,
  newEmail,
  handleSetAbsence,
  absenceInitialValues,
  absenceInitialValuesLoading,
  resendChangeEmailIsLoading,
  cancelChangeEmailIsLoading,
  onClose,
  teams,
}: UserProfileFormsContainerProps) => {
  const currentUser = useCurrentUser();
  const [t] = useTranslation();
  const { success } = useToastMessage();
  const sso = useSSO();

  const { sortedMembershipRoleNames } = useCurrentUserMembershipRoles();

  const {
    onRemoveAvatar,
    onUploadAvatar,
    removeAvatarLoading,
    uploadAvatarLoading,
  } = useChangeAvatar();

  const checkCircularDependency = useCheckCircularSubstitutionDependency();

  const absenceFormContext: AbsenceFormSchemaOptions = {
    checkCircularSubstitutionDependency: checkCircularDependency,
    selectedUser: currentUser,
  };

  const form = useForm<
    UserProfileWithAbsenceFormOutput,
    AbsenceFormSchemaOptions
  >({
    mode: 'onBlur',
    defaultValues: { ...userProfileInitialValues, ...absenceInitialValues },
    resolver: zodResolver({
      zodSchema: userProfileFormSchema.and(
        absenceFormSchema(absenceFormContext)
      ),
      errorMessages: {
        ...userProfileFormErrorMessages,
        ...absenceFormErrorMessages,
      },
    }),
  });

  const { getFieldState } = form;

  const resetEmailToDefaultValue = () => {
    if (userProfileInitialValues?.email)
      form.setValue('email', userProfileInitialValues.email);
  };

  const handleUserProfileSubmit = async (
    values: UserProfileFormOutput
  ): Promise<{
    emailIsADuplicate: boolean;
  } | void> => {
    const result = await handleUpdateCurrentUser(values);

    if (result?.emailIsADuplicate) {
      form.setError('email', {
        message: t('header.profile.tabs.update.form.email.errorDuplicateEmail'),
      });

      return result;
    }
  };

  const handleFormSubmit = async (values: UserProfileWithAbsenceFormOutput) => {
    await handleUserProfileSubmit(values);
    let setAbsenceResult: Awaited<ReturnType<typeof handleSetAbsence>>;

    const { isDirty: fromDateDirty } = getFieldState('fromDate');
    const { isDirty: toDateDirty } = getFieldState('toDate');
    const { isDirty: noteDirty } = getFieldState('note');
    const { isDirty: substituteDirty } = getFieldState('substitute');
    const hasAbsenceChanged =
      fromDateDirty || toDateDirty || noteDirty || substituteDirty;

    if (hasAbsenceChanged) {
      setAbsenceResult = await handleSetAbsence(values);
    }

    const emailNotChanged = currentUser?.email === values.email;
    if (emailNotChanged && setAbsenceResult?.__typename !== 'SetAbsenceError') {
      onNavigate();
    }
  };

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        gap="space24"
        id={formId}
        onSubmit={form.handleSubmit(handleFormSubmit)}>
        <UserProfileUpdateForm
          readOnly={{
            firstName: sso.provides.firstName,
            lastName: sso.provides.lastName,
          }}
          name={currentUser?.name}
          avatar={currentUser?.avatarUrl}
          userId={currentUser?.id}
          userRoles={currentUser?.roles}
          readableMembershipRoles={sortedMembershipRoleNames}
          onRemoveAvatar={onRemoveAvatar}
          onUploadAvatar={onUploadAvatar}
          newEmail={newEmail}
          onCancelChangeEmailRequest={async () => {
            await cancelChangeEmailRequest();
            resetEmailToDefaultValue();
          }}
          onResendVerificationEmail={async () => {
            onClose();
            await resendVerificationEmail();
            success(
              t('header.profile.tabs.update.pendingEmail.resendConfirmMessage')
            );
          }}
          removeAvatarLoading={removeAvatarLoading}
          uploadAvatarLoading={uploadAvatarLoading}
          cancelChangeEmailIsLoading={cancelChangeEmailIsLoading}
          resendChangeEmailIsLoading={resendChangeEmailIsLoading}
        />
        <UserTeams teams={teams} />
        <AbsenceFormWrapper
          defaultValuesLoading={absenceInitialValuesLoading}
          userId={currentUser?.id}
          userRoles={currentUser?.roles}
        />
      </Grid>
    </FormProvider>
  );
};
