import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery,
} from '@apollo/client';
import {
  EcmFilterInput,
  EcmPageInfo,
  EcmSortInput,
  Maybe,
  Query,
} from 'generated-types/graphql.types';
import { DocumentNode } from 'graphql';
import { useEffect } from 'react';
import { DeepPartial } from 'utility-types';

export type OnLoadMore = () => void;

export type UseEcmPaginationResponse<T> = Omit<QueryResult<T>, 'fetchMore'> & {
  onLoadMore: OnLoadMore;
};

export type EcmDocumentsPaginationParams = {
  next?: string;
  limit?: number;
  search?: string;
  sort?: EcmSortInput;
  filter?: EcmFilterInput;
};

export type PaginationResponse = {
  pageInfo?: Maybe<EcmPageInfo>; // # Information to aid in pagination.
};

export type EcmPaginationVariables = OperationVariables &
  EcmDocumentsPaginationParams;

export const useEcmPagination = <
  QueryType extends DeepPartial<Query>,
  QueryVariablesType extends EcmPaginationVariables,
  QueryKeyType extends keyof Omit<QueryType, '__typename'> = keyof Omit<
    QueryType,
    '__typename'
  >
>(
  query: DocumentNode,
  queryRootKey: QueryKeyType & string,
  options?: QueryHookOptions<QueryType, QueryVariablesType>
): UseEcmPaginationResponse<QueryType> => {
  const { data, loading, fetchMore, ...rest } = useQuery<QueryType, any>(
    query,
    options
  );

  const response = data?.[queryRootKey] as PaginationResponse | undefined;

  const hasMoreData = Boolean(response?.pageInfo?.hasNextPage);
  const nextPageCursor = response?.pageInfo?.nextPageCursor;

  const onLoadMore = () => {
    if (hasMoreData && nextPageCursor) {
      void fetchMore({
        query,
        //@ts-expect-error
        notifyOnNetworkStatusChange: true,
        variables: {
          ...options?.variables,
          next: nextPageCursor,
        },
      });
    }
  };

  /**
   * Evict all the cached pagination results, so when we navigate back to the
   * view, we don't try render potentionally hundreds of results from the cache.
   */
  useEffect(() => {
    return () => {
      rest.client.cache.evict({ fieldName: queryRootKey });
    };
  }, [queryRootKey, rest.client.cache]);

  return { data, loading, onLoadMore, ...rest };
};
