import { ApolloError } from '@apollo/client';
import {
  CreateBookingAccountInput,
  useBookingAccountImportFileUploadUrlMutation,
  useBulkCreateBookingAccountsMutation,
} from 'generated-types/graphql.types';
import { useCallback, useState } from 'react';
import { uploadFile } from 'utils/upload';
import { useBookingAccountRefetchQueries } from '../../../hooks/useBookingAccountRefetchQueries';

type ImportBookingAccountsCallback = {
  loading: boolean;
  errors?: ApolloError[];
};

type UseImportBookingAccountsHook = [
  (
    bookingAccounts: Omit<CreateBookingAccountInput, 'accountNumber'>[],
    file: File | null
  ) => Promise<{ successCount: number; errorCount: number }>,
  ImportBookingAccountsCallback
];

export const useImportBookingAccounts = (): UseImportBookingAccountsHook => {
  const { refetchQueries, resetPaginationResults } =
    useBookingAccountRefetchQueries();

  const [bulkImport] = useBulkCreateBookingAccountsMutation({
    onCompleted: resetPaginationResults,
    refetchQueries,
  });

  const [requestFileUploadUrl] = useBookingAccountImportFileUploadUrlMutation();

  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [importError, setImportError] = useState<ApolloError | null>(null);

  const doImport = useCallback(
    async (
      bookingAccounts: Omit<CreateBookingAccountInput, 'accountNumber'>[],
      file: File | null
    ) => {
      let successCount = 0;
      let errorCount = 0;

      setImportLoading(true);
      setImportError(null);

      try {
        let fileId: string | null = null;

        if (file) {
          const { data: requestFileUploadUrlData } = await requestFileUploadUrl(
            {
              variables: {
                fileName: file.name,
                contentType: 'text/csv',
              },
            }
          );

          const uploadOptions =
            requestFileUploadUrlData?.bookingAccountImportFileUploadURL;

          if (uploadOptions && uploadOptions.url) {
            await uploadFile(
              file,
              uploadOptions.postOptions || [],
              uploadOptions.url
            );
            fileId = uploadOptions.id;
          }
        }

        const { data } = await bulkImport({
          variables: { input: bookingAccounts, fileId },
        });

        if (
          data?.bulkCreateBookingAccounts.__typename ===
          'BookingAccountsBulkImportReport'
        ) {
          successCount = data?.bulkCreateBookingAccounts?.imported.length;
          errorCount = data?.bulkCreateBookingAccounts?.errors.length;
        } else {
          errorCount = bookingAccounts.length;
        }
      } catch (e) {
        setImportError(e as ApolloError);
      } finally {
        setImportLoading(false);
      }

      return { successCount, errorCount };
    },
    [bulkImport, requestFileUploadUrl, setImportError, setImportLoading]
  );

  const errors = importError ? [importError] : [];

  return [doImport, { loading: importLoading, errors }];
};
