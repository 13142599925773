import { Grid, Image, Text } from '@candisio/design-system';
import sepaXmlIcon from 'assets/icon-sepa-xml.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const SepaImage = () => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  return (
    <Grid
      placeItems="center"
      background="gray100"
      padding="space32 0"
      borderRadius="basic">
      <Image
        src={sepaXmlIcon}
        width="space64"
        height="space64"
        alt="SEPA XML"
      />
      <Text>{tPayments('details.iconDescription')}</Text>
    </Grid>
  );
};
