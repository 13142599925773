import { CellProps, Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  PaymentConditionsStatusIcon,
  PaymentConditionsStatusIconProps,
} from './PaymentConditionsStatusIcon';

type PaymentConditionsImportStatusCellProps<TTableData extends object> =
  CellProps<TTableData, PaymentConditionsStatusIconProps['status']>;

export const PaymentConditionsStatus = <TTableData extends object>({
  value,
}: PaymentConditionsImportStatusCellProps<TTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);

  if (value === undefined) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return (
    <Grid placeContent="center">
      <PaymentConditionsStatusIcon status={value} />
    </Grid>
  );
};
