import { Flex, Text } from '@candisio/design-system';
import { LimitText } from 'containers/credit-cards/components/CreditCardOverviewCard/LimitText';
import { TransactionLimitText } from 'containers/credit-cards/components/CreditCardOverviewCard/TransactionLimitText';
import { CardStatus, Maybe } from 'generated-types/graphql.types';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';
import { useGetCardById } from '../../../utils';
import { extractCardLimits } from '../../utils';

export interface Amount {
  value: Maybe<number> | undefined;
  currency: Maybe<string> | undefined;
}

interface LimitsCardProps {
  limit: Amount;
  loading?: boolean;
  status?: CardStatus;
  transactionLimit: Amount;
  availableBalance?: Amount;
  cardType?: CardType;
}

export const Limits = ({
  limit,
  loading,
  transactionLimit,
  availableBalance,
  cardType,
  status,
}: LimitsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);

  const formattedTransactionLimit = amountFormat(
    transactionLimit.value,
    transactionLimit.currency,
    {
      minimumFractionDigits: 0,
      convertToBasicMonetaryUnit: true,
    }
  );

  const formattedAvailableBalance = amountFormat(
    availableBalance?.value,
    availableBalance?.currency,
    {
      minimumFractionDigits: 0,
      convertToBasicMonetaryUnit: true,
    }
  );

  const formattedLimit = amountFormat(limit.value, limit.currency, {
    minimumFractionDigits: 0,
    convertToBasicMonetaryUnit: true,
  });

  if (cardType === CardType.SingleUse) {
    const isCardInUse = status
      ? [CardStatus.Active, CardStatus.Locked, CardStatus.LockedPin].includes(
          status
        )
      : false;

    return (
      <Flex direction="column">
        <Text fontSize="basic">{t('ccOverviewCard.oneTimePayment')}</Text>
        <Text fontSize="42px">
          {isCardInUse ? formattedAvailableBalance : formattedTransactionLimit}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex paddingY="space8" width="100%" gap="space16">
      <Flex gap="space8" direction="column">
        <LimitText loading={loading} limit={formattedLimit} />

        <TransactionLimitText
          loading={loading}
          transactionLimit={formattedTransactionLimit}
        />
      </Flex>
    </Flex>
  );
};

export const LimitsContainer = ({ cardId }: { cardId: string }) => {
  const { card, loading } = useGetCardById({ cardId });

  if (loading) {
    return (
      <Limits
        transactionLimit={{ value: 0, currency: 'EUR' }}
        limit={{ value: 0, currency: 'EUR' }}
        availableBalance={{ value: 0, currency: 'EUR' }}
        loading={loading}
      />
    );
  }

  if (!card) {
    // TODO error state
    return null;
  }

  const { limit, transactionLimit, availableBalance } = extractCardLimits(card);

  return (
    <Limits
      limit={limit}
      transactionLimit={transactionLimit}
      availableBalance={availableBalance}
      loading={loading}
      cardType={card.type}
      status={card.status}
    />
  );
};
