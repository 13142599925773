import { TablePaginationConfig } from 'antd/es/table/interface';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';

enum PARAMS {
  PAGE = 'page',
}

export const getCurrentPage = () => {
  const params = qs.parse(window.location.search);
  const page = params[PARAMS.PAGE] as string;

  return (page && parseInt(page)) || 1;
};

export const urlBasedTableActions =
  (history: any, defaultSort: string) =>
  (pagination: TablePaginationConfig, filters: any, sorting: any) => {
    const sortParams = {
      sort: sorting.order ? `${sorting.field}:${sorting.order}` : defaultSort,
    };

    const paginationParams = {
      [PARAMS.PAGE]: pagination.current || 1,
    };

    const query = {
      ...qs.parse(window.location.search),
      ...filters,
      ...sortParams,
      ...paginationParams,
    };

    if (history) {
      history.replace({
        search: qs.stringify(query),
      });
    }
  };
