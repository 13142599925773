import { PaginatedComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { useLinkInvoiceToPurchaseOrderMutation } from 'generated-types/graphql.types';
import { documentQueries } from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';
import { PurchaseOrderFieldItem } from '../PurchaseOrderItem';
import { usePurchaseOrderFieldItems } from './usePurchaseOrderFieldItems';

export interface UsePurchaseOrderFieldOptionsResult {
  props?: PaginatedComboBoxFieldOptions<PurchaseOrderFieldItem>;
  linkPurchaseOrder: (purchaseOrderId: string) => Promise<void>;
}

export interface UsePurchaseOrderOptionsProps {
  documentId?: string;
  accountsPayableNumber?: string | null;
  documentAmount?: number | null;
}

/** Returns options for purchase order number field */
export const usePurchaseOrderFieldOptions = ({
  documentId,
  documentAmount,
  accountsPayableNumber,
}: UsePurchaseOrderOptionsProps): UsePurchaseOrderFieldOptionsResult => {
  const { isLoading, fetchMoreItems, items, handleDebounceSearch } =
    usePurchaseOrderFieldItems({ documentAmount, accountsPayableNumber });

  const [
    linkInvoiceToPurchaseOrderMutation,
    { loading: isLinkingPurchaseOrder },
  ] = useLinkInvoiceToPurchaseOrderMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: documentQueries.forDraftForm, variables: { id: documentId } },
      { query: documentHistoryQuery, variables: { id: documentId } },
    ],
  });

  const linkPurchaseOrder = async (purchaseOrderId: string) => {
    if (documentId && purchaseOrderId) {
      await linkInvoiceToPurchaseOrderMutation({
        variables: { invoiceId: documentId, purchaseOrderId },
      });
    }
  };

  return {
    linkPurchaseOrder,
    props: {
      items,
      persistFilter: true,
      onEndReached: fetchMoreItems,
      isLoading: isLoading || isLinkingPurchaseOrder,
      onSearch: async (query: string) => {
        handleDebounceSearch(query);
      },
    },
  };
};
