import { useCardIssuerStatementCsvFileQuery } from 'generated-types/graphql.types';
import { usePolling } from 'hooks/usePolling';
import { useEffect } from 'react';
import { downloadFile } from 'utils/downloadFile';

interface UseDownloadCSVParams {
  csvFileRecordId: string | undefined;
  statementId: string | undefined;
}

/**
 * This hook is used for polling the Url and name of the file needed to download the CSV.
 */
export const useDownloadCSV = ({
  csvFileRecordId,
  statementId,
}: UseDownloadCSVParams) => {
  const {
    data,
    startPolling,
    stopPolling,
    loading: isDownloadingCSV,
  } = useCardIssuerStatementCsvFileQuery({
    skip: !csvFileRecordId || !statementId,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        csvFileRecordId: csvFileRecordId ?? '',
        statementId: statementId ?? '',
      },
    },
  });

  const hasCSVFile = data?.cardIssuerStatementCsvFile.hasFile;
  const fileName = data?.cardIssuerStatementCsvFile.file?.name;
  const fileUrl = data?.cardIssuerStatementCsvFile.file?.url;

  useEffect(() => {
    if (fileName && fileUrl) {
      downloadFile(fileUrl, fileName);
    }
  }, [fileName, fileUrl, hasCSVFile]);

  const { isPolling } = usePolling({
    startPolling,
    stopPolling,
    skip: !csvFileRecordId || !statementId || !!hasCSVFile,
  });

  return { isDownloadingCSV: isDownloadingCSV || isPolling };
};
