import { Box, Button, Heading, Item } from '@candisio/design-system';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { nodocumentData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Reimbursement } from '../hooks/useFormattedReimbursement';
import { ReimbursementItem } from './ReimbursementItem';

interface OpenReimbursementListProps {
  isLoading: boolean;
  reimbursements: Reimbursement[];
  reimbursementsCount: number;
  onEndReached?: (index: number) => void;
  onReset?: () => void;
}

export const OpenReimbursementList = ({
  isLoading,
  reimbursements,
  reimbursementsCount,
  onEndReached,
  onReset,
}: OpenReimbursementListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <FilterableList
      heading={<Heading as="h2">{t('dashboard.list.openListHeader')}</Heading>}
      isLoading={isLoading}
      onEndReached={onEndReached}
      emptyDataState={
        <Box paddingX="space16">
          <EmptyDataState
            lottieAnimation={nodocumentData}
            translation="reimbursement:dashboard.list.openListNoData"
          />
        </Box>
      }
      listActions={
        reimbursementsCount > 0 && (
          <Box padding="space16" justifySelf="end">
            <Button variant="tertiary">
              {t('dashboard.list.actions.goToArchiveCta')}
            </Button>
          </Box>
        )
      }>
      {reimbursements.map(item => (
        <Item key={item.id} textValue={item.id}>
          <ReimbursementItem {...item} />
        </Item>
      ))}
    </FilterableList>
  );
};
