import {
  GetPageBasedTransactionDownloadHistoryQuery,
  GetPageBasedTransactionDownloadHistoryQueryVariables,
  InvoiceAssociationStatus,
  TransactionCategory,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { getPageBasedTransactionDownloadHistoryQuery } from '../gql';

export interface TransactionDownloadItem {
  id: string;
  createdAt: string;
  username?: string;
  file?: {
    id?: string;
    name?: string;
    url?: string;
  };
  filters: {
    dateFrom?: string;
    dateTo?: string;
    merchantNames?: string[];
    category?: TransactionCategory[];
    type?: TransactionType[];
    status?: TransactionStatus[];
    invoiceAssociationStatus?: InvoiceAssociationStatus[];
    cards?: {
      id: string;
      refNum?: string;
      cardholder?: {
        firstName?: string;
        lastName?: string;
      };
    }[];
  };
}

export const useTransactionDownloadHistoryData = () => {
  const { data, loading, onLoadMore } = usePagePagination<
    GetPageBasedTransactionDownloadHistoryQuery,
    GetPageBasedTransactionDownloadHistoryQueryVariables
  >(
    getPageBasedTransactionDownloadHistoryQuery,
    'getPageBasedTransactionDownloadHistory',
    {
      variables: {
        input: {
          page: 0,
          limit: 20,
        },
      },
    }
  );

  const transactionHistory = data?.getPageBasedTransactionDownloadHistory;

  const items: TransactionDownloadItem[] = (
    transactionHistory?.records ?? []
  )?.map(item => {
    const {
      cards,
      categories,
      types,
      statuses,
      dateRangeFilters,
      invoiceAssociationStatuses,
      merchantNames,
    } = item.filters ?? {};

    return {
      id: item.id,
      createdAt: item.createdAt,
      filters: {
        dateFrom: dateRangeFilters?.transactionCreatedAt?.dateFrom ?? undefined,
        dateTo: dateRangeFilters?.transactionCreatedAt?.dateTo ?? undefined,
        merchantNames: merchantNames ?? undefined,
        category: categories ?? undefined,
        type: types ?? undefined,
        status: statuses ?? undefined,
        invoiceAssociationStatus: invoiceAssociationStatuses ?? undefined,
        cards:
          cards?.map(card => ({
            id: card.id,
            refNum: card.refNum ?? undefined,
            cardholder: {
              firstName: card.cardholder?.firstName ?? undefined,
              lastName: card.cardholder?.lastName ?? undefined,
            },
          })) ?? undefined,
      },
      file: {
        id: item.file?.id ?? undefined,
        name: item.file?.name ?? undefined,
        url: item.file?.url ?? undefined,
      },
      username: item?.createdBy?.name ?? undefined,
    };
  });

  const totalCount = transactionHistory?.pageInfo?.totalCount ?? 0;

  const hasMore = (transactionHistory?.records?.length ?? 0) < totalCount;

  return {
    items,
    hasMore,
    loading,
    onLoadMore,
  };
};
