import {
  Box,
  Chip,
  ComboBox,
  ComboBoxProps,
  FieldContainer,
  FieldContainerProps,
  Flex,
  Grid,
  Item,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { xor } from 'lodash';
import { ElementRef, KeyboardEvent, ReactNode, useRef, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHookFormField } from '../useHookFormField';
import { ChipItem } from './ChipItem';

export interface HookFormChipsFieldItem {
  id: string;
  name: string;
  description?: string;
}

export interface HookFormChipsFieldProps<TFormValues extends FieldValues> {
  /** Field name */
  name: FieldPath<TFormValues>;
  /** List of field items */
  defaultItems?: HookFormChipsFieldItem[];
  /** Shown when list box is empty */
  emptyListPlaceholder?: ReactNode;
  /** Field label */
  label?: ReactNode;
  /** Aria label used when label is ReactNode */
  'aria-label'?: string;
  /** Message to display in tooltip */
  message?: string;
  /** Called when field value changes */
  onChange?: (value: string[]) => void;
  /** Shown when field is empty */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Is adding more values disabled? */
  disabled?: boolean;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
  /** Register input's ref into the hook form, please note that it can have unintended side effects */
  forceInputFieldRef?: boolean;
  /** Render list with custom layout */
  renderCustomDropdown?: ComboBoxProps['renderCustomDropdown'];
  /** Called when user scrolls to end of dropdown list */
  onEndReached?: (index: number) => void;
  /** Called when user searches in the input field */
  onSearch?: (inputValue: string) => void | Promise<void>;
  /** Called when combo box text input changes */
  onInputChange?: (inputValue: string) => void | Promise<void>;
  /** Array containing the values of the selected items from dropdown or selecte values from the BE.
   * Needed when using a query with pagination.
   **/
  selectedOptions?: Array<{ name: string; id: string }>;
}

export const HookFormChipsField = <TFormValues extends FieldValues>({
  name,
  defaultItems = [],
  disabled: disabledProp,
  forceInputFieldRef = true, // default true to be able to focus the field when removing chips
  isLoading,
  label,
  message,
  onChange: onChangeProp,
  placeholder,
  readOnly: readOnlyProp,
  variant,
  renderCustomDropdown,
  emptyListPlaceholder,
  'aria-label': ariaLabel,
  onEndReached,
  onSearch,
  onInputChange,
  selectedOptions = [],
}: HookFormChipsFieldProps<TFormValues>) => {
  const [t] = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const {
    fieldContainerProps,
    inputProps: { onChange, value, readOnly, ...inputProps },
    setFocus,
  } = useHookFormField<TFormValues>({
    message,
    name,
    onChange: onChangeProp,
    readOnly: readOnlyProp,
    disabled: disabledProp,
    variant,
    forceInputFieldRef,
  });

  const comboBoxRef = useRef<ElementRef<'div'>>(null);

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  const selectedChipsIds = (value as string[]) ?? [];

  const selectedOptionsIds = selectedOptions.map(option => option.id);

  const chipsWithPagination =
    selectedOptions.length > 0
      ? selectedOptions.map(option => (
          <Chip
            key={option.id}
            size="xsmall"
            variant="lightGray"
            isDisabled={readOnly}
            type="removable"
            onClickButton={() => {
              const newOptionsSelected = xor(selectedOptionsIds, [option.id]);

              onChange(newOptionsSelected); // remove chip with given id from list
            }}>
            {option.name}
          </Chip>
        ))
      : false;

  const chipsWithoutPagination =
    defaultItems.length > 0 &&
    selectedChipsIds.length > 0 &&
    selectedChipsIds.map(value => (
      <ChipItem
        key={value}
        defaultItems={defaultItems}
        id={value}
        isDisabled={readOnly}
        onRemove={() => {
          onChange(selectedChipsIds.filter(id => id !== value)); // remove chip with given id from list
          setFocus();
          if (!forceInputFieldRef)
            comboBoxRef.current
              ?.querySelector<HTMLInputElement>(`[aria-label=${labelInput}]`)
              ?.focus();
        }}
      />
    ));

  return (
    <FieldContainer
      label={label}
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      placeholder={t('common.select')}
      {...(mergeProps(fieldContainerProps, labelProps) as Omit<
        FieldContainerProps,
        'color'
      >)}
      isLoading={isLoading}>
      <Flex
        role="listbox"
        aria-label={ariaLabel}
        inline
        gap="space8"
        alignItems="center"
        width="100%"
        wrap="wrap">
        {chipsWithPagination ? chipsWithPagination : chipsWithoutPagination}
        {!readOnly && (
          <Grid
            templateColumns="1fr auto"
            flex="1 1 50%"
            onKeyDownCapture={({
              key,
              target,
            }: KeyboardEvent<HTMLDivElement>) => {
              if (
                key === 'Backspace' &&
                (target as HTMLInputElement).value === ''
              ) {
                const newValue = selectedChipsIds.slice(0, -1); // remove last element
                onChange(newValue);
              }
            }}>
            <Box style={{ display: inputProps.disabled ? 'none' : 'initial' }}>
              <ComboBox
                emptyListPlaceholder={
                  emptyListPlaceholder ?? t('common.nothingFound')
                }
                onSelectionChange={async value => {
                  if (value !== null) {
                    const newValue = [...selectedChipsIds, value as string];
                    onChange(newValue);
                    await onInputChange?.('');
                  }

                  setInputValue('');
                }}
                selectedKey={null}
                key={`${disabledProp}`} // Force close dropdown when disabled changes
                placeholder={value?.length ? '' : placeholder}
                defaultItems={defaultItems.filter(
                  ({ id }) => !selectedChipsIds.includes(id)
                )}
                inputValue={inputValue}
                onInputChange={value => {
                  const stringValue = value ?? '';
                  setInputValue(stringValue);
                  void onInputChange?.(stringValue);
                }}
                onClear={undefined}
                onEndReached={onEndReached}
                /** @ts-expect-error TODO: React upgrade props types mismatch */
                onSearch={onSearch}
                isVirtualized
                clearLabel={t(
                  'document.requestApproval.inputs.approvalStep.remove'
                )}
                message={fieldContainerProps.message}
                variant={fieldContainerProps.variant}
                renderCustomDropdown={renderCustomDropdown}
                {...mergeProps(labelFieldProps, inputProps)}
                ref={comboBoxRef}>
                {({ id, name }) => (
                  <Item key={id} textValue={name}>
                    {name}
                  </Item>
                )}
              </ComboBox>
            </Box>
          </Grid>
        )}
      </Flex>
    </FieldContainer>
  );
};
