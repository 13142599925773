import { Grid, Modal } from '@candisio/design-system';
import { useHasAlreadyPhysicalCard } from '../CreditCardDrawer/Tabs/Request/hooks';
import { AccountingInfoFormContainer } from './AccountingInfoStep/AccountingInfoFormContainer';
import { CardSection } from './CardSection/CardSection';
import { SuccessStepCardSection } from './CardSection/SuccessStepCardSection';
import { CardUsageFormContainer } from './CardUsageStep/CardUsageFormContainer';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from './CreateCreditCardModalDataProvider';
import { CreateCreditCardModalHeader } from './CreateCreditCardModalHeader';
import { LimitsAndCardTypeStep } from './LimitsAndCardTypeStep';
import { SelectMememberStepContainer } from './SelectMemberStepContainer';
import { SuccessStep } from './SuccessStep/SuccessStep';
import { useModalInitialState } from './utils/utils';

interface CreateCreditCardModalNewProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateCreditCardModal = ({
  isOpen,
  onClose,
}: CreateCreditCardModalNewProps) => {
  const setModalState = useCreateCreditCardModalSetState();
  const initialState = useModalInitialState();

  const { formStep, teamMemberId } = useCreateCreditCardModalState();

  const { hasPhysicalCard } = useHasAlreadyPhysicalCard(teamMemberId);

  const handleCardCreatedSuccess = (cardId: string) => {
    setModalState(prevState => ({
      ...prevState,
      formStep: 'Success',
      createdCreditCardId: cardId,
      cardDetails: {
        ...prevState.cardDetails,
      },
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      padding="2px"
      width="76.75rem"
      height="48rem"
      onClose={() => {
        onClose();
        setModalState(initialState);
      }}>
      <Grid
        templateColumns={formStep === 'Success' ? '50% 50%' : '30% 70%'}
        height="100%">
        {formStep === 'Success' ? <SuccessStepCardSection /> : <CardSection />}
        <Grid
          background="gray50"
          paddingTop={formStep === 'Success' ? 'space16' : undefined}
          paddingBottom="0px"
          height="100%"
          overflow="hidden"
          templateRows="auto 1fr">
          <CreateCreditCardModalHeader onClose={onClose} />
          {formStep === 'TeamMember' && <SelectMememberStepContainer />}
          {formStep === 'CardTypeAndLimits' && (
            <LimitsAndCardTypeStep hasPhysicalCard={hasPhysicalCard} />
          )}
          {formStep === 'CardUsage' && <CardUsageFormContainer />}
          {formStep === 'AccountingInfo' && (
            <AccountingInfoFormContainer
              onCardCreatedSuccess={handleCardCreatedSuccess}
            />
          )}
          {formStep === 'Success' && <SuccessStep onClose={onClose} />}
        </Grid>
      </Grid>
    </Modal>
  );
};
