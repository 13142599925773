import { ImportHistoryItem } from 'components/HistoryList/HistoryList';
import { useContactsImportHistoryQuery } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useSap } from 'orgConfig/sap';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { CONTACT_ROUTE_HASH } from 'views/Contacts/types';

export const useContactImportHistoryData = () => {
  const organizationSlug = useOrganizationId();
  const { shouldUseSapContacts: isSapVariant } = useSap();
  const contactBackUrl = `/${organizationSlug}${Routes.CONTACTS}`;
  const contactImportUrl = `/${organizationSlug}${Routes.CONTACTS}${CONTACT_ROUTE_HASH.import}`;

  const { data, loading: isLoading } = useContactsImportHistoryQuery({
    fetchPolicy: 'network-only',
  });

  const contactHistoryItems: ImportHistoryItem[] = data
    ? data.contactsImportHistory?.map(item => {
        return {
          createdAt: item.createdAt,
          errorFileUrl: item.errorFile?.url ? item.errorFile?.url : undefined,
          file: {
            name: item.file.name,
            url: item.file.url,
          },
          importStatus: {
            countFailedToCreate: item.countFailedToCreate,
            countSuccessfullyCreated: item.countSuccessfullyCreated,
          },
          username: item.createdBy.name,
        };
      })
    : [];

  return {
    contactHistoryItems,
    contactBackUrl,
    contactImportUrl,
    isLoading,
    isSapVariant,
  };
};
