import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectMemberStepValues } from './utils/selectMemberStepSchema';

interface CreateCreditCardComboBoxFieldProps
  extends HookFormComboBoxFieldProps<SelectMemberStepValues> {
  name: keyof SelectMemberStepValues;
  suggestion?: {
    membershipId?: string;
    name: string;
  };
}

export const CreateCreditCardComboBoxField = ({
  name,
  suggestion,
  ...restProps
}: CreateCreditCardComboBoxFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { formState, getFieldState } = useFormContext<SelectMemberStepValues>();

  const isDirty = getFieldState(name, formState).isDirty;

  const variant = !isDirty && suggestion?.name ? 'success' : 'default';

  const message =
    !isDirty && suggestion?.name
      ? t('recurringPaymentsModal.createNewCard.suggestionMessage')
      : undefined;

  return (
    <HookFormComboBoxField
      {...restProps}
      name={name}
      variant={variant}
      message={message}
    />
  );
};
