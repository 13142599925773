import gql from 'graphql-tag';

export const getDatevSettings = gql`
  query new_datevSettings {
    new_datevSettings {
      client {
        __typename
        clientNumber
        connectedAt
        connectedBy
        consultantNumber
        exportType
        id
        isDxsoCashLedgerConfigured
        isDxsoJobsAvailable
        isExtfsFilesAvailable
        isBdsPermissionVerified
        isFiscalYearConfiguredForYear2024
        name
      }
      documentTypes {
        isActive
        name
        category
        bookingAccount
      }
      creditCardsLedger {
        bookingAccount
        transitAccount
      }
      provisionsLedger {
        provisionAccountNumber
        otherAssetsAccountNumber
      }
      hasAuthTokenForExportDocuments
      hasAuthTokenForReadClients
      connectionType
      chartOfAccount {
        accountLength
        code
      }
      datevCoreDataSource {
        isAccountLengthSourceDatev
        isChartOfAccountCodeSourceDatev
        isFiscalStartDateSourceDatev
      }
    }
  }
`;

export const getDatevClientsQuery = gql`
  query new_datevClients {
    new_datevClients {
      clientNumber
      consultantNumber
      id
      name
      isDxsoJobsAvailable
      isDxsoCashLedgerConfigured
      exportType
    }
  }
`;

export const datevClientInfo = gql`
  query datevClientInfo {
    datevClientInfo {
      id
      is_document_management_available
      client_number
      consultant_number
      ledgerState {
        cash
        accountsPayable
        accountsReceivable
      }
    }
  }
`;

export const getAvailableDocumentCategories = gql`
  query availableDocumentCategories {
    availableDocumentCategories {
      direction
      documentType
      isDefault
      isRds1_0Exportable
      isBdsExportable
      supportedCurrencies
    }
  }
`;

export const getAllDocumentTypes = gql`
  query allDocumentTypes {
    availableDocumentCategories(includeInactiveTypes: true) {
      documentType
      isDefault
    }
  }
`;

export const getBookingAccountValidation = gql`
  query datevBookingAccountValidation {
    datevBookingAccountValidation {
      minLength
      maxLength
    }
  }
`;

export const refetchDatevData = [
  { query: getDatevSettings },
  { query: getBookingAccountValidation },
  { query: getAvailableDocumentCategories },
];

export const refetchDatevDataWithNoToken = [
  { query: getDatevSettings },
  { query: getAvailableDocumentCategories },
];

export const getDatevAvailableServices = gql`
  query datevAvailableServices {
    datevAvailableServices {
      service
      warning
    }
  }
`;

export const getDatevBDSPreRequisites = gql`
  query datevBDSPreRequisites {
    datevBDSPreRequisites {
      step
      completed
    }
  }
`;

export const datevBdsPermission = gql`
  query datevBdsPermission {
    datevBdsPermission {
      ... on BdsPermissionErrorResult {
        __typename
        status
        error
      }
      ... on BdsPermissionSuccessResult {
        __typename
        status
      }
    }
  }
`;
