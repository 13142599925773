// eslint-disable-next-line no-restricted-imports
import baseStyled, {
  css as baseCss,
  ThemedCssFunction,
  ThemedStyledInterface,
} from 'styled-components';
import { Theme } from './Theme.interface';

/**
 * @deprecated Please use the design system instead.
 * @see https://candisio.github.io/design-system
 */
export const styled = baseStyled as ThemedStyledInterface<Theme>;
/**
 * @deprecated Please use the design system instead.
 * @see https://candisio.github.io/design-system
 */
export const css = baseCss as ThemedCssFunction<Theme>;
