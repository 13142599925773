import { Grid, Link, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useNumberFormat } from 'utils/useNumberFormat';
import { ProcessingFormFieldMetadata } from './ProcessingFormMetadataContext';

/** Tooltip message describing the metadata associated with the field */
export const ProcessingFormFieldMetadataMessage = ({
  confidence,
  source,
}: ProcessingFormFieldMetadata) => {
  const [t] = useTranslation();
  const percentFormatter = useNumberFormat({ style: 'percent' });

  return (
    <Grid fontSize="small">
      <Text fontWeight="bold">
        {
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          t(`document.requestApproval.fieldRecognition.${source}.title`)
        }
      </Text>
      <Text>
        {
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          t(`document.requestApproval.fieldRecognition.${source}.confidence`, {
            confidence: percentFormatter.format(confidence),
          })
        }
      </Text>
      <Text>
        {
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          t(
            `document.requestApproval.fieldRecognition.${source}.source.message`
          )
        }{' '}
        <Link
          external
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          href={t(
            `document.requestApproval.fieldRecognition.${source}.source.infoUrl`
          )}>
          {
            // eslint-disable-next-line candis/no-template-strings-inside-translation
            t(`document.requestApproval.fieldRecognition.${source}.source.type`)
          }
        </Link>
      </Text>
    </Grid>
  );
};
