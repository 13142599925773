import { ScrollBox } from '@candisio/design-system';
import { defaultTheme } from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { CSSProp, css } from 'styled-components';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export const Wrapper = styled(ScrollBox)<{ pageCss?: CSSProp }>`
  .react-pdf__Page {
    background: transparent !important;
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto;
  }

  .react-pdf__Page > * {
    grid-column: 2;

    &:not(span),
    .endOfContent {
      cursor: grab;
    }
  }

  .react-pdf__Page__canvas {
    border-radius: ${defaultTheme.space.space16};
    ${({ pageCss }) =>
      css`
        ${pageCss}
      `}
  }
`;
