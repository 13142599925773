import { TeamMemberInput } from 'generated-types/graphql.types';

/**
 * Maintains consistency and uniqueness in the list of team members.
 * Ensures the team admin is included with the isAdmin flag set to true,
 * and removes any duplicate occurrences of the team admin from the list.
 *
 * @param {string} teamAdmin - The ID of the team admin.
 * @param {string[]} teamMembers - The list of team members' IDs.
 * @returns {TeamMemberInput[]} The sanitized list of team members.
 **/

interface SanitizeMembersProps {
  teamAdmin: string;
  teamMembers: string[];
}

export const sanitizeMembers = ({
  teamAdmin,
  teamMembers,
}: SanitizeMembersProps): TeamMemberInput[] => {
  const otherTeamMembers = teamMembers
    .filter(memberId => memberId !== teamAdmin)
    .map(memberId => ({ membershipId: memberId }));

  return [
    {
      isAdmin: true,
      membershipId: teamAdmin,
    },
    ...otherTeamMembers,
  ];
};
