import { useSap } from 'orgConfig/sap';
import { useWatch } from 'react-hook-form';
import { DOCUMENT_TYPES } from 'views/Inbox/DocumentProcessing/consts';
import { DocumentFormValues } from '../schema';

export const useShowCreditMemoFieldsDocumentForm = () => {
  const sap = useSap();
  const [type] = useWatch<DocumentFormValues, ['category.type']>({
    name: ['category.type'],
  });

  const isCreditMemo =
    sap.isActiveIntegration && type === DOCUMENT_TYPES.EINGANGSGUTSCHRIFT;

  return !isCreditMemo;
};
