import { Text } from '@candisio/design-system';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

type Value = boolean | undefined;

export const InvoiceCorrectionCell = ({
  value,
}: CellProps<DocumentsTableData, Value>) => {
  const { t } = useTranslation();

  const yesKey = 'documents-table:isInvoiceCorrection.yes';
  const noKey = 'documents-table:isInvoiceCorrection.no';

  const message = value ? t(yesKey) : t(noKey);

  return <Text>{message}</Text>;
};
