import {
  HookFormChipsField,
  HookFormChipsFieldItem,
} from 'components/HookFormFields/HookFormTagsField/HookFormChipsField';
import { DocumentFilterInput } from 'generated-types/graphql.types';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCostCenterFieldOptions } from '../../hooks/useCostCenterFieldOptions';
import { useCostObjectFieldOptions } from '../../hooks/useCostObjectFieldOptions';
import { WidgetFormData } from '../../types';

interface WidgetCostCentersProps {
  changeTitle: (options: HookFormChipsFieldItem[]) => void;
  handleGetWidgetSumForFilters: (filters: DocumentFilterInput) => void;
  hasCostCenters: boolean;
  hasCostObjects: boolean;
  initialValues: { costCenters: string[]; costObjects: string[] };
}

export const WidgetCostCenters = ({
  changeTitle,
  handleGetWidgetSumForFilters,
  hasCostCenters,
  hasCostObjects,
  initialValues,
}: WidgetCostCentersProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const [costCentersFieldValue, costObjectsFieldValue] = useWatch<
    WidgetFormData,
    ['costCenters', 'costObjects']
  >({
    name: ['costCenters', 'costObjects'],
  });

  const [selectedCostCenters, setSelectedCostCenters] = useState<string[]>(
    initialValues.costCenters
  );

  const [selectedCostObjects, setSelectedCostObjects] = useState<string[]>(
    initialValues.costObjects
  );

  const {
    handleDebounceSearch: handleDebounceSearchCostCenters,
    loadMore: loadMoreCostCenters,
    defaultItems: defaultCostCenters,
    selectedCostCenterItems,
    loading: isCostCenterLoading,
  } = useCostCenterFieldOptions({
    selectedValues: selectedCostCenters,
  });

  const {
    handleDebounceSearch: handleDebounceSearchCostObjects,
    loadMore: loadMoreCostObjects,
    defaultItems: defaultCostObjects,
    selectedCostObjectItems,
    loading: isCostObjectLoading,
  } = useCostObjectFieldOptions({
    selectedValues: selectedCostObjects,
  });

  const onChangeCostCentersOrCostObjects = ({
    selectedCostCenterIds,
    selectedCostObjectIds,
  }: {
    selectedCostCenterIds: string[];
    selectedCostObjectIds: string[];
  }) => {
    setSelectedCostCenters(selectedCostCenterIds);
    setSelectedCostObjects(selectedCostObjectIds);

    const selectedCostCenterItems =
      defaultCostCenters?.filter(item =>
        selectedCostCenterIds.includes(item.id)
      ) ?? [];

    const selectedCostObjectItems =
      defaultCostCenters?.filter(item =>
        selectedCostObjectIds.includes(item.id)
      ) ?? [];

    changeTitle([...selectedCostCenterItems, ...selectedCostObjectItems]);

    handleGetWidgetSumForFilters({
      costCenterIds: selectedCostCenterIds,
      costObjectIds: selectedCostObjectIds,
    });
  };

  return (
    <>
      {hasCostCenters && (
        <HookFormChipsField
          label={t('dashboard.createNewWidget.types.costCenter.title', {
            context: getTranslationContext(),
          })}
          name="costCenters"
          placeholder={t(
            'dashboard.createNewWidget.types.costCenter.placeholder',
            {
              context: getTranslationContext(),
            }
          )}
          onChange={costCenterIds => {
            onChangeCostCentersOrCostObjects({
              selectedCostCenterIds: costCenterIds,
              selectedCostObjectIds: costObjectsFieldValue,
            });
          }}
          isLoading={isCostCenterLoading}
          onInputChange={handleDebounceSearchCostCenters}
          onEndReached={loadMoreCostCenters}
          defaultItems={defaultCostCenters}
          selectedOptions={selectedCostCenterItems}
        />
      )}
      {hasCostObjects && (
        <HookFormChipsField
          label={t('dashboard.createNewWidget.types.costObject.title', {
            context: getTranslationContext(),
          })}
          name="costObjects"
          placeholder={t(
            'dashboard.createNewWidget.types.costObject.placeholder',
            {
              context: getTranslationContext(),
            }
          )}
          onChange={costObjectIds =>
            onChangeCostCentersOrCostObjects({
              selectedCostCenterIds: costCentersFieldValue,
              selectedCostObjectIds: costObjectIds,
            })
          }
          isLoading={isCostObjectLoading}
          onInputChange={handleDebounceSearchCostObjects}
          onEndReached={loadMoreCostObjects}
          defaultItems={defaultCostObjects}
          selectedOptions={selectedCostObjectItems}
        />
      )}
    </>
  );
};
