import {
  Button,
  Flex,
  Grid,
  Image,
  Text,
  useTheme,
} from '@candisio/design-system';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import {
  CreditCardsTrackingEvents,
  useAnalytics,
} from 'providers/AnalyticsProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardAdoptedInfoBanner } from 'views/Dashboard/components/CreditCardAdoptedInfoBanner';
import { ApplyForCreditCardsModalHash } from '../CreditCardApplication/CreditCardApplicationSteps/toolkit/utils';
import { CreditCardsApplicationModal } from '../CreditCardApplication/CreditCardsApplicationModal';
import pliant from './media/pliant-logo.png';

export const CreditCardsInitialState = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const creditCardsSetup = useCreditCardsSetup();

  const isModalVisible =
    location.hash === ApplyForCreditCardsModalHash.APPLY_FOR_CC &&
    creditCardsSetup.isAddonBought;

  const onClose = () => {
    navigate(
      {
        pathname: location.pathname,
      },
      { replace: true }
    );
  };

  return (
    <Grid gap="space32" alignSelf="start">
      <CreditCardAdoptedInfoBanner routeType="Settings" />
      <PliantPanel />
      <CreditCardsApplicationModal isOpen={isModalVisible} close={onClose} />
    </Grid>
  );
};

const PliantPanel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { track } = useAnalytics();
  const { colors } = useTheme();

  const handleClick = () => {
    track(CreditCardsTrackingEvents.REQUEST_VIRTUAL_CREDIT_CARD_CLICKED);
  };

  return (
    <Grid
      padding="space16"
      gap="space16"
      borderRadius="medium"
      background="gray50"
      autoFlow="column"
      alignItems="center"
      justifyContent="space-between">
      <Flex alignItems="center">
        <Image src={pliant} height="space48" alt="pliant logo" />
        <Text fontSize="basic">
          <Trans t={t} i18nKey="informationBox.subDescription">
            For the issuance of credit cards we cooperate with our partner{' '}
            <Text fontWeight="bold">Pliant.</Text>
          </Trans>
        </Text>
      </Flex>
      <Button
        as="a"
        target="_blank"
        variant="secondary"
        href={t('informationBox.whyPliant.url')}
        style={{ color: colors.gray800 }}
        onClick={handleClick}>
        {t('informationBox.whyPliant.text')}
      </Button>
    </Grid>
  );
};
