import { escapeRegex } from 'utils/regex';
import { PaymentConditionCellValue, PaymentConditionsListData } from './types';

export enum SortBy {
  ascending = 'ascending',
  id = 'id',
  lastUpdated = 'lastUpdated',
}

export const queryPaymentConditions = (
  paymentConditions: PaymentConditionsListData[],
  renderPaymentCondition: (conditions: PaymentConditionCellValue) => string,
  queryString: string
) => {
  const regExp = new RegExp(escapeRegex(queryString), 'i');

  return paymentConditions.filter(paymentCondition => {
    if (
      paymentCondition.conditionNumber.toString().match(regExp) ||
      paymentCondition.conditionName?.match(regExp) ||
      renderPaymentCondition(paymentCondition.condition).match(regExp)
    ) {
      return true;
    }

    return null;
  });
};

export const sortPaymentConditions = (
  paymentConditions: PaymentConditionsListData[] | null,
  renderPaymentCondition: (conditions: PaymentConditionCellValue) => string,
  sortBy?: SortBy
) => {
  const conditions = paymentConditions ?? [];

  switch (sortBy) {
    case SortBy.ascending: {
      const collator = new Intl.Collator([], { numeric: true });

      return conditions.sort(
        (a: PaymentConditionsListData, b: PaymentConditionsListData) =>
          collator.compare(
            renderPaymentCondition(a.condition),
            renderPaymentCondition(b.condition)
          )
      );
    }

    case SortBy.id: {
      return conditions.sort((a, b) => a.conditionNumber - b.conditionNumber);
    }

    case SortBy.lastUpdated: {
      return conditions.sort(
        (a: PaymentConditionsListData, b: PaymentConditionsListData) => {
          return (a.updatedAt ?? '') < (b.updatedAt ?? '') ? 1 : -1;
        }
      );
    }

    default: {
      return conditions;
    }
  }
};
