import gql from 'graphql-tag';
import {
  transactionListFragment,
  pageInfoDataFragment,
} from 'views/queries/fragments';

export const archiveCardIssuerTransactionsQuery = gql`
  query archiveCardIssuerTransactions(
    $input: CardIssuerTransactionPaginationInput!
    $filters: CardIssuerTransactionFilterInput
    $sortBy: CardIssuerTransactionSortInput
    $queries: CardIssuerTransactionsQueryInput
  ) {
    archiveCardIssuerTransactions(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    ) {
      edges {
        cursor
        node {
          ...transactionListData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${transactionListFragment}
`;

export const getForeignCurrencyFeeSavings = gql`
  query getForeignCurrencyFeeSavings(
    $filters: DocumentFilterInput
    $dateRangeFilters: DocumentDateFilterInput
  ) {
    getForeignCurrencyFeeSavings(
      filters: $filters
      dateRangeFilters: $dateRangeFilters
    ) {
      aggregate {
        currency
        totalAmount
        estimatedFeesSaving
      }
    }
  }
`;
