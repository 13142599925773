import { AppRouteParams, Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { SETTINGS_ROUTES } from 'views/Settings/types';
import { useCheckTeamNameAvailability } from '../../toolkit/hooks/useCheckTeamNameAvailability';
import { useCreateTeam } from '../../toolkit/hooks/useCreateTeam';
import {
  CREATE_TEAM_URL_PARAM,
  TEAM_ID_URL_PARAM,
} from '../../toolkit/hooks/useModalVisibility';
import { TeamDetailsFormOutput } from '../../toolkit/utils/teamDetailsFormSchema';
import { CreateTeamModalForm } from './CreateTeamModalForm';

interface CreateTeamContainerProps {
  isOpen: boolean;
}

export const CreateTeamContainer = ({ isOpen }: CreateTeamContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const { createTeam, createTeamPending } = useCreateTeam();
  const { checkTeamName } = useCheckTeamNameAvailability();

  const handleSubmit = async (values: TeamDetailsFormOutput) => {
    const teamId = await createTeam(values);

    if (!teamId) {
      return;
    }

    const searchParams = new URLSearchParams();

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}/:id?`,
      { organizationSlug, id: CREATE_TEAM_URL_PARAM }
    );

    searchParams.set(TEAM_ID_URL_PARAM, teamId);
    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  const handleClose = () => {
    navigate({ pathname: '..' }, { relative: 'path' });
  };

  return (
    // Passing a unique key forces the component to re-render,
    // ensuring the modal form is reset when it is reopened.
    <CreateTeamModalForm
      key={`${isOpen}-${id}`}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isSubmitting={createTeamPending}
      isOpen={isOpen}
      checkTeamName={checkTeamName}
    />
  );
};
