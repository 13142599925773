import { MenuItem } from '@candisio/design-system';
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { RefObject, useState } from 'react';
import { getCountryName } from 'utils/get-country-name';
import { removeWhitespace } from 'utils/removeWhitespace';

interface UseDialingCodeProps {
  phoneNumber?: string;
  phoneNumberInputRef: RefObject<HTMLInputElement>;
  onChangePhoneNumber: (phoneNumber: string) => void;
}

interface DialingCode {
  code: string;
  country: CountryCode;
}

export const defaultDialingCode: DialingCode = {
  code: getCountryCallingCode('DE'),
  country: 'DE',
};

const getDialingCodeFromPhoneNumber = (
  phoneNumber?: string
): DialingCode | null => {
  try {
    return phoneNumber
      ? {
          code: parsePhoneNumber(phoneNumber).countryCallingCode,
          country: parsePhoneNumber(phoneNumber).country as CountryCode,
        }
      : null;
  } catch (_) {
    // `parsePhoneNumber` can throw an error if the phone number is too short/incomplete
    return null;
  }
};

export const useDialingCodes = ({
  phoneNumber,
  phoneNumberInputRef,
  onChangePhoneNumber,
}: UseDialingCodeProps) => {
  const [dialingCode, setDialingCode] = useState<DialingCode | null>(
    getDialingCodeFromPhoneNumber(phoneNumber)
  );

  const dialingCodes: MenuItem[] = getCountries()
    .map<MenuItem>(country => {
      return {
        id: country,
        label: `${getCountryName(country)} (+${getCountryCallingCode(
          country
        )})`,
        onAction: () => {
          const currentCode = dialingCode?.code;
          const currentPhoneNumber = phoneNumber ?? '';
          phoneNumberInputRef.current?.focus();

          let newPhoneNumber: string;
          if (currentCode) {
            const phoneNumberRegex = new RegExp(
              `^\\+(?<dialingCode>${currentCode})(?<phNumber>\\w+)`
            );

            const { phNumber = '' } =
              phoneNumberRegex.exec(removeWhitespace(currentPhoneNumber))
                ?.groups ?? {};

            newPhoneNumber = `+${getCountryCallingCode(country)}${phNumber}`;

            onChangePhoneNumber(newPhoneNumber);
          } else {
            newPhoneNumber = `+${getCountryCallingCode(
              country
            )}${currentPhoneNumber}`;

            onChangePhoneNumber(newPhoneNumber);
          }

          setDialingCode({
            code: getCountryCallingCode(country),
            country,
          });
        },
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    dialingCode,
    setDialingCode,
    dialingCodes,
  };
};
