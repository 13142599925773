import { Grid, TruncatedText } from '@candisio/design-system';
import { ErrorIcon } from 'components/Icons/DefaultIcons';
import { friendlyFormatIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { PaymentTableData } from './types';

export type PaymentAmountCellProps = CellProps<
  PaymentTableData,
  PaymentTableData['paymentInfo']
>;

export const PaymentInfoCell = ({ value }: PaymentAmountCellProps) => {
  const [t] = useTranslation('payments');

  if (isNil(value) || isNil(value.iban)) {
    return (
      <Grid alignItems="center" gap="space4" templateColumns="auto 1fr">
        <ErrorIcon size="space16" />
        <TruncatedText color="gray600">
          {t('table.warning.ibanMissing')}
        </TruncatedText>
      </Grid>
    );
  }

  const { iban, swiftCode } = value;
  const formattedIban = friendlyFormatIBAN(iban);
  const displayValueWithSwift = `${formattedIban ?? ''} / ${swiftCode}`;
  const displayValueWithoutSwift = `${formattedIban ?? ''}`;

  return (
    <TruncatedText charsAfterEllipsis={5}>
      {swiftCode ? displayValueWithSwift : displayValueWithoutSwift}
    </TruncatedText>
  );
};
