import gql from 'graphql-tag';

export const FileUploadsSchema = gql`
  type File {
    id: String!
    fileName: String!
    state: String!
    errorDetails: String
    documentId: String
  }
  input FileInput {
    id: String!
    fileName: String!
    state: String!
    errorDetails: String
    documentId: String
  }
`;
