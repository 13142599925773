import { Flex, Grid, Image, Text } from '@candisio/design-system';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useTranslation } from 'react-i18next';
import appleStoreLogoDE from '../components/media/applestore-logo-de.svg';
import appleStoreLogoEN from '../components/media/applestore-logo-en.svg';
import candisLogo from '../components/media/candis-logo.png';
import googlePlayLogoDE from '../components/media/googleplay-de.svg';
import googlePlayLogoEN from '../components/media/googleplay-en.svg';
import qrCode from '../components/media/invite_to_use_app_email_team_members.jpg';

interface InviteUserToUseAppDraftEmailProps {
  memberName: string;
}

export const InvitationEmailPreview = ({
  memberName,
}: InviteUserToUseAppDraftEmailProps) => {
  const [t] = useTranslation();

  const currentUserName = useCurrentUser()?.name;

  const appleStoreLogo =
    useCurrentUser()?.locale === 'EN' ? appleStoreLogoEN : appleStoreLogoDE;

  const googlePlayLogo =
    useCurrentUser()?.locale === 'EN' ? googlePlayLogoEN : googlePlayLogoDE;

  return (
    <Grid background="white" borderRadius="16px" padding="26px 36px 26px 36px">
      <Flex
        direction="column"
        paddingBottom="0"
        gap="space16"
        width="300px"
        paddingTop="space24">
        <Flex direction="column" gap="space16" width="300px">
          <Flex direction="column" gap="space8">
            <Image src={candisLogo} alt="" width="96.8px" height="28.48px" />
            <Text fontSize="xxlarge" fontWeight="500" lineHeight="33.6px">
              {t('settings:teamMembers.inviteTeamMember.emailDraft.subject')}
            </Text>
          </Flex>
          <Text fontSize="small" whiteSpace="pre-wrap">
            {t('settings:teamMembers.inviteTeamMember.emailDraft.body', {
              memberName,
              adminName: currentUserName,
            })}
          </Text>
        </Flex>
        <Flex alignItems="center" gap="space24" height="80px" width="316px">
          <Image src={qrCode} alt="" width="80px" height="80px" />
          <Flex direction="column" gap="space8" width="212px">
            <Text fontSize="small" whiteSpace="pre-wrap">
              {t(
                'settings:teamMembers.inviteTeamMember.emailDraft.qrCodeDescription'
              )}
            </Text>
          </Flex>
        </Flex>
        <Flex direction="column" paddingBottom="space16" gap="space16">
          <Flex gap="space4">
            <Image width="96px" height="32px" src={appleStoreLogo} alt="" />
            <Image width="108px" height="32px" src={googlePlayLogo} alt="" />
          </Flex>
          <Text whiteSpace="pre-wrap">
            {t('settings:teamMembers.inviteTeamMember.emailDraft.footer')}
          </Text>
        </Flex>
      </Flex>
    </Grid>
  );
};
