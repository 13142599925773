import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { HeaderLayout } from './HeaderLayout';

type GreeterKey = 'morning' | 'afternoon' | 'evening' | 'night';

type HeaderGreeterProps = {
  firstName?: string;
};

const greeter: Record<GreeterKey, string> = {
  morning: 'headerGreeter.morning',
  afternoon: 'headerGreeter.afternoon',
  evening: 'headerGreeter.evening',
  night: 'headerGreeter.night',
};

export const HeaderGreeterComponent = ({
  firstName,
  hour,
}: HeaderGreeterProps & { hour?: number }) => {
  const [tDashboard] = useTranslation('dashboard');

  const getTimeOfDay = () => {
    let displayTime = greeter.morning;

    if (hour && hour >= 12 && hour < 17) {
      displayTime = greeter.afternoon;
    } else if (hour && hour >= 17 && hour <= 20) {
      return greeter.evening;
    } else if (hour && hour > 20 && hour <= 24) {
      return greeter.night;
    }

    return displayTime;
  };

  const timeOfDay = getTimeOfDay();
  const greeterText = tDashboard(timeOfDay, { firstName });

  return <HeaderLayout>{greeterText}</HeaderLayout>;
};

export const HeaderGreeter = (props: HeaderGreeterProps) => {
  const formattedHour = moment().format('HH');
  const hour = Number(formattedHour);

  return <HeaderGreeterComponent {...props} hour={hour} />;
};
