import { defaultTheme } from '@candisio/design-system';
import { Spinner } from '@candisio/design-system';
import { ReactElement } from 'react';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export type InitialLoadingFooter = string | ReactElement<HTMLElement>;

type Props = {
  footer?: InitialLoadingFooter;
};

const Wrapper = styled.div`
  max-width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const InitialLoadingIndicatorText = styled.span`
  color: ${defaultTheme.colors.gray500};
  font-size: ${defaultTheme.fontSizes.xsmall};

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 0.25s ease-in 1 both fadeInOpacity;
  animation-delay: 0.5s;
`;

export const InitialLoadingIndicator = ({ footer }: Props) => {
  return (
    <Wrapper>
      <Spinner
        color="gray400"
        size="space64"
        data-cy="table-initial-loading-indicator"
      />
      {footer ? footer : null}
    </Wrapper>
  );
};
