import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const singleUseCardLimitsFormSchema = () => {
  const schema = z.object({
    transactionLimit: z.number(),
  });

  return schema;
};

export type SingleUseCardLimitsFormOutput = z.infer<
  ReturnType<typeof singleUseCardLimitsFormSchema>
>;

export type SingleUseCardLimitsFormValues =
  Partial<SingleUseCardLimitsFormOutput>;

export type SingleUseCardLimitsErrorMessages = ErrorMessages<
  ReturnType<typeof singleUseCardLimitsFormSchema>
>;
