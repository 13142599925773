import { Routes } from 'models';
import { useLocation, useMatch, useNavigate } from 'react-router-dom-v5-compat';
import { DocumentTagDetailsDrawer } from './DocumentTagDetailsDrawer';

export const DocumentTagDetailsRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const match = useMatch(
    `:organizationSlug${Routes.SETTINGS}${Routes.DOCUMENT_TAGS}/:id`
  );

  const documentTagId = match?.params.id;

  return (
    <DocumentTagDetailsDrawer
      documentTagId={documentTagId !== 'create' ? documentTagId : undefined}
      isOpen={match !== null}
      onCloseDrawer={() => {
        navigate(
          {
            pathname: '..',
            search: location.search,
          },
          {
            relative: 'path',
          }
        );
      }}
    />
  );
};
