import { ProcessingFormPaymentConditionFieldProps } from 'components/Form/ProcessingForm/ProcessingFormPaymentConditionFieldDefault';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { ProcessingFormSelectField } from 'components/Form/ProcessingForm/ProcessingFormSelectField';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaymentConditionItemCoreDataApi } from 'views/Contacts/ContactDetails/ContactForm/CoreDataApi/PaymentConditionItemCoreDataApi';
import { DocumentDirection } from 'views/utils/DocumentDirection';

/**
 * A `ProcessingFormSelectField` for selecting a payment condition
 *
 * Returns `null` if direction of currently-selected document type is not
 * incoming
 */
export const ProcessingFormPaymentConditionFieldCoreDataApi = ({
  items = [],
  typeFieldItems = [],
  ...restProps
}: ProcessingFormPaymentConditionFieldProps) => {
  const [t] = useTranslation();

  const type = useWatch<ProcessingFormValues, 'type'>({ name: 'type' });
  const typeItem = typeFieldItems.find(({ key }) => key === type);

  if (typeItem?.direction !== DocumentDirection.invoices_received) {
    return null;
  }

  return (
    <ProcessingFormSelectField
      items={[
        {
          key: 'none',
          children: t(
            'settings.contacts.details.edit.paymentCondition.options.none'
          ),
        },
        ...items.map(
          ({ discountOffset, discountPercentage, dueDateOffset, key }) => ({
            key,
            textValue: key,
            children: (
              <PaymentConditionItemCoreDataApi
                discountOffset={discountOffset}
                discountPercentage={discountPercentage}
                dueDateOffset={dueDateOffset}
                id={key}
              />
            ),
          })
        ),
      ]}
      {...restProps}
    />
  );
};
