import { Button, Flex, Text } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../../../models';
import {
  LOCALE_NAME_SPACE,
  Trans,
} from '../../../../../../../providers/LocaleProvider';
import { useOrganizationId } from '../../../../../../../providers/OrganizationProvider';
import { CONTACT_CREATE_ROUTE } from '../../../../../../../views/Contacts';
import { CONTACT_ROUTE_HASH } from '../../../../../../../views/Contacts/types';
import {
  HeadingDivider,
  TypeSelectionEmptyButtonGroup,
  TypeSelectionEmptyDetails,
  TypeSelectionTitle,
  WidgetTypeSelectionBox,
  Wrapper,
} from './styles';
import { SelectionProps, WIDGET_TYPE } from './types';

export const ContactSelection = ({
  image,
  setSelectedType,
}: SelectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return (
    <WidgetTypeSelectionBox
      title={t('widgets.typeSelection.contact.title')}
      cta={
        <Trans t={t} i18nKey="widgets.typeSelection.contact.cta">
          Create a report for one or more
          <Text fontWeight="semibold">Contacts</Text>.
        </Trans>
      }
      image={image}>
      <Button
        variant="secondary"
        data-cy="createForContacts"
        onClick={() => setSelectedType(WIDGET_TYPE.CONTACT)}>
        {t('widgets.typeSelection.contact.buttonTitle')}
      </Button>
    </WidgetTypeSelectionBox>
  );
};

export const ContactEmptySelection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const orgId = useOrganizationId();
  const history = useHistory();
  const { isAdmin, isRequester, isAccountant } = useUserRoles();

  const canCreateContacts = isAdmin || isRequester || isAccountant;

  const links = {
    create: `/${orgId}${Routes.CONTACTS}/${CONTACT_CREATE_ROUTE}`,
    import: `/${orgId}${Routes.CONTACTS}${CONTACT_ROUTE_HASH.import}`,
  };

  const texts = {
    askAdmin: t(
      'widgets.typeSelection.contact.noContactsAvailable.ctaApprover'
    ),
    createCTA: t(
      'widgets.typeSelection.contact.noContactsAvailable.ctaAdmin.create'
    ),
    importCTA: t(
      'widgets.typeSelection.contact.noContactsAvailable.ctaAdmin.import'
    ),
  };

  const title = t('widgets.typeSelection.contact.noContactsAvailable.title');
  const details = t(
    'widgets.typeSelection.contact.noContactsAvailable.details'
  );

  return (
    <Wrapper>
      <Flex gap="space16" direction="column">
        <TypeSelectionTitle>{title}</TypeSelectionTitle>
        <HeadingDivider />
        <TypeSelectionEmptyDetails>{details}</TypeSelectionEmptyDetails>
      </Flex>

      <TypeSelectionEmptyButtonGroup
        onCreate={() => history.push(links.create)}
        onImport={() => history.push(links.import)}
        canCreate={canCreateContacts}
        text={texts}
      />
    </Wrapper>
  );
};
