import { CandisApiExportDetails } from 'views/Integrations/Export/toolkit/components/ExportDetails/CandisApiExportDetails';
import { DatevBdsExportDetails } from 'views/Integrations/Export/toolkit/components/ExportDetails/DatevBdsExportDetails';
import { DatevExportDetails } from 'views/Integrations/Export/toolkit/components/ExportDetails/DatevExportDetails';
import { SapB1ExportDetails } from 'views/Integrations/Export/toolkit/components/ExportDetails/SapB1ExportDetails';
import { ExportDetails, ExportType } from '../../types';

export interface ApiExportProps {
  exportDetails: ExportDetails;
}

export const ApiExport = ({ exportDetails }: ApiExportProps) => {
  switch (exportDetails.type) {
    case ExportType.DATEV_BDS:
      return <DatevBdsExportDetails exportDetails={exportDetails} />;
    case ExportType.SAP_B1:
      return <SapB1ExportDetails exportDetails={exportDetails} />;
    case ExportType.API:
      return <CandisApiExportDetails exportDetails={exportDetails} />;
    default:
      return <DatevExportDetails exportDetails={exportDetails} />;
  }
};
