import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { OrganizationSettingsFormOutupt } from '../utils/organizationSettingsFormSchema';

/**
 *
 * This hooks is used to reset the react hook form field (value and error) when the field gets unmounted from the DOM
 */
export const useCleanupFieldOnUnmount = (
  fieldName: keyof OrganizationSettingsFormOutupt
) => {
  const { resetField } = useFormContext<OrganizationSettingsFormOutupt>();
  useEffect(() => {
    return () => {
      resetField(fieldName, undefined);
    };
  }, [fieldName, resetField]);
};
