import { Button, usePopover } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters } from 'react-table';
import { Filter } from './Filter/Filter';
import { PopoverFilters, WidgetFilter } from './utils';

interface CategoryFiltersProps {
  onFilter: (filters: Filters<PopoverFilters>) => void;
  appliedFilters: string[];
  allFilters: Filters<PopoverFilters>;
}

export const CategoryFilter = ({
  onFilter,
  appliedFilters,
  allFilters,
}: CategoryFiltersProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isOpen, close, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'bottom' });

  const filtersWithoutCategory = allFilters.filter(
    filter => filter.id !== WidgetFilter.category
  );

  const applyFilters = (filters: undefined | string[]) => {
    if (!filters) {
      return [];
    }

    // It maps the filters from the checkboxes into an object where the id identifies the general filter we are using
    // onFilter expects the filters to be in this exact shape

    const filtersForUrl = [
      ...filtersWithoutCategory,
      { id: WidgetFilter.category, value: filters },
    ];

    onFilter(filtersForUrl);
    close();
  };

  const resetFilters = () => {
    // removes the filters related to category and sends them to the URL

    onFilter(filtersWithoutCategory);
    close();
  };

  const buttonText = appliedFilters.length
    ? t('credit-cards:insights.filters.category.withNumber', {
        filters: appliedFilters.length,
      })
    : t('credit-cards:insights.filters.category.noNumber');

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        justifySelf="start"
        iconPosition="right"
        icon={isOpen ? 'caretUp' : 'caretDown'}
        variant="secondary">
        {buttonText}
      </Button>
      {isOpen && (
        <Filter
          {...popoverProps}
          filterValue={appliedFilters}
          onApply={applyFilters}
          filterName={WidgetFilter.category}
          onReset={resetFilters}
          ref={popoverRef}
        />
      )}
    </>
  );
};
