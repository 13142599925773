import { Card, Grid, Image, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import sparkles from 'views/CreditCards/CreditCardsInsights/media/sparkles.svg';

export const AutomatchedInfoBox = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Card background="bluebg" padding="space8" boxShadow="noShadow">
      <Grid templateColumns="auto 1fr" alignItems="center" gap="space4">
        <Image alt="" src={sparkles} height="space20" width="space20" />
        <Text>{t('transactionDetailsCard.automatchedInfo')}</Text>
      </Grid>
    </Card>
  );
};
