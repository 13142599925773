import { FilterOptionsAccessor } from '@candisio/design-system';
import { PurchaseOrdersTableData } from 'components/PurchaseOrders/Table/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { statusInSapFilters } from '../utils';

export const usePurchaseOrdersTableFilterOptions = () => {
  const [t] = useTranslation();

  const filterOptions: FilterOptionsAccessor<PurchaseOrdersTableData> =
    useMemo(() => {
      return {
        status: {
          data: statusInSapFilters.map(status => ({
            id: status.id,
            label: t('purchase-orders:' + status.label),
          })),
        },
      };
    }, [t]);

  return filterOptions;
};
