import { Button } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { ConfirmationPopoverDeprecated } from '../../../../../components/ConfirmationPopover/ConfirmationPopoverDeprecated';
import {
  DatevConnectionType,
  useDisconnectDatevClientMutation,
} from '../../../../../generated-types/graphql.types';
import { Routes } from '../../../../../models';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { useOrganizationId } from '../../../../../providers/OrganizationProvider';
import { getFullOrganizationQuery } from '../../../../../providers/OrganizationProvider/queries';
import { useDatevAuthorizeForClients } from '../datevAuthHooks';
import { refetchDatevDataWithNoToken } from '../gql';
import { urlParams } from '../utils';

export const DisconnectAndRedirectComponent = ({
  onDisconnect,
  loading,
}: {
  onDisconnect: () => void;
  loading: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <ConfirmationPopoverDeprecated
      title={t('datev.disconnect.info')}
      onConfirm={onDisconnect}
      closeText={t('datev.disconnect.cancel')}
      confirmText={t('datev.disconnect.ok')}
      placement="top"
      trigger={({ triggerProps, triggerRef }) => (
        <Button
          variant="primary"
          color="blue"
          size="small"
          minWidth="15rem"
          loading={loading}
          disabled={loading}
          {...triggerProps}
          ref={triggerRef}>
          {t('datev.disconnect.buttonText')}
        </Button>
      )}></ConfirmationPopoverDeprecated>
  );
};

export const DisconnectAndRedirect = () => {
  const history = useHistory();
  const { success } = useToastMessage();
  const orgId = useOrganizationId();
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const [disconnectDatevClient, { loading: isDisconnecting }] =
    useDisconnectDatevClientMutation({
      refetchQueries: [
        ...refetchDatevDataWithNoToken,
        { query: getFullOrganizationQuery },
      ],
    });

  const [authorizeForClients, { loading: authLoadingForClients }] =
    useDatevAuthorizeForClients();

  const onDisconnect = async () => {
    await disconnectDatevClient();

    success(t('datev.disconnect.success'));

    history.push(
      `/${orgId}${Routes.SETTINGS}${Routes.DATEV}?${urlParams.datevConnectionType}=${DatevConnectionType.DatevRewe}`
    );

    await authorizeForClients(DatevConnectionType.DatevRewe);
  };

  return (
    <DisconnectAndRedirectComponent
      onDisconnect={onDisconnect}
      loading={isDisconnecting || authLoadingForClients}
    />
  );
};
