import { CardActionName } from 'generated-types/graphql.types';
import { useGetCardById } from '../utils';

export const useCheckPendingStatus = (cardId: string) => {
  const { card, loading } = useGetCardById({
    cardId,
  });

  const shippingDate = card?.shippingDate ?? undefined;

  const pendingActions = (card?.pendingActions ?? []).map(action => ({
    actionName: action.name,
    actionId: action.id,
    createdAt: action.createdAt,
  }));

  const availablePendingActions = {
    missingPhoneNumber: pendingActions.find(
      action => action.actionName === CardActionName.CardholderMustHave_2Fa
    ),
    notAcceptedUsageAgreement: pendingActions.find(
      action => action.actionName === CardActionName.UsageAgreementMustBeAgreed
    ),
  };

  return {
    availablePendingActions,
    shippingDate,
    loading,
  };
};
