import { Button } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const SplitButton = ({
  isFormReadOnly,
  onClick,
}: {
  isFormReadOnly: boolean;
  onClick: () => void;
}) => {
  const [t] = useTranslation();

  return (
    <Button
      icon="plus"
      width="100%"
      variant="tertiary"
      label={
        isFormReadOnly
          ? t('document.requestApproval.splitBookings.label')
          : undefined
      }
      disabled={isFormReadOnly}
      onClick={onClick}>
      {t('document.requestApproval.splitBookings.splitButton')}
    </Button>
  );
};
