import { InvoiceAssociationStatus } from 'generated-types/graphql.types';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useGetTransactionById } from 'views/TransactionAssociation/hooks/useGetTransactionById';

export interface TransactionFieldOptions {
  comment: {
    isVisible: boolean;
  };
  transactionId: {
    isVisible: boolean;
  };
  transactionDate: {
    isVisible: boolean;
  };
  billingAmount: {
    isVisible: boolean;
  };
}

export const useTransactionFieldOptions = (): TransactionFieldOptions => {
  const { transactionId } = useParams<{ transactionId: string }>();
  const { transaction } = useGetTransactionById({
    id: transactionId,
  });

  const isMarkedAsInvoiceNotNeeded =
    transaction.invoiceAssociationStatus === InvoiceAssociationStatus.NotNeeded;

  const transactionFieldOptions: TransactionFieldOptions = {
    comment: {
      isVisible: isMarkedAsInvoiceNotNeeded,
    },
    transactionId: {
      isVisible: isMarkedAsInvoiceNotNeeded,
    },
    transactionDate: {
      isVisible: isMarkedAsInvoiceNotNeeded,
    },
    billingAmount: {
      isVisible: isMarkedAsInvoiceNotNeeded,
    },
  };

  return transactionFieldOptions;
};
