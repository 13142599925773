import gql from 'graphql-tag';

export const bankAccountsIbanFragment = gql`
  fragment BankAccountsIbanData on Iban {
    ... on Iban {
      id
      name
      iban
      isDefault
      logoUrl
    }
  }
`;

export const createIbanMutation = gql`
  mutation createIban($iban: IBAN!, $name: String!) {
    createIban(input: { iban: $iban, name: $name }) {
      ...BankAccountsIbanData

      ... on IbanError {
        kind
      }
    }
  }
  ${bankAccountsIbanFragment}
`;

export const updateIbanMutation = gql`
  mutation updateIban($id: ID!, $iban: IBAN!, $name: String!) {
    updateIban(id: $id, input: { iban: $iban, name: $name }) {
      ...BankAccountsIbanData

      ... on IbanError {
        kind
      }
    }
  }
  ${bankAccountsIbanFragment}
`;

export const deleteIbanMutation = gql`
  mutation deleteIban($id: ID!) {
    deleteIban(id: $id) {
      ... on DeleteIbanSuccessful {
        isDeleted
      }
    }
  }
`;

export const setIbanAsOrgDefaultMutation = gql`
  mutation setIbanAsOrgDefault($id: ID!) {
    setIbanAsOrgDefault(id: $id) {
      ...BankAccountsIbanData
    }
  }
  ${bankAccountsIbanFragment}
`;

export const listIbansQuery = gql`
  query listIbans {
    listIbans {
      ...BankAccountsIbanData
    }
  }
  ${bankAccountsIbanFragment}
`;
