import { RouterLink } from 'components/RouterLink/RouterLink';
import { SepaTransferGeneratedEventDataFragment } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { ItemSuccessStatus } from '../styles';

export const SepaTransferGeneratedComponent = ({
  paymentId,
}: SepaTransferGeneratedEventDataFragment) => {
  const [t] = useTranslation();
  const orgId = useOrganizationId();
  const { isAdmin, isAccountant } = useUserRoles();

  return (
    <ItemSuccessStatus>
      <span>{t('document.compact.history.sepaTransferGenerated.title')}</span>
      {(isAdmin || isAccountant) && (
        <RouterLink to={`/${orgId}${Routes.PAYMENTS}/${paymentId}`}>
          {t('document.compact.history.sepaTransferGenerated.details')}
        </RouterLink>
      )}
    </ItemSuccessStatus>
  );
};
