import { Grid } from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StorageFormDateField } from './StorageFormDateField';
import { StorageFormValues } from './storageFormSchema';
import { useRelativeReminderDateItems } from './useRelativeReminderDateItems';

interface StorageFormReminderDateFieldsProps {
  /** Display inputs as read only */
  readOnly?: boolean;
}

export const StorageFormReminderDateFields = ({
  readOnly,
}: StorageFormReminderDateFieldsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const relativeReminderDateItems = useRelativeReminderDateItems();
  const terminationDate = useWatch<StorageFormValues, 'terminationDate'>({
    name: 'terminationDate',
  });

  return (
    <Grid
      columnGap="space8"
      rowGap="space16"
      templateColumns="repeat(auto-fit, minmax(10rem ,1fr))">
      <HookFormSelectField
        items={relativeReminderDateItems}
        label={t('storageForm.fields.terminationReminderDate.select')}
        name="relativeDate"
        readOnly={!terminationDate || readOnly}
      />

      <StorageFormDateField
        name="terminationReminderDate"
        label={t('storageForm.fields.terminationReminderDate.label')}
        readOnly={readOnly}
      />
    </Grid>
  );
};
