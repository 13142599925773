import URLPattern from 'url-pattern';

export class RoutePath<T extends object = {}> {
  private _match: URLPattern['match'];
  private _stringify: URLPattern['stringify'];
  constructor(path: string) {
    /** Transform React Router path to match URLPattern path */
    const matchPattern = new URLPattern(
      path.replace(/(\/)(:[a-zA-Z()_-]+)\?/g, '($1)($2)')
    );

    const stringifyPattern = new URLPattern(
      path.replace(/(\/:[a-zA-Z()_-]+)\?/g, '($1)')
    );

    this._match = matchPattern.match.bind(matchPattern);
    this._stringify = stringifyPattern.stringify.bind(stringifyPattern);
  }
  /**
   *
   * @param values - path params that should be filled
   * @param url - a url where you can get additional params that you don't want to explicitly pass
   */
  stringify(values: T = {} as T, url?: string): string {
    // eslint-disable-next-line
    const { _: _others = null, ...params } = url
      ? { ...this._match(url), ...values }
      : values;

    return this._stringify(params);
  }
  /**
   *
   * @param url - a url to extract params from
   */
  match(url: string): Partial<T> {
    return this._match(url);
  }
}
