import { ProcessingFormTypeFieldItem } from 'components/Form/ProcessingForm/ProcessingFormTypeField';
import { DocumentCategory } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { DOCUMENT_TYPES } from './consts';

/**
 * Returns a function that maps `DocumentCategory` to
 * `ProcessingFormTypeFieldItem`
 */
export const useToTypeFieldItem = () => {
  const [t] = useTranslation();

  const {
    EINGANGSGUTSCHRIFT,
    KASSE,
    RECHNUNGSAUSGANG,
    RECHNUNGSEINGANG,
    RECHNUNGSKORREKTUR,
    SONSTIGE,
  } = DOCUMENT_TYPES;

  const toTypeFieldItem = ({
    direction,
    documentType,
    isDefault,
  }: Pick<
    DocumentCategory,
    'direction' | 'documentType' | 'isDefault' | 'isRds1_0Exportable'
  >): ProcessingFormTypeFieldItem => {
    let label = documentType;
    if (isDefault) {
      switch (documentType) {
        case RECHNUNGSEINGANG: {
          label = t(
            'document.requestApproval.defaultDocumentTypes.Rechnungseingang'
          );
          break;
        }

        case RECHNUNGSAUSGANG: {
          label = t(
            'document.requestApproval.defaultDocumentTypes.Rechnungsausgang'
          );
          break;
        }

        case KASSE: {
          label = t('document.requestApproval.defaultDocumentTypes.Kasse');
          break;
        }

        case SONSTIGE: {
          label = t('document.requestApproval.defaultDocumentTypes.Sonstige');
          break;
        }

        case RECHNUNGSKORREKTUR: {
          label = t(
            'document.requestApproval.defaultDocumentTypes.Rechnungskorrektur'
          );
          break;
        }

        case EINGANGSGUTSCHRIFT: {
          label = t(
            'document.requestApproval.defaultDocumentTypes.Eingangsgutschrift'
          );
          break;
        }
      }
    }

    return {
      key: documentType,
      children: label,
      direction:
        direction in DocumentDirection
          ? (direction as DocumentDirection)
          : DocumentDirection.others_document,
    };
  };

  return toTypeFieldItem;
};
