import { Grid, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { FooterActions } from './components/RightSection/FooterActions/FooterActions';
import { ReimbursementFormContainer } from './components/RightSection/ReimbursementFormContainer';
import { SectionLayout } from './components/SectionLayout';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useSubmitReimbursementCaseForReview } from './hooks/useSubmitReimbursementCaseForReview';

export const RightSectionContainer = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { reimbursement } = useGetReimbursementById({
    reimbursementId,
  });

  const { submitReimbursmentCaseForReview } =
    useSubmitReimbursementCaseForReview();

  return (
    <SectionLayout
      headerElement={
        <TruncatedText fontSize="xxlarge" lineHeight="normal">
          {t('reimbursementView.rightSection.title')}
        </TruncatedText>
      }>
      <Grid
        padding="0 space16 space2 space2"
        templateRows="1fr auto"
        height="100%">
        <ReimbursementFormContainer reimbursement={reimbursement} />
        <FooterActions onSubmitForReview={submitReimbursmentCaseForReview} />
      </Grid>
    </SectionLayout>
  );
};
