import {
  TruncatedText,
  HeaderProps,
  Grid,
  Tag,
  useTooltip,
  Tooltip,
  Text,
} from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { useTagsQuery } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { EcmDocumentsTableData } from '../EcmDocumentsTable/types';

const NewTag = () => {
  const [t] = useTranslation();
  const orgId = useOrganizationId();
  const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
    useTooltip();

  return (
    <>
      <Tag color="blue" variant="secondary" {...triggerProps} ref={triggerRef}>
        {t('tag.new')}
      </Tag>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Grid gap="space16" maxWidth="space256">
            <Text>{t('documentTags.table.emptyPromo.description')}</Text>
            <RouterLink
              to={`/${orgId}${Routes.SETTINGS}${Routes.DOCUMENT_TAGS}`}>
              {t('documentTags.table.emptyPromo.linkCTA')}
            </RouterLink>
          </Grid>
        </Tooltip>
      )}
    </>
  );
};

export const TagsHeader = ({
  headers,
  isFilteredOrSorted,
}: HeaderProps<EcmDocumentsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const orgId = useOrganizationId();
  const { data } = useTagsQuery();
  const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
    useTooltip();

  const noTagsCreated = data?.tags?.length === 0;

  // Temporary show an icon with tooltip that informs users
  // about upcoming Tags filter in invoice tables
  const tagsHeader = headers.find(header => header.id === 'tags');

  return (
    <Grid autoFlow="column" gap="space5" alignItems="center">
      <TruncatedText
        wordBreak="break-all"
        color={isFilteredOrSorted ? 'gray800' : 'gray500'}
        {...triggerProps}
        ref={triggerRef}>
        {t('columns.tags')}
      </TruncatedText>
      {noTagsCreated && <NewTag />}
      {!noTagsCreated && !tagsHeader?.canFilter && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Trans
            i18nKey="documentTags.table.comingSoon"
            ns={LOCALE_NAME_SPACE.COMMON}>
            Tags can currently only be filtered in the
            <RouterLink to={`/${orgId}${Routes.ARCHIVE}`}>
              archive tab
            </RouterLink>
            .
          </Trans>
        </Tooltip>
      )}
    </Grid>
  );
};
