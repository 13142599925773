import { Button, Grid, Text, useTheme } from '@candisio/design-system';
import { useReauthenticationState } from 'providers/ReauthenticationProvider/ReauthenticationProvider';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'services/AuthService';
import { DefaultModalContent } from './DefaultModalContent';
import { ReauthenicationSuccess } from './ReauthenticationSuccess';
import { useReauthSucceededListener } from './useReauthSucceededListener';

interface ReauthenticationModalContentProps {
  onReauthenticated: () => void;
}

const REDIRECT_ROUTE = '/pages/iam/reauthentication/index.html';

export const ReauthenticationModalContent = ({
  onReauthenticated,
}: ReauthenticationModalContentProps) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const { authType, modalSidePaneContent } = useReauthenticationState();

  const hasReauthenticated = useReauthSucceededListener();

  const loginUrl = AuthService.createReauthenticateUrl(
    REDIRECT_ROUTE,
    authType
  );

  return (
    <Grid height="100%" gap="space32">
      {hasReauthenticated ? (
        <ReauthenicationSuccess onReauthenticated={onReauthenticated} />
      ) : (
        <>
          {modalSidePaneContent ?? <DefaultModalContent />}
          <Button
            icon="arrowRight"
            iconPosition="right"
            size="medium"
            onClick={() => {
              window.open(
                loginUrl,
                '_blank',
                'height=770,width=570,modal=yes,alwaysRaised=yes'
              );
            }}>
            {t('reauthentication.modal.beginReauthentication.cta')}
          </Button>
          <Grid
            fontSize="basic"
            background="bluebg"
            borderRadius="medium"
            lineHeight={theme.lineHeights.heading}
            padding="space16">
            <Text fontWeight="bold">
              {t(
                'reauthentication.modal.beginReauthentication.popupWarning.title'
              )}
            </Text>
            <Text>
              {t(
                'reauthentication.modal.beginReauthentication.popupWarning.text'
              )}
            </Text>
          </Grid>
        </>
      )}
    </Grid>
  );
};
