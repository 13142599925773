import { Button, Grid, useId } from '@candisio/design-system';
import { IbanForm } from 'components/IbanListView/IbanForm';
import { IbanFormValues } from 'components/IbanListView/ibanFormSchema';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface CreateIbanProps {
  onSubmit: (values: IbanFormValues) => void;
  isSubmitting: boolean;
  handleDropdownState: () => void;
}

export const CreateIban = ({
  onSubmit,
  isSubmitting,
  handleDropdownState,
}: CreateIbanProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const formId = useId();

  return (
    <Grid
      onSubmit={
        // @TODO explicit MouseEvent<HTMLDivElement> type should not be
        // required.
        // See: https://github.com/microsoft/TypeScript/issues/44596
        (e: MouseEvent<HTMLDivElement>) => {
          // stop form submit event from bubbling up through the React Portal
          // and submitting parent form
          e.stopPropagation();
        }
      }
      padding="space16"
      gap="space16">
      <IbanForm id={formId} onSubmit={onSubmit} />
      <Grid gap="space8">
        <Button
          color="blue"
          form={formId}
          loading={isSubmitting}
          type="submit"
          variant="secondary">
          {t('details.addIban')}
        </Button>
        <Button color="blue" onClick={handleDropdownState} variant="tertiary">
          {t('details.discard')}
        </Button>
      </Grid>
    </Grid>
  );
};
