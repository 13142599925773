import { Order, SortInput } from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';
import { DocumentsTableData } from '../types';

/**
 * Map DocumentsTable sort state to SortInput.
 * Only considers the first sorted column.
 */
export const mapToSortInput = (
  sort: Array<SortingRule<DocumentsTableData>>
): SortInput | undefined => {
  if (sort.length < 1) {
    return undefined;
  }

  // The sort array in our case will always contain a max of one object because in our tables
  // we explicitly set disableMultiSort to true https://react-table-v7.tanstack.com/docs/api/useSortBy#usesortby

  const [firstSort] = sort;

  if (firstSort.id === 'grossAmount') {
    return {
      field: 'amount',
      order: firstSort.desc ? Order.Desc : Order.Asc,
    };
  }

  return {
    field: firstSort.id,
    order: firstSort.desc ? Order.Desc : Order.Asc,
  };
};
