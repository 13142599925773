import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const selectClientFormSchema = () => {
  const schema = z.object({
    client: z.string(),
  });

  return schema;
};

export type SelectClientFormOutput = z.infer<
  ReturnType<typeof selectClientFormSchema>
>;

export type SelectClientFormValues = Partial<SelectClientFormOutput>;

export type SelectClientFormErrorMessages = ErrorMessages<
  ReturnType<typeof selectClientFormSchema>
>;
