import { FilterOption } from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import { CardIssuerCard } from 'generated-types/graphql.types';

export type CardRefNumFilterProps = (filteredValues: string[]) => {
  filterOptions: FilterOption[];
  loading: boolean;
  loadMore: () => void;
  handleDebounceSearch: (inputStr: string) => void;
};

export const mapRecordsToCardDetails = (
  entries: Pick<
    CardIssuerCard,
    'id' | 'label' | 'refNum' | 'cardholder' | 'type' | 'brand'
  >[]
) =>
  entries
    .map(cc => ({
      id: cc.id,
      label: cc.label ?? '',
      cardNumber: cc.refNum ?? '',
      firstName: cc.cardholder.firstName ?? '',
      lastname: cc.cardholder.lastName ?? '',
      brand: cc.brand ?? undefined,
      type: cc.type,
    }))
    .filter(card => card.id && card.firstName && card.lastname)
    .sort((a, b) =>
      `${a.firstName} ${a.lastname}`.localeCompare(
        `${b.firstName} ${b.lastname}`
      )
    );
