import debounce from 'debounce-promise';
import { useProcessingFormContactFieldItemsQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { toContactFieldItem } from './toContactFieldItem';

/** Fetches items for contact field */
export const useContactFieldItems = (name?: string) => {
  const { data, refetch, fetchMore } = useProcessingFormContactFieldItemsQuery({
    variables: { name, page: 1, filters: { isArchived: false } },
  });

  const items = (data?.contactsPagination?.records ?? []).map(
    toContactFieldItem
  );

  const refetchItems = useMemo(() => debounce(refetch, 500), [refetch]);

  const pageCount = data?.contactsPagination.pageInfo.pageCount ?? 1;
  const currentPage = data?.contactsPagination.pageInfo.currentPage ?? 1;
  const nextPage = currentPage + 1;

  const fetchMoreItems = async () => {
    if (nextPage <= pageCount) {
      await fetchMore({ variables: { page: nextPage } });
    }
  };

  return { items, refetchItems, fetchMoreItems };
};
