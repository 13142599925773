import {
  HookFormNumberField,
  HookFormNumberFieldProps,
} from 'components/HookFormFields/HookFormNumberField';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export interface HookFormAmountFieldProps<TFormValues extends FieldValues>
  extends HookFormNumberFieldProps<TFormValues> {
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

/**
 * A `HookFormNumberField` for entering monetary amounts
 */
export const HookFormAmountField = <TFormValues extends FieldValues>({
  currency,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  ...restProps
}: HookFormAmountFieldProps<TFormValues>) => {
  // useNumberField's formatOptions must be memoized:
  // https://github.com/adobe/react-spectrum/issues/1893
  const formatOptions = useMemo(
    (): Intl.NumberFormatOptions => ({
      currency,
      maximumFractionDigits,
      minimumFractionDigits,
      style: currency ? 'currency' : 'decimal',
    }),
    [currency, maximumFractionDigits, minimumFractionDigits]
  );

  return <HookFormNumberField formatOptions={formatOptions} {...restProps} />;
};
