import { DocumentsTableData } from 'components/DocumentsTable/types';
import { DocumentAttachment } from 'generated-types/graphql.types';
import { useMemo } from 'react';

export const MAX_FILES_SIZE_MB = 19;
const MAX_FILES_SIZE = MAX_FILES_SIZE_MB * 1024 * 1024;
export const MAX_FILES_COUNT = 47;

const attachmentsSizeTooBig = (
  filesSize: number,
  maxFilesSize: number
): boolean => {
  return filesSize > maxFilesSize;
};

const tooManyAttachments = (
  filesCount: number,
  maxFilesCount: number
): boolean => {
  return filesCount > maxFilesCount;
};

export type AttachmentsValidationsError = {
  type: 'maxFileSize' | 'maxFileLength';
  maxValue: number;
};

export const useAttachmentsValidations = (
  documentSize: number,
  documentAttachments: Pick<DocumentAttachment, 'size'>[],
  selectedDocuments: DocumentsTableData[]
) => {
  const attachmentsSize = documentAttachments.reduce(
    (sum, attachment) => sum + (attachment.size ?? 0),
    0
  );

  const errorType: AttachmentsValidationsError | null = useMemo(() => {
    if (
      tooManyAttachments(
        selectedDocuments.length + documentAttachments.length,
        MAX_FILES_COUNT
      )
    ) {
      return { type: 'maxFileLength', maxValue: MAX_FILES_COUNT };
    } else if (
      attachmentsSizeTooBig(
        documentSize +
          selectedDocuments.reduce((sum, doc) => sum + (doc.fileSize ?? 0), 0) +
          attachmentsSize,
        MAX_FILES_SIZE
      )
    ) {
      return { type: 'maxFileSize', maxValue: MAX_FILES_SIZE_MB };
    } else {
      return null;
    }
  }, [
    selectedDocuments,
    documentSize,
    attachmentsSize,
    documentAttachments.length,
  ]);

  return {
    errorType,
  };
};
