import { AppRouteParams, Routes } from 'models';
import { generatePath } from 'react-router-dom-v5-compat';
import { NotificationProps } from '../getNotificationInfo';

export const getLinkToApprovedCardRequest = (
  notification: NotificationProps<'CardRequestApprovedInAppNotification'>
) => {
  const searchParams = new URLSearchParams();
  searchParams.append('cardId', notification.cardId);
  const link = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.DASHBOARD_CREDIT_CARD}/:creditCardId`,
      {
        creditCardId: notification.cardId,
        organizationSlug: notification.organizationId,
      }
    ),
    search: searchParams.toString(),
  };

  return { link };
};
