import { getEmailAddresses } from 'components/EmailEditor/utils';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const contactSupplierFormSchema = z.object({
  emailTo: z.string().refine(value => {
    // Extract and clean up email addresses
    const emails = getEmailAddresses(value);

    // Check that every email is valid
    return emails.every(email => z.string().email().safeParse(email).success);
  }),
  subject: z.string(),
  htmlText: z.string(),
  emailFrom: z.string().email().optional(),
  emailCc: z.string().email().optional(),
});

export type ContactSupplierFormOutput = z.infer<
  typeof contactSupplierFormSchema
>;
export type ContactSupplierFormValues = Partial<ContactSupplierFormOutput>;
export type ContactSupplierFormErrorMessages = ErrorMessages<
  typeof contactSupplierFormSchema
>;
