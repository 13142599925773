import {
  AriaTabListProps,
  Grid,
  Item,
  TabItem,
  TabPanel,
  Tabs,
  useTabListState,
} from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { CreditCardsSpentOverviewTrackingEvents } from 'providers/AnalyticsProvider/events/CreditCardsSpentOverviewTrackingEvents';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useCreditCardRequestCountData } from '../hooks/useCreditCardRequestCountData';

export enum CreditCardsTab {
  Dashboard = 'Dashboard',
  Table = 'Table',
  Statements = 'Statements',
}

interface CreditCardsTabsPanelProps extends AriaTabListProps<TabItem> {
  'aria-label': string;
}

const CreditCardsTabsPanel = (props: CreditCardsTabsPanelProps) => {
  const state = useTabListState(props);

  return (
    <>
      <Grid templateRows="auto 1fr" flexGrow={1}>
        <Tabs {...props} state={state} />

        <TabPanel
          key={state.selectedItem?.key}
          state={state}
          height="100%"
          overflow="hidden"
        />
      </Grid>
    </>
  );
};

export const CreditCardsTabs = ({
  defaultSelectedKey,
}: {
  defaultSelectedKey: CreditCardsTab;
}) => {
  const { track } = useAnalytics();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const { loading, totalRequests } = useCreditCardRequestCountData();

  const isCountVisibile = !loading && Boolean(totalRequests);
  const tabItems: TabItem[] = creditCardsStatementsFF
    ? [
        {
          key: CreditCardsTab.Dashboard,
          children: null,
          title: t('credit-cards:tabs.dashboard'),
        },
        {
          key: CreditCardsTab.Table,
          children: null,
          title: t('credit-cards:tabs.totalOverview'),
          badge: isCountVisibile ? String(totalRequests) : undefined,
        },
        {
          key: CreditCardsTab.Statements,
          children: null,
          title: t('credit-cards:statements.heading'),
        },
      ]
    : [
        {
          key: CreditCardsTab.Dashboard,
          children: null,
          title: t('credit-cards:tabs.dashboard'),
        },
        {
          key: CreditCardsTab.Table,
          children: null,
          title: t('credit-cards:tabs.totalOverview'),
          badge: isCountVisibile ? String(totalRequests) : undefined,
        },
      ];

  const navigateToUrl = (item: Key) => {
    if (item === CreditCardsTab.Table) {
      track(CreditCardsSpentOverviewTrackingEvents.OVERVIEW_TAB_CLICKED);
      navigate({
        pathname: `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`,
      });
    } else if (item === CreditCardsTab.Dashboard) {
      track(CreditCardsSpentOverviewTrackingEvents.INSIGHTS_TAB_CLICKED);
      navigate({
        pathname: `/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}`,
      });
    } else {
      navigate({
        pathname: `/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.STATEMENTS}`,
      });
    }
  };

  return (
    <CreditCardsTabsPanel
      aria-label="creditCardsTabs"
      defaultSelectedKey={defaultSelectedKey}
      items={tabItems}
      onSelectionChange={navigateToUrl}
      children={item => (
        <Item key={item.key} title={item.title} textValue={item.title}>
          {item.children}
        </Item>
      )}
    />
  );
};
