import { Button, Grid } from '@candisio/design-system';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface DocumentTableRowActionsOverlayProps<T> {
  data: T;
  onQuickViewClick: (data: T) => void;
  isSelected?: boolean;
}

export const DocumentTableRowActionsOverlay = <T extends object>({
  data,
  onQuickViewClick,
  isSelected,
}: DocumentTableRowActionsOverlayProps<T>) => {
  const [t] = useTranslation();

  return (
    <Grid
      width="100%"
      height="100%"
      justifyContent="end"
      alignItems="center"
      zIndex={100}>
      <Grid
        height="100%"
        background={
          isSelected
            ? `linear-gradient(
            90deg,
            rgba(235, 237, 247, 0) 0%,
            #ebedf7 10%
          )`
            : `linear-gradient(
            90deg,
            rgba(248, 246, 245, 0.00) 0%,
            #F8F6F5 10%
          )`
        }
        paddingLeft="space32"
        paddingRight="space16"
        alignItems="center"
        style={{
          pointerEvents: 'all',
        }}>
        <Button
          size="small"
          variant="secondary"
          color="gray"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onQuickViewClick(data);
          }}>
          {t('documentPreview.quickView')}
        </Button>
      </Grid>
    </Grid>
  );
};
