import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateHospitalityExpenseItemMutation } from 'generated-types/graphql.types';
import { debounce } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { BaseHospitalityOutput } from '../toolkit/expensesFormSchema';
import {
  reimbursementCaseByIdQuery,
  reimbursementItemsListQuery,
} from '../toolkit/queries';
import { sanitizeHospitalityFormInput } from '../utils/sanitizeHospitalityFormInput';

const UPDATE_EXPENSE_DELAY = 2000;

export const useUpdateHospitalityExpense = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();

  const { error } = useToastMessage();

  const [updateHospitalityExpense, { loading: updateHosExpensePending }] =
    useUpdateHospitalityExpenseItemMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: {
            id: reimbursementId,
          },
        },
        {
          query: reimbursementItemsListQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
      ],
    });

  const handleUpdateHospitalityExpense = useCallback(
    async (values: BaseHospitalityOutput) => {
      if (!values.expenseId) return;

      const { errors } = await updateHospitalityExpense({
        variables: {
          id: values.expenseId,
          input: {
            reimbursementCaseId: reimbursementId,
            ...sanitizeHospitalityFormInput(values),
          },
        },
      });

      if (errors?.length) {
        error(t('reimbursementView.createErr'));

        return;
      }
    },
    [error, reimbursementId, t, updateHospitalityExpense]
  );

  const debouncedUpdate = useMemo(() => {
    return debounce(handleUpdateHospitalityExpense, UPDATE_EXPENSE_DELAY);
  }, [handleUpdateHospitalityExpense]);

  return {
    updateHosExpensePending,
    updateHospitalityExpense: debouncedUpdate,
  };
};
