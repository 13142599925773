import { DocumentsTableData } from 'components/DocumentsTable/types';
import { SortDirection } from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';

type AvailableSortField =
  | 'invoiceDueDate'
  | 'status'
  | 'amount'
  | 'invoiceDate'
  | 'isPayable'
  | 'paymentState'
  | 'dueDateWithCashDiscount'
  | 'hasTransactionLinked'
  | 'createdAt'
  | 'contact'
  | 'requestedAt';

const mapColumnIdsToSortInput: Record<string, AvailableSortField> = {
  dueDate: 'invoiceDueDate',
  status: 'status',
  grossAmount: 'amount',
  invoiceDate: 'invoiceDate',
  isPayable: 'isPayable',
  paymentStatus: 'paymentState',
  discountDateWPercentage: 'dueDateWithCashDiscount',
  createdAt: 'createdAt',
  requestedAt: 'requestedAt',
  contact: 'contact',
};

export const mapToDocumentSortInput = (
  sort: Array<SortingRule<DocumentsTableData>>
) => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  const firstSortId = firstSort.id;

  return {
    field: mapColumnIdsToSortInput[firstSortId],
    order: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};
