import { Text } from '@candisio/design-system';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import DateFormatters from 'utils/date_formatter';
import { isPaidDocumentState } from 'utils/document_payment_data';
import { amountFormat } from 'utils/format';
import { getRemainingDays } from 'utils/getRemainingDays';
import { remainingDaysToColor } from 'utils/remainingDaysToColor';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { DiscountDateWithPercentage, DocumentsTableData } from '../types';

export const DiscountDateWPercentageCell = ({
  value,
  row,
}: Cell<DocumentsTableData, DiscountDateWithPercentage>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  if (isNil(value)) {
    return null;
  }

  const documentCurrency = row.original.grossAmount?.currency;
  const documentAmount = row.original.grossAmount?.amount;
  const isPaid = isPaidDocumentState(row.original.paymentStatus);

  const discountPercentage = value.discountPercentage;

  const discountPaymentDate = value.discountPaymentDate;

  const discountAmount =
    discountPercentage !== undefined && documentAmount !== undefined
      ? roundToCurrencyPrecision((documentAmount / 100) * discountPercentage)
      : undefined;

  if (
    documentCurrency === undefined ||
    discountAmount === undefined ||
    discountPercentage === undefined ||
    discountPaymentDate === undefined
  ) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const remainingDays = getRemainingDays(discountPaymentDate);

  return (
    <Text color={!isPaid ? remainingDaysToColor(remainingDays) : undefined}>
      {t('dueDateWithCashDiscount', {
        discountAmount: amountFormat(discountAmount, documentCurrency),
        discountPaymentDate: DateFormatters.compact(
          new Date(discountPaymentDate)
        ),
      })}
    </Text>
  );
};
