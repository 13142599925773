interface GetTemplateRowsProps {
  hasMembers: boolean;
  hasAdditionalDetails: boolean;
}
export const getTemplateRows = ({
  hasMembers,
  hasAdditionalDetails,
}: GetTemplateRowsProps) => {
  const hasBothElements = hasMembers && hasAdditionalDetails;
  if (hasBothElements) {
    return '1fr 10.5rem';
  } else if (hasMembers) {
    return '1fr auto';
  }
};
