import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { useTranslation } from 'react-i18next';

interface RoundingAmountProps {
  currency?: string;
}

export const RoundingAmount = ({ currency }: RoundingAmountProps) => {
  const [t] = useTranslation();

  return (
    <HookFormAmountField
      name="roundingAmount"
      currency={currency}
      label={t('document.requestApproval.inputs.roundingAmount.label')}
      readOnly
    />
  );
};
