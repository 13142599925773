import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { isDatevExportType } from 'views/Integrations/Export/toolkit/utils';
import {
  Entity,
  ExportEntityRow,
  ExportType,
  View,
} from 'views/Integrations/Export/types';

export const toDocumentTableData =
  (failedDocuments: { id: string; invoiceNumber: string }[]) =>
  (row: ExportEntityRow) => {
    const { amount, currency, precision = 0 } = row.amount;
    const normalizedAmount = amount / 10 ** precision;

    const exportErrorStatus = {
      exportError: {
        ...row.exportStatus?.exportError,
        failedDocuments,
      },
    };

    return {
      id: row.id,
      transactionStatus: row.transactionStatus,
      grossAmount: { amount: normalizedAmount, currency },
      contact: row.contact,
      invoiceDate: row.invoiceDate,
      invoiceNumber: row.invoiceNumber,
      isDisabled: row.isDisabled,
      canBeSelectedTooltipText: row.disableReason,
      exportMethod: row.exportStatus?.exportMethod,
      hasDocument: row.hasDocument,
      openInDatev: {
        consultantNumber: row.consultantNumber,
        clientNumber: row.clientNumber,
        exportGuid: row.exportGuid,
        exportStatus: { ...row.exportStatus },
      },
      exportStatus: row.exportStatus
        ? {
            ...row.exportStatus,
            ...(row.exportStatus?.exportError && exportErrorStatus),
          }
        : undefined,
    };
  };

const defaultColumns: Array<DocumentTableColumnKeys> = [
  'contact',
  'invoiceNumber',
  'invoiceDate',
  'grossAmount',
] as const;

export const useGetColumns = () => {
  const state = useExportContext();
  const { exportId, isReExport, entity, view } = state;
  const { exportDetails } = useExportDataGigaFactory(state);
  const exportType = exportDetails?.type;

  const hasExportStatus = !!exportId && !isReExport;

  const hasExportMethod =
    hasExportStatus && !!exportType && isDatevExportType(exportType);

  const hasTransactionStatus =
    entity === Entity.TRANSACTION && view === View.NOT_READY;

  const isDatevBDSExport = exportType === ExportType.DATEV_BDS;

  const canOpenInDatev =
    // Show links only for exports which have
    // `datev client number` and `consultant number`
    !!exportDetails?.clientNumber &&
    !!exportDetails?.consultantNumber &&
    !isDatevBDSExport;

  const columns = Array<DocumentTableColumnKeys | false>()
    .concat(
      hasExportStatus && 'exportStatus',
      hasExportMethod && 'exportMethod',
      hasTransactionStatus && 'transactionStatus',
      defaultColumns,
      canOpenInDatev && 'openInDatev'
    )
    .filter(Boolean) as DocumentTableColumnKeys[];

  return columns;
};
