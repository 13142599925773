import {
  Button,
  Card,
  Grid,
  Skeleton,
  Text,
  useModal,
} from '@candisio/design-system';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useTranslation } from 'react-i18next';
import { useExportContext } from '../../Context';
import { ModalSource, Table } from '../../types';
import { useExportDataGigaFactory } from '../hooks/useExportDataGigaFactory';
import { BDSPromotionModal } from './BDSPromotion';

const translationKeys = {
  reversal: {
    titleWithCount: 'reversalsPromotionTableCard.titleWithCount',
    title: 'reversalsPromotionTableCard.title',
    description: 'reversalsPromotionTableCard.description',
    button: 'reversalsPromotionTableCard.button',
  },
  provision: {
    titleWithCount: 'provisionsPromotionTableCard.titleWithCount',
    title: 'provisionsPromotionTableCard.title',
    description: 'provisionsPromotionTableCard.description',
    button: 'provisionsPromotionTableCard.button',
  },
} as const;

type ProvisionReversalPromotionCardProps = {
  amount: number;
  type: 'provision' | 'reversal';
  loading?: boolean;
};

export const ProvisionReversalPromotionCard = ({
  amount,
  type,
  loading = false,
}: ProvisionReversalPromotionCardProps) => {
  const [t] = useTranslation('exports');
  const { documentMoneyFormatter } = useMoneyFormatter();
  const modalSource = ModalSource.EXPORT_PROVISION_REVERSAL_TAB;

  const { triggerProps, modalProps, isOpen } = useModal();

  const title =
    amount === 0
      ? t(translationKeys[type].title)
      : t(translationKeys[type].titleWithCount, {
          amount: documentMoneyFormatter({
            amount,
            currency: DocumentCurrency.Eur,
            precision: 2,
          }),
        });

  if (loading) {
    return <Skeleton width="100%" height="space64"></Skeleton>;
  }

  return (
    <Card background="purplebg" paddingY="space12">
      <Grid templateColumns="1fr auto" alignItems="center">
        <Grid>
          <Text fontWeight="semibold" fontSize="large">
            {title}
          </Text>
          <Text fontSize="basic">{t(translationKeys[type].description)}</Text>
        </Grid>
        <Button color="purple" {...triggerProps}>
          {t(translationKeys[type].button)}
        </Button>
      </Grid>
      {isOpen && (
        <BDSPromotionModal modalSource={modalSource} {...modalProps} />
      )}
    </Card>
  );
};

export const BdsPromotionCardContainer = () => {
  const state = useExportContext();
  const {
    amounts,
    isLoading: { loadingReversals, loadingProvisions },
  } = useExportDataGigaFactory(state);

  if (state.table === Table.REVERSALS)
    return (
      <ProvisionReversalPromotionCard
        loading={loadingReversals}
        amount={amounts.reverals}
        type="reversal"
      />
    );

  if (state.table === Table.PROVISIONS)
    return (
      <ProvisionReversalPromotionCard
        loading={loadingProvisions}
        amount={amounts.provisions}
        type="provision"
      />
    );

  return null;
};
