import { Box, Button, Grid, Separator } from '@candisio/design-system';
import { MobileAppPromoTip } from 'containers/credit-cards/CreateCreditCard/SuccessStep/MobileAppPromoTip';
import { motion } from 'framer-motion';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useCreateCreditCardModalSetState } from '../CreateCreditCardModalDataProvider';
import { getSlideInVariants } from '../utils';
import { useModalInitialState } from '../utils/utils';
import { CardholderContent } from './CardholderContent';
import { CardManagerContent } from './CardManagerContent';

const MotionBox = motion(Box);

export const SuccessStep = ({ onClose }: { onClose: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const setModalState = useCreateCreditCardModalSetState();

  const { isCardManager } = useUserRoles();
  const initialModalState = useModalInitialState();

  const { animateFromRight } = getSlideInVariants();

  const handleClick = () => {
    setModalState({
      ...initialModalState,
      cardDetails: undefined,
    });

    onClose();
  };

  return (
    <MotionBox
      animate="visible"
      variants={animateFromRight}
      initial={animateFromRight.hidden}>
      <Grid
        height="100%"
        templateRows="1fr auto"
        gap="space8"
        fontFamily="body"
        padding="space16"
        paddingTop={0}
        alignItems="start">
        <Box height="fit-content">
          <MobileAppPromoTip />
          <Separator />
          {isCardManager ? <CardManagerContent /> : <CardholderContent />}
        </Box>
        <Button justifySelf="end" onClick={handleClick}>
          {isCardManager ? t('successPage.finished') : t('successPage.close')}
        </Button>
      </Grid>
    </MotionBox>
  );
};
