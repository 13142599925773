import {
  DocumentCurrency,
  TransactionStatus,
  useGetSpendOverviewInsightsQuery,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import moment from 'moment';
import { formatFromCents } from '../utils/formatFromCents';
import { useGetSpentOverviewFilters } from './useGetSpentOverviewFilters';

export type MonthSpentOverview = {
  spentAmount: {
    value: number | undefined | null;
    currency: DocumentCurrency | undefined;
  };
  monthLabel: string;
  fullDate: string;
};

export const useGetSpendOverviewInsights = () => {
  const { allAppliedFilters, filtersFromUrl } = useGetSpentOverviewFilters();
  const { data, loading } = useGetSpendOverviewInsightsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        statuses: [TransactionStatus.Confirmed, TransactionStatus.Pending],
        categories: allAppliedFilters.category.length
          ? allAppliedFilters.category
          : undefined,
        cardIds: allAppliedFilters.cardRefNum.length
          ? allAppliedFilters.cardRefNum
          : undefined,
        merchantNames: allAppliedFilters.merchantNames.length
          ? allAppliedFilters.merchantNames
          : undefined,
      },
    },
  });

  const overviewMonths: MonthSpentOverview[] = (
    data?.getSpendOverviewInsights.details || []
  ).map(detail => ({
    spentAmount: {
      currency: detail.spentAmount?.currency ?? undefined,
      value: !isNil(detail.spentAmount?.amount)
        ? formatFromCents(
            detail.spentAmount?.currency,
            detail.spentAmount?.amount
          )
        : undefined,
    },

    // Only for the monthy of January we want to surface the year
    monthLabel:
      moment(detail.dateLabel).month() === 0
        ? `${moment(detail.dateLabel).format('MMM')} ${moment(
            detail.dateLabel
          ).format('YY')} `
        : moment(detail.dateLabel).format('MMM'),

    fullDate: detail.dateLabel,
  }));

  const maxNumberReference = Math.max(
    ...overviewMonths.map(month => month.spentAmount.value ?? 0)
  );

  const monthsWithCreditCards = overviewMonths.filter(
    month => !isNil(month.spentAmount.value)
  ).length;

  const isOverviewEmpty = overviewMonths.every(
    month => !month.spentAmount.value
  );

  const hasFiltersApplied = Boolean(filtersFromUrl.length);

  return {
    overviewMonths,
    loading,
    maxNumberReference,
    monthsWithCreditCards,
    isOverviewEmpty,
    allAppliedFilters,
    hasFiltersApplied,
  };
};
