import { useState, useEffect } from 'react';
import { Destination } from './types';
import {
  NormalisedDestinations,
  normaliseDestinations,
} from './utils/preferences';

const fetchSegmentDestinations = async (segmentWriteKey: string) => {
  const destinationUrl = `https://cdn.segment.com/v1/projects/${segmentWriteKey}/integrations`;
  const res = await fetch(destinationUrl);

  if (!res.ok) {
    throw new Error(
      `Failed to fetch integrations for write key ${segmentWriteKey}: HTTP ${res.status} ${res.statusText}`
    );
  }

  const destinations: Array<Omit<Destination, 'id'>> = await res.json();

  return destinations.map(destination => {
    return {
      ...destination,
      id: destination.creationName,
    };
  });
};

interface Result<Data> {
  data: Data;
  error: null;
  loading: false;
}

interface Loading {
  data: null;
  error: null;
  loading: true;
}

interface ErrorResult {
  data: null;
  error: Error;
  loading: false;
}

type ResultWrapper<Data> = Result<Data> | Loading | ErrorResult;

export const useSegmentDestinations =
  (): ResultWrapper<NormalisedDestinations> => {
    const segmentWriteKey = import.meta.env.REACT_APP_SEGMENT_KEY;
    const [destinations, setDestinations] =
      useState<NormalisedDestinations | null>(null);

    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
      if (!segmentWriteKey) {
        return setError(
          new Error('No Segment Write Key found in REACT_APP_SEGMENT_KEY')
        );
      }

      fetchSegmentDestinations(segmentWriteKey)
        .then(normaliseDestinations)
        .then(setDestinations)
        .catch(setError);
    }, [segmentWriteKey, setDestinations, setError]);

    return {
      data: destinations,
      loading: destinations === null && error === null,
      error,
    } as ResultWrapper<NormalisedDestinations>;
  };
