import { usePaymentConditionsCountQuery } from 'generated-types/graphql.types';

export interface UsePaymentConditionsCountsReturn {
  numActive?: number;
  numArchived?: number;
  loading: boolean;
}

/** Fetches counts of active and archived payment conditions */
export const usePaymentConditionsCounts =
  (): UsePaymentConditionsCountsReturn => {
    const { data: activeData, loading: activeLoading } =
      usePaymentConditionsCountQuery({
        variables: { filter: { isArchived: false } },
      });

    const { data: archivedData, loading: archivedLoading } =
      usePaymentConditionsCountQuery({
        variables: { filter: { isArchived: true } },
      });

    return {
      numActive: activeData?.paymentConditionsCount,
      numArchived: archivedData?.paymentConditionsCount,
      loading: activeLoading || archivedLoading,
    };
  };
