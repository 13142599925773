import {
  Link as DSLink,
  LinkProps as DSLinkProps,
} from '@candisio/design-system';
import { forwardRef, Ref } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';
import { createClickHandler } from './createClickHandler';

export interface LinkProps
  extends Pick<RouterLinkProps, 'replace' | 'to'>,
    Omit<DSLinkProps, 'href' | 'ref'> {}

/**
 * React Router Link that uses the Link component from the Candis design
 * system
 * - Supports all design system Link props except `href` (use `to` instead)
 * - Supports a subset of the React Router Link props (`to` and `replace`)
 *
 * @example <RouterLink to="/the-past">A Link to the Past</RouterLink>
 */
export const RouterLink = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, onClick, ...restProps }: LinkProps, ref) => {
    return (
      <Link
        component={LinkAdaptor}
        innerRef={ref}
        onClick={onClick}
        {...restProps}>
        {children}
      </Link>
    );
  }
);

interface LinkAdpatorProps extends DSLinkProps {
  innerRef: Ref<HTMLAnchorElement>;
  navigate: () => void;
}

const LinkAdaptor = ({
  children,
  color,
  innerRef,
  navigate,
  onClick,
  target,
  ...restProps
}: LinkAdpatorProps) => {
  return (
    <DSLink
      color={color}
      onClick={createClickHandler({ target, onClick, navigate })}
      target={target}
      {...restProps}
      ref={innerRef}>
      {children}
    </DSLink>
  );
};
