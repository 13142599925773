import { CostCenterExtractionEventDataFragment } from 'generated-types/graphql.types';
import { getTranslationContext } from 'orgConfig';
import { Trans } from 'providers/LocaleProvider';

export const CostCenterExtractedComponent = ({
  costCenter1,
  costCenter2,
}: CostCenterExtractionEventDataFragment) => {
  let tslKey = 'document.compact.history.costCenterExtracted.title';
  const interpolateOptions = {
    ...(costCenter1 ? { costCenter: costCenter1.readableName } : null),
    ...(costCenter2 ? { costObject: costCenter2.readableName } : null),
  };

  if (costCenter1 && !costCenter2) {
    tslKey =
      getTranslationContext() === 'sap'
        ? 'document.compact.history.costCenterExtracted.costCenterOnly_sap'
        : 'document.compact.history.costCenterExtracted.costCenterOnly';
  } else if (!costCenter1 && costCenter2) {
    tslKey =
      getTranslationContext() === 'sap'
        ? 'document.compact.history.costCenterExtracted.costObjectOnly_sap'
        : 'document.compact.history.costCenterExtracted.costObjectOnly';
  }

  return (
    <Trans i18nKey={tslKey} values={interpolateOptions}>
      Cost center {interpolateOptions as any} automatically extracted
    </Trans>
  );
};
