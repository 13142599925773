import { SortingRule } from 'react-table';
import { getSortField } from 'views/Payments/utils/mapToSort';

export const prepareTableSortForUrl = <TableData extends object>(
  sort: SortingRule<TableData>[]
) => {
  if (!sort.length) {
    return null;
  }

  const firstSort = sort[0];

  const sortField = getSortField(firstSort?.id);

  const mapSortForUrl = {
    sort: firstSort?.desc ? `${sortField}:descend` : `${sortField}:ascend`,
  };

  return mapSortForUrl;
};
