import { ProcessingFormFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';

export interface UseAccountingAreaFieldOptionsOptions {
  skip?: boolean;
  accountingAreas: { id: string; name: string }[];
}

/** Returns options for accounting area field */
export const useAccountingAreaFieldOptions = ({
  skip,
  accountingAreas,
}: UseAccountingAreaFieldOptionsOptions): ProcessingFormFieldOptions['accountingArea'] => {
  if (skip) {
    return { hidden: true };
  }

  const items = accountingAreas.map(item => {
    return toAccountingDataFieldItem({
      id: item.id,
      readableName: item.name,
    });
  });

  return {
    hidden: false,
    props: {
      items,
    },
  };
};
