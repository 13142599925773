import { FieldPolicy, TypePolicies } from '@apollo/client';
import { GoodsReceiptConnection } from 'generated-types/graphql.types';
import { paginationWithRecordConnectionsMergeNoNodes } from '../pagination-results-merge';

const goodsReceiptListPagination: FieldPolicy<GoodsReceiptConnection> = {
  keyArgs: ['query', 'queryFields', 'filters', 'sortBy'],
  merge: paginationWithRecordConnectionsMergeNoNodes('GoodsReceiptConnection'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      inboxGoodsReceipts: goodsReceiptListPagination,
    },
  },
};
