import { defaultTheme } from '@candisio/design-system';
import { SvgIcon, SvgProps } from '../SvgIcon';

export const ZipGreyscale = ({
  fill = defaultTheme.colors.gray800,
  ...props
}: SvgProps) => {
  return (
    <SvgIcon {...props} fill={fill}>
      <path
        d="M15.0088 62.312L15.0088 3.59497L48.5614 3.59497L62.9411 18.2742L62.9411 62.312L15.0088 62.312Z"
        fill="#EFEEEE"
      />
      <path
        d="M48.5615 17.9746L48.5615 3.59492L62.9412 17.9746L48.5615 17.9746Z"
        fill="#ABABAB"
      />
      <path
        d="M7.81836 42.6533V55.608H37.776V42.6533H7.81836Z"
        fill="#AEAEAE"
      />
      <path
        d="M20.6072 52.1392H15.5144V51.1983L18.7957 46.494H15.6033V45.2957H20.5183V46.2319L17.2416 50.9409H20.6072V52.1392ZM21.8366 52.1392V45.2957H23.2877V52.1392H21.8366ZM26.6002 48.5162H27.0776C27.5238 48.5162 27.8578 48.4288 28.0793 48.254C28.3009 48.0761 28.4117 47.8187 28.4117 47.4817C28.4117 47.1415 28.318 46.8903 28.1308 46.7281C27.9467 46.5658 27.6565 46.4846 27.2602 46.4846H26.6002V48.5162ZM29.8768 47.4302C29.8768 48.1666 29.6459 48.7299 29.184 49.12C28.7253 49.5101 28.0715 49.7051 27.2227 49.7051H26.6002V52.1392H25.1491V45.2957H27.3351C28.1651 45.2957 28.7955 45.4751 29.2261 45.834C29.6599 46.1898 29.8768 46.7218 29.8768 47.4302Z"
        fill="white"
      />
    </SvgIcon>
  );
};
