/* tslint:disable max-classes-per-file */
import { ParseError } from 'papaparse';

export class CSVFileParseError extends Error {
  errors: ParseError[];

  constructor(errors: ParseError[]) {
    super('Failed to parse csv file');
    this.errors = errors;
  }
}

export class CSVMaxCostCentersError extends Error {
  constructor() {
    super('The CSV export contains more than maximum allowed cost centers');
  }
}

export class CostCentersWithoutCodeError extends Error {
  constructor() {
    super('Found cost center without code');
  }
}

/* tslint:enable max-classes-per-file */
