import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export enum DatevErrors {
  DXSO_SERVICE_NOT_ENABLED = 'DXSO_SERVICE_NOT_ENABLED',
  MISSING_REQUESTER_ID = 'MISSING_REQUESTER_ID',
  GENERATE_AUDIT_TRAIL_FAILED = 'GENERATE_AUDIT_TRAIL_FAILED',
  GENERATE_AUDIT_TRAIL_RESPONSE_INVALID = 'GENERATE_AUDIT_TRAIL_RESPONSE_INVALID',
  GENERATE_AUDIT_TRAIL_FORBIDDEN = 'GENERATE_AUDIT_TRAIL_FORBIDDEN',
  GENERATE_AUDIT_TRAIL_INPUT_INVALID = 'GENERATE_AUDIT_TRAIL_INPUT_INVALID',
  ENTITY_RESPONSE_NOT_FOUND = 'ENTITY_RESPONSE_NOT_FOUND',
  MERGED_PDF_DOWNLOAD_ERROR = 'MERGED_PDF_DOWNLOAD_ERROR',
  CSV_UNKNOWN_ERROR = 'CSV_UNKNOWN_ERROR',
  FETCH_DOWNLOAD_URL_FAILED = 'FETCH_DOWNLOAD_URL_FAILED',
  FAILED_S3_UPLOAD = 'FAILED_S3_UPLOAD',
  FAILED_TO_FETCH_ENTITIES = 'FAILED_TO_FETCH_ENTITIES',
  STUCK_EXPORT = 'STUCK_EXPORT',
  FAILED_TO_FETCH_TRANSACTION_DETAILS = 'FAILED_TO_FETCH_TRANSACTION_DETAILS',
  CSV_BUFFER_GENERATION_FAILED = 'CSV_BUFFER_GENERATION_FAILED',
  XML_UNKNOWN_ERROR = 'XML_UNKNOWN_ERROR',
  ZIP_UNKNOWN_ERROR = 'ZIP_UNKNOWN_ERROR',
  ENTITY_FILE_ID_MISSING = 'ENTITY_FILE_ID_MISSING',
  NO_MERGE_PDF_APIS_FOUND = 'NO_MERGE_PDF_APIS_FOUND',
  INVALID_XML_VALUES = 'INVALID_XML_VALUES',
  INVALID_FILE = 'INVALID_FILE',
  INVALID_AMOUNT = 'INVALID_AMOUNT',
  UNSUPPORTED_CATEGORY = 'UNSUPPORTED_CATEGORY',
  INVALID_INVOICE_DATE = 'INVALID_INVOICE_DATE',
  INVALID_CONTRA_ACCOUNT_LENGTH = 'INVALID_CONTRA_ACCOUNT_LENGTH',
  INVALID_CONTACT = 'INVALID_CONTACT',
  INVALID_ACCOUNTS_PAYABLE_NUMBER = 'INVALID_ACCOUNTS_PAYABLE_NUMBER',
  INVALID_ACCOUNTS_RECEIVABLE_NUMBER = 'INVALID_ACCOUNTS_RECEIVABLE_NUMBER',
  UNPROCESSED_ENTITY = 'UNPROCESSED_ENTITY',
  INVALID_INVOICE_ID = 'INVALID_INVOICE_ID',
  INVALID_VAT_RATE = 'INVALID_VAT_RATE',
  PDF_MERGE_ERROR = 'PDF_MERGE_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  DUO_UNKNOWN_ERROR = 'DUO_UNKNOWN_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  CASH_LEDGER_HAS_NEGATIVE_BALANCE = 'CASH_LEDGER_HAS_NEGATIVE_BALANCE',
}

export const useDatevErrorsMapping = (): Record<DatevErrors, string> => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ERRORS);

  return {
    /** Settings errors */
    [DatevErrors.DXSO_SERVICE_NOT_ENABLED]: t(
      'datevErrors.dxsoServiceNotEnabled'
    ),
    /** Export errors */
    [DatevErrors.MISSING_REQUESTER_ID]: t('datevErrors.E101'),
    [DatevErrors.GENERATE_AUDIT_TRAIL_FAILED]: t('datevErrors.E102'),
    [DatevErrors.GENERATE_AUDIT_TRAIL_RESPONSE_INVALID]: t('datevErrors.E102'),
    [DatevErrors.GENERATE_AUDIT_TRAIL_FORBIDDEN]: t('datevErrors.E102'),
    [DatevErrors.GENERATE_AUDIT_TRAIL_INPUT_INVALID]: t('datevErrors.E102'),
    [DatevErrors.ENTITY_RESPONSE_NOT_FOUND]: t('datevErrors.E102'),
    [DatevErrors.MERGED_PDF_DOWNLOAD_ERROR]: t('datevErrors.E102'),
    [DatevErrors.CSV_UNKNOWN_ERROR]: t('datevErrors.E102'),
    [DatevErrors.FETCH_DOWNLOAD_URL_FAILED]: t('datevErrors.E102'),
    [DatevErrors.FAILED_S3_UPLOAD]: t('datevErrors.E102'),
    [DatevErrors.FAILED_TO_FETCH_ENTITIES]: t('datevErrors.E102'),
    [DatevErrors.STUCK_EXPORT]: t('datevErrors.E102'),
    [DatevErrors.FAILED_TO_FETCH_TRANSACTION_DETAILS]: t('datevErrors.E102'),
    [DatevErrors.CSV_BUFFER_GENERATION_FAILED]: t('datevErrors.E102'),
    [DatevErrors.XML_UNKNOWN_ERROR]: t('datevErrors.E102'),
    [DatevErrors.ZIP_UNKNOWN_ERROR]: t('datevErrors.E102'),
    [DatevErrors.ENTITY_FILE_ID_MISSING]: t('datevErrors.E103'),
    [DatevErrors.NO_MERGE_PDF_APIS_FOUND]: t('datevErrors.E103'),
    [DatevErrors.INVALID_XML_VALUES]: t('datevErrors.E103'),
    [DatevErrors.INVALID_FILE]: t('datevErrors.E103'),
    [DatevErrors.INVALID_AMOUNT]: t('datevErrors.E104'),
    [DatevErrors.UNSUPPORTED_CATEGORY]: t('datevErrors.E105'),
    [DatevErrors.INVALID_INVOICE_DATE]: t('datevErrors.E106'),
    [DatevErrors.INVALID_CONTRA_ACCOUNT_LENGTH]: t('datevErrors.E107'),
    [DatevErrors.INVALID_CONTACT]: t('datevErrors.E108'),
    [DatevErrors.INVALID_ACCOUNTS_PAYABLE_NUMBER]: t('datevErrors.E109'),
    [DatevErrors.INVALID_ACCOUNTS_RECEIVABLE_NUMBER]: t('datevErrors.E110'),
    [DatevErrors.UNPROCESSED_ENTITY]: t('datevErrors.E111'),
    [DatevErrors.INVALID_INVOICE_ID]: t('datevErrors.E112'),
    [DatevErrors.INVALID_VAT_RATE]: t('datevErrors.E113'),
    [DatevErrors.PDF_MERGE_ERROR]: t('datevErrors.E114'),
    [DatevErrors.CASH_LEDGER_HAS_NEGATIVE_BALANCE]: t('datevErrors.E115'),
    [DatevErrors.DUO_UNKNOWN_ERROR]: t('datevErrors.E116'),
    [DatevErrors.UNAUTHORIZED]: t('datevErrors.E116'),
    [DatevErrors.INTERNAL_SERVER_ERROR]: t('datevErrors.E117'),
    [DatevErrors.SERVICE_UNAVAILABLE]: t('datevErrors.E117'),
    [DatevErrors.FORBIDDEN]: t('datevErrors.E118'),
    [DatevErrors.NOT_FOUND]: t('datevErrors.E118'),
  };
};
