import {
  InsightsWidgetReportingUnit,
  useUpdateInsightsPreferencesMutation,
} from 'generated-types/graphql.types';
import { insightsPreferencesQuery } from '../Widgets/NetGrossSelector/gql';

interface UseUpdateInsightsPreferencesReturn {
  update: (unit: InsightsWidgetReportingUnit) => Promise<{
    status: 'success' | 'error';
  }>;
  updating: boolean;
}

export const useUpdateInsightsPreferences =
  (): UseUpdateInsightsPreferencesReturn => {
    const [updateInsightsPreferences, { loading, client }] =
      useUpdateInsightsPreferencesMutation();

    return {
      update: async (unit: InsightsWidgetReportingUnit) => {
        const { errors, data } = await updateInsightsPreferences({
          variables: { input: { unit } },
          refetchQueries: [{ query: insightsPreferencesQuery }],
          awaitRefetchQueries: true,
          onCompleted: data => {
            if (!data) {
              return;
            }

            client.cache.evict({ fieldName: 'getPaginatedInsightsWidgets' });
          },
        });

        if (
          data?.updateInsightsPreferences.__typename ===
            'InsightsPreferences' &&
          !errors?.length
        ) {
          return { status: 'success' };
        }

        return { status: 'error' };
      },
      updating: loading,
    };
  };
