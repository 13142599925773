import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const useNewDashboardFF = () => {
  const newDashboardPromotionsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.newDashboardPromotions
  );

  const activeEntitlement = useEntitlementsFlag();
  const creditCardsSetup = useCreditCardsSetup();

  const showNewDashboardPromotions =
    newDashboardPromotionsFF &&
    activeEntitlement === Entitlements.LEGACY &&
    (!creditCardsSetup.isAddonBought || !creditCardsSetup.showAllExtraFeatures);

  return { showNewDashboardPromotions };
};
