import { getOrganizationMembershipsQuery } from 'containers/credit-cards/CreateCreditCard/gql';
import {
  Locale,
  MemberSortField,
  MembershipStatus,
  OrganizationMembershipsFiltersInput,
  OrganizationMembershipsQuery,
  OrganizationMembershipsQueryVariables,
  SortDirection,
  UserRole,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Key, useCallback, useState } from 'react';

export interface MembershipRole {
  isLegacyRole: boolean;
  isBuiltinRole: boolean;
  id: string;
  name: string;
}

export type Tab = 'ACTIVE' | 'INACTIVE' | 'PENDING';

export interface User {
  id: string;
  name: string;
  email: string;
  status: MembershipStatus | undefined;
  emailVerified: boolean;
  avatarUrl: string | undefined;
  firstName: string;
  lastName: string;
  roles: UserRole[];
  locale: Locale;
  membershipRoles: Array<MembershipRole>;
}
export type UsersSortBy = 'lastAddedFirst' | 'alphabetically';
const mappedQueryVariable: Record<
  string,
  { filters: { status: MembershipStatus; emailVerified: boolean | undefined } }
> = {
  ACTIVE: {
    filters: { status: MembershipStatus.Active, emailVerified: true },
  },
  PENDING: {
    filters: { status: MembershipStatus.Active, emailVerified: false },
  },
  INACTIVE: {
    filters: { status: MembershipStatus.Inactive, emailVerified: undefined },
  },
};

interface UseGetUsersParams {
  filters?: OrganizationMembershipsFiltersInput;
}

export const useGetUsers = ({ filters }: UseGetUsersParams = {}) => {
  const [queryString, setQueryString] = useState('');
  const [sortBy, setSortBy] = useState<UsersSortBy>('alphabetically');
  const { searchParams } = useMutateSearchParams();
  const activeTab = (searchParams.get('status') ?? 'ACTIVE') as Tab;
  const queryVariables = mappedQueryVariable[activeTab];

  const computeVariables = useCallback(
    (name?: string): OrganizationMembershipsQueryVariables => ({
      filters: {
        emailVerified:
          queryVariables.filters.emailVerified ?? filters?.emailVerified,
        status: queryVariables.filters.status ?? filters?.status,
        searchQuery: (name ?? []).length > 0 ? name : undefined,
        roleNames: filters?.roleNames,
      },
      sortBy: {
        direction:
          sortBy === 'alphabetically' ? SortDirection.Asc : SortDirection.Desc,
        field:
          sortBy === 'alphabetically'
            ? MemberSortField.Name
            : MemberSortField.CreatedAt,
      },
    }),
    [
      filters?.emailVerified,
      filters?.roleNames,
      filters?.status,
      queryVariables.filters.emailVerified,
      queryVariables.filters.status,
      sortBy,
    ]
  );

  const { data, loading, handleDebounceSearch } = useDebouncedSearchPagination<
    OrganizationMembershipsQuery,
    OrganizationMembershipsQueryVariables
  >({
    query: getOrganizationMembershipsQuery,
    queryRootKey: 'organizationMemberships',
    // This would not be needed but somehwere in the cache there is a mismatchment
    // which causes the page to reload when we filter the list by card manager
    options: { fetchPolicy: 'no-cache' },
    computeVariables,
  });

  const dataUsers = data?.organizationMemberships ?? [];

  const users: Array<User> = dataUsers.map(user => ({
    id: user.id,
    name: user.name,
    firstName: user.firstName,
    lastName: user.lastName,
    locale: user.locale ?? Locale.De,
    status: user.status ?? undefined,
    // This will need to be removed. Right now there are some checks down the components in the drawer
    // that rely on these roles and we cannot modify it yet until permissions are established.
    roles: user.roles ?? [],
    emailVerified:
      activeTab === 'ACTIVE' || activeTab === 'INACTIVE' ? true : false,
    email: user.email,
    avatarUrl: user.avatarUrl ?? undefined,
    membershipRoles: (user.membershipRoles ?? []).map(role => ({
      isLegacyRole: role.isLegacyRole ?? false,
      isBuiltinRole: role.isBuiltinRole ?? false,
      name: role.name,
      id: role.id,
    })),
  }));

  const handleSearch = (search: string) => {
    setQueryString(search);
    handleDebounceSearch(search);
  };

  const handleSetSortBy = (sortBy: Key[]) => {
    setSortBy(sortBy.length ? (sortBy[0] as UsersSortBy) : 'alphabetically');
  };

  return {
    users,
    isLoadingUsers: loading,
    queryString,
    sortBy,
    handleSearch,
    handleSetSortBy,
    activeTab,
  };
};
