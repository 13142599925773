import { MenuItem, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const CopyGuidItem = ({ label, disabled }: MenuItem) => {
  const [t] = useTranslation();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ isDisabled: !disabled });

  return (
    <>
      <Text wordBreak="break-all" {...triggerProps} ref={triggerRef}>
        {label}
      </Text>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('documentContextMenu.guidCopyNotAvailableHint')}
        </Tooltip>
      )}
    </>
  );
};
