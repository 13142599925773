import { Avatar } from '@candisio/design-system';

import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useRemoveAvatarMutation,
  useRequestUploadAvatarUrlMutation,
  useUpdateAvatarMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadFile } from 'utils/upload';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { getTeamMembersQuery } from 'views/Settings/TeamMembers/queries';

type Props = {
  selectedUser: User;
};

export const UserAvatar = ({ selectedUser }: Props) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const [removeAvatar, { loading: removeAvatarLoading }] =
    useRemoveAvatarMutation();

  const [requestUploadAvatarUrl, { loading: requestUploadAvatarLoading }] =
    useRequestUploadAvatarUrlMutation();

  const [updateAvatar, { loading: updateAvatarLoading }] =
    useUpdateAvatarMutation();

  const onRemoveAvatar = useCallback(async () => {
    const { data } = await removeAvatar({
      variables: {
        membershipId: selectedUser.id,
      },
      refetchQueries: [{ query: getTeamMembersQuery }],
      awaitRefetchQueries: true,
    });

    if (data?.removeAvatar) {
      success(t('uploads.removeImage.success'));
    } else {
      error(t('uploads.removeImage.error'));
    }
  }, [removeAvatar, selectedUser.id, success, t, error]);

  const onUploadAvatar = useCallback(
    async (f: File) => {
      const { data, errors } = await requestUploadAvatarUrl();
      if (!data?.requestUploadAvatarUrl?.uploadUrl) {
        return Promise.reject(errors);
      }

      const res = await uploadFile(
        f,
        data.requestUploadAvatarUrl.postOptions || [],
        data.requestUploadAvatarUrl.uploadUrl
      );

      void updateAvatar({
        variables: {
          id: selectedUser.id,
          url: data.requestUploadAvatarUrl.downloadUrl,
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: getTeamMembersQuery }],
      });

      return res;
    },
    [requestUploadAvatarUrl, selectedUser, updateAvatar]
  );

  return (
    <AvatarUpload
      onRemove={onRemoveAvatar}
      onUpload={onUploadAvatar}
      hasAvatar={!!selectedUser?.avatarUrl}
      removeAvatarLoading={removeAvatarLoading}
      uploadAvatarLoading={requestUploadAvatarLoading || updateAvatarLoading}>
      <Avatar
        name={selectedUser?.name}
        img={selectedUser?.avatarUrl ?? undefined}
        size="large"
        hasTooltip={false}
      />
    </AvatarUpload>
  );
};
