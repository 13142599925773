import { ObservableQuery } from '@apollo/client';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateEcmDocumentMutation } from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { delay, isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ecmDocumentHistoryQuery } from 'views/EcmDocument/queries';
import { ecmStorageFormInitialDataQuery } from './gql';
import { mapStorageFormValues } from './mapStorageFormValues';
import { StorageFormValues } from './storageFormSchema';

interface UseUpdateEcmDocumentOptions {
  documentId: string;
}

type UpdateEcmDocumentResult =
  | { status: 'success'; id: string }
  | { status: 'error' };

type UpdateEcmDocument = (
  values: StorageFormValues
) => Promise<UpdateEcmDocumentResult>;

interface UseUpdateEcmDocumentReturn {
  /** Store document */
  updateEcmDocument: UpdateEcmDocument;
  /** Loading state */
  loading: boolean;
}

const REFETCH_DELAY = 500;

// We need to delay the refetching of the history query because
// the BE does not update the history immediately sometimes
const delayRefetchedQuery = async (observableQuery: ObservableQuery) => {
  if (observableQuery.queryName === 'ecmDocumentHistory') {
    // Try to refetch and see if the result is different
    await observableQuery.refetch();
    const result = await observableQuery.result();
    const hasNewResult = observableQuery.isDifferentFromLastResult(result);

    // If the result is the same, try to refetch again after a delay
    if (!hasNewResult) {
      delay(async () => {
        await observableQuery.refetch();
      }, REFETCH_DELAY);
    }
  } else {
    // Every other query should be refetched immediately
    await observableQuery.refetch();
  }
};

export const useUpdateEcmDocument = ({
  documentId,
}: UseUpdateEcmDocumentOptions): UseUpdateEcmDocumentReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { success, error } = useToastMessage();
  const { dateStringToDateTimeString } = useDateConverter();

  const [mutation, { loading }] = useUpdateEcmDocumentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ecmDocumentHistoryQuery, variables: { id: documentId } },
      // @TODO BE mutation should return the updated document so we don’t need
      // to manually refetch here
      {
        query: ecmStorageFormInitialDataQuery,
        variables: { id: documentId },
      },
    ],
    onQueryUpdated: delayRefetchedQuery,
  });

  const updateEcmDocument: UpdateEcmDocument = async formValues => {
    if (!documentId) {
      return { status: 'error' };
    }

    const mappedStorageFormValues = mapStorageFormValues(
      formValues,
      dateStringToDateTimeString
    );

    const { data } = await mutation({
      variables: {
        input: {
          id: documentId,
          ...mappedStorageFormValues,
        },
      },
    });

    if (
      data?.updateEcmDocument.__typename === 'UpdateEcmDocumentResponse' &&
      !isNil(data.updateEcmDocument.ecmDocumentId)
    ) {
      const id = data?.updateEcmDocument?.ecmDocumentId;

      success(t('storageForm.actions.documentUpdatedSuccessfully'));

      return { status: 'success', id };
    } else {
      error(t('genericErrorMessage'));

      return { status: 'error' };
    }
  };

  return { updateEcmDocument, loading };
};
