import { defaultTheme } from '@candisio/design-system';
import { FunctionComponent } from 'react';
import Textarea, { TextareaAutosizeProps } from 'react-textarea-autosize';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import infoIcon from './images/icon-info.svg';

type ContainerProps = {
  readonly?: boolean;
  alert?: boolean;
  disabled?: boolean;
  primary?: 'true' | undefined;
};

type LabelProps = ContainerProps & {
  value?: any;
  required?: boolean;
  readonly?: boolean;
};

export const ClearInput = styled.div.attrs({
  tabIndex: 0,
  role: 'button',
  'aria-label': 'clear-input',
})<{ value?: any }>`
  grid-area: clear;
  display: flex;
  opacity: ${p => (p.value != null && p.value !== '' ? '1' : '0')};
  visibility: ${p =>
    p.value != null && p.value !== '' ? 'visible' : 'hidden'};

  position: absolute;
  align-items: center;
  z-index: 2;
  right: -0.35rem;
  top: 1.3rem;
  background-color: transparent;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;

  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

  &:focus {
    outline: 0;
  }

  &:focus,
  &:hover {
    cursor: pointer;

    > svg path {
      fill: ${defaultTheme.colors.blue500};
    }
  }
`;

export const StyledInput = styled.input<ContainerProps & { icon?: string }>`
  grid-area: input-component;
  z-index: 1;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  ${p => p.primary === 'true' && 'font-weight: inherit'};
  font-weight: inherit !important;
  margin: 0.875rem 0 0 0;
  color: ${defaultTheme.colors.gray800};
  font-size: ${defaultTheme.fontSizes.basic};
  line-height: ${defaultTheme.lineHeights.body};
  width: 100%;
  padding-left: 0.0625rem;
  &:focus,
  &:focus-within {
    outline: none;
  }
  &::placeholder {
    font-weight: inherit;
    font-size: ${defaultTheme.fontSizes.basic};
    transform: translate(0, 0.063rem);
    color: ${p =>
      p.alert && !p.readOnly
        ? defaultTheme.colors.red500
        : defaultTheme.colors.gray500};
    transition: opacity 150ms;
  }
  &:focus::placeholder {
    opacity: 0;
  }
  ${p => p.icon && 'padding-right: 1.5rem;'}
  &::-webkit-outer-spin-button {
    ${p => {
      return p.type === 'number' ? '-webkit-appearance: none; margin: 0;' : '';
    }}
  }
  &::-webkit-inner-spin-button {
    ${p => {
      return p.type === 'number' ? '-webkit-appearance: none; margin: 0;' : '';
    }}
  }
  ${p => {
    return p.type === 'number' ? '-moz-appearance:textfield;' : '';
  }}
`;

export const TextInputContainer = styled.div<ContainerProps>`
  display: grid;
  padding: 0 0.75rem 0 0.75rem;
  min-height: 2.938rem;
  line-height: 2.938rem;
  border-bottom: ${p =>
    `0.0625rem solid ${
      p.readonly ? 'transparent' : defaultTheme.colors.gray250
    };`};
  background-color: ${p =>
    p.readonly ? 'transparent' : defaultTheme.colors.gray0};
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'input-component clear';
  position: relative;
  ${p =>
    p.alert &&
    !p.readonly &&
    `border-left: 4px solid ${defaultTheme.colors.red500};
    border-bottom: 1px solid ${defaultTheme.colors.red500};
  `}
  ${p =>
    p.disabled &&
    `
    background-color: ${
      p.readonly ? defaultTheme.colors.gray200 : defaultTheme.colors.gray0
    };
    border-bottom-color: ${
      p.readonly ? 'transparent' : defaultTheme.colors.gray250
    };
    ${StyledLabel} {
      color: ${defaultTheme.colors.gray500}
    }
    ${StyledTextArea}, ${StyledInput} {
      color: ${defaultTheme.colors.gray500};
      opacity: 1;
    }
  `}
  &:focus-within {
    border-bottom-color: ${defaultTheme.colors.blue500};

    ${StyledInput} [value]:not([value=""]) {
      ${ClearInput} {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;

export const InputInfo = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 14.5px;
  bottom: 4px;
  background-image: url(${infoIcon});
  z-index: 1;
`;

export const StyledTextArea = styled<
  // eslint-disable-next-line @typescript-eslint/ban-types
  FunctionComponent<ContainerProps & TextareaAutosizeProps & { icon?: string }>
>(({ alert, ...props }) => <Textarea {...(props as any)} />)`
  grid-area: input-component;
  z-index: 1;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: ${defaultTheme.colors.gray800};
  font-size: ${defaultTheme.fontSizes.basic};
  line-height: ${defaultTheme.lineHeights.paragraph};
  ${p => p.primary === 'true' && 'font-weight:  inherit'};
  font-weight: inherit;
  width: 100%;
  min-height: 86px;
  margin: 1.5rem 0 0 0;
  padding: 0;
  resize: none;
  &:focus,
  &:focus-within {
    outline: none;
  }
  &::placeholder {
    font-size: ${defaultTheme.fontSizes.basic};
    font-weight: inherit;
    transform: translate(-0.063rem, 0);
    color: ${p =>
      p.alert && !p.readOnly
        ? defaultTheme.colors.red500
        : defaultTheme.colors.gray500};
    transition: opacity 150ms;
  }
  &:focus::placeholder {
    opacity: 0;
  }
  ${p => p.icon && 'padding-right: 1.5rem;'}
`;

export const StyledLabel = styled.label<LabelProps>`
  grid-area: input-component;
  top: 0;
  margin-top: 5px;
  ${p => p.value != null && 'transform: translate(0, -0.1rem);'}

  line-height: 17px;
  height: 17px;
  color: ${defaultTheme.colors.gray600};
  font-size: ${defaultTheme.fontSizes.small};
  color: ${p =>
    !p.readonly ? defaultTheme.colors.gray600 : defaultTheme.colors.gray500};
  transition: all 0.2s;
  padding: 0 0.063rem;
  font-weight: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  ${p => p.alert && !p.readonly && `color: ${defaultTheme.colors.red500};`}
`;

export const StyledIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 0.4rem;
  bottom: 0.25rem;
`;

export const CharacterWarningText = styled.div`
  display: flex;
  align-items: start;

  & svg {
    margin-right: 0.125rem;
    width: ${defaultTheme.fontSizes.xsmall};
    height: ${defaultTheme.fontSizes.xsmall};
  }
`;

export const CharacterCount = styled.div`
  display: flex;
  flex: auto;
  justify-content: end;
`;

export const CharacterCounterWrapper = styled.div<{ showWarning: boolean }>`
  grid-row: 2
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 0;
  line-height: ${defaultTheme.fontSizes.xsmall};
  font-size: ${defaultTheme.fontSizes.xsmall};
  color: ${defaultTheme.colors.yellow700};
  opacity: ${p => (p.showWarning ? 1 : 0)};
  visibility: ${p => (p.showWarning ? 'visible' : 'hidden')};
  transition: opacity 200ms;
`;
