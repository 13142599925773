import {
  ContactType,
  ContactValidationErrorType,
} from 'generated-types/graphql.types';
import { SUPPORTED_CONTACT_TYPES } from 'views/Contacts/consts';
import { FilterState, SingleParsedContact } from './types';

const getDefaultFilterState = (): FilterState => ({
  accountsPayableNumber: null,
  customerNumber: null,
  iban: null,
  taxNumber: null,
  vatId: null,
  companyName: null,
  individualFirstName: null,
  individualLastName: null,
  notSpecifiedName: null,
  shortName: null,
  paymentConditionNumber: null,
  email: null,
  phoneNumber: null,
  bankAccountNumber: null,
  swiftCode: null,
  contactTypeNumber: null,
  street: null,
  postOfficeBox: null,
  postalCode: null,
  city: null,
  countryISOCode: null,
});

const filterAndMapContacts = (
  contacts: SingleParsedContact[],
  errorType: ContactValidationErrorType,
  fields: Array<keyof SingleParsedContact['contactInputData']>
) => {
  return contacts
    .filter(c => c.errors.includes(errorType))
    .map(c => {
      const result: Partial<
        Record<keyof SingleParsedContact['contactInputData'], any>
      > = {};

      for (const field of fields) {
        result[field] = c.contactInputData[field];
      }

      return result;
    });
};

type ErrorConfigType = {
  [key in ContactValidationErrorType]: {
    infoKey: string;
    fields: Array<keyof SingleParsedContact['contactInputData']>;
    extra?: Record<string, any>;
  };
};

const errorConfig: Partial<ErrorConfigType> = {
  [ContactValidationErrorType.InvalidContactType]: {
    infoKey: 'import.preview.errors.contactTypeNumberInvalid.showInfo',
    fields: ['contactTypeNumber'],
  },
  [ContactValidationErrorType.InvalidCompanyName]: {
    infoKey: 'import.preview.errors.companyNameMissing.showInfo',
    fields: ['companyName'],
    extra: {
      contactTypeNumber: [
        Number(SUPPORTED_CONTACT_TYPES.get(ContactType.Company)),
      ],
    },
  },
  [ContactValidationErrorType.AllNameFieldsAreEmptyForIndividual]: {
    infoKey: 'import.preview.errors.individualNameMissing.showInfo',
    fields: ['individualFirstName'],
    extra: {
      contactTypeNumber: [
        Number(SUPPORTED_CONTACT_TYPES.get(ContactType.Individual)),
      ],
    },
  },
  [ContactValidationErrorType.InvalidNotSpecifiedName]: {
    infoKey: 'import.preview.errors.notSpecifiedNameMissing.showInfo',
    fields: ['notSpecifiedName'],
    extra: {
      contactTypeNumber: [
        Number(SUPPORTED_CONTACT_TYPES.get(ContactType.NotSpecified)),
      ],
    },
  },
  [ContactValidationErrorType.DuplicateName]: {
    infoKey: 'import.preview.errors.nameDuplicate.showInfoCsv',
    fields: ['companyName'],
  },
  [ContactValidationErrorType.DuplicateCreditorAccountNumber]: {
    infoKey: 'import.preview.errors.accountsPayableNumberDuplicate.showInfoCsv',
    fields: ['accountsPayableNumber'],
  },
  [ContactValidationErrorType.InvalidAccountsPayableNumber]: {
    infoKey: 'import.preview.errors.accountsPayableNumberInvalid.showInfo',
    fields: ['accountsPayableNumber'],
  },
  [ContactValidationErrorType.InvalidCustomerNumber]: {
    infoKey: 'import.preview.errors.customerNumberInvalid.showInfo',
    fields: ['customerNumber'],
  },
  [ContactValidationErrorType.InvalidPaymentCondition]: {
    infoKey: 'import.preview.errors.paymentConditionNumberInvalid.showInfo',
    fields: ['paymentConditionNumber'],
  },
  [ContactValidationErrorType.PaymentConditionNotFound]: {
    infoKey: 'import.preview.errors.paymentConditionNumberNotFound.showInfo',
    fields: ['paymentConditionNumber'],
  },
  [ContactValidationErrorType.PaymentConditionArchived]: {
    infoKey: 'import.preview.errors.paymentConditionNumberArchived.showInfo',
    fields: ['paymentConditionNumber'],
  },
  [ContactValidationErrorType.InvalidIban]: {
    infoKey: 'import.preview.errors.ibanInvalid.showInfo',
    fields: ['iban'],
  },
  [ContactValidationErrorType.InvalidTaxNumber]: {
    infoKey: 'import.preview.errors.taxNumberInvalid.showInfo',
    fields: ['taxNumber'],
  },
  [ContactValidationErrorType.InvalidVatId]: {
    infoKey: 'import.preview.errors.vatIdInvalid.showInfo',
    fields: ['vatId'],
  },
  [ContactValidationErrorType.InvalidPhoneNumber]: {
    infoKey: 'import.preview.errors.phoneNumberInvalid.showInfo',
    fields: ['phoneNumber'],
  },
  [ContactValidationErrorType.InvalidEmail]: {
    infoKey: 'import.preview.errors.emailInvalid.showInfo',
    fields: ['email'],
  },
  [ContactValidationErrorType.InvalidBankAccountNumber]: {
    infoKey: 'import.preview.errors.bankAccountNumberInvalid.showInfo',
    fields: ['bankAccountNumber'],
  },
  [ContactValidationErrorType.BankAccountNumberAndIbanMismatch]: {
    infoKey: 'import.preview.errors.bankAccountNumberAndIbanMismatch.showInfo',
    fields: ['bankAccountNumber', 'iban'],
  },
  [ContactValidationErrorType.BankAccountNumberMissingSwiftCode]: {
    infoKey: 'import.preview.errors.bankAccountNumberMissingSwiftCode.showInfo',
    fields: ['bankAccountNumber', 'swiftCode'],
  },
  [ContactValidationErrorType.InvalidSwiftCode]: {
    infoKey: 'import.preview.errors.swiftCodeInvalid.showInfo',
    fields: ['swiftCode'],
  },
  [ContactValidationErrorType.SwiftCodeMissingIbanOrBankAccountNumber]: {
    infoKey: 'import.preview.errors.ibanOrBankAccountMissing.showInfo',
    fields: ['swiftCode', 'iban', 'bankAccountNumber'],
  },
  [ContactValidationErrorType.InvalidStreet]: {
    infoKey: 'import.preview.errors.streetInvalid.showInfo',
    fields: ['street'],
  },
  [ContactValidationErrorType.InvalidPostOfficeBox]: {
    infoKey: 'import.preview.errors.postOfficeBoxInvalid.showInfo',
    fields: ['postOfficeBox'],
  },
  [ContactValidationErrorType.InvalidPostalCode]: {
    infoKey: 'import.preview.errors.postalCodeInvalid.showInfo',
    fields: ['postalCode'],
  },
  [ContactValidationErrorType.InvalidCity]: {
    infoKey: 'import.preview.errors.cityInvalid.showInfo',
    fields: ['city'],
  },
  [ContactValidationErrorType.InvalidCountryCode]: {
    infoKey: 'import.preview.errors.countryISOCodeInvalid.showInfo',
    fields: ['countryISOCode'],
  },
};

type ValidFields = keyof FilterState &
  keyof SingleParsedContact['contactInputData'];

export const useContactImportErrorReports = () => {
  const buildErrorObject = (errorType: ContactValidationErrorType) => {
    const errorObj = errorConfig[errorType];

    if (!errorObj) {
      return null;
    }

    const { fields, infoKey, extra } = errorObj;

    return {
      infoKey,
      linkKey: 'import.preview.errors.actions.showLink',
      onLinkClick: (contacts: SingleParsedContact[]): FilterState => {
        const fieldValues = filterAndMapContacts(contacts, errorType, fields);

        let newFilterState: FilterState = {
          ...getDefaultFilterState(),
          ...extra,
        };

        for (const field of fields) {
          if (field in newFilterState) {
            const key = field as ValidFields;
            newFilterState[key] = fieldValues.map(f => f[key]);
          }
        }

        return newFilterState;
      },
    };
  };

  let errorReports: { [key: string]: any } = {};

  for (const errorType in errorConfig) {
    const reportObj = buildErrorObject(errorType as ContactValidationErrorType);

    if (reportObj) {
      errorReports[errorType] = reportObj;
    }
  }

  return errorReports;
};
