import { Box, Grid, Text, TruncatedText } from '@candisio/design-system';
import { AvailableEcmDocumentFiltersKeys } from 'components/EcmDocumentsTable/constants';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { useContractCustomSubCategoryData } from '../../toolkit/useContractCustomSubCategoryData';
import { MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH } from '../Form/contractSubCategoryDetailsFormSchema';

export const CONTRACT_TYPE_FILTER_NAME: AvailableEcmDocumentFiltersKeys =
  'contractType';

export interface ContractCustomSubCategoriesListItemProps {
  contractSubCategory: ReturnType<
    typeof useContractCustomSubCategoryData
  >['contractSubCategories'][number];
  queryString: string;
}

export const ContractCustomSubCategoriesListItem = ({
  contractSubCategory: { id, name, description, isStatic, documentCount },
  queryString,
}: ContractCustomSubCategoriesListItemProps) => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const hasDocumentCount = typeof documentCount === 'number';

  const archiveWithFilterUrl = `/${organizationSlug}${Routes.ECM_CONTRACTS}?${CONTRACT_TYPE_FILTER_NAME}=${id}`;

  const customWithNoDocuments =
    !isStatic && (!hasDocumentCount || documentCount === 0);

  const customWithDocuments = !isStatic && !!documentCount;

  const archiveTableLink = (
    <RouterLink
      to={archiveWithFilterUrl}
      onClick={e => e.stopPropagation()}
      justifySelf="end">
      <Box padding="space8 space12" hover={{ textDecoration: 'underline' }}>
        {customWithDocuments
          ? documentCount
          : t('settings:contractCustomSubCategories.showDocuments')}
      </Box>
    </RouterLink>
  );

  return (
    <Grid
      alignItems="center"
      autoFlow="column"
      // ensure all rows have same height and it corresponds to
      // the height when the description breaks into 2 lines
      autoRows="44px"
      gap="space16"
      // apply 2 or 3 columns depending on `hasDocumentCount`
      templateColumns={`${MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH}ch 1fr ${
        hasDocumentCount && 'auto'
      }`}>
      <Text
        color="gray800"
        fontWeight="semibold"
        fontSize="basic"
        lineHeight="22px">
        {renderQueryHighlight({
          value: name ?? '',
          queryString: queryString,
        })}
        {isStatic && (
          <Text color="gray500" fontWeight="regular">
            {' '}
            {t('settings:contractCustomSubCategories.predefinedType')}
          </Text>
        )}
      </Text>
      {/* @ts-expect-error for some reason TrucatedText complains about JSX.Element[] */}
      <TruncatedText
        fontSize="small"
        color="gray500"
        lineClamp={2}
        lineHeight="22px">
        {renderQueryHighlight({
          value: description ?? '',
          queryString: queryString,
        }) ?? ''}
      </TruncatedText>

      {customWithNoDocuments && (
        <Box padding="space8 space12" color="gray500" justifySelf="end">
          0
        </Box>
      )}

      {!customWithNoDocuments && archiveTableLink}
    </Grid>
  );
};
