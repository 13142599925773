import { Flex, Button, Grid } from '@candisio/design-system';
import { useGetCardById } from 'containers/credit-cards/utils';
import { useInboxTransactionCount } from 'hooks/useCounterQueries';
import { Routes } from 'models';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { CCWalletTrackingEvents } from 'providers/AnalyticsProvider/events/CCWalletTrackingEvents';
import { useCardholderId } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { TRANSACTION_FILTER } from 'views/Inbox/Transactions/useTransactionListFilters';
import { LimitChangeInfo } from '../../LimitChangeInfo';
import { ShowTransactions } from '../../ShowTransactions';

type LockedProps = {
  cardId: string;
  onCardDetails: () => void;
  onShowAll: () => void;
  onShowMissing: () => void;
  transactionsWithoutDocumentCount: number;
};

export const Locked = ({
  cardId,
  onCardDetails,
  onShowAll,
  onShowMissing,
  transactionsWithoutDocumentCount,
}: LockedProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Grid gap="space8">
      <LimitChangeInfo cardId={cardId} />
      <Flex direction="column" gap="space8" alignSelf="end">
        <ShowTransactions
          onShowAll={onShowAll}
          onShowMissing={onShowMissing}
          transactionsWithoutDocumentCount={transactionsWithoutDocumentCount}
        />
        <Button
          size="small"
          variant="secondary"
          justifySelf="end"
          alignSelf="end"
          icon="arrowRight"
          iconPosition="right"
          onClick={onCardDetails}>
          {t('carousel.details')}
        </Button>
      </Flex>
    </Grid>
  );
};

export const LockedContainer = ({ cardId }: { cardId: string }) => {
  const history = useHistory();
  const { track } = useAnalytics();

  const organizationSlug = useOrganizationId();
  const { card } = useGetCardById({ cardId });

  const { cardholderId } = useCardholderId();

  const userEventMetrics = {
    organizationId: organizationSlug,
    cardType: card?.type,
    refNum: card?.refNum,
  };

  const { inboxTransactionCount } = useInboxTransactionCount({
    cardholderIds: cardholderId ? [cardholderId] : undefined,
    cardIds: [cardId],
    skip: !cardholderId,
  });

  const onShowAll = () => {
    history.push({
      pathname: `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`,
      search: qs.stringify({
        [TRANSACTION_FILTER.cardRefNum]: [cardId],
      }),
    });

    track(CCWalletTrackingEvents.SHOW_TRANSACTIONS_BTN_CLICKED, {
      ...userEventMetrics,
    });
  };

  const onShowMissing = () => {
    history.push({
      pathname: `/${organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}`,
      search: qs.stringify({
        [TRANSACTION_FILTER.cardRefNum]: [cardId],
      }),
    });
  };

  const onCardDetails = () => {
    history.replace({
      pathname: `/${organizationSlug}${Routes.DASHBOARD_CREDIT_CARD}/${cardId}`,
      search: history.location.search,
    });
  };

  return (
    <Locked
      cardId={cardId}
      onCardDetails={onCardDetails}
      onShowAll={onShowAll}
      onShowMissing={onShowMissing}
      transactionsWithoutDocumentCount={inboxTransactionCount}
    />
  );
};
