import { Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const DocumentResetTransactionUnlinkedComponent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Text>{t('documentHistoryCCEvents.documentResetTransactionUnlinked')}</Text>
  );
};

export const DocumentResetTransactionLinkedComponent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Text>{t('documentHistoryCCEvents.documentResetTransactionLinked')}</Text>
  );
};

export const DocumentResetTransactionAmountUpdatedComponent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Text>
      {t('documentHistoryCCEvents.documentResetTransactionAmountUpdate')}
    </Text>
  );
};
