import { Button, Flex, Image, Tag, Text } from '@candisio/design-system';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { thumbnails } from 'containers/Entitlements/components/Upsell/utils';
import { appointmentLinks } from 'containers/Entitlements/toolkit/utils';
import { Locale } from 'generated-types/graphql.types';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { ArchiveContractManagementEvents } from 'providers/AnalyticsProvider/events/ContractManagementEvents';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ArchiveViewLayout } from 'views/Archive/components/ArchiveViewLayout';
import { TabView } from 'views/Inbox/models';
import { FeatureBenefit, PromotionCards } from './PromotionCard';
import { VideoCard } from './VideoCard';

export const contractBenefits: FeatureBenefit[] = [
  {
    icon: 'creditCardOutline',
    heading: 'promo.contractTable.modal.benefit1.heading',
    description: 'promo.contractTable.modal.benefit1.description',
    isUpcoming: false,
  },
  {
    icon: 'bell',
    heading: 'promo.contractTable.modal.benefit2.heading',
    description: 'promo.contractTable.modal.benefit2.description',
    isUpcoming: false,
  },
  {
    icon: 'copy',
    heading: 'promo.contractTable.modal.benefit3.heading',
    description: 'promo.contractTable.modal.benefit3.description',
    isUpcoming: true,
  },
  {
    icon: 'linkOutlined',
    heading: 'promo.contractTable.modal.benefit4.heading',
    description: 'promo.contractTable.modal.benefit4.description',
    isUpcoming: false,
  },
] as const;

const { MODAL_DEMO_BTN_CLICKED, MORE_INFO_CLICKED, DEMO_BTN_CLICKED } =
  ArchiveContractManagementEvents;

export const ContractsTablePromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[FEATURE.CONTRACTS][locale];

  const { track } = useAnalytics();

  const trackModalDemoClick = useCallback(
    () => track(MODAL_DEMO_BTN_CLICKED),
    [track]
  );

  const trackDemoClick = useCallback(
    () => track(DEMO_BTN_CLICKED), // ignoring prettier
    [track]
  );

  const trackMoreInfoClick = useCallback(
    () => track(MORE_INFO_CLICKED),
    [track]
  );

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_CONTRACTS}>
      <Flex
        direction="column"
        gap="space16"
        paddingX="space12"
        maxWidth="1300px">
        <Tag color="purple" variant="secondary">
          {t('promo.tag')}
        </Tag>
        <Text as="h2" fontSize="xxlarge">
          <Trans t={t} i18nKey="promo.contractTable.title">
            <Text fontWeight="bold">Contract management</Text>
            Behalte den Überblick über laufende Verträge und Fristen
          </Trans>
        </Text>
        <VideoCard feature={FEATURE.CONTRACTS} withFading>
          <Image src={thumbnail} alt="" width="100%" opacity=".7" />
        </VideoCard>
        <Flex
          position="absolute"
          bottom="-4vmax"
          direction="column"
          alignItems="center"
          gap="space16"
          alignSelf="center"
          width="fit-content">
          <PromotionLink
            trackClick={trackDemoClick}
            href={t(appointmentLinks[FEATURE.CONTRACTS])}
            variant="calendar">
            {t('promo.modal.bookAppointment')}
          </PromotionLink>
          <UpsellPromo
            feature={FEATURE.CONTRACTS}
            CustomPromoMaterial={
              <PromotionCards
                benefits={contractBenefits}
                feature={FEATURE.CONTRACTS}
              />
            }
            trackMoreInfo={trackMoreInfoClick}
            trackDemo={trackModalDemoClick}
            hidePackages>
            <Button variant="tertiary" color="promo" width="100%">
              {t('promo.contractTable.moreInfoCTA')}
            </Button>
          </UpsellPromo>
        </Flex>
      </Flex>
    </ArchiveViewLayout>
  );
};
