import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { CardId } from './useLatestTransactionsByCardId';

const urlParam = 'cardRefNum';

export const useCardTransactionsLink = ({
  linkedCardId,
  replacedCardId,
}: CardId) => {
  const organizationSlug = useOrganizationId();
  const searchParams = new URLSearchParams();

  if (linkedCardId) {
    searchParams.append(urlParam, `${linkedCardId}`);
  }

  if (replacedCardId) {
    searchParams.append(urlParam, `${replacedCardId}`);
  }

  const link = `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}?${searchParams}`;

  return { link };
};
