import { z } from 'zod';

/** Zod's `z.coerce` uses `Number(value)` which converts invalid values to numbers
 *
 * Eg `z.coerce.number().parse('070001')` => 70001
 *
 * Using this function with `z.preprocess` instead will prevent the above from happening.
 */
export const preprocessStringToNumber = (
  value: unknown,
  ctx: z.RefinementCtx
): unknown => {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value !== 'string') {
    return value;
  }

  const valueAsNumber = Number.parseInt(value);

  if (Number.isNaN(valueAsNumber) || valueAsNumber.toString() !== value) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'number',
      received: typeof value,
    });

    return z.NEVER;
  }

  return valueAsNumber;
};
