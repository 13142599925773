import { useContext, useEffect } from 'react';
import { getPaginatedExportsQuery } from 'views/Integrations/Export/toolkit/queries';
import { ExportStatus } from '../../../../../generated-types/graphql.types';
import { usePagination } from '../../../../../providers/GraphQLProvider/Pagination/usePagination';
import { ExportQuery, paginationQueryOptions } from '../../consts';
import { ExportContext } from '../../Context';
import { useExportDataGigaFactory } from './useExportDataGigaFactory';

export const useIsExporting = () => {
  const state = useContext(ExportContext);
  const paginationResponse = usePagination<ExportQuery>(
    getPaginatedExportsQuery,
    '_exports',
    paginationQueryOptions
  );

  const {
    refetch: { all: refetchAll },
  } = useExportDataGigaFactory(state);

  const { data } = paginationResponse;

  const exports = data?._exports.node ?? [];
  const exporting =
    exports.find(({ status }) => status === ExportStatus.Exporting) ?? null;

  useEffect(() => {
    if (exporting === null) {
      refetchAll.forEach(refetch => refetch());
    }
  }, [exporting, refetchAll]);

  return { isExporting: !!exporting };
};
