import {
  Flex,
  Grid,
  Spinner,
  TableElement,
  TableBody,
  TableHead,
  TableRow,
  Tag,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, useTable } from 'react-table';
import DateFormatters from 'utils/date_formatter';
import { Cell, Header, TableBodyLayout } from './styles';
import { CreditCardSettlementsTableData } from './types';

export const CreditCardSettlementsTable = ({
  loading,
  data,
}: {
  loading: boolean;
  data: CreditCardSettlementsTableData[];
}) => {
  const defaultColumn = useMemo(
    (): Partial<Column<CreditCardSettlementsTableData>> => ({
      Header,
      Cell,
    }),
    []
  );

  const columns: Array<Column<CreditCardSettlementsTableData>> = [
    {
      accessor: 'actualSettlementDate',
      Cell: ({ value }) => (
        <TruncatedText wordBreak="break-all">
          {value ? DateFormatters.compact(value) : ''}
        </TruncatedText>
      ),
    },
    {
      accessor: 'formattedAmount',
    },
    {
      accessor: 'status',
      Cell: ({ value }) => (
        <Tag color="gray" variant="secondary">
          {value}
        </Tag>
      ),
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      Header,
      Cell,
      defaultColumn,
      defaultCanFilter: false,
      autoResetHiddenColumns: false,
    });

  const Loading = () => {
    const [tCreditCards] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

    return (
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100%">
        <Spinner size="space64" color="gray400" />
        <Text fontSize="small">
          {tCreditCards('creditCardPayments.tableLoading')}
        </Text>
      </Flex>
    );
  };

  return (
    <TableElement
      {...getTableProps()}
      style={{ minWidth: '100%', width: 'max-content' }}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              return (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps()}
                  style={{ background: 'white' }}>
                  <Grid
                    alignItems="center"
                    autoFlow="column"
                    justifyContent="start">
                    <Grid alignItems="center" autoFlow="column" gap="space4">
                      {column.render('Header')}
                    </Grid>
                  </Grid>
                </th>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {loading ? (
          <TableBodyLayout colSpan={columns?.length}>
            <Loading />
          </TableBodyLayout>
        ) : (
          rows.map(row => {
            prepareRow(row);

            return (
              // eslint-disable-next-line react/jsx-key
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </TableElement>
  );
};
