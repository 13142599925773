const threshold = 33;
const max = 100;
const midThreshold = 66;

export const determineColorBar = (percentage: number) => {
  if (percentage >= midThreshold && percentage <= max) {
    return 'green500';
  } else if (percentage <= midThreshold && percentage >= threshold) {
    return 'yellow500';
  } else {
    return 'red500';
  }
};
