import { ContactSupplierFormErrorMessages } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/contactSupplierFormSchema';

export const contactSupplierFormErrorMessages: ContactSupplierFormErrorMessages =
  {
    emailTo: {
      label: 'emailEditor.email.label',
      custom: {
        translationKey: 'emailEditor.email.validationError',
      },
    },
    subject: {
      label: 'emailEditor.subject.label',
    },
    htmlText: {
      label: 'emailEditor.htmlText.label',
    },
    emailFrom: {
      label: 'emailEditor.emailFrom.label',
    },
    emailCc: {
      label: 'emailEditor.emailCc.label',
    },
  };
