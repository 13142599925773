import { gql } from '@apollo/client';

export const virtualCardRequestFragment = gql`
  fragment VirtualCardRequestData on VirtualCardRequest {
    validityPeriod
    approvedLabel
    label
    comment
    requestedLimit {
      value
      precision
      currency
    }
    requestedLimitRenewFrequency
    requestedTransactionLimit {
      value
      precision
      currency
    }
  }
`;

export const singleUseVirtualCardRequestFragment = gql`
  fragment SingleUseVirtualCardRequestData on SingleUseVirtualCardRequest {
    purpose
    requestedTransactionLimit {
      value
      precision
      currency
    }
  }
`;

export const physicalCardRequestFragment = gql`
  fragment PhysicalCardRequestData on PhysicalCardRequest {
    comment
    label
    requestedLimitRenewFrequency
    requestedLimit {
      value
      precision
      currency
    }
    requestedTransactionLimit {
      value
      precision
      currency
    }
  }
`;

export const CardLimitChangeRequest = gql`
  fragment CardLimitChangeRequestData on CardLimitChangeRequest {
    comment
    requestedLimitRenewFrequency
    requestedTransactionLimit {
      value
      precision
      currency
    }
    requestedLimit {
      value
      precision
      currency
    }
  }
`;
export const creditCardByIdDataFragment = gql`
  fragment creditCardByIdData on CardIssuerCard {
    id
    permissionsForMembership
    cardholder {
      id
      membershipId
      membershipEmail
      firstName
      lastName
    }
    type
    status
    label
    refNum
    issuingDate
    shippingDate
    expiryDate
    purpose
    limitRenewFrequency
    limitRenewDate
    limit {
      value
      precision
      currency
    }
    spendingBalance {
      value
      precision
      currency
    }
    availableBalance {
      value
      precision
      currency
    }
    transactionLimit {
      value
      precision
      currency
    }
    brand
    pendingRequest {
      __typename
      id
      status
      createdAt
      ...VirtualCardRequestData
      ...SingleUseVirtualCardRequestData
      ...PhysicalCardRequestData
      ...CardLimitChangeRequestData
    }
    allRequests {
      __typename
      id
      status
      createdAt
      ...VirtualCardRequestData
      ...SingleUseVirtualCardRequestData
      ...PhysicalCardRequestData
      ...CardLimitChangeRequestData
    }
    pendingActions {
      id
      name
      createdAt
    }
    linkedVendors {
      records {
        vendor {
          name
        }
        linkedCards {
          card {
            id
          }
          transactionState
        }
      }
      pageInfo {
        totalCount
      }
    }
    hasAccountingData
    accountingData {
      contactId
      costCenterId
      costObjectId
      generalLedgerAccountId
      bookingKeyId
      artistSocialInsuranceCode
      extraCostInfoId
      paymentConditionId
      approvalWorkflowTemplateId
      approverIds
    }
    replacedByCardId
    replacedCardId
    replacedBy {
      firstName
      lastName
    }
    isInvoiceNeeded
  }
  ${virtualCardRequestFragment}
  ${singleUseVirtualCardRequestFragment}
  ${physicalCardRequestFragment}
  ${CardLimitChangeRequest}
`;

export const getCardIssuerCardByIdQuery = gql`
  query getCardIssuerCardById($id: ID!) {
    getCardIssuerCardById(id: $id) {
      ...creditCardByIdData
    }
  }
  ${creditCardByIdDataFragment}
`;

export const updateCardLabelMutation = gql`
  mutation updateCardLabel($input: UpdateCardLabelInput!) {
    updateCardLabel(input: $input)
  }
`;

export const terminateCard = gql`
  mutation terminateCard($input: CardIssuerTerminateCardInput!) {
    terminateCard(input: $input)
  }
`;

export const terminateProcessingCardMutation = gql`
  mutation terminateProcessingCard($input: CardIssuerCancelParkedCardInput!) {
    cancelParkedCard(input: $input)
  }
`;

export const updateCardPurposeMutation = gql`
  mutation updateCardPurpose($input: UpdateCardPurposeInput!) {
    updateCardPurpose(input: $input)
  }
`;
