import {
  Card,
  Flex,
  Grid,
  Button,
  Link,
  Paragraph,
  Text,
} from '@candisio/design-system';
import { useSetFiscalYearConfiguredMutation } from 'generated-types/graphql.types';
import { Trans } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { refetchDatevData } from '../../../../Settings/Integrations/DATEV/gql';

export const DatevFiscalYearReminderPanel = () => {
  const [t] = useTranslation();

  const [setFiscalYearAsConfigured] = useSetFiscalYearConfiguredMutation();

  const onBtnClick = useCallback(async () => {
    await setFiscalYearAsConfigured({
      refetchQueries: refetchDatevData,
    });
  }, [setFiscalYearAsConfigured]);

  return (
    <Card boxShadow="none" background="bluebg">
      <Grid gap="space8">
        <Text fontWeight="semibold" fontSize="small" lineHeight="paragraph">
          {t('export.exportForm.fiscalYearReminder.heading')}
        </Text>
        <Paragraph>
          <Trans
            t={t}
            i18nKey="export.exportForm.fiscalYearReminder.body"
            components={{
              1: (
                <Link
                  external
                  href={t('export.exportForm.fiscalYearReminder.linkUrl')}
                />
              ),
            }}
          />
        </Paragraph>
        <Flex>
          <Button
            size="small"
            onClick={onBtnClick}
            variant="secondary"
            color="blue">
            {t('export.exportForm.fiscalYearReminder.btnLabel')}
          </Button>
        </Flex>
      </Grid>
    </Card>
  );
};
