import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
import { Member } from '../../toolkit/hooks/useGetTeams';
import { TeamMembers } from './TeamMembers';

interface TeamMembersContainerProps {
  isSubmitting: boolean;
  onSubmit: () => void;
  onGoBack: () => void;
  onSetMembers: (members: Member[]) => void;
}

export const TeamMembersContainer = ({
  isSubmitting,
  onSubmit,
  onGoBack,
  onSetMembers,
}: TeamMembersContainerProps) => {
  const { loading, users } = useUsersFieldOptions({
    status: 'ActiveAndPending',
  });

  return (
    <TeamMembers
      teamMembers={users}
      isSubmitting={isSubmitting}
      isLoading={loading}
      onClick={onSubmit}
      onGoBack={onGoBack}
      onSetMembers={onSetMembers}
    />
  );
};
