import { Button, Card, Grid, Heading, Text } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TeamDetails as TeamDetailsCard } from 'views/Settings/Teams/elements/List/TeamDetails';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';

interface ArchivedTeamProps {
  team: Team;
  isRestorePending: boolean;
  onClose: () => void;
  onRestoreTeam: () => Promise<void>;
}

export const ArchivedTeam = ({
  team,
  isRestorePending,
  onClose,
  onRestoreTeam,
}: ArchivedTeamProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const handleClick = async () => {
    await onRestoreTeam();
    onClose();
  };

  const deactivatedAtText = team.updatedAt
    ? t('teams.archivedTeam.archivedAt', { archivedAt: team.updatedAt })
    : undefined;

  return (
    <DrawerLayout
      onClose={onClose}
      header={<Heading as="h3">{t('teams.editTeam.title')}</Heading>}
      children={
        <Grid height="100%" gap="space24">
          <Card background="bluebg">
            <Grid gap="space4">
              <Text fontSize="basic" fontWeight="semibold">
                {t('teams.archivedTeam.title')}
              </Text>
              {deactivatedAtText && <Text>{deactivatedAtText}</Text>}
              <Button
                loading={isRestorePending}
                disabled={isRestorePending}
                justifySelf="right"
                onClick={handleClick}
                color="blue">
                {t('teams.archivedTeam.restoreCTA')}
              </Button>
            </Grid>
          </Card>
          <Card padding="0px" border="1px solid gray250">
            <TeamDetailsCard team={team} />
          </Card>
        </Grid>
      }
    />
  );
};
