import { Grid, GridProps } from '@candisio/design-system';
import { motion } from 'framer-motion';

interface FilterableListItemProps
  extends Omit<GridProps<'div'>, 'as' | 'onSelect'> {
  itemId: string;
  onSelect?: (itemId: string) => void;
  hasAction?: boolean;
  showDefaultStyling?: boolean;
}

const MotionGrid = motion(Grid);

export const FilterableListItem = ({
  children,
  itemId,
  onSelect,
  hasAction = false,
  showDefaultStyling = true,
  style = {},
  ...gridProps
}: FilterableListItemProps) => {
  return (
    <MotionGrid
      width="100%"
      {...(showDefaultStyling && {
        templateColumns: '1fr',
        autoFlow: 'column',
        gap: 'space16',
        justifyContent: 'start',
        alignItems: 'center',
        padding: 'space12 space20',
        borderBottom: '1px solid gray200',
      })}
      style={onSelect || hasAction ? { ...style, cursor: 'pointer' } : style}
      {...gridProps}
      onClick={() => onSelect?.(itemId)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.25 }}>
      {children}
    </MotionGrid>
  );
};
