import {
  Box,
  Button,
  Grid,
  Paragraph,
  Popover,
  Text,
  usePopover,
} from '@candisio/design-system';
import {
  DocumentStatus,
  EcmDocumentStatus,
  useUnlinkInvoiceFromPurchaseOrderMutation,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  documentQueries,
  getPurchaseOrderByInvoiceIdQuery,
} from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';
import { AcceptedDocumentStatus, popoverDescription } from './utils';

interface PurchaseOrderUnlinkContextMenuProps {
  docStatus?: DocumentStatus | EcmDocumentStatus;
  documentId?: string;
  purchaseOrderId?: string | null;
}

export const PurchaseOrderUnlinkContextMenu = ({
  docStatus,
  documentId,
  purchaseOrderId,
}: PurchaseOrderUnlinkContextMenuProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { isOnlyApprover } = useUserRoles();

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'bottom' });

  const description = isOnlyApprover
    ? 'purchaseOrderCardContextMenu.approver.description'
    : popoverDescription[docStatus as AcceptedDocumentStatus];

  const [
    unlinkInvoiceToPurchaseOrderMutation,
    { loading: isUnlinkingLoading },
  ] = useUnlinkInvoiceFromPurchaseOrderMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: getPurchaseOrderByInvoiceIdQuery,
        variables: { invoiceId: documentId || '' },
      },
      { query: documentQueries.forDraftForm, variables: { id: documentId } },
      { query: documentHistoryQuery, variables: { id: documentId } },
    ],
    onCompleted: () => {
      close();
    },
  });

  return (
    <Box>
      <Button
        icon="unlink"
        label={t('purchaseOrderCardContextMenu.tooltip')}
        variant="tertiary"
        size="small"
        position="absolute"
        right="0"
        top="space8"
        {...triggerProps}
        ref={triggerRef}
        name={t('purchaseOrderCardContextMenu.tooltip')}
      />

      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="365px"
          padding="space14">
          <Grid gap="space16">
            <Grid gap="space8">
              <Text fontWeight="bold">
                {t('purchaseOrderCardContextMenu.title')}
              </Text>
              <Paragraph>{t(description)}</Paragraph>
            </Grid>
            <Grid autoFlow="column" gap="space16" justifyContent="end">
              <Button
                variant="secondary"
                onClick={close}
                name={t('purchaseOrderCardContextMenu.cancelCTALabel')}>
                {t('purchaseOrderCardContextMenu.cancelCTALabel')}
              </Button>
              <Button
                color="error"
                loading={isUnlinkingLoading}
                onClick={async () => {
                  if (documentId && purchaseOrderId) {
                    await unlinkInvoiceToPurchaseOrderMutation({
                      variables: {
                        invoiceId: documentId,
                        purchaseOrderId: purchaseOrderId,
                      },
                    });
                  }
                }}
                name={t('purchaseOrderCardContextMenu.confirmCTALabel')}>
                {t('purchaseOrderCardContextMenu.confirmCTALabel')}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </Box>
  );
};
