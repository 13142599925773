import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { Maybe, PageInfo, Query } from 'generated-types/graphql.types';
import { DocumentNode } from 'graphql';
import { useEffect } from 'react';
import { DeepPartial } from 'utility-types';

export type OnLoadMore = () => void;

export type UsePaginationResponse<T> = Omit<QueryResult<T>, 'fetchMore'> & {
  onLoadMore: OnLoadMore;
};

type PaginationResponse = {
  pageInfo?: Maybe<PageInfo>; // # Information to aid in pagination.
};

/**
 * Pagination wrapper for useQuery hook
 * 1. Provides same as useQuery api + onLoadMore callback.
 * 2. You must create a TypePolicy to merge the pagination results. (see examples in the ../LocalState dir)
 * 3. Use the `keyArgs` in the `FieldPolicy` or the `@connection` directive to
 *    control the cache key for the pagination results.
 * 4. All the pagination results will be evicted from the cache on unmount.
 *
 * @example
 * .....
 * const { data, loading, onLoadMore } = usePagination<Pick<Query, "documents">>(getDocuments);
 *
 * return (
 *    <Table
 *      dataSource={data.documents.records}
 *      onLoadMore={onLoadMore}
 *      .....
 *    />
 * );
 * .....
 *
 * Note: while using it with table, remember to remove local sorting and filtering!
 *
 */
export function usePagination<
  T extends DeepPartial<Query>,
  K extends keyof Omit<T, '__typename'> = keyof Omit<T, '__typename'>
>(
  query: DocumentNode,
  queryRootKey: K & string,
  options?: QueryHookOptions<T>,
  {
    computeVariables,
  }: { computeVariables?: (endCursor: string) => object } = {}
): UsePaginationResponse<T> {
  let { data, loading, fetchMore, ...rest } = useQuery<T>(query, {
    ...options,
  });

  // root query key under data, usually query name
  const response = data?.[queryRootKey] as PaginationResponse | undefined;

  // main handler to fetch more
  const onLoadMore: OnLoadMore = () => {
    const endCursor = response?.pageInfo?.endCursor;
    const hasNextPage = response?.pageInfo?.hasNextPage;

    // cannot fetch more without an end cursor
    if (endCursor && hasNextPage) {
      void fetchMore({
        query,
        //@ts-expect-error https://github.com/apollographql/apollo-client/issues/7059
        notifyOnNetworkStatusChange: true,
        variables: computeVariables
          ? computeVariables(endCursor)
          : {
              /** Variables from the original query are used again to keep the GQL cache
               * key the same. `after` and `limit` (and any other purely pagination variables)
               * should be excluded from the `FieldPolicy`'s `keyArgs` or the
               * `@connection` directive's `filter` parameter
               */
              ...options?.variables,
              after: endCursor,
              limit: options?.variables?.limit,
            },
      });
    }
  };

  /**
   * Evict all the cached pagination results, so when we navigate back to the
   * view, we don't try render potentionally hundreds of results from the cache.
   */
  useEffect(() => {
    return () => {
      rest.client.cache.evict({ fieldName: queryRootKey });
    };
  }, [queryRootKey, rest.client.cache]);

  return { data, loading, onLoadMore, ...rest };
}
