import { UserRole } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { CreditCardsSpentOverviewTrackingEvents } from 'providers/AnalyticsProvider/events/CreditCardsSpentOverviewTrackingEvents';
import { travelExpenseEvents } from 'providers/AnalyticsProvider/events/TravelAndExpenses';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ReactNode, useMemo } from 'react';
import { useInitialExportUrl } from 'views/Integrations/Export/Manifest';
import {
  Approvals,
  Archive,
  Contacts,
  Dashboard,
  Exports,
  Inbox,
  Payments,
  Settings,
} from './Items';
import { CreditCards } from './Items/CreditCards';
import { TravelAndExpenses } from './Items/TravelAndExpense';

export type MenuLinkItem = {
  title: string;
  url: string;
  itemProps?: any;
  allowedRoles?: UserRole[];
  onMenuLinkClick?: () => void | undefined;
  itemWithCounter: ReactNode;
  showMenuItem?: boolean;
};

export const useMenuTopLinks = (): MenuLinkItem[] => {
  const { ft, show } = useFeatureToggle();
  const exportViewDef = useInitialExportUrl();

  return [
    {
      title: 'navigation.dashboard',
      url: Routes.DASHBOARD,
      allowedRoles: [
        UserRole.Requester,
        UserRole.Admin,
        UserRole.Approver,
        UserRole.Accountant,
      ],
      itemWithCounter: <Dashboard />,
      itemProps: {
        'data-cy': 'nav-item-home',
      },
    },
    {
      title: 'navigation.inbox',
      url: Routes.INBOX,
      allowedRoles: [
        UserRole.Requester,
        UserRole.Admin,
        UserRole.Approver,
        UserRole.Accountant,
      ],
      itemWithCounter: <Inbox />,
      itemProps: {
        'data-cy': 'nav-item-inbox',
      },
    },
    {
      title: 'navigation.approvals',
      url: Routes.APPROVALS,
      itemWithCounter: <Approvals />,
      allowedRoles: [
        UserRole.Approver,
        UserRole.Requester,
        UserRole.Admin,
        UserRole.Accountant,
      ],
      itemProps: {
        'data-cy': 'nav-item-approvals',
      },
    },
    {
      title: 'navigation.payments',
      url: Routes.PAYMENTS,
      itemWithCounter: <Payments />,
      allowedRoles: show(ft.SEPA_XML)
        ? [UserRole.Admin, UserRole.Accountant]
        : [],
      itemProps: {
        'data-cy': 'nav-item-payments',
      },
    },
    {
      title: 'navigation.export',
      url: exportViewDef.url || Routes.EXPORTS,
      itemWithCounter: <Exports />,
      allowedRoles: [UserRole.Admin, UserRole.Accountant],
      itemProps: {
        'data-cy': 'nav-item-exports',
      },
    },
    {
      title: 'navigation.archive',
      url: Routes.ARCHIVE,
      itemWithCounter: <Archive />,
      allowedRoles: [
        UserRole.Approver,
        UserRole.Requester,
        UserRole.Admin,
        UserRole.Accountant,
      ],
      itemProps: {
        'data-cy': 'nav-item-archive',
      },
    },
  ];
};

export const useMenuBottomLinks = (): MenuLinkItem[] => {
  const organization = useFullOrganization();
  const { name, email } = useCurrentUser() ?? {};
  const { track } = useAnalytics();
  const { isAccountant, isAdmin, isCardManager } = useUserRoles();
  const { showAllExtraFeatures } = useCreditCardsSetup();
  const { canUseReimbursement } = useReimbursement();

  const showCreditCardMenuItem = Boolean(
    organization?.creditCardsDetails && showAllExtraFeatures
  );

  const creditCardsUrl =
    isAdmin || isCardManager
      ? Routes.CREDIT_CARDS_DASHBOARD
      : Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW;

  // Currently only two roles can access any routes under on the settings page.
  // If more roles are added that can access the settings page, or custom roles
  // are enabled, we'll need a smarter way to redirect to the first settings
  // sub-route the user has permission to access.
  // This requires completely upgrading to react-router v6.
  const settingsRoute = isAccountant
    ? `${Routes.SETTINGS}${Routes.TEAM_MEMBERS}`
    : `${Routes.SETTINGS}${Routes.ORGANIZATION}`;

  return useMemo(
    () => [
      {
        showMenuItem: canUseReimbursement,
        title: 'navigation.travelAndExpense',
        url: Routes.REIMBURSEMENT,
        itemWithCounter: <TravelAndExpenses />,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CardManager,
          UserRole.Approver,
          UserRole.Requester,
          UserRole.Accountant,
        ],
        onMenuLinkClick: () =>
          track(travelExpenseEvents.DASHBOARD_OPENED, {
            name,
            email,
          }),
      },
      {
        showMenuItem: showCreditCardMenuItem,
        title: 'navigation.creditCards',
        url: creditCardsUrl,
        itemWithCounter: <CreditCards />,
        allowedRoles: [
          UserRole.CardManager,
          UserRole.Admin,
          UserRole.Accountant,
          UserRole.Requester,
          UserRole.Approver,
        ],
        onMenuLinkClick: () =>
          track(CreditCardsSpentOverviewTrackingEvents.INSIGHTS_TAB_CLICKED),
      },
      {
        title: 'navigation.contacts',
        url: Routes.CONTACTS,
        itemWithCounter: <Contacts />,
        allowedRoles: [UserRole.Admin, UserRole.Requester, UserRole.Accountant],
        itemProps: {
          'data-cy': 'nav-item-contacts',
        },
      },
      {
        title: 'navigation.settings',
        url: settingsRoute,
        itemWithCounter: <Settings />,
        allowedRoles: [UserRole.Admin, UserRole.Accountant],
        itemProps: {
          'data-cy': 'nav-item-settings',
        },
      },
    ],
    [
      canUseReimbursement,
      creditCardsUrl,
      email,
      name,
      settingsRoute,
      showCreditCardMenuItem,
      track,
    ]
  );
};
