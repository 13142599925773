import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { ArtistSocialInsuranceCode } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

/**
 * Returns a function that maps `ArtistSocialInsuranceCode` to
 * `ProcessingFormAccountingDataFieldItem`
 */
export const useToArtistSocialInsuranceCodeFieldItem = () => {
  const [t] = useTranslation();

  return (
    artistSocialInsuranceCode: ArtistSocialInsuranceCode
  ): ProcessingFormAccountingDataFieldItem => ({
    key: artistSocialInsuranceCode,
    // eslint-disable-next-line candis/no-template-strings-inside-translation
    children: t(
      `document.requestApproval.inputs.artistSocialInsuranceCode.codes.${artistSocialInsuranceCode}`
    ),
  });
};
