import {
  ReimbursementCaseFiltersInput,
  useReimbursementCasesCountQuery,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';

interface UseReimbursementsCountProps {
  filters?: ReimbursementCaseFiltersInput;
}

export const useReimbursementsCount = ({
  filters,
}: UseReimbursementsCountProps) => {
  const { canUseReimbursement } = useReimbursement();

  const { data, loading: loadingCount } = useReimbursementCasesCountQuery({
    variables: { filters },
    skip: !canUseReimbursement,
  });

  const count = data?.reimbursementCasesCount?.total ?? 0;

  return {
    count,
    loadingCount,
  };
};
