export function formatStringWithSpaces(
  str: string,
  start: number,
  interval: number
): string {
  const sanitized = str.replace(/\s/g, ''); // Remove all spaces from the string
  if (sanitized.length <= start) return sanitized;

  let result = '';
  for (let i = 1; i <= sanitized.length; i++) {
    if (i < start) {
      result += sanitized[i - 1];
      continue;
    }

    result += sanitized[i - 1];
    if ((i - start) % interval === 0 && i !== sanitized.length) {
      // Check if the current index is at the specified interval
      result += ' ';
    }
  }

  return result;
}
