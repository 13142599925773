import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  Span,
  SpanContext,
  Transaction,
  TransactionContext,
} from '@sentry/types';
import { History } from 'history';
import { compiledRoutes } from '../../models';
import {
  CustomTransactionsIntegration,
  IncludeRoute,
} from './CustomTransactionsIntegration';

// Errors from browser extensions and third party libraries are also being sent
// to Sentry (LaunchDarkly, Intercom etc)
const denyUrls: Array<string | RegExp> = [
  /intercomcdn\.com/,
  /launchdarkly\.com/,

  /cypress_runner\.js$/,

  // Browser extensions
  /extensions\//i,
  /-extension[s]?:/i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  /^safari-extension:\/\//i,
];

const ignoreErrors: Array<string | RegExp> = [
  /LaunchDarklyFlagFetchError/,
  /ResizeObserver loop/,
  /Unexpected server response \(403\) while retrieving PDF/,
];

export const DOCUMENT_REQUEST_APPROVAL = 'Document request approval';

const { REACT_APP_GRAPHQL_ENDPOINT: graphqlEndpoint } = import.meta.env || {};
const tracingOrigins: Array<string | RegExp> = graphqlEndpoint
  ? [graphqlEndpoint, new RegExp('.amazonaws.com')]
  : [];

const routes: IncludeRoute[] = [
  {
    path: `/:organizationSlug${compiledRoutes.documentProcessingRoute}`,
    name: 'Processing > document view',
  },
  {
    path: `/:organizationSlug${compiledRoutes.inboxRoute}`,
    name: 'Processing > list view',
  },
  {
    path: `/:organizationSlug${compiledRoutes.documentApprovalRoute}`,
    name: 'Approvals > document view',
  },
  {
    path: `/:organizationSlug${compiledRoutes.approvalsRoute}`,
    name: 'Approvals > list view',
  },
  {
    path: `/:organizationSlug${compiledRoutes.documentArchiveRoute}`,
    name: 'Documents > document view',
  },
  {
    path: `/:organizationSlug${compiledRoutes.archiveRoute}`,
    name: 'Documents > list view',
  },
  {
    path: DOCUMENT_REQUEST_APPROVAL,
    name: DOCUMENT_REQUEST_APPROVAL,
  },
];

type InitSentry = (params: {
  dsn?: string;
  environment?: string;
  release?: string;
  history: History<unknown>;
}) => void;

export const initSentry: InitSentry = ({
  dsn = import.meta.env.REACT_APP_SENTRY_DSN,
  environment = import.meta.env.REACT_APP_SENTRY_ENV,
  release = import.meta.env.REACT_APP_RELEASE_SHA1,
  history,
}) => {
  if (!dsn) {
    return null;
  }

  return Sentry.init({
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        startTransactionOnPageLoad: false,
        tracingOrigins,
      }),
      new CustomTransactionsIntegration({ includedRoutes: routes }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [import.meta.env.REACT_APP_GRAPHQL_ENDPOINT], // allow to log GQL request/response in session replay
      }),
    ],
    tracesSampleRate: environment === 'prod' ? 0.25 : 1,
    dsn,
    environment,
    release,
    denyUrls,
    ignoreErrors,
    // Session Replay
    replaysSessionSampleRate: 0, // We only care about sessions where an error occurs.
    replaysOnErrorSampleRate: environment === 'prod' ? 1.0 : 0, // Skip non-prod environments
  });
};

export const startTransaction = (context: TransactionContext): Transaction => {
  const transaction = Sentry.startTransaction(context);
  Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction));

  return transaction;
};

export const addSpanToTransaction = (
  context: Pick<
    SpanContext,
    | 'description'
    | 'op'
    | 'status'
    | 'tags'
    | 'data'
    | 'startTimestamp'
    | 'endTimestamp'
  >,
  transaction?: Transaction
): Span | undefined => {
  const currentTx =
    transaction ?? Sentry.getCurrentHub().getScope()?.getTransaction();

  const span = currentTx?.startChild(context);

  return span;
};
