import {
  CardStatus,
  CardType,
  useGetCountCardsQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export const useHasAlreadyPhysicalCard = (
  teamMemberFieldValue: string | undefined
) => {
  const { isCardManager } = useUserRoles();
  const user = useCurrentUser();

  const membershipId = isCardManager ? teamMemberFieldValue : user?.id;

  const { data, loading } = useGetCountCardsQuery({
    skip: !membershipId,
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        types: [CardType.Physical],
        statuses: [
          CardStatus.Active,
          CardStatus.Locked,
          CardStatus.Processing,
          CardStatus.LockedPin,
          CardStatus.Pending,
          CardStatus.Requested,
        ],
        ...(membershipId && { membershipIds: [membershipId] }),
      },
    },
  });

  return { loading, hasPhysicalCard: Boolean(data?.countCards?.total) };
};
