import { useApproveCardLimitChangeRequestMutation } from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { ModifiedLimitDetails } from '../../types';

export interface UseApproveLimitRequestParams {
  cardId: string;
}

export const useApproveLimitRequest = ({
  cardId,
}: UseApproveLimitRequestParams) => {
  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  const options = {
    awaitRefetchQueries: true,
    refetchQueries: [
      ...refetchQueries.cardIssuerCards(cardId),
      ...refetchQueries.cardIssuerCardsCardManager(),
    ],
    onCompleted() {
      evictPaginationResults();
    },
  };

  const [approveRequest, { loading }] =
    useApproveCardLimitChangeRequestMutation(options);

  return {
    approve: async (
      pendingRequestId: string,
      limitDetails?: ModifiedLimitDetails
    ) => {
      const { errors } = await approveRequest({
        variables: {
          input: {
            cardRequestId: pendingRequestId,
            ...limitDetails,
          },
        },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    approving: loading,
  };
};
