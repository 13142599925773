import {
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Link,
  Tooltip,
  useTooltip,
  Text,
} from '@candisio/design-system';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface FinancialOverviewPrefundOrgProps {
  available: number;
  currency: string;
  precision: number;
  onClick: () => void;
}

export const FinancialOverviewPrefundOrg = ({
  available,
  currency,
  precision,
  onClick,
}: FinancialOverviewPrefundOrgProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { transactionAmountFormatter } = useMoneyFormatter();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const readableAvailableLimit = transactionAmountFormatter({
    currency,
    precision,
    value: available,
  });

  return (
    <Card boxShadow="none" background="gray50">
      <Grid gap="space16" height="100%">
        <Grid templateColumns="repeat(2, 1fr)">
          <Flex gap="space16" alignItems="center" alignSelf="start">
            <Heading as="h3" justifySelf="start">
              {t('organizationLimit.details.available')}
            </Heading>
            <Icon
              icon="infoOutline"
              size="space16"
              {...triggerProps}
              ref={triggerRef}
            />
          </Flex>
          <Flex
            direction="column"
            justifyContent="space-between"
            justifySelf="end">
            <Heading as="h3" alignSelf="end">
              {readableAvailableLimit}
            </Heading>
            <Link onClick={onClick} as="button">
              {t('prefunded.topUpButtonCTA')}
            </Link>
          </Flex>
        </Grid>
      </Grid>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Grid gap="space12">
            <Text>{t('organizationLimit.info.prefund')}</Text>
            <Link
              href="containers/credit-cards/components/HelpCenterPanel"
              data-intercom="intercom-chat">
              {t('organizationLimit.linkText')}
            </Link>
          </Grid>
        </Tooltip>
      )}
    </Card>
  );
};
