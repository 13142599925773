import { Item, ListView } from '@candisio/design-system';
import { CandisApiSession } from 'generated-types/graphql.types';
import { ApiSessionEmptyListItem } from 'views/Settings/ApiSettings/elements/List/ApiSessionEmptyListItem';
import { SessionListItem } from 'views/Settings/ApiSettings/elements/List/ApiSessionListItem';
import { SessionListItemLoading } from 'views/Settings/ApiSettings/elements/List/SessionListItemLoading';
import { useApiSettings } from 'views/Settings/ApiSettings/toolkit/hooks/useApiSettings';

export const ApiSessionList = () => {
  const {
    revokeApiSession,
    isRevokeCandisApiSessionLoading,
    listCandisAPISessions,
    listCandisAPISessionsDataLoading,
  } = useApiSettings();

  if (listCandisAPISessionsDataLoading) {
    return <SessionListItemLoading />;
  }

  if (!listCandisAPISessions?.length) {
    return <ApiSessionEmptyListItem />;
  }

  return (
    <ListView
      children={listCandisAPISessions?.map((session: CandisApiSession) => (
        <Item textValue={session.clientName} key={session.sessionId}>
          <SessionListItem
            apiSession={session}
            isRevokeSessionLoading={isRevokeCandisApiSessionLoading}
            onRevokeSession={revokeApiSession}
          />
        </Item>
      ))}
    />
  );
};
