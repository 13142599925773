import { mergeProps } from '@candisio/design-system';
import {
  HookFormPaginatedComboBoxField,
  HookFormPaginatedComboBoxFieldProps,
} from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { FieldPath } from 'react-hook-form';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

export const ProcessingFormPaginatedComboBoxField = ({
  name,
  readOnly,
  ...restProps
}: HookFormPaginatedComboBoxFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(
    `${name}.value` as FieldPath<ProcessingFormValues>
  );

  return (
    <HookFormPaginatedComboBoxField
      name={name}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
