/**
 * Helpers to consistently format date across the FE
 */
import 'moment';
import moment from 'moment-timezone';

export enum CandisFormat {
  compact = 'compact',
  compactDatetime = 'compactDatetime',
  regularDatetime = 'regularDatetime',
  longDatetime = 'longDatetime',
}

type FormatDateFn = (
  date: Date,
  format?: CandisFormat,
  timezone?: string
) => string;

type DateFormatterHelper = (date: Date, timezone?: string) => string;
interface DateFormattersType {
  compact: DateFormatterHelper;
  compactDatetime: DateFormatterHelper;
  formatDate: FormatDateFn;
  germanDate: DateFormatterHelper;
  longDatetime: DateFormatterHelper;
  regularDatetime: DateFormatterHelper;
  fromNow: DateFormatterHelper;
}

/**
 * Mapping formats used at Candis to library specific formats (currently Moment)
 */
const DateFormatMap: Record<CandisFormat, string> = {
  compact: 'L',
  compactDatetime: 'L HH:mm',
  regularDatetime: 'LLL',
  longDatetime: 'LLLL',
};

const DEFAULT_TIMEZONE = 'Europe/Berlin';

/**
 * Formats a date using CandisFormats. Default format is `compact`
 */
export const formatDate: FormatDateFn = (
  date,
  format = CandisFormat.compactDatetime,
  timezone = DEFAULT_TIMEZONE
) => moment(date).tz(timezone).format(DateFormatMap[format]);

/**
 * Formats a date into a string that says how much time has passed from now
 */

const fromNow: DateFormatterHelper = (date, timezone = DEFAULT_TIMEZONE) =>
  moment(date).tz(timezone).fromNow();

/**
 * Helpers to format dates
 */
const DateFormatters: DateFormattersType = {
  formatDate,
  compact: (date, timezone) => formatDate(date, CandisFormat.compact, timezone),
  compactDatetime: (date, timezone) =>
    formatDate(date, CandisFormat.compactDatetime, timezone),
  regularDatetime: (date, timezone) =>
    formatDate(date, CandisFormat.regularDatetime, timezone),
  longDatetime: (date, timezone) =>
    formatDate(date, CandisFormat.longDatetime, timezone),
  germanDate: date =>
    date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  fromNow,
};

export default DateFormatters;
