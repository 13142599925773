import { MouseEventHandler, MouseEvent } from 'react';

/**
 * Creates a click handler to work around this React Router bug:
 * https://github.com/remix-run/react-router/issues/7727
 */
export const createClickHandler = ({ onClick, target, navigate }: any) => {
  // The click handler code is taken directly from the React Router source.
  // See: https://github.com/remix-run/react-router/issues/7727#issuecomment-751286252
  const handleClick: MouseEventHandler<HTMLAnchorElement> = event => {
    try {
      if (onClick) {
        onClick(event);
      }
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!target || target === '_self') && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      navigate();
    }
  };

  return handleClick;
};

const isModifiedEvent = (event: MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
