import { Card, Flex, Grid, Icon, Text } from '@candisio/design-system';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const transferredItemsPhysicalCard = [
  'creditCardFormDataPreview.replaceCard.transferredDataList.limits',
  'creditCardFormDataPreview.replaceCard.transferredDataList.cardControls',
  'creditCardFormDataPreview.replaceCard.transferredDataList.accountingInformation',
  'creditCardFormDataPreview.replaceCard.transferredDataList.validityPeriod',
];

const transferredItemsVirtualCard = [
  'creditCardFormDataPreview.replaceCard.transferredDataList.limits',
  'creditCardFormDataPreview.replaceCard.transferredDataList.cardControls',
  'creditCardFormDataPreview.replaceCard.transferredDataList.accountingInformation',
];

interface TransferredItemInfoProps {
  cardType: CardType;
}

export const TransferredItemInfo = ({ cardType }: TransferredItemInfoProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const transferredItemsTranslations =
    cardType === CardType.Virtual
      ? transferredItemsVirtualCard
      : transferredItemsPhysicalCard;

  return (
    <Card background="bluebg">
      <Grid templateColumns="auto 1fr" gap="space8" alignItems="flex-start">
        <Icon size="space20" icon="infoCircle" color="blue500" />
        <Flex direction="column" gap="space4">
          <Text fontSize="basic">
            {t(
              'creditCardFormDataPreview.replaceCard.transferredDataList.title'
            )}
          </Text>
          <ul style={{ margin: '0px', paddingLeft: '16px' }}>
            {transferredItemsTranslations.map((item, index) => (
              <li key={index}>
                <Text fontSize="basic">{t(item)}</Text>
              </li>
            ))}
          </ul>
        </Flex>
      </Grid>
    </Card>
  );
};
