import {
  Button,
  Grid,
  Paragraph,
  SectionHeading,
  Separator,
} from '@candisio/design-system';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import {
  FeatureBenefit,
  PromotionCards,
} from 'containers/Entitlements/components/Upsell/components/PromotionCard';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { videoTours } from 'containers/Entitlements/components/Upsell/utils';
import { EcmDocumentType, Locale } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { isEmpty } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormAction } from '../FormAction';
import { StorageFormValues } from './storageFormSchema';

const contractsBenefits: FeatureBenefit[] = [
  {
    heading: 'promo.contractTable.modal.benefit1.heading',
    description: 'promo.contractTable.modal.benefit1.description',
    isUpcoming: false,
  },
  {
    heading: 'promo.contractTable.modal.benefit2.heading',
    description: 'promo.contractTable.modal.benefit2.description',
    isUpcoming: false,
  },
  {
    heading: 'promo.contractTable.modal.benefit3.heading',
    description: 'promo.contractTable.modal.benefit3.description',
    isUpcoming: true,
  },
  {
    heading: 'promo.contractTable.modal.benefit4.heading',
    description: 'promo.contractTable.modal.benefit4.description',
    isUpcoming: false,
  },
] as const;

const ContractPromo = memo(() => {
  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const videoTour = videoTours[FEATURE.CONTRACTS][locale];

  return (
    <>
      <PromotionCards
        videoTour={videoTour}
        benefits={contractsBenefits}
        feature={FEATURE.CONTRACTS}
      />
      <Separator />
    </>
  );
});

export interface StorageFormActionsProps {
  /** Determines if document should be stored or updated */
  isInvoice?: boolean;
  /** Determines if document should be stored as a sensitive contract */
  isNewSensitiveContract?: boolean;
  /** Called when document should be deleted */
  onDeleteDocument?: () => Promise<void>;
}

/**
 * Actions for ECM storage form:
 * - Store document (submit)
 * - Store sensitive contract (submit)
 * - Update document (submit)
 * - Delete document
 */
export const StorageFormActions = ({
  isInvoice = false,
  isNewSensitiveContract = false,
  onDeleteDocument,
}: StorageFormActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const [deleting, setDeleting] = useState(false);
  const { isOnlyApprover } = useUserRoles();

  const { ft, promo } = useFeatureToggle();

  const { formState, watch } = useFormContext<StorageFormValues>();

  const documentType = watch('documentType');
  const isSubmitted = formState.isSubmitted;

  const hasValidationErrors = !isEmpty(formState.errors);
  const isFormSubmitting = formState.isSubmitting;

  let submitButtonText = t('storageForm.actions.updateDocument');

  if (isNewSensitiveContract) {
    submitButtonText = t('storageForm.actions.storeSensitiveContract');
  }

  if (isInvoice) {
    submitButtonText = t('storageForm.actions.storeDocument');
  }

  const isContract = documentType === EcmDocumentType.Contract;
  const showContractPromo = promo(ft.CONTRACTS) && isContract;
  const isSubmittedWithErrors = isSubmitted && hasValidationErrors;

  return (
    <FormAction status={isSubmittedWithErrors ? 'error' : 'default'}>
      <Grid gap="space8">
        <SectionHeading as="h2">
          {t('storageForm.actions.title')}
        </SectionHeading>

        {isSubmittedWithErrors && (
          <Paragraph color="red500">
            {t('storageForm.actions.validationErrors.required')}
          </Paragraph>
        )}

        {showContractPromo && (
          <UpsellPromo
            feature={FEATURE.CONTRACTS}
            CustomPromoMaterial={<ContractPromo />}
            shortTitle
          />
        )}

        <Button
          color="blue"
          loading={isFormSubmitting}
          type="submit"
          disabled={showContractPromo}>
          {submitButtonText}
        </Button>

        {!isOnlyApprover && (
          <ConfirmationButton
            color="red"
            confirmationTitle={t(
              'storageForm.actions.deleteConfirmationMessage'
            )}
            disabled={deleting || isFormSubmitting}
            loading={deleting}
            onConfirm={async () => {
              setDeleting(true);
              await onDeleteDocument?.();
              setDeleting(false);
            }}
            variant="secondary">
            {t('storageForm.actions.deleteDocument')}
          </ConfirmationButton>
        )}
      </Grid>
    </FormAction>
  );
};
