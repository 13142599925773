import { Text, TruncatedText } from '@candisio/design-system';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import DateFormatters from 'utils/date_formatter';
import { getRemainingDays } from 'utils/getRemainingDays';
import { remainingDaysToColor } from 'utils/remainingDaysToColor';
import { PaymentTableData } from './types';

export type DueDateWithCashDiscountCellProps = CellProps<
  PaymentTableData,
  PaymentTableData['dueDateWithCashDiscount']
>;

/** Displays color-coded payment target when a cash discount is available */
export const DueDateWithCashDiscountCell = ({
  value,
}: DueDateWithCashDiscountCellProps) => {
  const [t] = useTranslation(['payments', 'table']);
  const { documentMoneyFormatter } = useMoneyFormatter();

  if (value === undefined) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  const { discountAmount, discountPaymentDate } = value;
  const remainingDays = getRemainingDays(discountPaymentDate);

  return (
    <TruncatedText color={remainingDaysToColor(remainingDays)}>
      {t('payments:table.values.dueDateWithCashDiscount', {
        discountPaymentDate: DateFormatters.compact(discountPaymentDate),
        discountAmount: documentMoneyFormatter(discountAmount),
      })}
    </TruncatedText>
  );
};
