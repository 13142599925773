import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider/useOrganizationId';
import { Key, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { useContractCustomSubCategoryData } from './useContractCustomSubCategoryData';

export const useContractCustomSubCategoryUtils = () => {
  const organization = useOrganizationId();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [queryString, setQueryString] = useState('');
  const {
    activeCount,
    archivedCount,
    contractSubCategories,
    countLoading,
    onSortBy,
    sortBy,
    handleDebounceSearch,
  } = useContractCustomSubCategoryData({
    search: queryString,
  });

  const isArchived = searchParams.get('archived') === 'true';

  const onClickCreateCustomSubCategory = () => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}/create`,
      search: searchParams.toString(),
    });
  };

  const onSelectCustomSubCategory = (id: string) => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}/${id}`,
      search: searchParams.toString(),
    });
  };

  const onSearchFilter = (filter: string) => {
    handleDebounceSearch(filter);
    setQueryString(filter);
  };

  const onTabChanged = (key: Key) => {
    searchParams.set('archived', key === 'archived' ? 'true' : 'false');
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return {
    isArchived,
    onClickCreateCustomSubCategory,
    onSearchFilter,
    onSelectCustomSubCategory,
    onTabChanged,
    queryString,
    selectedKey: isArchived ? 'archived' : 'active',
    activeCount,
    archivedCount,
    contractSubCategories,
    countLoading,
    onSortBy,
    sortBy,
  };
};
