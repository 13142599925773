import { Checkbox, Grid, TruncatedText } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { FixedConfiguration } from './ConfigurationsMenu';

interface FixedItemsProps {
  fixedItems: FixedConfiguration[];
}

export const FixedItems = ({ fixedItems }: FixedItemsProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      as="ul"
      style={{
        listStyle: 'none',
        padding: '0px',
        paddingLeft: '17px',
      }}>
      {fixedItems.map((fixedItem, index) => (
        <Grid
          padding="space8"
          as="li"
          key={index}
          templateColumns="auto 1fr"
          aria-readonly>
          <Checkbox
            isSelected={fixedItem.isFixed}
            value={fixedItem.label}
            isDisabled>
            <TruncatedText fontSize="large" color="gray400">
              {t(fixedItem.label)}
            </TruncatedText>
          </Checkbox>
        </Grid>
      ))}
    </Grid>
  );
};
