import { useLocation, useParams } from 'react-router-dom-v5-compat';

/**
 * Custom hook to determine the visibility of various modals based on URL parameters.
 *
 * @returns {Object} An object containing boolean flags for the visibility of different modals.
 */

export const CREATE_TEAM_URL_PARAM = 'create';
export const TEAM_ID_URL_PARAM = 'teamId';
export const CC_SETUP_URL_PARAM = 'credit-card-setup';

export const useModalVisibility = () => {
  const { id, additionalSetup } = useParams<{
    id: string;
    additionalSetup: string;
  }>();

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const isCreateModalVisible = id === CREATE_TEAM_URL_PARAM;
  const isSuccessModalVisible = Boolean(urlParams.get(TEAM_ID_URL_PARAM));
  const isCCSetupModalVisible = additionalSetup === CC_SETUP_URL_PARAM;

  return {
    isCreateModalVisible,
    isSuccessModalVisible,
    isCCSetupModalVisible,
  };
};
