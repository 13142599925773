import { SwitcherProps } from 'components/Switcher/Switcher';
import { Document, DocumentStatus, Maybe } from 'generated-types/graphql.types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isOnlyApprover } from 'utils/authorization';

type DocumentType = Maybe<
  Pick<Document, 'hasAttachments' | 'hasTransactionLinked' | 'id' | 'status'>
>;

type FormState = 'approval' | 'storage';

/**
 * Handles switching between approval and storage form for the current document.
 *
 * Determines whether switcher should be shown and/or storing document should be
 * disabled.
 *
 * Switches back to approval form when we switch documents or when the document
 * has attachments or transaction.
 *
 */
export const useDocumentProcessingSwitcher = (
  document?: DocumentType,
  trackPromoClick?: () => void
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { showEcm } = useEcm();
  const currentUser = useCurrentUser();
  const canUserSeeStorageForm = !isOnlyApprover(currentUser);

  const [formType, setFormType] = useState<FormState>('approval');

  const onSwitcherClick = useCallback(
    (value: FormState) => {
      if (!showEcm && value === 'storage') trackPromoClick?.();
      setFormType(value);
    },
    [showEcm, trackPromoClick]
  );

  const { id, hasAttachments, hasTransactionLinked, status } = document ?? {};

  const [prevId, setPrevId] = useState(id);
  if (id !== prevId) {
    setPrevId(id);
  }

  const storageDisabled = Boolean(hasAttachments || hasTransactionLinked);

  const shouldShowApproval = id !== prevId || storageDisabled;

  if (formType === 'storage' && shouldShowApproval) {
    setFormType('approval');
  }

  const switcherShown = canUserSeeStorageForm && status === DocumentStatus.New;

  // This ensures that the text is properly updated when the global language setting is changed.
  // The text needs to be extracted out of the `useMemo` because the `t` function stays the same during rerendering.
  const approvalLabel = t('formSwitcher.approval');
  const storageLabel = t('formSwitcher.storage.title');
  const storageTooltip = t('formSwitcher.storage.tooltip');

  const switcherProps: SwitcherProps<'approval' | 'storage'> = useMemo(
    () => ({
      items: [
        {
          value: 'approval',
          label: approvalLabel,
        },
        {
          value: 'storage',
          label: storageLabel,
          disabled: storageDisabled,
          tooltip: storageDisabled ? storageTooltip : undefined,
          isAddon: !showEcm,
        },
      ],
      onChange: onSwitcherClick,
      value: formType,
    }),
    [
      approvalLabel,
      formType,
      onSwitcherClick,
      showEcm,
      storageDisabled,
      storageLabel,
      storageTooltip,
    ]
  );

  return {
    formType,
    setFormType,
    switcherProps,
    switcherShown,
  };
};
