import { Box, Grid } from '@candisio/design-system';
import {
  StickyCardRefProvider,
  useStickyCardRefContext,
} from 'providers/StickyCardRefProvider';
import { ReactNode } from 'react';
import { BookingsFormContextProvider } from 'views/DocumentDetails/BookingsFormContext';
import { BoxWrapper } from './styles';

export const DetailsLayout = ({
  children,
  leftSection,
  middleSection,
  rightSection,
}: {
  children?: ReactNode;
  leftSection: ReactNode;
  middleSection: ReactNode;
  rightSection: ReactNode;
}) => {
  const cardRef = useStickyCardRefContext();

  return (
    <Grid
      // @ts-ignore
      style={{ containerType: 'size' }}
      height="100%"
      paddingBottom={0}
      paddingRight={0}
      paddingLeft="space16"
      maxWidth="100%"
      overflow="hidden"
      alignItems="start"
      gap="space16"
      templateColumns="1fr 2fr 1fr">
      <BookingsFormContextProvider>
        <Box overflow="hidden" height="100%">
          {leftSection}
        </Box>
        {middleSection}
        <StickyCardRefProvider>
          <BoxWrapper
            overflow="hidden"
            height="100%"
            $scrollMargin={cardRef?.current?.offsetHeight}>
            {rightSection}
          </BoxWrapper>
        </StickyCardRefProvider>
        {children}
      </BookingsFormContextProvider>
    </Grid>
  );
};
