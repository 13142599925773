import {
  Grid,
  Paragraph,
  mergeProps,
  useTooltip,
} from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import {
  useCountDocumentsProcessing,
  useCountTransactionsAndDocumentsInbox,
} from 'hooks/useCounters';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEcmSensitiveDocumentsCount } from 'views/Inbox/EcmSensitiveDocuments/useGetEcmSensitiveDocumentsCount';
import { InboxTooltip } from './Items/Inbox/InboxTooltip';
import { MenuLinkItem, useMenuBottomLinks, useMenuTopLinks } from './menuLinks';
import { MenuItem, MenuLink } from './style';

export type NavigationProps = RouteComponentProps<{ organizationSlug: string }>;

const CreateMenuItem = ({
  title,
  url,
  itemProps,
  itemWithCounter,
  allowedRoles,
  prefix,
  onMenuLinkClick,
}: MenuLinkItem & {
  prefix: string;
}) => {
  const [t] = useTranslation();
  const creditCardsSetup = useCreditCardsSetup();
  const organization = useFullOrganization();

  const { count: processingDocuments, loading: loadingProcessing } =
    useCountDocumentsProcessing({ fetchPolicy: 'cache-first' });

  const { transactionsCount } = useCountTransactionsAndDocumentsInbox({
    processingDocuments,
    loadingProcessing,
    featureFlag: creditCardsSetup.isAddonBought,
  });

  const { sensitiveDocumentsCount } = useEcmSensitiveDocumentsCount();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef, close } =
    useTooltip({
      placement: 'right',
      passiveTrigger: true,
    });

  const { showEcmSensitiveDocuments, canAccessEcm } = useEcm();

  const onLinkClicked = () => {
    close();
  };

  const menuItemProps =
    url === Routes.INBOX ? mergeProps(itemProps, triggerProps) : itemProps;

  const menuItemRef = url === Routes.INBOX ? triggerRef : undefined;

  const { isAdmin, isApprover } = useUserRoles();
  const isApprovals = url === Routes.APPROVALS;
  const currentUser = useCurrentUser();

  let targetRoute = `/${prefix}${url}`;

  // the approver id needs to be appended to the url for the default table filtering to work
  if (isApprovals && (isAdmin || isApprover)) {
    targetRoute = `${targetRoute}?approvers=${currentUser?.id}`;
  }

  return (
    <UserRoleAccessWrapper key={title} allowedRoles={allowedRoles}>
      <MenuItem {...menuItemProps} ref={menuItemRef}>
        <MenuLink
          aria-label={t(title)}
          to={targetRoute}
          activeClassName="nav-item-active"
          onClick={onMenuLinkClick}>
          {itemWithCounter}
          <Paragraph fontSize={url === Routes.DASHBOARD ? 0 : 'inherit'}>
            {t(title)}
          </Paragraph>
        </MenuLink>
      </MenuItem>
      {isOpen &&
        (creditCardsSetup.isInUse ||
          (showEcmSensitiveDocuments && canAccessEcm)) && (
          <InboxTooltip
            onLinkClicked={onLinkClicked}
            tooltipProps={tooltipProps}
            documentCount={processingDocuments}
            transactionCount={transactionsCount}
            sensitiveDocumentsCount={sensitiveDocumentsCount}
            organizationSlug={organization?.id}
            ref={tooltipRef}
          />
        )}
    </UserRoleAccessWrapper>
  );
};

export const Navigation = withRouter<
  NavigationProps,
  // eslint-disable-next-line @typescript-eslint/ban-types
  FunctionComponent<NavigationProps>
>(
  ({
    match: {
      params: { organizationSlug },
    },
  }) => {
    const organization = useFullOrganization();
    const menuBottomLinks = useMenuBottomLinks();
    const menuTopLinks = useMenuTopLinks();

    const visibleMenuTopLinks = menuTopLinks.filter(
      link =>
        link.showMenuItem !== false &&
        (link.url !== Routes.PAYMENTS ||
          (link.url === Routes.PAYMENTS && organization?.payment.isActive))
    );

    const visibleMenuBottomLinks = menuBottomLinks.filter(
      item => item.showMenuItem !== false
    );

    // use smaller gap when the height is <= 872px
    const RESPONSIVE_ITEM_VERTICAL_GAP = useMediaQuery({
      maxHeight: 872,
    })
      ? 'space24'
      : 'space32';

    return (
      <Grid
        alignContent="space-between"
        as="nav"
        data-cy="main-nav"
        gap={RESPONSIVE_ITEM_VERTICAL_GAP}
        height="100%"
        paddingTop="space12"
        justifyItems="center"
        width="100%"
        overflowY="auto">
        <Grid
          as="ul"
          listStyle="none"
          autoFlow="row"
          alignItems="center"
          gap={RESPONSIVE_ITEM_VERTICAL_GAP}
          padding={0}
          lineHeight={1.2}>
          {visibleMenuTopLinks.map(
            item =>
              item.itemWithCounter && (
                <CreateMenuItem
                  {...item}
                  prefix={organizationSlug}
                  key={item.title}
                />
              )
          )}
        </Grid>

        <Grid
          as="ul"
          listStyle="none"
          autoFlow="row"
          alignItems="center"
          gap={RESPONSIVE_ITEM_VERTICAL_GAP}
          padding={0}>
          {visibleMenuBottomLinks.map(
            item =>
              item.itemWithCounter && (
                <CreateMenuItem
                  {...item}
                  prefix={organizationSlug}
                  key={item.title}
                />
              )
          )}
        </Grid>
      </Grid>
    );
  }
);
