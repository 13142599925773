import { InfoPanel as InfoPanelDS, Link } from '@candisio/design-system';
import { ReactElement } from 'react';

export const InfoPanel = ({
  message,
  linkText,
  onClick,
}: {
  message: ReactElement;
  linkText: string;
  onClick?: () => void;
}) => {
  return (
    <InfoPanelDS variant="promo">
      {message}
      <br />
      <Link as="button" color="purple" onClick={onClick}>
        {linkText}
      </Link>
    </InfoPanelDS>
  );
};
