import { ErrorMessages } from 'utils/zodFormValidation';
import { teamDetailsFormSchema } from './teamDetailsFormSchema';

export const teamDetailsFormErrorMessages: ErrorMessages<
  ReturnType<typeof teamDetailsFormSchema>
> = {
  teamAdmin: {
    label: 'teams.createTeam.teamDetails.fields.teamAdmin.label',
  },
  teamName: {
    label: 'teams.createTeam.teamDetails.fields.teamName.label',
    custom: {
      translationKey: 'teams.createTeam.teamDetails.fields.teamName.takenError',
    },
  },
  teamMembers: {
    label: 'teams.createTeam.teamMembers.field.label',
    too_small: {
      translationKey: 'teams.createTeam.teamMembers.field.tooSmallError',
    },
    custom: {
      translationKey: 'teams.createTeam.teamMembers.field.sameAsAdminError',
    },
  },
};
