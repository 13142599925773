import { BuiltinRoleName } from 'generated-types/graphql.types';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

type ExcludeKeys = 'allAppliedRoles.0.id' | 'allAppliedRoles.0.name';

export interface RolesFormSchemaOptions {
  /** Fields that are hidden due to the organization using SSO should not be validated */
  hiddenFields?: {
    roles?: boolean;
  };
}

export const rolesFormSchema = ({ hiddenFields }: RolesFormSchemaOptions) => {
  return z.object({
    allAppliedRoles: z
      .array(z.object({ id: z.string(), name: z.string() }))
      .min(hiddenFields?.roles ? 0 : 1)
      .superRefine((value, context) => {
        const roleNamesSelected = value.map(role => role.name);
        const cardManagerWithoutAdmin =
          roleNamesSelected.includes(BuiltinRoleName.CardManager) &&
          !roleNamesSelected.includes(BuiltinRoleName.Admin);

        if (cardManagerWithoutAdmin) {
          context.addIssue({
            code: 'custom',
            params: {
              translationKey: 'settings.teamMembers.errors.rolesCardManager',
            },
          });
        }
      }),
  });
};

export const rolesFormErrorMessages: ErrorMessages<
  ReturnType<typeof rolesFormSchema>,
  ExcludeKeys
> = {
  allAppliedRoles: {
    label: 'settings.teamMembers.form.roles',
    too_small: {
      translationKey: 'settings.teamMembers.errors.roles',
    },
  },
};

export type RolesFormOutput = z.infer<ReturnType<typeof rolesFormSchema>>;
export type RolesFormValues = Partial<RolesFormOutput>;
