import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import DateFormatters from 'utils/date_formatter';
import { EcmDocumentsTableData } from '../types';

type Value = Date | undefined;

export const DateCell = ({
  value,
}: CellProps<EcmDocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return (
    <TruncatedText wordBreak="break-all">
      {DateFormatters.compact(value)}
    </TruncatedText>
  );
};
