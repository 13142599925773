import { TypePolicies, FieldPolicy } from '@apollo/client';
import { InAppNotificationPaginationResult } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const inAppNotificationRecords: FieldPolicy<InAppNotificationPaginationResult> =
  {
    keyArgs: ['filters', 'dateRangeFilters', 'sortBy'],
    merge: simplePaginationMergeWithoutDuplicates(
      'InAppNotificationPaginationResult'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      inAppNotifications: inAppNotificationRecords,
    },
  },
};
