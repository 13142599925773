import { get } from 'lodash';
import { FieldPath } from 'react-hook-form';
import {
  StorageFormFieldMetadata,
  useStorageFormMetadata,
} from './StorageFormMetadataContext';
import { StorageFormValues } from './storageFormSchema';

/** Returns field metadata for the passed field */
export const useStorageFormFieldMetadata = (
  name: FieldPath<StorageFormValues>
) => {
  const metadata = useStorageFormMetadata();

  const fieldMetadata = get(metadata, name) as
    | StorageFormFieldMetadata
    | undefined;

  return fieldMetadata;
};
