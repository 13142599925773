import { noop } from 'lodash';
import { createContext, useContext } from 'react';
import { FieldPath } from 'react-hook-form';
import { StorageFormFieldMetadata } from './StorageFormMetadataContext';
import { StorageFormValues } from './storageFormSchema';

export type StorageFormSetFieldMetadata = (
  path: FieldPath<StorageFormValues>,
  meta?: StorageFormFieldMetadata
) => void;

export const StorageFormSetMetadataContext =
  createContext<StorageFormSetFieldMetadata>(noop);

/** Returns function to set individual processing form field metadata */
export const useSetStorageFormMetadata = () => {
  return useContext(StorageFormSetMetadataContext);
};
