import { Button, Flex, Grid, Spinner } from '@candisio/design-system';
import { AnimatePresence, motion } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const MotionButton = motion(Button);
const MotionFlex = motion(Flex);
const MotionGrid = motion(Grid);

const elementHide = {
  visibility: 'hidden',
  width: 0,
  opacity: 0,
  transition: { duration: 0.2, delay: 0.1 },
};

const boxShow = {
  visibility: 'visible',
  width: 'inherit',
  opacity: 1,
  transition: { duration: 0.3 },
};

const buttonHide = {
  opacity: 0,
  scale: 0.8,
  transition: { duration: 0.2 },
};

const buttonShow = {
  opacity: 1,
  scale: 1,
  transition: { duration: 0.2 },
};

interface CTAButtonsProps {
  isFormDirty: boolean;
  resetField: () => void;
  isLoading?: boolean;
}

export const QuickSaveButtons = ({
  isFormDirty,
  resetField,
  isLoading = false,
}: CTAButtonsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <AnimatePresence>
      {isFormDirty && (
        <MotionFlex
          initial={elementHide}
          animate={boxShow}
          exit={elementHide}
          gap="space8">
          {!isLoading ? (
            <MotionButton
              initial={buttonHide}
              animate={buttonShow}
              icon="check"
              type="submit"
              color="green"
              variant="tertiary"
              label={t('organisation.actions.save')}
            />
          ) : (
            <MotionGrid
              animate={buttonShow}
              width="space36"
              height="space36"
              alignItems="center"
              justifyContent="center"
              borderRadius="full">
              <Spinner size="space20" />
            </MotionGrid>
          )}
          {!isLoading && (
            <MotionButton
              initial={buttonHide}
              animate={buttonShow}
              // exit={elementHide}
              width="36px"
              height="36px"
              type="reset"
              color="red"
              variant="tertiary"
              onClick={resetField}
              icon="close"
              label={t('organisation.actions.cancel')}
            />
          )}
        </MotionFlex>
      )}
    </AnimatePresence>
  );
};
