import { Box, Text, Flex, useTooltip, Tooltip } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { amountFormat } from 'utils/format';

interface VerticalBarProps {
  percentage: number;
  amount: number;
  currency: DocumentCurrency | undefined;
  onClick: () => void;
}

const MotionBox = motion(Box);

const minHeight = 4.5;

const formatter = Intl.NumberFormat('en', { notation: 'compact' });

export const VerticalBar = ({
  percentage,
  amount,
  currency,
  onClick,
}: VerticalBarProps) => {
  const formattedNumber = formatter.format(amount);

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  if (amount === 0) {
    return (
      <MotionBox
        initial={{ height: 0 }}
        animate={{ height: `${minHeight}%` }}
        height="2.5%"
        background="gray400"
        justifySelf="center"
        borderRadius={12}
        width="space12"
      />
    );
  }

  return (
    <Flex
      height="100%"
      justifyContent="end"
      direction="column"
      alignItems="center">
      <Text
        {...triggerProps}
        ref={triggerRef}
        textAlign="center"
        fontSize="basic">
        {formattedNumber}
      </Text>
      <MotionBox
        onClick={onClick}
        initial={{ height: 0 }}
        animate={{ height: `${percentage + minHeight}%` }}
        height={`${percentage + minHeight}%`}
        style={{ cursor: 'pointer' }}
        transition={{ duration: 1.5 }}
        background="blue400"
        justifySelf="center"
        borderRadius={12}
        width="space12"
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {amountFormat(amount, currency)}
        </Tooltip>
      )}
    </Flex>
  );
};
