import { useState } from 'react';

const getItem = (key: string) =>
  document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];

    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');

const setItem = (key: string, value: string, numberOfDays: number) => {
  const now = new Date();

  // set the cookie expiry date to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);

  document.cookie = `${key}=${value};expires=${now.toUTCString()};path=/`;
};

/**
 *
 * @param {String} key The key to store data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
export const useCookie = (key: string, defaultValue: string) => {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value: string, numberOfDays: number) => {
    setCookie(value);
    setItem(key, value, numberOfDays);
  };

  // hook returns cookie value and the function to set or update the cookie
  return [cookie, updateCookie] as const;
};
