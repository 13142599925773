import { gql } from '@apollo/client';
import { creditCardByIdDataFragment } from '../components/CreditCardsSection/gql';

export const activateCardMutation = gql`
  mutation activateCard($input: ActivateCardInput!) {
    activateCard(input: $input)
  }
`;

export const lockUnlockCardMutation = gql`
  mutation lockUnlockCard($input: CardIssuerLockUnlockCardInput!) {
    lockUnlockCard(input: $input)
  }
`;

export const getCardIssuerCardUniqueTokenByIdQuery = gql`
  query getCardIssuerCardUniqueTokenById($id: ID!) {
    getCardIssuerCardById(id: $id) {
      id
      token
      uniqueToken
    }
  }
`;

export const cardCredentialQuery = gql`
  query cardCredentialByCardId($cardId: ID!) {
    getCardIssuerCardCredential(id: $cardId) {
      __typename

      ... on CardIssuerCardCredential {
        token
        uniqueToken
      }

      ... on RecentAuthnRequiredError {
        requiredAuthType
        validForSeconds
      }
    }
  }
`;

export const getPINForCardQuery = gql`
  query getPINForCard($cardId: String!) {
    getPINForCard(cardId: $cardId)
  }
`;

export const cardIssuerCardsForCardholderQuery = gql`
  query cardIssuerCardsForCardholder($cursor: String) {
    prevCard: getCardIssuerCardsForCardholder(
      input: { limit: 1, before: $cursor }
    ) {
      edges {
        cursor
        node {
          id
        }
      }
    }
    nextCard: getCardIssuerCardsForCardholder(
      input: { limit: 1, after: $cursor }
    ) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const acceptCardUsageAgreementMutation = gql`
  mutation acceptCardUsageAgreement(
    $input: CardIssuerCardUsageAgreementInput!
  ) {
    acceptCardUsageAgreement(input: $input) {
      id
    }
  }
`;

export const getCardPendingActionsQuery = gql`
  query getCardPendingActions($id: ID!) {
    getCardIssuerCardById(id: $id) {
      id
      pendingActions {
        id
        name
        createdAt
      }
    }
  }
`;

export const cardSubscription = gql`
  subscription card($input: CardSubscriptionInput) {
    card(input: $input) {
      ...creditCardByIdData
    }
  }
  ${creditCardByIdDataFragment}
`;
