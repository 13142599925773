import { PermissionForMembershipDtoIdEnum } from '@candisio/iam-sdk';
import { useFullOrganization } from 'providers/OrganizationProvider';

export type PermissionsForMembership = Partial<
  Record<PermissionForMembershipDtoIdEnum, boolean>
>;

/**
 * Custom hook to retrieve user permissions for membership based on organization.
 * @returns {PermissionsForMembership} An object containing user permissions.
 */
export const usePermissionsForMembership = (): PermissionsForMembership => {
  const organization = useFullOrganization();

  const permissionsForMembership = (organization?.permissionsForMembership ??
    []) as PermissionForMembershipDtoIdEnum[];

  const permissions = permissionsForMembership.reduce<PermissionsForMembership>(
    (acc, value) => {
      acc[value] = true;

      return acc;
    },
    {}
  );

  return permissions;
};
