import { nohistoryData } from 'components/Lottie/Lottie';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { Trans } from 'providers/LocaleProvider';
import { EmptyState } from './EmptyState';

interface EmptyDataStateProps {
  /** translation string must include JSON file prefix i.e. settings: , otherwise common: will be use by default https://candis.atlassian.net/wiki/spaces/FG/pages/1247281159/Frontend+Code+Guidelines#i18next */
  translation: string;
  /** link to open route using RouterLink component */
  url?: string;
}

export const EmptyDataState = ({ translation, url }: EmptyDataStateProps) => {
  return (
    <EmptyState animationData={nohistoryData}>
      <Trans i18nKey={translation}>
        {translation}
        {url ? <RouterLink to={url} /> : null}
      </Trans>
    </EmptyState>
  );
};
