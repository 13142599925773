import { Heading } from '@candisio/design-system';

import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  Locale,
  useCancelInvitationMutation,
  useResendInvitationMutation,
} from 'generated-types/graphql.types';
import { useSSO } from 'orgConfig/sso/sso';
import { useTeams } from 'orgConfig/teams/useTeams';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { organizationMembershipCountByStatusQuery } from 'views/Settings/TeamMembers/queries';
import { isSuccess } from 'views/Settings/TeamMembers/responses';
import { useUpdateMembership } from 'views/Settings/TeamMembers/useUpdateMembership';
import { useGetTeams } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { RolesStep } from '../../invite/InviteNew/RolesStep/RolesStep';
import { RolesFormOutput } from '../../invite/InviteNew/RolesStep/utils';
import { teamMemberFormErrorMessages } from '../../teamMemberFormErrorMessages';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
  teamMemberFormSchema,
} from '../../teamMemberFormSchema';
import { PendingTeamMemberForm } from './PendingTeamMemberForm';
import { PendingTeamMemberFormFooter } from './PendingTeamMemberFormFooter';

type Props = {
  closeDrawer: () => void;
  loading?: boolean;
  organizationLanguage: Locale | null;
  selectedUser: User;
};

const PENDING_TEAM_MEMBER_FORM_ID = 'pending-team-member';

export const PendingFormContainer = ({
  closeDrawer,
  loading,
  selectedUser,
}: Props) => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();
  const { isTeamsFeatureEnabled } = useTeams();

  const [step, setStep] = useState<'teamMemberDetails' | 'teamMemberRoles'>(
    'teamMemberDetails'
  );

  const sso = useSSO();

  const {
    updateUser,
    mutationProps: { loading: isSubmittingUpdateMembership },
  } = useUpdateMembership();

  const [resendInvitation, { loading: isSubmittingResendInvite }] =
    useResendInvitationMutation();

  const [cancelInvitation, { loading: isSubmittingCancelInvite }] =
    useCancelInvitationMutation();

  const { teams, loading: isLoadingTeams } = useGetTeams({
    membershipIds: [selectedUser.id],
  });

  const handleSubmit = useCallback(
    async (values: TeamMemberFormOutput) => {
      try {
        const updateMembershipResult = await updateUser(selectedUser.id, {
          firstName: values.firstName,
          lastName: values.lastName,
          roles: values.roles,
          locale: values.locale,
        });

        if (isSuccess(updateMembershipResult)) {
          // update success
          success(t('settings.teamMembers.actions.userUpdated'));

          closeDrawer();
        }
      } catch (e) {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        error(t(`${(e as Error).message}`));

        return e;
      }
    },
    [closeDrawer, error, selectedUser.id, success, t, updateUser]
  );

  const handleResetInvite = useCallback(async () => {
    try {
      if (!selectedUser) {
        return;
      }

      await resendInvitation({
        variables: { membershipId: selectedUser.id },
        refetchQueries: ['organizationMemberships'],
      });

      info(t('settings.teamMembers.actions.resendSuccess'));
      closeDrawer();
    } catch (e) {
      // eslint-disable-next-line candis/no-template-strings-inside-translation
      error(t(`${(e as Error).message}`));
    }
  }, [selectedUser, resendInvitation, info, t, closeDrawer, error]);

  const handleCancelInvite = useCallback(async () => {
    try {
      if (!selectedUser) {
        return;
      }

      await cancelInvitation({
        variables: { id: selectedUser.id },
        refetchQueries: [
          'organizationMemberships',
          { query: organizationMembershipCountByStatusQuery },
        ],
      });

      success(t('settings.teamMembers.actions.cancelInviteSuccess'));

      closeDrawer();
    } catch (e) {
      // eslint-disable-next-line candis/no-template-strings-inside-translation
      error(t(`${(e as Error).message}`));
    }
  }, [selectedUser, cancelInvitation, success, t, closeDrawer, error]);

  const userRoles = (selectedUser.membershipRoles ?? []).map(role => ({
    id: role.id,
    name: role.name,
  }));

  const initialValues: TeamMemberFormOutput = {
    firstName: selectedUser.firstName,
    lastName: selectedUser.lastName,
    locale: selectedUser.locale ?? Locale.De,
    email: selectedUser.email,
    roles: userRoles,
  };

  const hide = {
    roles: sso.provides.roles,
    firstName: sso.provides.firstName,
    lastName: sso.provides.lastName,
  };

  const context: TeamMemberFormSchemaOptions = {
    hiddenFields: hide,
  };

  const form = useForm<TeamMemberFormOutput>({
    context,
    defaultValues: initialValues,
    mode: 'onBlur',
    resolver: zodResolver({
      zodSchema: teamMemberFormSchema,
      errorMessages: teamMemberFormErrorMessages,
    }),
  });

  const rolesValue = form.getValues('roles');
  const rolesFormDefaultValues: RolesFormOutput = {
    allAppliedRoles: rolesValue,
  };

  const handleSubmitRoles = (formData: RolesFormOutput) => {
    form.setValue('roles', formData.allAppliedRoles, {
      // It’s important to set shouldDirty to true, so that later we can tell
      // that the field has been changed
      shouldDirty: true,
      // We trigger validation to clear any existing error that the field
      // might have
      shouldValidate: true,
    });
  };

  if (step === 'teamMemberRoles') {
    return (
      <RolesStep
        defaultValues={rolesFormDefaultValues}
        onGoBack={() => setStep('teamMemberDetails')}
        onSubmit={handleSubmitRoles}
      />
    );
  }

  const isFormLoading = loading || isLoadingTeams;

  return (
    <FormProvider {...form}>
      <DrawerLayout
        onClose={closeDrawer}
        header={
          <Heading as="h3">
            {isTeamsFeatureEnabled
              ? t('settings.users.details.tabs.edit')
              : t('settings.teamMembers.details.tabs.edit')}
          </Heading>
        }
        footer={
          <PendingTeamMemberFormFooter
            formId={PENDING_TEAM_MEMBER_FORM_ID}
            loading={isSubmittingUpdateMembership}
            disabled={
              isFormLoading ||
              isSubmittingCancelInvite ||
              isSubmittingResendInvite ||
              isSubmittingUpdateMembership
            }
          />
        }>
        <PendingTeamMemberForm
          isSubmittingCancelInvite={isSubmittingCancelInvite}
          isSubmittingResendInvite={isSubmittingResendInvite}
          isSubmittingUpdateUser={isSubmittingUpdateMembership}
          loading={isFormLoading}
          onSubmit={handleSubmit}
          onShowRolesStep={() => setStep('teamMemberRoles')}
          onResendInvite={handleResetInvite}
          onCancelInvite={handleCancelInvite}
          selectedUser={selectedUser}
          formId={PENDING_TEAM_MEMBER_FORM_ID}
          hide={{
            roles: sso.provides.roles,
            firstName: sso.provides.firstName,
            lastName: sso.provides.lastName,
          }}
          teams={teams}
        />
      </DrawerLayout>
    </FormProvider>
  );
};
