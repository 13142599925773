import { FieldContainerProps } from '@candisio/design-system';
import { FieldPath } from 'react-hook-form';
import { ReadOnlyFormFieldMetadataMessage } from '../ReadOnlyFormFieldMetadataMessage';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';
import { useReadOnlyTransactionFormMetaData } from './useReadOnlyTransactionFormFieldsProps';

export const useReadOnlyTransactionFormField = (
  name: FieldPath<ReadOnlyTransactionFormValues>
) => {
  const fieldMetaData = useReadOnlyTransactionFormMetaData(name);

  const message = fieldMetaData ? (
    <ReadOnlyFormFieldMetadataMessage {...fieldMetaData} />
  ) : undefined;

  const variant: FieldContainerProps['variant'] | undefined = fieldMetaData
    ? 'success'
    : undefined;

  return {
    fieldProps: {
      message,
      variant,
    },
  };
};
