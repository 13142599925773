import { Card, Flex, Icon, TruncatedText } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';

interface PendingRequestsProps {
  creditCardRequests: number;
  limitRequests: number;
}

export const PendingRequests = ({
  creditCardRequests,
  limitRequests,
}: PendingRequestsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const organizationSlug = useOrganizationId();
  const { isCardManager } = useUserRoles();

  const handleClick = () => {
    if (!isCardManager) {
      return;
    }

    const linkToCardRequests = `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`;

    navigate(linkToCardRequests);
  };

  return (
    <Card
      background="gray50"
      border="1px solid gray250"
      paddingY="space8"
      paddingX="space12"
      onClick={handleClick}
      minWidth="240px"
      hover={{ background: isCardManager ? 'gray200' : 'none' }}
      style={{ cursor: isCardManager ? 'pointer' : 'default' }}>
      <Flex gap="space16">
        {creditCardRequests > 0 && (
          <Flex alignItems="start" direction="column">
            <Flex alignItems="center" gap="space4">
              <Icon icon="creditCardOutline" color="yellow700" size="space14" />
              <TruncatedText color="yellow700" fontSize="xlarge">
                {String(creditCardRequests)}
              </TruncatedText>
            </Flex>
            <TruncatedText color="yellow700" fontSize="basic">
              {t('insights.requestsOverview.cardRequests', {
                count: creditCardRequests,
              })}
            </TruncatedText>
          </Flex>
        )}
        {limitRequests > 0 && (
          <Flex alignItems="start" direction="column">
            <Flex alignItems="center" gap="space4">
              <Icon icon="edit" color="yellow700" size="space14" />
              <TruncatedText color="yellow700" fontSize="xlarge">
                {String(limitRequests)}
              </TruncatedText>
            </Flex>
            <TruncatedText color="yellow700" fontSize="basic">
              {t('insights.requestsOverview.limitRequests', {
                count: limitRequests,
              })}
            </TruncatedText>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
