import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { CommentView } from 'components/DocumentHistory/styles';
import { TransactionCommentEventDataFragment } from 'generated-types/graphql.types';

export const Comment = ({
  comment,
  clientInfo,
}: TransactionCommentEventDataFragment) => {
  return (
    <TimelineEventWithMobileIndication
      iconName="mobileComment"
      clientName={clientInfo?.name}
      eventComponent={<CommentView>{comment?.text}</CommentView>}
    />
  );
};
