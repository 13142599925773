import { Text } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import { nocontactData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const DocumentTagsEmptyDataState = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <EmptyState animationData={nocontactData}>
      <Text>{t('documentTags.noData.message')}</Text>
    </EmptyState>
  );
};
