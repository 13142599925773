import { defaultTheme } from '@candisio/design-system';
import { SvgIcon, SvgProps } from '../SvgIcon';

export const AddisonXmlGreyscale = ({
  fill = defaultTheme.colors.gray800,
  ...props
}: SvgProps) => {
  return (
    <SvgIcon {...props} fill={fill}>
      <path
        d="M15.1016 62.312L15.1016 3.59497L48.6542 3.59497L63.0338 18.2742L63.0338 62.312L15.1016 62.312Z"
        fill="#EFEEEE"
      />
      <path
        d="M48.6543 17.9746L48.6543 3.59492L63.034 17.9746L48.6543 17.9746Z"
        fill="#ABABAB"
      />
      <path
        d="M7.91113 42.6533V55.608H37.8688V42.6533H7.91113Z"
        fill="#AEAEAE"
      />
      <path
        d="M18.7153 52.1392H17.0582L15.4667 49.5506L13.8752 52.1392H12.3212L14.5914 48.6098L12.4663 45.2957H14.0671L15.5416 47.7578L16.988 45.2957H18.5514L16.4029 48.6893L18.7153 52.1392ZM22.6128 52.1392L20.9651 46.7702H20.923C20.9823 47.8624 21.012 48.591 21.012 48.9562V52.1392H19.7154V45.2957H21.6907L23.3103 50.5289H23.3384L25.0562 45.2957H27.0316V52.1392H25.6788V48.9C25.6788 48.7471 25.6804 48.5708 25.6835 48.371C25.6897 48.1713 25.7116 47.6408 25.749 46.7795H25.7069L23.9422 52.1392H22.6128ZM28.8976 52.1392V45.2957H30.3487V50.9409H33.1245V52.1392H28.8976Z"
        fill="white"
      />
      <path
        d="M25.6903 10.7863C23.3964 10.2216 20.9999 10.2216 18.7061 10.7863V14.7266H25.6903V10.7863Z"
        fill="#D5D5D5"
      />
      <path
        d="M25.6903 21.6748H18.7061V28.659H25.6903V21.6748Z"
        fill="#A4A4A4"
      />
      <path
        d="M25.6903 28.6543H18.7061V35.6385H25.6903V28.6543Z"
        fill="#CDCDCD"
      />
      <path
        d="M18.7061 39.5404C20.9999 40.1051 23.3964 40.1051 25.6903 39.5404V35.6001H18.7061V39.5404Z"
        fill="#B7B7B7"
      />
      <path
        d="M25.6903 14.6978H18.7061V21.682H25.6903V14.6978Z"
        fill="#E0E0E0"
      />
      <path
        d="M18.7156 21.6748H11.7314V28.659H18.7156V21.6748Z"
        fill="#E0E0E0"
      />
      <path
        d="M18.7156 28.6543H11.7314V35.6385H18.7156V28.6543Z"
        fill="#D4CECE"
      />
      <path
        d="M11.7217 35.6304C13.642 37.5625 16.0591 38.9262 18.7059 39.5707V35.6304H11.7217Z"
        fill="#A1A1A1"
      />
      <path
        d="M18.7156 14.6978H11.7314V21.682H18.7156V14.6978Z"
        fill="#CDCDCD"
      />
      <path
        d="M18.7059 10.7871C16.0591 11.4316 13.642 12.7953 11.7217 14.7274H18.7059V10.7871Z"
        fill="#B7B7B7"
      />
      <path
        d="M11.7225 35.6287V28.6543H7.78223C8.42843 31.2975 9.79198 33.711 11.7225 35.6287V35.6287Z"
        fill="#A1A1A1"
      />
      <path
        d="M11.7225 14.6885C9.79041 16.6088 8.42675 19.0259 7.78223 21.6727H11.7225V14.6885Z"
        fill="#B7B7B7"
      />
      <path
        d="M32.6746 21.6748H25.6904V28.659H32.6746V21.6748Z"
        fill="#CDCDCD"
      />
      <path
        d="M32.6746 28.6543H25.6904V35.6385H32.6746V28.6543Z"
        fill="#D4CECE"
      />
      <path
        d="M32.6648 35.6304H25.6904V39.5707C28.3337 38.9245 30.7472 37.5609 32.6648 35.6304Z"
        fill="#A1A1A1"
      />
      <path
        d="M32.6746 14.6978H25.6904V21.682H32.6746V14.6978Z"
        fill="#D4CECE"
      />
      <path
        d="M25.6904 10.7871V14.7274H32.6648C30.7472 12.7969 28.3337 11.4333 25.6904 10.7871V10.7871Z"
        fill="#A1A1A1"
      />
      <path
        d="M32.665 35.6287C34.5956 33.711 35.9592 31.2975 36.6054 28.6543H32.665V35.6287Z"
        fill="#A1A1A1"
      />
      <path
        d="M32.665 14.6885V21.6727H36.6054C35.9608 19.0259 34.5972 16.6088 32.665 14.6885V14.6885Z"
        fill="#A1A1A1"
      />
      <path
        d="M7.82098 21.6748C7.25629 23.9686 7.25629 26.3652 7.82098 28.659H11.7613V21.6748H7.82098Z"
        fill="#D5D5D5"
      />
      <path
        d="M36.5741 28.659C37.1388 26.3652 37.1388 23.9686 36.5741 21.6748H32.6338V28.659H36.5741Z"
        fill="#B7B7B7"
      />
      <path
        d="M38.9795 32.3789H39.9646C40.1421 32.3708 40.3195 32.3975 40.4867 32.4577C40.669 32.4986 40.8314 32.6015 40.9464 32.7488C41.0613 32.8961 41.1217 33.0786 41.1171 33.2654C41.1149 33.4803 41.0365 33.6874 40.8957 33.8498C40.755 34.0122 40.5611 34.1193 40.3488 34.152C40.7635 34.5737 41.0974 35.0678 41.3338 35.6099H40.5753C40.1517 34.8416 39.8956 34.3293 39.7577 34.3195H39.6493V35.6099H38.9893L38.9795 32.3789ZM39.7971 33.8171C40.132 33.8171 40.4276 33.6792 40.4276 33.3442C40.4382 33.2589 40.4184 33.1725 40.3715 33.1004C40.3246 33.0283 40.2538 32.9751 40.1714 32.9502C40.0541 32.911 39.9305 32.8943 39.807 32.9009H39.6395V33.8171H39.7971Z"
        fill="#B7B7B7"
      />
      <path
        d="M43.0277 34.0523C43.0296 34.6489 42.8545 35.2327 42.5245 35.7297C42.1945 36.2267 41.7244 36.6146 41.1737 36.8442C40.6231 37.0739 40.0167 37.135 39.4313 37.0198C38.846 36.9046 38.3079 36.6183 37.8854 36.1971C37.4628 35.776 37.1748 35.2389 37.0577 34.6539C36.9406 34.0689 36.9997 33.4623 37.2275 32.9109C37.4554 32.3596 37.8417 31.8882 38.3377 31.5566C38.8336 31.2249 39.4168 31.0479 40.0134 31.0479C40.8111 31.0478 41.5763 31.3641 42.1413 31.9272C42.7064 32.4904 43.0251 33.2546 43.0277 34.0523V34.0523ZM40.0134 31.6685C39.5419 31.6685 39.081 31.8083 38.6889 32.0702C38.2969 32.3322 37.9914 32.7045 37.8109 33.1401C37.6305 33.5757 37.5833 34.055 37.6753 34.5174C37.7672 34.9798 37.9943 35.4046 38.3277 35.738C38.6611 36.0714 39.0859 36.2984 39.5483 36.3904C40.0107 36.4824 40.49 36.4352 40.9256 36.2548C41.3612 36.0743 41.7335 35.7688 41.9955 35.3768C42.2574 34.9847 42.3972 34.5238 42.3972 34.0523C42.3972 33.7393 42.3356 33.4293 42.2158 33.1401C42.096 32.8508 41.9204 32.588 41.699 32.3667C41.4777 32.1453 41.2149 31.9697 40.9256 31.8499C40.6364 31.7301 40.3264 31.6685 40.0134 31.6685V31.6685Z"
        fill="#B7B7B7"
      />
    </SvgIcon>
  );
};
