import { useApproveSingleUseVirtualCardRequestMutation } from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { ModifiedLimitDetails } from '../../types';

interface UseApproveSingleUseCardRequestParams {
  cardId: string;
}

export const useApproveSingleUseCardRequest = ({
  cardId,
}: UseApproveSingleUseCardRequestParams) => {
  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  const [approveSingleUseCardRequest, { loading }] =
    useApproveSingleUseVirtualCardRequestMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        ...refetchQueries.cardIssuerCards(cardId),
        ...refetchQueries.cardIssuerCardsCardManager(),
      ],
      onCompleted() {
        evictPaginationResults();
      },
    });

  return {
    approveSingleUseCard: async (
      cardRequestId: string,
      updatedCardDetails?: ModifiedLimitDetails
    ) => {
      const { errors } = await approveSingleUseCardRequest({
        variables: { input: { cardRequestId, ...updatedCardDetails } },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    approvingSingleUseCard: loading,
  };
};
