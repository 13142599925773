import { Grid, Icon } from '@candisio/design-system';

interface StatusIconProps {
  hasErrorFile: boolean;
}

export const StatusIcon = ({ hasErrorFile }: StatusIconProps) => {
  return (
    <Grid justifyContent="center" alignContent="center" height="41px">
      {hasErrorFile ? (
        <Icon icon="failCircle" color="red700" size="space24" />
      ) : (
        <Icon icon="checkCircle" color="green700" size="space24" />
      )}
    </Grid>
  );
};
