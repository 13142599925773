import { Box, Flex, Text } from '@candisio/design-system';
import { ReactNode, useCallback, useEffect, useState } from 'react';

type CarouselProps = {
  cards: ReactNode[];
  autoPlayCard?: boolean;
  autoPlayInterval?: number;
  title: string;
  currentCardIndex?: number;
  goToSlide?: (index: number) => void;
};

export const Carousel = ({
  cards,
  title,
  autoPlayCard = true,
  autoPlayInterval = 300000,
  currentCardIndex: controlledCardIndex,
  goToSlide,
}: CarouselProps) => {
  const [internalCardIndex, setInternalCardIndex] = useState(0);
  const currentCardIndex = controlledCardIndex ?? internalCardIndex;

  const updateCardIndex = useCallback(
    (index: number) => {
      if (goToSlide) {
        goToSlide(index);
      } else {
        setInternalCardIndex(index);
      }
    },
    [goToSlide]
  );

  useEffect(() => {
    if (!autoPlayCard) return;

    const interval = setInterval(() => {
      updateCardIndex((currentCardIndex + 1) % cards.length);
    }, autoPlayInterval);

    return () => clearInterval(interval);
  }, [
    autoPlayInterval,
    cards.length,
    autoPlayCard,
    currentCardIndex,
    updateCardIndex,
  ]);

  const displayButtons = cards.length > 1;

  return (
    <Flex width="100%">
      <Flex
        direction="column"
        gap="space24"
        paddingBottom="space8"
        alignItems="flex-start"
        alignSelf="stretch"
        width="100%"
        height="100%">
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text fontSize="xlarge" fontWeight="normal" lineHeight="space24">
            {title}
          </Text>

          {displayButtons && (
            <Flex gap="space10">
              {cards.map((_, index: number) => (
                <Box
                  as="button"
                  key={index}
                  width="space10"
                  height="space10"
                  borderRadius="full"
                  background={
                    currentCardIndex === index ? 'gray600' : 'gray300'
                  }
                  onClick={() => updateCardIndex(index)}
                  cursor="pointer"
                  border="none"
                  padding={0}
                  aria-label={`PlayButton${index}`}
                />
              ))}
            </Flex>
          )}
        </Flex>

        <Box overflow="hidden" position="relative" width="100%" height="100%">
          {cards[currentCardIndex]}
        </Box>
      </Flex>
    </Flex>
  );
};
