import {
  Flex,
  Icon,
  Link,
  Switch,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import AvatarPlaceholderImage from 'assets/insights/avatar-placeholder.png';
import { AnimatePresence, motion } from 'framer-motion';
import { ActiveMembersQuery } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SelectTeamMembers } from './SelectTeamMembers';
import { fadeUsers, slideInformations } from './ShareWidget.helper';

type ShareWidgetProps = {
  isWidgetShared: boolean;
  handleSetIsWidgetShared: (sharedWith: boolean) => void;
  shareableUsers: ActiveMembersQuery['organizationMemberships'];
};

export const ShareWidget = ({
  isWidgetShared,
  shareableUsers,
  handleSetIsWidgetShared,
}: ShareWidgetProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  return (
    <Flex direction="column" gap="space12">
      <Flex alignItems="center" gap="space8">
        <Text fontWeight="semibold">
          {t('dashboard.createNewWidget.sharing.title')}
        </Text>
        <Flex {...triggerProps} ref={triggerRef}>
          <Icon icon="infoCircle" color="gray700" size="space12" />

          {isOpen && (
            <Tooltip {...tooltipProps} ref={tooltipRef}>
              <Flex direction="column" width="350px" gap="space8">
                <Text fontWeight="regular" color="gray800" fontSize="basic">
                  {t('dashboard.createNewWidget.sharing.infoTooltip.content')}
                </Text>
                <Link
                  external
                  href={t(
                    'dashboard.createNewWidget.sharing.infoTooltip.linkUrl'
                  )}
                  alignSelf="start">
                  {t('dashboard.createNewWidget.sharing.infoTooltip.linkText')}
                </Link>
              </Flex>
            </Tooltip>
          )}
        </Flex>
      </Flex>

      <AnimatePresence>
        {isWidgetShared && (
          <motion.div
            key="sharedWithUsers"
            animate={isWidgetShared && 'visible'}
            variants={fadeUsers}
            exit={fadeUsers.onExit}
            initial={fadeUsers.hidden}>
            <SelectTeamMembers shareableUsers={shareableUsers} />
          </motion.div>
        )}
      </AnimatePresence>
      <Flex as="label" gap="space8" alignItems="center">
        <Switch
          name="switchShareToggle"
          label="" // already has external label
          checked={isWidgetShared}
          onChange={() => handleSetIsWidgetShared(!isWidgetShared)}
        />
        <Text fontSize="small">
          {t('dashboard.createNewWidget.sharing.label')}
        </Text>
      </Flex>
      <AnimatePresence initial={false}>
        {!isWidgetShared && (
          <motion.div
            variants={slideInformations}
            animate={!isWidgetShared && 'visible'}
            key="animatedHelpParagraph"
            exit={slideInformations.onExit}
            initial={slideInformations.hidden}>
            <Flex
              alignItems="center"
              background="bluebg"
              padding="space8"
              borderRadius="small">
              <img src={AvatarPlaceholderImage} alt="sample avatar" />
              <div>
                <Text fontSize="small" as="div">
                  {t('dashboard.createNewWidget.sharing.infoText')}
                </Text>
              </div>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  );
};
