import { NetworkStatus } from '@apollo/client';
import { Box, Button, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentsPaginationResponse } from './types';

export const LoadMoreItemsButton = ({
  paginationLimit,
  ...paginationResponse
}: PaymentsPaginationResponse & {
  paginationLimit: number;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  if (paginationResponse.error) {
    return <LoadMoreErrorMessage />;
  }

  const hasMoreData = paginationResponse.data?.payments?.pageInfo?.hasNextPage;
  const endCursor = paginationResponse.data?.payments?.pageInfo?.endCursor;
  const showLoadMore =
    hasMoreData && endCursor && !isLoadingInitialOrMoreData(paginationResponse);

  if (!showLoadMore) {
    return null;
  }

  return (
    <Button
      justifySelf="center"
      variant="tertiary"
      size="small"
      // @TODO explicit MouseEvent<HTMLButtonElement> type should not be required
      // See: https://github.com/microsoft/TypeScript/issues/44596
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        paginationResponse.onLoadMore && paginationResponse.onLoadMore();
      }}>
      {t('history.loadMore', { count: paginationLimit })}
    </Button>
  );
};

const LoadMoreErrorMessage = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  return (
    <Box justifySelf="center" paddingX="space32">
      <Text color="red500" fontSize="xsmall">
        {t('history.dataFetchingErrorDescription')}
      </Text>
    </Box>
  );
};

const isLoadingInitialOrMoreData = ({
  loading,
  networkStatus,
}: PaymentsPaginationResponse): boolean =>
  loading && networkStatus !== NetworkStatus.poll;
