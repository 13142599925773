import { Button, Text } from '@candisio/design-system';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { useDatev } from 'orgConfig/datev';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowEntitlementPromo } from 'views/Integrations/Export/toolkit/hooks/useShowEntitlementPromo';
import { ProgressBar } from '../../../../../../../../components/ProgressBar/ProgressBar';
import { ActionBar } from '../../../../../../../../components/Sidebar/ActionBar';
import { ExportContext } from '../../../../../Context';
import { BdsPromotionButton } from '../../../../../toolkit/components/PromotionButton';
import { useIsExporting } from '../../../../../toolkit/hooks/useIsExporting';
import { Table } from '../../../../../types';

export const AddisonExportButton = ({
  onExport,
  hasExportableEntity,
  loading,
}: {
  onExport: () => void;
  hasExportableEntity: boolean;
  loading?: boolean;
}) => {
  const [t] = useTranslation();
  const { table } = useContext(ExportContext);
  const { isExporting } = useIsExporting();
  const [isExportTriggered, setIsExportTriggered] = useState<boolean>(false);
  const { exportProvisionsFF } = useDatev(); // BDS-checked

  const showEntitlementPromo = useShowEntitlementPromo();

  const hasError = !hasExportableEntity && isExportTriggered;

  const provisionsOpened =
    table === Table.PROVISIONS || table === Table.REVERSALS;

  if (isExporting) {
    return (
      <ActionBar>
        <ProgressBar completion={0}>
          {t('export.exportForm.actionBar.exporting')}
        </ProgressBar>
      </ActionBar>
    );
  }

  if (hasError) {
    return (
      <ActionBar>
        <Text color="red500" fontSize="xsmall">
          {t('export.exportForm.actionBar.noExportableDocuments')}
        </Text>
        <Button disabled>{t('export.exportForm.actionBar.export')}</Button>
      </ActionBar>
    );
  }

  if (showEntitlementPromo) {
    return (
      <ActionBar>
        <UpsellPromo feature={FEATURE.PROVISIONS} />
        <Button disabled>{t('export.exportForm.actionBar.export')}</Button>
      </ActionBar>
    );
  }

  return (
    <ActionBar>
      <BdsPromotionButton />
      <Button
        onClick={() => {
          setIsExportTriggered(true);

          if (hasExportableEntity) {
            void onExport();
          }
        }}
        loading={loading}
        disabled={!exportProvisionsFF && provisionsOpened}
        data-cy="submit">
        {t('export.exportForm.actionBar.export')}
      </Button>
    </ActionBar>
  );
};
