import { Image } from '@candisio/design-system';
import { thumbnails } from 'containers/Entitlements/components/Upsell/utils';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';

export const Absence = ({ onClick }: { onClick?: () => void }) => {
  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[FEATURE.ABSENCE_MANAGEMENT][locale];

  return (
    <Image
      style={{ cursor: 'pointer' }}
      src={thumbnail}
      alt=""
      onClick={onClick}
    />
  );
};
