import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';
import { SplitsCell } from './SplitsCell';

export const GLACell = (
  props: CellProps<DocumentsTableData, (undefined | string)[]>
) => {
  const { friendlyFormatGLA, normalizeGLA } = useAccountingNumberFormatters();
  const formattedGLA = useMemo(() => {
    if (!props?.value) return props?.value;

    return props?.value.map(value => {
      if (!value) return value;
      const [code, name] = normalizeGLA(value) ?? [];
      const formattedGLACode = friendlyFormatGLA(code);

      return name ? `${formattedGLACode} - ${name}` : formattedGLACode;
    });
  }, [friendlyFormatGLA, normalizeGLA, props?.value]);

  return <SplitsCell {...props} value={formattedGLA} />;
};
