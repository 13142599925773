import { Button } from '@candisio/design-system';
import { useGenericErrorMessageHandler } from 'containers/credit-cards/utils';
import { useLockUnlockCardMutation } from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { AppRouteParams, Routes } from 'models';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { CarouselStates } from '../../types';

export const UnlockButton = ({
  cardId,
  cardState,
}: {
  cardId: string;
  cardState: CarouselStates.LOCKED | CarouselStates.LOCKEDPIN;
}) => {
  const genericErrorMessageHandler = useGenericErrorMessageHandler();
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const { searchParams } = useMutateSearchParams();
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const [lockOrUnlockCard, { loading }] = useLockUnlockCardMutation({
    onError: () => genericErrorMessageHandler(),
  });

  const { refetchQueries } = useCreditCardsRefetchQueries();

  const onUnlockByPin = () => {
    const cardDetails = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.DASHBOARD_CREDIT_CARD}/:creditCardId`,
      {
        creditCardId: cardId,
        organizationSlug,
      }
    );

    navigate({ pathname: cardDetails, search: searchParams.toString() });
  };

  const onUnlock = async () => {
    void lockOrUnlockCard({
      variables: {
        input: {
          cardId,
          locked: false,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: refetchQueries.cardIssuerCards(cardId),
    });
  };

  const onClick = async () => {
    if (cardState === CarouselStates.LOCKEDPIN) {
      onUnlockByPin();
    } else {
      await onUnlock();
    }
  };

  return (
    <Button
      icon="unlock"
      iconPosition="right"
      size="small"
      loading={loading}
      onClick={onClick}
      variant="secondary">
      {tTransactions('carousel.locked.unlock')}
    </Button>
  );
};
