import { gql } from 'graphql-tag';

export const paymentConditionsQuery = gql`
  query paymentConditions($filter: PaymentConditionsFilter!) {
    paymentConditions(filter: $filter) {
      id
      paymentConditionNumber
      paymentConditionName
      dueDateOffset
      discountOffset
      discountPercentage
      updatedAt
      isArchived
      updatedBy {
        name
        avatarUrl
      }
    }
  }
`;

export const paymentConditionsCountQuery = gql`
  query paymentConditionsCount($filter: PaymentConditionsFilter!) {
    paymentConditionsCount(filter: $filter)
  }
`;

export const paymentConditionsExportMutation = gql`
  mutation exportPaymentConditions {
    exportPaymentConditions {
      ... on PaymentConditionsExportError {
        __typename
        kind
      }
      ... on PaymentConditionsExport {
        __typename
        file {
          url
        }
      }
    }
  }
`;

export const refetchPaymentConditionQueries = [
  {
    query: paymentConditionsQuery,
    variables: { filter: { isArchived: true } },
  },
  {
    query: paymentConditionsQuery,
    variables: { filter: { isArchived: false } },
  },
  {
    query: paymentConditionsCountQuery,
    variables: { filter: { isArchived: true } },
  },
  {
    query: paymentConditionsCountQuery,
    variables: { filter: { isArchived: false } },
  },
];

export const exportPaymentConditionsGenericQuery = gql`
  mutation exportPaymentConditionsGeneric {
    exportPaymentConditionsGeneric {
      url
    }
  }
`;
