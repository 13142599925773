import { Box, Button, Grid, Text } from '@candisio/design-system';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type MaybePromise<T> = Promise<T> | T;

interface VerifyActionCardProps {
  fieldName?: 'email';
  title: string;
  description: ReactNode;
  hasPendingVerification: boolean;
  children: ReactNode;
}

interface VerifyEmailCardProps {
  newEmail?: string;
  hasPendingChangeEmailRequest: boolean;
  onCancelChangeEmailRequest: () => void;
  onResendVerificationEmail: () => void;
  fieldName?: VerifyActionCardProps['fieldName'];
  cancelChangeEmailIsLoading: boolean;
  resendChangeEmailIsLoading: boolean;
}

export const VerifyEmailCard = ({
  hasPendingChangeEmailRequest,
  newEmail,
  onCancelChangeEmailRequest,
  onResendVerificationEmail,
  fieldName,
  cancelChangeEmailIsLoading,
  resendChangeEmailIsLoading,
}: VerifyEmailCardProps) => {
  const [t] = useTranslation();

  return (
    <VerifyActionCard
      fieldName={fieldName}
      title={t('header.profile.tabs.update.pendingEmail.title')}
      description={t('header.profile.tabs.update.pendingEmail.description', {
        newEmail,
      })}
      hasPendingVerification={hasPendingChangeEmailRequest}>
      <VerifyActionCardButtons
        cancelActionButtonText={t(
          'header.profile.tabs.update.pendingEmail.cancel'
        )}
        confirmActionButtonText={t(
          'header.profile.tabs.update.pendingEmail.resend'
        )}
        onCancelAction={onCancelChangeEmailRequest}
        onConfirmAction={onResendVerificationEmail}
        cancelChangeEmailIsLoading={cancelChangeEmailIsLoading}
        resendChangeEmailIsLoading={resendChangeEmailIsLoading}
      />
    </VerifyActionCard>
  );
};

interface VerifyActionCardButtonProps {
  onCancelAction: () => MaybePromise<any>;
  cancelActionButtonText: string;
  onConfirmAction: () => MaybePromise<any>;
  confirmActionButtonText: string;
  cancelChangeEmailIsLoading: boolean;
  resendChangeEmailIsLoading: boolean;
}

const VerifyActionCardButtons = ({
  cancelActionButtonText,
  confirmActionButtonText,
  onCancelAction,
  onConfirmAction,
  cancelChangeEmailIsLoading,
  resendChangeEmailIsLoading,
}: VerifyActionCardButtonProps) => {
  return (
    <Grid
      justifyContent="end"
      paddingTop="space16"
      gap="space8"
      templateColumns="repeat(2, max-content)">
      <Button
        variant="tertiary"
        color="red"
        onClick={onCancelAction}
        loading={cancelChangeEmailIsLoading}
        disabled={cancelChangeEmailIsLoading || resendChangeEmailIsLoading}>
        {cancelActionButtonText}
      </Button>
      <Button
        color="blue"
        alignSelf="center"
        onClick={onConfirmAction}
        loading={resendChangeEmailIsLoading}
        disabled={resendChangeEmailIsLoading || cancelChangeEmailIsLoading}>
        {confirmActionButtonText}
      </Button>
    </Grid>
  );
};

export const VerifyActionCard = ({
  fieldName,
  title,
  description,
  hasPendingVerification,
  children,
}: VerifyActionCardProps) => {
  return (
    <AnimatePresence>
      {hasPendingVerification && (
        <motion.div
          style={fieldName ? { gridArea: fieldName } : {}}
          initial={{ opacity: 0, height: '0px' }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: '0px' }}
          transition={{ duration: 0.25, ease: 'easeInOut' }}>
          <Box
            as="div"
            borderRadius="medium"
            padding="space16"
            background="bluebg">
            <Grid gap="space4">
              <Text fontSize="basic" fontWeight="semibold">
                {title}
              </Text>
              <Text fontSize="small">{description}</Text>
              {children}
            </Grid>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
