import { Grid, Skeleton } from '@candisio/design-system';
import mobileAppPromoQRcode from 'components/MobileAppPromotionBanner/media/Document_processing_banner_QR_code.jpg';
import { MobileAppPromotionBanner } from 'components/MobileAppPromotionBanner/MobileAppPromotionBanner';
import { memo, useMemo, SetStateAction } from 'react';
import { useFilesSelected } from 'views/Inbox/DocumentProcessing/components/useFilesSelected';
import { useMobileAppPromoBanner } from 'views/Inbox/DocumentProcessing/useMobileAppPromotionBanner';
import { AttachmentSection } from './AttachmentSection';
import { PdfInvoiceSkeleton } from './PdfViewer/PdfInvoiceSkeleton';
import { PdfViewer } from './PdfViewer/PdfViewer';
import { PDFDetails } from './utils';

interface DocumentViewerProps {
  documentId: string;
  attachments?: PDFDetails[];
  canAttachFiles?: {
    fromCandis: boolean;
    fromDisk: boolean;
  };
  // @TODO TGE-51 simplify how we pass and use files with PdfViewer and Attachments
  documentFile?: PDFDetails;
  selectedFile?: PDFDetails;
  /** Handler function that is invoked whenever we select a different file to show
   * in `PdfViewer`. E.g. selecting/clicking on different attachment and/or main file.
   *
   * Commonly we pass `setSelectedPdf` from `useAttachments` here.
   */
  onSelectDocument?: (value: SetStateAction<PDFDetails>) => void;
  hideAttachmentSection?: boolean;
  isLoadingPdf?: boolean;
}

const isPDF = (pdfDetails: {
  name?: string;
  url?: string;
}): pdfDetails is { name: string; url: string } =>
  Boolean(pdfDetails.name && pdfDetails.url);

export const DocumentViewer = memo<DocumentViewerProps>(
  ({
    documentId,
    attachments,
    canAttachFiles = { fromCandis: false, fromDisk: false },
    documentFile,
    selectedFile,
    onSelectDocument,
    hideAttachmentSection,
    isLoadingPdf,
  }) => {
    // @TODO TGE-51 simplify how we pass and use files with PdfViewer and Attachments
    const pdfFile = useMemo(
      () => selectedFile ?? documentFile,
      [selectedFile, documentFile]
    );

    const { onAttachFiles, isAttachingFiles } = useFilesSelected(
      attachments,
      documentFile,
      documentId
    );

    const { showMobileAppPromoBanner } = useMobileAppPromoBanner({
      documentId,
    });

    if (!pdfFile || !isPDF(pdfFile)) return null;

    if (isLoadingPdf || !documentFile) {
      return (
        <Grid gap="space10" templateRows="auto 1fr" height="100%">
          <Skeleton height="space32" width="100%" />
          <PdfInvoiceSkeleton />
        </Grid>
      );
    }

    return (
      <PdfViewer
        documentId={documentId}
        mobileAppPromo={
          showMobileAppPromoBanner && (
            <MobileAppPromotionBanner
              fontSize="basic"
              qrCode={mobileAppPromoQRcode}
              bannerHeight="110px"
              imageHeight="110px"
              imageWidth="150px"
              localStorageKey="mobile_app_promotion_banner_document_processing"
              trackingContext="document-processing-form"
            />
          )
        }
        attachments={attachments}
        documentFile={documentFile}
        selectedFile={selectedFile}
        onSelectDocument={onSelectDocument}
        attachmentSection={
          !hideAttachmentSection && (
            <AttachmentSection
              canAttachFiles={canAttachFiles}
              isAttachingFiles={isAttachingFiles}
              onAttachFiles={onAttachFiles}
              documentId={documentId}
            />
          )
        }
      />
    );
  }
);
