import { HookFormPercentField } from 'components/HookFormFields/HookFormPercentField';
import { useTranslation } from 'react-i18next';

export const VatRate = () => {
  const [t] = useTranslation();

  return (
    <HookFormPercentField
      name="vatRate"
      label={t('document.requestApproval.inputs.vatRate.label')}
      readOnly
    />
  );
};
