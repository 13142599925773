import {
  CardIssuerTransactionView,
  DateFilterInput,
  DateFilterOption,
  TransactionStatus,
  useCountCardIssuerTransactionsQuery,
} from 'generated-types/graphql.types';
import moment from 'moment';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';

export const useGetTransactionDeclinedInLastWeek = () => {
  const timezone = useOrganizationTimezone();
  const todayDate = new Date();

  const lastWeekFilter: DateFilterInput = {
    dateFrom: moment
      .tz(todayDate.toISOString(), timezone)
      .subtract(7, 'days')
      .format('DD.MM.YYYY'),
    dateTo: moment.tz(todayDate.toISOString(), timezone).format('DD.MM.YYYY'),
    filterOption: DateFilterOption.Custom,
  };

  const { data, loading } = useCountCardIssuerTransactionsQuery({
    // We need to have cache-and-network policy because this query is used in the credit cardsdashboard and we need to rely as much as possible on new data
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        view: CardIssuerTransactionView.Archive,
      },
      filters: {
        status: [TransactionStatus.Declined],
        dateRangeFilters: {
          transactionCreatedAt: lastWeekFilter,
        },
      },
    },
  });

  const declinedTransactionsCount =
    data?.countCardIssuerTransactions.count ?? 0;

  return { declinedTransactionsCount, loading, lastWeekFilter };
};
