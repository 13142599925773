import { Grid, Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE, i18n } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useLinkedPurchaseOrder } from 'views/Inbox/DocumentProcessing/useLinkedPurchaseOrder';
import { getFormattedNote } from '../Form/ProcessingForm/getFormattedNote';

export interface PurchaseOrderSplitItemProps {
  color?: string;
  fieldIndex: number;
  note?: string | null;
  quantity?: number | null;
}

export const PurchaseOrderSplitItem = ({
  color,
  fieldIndex,
  note,
  quantity,
}: PurchaseOrderSplitItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { documentId } = useParams<{ documentId: string }>();
  const { purchaseOrder, isQuantityRequired } =
    useLinkedPurchaseOrder(documentId);

  const formatQuantity = (value: number) => {
    const formatDefinition = new Intl.NumberFormat(i18n.language);

    return formatDefinition.format(value);
  };

  const descriptionText =
    note ??
    getFormattedNote(t, note) ??
    purchaseOrder?.goodsLineItems?.[fieldIndex]?.description;

  return (
    <>
      {quantity && isQuantityRequired ? (
        <Grid
          color={color}
          autoFlow="column"
          gap="space3"
          templateColumns="auto 1fr">
          <TruncatedText maxWidth="10ch">
            {formatQuantity(quantity)}
          </TruncatedText>
          {t('quantitySuffix')}
        </Grid>
      ) : (
        <Text color={color}>{t('inputs.quantity.placeholder.notSet')}</Text>
      )}

      <TruncatedText color={color}>
        {descriptionText || t('inputs.quantity.placeholder.notSet')}
      </TruncatedText>
    </>
  );
};
