import { Box, Grid, Paragraph, Text } from '@candisio/design-system';
import {
  AcitvityEventType,
  ActivityEvent,
  Maybe,
} from 'generated-types/graphql.types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Omit } from 'utils/extractors';

type Props = { events?: Maybe<ActivityEvent[]>; initial?: boolean };

const ContentEvent = ({
  type,
  data,
  values,
}: Omit<ActivityEvent, 'id' | '__typename'>) => {
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const onExpand = () => setExpanded(!expanded);

  return (
    <>
      <Box
        onClick={values ? onExpand : undefined}
        paddingBottom="0.1rem"
        style={{
          cursor: !!values ? 'pointer' : 'default',
        }}>
        <Text>
          {type &&
            type !== AcitvityEventType.System &&
            // eslint-disable-next-line candis/no-template-strings-inside-translation
            t(`activity.event.type.${type}`)}{' '}
          &nbsp;
        </Text>
        <Text fontWeight="bold">{data}</Text>
      </Box>
      {expanded && values && (
        <Grid
          background="gray200"
          autoFlow="column"
          width="calc(100% - 1rem)"
          padding="space8"
          borderRadius="medium"
          alignItems="center"
          style={{
            margin: '0.3rem 0 0.5rem -0.5rem',
          }}>
          <Grid flex="50%" padding="space8 space12" gap="0.1rem">
            <Paragraph color="gray700" fontSize="xsmall">
              Before
            </Paragraph>
            <Text>{values?.old || '-'}</Text>
          </Grid>
          <Grid
            flex="50%"
            padding="space8 space12"
            gap="0.1rem"
            background="white">
            <Paragraph color="gray700" fontSize="xsmall">
              After
            </Paragraph>
            <Text>{values?.new || '-'}</Text>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const Content = ({ events, initial }: Props) => {
  return (
    <Box fontWeight="regular">
      {events &&
        (!initial ? (
          events.map(({ id, ...event }) => <ContentEvent key={id} {...event} />)
        ) : (
          <Text textTransform="uppercase" color="gray600">
            {events[0].data}
          </Text>
        ))}
    </Box>
  );
};
