import { Grid } from '@candisio/design-system';
import { useProcessingFormIbanWarning } from 'components/Form/ProcessingForm/hooks/useProcessingFormIbanWarning';
import { ProcessingFormFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { useTranslation } from 'react-i18next';
import {
  useMakeBankingDetailsReadOnly,
  useShowSwiftCodeField,
} from 'views/utils/useShouldShowField';
import { useSwiftCodeWarning } from './hooks/useSwiftCodeWarning';
import { ProcessingFormIbanField } from './ProcessingFormIbanField';
import { ProcessingFormSwiftCodeField } from './ProcessingFormSwiftCodeFields';

interface ProcessingFormBankInfoFieldsProps {
  /** Display fields as read only */
  readOnly?: boolean;
  /** Processing form field options */
  fieldOptions?: ProcessingFormFieldOptions;
}
export const ProcessingFormBankInfoFields = ({
  readOnly,
  fieldOptions,
  ...restProps
}: ProcessingFormBankInfoFieldsProps) => {
  const [t] = useTranslation();
  const ibanWarning = useProcessingFormIbanWarning(fieldOptions);
  const swiftCodeWarning = useSwiftCodeWarning(fieldOptions);
  const showSwiftCodeField = useShowSwiftCodeField();
  const makeIbanReadOnly = useMakeBankingDetailsReadOnly();

  return (
    <Grid gap="space16">
      <Grid>
        <ProcessingFormIbanField
          name="iban"
          label={t('document.requestApproval.inputs.iban.label')}
          placeholder={t('document.requestApproval.inputs.iban.placeholder')}
          readOnly={readOnly || makeIbanReadOnly}
          description={ibanWarning}
          variant={ibanWarning ? 'warning' : undefined}
          {...restProps}
        />
      </Grid>
      {showSwiftCodeField && (
        <Grid>
          <ProcessingFormSwiftCodeField
            name="swiftCode"
            label={t('document.requestApproval.inputs.swiftCode.label')}
            placeholder={t(
              'document.requestApproval.inputs.swiftCode.placeholder'
            )}
            readOnly={readOnly}
            description={swiftCodeWarning}
            variant={swiftCodeWarning ? 'warning' : undefined}
            {...restProps}
          />
        </Grid>
      )}
    </Grid>
  );
};
