import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { useTranslation } from 'react-i18next';

export const CreateTransfer = () => {
  const [t] = useTranslation();

  return (
    <HookFormCheckboxField
      name="createTransfer"
      readOnly
      label={t('document.requestApproval.inputs.createTransfer.label')}
    />
  );
};
