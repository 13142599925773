import { IbanItem } from 'components/IbanListView/IbanListView';
import { BankAccountsIbanDataFragment } from 'generated-types/graphql.types';

export const toIbanItem = ({
  iban,
  id,
  isDefault,
  logoUrl,
  name,
}: BankAccountsIbanDataFragment): IbanItem => ({
  iban,
  isDefault: isDefault,
  key: id,
  logoUrl: logoUrl ?? undefined,
  name,
});
