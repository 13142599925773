import gql from 'graphql-tag';

export const currentUserQuery = gql`
  query currentUser($id: String!) {
    currentUser(id: $id) {
      id
      name
      email
      phoneNumber
      firstName
      lastName
      locale
      createdTimestamp
      analyticsId
      intercomHash
      membershipRoles {
        __typename
        id
        name
        isBuiltinRole
      }
      preferences {
        tables {
          id
          columns {
            id
            isVisible
            isFixed
            label
          }
        }
      }
      roles
      cardRoles
      avatarUrl
      trackingConfiguration {
        intercom
        trackingEnabled
      }
    }
  }
`;

export const updateCurrentUserMutation = gql`
  mutation updateCurrentUser($input: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      id
      firstName
      lastName
      locale
    }
  }
`;

export const saveTableConfigurationsMutation = gql`
  mutation saveTableConfigurations($input: SaveTablePreferenceInput!) {
    saveTablePreferences(input: $input) {
      id
      columns {
        id
        isVisible
        isFixed
        label
      }
    }
  }
`;

export const resetTableConfigurations = gql`
  mutation resetTableConfigurations($input: DeleteTablePreferenceInput!) {
    deleteTablePreferences(input: $input)
  }
`;
