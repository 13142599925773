import { PDFDetails } from 'components/DocumentViewer/utils';
import { useGetDocumentFileQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';

export const useDocumentFile = (documentId: string) => {
  const { data: documentFileQuery, loading: isDocumentFileLoading } =
    useGetDocumentFileQuery({
      // Document file URL expires after 12 hours but the user’s session can
      // last much longer. If we use the default `cache-first` fetch policy we
      // might try to download the PDF from an expired URL.
      //
      // We use `cache-and-network` instead so that we can still show the PDF
      // viewer immediately but then rerender if the URL has changed.
      fetchPolicy: 'cache-and-network',
      variables: { id: documentId },
      skip: !documentId,
    });

  const documentData = documentFileQuery?.getDocument;

  const documentFile: PDFDetails = useMemo(
    () => ({
      id: documentData?.documentFile?.id ?? documentId,
      name: documentData?.documentFile?.name ?? '',
      url: documentData?.documentFile?.url ?? '',
      size: documentData?.documentFile?.size ?? 0,
    }),
    [documentId, documentData]
  );

  const documentAttachments = documentData?.attachments;

  return { documentFile, isDocumentFileLoading, documentAttachments };
};
