import ExportTypesCashLedgerImageDe from './DE/kassenbuch.svg';
import ExportTypesDxsoImageDe from './DE/rechnung-mit-belegdaten.svg';
import ExportTypesAccDocumentsImageDe from './DE/rechnung.svg';
import ExportTypesRechnungswesenImageDe from './DE/rechnungen-und-buchungen.svg';
import ExportTypesCashLedgerImageEn from './EN/cash-ledger.svg';
import ExportTypesRechnungswesenImageEn from './EN/invoice-and-bookings.svg';
import ExportTypesDxsoImageEn from './EN/invoice-with-document-data.svg';
import ExportTypesAccDocumentsImageEn from './EN/invoice.svg';

type LanguageVersion = Record<string, string>;

export const EXPORT_TYPE_IMAGES: Record<string, LanguageVersion> = {
  accountingDocuments: {
    en: ExportTypesAccDocumentsImageEn,
    de: ExportTypesAccDocumentsImageDe,
  },
  dxsoJobs: {
    en: ExportTypesDxsoImageEn,
    de: ExportTypesDxsoImageDe,
  },
  cashLedger: {
    en: ExportTypesCashLedgerImageEn,
    de: ExportTypesCashLedgerImageDe,
  },
  rechnungswesen: {
    en: ExportTypesRechnungswesenImageEn,
    de: ExportTypesRechnungswesenImageDe,
  },
  bds: {
    en: ExportTypesRechnungswesenImageEn,
    de: ExportTypesRechnungswesenImageDe,
  },
};
