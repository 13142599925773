import { CardStatus } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { COST_CENTER_SEARCH_PARAM } from 'views/Settings/CostCenters/consts';
import { WorkflowTemplatesQueryProps } from 'views/Settings/WorkflowTemplates/hooks/useWorkflowData';

const trueString = 'true';
const falseString = 'false';
const ccParam = {
  cardholderId: 'cardholderName',
  status: 'status',
};

interface UseGenerateUrlsProps {
  approverId: string;
  cardholderId?: string;
  organizationSlug: string;
  usersThatAreBeingSubstitutedByTeamMember: {
    id: string;
    name?: string;
  }[];
}

export const useGenerateUrls = ({
  approverId,
  cardholderId,
  organizationSlug,
  usersThatAreBeingSubstitutedByTeamMember,
}: UseGenerateUrlsProps) => {
  const contractsBase = `/${organizationSlug}${Routes.ECM_CONTRACTS}`;
  const costCenterBase = `/${organizationSlug}${Routes.SETTINGS}${Routes.COST_CENTER}`;
  const creditCardsBase = `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`;
  const teamMemberBase = `/${organizationSlug}${Routes.SETTINGS}${Routes.TEAM_MEMBERS}`;
  const workflowTemplatesBase = `/${organizationSlug}${Routes.SETTINGS}${Routes.WORKFLOWS}`;

  const costCenterActive = () => {
    const searchParams = new URLSearchParams();

    const costCenterFilters = [
      { name: COST_CENTER_SEARCH_PARAM.archive, value: falseString },
      { name: COST_CENTER_SEARCH_PARAM.approvers, value: approverId },
    ];

    costCenterFilters.forEach(f => {
      searchParams.set(f.name, f.value);
    });

    return searchParams.toString();
  };

  const costCenterArchived = () => {
    const searchParams = new URLSearchParams();

    const costCenterArchivedFilters = [
      { name: COST_CENTER_SEARCH_PARAM.archive, value: trueString },
      { name: COST_CENTER_SEARCH_PARAM.approvers, value: approverId },
    ];

    costCenterArchivedFilters.forEach(f => {
      searchParams.set(f.name, f.value);
    });

    return searchParams.toString();
  };

  const notifyPersonSearchParam = () => {
    const searchParams = new URLSearchParams();

    searchParams.set('notifyPerson', approverId);

    return searchParams.toString();
  };

  const responsiblePersonSearchParam = () => {
    const searchParams = new URLSearchParams();

    searchParams.set('responsiblePerson', approverId);

    return searchParams.toString();
  };

  const workflowTemplatesActive = () => {
    const searchParams = new URLSearchParams();

    const workflowFilters = [
      { name: WorkflowTemplatesQueryProps.archive, value: falseString },
      { name: WorkflowTemplatesQueryProps.workflowUser, value: approverId },
    ];

    workflowFilters.forEach(f => {
      searchParams.set(f.name, f.value);
    });

    return searchParams.toString();
  };

  const workflowTemplatesArchived = () => {
    const searchParams = new URLSearchParams();

    const workflowArchivedFilters = [
      { name: WorkflowTemplatesQueryProps.archive, value: trueString },
      { name: WorkflowTemplatesQueryProps.workflowUser, value: approverId },
    ];

    workflowArchivedFilters.forEach(f => {
      searchParams.set(f.name, f.value);
    });

    return searchParams.toString();
  };

  const creditCards = () => {
    if (!cardholderId) return;
    const searchParams = new URLSearchParams();

    const ccFilters = [
      { name: ccParam.cardholderId, value: cardholderId },
      { name: ccParam.status, value: CardStatus.Active },
      { name: ccParam.status, value: CardStatus.Locked },
      { name: ccParam.status, value: CardStatus.LockedPin },
      { name: ccParam.status, value: CardStatus.Pending },
      { name: ccParam.status, value: CardStatus.Requested },
      { name: ccParam.status, value: CardStatus.Processing },
    ];

    ccFilters.forEach(f => {
      searchParams.append(f.name, f.value);
    });

    return searchParams.toString();
  };

  const url = {
    costCenterArchivedUrl: `${costCenterBase}?${costCenterArchived()}`,
    costCenterUrl: `${costCenterBase}?${costCenterActive()}`,
    creditCardsUrl: `${creditCardsBase}?${creditCards()}`,
    notifyPersonUrl: `${contractsBase}?${notifyPersonSearchParam()}`,
    responsiblePersonUrl: `${contractsBase}?${responsiblePersonSearchParam()}`,
    workflowTemplatesArchivedUrl: `${workflowTemplatesBase}?${workflowTemplatesArchived()}`,
    workflowTemplatesUrl: `${workflowTemplatesBase}?${workflowTemplatesActive()}`,
    usersSubstitutedByTeamMemberUrls:
      usersThatAreBeingSubstitutedByTeamMember.map(({ id, name }) => ({
        url: `${teamMemberBase}/${id}`,
        substitutedPersonName: name,
      })),
  };

  return {
    url,
  };
};
