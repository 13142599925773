import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useRemoveAvatarMutation,
  useRequestUploadAvatarUrlMutation,
  useUpdateAvatarMutation,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { useTranslation } from 'react-i18next';
import { uploadFile } from 'utils/upload';

export const useChangeAvatar = () => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const currentUser = useCurrentUser();
  const [requestUploadAvatarUrl] = useRequestUploadAvatarUrlMutation();
  const [updateAvatar, { loading: uploadAvatarLoading }] =
    useUpdateAvatarMutation();

  const [removeAvatar, { loading: removeAvatarLoading }] =
    useRemoveAvatarMutation();

  const onRemoveAvatar = async () => {
    if (currentUser?.id) {
      const { data } = await removeAvatar({
        variables: {
          membershipId: currentUser?.id,
        },
        refetchQueries: [currentUserQuery],
        awaitRefetchQueries: true,
      });

      if (data?.removeAvatar) {
        success(t('uploads.removeImage.success'));
      } else {
        error(t('uploads.removeImage.error'));
      }
    }
  };

  const onUploadAvatar = async (f: File) => {
    if (currentUser?.id) {
      const { data, errors } = await requestUploadAvatarUrl();
      if (!data?.requestUploadAvatarUrl?.uploadUrl) {
        return Promise.reject(errors);
      }

      const res = await uploadFile(
        f,
        data.requestUploadAvatarUrl.postOptions ?? [],
        data.requestUploadAvatarUrl.uploadUrl
      );

      void updateAvatar({
        variables: {
          id: currentUser?.id,
          url: data.requestUploadAvatarUrl.downloadUrl,
        },
        refetchQueries: [currentUserQuery],
        awaitRefetchQueries: true,
      });

      return res;
    }
  };

  return {
    onRemoveAvatar,
    onUploadAvatar,
    uploadAvatarLoading,
    removeAvatarLoading,
  };
};
