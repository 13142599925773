import { Flex, Icon, Tooltip } from '@candisio/design-system';
import { useTooltip } from '@candisio/design-system/src/Atoms/Tooltip';
import { useTranslation } from 'react-i18next';

export const AddOnIcon = () => {
  const [t] = useTranslation();
  const addOnToolTip = useTooltip();

  return (
    <Flex alignItems="center">
      <Icon
        color="purple500"
        icon="arrowUpCircleFilled"
        width="space16"
        height="space16"
        ref={addOnToolTip.triggerRef}
        {...addOnToolTip.triggerProps}
      />
      {addOnToolTip.isOpen && (
        <Tooltip
          {...addOnToolTip.tooltipProps}
          ref={addOnToolTip.tooltipRef}
          state={addOnToolTip.state}>
          {t('tag.addOn')}
        </Tooltip>
      )}
    </Flex>
  );
};
