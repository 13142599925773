import { gql } from '@apollo/client';

export const reimbursementCaseDataFragment = gql`
  fragment ReimbursementCaseData on ReimbursementCase {
    id
    organizationId
    createdAt
    status
    createTransfer
    isPaid
    paidAt
    title
    note
    createdByMembership {
      id
      firstName
      lastName
      avatarUrl
    }
    targetMembership {
      id
      firstName
      lastName
      avatarUrl
    }
    totalAmount {
      amount
      currency
      precision
    }
    suggestedCostCenter {
      id
      readableName
    }
    errors {
      field
      errors
    }
  }
`;

export const reimbursementCasesPageBasedQuery = gql`
  query reimbursementCasesPageBased(
    $input: ReimbursementCasePageBasedPaginationInput!
    $filters: ReimbursementCaseFiltersInput
    $sortBy: ReimbursementCaseSortInput
  ) {
    reimbursementCasesPageBased(
      input: $input
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        ...ReimbursementCaseData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${reimbursementCaseDataFragment}
`;

export const reimbursementCaseByIdQuery = gql`
  query reimbursementCaseById($id: ID!) {
    reimbursementCaseById(id: $id) {
      ...ReimbursementCaseData
    }
  }
  ${reimbursementCaseDataFragment}
`;

export const reimbursementCasesCountQuery = gql`
  query reimbursementCasesCount($filters: ReimbursementCaseFiltersInput) {
    reimbursementCasesCount(filters: $filters) {
      total
    }
  }
`;

export const reimbursementItemsQuery = gql`
  query reimbursementItems($filters: ReimbursementItemFiltersInput!) {
    reimbursementItems(filters: $filters) {
      ... on GeneralExpenseItem {
        id
        reimbursementCaseId
        createdAt
        type
        status
        title
        expenseDate
        files {
          id
          url
        }
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
      }

      ... on HospitalityExpenseItem {
        id
        reimbursementCaseId
        createdAt
        type
        status
        title
        expenseDate
        files {
          id
          url
        }
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        internalGuests
        externalGuests
        receiptAmount {
          amount
          currency
          precision
        }
        tipAmount {
          amount
          currency
          precision
        }
      }
    }
  }
`;

export const reimbursementItemsListQuery = gql`
  query reimbursementItemsList($filters: ReimbursementItemFiltersInput!) {
    reimbursementItemsList: reimbursementItems(filters: $filters)
      @connection(key: "reimbursementItemsList", filter: ["filters"]) {
      ... on GeneralExpenseItem {
        id
        type
        status
        title
        errors {
          field
          errors
        }
      }

      ... on HospitalityExpenseItem {
        id
        type
        status
        title
        errors {
          field
          errors
        }
      }
    }
  }
`;
