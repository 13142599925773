import { ExportableEntityType } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { isEqual } from 'lodash';
import { useDatev } from 'orgConfig/datev';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  isSupportedInOutDocument,
  isSupportedOtherDocument,
} from 'views/utils/DocumentDirection';
import { csvExportTypesDatev, csvExportTypesNonDatev } from '../../consts';
import { ExportEntity, ExportEntityRow, ExportType } from '../../types';
import { deSerializeTimeFrame } from './timeframe';

export function useGetExportEntitiesRows(
  entities: ExportEntity[],
  exportType: ExportType | null,
  selectedTimeFrameQuery: string | null
): ExportEntityRow[] {
  const selectSupportedEntities = useMakeSelectSupportedEntities(exportType);

  const selectByTimeFrame = useMakeSelectByTimeFrameAndDocumentType(
    selectedTimeFrameQuery
  );

  const disabledEntities = useMakeDisabledEntities();

  const mappedEntities = entities
    .map(selectSupportedEntities)
    .map(selectByTimeFrame)
    .map(disabledEntities);

  const disabled = mappedEntities
    .filter(e => e.isDisabled ?? e.disableReason)
    .sort(sortEnabledEntities);

  const exportable = mappedEntities.filter(
    e => !e.isDisabled && !e.disableReason
  );

  return exportable.concat(disabled);
}

export function useMakeDisabledEntities() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  const { exportProvisionsFF } = useDatev(); // BDS-checked

  return (entity: ExportEntityRow): ExportEntityRow => {
    let { isDisabled, disableReason } = entity;

    const isReversalOrProvision =
      entity.type === ExportableEntityType.Provision ||
      entity.type === ExportableEntityType.ProvisionReversal;

    if (!exportProvisionsFF && isReversalOrProvision) {
      disableReason = t('notSupportedTooltipMessages.provisions-addon');
    }

    return { ...entity, isDisabled, disableReason };
  };
}

export function useMakeSelectSupportedEntities(exportType: ExportType | null) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const [exportProvisionsDatevFormatInternal] = useCandisFeatureFlags([
    FEATURE_FLAGS.exportProvisionsDatevFormatInternal,
  ]);

  if (!exportType) return (entity: ExportEntityRow) => entity;

  return (entity: ExportEntityRow) => {
    let { isDisabled, disableReason } = entity;

    const supportedTypes = exportProvisionsDatevFormatInternal
      ? [ExportType.DATEV_CSV, ExportType.DATEV_BDS]
      : [ExportType.DATEV_BDS];

    const isReversalOrProvision =
      entity.type === ExportableEntityType.Provision ||
      entity.type === ExportableEntityType.ProvisionReversal;

    const hasSupportedEntityType =
      exportType === ExportType.DATEV_BDS
        ? isSupportedOtherDocument(entity)
        : isSupportedInOutDocument(entity);

    const isCsvExportType =
      csvExportTypesDatev.includes(
        exportType as (typeof csvExportTypesDatev)[number]
      ) ||
      csvExportTypesNonDatev.includes(
        exportType as (typeof csvExportTypesNonDatev)[number]
      );

    if (isCsvExportType && !hasSupportedEntityType) {
      isDisabled = true;
      disableReason = t('notSupportedTooltipMessages.notSupported');
    }

    if (isReversalOrProvision && !supportedTypes.includes(exportType)) {
      isDisabled = true;
      disableReason = t(
        'notSupportedTooltipMessages.provisionsReversalsSupported'
      );
    }

    return { ...entity, isDisabled, disableReason };
  };
}

export function useMakeSelectByTimeFrameAndDocumentType(
  selectedTimeFrameQuery: string | null
) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  if (!selectedTimeFrameQuery) return (entity: ExportEntityRow) => entity;

  return (entity: ExportEntityRow): ExportEntityRow => {
    let { isDisabled, disableReason } = entity;

    const deSerializedTimeFrame = deSerializeTimeFrame(selectedTimeFrameQuery);

    const selectedTimeFrame = {
      year: deSerializedTimeFrame.year,
      month: deSerializedTimeFrame.month,
    };

    const invoiceDate = new Date(entity.invoiceDate);

    const entityTimeFrame = {
      year: invoiceDate.getFullYear(),
      month: invoiceDate.getMonth(),
    };

    const isSelectedByTimeFrame = isEqual(selectedTimeFrame, entityTimeFrame);

    if (!isSelectedByTimeFrame) {
      isDisabled = true;
      disableReason = t('notSupportedTooltipMessages.notSelectedByTimeFrame');
    }

    return { ...entity, isDisabled, disableReason };
  };
}

export function sortEnabledEntities(
  current: ExportEntityRow,
  next: ExportEntityRow
): number {
  if (current.isDisabled === next.isDisabled) {
    // keep the one with the description at the bottom of a list
    return current.disableReason ? 1 : -1;
  } else {
    return current.isDisabled ? 1 : -1;
  }
}
