import { CustomEmptyStateProps } from '@candisio/design-system';
import { UNTAGGED_ID } from 'components/DocumentsTable/constants';
import { availableEcmDocumentFilters } from 'components/EcmDocumentsTable/constants';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { useEcmContractsData } from 'components/EcmDocumentsTable/hooks/useEcmContractsData';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { DEFAULT_DATE_FROM } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import {
  EcmDateFilter,
  EcmDocumentContractStatus,
  EcmDocumentType,
  EcmFilterInput,
  EcmSortInput,
  EcmSortOrder,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useDateConverter } from 'hooks/useDateConverter';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { AppRouteParams, Routes } from 'models';
import moment from 'moment';
import { EcmDocumentsPaginationParams } from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { EcmDocumentsEmptyState } from '../EcmDocuments/EcmDocumentsEmptyState';
import { useGetEcmContractsTableConfigs } from './hooks/useGetEcmContractsTableConfigs';

export const EcmContracts = () => {
  const { organizationSlug } = useParams<AppRouteParams>();

  const navigate = useNavigate();
  const { searchParams, updateSearchParam } = useMutateSearchParams();

  const { dateStringToIsoDateFilterFormat } = useDateConverter();

  const { filters, onFilter, sortBy, onSort } =
    useUrlBasedSortAndFilter<EcmDocumentsTableData>({
      availableFilters: availableEcmDocumentFilters,
    });

  const {
    isSavingConfigurations,
    availableDocumentColumnIds,
    configurationsTable,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetEcmContractsTableConfigs({ filters, sortBy });

  const searchQuery = searchParams.get(queryParameter) ?? '';

  /** For the date filters, we need to split the value
   *  into 'toDate' and 'fromDate', and transform it to ISO format.
   */
  const extractDates = (dateFilter: string[]): EcmDateFilter => {
    const activeFilterValues = dateFilter?.[0] as string;
    const [from, to] = activeFilterValues?.split('-');
    const fromIso = dateStringToIsoDateFilterFormat(from);
    const toIso = dateStringToIsoDateFilterFormat(to);

    return {
      fromDate: fromIso || '',
      toDate: toIso || '',
    };
  };

  const handleRowClick = (
    documentId: string,
    documentType?: EcmDocumentType,
    cursor?: string
  ) => {
    if (!organizationSlug) {
      return;
    }

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ECM_CONTRACTS}/:documentId`,
      { organizationSlug, documentId }
    );

    if (cursor) {
      searchParams.set('cursor', cursor);
    }

    if (documentType) {
      searchParams.set('documentType', documentType);
    }

    if (searchQuery) {
      searchParams.set(queryParameter, searchQuery);
    }

    navigate({ pathname, search: searchParams.toString() });
  };

  const isTableFiltered = filters.length > 0;

  const formattedFilters: EcmFilterInput | undefined = isTableFiltered
    ? filters.reduce<EcmFilterInput>((acc, filter) => {
        const columnId = filter.id;

        switch (columnId) {
          // The column is named 'contact' but the filter for the `getEcmDocuments` query is named 'contactId'.
          case 'contact': {
            acc.contactId = filter.value as Array<string>;

            break;
          }

          case 'contractStatus': {
            const filterValue = filter.value as Array<string>;

            // to filter for "Overdue" contracts, we set "Reminder date"
            // filter ending today and omit "Overdue" from "Status"
            const formattedFilterValue = filterValue.filter(
              value => value !== 'Overdue' && value !== 'Sensitive'
            ) as Array<EcmDocumentContractStatus>;

            if (filterValue.includes('Overdue')) {
              const formattedReminderDates = {
                fromDate: dateStringToIsoDateFilterFormat(
                  DEFAULT_DATE_FROM.format(moment.HTML5_FMT.DATE)
                ),
                toDate: dateStringToIsoDateFilterFormat(
                  moment().format(moment.HTML5_FMT.DATE)
                ),
              };

              acc.terminationReminderDate = formattedReminderDates;
            }

            if (filterValue.includes('Sensitive')) {
              acc.isSensitive = true;
            }

            // exclude status filter if we're only filtering for "Overdue" or "Sensitive" documents
            if (formattedFilterValue.length > 0) {
              acc.contractStatus = formattedFilterValue;
            }

            break;
          }

          // The column is named 'contractType' but the filter for the `getEcmDocuments` query is named 'documentSubCategory'.
          case 'contractType': {
            acc.documentSubCategory = filter.value as Array<string>;

            break;
          }

          // The column is named 'costCenter' but the filter for the `getEcmDocuments` query is named 'costCenterIds'.
          case 'costCenter': {
            acc.costCenterIds = filter.value as Array<string>;

            break;
          }

          case 'startDate': {
            const filterValue = filter.value as Array<string>;
            acc.startDate = extractDates(filterValue);

            break;
          }

          case 'endDate': {
            const filterValue = filter.value as Array<string>;
            acc.endDate = extractDates(filterValue);

            break;
          }

          case 'terminationDate': {
            const filterValue = filter.value as Array<string>;
            acc.terminationDate = extractDates(filterValue);

            break;
          }

          case 'terminationReminderDate': {
            const filterValue = filter.value as Array<string>;
            acc.terminationReminderDate = extractDates(filterValue);

            break;
          }

          case 'documentType': {
            acc.documentType = filter.value as Array<string>;

            break;
          }

          case 'responsiblePerson': {
            acc.responsiblePerson = filter.value as Array<string>;

            break;
          }

          case 'notifyPerson': {
            acc.notifyPerson = filter.value as Array<string>;

            break;
          }

          case 'tags': {
            // TODO: fix type definition
            // `filter.value` is type `unknown`, so we typecast here
            if ((filter.value as string).includes(UNTAGGED_ID)) {
              acc.tags = [];
            } else {
              acc.tags = filter.value as Array<string>;
            }
          }
        }

        return acc;
      }, {})
    : undefined;

  const formattedSort: EcmSortInput | undefined =
    sortBy.length > 0
      ? sortBy.map(field => {
          return {
            [field.id]:
              typeof field.desc !== 'boolean'
                ? undefined
                : field.desc
                ? EcmSortOrder.Desc
                : EcmSortOrder.Asc,
          };
        })[0]
      : undefined;

  const paginationParams: EcmDocumentsPaginationParams = {
    filter: formattedFilters,
    sort: formattedSort,
  };

  const {
    data,
    handleDebounceSearch,
    hasNoData,
    isLoading,
    onLoadMore,
    selectedContractsCount,
  } = useEcmContractsData({
    params: paginationParams,
    initialSearchQuery: searchQuery,
  });

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return (
      <EcmDocumentsEmptyState
        isTableEmpty={hasNoData}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const handleSearchChange = (searchQuery: string) => {
    handleDebounceSearch(searchQuery);
    updateSearchParam(queryParameter, searchQuery);
  };

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_CONTRACTS}>
      <EcmDocumentsTable
        context="contracts"
        columns={availableDocumentColumnIds}
        isLoadingConfigurations={isSavingConfigurations || isResetPending}
        data={data}
        isLoading={isLoading}
        defaultFilters={filters}
        defaultSortBy={sortBy}
        searchQuery={searchQuery}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        selectedDocumentsCount={selectedContractsCount}
        onSearchChange={handleSearchChange}
        onRowClick={handleRowClick}
        onFilter={onFilter}
        onEndReached={onLoadMore}
        onUpdateConfigurations={handleUpdateConfigurations}
        onSort={onSort}
        customEmptyState={customEmptyState}
        onResetTableConfigurations={handleResetTableConfigurations}
      />
    </ArchiveViewLayout>
  );
};
