import { ReplaceCardFormErrorMessages } from './replaceCardFormSchema';

export const replaceCardFormErrorMessages: ReplaceCardFormErrorMessages = {
  replaceReason: {
    label: 'creditCardFormDataPreview.replaceCard.radioButton.label',
  },
  validity: {
    label: 'ccRequestForm.fields.validity',
  },
};
