import * as Sentry from '@sentry/react';
import { Organization, User } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useEffect, ReactNode } from 'react';

const INTERCOM_CHAT_SELECTOR = "[data-intercom = 'intercom-chat']";

/**
 * New traits should be in snake_case
 * We will change the other traits to snake_case when we have a good solution
 * from Segment support on how to remove old traits
 */
const computeViewerTraits = (viewer: User) => {
  const createdAtTimestamp = parseInt(viewer.createdTimestamp);
  const createdAt = !isNaN(createdAtTimestamp) && new Date(createdAtTimestamp);

  return {
    name: viewer.name,
    email: viewer.email,
    createdAt: createdAt && createdAt.toISOString(),
    firstName: viewer.firstName,
    lastName: viewer.lastName,
    locale: viewer.locale,
    user_hash: viewer.intercomHash ?? undefined,
    user_id: viewer.analyticsId ?? undefined,
    has_candis: true,
    db_id: viewer.id,
  };
};

const initIntercom = (
  viewer: User,
  organization: Pick<
    Organization,
    | 'id'
    | 'name'
    | 'slug'
    | 'salesforceAccountId'
    | 'chargebeeSubscriptionId'
    | 'realmName'
  >
) => {
  const options: SegmentAnalytics.SegmentOpts = {
    integrations: {
      All: true,
    },
  };

  const viewerTraits = computeViewerTraits(viewer);

  window.intercomSettings = {
    ...window.intercomSettings,
    ...viewerTraits,
    alignment: 'left',
    horizontal_padding: 40,
    vertical_padding: 26,
    custom_launcher_selector: INTERCOM_CHAT_SELECTOR,
    language_override: viewer.locale?.toLowerCase() ?? 'de',
  };

  if (window.analytics) {
    window.analytics.identify(
      viewer.analyticsId || viewer.id,
      viewerTraits,
      options
    );
  }

  Sentry.configureScope(scope => {
    scope.setUser({
      id: viewer.id,
      email: viewer.email,
      username: viewer.name,
      organization: organization.realmName,
    });
  });
};

interface IntercomProviderProps {
  children: ReactNode;
}

// This Provider seems rather unnecessary to me and should be integrated in
// the Analytics Provider as most of the settings in here are not Intercom
// specific and will be used by all connected analytics tools.
export const IntercomProvider = ({ children }: IntercomProviderProps) => {
  const viewer = useCurrentUser();
  const organization = useFullOrganization();

  useEffect(() => {
    const intercomIsEnabled = viewer?.trackingConfiguration?.intercom;

    if (viewer && organization && intercomIsEnabled) {
      initIntercom(viewer, organization);
    }

    if (viewer && organization && !intercomIsEnabled && window.Intercom) {
      window.Intercom('shutdown');
      window.intercomSettings = {};
    }
  }, [viewer, organization]);

  return <>{children}</>;
};
