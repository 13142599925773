import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useTrackConfigurations } from 'components/Table/Configurations/hooks/useTrackConfigurations';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';

interface Params {
  filters: Filters<EcmDocumentsTableData>;
  sortBy: SortingRule<EcmDocumentsTableData>[];
}

export const archiveEcmDocumentsTableId =
  'archive-ecm-documents-table-configurations';

const useDefaultColumnsConfigurations = (): Configuration[] => {
  const { showDocumentTags } = useEcm();

  return [
    {
      id: 'documentStatus',
      isVisible: true,
      label: 'ecm:tableColumns.documentStatus',
      isFixed: true,
    },
    {
      id: 'contact',
      isVisible: true,
      label: 'ecm:tableColumns.contact',
      isFixed: true,
    },
    {
      id: 'documentDate',
      isVisible: true,
      label: 'ecm:tableColumns.documentDate',
    },
    {
      id: 'documentType',
      isVisible: true,
      label: 'ecm:tableColumns.documentType',
    },
    showDocumentTags && {
      id: 'tags',
      isVisible: true,
      label: 'ecm:tableColumns.tags',
    },
    {
      id: 'documentName',
      isVisible: true,
      label: 'ecm:tableColumns.documentName',
    },
    {
      id: 'documentNumber',
      isVisible: true,
      label: 'ecm:tableColumns.documentNumber',
    },
    {
      id: 'notes',
      isVisible: true,
      label: 'ecm:tableColumns.notes',
    },
  ].filter((c): c is Configuration => Boolean(c));
};

export const useGetEcmDocumentsTableConfigs = ({ filters, sortBy }: Params) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();
  const defaultColumnsConfigurations = useDefaultColumnsConfigurations();
  const { configurations } = useGetTableConfigurations({
    tableId: archiveEcmDocumentsTableId,
    defaultColumnsConfigurations,
  });

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const { handleTrackHiddenColumns } = useTrackConfigurations();

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: archiveEcmDocumentsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
      handleTrackHiddenColumns(configurations, archiveEcmDocumentsTableId);
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: archiveEcmDocumentsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  const visibleColumnIds = configurations
    .filter(config => config.isVisible)
    .map(config => config.id as keyof EcmDocumentsTableData);

  useUpdateColumnsBE({
    tableId: archiveEcmDocumentsTableId,
    defaultColumnsConfigurations,
  });

  return {
    availableDocumentColumnIds: visibleColumnIds,
    configurationsTable: configurations,
    isResetPending,
    isSavingConfigurations,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  };
};
