import { TypePolicies, FieldPolicy } from '@apollo/client';
import {
  ReimbursementCasesConnection,
  ReimbursementCasesConnectionPageBased,
} from 'generated-types/graphql.types';
import {
  paginationWithRecordConnectionsMerge,
  simplePaginationMergeWithoutDuplicates,
} from '../pagination-results-merge';

const reimbursementCasesPageBasedRecords: FieldPolicy<ReimbursementCasesConnectionPageBased> =
  {
    keyArgs: ['filters', 'sortBy'],
    merge: simplePaginationMergeWithoutDuplicates(
      'ReimbursementCasesConnectionPageBased'
    ),
  };

const reimbursementsListCursorPagination: FieldPolicy<ReimbursementCasesConnection> =
  {
    merge: paginationWithRecordConnectionsMerge('ReimbursementCasesConnection'),
    keyArgs: ['filters', 'sortBy', 'queries'],
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      reimbursementCasesPageBased: reimbursementCasesPageBasedRecords,
      reimbursementCasesCursorBased: reimbursementsListCursorPagination,
    },
  },
};
