import gql from 'graphql-tag';

export const getOrganizationsQuery = gql`
  query getOrganizations {
    getOrganizations {
      id
      name
      slug
      realmName
    }
  }
`;
