// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

// TODO: remove z-index in processing forms
// so that this z-index: 100 can be removed
export const ConsentManagerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 570px;
  margin: 0 auto;
  z-index: 98;
`;
