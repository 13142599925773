import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Skeleton,
  Text,
  TextField,
} from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { SapB1Settings } from 'generated-types/resolvers-types';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationPopoverDeprecated } from '../../../../../components/ConfirmationPopover/ConfirmationPopoverDeprecated';
import {
  useDeleteSapB1CredentialsMutation,
  useSapB1SettingsQuery,
  useVerifySapB1CredentialsQuery,
} from '../../../../../generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { InfoList } from '../../DATEV/ExportTypes/components/InfoList';
import { CheckProp } from '../../DATEV/ExportTypes/types';
import { refetchSapSettings } from '../queries';
import { SapFormValues } from './Form/SapConnectionForm';
import { fieldTranslationKeys, getChecks } from './utils';

const SapConnectionSummaryBase = ({
  CallToAction,
  MainContent,
  checks = [],
}: {
  CallToAction: ReactElement;
  MainContent: ReactElement;
  checks?: CheckProp[];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  return (
    <Card width="50.125rem">
      <Grid gap="space16">
        <Grid gap="space16">
          <Flex justifyContent="space-between">
            <Heading as="h3">{t('settings.form.description')}</Heading>
            {CallToAction}
          </Flex>
          {MainContent}
          {(checks || []).length ? <InfoList checks={checks} animate /> : null}
        </Grid>
      </Grid>
    </Card>
  );
};

export const SapConnectionSummarySuccess = ({
  values,
  onDisconnect,
  isDisconnecting,
  checks,
}: {
  values: Omit<SapFormValues, 'password'> & Pick<SapB1Settings, 'sapB1Version'>;
  onDisconnect: () => void;
  isDisconnecting: boolean;
  checks: CheckProp[];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  const fields = [
    {
      label: t(fieldTranslationKeys.serviceLayerUrl),
      value: values.serviceLayerUrl,
    },
    {
      label: t(fieldTranslationKeys.companyDbId),
      value: values.companyDbId,
    },
    {
      label: t(fieldTranslationKeys.username),
      value: values.username,
    },
    {
      label: t(fieldTranslationKeys.version),
      value: values.sapB1Version ?? '',
    },
  ];

  return (
    <SapConnectionSummaryBase
      checks={checks}
      CallToAction={
        <ConfirmationPopoverDeprecated
          title={t('settings.summary.disconnectCTA.confirmText')}
          closeText={t('settings.summary.disconnectCTA.confirmNo')}
          confirmText={t('settings.summary.disconnectCTA.confirmYes')}
          placement="top"
          onConfirm={onDisconnect}
          trigger={({ triggerProps, triggerRef }) => (
            <Button
              {...triggerProps}
              ref={triggerRef}
              variant="secondary"
              size="small"
              width="fit-content"
              loading={isDisconnecting}
              disabled={isDisconnecting}>
              {t('settings.summary.disconnectCTA.text')}
            </Button>
          )}
        />
      }
      MainContent={
        <Grid columns={2} columnGap="space20" rowGap="space8">
          {fields.map(f => {
            return (
              <TextField
                key={f.label}
                input={{
                  value: f.value,
                }}
                label={f.label}
                readOnly
              />
            );
          })}
        </Grid>
      }
    />
  );
};

export const SapConnectionSummaryLoading = ({
  checks,
}: {
  checks: CheckProp[];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  return (
    <SapConnectionSummaryBase
      checks={checks}
      CallToAction={
        <Button disabled>{t('settings.summary.disconnectCTA.text')}</Button>
      }
      MainContent={<Skeleton height="3rem" width="90%" />}
    />
  );
};

export const SapConnectionSummaryError = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  return (
    <SapConnectionSummaryBase
      CallToAction={
        <Button disabled>{t('settings.summary.disconnectCTA.text')}</Button>
      }
      MainContent={
        <Flex justifyContent="center">
          <Text color="red500">
            {t('settings.summary.credentialsNotFoundError')}
          </Text>
        </Flex>
      }
    />
  );
};

export const SapConnectionSummary = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  const { success, error } = useToastMessage();

  const { data, loading: loadingData } = useGetSapB1Credentials();

  const { data: sapB1Settings, loading: sapB1SettingsLoading } =
    useSapB1SettingsQuery();

  const { data: verify, loading: loadingVerify } =
    useVerifySapB1CredentialsQuery({
      // XXX we need to ensure that the credentials are verified properly every time the users visits this page
      // since the validity of the configuration could change in the external system
      fetchPolicy: 'no-cache',
    });

  const [deleteSapB1Credentials, { loading: isDisconnecting }] =
    useDeleteSapB1CredentialsMutation({
      refetchQueries: refetchSapSettings,
    });

  const config = data?.sapB1Credentials;

  const isLoading = loadingData || loadingVerify || sapB1SettingsLoading;

  if (isLoading) {
    return <SapConnectionSummaryLoading checks={[getChecks(t).loadingCheck]} />;
  }

  const hasError =
    !config?.companyDbId || !config?.username || !config?.serviceLayerUrl;

  if (hasError) {
    return <SapConnectionSummaryError />;
  }

  const values = {
    companyDbId: config.companyDbId,
    username: config.username,
    serviceLayerUrl: config.serviceLayerUrl,
    sapB1Version: sapB1Settings?.sapB1Settings?.sapB1Version,
  };

  const onDisconnect = async () => {
    const { data } = await deleteSapB1Credentials();

    if (!data?.deleteSapB1Credentials) {
      success(t('settings.summary.disconnect.success'));
    } else {
      error(t('settings.summary.disconnect.errors.unexpected'));
    }
  };

  const checks = verify?.verifySapB1Credentials?.code
    ? [getChecks(t).errorCheck]
    : [getChecks(t).successCheck];

  return (
    <SapConnectionSummarySuccess
      isDisconnecting={isDisconnecting}
      onDisconnect={onDisconnect}
      values={values}
      checks={checks}
    />
  );
};
