import { useTheme } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { ContactType } from 'generated-types/graphql.types';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'views/Contacts/ContactDetails/ContactForm/ContactTypeFields/utils';
import { ContactFormValues } from '../contactFormSchema';

export interface ContactInternalNameFieldProps {
  showField?: boolean;
}

export const ContactInternalNameField = ({
  showField,
}: ContactInternalNameFieldProps) => {
  const [t] = useTranslation();
  const { colors } = useTheme();

  const { setValue, formState } = useFormContext<ContactFormValues>();

  const [
    companyName,
    individualFirstName,
    individualLastName,
    notSpecifiedName,
    contactType,
  ] = useWatch<
    ContactFormValues,
    (
      | 'companyName'
      | 'individualFirstName'
      | 'individualLastName'
      | 'notSpecifiedName'
      | 'contactType'
    )[]
  >({
    name: [
      'companyName',
      'individualFirstName',
      'individualLastName',
      'notSpecifiedName',
      'contactType',
    ],
  });

  useEffect(() => {
    const setInternalNameBasedOnAdditionalNames = () => {
      switch (contactType) {
        case ContactType.Company:
          if (formState.isDirty) {
            setValue('name', companyName ?? '', {
              shouldValidate: true,
            });
          }

          setValue('individualLastName', companyName ?? '', {
            shouldValidate: false,
          });
          setValue('notSpecifiedName', companyName ?? '', {
            shouldValidate: false,
          });
          break;

        case ContactType.Individual:
          const fullName = getFullName(individualFirstName, individualLastName);
          if (formState.isDirty) {
            setValue('name', fullName ?? '', {
              shouldValidate: true,
            });
          }

          setValue('companyName', fullName ?? '', {
            shouldValidate: false,
          });
          setValue('notSpecifiedName', fullName ?? '', {
            shouldValidate: false,
          });
          break;
        case ContactType.NotSpecified:
          if (formState.isDirty) {
            setValue('name', notSpecifiedName ?? '', {
              shouldValidate: true,
            });
          }

          setValue('individualLastName', notSpecifiedName ?? '', {
            shouldValidate: false,
          });
          setValue('companyName', notSpecifiedName ?? '', {
            shouldValidate: false,
          });
          break;
        default:
          // do nothing
          break;
      }
    };

    setInternalNameBasedOnAdditionalNames();
  }, [
    companyName,
    contactType,
    individualFirstName,
    individualLastName,
    notSpecifiedName,
    setValue,
    formState.isDirty,
  ]);

  return (
    <>
      {showField && (
        <HookFormTextField<ContactFormValues>
          autoFocus
          name="name"
          label={t('settings.contacts.details.edit.internalName.label')}
          readOnly
          inputProps={{
            style: {
              color: colors.gray500, // TODO this should be possible from top lvl prop
            },
            autoComplete: 'off',
          }}
        />
      )}
    </>
  );
};
