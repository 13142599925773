import { ApolloError } from '@apollo/client';
import {
  CostCenterAlreadyExists,
  CostCenterCreated,
  CostCenterUpdated,
  CreateCostCenterInput,
  UpdateCostCenterInput,
  useCreateCostCenter_NewMutation,
  useUpdateCostCenter_NewMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { useCostCenterRefetchQueries } from '../../hooks/useCostCenterRefetchQueries';
import { costCenterQuery } from '../../queries';

type SaveCostCenterInput = CreateCostCenterInput | UpdateCostCenterInput;

type SaveCostCenterResult =
  | CostCenterAlreadyExists
  | CostCenterUpdated
  | CostCenterCreated
  | null;

interface SaveCostCentersCallback {
  loading: boolean;
  errors?: ApolloError[] | null;
}

type CostCenterSaveFn = (
  costCenterId: string | null,
  input: SaveCostCenterInput
) => Promise<SaveCostCenterResult>;

type UseSaveCostCentersHook = [CostCenterSaveFn, SaveCostCentersCallback];

export const useSaveCostCenter = (): UseSaveCostCentersHook => {
  const { refetchQueries, resetPaginationResults } =
    useCostCenterRefetchQueries();

  const [updateCostCenter, { loading: loadingUpdate, error: errorUpdate }] =
    useUpdateCostCenter_NewMutation({});

  const [createCostCenter, { loading: loadingCreate, error: errorCreate }] =
    useCreateCostCenter_NewMutation({
      refetchQueries,
      onCompleted: resetPaginationResults,
    });

  const doSave = useCallback<CostCenterSaveFn>(
    async (costCenterId, input) => {
      if (costCenterId) {
        const updateResult = await updateCostCenter({
          variables: {
            id: costCenterId,
            input: input as UpdateCostCenterInput,
          },
          refetchQueries: result => {
            if (
              result.data?.updateCostCenter_new.__typename ===
              'CostCenterUpdated'
            )
              return [
                {
                  query: costCenterQuery,
                  variables: {
                    input: { id: result.data?.updateCostCenter_new.id },
                  },
                },
              ];

            return [];
          },
        });

        return updateResult.data?.updateCostCenter_new || null;
      }

      const createResult = await createCostCenter({
        variables: { input: input as CreateCostCenterInput },
      });

      return createResult.data?.createCostCenter_new || null;
    },
    [updateCostCenter, createCostCenter]
  );

  const loading = loadingUpdate || loadingCreate;
  const errors = [
    ...(errorUpdate ? [errorUpdate] : []),
    ...(errorCreate ? [errorCreate] : []),
  ];

  return [doSave, { loading, errors }];
};

export const isCreated = (result: SaveCostCenterResult): boolean =>
  !!result?.__typename && result.__typename === 'CostCenterCreated';

export const isUpdated = (
  result: SaveCostCenterResult
): result is CostCenterUpdated =>
  !!result?.__typename && result.__typename === 'CostCenterUpdated';

export const isAlreadyExists = (result: SaveCostCenterResult): boolean =>
  !!result?.__typename && result.__typename === 'CostCenterAlreadyExists';
