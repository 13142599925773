import { Button, Card, Grid, Image, Text } from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { CSSProperties, ReactNode } from 'react';

type PromotionPanelProps = {
  thumbnails: {
    EN: string;
    DE: string;
  };
  slots: {
    header: string;
    description: ReactNode;
    button: string;
  };
  onLearnMoreClick?: () => void;
  imageStyle?: CSSProperties;
};

export const PromotionPanel = ({
  thumbnails,
  slots,
  onLearnMoreClick,
  imageStyle = {},
}: PromotionPanelProps) => {
  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[locale];

  return (
    <Grid autoFlow="column" templateColumns="312px auto">
      <Grid
        background="purple200"
        borderRadius="none"
        borderTopLeftRadius="medium"
        borderBottomLeftRadius="medium"
        alignContent="center"
        justifyContent="center"
        height="200px">
        <Image src={thumbnail} alt="cards" height="190px" style={imageStyle} />
      </Grid>
      <Grid>
        <Card
          background="purple100"
          borderRadius="none"
          borderTopRightRadius="medium"
          borderBottomRightRadius="medium"
          height="200px">
          <Grid alignItems="center" height="100%">
            <Text fontSize="large" color="gray800">
              {slots.header}
            </Text>
            <Text fontSize="small" color="gray700">
              {slots.description}
            </Text>
            <Button
              width="max-content"
              variant="primary"
              color="purple"
              onClick={onLearnMoreClick}>
              {slots.button}
            </Button>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
