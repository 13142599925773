import {
  useSapB1SettingsQuery,
  useUpdateSapB1SettingsMutation,
} from 'generated-types/graphql.types';
import { refetchSapB1Settings } from 'views/Settings/CreditCards/gql';

export const useUpdateSapB1Settings = () => {
  const { data: sapB1Settings = {} } = useSapB1SettingsQuery();

  const [updateSapB1SettingsMutation, { loading: isLoading }] =
    useUpdateSapB1SettingsMutation(); // actually i think isUpdating is more suitable name for a mutation, isLoading for fetching data

  const onUpdateSapB1Settings = async ({
    creditCardId,
    creditCardGLA,
    moneyTransitAccountNumber,
  }: {
    creditCardId?: number;
    creditCardGLA?: string;
    moneyTransitAccountNumber?: string;
  }) => {
    if (!sapB1Settings.sapB1Settings) return;

    const { __typename: _, ...restProps } = sapB1Settings.sapB1Settings;

    const settings = {
      ...restProps,
      ...(creditCardId && {
        creditCardId: Number(creditCardId),
      }),

      ...(creditCardGLA && {
        creditCardGeneralLedgerAccount: creditCardGLA,
      }),

      ...(moneyTransitAccountNumber && {
        moneyInTransitGeneralLedgerAccount: moneyTransitAccountNumber,
      }),
    };

    const result = await updateSapB1SettingsMutation({
      variables: {
        sapB1Settings: settings,
      },
      refetchQueries: refetchSapB1Settings,
    });

    return result;
  };

  return { isLoading, onUpdateSapB1Settings, sapB1Settings };
};
