import { useEffect, useState } from 'react';
import { AuthService } from 'services/AuthService';
import {
  BROADCAST_CHANNEL_NAME,
  isReauthenticationSucceededMessage,
} from './postMessage';

export const useReauthSucceededListener = () => {
  const [hasReauthenticated, setHasReauthenticated] = useState(false);

  useEffect(() => {
    const onReauthSucceeded = async (event: MessageEvent) => {
      if (isReauthenticationSucceededMessage(event) && !hasReauthenticated) {
        // Re-initialise the KeycloakJS instance by reading the access token
        // from local storage that was saved from within the popup window.
        setHasReauthenticated(true);
        await AuthService.authorizedToken(-1);
      }
    };

    const bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
    bc.onmessage = onReauthSucceeded;
  }, [hasReauthenticated]);

  return hasReauthenticated;
};
