import gql from 'graphql-tag';

export const teamsQuery = gql`
  query teams(
    $input: TeamsPageBasedPaginationInput!
    $queries: TeamQueryInput
    $filters: TeamsFilterInput
    $sortBy: TeamsSortInput
  ) {
    teams(
      input: $input
      queries: $queries
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        id
        name
        isArchived
        members {
          membership {
            id
            firstName
            lastName
            avatarUrl
          }
          isAdmin
        }
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const teamQuery = gql`
  query team($id: ID!) {
    team(id: $id) {
      id
      name
      isArchived
      updatedAt
      members {
        membership {
          id
          firstName
          lastName
          avatarUrl
        }
        isAdmin
      }
    }
  }
`;

export const teamsByNameQuery = gql`
  query teamsByName($input: TeamsPageBasedPaginationInput!, $name: String) {
    teamsByName: teams(input: $input, filters: { name: $name })
      @connection(key: "teamsByName", filter: ["filters"]) {
      records {
        id
        name
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const teamSettingsForCreditCardsQuery = gql`
  query teamSettingsForCreditCards($input: TeamSettingsForCreditCardsInput!) {
    teamSettingsForCreditCards(input: $input) {
      teamAdminCanManageCards
      linkedCardIds
      limitThresholds {
        VIRTUAL {
          value
          currency
          precision
        }
        PHYSICAL {
          value
          currency
          precision
        }
        SINGLE_USE {
          value
          currency
          precision
        }
      }
    }
  }
`;

export const countTeamsQuery = gql`
  query countTeams($filters: TeamsFilterInput) {
    countTeams(filters: $filters) {
      total
    }
  }
`;
