import { Avatar, Image } from '@candisio/design-system';
import candisImage from 'assets/icon-history-candis.svg';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { BaseItem, BaseItemProps } from './BaseItem';

const StyledBaseItem = styled(BaseItem)`
  ${p =>
    p.size === 'big'
      ? `
    .ant-timeline-item-head {
      width: 2rem;
      height: 2rem;
      top: 1.5rem;
    }
    .ant-timeline-item-tail {
      top: 2.5rem;
      height: calc(100% - 1.7625rem);
    }
  `
      : `
    .ant-timeline-item-head {
      width: 1.5rem;
      height: 1.5rem;
      top: 1.625rem;
    }
    .ant-timeline-item-tail {
      top: 2.5rem;
      height: calc(100% - 1.25rem);
    }
  `}
  ${p =>
    p.hideTail &&
    `.ant-timeline-item-tail {
        display: none;
    }
  `}
`;

export const User = ({
  src,
  avatarUrl,
  author,
  wasImpersonated,
  ...rest
}: BaseItemProps) => {
  const [t] = useTranslation();
  const isSystemUser = author?.toLowerCase().localeCompare('candis') === 0; // TODO TG-1561: This 'CANDIS' string comes from backend api, it would be good to have it in generated types

  const props = src
    ? { ...rest, author, src, wasImpersonated }
    : {
        ...rest,
        author,
        wasImpersonated,
        customDot: isSystemUser ? (
          <Image
            alt={t('document.compact.history.shared.systemUser')}
            src={candisImage}
            width="18px"
            height="18px"
          />
        ) : (
          <Avatar name={author} img={avatarUrl} hasTooltip={false} />
        ),
      };

  return isSystemUser ? <BaseItem {...props} /> : <StyledBaseItem {...props} />;
};
