import { Box, Flex, Grid, Icon, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useExportContext } from 'views/Integrations/Export/Context';
import creditCardIcon from 'views/Integrations/Export/toolkit/images/icon-credit-card.svg';
import { Entity, Table } from 'views/Integrations/Export/types';

const translationMap = {
  [Table.APPROVALS]: {
    iconText: 'export.notReady.transactionIconText',
    infoText: 'export.notReady.transactionInfoText',
  },
  [Table.SETTLEMENTS]: {
    iconText: 'export.notReady.settlementIconText',
    infoText: 'export.notReady.settlementInfoText',
  },
} as const;

export const NotReady = () => {
  const [t] = useTranslation();
  const { entity, table } = useExportContext();

  if (entity !== Entity.TRANSACTION) return null;
  if (table !== Table.APPROVALS && table !== Table.SETTLEMENTS) return null;

  return (
    <Grid
      rowGap="space8"
      background="white"
      padding="space16"
      templateRows="1fr auto"
      alignItems="center">
      <Flex
        alignItems="center"
        justifyContent="center"
        direction="column"
        maxWidth="100%"
        textAlign="center">
        <img src={creditCardIcon} alt="" />
        <Text color="gray500" whiteSpace="pre-line">
          {t(translationMap[table].iconText)}
        </Text>
      </Flex>
      <Flex
        background="bluebg"
        paddingX="space8"
        paddingY="space12"
        borderRadius="medium"
        justifyContent="space-between">
        <Box paddingX="space10">
          <Icon icon="infoCircle" color="blue500" size="space12" />
        </Box>
        <Text>{t(translationMap[table].infoText)}</Text>
      </Flex>
    </Grid>
  );
};
