import { ProvisionEntityType } from 'generated-types/graphql.types';
import { useContext } from 'react';
import { ExportContext } from '../../Context';
import { DocumentType, ExportEntity, TimeFrameOption } from '../../types';

type UseGetReadyToExportCountByTimeframe = {
  selectedTimeFrame?: TimeFrameOption;
  entities: ExportEntity[];
  contacts?: number;
  paymentConditions?: number;
};

export const useGetReadyToExportCountByTimeframe = ({
  selectedTimeFrame,
  entities,
  contacts,
  paymentConditions,
}: UseGetReadyToExportCountByTimeframe) => {
  const { selectedProvisions: selectedProvisionsIds } =
    useContext(ExportContext);

  const selectedProvisions = entities.filter(ety =>
    selectedProvisionsIds?.includes(ety.id)
  );

  return {
    [DocumentType.Document]: selectedTimeFrame
      ? selectedTimeFrame.numberOfDocuments
      : 0,
    [DocumentType.CardTransaction]: selectedTimeFrame
      ? selectedTimeFrame.numberOfTxnBasedDocuments +
        selectedTimeFrame.numberOfTxnWithoutDocuments
      : 0,
    [DocumentType.CardSettlement]: selectedTimeFrame
      ? selectedTimeFrame.numberOfSettlements
      : 0,
    [DocumentType.DocumentProvision]: selectedProvisions.filter(
      prov => prov.provisionEntityType === ProvisionEntityType.Document
    ).length,
    [DocumentType.TransactionProvision]: selectedProvisions.filter(
      prov => prov.provisionEntityType === ProvisionEntityType.Transaction
    ).length,
    [DocumentType.DocumentReversal]: selectedTimeFrame
      ? selectedTimeFrame.numberOfDocumentReversals
      : 0,
    [DocumentType.TransactionReversal]: selectedTimeFrame
      ? selectedTimeFrame.numberOfTxnReversals
      : 0,
    [DocumentType.Contacts]: contacts ?? 0,
    [DocumentType.PaymentConditions]: paymentConditions ?? 0,
  };
};
