import {
  ASSOCIATION,
  OnAssociateDocument,
  OnDeleteFile,
} from 'views/TransactionAssociation/types';
import { ActionsWrapper } from '../styles';
import { DocumentFile } from './DocumentFile';
import { NoFile } from './NoFile';
import { UploadedFile } from './UploadedFile';

interface BottomActionBoxProps {
  associationState: ASSOCIATION;
  isDeletingLoading: boolean;
  isProcessingLoading: boolean;
  onAssociateDocument: OnAssociateDocument;
  onDeleteFile: OnDeleteFile;
}

export const BottomActionBox = ({
  associationState,
  isDeletingLoading,
  isProcessingLoading,
  onAssociateDocument,
  onDeleteFile,
}: BottomActionBoxProps) => {
  const renderActions = () => {
    if (associationState === ASSOCIATION.NONE) {
      return <NoFile />;
    } else if (associationState === ASSOCIATION.DOCUMENT) {
      return <DocumentFile />;
    } else {
      return (
        <UploadedFile
          isProcessingLoading={isProcessingLoading}
          isDeletingLoading={isDeletingLoading}
          onAssociateDocument={onAssociateDocument}
          onDeleteFile={onDeleteFile}
        />
      );
    }
  };

  return <ActionsWrapper>{renderActions()}</ActionsWrapper>;
};
