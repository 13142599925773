/**
 * We take MIN and MAX payment condition numbers from Datev
 * @see https://apps.datev.de/help-center/documents/9211814
 */
export const PAYMENT_CONDITION_NUMBER = {
  MIN: 10,
  MAX: 999,
};

/**
 * Elasticsearch supports queries up to 128 characters
 */
export const MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH = 128;

export const NOTE_FIELD_MAX_LENGTH = 120;
export const POSTING_TEXT_FIELD_MAX_LENGTH = 60;

/**  see TSB-260, TSB-3015 */
export const MAX_PAYABLE_DOCUMENT_COUNT = 850;
