import { Nullable } from 'utils/extractors';
import { PAYMENT_CONDITION_NUMBER } from 'views/consts';
import { z } from 'zod';

export const DATE_OFFSET = { MIN: 0, MAX: 999 };
export const DISCOUNT_PERCENTAGE = { MIN: 0.01, MAX: 99.99 };

const formSchema = z.object({
  conditionNumber: z
    .number()
    .int()
    .gte(PAYMENT_CONDITION_NUMBER.MIN)
    .lte(PAYMENT_CONDITION_NUMBER.MAX)
    .optional(),
  dueDateOffset: z.number().int().gte(DATE_OFFSET.MIN).lte(DATE_OFFSET.MAX),
  discountPercentage: z
    .number()
    .gte(DISCOUNT_PERCENTAGE.MIN)
    .lte(DISCOUNT_PERCENTAGE.MAX)
    .nullable(),
  discountOffset: z
    .number()
    .int()
    .gte(DATE_OFFSET.MIN)
    .lte(DATE_OFFSET.MAX)
    .nullable(),
});

const discountSchema = formSchema
  .pick({
    discountPercentage: true,
    discountOffset: true,
  })
  .superRefine(({ discountOffset, discountPercentage }, ctx) => {
    if (discountOffset === null && discountPercentage !== null) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: 'number',
        received: 'null',
        path: ['discountOffset'],
      });
    } else if (discountPercentage === null && discountOffset !== null) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: 'number',
        received: 'null',
        path: ['discountPercentage'],
      });
    }
  });

const dateSchema = formSchema
  .pick({
    dueDateOffset: true,
    discountOffset: true,
  })
  .superRefine(({ dueDateOffset, discountOffset }, ctx) => {
    if (discountOffset !== null && discountOffset >= dueDateOffset) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['discountOffset'],
      });
    }
  });

export const paymentConditionsFormSchema = formSchema
  .and(discountSchema)
  .and(dateSchema);

export type PaymentConditionsFormOutput = z.infer<
  typeof paymentConditionsFormSchema
>;
export type PaymentConditionsFormValues = Nullable<PaymentConditionsFormOutput>;
