import { Card, Flex, Grid, Skeleton } from '@candisio/design-system';
import { Limits } from 'containers/credit-cards/CreditCardCarousel/components/Limits';

export const LoadingCardDetails = () => {
  return (
    <Card background="gray100">
      <Grid templateColumns="auto 1fr" gap="space20">
        <Card
          position="relative"
          background="gray800"
          borderRadius="medium"
          width="180px"
          height="280px"
          boxShadow="none">
          <Skeleton width="4rem" height="3rem" justifySelf="end" />
          <Flex direction="column" top={40} gap="0.5rem">
            <Skeleton width="5rem" height="1rem" />
            <Skeleton width="10rem" height="1rem" />
            <Flex justifyContent="space-between">
              <Skeleton width="4rem" height="1rem" />
              <Skeleton width="4rem" height="1rem" />
            </Flex>
          </Flex>
          <Skeleton
            width="4rem"
            height="2rem"
            position="absolute"
            bottom="space8"
            right="space8"
          />
        </Card>
        <Grid gap="space16" templateRows="auto 1fr" height="fit-content">
          <Skeleton height="space24" width="12rem" />
          <Grid>
            <Grid gap="space8">
              <Skeleton height="2.325rem" width="4.5rem" />
              <Skeleton height="space14" width="100%" borderRadius="medium" />
            </Grid>
            <Limits
              transactionLimit={{ value: 0, currency: 'EUR' }}
              limit={{ value: 0, currency: 'EUR' }}
              loading={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid autoFlow="column" gap="space16" placeItems="end" placeContent="end">
        {Array.from({ length: 4 }, (_, index) => (
          <Skeleton key={index} height="2.325rem" width="4.5rem" />
        ))}
      </Grid>
    </Card>
  );
};
