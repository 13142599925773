import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaymentConditionItem } from 'views/Contacts/ContactDetails/ContactForm/PaymentConditionItem';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { NONE_PAYMENT_CONDITION } from './ProcessingFormPaymentFields';
import { ProcessingFormValues } from './processingFormSchema';
import {
  ProcessingFormSelectField,
  ProcessingFormSelectFieldProps,
} from './ProcessingFormSelectField';
import { ProcessingFormTypeFieldItem } from './ProcessingFormTypeField';

export interface ProcessingFormPaymentConditionFieldItem {
  conditionNumber: number;
  discountOffset?: number;
  discountPercentage?: number;
  dueDateOffset: number;
  key: string;
}

export interface ProcessingFormPaymentConditionFieldProps
  extends ProcessingFormSelectFieldProps {
  typeFieldItems?: ProcessingFormTypeFieldItem[];
  items?: ProcessingFormPaymentConditionFieldItem[];
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean;
}

/**
 * A `ProcessingFormSelectField` for selecting a payment condition
 *
 * Returns `null` if direction of currently-selected document type is not
 * incoming
 */
export const ProcessingFormPaymentConditionFieldDefault = ({
  items = [],
  typeFieldItems = [],
  ...restProps
}: ProcessingFormPaymentConditionFieldProps) => {
  const [t] = useTranslation();

  const type = useWatch<ProcessingFormValues, 'type'>({ name: 'type' });
  const typeItem = typeFieldItems.find(({ key }) => key === type);

  if (typeItem?.direction !== DocumentDirection.invoices_received) {
    return null;
  }

  return (
    <ProcessingFormSelectField
      items={[
        {
          key: NONE_PAYMENT_CONDITION,
          children: t(
            'settings.contacts.details.edit.paymentCondition.options.none'
          ),
        },
        ...items.map(
          ({
            conditionNumber,
            discountOffset,
            discountPercentage,
            dueDateOffset,
            key,
          }) => ({
            key,
            textValue: conditionNumber,
            children: (
              <PaymentConditionItem
                conditionNumber={conditionNumber}
                discountOffset={discountOffset}
                discountPercentage={discountPercentage}
                dueDateOffset={dueDateOffset}
                id={key}
              />
            ),
          })
        ),
      ]}
      transform={{
        input: value => value ?? 'none',
        output: value => (value === 'none' ? null : value),
      }}
      {...restProps}
    />
  );
};
