import { useUpdateWaitingForClarificationMutation } from 'generated-types/graphql.types';
import { useState } from 'react';
import { documentHistoryQuery } from 'views/queries';
import { documentQueries } from './queries';

export const useWaitingForClarificationCheckbox = (
  documentId: string,
  isWaitingForClarification: boolean
) => {
  const [selected, setSelected] = useState<boolean>(isWaitingForClarification);
  const [updateWaitingForClarificationMutation] =
    useUpdateWaitingForClarificationMutation({
      refetchQueries: [
        {
          query: documentQueries.forDraftForm,
          variables: { id: documentId },
        },
        {
          query: documentHistoryQuery,
          variables: { id: documentId },
        },
      ],
    });

  const invokeMutation = (isSelected: boolean | null = null) =>
    updateWaitingForClarificationMutation({
      variables: { documentId, isWaiting: isSelected ?? selected },
    });

  const handleChange = (isSelected: boolean) => {
    setSelected(isSelected);
    void invokeMutation(isSelected);
  };

  return {
    selected,
    setSelected,
    handleChange,
    invokeMutation,
  };
};
