import { GraphQLFormattedError } from 'graphql';

export enum GQLErrorTypes {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export type GQLError = {
  graphQLErrors?: GraphQLFormattedError[];
  extraInfo?: any;
  message: string;
  newtorkError?: any | null;
};

/**
 * TODO: try to use this type
 */
// type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
//   { [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>> }[Keys];
