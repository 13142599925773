import { Card, Grid, ScrollBox, Skeleton } from '@candisio/design-system';

export const LoadingReimbursementSection = () => {
  return (
    <ScrollBox scrollDirection="none" scrollbarGutter="stable">
      <Card padding="space24" height="120.5px">
        <Grid templateColumns="auto 1fr" gap="space24" top={5}>
          <Skeleton height="65px" width="space64" />
          <Grid templateRows="auto auto auto" gap="space3">
            <Skeleton height="100%" width="space64" />
            <Skeleton height="space24" width="100%" />
            <Skeleton height="100%" width="space80" justifySelf="end" />
          </Grid>
        </Grid>
      </Card>
    </ScrollBox>
  );
};
