export type MembershipInvolvedResponse = { _tag: 'INVOLVED' };
export type MembershipOnlyAdminResponse = { _tag: 'ONLY_ADMIN' };
export type MembershipPendingApprovalsResponse = {
  _tag: 'PENDING_APPROVALS';
  numberOfPendingApprovals: number;
};

export function isInvolvedResponse(
  response: any
): response is MembershipInvolvedResponse {
  return response?._tag === 'INVOLVED';
}

export function isOnlyAdminResponse(
  response: any
): response is MembershipOnlyAdminResponse {
  return response?._tag === 'ONLY_ADMIN';
}

export function isPendingApprovalsResponse(
  response: any
): response is MembershipPendingApprovalsResponse {
  return response?._tag === 'PENDING_APPROVALS';
}

export function isSuccess(response: unknown): response is void {
  return typeof response === 'undefined';
}
