import { Button, Grid, Paragraph, Text } from '@candisio/design-system';

type Props = {
  title: string;
  infoText: string;
  cancelLabel: string;
  confirmLabel: string;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmationPopover = ({
  title,
  infoText,
  cancelLabel,
  confirmLabel,
  onConfirm,
  onClose,
}: Props) => {
  return (
    <Grid gap="space16" padding="space4" width="350px">
      <Grid gap="space8">
        <Text fontWeight="bold">{title}</Text>
        <Paragraph>{infoText}</Paragraph>
      </Grid>
      <Grid autoFlow="column" gap="space16" justifyContent="end">
        <Button variant="secondary" onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button color="red" onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </Grid>
    </Grid>
  );
};
