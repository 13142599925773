import {
  Flex,
  Link,
  Paragraph,
  Text,
  CustomEmptyStateProps,
} from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// Use v5 version of useParams because we’re inside a v5 route
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { InboxDocumentsEmptyStateLayout } from './InboxDocumentsEmptyStateLayout';

interface InboxDocumentsEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
}

const TableIsEmpty = () => {
  const { isOnlyApprover } = useUserRoles();
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const navigate = useNavigate();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();

  const dispatch = useDocumentUploadDispatch();

  const emailImportLink = () => {
    navigate(`/${organizationSlug}${Routes.SETTINGS}${Routes.MAIL_SYNC}`);
  };

  return (
    <Flex direction="column" alignItems="center" gap="space16">
      <Paragraph
        textAlign="center"
        width="12rem"
        minWidth="10rem"
        fontSize="basic"
        fontWeight="semibold"
        wordBreak="break-word">
        {t('emptyState.content.allDoneInbox')}
      </Paragraph>
      {!isOnlyApprover && (
        <Paragraph textAlign="center">
          <Trans i18nKey="emptyState.content.upload" t={t}>
            You can
            <Link as="button" onClick={() => dispatch('showModal')}>
              upload
            </Link>
            <br />
            or
            <Link as="button" onClick={emailImportLink}>
              import documents using the E-Mail import
            </Link>
          </Trans>
        </Paragraph>
      )}
    </Flex>
  );
};

const TableIsFilteredAndEmpty = ({
  resetFilters,
}: {
  resetFilters: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t('emptyState.content.filtered')}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('emptyState.resetFilters')}
      </Link>
    </>
  );
};

export const InboxDocumentsEmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: InboxDocumentsEmptyStateProps) => {
  const isFilteredAndEmpty = isTableEmpty && isTableFiltered;

  return (
    <InboxDocumentsEmptyStateLayout isTableFiltered={isTableFiltered}>
      {isFilteredAndEmpty ? (
        <TableIsFilteredAndEmpty resetFilters={resetFilters} />
      ) : (
        <TableIsEmpty />
      )}
    </InboxDocumentsEmptyStateLayout>
  );
};
