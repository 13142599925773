export const TransactionsTrackingEvents = {
  YES_UNLINK_TRANSACTION_FROM_DOCUMENT_CLICKED:
    'yes_unlink_transaction_from_document_clicked',
  ADD_TO_DOCUMENT_BTN_CLICKED: 'add_to_document_btn_clicked',
  ADD_TO_TRANSACTION_BTN_CLCIKED: 'add_to_transaction_btn_clicked',
  REQUEST_APPROVAL_BTN_CLCIKED: 'request_approval_btn_clicked',
  TRANSACTIONS_TABLE_FILTERS_APPLIED: 'transactions_table_filters_applied',
  TRANSACTIONS_TABLE_SORTING_APPLIED: 'transaction_table_sorting_applied',
  INVOICE_NOT_NEEDED_CONFIRMED: 'Invoice_not_needed_confirmed',
  AUTO_MATCHING: {
    VIEW_IN_ARCHIVE_CLICKED:
      'auto_matching_banner_view_in_archive_link_clicked',
  },
};
