import { ContactImportFormErrorMessages } from 'views/Contacts/ContactImport/contactImportFormSchema';

export const contactImportFormErrorMessages: ContactImportFormErrorMessages = {
  file: {
    label: '',
  },
  'file.type': {
    label: 'contacts:import.fileSelectionStep.validation.file.type',
  },
  'file.size': {
    label:
      'contacts:import.fileSelectionStep.validation.wrongFileFormat.file.size',
  },
};
