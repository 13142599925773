import {
  AvatarStack,
  Button,
  Card,
  Flex,
  Grid,
  Text,
} from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import {
  BuiltinRoleName,
  MembershipStatus,
  useCancelCardRequestMutation,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { CCWalletTrackingEvents } from 'providers/AnalyticsProvider/events/CCWalletTrackingEvents';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import DateFormatters from 'utils/date_formatter';
import {
  useGetUsers,
  User,
} from 'views/Settings/TeamMembers/hooks/useGetUsers';
import {
  useGenericErrorMessageHandler,
  useGetCardById,
} from '../../../../utils';

const maxNumberAvatarCircle = 4;

type RequestedProps = {
  onCancelRequest: () => void;
  requestedAt: Date;
  cancelRequestLoading: boolean;
  activeCardManagers: User[];
};

export const Requested = ({
  onCancelRequest,
  requestedAt,
  cancelRequestLoading,
  activeCardManagers,
}: RequestedProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Flex
      direction="column"
      gap="space8"
      height="260px"
      justifySelf="end"
      justifyContent="space-between">
      <Card
        background="gray200"
        borderRadius="8px"
        width="space256"
        alignSelf="end"
        boxShadow="none">
        <Grid gap="space12">
          <Text color="gray800" fontSize="space12">
            {t('carousel.requested.infoPanel', {
              requestedAt: DateFormatters.compact(requestedAt),
            })}
          </Text>
          <Grid
            gap="space2"
            templateColumns={
              activeCardManagers.length >= maxNumberAvatarCircle
                ? 'space80 1fr'
                : 'auto auto'
            }>
            <AvatarStack size="small" alignSelf="baseline">
              {activeCardManagers.map(cardManager => (
                <AvatarWithStatusContainer
                  key={cardManager.id}
                  hasTooltip
                  img={cardManager?.avatarUrl ?? undefined}
                  name={cardManager?.name}
                  size="small"
                  userId={cardManager?.id}
                />
              ))}
            </AvatarStack>
            <Text
              fontSize="space12"
              color="gray600"
              style={{ hyphens: 'auto' }}>
              {t('carousel.requested.approvalByCardmanager')}
            </Text>
          </Grid>
        </Grid>
      </Card>
      <Button
        loading={cancelRequestLoading}
        size="small"
        alignSelf="end"
        variant="secondary"
        onClick={onCancelRequest}>
        {t('carousel.requested.cancelRequest')}
      </Button>
    </Flex>
  );
};

export const RequestedContainer = (props: { cardId: string }) => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const genericErrorMessageHandler = useGenericErrorMessageHandler();
  const { success } = useToastMessage();
  const { card, loading } = useGetCardById({ cardId: props.cardId });

  const { users } = useGetUsers({
    filters: {
      roleNames: [BuiltinRoleName.CardManager],
      status: MembershipStatus.Active,
      emailVerified: true,
    },
  });

  const history = useHistory();

  const organizationSlug = useOrganizationId();
  const { track } = useAnalytics();

  const { refetchQueries } = useCreditCardsRefetchQueries();

  const options = {
    refetchQueries: refetchQueries.cardIssuerCards(props.cardId),
    onError: () => genericErrorMessageHandler(),
  };

  const [onCancelRequest, { loading: cancelRequestLoading }] =
    useCancelCardRequestMutation(options);

  const createdAt = card?.pendingRequest?.createdAt;

  if (!createdAt || loading) {
    // XXX introduce proper loading state
    return null;
  }

  return (
    <Requested
      onCancelRequest={() => {
        if (card?.pendingRequest?.id) {
          void onCancelRequest({
            variables: {
              input: {
                cardRequestId: card?.pendingRequest?.id,
              },
            },
            onCompleted: () => {
              history.push(`/${organizationSlug}${Routes.DASHBOARD}`);
            },
          });
          success(tTransactions('carousel.requested.cancelSuccess'));

          track(CCWalletTrackingEvents.CANCEL_CREDIT_CARD_REQUEST_BTN_CLICKED, {
            organizationSlug,
            source: 'canceled by user from carousel',
            cardType: card?.type,
            limitRenewFrequency: card.limitRenewFrequency,
            limit: card?.limit?.value,
            transactionLimit:
              card?.pendingRequest?.requestedTransactionLimit.value,
          });
        }
      }}
      requestedAt={new Date(createdAt)}
      cancelRequestLoading={cancelRequestLoading}
      activeCardManagers={users}
    />
  );
};
