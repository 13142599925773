import { Image } from '@candisio/design-system';
import { PaginationWithSearchFilterHook } from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  CardBrand,
  CardIssuerCardSortField,
  CardStatus,
  GetCardsInfiniteScrollPaginationQuery,
  GetCardsInfiniteScrollPaginationQueryVariables,
  SortDirection,
  useGetPageBasedCardIssuerCardsQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { cardTypes } from '../CardManagerView/components/CreditCardsTable/Cells/CardNumberCell';
import { getCardsInfiniteScrollPaginationQuery } from '../CreditCardsInsights/gql';
import { CreditCardFilterMenuItem } from '../CreditCardsInsights/SpentOverview/CreditCardFilter/CreditCardFilterMenuItem';
import { mapRecordsToCardDetails } from '../CreditCardsInsights/SpentOverview/CreditCardFilter/util';
import { CCFilterOption } from '../CreditCardsInsights/utils/paginationFilterHooks';

const defaultSort = {
  direction: SortDirection.Asc,
  field: CardIssuerCardSortField.CardholderFullName,
};

const sortForInputString = {
  direction: SortDirection.Desc,
  field: CardIssuerCardSortField.Query,
};

export const useCardRefNumFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const computeVariables = useCallback(
    (inputString?: string): GetCardsInfiniteScrollPaginationQueryVariables => ({
      input: {},
      queries: {
        query: inputString,
      },
      filters: {
        statuses: [
          CardStatus.Active,
          CardStatus.Locked,
          CardStatus.LockedPin,
          CardStatus.Terminated,
          CardStatus.Expired,
        ],
      },
      sortBy: inputString ? sortForInputString : defaultSort,
    }),
    []
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<
      GetCardsInfiniteScrollPaginationQuery,
      GetCardsInfiniteScrollPaginationQueryVariables
    >({
      query: getCardsInfiniteScrollPaginationQuery,
      queryRootKey: 'getPageBasedCardIssuerCards',
      computeVariables,
    });

  const unselectedEntries = data?.getPageBasedCardIssuerCards?.records || [];

  const { data: selectedData } = useGetPageBasedCardIssuerCardsQuery({
    variables: {
      input: { page: 0, limit: filteredValues.length },
      filters: {
        ids: filteredValues,
        statuses: [
          CardStatus.Active,
          CardStatus.Locked,
          CardStatus.Terminated,
          CardStatus.Expired,
        ],
      },
      sortBy: defaultSort,
    },
    skip: filteredValues.length === 0 || Boolean(searchStr),
    fetchPolicy: 'cache-and-network',
  });

  const selectedEntries =
    selectedData?.getPageBasedCardIssuerCards?.records || [];

  const mappedSelectedCreditCards = mapRecordsToCardDetails(selectedEntries);

  const mappedUnselectedCreditCards =
    mapRecordsToCardDetails(unselectedEntries);

  const filterOptions: CCFilterOption[] = [
    ...mappedSelectedCreditCards,
    ...mappedUnselectedCreditCards,
  ].map(cc => {
    const isCandisBranded = cc.brand === CardBrand.Candis;

    return {
      id: cc.id,
      icon: cc.brand ? (
        <Image
          src={
            isCandisBranded
              ? cardTypes[cc.type].candis
              : cardTypes[cc.type].pliant
          }
          height="40px"
          alt=""
        />
      ) : undefined,
      label: (
        <CreditCardFilterMenuItem
          label={cc.label}
          cardNumber={cc.cardNumber}
          firstName={cc.firstName}
          lastname={cc.lastname}
        />
      ),
    };
  });

  return {
    filterOptions: uniqBy(filterOptions, 'id'),
    loading,
    loadMore,
    handleDebounceSearch,
  };
};
