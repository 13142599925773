import { usePaymentConditionsImportHistoryQuery } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { SETTINGS_ROUTES } from 'views/Settings/types';

export const usePaymentConditionsImportHistoryData = () => {
  const organizationSlug = useOrganizationId();
  const paymentConditionsUrl = `/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.PAYMENT_CONDITIONS}`;
  const paymentConditionsImportUrl = `${paymentConditionsUrl}#import`;
  const { data, loading: isLoading } = usePaymentConditionsImportHistoryQuery();

  const paymentConditionsHistoryItems = data?.paymentConditionsImportHistory
    ? data.paymentConditionsImportHistory.map(item => ({
        createdAt: item.createdAt,
        errorFileUrl: item.errorFile ? item.errorFile.url : undefined,
        file: item.file
          ? {
              name: item.file.name ?? undefined,
              url: item.file.url ?? undefined,
            }
          : undefined,
        username: item.createdBy?.name,
      }))
    : [];

  return {
    paymentConditionsHistoryItems,
    paymentConditionsImportUrl,
    paymentConditionsUrl,
    isLoading,
  };
};
