import { QueryLazyOptions } from '@apollo/client';
import { Button, Flex, Grid, Item } from '@candisio/design-system';
import { HookFormChipsFieldItem } from 'components/HookFormFields/HookFormTagsField/HookFormChipsField';
import { useInsightsWidgets } from 'components/Insights/hooks/useInsightsWidgets';
import {
  WidgetFormAnnualBudget,
  WidgetFormQuarterBudget,
} from 'components/Insights/Modals/types';
import { getWidgetType } from 'components/Insights/Modals/utils';
import { WIDGET_TYPE } from 'components/Insights/Modals/WidgetModal/components/WidgetTypeSelection/TypeSelection/types';
import { WidgetPreview } from 'components/Insights/Modals/WidgetModal/Sections/WidgetPreview';
import {
  SlideAnimation,
  FadeAnimation,
} from 'components/Insights/Modals/WidgetModal/styles';
import { WidgetModalTabs } from 'components/Insights/Modals/WidgetModal/WidgetModalTabs';
import { sourceTranslations } from 'components/Insights/Widgets/utils';
import {
  DocumentCurrency,
  DocumentFilters,
  FinancialInsightsQueryVariables,
  InsightBudgetType,
  Maybe,
  useActiveMembersQuery,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';
import { zodResolver } from 'utils/zodFormValidation';
import { WidgetModalTab } from 'views/InsightsWidgets/utils';
import { BudgetTab } from './BudgetTab/BudgetTab';
import { getBudgetAmount } from './BudgetTab/utils/budget';
import { widgetFormSchema, errorMessages } from './schema';
import { ShareWidget } from './ShareWidget/ShareWidget';
import { WidgetFormData } from './types';
import { WidgetDetails } from './WidgetDetails/WidgetDetails';
import { buildTitle } from './WidgetForm.helper';

interface WidgetFormProps {
  calculateWidgetSum: (
    options?: QueryLazyOptions<FinancialInsightsQueryVariables> | undefined
  ) => void;
  filters: Maybe<DocumentFilters>;
  getSumAggregateCostCentersIsLoading: boolean;
  sumAggregateCostCenters: number;
  currency?: DocumentCurrency;
  resetModalValues: ({ isCloseModal }: { isCloseModal: boolean }) => void;
  isWidgetShared: boolean;
  selectedType: WIDGET_TYPE;
  budgetType: InsightBudgetType | undefined;
  handleSetIsWidgetShared: (sharedWith: boolean) => void;
  budgets: WidgetFormAnnualBudget | WidgetFormQuarterBudget;
  onSubmit: (formData: WidgetFormData) => Promise<void>;
  initialValues: WidgetFormData;
}

export const WidgetForm = ({
  currency,
  filters,
  budgetType,
  calculateWidgetSum,
  getSumAggregateCostCentersIsLoading,
  handleSetIsWidgetShared,
  isWidgetShared,
  resetModalValues,
  selectedType,
  sumAggregateCostCenters,
  budgets,
  onSubmit,
  initialValues,
}: WidgetFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const currentUser = useCurrentUser();
  const { data: usersData } = useActiveMembersQuery();
  const [startAnimation, setStartAnimation] = useState(false);
  const { totalCount, loading: insightsLoading } = useInsightsWidgets();

  const form = useForm<WidgetFormData>({
    defaultValues: initialValues,
    mode: 'onTouched',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.INSIGHTS,
      zodSchema: widgetFormSchema,
      errorMessages: errorMessages(t),
    }),
    shouldFocusError: true,
  });

  const budgetAmount = getBudgetAmount(form.getValues(), currentUser?.id);

  const widgetType = getWidgetType(filters);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sumWidgetSource = t(sourceTranslations[widgetType!]);

  const sumWidgetFormattedSum = amountFormat(sumAggregateCostCenters, currency);

  const shareableUsers = usersData?.organizationMemberships.filter(
    x => x.id !== currentUser?.id
  );

  const canBeShared = shareableUsers && shareableUsers.length > 0;

  const isButtonDisabled =
    !form.formState.isValid ||
    !form.watch('title') ||
    (!form.watch('costCenters')?.length &&
      !form.watch('costObjects')?.length &&
      !form.watch('contacts')?.length &&
      !form.watch('generalLedgerAccounts')?.length);

  const executeAnimation = startAnimation && !totalCount;

  const changeTitle = (selectOption: HookFormChipsFieldItem[]) => {
    const newTitle = buildTitle(selectOption);

    form.setValue('title', newTitle);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid gap="space16">
          <Grid
            templateColumns="1fr 1fr"
            gap="space24"
            alignItems="start"
            height="392px">
            <Grid gap="space24">
              <WidgetModalTabs
                aria-label="Widget modal tabs"
                executeAnimation={executeAnimation}>
                <Item
                  key={WidgetModalTab.DETAILS}
                  textValue={t('dashboard.createNewWidget.section.content')}
                  title={t('dashboard.createNewWidget.section.content')}>
                  <Grid gap="space32" templateRows="1fr 196px">
                    <WidgetDetails
                      changeTitle={changeTitle}
                      resetModalValues={resetModalValues}
                      currentUser={currentUser}
                      selectedType={selectedType}
                      calculateWidgetSum={calculateWidgetSum}
                      initialValues={initialValues}
                    />
                    {canBeShared && (
                      <ShareWidget
                        isWidgetShared={isWidgetShared}
                        shareableUsers={shareableUsers}
                        handleSetIsWidgetShared={handleSetIsWidgetShared}
                      />
                    )}
                  </Grid>
                </Item>
                <Item
                  key={WidgetModalTab.BUDGET}
                  textValue={t('dashboard.createNewWidget.section.budget')}
                  title={t('dashboard.createNewWidget.section.budget')}>
                  <BudgetTab budgetType={budgetType} budgets={budgets} />
                </Item>
              </WidgetModalTabs>
            </Grid>
            <SlideAnimation
              // @ts-expect-error
              handleSubmit={form.handleSubmit(onSubmit)}
              executeAnimation={executeAnimation}>
              <WidgetPreview
                sumAggregateCostCenters={sumAggregateCostCenters}
                currency={currency}
                executeAnimation={executeAnimation}
                sumWidgetSource={sumWidgetSource}
                sumWidgetName={form.getValues('title')}
                sumWidgetFormattedSum={sumWidgetFormattedSum}
                budgetAmount={budgetAmount}
                loading={getSumAggregateCostCentersIsLoading}
                sharedWithUsers={usersData?.organizationMemberships.filter(x =>
                  form.getValues('sharedWithUsers').includes(x.id)
                )}
              />
            </SlideAnimation>
          </Grid>
          <FadeAnimation executeAnimation={executeAnimation}>
            <Flex justifyContent="end">
              <Button
                size="medium"
                type="submit"
                disabled={isButtonDisabled}
                onClick={async () => {
                  if (!totalCount && !insightsLoading) {
                    setStartAnimation(true);
                  } else {
                    await form.handleSubmit(onSubmit);
                  }
                }}>
                {t('dashboard.createNewWidget.buttonCTA')}
              </Button>
            </Flex>
          </FadeAnimation>
        </Grid>
      </form>
    </FormProvider>
  );
};
