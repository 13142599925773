import { Text, TruncatedText } from '@candisio/design-system';
import { castArray } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

type Value = string | number | [string, ...string[]];

export const Cell = ({
  value,
  column,
}: CellProps<DocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const [tCommon] = useTranslation();

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const values = castArray(value);

  if (values.every(v => !v)) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const allValuesAreTheSame = values.every(v => v === values[0]);

  if (values.length > 1 && !allValuesAreTheSame) {
    return (
      <TruncatedText color="gray600" wordBreak="break-all" fontStyle="italic">
        {t('seeSplitBookings')}
      </TruncatedText>
    );
  }

  const cellContent =
    column.id === 'exportMethod'
      ? // eslint-disable-next-line candis/no-template-strings-inside-translation
        tCommon(`export.table.columns.exportMethod.methods.${value}`)
      : String(values[0]);

  return (
    <TruncatedText maxWidth="50ch" wordBreak="break-all">
      {cellContent}
    </TruncatedText>
  );
};
