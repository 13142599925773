import { Box } from '@candisio/design-system';
import { ReactNode } from 'react';

type ContentLayoutProps = {
  id?: string;
  children: ReactNode;
};

export const ContentLayout = ({ id, children }: ContentLayoutProps) => (
  <Box
    id={id}
    flex="1"
    height="100%"
    overflowY="auto"
    minHeight="space80"
    paddingBottom="space80">
    {children}
  </Box>
);
