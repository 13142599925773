import { CustomEmptyStateProps } from '@candisio/design-system';
import { UNTAGGED_ID } from 'components/DocumentsTable/constants';
import { availableEcmDocumentFilters } from 'components/EcmDocumentsTable/constants';
import {
  UseEcmDocumentsDataParams,
  useEcmDocumentsData,
} from 'components/EcmDocumentsTable/hooks/useEcmDocumentsData';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import {
  queryDebouncedParameter,
  queryParameter,
} from 'components/Table/consts';
import {
  EcmDocumentStatus,
  EcmFilterInput,
  EcmSortInput,
  EcmSortOrder,
} from 'generated-types/graphql.types';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useDateConverter } from 'hooks/useDateConverter';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { EcmDocumentsPaginationParams } from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { useCallback, useMemo, useState } from 'react';
import { SortingRule } from 'react-table';
import { EcmDocumentsEmptyState } from 'views/Archive/EcmDocuments/EcmDocumentsEmptyState';
import { useGetEcmDocumentsTableConfigs } from 'views/Archive/EcmDocuments/hooks/useGetEcmDocumentsTableConfigs';

const mapToSortBy = (field: SortingRule<object>) => {
  return {
    [field.id]:
      typeof field.desc !== 'boolean'
        ? undefined
        : field.desc
        ? EcmSortOrder.Desc
        : EcmSortOrder.Asc,
  };
};

export const useEcmDocumentsTableData = ({
  filterParamsSource,
  queryOptions,
}: {
  filterParamsSource: 'local' | 'url';
  queryOptions?: UseEcmDocumentsDataParams['queryOptions'];
}) => {
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const { dateStringToIsoDateFilterFormat } = useDateConverter();
  const {
    filters: localFilters,
    onFilter: localOnFilter,
    onSort: localOnSort,
    sortBy: localSortBy,
  } = useLocalSortAndFilters();

  const {
    filters: urlFilters,
    onFilter: urlOnFilter,
    sortBy: urlSortBy,
    onSort: urlOnSort,
  } = useUrlBasedSortAndFilter<EcmDocumentsTableData>({
    availableFilters: availableEcmDocumentFilters,
  });

  const isLocalFilter = filterParamsSource === 'local';

  const filters = isLocalFilter ? localFilters : urlFilters;
  const onFilter = isLocalFilter ? localOnFilter : urlOnFilter;
  const sortBy = isLocalFilter ? localSortBy : urlSortBy;
  const onSort = isLocalFilter ? localOnSort : urlOnSort;

  const [selectedDocuments, setSelectedDocuments] = useState<
    EcmDocumentsTableData[]
  >([]);

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isResetPending,
    isSavingConfigurations,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetEcmDocumentsTableConfigs({ filters, sortBy });

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return (
      <EcmDocumentsEmptyState
        isTableEmpty={hasNoData}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const isTableFiltered = filters.length > 0;

  const searchDebouncedQuery = searchParams.get(queryDebouncedParameter) ?? '';
  const searchQuery = isLocalFilter
    ? localSearchQuery
    : searchParams.get(queryParameter) ?? '';

  const formattedFilters: EcmFilterInput | undefined = isTableFiltered
    ? filters.reduce<EcmFilterInput>((acc, filter) => {
        // The column is named 'contact' but the filter for the `getEcmDocuments` mutation is named 'contactId'.
        if (filter.id === 'contact') {
          acc.contactId = filter.value as Array<string>;
        }

        // For the 'documentDate' filter, we need to split the value
        // into 'toDate' and 'fromDate' and transform it to ISO format.
        if (filter.id === 'documentDate') {
          const filterValue = filter.value as Array<string>;
          const [from, to] = filterValue[0].split('-');
          const fromIso = dateStringToIsoDateFilterFormat(from);
          const toIso = dateStringToIsoDateFilterFormat(to);

          acc.documentDate = {
            fromDate: fromIso || '',
            toDate: toIso || '',
          };
        }

        if (filter.id === 'documentStatus') {
          acc.documentStatus = filter.value as Array<EcmDocumentStatus>;
        }

        if (filter.id === 'documentType') {
          acc.documentType = filter.value as Array<string>;
        }

        if (filter.id === 'tags') {
          if (filter.value.includes(UNTAGGED_ID)) {
            acc.tags = [];
          } else {
            acc.tags = filter.value as Array<string>;
          }
        }

        return acc;
      }, {})
    : undefined;

  const formattedSort: EcmSortInput | undefined =
    sortBy.length > 0 ? mapToSortBy(sortBy[0]) : undefined;

  const paginationParams: EcmDocumentsPaginationParams = useMemo(
    () => ({
      filter: formattedFilters,
      sort: formattedSort,
    }),
    [formattedFilters, formattedSort]
  );

  const ecmDocumentsDataOptions: UseEcmDocumentsDataParams = useMemo(
    () => ({
      params: paginationParams,
      initialSearchQuery: searchQuery,
      filterParamsSource,
      queryOptions,
    }),
    [filterParamsSource, paginationParams, searchQuery, queryOptions]
  );

  const {
    data,
    handleDebounceSearch,
    hasNoData,
    isLoading,
    onLoadMore,
    selectedDocumentsCount: totalCount,
  } = useEcmDocumentsData(ecmDocumentsDataOptions);

  const handleSearchChange = useCallback(
    (searchQuery: string) => {
      handleDebounceSearch(searchQuery);
      if (isLocalFilter) {
        setLocalSearchQuery(searchQuery);
      } else {
        updateSearchParam(queryParameter, searchQuery);
      }
    },
    [isLocalFilter, handleDebounceSearch, updateSearchParam]
  );

  return {
    data,
    filters,
    handleSearchChange,
    isLoading,
    isTableFiltered,
    formattedFilters,
    onFilter,
    onSort,
    selectedDocuments,
    setSelectedDocuments,
    totalCount,
    customEmptyState,
    configurationsTable,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    availableDocumentColumnIds,
    onLoadMore,
    searchQuery,
    searchDebouncedQuery,
    searchParams,
    sortBy,
    isSavingConfigurations,
    isResetPending,
  };
};
