import { Button, Modal, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  ConfirmationButtonContainer,
  ConfirmationInfoSection,
} from '../styles';

type Props = {
  visible: boolean;
  importing: boolean;
  organizationName: string;
  successCount: number;
  handleImport: () => void;
  onClose: () => void;
};

export const ConfirmationModal = ({
  visible,
  importing,
  organizationName,
  successCount,
  handleImport,
  onClose,
}: Props) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);

  return (
    <Modal
      title={t('import.confirmation.title')}
      isOpen={visible}
      onClose={onClose}>
      <ConfirmationInfoSection>
        <Trans
          t={t}
          i18nKey="import.confirmation.info"
          values={{ successCount, organizationName }}
          count={successCount}>
          These
          {String(successCount)}
          general ledger accounts will be imported in
          <Text fontWeight="bold">{organizationName}</Text>
          You can then no longer delete them, you can only archive them. Do you
          want to continue?
        </Trans>
      </ConfirmationInfoSection>
      <ConfirmationButtonContainer>
        <Button onClick={handleImport} loading={importing}>
          {t('import.confirmation.confirmButtonCTA')}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {t('import.confirmation.cancelButtonCTA')}
        </Button>
      </ConfirmationButtonContainer>
    </Modal>
  );
};
