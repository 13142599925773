import { CardLimitRenewFrequency } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Validity } from '../CreditCardDrawer/Tabs/Request/utils/validityFieldOptions';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from './CreateCreditCardModalDataProvider';
import {
  VirtualCardLimitsFormOutput,
  VirtualCardLimitsFormValues,
} from './utils/virtualCardLimitsFormSchema';
import { VirtualCardLimitsForm } from './VirtualCardLimitsForm';

export interface VirtualCardLimitsFormContainerProps {
  suggestedAmount?: number;
}

export const VirtualCardLimitsFormContainer = ({
  suggestedAmount,
}: VirtualCardLimitsFormContainerProps) => {
  const { isCardManager } = useUserRoles();
  const setModalState = useCreateCreditCardModalSetState();
  const { cardDetails } = useCreateCreditCardModalState();

  const handleSubmit = (formValues: VirtualCardLimitsFormOutput) => {
    const { limit, limitInterval, transactionLimit, comment } = formValues;
    setModalState(prevState => ({
      ...prevState,
      formStep: 'CardUsage',
      cardDetails: {
        ...prevState.cardDetails,
        limit,
        limitInterval,
        transactionLimit,
        comment,
        validity: prevState.cardDetails?.validity ?? Validity.months36,
      },
    }));
  };

  const defaultValues: VirtualCardLimitsFormValues = {
    comment: cardDetails?.comment,
    limit: cardDetails?.limit ?? suggestedAmount,
    transactionLimit: cardDetails?.transactionLimit ?? suggestedAmount,
    limitInterval: CardLimitRenewFrequency.Monthly,
  };

  const isSuggestionVisible =
    typeof cardDetails?.limit === 'undefined' ||
    typeof cardDetails?.transactionLimit === 'undefined';

  return (
    <VirtualCardLimitsForm
      onSubmit={handleSubmit}
      isCardManager={isCardManager}
      defaultValues={defaultValues}
      suggestedAmount={isSuggestionVisible ? suggestedAmount : undefined}
    />
  );
};
