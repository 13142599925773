import { Box, Flex, Icon, Paragraph } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const NoBudgetOption = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return (
    <>
      <Box background="bluebg" borderRadius="medium" padding="space8">
        <Flex alignItems="center" gap="space8">
          <Icon icon="infoCircle" size="space32" color="blue500" />
          <Paragraph color="gray800" fontSize="small">
            {t('dashboard.createNewWidget.tabs.infoBox.noBudgetSetup')}
          </Paragraph>
        </Flex>
      </Box>
    </>
  );
};
