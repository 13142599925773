import { z } from 'zod';

export const creditCardGLASchema = z.object({
  creditCardGLA: z.string().min(1).max(100),
});

export const errorMessages = {
  creditCardGLA: {
    label:
      'creditCardExportingSap.creditCardGeneralLedgerAccount.textInput.ariaLabel',
  },
};
