import { useToastMessage } from 'components/Toast/useToastMessage';
import { useRemoveEcmDocumentMutation } from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { getEcmSensitiveDocumentsCountQuery } from 'views/Inbox/EcmSensitiveDocuments/gql';
import { delayRefetchedSensitiveContractsQueries } from './delayRefetchedSensitiveContractsQueries';

/**
 * Error messages are defined and come from BE
 * TODO: either localise errors in BE or introduce status code
 * to reliably show localised error in FE
 */

export const LINKED_DOCUMENTS_PARTIAL_ERROR_MESSAGE =
  'has active links and cannot be deleted';

type RemoveEcmDocumentResult = { status: 'success' } | { status: 'error' };

type RemoveEcmDocument = () => Promise<RemoveEcmDocumentResult>;

interface UseRemoveEcmDocumentReturn {
  /** Remove ECM document */
  removeEcmDocument: RemoveEcmDocument;
  /** Loading state */
  loading: boolean;
}

export const useRemoveEcmDocument = (
  documentId: string
): UseRemoveEcmDocumentReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { success, error } = useToastMessage();

  const counterQueries = useCounterQueries();
  const [mutation, { loading }] = useRemoveEcmDocumentMutation({
    refetchQueries: [...counterQueries, getEcmSensitiveDocumentsCountQuery],
    onQueryUpdated: delayRefetchedSensitiveContractsQueries,
  });

  const removeEcmDocument: RemoveEcmDocument = async () => {
    if (!documentId) {
      return { status: 'error' };
    }

    const { errors } = await mutation({
      variables: { input: { id: documentId } },
    });

    if (errors) {
      if (
        errors.find(error =>
          error.message.includes(LINKED_DOCUMENTS_PARTIAL_ERROR_MESSAGE)
        )
      ) {
        error(t('linkedDocumentError'));
      } else {
        error(t('genericErrorMessage'));
      }

      return { status: 'error' };
    } else {
      success(t('storageForm.actions.documentDeletedSuccessfully'));

      return { status: 'success' };
    }
  };

  return { removeEcmDocument, loading };
};
