import { Box, Grid } from '@candisio/design-system';
import { HistoryList } from 'components/HistoryList/HistoryList';
import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { CoreDataTypes } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CoreDataImportHistory } from 'views/CoreDataImportHistory/CoreDataImportHistory';
import { SapFeedbackCard } from '../integrations/components/SapFeedbackCard';
import { useContactImportHistoryData } from './hooks/useContactImportHistoryData';

const localStorageKey = 'sap_feedback_contact_history_dismissed';

export const ContactImportHistory = () => {
  const [dismissed] = useLocalStorage(localStorageKey, false);
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const {
    contactHistoryItems,
    contactBackUrl,
    contactImportUrl,
    isLoading,
    isSapVariant,
  } = useContactImportHistoryData();

  const title = t('importHistory.title');
  const backButtonLabel = t('importHistory.backToContactTableLinkText');

  if (shouldUseCoreDataApi) {
    return (
      <CoreDataImportHistory
        coreDataType={CoreDataTypes.Contact}
        title={title}
        backButtonLabel={backButtonLabel}
        backButtonLink={contactBackUrl}
        localeNamespace={LOCALE_NAME_SPACE.CONTACTS}
      />
    );
  }

  return (
    <Grid height="100%" padding="space40 space32 space32 space32">
      <ImportHistoryLayout
        title={title}
        titleAs="h1"
        backButtonLabel={backButtonLabel}
        backButtonLink={contactBackUrl}
        aboveTable={
          !dismissed && isSapVariant ? (
            <Box width="50rem">
              <SapFeedbackCard localStorageKey={localStorageKey} />
            </Box>
          ) : undefined
        }>
        <HistoryList
          data={contactHistoryItems}
          variant={
            isSapVariant ? 'errorImportHistorySap' : 'errorImportHistory'
          }
          emptyState={
            isSapVariant
              ? { translation: 'contacts:importHistory.noDataSap' }
              : {
                  translation: 'contacts:importHistory.noData',
                  url: contactImportUrl,
                }
          }
          isLoading={isLoading}
          searchPlaceholder={
            isSapVariant ? undefined : t('importHistory.searchPlaceholder')
          }
          isSapVariant={isSapVariant}
          width="50rem"
        />
      </ImportHistoryLayout>
    </Grid>
  );
};
