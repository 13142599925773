import { Card, Flex, Grid, Separator, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { defaultRolesTranslations } from 'views/Settings/Roles/toolkit/utils/defaultRolesTranslations';
import { Role } from 'views/Settings/TeamMembers/hooks/useGetRolesForUser';
import { HandleChangeParams, RoleSwitch } from './RoleSwitch';

interface RolesFieldProps {
  roles: Role[];
  isReadOnly?: boolean;
  name: UseControllerProps['name'];
  control?: UseControllerProps['control'];
  onChange?: () => void;
}

export const RolesField = ({
  name,
  control,
  roles,
  isReadOnly = false,
  onChange: onChangeProp,
}: RolesFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { field, fieldState } = useController({ name, control });

  const { value, onChange } = field;

  const { error } = fieldState;

  const handleChange = ({
    isChecked,
    switchName,
    switchValue,
  }: HandleChangeParams) => {
    if (isChecked) {
      onChange([...value, { id: switchValue, name: switchName }]);
    } else {
      onChange(
        value.filter(
          (currentRole: { id: string; name: string }) =>
            currentRole.id !== switchValue
        )
      );
    }

    onChangeProp?.();
  };

  const selectedRoleIds = (value ?? []).map(
    (role: { id: string; name: string }) => role.id
  );

  return (
    <Card padding="0px" background="gray100">
      <Grid as="ul" style={{ listStyle: 'none', paddingLeft: '0px' }}>
        {roles.map((role, index) => {
          const isLastItem = index === roles.length - 1;

          const isToggled =
            selectedRoleIds.includes(role.id) ||
            (selectedRoleIds.length > 0 && isReadOnly);

          return (
            <Grid key={role.name} as="li">
              <RoleSwitch
                onChange={handleChange}
                label={
                  role.type === 'CustomRole'
                    ? role.name
                    : t(defaultRolesTranslations[role.name])
                }
                value={role.id}
                name={role.name}
                description={role.description}
                isToggled={isToggled}
                isReadOnly={isReadOnly}
              />
              {!isLastItem && <Separator />}
            </Grid>
          );
        })}

        {Boolean(error?.message) && (
          <Flex padding="space16" color="red500" borderTop="1px solid gray300">
            <Text>{error?.message}</Text>
          </Flex>
        )}
      </Grid>
    </Card>
  );
};
