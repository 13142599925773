import { useDatevSettings_v2 } from 'hooks/useDatevSettings';

export const useCreditCardExportRequirements = () => {
  const {
    creditCards: {
      accountNumbers: { transitAccount, bookingAccount },
    },
  } = useDatevSettings_v2();

  const hasAccountNumbers = transitAccount && bookingAccount;

  return {
    hasAccountNumbers,
    areMet: !!hasAccountNumbers,
  };
};
