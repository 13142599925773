import {
  HookFormChipsField,
  HookFormChipsFieldItem,
} from 'components/HookFormFields/HookFormTagsField/HookFormChipsField';
import { DocumentFilterInput } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBookingAccountFieldOptions } from '../../hooks/useBookingAccountFieldOptions';

interface WidgetGeneralLedgerAccountProps {
  changeTitle: (options: HookFormChipsFieldItem[]) => void;
  handleGetWidgetSumForFilters: (filters: DocumentFilterInput) => void;
  initialGeneralLedgerAccounts: string[];
}

export const WidgetGeneralLedgerAccount = ({
  changeTitle,
  handleGetWidgetSumForFilters,
  initialGeneralLedgerAccounts,
}: WidgetGeneralLedgerAccountProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const [selectedGeneralLedgerAccounts, setSelectedGeneralLedgerAccounts] =
    useState(initialGeneralLedgerAccounts);

  const {
    defaultItems,
    loadMore,
    loading,
    handleDebounceSearch,
    selectedBookingAccountItems,
  } = useBookingAccountFieldOptions({
    selectedValues: selectedGeneralLedgerAccounts,
  });

  const onChangeGeneralLedgerAccounts = (
    selectedGeneralLedgerAccountIds: string[]
  ) => {
    setSelectedGeneralLedgerAccounts(selectedGeneralLedgerAccountIds);

    const selectedGeneralLedgerAccountItems =
      defaultItems?.filter(item =>
        selectedGeneralLedgerAccountIds.includes(item.id)
      ) ?? [];

    changeTitle(selectedGeneralLedgerAccountItems);

    handleGetWidgetSumForFilters({
      generalLedgerAccountIds: selectedGeneralLedgerAccountIds,
    });
  };

  return (
    <HookFormChipsField
      label={t('dashboard.createNewWidget.types.generalLedgerAccount.title')}
      name="generalLedgerAccounts"
      onChange={onChangeGeneralLedgerAccounts}
      placeholder={t(
        'dashboard.createNewWidget.types.generalLedgerAccount.placeholder'
      )}
      isLoading={loading}
      onInputChange={handleDebounceSearch}
      onEndReached={loadMore}
      defaultItems={defaultItems}
      selectedOptions={selectedBookingAccountItems}
    />
  );
};
