import { FieldFunctionOptions } from '@apollo/client';

const logIt = (message: string) => {
  const shouldLogGQLCache = localStorage.getItem('shouldLogGQLCache');

  if (shouldLogGQLCache === 'true') {
    console.info(message);
  }
};

export const FieldPolicy = {
  DocumentFile: {
    fields: {
      url: {
        read: (
          url: string,
          { storage, readField }: FieldFunctionOptions<any, any>
        ) => {
          const fileId = readField('id');
          logIt(
            `reading storage.urlExpiresOn as: ${storage.urlExpiresOn} for fileId: ${fileId}`
          );
          const urlExpiresOn = storage.urlExpiresOn as number;
          if (!urlExpiresOn) {
            return url;
          }

          const expiresOn = new Date(urlExpiresOn);
          logIt(`url of fileId: ${fileId} expiresOn: ${expiresOn}`);

          if (expiresOn.getTime() < Date.now()) {
            // already expired
            logIt(`url expired for fileId: ${fileId}`);

            return undefined;
          }

          logIt(`returning existing url of fileId: ${fileId}`);

          return url;
        },
        merge: (
          _existing: Readonly<string> | undefined,
          incoming: Readonly<string>,
          { storage, readField }: FieldFunctionOptions<any, any>
        ) => {
          const oneHourWindow = 60 * 60 * 1000;
          storage.urlExpiresOn = Date.now() + oneHourWindow;
          const fileId = readField('id');

          logIt(
            `setting storage.urlExpiresOn to: ${storage.urlExpiresOn} for fileId: ${fileId}`
          );

          return incoming;
        },
      },
    },
  },
};
