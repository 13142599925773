import {
  Avatar,
  Button,
  Grid,
  TruncatedText,
  Text,
} from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { VendorDetailsLoader } from './VendorDetailsLoader';

interface VendorSectionProps {
  vendorName?: string;
  loading: boolean;
  logoUrl?: string;
  isEditable: boolean;
  formMode: 'firstLinkage' | 'updateCardLinkage';
  onAddRecurringPayment: () => void;
}

export const VendorSection = ({
  loading,
  isEditable,
  logoUrl,
  vendorName,
  formMode,
  onAddRecurringPayment,
}: VendorSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  if (loading) {
    return <VendorDetailsLoader />;
  }

  if (isEditable) {
    return (
      <Grid alignItems="center" templateColumns="1fr auto" gap="space20">
        <Grid
          gap="space12"
          autoFlow="column"
          templateColumns="auto 1fr"
          alignItems="center">
          <Avatar
            img={logoUrl}
            hasTooltip={false}
            name={vendorName}
            size="medium"
          />
          <HookFormTextField
            name="vendorName"
            label={t('recurringPaymentsModal.linkCard.vendorName.label')}
          />
        </Grid>
        <Text justifySelf="start" width="space128">
          {t('recurringPaymentsModal.linkCard.customVendorHint')}
        </Text>
      </Grid>
    );
  }

  return (
    <Grid autoFlow="column" justifyContent="space-between">
      <Grid autoFlow="column" gap="space12" alignItems="center">
        <Avatar
          img={logoUrl}
          hasTooltip={false}
          name={vendorName}
          size="medium"
        />
        {!!vendorName && (
          <TruncatedText
            maxWidth="50ch"
            fontSize="xxlarge"
            lineHeight="paragraph">
            {vendorName}
          </TruncatedText>
        )}
      </Grid>
      {formMode === 'firstLinkage' && (
        <Button
          icon="plus"
          variant="tertiary"
          onClick={() => onAddRecurringPayment()}>
          {t('recurringPaymentsModal.linkCard.createNewCard')}
        </Button>
      )}
    </Grid>
  );
};
