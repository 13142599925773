import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  UpdateCardLabelInput,
  useUpdateCardLabelMutation,
} from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useUpdateCardLabel = () => {
  let status: 'error' | 'success';
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();

  const [updateCardLabel, { loading: updatingLabel }] =
    useUpdateCardLabelMutation({
      onError: () => error(t('dashboard.genericErrorMessage')),
    });

  const { refetchQueries } = useCreditCardsRefetchQueries();

  return {
    updatingLabel,
    updateCardLabel: async ({ cardId, label }: UpdateCardLabelInput) => {
      const { errors } = await updateCardLabel({
        variables: {
          input: { cardId, label },
        },
        awaitRefetchQueries: true,
        refetchQueries: refetchQueries.cardIssuerCards(cardId),
      });

      if (errors?.length) {
        status = 'error';
      } else {
        status = 'success';
      }

      return {
        status,
        errors,
      };
    },
  };
};
