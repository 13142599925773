import { Grid, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { WorkflowTemplateAppliedToDocumentEventDataFragment } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

export const WorkflowAppliedComponent = ({
  workflow,
}: WorkflowTemplateAppliedToDocumentEventDataFragment) => {
  const [t] = useTranslation();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      isDisabled: isNil(workflow?.description),
      passiveTrigger: true,
    });

  return (
    <>
      {t('document.compact.history.workflowCreatedForDocument')}
      {workflow?.name && (
        <>
          {' '}
          <Text
            color="blue500"
            fontWeight="bold"
            {...triggerProps}
            ref={triggerRef}>
            "{workflow?.name}"
          </Text>
        </>
      )}
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Grid>
            <Text fontWeight="bold">{workflow?.name}</Text>
            <Text>{workflow?.description}</Text>
          </Grid>
        </Tooltip>
      )}
    </>
  );
};
