import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

type Value = string | undefined;

export const FileNameCell = ({
  value,
}: CellProps<DocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return (
    <TruncatedText wordBreak="break-all" charsAfterEllipsis={7}>
      {value}
    </TruncatedText>
  );
};
