import { Link } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { Validity } from 'containers/credit-cards/CreditCardDrawer/Tabs/Request/utils/validityFieldOptions';
import {
  useGetCardById,
  useIsCardManagerOnCreditCardsView,
} from 'containers/credit-cards/utils';
import {
  CardIssuerCardReplacementReason,
  CardType,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useReplaceCard } from '../Hooks/useReplaceCard';
import { ReplaceCardForm } from './ReplaceCardForm';
import { ReplaceCardFormLoading } from './ReplaceCardFormLoading';
import {
  ReplaceCardFormOutput,
  ReplaceCardFormValues,
} from './utils/replaceCardFormSchema';

const onCreditCardsView = 'onCreditCardsView';

export const ReplaceCardFormContainer = () => {
  const { creditCardId } = useParams<{ creditCardId: string }>();
  const { success, error } = useToastMessage();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { replacing, replaceCard } = useReplaceCard();
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const isOnCardManagerCreditCardView = useIsCardManagerOnCreditCardsView();

  const { card, loading } = useGetCardById({ cardId: creditCardId });

  if (loading) {
    return <ReplaceCardFormLoading />;
  }

  if (!card) {
    return null;
  }

  const handleOnSubmit = async (formValues: ReplaceCardFormOutput) => {
    const { status, cardId, messages } = await replaceCard(formValues);

    if (status === 'error') {
      (messages ?? []).forEach(errorMessage => error(t(errorMessage)));
    } else {
      const linkToReplacedCard = `/${orgId}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/${cardId}?${onCreditCardsView}=true`;
      const toastMessage = (
        <Trans
          t={t}
          i18nKey="creditCardFormDataPreview.replaceCard.successToastMessage">
          Card Replaced By
          <Link external href={linkToReplacedCard}>
            new card
          </Link>
        </Trans>
      );

      success(toastMessage);

      if (isOnCardManagerCreditCardView) {
        navigate(`/${orgId}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`);
      } else {
        navigate(`/${orgId}${Routes.DASHBOARD}`);
      }
    }
  };

  const defaultValues: ReplaceCardFormValues = {
    replaceReason: CardIssuerCardReplacementReason.Stolen,
    validity: card.type === CardType.Virtual ? Validity.months36 : undefined,
  };

  return (
    <ReplaceCardForm
      cardType={card.type}
      defaultValues={defaultValues}
      replacing={replacing}
      onSubmit={handleOnSubmit}
    />
  );
};
