import { Button, Card, Grid, Text } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import {
  generatePath,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useDeleteReimbursement } from 'views/Reimbursement/hooks/useDeleteReimbursement';
import { DeleteAction } from './DeleteAction';

interface FooterActionsProps {
  onSubmitForReview: (reimbursementId: string) => Promise<{
    status: string;
    numberOfErrorsOnReimbursement: number;
    numberOfErrorsOnItems: number;
  }>;
}

export const FooterActions = ({ onSubmitForReview }: FooterActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [numberOfErrors, setNumberOfErrors] = useState(0);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const organizationSlug = useOrganizationId();
  const { error, success } = useToastMessage();
  const { deletePending, deleteReimbursement } = useDeleteReimbursement();
  const { triggerAllValidations } = useReimbursementFormsContext();

  const errMessage = t('reimbursementView.createErr');
  const successMessage = t(
    'reimbursementView.rightSection.footerActions.doubleConfirm.deleteSuccessToast'
  );

  const handleDelete = async () => {
    const status = await deleteReimbursement(reimbursementId);
    if (status === 'error') {
      error(errMessage);

      return;
    }

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.REIMBURSEMENT}`,
      { organizationSlug }
    );

    success(successMessage);
    navigate({
      pathname,
      search,
    });
  };

  const handleClick = async () => {
    const response = await onSubmitForReview(reimbursementId);
    if (
      response.numberOfErrorsOnItems > 0 ||
      response.numberOfErrorsOnReimbursement > 0
    ) {
      triggerAllValidations();
      setNumberOfErrors(response.numberOfErrorsOnItems);
    }
  };

  return (
    <Card
      alignSelf="end"
      corners="top"
      boxShadow="elevatedShadow3"
      position="sticky"
      padding="space20"
      bottom="0"
      zIndex="1">
      <Grid gap="space10">
        <Text color="gray500" fontSize="basic" fontWeight="semibold">
          {t('reimbursementView.rightSection.footerActions.title')}
        </Text>
        {numberOfErrors > 0 && (
          <Text color="red500">
            {t('reimbursementView.rightSection.errors', {
              count: numberOfErrors,
            })}
          </Text>
        )}
        <Grid templateColumns="1fr 1fr" gap="space10">
          <Button color="blue" onClick={handleClick}>
            {t('reimbursementView.rightSection.footerActions.submitCta')}
          </Button>
          <DeleteAction loading={deletePending} onDelete={handleDelete} />
        </Grid>
      </Grid>
    </Card>
  );
};
