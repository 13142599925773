import {
  CustomEmptyStateProps,
  FilterOptionsAccessor,
  SelectionOptions,
  TableProps,
} from '@candisio/design-system';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import {
  Maybe,
  Scalars,
  TransactionAmount,
  MemberInfo,
  CardIssuerTransaction,
  CardIssuerTransactionEdge,
  InboxCardIssuerTransactionsQuery,
  InvoiceAssociationStatus,
  ArchiveCardIssuerTransactionsQuery,
  UnassociatedCardIssuerTransactionsQuery,
  TransactionStatus,
  TransactionType,
  TransactionCategory,
} from 'generated-types/graphql.types';
import { Filters, SortingRule, TableState } from 'react-table';

// TODO streamline with Design System Documents Table
export interface Amount {
  value: number;
  currency: string;
}
export interface TransactionsTableData {
  id: string;
  status?: Maybe<TransactionStatus>;
  merchantName?: Maybe<string>;
  grossAmount?: Maybe<Amount>;
  transactionCreatedAt?: Maybe<Date>;
  cardRefNum?: Maybe<string>;
  cardholderName?: Maybe<MemberInfo>;
  cursor?: Scalars['String']['output'];
  transactionAmount?: Maybe<TransactionAmount>;
  invoiceAssociationStatus?: Maybe<InvoiceAssociationStatus>;
  type?: Maybe<TransactionType>;
  category?: TransactionCategory;
  localizedDeclineReason?: {
    en: string | undefined;
    de: string | undefined;
  };
  merchantLogoUrl: string | undefined;
}

export type OnRowClick = {
  id: string;
  cursor: string | undefined;
};

export interface TransactionsTableProps {
  data: Array<TransactionsTableData>;
  columns?: Array<keyof TransactionsTableData>;
  onRowClick?: ({ id, cursor }: OnRowClick) => void;
  onSort?: (sortBy: SortingRule<TransactionsTableData>[]) => void;
  onFilter?: (filters: Filters<TransactionsTableData>) => void;
  selectionOptions?: SelectionOptions<TransactionsTableData>;
  filterOptions?: FilterOptionsAccessor<TransactionsTableData>;
  defaultFilters?: TableState<TransactionsTableData>['filters'];
  defaultSortBy?: TableState<TransactionsTableData>['sortBy'];
  isFilterLoading?: boolean;
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  isLoading?: boolean;
  onEndReached: (index: number) => void;
  borderTopRadius?: TableProps<TransactionsTableData>['borderTopRadius'];
  search?: string;
  onSearch?: (search: string) => void;
  isSavingConfigurations?: boolean;
  isDownloadingCSV?: boolean;
  configurations?: Configuration[];
  onUpdateConfigurations?: (configurations: Configuration[]) => void;
  onResetTableConfigurations?: () => void;
  onDownloadCSV?: () => void;
  downloadableTransactionsCount?: number;
}

export type Invoice = Document[];

export type CardTransactionsType = CardIssuerTransaction &
  CardIssuerTransactionEdge;

export interface UseControlledStateOptions {
  filters: Filters<TransactionsTableData>;
  sortBy: SortingRule<TransactionsTableData>[];
  onSort?: (sortBy: SortingRule<TransactionsTableData>[]) => void;
  onFilter?: (filters: Filters<TransactionsTableData>) => void;
  handleFiltersInUrl?: (
    sortBy: SortingRule<TransactionsTableData>[],
    filters: Filters<TransactionsTableData>
  ) => void;
}

export type QueryTypes =
  | InboxCardIssuerTransactionsQuery
  | ArchiveCardIssuerTransactionsQuery
  | UnassociatedCardIssuerTransactionsQuery;

export type TransactionListProps = (CardIssuerTransaction &
  CardIssuerTransactionEdge)[];

export type TransactionsTableDataKeys = Exclude<
  keyof TransactionsTableData,
  | 'cursor'
  | 'localizedDeclineReason'
  | 'id'
  | 'invoiceAssociationStatus'
  | 'linkedInvoice'
  | 'member'
  | 'transactionAmount'
  | 'type'
  | 'category'
  | 'merchantLogoUrl'
>;

export enum ViewUsingTransactions {
  INBOX = 'INBOX',
  ARCHIVE = 'ARCHIVE',
  PROCESSING_DOCUMENT = 'PROCESSING_DOCUMENT',
}

export type AvailableFiltersTransactionTable = Pick<
  TransactionsTableData,
  | 'cardholderName'
  | 'cardRefNum'
  | 'invoiceAssociationStatus'
  | 'status'
  | 'transactionCreatedAt'
  | 'type'
  | 'merchantName'
  | 'category'
>;

export enum TRANSACTIONS_QUERY_PARAMS {
  cardholderName = 'cardholderName',
  cardRefNum = 'cardRefNum',
  invoiceAssociationStatus = 'invoiceAssociationStatus',
  sort = 'sort',
  status = 'status',
  transactionCreatedAt = 'transactionCreatedAt',
  type = 'type',
  merchantName = 'merchantName',
  category = 'category',
}
