import { FieldPolicy, TypePolicies } from '@apollo/client';
import { VendorsForRecurringPaymentsConnectionPageBased } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const vendorsForRecurringPayment: FieldPolicy<VendorsForRecurringPaymentsConnectionPageBased> =
  {
    keyArgs: ['filters', 'sortBy'],
    merge: simplePaginationMergeWithoutDuplicates(
      'VendorsForRecurringPaymentsConnectionPageBased'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      getVendorsForRecurringPayments: vendorsForRecurringPayment,
    },
  },
};
