import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { usePermissionsForMembership } from 'hooks/usePermissionsForMembership';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

/** Interface representing the overall return value of the useUserPermissions hook */
interface PermissionsForCreditCards {
  canManageCards: boolean;
  canIssueCards: boolean;
}

/**
 * Custom hook to retrieve and manage user permissions.
 * It checks if the user has permissions to manage credit cards based on organization settings and feature flags.
 * @returns {PermissionsForCreditCards} An object containing user permissions.
 */
export const usePermissionsForCreditCards = (): PermissionsForCreditCards => {
  const { isCardManager } = useUserRoles();
  const permissionsManagementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.permissionsManagement
  );

  const permissions = usePermissionsForMembership();

  const defaultPermissions = {
    canManageCards: isCardManager,
    canIssueCards: isCardManager,
  };

  // Check if permissions feature flag is enabled
  // If not enabled, return permissions based on user roles
  if (!permissionsManagementFF) {
    return defaultPermissions;
  }

  return {
    canManageCards: permissions?.['organization.manage_cards'] ?? false,
    canIssueCards: permissions?.['organization.issue_new_card'] ?? false,
  };
};
