import { Skeleton, Text } from '@candisio/design-system';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { History } from '../../../../../../components/History/History';
import { ExportContext } from '../../../Context';
import { useInitialExportUrl } from '../../../Manifest';
import { useExportableEntitiesCounter } from '../../../toolkit/hooks/useExportableEntitiesCounter';
import { View } from '../../../types';

export const Ready = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { view } = useContext(ExportContext);

  const viewDef = useInitialExportUrl();

  const { loading, readyToExportEntitiesCount } = useExportableEntitiesCounter({
    fetchPolicy: 'network-only',
  });

  const isActive = (view === View.READY).toString();

  return (
    <History.Export
      data-cy="history-main-item"
      header={t('export.exportHistory.title.ready')}
      onClick={() => navigate(viewDef.navigate)}
      status="default"
      active={isActive}>
      {loading ? (
        <Skeleton height="15px" width="50%" />
      ) : (
        <Text fontSize="small">
          {t('export.exportHistory.counter.posting', {
            count: readyToExportEntitiesCount,
          })}
        </Text>
      )}
    </History.Export>
  );
};
