import {
  SfsSettingsForRequesterQuery,
  useSfsSettingsForApproverQuery,
  useSfsSettingsForRequesterQuery,
  useSfsSwitchBookingKeyForApproverOffManuallyMutation,
  useSfsSwitchBookingKeyForApproverOnManuallyMutation,
  useSfsSwitchBookingKeyOffManuallyMutation,
  useSfsSwitchBookingKeyOnManuallyMutation,
  useSfsSwitchCostCenterForApproverOffManuallyMutation,
  useSfsSwitchCostCenterForApproverOnManuallyMutation,
  useSfsSwitchCostCenterOffManuallyMutation,
  useSfsSwitchCostCenterOnManuallyMutation,
  useSfsSwitchCostObjectForApproverOffManuallyMutation,
  useSfsSwitchCostObjectForApproverOnManuallyMutation,
  useSfsSwitchCostObjectOffManuallyMutation,
  useSfsSwitchCostObjectOnManuallyMutation,
  useSfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation,
  useSfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation,
  useSfsSwitchGeneralLedgerAccountOffManuallyMutation,
  useSfsSwitchGeneralLedgerAccountOnManuallyMutation,
} from 'generated-types/graphql.types';
import { sfsSettingsForApprover, sfsSettingsForRequester } from './gql';

export const fieldNames = [
  'generalLedgerAccount',
  'bookingKey',
  'costCenter',
  'costObject',
] as const;

type SettingsKey = keyof SfsSettingsForRequesterQuery['sfsSettings'] &
  (typeof fieldNames)[number];

export enum Role {
  APPROVER = 'APPROVER',
  REQUESTER = 'REQUESTER',
}

export type SfsSettingsType = {
  [Role.APPROVER]: SfsSettingsForRequesterQuery['sfsSettings'];
  [Role.REQUESTER]: SfsSettingsForRequesterQuery['sfsSettings'];
};

export function useSwitchSfsField() {
  const { data: approverData, loading: loadingApprover } =
    useSfsSettingsForApproverQuery();

  const { data: requesterData, loading: loadingRequester } =
    useSfsSettingsForRequesterQuery();

  const sfsSettings = {
    [Role.APPROVER]: approverData?.sfsSettingsForApprover,
    [Role.REQUESTER]: requesterData?.sfsSettings,
  };

  const [switchCostCenterOffManually] =
    useSfsSwitchCostCenterOffManuallyMutation();

  const [switchCostCenterOnManually] =
    useSfsSwitchCostCenterOnManuallyMutation();

  const [switchCostObjectOffManually] =
    useSfsSwitchCostObjectOffManuallyMutation();

  const [switchCostObjectOnManually] =
    useSfsSwitchCostObjectOnManuallyMutation();

  const [switchGeneralLedgerAccountOffManually] =
    useSfsSwitchGeneralLedgerAccountOffManuallyMutation();

  const [switchGeneralLedgerAccountOnManually] =
    useSfsSwitchGeneralLedgerAccountOnManuallyMutation();

  const [switchBookingKeyOnManually] =
    useSfsSwitchBookingKeyOnManuallyMutation();

  const [switchBookingKeyOffManually] =
    useSfsSwitchBookingKeyOffManuallyMutation();

  // Approver mutations
  const [switchCostObjectForApproverOnManually] =
    useSfsSwitchCostObjectForApproverOnManuallyMutation();

  const [switchCostObjectForApproverOffManually] =
    useSfsSwitchCostObjectForApproverOffManuallyMutation();

  const [switchCostCenterForApproverOnManually] =
    useSfsSwitchCostCenterForApproverOnManuallyMutation();

  const [switchCostCenterForApproverOffManually] =
    useSfsSwitchCostCenterForApproverOffManuallyMutation();

  const [switchGeneralLedgerAccountForApproverOnManually] =
    useSfsSwitchGeneralLedgerAccountForApproverOnManuallyMutation();

  const [switchGeneralLedgerAccountForApproverOffManually] =
    useSfsSwitchGeneralLedgerAccountForApproverOffManuallyMutation();

  const [switchBookingKeyForApproverOnManually] =
    useSfsSwitchBookingKeyForApproverOnManuallyMutation();

  const [switchBookingKeyForApproverOffManually] =
    useSfsSwitchBookingKeyForApproverOffManuallyMutation();

  const switchOnMutations = {
    [Role.REQUESTER]: {
      costObject: switchCostObjectOnManually,
      costCenter: switchCostCenterOnManually,
      bookingKey: switchBookingKeyOnManually,
      generalLedgerAccount: switchGeneralLedgerAccountOnManually,
    },
    [Role.APPROVER]: {
      costObject: switchCostObjectForApproverOnManually,
      costCenter: switchCostCenterForApproverOnManually,
      generalLedgerAccount: switchGeneralLedgerAccountForApproverOnManually,
      bookingKey: switchBookingKeyForApproverOnManually,
    },
  };

  const switchOffMutations = {
    [Role.REQUESTER]: {
      costObject: switchCostObjectOffManually,
      costCenter: switchCostCenterOffManually,
      bookingKey: switchBookingKeyOffManually,
      generalLedgerAccount: switchGeneralLedgerAccountOffManually,
    },
    [Role.APPROVER]: {
      costObject: switchCostObjectForApproverOffManually,
      costCenter: switchCostCenterForApproverOffManually,
      bookingKey: switchBookingKeyForApproverOffManually,
      generalLedgerAccount: switchGeneralLedgerAccountForApproverOffManually,
    },
  };

  const switchSfsField = (field: SettingsKey, checked: boolean, role: Role) => {
    const queryOptions = {
      refetchQueries:
        role === Role.APPROVER
          ? [{ query: sfsSettingsForApprover }]
          : [{ query: sfsSettingsForRequester }],
    };

    return checked
      ? switchOnMutations[role][field](queryOptions)
      : switchOffMutations[role][field](queryOptions);
  };

  return {
    sfsSettings,
    switchSfsField,
    loading: loadingApprover || loadingRequester,
  } as const;
}
