import {
  Box,
  Grid,
  InlineSkeleton,
  Link,
  Separator,
  mergeProps,
} from '@candisio/design-system';
import { ProcessingFormNewContactActions } from 'components/Form/ProcessingForm/ProcessingFormNewContactActions';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { ReactElement, ReactNode, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EXTRACTED_CONTACT_ID,
  ProcessingFormValues,
} from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

export interface ProcessingFormContactFieldItem {
  key: string;
  children: string;
  accountsPayableNumber?: string;
  accountsReceivableNumber?: string;
  createTransfer?: boolean;
  iban?: string;
  swiftCode?: string;
  paymentCondition?: string;
  taxNumber?: string;
  vatId?: string;
  email?: string;
  phoneNumber?: string;
  bankAccountNumber?: string;
}

export interface ProcessingFormExtractedContactFieldItem {
  children?: string;
  createTransfer?: boolean;
  swiftCode?: string;
  bankAccountNumber?: string;
  iban?: string;
  taxNumber?: string;
  vatId?: string;
  email?: string;
  phoneNumber?: string;
  street?: string;
  city?: string;
  postOfficeBox?: string;
  postalCode?: string;
  countryISOCode?: string;
  customerNumber?: string;
}

export interface ProcessingFormContactFieldProps {
  /** Add contact button label */
  addContactLabel?: string;
  /** ... */
  autoFocus?: boolean;
  /** Field name */
  name: 'contact';
  /** Initial value for combo box input */
  defaultInputValue?: string;
  /** Edit button label */
  editLabel?: string;
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
  /** Combo box items */
  items?: ProcessingFormContactFieldItem[];
  /** New contact card description */
  newContactDescription?: string;
  /** New contact card title */
  newContactTitle?: string;
  /** Field label */
  label: string;
  /** Called when add contact drawer should be shown */
  onAddContact?: () => void;
  /** Called when field value changes */
  onChange?: (newValue: string | null) => void;
  /** Called when edit contact drawer should be shown */
  onEditContact?: () => void;
  /** Called when user searches for an item */
  onSearch?: (inputValue: string) => Promise<void>;
  /** Placeholder shown when no value is set */
  placeholder?: string;
  /** Display field as read only */
  readOnly?: boolean;
  /** Custom render function for combo box dropdown */
  renderCustomDropdown?: (listbox: ReactElement) => ReactNode;
}

/** Processing form contact field */
export const ProcessingFormContactField = ({
  addContactLabel,
  autoFocus,
  editLabel,
  items,
  name,
  newContactDescription,
  newContactTitle,
  onAddContact,
  onEditContact,
  onSearch,
  readOnly,
  isLoading,
  ...restProps
}: ProcessingFormContactFieldProps) => {
  const [t] = useTranslation();

  const { value, inputValue } =
    useWatch<ProcessingFormValues, typeof name>({ name }) ?? {};

  const { fieldProps } = useProcessingFormField(`${name}.value`);

  useEffect(() => {
    void onSearch?.(inputValue ?? '');
  }, [inputValue, onSearch]);

  const displayNewContactCard =
    inputValue !== undefined &&
    !items?.some(item => item.children === inputValue) &&
    !isLoading;

  const newContactActions = (
    <ProcessingFormNewContactActions
      newContactDescription={newContactDescription}
      newContactTitle={newContactTitle}
      addContactLabel={addContactLabel}
      onAddContact={onAddContact}
    />
  );

  return (
    <Grid gap="space8">
      <HookFormPaginatedComboBoxField
        name={name}
        autoFocus={autoFocus}
        allowsCustomValue={true}
        allowsEmptyCollection={true}
        emptyListPlaceholder={newContactActions}
        items={items}
        onSearch={onSearch}
        renderCustomDropdown={listbox => (
          <Grid templateRows="1fr auto" maxHeight="inherit" overflow="hidden">
            <Box overflow="hidden">{listbox}</Box>
            {displayNewContactCard && (
              <Grid>
                <Separator />
                {newContactActions}
              </Grid>
            )}
          </Grid>
        )}
        {...mergeProps(fieldProps, restProps)}
        readOnly={readOnly || fieldProps.readOnly}
        isLoading={isLoading}
        forceInputFieldRef
      />
      {!readOnly && (
        <>
          {displayNewContactCard && newContactActions}
          {typeof value === 'string' && value !== EXTRACTED_CONTACT_ID && (
            <Link
              as="button"
              fontSize="small"
              lineHeight="1"
              onClick={onEditContact}
              position="absolute"
              right="space8"
              top="0"
              // @TODO DS: make lineHeight prop work for <Link as="button">
              style={{ lineHeight: '1' }}>
              <InlineSkeleton isLoading={isLoading}>
                {editLabel ?? t('common.edit')}
              </InlineSkeleton>
            </Link>
          )}
        </>
      )}
    </Grid>
  );
};
