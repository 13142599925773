import { Grid } from '@candisio/design-system';
import { GridComponents } from 'react-virtuoso';

export const VirtuosoItem: GridComponents['Item'] = ({
  /** @ts-expect-error TODO: React upgrade props types mismatch */
  children,
  context,
  ...props
}) => {
  return (
    <Grid as="li" {...props}>
      {children}
    </Grid>
  );
};
