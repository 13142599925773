import { ReactNode } from 'react';
import {
  FormProvider,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { BookingsFieldArrayContext } from './BookingsFieldArrayContext';
import { ProcessingFormValues } from './processingFormSchema';

export interface ProcessingFormProviderProps {
  /** Bookings field array instance returned by `useFieldArray`  */
  bookingsFieldArray: UseFieldArrayReturn<ProcessingFormValues, 'bookings'>;
  /** Form children */
  children: ReactNode;
  /** Form instance returned by `useForm` */
  form: UseFormReturn<ProcessingFormValues>;
}

/**
 * Provides the bookings field array context to the processing form
 *
 * @todo refactor this when React Hook Form provides a way to include field
 * arrays in the form context:
 * https://github.com/orgs/react-hook-form/discussions/7433
 */
export const ProcessingFormProvider = ({
  children,
  bookingsFieldArray,
  form,
}: ProcessingFormProviderProps) => {
  return (
    <BookingsFieldArrayContext.Provider value={bookingsFieldArray}>
      <FormProvider {...form}>{children}</FormProvider>
    </BookingsFieldArrayContext.Provider>
  );
};
