import {
  Cell,
  Color,
  InfoPanel,
  Flex,
  Table,
  Tag,
  Text,
  defaultTheme,
} from '@candisio/design-system';
import { ExportableContactStatus } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Column, Row } from 'react-table';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useGetColumnIds } from 'views/Integrations/Export/hooks/useGetColumnIds';
import { useExportTableMaxHeight } from 'views/Integrations/Export/toolkit/hooks/useExportTableMaxHeight';
import { ContactsEmptyState, MaskedContactsOverlay } from './EmptyState';
import { Header } from './Header';
import {
  ContactTableColumnKeys,
  ContactTableColumns,
  ContactsTableData,
  ContactsTableDataRow,
} from './types';

export interface ExportContactsTableProps {
  data: ContactsTableData;
  hasWarning: boolean;
  isMasked: boolean;
  showAll: boolean;
}

const translationKeys = {
  [ExportableContactStatus.New]: 'contacts.table.status.new',
  [ExportableContactStatus.Updated]: 'contacts.table.status.updated',
} as const;

const StatusCell = ({ value }: { value: ExportableContactStatus }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  const status = t(translationKeys[value]);

  const isNew = value === ExportableContactStatus.New;
  const tagColor: Color = isNew ? 'green' : 'gray';

  return (
    <Tag color={tagColor} variant="secondary">
      {status}
    </Tag>
  );
};

export const ExportContactTable = ({
  data,
  hasWarning,
  isMasked,
  showAll,
}: ExportContactsTableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const navigate = useNavigate();
  const orgId = useOrganizationId();
  const state = useExportContext();
  const tableId = state.tableId;
  const columnIds = useGetColumnIds<ContactTableColumnKeys>({
    tableId,
  });

  const defaultColumn = useMemo(
    (): Partial<Column<ContactsTableDataRow>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      Cell,
      disableSortBy: isMasked,
    }),
    [isMasked]
  );

  const columns = useMemo(() => {
    const allColumns: ContactTableColumns = {
      name: {
        accessor: 'name',
        width: 'auto',
        disableFilters: true,
      },
      status: {
        accessor: 'status',
        width: `${defaultTheme.space.space128}`,
        Cell: StatusCell,
        disableFilters: true,
      },
    };

    const visibleColumns = columnIds.map(column => allColumns[column]);

    return showAll
      ? visibleColumns.filter(column => column.accessor !== 'status')
      : visibleColumns;
  }, [columnIds, showAll]);

  const handleRowClick = ({ original: { id } }: Row<ContactsTableDataRow>) => {
    navigate(`/${orgId}${Routes.CONTACTS}/${id}`);
  };

  const height = useExportTableMaxHeight(data.length);
  const showEmptyState = data.length === 0;

  if (showEmptyState) return <ContactsEmptyState />;

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      overflow="hidden"
      height="100%">
      <Flex direction="column" overflow="hidden" height="100%">
        {hasWarning && (
          <InfoPanel
            variant="warning"
            message={t('contacts.table.warning.bigDataExport')}
          />
        )}
        {isMasked && <MaskedContactsOverlay />}
        <Table
          key={`${tableId}-${columns.join('-')}`}
          borderTopRadius="0"
          borderBottomRadius="0"
          height={height}
          defaultColumn={defaultColumn}
          columns={columns}
          data={data}
          onRowClick={handleRowClick}
        />
      </Flex>
      {!isMasked && (
        <Flex
          direction="column"
          width="100%"
          justifyContent="start"
          alignItems="center"
          color="gray500"
          fontSize="basic"
          paddingTop="space16"
          rowGap="space8">
          <Text>{t('contacts.table.message.contactsExcluded')}</Text>
          <Text>{t('contacts.table.message.entriesLimit')}</Text>
        </Flex>
      )}
    </Flex>
  );
};
