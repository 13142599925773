import {
  HookFormChipsField,
  HookFormChipsFieldItem,
} from 'components/HookFormFields/HookFormTagsField/HookFormChipsField';
import { DocumentFilterInput } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContactFieldOptions } from '../../hooks/useContactFieldOptions';

interface WidgetContactsProps {
  changeTitle: (options: HookFormChipsFieldItem[]) => void;
  handleGetWidgetSumForFilters: (filters: DocumentFilterInput) => void;
  initialContacts: string[];
}

export const WidgetContacts = ({
  changeTitle,
  handleGetWidgetSumForFilters,
  initialContacts,
}: WidgetContactsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const [selectedContacts, setSelectedContacts] =
    useState<string[]>(initialContacts);

  const {
    handleDebounceSearch,
    loadMore,
    defaultItems,
    selectedContactItems,
    loading,
  } = useContactFieldOptions({
    selectedValues: selectedContacts,
  });

  const onChangeContacts = (selectedContactIds: string[]) => {
    setSelectedContacts(selectedContactIds);

    const selectedContactItems =
      defaultItems?.filter(item => selectedContactIds.includes(item.id)) ?? [];

    changeTitle(selectedContactItems);

    handleGetWidgetSumForFilters({
      contactIds: selectedContactIds,
    });
  };

  return (
    <HookFormChipsField
      label={t('dashboard.createNewWidget.types.contact.title')}
      name="contacts"
      placeholder={t('dashboard.createNewWidget.types.contact.placeholder')}
      isLoading={loading}
      onInputChange={handleDebounceSearch}
      onEndReached={loadMore}
      defaultItems={defaultItems}
      onChange={onChangeContacts}
      selectedOptions={selectedContactItems}
    />
  );
};
