import { Grid, Text, TruncatedText } from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';
import { percentageFormat } from 'utils/format';

export interface PaymentConditionItemProps {
  id: string;
  discountOffset?: number;
  discountPercentage?: number;
  dueDateOffset: number;
  conditionNumber: number;
}

/** Individual items displayed by PaymentConditionField */
export const PaymentConditionItem = ({
  discountOffset,
  discountPercentage,
  dueDateOffset,
  conditionNumber,
}: PaymentConditionItemProps) => {
  return (
    <Grid gap="space8" templateColumns="auto 1fr">
      {discountPercentage ? (
        <Trans
          parent={TruncatedText}
          i18nKey="settings.contacts.details.edit.paymentCondition.options.conditionWithDiscount"
          values={{
            discountOffset,
            discountPercentage: percentageFormat(discountPercentage),
            dueDateOffset,
            conditionNumber,
          }}>
          <Text color="gray500" fontSize="basic">
            {{ conditionNumber } as any}
          </Text>
          <Text fontSize="basic">
            {{ discountOffset } as any}
            {{ discountPercentage: percentageFormat(discountPercentage) }},
            {{ dueDateOffset }}
          </Text>
        </Trans>
      ) : (
        <Trans
          parent={TruncatedText}
          i18nKey="settings.contacts.details.edit.paymentCondition.options.conditionWithoutDiscount"
          values={{ conditionNumber, dueDateOffset }}>
          <Text color="gray500" fontSize="basic">
            {{ conditionNumber } as any}
          </Text>
          <Text fontSize="basic">{{ dueDateOffset } as any}</Text>
        </Trans>
      )}
    </Grid>
  );
};
