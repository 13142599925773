import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { useTranslation } from 'react-i18next';

interface NetAmountProps {
  currency?: string;
}

export const NetAmount = ({ currency }: NetAmountProps) => {
  const [t] = useTranslation();

  return (
    <HookFormAmountField
      name="netAmount"
      currency={currency}
      label={t('document.requestApproval.inputs.netAmount.label')}
      placeholder={t('document.requestApproval.inputs.netAmount.placeholder')}
      readOnly
    />
  );
};
