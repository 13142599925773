import { DocumentConvertedToAttachmentEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const DocumentConvertedToAttachmentComponent = ({
  target,
}: DocumentConvertedToAttachmentEventDataFragment) => {
  const [t] = useTranslation();

  const translationKey = target?.fileName
    ? 'document.compact.history.documentAttachment.history.documentConvertedToAttachment'
    : 'document.compact.history.documentAttachment.history.documentConvertedToAttachment_fallback';

  return <div>{t(translationKey, { fileName: target?.fileName })}</div>;
};
