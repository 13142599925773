// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';

const { REACT_APP_DATEV_WEBAPP_HOST: datevWebappHost } = import.meta.env || {};

export const buildDatevDocumentLink = ({
  consultantNumber,
  clientNumber,
  documentExportGuid,
}: {
  consultantNumber?: string | null;
  clientNumber?: string | null;
  documentExportGuid?: string | null;
}): string | null => {
  if (!clientNumber || !consultantNumber || !documentExportGuid) {
    return null;
  }

  const query = qs.stringify({
    page: 'beleguebersicht',
    isBearbeitet: 'true',
    searchcallerid: 'duostartclient',
    mandantId: clientNumber,
    beraterId: consultantNumber,
    search: documentExportGuid,
  });

  return `${datevWebappHost}/duo-mydatev-apphost/app/1114?${query}`;
};
