import gql from 'graphql-tag';

export const documentFileUploadUrl = gql`
  mutation documentFileUploadUrl($options: UploadOptions!) {
    documentFileUploadUrl(options: $options) {
      id
      url
      file {
        id
        url
        name
      }
      postOptions {
        field
        value
      }
    }
  }
`;

export const processDocumentMutation = gql`
  mutation processDocument($fileId: String!, $transactionIds: [String!]) {
    processDocument(fileId: $fileId, transactionIds: $transactionIds) {
      id
    }
  }
`;

export const processEcmDocumentMutation = gql`
  mutation processEcmDocument($input: ProcessEcmDocumentInput!) {
    processEcmDocument(input: $input) {
      ecmDocumentId
    }
  }
`;

export const organizationLocalQuery = gql`
  query GetOrganizationLocal($id: ID!) {
    organization(id: $id) {
      locale
    }
  }
`;

export const currentSlackTokenQuery = gql`
  query currentSlackToken {
    currentSlackToken {
      tokenValid
      redirectUrl
    }
  }
`;

export const obtainNewSlackTokenMutation = gql`
  mutation obtainNewSlackToken($token: ObtainSlackTokenInput!) {
    obtainNewSlackToken(token: $token)
  }
`;
