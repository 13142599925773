/**
 * Unfortunately i18n-iso-countries does not offer a possibility to retrieve only european conuntries
 * and it's just easier to make a static list
 */
export const ALPHA2_EUROPEAN_COUNTRIES: Record<string, string> = {
  AT: 'insights.organizationSettingsDrawer.form.countries.AT',
  BE: 'insights.organizationSettingsDrawer.form.countries.BE',
  BG: 'insights.organizationSettingsDrawer.form.countries.BG',
  HR: 'insights.organizationSettingsDrawer.form.countries.HR',
  CY: 'insights.organizationSettingsDrawer.form.countries.CY',
  CZ: 'insights.organizationSettingsDrawer.form.countries.CZ',
  DK: 'insights.organizationSettingsDrawer.form.countries.DK',
  EE: 'insights.organizationSettingsDrawer.form.countries.EE',
  FI: 'insights.organizationSettingsDrawer.form.countries.FI',
  FR: 'insights.organizationSettingsDrawer.form.countries.FR',
  DE: 'insights.organizationSettingsDrawer.form.countries.DE',
  GR: 'insights.organizationSettingsDrawer.form.countries.GR',
  HU: 'insights.organizationSettingsDrawer.form.countries.HU',
  IE: 'insights.organizationSettingsDrawer.form.countries.IE',
  IT: 'insights.organizationSettingsDrawer.form.countries.IT',
  LV: 'insights.organizationSettingsDrawer.form.countries.LV',
  LT: 'insights.organizationSettingsDrawer.form.countries.LT',
  LU: 'insights.organizationSettingsDrawer.form.countries.LU',
  MT: 'insights.organizationSettingsDrawer.form.countries.MT',
  NL: 'insights.organizationSettingsDrawer.form.countries.NL',
  PL: 'insights.organizationSettingsDrawer.form.countries.PL',
  PT: 'insights.organizationSettingsDrawer.form.countries.PT',
  RO: 'insights.organizationSettingsDrawer.form.countries.RO',
  SK: 'insights.organizationSettingsDrawer.form.countries.SK',
  SI: 'insights.organizationSettingsDrawer.form.countries.SI',
  ES: 'insights.organizationSettingsDrawer.form.countries.ES',
  SE: 'insights.organizationSettingsDrawer.form.countries.SE',
  GB: 'insights.organizationSettingsDrawer.form.countries.GB',
};

/**
 * Unfortunately i18n-iso-countries does not offere a possibility to retrieve only european conuntries
 * and it's just easier to make a static list
 */
export const POSTAL_CODE_REGEX = /^[0-9]*$/;
