import { Grid, Image } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Maybe } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import workflowIllustration from '../assets/workflowIllustration.svg';

interface TitleFieldsProps {
  isArchived?: Maybe<boolean>;
}

export const TitleFields = ({ isArchived }: TitleFieldsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <Grid
      background="gray100"
      gap="space16"
      padding="space30 space64"
      templateColumns="auto 1fr"
      alignItems="center">
      <Image alt="" height="6rem" src={workflowIllustration} />
      <Grid gap="space16">
        <HookFormTextField
          label={t('workflows.details.edit.name.label')}
          name="name"
          readOnly={!!isArchived}
          autoFocus={!isArchived}
        />
        <HookFormTextField
          label={t('workflows.details.edit.description.label')}
          name="description"
          readOnly={!!isArchived}
        />
      </Grid>
    </Grid>
  );
};
