import {
  FieldContainer,
  FieldContainerProps,
  Grid,
  Icon,
  Item,
  Select,
  SelectProps,
  Text,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { identity } from 'lodash';
import { Key, ReactNode, useState } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

export interface HookFormSelectFieldProps<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Should field focus on mount? */
  autoFocus?: boolean;
  /**
   * `<Item>` elements representing select options, or custom item render
   * function
   */
  children?: SelectProps['children'];
  /** default selected option */
  defaultSelectedKey?: SelectProps['defaultSelectedKey'];
  /** default selected option */
  description?: FieldContainerProps['description'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Disabled options */
  disabledKeys?: SelectProps['disabledKeys'];
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
  /** Is the select dropdown open? */
  isOpen?: boolean;
  /** Use list virtualization? */
  isVirtualized?: boolean;
  /** Select options */
  items?: SelectProps['items'];
  /** Field label */
  label?: string;
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Called when field value changes */
  onChange?: (newValue: Key | null) => void;
  /** Called when the open state of the select dropdown changes */
  onOpenChange?: (isOpen: boolean) => void;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /**
   * Render function that can be passed to render custom content in the
   * dropdown
   */
  renderCustomDropdown?: SelectProps['renderCustomDropdown'];
  /** Warning to display inside field */
  warning?: ReactNode;
  /** Transform functions for field input (`value`) and output (`onChange`) */
  transform?: {
    input?: (value: Key | null) => Key | null;
    output?: (value: Key | null) => Key | null;
  };
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  width?: string;
  dataCy?: string;
}

// @TODO Design system Select should already do something similar
const defaultChildren: SelectProps['children'] = item => <Item {...item} />;

/**
 * Controlled select field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormSelectField = <TFormValues extends FieldValues>({
  autoFocus,
  children = defaultChildren,
  control,
  defaultSelectedKey,
  description,
  disabled,
  disabledKeys,
  isOpen: isOpenProp,
  isLoading,
  isVirtualized,
  items = [],
  label,
  message,
  name,
  onChange: onChangeProp,
  onOpenChange,
  placeholder,
  readOnly: readOnlyProp,
  renderCustomDropdown,
  transform = { input: identity, output: identity },
  variant,
  warning,
  width,
  dataCy,
}: HookFormSelectFieldProps<TFormValues>) => {
  const { field, fieldState, formState } = useController({ control, name });
  const { onChange, value, ...fieldProps } = field;

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  const [isOpen, setIsOpen] = useState(isOpenProp);

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;
  const readOnly = readOnlyProp || formState.isSubmitting;

  return (
    <FieldContainer
      description={description}
      disabled={disabled}
      label={label}
      readOnly={readOnly}
      width={width}
      variant={hasError ? 'error' : variant}
      isLoading={isLoading}
      {...(labelProps as Omit<FieldContainerProps, 'color'>)}
      onClick={() => {
        if (!readOnly) {
          setIsOpen(!isOpen);
        }
      }}>
      <Grid gap="space2">
        <Select
          autoFocus={autoFocus}
          defaultSelectedKey={defaultSelectedKey}
          disabledKeys={disabledKeys}
          isDisabled={disabled}
          isOpen={isOpen}
          isVirtualized={isVirtualized}
          items={items}
          onOpenChange={isOpen => {
            if (onOpenChange) {
              onOpenChange(isOpen);
            }

            setIsOpen(isOpen);
          }}
          onSelectionChange={newValue => {
            const transformedValue = transform.output?.(newValue) ?? newValue;
            onChange(transformedValue);
            onChangeProp?.(transformedValue);
          }}
          placeholder={readOnly ? '–' : placeholder}
          readOnly={readOnly}
          renderCustomDropdown={renderCustomDropdown}
          /** @ts-expect-error TODO: React upgrade props types mismatch */
          selectedKey={transform.input?.(value) ?? value}
          message={errorMessage ?? message}
          variant={hasError ? 'error' : variant}
          showMessageOnFocus={hasError || variant === 'error'}
          data-cy={dataCy}
          {...mergeProps(fieldProps, labelFieldProps)}>
          {children}
        </Select>
        {warning && (
          <Grid
            templateColumns="auto 1fr"
            color="yellow500"
            fontSize="xsmall"
            gap="space4"
            alignItems="center">
            <Icon icon="warning" size="space16" />
            <Text>{warning}</Text>
          </Grid>
        )}
      </Grid>
    </FieldContainer>
  );
};
