import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useWidgetCards = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);

  const initialWidgetCards = [
    {
      title: t('insights.widgets.initialState.card1.title'),
      imageUrl: t('insights.widgets.initialState.card1.imageUrl'),
      linkText: t('insights.widgets.initialState.addCtaButton'),
    },
    {
      title: t('insights.widgets.initialState.card2.title'),
      linkText: t('insights.widgets.initialState.addCtaButton'),
    },
    {
      title: t('insights.widgets.initialState.card3.title'),
      linkText: t('insights.widgets.initialState.addCtaButton'),
    },
    {
      title: t('insights.widgets.initialState.card4.title'),
      linkText: t('insights.widgets.initialState.addCtaButton'),
    },
    {
      title: t('insights.widgets.initialState.card5.title'),
      linkText: t('insights.widgets.initialState.addCtaButton'),
    },
    {
      title: t('insights.widgets.initialState.card6.title'),
      tag: t('insights.widgets.initialState.card6.tag'),
      linkText: t('insights.widgets.initialState.card6.linkText'),
      openModal: true,
    },
  ];

  return { initialWidgetCards };
};
