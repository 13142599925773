import { ContactsTableDataRow } from './types';

export const useColumns = () => {
  const columns: (keyof Omit<ContactsTableDataRow, 'id'>)[] = [
    'status',
    'name',
  ];

  return columns;
};
