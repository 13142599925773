import { Card, Grid, Icon, Link } from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

interface CardIsReplacedByInfoProps {
  id: string;
  issuingDate?: string;
  refNumber?: string;
  replacedBy?: string;
}

export const CardIsReplacedByInfo = ({
  id,
  issuingDate,
  refNumber,
  replacedBy,
}: CardIsReplacedByInfoProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const orgId = useOrganizationId();

  const linkToReplacementCard = `/${orgId}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/${id}?onCreditCardsView=true`;

  return (
    <Card background="bluebg">
      <Grid templateColumns="auto 1fr" gap="space8" alignItems="center">
        <Icon size="space20" icon="infoCircle" color="blue500" />
        {refNumber ? (
          <Trans
            t={t}
            parent="span"
            values={{
              cardRefNum: refNumber,
              date: issuingDate,
              userName: replacedBy,
            }}
            i18nKey="creditCardFormDataPreview.replaceCard.infoBox.replacedBy.withRefNumber">
            Card replaced by new card
            <Link external href={linkToReplacementCard}>
              {{ cardRefNum: refNumber } as any}
            </Link>
            on {{ date: issuingDate } as any} by {{ userName: replacedBy }}
          </Trans>
        ) : (
          <Trans
            t={t}
            parent="span"
            values={{
              date: issuingDate,
              userName: replacedBy,
            }}
            i18nKey="creditCardFormDataPreview.replaceCard.infoBox.replacedBy.withoutRefNumber">
            Card replaced by <Link href={linkToReplacementCard}>new card</Link>{' '}
            am
            {{ date: issuingDate } as any} by
            {{ userName: replacedBy } as any}
          </Trans>
        )}
      </Grid>
    </Card>
  );
};
