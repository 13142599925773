import { Button, Grid, ScrollBox } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useGetRolesForUser } from 'views/Settings/TeamMembers/hooks/useGetRolesForUser';
import { RolesForm } from './RolesForm';
import { RolesFormOutput, RolesFormValues } from './utils';

export interface RolesStepProps {
  defaultValues: RolesFormValues;
  onGoBack: () => void;
  onSubmit: (formData: RolesFormOutput) => void;
}

export const RolesStep = ({
  defaultValues,
  onGoBack,
  onSubmit,
}: RolesStepProps) => {
  const [t] = useTranslation();

  // This hook here will be soon replaced with another query which is not paginated
  // for the moment it is fine to use this one
  const { roles, loading } = useGetRolesForUser();

  if (loading) {
    // Loading state still needs to be refined
    return null;
  }

  return (
    <Grid gap="space16" templateRows="auto 1fr" height="100%">
      <Grid paddingX="space32" paddingY="space16">
        <Button
          icon="arrowLeft"
          iconPosition="left"
          alignSelf="center"
          justifySelf="start"
          onClick={onGoBack}
          size="small"
          variant="tertiary">
          {t('settings.teamMembers.form.rolesForm.backCTA')}
        </Button>
      </Grid>
      <ScrollBox height="100%" paddingX="space40">
        <RolesForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          roles={roles}
        />
      </ScrollBox>
    </Grid>
  );
};
