import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useUpdateProcessingFormField } from 'components/Form/ProcessingForm/useUpdateProcessingFormField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { Contact } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { merge } from 'lodash';
import { DefaultValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DeepPartial } from 'utility-types';
import {
  ContactFormOutput,
  ContactFormValues,
} from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';
import { useCheckAccountsNumber } from 'views/Contacts/ContactDetails/useCheckAccountsNumber';
import { useCheckContactName } from 'views/Contacts/ContactDetails/useCheckContactName';
import { useNextFreeAccountsPayableNumber } from 'views/Contacts/ContactDetails/useNextFreeAccountsPayableNumber';
import { usePaymentConditionItems } from 'views/Contacts/ContactDetails/usePaymentConditionItems';
import { PaymentConditionsFormOutput } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm';
import { useCreatePaymentCondition } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/useCreatePaymentCondition';
import { AddContact, AddContactProps } from './AddContact';
import { useCreateContact } from './useCreateContact';

export interface AddContactContainerProps {
  /** Initial contact form values */
  defaultValues?: AddContactProps['defaultValues'];
  /** Called when contact was successfully created */
  onAddContact?: (contact: DeepPartial<Contact>) => void;
  /** Called when contact form overlay should close */
  onClose?: AddContactProps['onClose'];
}

/** Handles data fetching for AddContact component */
export const AddContactContainer = ({
  defaultValues: defaultValuesProp,
  onAddContact,
  onClose,
}: AddContactContainerProps) => {
  const [t] = useTranslation();
  const { isAdmin, isAccountant } = useUserRoles();
  const { success, error } = useToastMessage();

  const processingFormContext = useFormContext<ProcessingFormValues>();
  const processingFormValues = processingFormContext?.getValues() as
    | DefaultValues<ProcessingFormValues>
    | undefined;

  const defaultValues: ContactFormValues = merge(
    {
      name: processingFormValues?.contact?.inputValue,
      // @TODO refactor contact form so we don’t need to pass in both 'name' and
      // 'companyName' values?
      companyName: processingFormValues?.contact?.inputValue,
      createTransfer: processingFormValues?.createTransfer ?? false,
      iban: processingFormValues?.iban,
      swiftCode: processingFormValues?.swiftCode,
      paymentCondition: processingFormValues?.paymentCondition ?? undefined,
      bankInfoType: 'SEPA',
      addressInfoType: 'STREET',
    } satisfies ContactFormValues,
    defaultValuesProp
  );

  const updateProcessingFormField = useUpdateProcessingFormField();

  const { creating, createContact } = useCreateContact();

  const { checkAccountsReceivableNumber, checkAccountsPayableNumber } =
    useCheckAccountsNumber({
      originalPayableValue: defaultValues?.accountsPayableNumber,
      originalReceivableValue: defaultValues?.accountsReceivableNumber,
    });

  const checkContactName = useCheckContactName();

  const {
    items: paymentConditionItems,
    loading: loadingPaymentConditionItems,
  } = usePaymentConditionItems();

  const {
    nextFreeAccountsPayableNumber,
    loading: loadingNextFreeAccountsPayableNumber,
  } = useNextFreeAccountsPayableNumber();

  const handleSubmit = async (formValues: ContactFormOutput) => {
    const result = await createContact(formValues);

    if (result.status === 'success') {
      const newContact = result.contact;

      updateProcessingFormField?.('contact.value', newContact.id, undefined);
      updateProcessingFormField?.(
        'contact.inputValue',
        newContact.name.value,
        undefined
      );

      onAddContact?.(newContact);

      success(
        <span data-cy="contact-created-successfully">
          {t('settings.contacts.created')}
        </span>
      );
    } else {
      error(t('settings.contacts.messages.createFailed'));
    }

    onClose?.();
  };

  const { create: createPaymentCondition } = useCreatePaymentCondition();

  const handleCreatePaymentCondition = async (
    values: PaymentConditionsFormOutput
  ) => {
    const result = await createPaymentCondition(values);
    if (result.status === 'success') {
      success(t('settings:paymentConditions.messages.created'));

      return result.id;
    } else {
      error(t('settings:paymentConditions.messages.createFailed'));
    }
  };

  return (
    <AddContact
      checkContactName={checkContactName}
      checkAccountsPayableNumber={checkAccountsPayableNumber}
      checkAccountsReceivableNumber={checkAccountsReceivableNumber}
      defaultValues={defaultValues}
      loading={
        loadingPaymentConditionItems || loadingNextFreeAccountsPayableNumber
      }
      onClose={onClose}
      onCreatePaymentCondition={
        isAdmin || isAccountant ? handleCreatePaymentCondition : undefined
      }
      onSubmit={handleSubmit}
      paymentConditionItems={paymentConditionItems}
      submitting={creating}
      suggestedAccountsPayableNumber={nextFreeAccountsPayableNumber}
    />
  );
};
