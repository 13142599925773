import { SplitBookingsFormValues } from 'components/Form/SplitBookingsForm/schema';
import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import {
  HookFormSelectField,
  HookFormSelectFieldProps,
} from 'components/HookFormFields/HookFormSelectField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TaxRepresentation = (
  props: Partial<HookFormSelectFieldProps<SplitBookingsFormValues>>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  return (
    <HookFormSelectField
      name="taxPresentation"
      label={t('taxPresentation.label')}
      items={[
        {
          children: t('taxPresentation.net'),
          key: TaxPresentation.Net,
        },
        {
          children: t('taxPresentation.gross'),
          key: TaxPresentation.Gross,
        },
      ]}
      dataCy="taxPresentation"
      {...props}
    />
  );
};
