import {
  Box,
  Flex,
  Modal,
  ModalProps,
  ScrollBox,
} from '@candisio/design-system';
import { Stepper } from 'components/Stepper/Stepper';
import { AnimatePresence } from 'framer-motion';
import { ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardModalFooter } from './WizardModalFooter';

export interface WizardModalProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  steps: string[];
  currentIndex: number;
  width?: ModalProps['width'];
  footer?: ReactNode;
  children: ReactNode;
  gap?: ComponentProps<typeof Flex>['gap'];
  stepperPadding?: ComponentProps<typeof Box>['padding'];
}
export const WizardModal = ({
  onClose,
  isOpen = false,
  title,
  steps,
  currentIndex,
  children,
  width = '60rem',
  gap = 'space16',
  stepperPadding = 'space32',
  footer,
}: WizardModalProps) => {
  const [t] = useTranslation();

  return (
    <Modal
      title={title}
      closeLabel={t('common.close')}
      onClose={onClose}
      isOpen={isOpen}
      height="calc(100vh - space64)"
      width={width}
      background="gray200"
      padding={0}>
      <Flex direction="column" gap={gap} height="100%" overflow="hidden">
        <Box padding={stepperPadding} width="500px">
          <Stepper steps={steps} currentStep={currentIndex} />
        </Box>
        <ScrollBox flex="1">{children}</ScrollBox>
        <AnimatePresence>
          {footer && <WizardModalFooter>{footer}</WizardModalFooter>}
        </AnimatePresence>
      </Flex>
    </Modal>
  );
};
