import { TruncatedText, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { percentageFormat } from 'utils/format';
import { PaymentConditionCellValue, PaymentConditionsListData } from '../types';

export const PaymentConditionCell = ({
  value,
}: CellProps<PaymentConditionsListData, PaymentConditionCellValue>) => {
  const [t] = useTranslation([
    LOCALE_NAME_SPACE.SETTINGS,
    LOCALE_NAME_SPACE.DOCUMENTS_TABLE,
  ]);

  const { discountOffset, discountPercentage, dueDateOffset } = value;

  if (typeof dueDateOffset === 'undefined') {
    return <Text>{t('documents-table:emptyCellPlaceholder')}</Text>;
  }

  const hasDiscount =
    discountOffset !== undefined && discountPercentage !== undefined;

  return (
    <TruncatedText>
      {hasDiscount
        ? t('settings:paymentConditions.table.values.conditionWithDiscount', {
            discountOffset,
            discountPercentage: percentageFormat(discountPercentage),
            dueDateOffset,
          })
        : t(
            'settings:paymentConditions.table.values.conditionWithoutDiscount',
            {
              dueDateOffset,
            }
          )}
    </TruncatedText>
  );
};
