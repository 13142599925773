import moment from 'moment';

export const getDueDate = (value?: string, offset?: number) => {
  if (typeof value !== 'string' || typeof offset !== 'number') {
    return;
  }

  return moment
    .tz(value, 'Europe/Berlin')
    .add(offset, 'days')
    .format(moment.HTML5_FMT.DATE);
};
