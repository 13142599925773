import { useCurrentUserQuery } from 'generated-types/graphql.types';
import { useCreateCreditCardModalState } from '../CreateCreditCardModalDataProvider';

export const useGetSelectedCarholder = () => {
  const { teamMemberId } = useCreateCreditCardModalState();
  const { data } = useCurrentUserQuery({
    variables: { id: teamMemberId ?? '' },
    skip: !teamMemberId,
  });

  const selectedCardholder = {
    id: teamMemberId,
    avatarUrl: data?.currentUser?.avatarUrl ?? undefined,
    fullName: data?.currentUser?.name,
    phoneNumber: data?.currentUser?.phoneNumber ?? undefined,
  };

  return { selectedCardholder };
};
