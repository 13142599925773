import { ProcessingFormSubmitErrors } from 'components/Form/ProcessingForm/ProcessingForm';
import { DocumentFastApproveMutation } from 'generated-types/graphql.types';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';

/**
 * Returns a function that gets ProcessingFormSubmitErrors based on response
 * from fast approve mutation.
 */
export const useGetFastApproveSubmitErrors = () => {
  const [t] = useTranslation();

  const getSubmitErrors = (
    response: DocumentFastApproveMutation['documentFastApprove']
  ) => {
    if (response?.__typename !== 'DocumentFastApproveErrors') {
      return undefined;
    }

    const errorMessages: ProcessingFormSubmitErrors = {};

    // There's not a nicer way to do this without losing the type safety of only
    // having access to the properties on the fragments on
    // `documentRequestApprovalUnionResponse`
    //
    // It’s important that we set the errors in the correct order, matching the
    // order of the fields in the form, so that the first field with an error is
    // focused.
    //
    for (const error of response.errors) {
      switch (error.__typename) {
        // typo comes from API
        case 'DocumentCannotBeModifedError': {
          errorMessages.root = t(
            'document.requestApproval.submitErrors.document.cannotBeEdited',
            { status: error.status }
          );

          break;
        }

        case 'DocumentIsAlreadyRequestedForApprovalError': {
          errorMessages.root = t(
            'document.requestApproval.submitErrors.document.isAlreadyRequestedForApproval',
            { status: error.status }
          );

          break;
        }

        case 'ContactDoesNotExistError': {
          errorMessages.contact = t(
            'document.requestApproval.submitErrors.contact.doesNotExist'
          );
          break;
        }

        case 'InvalidInvoiceDateError': {
          errorMessages.invoiceDate = t(
            'document.requestApproval.submitErrors.invoiceDate.invalid',
            { date: DateFormatters.compact(new Date(error.referenceDate)) }
          );

          break;
        }

        case 'InvalidDeliveryDateError': {
          errorMessages.deliveryDate = t(
            'document.requestApproval.submitErrors.deliveryDate.afterInvoiceDate',
            { date: DateFormatters.compact(new Date(error.referenceDate)) }
          );

          break;
        }

        case 'BookingsError': {
          switch (error.error.__typename) {
            case 'GeneralLedgerArchivedError': {
              errorMessages[`bookings.${error.index}.generalLedgerAccount`] = t(
                'document.requestApproval.submitErrors.generalLedgerAccount.isArchived',
                { name: error.error.generalLedgerAccount.readableName }
              );

              break;
            }

            case 'BookingKeyArchivedError': {
              errorMessages[`bookings.${error.index}.taxCode`] = t(
                'document.requestApproval.submitErrors.bookingKey.isArchived',
                { name: error.error.bookingKey.readableName }
              );

              break;
            }

            case 'CostCenterArchivedError': {
              errorMessages[`bookings.${error.index}.costCenter`] = t(
                'document.requestApproval.submitErrors.costCenter.isArchived',
                {
                  name: error.error.costCenter.readableName,
                  context: getTranslationContext(),
                }
              );

              break;
            }

            case 'CostObjectArchivedError': {
              errorMessages[`bookings.${error.index}.costObject`] = t(
                'document.requestApproval.submitErrors.costObject.isArchived',
                {
                  name: error.error.costObject.readableName,
                  context: getTranslationContext(),
                }
              );

              break;
            }

            case 'ExtraCostInfoArchivedError': {
              errorMessages[`bookings.${error.index}.extraCostInfo`] = t(
                'document.requestApproval.submitErrors.extraCostInfo.isArchived',
                { name: error.error.extraCostInfo.readableName }
              );

              break;
            }

            default: {
              if (!errorMessages.root) {
                errorMessages.root = error.error.message;
              }

              break;
            }
          }

          break;
        }

        default:
          if (!errorMessages.root) {
            errorMessages.root = error.message;
          }
      }
    }

    return Object.keys(errorMessages).length > 0 ? errorMessages : undefined;
  };

  return getSubmitErrors;
};
