export const RecurringPaymentTrackingEvents = {
  RECURRING_PAYMENTS_WIDGET: {
    CREDIT_CARDS_RP_LINK_CARD_OPENED: 'credit_cards_rp_link_card_opened',
    CREDIT_CARDS_RP_LINK_CARD_CLOSED: 'credit_cards_rp_link_card_closed',
    CREDIT_CARDS_RP_CREATE_CARD_OPENED: 'credit_cards_rp_create_card_opened',
    CREDIT_CARDS_RP_CREATE_CARD_CLOSED: 'credit_cards_rp_create_card_closed',
    CREDIT_CARDS_RP_REMINDER_EMAIL_SENT: 'credit_cards_rp_reminder_email_sent',
    CREDIT_CARDS_RP_ISSUE_RESOLVED: 'credit_cards_rp_issue_resolved',
    CREDIT_CARDS_RP_ADD_CUSTOM_PAYMENT_OPENED:
      'credit_cards_rp_add_custom_payment_opened',
    CREDIT_CARDS_RP_ADD_CUSTOM_PAYMENT_CLOSED:
      'credit_cards_rp_add_custom_payment_closed',
  },
};
