import { ApplyForCreditCardsInput } from 'generated-types/graphql.types';
import { CredCardApplicationFormValues } from './schema';

export enum ApplyForCreditCardsModalHash {
  APPLY_FOR_CC = '#apply_for_cc',
}

export const phoneNumberPattern = new RegExp('^\\+?[0-9]*$');

export const sanitizeCreditCardApplicationFormData: (
  formData: CredCardApplicationFormValues
) => ApplyForCreditCardsInput = (formData: CredCardApplicationFormValues) => {
  const sanitizedFormData: ApplyForCreditCardsInput = {
    company: {
      address: formData.address,
      city: formData.city,
      country: formData.country ?? 'DE',
      foundationYear: Number(formData.foundingYear),
      name: formData.companyName,
      numberOfEmployees: Number(formData.employeeCount),
      plannedCreditLimit: Number(formData.monthlyCardLimit),
      postalCode: Number(formData.zipCode),
      yearlyRevenue: Number(formData.annualRevenue),
    },
    authorizedRepresentative: {
      email: formData.authorizedRepresentativeEmail,
      firstName: formData.authorizedRepresentativeFirstName,
      lastName: formData.authorizedRepresentativeLastName,
    },
    otherContacts: [
      {
        email: formData.contactEmail,
        firstName: formData.contactFirstName,
        lastName: formData.contactLastName,
        phone: formData.contactPhone,
      },
    ],
  };

  return sanitizedFormData;
};
