import { getEmailAddresses } from 'components/EmailEditor/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useGetDocumentFileQuery,
  useGetDocumentForDraftQuery,
  useGetOrganizationEmailAccountsQuery,
  useSendEmailToSupplierMutation,
} from 'generated-types/graphql.types';
import { useAttachments } from 'hooks/useCanAddAttachments/useAttachments';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactSupplierFormOutput } from './contactSupplierFormSchema';

type ContactSupplierFormProps = {
  documentId: string;
  onCloseModal: () => void;
  selectedContactEmail?: string | null;
};

export const useContactSupplier = ({
  documentId,
  onCloseModal,
  selectedContactEmail,
}: ContactSupplierFormProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const currentUser = useCurrentUser();
  const { data, loading: emailAccountsDataLoading } =
    useGetOrganizationEmailAccountsQuery();

  const { data: documentData, loading: documentDataLoading } =
    useGetDocumentForDraftQuery({
      variables: { id: documentId },
    });

  const { data: documentFileData, loading: documentFileDataLoading } =
    useGetDocumentFileQuery({
      variables: { id: documentId },
      fetchPolicy: 'cache-and-network',
    });

  const isDataLoading =
    emailAccountsDataLoading || documentDataLoading || documentFileDataLoading;

  const mainDocumentFile = useMemo(
    () => ({
      name: documentFileData?.getDocument?.documentFile?.name ?? '',
      url: documentFileData?.getDocument?.documentFile?.url ?? '',
      id: documentFileData?.getDocument?.documentFile?.id ?? documentId,
      size: documentFileData?.getDocument?.documentFile?.size ?? 0,
    }),
    [documentFileData, documentId]
  );

  const documentAttachments = documentFileData?.getDocument?.attachments;

  const { attachments } = useAttachments({
    documentFile: mainDocumentFile,
    documentId,
    documentAttachments,
  });

  const organization = useFullOrganization();

  const emailAccounts = data?.organization?.emailAccounts?.records?.filter(
    r => r?.isEnabled && r?.organizationId === organization?.id
  );

  const emailAccount = emailAccounts && emailAccounts[0];

  const defaultSubjectText = t(
    'common:contactSupplierFromCandis.draftText.subject',
    {
      invoiceNumber: documentData?.getDocument?.invoiceNumber?.value,
    }
  );

  const defaultEmailText = [
    t('common:contactSupplierFromCandis.draftText.greetings'),
    t('common:contactSupplierFromCandis.draftText.content', {
      invoiceNumber: documentData?.getDocument?.invoiceNumber?.value,
    }),
    t('common:contactSupplierFromCandis.draftText.details', {
      onBehalfOf: currentUser?.email,
      organizationName: organization?.name,
    }),
    t('common:contactSupplierFromCandis.draftText.closing', {
      importEmail: emailAccount?.address,
      fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
      organizationName: organization?.name,
      userEmail: currentUser?.email,
    }),
    t('common:contactSupplierFromCandis.draftText.footer'),
  ].join('<br/><br/>');

  const [sendEmail] = useSendEmailToSupplierMutation({
    refetchQueries: ['getDocumentHistory'],
  });

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const onSubmit = async (formData: ContactSupplierFormOutput) => {
    setIsFormSubmitting(true);
    const { errors, data } = await sendEmail({
      variables: {
        emailInput: {
          recipients: getEmailAddresses(formData.emailTo).map(email => ({
            email,
          })),
          subject: formData.subject,
          htmlBody: formData.htmlText,
        },
        documentId,
      },
    });

    const emailSentSuccessfully = Boolean(
      !errors &&
        data?.sendEmailToSupplier?.__typename === 'SendEmailResponse' &&
        data?.sendEmailToSupplier?._id
    );

    if (emailSentSuccessfully) {
      success(t('common:contactSupplierFromCandis.sendEmailSuccess'));
      onCloseModal();
    } else {
      error(t('common:contactSupplierFromCandis.sendEmailError'));
    }

    setIsFormSubmitting(false);
  };

  return {
    onSubmit,
    subjectText: defaultSubjectText,
    emailText: defaultEmailText,
    mainDocumentFile,
    attachments,
    isDataLoading,
    isFormSubmitting,
    defaultContactEmail:
      selectedContactEmail ??
      documentData?.getDocument?.extractedContact?.email?.value,
  };
};
