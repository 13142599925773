import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider/useOrganizationId';
import { Key, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { TagsSortBy, useDocumentTags } from './useDocumentTags';

export const useDocumentTagsListLogic = () => {
  const organization = useOrganizationId();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [queryString, setQueryString] = useState('');
  const isArchived = searchParams.get('archived') === 'true';

  const [sortBy, setSortBy] = useState(TagsSortBy.alphabetically);

  const {
    activeCount,
    archivedCount,
    countLoading,
    handleDebounceSearch,
    tags,
  } = useDocumentTags({
    search: queryString,
    sortBy,
  });

  const [selectedTagIds, setSelectedTagIds] = useState<
    Set<string | number> | 'all'
  >(new Set());

  const clearSelection = useCallback(() => {
    setSelectedTagIds(new Set());
  }, []);

  const handleCreateTag = () => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.DOCUMENT_TAGS}/create`,
      search: searchParams.toString(),
    });
  };

  const onSelect = (id: Key) => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.DOCUMENT_TAGS}/${id}`,
      search: searchParams.toString(),
    });
  };

  const onSearchFilter = (filter: string) => {
    handleDebounceSearch(filter);
    setQueryString(filter);
  };

  const onTabChanged = (key: Key) => {
    clearSelection();

    if (key === 'archived') {
      searchParams.set('archived', 'true');
    } else {
      searchParams.delete('archived');
    }

    navigate({
      search: searchParams.toString(),
    });
  };

  const onSortBy = useCallback((key: Key) => {
    setSortBy(key as TagsSortBy);
  }, []);

  return {
    queryString,
    handleCreateTag,
    onSelect,
    onSearchFilter,
    activeCount,
    archivedCount,
    countLoading,
    tags,
    onTabChanged,
    selectedKey: isArchived ? 'archived' : 'active',
    onSortBy,
    sortBy,
    selectedTagIds,
    setSelectedTagIds,
    clearSelection,
  };
};
