import { Flex, Grid, Image, Text } from '@candisio/design-system';
import qrCode from 'containers/credit-cards/CreateCreditCard/SuccessStep/media/Credit_card_issued_QR_code.jpg';
import mobileAppPromoApprovalsTransactionsImg from 'containers/credit-cards/CreateCreditCard/SuccessStep/media/mobile-app-promo-approvals-transactions.png';
import { useTranslation } from 'react-i18next';

export const MobileAppPromoTip = () => {
  const [t] = useTranslation();

  return (
    <Grid gap="space24" paddingLeft="space24" padding="space24" paddingTop={0}>
      <Text fontSize="xxxlarge">
        {t('credit-cards:successPage.mobileAppPromotion.cardTitle')}
      </Text>
      <Flex
        borderRadius="space8"
        justifyContent="center"
        height="15vh"
        background="white"
        maxWidth="510px"
        width="auto">
        <Image
          alt={t('credit-cards:successPage.mobileAppPromotion.bannerImageAlt')}
          src={mobileAppPromoApprovalsTransactionsImg}
        />
      </Flex>
      <Grid
        width="100%"
        justifyContent="center"
        alignItems="center"
        gap="space24"
        templateColumns="1fr 2fr">
        <Image
          style={{
            borderRadius: '8px', // todo: remove this when DS supports borderRadius on Image
          }}
          width="150px"
          height="150px"
          src={qrCode}
          alt={t('credit-cards:successPage.mobileAppPromotion.qrCodeAlt')}
        />
        <Grid fontSize="basic" gap="space8">
          <Text fontSize="xlarge" fontWeight="semibold">
            {t('credit-cards:successPage.mobileAppPromotion.title')}
          </Text>
          <Text>
            {t('credit-cards:successPage.mobileAppPromotion.description')}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
