import { Skeleton } from '@candisio/design-system';
import { useInsightsPreferences } from 'components/Insights/hooks/useInsightsPreferences';
import { useUpdateInsightsPreferences } from 'components/Insights/hooks/useUpdateInsightsPreferences';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { InsightsWidgetReportingUnit } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NetGrossSelector } from './NetGrossSelector';

export const NetGrossSelectorContainer = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { success, error } = useToastMessage();
  const { preferences, loading } = useInsightsPreferences();
  const { update, updating } = useUpdateInsightsPreferences();

  const handleUpdate = async (value: InsightsWidgetReportingUnit) => {
    const { status } = await update(value);

    if (status === 'success') {
      success(t('netGrossSelector.toast.success'));
    } else {
      error(t('netGrossSelector.toast.error'));
    }
  };

  if (loading) {
    return <Skeleton width="space64" height="space32" />;
  }

  return (
    <NetGrossSelector
      unit={preferences.unit}
      onUpdate={handleUpdate}
      updating={updating}
    />
  );
};
