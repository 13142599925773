import {
  Button,
  Grid,
  Paragraph,
  Popover,
  Text,
  usePopover,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Dispatch, SetStateAction, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface RemoveVendorItemProps {
  removeVendor: {
    onClick: () => Promise<void>;
    loading: boolean;
  };
  isPopoverOpen: boolean;
  setIsPopoverOpen: Dispatch<SetStateAction<boolean>>;
}

export const RemoveVendorItem = ({
  setIsPopoverOpen,
  isPopoverOpen,
  removeVendor,
}: RemoveVendorItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { close, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({
      placement: 'bottom',
      isOpen: isPopoverOpen,
      onOpenChange: () => {
        setIsPopoverOpen(prevState => !prevState);
      },
    });

  const removeMerchantTranslations = {
    title: t('vendorListSuggestion.removeVendor.title'),
    description: t('vendorListSuggestion.removeVendor.description'),
    cancelCTALabel: t('vendorListSuggestion.removeVendor.cancelCTALabel'),
    confirmCTALabel: t('vendorListSuggestion.removeVendor.confirmCTALabel'),
  };

  return (
    <>
      <Button
        justifySelf="center"
        variant="tertiary"
        size="small"
        {...triggerProps}
        onClick={(e: MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
        ref={triggerRef}>
        {t('vendorListSuggestion.removeVendor.btnLabel')}
      </Button>
      {isPopoverOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="355px"
          padding="space14">
          <Grid gap="space16">
            <Grid gap="space8">
              <Text fontWeight="bold">{removeMerchantTranslations.title}</Text>
              <Paragraph>{removeMerchantTranslations.description}</Paragraph>
            </Grid>
            <Grid autoFlow="column" alignItems="center" gap="space8">
              <Button
                variant="secondary"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  close();
                }}>
                {removeMerchantTranslations.cancelCTALabel}
              </Button>
              <Button
                color="red"
                loading={removeVendor.loading}
                disabled={removeVendor.loading}
                onClick={async (e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  await removeVendor.onClick();
                  close();
                }}>
                {removeMerchantTranslations.confirmCTALabel}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};
