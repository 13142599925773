import {
  Box,
  Flex,
  Popover,
  Tag,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { useReadableFilterNames } from './hooks/useReadableFilterNames';
import { TransactionDownloadItem } from './hooks/useTransactionDownloadHistoryData';

const MAX_NUMBER_OF_TAGS = 4;

interface FilterNameTagsProps {
  filters: TransactionDownloadItem['filters'];
}

export const FilterNameTags = ({ filters }: FilterNameTagsProps) => {
  const {
    close,
    isOpen,
    open,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'top right' });

  const allAppliedFilters = useReadableFilterNames({ filters });

  const filtersWithinColumn = allAppliedFilters.slice(0, MAX_NUMBER_OF_TAGS);
  const filtersWithinPopover = allAppliedFilters.slice(MAX_NUMBER_OF_TAGS);
  const showEllipsis = filtersWithinPopover.length > 0;

  return (
    <Flex
      gap="space8"
      alignItems="center"
      justifyContent="end"
      overflow="hidden">
      {filtersWithinColumn.map((filt, i) => (
        <Tag color="gray" key={i} variant="secondary">
          <TruncatedText>{filt}</TruncatedText>
        </Tag>
      ))}
      {showEllipsis ? (
        <Tag
          color="gray"
          onMouseEnter={open}
          {...triggerProps}
          ref={triggerRef}
          onMouseLeave={close}
          variant="secondary">
          ...
        </Tag>
      ) : null}
      {isOpen && (
        <Popover {...popoverProps} onMouseLeave={close} ref={popoverRef}>
          <Flex maxWidth="25rem" gap="space8" wrap="wrap">
            {filtersWithinPopover.map((filter, index) => {
              return (
                <Box key={index}>
                  <Tag color="gray" variant="secondary">
                    {filter}
                  </Tag>
                </Box>
              );
            })}
          </Flex>
        </Popover>
      )}
    </Flex>
  );
};
