import {
  Button,
  Flex,
  Image,
  Item,
  Link,
  TabPanel,
  Tabs,
  useModal,
} from '@candisio/design-system';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useTabs } from 'components/Tabs/useTabs';
import { VideoCard } from 'containers/Entitlements/components/Upsell/components/VideoCard';
import { Locale } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { DocumentTagDetailsRoute } from './elements/Drawer/DocumentTagDetailsRoute';
import { DocumentTagsEmptyDataState } from './elements/List/DocumentTagsEmptyDataState';
import { DocumentTagsListItem } from './elements/List/DocumentTagsListItem';
import { MergeModal } from './elements/Modal/MergeModal';
import teaserDe from './toolkit/assets/teaser-de.png';
import teaserEn from './toolkit/assets/teaser-en.png';
import { TagsSortBy } from './toolkit/hooks/useDocumentTags';
import { useDocumentTagsListLogic } from './toolkit/hooks/useDocumentTagsListLogic';

const DOCUMENT_TAGS_GRID_WIDTH = '50rem';

const thumbnails = {
  [Locale.De]: teaserDe,
  [Locale.En]: teaserEn,
};

export const DocumentTags = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [documentTagsSecondIterationFF, documentTagsMergeFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.documentTagsSecondIteration,
      FEATURE_FLAGS.documentTagsMerge,
    ]);

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const videoTourThumbnail = thumbnails[locale];

  const sortMenuItems = [
    {
      id: TagsSortBy.alphabetically,
      label: t('documentTags.sort.alphabetically'),
    },
    {
      id: TagsSortBy.newestFirst,
      label: t('documentTags.sort.newestFirst'),
    },
    {
      id: TagsSortBy.mostUsed,
      label: t('documentTags.sort.mostUsed'),
    },
  ] as const;

  const {
    activeCount,
    archivedCount,
    countLoading,
    handleCreateTag,
    onSearchFilter,
    onSelect,
    queryString,
    tags,
    selectedKey,
    onTabChanged,
    sortBy,
    onSortBy,
    selectedTagIds,
    setSelectedTagIds,
    clearSelection,
  } = useDocumentTagsListLogic();

  const { modalProps, triggerProps } = useModal();

  const areAllTagsSelected = selectedTagIds === 'all';
  const showListActions = areAllTagsSelected || selectedTagIds.size > 0;
  const isMergeDisabled = areAllTagsSelected || selectedTagIds.size < 2;

  const selectedItems = useMemo(() => {
    if (areAllTagsSelected) return tags;

    return tags.filter(({ id }) => selectedTagIds.has(id));
  }, [areAllTagsSelected, selectedTagIds, tags]);

  const isActive = selectedKey === 'active';
  const canSelect = documentTagsMergeFF && isActive;

  const filterableList = (
    <FilterableList
      selectionMode={canSelect ? 'multiple' : 'none'}
      selectedKeys={selectedTagIds}
      onSelectionChange={setSelectedTagIds}
      width={DOCUMENT_TAGS_GRID_WIDTH}
      emptyDataState={<DocumentTagsEmptyDataState />}
      onAction={onSelect}
      isLoading={countLoading}
      onCreate={{
        value: handleCreateTag,
        text: t('documentTags.actions.new'),
      }}
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: value => {
            if (value?.length) {
              onSortBy(value[0]);
            }
          },
          text: sortMenuItems.find(s => s.id === sortBy)?.label ?? '',
          menuButtonItems: sortMenuItems,
        },
      ]}
      searchField={{
        onSearchFilter,
        placeholder: t('documentTags.searchPlaceholder'),
        searchQuery: queryString,
      }}
      listActions={
        showListActions && (
          <Flex
            background="bluebg"
            padding="space16 space16"
            justifyContent="space-between">
            <Flex alignItems="center" gap="space8" fontSize="basic">
              {t('documentTags.mergeModal.subheader.selection', {
                count: areAllTagsSelected ? tags.length : selectedTagIds.size,
              })}
              <Link as="button" onClick={clearSelection}>
                {t('documentTags.mergeModal.subheader.unselectAction')}
              </Link>
            </Flex>
            <Button
              variant="secondary"
              {...(isMergeDisabled ? { disabled: true } : triggerProps)}>
              {t('documentTags.mergeModal.subheader.mergeAction')}
            </Button>
          </Flex>
        )
      }>
      {tags.map(tag => (
        <Item key={tag.id} textValue={tag.name}>
          <FilterableListItem
            itemId={tag.id}
            showDefaultStyling={!documentTagsMergeFF}
            hasAction>
            <DocumentTagsListItem
              tag={tag}
              queryString={queryString}
              isActive={isActive}
            />
          </FilterableListItem>
        </Item>
      ))}
    </FilterableList>
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('documentTags.actions.activeTab'),
        badge: !countLoading ? String(activeCount) : undefined,
        children: filterableList,
      },
      ...(documentTagsSecondIterationFF
        ? [
            {
              key: 'archived',
              title: t('documentTags.actions.archiveTab'),
              badge: !countLoading ? String(archivedCount) : undefined,
              children: filterableList,
            },
          ]
        : []),
    ],
    onSelectionChange: onTabChanged,
    selectedKey,
  });

  const handleMergeSuccess = () => {
    clearSelection();
    modalProps.onClose?.();
  };

  return (
    <SettingsLayout
      title={t('documentTags.title')}
      width={DOCUMENT_TAGS_GRID_WIDTH}
      actions={<Tabs {...tabsProps} />}>
      <TabPanel {...tabPanelProps} />

      <InfoPanel
        sections={t('documentTags.infoPanel.sections', {
          returnObjects: true,
        })}
        title={t('documentTags.infoPanel.title')}
        intercomLink={
          <IntercomLink
            translation={t('documentTags.infoPanel.feedbackButton')}
            id="settings-tags-intercom-link"
          />
        }>
        <VideoCard
          videoTourUrl={t('documentTags.drawer.links.videoTour.url')}
          videoTourTitle={t('documentTags.drawer.links.videoTour.label')}
          withFilter>
          <Image src={videoTourThumbnail} alt="" height="10rem" />
        </VideoCard>
      </InfoPanel>

      <MergeModal
        modalProps={modalProps}
        tags={selectedItems}
        handleMergeSuccess={handleMergeSuccess}
      />

      <DocumentTagDetailsRoute />
    </SettingsLayout>
  );
};
