import { Grid, Text } from '@candisio/design-system';
import i18next from 'i18next';

export const formatCountByType = ({
  transactionsCount,
  settlementsCount,
  t,
}: {
  transactionsCount?: number;
  settlementsCount?: number;
  t: i18next.TFunction;
}) => {
  return (
    <Grid>
      {transactionsCount && transactionsCount > 0 ? (
        <Text>
          {t('export.exportSummary.types.creditCardPayments', {
            count: transactionsCount,
          })}
        </Text>
      ) : null}
      {settlementsCount && settlementsCount > 0 ? (
        <Text>
          {t('export.exportSummary.types.cardSettlements', {
            count: settlementsCount,
          })}
        </Text>
      ) : null}
    </Grid>
  );
};
