import { Button, Grid, Heading, usePopover } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { CreditCardOption } from 'views/Settings/Teams/toolkit/hooks/useCreditCardOptions';
import { creditCardSetupFormErrorMessages } from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormErrorMessages';
import {
  CreditCardSetupFormOutput,
  CreditCardSetupFormValues,
  creditCardSetupFormSchema,
} from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormSchema';
import { SecondaryLayoutDrawer } from '../../components/SecondaryLayoutDrawer';
import { SwitchFieldWithPanel } from '../../components/SwitchFieldWithPanel';
import { UnsetTeamCardsConfirmationPopover } from '../../components/UnsetTeamCardsConfirmationPopover';
import { CreditCardsPanel } from './CreditCardsPanel';
import { LimitsPanel } from './LimitsPanel';

const CREDIT_CARDS_SETUP_FORM_ID = 'credit-cards-for-teams-setup-form';

interface CreditCardsSetupFormProps {
  isLoadingCreditCardOptions: boolean;
  defaultValues: CreditCardSetupFormValues;
  creditCardOptions: CreditCardOption[];
  search: string;
  teamAdminName: string | undefined;
  isUpdateCCTeamSettingsPending: boolean;
  onGoBack: () => void;
  onSubmit: (formData: CreditCardSetupFormOutput) => void;
  onSearch: (value: string) => void;
  onLoadMore: () => void;
}

export const CreditCardsSetupForm = ({
  isLoadingCreditCardOptions,
  creditCardOptions,
  defaultValues,
  teamAdminName,
  isUpdateCCTeamSettingsPending,
  search,
  onSubmit,
  onGoBack,
  onSearch,
  onLoadMore,
}: CreditCardsSetupFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [isSwitchToggleable, setIsSwitchToggleable] = useState(
    !defaultValues.isACardAssignedToTeam
  );

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({
      placement: 'bottom',
    });

  const form = useForm({
    defaultValues,
    values: defaultValues,
    resolver: zodResolver({
      errorMessages: creditCardSetupFormErrorMessages,
      zodSchema: creditCardSetupFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
  });

  const handleIsCardAssignedToTeamChange = useCallback(() => {
    form.setValue('cards', [], { shouldDirty: true, shouldValidate: true });
  }, [form]);

  const handleAdminCanIssueCardChange = () => {
    form.setValue('limit', null, {
      shouldDirty: true,
      shouldValidate: true,
    });

    form.setValue('oneTimeCardLimit', null, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const limitPanelText = t(
    'teams.editTeam.creditCardsSetup.form.adminAllowedToManageCards',
    {
      adminName: teamAdminName,
    }
  );

  const handleConfrimUnsetTeamCards = () => {
    setIsSwitchToggleable(true);
    form.setValue('isACardAssignedToTeam', false);
    form.setValue('cards', [], { shouldDirty: true, shouldValidate: true });
  };

  return (
    <SecondaryLayoutDrawer
      onGoBack={onGoBack}
      footer={
        <Button
          type="submit"
          form={CREDIT_CARDS_SETUP_FORM_ID}
          loading={isUpdateCCTeamSettingsPending}>
          {t('teams.editTeam.teamDetails.saveCTA')}
        </Button>
      }>
      <FormProvider {...form}>
        <Grid
          as="form"
          onSubmit={form.handleSubmit(onSubmit)}
          gap="space16"
          height="100%"
          templateRows="auto auto 1fr"
          id={CREDIT_CARDS_SETUP_FORM_ID}>
          <Heading as="h3">
            {t('teams.editTeam.creditCardsSetup.heading')}
          </Heading>
          <SwitchFieldWithPanel
            switchFieldName="isTeamAdminAllowedToManageCards"
            text={limitPanelText}
            onSwitchChange={handleAdminCanIssueCardChange}
            switchFieldLabel={t(
              'teams.editTeam.creditCardsSetup.form.fields.isTeamAdminAllowedToManageCards.label'
            )}>
            <LimitsPanel />
          </SwitchFieldWithPanel>

          <SwitchFieldWithPanel
            switchFieldName="isACardAssignedToTeam"
            isSwitchToggleable={isSwitchToggleable}
            text={t('teams.editTeam.creditCardsSetup.form.assignCardsToTeam')}
            switchFieldLabel={t(
              'teams.editTeam.creditCardsSetup.form.fields.isACardAssignedToTeam.label'
            )}
            onSwitchChange={handleIsCardAssignedToTeamChange}
            ref={triggerRef}
            {...(isSwitchToggleable ? undefined : triggerProps)}>
            <CreditCardsPanel
              search={search}
              creditCardOptions={creditCardOptions}
              isLoadingCreditCardOptions={isLoadingCreditCardOptions}
              savedOptions={defaultValues.cards ?? []}
              onSearch={onSearch}
              onLoadMore={onLoadMore}
            />
            {isOpen && (
              <UnsetTeamCardsConfirmationPopover
                ref={popoverRef}
                {...popoverProps}
                onClose={close}
                onConfirm={handleConfrimUnsetTeamCards}
              />
            )}
          </SwitchFieldWithPanel>
        </Grid>
      </FormProvider>
    </SecondaryLayoutDrawer>
  );
};
