import { Grid, ScrollBox, Text, Tooltip } from '@candisio/design-system';
import { CustomDatevWarningCode } from 'components/DocumentsTable/types';
import { ExportableEntityType } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportErrorCodes } from 'views/Integrations/Export/consts';
import {
  DocumentExportStatus,
  ExportError,
} from 'views/Integrations/Export/types';
import { ExportStatusTooltipProps } from './ExportStatusTooltip';

export const BdsExportStatusTooltip = forwardRef<
  HTMLDivElement,
  ExportStatusTooltipProps
>(({ exportStatus, ...restProps }, ref) => {
  const [t] = useTranslation();
  const warnings = exportStatus.warnings ?? [];
  const exportError = exportStatus.exportError ?? undefined;
  const entityType = exportStatus.entityType;
  const status = exportStatus.status;
  const hasDocumentDirectionOthers =
    exportStatus.isDocumentDirectionOthers ?? false;

  const isDocumentEntity =
    exportStatus.entityType === ExportableEntityType.Document;

  return (
    <Tooltip paddingX={0} paddingY={0} {...restProps} ref={ref}>
      <ScrollBox padding="space16" maxHeight="inherit" scrollDirection="y">
        <Grid gap="space8" fontSize="small" maxWidth="360px">
          {status === 'EXPORTED' &&
            (isDocumentEntity ? (
              <Grid>
                <Text fontWeight="semibold">
                  {t('export.table.columns.status.bdsExport.document_export', {
                    index: 1,
                  })}
                  {t(
                    'export.table.columns.status.bdsExport.successfulEntityStatus'
                  )}
                </Text>
                {warnings.map((warning, index) => {
                  if (
                    warning === CustomDatevWarningCode.DOCUMENT_ALREADY_EXIST
                  ) {
                    return <DetailsText key={index} details={warning} />;
                  }

                  return <WarningText key={index} warning={warning} />;
                })}
                {hasDocumentDirectionOthers ? (
                  <Grid paddingTop="space8">
                    <Text fontWeight="semibold">
                      {t('export.table.columns.status.bdsExport.csv_export', {
                        index: 2,
                      })}
                      {t(
                        'export.table.columns.status.bdsExport.skippedEntityStatus'
                      )}
                    </Text>
                    <Text>
                      {t(
                        'export.table.columns.status.bdsExport.csvNotRequiredEntityStatusMessage',
                        {
                          entityType: entityType
                            ? // eslint-disable-next-line candis/no-template-strings-inside-translation
                              t(
                                `export.exportableEntityType.${entityType.toLowerCase()}`
                              )
                            : t('export.exportableEntityType.default'),
                        }
                      )}
                    </Text>
                  </Grid>
                ) : (
                  <Grid paddingTop="space8">
                    <Text fontWeight="semibold">
                      {t('export.table.columns.status.bdsExport.csv_export', {
                        index: 2,
                      })}
                      {t(
                        'export.table.columns.status.bdsExport.successfulEntityStatus'
                      )}
                    </Text>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid>
                <Grid paddingTop="space8">
                  <Text>
                    <Text fontWeight="semibold">
                      {t(
                        'export.table.columns.status.bdsExport.document_export',
                        {
                          index: 1,
                        }
                      )}
                    </Text>
                    <Text>
                      {t(
                        'export.table.columns.status.bdsExport.skippedEntityStatus'
                      )}
                    </Text>
                  </Text>
                  <Text>
                    {t(
                      'export.table.columns.status.bdsExport.notRequiredEntityStatusMessage',
                      {
                        entityType: entityType
                          ? // eslint-disable-next-line candis/no-template-strings-inside-translation
                            t(
                              `export.exportableEntityType.${entityType.toLowerCase()}`
                            )
                          : t('export.exportableEntityType.default'),
                      }
                    )}
                  </Text>
                </Grid>
                <Grid paddingTop="space8">
                  <Text fontWeight="semibold">
                    {t('export.table.columns.status.bdsExport.csv_export', {
                      index: 2,
                    })}
                    {t(
                      'export.table.columns.status.bdsExport.successfulEntityStatus'
                    )}
                  </Text>
                </Grid>
              </Grid>
            ))}
          {status !== 'EXPORTED' && exportError && entityType && (
            <BdsErrorText
              entityType={entityType}
              exportError={exportError}
              isDocumentEntity={isDocumentEntity}
              hasDocumentDirectionOthers={hasDocumentDirectionOthers}
            />
          )}
        </Grid>
      </ScrollBox>
    </Tooltip>
  );
});

const DetailsText = ({ details }: { details: string }) => {
  const [t] = useTranslation();
  const detailsKey = `export.table.columns.status.bdsExport.entityDetails.${details?.toLowerCase()}`;
  const detailsMessage = i18next.exists(detailsKey) ? t(detailsKey) : details;

  return <Text>{detailsMessage}</Text>;
};

const WarningText = ({ warning }: { warning: string }) => {
  const [t] = useTranslation();
  const warningKey = `export.table.columns.status.bdsExport.entityDetails.${warning?.toLowerCase()}`;
  const warningMessage = i18next.exists(warningKey) ? t(warningKey) : warning;

  return <Text color="yellow500">{warningMessage}</Text>;
};

const BdsErrorText = ({
  exportError,
  entityType,
  isDocumentEntity,
  hasDocumentDirectionOthers,
}: {
  exportError: ExportError;
  entityType: NonNullable<DocumentExportStatus['entityType']>;
  isDocumentEntity: boolean;
  hasDocumentDirectionOthers: boolean;
}) => {
  const [t] = useTranslation();

  const getGenericError = (): string | undefined => {
    const errorTslKey = `export.table.columns.status.bdsExport.genericError.${exportError.errorCode?.toLowerCase()}`;
    if (i18next.exists(errorTslKey)) {
      return t(errorTslKey);
    }

    return undefined;
  };

  const getEntityErrorMessage = (): string | undefined => {
    const failedDocuments = exportError.failedDocuments || [];
    if (
      exportError.errorCode ===
      ExportErrorCodes.ABORTED_BECAUSE_OF_OTHER_DOCUMENT
    ) {
      if (failedDocuments.length) {
        return t(
          'export.table.columns.status.bdsExport.entityErrors.aborted_because_of_other_document',
          {
            invoiceNumber: failedDocuments.map(d => d.invoiceNumber).join(', '),
          }
        );
      }

      return t(
        'export.table.columns.status.bdsExport.entityErrors.aborted_because_of_other_document_but_documents_has_deleted'
      );
    }

    if (exportError.errorCode === ExportErrorCodes.NON_EXPORTABLE) {
      return t(
        'export.table.columns.status.bdsExport.entityErrors.non_exportable',
        {
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          entityType: t(
            `export.exportableEntityType.${entityType.toLowerCase()}`
          ),
        }
      );
    }

    const errorTslKey = `export.table.columns.status.bdsExport.entityErrors.${exportError.errorCode?.toLowerCase()}`;

    if (i18next.exists(errorTslKey)) {
      return t(errorTslKey);
    }

    return undefined;
  };

  const getCsvErrorMessage = (): string | undefined => {
    const errorTslKey = `export.table.columns.status.bdsExport.csvErrors.${exportError.errorCode?.toLowerCase()}`;

    if (i18next.exists(errorTslKey)) {
      return t(errorTslKey);
    }

    return undefined;
  };

  const genericErrorText = getGenericError();

  if (genericErrorText) {
    return <Text color="red500">{genericErrorText}</Text>;
  }

  const entityErrorText = getEntityErrorMessage();
  const csvErrorText = getCsvErrorMessage();

  if (hasDocumentDirectionOthers) {
    return (
      <Grid>
        <Text fontWeight="semibold">
          {t('export.table.columns.status.bdsExport.document_export', {
            index: 1,
          })}
          {t('export.table.columns.status.bdsExport.failedEntityStatus')}
        </Text>
        <Text color="red500">{entityErrorText}</Text>
        <Grid paddingTop="space8">
          <Text>
            <Text fontWeight="semibold">
              {t('export.table.columns.status.bdsExport.csv_export', {
                index: 2,
              })}
            </Text>
            <Text>
              {t('export.table.columns.status.bdsExport.skippedEntityStatus')}
            </Text>
          </Text>
          <Text>
            {t(
              'export.table.columns.status.bdsExport.csvNotRequiredEntityStatusMessage',
              {
                entityType: entityType
                  ? // eslint-disable-next-line candis/no-template-strings-inside-translation
                    t(`export.exportableEntityType.${entityType.toLowerCase()}`)
                  : t('export.exportableEntityType.default'),
              }
            )}
          </Text>
        </Grid>
      </Grid>
    );
  }

  if (entityErrorText && csvErrorText) {
    return (
      <Grid>
        <Text fontWeight="semibold">
          {t('export.table.columns.status.bdsExport.document_export', {
            index: 1,
          })}
          {t('export.table.columns.status.bdsExport.failedEntityStatus')}
        </Text>
        <Text color="red500">{entityErrorText}</Text>
        <Grid paddingTop="space8">
          <Text fontWeight="semibold">
            {t('export.table.columns.status.bdsExport.csv_export', {
              index: 2,
            })}
            {t('export.table.columns.status.bdsExport.failedEntityStatus')}
          </Text>
          <Text color="red500">{csvErrorText}</Text>
        </Grid>
      </Grid>
    );
  }

  if (entityErrorText && !csvErrorText) {
    if (isDocumentEntity) {
      return (
        <Grid>
          <Text fontWeight="semibold">
            {t('export.table.columns.status.bdsExport.document_export', {
              index: 1,
            })}
            {t('export.table.columns.status.bdsExport.failedEntityStatus')}
          </Text>
          <Text color="red500">{entityErrorText}</Text>
          <Grid paddingTop="space8">
            <Text>
              <Text fontWeight="semibold">
                {t('export.table.columns.status.bdsExport.csv_export', {
                  index: 2,
                })}
              </Text>
              <Text color="red500">
                {t('export.table.columns.status.bdsExport.skippedEntityStatus')}
              </Text>
            </Text>
            <Text>
              {t(
                'export.table.columns.status.bdsExport.skippedEntityStatusMessage'
              )}
            </Text>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid paddingTop="space8">
        <Text>
          <Text fontWeight="semibold">
            {t('export.table.columns.status.bdsExport.document_export', {
              index: 1,
            })}
          </Text>
          <Text>
            {t('export.table.columns.status.bdsExport.skippedEntityStatus')}
          </Text>
        </Text>
        <Text>
          {t(
            'export.table.columns.status.bdsExport.notRequiredEntityStatusMessage',
            {
              entityType: entityType
                ? // eslint-disable-next-line candis/no-template-strings-inside-translation
                  t(`export.exportableEntityType.${entityType.toLowerCase()}`)
                : t('export.exportableEntityType.default'),
            }
          )}
        </Text>
        <Grid paddingTop="space8">
          <Text fontWeight="semibold">
            {t('export.table.columns.status.bdsExport.csv_export', {
              index: 2,
            })}
            {t('export.table.columns.status.bdsExport.failedEntityStatus')}
          </Text>
          <Text color="red500">{entityErrorText}</Text>
        </Grid>
      </Grid>
    );
  }

  if (!entityErrorText && csvErrorText) {
    if (isDocumentEntity) {
      return (
        <Grid>
          <Text fontWeight="semibold">
            {t('export.table.columns.status.bdsExport.document_export', {
              index: 1,
            })}
            {t('export.table.columns.status.bdsExport.successfulEntityStatus')}
          </Text>
          <Grid paddingTop="space8">
            <Text fontWeight="semibold">
              {t('export.table.columns.status.bdsExport.csv_export', {
                index: 2,
              })}
              {t('export.table.columns.status.bdsExport.failedEntityStatus')}
            </Text>
            <Text color="red500">{csvErrorText}</Text>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid paddingTop="space8">
        <Text>
          <Text fontWeight="semibold">
            {t('export.table.columns.status.bdsExport.document_export', {
              index: 1,
            })}
          </Text>
          <Text>
            {t('export.table.columns.status.bdsExport.skippedEntityStatus')}
          </Text>
        </Text>
        <Text>
          {t(
            'export.table.columns.status.bdsExport.notRequiredEntityStatusMessage',
            {
              entityType: entityType
                ? // eslint-disable-next-line candis/no-template-strings-inside-translation
                  t(`export.exportableEntityType.${entityType.toLowerCase()}`)
                : t('export.exportableEntityType.default'),
            }
          )}
        </Text>
        <Grid paddingTop="space8">
          <Text fontWeight="semibold">
            {t('export.table.columns.status.bdsExport.csv_export', {
              index: 2,
            })}
            {t('export.table.columns.status.bdsExport.failedEntityStatus')}
          </Text>
          <Text color="red500">{csvErrorText}</Text>
        </Grid>
      </Grid>
    );
  }

  return (
    <Text color="red500">
      {t('export.table.columns.status.bdsExport.entityErrors.unknown')}
    </Text>
  );
};
