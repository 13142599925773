import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { useTranslation } from 'react-i18next';

interface NetAmountProps {
  currency?: string;
  hasMultipleBookings?: boolean;
}

export const NetAmount = ({
  currency,
  hasMultipleBookings,
}: NetAmountProps) => {
  const [t] = useTranslation();

  return (
    <HookFormAmountField
      name={hasMultipleBookings ? 'netAmount' : 'bookings.0.netAmount'}
      currency={currency}
      label={t('document.requestApproval.inputs.netAmount.label')}
      placeholder={t('document.requestApproval.inputs.netAmount.placeholder')}
      readOnly
    />
  );
};
