import { useMemo } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';
import { getCountries } from 'utils/get-country-name';
import { HookFormComboBoxField } from './HookFormComboBoxField';
import { HookFormSelectFieldProps } from './HookFormSelectField';

export const HookFormCountrySelectField = <TFormValues extends FieldValues>(
  props: Omit<HookFormSelectFieldProps<TFormValues>, 'items'>
) => {
  const allCountries = useMemo(() => {
    return getCountries().map(({ isoCode, countryName }) => ({
      children: countryName,
      key: isoCode,
    }));
  }, []);

  const selectedCountry = useWatch<TFormValues>({ name: props.name });
  const initialIndex = useMemo(() => {
    const selectedCountryIndex = allCountries.findIndex(
      c => c.key === selectedCountry
    );

    return selectedCountryIndex >= 0 ? selectedCountryIndex : 0;
  }, [allCountries, selectedCountry]);

  return (
    <HookFormComboBoxField
      {...props}
      defaultItems={allCountries}
      isVirtualized
      label={props.label}
      initialTopMostItemIndex={{
        index: initialIndex,
        align: 'center',
      }}
    />
  );
};
