import { Flex } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { CandisApiExportInfoCard } from 'views/Integrations/Export/elements/sidebar/Init/api/candisApi/CandisApiExportInfo';
import { CandisApiExportSummary } from 'views/Integrations/Export/elements/sidebar/Init/api/candisApi/CandisApiExportSummary';
import { CandisApiInfo } from 'views/Integrations/Export/elements/sidebar/Init/api/candisApi/CandisApiInfo';
import { ZipExportButton } from 'views/Integrations/Export/elements/sidebar/Init/file/zip/ZipExportButton';
import { useZipExport } from 'views/Integrations/Export/useZipExport';

export const CandisApi = () => {
  const [t] = useTranslation();

  const {
    isReExport,
    zipExportMutationLoading,
    hasExportableEntity,
    onZipExport,
  } = useZipExport();

  if (!hasExportableEntity) return null;

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      paddingBottom={isReExport ? '0' : 'space24'}>
      <Flex direction="column" gap="space24">
        <CandisApiExportInfoCard />
        <CandisApiExportSummary />
      </Flex>

      {isReExport ? (
        <ZipExportButton
          hasExportableEntity={hasExportableEntity}
          onExport={onZipExport}
          buttonLabel={t('export.exportForm.actionBar.buttonLabel')}
          loading={zipExportMutationLoading}
        />
      ) : (
        <CandisApiInfo />
      )}
    </Flex>
  );
};
