import { Grid, Icon } from '@candisio/design-system';

interface StatusIconProps {
  hasErrors: boolean;
}

export const ImportStatusIcon = ({ hasErrors }: StatusIconProps) => (
  <Grid justifyContent="center" alignContent="center" height="41px">
    <Icon
      icon={hasErrors ? 'failCircle' : 'checkCircle'}
      color={hasErrors ? 'red700' : 'green700'}
      size="space24"
    />
  </Grid>
);
