import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { Filters } from 'react-table';

interface Params<TableDataType extends object> {
  filters: Filters<TableDataType>;
  newConfigurations: Configuration[];
}

export const getHiddenColumnsWithFiltersApplied = <
  TableDataType extends object
>({
  newConfigurations,
  filters,
}: Params<TableDataType>) => {
  const hiddenColumnsWithFiltersApplied: string[] = [];

  const hiddenColumns = newConfigurations
    .filter(configuration => !configuration.isVisible)
    .map(configuration => configuration.id);

  const filteredColumns = filters.map(column => column.id);

  hiddenColumns.forEach(hiddenColumn => {
    filteredColumns.forEach(filterColumn => {
      if (filterColumn === hiddenColumn) {
        hiddenColumnsWithFiltersApplied.push(hiddenColumn);
      }
    });
  });

  return hiddenColumnsWithFiltersApplied;
};
