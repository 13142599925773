import { SvgIcon, SvgProps } from '../SvgIcon';

export const UploadFileProcessingIcon = ({ ...props }: SvgProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.625 23.25H4.125C3.29657 23.25 2.625 22.5784 2.625 21.75V5.625"
        stroke="#9E9E9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.375 18.159C21.3979 19.1498 20.6157 19.9727 19.625 20H7.375C6.38432 19.9727 5.60205 19.1498 5.625 18.159V2.591C5.60205 1.60021 6.38432 0.777263 7.375 0.75H16.275C16.7441 0.752355 17.1917 0.947216 17.513 1.289L20.862 4.813C21.193 5.16476 21.3766 5.63 21.375 6.113V18.159Z"
        stroke="#9E9E9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
