import { IntegrationName } from 'generated-types/graphql.types';
import { accountsPayableNumberSchema } from 'utils/zodFormValidation/Schemas/accountsPayableNumber';
import { accountsReceivableNumberSchema } from 'utils/zodFormValidation/Schemas/accountsReceivableNumber';
import { z } from 'zod';

export type AccountType = 'accountsReceivable' | 'accountsPayable';

export type CheckAccountsNumber = (
  value: string,
  accountType?: AccountType
) => Promise<
  | { isAvailable: true; contactName: undefined; numberUsed?: undefined }
  | { isAvailable: false; contactName: string; numberUsed?: AccountType }
>;

export interface AccountsNumberSchemaOptions {
  checkAccountsNumber?: CheckAccountsNumber;
  integration?: IntegrationName;
  accountType?: AccountType;
  glaLength?: number;
  shouldRequireAccountsPayableNumber?: boolean;
}

/** Schema to validate accounts number (payable or receivable), including duplicate check */
export const accountsNumberSchemaWithDuplicateCheck = ({
  checkAccountsNumber,
  shouldRequireAccountsPayableNumber,
  integration = IntegrationName.Datev,
  accountType = 'accountsPayable',
  glaLength,
}: AccountsNumberSchemaOptions = {}) => {
  const schema =
    accountType === 'accountsReceivable'
      ? accountsReceivableNumberSchema(integration, glaLength)
      : accountsPayableNumberSchema(
          integration,
          glaLength,
          shouldRequireAccountsPayableNumber
        );

  return schema.superRefine(async (value, context) => {
    if (!checkAccountsNumber || !value) {
      return;
    }

    const { isAvailable, contactName } = await checkAccountsNumber(value);

    if (!isAvailable) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        params: { contactName },
      });
    }
  });
};
