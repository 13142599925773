import { Box, Checkbox, TruncatedText } from '@candisio/design-system';
import { ReactNode } from 'react';

interface FilterMenuItemProps {
  isSelected: boolean;
  onSelect: () => void;
  label: string | ReactNode;
}

export const FilterMenuItem = ({
  isSelected,
  label,
  onSelect,
}: FilterMenuItemProps) => {
  return (
    <Box as="li" listStyle="none" paddingLeft="3px" paddingY="space8">
      <Checkbox isSelected={isSelected} onChange={onSelect}>
        {typeof label === 'string' ? (
          <TruncatedText>{label}</TruncatedText>
        ) : (
          <>{label}</>
        )}
      </Checkbox>
    </Box>
  );
};
