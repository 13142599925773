export const transition = {
  duration: 0.3,
  ease: 'easeInOut',
};

export const animationVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};
