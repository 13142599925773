import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { GoodsReceiptsTableData } from '../types';

export const LineItemsCell = ({
  value,
}: {
  value?: GoodsReceiptsTableData['receiptLines'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  if (!value) {
    return <Text>-</Text>;
  }

  const lineItemsText = value
    .map(({ quantity, description }) =>
      t('lineItems.goods', {
        quantity,
        description,
      })
    )
    .join(', ');

  return <TruncatedText wordBreak="break-word">{lineItemsText}</TruncatedText>;
};
