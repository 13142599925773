import { Table, Cell, Filter } from '@candisio/design-system';
import { DateCell } from 'components/DocumentsTable/Cells/DateCell';
import { InvoiceListCell } from 'components/PurchaseOrders/Table/InvoiceListCell';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { paginationFiltersHooks } from 'views/Archive/PurchaseOrders/hooks/paginationFilterHooks';
import {
  GoodsReceiptsTableColumns,
  GoodsReceiptsTableData,
  GoodsReceiptsTableProps,
} from '../types';
import { ConnectedOrdersCell } from './ConnectedOrdersCell';
import { LineItemsCell } from './LineItemsCell';
import { TableHeader } from './TableHeader';

const useColumns = (
  visibleColumns: GoodsReceiptsTableProps['columns'],
  filterOptions: GoodsReceiptsTableProps['filterOptions']
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const defaultColumn = useMemo(
    (): Partial<Column<GoodsReceiptsTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: TableHeader,
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof GoodsReceiptsTableData];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              const isLoading = filterOption.isLoading;

              return (
                <Filter<GoodsReceiptsTableData>
                  column={column}
                  options={filterOption.data}
                  isLoading={isLoading}
                  applyFilterButton={t('filterApply')}
                  resetFilterButton={t('filterReset')}
                  searchFieldPlaceholder={t('filterSearchPlaceholder')}
                  filterLabel={t('filterLabel')}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
    }),
    [filterOptions, t]
  );

  const columns = useMemo(() => {
    const allColumns: GoodsReceiptsTableColumns = {
      contact: {
        accessor: 'contact',
        disableSortBy: true,
        Cell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
            customPaginationFilterHooks={paginationFiltersHooks}
          />
        ),
      },
      orderNumber: {
        accessor: 'orderNumber',
        Cell: ConnectedOrdersCell,
        disableSortBy: true,
        disableFilters: true,
      },
      deliveryDate: {
        accessor: 'deliveryDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        disableFilters: true,
      },
      status: {
        accessor: 'status',
        Cell,
        disableSortBy: true,
      },
      connectedInvoices: {
        accessor: 'connectedInvoices',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }) => (
          <InvoiceListCell connectedInvoices={value || []} />
        ),
      },
      receiptNumber: {
        accessor: 'receiptNumber',
        Cell,
        disableFilters: true,
      },
      receiptLines: {
        accessor: 'receiptLines',
        Cell: LineItemsCell,
        disableSortBy: true,
        disableFilters: true,
      },
    };

    if (!visibleColumns.length) {
      return Object.values(allColumns);
    }

    return visibleColumns.map(accessor => allColumns[accessor]);
  }, [visibleColumns]);

  return { columns, defaultColumn };
};

export const GoodsReceiptsTable = ({
  columns: visibleColumns,
  data,
  defaultFilters,
  defaultSortBy,
  filterOptions,
  isLoading,
  onEndReached,
  onFilter,
  onSort,
  customEmptyState,
}: GoodsReceiptsTableProps) => {
  const { columns, defaultColumn } = useColumns(visibleColumns, filterOptions);

  return (
    <Table<GoodsReceiptsTableData>
      columns={columns}
      data={data}
      defaultColumn={defaultColumn}
      filterOptions={filterOptions}
      isLoading={isLoading}
      onEndReached={onEndReached}
      onFilter={onFilter}
      onSort={onSort}
      customEmptyState={customEmptyState}
      initialState={{
        filters: defaultFilters ?? [],
        sortBy: defaultSortBy ?? [],
      }}
      borderTopRadius="none"
    />
  );
};
