import moment from 'moment';

/**
 * Validates invoice date and returns a warning message if the date is unusual.
 *
 * @param invoiceDate The date of the invoice to validate
 * @param timezone Timezone for date calculations
 */

interface ValidateInvoiceDateOptions {
  /** The date of the invoice to validate */
  invoiceDate?: string | null;
  /** Timezone for date calculations */
  timezone?: string;
}
export const getUnusualInvoiceDateWarning = ({
  invoiceDate,
  timezone = 'Europe/Berlin',
}: ValidateInvoiceDateOptions) => {
  if (!invoiceDate) {
    return null;
  }

  const now = moment.tz(timezone);
  const oneYearAgo = now.clone().subtract(1, 'years');
  const invoiceMoment = moment.tz(invoiceDate, timezone);

  // Check for past date older than one year
  if (invoiceMoment.isBefore(oneYearAgo, 'day')) {
    return 'document.requestApproval.inputs.errors.invoiceDate.unusual.past';
  }

  // Check for future date after 1 day
  if (invoiceMoment.isAfter(now.clone().add(1, 'days'))) {
    return 'document.requestApproval.inputs.errors.invoiceDate.unusual.future';
  }

  // No warnings
  return null;
};
