import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../providers/LocaleProvider';

export const optionsMonthsTranslations = [
  { key: '01', translation: 'dateRangePicker.monthsList.january' },
  { key: '02', translation: 'dateRangePicker.monthsList.february' },
  { key: '03', translation: 'dateRangePicker.monthsList.march' },
  { key: '04', translation: 'dateRangePicker.monthsList.april' },
  { key: '05', translation: 'dateRangePicker.monthsList.may' },
  { key: '06', translation: 'dateRangePicker.monthsList.june' },
  { key: '07', translation: 'dateRangePicker.monthsList.july' },
  { key: '08', translation: 'dateRangePicker.monthsList.august' },
  { key: '09', translation: 'dateRangePicker.monthsList.september' },
  { key: '10', translation: 'dateRangePicker.monthsList.october' },
  { key: '11', translation: 'dateRangePicker.monthsList.november' },
  { key: '12', translation: 'dateRangePicker.monthsList.december' },
];

export const useSelectOptionMonths = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return optionsMonthsTranslations.map(({ key, translation }) => ({
    id: key,
    name: t(translation),
  }));
};

export const useHookFormSelectOptionMonths = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return optionsMonthsTranslations.map(({ key, translation }) => ({
    key,
    children: t(translation),
  }));
};
