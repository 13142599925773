import { Grid, Image, Link, Text } from '@candisio/design-system';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { MobileAppPromotionTrackingEvents } from 'providers/AnalyticsProvider/events/MobileAppPromotionTrackingEvents';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileAppPromotionModal } from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/MobileAppPromotionModal';
import qrCode from './media/credit-cards-dashboard-QR-code.jpg';
import mobileAppPromotionImage from './media/mobile-app-promotion-img.png';

export const MobileAppTipPromotion = () => {
  const [t] = useTranslation();

  const [mobileAppPromotionModalVisible, setMobileAppPromotionModalVisible] =
    useState(false);

  const { track } = useAnalytics();

  return (
    <Grid gap="space16" padding="space16">
      <Text fontSize="xxlarge">{t('credit-cards:successPage.title')} </Text>
      <Image
        alt={t(
          'credit-cards:creditCardTipsWidget.mobileApp.backgroundImageAlt'
        )}
        width="100%"
        src={mobileAppPromotionImage}
      />
      <Grid gap="space4">
        <Text fontSize="large">
          {t('credit-cards:creditCardTipsWidget.mobileApp.description')}
        </Text>
        <Link
          icon="arrowDownCircle"
          iconPosition="right"
          fontSize="basic"
          onClick={() => {
            setMobileAppPromotionModalVisible(true);
            track(
              MobileAppPromotionTrackingEvents.MOBILE_APP_PROMOTION_MODAL_OPENED,
              {
                path: 'credit-cards-dashboard',
              }
            );
          }}>
          {t('credit-cards:creditCardTipsWidget.mobileApp.linkLabel')}
        </Link>
      </Grid>
      <MobileAppPromotionModal
        qrCode={qrCode}
        visible={mobileAppPromotionModalVisible}
        onCancel={() => setMobileAppPromotionModalVisible(false)}
      />
    </Grid>
  );
};
