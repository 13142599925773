import {
  UseIssuePhysicalCardOutput,
  UseIssueSingleUseVirtualCardOutput,
  UseIssueVirtualCreditCardOutput,
  UseRequestPhysicalCardOutput,
  UseRequestSingleUseVirtualCardOutput,
  UseRequestVirtualCardOutput,
  useIssuePhysicalCard,
  useIssueSingleUseVirtualCard,
  useIssueVirtualCard,
  useRequestPhysicalCard,
  useRequestSingleUseVirtualCard,
  useRequestVirtualCard,
} from 'containers/credit-cards/CreditCardDrawer/Tabs/Request/hooks';
import { CardType } from 'generated-types/graphql.types';
import { useCreateCreditCardModalState } from '../CreateCreditCardModalDataProvider';

export type ActionToCreateCard = {
  issue:
    | UseIssuePhysicalCardOutput['issue']
    | UseIssueVirtualCreditCardOutput['issue']
    | UseIssueSingleUseVirtualCardOutput['issue'];
  request:
    | UseRequestVirtualCardOutput['request']
    | UseRequestPhysicalCardOutput['request']
    | UseRequestSingleUseVirtualCardOutput['request'];
  loading: boolean;
};

export const useCreateCreditCardAction = (
  onCardCreatedSuccess: (cardId: string) => void
) => {
  const { cardType } = useCreateCreditCardModalState();
  const { issuing, issue } = useIssuePhysicalCard({
    onCardCreatedSuccess,
  });

  const { requesting, request } = useRequestPhysicalCard({
    onCardCreatedSuccess,
  });

  const { issue: issueVirtual, issuing: issuingVirtual } = useIssueVirtualCard({
    onCardCreatedSuccess,
  });

  const { request: requestVirtual, requesting: requestingVirtual } =
    useRequestVirtualCard({ onCardCreatedSuccess });

  const { request: requestSingleUseCard, requesting: requestingSingleUseCard } =
    useRequestSingleUseVirtualCard({ onCardCreatedSuccess });

  const { issue: issueSingleUseCard, issuing: issuingSingleUseCard } =
    useIssueSingleUseVirtualCard({ onCardCreatedSuccess });

  const mapActionToCreateCard: Record<
    CardType.Physical | CardType.Virtual | CardType.SingleUse,
    ActionToCreateCard
  > = {
    VIRTUAL: {
      issue: issueVirtual,
      request: requestVirtual,
      loading: requestingVirtual || issuingVirtual,
    },
    PHYSICAL: {
      issue: issue,
      request: request,
      loading: requesting || issuing,
    },
    SINGLE_USE: {
      issue: issueSingleUseCard,
      request: requestSingleUseCard,
      loading: issuingSingleUseCard || requestingSingleUseCard,
    },
  };

  const action = mapActionToCreateCard[cardType];

  return action;
};
