import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

type UseMutateSearchParamsReturn = {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: URLSearchParams) => void;
  updateSearchParam: (name: string, value: string) => void;
};

/*
 * Thin wrapper around react-router's useSearchParams that provides an additional function to update a search param.
 */
export const useMutateSearchParams = (): UseMutateSearchParamsReturn => {
  const [searchParams, setSearchParams] = useSearchParams();

  /*
   * Update a search param in the URL.
   * If the value is empty, the param will be removed.
   *
   * @param name The name of the search param to update.
   * @param value The new value of the search param.
   * @returns void
   */
  const updateSearchParam = useCallback(
    (name: string, value: string) => {
      const updatedSearchParams = new URLSearchParams(searchParams);

      if (value !== '') {
        updatedSearchParams.set(name, value);
      } else {
        updatedSearchParams.delete(name);
      }

      setSearchParams(updatedSearchParams);
    },
    [searchParams, setSearchParams]
  );

  return { searchParams, setSearchParams, updateSearchParam };
};
