import { CellProps, Flex, TruncatedText, Text } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { useTranslation } from 'react-i18next';
import {
  ReimbursementTableRow,
  ReimbursementUser,
} from 'views/Reimbursement/toolkit/types';

export const MemberCell = ({
  value,
}: CellProps<ReimbursementTableRow, ReimbursementUser>) => {
  const [t] = useTranslation();
  if (!value) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  const { avatarUrl, firstName, lastName, membershipId } = value;

  if (!membershipId || (!firstName && !lastName)) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  const fullName = `${firstName ?? ''} ${lastName ?? ''}`;

  return (
    <Flex alignItems="center" gap="space8">
      <AvatarWithStatusContainer
        flex="none"
        name={fullName}
        img={avatarUrl}
        userId={membershipId}
        size="small"
        hasTooltip={false}
      />

      <TruncatedText wordBreak="break-word" color="gray800">
        {fullName}
      </TruncatedText>
    </Flex>
  );
};
