import { useTooltip, Grid, Icon, Tag, Tooltip } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TerminatedTag = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ delay: 500, passiveTrigger: true });

  return (
    <Grid
      {...triggerProps}
      ref={triggerRef}
      autoFlow="column"
      gap="space8"
      alignItems="center">
      <Icon icon="warning" size="space14" color="red500" />
      <Tag color="gray" variant="secondary">
        {t('recurringPaymentList.terminated')}
      </Tag>
      {isOpen && (
        <Tooltip width="space256" {...tooltipProps} ref={tooltipRef}>
          {t('recurringPaymentList.terminatedTooltip')}
        </Tooltip>
      )}
    </Grid>
  );
};
