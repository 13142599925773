import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { useTranslation } from 'react-i18next';
import { useApproversFieldOptions } from 'views/Settings/CostCenters/components/CostCenterForm/toolkit/hooks/useApproversFieldOptions';

export const ApproversField = ({ disabled }: { disabled: boolean }) => {
  const [t] = useTranslation();

  const { defaultItems } = useApproversFieldOptions();

  return (
    <HookFormUsersField
      defaultItems={defaultItems}
      readOnly={disabled}
      name="approvers"
      label={t('settings.costCenter.details.form.approvers.label')}
      placeholder={t('settings.costCenter.details.form.approvers.placeholder')}
    />
  );
};
