import { DocumentNode, useApolloClient } from '@apollo/client';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';

export const useBookingAccountRefetchQueries = () => {
  const client = useApolloClient();

  const refetchQueries: Array<{ query: DocumentNode } | string> = [
    {
      query: getFullOrganizationQuery,
    },
    'countBookingAccounts',
  ];

  const resetPaginationResults = () => {
    client.cache.evict({ fieldName: 'bookingAccountPagination' });
  };

  return { refetchQueries, resetPaginationResults };
};
