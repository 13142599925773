import { useCreditCardsAccountingInfoFieldItemsQuery } from 'generated-types/graphql.types';

export const useTaxCodesFieldOptions = () => {
  const { data } = useCreditCardsAccountingInfoFieldItemsQuery();

  const { taxCodes = [] } = data ?? {};

  const defaultItems = taxCodes.map(tax => ({
    key: tax.id,
    children: tax.readableName,
    textValue: tax.readableName,
  }));

  return { defaultItems };
};
