import {
  DocumentRelationshipRemovedEventDataFragment,
  RelatedDocumentActivityFragment,
} from 'generated-types/graphql.types';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { DocumentRelationshipActivityItem } from './DocumentRelationshipCreatedComponent';

const getRelatedDocument = (
  relatedDocument: DocumentRelationshipRemovedEventDataFragment['unlinkedDocument']
): relatedDocument is RelatedDocumentActivityFragment => {
  return relatedDocument?.__typename === 'RelatedDocument';
};

export const DocumentRelationshipRemovedComponent = ({
  unlinkedDocument,
}: DocumentRelationshipRemovedEventDataFragment) => {
  const [t] = useTranslation();
  const organizationId = useOrganizationId();

  if (!getRelatedDocument(unlinkedDocument)) return null;

  return (
    <DocumentRelationshipActivityItem
      key={unlinkedDocument.id}
      organizationId={organizationId ?? ''}
      relatedDocument={unlinkedDocument}
      additionalText={t('ecm:documentHistoryEvents.documentsUnlinked.unlinked')}
    />
  );
};
