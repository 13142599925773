// This is an example of creating client-side resolvers to mock a non-existing backend API that is work in progress,
// to unblock the frontend development.
export const resolvers = {
  Mutation: {
    getExampleUrl: (_root: any, _variables: {}) => {
      return {
        getExampleUrl: {
          __typename: 'GetExampleUrl',
          id: '1',
          url: 'https://www.example.com',
        },
      };
    },
  },
};
