import { useCostCenterArchivedCountForApproverLazyQuery } from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';

interface UseCostCenterArchivedCountProps {
  membershipId: string;
}

export const useCostCenterArchivedCount = ({
  membershipId,
}: UseCostCenterArchivedCountProps) => {
  const [
    fetchCostCenterArchivedCount,
    { data: costCenterArchivedData, loading: costCenterArchivedCountLoading },
  ] = useCostCenterArchivedCountForApproverLazyQuery({
    variables: { membershipId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
  });

  useFetchOnWindowFocus({ fetch: fetchCostCenterArchivedCount });

  const costCenterArchivedCount =
    costCenterArchivedData?.costCenterArchivedCountForApprover ?? 0;

  return {
    costCenterArchivedCount,
    costCenterArchivedCountLoading,
  };
};
