import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Grid } from '@candisio/design-system';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { pdfjs } from 'react-pdf';
import { PdfInvoiceSkeleton } from '../PdfInvoiceSkeleton';
import { PdfViewerProps } from '../PdfViewer';
import { PdfViewerError } from '../PdfViewerError';
import { Attachment } from './Attachment';
import { DocumentWrapper } from './DocumentWrapper';
import { PageWrapper } from './PageWrapper';
import { PdfHeader, PdfHeaderProps } from './PdfHeader';
import { Wrapper } from './styles';
import { useDocumentPages } from './useDocumentPages';
import {
  PDF_WRAPPER_ID,
  UseReactPdfControlsProps,
  useReactPdfControls,
} from './useReactPdfControls';
import { PdfStatus, getPageId, options } from './utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface ReactPdfProps
  extends PdfViewerProps,
    Pick<UseReactPdfControlsProps, 'smoothScroll' | 'initialScale'>,
    Pick<PdfHeaderProps, 'disabledControls'> {
  wrapper?: (props: {
    children: ReactNode;
    hasLoaded: boolean;
    documentId?: string;
  }) => JSX.Element;
}

export const ReactPdf = ({
  documentFile,
  mobileAppPromo, // TODO adjust templateRows in Grid below when mobileAppPromo is removed
  attachmentSection,
  attachments,
  onSelectDocument,
  selectedFile,
  smoothScroll = true,
  wrapper: OptionalWrapper,
  documentId,
  disabledControls,
  pageCss,
  initialScale,
}: ReactPdfProps) => {
  const [numPages, setNumPages] = useState<number>();
  const [status, setStatus] = useState<PdfStatus>('idle');

  const {
    currentPage,
    fitType,
    height,
    onFitPageHeight,
    onFitPageWidth,
    onRotateLeft,
    onZoomIn,
    onZoomOut,
    rotation,
    scale,
    setCurrentPage,
    width,
    wrapperRef,
    onGoToNextPage,
    onGoToPreviousPage,
    pdfViewerInstanceId,
    maxZoomInReached,
    maxZoomOutReached,
  } = useReactPdfControls({
    smoothScroll,
    initialScale,
  });

  const {
    getPageNumberOffset,
    handleChangePageVisibility,
    totalPages,
    onDocumentPagesLoaded,
  } = useDocumentPages({
    currentPage,
    documentFile,
    setCurrentPage,
    attachments,
    onSelectDocument,
    selectedFile,
    smoothScroll,
  });

  const [isPdfProtected, setIsPdfProtected] = useState(false);

  // called when main document is loaded
  const onDocumentLoadSuccess: NonNullable<
    ComponentProps<typeof DocumentWrapper>['onLoadSuccess']
  > = useCallback(
    async doc => {
      setNumPages(doc?.numPages ?? 0);
      if (documentFile?.id)
        onDocumentPagesLoaded(documentFile.id, doc.numPages);
      setStatus('loaded');
    },
    [documentFile.id, onDocumentPagesLoaded]
  );

  const onDownloadFiles = useCallback(() => {
    const fileToDownload = selectedFile ?? documentFile;

    window.open(fileToDownload.url, '_blank', 'noopener noreferrer');
  }, [documentFile, selectedFile]);

  const handleLoadProgress = useCallback(() => setStatus('loading'), []);

  const handleLoadError = useCallback(() => setStatus('error'), []);

  // called when PDF asks for a password
  // we treat document as loaded and set its page to exactly one (the page with password)
  const handleOnPassword = useCallback(() => {
    setStatus('loaded');
    setIsPdfProtected(true);
    if (documentFile?.id) onDocumentPagesLoaded(documentFile.id, 1);
  }, [documentFile.id, onDocumentPagesLoaded]);

  const docWrapperChildren = useMemo(() => {
    return (
      <Grid id={documentFile?.id} gap="space16">
        {Array.from(new Array(numPages), (_el, index) => (
          <PageWrapper
            key={getPageId(pdfViewerInstanceId, index + 1)}
            pageNumber={index + 1}
            width={width}
            fitType={fitType}
            height={height}
            scale={scale}
            rotation={rotation}
            handleChangePageVisibility={handleChangePageVisibility}
            pageNumberInTotal={index + 1}
            uniqueId={pdfViewerInstanceId}
          />
        ))}
      </Grid>
    );
  }, [
    documentFile?.id,
    fitType,
    handleChangePageVisibility,
    height,
    numPages,
    rotation,
    scale,
    pdfViewerInstanceId,
    width,
  ]);

  const wrapper = (
    <Wrapper
      ref={wrapperRef}
      data-cy="document-viewer-body"
      zIndex={
        0 /** Internal code of react-pdf modifies z-index, so we have to reset it here to 0 */
      }
      height="100%"
      pageCss={pageCss}>
      {(status === 'idle' || status === 'loading') && <PdfInvoiceSkeleton />}
      {status === 'error' && <PdfViewerError />}

      {status !== 'error' && (
        <Grid
          gap="space16"
          height="100%"
          visibility={status !== 'loaded' ? 'hidden' : 'visible'}>
          <DocumentWrapper
            fileUrl={documentFile?.url}
            file={documentFile?.url}
            onLoadProgress={handleLoadProgress}
            onLoadError={handleLoadError}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={null}
            error={null}
            externalLinkTarget="_blank"
            onPassword={handleOnPassword}
            changePageVisibility={handleChangePageVisibility}
            options={options}
            protectedPageId={getPageId(pdfViewerInstanceId, 1)}
            protectedPageNumber={1}>
            {docWrapperChildren}
          </DocumentWrapper>

          {status === 'loaded' &&
            attachments?.map(attachment => (
              <Attachment
                key={attachment.id}
                attachment={attachment}
                width={width}
                scale={scale}
                rotation={rotation}
                handleChangePageVisibility={handleChangePageVisibility}
                pageNumberOffset={getPageNumberOffset(attachment.id ?? '') ?? 0}
                fitType={fitType}
                height={height}
                onDocumentPagesLoaded={onDocumentPagesLoaded}
                uniqueId={pdfViewerInstanceId}
              />
            ))}
        </Grid>
      )}
    </Wrapper>
  );

  return (
    <Grid
      id={PDF_WRAPPER_ID}
      height="100%"
      // TODO: adjust this to min-content minmax(0, auto) when mobileAppPromo is removed
      templateRows={
        status === 'loaded' && !isPdfProtected && mobileAppPromo
          ? 'min-content min-content minmax(0, auto)'
          : 'min-content minmax(0, auto)'
      }
      gap="space8">
      <Grid
        color="gray500"
        columns={2}
        gap="space4"
        templateColumns="1fr auto"
        data-cy="document-viewer-details">
        <PdfHeader
          currentPage={currentPage}
          onDownloadFilesClick={onDownloadFiles}
          onFitPageHeightClick={onFitPageHeight}
          onFitPageWidthClick={onFitPageWidth}
          onHandleRotateLeftClick={onRotateLeft}
          onNextPageClick={onGoToNextPage}
          onPreviousPageClick={onGoToPreviousPage}
          onZoomInClick={onZoomIn}
          zoomInDisabled={maxZoomInReached}
          zoomOutDisabled={maxZoomOutReached}
          onZoomOutClick={onZoomOut}
          status={status}
          totalPages={totalPages}
          attachmentSection={attachmentSection}
          disabledControls={disabledControls}
        />
      </Grid>
      {status === 'loaded' && !isPdfProtected && mobileAppPromo}
      {OptionalWrapper ? (
        <OptionalWrapper
          hasLoaded={status === 'loaded'}
          documentId={documentId}>
          {wrapper}
        </OptionalWrapper>
      ) : (
        wrapper
      )}
    </Grid>
  );
};
