import { EcmDocumentStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface FilterOption {
  id: EcmDocumentStatus;
  label: string;
}

interface EcmDocumentStatusReturn {
  /** A map of the EcmDocumentStatus enum to the translated string */
  ecmDocumentStatusTranslationMap: Record<EcmDocumentStatus, string>;
  /** A list of filter options used in the table */
  ecmDocumentStatusFilterOptions: Array<FilterOption>;
}

/**
 * A custom hook to get the translation map and filter options for the ECM document status
 * based on the EcmDocumentStatus enum
 */
export const useEcmDocumentStatusOptions = (): EcmDocumentStatusReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const ecmDocumentStatusTranslationMap: Record<EcmDocumentStatus, string> = {
    [EcmDocumentStatus.Processing]: t('status.processing'),
    [EcmDocumentStatus.New]: t('status.new'),
    [EcmDocumentStatus.Open]: t('status.open'),
    [EcmDocumentStatus.Rejected]: t('status.rejected'),
    [EcmDocumentStatus.Approved]: t('status.approved'),
    [EcmDocumentStatus.Exported]: t('status.exported'),
    [EcmDocumentStatus.Exporting]: t('status.exporting'),
    [EcmDocumentStatus.Stored]: t('status.stored'),
    [EcmDocumentStatus.Archived]: t('status.archived'),
  };

  const ecmDocumentStatusFilterOptions = Object.entries(
    ecmDocumentStatusTranslationMap
  )
    .filter(
      ([id]) =>
        id !== EcmDocumentStatus.Exporting &&
        id !== EcmDocumentStatus.Processing
    )
    .map(([id, label]) => {
      return {
        id,
        label,
      } as FilterOption;
    });

  return {
    ecmDocumentStatusTranslationMap,
    ecmDocumentStatusFilterOptions,
  };
};
