import { Avatar, Box, Grid, TruncatedText } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { MouseEvent } from 'react';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { DocumentTags } from '../../toolkit/hooks/useDocumentTags';
import { MAX_DOCUMENT_TAG_NAME_LENGTH } from '../Drawer/Form/documentTagDetailsFormSchema';

export interface DocumentTagsListItemProps {
  tag: DocumentTags[number];
  queryString: string;
  isActive: boolean;
}

const preventDrawerOpen = (e: MouseEvent<HTMLAnchorElement>) => {
  e.stopPropagation();
};

export const DocumentTagsListItem = ({
  tag: { id, name, description, documentCount, createdBy },
  queryString,
  isActive,
}: DocumentTagsListItemProps) => {
  const organizationSlug = useOrganizationId();

  const archiveWithFilterUrl = `/${organizationSlug}${Routes.ECM_DOCUMENTS}?tags=${id}`;

  const hasAssignedDocuments =
    typeof documentCount === 'number' && documentCount > 0;

  return (
    <Grid
      autoFlow="column"
      lineHeight="20px"
      alignItems="center"
      gap="space32"
      {...(!isActive && {
        borderBottom: '1px solid gray200',
        paddingX: 'space16',
        height: '57px',
      })}
      templateColumns={`[name] ${MAX_DOCUMENT_TAG_NAME_LENGTH}ch [description] 5fr [createdBy] auto [count] .75fr`}>
      {/* @ts-expect-error for some reason TrucatedText complains about JSX.Element[] */}
      <TruncatedText fontWeight="semibold" fontSize="basic">
        {renderQueryHighlight({
          value: name ?? '',
          queryString: queryString,
        })}
      </TruncatedText>
      {/* @ts-expect-error for some reason TrucatedText complains about JSX.Element[] */}
      <TruncatedText color="gray500" fontSize="small" lineClamp={2}>
        {renderQueryHighlight({
          value: description ?? '',
          queryString: queryString,
        })}
      </TruncatedText>
      <Avatar img={createdBy?.avatarUrl ?? undefined} name={createdBy?.name} />
      {!hasAssignedDocuments && (
        <Box padding="space8 space12" color="gray500" justifySelf="end">
          0
        </Box>
      )}
      {hasAssignedDocuments && (
        <RouterLink
          to={archiveWithFilterUrl}
          onClick={preventDrawerOpen}
          justifySelf="end">
          <Box padding="space8 space12" hover={{ textDecoration: 'underline' }}>
            {documentCount}
          </Box>
        </RouterLink>
      )}
    </Grid>
  );
};
