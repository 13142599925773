import { mergeProps } from '@candisio/design-system';
import {
  HookFormAmountField,
  HookFormAmountFieldProps,
} from 'components/HookFormFields/HookFormAmountField';
import { useWatch } from 'react-hook-form';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

/**
 * Processing form monetary amount field
 *
 * Formats the amount using the current value of the currency field
 */
export const ProcessingFormAmountField = ({
  name,
  readOnly,
  ...restProps
}: HookFormAmountFieldProps<ProcessingFormValues>) => {
  const currency = useWatch<ProcessingFormValues, 'currency'>({
    name: 'currency',
  });

  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormAmountField
      name={name}
      currency={currency ?? undefined}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
