import { useApproveVirtualCardRequestMutation } from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { ModifiedLimitDetails } from '../../types';

interface UseApproveVirtualCardRequestParams {
  cardId: string;
}

export const useApproveVirtualCardRequest = ({
  cardId,
}: UseApproveVirtualCardRequestParams) => {
  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  const [approveVirtualCardRequest, { loading }] =
    useApproveVirtualCardRequestMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        ...refetchQueries.cardIssuerCards(cardId),
        ...refetchQueries.cardIssuerCardsCardManager(),
      ],
      onCompleted() {
        evictPaginationResults();
      },
    });

  return {
    approveVirtualCard: async (
      cardRequestId: string,
      updatedCardDetails?: ModifiedLimitDetails
    ) => {
      const { errors } = await approveVirtualCardRequest({
        variables: { input: { cardRequestId, ...updatedCardDetails } },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    approvingVirtualCard: loading,
  };
};
