import { EmptyExpenseDetailsCard } from './EmptyExpenseDetailsCard';
import { ExpenseDetailsCardWithAction } from './ExpenseDetailsCardWithAction';

interface ExpenseSectionWithActionProps {
  totalAmount: string;
  expenseCount: number;
  creatingExpense: boolean;
  onGeneralExpenseClick: () => void;
  onHospitalityExpenseClick: () => void;
}

export const ExpenseSectionWithAction = ({
  totalAmount,
  expenseCount,
  creatingExpense,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: ExpenseSectionWithActionProps) => {
  const hasExpenseDetails = expenseCount > 0;

  if (!hasExpenseDetails) {
    return (
      <EmptyExpenseDetailsCard
        creatingExpense={creatingExpense}
        onGeneralExpenseClick={onGeneralExpenseClick}
        onHospitalityExpenseClick={onHospitalityExpenseClick}
      />
    );
  }

  return (
    <ExpenseDetailsCardWithAction
      creatingExpense={creatingExpense}
      totalAmount={totalAmount}
      expenseCount={expenseCount}
      onGeneralExpenseClick={onGeneralExpenseClick}
      onHospitalityExpenseClick={onHospitalityExpenseClick}
    />
  );
};
