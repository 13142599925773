import { CardType } from 'generated-types/graphql.types';
import moment from 'moment';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CreditCardDetailsProps } from '../CreditCard/types';
import { FormStep } from './utils/utils';

export type FormStepWithFooter = Exclude<FormStep, 'Success'>;

export type CardDetails = CreditCardDetailsProps & {
  cardType: CardType;
  avatarUrl?: string;
  name?: string;
};

type TitleMap = Record<
  FormStep,
  | { steppperTitle: string; footerTitle: string; showTitle?: boolean }
  | undefined
>;
interface ModalStepTitleProps {
  formStep: FormStep;
  isCardManager?: boolean;
}

export const useModalStepTitles = ({
  formStep,
  isCardManager,
}: ModalStepTitleProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const titleMap: TitleMap = {
    CreateVendor: undefined,
    LinkCard: undefined,
    TeamMember: {
      showTitle: isCardManager,
      steppperTitle: t('createCard.teamMember'),
      footerTitle: t('createCard.cardAndFooter.teamMemberTitle'),
    },
    CardTypeAndLimits: {
      footerTitle: t('createCard.cardAndFooter.cardTypeAndLimitTitle'),
      steppperTitle: t('createCard.cardTypeAndLimit'),
    },
    CardUsage: {
      steppperTitle: t('createCard.cardUsage'),
      footerTitle: t('createCard.cardAndFooter.cardUsageTitle'),
    },
    AccountingInfo: {
      steppperTitle: t('createCard.accountingInfo'),
      footerTitle: t('createCard.cardAndFooter.accountingInfoTitle'),
    },
    Success: undefined,
  };

  const footerTitle = titleMap[formStep]?.footerTitle;

  //for stepper component
  const stepperTitles =
    Object.keys(titleMap)
      .filter(
        formStep =>
          titleMap[formStep as FormStep] !== undefined &&
          titleMap[formStep as FormStep]?.showTitle !== false
      ) // filter out steps that should not have a step title
      .map(formStep => titleMap[formStep as FormStep]?.steppperTitle) ?? []; // map to stepper titles array for stepper component

  const indexOfCurrentStep =
    stepperTitles.indexOf(titleMap[formStep]?.steppperTitle) ?? 0; // get index of current step in stepper titles array

  return {
    footerTitle,
    stepperTitles: stepperTitles as string[],
    indexOfCurrentStep,
    stepCount: indexOfCurrentStep + 1,
  };
};

export const formatValidityPeriod = (validity: number) => {
  return moment().add(validity, 'months').format('MM/YY');
};

export const getSlideInVariants = () => {
  const animateFromRight = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 0.05,
        ease: 'easeInOut',
      },
    },
    hidden: {
      opacity: 0,
      x: 50,
      transition: {
        duration: 1,
        delay: 0.3,
        ease: 'easeInOut',
      },
    },
  };

  const animateFromLeft = {
    ...animateFromRight,
    hidden: {
      x: -50,
    },
  };

  return { animateFromLeft, animateFromRight };
};
