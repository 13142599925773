import { useUserRoles } from 'hooks/useUserRoles';
import { Promotion } from '../types';
import { usePromotionContent } from './usePromotionContent';

export const usePromotionsWithEntitlements = () => {
  const { isAdmin } = useUserRoles();

  const { creditCardsPromotion, mobileAppPromotion, upsellPlusPromotion } =
    usePromotionContent();

  const getPromotions = (): Promotion[] => {
    return isAdmin
      ? [upsellPlusPromotion, creditCardsPromotion, mobileAppPromotion]
      : [mobileAppPromotion];
  };

  return { promotions: getPromotions() };
};
