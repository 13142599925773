import { Box, Flex } from '@candisio/design-system';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useDatev } from 'orgConfig/datev';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';
import { useCheckDatevSetup } from 'views/Settings/Integrations/DATEV/setup-checks';
import {
  useNeedsSapConfig,
  useNeedsSapCreditCardConfig,
} from 'views/Settings/Integrations/SAP/utils';
import { useOrganizationData } from 'views/Settings/Organization/hooks/useOrganizationData';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';
import settingsIcon from '../../images/settings-icon.svg';
import { MenuItemIcon } from '../../style';

export const Settings = () => {
  const [t] = useTranslation();

  const {
    hasReadClientsButNotSelected,
    hasProvisionsAndRequiresSetup,
    accountingCoreDataMissing,
    indicateDatevCreditCardSetupNeeded,
  } = useCheckDatevSetup();

  const { hasOrgTaxDetails } = useOrganizationData();

  const { showMissingIBANIndicator } = useOrganizationOwnIbansData();

  const { shouldUseSapConfig } = useSap();
  const indicateSapSetupNeeded = useNeedsSapConfig();
  const indicateSapCreditCardSetupNeeded = useNeedsSapCreditCardConfig();

  const { bdsBoughtButNotConnected } = useDatev();

  const isSapOrDatevConfigured = shouldUseSapConfig
    ? indicateSapCreditCardSetupNeeded
    : indicateDatevCreditCardSetupNeeded;

  const showMissingConfigurationIndicator =
    isSapOrDatevConfigured ||
    hasReadClientsButNotSelected ||
    hasProvisionsAndRequiresSetup ||
    indicateSapSetupNeeded ||
    accountingCoreDataMissing ||
    bdsBoughtButNotConnected ||
    !hasOrgTaxDetails ||
    showMissingIBANIndicator;

  return (
    <Flex direction="column" alignItems="end">
      {showMissingConfigurationIndicator && (
        <Box position="absolute" right={-12} top={-8}>
          <PulseIndicator status="default" source="main-settings-navigation" />
        </Box>
      )}
      <MenuItemIcon>
        <img src={settingsIcon} alt={t('navigation.settings')} />
      </MenuItemIcon>
    </Flex>
  );
};
