import gql from 'graphql-tag';
import { pageInfoDataFragment } from 'views/queries/fragments';

export const getGoodsReceiptQuery = gql`
  query getGoodsReceipts(
    $input: GoodsReceiptPaginationInput!
    $query: String
    $queryFields: [GoodsReceiptQueryField!]
    $filters: GoodsReceiptFilterInput
    $sortBy: GoodsReceiptSortInput
  ) {
    inboxGoodsReceipts(
      input: $input
      query: $query
      queryFields: $queryFields
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          accountsPayableNumber
          contactName
          connectedPurchaseOrders {
            purchaseOrderId
            purchaseOrderNumber
          }
          deliveryDate
          status
          receiptNumber
          organizationId
          goodsLineItems {
            quantity
            itemNumber
            description
          }
          connectedInvoices {
            invoiceId
            invoiceNumber
          }
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
`;

// TODO add goods receipts import history entries query once backend implements it

// TODO add type policy to merged-pagination once the query is implemented
