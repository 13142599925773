import { InboxReimbursements } from './InboxReimbursements';
import { useInboxReimbursementsData } from './toolkit/hooks/useInboxReimbursementsData';
import { useInboxReimbursementsFilterOptions } from './toolkit/hooks/useInboxReimbursementsFilterOptions';
import { useInboxReimbursementsTableConfigurations } from './toolkit/hooks/useInboxReimbursementsTableConfigurations';

export const InboxReimbursementsContainer = () => {
  const {
    filters,
    isLoadingReimbursements,
    handleDebounceSearch,
    reimbursements,
    reimbursementsCount,
    sortBy,
    isTableEmpty,
    isTableFiltered,
    onFilter,
    onLoadMore,
    onSort,
  } = useInboxReimbursementsData();

  const filterOptions = useInboxReimbursementsFilterOptions();

  const {
    availableReimbursementsColumnIds,
    configurationsTable,
    isResetPending,
    isSavingConfigurations,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useInboxReimbursementsTableConfigurations({ filters, sortBy });

  return (
    <InboxReimbursements
      reimbursementsCount={reimbursementsCount}
      reimbursements={reimbursements}
      filters={filters}
      sortBy={sortBy}
      isLoadingReimbursements={
        isLoadingReimbursements || isResetPending || isSavingConfigurations
      }
      onDebounceSearch={handleDebounceSearch}
      filterOptions={filterOptions}
      visibleColumnIds={availableReimbursementsColumnIds}
      isTableEmpty={isTableEmpty}
      isTableFiltered={isTableFiltered}
      isLoadingConfigurations={isSavingConfigurations}
      configurations={configurationsTable}
      onUpdateConfigurations={handleUpdateConfigurations}
      onFilter={onFilter}
      onSort={onSort}
      onLoadMore={onLoadMore}
      onResetTableConfigurations={handleResetTableConfigurations}
    />
  );
};
