import { mergeProps } from '@candisio/design-system';
import {
  HookFormPaginatedComboBoxField,
  HookFormPaginatedComboBoxFieldProps,
} from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { useReadOnlyTransactionFormField } from '../hooks/useReadOnlyTransactionFormField';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';

export const ReadOnlyTransactionFormPaginatedComboBoxField = ({
  name,
  ...restProps
}: HookFormPaginatedComboBoxFieldProps<ReadOnlyTransactionFormValues>) => {
  const { fieldProps } = useReadOnlyTransactionFormField(name);

  return (
    <HookFormPaginatedComboBoxField
      name={name}
      {...mergeProps(restProps, fieldProps)}
    />
  );
};
