import moment from 'moment';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';

export const useGetDateRange = (year: number) => {
  const timezone = useOrganizationTimezone();

  const firsDayOfTheYear = new Date(year, 0).toISOString();

  const lastDayOfTheYear = new Date(year, 11, 31).toISOString();

  // Following the pattern we use inside the table filter for sending dates to BE
  // we should never use this format to display dates inside the application UI

  const dateFrom = moment.tz(firsDayOfTheYear, timezone).format('DD.MM.YYYY');

  const dateTo = moment.tz(lastDayOfTheYear, timezone).format('DD.MM.YYYY');

  return { dateFrom, dateTo };
};
