import { Locale } from 'generated-types/graphql.types';
import moment from 'moment';
import { i18n } from 'providers/LocaleProvider';

export const languageToMomentLocale: Record<string, string> = {
  de: 'de',
  en: 'en-gb',
} as const;

export const updateAppLanguage = (locale: Locale) => {
  const formatted = locale.toLowerCase() as Lowercase<Locale>;
  moment.locale(languageToMomentLocale[formatted]);
  void i18n.changeLanguage(formatted);
};
