import { Grid, Text } from '@candisio/design-system';
import { New_DatevDocumentType } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { BookingAccountForm } from './BookingAccountForm/BookingAccountForm';

interface BookingAccountCellProps {
  documentType: New_DatevDocumentType;
}

export const BookingAccountCell = ({
  documentType,
}: BookingAccountCellProps) => {
  const [t] = useTranslation();

  if (documentType.category === 'other_documents') {
    return (
      <Grid>
        <BookingAccountForm documentType={documentType} />
      </Grid>
    );
  }

  return (
    <Text fontSize="small" color="gray500">
      {t('settings.datev.connect.documentTypes.bookingAccountNotAvailable')}
    </Text>
  );
};
