import gql from 'graphql-tag';

export const requestUploadAvatarUrlMutation = gql`
  mutation requestUploadAvatarUrl {
    requestUploadAvatarUrl {
      uploadUrl
      downloadUrl
      postOptions {
        field
        value
      }
    }
  }
`;

export const removeAvatarMutation = gql`
  mutation removeAvatar($membershipId: String!) {
    removeAvatar(membershipId: $membershipId)
  }
`;

export const updateAvatarMutation = gql`
  mutation updateAvatar($id: String!, $url: String!) {
    updateAvatar(id: $id, url: $url)
  }
`;

export const pendingChangeEmailRequestQuery = gql`
  query pendingChangeMembershipEmail($id: String!) {
    pendingChangeMembershipEmail(id: $id) {
      newEmail
    }
  }
`;

export const requestChangeEmailMutation = gql`
  mutation requestChangeUserEmail($input: RequestChangeUserEmailInput!) {
    requestChangeUserEmail(input: $input) {
      __typename
      ... on RequestChangeUserEmailSucceeded {
        email
      }
      ... on RequestChangeUserEmailConflict {
        email
      }
    }
  }
`;

export const cancelChangeEmailRequestMutation = gql`
  mutation cancelRequestChangeUserEmail($membershipId: String!) {
    cancelRequestChangeUserEmail(id: $membershipId)
  }
`;

export const resendChangeEmailVerificationMutation = gql`
  mutation resendChangeEmailRequestVerificationEmail($membershipId: String!) {
    resendChangeEmailRequestVerificationEmail(id: $membershipId)
  }
`;

export const pendingPhoneNumberVerification = gql`
  query pendingPhoneNumberVerification {
    pendingPhoneNumberVerification {
      id
      phoneNumber
    }
  }
`;

export const requestPhoneNumberVerification = gql`
  mutation requestPhoneNumberVerification($phoneNumber: String!) {
    requestPhoneNumberVerification(phoneNumber: $phoneNumber) {
      id
      phoneNumber
    }
  }
`;

export const cancelPendingPhoneNumberVerification = gql`
  mutation cancelPendingPhoneNumberVerification {
    cancelPendingPhoneNumberVerification
  }
`;

export const resendPendingPhoneNumberVerification = gql`
  mutation resendPendingPhoneNumberVerification {
    resendPendingPhoneNumberVerification
  }
`;

export const verifyPhoneNumber = gql`
  mutation verifyPhoneNumber($otpCode: String!) {
    verifyPhoneNumber(otpCode: $otpCode) {
      verified
      phoneNumber
    }
  }
`;

export const authenticatorDevice = gql`
  query authenticatorDevice {
    authenticatorDevice {
      id
      name
    }
  }
`;

export const addAuthenticatorDevice = gql`
  mutation addAuthenticatorDevice($name: String!) {
    addAuthenticatorDevice(name: $name) {
      id
      name
      secret
      secretQRDataUrl
    }
  }
`;

export const verifyAuthenticatorDevice = gql`
  mutation verifyAuthenticatorDevice(
    $deviceId: String!
    $token: String!
    $name: String
  ) {
    verifyAuthenticatorDevice(deviceId: $deviceId, token: $token, name: $name) {
      verified
    }
  }
`;
