import gql from 'graphql-tag';
import {
  pageInfoDataFragment,
  transactionAmountChangedEventFragment,
  transactionCommentEventFragment,
  transactionDetailsFragment,
  transactionMarkedAsExportedEventFragment,
  transactionMarkedAsInvoiceNeededEventFragment,
  transactionMarkedAsInvoiceNotNeededEventFragment,
  transactionReconciledEventFragment,
  transactionStatusUpdatedEventFragment,
} from 'views/queries/fragments';

export const getCardIssuerTransactionById = gql`
  query getCardIssuerTransactionById($id: ID!) {
    getCardIssuerTransactionById(id: $id) {
      ...TransactionDetailsData
    }
  }
  ${transactionDetailsFragment}
`;

export const unlinkTransactionFromDocument = gql`
  mutation unlinkTransactionFromDocument(
    $documentId: String!
    $transactionId: String!
  ) {
    unlinkTransactionFromDocument(
      documentId: $documentId
      transactionId: $transactionId
    ) {
      id
    }
  }
`;

export const deleteFile = gql`
  mutation deleteFile($fileId: String!) {
    deleteFile(fileId: $fileId)
  }
`;

export const getRefetchCardIssuerTransaction = (transactionId: string) => [
  {
    query: getCardIssuerTransactionById,
    variables: { id: transactionId },
  },
];

export const inboxTransactionAssociationsQuery = gql`
  query inboxTransactionAssociations($cursor: String) {
    prevTransaction: inboxCardIssuerTransactions(
      input: { limit: 1, before: $cursor }
    ) {
      edges {
        cursor
        node {
          ...TransactionDetailsData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
    nextTransaction: inboxCardIssuerTransactions(
      input: { limit: 1, after: $cursor }
    ) {
      edges {
        cursor
        node {
          ...TransactionDetailsData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${transactionDetailsFragment}
`;

export const archiveTransactionAssociationsQuery = gql`
  query archiveTransactionAssociations($cursor: String) {
    prevTransaction: archiveCardIssuerTransactions(
      input: { limit: 1, before: $cursor }
    ) {
      edges {
        cursor
        node {
          ...TransactionDetailsData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
    nextTransaction: archiveCardIssuerTransactions(
      input: { limit: 1, after: $cursor }
    ) {
      edges {
        cursor
        node {
          ...TransactionDetailsData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${transactionDetailsFragment}
`;

export const listDocumentsWithNoTransaction = gql`
  query listDocumentsWithNoTransaction(
    $limit: Int
    $after: String
    $filters: DocumentFilterInput
    $sort: SortInput
  ) {
    listInboxDocuments(
      limit: $limit
      after: $after
      filters: $filters
      sort: $sort
    )
      @connection(
        key: "listDocumentsWithNoTransaction"
        filter: ["filters", "sort"]
      ) {
      edges {
        cursor
        node {
          isDuplicate
          isOverdue
          documentFile {
            name
          }
          id
          status
          contact {
            value {
              id
              name {
                value
              }
            }
          }
          currency {
            value
          }
          extractedContact {
            cachingKey
            name {
              value
            }
          }
          createdAt
          hasAttachments
          amount {
            value
          }
          invoiceNumber {
            value
          }
          invoiceDate {
            value
          }
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
`;

export const createTransactionCommentMutation = gql`
  mutation createTransactionComment(
    $transactionId: String!
    $text: String!
    $mentionedMembershipIds: [String!]
  ) {
    createTransactionComment(
      transactionId: $transactionId
      text: $text
      mentionedMembershipIds: $mentionedMembershipIds
    ) {
      id
    }
  }
`;

export const getTransactionHistoryQuery = gql`
  query getTransactionHistory($id: ID!) {
    getCardIssuerTransactionById(id: $id) {
      id
      status
      timeline {
        __typename
        ...TransactionCommentEventData
        ...TransactionStatusUpdatedEventData
        ...TransactionAmountChangedEventData
        ...TransactionReconciledEventData
        ...TransactionMarkedAsInvoiceNeededEventData
        ...TransactionMarkedAsInvoiceNotNeededEventData
        ...TransactionMarkedAsExportedEventData
      }
    }
  }
  ${transactionStatusUpdatedEventFragment}
  ${transactionReconciledEventFragment}
  ${transactionAmountChangedEventFragment}
  ${transactionCommentEventFragment}
  ${transactionMarkedAsInvoiceNeededEventFragment}
  ${transactionMarkedAsInvoiceNotNeededEventFragment}
  ${transactionMarkedAsExportedEventFragment}
`;

export const getRefetchTransactionHistory = (transactionId: string) => [
  {
    query: getTransactionHistoryQuery,
    variables: { id: transactionId },
  },
];
