import { Tag } from 'generated-types/resolvers-types';
import { createUser } from './user';

export const createTag = (tag: Partial<Tag> = {}): Tag => ({
  __typename: 'Tag',
  id: 'tag-1',
  createdAt: '2022-07-012T00:00:00Z',
  createdBy: createUser({
    user: {
      id: 'user-1',
    },
  }),
  name: 'Tag 1',
  description: 'tag description',
  isActive: true,
  updatedAt: '2022-07-012T00:00:00Z',
  updatedBy: createUser({
    user: {
      id: 'user-1',
    },
  }),
  ...tag,
});
