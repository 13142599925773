import { Editor, EditorProvider } from 'react-simple-wysiwyg';
import './WysiwygEditor.css';

type WysiwygEditorProps = {
  /** The initial value of the editor. */
  value: string;
  /** A callback function that is called when the editor value changes. */
  onChange: (newValue: string) => void;
};

/**
 * This is a simple wrapper around `react-simple-wysiwyg` to provide a WYSIWYG editor mainly used for email templates.
 *
 * @param value The initial value of the editor.
 * @param onChange A callback function that is called when the editor value changes.
 */
export const WysiwygEditor = ({ onChange, value }: WysiwygEditorProps) => {
  return (
    <EditorProvider>
      <Editor
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        containerProps={{
          style: {
            height: '100%',
            fontSize: '0.875rem',
            padding: 0,
            margin: 0,
            border: 'none',
          },
        }}
      />
    </EditorProvider>
  );
};
