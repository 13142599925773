import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { usePermissionsForMembership } from 'hooks/usePermissionsForMembership';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

interface PermissionsForTeamMembers {
  canInviteMember: boolean;
  canDeactivateMember: boolean;
  canModifyPersonalInfo: boolean;
  canModifyMemberRoles: boolean;
  canModifyMemberAbsence: boolean;
}

export const usePermissionsForTeamMembers = (): PermissionsForTeamMembers => {
  const { isAccountant, isAdmin } = useUserRoles();
  const permission = usePermissionsForMembership();
  const permissionsManagementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.permissionsManagement
  );

  if (!permissionsManagementFF) {
    return {
      canInviteMember: isAdmin,
      canDeactivateMember: isAdmin,
      canModifyPersonalInfo: isAdmin,
      canModifyMemberRoles: isAdmin,
      canModifyMemberAbsence: isAdmin || isAccountant,
    };
  }

  return {
    canInviteMember: permission['organization.invite_member'] ?? false,
    canModifyMemberAbsence:
      permission['organization.modify_any_member_absence'] ?? false,
    canModifyMemberRoles: permission['organization.manage_any_role'] ?? false,
    // Permission in backend is not implemented yet
    canModifyPersonalInfo: isAdmin ?? false,
    canDeactivateMember: isAdmin ?? false,
  };
};
