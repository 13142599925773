import {
  Item,
  TabPanel,
  Tabs,
  useId,
  useTabListState,
} from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useOtherIntegration } from 'orgConfig/other';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';
import { CONTACT_TABS } from 'views/Contacts/consts';
import { ContactDrawerFooter } from 'views/Contacts/ContactDetails/ContactDrawerFooter';
import {
  ContactForm,
  ContactFormProps,
} from 'views/Contacts/ContactDetails/ContactForm/ContactForm';
import { ContactFormSkeleton } from 'views/Contacts/ContactDetails/ContactFormSkeleton';
import { ContactHistoryContainer } from 'views/Contacts/ContactDetails/ContactHistory/ContactHistoryContainer';
import { useSap } from '../../../../../orgConfig/sap';
import { useProcessingFormOverlayContext } from '../AddContact/ProcessingFormOverlay';

export interface EditContactProps extends ContactFormProps {
  contactId?: string;
  submitting: boolean;
  loading?: boolean;
  onClose?: () => void;
}

export const EditContact = ({
  checkContactName,
  checkAccountsPayableNumber,
  checkAccountsReceivableNumber,
  defaultValues,
  onCreatePaymentCondition,
  onSubmit,
  contactId,
  paymentConditionItems,
  suggestedAccountsPayableNumber,
  loading,
  onClose,
  submitting,
}: EditContactProps) => {
  const { animating, headingId } = useProcessingFormOverlayContext();

  const [provideContactAuditLogsToUserFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.provideContactAuditLogsToUser,
  ]);

  const [t] = useTranslation();
  const formId = useId();
  const { shouldUseSapContacts } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  const headingTranslationKey = {
    readOnly: 'settings.contacts.details.tabs.view',
    default: 'settings.contacts.details.tabs.edit',
  };

  const detailsTitle =
    shouldUseSapContacts || shouldUseCoreDataApi
      ? t(headingTranslationKey.readOnly)
      : t(headingTranslationKey.default);

  const tabsProps = {
    'aria-label': detailsTitle,
    items: [
      {
        key: CONTACT_TABS.details,
        title: detailsTitle,
        children:
          animating || loading ? (
            <ContactFormSkeleton />
          ) : (
            <ContactForm
              shouldUseSapContacts={shouldUseSapContacts}
              shouldUseCoreDataApi={shouldUseCoreDataApi}
              action="edit"
              checkContactName={checkContactName}
              checkAccountsPayableNumber={checkAccountsPayableNumber}
              checkAccountsReceivableNumber={checkAccountsReceivableNumber}
              defaultValues={defaultValues}
              id={formId}
              onCreatePaymentCondition={onCreatePaymentCondition}
              onSubmit={onSubmit}
              paymentConditionItems={paymentConditionItems}
              suggestedAccountsPayableNumber={suggestedAccountsPayableNumber}
            />
          ),
      },
      ...(provideContactAuditLogsToUserFF
        ? [
            {
              key: CONTACT_TABS.history,
              title: t('common:settings.contacts.details.tabs.history'),
              children: <ContactHistoryContainer contactId={contactId} />,
            },
          ]
        : []),
    ],
    defaultSelectedKey: CONTACT_TABS.details,
    children: (item: any) => (
      <Item key={item.key} title={item.title} textValue={item.title}>
        {item.children}
      </Item>
    ),
  };

  const tabsState = useTabListState(tabsProps);

  const handleClose = () => {
    tabsState.setSelectedKey(CONTACT_TABS.details);

    if (onClose) {
      onClose();
    }
  };

  const showSaveButton =
    !shouldUseSapContacts &&
    !shouldUseCoreDataApi &&
    tabsState.selectedItem?.key === CONTACT_TABS.details;

  return (
    <DrawerLayout
      compact
      hasTabs
      id="edit_contact_sidebar"
      onClose={handleClose}
      footer={
        <ContactDrawerFooter
          formId={formId}
          submitting={submitting}
          isSaveButtonVisible={showSaveButton}
        />
      }
      header={<Tabs key={headingId} {...tabsProps} state={tabsState} />}>
      <TabPanel key={tabsState.selectedItem?.key} state={tabsState} />
    </DrawerLayout>
  );
};
