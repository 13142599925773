import { Flex, Skeleton } from '@candisio/design-system';

export const LoadingInfoBoxCard = () => {
  return (
    <>
      <Skeleton height="space20" width="space20" borderRadius="100%" />
      <Flex direction="column" gap="space8">
        <Skeleton height="space10" width="space256" />
        <Skeleton height="space10" width="space80" />
        <Skeleton height="space10" width="space256" />
        <Skeleton height="space10" width="space128" />
      </Flex>
    </>
  );
};
