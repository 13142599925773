import { useGetProcessingAccountDataForCardManagerQuery } from 'generated-types/graphql.types';

export interface ProcessingAccountData {
  iban: string | undefined;
  bic: string | undefined;
  beneficiary: string | undefined;
  bankName: string | undefined;
}

export const useGetProcessingAccountDataCardManager = () => {
  const { data, loading } = useGetProcessingAccountDataForCardManagerQuery();

  const processingAccountData: ProcessingAccountData = {
    bankName:
      data?.organization?.creditCardsSettings?.processingAccount.bankName ??
      undefined,
    bic:
      data?.organization?.creditCardsSettings?.processingAccount.bic ??
      undefined,
    beneficiary:
      data?.organization?.creditCardsSettings?.processingAccount.beneficiary ??
      undefined,
    iban:
      data?.organization?.creditCardsSettings?.processingAccount.iban ??
      undefined,
  };

  return { loading, processingAccountData };
};
