import { Grid } from '@candisio/design-system';
import { useGetUserAbsence } from 'containers/absence/useGetUserAbsence';
import { useEffect, useMemo } from 'react';
import { EmptyMembershipInvolvement } from './EmptyMembershipInvolvement';
import { useCostCenterArchivedCount } from './hooks/useCostCenterArchivedCount';
import { useCostCenterCount } from './hooks/useCostCenterCount';
import { useCreditCardInvolvementCount } from './hooks/useCreditCardInvolvementCount';
import { useEcmDocumentsInvolvementCount } from './hooks/useEcmDocumentsInvolvement';
import { useGenerateUrls } from './hooks/useGenerateUrls';
import { usePendingDocumentsCount } from './hooks/usePendingDocumentsCount';
import { useTeamInvolvements } from './hooks/useTeamInvolvements';
import { useWorkflowTemplatesCount } from './hooks/useWorkflowTemplatesCount';
import { LoadingMembershipInvolvement } from './LoadingMembershipInvolvement';
import { MembershipInvolvement } from './MembershipInvolvement';

type MembershipInvolvementContainerProps = {
  selectedUserId: string;
  organizationSlug: string;
  setInvolvedCount: (value: number) => void;
  setPendingCount: (value: number) => void;
  errorMessage?: string;
};

export const MembershipInvolvementContainer = ({
  selectedUserId,
  organizationSlug,
  setInvolvedCount,
  setPendingCount,
  errorMessage,
}: MembershipInvolvementContainerProps) => {
  const { costCenterCount, costCenterCountLoading } = useCostCenterCount({
    membershipId: selectedUserId,
  });

  const { costCenterArchivedCount, costCenterArchivedCountLoading } =
    useCostCenterArchivedCount({
      membershipId: selectedUserId,
    });

  const { workflowTemplatesCount, workflowTemplatesCountLoading } =
    useWorkflowTemplatesCount({
      approverId: selectedUserId,
    });

  const { creditCardCount, cardholderId, creditCardsDataLoading } =
    useCreditCardInvolvementCount({ membershipId: selectedUserId });

  const { teams, teamsCount, isTeamsLoading } = useTeamInvolvements({
    adminMembershipIds: [selectedUserId],
  });

  const {
    notifyPersonCount,
    responsiblePersonCount,
    ecmDocumentsInvolvementLoading,
  } = useEcmDocumentsInvolvementCount({ membershipId: selectedUserId });

  const { getWhoUserIsSubstituteFor } = useGetUserAbsence();

  const usersSubstitutedByTeamMember = useMemo(
    () => getWhoUserIsSubstituteFor(selectedUserId),
    [selectedUserId, getWhoUserIsSubstituteFor]
  );

  const usersSubstitutedByTeamMemberCount =
    usersSubstitutedByTeamMember?.length ?? 0;

  const involvementCount =
    costCenterArchivedCount +
    costCenterCount +
    workflowTemplatesCount.all +
    creditCardCount +
    usersSubstitutedByTeamMemberCount +
    notifyPersonCount +
    responsiblePersonCount +
    teamsCount;

  useEffect(() => {
    setInvolvedCount(involvementCount);
  }, [
    costCenterArchivedCount,
    costCenterCount,
    involvementCount,
    setInvolvedCount,
    workflowTemplatesCount.all,
    creditCardCount,
    usersSubstitutedByTeamMemberCount,
    teamsCount,
  ]);

  const { pendingDocumentsLoading } = usePendingDocumentsCount({
    membershipId: selectedUserId,
    setPendingCount,
  });

  const { url } = useGenerateUrls({
    approverId: selectedUserId,
    cardholderId,
    organizationSlug,
    usersThatAreBeingSubstitutedByTeamMember: usersSubstitutedByTeamMember,
  });

  const isLoading =
    costCenterCountLoading ||
    costCenterArchivedCountLoading ||
    pendingDocumentsLoading ||
    workflowTemplatesCountLoading ||
    creditCardsDataLoading ||
    ecmDocumentsInvolvementLoading ||
    isTeamsLoading;

  if (isLoading) {
    return <LoadingMembershipInvolvement />;
  }

  if (involvementCount < 1) {
    return <EmptyMembershipInvolvement />;
  }

  const counter = {
    creditCardCount,
    costCenterCount,
    costCenterArchivedCount,
    notifyPersonCount,
    responsiblePersonCount,
    workflowTemplatesCount: workflowTemplatesCount.all,
    workflowTemplatesArchivedCount: workflowTemplatesCount.archived,
    teamsCount,
  };

  const additionalInfo = {
    teams,
  };

  return (
    <Grid background="gray100" borderRadius="medium" padding="space16">
      <MembershipInvolvement
        url={url}
        counter={counter}
        errorMessage={errorMessage}
        additionalInfo={additionalInfo}
      />
    </Grid>
  );
};
