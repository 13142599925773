import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useGetVendorForRecurringPaymentsById } from 'views/CreditCards/hooks/useGetVendorForRecurringPaymentsById';
import { useLinkCardForRecurringPaymentVendor } from 'views/CreditCards/hooks/useLinkCardForRecurringPaymentVendor';
import { useLinkExisitingCardToFormVendorValues } from 'views/CreditCards/hooks/useLinkExisitingCardToVendorFormValues';
import { useUnlinkCardFromVendor } from 'views/CreditCards/hooks/useUnlinkCardFromVendor';
import { useUpdateCardForRecurringPayment } from 'views/CreditCards/hooks/useUpdateCardForRecurringPayment';
import { LinkExisitingCardToVendorForm } from './LinkExisitingCardToVendorForm';
import { LinkExistingCardToVendorFormLoading } from './LinkExistingCardToVendorFormLoading';
import { LinkCardToVendorFormOutput } from './utils/linkCardToVendorFormSchema';

interface LinkExisitingCardToVendorFormContainerProps {
  onClose: () => void;
  onAddRecurringPayment: () => void;
}

export const LinkExisitingCardToVendorFormContainer = ({
  onAddRecurringPayment,
  onClose,
}: LinkExisitingCardToVendorFormContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();
  const { vendorId } = useParams<{ vendorId: string }>();

  const { link, loading } = useLinkCardForRecurringPaymentVendor();
  const { updateCard, loading: updateLoading } =
    useUpdateCardForRecurringPayment();

  const { vendorDetails, loading: loadingVendor } =
    useGetVendorForRecurringPaymentsById({ vendorId });

  const {
    defaultInputValue,
    defaultValues,
    formMode,
    loading: loadingFormValues,
  } = useLinkExisitingCardToFormVendorValues();

  const { unlinkCard, loading: loadingUnlink } = useUnlinkCardFromVendor();

  const handleLinkCardToVendor = async (
    formData: LinkCardToVendorFormOutput
  ) => {
    const action = formMode === 'firstLinkage' ? link : updateCard;
    const { status } = await action(formData);

    if (status === 'error') {
      error(t('recurringPaymentsModal.linkCard.errorMessage'));
    } else {
      success(t('recurringPaymentsModal.linkCard.successToastMessage'));

      onClose();
    }
  };

  const handleUnlinkCardFromVendor = async () => {
    const { status } = await unlinkCard();

    if (status === 'error') {
      error(t('recurringPaymentsModal.unlinkCard.errorMessage'));
    } else {
      success(t('recurringPaymentsModal.unlinkCard.successMessage'));

      onClose();
    }
  };

  if (loadingFormValues || loadingVendor) {
    return <LinkExistingCardToVendorFormLoading />;
  }

  const vendor = {
    name: vendorDetails.name,
    source: vendorDetails.source,
    logoUrl: vendorDetails.logoUrl,
  };

  return (
    <LinkExisitingCardToVendorForm
      loadingVendor={loadingVendor}
      formMode={formMode}
      vendor={vendor}
      defaultValues={defaultValues}
      defaultInputValue={defaultInputValue}
      isLoading={updateLoading || loading}
      isUnlinkLoading={loadingUnlink}
      onAddRecurringPayment={onAddRecurringPayment}
      onUnlink={handleUnlinkCardFromVendor}
      onSubmit={handleLinkCardToVendor}
      onClose={onClose}
    />
  );
};
