import { Grid, Skeleton, Separator, Card } from '@candisio/design-system';

export const UpdateCardLimitFormLoading = () => {
  return (
    <Grid alignContent="start" padding="space16" gap="space16">
      <Skeleton height="space36" width="space128" />
      <Card background="gray50">
        <Grid padding="space8">
          <Skeleton height="space36" width="space128" />
        </Grid>
        <Separator />
        <Grid gap="space18" padding="space8" rows={2} columns={2}>
          <Skeleton height="space36" width="space128" />
          <Skeleton height="space36" width="space128" />
          <Skeleton height="space36" width="space128" />
          <Skeleton height="space36" width="space128" />
        </Grid>
      </Card>
    </Grid>
  );
};
