import { FieldContainer, NumberInput } from '@candisio/design-system';
import { useSplitBookingsContext } from 'containers/SplitBookings/SplitBookingsContext';
import {
  DocumentCurrency,
  useCalculateDocumentGrossNetTaxRoundingAmountsQuery,
} from 'generated-types/graphql.types';

type RoundingAmountProps = {
  locale: string;
  label: string;
  formatOptions: Intl.NumberFormatOptions;
};
/**
 * RoundingAmount for SplitBookings Header
 */
export const RoundingAmount = ({
  locale,
  formatOptions,
  label,
}: RoundingAmountProps) => {
  const { bookings, currency, grossAmount, setRoundingAmount, roundingAmount } =
    useSplitBookingsContext();

  useCalculateDocumentGrossNetTaxRoundingAmountsQuery({
    variables: {
      input: {
        currency: currency as DocumentCurrency,
        grossAmount: grossAmount ?? 0,
        splitBookingsAmounts: bookings.map(booking => ({
          netAmount: booking.netAmount ?? 0,
          taxAmount: booking.taxAmount ?? 0,
        })),
      },
    },
    onCompleted: data => {
      if (
        data.calculateDocumentGrossNetTaxRoundingAmounts.__typename ===
        'CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult'
      ) {
        setRoundingAmount(
          data.calculateDocumentGrossNetTaxRoundingAmounts?.rounding ?? 0
        );
      }
    },
  });

  return (
    <FieldContainer
      label={label}
      readOnly
      wrapperBoxProps={{ paddingTop: '0' }}>
      <NumberInput
        readOnly
        label={label}
        formatOptions={formatOptions}
        value={roundingAmount ?? 0}
        locale={locale}
        variant="default"
      />
    </FieldContainer>
  );
};
