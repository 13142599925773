import { useApolloClient } from '@apollo/client';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { getOrgMembersAbsenceQuery } from 'containers/absence/gql';
import {
  Absence,
  Maybe,
  Query,
  SetAbsenceForTeamMemberInput,
  useGetMemberAbsenceQuery,
  useSetAbsenceForMemberMutation,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useTranslation } from 'react-i18next';
import {
  AbsenceFormOutput,
  AbsenceFormValues,
} from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/absenceFormSchema';
import {
  getCurrentUserAbsenceQuery,
  getMemberAbsenceQuery,
} from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/gql';
import { useGetSetAbsenceInput } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/useGetSetAbsenceInput';

export const useTeamMemberAbsenceUpdate = (teamMemberId: string) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const client = useApolloClient();
  const currentUser = useCurrentUser();
  const { dateTimeStringToDateString } = useDateConverter();

  const { data: memberAbsenceData, loading: memberAbsenceDataLoading } =
    useGetMemberAbsenceQuery({
      variables: {
        id: teamMemberId,
      },
    });

  const memberAbsence = memberAbsenceData?.getMemberAbsence;

  const { getSetAbsenceInput } = useGetSetAbsenceInput();
  const [setAbsence] = useSetAbsenceForMemberMutation({
    refetchQueries: result => {
      const queriesToRefetch = [
        getOrgMembersAbsenceQuery,
        getMemberAbsenceQuery,
      ];

      if (
        result.data?.setAbsenceForMember.__typename === 'Absence' &&
        result.data?.setAbsenceForMember.membershipId === currentUser?.id
      ) {
        queriesToRefetch.push(getCurrentUserAbsenceQuery);
      }

      return queriesToRefetch;
    },
    onCompleted: async data => {
      if (data.setAbsenceForMember.__typename !== 'Absence') return;

      // Documents may be affected by substitute being assigned to them. To be safe, we force refetch of below queries.
      (
        [
          'getDocument',
          'archivedDocuments',
          'listApprovalsDocuments',
        ] as (keyof Query)[]
      ).forEach(fieldName => {
        client.cache.evict({ fieldName });
      });
    },
  });

  const handleSubmit = async (
    formOutput: AbsenceFormOutput
  ): Promise<Maybe<Absence>> => {
    const input: SetAbsenceForTeamMemberInput = {
      ...getSetAbsenceInput(formOutput),
      id: teamMemberId,
      userMembershipId: teamMemberId,
    };

    const result = await setAbsence({
      variables: {
        input,
      },
    });

    if (result.data?.setAbsenceForMember.__typename === 'Absence') {
      if (!Boolean(input.fromDate) || !Boolean(input.toDate)) {
        success(t('header.profile.tabs.update.absence.form.reset'));

        return result.data?.setAbsenceForMember;
      }

      success(t('header.profile.tabs.update.absence.form.success'));

      return result.data?.setAbsenceForMember;
    }

    if (result.data?.setAbsenceForMember.__typename === 'SetAbsenceError') {
      error(t('header.profile.tabs.update.absence.form.error'));

      return;
    }
  };

  const defaultFromDate = memberAbsence?.fromDate
    ? dateTimeStringToDateString(memberAbsence.fromDate)
    : null;

  const defaultToDate = memberAbsence?.toDate
    ? dateTimeStringToDateString(memberAbsence.toDate)
    : null;

  const defaultValues: AbsenceFormValues = {
    fromDate: defaultFromDate,
    toDate: defaultToDate,
    note: memberAbsence?.note ?? null,
    substitute: memberAbsence?.substituteMembershipId ?? null,
  };

  return {
    handleSubmit,
    defaultValues,
    loading: memberAbsenceDataLoading,
  };
};
