import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { getDaysAgo } from '../getDaysAgo';

export const useGetContactImportErrorDetailsTranslation = (
  importedAt: string | undefined,
  userWhoDidLastImport: string | undefined
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);

  const numberOfDaysAgo = getDaysAgo(String(importedAt));

  let whenTranslation = '';

  switch (numberOfDaysAgo) {
    case 0:
      whenTranslation = t('importHistory.errorPopup.importDetails.today');
      break;
    case 1:
      whenTranslation = t('importHistory.errorPopup.importDetails.yesterday');
      break;
    default:
      whenTranslation = t(
        'importHistory.errorPopup.importDetails.when_plural',
        {
          numberOfDays: numberOfDaysAgo,
        }
      );
  }

  const whoTranslation = t('importHistory.errorPopup.importDetails.by', {
    name: userWhoDidLastImport,
  });

  const importAmountTranslation = t(
    'importHistory.errorPopup.importDetails.imported'
  );

  const detailsTranslation = `${importAmountTranslation} ${whenTranslation} ${whoTranslation}`;

  return { detailsTranslation };
};
