import { Box, Button, Card, Grid } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CardStatus,
  CardType,
  UpdateCardPurposeInput,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { CardLimitFormHeader } from '../CardLimitFormHeader';
import { updateSingleUseCardLimitFormErrorMessages } from './updateSingleUseCardLimitFormErrors';
import {
  UpdateSingleUseCardLimitFormValues,
  updateSingleUseCardLimitFormSchema,
} from './updateSingleUseCardLimitFormSchema';

interface UpdateSingleUseCardLimitFormProps {
  isSubmitting: boolean;
  hasPendingLimitRequest: boolean;
  cardType?: CardType;
  cardStatus: CardStatus;
  updatingPurpose: boolean;
  cardId: string;
  defaultValues: UpdateSingleUseCardLimitFormValues;
  isCardNotUsableAnymore: boolean;
  onClose: () => void;
  updatePurpose: ({ cardId, purpose }: UpdateCardPurposeInput) => Promise<{
    status: 'error' | 'success';
    errors: readonly GraphQLError[] | undefined;
  }>;
  onSubmit: (values: UpdateSingleUseCardLimitFormValues) => void;
}

export const UpdateSingleUseCardLimitForm = ({
  isSubmitting,
  hasPendingLimitRequest,
  cardType,
  cardStatus,
  cardId,
  defaultValues,
  updatingPurpose,
  isCardNotUsableAnymore,
  updatePurpose,
  onClose,
  onSubmit,
}: UpdateSingleUseCardLimitFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success } = useToastMessage();
  const { isCardManager } = useUserRoles();

  const form = useForm<UpdateSingleUseCardLimitFormValues>({
    mode: 'onTouched',
    defaultValues,
    values: defaultValues,
    context: { isCardManager },
    resolver: zodResolver({
      zodSchema: updateSingleUseCardLimitFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
      errorMessages: updateSingleUseCardLimitFormErrorMessages,
    }),
  });

  const canUserEditFieldValues =
    [
      CardStatus.Active,
      CardStatus.Locked,
      CardStatus.Pending,
      CardStatus.LockedPin,
    ].includes(cardStatus) ||
    (cardStatus === CardStatus.Requested && isCardManager);

  const isReadOnly =
    !canUserEditFieldValues ||
    (hasPendingLimitRequest && !isCardManager) ||
    isCardNotUsableAnymore;

  const dirtyFields = Object.keys(form.formState.dirtyFields);

  const purposeFieldValue = form.getValues().purpose;

  const updatePurposeOnly = async () => {
    // We want to invoke the mutation for updating purpose independently if all the other fields are not dirty
    // but there was no way to prevent the validation onSubmit thus we check only if the label is dirty and we clean up the errors
    if (
      dirtyFields.length === 1 &&
      dirtyFields[0] === 'purpose' &&
      purposeFieldValue
    ) {
      form.clearErrors();
      await updatePurpose({
        cardId,
        purpose: form.getValues().purpose,
      });
      success(t('creditCardFormDataPreview.successMsg'));
    }
  };

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        height="100%"
        paddingY="space16"
        paddingX="space24"
        overflow="hidden"
        onSubmit={form.handleSubmit(
          // The first callback gets invoked only when validation is successfull
          // The second callback gets invoked when there are some form errors
          data => onSubmit(data),
          updatePurposeOnly
        )}
        templateRows="auto 1fr auto">
        <CardLimitFormHeader
          cardType={cardType}
          onClick={onClose}
          hasPendingLimitRequest={hasPendingLimitRequest}
          showInfo={hasPendingLimitRequest || !isReadOnly}
        />
        <Box overflowY="auto">
          <Card background="gray100" borderTopRadius="none">
            <Grid gap="space16">
              <Grid gap="space16" templateColumns="auto auto">
                <HookFormTextField
                  name="purpose"
                  maxLength={50}
                  readOnly={isReadOnly}
                  label={t('ccRequestForm.fields.purpose.label')}
                  placeholder={t('ccRequestForm.fields.purpose.placeholder')}
                />
                <HookFormAmountField
                  name="transactionLimit"
                  minValue={0}
                  currency="EUR"
                  readOnly={isReadOnly}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                  label={t('dashboard.drawer.form.oneTimePaymentTxLimit.label')}
                  placeholder={t(
                    'dashboard.drawer.form.transactionLimit.placeHolder'
                  )}
                />
              </Grid>
              {!isCardManager && (
                <HookFormTextField
                  name="comment"
                  label={t('dashboard.drawer.form.comment.label')}
                  readOnly={isReadOnly}
                  placeholder={t('dashboard.drawer.form.comment.placeHolder')}
                  maxLength={200}
                  optionalHint={t('dashboard.drawer.form.comment.hint')}
                />
              )}
            </Grid>
          </Card>
        </Box>
        {!isReadOnly && (
          <Box paddingTop="space16">
            <Button
              type="submit"
              justifySelf="start"
              disabled={isSubmitting || updatingPurpose}
              loading={isSubmitting || updatingPurpose}>
              {t('creditCardFormDataPreview.save')}
            </Button>
          </Box>
        )}
      </Grid>
    </FormProvider>
  );
};
