import { Button, Grid, Text } from '@candisio/design-system';
import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { UserFieldItem } from 'hooks/useUsersFieldOptions';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Member } from '../../toolkit/hooks/useGetTeams';

interface TeamMembersProps {
  teamMembers: UserFieldItem[];
  isLoading: boolean;
  isSubmitting: boolean;
  onClick: () => void;
  onGoBack: () => void;
  onSetMembers: (members: Member[]) => void;
}

export const TeamMembers = ({
  teamMembers,
  isLoading,
  isSubmitting,
  onClick,
  onGoBack,
  onSetMembers,
}: TeamMembersProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const bulletList: string[] = t('teams.createTeam.teamMembers.bulletPoints', {
    returnObjects: true,
  });

  const handleChange = (val: string[]) => {
    const selectedItemSet = new Set(val);
    const members = teamMembers.reduce<Member[]>((acc, member) => {
      if (selectedItemSet.has(member.key)) {
        acc.push({
          id: member.key,
          fullName: member.children,
          avatarUrl: member.avatarUrl,
        });
      }

      return acc;
    }, []);

    onSetMembers(members);
  };

  return (
    <Grid gap="space24" height="100%" templateRows="1fr auto">
      <Grid gap="space24" height="fit-content">
        <Text fontSize="xxlarge">
          {t('teams.createTeam.teamMembers.heading')}
        </Text>
        <Grid gap="space8" width="50%">
          <HookFormUsersField
            name="teamMembers"
            isLoading={isLoading}
            onChange={handleChange}
            defaultItems={teamMembers}
            label={t('teams.createTeam.teamMembers.field.label')}
            placeholder={t('teams.createTeam.teamMembers.field.placeholder')}
            emptyListPlaceholder={t(
              'teams.createTeam.teamMembers.field.noUserFound'
            )}
          />
          <Grid gap="space8">
            <Text color="gray600" fontSize="basic">
              {t('teams.createTeam.teamMembers.info')}
            </Text>
            <Grid as="ul" padding={0} listStyle="none" gap="space4">
              {bulletList.map((item, index) => (
                <li key={index}>
                  <Text color="gray600">{item}</Text>
                </li>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid autoFlow="column" placeContent="space-between">
        <Button onClick={onGoBack} variant="secondary" disabled={isSubmitting}>
          {t('teams.createTeam.teamMembers.backCta')}
        </Button>
        <Button
          onClick={onClick}
          loading={isSubmitting}
          disabled={isSubmitting}>
          {t('teams.createTeam.teamMembers.cta')}
        </Button>
      </Grid>
    </Grid>
  );
};
