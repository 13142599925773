import { Description } from '@candisio/design-system';
import { isValidBIC } from 'ibantools';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProcessingFormFieldOptions } from '../ProcessingForm';
import { ProcessingFormValues } from '../processingFormSchema';

export const useSwiftCodeWarning = (
  fieldOptions: ProcessingFormFieldOptions | undefined
): Description | undefined => {
  const [t] = useTranslation();

  const [processingFormSwiftCode, contact] = useWatch<
    ProcessingFormValues,
    ['swiftCode', 'contact']
  >({
    name: ['swiftCode', 'contact'],
  });

  const contactFormSwiftCode = contact.value
    ? fieldOptions?.contact?.items?.find(item => item.key === contact.value)
        ?.swiftCode
    : undefined;

  if (!contactFormSwiftCode || !processingFormSwiftCode) {
    return undefined;
  }

  if (!isValidBIC(processingFormSwiftCode ?? '')) {
    return undefined;
  }

  const isSameSwiftCode =
    contactFormSwiftCode?.localeCompare(processingFormSwiftCode ?? '') === 0;

  if (isSameSwiftCode) {
    return undefined;
  }

  return {
    text: t('document.requestApproval.inputs.swiftCode.warningLabel'),
    color: 'warning',
  };
};
