import { useOtherIntegration } from 'orgConfig/other';
import { InitNewExportWithCandisAPI } from 'views/Integrations/Export/elements/sidebar/Init/InitNewExportWithCandisAPI';
import { InitNewExportWithoutCandisAPI } from 'views/Integrations/Export/elements/sidebar/Init/InitNewExportWithoutCandisAPI';

export const InitNewExport = () => {
  const { shouldUseCandisApi } = useOtherIntegration();

  return shouldUseCandisApi ? (
    // TODO: rename to InitNewExport when Candis API is archived
    <InitNewExportWithCandisAPI />
  ) : (
    // TODO: remove this when Candis API is archived
    <InitNewExportWithoutCandisAPI />
  );
};
