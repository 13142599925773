import { getTranslationContext } from 'orgConfig';
import { ErrorMessages } from 'utils/zodFormValidation';
import { storageFormSchema } from './storageFormSchema';

export const storageFormErrors: ErrorMessages<
  typeof storageFormSchema,
  'contact.inputValue' | 'costCenter.inputValue' | 'isSensitive'
> = {
  amount: { label: 'storageForm.fields.amount.label' },
  currency: { label: 'storageForm.fields.currency.label' },
  'contact.value': { label: 'storageForm.fields.contact.label' },
  documentSubCategory: { label: 'storageForm.fields.contractType.label' },
  'costCenter.value': {
    label:
      getTranslationContext() === 'sap'
        ? 'common:document.requestApproval.inputs.costCenter.label_sap'
        : 'common:document.requestApproval.inputs.costCenter.label',
  },
  documentDate: { label: 'storageForm.fields.documentDate.label' },
  documentName: { label: 'storageForm.fields.documentName.label' },
  documentNumber: { label: 'storageForm.fields.documentNumber.label' },
  documentType: { label: 'storageForm.fields.documentType.label' },
  endDate: { label: 'storageForm.fields.endDate.label' },
  notes: { label: 'storageForm.fields.notes.label' },
  notifyPerson: { label: 'storageForm.fields.notifyPerson.label' },
  relativeDate: { label: 'storageForm.fields.relativeDate.label' },
  startDate: { label: 'storageForm.fields.startDate.label' },
  terminationDate: { label: 'storageForm.fields.terminationDate.label' },
  terminationReminderDate: {
    label: 'storageForm.fields.terminationReminderDate.label',
  },
  responsiblePerson: { label: 'storageForm.fields.responsiblePerson.label' },
};
