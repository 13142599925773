import { Grid, Link, Text } from '@candisio/design-system';
import { TransactionMarkedAsExportedEventDataFragment } from 'generated-types/graphql.types';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useDefineExportView } from 'views/Integrations/Export/Manifest';
import { Entity, Sidebar, View } from 'views/Integrations/Export/types';

export const TransactionExported = ({
  properties,
}: Pick<TransactionMarkedAsExportedEventDataFragment, 'properties'>) => {
  const [t] = useTranslation(['common', 'transactions']);

  const orgId = useOrganizationId();

  const viewDef = useDefineExportView({
    exportId: properties.exportId,
    sidebar: Sidebar.HISTORY,
    view: View.HISTORY,
    entity: Entity.TRANSACTION,
  });

  const exportLink = `/${orgId}${viewDef.url}`;

  return (
    <Grid gap="space4">
      <Text>{t('transactions:documentHistoryCCEvents.txExportTagline')}</Text>
      <Link href={exportLink}>
        {t(
          'common:document.compact.history.documentExported.openExportHistory'
        )}
      </Link>
    </Grid>
  );
};
