export enum CarouselStates {
  ACTIVATE = 'ACTIVATE',
  DEFAULT = 'DEFAULT',
  EXPIRED = 'EXPIRED',
  LOCKED = 'LOCKED',
  LOCKEDPIN = 'LOCKED_PIN',
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
}

export type SensitiveDataProps = {
  onShowCardNumbers: (cardId: string) => void;
  resetTokens: () => void;
  sensitiveDataIsShown?: boolean;
  isFetchingSensitiveData?: boolean;
};
