import { EcmDocumentType } from 'generated-types/graphql.types';
import { useFetchDocNavigationData } from 'hooks/useFetchDocNavigationData/useFetchDocNavigationData';
import { AppRouteParams, DocumentProcessingRouteParams, Routes } from 'models';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import {
  generatePath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { RouteParams } from '../Context';
import { usePrefetchPdfData } from '../DocumentProcessing/util/usePrefetchPdfData';
import { usePrefetchQueries } from '../DocumentProcessing/util/usePrefetchQueries';

enum SearchParams {
  cursor = 'cursor',
  isArchived = 'isArchived',
}

const DOCUMENT_VIEW_PATH =
  `/:${AppRouteParams.organizationSlug}${Routes.INBOX}/:${DocumentProcessingRouteParams.documentId}` as const;

const DOCUMENT_ARCHIVE_PATH =
  `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:${DocumentProcessingRouteParams.documentId}` as const;

export const useDocumentNavigation = () => {
  const [t] = useTranslation();

  const {
    documentId,
    organizationSlug,
    type: routeType = Routes.ARCHIVE,
  } = useParams<RouteParams>();

  const LIST_VIEW_PATH =
    `/:${AppRouteParams.organizationSlug}${routeType}` as const;

  const originListViewPathname = generatePath(LIST_VIEW_PATH, {
    organizationSlug,
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentDocumentCursor = searchParams.get(SearchParams.cursor);
  const isArchived = !!searchParams.get(SearchParams.isArchived);

  const [editRequestedDocumentMode, setEditRequestedDocumentMode] =
    useState(isArchived);

  let route: Routes = routeType;
  if (editRequestedDocumentMode && isArchived) route = Routes.ARCHIVE;
  if (editRequestedDocumentMode && !isArchived) route = Routes.APPROVALS;

  const {
    navigationLoading: isNavigationLoading,
    documentListCount,
    nextDocumentId,
    nextDocumentLink,
    prevDocumentId,
    prevDocumentLink,
    linkBackToList,
  } = useFetchDocNavigationData({
    cursor: currentDocumentCursor,
    organizationSlug,
    route,
  });

  usePrefetchQueries({ nextDocumentId, prevDocumentId });
  usePrefetchPdfData({ nextDocumentId, prevDocumentId });

  const handleBackToList = useCallback(() => {
    navigate(linkBackToList);
  }, [navigate, linkBackToList]);

  const gotoDocumentListView = useCallback(() => {
    navigate({
      pathname: originListViewPathname,
      search: searchParams.toString(),
    });
  }, [navigate, originListViewPathname, searchParams]);

  const gotoNextDocument = useMemo(() => {
    if (!nextDocumentLink) return undefined;

    return () => navigate(nextDocumentLink);
  }, [navigate, nextDocumentLink]);

  const gotoPrevDocument = useMemo(() => {
    if (!prevDocumentLink) return undefined;

    return () => navigate(prevDocumentLink);
  }, [navigate, prevDocumentLink]);

  const cycleDocument = useCallback(() => {
    if (gotoNextDocument) {
      gotoNextDocument();

      return;
    }

    if (gotoPrevDocument) {
      gotoPrevDocument();

      return;
    }

    gotoDocumentListView();
  }, [gotoNextDocument, gotoPrevDocument, gotoDocumentListView]);

  const enableEditMode = useCallback(() => {
    const pathname = generatePath(DOCUMENT_VIEW_PATH, {
      organizationSlug,
      documentId,
    });

    navigate({ pathname, search: searchParams.toString() });

    setEditRequestedDocumentMode(true);
  }, [documentId, navigate, organizationSlug, searchParams]);

  const disableEditMode = useCallback(() => {
    setEditRequestedDocumentMode(false);
  }, []);

  const listNavigatorTitle: string = isNavigationLoading
    ? t('loading')
    : t('document.details.listNavigatorTitle', { count: documentListCount });

  const getOpenDocumentLink = useCallback(
    (documentId: string, type: EcmDocumentType) => {
      // We need to set `isInvoice` search param to properly handle
      // invoice view in "All documents" view ("/stored_documents" route)
      const searchParam =
        type === EcmDocumentType.Invoice ? '?isInvoice=true' : '';

      const path = generatePath(DOCUMENT_ARCHIVE_PATH, {
        documentId,
        organizationSlug,
      });

      return path + searchParam;
    },
    [organizationSlug]
  );

  const result = useMemo(
    () => ({
      isNavigationLoading,
      listNavigatorTitle,
      editRequestedDocumentMode,
      gotoDocumentListView,
      gotoNextDocument,
      gotoPrevDocument,
      cycleDocument,
      handleBackToList,
      enableEditMode,
      disableEditMode,
      getOpenDocumentLink,
      navigate,
    }),
    [
      isNavigationLoading,
      listNavigatorTitle,
      editRequestedDocumentMode,
      gotoDocumentListView,
      gotoNextDocument,
      gotoPrevDocument,
      cycleDocument,
      handleBackToList,
      enableEditMode,
      disableEditMode,
      getOpenDocumentLink,
      navigate,
    ]
  );

  return result;
};
