import { Tag } from '@candisio/design-system';
import { CardStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { mappedStatusesToColor } from 'views/CreditCards/utils/utils';

export const StatusCell = ({ value }: { value: CardStatus }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Tag variant="secondary" color={mappedStatusesToColor[value].color}>
      {t(mappedStatusesToColor[value].translation)}
    </Tag>
  );
};
