import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import { CostCenterTypes } from 'generated-types/graphql.types';
import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
import { useShowCostCenterField } from 'views/utils/useShouldShowField';
import { ReimbursementFormFieldOptions } from './types';

export const useReimbursementFormFieldOptions =
  (): ReimbursementFormFieldOptions => {
    const showCostCenterField = useShowCostCenterField();
    const costCenter = useCostCenterFieldOptions({
      skip: !showCostCenterField,
      type: CostCenterTypes.CostCenter,
    });

    const { loading: isLoadingTargetMembers, users: targetMemberOptions } =
      useUsersFieldOptions({
        status: 'ActiveAndPending',
      });

    const fieldOptions: ReimbursementFormFieldOptions = {
      suggestedCostCenter: {
        isVisible: showCostCenterField,
        options: costCenter?.props,
      },
      targetMembership: {
        isVisible: true,
        options: {
          defaultItems: targetMemberOptions,
          isLoading: isLoadingTargetMembers,
        },
      },
    };

    return fieldOptions;
  };
