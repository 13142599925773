import gql from 'graphql-tag';

export const getPageBasedTransactionDownloadHistoryQuery = gql`
  query getPageBasedTransactionDownloadHistory(
    $input: TransactionDownloadHistoryPageBasedPaginationInput!
    $sortBy: TransactionDownloadHistorySortInput
  ) {
    getPageBasedTransactionDownloadHistory(input: $input, sortBy: $sortBy) {
      records {
        id
        locale
        createdAt
        updatedAt
        createdBy {
          id
          name
          avatarUrl
        }
        file {
          id
          name
          url
        }
        filters {
          cards {
            id
            refNum
            cardholder {
              firstName
              lastName
            }
          }
          statuses
          types
          categories
          invoiceAssociationStatuses
          merchantNames
          dateRangeFilters {
            transactionCreatedAt {
              dateFrom
              dateTo
            }
          }
        }
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
`;
