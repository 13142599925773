import { get } from 'lodash';

export const sortByStringProp = (path: string) => (a: any, b: any) => {
  let nameA = get(a, path);
  let nameB = get(b, path);

  // equal items sort equally
  if (nameA == null) {
    return -1;
  }

  if (nameB == null) {
    return 1;
  }

  nameA = nameA.toLowerCase();
  nameB = nameB.toLowerCase();

  if (nameA === nameB) {
    return 0;
  }

  const collator = new Intl.Collator([], { numeric: true });

  return collator.compare(nameA, nameB);
};

export const sortByNumberProp = (path: string) => (a: any, b: any) => {
  const numberA = get(a, path);
  const numberB = get(b, path);

  return (numberA || 0) - (numberB || 0);
};

export const sortByDateProp = (path: string) => (a: any, b: any) => {
  const dateA = get(a, path);
  const dateB = get(b, path);

  return +new Date(dateA) - +new Date(dateB);
};
