import {
  HookFormNumberField,
  HookFormNumberFieldProps,
} from 'components/HookFormFields/HookFormNumberField';
import { FieldValues } from 'react-hook-form';

// useNumberField's formatOptions should be memoized:
// https://github.com/adobe/react-spectrum/issues/1893
const formatOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 0,
  style: 'unit',
  unit: 'day',
  unitDisplay: 'long',
};

/** A `HookFormNumberField` for entering a number of days */
export const HookFormDayField = <TFormValues extends FieldValues>(
  props: HookFormNumberFieldProps<TFormValues>
) => {
  return <HookFormNumberField formatOptions={formatOptions} {...props} />;
};
