import { EventProcessor, Integration } from '@sentry/types';

export interface IncludeRoute {
  path: string;
  name: string;
}

type CustomTransactionsOptions = {
  includedRoutes: IncludeRoute[];
};

// https://github.com/getsentry/sentry-javascript/blob/master/packages/types/src/integration.ts
export class CustomTransactionsIntegration implements Integration {
  name = 'CustomTransactionsIntegration';
  options: CustomTransactionsOptions;

  constructor(options: CustomTransactionsOptions) {
    this.options = options;
  }

  setupOnce(addGlobalEventProcessor: (callback: EventProcessor) => void): void {
    addGlobalEventProcessor(currentEvent => {
      if (!currentEvent.transaction) {
        // don’t do anything if transaction is not set
        // ensures we don’t filter out non-transaction events (e.g. errors)
        return currentEvent;
      }

      const includeRoute = this.options.includedRoutes.find(
        route => route.path === currentEvent.transaction
      );

      if (includeRoute) {
        // readable transaction name
        currentEvent.transaction = includeRoute.name;

        return currentEvent;
      }

      return null;
    });
  }
}
