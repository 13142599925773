import { FieldPolicy, TypePolicies } from '@apollo/client';
import { PayableDocumentConnection } from 'generated-types/graphql.types';
import { paginationWithRecordConnectionsMerge } from '../pagination-results-merge';

const payablePagination: FieldPolicy<PayableDocumentConnection> = {
  keyArgs: ['sort'],

  merge: paginationWithRecordConnectionsMerge('PayableDocumentConnection'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      payableDocumentsPagination: payablePagination,
    },
  },
};
