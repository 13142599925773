import { Grid, Text } from '@candisio/design-system';
import { MetaDataWrapper } from 'components/DocumentHistory/MetaDataWrapper';
import { Collapse } from 'components/History/items/Collapse';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface EcmStoredEventProps {
  createdAt?: string;
  documentNumber?: string;
  documentType?: string;
  fields: Array<{ key: string; value: string | string[]; label: string }>;
}

export const EcmStoredEventItem = ({
  createdAt,
  documentNumber,
  documentType,
  fields,
}: EcmStoredEventProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <Grid>
      <Text>
        {documentType
          ? t('documentHistoryEvents.storedAsEcm', {
              documentNumber: documentNumber,
              documentType: documentType,
              createdAt,
            })
          : t('documentHistoryEvents.storedOn', {
              documentNumber: documentNumber,
              createdAt,
            })}
      </Text>

      <Collapse>
        <MetaDataWrapper>
          {fields.map(({ key, value, label }) => (
            <Grid key={key}>
              <Text
                color="gray500"
                lineHeight="paragraph"
                fontWeight="regular"
                fontSize="small">
                {label}
              </Text>
              {Array.isArray(value) ? (
                value.map((v, index) => (
                  <Text
                    key={index}
                    color="gray800"
                    fontSize="small"
                    lineHeight="paragraph">
                    {v}
                  </Text>
                ))
              ) : (
                <Text color="gray800" fontSize="small" lineHeight="paragraph">
                  {value}
                </Text>
              )}
            </Grid>
          ))}
        </MetaDataWrapper>
      </Collapse>
    </Grid>
  );
};
