import { Card, Grid, Link, Separator, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { MobileAppTipPromotion } from './MobileAppPromotionTip/MobileAppPromotionTip';

export const CreditCardTipsWidget = () => {
  const [t] = useTranslation();

  return (
    <Card background="gray250" padding={0} position="sticky" top={0}>
      <MobileAppTipPromotion />
      <Separator />
      <Grid>
        <Grid padding="space16" gap="space4">
          <Text fontSize="large">
            {t('credit-cards:creditCardTipsWidget.firstSectionDesc')}
          </Text>
          <Link
            external
            fontSize="basic"
            icon="newTab"
            iconPosition="right"
            href={t('credit-cards:creditCardTipsWidget.firstSectionLink')}>
            {t(
              'credit-cards:successPage.cardManager.secondSection.toBlogArticle.text'
            )}
          </Link>
        </Grid>
        <Separator />
        <Grid padding="space16" gap="space4">
          <Text fontSize="large">
            {t('credit-cards:successPage.cardManager.secondSection.text')}
          </Text>
          <Link
            external
            fontSize="basic"
            icon="newTab"
            iconPosition="right"
            href={t(
              'credit-cards:successPage.cardManager.secondSection.toBlogArticle.link'
            )}>
            {t(
              'credit-cards:successPage.cardManager.secondSection.toBlogArticle.text'
            )}
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};
