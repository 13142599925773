import { FilterOptionsAccessor } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

export const useArchiveReimbursementsFilterOptions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const filterOptions: FilterOptionsAccessor<ReimbursementTableRow> = {
    paidAt: true,
    createdAt: true,
    status: {
      data: [
        {
          id: ReimbursementCaseStatus.Rejected,
          label: t('status.rejected'),
        },
        {
          id: ReimbursementCaseStatus.Check,
          label: t('status.check'),
        },
        {
          id: ReimbursementCaseStatus.Review,
          label: t('status.review'),
        },
        {
          id: ReimbursementCaseStatus.Approved,
          label: t('status.approved'),
        },
        {
          id: ReimbursementCaseStatus.Approving,
          label: t('status.approving'),
        },
        {
          id: ReimbursementCaseStatus.Archived,
          label: t('status.archived'),
        },
        {
          id: ReimbursementCaseStatus.Exported,
          label: t('status.exported'),
        },
        {
          id: ReimbursementCaseStatus.Draft,
          label: t('status.draft'),
        },
      ],
    },
    isPaid: {
      data: [
        {
          id: 'Paid',
          label: t('table.paid'),
        },
        {
          id: 'Not_paid',
          label: t('table.notPaid'),
        },
      ],
    },
  };

  return filterOptions;
};
