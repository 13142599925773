import { Flex, Skeleton } from '@candisio/design-system';

const LOADING_TEXT_HEIGHT = 16;
const LOADING_TEXT_WIDTH = 120;

export const LoadingDocumentDetailsPanel = () => {
  return (
    <Flex direction="column" gap="space8">
      <Flex justifyContent="space-between">
        <Skeleton
          height={`${LOADING_TEXT_HEIGHT}px`}
          width={`${LOADING_TEXT_WIDTH + 60}px`}
        />
      </Flex>
      <Flex justifyContent="space-between">
        {[1, 2].map(i => (
          <Skeleton
            key={i}
            height={`${LOADING_TEXT_HEIGHT}px`}
            width={`${
              i === 2 ? LOADING_TEXT_WIDTH - 60 : LOADING_TEXT_WIDTH
            }px`}
          />
        ))}
      </Flex>
    </Flex>
  );
};
