import {
  Box,
  Button,
  Grid,
  Text,
  mergeProps,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { ChangeEvent, ReactNode, useCallback, useRef, useState } from 'react';
import { useHover, useFocusWithin } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { validateDimensions } from 'utils/validate-img-dimensions';

const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
const MAX_AVATAR_WIDTH_PX = 1200;
const MAX_AVATAR_HEIGHT_PX = 1200;

export interface AvatarUploadProps {
  onUpload?: (f: File) => Promise<unknown>;
  onRemove?: () => void;
  readonly?: boolean;
  hasAvatar?: boolean;
  children: ReactNode;
  removeAvatarLoading?: boolean;
  uploadAvatarLoading?: boolean;
}

export const AvatarUpload = ({
  children,
  hasAvatar,
  onRemove,
  onUpload,
  readonly,
  removeAvatarLoading,
  uploadAvatarLoading,
}: AvatarUploadProps) => {
  const [t] = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const [focused, setFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: isFocusWithin => setFocused(isFocusWithin),
  });

  const { hoverProps } = useHover({
    onHoverChange: isHovering => setIsHovered(isHovering),
  });

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const clearHoverAndFocus = () => {
    setFocused(false);
    setIsHovered(false);
  };

  const fileRef = useRef<HTMLInputElement>(null);
  const handleChooseFile = useCallback(() => {
    clearHoverAndFocus();

    return fileRef.current?.click();
  }, [fileRef]);

  const [loading, setLoading] = useState(false);
  const uploadHandler = useCallback(
    async ({ target }: ChangeEvent<HTMLInputElement>) => {
      setError(undefined);
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        if (file.size > MAX_FILE_SIZE) {
          setError(t('uploads.errors.imageSize'));

          return;
        }

        try {
          await validateDimensions(file, {
            width: MAX_AVATAR_WIDTH_PX,
            height: MAX_AVATAR_HEIGHT_PX,
          });
          if (onUpload) {
            setLoading(true);
            await onUpload(file);
            setLoading(false);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            fileRef.current!.value = '';
          }
        } catch (e) {
          setError(t((e as Error).message));
        } finally {
          clearHoverAndFocus();
        }
      }
    },
    [setError, onUpload, t]
  );

  if (readonly) {
    return (
      <Grid placeContent="center" placeItems="center" gap="space4">
        <Box position="relative">{children}</Box>
      </Grid>
    );
  }

  const anythingIsLoading =
    loading || removeAvatarLoading || uploadAvatarLoading;

  const isFocusedOrHovered = focused || isHovered;

  const showUploadAvatarButton =
    (isFocusedOrHovered && !hasAvatar) || anythingIsLoading;

  const showRemoveAvatarButton = isFocusedOrHovered && hasAvatar;

  return (
    <Grid placeContent="center" placeItems="center" gap="space4">
      <Grid
        tabIndex={1}
        data-testid="avatar-upload-wrapper"
        placeContent="center"
        placeItems="center"
        {...mergeProps(hoverProps, focusWithinProps)}>
        <Box opacity={anythingIsLoading ? 0 : 1}>{children}</Box>

        <Button
          icon="trash"
          size="medium"
          loading={anythingIsLoading}
          color="red"
          position="absolute"
          iconSize="space40"
          spinnerSize="space64"
          width="100%"
          height="100%"
          onClick={() => {
            onRemove?.();
            clearHoverAndFocus();
          }}
          label={t('uploads.removeImage.cta')}
          visibility={showRemoveAvatarButton ? 'visible' : 'hidden'}
        />

        <Button
          icon="camera"
          size="medium"
          color="whiteBlue"
          loading={anythingIsLoading && !error}
          spinnerSize="space64"
          position="absolute"
          iconSize="space40"
          width="100%"
          height="100%"
          variant="tertiary"
          data-testid={t('uploads.uploadImage')}
          {...triggerProps}
          onClick={handleChooseFile}
          ref={triggerRef}
          visibility={showUploadAvatarButton ? 'visible' : 'hidden'}
        />
      </Grid>
      {error !== undefined && (
        <Grid autoFlow="column" alignItems="center" gap="space4">
          <Text color="red500" fontSize="basic">
            {error}
          </Text>
        </Grid>
      )}
      <input
        aria-label={t('uploads.uploadImage')}
        ref={fileRef}
        onChange={uploadHandler}
        id="image-upload"
        type="file"
        accept="image/*"
        hidden
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Grid>
            <Text color="gray800" fontSize="basic">
              {t('uploads.uploadImageTooltip.header')}
            </Text>
            <Text color="gray500" fontSize="small">
              {t('uploads.uploadImageTooltip.content', {
                width: MAX_AVATAR_WIDTH_PX,
                height: MAX_AVATAR_HEIGHT_PX,
                sizeInMb: MAX_FILE_SIZE_MB,
              })}
            </Text>
          </Grid>
        </Tooltip>
      )}
    </Grid>
  );
};
