import { Card, Grid, Heading, Skeleton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CardsAndRequestsOverviewWidgetLoading = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background="gray50" boxShadow="none">
      <Grid>
        <Heading as="h3">{t('insights.requestsOverview.title')}</Heading>
        <Grid gap="space8" autoFlow="column">
          {Array.from({ length: 4 }, (_, index) => (
            <Card background="gray50" border="1px solid gray250" key={index}>
              <Grid gap="space4">
                <Skeleton width="space128" height="space14" />
                <Skeleton width="space80" height="space14" />
              </Grid>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};
