import { BookingKey } from 'generated-types/graphql.types';

export enum SortBy {
  alphabetically = 'alphabetically',
  taxCode = 'taxCode',
  lastAdded = 'lastAdded',
}

export const sortTaxCodes = (bookingKeys: BookingKey[], sortBy: SortBy) => {
  switch (sortBy) {
    case SortBy.alphabetically: {
      const collator = new Intl.Collator();

      return (bookingKeys || []).sort((a: BookingKey, b: BookingKey) =>
        collator.compare(a.description ?? '', b.description ?? '')
      );
    }

    case SortBy.taxCode: {
      const isNumberSort = (bookingKeys || []).every(
        (bookingKey: BookingKey) => !isNaN(parseInt(bookingKey.taxCode))
      );

      let comparator;
      if (isNumberSort) {
        comparator = (a: BookingKey, b: BookingKey) =>
          parseInt(a.taxCode) - parseInt(b.taxCode);
      } else {
        const collator = new Intl.Collator();
        comparator = (a: BookingKey, b: BookingKey) =>
          collator.compare(a.taxCode ?? '', b.taxCode ?? '');
      }

      return (bookingKeys || []).sort(comparator);
    }

    case SortBy.lastAdded: {
      return (bookingKeys || []).sort((a: BookingKey, b: BookingKey) => {
        return (a.createdAt ?? '') < (b.createdAt ?? '') ? 1 : -1;
      });
    }

    default: {
      return bookingKeys;
    }
  }
};
