import { Card, Flex, Link, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../../../providers/LocaleProvider';
import { DisconnectAndRedirect } from '../DisconnectAndRedirect';

export const BdsSetupPanel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <Card
      boxShadow="noShadow"
      width="100%"
      paddingY="space16"
      paddingX="space24"
      background="bluebg">
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="space24">
        <Flex direction="column" gap="space4">
          <Paragraph>{t('datev.bdsConnectPanel.content')}</Paragraph>
          <Link external href={t('datev.bdsConnectPanel.linkUrl')}>
            {t('datev.bdsConnectPanel.linkText')}
          </Link>
        </Flex>
        <DisconnectAndRedirect />
      </Flex>
    </Card>
  );
};
