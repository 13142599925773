// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export const NotificationItemContainer = styled.li`
  margin: 0.5rem 0;
`;

export const NotificationsContainer = styled.ul`
  list-style-type: none;
  margin: 0.8rem 0;
  padding: 0;
  max-height: 40vh;
  overflow-y: auto;
`;

export const FilledPopoverContainer = styled.div`
  padding: 14px 8px 8px;
`;

export const EmptyNotificationsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
