import { Button, Flex, Icon, Paragraph, Text } from '@candisio/design-system';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DatevStatusCardState } from './datevStatusCardState';

export interface DatevStatusCardProps {
  loading?: boolean;
  primaryAction: () => void;
  secondaryAction: () => void;
  additionalActions?: ReactElement[];
  state?: DatevStatusCardState;
}

export const DatevStatusCard = ({
  loading,
  primaryAction,
  secondaryAction,
  additionalActions,
  state = DatevStatusCardState.UNAVAILABLE,
}: DatevStatusCardProps) => {
  const [t] = useTranslation('exports');

  return (
    <Flex
      direction="column"
      gap="space16"
      borderRadius="medium"
      background="bluebg"
      padding="space16">
      <Flex alignItems="center" gap="space8">
        <Icon size="space24" icon="infoCircle" />
        <Text as="h2" fontSize="xlarge" fontWeight="semibold">
          {
            // eslint-disable-next-line candis/no-template-strings-inside-translation
            t(`${state}.title`)
          }
        </Text>
      </Flex>

      <Paragraph>
        {
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          t(`${state}.contextInformation`)
        }
      </Paragraph>

      <Flex direction="column" gap="space8">
        <Button loading={loading} variant="primary" onClick={primaryAction}>
          <Text>
            {
              // eslint-disable-next-line candis/no-template-strings-inside-translation
              t(`${state}.primaryCta`)
            }
          </Text>
        </Button>
        <Button variant="tertiary" onClick={secondaryAction}>
          {
            // eslint-disable-next-line candis/no-template-strings-inside-translation
            t(`${state}.secondaryCta`)
          }
        </Button>
        {additionalActions ? additionalActions : null}
      </Flex>
    </Flex>
  );
};
