import {
  Badge,
  Flex,
  Grid,
  Tooltip,
  TooltipProps,
} from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { UserRole } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface InboxTooltipProps {
  tooltipProps: TooltipProps;
  documentCount: number | null;
  organizationSlug: string | undefined;
  onLinkClicked: () => void;
  transactionCount?: number;
  sensitiveDocumentsCount?: number;
}

export const InboxTooltip = forwardRef<HTMLDivElement, InboxTooltipProps>(
  (
    {
      tooltipProps,
      documentCount,
      transactionCount,
      sensitiveDocumentsCount,
      organizationSlug,
      onLinkClicked,
    },
    ref
  ) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);

    const creditCardsSetup = useCreditCardsSetup();
    const { showEcmSensitiveDocuments, canAccessEcm } = useEcm();

    if (!organizationSlug) {
      return null;
    }

    const hasSensitiveDocuments = sensitiveDocumentsCount !== 0;

    const documentLinkText = !!documentCount ? (
      <Flex alignItems="center" gap="space4">
        <Trans
          i18nKey="inbox.popover.documentsToProcess"
          ns={LOCALE_NAME_SPACE.CREDIT_CARDS}
          count={documentCount}>
          Documents to process
          <Badge color="blue">{String(documentCount)}</Badge>
        </Trans>
      </Flex>
    ) : (
      t('archive.tabs.documents')
    );

    const transactionLinkText = !!transactionCount ? (
      <Flex alignItems="center" gap="space4">
        <Trans
          i18nKey="inbox.popover.transactionsToProcess"
          ns={LOCALE_NAME_SPACE.CREDIT_CARDS}
          count={transactionCount}>
          Transactions
          <Badge color="blue">{String(transactionCount)}</Badge>
        </Trans>
      </Flex>
    ) : (
      t('archive.tabs.transactions')
    );

    const sensitiveDocumentsLinkText =
      showEcmSensitiveDocuments && canAccessEcm && hasSensitiveDocuments ? (
        <Flex alignItems="center" gap="space4">
          <Trans
            i18nKey="navigation.sensitiveDocuments.tooltip"
            ns={LOCALE_NAME_SPACE.ECM}
            count={sensitiveDocumentsCount}>
            Sensitive contracts
            <Badge color="blue">{String(sensitiveDocumentsCount)}</Badge>
          </Trans>
        </Flex>
      ) : (
        t('archive.tabs.ecmSensitiveDocuments')
      );

    return (
      <UserRoleAccessWrapper
        allowedRoles={[
          UserRole.Requester,
          UserRole.Admin,
          UserRole.Accountant,
        ]}>
        <Tooltip {...tooltipProps} ref={ref}>
          <Grid justifyItems="start">
            <RouterLink
              to={`/${organizationSlug}${Routes.INBOX}`}
              onClick={() => {
                onLinkClicked();
              }}>
              {documentLinkText}
            </RouterLink>
            {creditCardsSetup.isInUse && (
              <RouterLink
                to={`/${organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}`}
                onClick={() => {
                  onLinkClicked();
                }}>
                {transactionLinkText}
              </RouterLink>
            )}
            {showEcmSensitiveDocuments && canAccessEcm && (
              <RouterLink
                to={`/${organizationSlug}${Routes.ECM_SENSITIVE_CONTRACTS}`}
                onClick={() => {
                  onLinkClicked();
                }}>
                {sensitiveDocumentsLinkText}
              </RouterLink>
            )}
          </Grid>
        </Tooltip>
      </UserRoleAccessWrapper>
    );
  }
);
