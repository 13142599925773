import gql from 'graphql-tag';

export const applyForCreditCardsMutation = gql`
  mutation applyForCreditCards($input: ApplyForCreditCardsInput!) {
    applyForCreditCards(input: $input) {
      accepted
    }
  }
`;

export const getCardIssuerOrganizationOnboarding = gql`
  query getCardIssuerOrganizationOnboarding {
    getCardIssuerOrganizationOnboarding {
      requestedBy {
        firstName
        lastName
        email
      }
      requestedAt
      status
    }
  }
`;

export const refetchCreditCardIssuerOrganizationOnboarding = [
  { query: getCardIssuerOrganizationOnboarding },
];

export const updateDatevCreditCardsLedger = gql`
  mutation updateDatevCreditCardsLedger(
    $bookingAccount: String
    $transitAccount: String
  ) {
    updateDatevCreditCardsLedger(
      creditCardsLedger: {
        bookingAccount: $bookingAccount
        transitAccount: $transitAccount
      }
    )
  }
`;

export const getProcessingAccountDataForCardManagerQuery = gql`
  query getProcessingAccountDataForCardManager($id: ID) {
    organization(id: $id) {
      creditCardsSettings {
        processingAccount {
          beneficiary
          bankName
          iban
          bic
        }
      }
    }
  }
`;

export const sapB1SettingsQuery = gql`
  query sapB1Settings {
    sapB1Settings {
      purchaseInvoiceSeries
      outgoingPaymentSeries
      moneyInTransitGeneralLedgerAccount
      creditCardId
      creditCardGeneralLedgerAccount
      sapB1Version
    }
  }
`;

export const updateSapB1SettingsMutation = gql`
  mutation updateSapB1Settings($sapB1Settings: SapB1SettingsInput!) {
    updateSapB1Settings(sapB1Settings: $sapB1Settings) {
      code
    }
  }
`;

export const refetchSapB1Settings = [{ query: sapB1SettingsQuery }];
