import { IntegrationName } from 'generated-types/graphql.types';
import { ErrorMessages } from 'utils/zodFormValidation';
import { taxCodeExportValidationSchema } from 'views/utils/taxCodeExportValidationSchema';
import { z, ZodIssueCode, ZodTypeAny } from 'zod';

const validDatevTaxCodes = Object.keys(taxCodeExportValidationSchema);

export const DatevTaxCodeRequirements = {
  MinNumber: 1,
  MaxNumber: 9999,
  Regex: /^(?:[1-9]\d{0,3}|9999)$/, // ensure numbers between 1-9999
};

export const DatevAdjacentTaxCodeRequirements = {
  MinNumber: 0,
  MaxNumber: 9999,
  Regex: /^(?:0|[1-9]\d{0,3}|9999)$/, // ensure numbers between 0-9999
};

export const SapTaxCodeRequirements = {
  MinLength: 1,
  MaxLength: 8,
  Regex: /^[a-zA-Z0-9]*$/,
};

export const OtherTaxCodeRequirements = {
  MinLength: 1,
};

const getDatevTaxCodeSchema = () => {
  return z
    .object({
      taxCode: z.string().regex(DatevTaxCodeRequirements.Regex),
    })
    .superRefine(({ taxCode }, ctx) => {
      const isTaxCodeInRange =
        parseInt(taxCode, 10) >= DatevTaxCodeRequirements.MinNumber &&
        parseInt(taxCode, 10) <= DatevTaxCodeRequirements.MaxNumber;

      if (
        isTaxCodeInRange &&
        !validDatevTaxCodes.includes(taxCode.toString())
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['taxCode'],
          params: {
            translationKey: 'form.taxCode.datevInvalid',
          },
        });
      }
    });
};

const getDatevAdjacentTaxCodeSchema = () => {
  return z.object({
    taxCode: z.string().regex(DatevAdjacentTaxCodeRequirements.Regex),
  });
};

const getSapTaxCodeSchema = () => {
  return z.object({
    taxCode: z
      .string()
      .min(SapTaxCodeRequirements.MinLength)
      .max(SapTaxCodeRequirements.MaxLength)
      .regex(SapTaxCodeRequirements.Regex),
  });
};

const getOtherTaxCodeSchema = () => {
  return z.object({
    taxCode: z.string().min(OtherTaxCodeRequirements.MinLength),
  });
};

export const getTaxCodeSchema = (
  integration: IntegrationName,
  existingTaxCodes: string[]
): ZodTypeAny => {
  let taxCodeSchema: ZodTypeAny;

  switch (integration) {
    case IntegrationName.Sap:
      taxCodeSchema = getSapTaxCodeSchema();
      break;

    case IntegrationName.Other:
      taxCodeSchema = getOtherTaxCodeSchema();
      break;

    case IntegrationName.DatevAdjacent:
      taxCodeSchema = getDatevAdjacentTaxCodeSchema();
      break;

    case IntegrationName.Datev:
    default:
      taxCodeSchema = getDatevTaxCodeSchema();
  }

  return taxCodeSchema.superRefine(({ taxCode }, ctx) => {
    if (existingTaxCodes.includes(taxCode.toString())) {
      ctx.addIssue({
        path: ['taxCode'],
        code: ZodIssueCode.custom,
        params: {
          translationKey: 'form.taxCode.duplicate',
        },
      });
    }
  });
};

const formSchema = z.object({
  taxCode: z.string(),
  description: z.string(),
  taxPercentage: z.number().min(0).max(99.99).nullish(),
});

export const taxCodeSchema = ({
  integration,
  existingTaxCodes,
}: {
  integration: IntegrationName;
  existingTaxCodes: string[];
}) => formSchema.and(getTaxCodeSchema(integration, existingTaxCodes));

export const errorMessages: ErrorMessages<
  ReturnType<typeof taxCodeSchema>,
  '0.'
> = {
  taxCode: {
    label: 'form.taxCode.label',
  },
  description: { label: 'form.description.label' },
  taxPercentage: { label: 'form.taxPercentage.label' },
};
