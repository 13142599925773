import { HistoryList } from 'components/HistoryList/HistoryList';
import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { CoreDataTypes } from 'generated-types/graphql.types';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CoreDataImportHistory } from 'views/CoreDataImportHistory/CoreDataImportHistory';
import { useBookingAccountImportHistoryData } from './hooks/useBookingAccountImportHistoryData';

export const BookingAccountImportHistory = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const { shouldUseSapGL } = useSap();

  const {
    bookingAccountHistoryItems,
    bookingAccountImportUrl,
    bookingAccountUrl,
    isLoading,
  } = useBookingAccountImportHistoryData();

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const title = t('importHistory.title');
  const backButtonLabel = t('importHistory.backToBookingAccount');

  if (shouldUseCoreDataApi) {
    return (
      <CoreDataImportHistory
        coreDataType={CoreDataTypes.GeneralLedger}
        title={title}
        backButtonLabel={backButtonLabel}
        backButtonLink={bookingAccountUrl}
        localeNamespace={LOCALE_NAME_SPACE.BOOKING_ACCOUNTS}
      />
    );
  }

  return (
    <ImportHistoryLayout
      title={title}
      backButtonLabel={backButtonLabel}
      backButtonLink={bookingAccountUrl}>
      <HistoryList
        data={bookingAccountHistoryItems}
        variant="importHistory"
        emptyState={
          shouldUseSapGL
            ? { translation: 'booking-accounts:table.nodata.sap' }
            : {
                translation: 'booking-accounts:importHistory.noData',
                url: bookingAccountImportUrl,
              }
        }
        isLoading={isLoading}
        searchPlaceholder={t('importHistory.searchPlaceholder')}
      />
    </ImportHistoryLayout>
  );
};
