import { usePaymentConditionsQuery } from 'generated-types/graphql.types';
import { PaymentConditionItemProps } from './ContactForm/PaymentConditionItem';

interface UsePaymentConditionItemsReturn {
  items: PaymentConditionItemProps[];
  loading: boolean;
}

/**
 * Fetches available payment conditions and maps to PaymentConditionItemProps[]
 */
export const usePaymentConditionItems = (): UsePaymentConditionItemsReturn => {
  const { data, loading } = usePaymentConditionsQuery({
    variables: { filter: { isArchived: false } },
  });

  return {
    items:
      data?.paymentConditions.map(paymentCondition => ({
        id: paymentCondition.id,
        dueDateOffset: paymentCondition.dueDateOffset,
        conditionNumber: paymentCondition.paymentConditionNumber,
        discountOffset: paymentCondition.discountOffset ?? undefined,
        discountPercentage: paymentCondition.discountPercentage ?? undefined,
      })) ?? [],
    loading,
  };
};
