export enum Validity {
  months3 = '3',
  months6 = '6',
  months9 = '9',
  months12 = '12',
  months18 = '18',
  months24 = '24',
  months30 = '30',
  months36 = '36',
}

export const validityFieldOptions = [
  {
    key: Validity.months3,
    children: 'ccRequestForm.fields.validity.options.3Months',
  },
  {
    key: Validity.months6,
    children: 'ccRequestForm.fields.validity.options.6Months',
  },
  {
    key: Validity.months9,
    children: 'ccRequestForm.fields.validity.options.9Months',
  },
  {
    key: Validity.months12,
    children: 'ccRequestForm.fields.validity.options.12Months',
  },
  {
    key: Validity.months18,
    children: 'ccRequestForm.fields.validity.options.18Months',
  },
  {
    key: Validity.months24,
    children: 'ccRequestForm.fields.validity.options.24Months',
  },
  {
    key: Validity.months30,
    children: 'ccRequestForm.fields.validity.options.30Months',
  },
  {
    key: Validity.months36,
    children: 'ccRequestForm.fields.validity.options.36Months',
  },
];
