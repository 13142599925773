import { Button, Card, Flex, Text, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../models';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { useOrganizationId } from '../../../../../providers/OrganizationProvider';

export const BdsSetupHintPanel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const orgId = useOrganizationId();
  const history = useHistory();

  const settingsUrl = `/${orgId}${Routes.SETTINGS}${Routes.DATEV}`;

  return (
    <Card
      boxShadow="noShadow"
      width="100%"
      paddingY="space16"
      paddingX="space16"
      background="bluebg">
      <Flex direction="column" justifyContent="space-between" gap="space16">
        <Flex direction="column" justifyContent="space-between" gap="space8">
          <Text fontWeight="semibold" fontSize="large">
            {t('datev.bdsSetupHintPanel.title')}
          </Text>
          <Paragraph fontSize="basic">
            {t('datev.bdsSetupHintPanel.content')}
          </Paragraph>
        </Flex>
        <Button
          variant="primary"
          color="blue"
          size="small"
          width="fit-content"
          onClick={() => {
            history.push(settingsUrl);
          }}>
          {t('datev.bdsSetupHintPanel.buttonCta')}
        </Button>
      </Flex>
    </Card>
  );
};
