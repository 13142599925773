import {
  Button,
  Grid,
  Icon,
  Text,
  defaultTheme,
} from '@candisio/design-system';
import { useSap } from 'orgConfig/sap';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';

interface ProcessingFormRemainingAmountErrorCardProps {
  currency: string;
  remainingAmount: number;
  readOnly?: boolean;
  onSplitDocument?: (index?: number) => void;
}

const commonButtonProps: Omit<ComponentProps<typeof Button>, 'as'> = {
  flexGrow: '1',
  style: {
    padding: '12px 1rem',
    borderRadius: '0',
    borderEndStartRadius: defaultTheme.radii.medium,
    borderEndEndRadius: defaultTheme.radii.medium,
  },
} as const;

export const ProcessingFormRemainingAmountErrorCard = ({
  currency,
  remainingAmount,
  readOnly,
  onSplitDocument,
}: ProcessingFormRemainingAmountErrorCardProps) => {
  const [t] = useTranslation();
  const { isActive: isSapActive } = useSap();

  const formattedAmount = amountFormatNonNull(remainingAmount, currency);

  const description =
    remainingAmount > 0
      ? 'document.requestApproval.inputs.errors.remainingAmount.positiveBalance'
      : 'document.requestApproval.inputs.errors.remainingAmount.negativeBalance';

  const sapUserDescription =
    remainingAmount > 0
      ? 'document.requestApproval.inputs.errors.remainingAmount.positiveBalance_sap'
      : 'document.requestApproval.inputs.errors.remainingAmount.negativeBalance_sap';

  return (
    <Grid background="redbg">
      <Grid
        gap="space8"
        templateColumns="auto 1fr"
        padding="space14 space8 space14 space16"
        borderTop={`1px solid ${defaultTheme.colors.gray300}`}>
        <Icon icon="failCircle" color="red700" size="space16" />
        <Grid gap="space4">
          <Text fontWeight="semibold">
            {t('document.requestApproval.inputs.errors.remainingAmount.title', {
              balance: formattedAmount,
            })}
          </Text>
          <Text>{t(isSapActive ? sapUserDescription : description)}</Text>
        </Grid>
      </Grid>
      {!readOnly && (
        <Button
          onClick={() => onSplitDocument?.()}
          size="small"
          variant="tertiary"
          color="gray"
          icon="plus"
          iconPosition="left"
          {...commonButtonProps}>
          {t('document.requestApproval.splitBookings.editSplitCTA')}
        </Button>
      )}
    </Grid>
  );
};
