import gql from 'graphql-tag';
import { costCenterData } from '../../queries/cost-centers';

export const createCostCenterMutation = gql`
  mutation createCostCenter_new(
    $input: CreateCostCenterInput!
    $source: String
  ) {
    createCostCenter_new(input: $input, source: $source) {
      __typename
      ... on CostCenterCreated {
        id
      }
      ... on CostCenterAlreadyExists {
        code
      }
    }
  }
`;

export const updateCostCenterMutation = gql`
  mutation updateCostCenter_new($id: ID!, $input: UpdateCostCenterInput!) {
    updateCostCenter_new(id: $id, input: $input) {
      __typename
      ... on CostCenterUpdated {
        id
      }
      ... on CostCenterAlreadyExists {
        code
      }
    }
  }
`;

export const costCenterQuery = gql`
  query getCostCenter($input: GetCostCenterInput!) {
    costCenter(input: $input) {
      createdAt
      createdBy {
        name
      }
      ...CostCenterData
    }
  }
  ${costCenterData}
`;

export const updateCostCenterStatusMutation = gql`
  mutation updateCostCenterStatus($id: ID!, $input: UpdateCostCenterInput!) {
    updateCostCenterStatus(id: $id, input: $input) {
      createdAt
      createdBy {
        name
      }
      ...CostCenterData
    }
  }
  ${costCenterData}
`;
