import { Text } from '@candisio/design-system';
import { InfoPanel } from 'containers/Entitlements/components/Upsell/components/InfoPanel';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const Provisions = ({ onClick }: { onClick?: () => void }) => {
  const [t] = useTranslation();
  const linkText = t('entitlements:promo.link');

  return (
    <InfoPanel
      linkText={linkText}
      onClick={onClick}
      message={
        <Trans t={t} i18nKey="entitlements:promo.provisions">
          Um Rückstellungen zu exportieren, brauchst du die Erweiterung
          <Text fontWeight="bold">Candis Plus</Text>
        </Trans>
      }
    />
  );
};
