import { PercentageProgressBar } from '../../../../components/PercentageProgressBar/PercentageProgressBar';
import { getColor } from './utils/getColor';

interface ProgressbarProps {
  total: number;
  available: number;
}

export const Progressbar = ({ total, available }: ProgressbarProps) => {
  const percentage = (available / total) * 100;
  const roundedPercentage = Math.round(percentage);

  return (
    <PercentageProgressBar
      color={getColor(percentage).progressBar}
      percentage={roundedPercentage}
    />
  );
};
