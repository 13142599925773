import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useTranslation } from 'react-i18next';
import { SessionActionCard } from 'views/Settings/ApiSettings/elements/Card/SessionActionCard';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';

export const ApiSettings = () => {
  const [t] = useTranslation();

  return (
    <SettingsLayout width="700px" title={t('settings:apiSettings.title')}>
      <SessionActionCard />
      <InfoPanel
        externalLink={t('settings:apiSettings.infoPanel.externalLink', {
          returnObjects: true,
        })}
        sections={t('settings:apiSettings.infoPanel.sections', {
          returnObjects: true,
        })}
        title={t('settings:apiSettings.infoPanel.title')}
      />
    </SettingsLayout>
  );
};
