import { Text } from '@candisio/design-system';
import { InfoPanel } from 'containers/Entitlements/components/Upsell/components/InfoPanel';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const Contracts = ({ onClick }: { onClick?: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  return (
    <InfoPanel
      linkText={t('promo.link')}
      onClick={onClick}
      message={
        <Trans t={t} i18nKey="promo.contracts">
          Um Verträge abzulegen, brauchst du die Erweiterung
          <Text fontWeight="bold">Candis Plus</Text>
        </Trans>
      }
    />
  );
};
