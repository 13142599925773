import {
  Grid,
  Item,
  ListView,
  ScrollBox,
  useTheme,
} from '@candisio/design-system';
import {
  DocumentCurrency,
  DocumentStatus,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useRemoveVendor } from 'views/CreditCards/hooks/useRemoveVendor';
import { Vendor } from 'views/CreditCards/hooks/useVendorSuggestionsData';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';
import { VendorItem } from './VendorItem';

interface VendorListSuggestionsProps {
  vendors: Vendor[];
  showVendors: boolean;
  onSetSuggestedAmount: React.Dispatch<React.SetStateAction<number>>;
}

const documentStatusFilters = [
  DocumentStatus.Approved,
  DocumentStatus.Exported,
];

export const VendorList = ({
  vendors,
  showVendors,
  onSetSuggestedAmount,
}: VendorListSuggestionsProps) => {
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const location = useLocation();
  const { colors } = useTheme();

  const { loading, handleRemoveVendor } = useRemoveVendor();

  const handleButtonClick = ({
    vendorId,
    maxMonthlyInvoiceAmount,
  }: {
    maxMonthlyInvoiceAmount: {
      amount: number;
      precision: number;
      currency: DocumentCurrency;
    };
    vendorId: string;
  }) => {
    const urlParams = new URLSearchParams();
    urlParams.getAll(location.search);
    urlParams.append('onCreditCardsView', 'true');
    const convertedAmountFromCents =
      formatFromCents(
        maxMonthlyInvoiceAmount.currency,
        maxMonthlyInvoiceAmount.amount
      ) ?? 0;

    onSetSuggestedAmount(convertedAmountFromCents);
    navigate({
      pathname: `/${orgId}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.RECURRING_PAYMENTS}/${vendorId}`,
      search: urlParams.toString(),
    });
  };

  const handleRowClick = (contactIds: string[]) => {
    const urlParams = new URLSearchParams();

    const documentViewParams = {
      sort: ['invoiceDate:descend'],
      contact: contactIds,
      status: documentStatusFilters,
    };

    Object.entries(documentViewParams).forEach(([key, filters]) => {
      filters.forEach(filter => urlParams.append(key, filter));
    });

    navigate({
      pathname: `/${orgId}${Routes.ARCHIVE}`,
      search: urlParams.toString(),
    });
  };

  return showVendors ? (
    <Grid>
      <ScrollBox height="100%" maxHeight="400px">
        <ListView maxHeight="400px" style={{ background: colors.gray100 }}>
          {vendors.map(({ details, aggregate, maxMonthlyInvoiceAmount }) => (
            <Item textValue={details.vendorName} key={details.id}>
              <VendorItem
                onRowClick={handleRowClick}
                onButtonClick={() =>
                  handleButtonClick({
                    vendorId: details.id,
                    maxMonthlyInvoiceAmount,
                  })
                }
                details={details}
                aggregate={aggregate.value}
                removeVendor={{
                  loading,
                  onClick: () =>
                    handleRemoveVendor({
                      vendorId: details.id,
                    }),
                }}
              />
            </Item>
          ))}
        </ListView>
      </ScrollBox>
    </Grid>
  ) : null;
};
