import { MenuButton, MenuButtonProps } from '@candisio/design-system';
import { forwardRef } from 'react';

export interface KebabMenuProps extends Omit<MenuButtonProps, 'ref'> {
  /**
   * Label is required for accessibility
   */
  label: string;
}

/**
 * A MenuButton that implements the “kebab menu” pattern
 */
export const KebabMenu = forwardRef<HTMLButtonElement, KebabMenuProps>(
  (props, ref) => {
    return <MenuButton icon="kebab" variant="tertiary" {...props} ref={ref} />;
  }
);
