import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  Heading,
} from '@candisio/design-system';
import { ReactNode } from 'react';

export interface SettingsLayoutProps extends GridProps {
  width: string;
  /** Optional actions to display in title row */
  actions?: ReactNode;
  /** Optional description to display below title */
  description?: ReactNode;
  /** Title of this settings page */
  title: string;
  /** Optional title tag */
  titleTag?: ReactNode;
  /** Optional fixed height of the layout */
  height?: GridProps['height'];
  /** Optional content props */
  contentProps?: BoxProps;
}

/**
 * Common layout for settings pages
 *
 * Includes title and body (`children`), and optional actions and description.
 */
export const SettingsLayout = ({
  width,
  actions,
  children,
  description,
  title,
  titleTag,
  height,
  contentProps,
  ...restProps
}: SettingsLayoutProps) => {
  return (
    <Grid
      gap="space24"
      templateColumns="1fr"
      templateRows="auto 1fr"
      minHeight="100%"
      height={height}
      paddingRight="space24"
      width="fit-content"
      {...restProps}>
      <Grid gap="space16" width={width}>
        <Grid
          autoFlow="column"
          gap="space16"
          justifyContent="space-between"
          height="46px">
          <Grid gap="space16" templateColumns="auto 1fr" alignItems="center">
            <Heading as="h2">{title}</Heading>
            {titleTag}
          </Grid>
          {actions !== undefined && <Box>{actions}</Box>}
        </Grid>
        {description !== undefined && (
          <Box minWidth="60ch" maxWidth="50%">
            {description}
          </Box>
        )}
      </Grid>
      <Grid
        gap="space40"
        height="100%"
        templateColumns={`${width} 1fr`}
        {...contentProps}>
        {children}
      </Grid>
    </Grid>
  );
};
