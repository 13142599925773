import { AccountingInfoFormSchemaFormValues } from './accountingInfoFormSchema';

export const transformFormValuesToAccountingData = ({
  formValues,
}: {
  formValues: AccountingInfoFormSchemaFormValues;
}) => {
  const approverIds = formValues.approvers?.length
    ? formValues.approvers?.map(itm => itm?.approvers)
    : undefined;

  const accountingData = {
    approverIds:
      formValues.approvalMode === 'approvers' ? approverIds : undefined,
    costCenterId: formValues.costCenter.value,
    costObjectId: formValues.costObject.value,
    generalLedgerAccountId: formValues.generalLedgerAccount.value,
    contactId: formValues.contact.value,
    bookingKeyId: formValues.taxCode,
    approvalWorkflowTemplateId:
      formValues.approvalMode === 'workflow' ? formValues.workflow : undefined,
    artistSocialInsuranceCode: formValues.artistSocialInsuranceCode,
    extraCostInfoId: formValues.extraCostInfo.value,
  };

  return accountingData;
};
