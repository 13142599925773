const warning = {
  icon: 'warning',
  color: 'yellow700',
  backgroundColor: '#F6E3C680', // yellowbg, will be used in style object
} as const;

const success = {
  icon: 'checkCircle',
  color: 'green700',
  backgroundColor: '#EFFBF380', // greenbg
} as const;

export const matchStates = {
  warning,
  success,
} as const;
