import {
  FieldArray,
  UseFieldArrayRemove,
  UseFormReturn,
} from 'react-hook-form';
import { useCreateExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { HospitalityExpenseForm } from './HospitalityExpenseForm';

interface HospitalityExpenseFormContainerProps {
  onRemoveForm: UseFieldArrayRemove;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  forms: FieldArray<ExpensesFormOutput, 'hospitalityExpenses'>[];
}

export const HospitalityExpenseFormContainer = ({
  forms,
  formMethods,
  onRemoveForm,
}: HospitalityExpenseFormContainerProps) => {
  const { createExpenseInvoice } = useCreateExpenseInvoice();

  return (
    <HospitalityExpenseForm
      forms={forms}
      formMethods={formMethods}
      onRemoveForm={onRemoveForm}
      createExpenseInvoice={createExpenseInvoice}
    />
  );
};
