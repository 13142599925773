import {
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  TruncatedText,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ActiveCards } from './ActiveCards';
import { NoActiveCards } from './NoActiveCards';

interface CardsAndRequestsOverviewWidgetNoTodosProps {
  activeCards: number;
}

export const CardsAndRequestsOverviewWidgetNoTodos = ({
  activeCards,
}: CardsAndRequestsOverviewWidgetNoTodosProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background="gray50">
      <Grid>
        <Heading as="h3">{t('insights.requestsOverview.title')}</Heading>
        <Grid templateColumns="auto 1fr" gap="space8">
          {activeCards > 0 ? (
            <ActiveCards value={activeCards} />
          ) : (
            <NoActiveCards />
          )}
          <Flex gap="space4" alignItems="center">
            <Icon size="space20" icon="checkCircle" color="gray500" />
            <TruncatedText fontSize="basic" color="gray500" alignSelf="center">
              {t('insights.requestsOverview.noTodos')}
            </TruncatedText>
          </Flex>
        </Grid>
      </Grid>
    </Card>
  );
};
