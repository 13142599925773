import { useCreditCardsAccountingInfoFieldItemsQuery } from 'generated-types/graphql.types';
import { useToApproverFieldItem } from 'views/Inbox/DocumentProcessing/useToApproverFieldItem';

export const useApproversFieldOptions = () => {
  const { data } = useCreditCardsAccountingInfoFieldItemsQuery();

  const { approvers = [] } = data ?? {};

  const mapToApproverFieldItem = useToApproverFieldItem(
    [], // deliberately do not show absence
    approvers
  );

  const defaultItems = approvers.map(mapToApproverFieldItem);

  return { defaultItems };
};
