import {
  GenerateTestTransactionsCurrency,
  GenerateTestTransactionsType,
  TransactionStatus,
} from 'generated-types/graphql.types';

export const transactionStatuses = [
  {
    key: TransactionStatus.Confirmed,
    children: 'Confirmed',
  },
  {
    key: TransactionStatus.Declined,
    children: 'Declined',
  },
  {
    key: TransactionStatus.Pending,
    children: 'Pending',
  },
  {
    key: TransactionStatus.Reversed,
    children: 'Reversed',
  },
];

export const transactionTypes = [
  {
    key: GenerateTestTransactionsType.Purchase,
    children: 'Purchase',
  },
  {
    key: GenerateTestTransactionsType.PurchaseChargeback,
    children: 'PurchaseChargeback',
  },
  {
    key: GenerateTestTransactionsType.PurchaseChargebackReversal,
    children: 'PurchaseChargebackReversal',
  },
  {
    key: GenerateTestTransactionsType.PurchaseRefund,
    children: 'PurchaseRefund',
  },
  {
    key: GenerateTestTransactionsType.StatusInquiry,
    children: 'StatusInquiry',
  },
];

export const currencies = [
  {
    key: 'EUR',
    children: GenerateTestTransactionsCurrency.Eur,
  },
  {
    key: 'USD',
    children: GenerateTestTransactionsCurrency.Usd,
  },
];
