import { ParseError } from 'papaparse';

export class CSVFileParseError extends Error {
  errors: ParseError[];

  constructor(errors: ParseError[]) {
    super('Failed to parse csv file');
    this.errors = errors;
  }
}
