import gql from 'graphql-tag';

export const documentDataExtractionsSettingsQuery = gql`
  query documentDataExtractionSettings {
    documentDataExtractionSettings {
      suggestOutgoingInvoices
    }
  }
`;

export const updateDocumentDataExtractionSettingsMutation = gql`
  mutation updateDocumentDataExtractionSettings(
    $settings: UpdateDocumentDataExtractionSettingsInput!
  ) {
    updateDocumentDataExtractionSettings(settings: $settings)
  }
`;
