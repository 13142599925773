/* tslint:disable max-classes-per-file */
import { ParseError } from 'papaparse';

export class CSVFileParseError extends Error {
  errors: ParseError[];

  constructor(errors: ParseError[]) {
    super('Failed to parse csv file');
    this.errors = errors;
  }
}

export class CSVMaxBookingAccountsError extends Error {
  constructor() {
    super('The CSV export contains more than maximum allowed booking accounts');
  }
}

export class BookingAccountsWithoutAccountNumberError extends Error {
  constructor() {
    super('Found booking account without account number');
  }
}

export class BookingAccountsWithoutNameError extends Error {
  constructor() {
    super('Found booking account without name');
  }
}

/* tslint:enable max-classes-per-file */
