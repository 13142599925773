import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';

interface DueDateProps {
  dueDate?: Date | null;
}

export const DueDate = ({ dueDate }: DueDateProps) => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="bookings[0].dueDate"
      label={t('document.requestApproval.inputs.invoiceDueDate.label')}
      inputProps={{
        value: dueDate ? DateFormatters.compact(new Date(dueDate)) : undefined,
      }}
      readOnly
    />
  );
};
