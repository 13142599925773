import { useFullOrganization } from 'providers/OrganizationProvider';

export interface ProcessingAccount {
  bankName: string | undefined;
  iban: string | undefined;
}

export interface Address {
  streetName: string | undefined;
  streetNumber: string | undefined;
  streetAdditional: string | undefined;
  postalCode: string | undefined;
  city: string | undefined;
  country: string | undefined;
}

export interface OrganizationSettings {
  name: string | undefined;
  nationalRegisterCourt: string | undefined;
  nationalRegisterId: string | undefined;
  nationalRegisterType: string | undefined;
  tradeName: string | undefined;
  isDeliveryAddressandBillingSame: boolean;
  billingAddress: Address;
  deliveryAddress: Address;
  vatId: string | undefined;
  billingEmail: string | undefined;
  processingAccount: ProcessingAccount;
}

export interface ReadOnlySettings {
  billingAddress: Address;
  nationalRegisterCourt: string | undefined;
  nationalRegisterId: string | undefined;
  nationalRegisterType: string | undefined;
  processingAccount: ProcessingAccount;
}

export const useGetOrganizationSettings = () => {
  const organization = useFullOrganization();

  const creditCardsSettings = organization?.creditCardsSettings;

  const organizationSettings: OrganizationSettings = {
    name: organization?.name,
    nationalRegisterCourt: creditCardsSettings?.nationalRegisterCourt,
    nationalRegisterId: creditCardsSettings?.nationalRegisterId ?? undefined,
    nationalRegisterType:
      creditCardsSettings?.nationalRegisterType ?? undefined,
    tradeName: creditCardsSettings?.tradeName ?? undefined,
    isDeliveryAddressandBillingSame:
      creditCardsSettings?.deliveryAddressSameAsBillingAddress ?? false,
    billingAddress: {
      streetName: creditCardsSettings?.billingAddress.streetName,
      streetNumber: creditCardsSettings?.billingAddress.streetNumber,
      streetAdditional:
        creditCardsSettings?.billingAddress.streetAdditional ?? undefined,
      postalCode: creditCardsSettings?.billingAddress.postalCode ?? undefined,
      city: creditCardsSettings?.billingAddress.city ?? undefined,
      country: creditCardsSettings?.billingAddress.country ?? undefined,
    },
    deliveryAddress: {
      streetName: creditCardsSettings?.deliveryAddress?.streetName,
      streetNumber: creditCardsSettings?.deliveryAddress?.streetNumber,
      streetAdditional:
        creditCardsSettings?.deliveryAddress?.streetAdditional ?? undefined,
      postalCode: creditCardsSettings?.deliveryAddress?.postalCode ?? undefined,
      city: creditCardsSettings?.deliveryAddress?.city ?? undefined,
      country: creditCardsSettings?.deliveryAddress?.country ?? undefined,
    },
    vatId: creditCardsSettings?.vatId ?? undefined,
    billingEmail: creditCardsSettings?.billingEmail ?? undefined,
    processingAccount: {
      bankName: creditCardsSettings?.processingAccount.bankName ?? undefined,
      iban: creditCardsSettings?.processingAccount.iban ?? undefined,
    },
  };

  return { organizationSettings };
};
