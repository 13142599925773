import {
  Box,
  Button,
  Drawer,
  Flex,
  Item,
  TabPanel,
  Tabs,
  useTabListState,
} from '@candisio/design-system';
import { EmailInboxMessage, Maybe } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { MailHistory } from './components/MailHistory';
import { MailMetadata } from './components/MailMetadata/MailMetadata';

type Props = {
  mail: Maybe<EmailInboxMessage>;
  onClose: () => void;
  isLoading: boolean;
};

export const MailDetails = ({ mail, onClose, isLoading }: Props) => {
  const [t] = useTranslation();

  const tabsProps = {
    'aria-label': t('settings.mailSync.details.tabs.ariaLabel'),
    items: [
      {
        key: 'details',
        title: t('settings.mailSync.details.tabs.metadata.title'),
        children: mail && <MailMetadata isLoading={isLoading} {...mail} />,
      },
      {
        key: 'history',
        title: t('settings.mailSync.details.tabs.history'),
        children: mail && <MailHistory mail={mail}></MailHistory>,
      },
    ],
    defaultSelectedKey: 'details',
    children: (item: any) => (
      <Item key={item.key} title={item.title} textValue={item.title}>
        {item.children}
      </Item>
    ),
  };

  const tabsState = useTabListState(tabsProps);

  return (
    <Drawer isOpen={!!mail} onClose={onClose}>
      <Flex
        borderBottom="1px solid gray300"
        alignItems="center"
        justifyContent="space-between"
        paddingX="space40"
        paddingY="space20"
        paddingBottom={0}>
        <Tabs {...tabsProps} state={tabsState} />
        <Button
          icon="close"
          label={t('common.close')}
          onClick={onClose}
          zIndex={10}
          variant="tertiary"
        />
      </Flex>
      <Box paddingX="space40" paddingY="space24" height="100%">
        <TabPanel key={tabsState.selectedItem?.key} state={tabsState} />
      </Box>
    </Drawer>
  );
};
