import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAvailableCardsForLinkingRecurringPayments } from 'views/CreditCards/hooks/useAvailableCardsForLinkingRecurringPayments';
import { LinkCardToVendorFormValues } from './utils/linkCardToVendorFormSchema';

interface RecurringPaymentsComboBoxFieldProps {
  defaultInputValue?: string;
  defaultCreditCardId?: string;
  showConfidence: boolean;
}

export const RecurringPaymentsComboBoxField = ({
  defaultInputValue,
  showConfidence,
  defaultCreditCardId,
}: RecurringPaymentsComboBoxFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  //TO-DO: These options should all be fed in the component from outside
  const { comboBoxOptions, handleDebounceSearch, loadMore, loading } =
    useAvailableCardsForLinkingRecurringPayments(
      defaultInputValue,
      defaultCreditCardId
    );

  const { formState, getFieldState } =
    useFormContext<LinkCardToVendorFormValues>();

  const isDirty = getFieldState('creditCard', formState).isDirty;

  const variant =
    !isDirty && defaultInputValue && showConfidence ? 'success' : 'default';

  const message =
    variant === 'success'
      ? t('recurringPaymentsModal.linkCard.field.suggestedMessage')
      : undefined;

  return (
    <HookFormComboBoxField
      name="creditCard"
      aria-label="creditCard"
      isVirtualized
      variant={variant}
      label={t('recurringPaymentsModal.linkCard.field.label')}
      placeholder={t('recurringPaymentsModal.linkCard.field.placeholder')}
      onEndReached={loadMore}
      loading={loading}
      message={message}
      allowsCustomValue
      onSearch={handleDebounceSearch}
      items={comboBoxOptions}
      defaultInputValue={defaultInputValue}
      itemHeight="space64"
      emptyListPlaceholder={t(
        'recurringPaymentsModal.linkCard.field.emptyList'
      )}
    />
  );
};
