import {
  CreateCreditCardContactFieldItemsQuery,
  CreateCreditCardContactFieldItemsQueryVariables,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';

import { createCreditCardContactFieldItemsQuery } from '../CreditCardsInsights/gql';

export const useContactFieldOptions = () => {
  const computeVariables = useCallback(
    (inputString?: string): CreateCreditCardContactFieldItemsQueryVariables => {
      return {
        name: inputString,
      };
    },
    []
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<
      CreateCreditCardContactFieldItemsQuery,
      CreateCreditCardContactFieldItemsQueryVariables
    >({
      query: createCreditCardContactFieldItemsQuery,
      queryRootKey: 'createCreditCardContactsPagination',
      computeVariables,
    });

  const items = (data?.createCreditCardContactsPagination?.records ?? []).map(
    contact => ({
      key: contact.id,
      textValue: contact.name.value,
      children: contact.name.value,
    })
  );

  return {
    items,
    loading,
    onEndReached: loadMore,
    onSearch: handleDebounceSearch,
  };
};
