import { Collapse } from 'components/History/items/Collapse';
import {
  Maybe,
  RequestApprovalEventDataFragment,
  SubstituteUser,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { OrderList } from '../styles';

type Approver = Maybe<Pick<SubstituteUser, 'id' | 'name' | 'isSubstituting'>>;

export type Approvers = Maybe<Array<Approver>>;

const ApproverWithSubstituteList = ({
  approvers,
}: {
  approvers: Approvers;
}) => {
  const [t] = useTranslation();

  return (
    <Collapse>
      <OrderList>
        {approvers?.map(approver =>
          approver ? (
            <li
              key={
                approver.isSubstituting
                  ? `sub-${approver.isSubstituting.id}`
                  : approver.id
              }>
              {approver.isSubstituting
                ? t(
                    'document.compact.history.requestApprovalFromSubstituteList',
                    {
                      approver: approver.name,
                      substitutedApprover: approver.isSubstituting.name,
                    }
                  )
                : approver.name}
            </li>
          ) : null
        )}
      </OrderList>
    </Collapse>
  );
};

export const RequestApprovalWithSubstituteEvent = ({
  approvers,
}: RequestApprovalEventDataFragment) => {
  const [t] = useTranslation();

  const approversCount = approvers?.length ?? 0;
  const firstApprover = approvers?.[0];

  const approverText = () => {
    if (approversCount === 1 && firstApprover) {
      return firstApprover?.isSubstituting
        ? t('document.compact.history.requestApprovalFromSubstitute', {
            approver: firstApprover.name,
            substitutedApprover: firstApprover.isSubstituting.name,
          })
        : t('document.compact.history.requestApproval', {
            name: firstApprover.name,
          });
    } else {
      return t('document.compact.history.requestApproval', {
        count: approversCount,
      });
    }
  };

  return (
    <>
      {approverText()}
      {approversCount > 1 && (
        <ApproverWithSubstituteList approvers={approvers} />
      )}
    </>
  );
};
