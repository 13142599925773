import { Box, Theme, useTheme } from '@candisio/design-system';
import {
  AnimatePresence,
  DragHandlers,
  Variants,
  motion,
  useMotionValue,
} from 'framer-motion';
import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import { prefix } from 'utils/localStorage';
import { DocumentPreview } from './DocumentPreview';

const MotionBox = motion(Box);

const DragHandleWrapperBox = styled(MotionBox)<{ theme: Theme }>`
  .drag-handle {
    width: 1px;
  }

  &:hover {
    .drag-handle {
      width: ${({ theme }) => theme.space.space2};
      background-color: ${({ theme }) => theme.colors.blue100};
    }
  }
`;

const DRAWER_WIDTH_PX = 464;
const MIN_DRAWER_WIDTH_PX = 440;
const MAX_DRAWER_WIDTH_PX = 800;
const DRAWER_VARIANTS: (width: number) => Variants = width => ({
  open: {
    width: `${width}px`,
  },
  closed: {
    width: 0,
  },
});

interface DocumentPreviewDrawerProps {
  previewDocumentId: string | null;
  handlePreviewDocumentIdChange: (documentId: string | null) => void;
  cursor: string | null;
  onOpen: (params: { documentId: string; cursor?: string | null }) => void;
}

export const DocumentPreviewDrawer = ({
  previewDocumentId,
  handlePreviewDocumentIdChange,
  cursor,
  onOpen,
}: DocumentPreviewDrawerProps) => (
  <AnimatePresence>
    {previewDocumentId && (
      <DocumentPreviewDrawerContent
        cursor={cursor}
        onOpen={onOpen}
        handlePreviewDocumentIdChange={handlePreviewDocumentIdChange}
        previewDocumentId={previewDocumentId}
      />
    )}
  </AnimatePresence>
);

const DOCUMENT_PREVIEW_WIDTH_LOCAL_STORAGE_KEY = `${prefix}-document-preview-width`;
export const DocumentPreviewDrawerContent = ({
  cursor,
  handlePreviewDocumentIdChange,
  onOpen,
  previewDocumentId,
}: DocumentPreviewDrawerProps) => {
  const theme = useTheme();
  const defaultWidth = localStorage.getItem(`${prefix}-document-preview-width`);
  const [animationFinished, setAnimationFinished] = useState(false);

  const mWidth = useMotionValue(
    defaultWidth ? Number(defaultWidth) : DRAWER_WIDTH_PX
  );

  if (!previewDocumentId) return null;

  const handleDrag: DragHandlers['onDrag'] = (_event, info) => {
    let newWidth = mWidth.get() - info.delta.x;

    if (newWidth < MIN_DRAWER_WIDTH_PX) {
      newWidth = MIN_DRAWER_WIDTH_PX;
    } else if (newWidth > MAX_DRAWER_WIDTH_PX) {
      newWidth = MAX_DRAWER_WIDTH_PX;
    }

    mWidth.set(newWidth);
    localStorage.setItem(
      DOCUMENT_PREVIEW_WIDTH_LOCAL_STORAGE_KEY,
      newWidth.toString()
    );
  };

  return (
    <MotionBox
      onAnimationStart={() => {
        setAnimationFinished(false);
      }}
      onAnimationComplete={() => {
        setAnimationFinished(true);
      }}
      key="previewWrapper"
      id="previewWrapper"
      initial="closed"
      animate="open"
      exit="closed"
      variants={DRAWER_VARIANTS(mWidth.get())}
      transition={{
        ease: 'easeOut',
      }}
      height="100%"
      minWidth={0}
      minHeight={0}
      style={{
        width: mWidth,
      }}>
      <DragHandleWrapperBox
        drag="x"
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        onDrag={handleDrag}
        dragSnapToOrigin
        dragElastic={0}
        dragMomentum={false}
        position="absolute"
        zIndex={10}
        height="100%"
        paddingX="space10"
        left="-space10"
        cursor="col-resize"
        theme={theme}
        onDoubleClick={() => {
          mWidth.set(DRAWER_WIDTH_PX);
          localStorage.setItem(
            DOCUMENT_PREVIEW_WIDTH_LOCAL_STORAGE_KEY,
            DRAWER_WIDTH_PX.toString()
          );
        }}>
        <Box className="drag-handle" height="100%" background="gray250" />
      </DragHandleWrapperBox>
      <DocumentPreview
        documentId={previewDocumentId}
        onOpen={() => {
          onOpen({
            documentId: previewDocumentId,
            cursor: cursor ?? undefined,
          });
        }}
        onClose={() => handlePreviewDocumentIdChange(null)}
        showPdf={animationFinished}
      />
    </MotionBox>
  );
};
