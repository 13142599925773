import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';

export const Note = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextareaField
      name="bookings[0].note"
      label={t('document.requestApproval.inputs.note.label', {
        context: getTranslationContext(),
      })}
      readOnly
    />
  );
};
