import { InsightBudgetType } from 'generated-types/graphql.types';
import { Dispatch, SetStateAction } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export interface WidgetFormAnnualBudget {
  year2022?: number;
  year2023?: number;
  year2024?: number;
}

export interface WidgetFormQuarterBudget {
  q1_2022?: number;
  q2_2022?: number;
  q3_2022?: number;
  q4_2022?: number;
  q1_2023?: number;
  q2_2023?: number;
  q3_2023?: number;
  q4_2023?: number;
  q1_2024?: number;
  q2_2024?: number;
  q3_2024?: number;
  q4_2024?: number;
}

export enum BudgetYear {
  YEAR_2022 = '2022',
  YEAR_2023 = '2023',
  YEAR_2024 = '2024',
}

type YearFields = keyof WidgetFormAnnualBudget;
export const yearsFields: Array<YearFields> = [
  'year2022',
  'year2023',
  'year2024',
];

type QuarterFields = keyof WidgetFormQuarterBudget;
export const quarterFields: Array<QuarterFields> = [
  'q1_2022',
  'q2_2022',
  'q3_2022',
  'q4_2022',
  'q1_2023',
  'q2_2023',
  'q3_2023',
  'q4_2023',
  'q1_2024',
  'q2_2024',
  'q3_2024',
  'q4_2024',
];

export interface BudgetOptionHandlerProps {
  setBudgetView: Dispatch<SetStateAction<InsightBudgetType | 'default'>>;
  form: UseFormReturn<FieldValues, any, undefined>;
  budgetType: InsightBudgetType | undefined;
  budgets: WidgetFormAnnualBudget | WidgetFormQuarterBudget;
}

export enum QUARTER {
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
}

export enum Month {
  JANUARY = 'january',
  FEBRUARY = 'february',
  MARCH = 'march',
  APRIL = 'april',
  MAY = 'may',
  JUNE = 'june',
  JULY = 'july',
  AUGUST = 'august',
  SEPTEMBER = 'september',
  OCTOBER = 'october',
  NOVEMBER = 'november',
  DECEMBER = 'december',
}

export type Quarters = {
  [QUARTER.Q1]?: number;
  [QUARTER.Q2]?: number;
  [QUARTER.Q3]?: number;
  [QUARTER.Q4]?: number;
};

export const QUARTERS_IN_A_MONTH = Object.keys(QUARTER).length;

export type YearlyBudget = {
  year: number;
  budget: number | null | undefined;
};

export type YearDividedByQuarter = {
  year: number;
  quarters: Quarters;
  type: InsightBudgetType;
};

export type QuarterBudget = {
  year: number;
  quarters: Quarters;
};
