import { Avatar, Flex, Text } from '@candisio/design-system';
import { Maybe } from '@graphql-tools/utils';
import { DocumentFilters } from 'generated-types/graphql.types';
import { User } from 'generated-types/graphql.types';
import { getPreferencesWidgetfromUrl } from 'views/InsightsWidgets/utils';
import { checkIfBudgetsAreApplied } from './WidgetForm/BudgetTab/utils/budget';
import { WidgetFormData } from './WidgetForm/types';

export const sanitizeWidgetFormData = (
  formData: WidgetFormData,
  isWidgetShared: boolean
) => {
  const costCenterIds = formData.costCenters;
  const costObjectIds = formData.costObjects;
  const contactIds = formData.contacts;
  const generalLedgerAccountIds = formData.generalLedgerAccounts;

  const sharedWithUsersIds = !isWidgetShared ? [] : formData.sharedWithUsers;

  const preferencesFromUrl = getPreferencesWidgetfromUrl(
    window.location.search
  );

  const isWidgetOnDashboardSection = preferencesFromUrl.onDashboard;
  const budgetsbyMonth = checkIfBudgetsAreApplied(formData);
  const yearsWithBudgetAssigned = budgetsbyMonth.filter(
    bud => Object.keys(bud.monthlyBudgets).length
  );

  return {
    title: formData.title,
    budget: yearsWithBudgetAssigned,
    filters: {
      costCenterIds: costCenterIds?.length ? costCenterIds : null,
      costObjectIds: costObjectIds?.length ? costObjectIds : null,
      contactIds: contactIds?.length ? contactIds : null,
      generalLedgerAccountIds: generalLedgerAccountIds?.length
        ? generalLedgerAccountIds
        : null,
    },
    sharedWithUsers: sharedWithUsersIds,
    preferences: {
      onDashboard: isWidgetOnDashboardSection === 'true',
    },
  };
};

export const getInitialFilterOptions = (filters?: Maybe<DocumentFilters>) => {
  const costCenters =
    filters?.costCenters?.map(cc => ({
      label: cc.readableName,
      value: cc.id,
      key: cc.id,
    })) || [];

  const contacts =
    filters?.contacts?.map(cc => ({
      label: cc.name.value,
      value: cc.id,
      key: cc.id,
    })) || [];

  const costObjects =
    filters?.costObjects?.map(cc => ({
      label: cc.readableName,
      value: cc.id,
      key: cc.id,
    })) || [];

  const generalLedgerAccounts =
    filters?.generalLedgerAccounts?.map(cc => ({
      label: cc.readableName,
      value: cc.id,
      key: cc.id,
    })) || [];

  return {
    costCenters,
    contacts,
    costObjects,
    generalLedgerAccounts,
  };
};

export const toInitialSharedUsersValue = (
  user: Pick<User, 'name' | 'id' | 'avatarUrl'>
) => ({
  value: user.id,
  label: (
    <Flex alignItems="center" gap="space8">
      <Avatar
        flex="none"
        name={user.name}
        img={user.avatarUrl ?? undefined}
        size="small"
      />
      <Text>{user.name}</Text>
    </Flex>
  ),
  key: user.id,
});
