import { Order, SortInput } from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';
import { PaymentTableData } from '../PaymentTable/types';
import { SimplifiedPayment } from '../types';

export const getSortField = (
  sortId: keyof PaymentTableData | (string & {})
): string => {
  if (sortId === 'paymentInfo') return 'creditorIban';

  return sortId;
};

export const mapToSortInput = (
  sort: Array<SortingRule<SimplifiedPayment>>
): SortInput | undefined => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  return {
    field: getSortField(firstSort.id),
    order: firstSort.desc ? Order.Desc : Order.Asc,
  };
};
