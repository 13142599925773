import { useApolloClient, useQuery } from '@apollo/client';
import {
  ArchiveTransactionAssociationsQuery,
  InboxTransactionAssociationsQuery,
  TransactionConnection,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { DocumentNode } from 'graphql';
import { Routes } from 'models';
import { useEffect } from 'react';
import { getFiltersAndSortFromUrl } from 'utils/url-helper';
import {
  archiveTransactionAssociationsQuery,
  getCardIssuerTransactionById,
  inboxTransactionAssociationsQuery,
} from './gql';

export type TransactionAssociationView = Routes.ARCHIVE | Routes.INBOX;

type QueryTypes =
  | ArchiveTransactionAssociationsQuery
  | InboxTransactionAssociationsQuery;

interface Navigation {
  id: string | undefined;
  cursor: string | undefined | null;
  organizationSlug: string;
  routeType: TransactionAssociationView;
  filtersAndSortFromUrl: string;
}

interface UseTransactionAssociationNavigationProps {
  cursor: string | undefined | null;
  organizationSlug: string;
  routeType: TransactionAssociationView;
}

type NavigationResult = {
  prevTransactionLink?: string;
  prevTransactionId?: string;
  nextTransactionLink?: string;
  nextTransactionId?: string;
  isLoadingNavigationData: boolean;
  linkBackToList: string;
  transactionListCount?: number;
};

const query: DocumentNode = getCardIssuerTransactionById;

const queryList: Record<TransactionAssociationView, DocumentNode> = {
  [Routes.ARCHIVE]: archiveTransactionAssociationsQuery,
  [Routes.INBOX]: inboxTransactionAssociationsQuery,
};

const generateTransactionLink = ({
  id,
  cursor,
  routeType,
  organizationSlug,
  filtersAndSortFromUrl,
}: Navigation) => {
  return `/${organizationSlug}${routeType}${Routes.TRANSACTIONS}/${id}?cursor=${cursor}&${filtersAndSortFromUrl}`;
};

const generateLinkBackToList = ({
  organizationSlug,
  routeType,
  filtersAndSortFromUrl,
}: {
  organizationSlug: string;
  routeType: TransactionAssociationView;
  filtersAndSortFromUrl: string;
}) => {
  return `/${organizationSlug}${routeType}${Routes.TRANSACTIONS}?${filtersAndSortFromUrl}`;
};

export const useTransactionAssociationDetails = ({
  cursor,
  routeType,
}: {
  cursor: string | undefined | null;
  routeType: TransactionAssociationView;
}) => {
  const gqlQuery = queryList[routeType];

  const { data, loading } = useQuery<QueryTypes>(gqlQuery, {
    skip: !cursor,
    variables: { cursor },
    fetchPolicy: 'no-cache',
  });

  const { edges: prevEdges } = (data?.prevTransaction ||
    {}) as TransactionConnection;

  const { edges: nextEdges, pageInfo } = (data?.nextTransaction ||
    {}) as TransactionConnection;

  return {
    nextTransactionEdge: nextEdges?.[0],
    prevTransactionEdge: prevEdges?.[0],
    loading,
    transactionListCount: pageInfo?.totalCount ?? undefined,
  };
};

export const useTransactionAssociationNavigation = ({
  cursor,
  organizationSlug,
  routeType,
}: UseTransactionAssociationNavigationProps): NavigationResult => {
  const {
    prevTransactionEdge,
    nextTransactionEdge,
    loading: isLoadingNavigationData,
    transactionListCount,
  } = useTransactionAssociationDetails({
    cursor,
    routeType,
  });

  const filtersAndSortFromUrl = getFiltersAndSortFromUrl(
    window.location.search
  );

  const nextTransactionId = nextTransactionEdge?.node?.id;
  const nextTransactionLink = nextTransactionEdge
    ? generateTransactionLink({
        cursor: nextTransactionEdge.cursor,
        id: nextTransactionId,
        organizationSlug,
        routeType,
        filtersAndSortFromUrl,
      })
    : undefined;

  const prevTransactionId = prevTransactionEdge?.node?.id;
  const prevTransactionLink = prevTransactionEdge
    ? generateTransactionLink({
        cursor: prevTransactionEdge.cursor,
        id: prevTransactionId,
        organizationSlug,
        routeType,
        filtersAndSortFromUrl,
      })
    : undefined;

  const linkBackToList = generateLinkBackToList({
    organizationSlug,
    routeType,
    filtersAndSortFromUrl,
  });

  return {
    nextTransactionLink,
    prevTransactionLink,
    isLoadingNavigationData,
    nextTransactionId,
    prevTransactionId,
    transactionListCount,
    linkBackToList,
  };
};

export const usePrefetchQueries = (
  prevTransactionId: string | undefined,
  nextTransactionId: string | undefined
) => {
  const client = useApolloClient();

  useEffect(() => {
    const idleCallback = requestIdleCallback(() => {
      if (prevTransactionId) {
        void client.query({
          query,
          variables: {
            id: prevTransactionId,
          },
        });
      }

      if (nextTransactionId) {
        void client.query({
          query,
          variables: {
            id: nextTransactionId,
          },
        });
      }
    });

    return () => cancelIdleCallback(idleCallback);
  }, [client, prevTransactionId, nextTransactionId]);
};

export const validTransactionStatus = [
  TransactionStatus.Confirmed,
  TransactionStatus.Pending,
];
