import { Grid, Text, Button, Heading } from '@candisio/design-system';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { HookFormTextField } from '../../../../../../components/HookFormFields/HookFormTextField';
import { InfoIcon } from '../../../../../../components/Icons/InfoIcon';
import {
  i18n,
  LOCALE_NAME_SPACE,
} from '../../../../../../providers/LocaleProvider';
import {
  ErrorMessages,
  zodResolver,
} from '../../../../../../utils/zodFormValidation';
import { InfoList } from '../../../DATEV/ExportTypes/components/InfoList';
import { fieldTranslationKeys, getChecks } from '../utils';

const sapFormSchema = () =>
  z.object({
    serviceLayerUrl: z.string(),
    companyDbId: z.string(),
    username: z.string(),
    password: z.string(),
  });

export type SapFormValues = z.infer<ReturnType<typeof sapFormSchema>>;
export type SapFormErrorMessages = ErrorMessages<
  ReturnType<typeof sapFormSchema>
>;

const getErrorMessages = (t: i18n.TFunction): SapFormErrorMessages => {
  return {
    serviceLayerUrl: { label: t(fieldTranslationKeys.serviceLayerUrl) },
    companyDbId: { label: t(fieldTranslationKeys.companyDbId) },
    username: { label: t(fieldTranslationKeys.username) },
    password: { label: t(fieldTranslationKeys.password) },
  };
};

const ValidationCheckError = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  return <InfoList checks={[getChecks(t).errorCheck]} animate />;
};

const CenteredInfoIcon = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  return (
    <div style={{ margin: 'auto 0' }}>
      <InfoIcon
        message={
          <Text>
            {t('settings.form.fields.serviceLayerUrl.info')}
            <br />
            <br />
            {t('settings.form.fields.serviceLayerUrl.infoDetails')}
          </Text>
        }
      />
    </div>
  );
};

export const SapConnectionForm = ({
  onSubmit,
  isSubmitting,
  hasValidationCheckError,
}: {
  onSubmit: (values: SapFormValues) => void;
  isSubmitting: boolean;
  hasValidationCheckError: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  const form = useForm<SapFormValues>({
    mode: 'onTouched',
    resolver: zodResolver({
      zodSchema: sapFormSchema,
      errorMessages: getErrorMessages(t),
    }),
  });

  return (
    <Grid gap="space16">
      <Heading as="h3">{t('settings.form.description')}</Heading>
      <FormProvider {...form}>
        <Grid as="form" gap="space32" onSubmit={form.handleSubmit(onSubmit)}>
          <Grid gap="space8">
            <Grid gap="space8" templateColumns="1fr auto">
              <HookFormTextField<SapFormValues>
                autoFocus
                name="serviceLayerUrl"
                label={t(fieldTranslationKeys.serviceLayerUrl)}
              />
              <CenteredInfoIcon />
            </Grid>
            <HookFormTextField<SapFormValues>
              name="companyDbId"
              label={t(fieldTranslationKeys.companyDbId)}
            />
            <Grid columns={2} gap="space16">
              <HookFormTextField<SapFormValues>
                name="username"
                label={t(fieldTranslationKeys.username)}
              />
              <HookFormTextField<SapFormValues>
                name="password"
                inputProps={{ type: 'password' }}
                label={t(fieldTranslationKeys.password)}
              />
            </Grid>
          </Grid>

          {hasValidationCheckError && <ValidationCheckError />}

          <Button
            loading={isSubmitting}
            disabled={isSubmitting}
            type="submit"
            width="fit-content"
            size="small">
            {t('settings.form.submitButton')}
          </Button>
        </Grid>
      </FormProvider>
    </Grid>
  );
};
