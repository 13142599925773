import { gql } from 'graphql-tag';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';

export const storeEcmDocumentMutation = gql`
  mutation storeEcmDocument($input: StoreEcmDocumentInput!) {
    storeEcmDocument(input: $input) {
      ecmDocumentId
    }
  }
`;

export const storeSensitiveEcmDocumentMutation = gql`
  mutation storeSensitiveEcmDocument($input: StoreSensitiveEcmDocumentInput!) {
    storeSensitiveEcmDocument(input: $input) {
      ecmDocumentId
    }
  }
`;

export const updateEcmDocumentMutation = gql`
  mutation updateEcmDocument($input: UpdateEcmDocumentInput!) {
    updateEcmDocument(input: $input) {
      ecmDocumentId
    }
  }
`;

export const removeEcmDocumentMutation = gql`
  mutation removeEcmDocument($input: RemoveEcmDocumentInput!) {
    removeEcmDocument(input: $input) {
      ecmDocumentId
    }
  }
`;

export const storageFormInitialDataQuery = gql`
  query storageFormInitialData($documentId: ID!) {
    getDocument(id: $documentId) {
      contact {
        value {
          id
          name {
            value
          }
        }
        source
        confidence
      }
      extractedContact {
        id
        cachingKey
        name {
          value
          confidence
          source
        }
        iban {
          value
          confidence
          source
        }
        bankAccountNumber {
          value
          confidence
          source
        }
        swiftCode {
          value
          confidence
          source
        }
        taxNumber {
          value
          confidence
          source
        }
        vatId {
          value
          confidence
          source
        }
        createTransfer {
          value
          confidence
          source
        }
        email {
          value
          confidence
          source
        }
        phoneNumber {
          value
          confidence
          source
        }
        street {
          value
          confidence
          source
        }
        postOfficeBox {
          value
          confidence
          source
        }
        postalCode {
          value
          confidence
          source
        }
        city {
          value
          confidence
          source
        }
        countryISOCode {
          value
          confidence
          source
        }
        customerNumber {
          value
          confidence
          source
        }
      }
      documentFile {
        name
        size
        url
      }
      status
      invoiceDate {
        value
        confidence
        source
      }
      invoiceNumber {
        value
        confidence
        source
      }
      amount {
        value
        confidence
        source
      }
      currency {
        value
        confidence
        source
      }
      bookings {
        costCenter {
          value {
            id
            readableName
          }
          confidence
          source
        }
      }
      tags {
        ...Tag
        __typename
      }
      globalDocumentId
      isReadOnly
    }
  }
`;

export const ecmStorageFormInitialDataQuery = gql`
  query ecmStorageFormInitialData($id: String!) {
    getAggregatedEcmDocument(id: $id) {
      id
      documentType
      type
      contactId
      contactName
      createdBy {
        id
        name
      }
      notes
      documentDate
      documentName
      documentNumber
      documentStatus
      fileSource {
        name
      }
      costCenter {
        id
        readableName
      }
      documentSubCategory {
        id
        name
      }
      startDate
      endDate
      terminationDate
      terminationReminderDate
      amount {
        amount
        currency
        precision
      }
      responsiblePerson {
        primary {
          id
          name
          avatarUrl
        }
      }
      notifyPerson {
        primary {
          id
          name
          avatarUrl
        }
      }
      isSensitive
      isReadOnly
      extractedContact {
        id
        name
        iban
        bankAccountNumber
        swiftCode
        taxNumber
        vatId
        automaticPayment
        email
        phoneNumber
        street
        postOfficeBox
        postalCode
        city
        country
        customerNumber
      }

      globalDocumentId
      tags {
        ...Tag
      }
    }
  }
  ${tagFragment}
`;

export const ecmDocumentStatusQuery = gql`
  query ecmDocumentStatus($id: String!) {
    getAggregatedEcmDocument(id: $id) {
      documentStatus
      isSensitive
    }
  }
`;

export const teamMembersByRolesQuery = gql`
  query membersByRole($filters: OrganizationMembershipsFiltersInput) {
    organizationMemberships(filters: $filters) {
      id
      name
      avatarUrl
    }
  }
`;
