import { Grid, MenuButton } from '@candisio/design-system';
import { SearchField } from 'components/SearchField/SearchField';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardQueryParams } from 'views/CreditCards/utils/utils';

export type ToolbarFilter = 'InUse' | 'Inactive';

export const toolBarFilterParam = 'toolbarFilter';

interface CreditCardsTableToolbarProps {
  isLoadingConfigs: boolean;
  search: string;
  configurationsTable: Configuration[];
  onUpdateColumnsConfigurations: (configurations: Configuration[]) => void;
  onResetConfigurations: () => void;
  onSearch: (querySearch: string) => void;
}

export const CreditCardsTableToolbar = ({
  onSearch,
  onUpdateColumnsConfigurations,
  onResetConfigurations,
  configurationsTable,
  search,
  isLoadingConfigs,
}: CreditCardsTableToolbarProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { canManageCards } = usePermissionsForCreditCards();

  const navigate = useNavigate();
  const { searchParams } = useMutateSearchParams();

  const toolbarFilter =
    (searchParams.get(toolBarFilterParam) as ToolbarFilter) ?? 'InUse';

  const handleChangeFilter = (value: Key[]) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    const selectedItem = value[0];
    if (selectedItem) {
      urlSearchParams.delete(CreditCardQueryParams.status);
      urlSearchParams.set(toolBarFilterParam, String(selectedItem));
      navigate({
        search: urlSearchParams.toString(),
      });
    }
  };

  const filterItems: Array<{ id: ToolbarFilter; label: string }> =
    useMemo(() => {
      return [
        {
          id: 'InUse',
          label: t('cardManagerView.table.toolbar.filter.inUse'),
        },
        {
          id: 'Inactive',
          label: t('cardManagerView.table.toolbar.filter.inactive'),
        },
      ];
    }, [t]);

  return (
    <Grid
      templateColumns="24rem 1fr"
      borderTopRadius="basic"
      overflow="hidden"
      borderBottom="1px solid gray200"
      background="white"
      padding="space12">
      <SearchField
        clearTooltip={t('cardManagerView.table.toolbar.search.tooltip')}
        placeholder={
          canManageCards
            ? t('cardManagerView.table.toolbar.search.placeholder')
            : t('cardholderView.search.label')
        }
        value={search}
        onChange={onSearch}
      />
      <Grid justifySelf="end" alignSelf="center" gap="space8" autoFlow="column">
        <ConfigurationsMenu
          isLoading={isLoadingConfigs}
          configurationItems={configurationsTable}
          onUpdateConfigurations={onUpdateColumnsConfigurations}
          onResetConfigurations={onResetConfigurations}
        />

        <MenuButton
          variant="secondary"
          selectionMode="single"
          value={[toolbarFilter]}
          onChange={handleChangeFilter}
          items={filterItems}>
          {toolbarFilter === 'InUse'
            ? t('cardManagerView.table.toolbar.filter.inUse')
            : t('cardManagerView.table.toolbar.filter.inactive')}
        </MenuButton>
      </Grid>
    </Grid>
  );
};
