import { Grid, Image, Modal, Text } from '@candisio/design-system';
import datevLogoIcon from 'assets/icon-datev-logo.jpg';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';

export interface DatevConnectionModalProps {
  isVisible: boolean;
}

export const DatevConnectionModal = ({
  isVisible,
}: DatevConnectionModalProps) => {
  const [t] = useTranslation();

  return (
    <Modal width={345} isOpen={isVisible}>
      <Grid gap="space32">
        <Grid gap="space8" justifyContent="center">
          <Image src={datevLogoIcon} alt="DATEV" width={74} height={74} />
          <Text textAlign="center">Schnittstelle</Text>
        </Grid>
        <ProgressBar completion={0}>{t('loading')}...</ProgressBar>
      </Grid>
    </Modal>
  );
};
