import { Color } from '@candisio/design-system';
import { Variant } from './types';

export const mapVariantToColors: Record<Variant, string> = {
  [Variant.WITHIN_THRESHOLD]: 'green500',
  [Variant.ALMOST_MAXIMUM]: 'yellow500',
  [Variant.OVER_MAXIMUM]: 'red500',
};

export const mapTagVariantToColors: Record<Variant, Color> = {
  [Variant.WITHIN_THRESHOLD]: 'green',
  [Variant.ALMOST_MAXIMUM]: 'yellow',
  [Variant.OVER_MAXIMUM]: 'red',
};
