import { escapeRegex } from 'utils/regex';
import { AccountingAreasListData } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasList';

export enum AccountingAreaSortBy {
  alphabetically = 'alphabetically',
  lastAdded = 'lastAdded',
}

interface QueryAccountingAreasParams {
  queryString: string;
  accountingAreas?: AccountingAreasListData[];
}

export const queryAccountingAreas = ({
  queryString,
  accountingAreas,
}: QueryAccountingAreasParams) => {
  const regExp = new RegExp(escapeRegex(queryString), 'i');

  return accountingAreas?.filter(accountingArea => {
    if (
      accountingArea.name?.match(regExp) ||
      accountingArea.description?.match(regExp)
    ) {
      return true;
    }

    return null;
  });
};

interface SortAccountingAreasParams {
  sortBy: AccountingAreaSortBy;
  accountingAreas?: AccountingAreasListData[];
}

export const sortAccountingAreas = ({
  sortBy,
  accountingAreas,
}: SortAccountingAreasParams) => {
  switch (sortBy) {
    case AccountingAreaSortBy.alphabetically: {
      const collator = new Intl.Collator();

      return (accountingAreas || []).sort(
        (a: AccountingAreasListData, b: AccountingAreasListData) =>
          collator.compare(a.name ?? '', b.name ?? '')
      );
    }

    case AccountingAreaSortBy.lastAdded: {
      return (accountingAreas || []).sort(
        (a: AccountingAreasListData, b: AccountingAreasListData) => {
          return (a.createdAt ?? '') < (b.createdAt ?? '') ? 1 : -1;
        }
      );
    }

    default: {
      return accountingAreas;
    }
  }
};
