import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export interface PhysicalCardLimitsFormSchemaOptions {
  isCardManager: boolean;
}

const limitsSchema = z
  .object({
    limit: z.number(),
    transactionLimit: z.number(),
  })
  .superRefine((values, ctx) => {
    if (values.transactionLimit > values.limit) {
      ctx.addIssue({ code: 'custom', path: ['transactionLimit'] });
    }
  });

export const physicalCardLimitsFormSchema = ({
  isCardManager,
}: PhysicalCardLimitsFormSchemaOptions) => {
  const schema = z.object({
    comment: isCardManager ? z.string().optional() : z.string(),
    limitInterval: z.string(),
  });

  return schema.and(limitsSchema);
};

export type PhysicalCardLimitsFormOutput = z.infer<
  ReturnType<typeof physicalCardLimitsFormSchema>
>;

export type PhysicalCardLimitsFormValues =
  Partial<PhysicalCardLimitsFormOutput>;

export type PhysicalCardLimitsErrorMessages = ErrorMessages<
  ReturnType<typeof physicalCardLimitsFormSchema>
>;
