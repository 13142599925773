import { useToastMessage } from 'components/Toast/useToastMessage';
import { AccountingAreaErrorType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
interface AccountingAreaErrorMessages {
  errorKind?: Partial<AccountingAreaErrorType>;
}
export const useMapErrorMessage = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { error } = useToastMessage();

  const mapErrorMessages = (kind: AccountingAreaErrorMessages) => {
    switch (kind.errorKind) {
      case AccountingAreaErrorType.Invalid:
        return error(t('accountingAreas.drawer.form.errors.invalid'));
      case AccountingAreaErrorType.Duplicate:
        return error(t('accountingAreas.drawer.form.errors.duplicate'));
      case AccountingAreaErrorType.NotFound:
        return error(t('accountingAreas.drawer.form.errors.notFound'));
      case AccountingAreaErrorType.Forbidden:
        return error(t('accountingAreas.drawer.form.errors.forbidden'));
      case AccountingAreaErrorType.Unknown:
        return error(t('accountingAreas.drawer.form.errors.unknown'));
      default:
        return error(t('accountingAreas.drawer.form.errors.unknown'));
    }
  };

  return { mapErrorMessages };
};
