import { SelectionOptions } from '@candisio/design-system';
import { DocumentsTable } from 'components/DocumentsTable/DocumentsTable';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { useMemo } from 'react';

export const DocumentSelectionTable = ({
  selectedIds,
  setSelectedIds,
  loading,
  onLoadMore,
  documentsTableData,
}: {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  loading: boolean;
  onLoadMore: () => void;
  documentsTableData: DocumentsTableData[];
}) => {
  const selectionOptions: SelectionOptions<DocumentsTableData> = useMemo(() => {
    return {
      onSelectionRowChanged: documents => {
        setSelectedIds(documents.map(d => d.id));
      },
      selectedRowsIds: selectedIds,
    };
  }, [selectedIds, setSelectedIds]);

  return (
    <DocumentsTable
      columns={['status']}
      data={documentsTableData}
      isLoading={loading}
      onEndReached={onLoadMore}
      selectionOptions={selectionOptions}
      onAllRowsSelected={selectedRows =>
        setSelectedIds(selectedRows.map(d => d.id))
      }
      width="100%"
    />
  );
};
