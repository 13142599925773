import { Grid, Text, Button } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface AddNewTagCTAProps {
  onClick: () => void;
}

export const AddNewTagCTA = ({ onClick }: AddNewTagCTAProps) => {
  const [t] = useTranslation();

  return (
    <Grid gap="space20" padding="space16">
      <Grid>
        <Text fontWeight="semibold" fontSize="basic" lineHeight="paragraph">
          {t('documentTags.field.addNew.title')}
        </Text>
        <Text fontSize="basic" lineHeight="paragraph">
          {t('documentTags.field.addNew.subtitle')}
        </Text>
      </Grid>
      <Button color="gray" variant="secondary" onPointerDown={onClick}>
        {t('documentTags.field.addNew.button')}
      </Button>
    </Grid>
  );
};
