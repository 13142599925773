import { Box, Grid } from '@candisio/design-system';
import { MENU_SETTINGS_ICON, NavIconProps } from './hooks/useNavigationIcons';
import { NavigationSettings } from './MenuItems/NavigationSettings/NavigationSettings';
import { NavigationIcon } from './NavigationIcon';

const Divider = () => {
  return (
    <Grid padding="space14 0">
      <Box width="100%" height={1} background="gray250"></Box>
    </Grid>
  );
};

const DIVIDER_POSITIONS = [0, 3, 7];

interface NavigationIconsProps {
  icons: NavIconProps[];
  isNavigationOpen: boolean;
}

export const NavigationIcons = ({
  icons,
  isNavigationOpen,
}: NavigationIconsProps) => {
  return (
    <Grid paddingLeft="space8" gap="space8">
      {icons.map((icon, index) => {
        const IconComponent =
          icon.icon === MENU_SETTINGS_ICON
            ? NavigationSettings
            : NavigationIcon;

        return (
          <Box key={icon.label}>
            <IconComponent {...icon} expanded={isNavigationOpen} />
            {DIVIDER_POSITIONS.includes(index) && <Divider />}
          </Box>
        );
      })}
    </Grid>
  );
};
