import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';

export const CostObject = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="bookings[0].costObject.inputValue"
      label={t('document.requestApproval.inputs.costObject.label', {
        context: getTranslationContext(),
      })}
      readOnly
    />
  );
};
