import gql from 'graphql-tag';

export const cardIssuerCardData = gql`
  fragment CardIssuerCardData on CardIssuerCard {
    id
    team {
      id
    }
    cardholder {
      id
      firstName
      lastName
      avatarUrl
      membershipEmail
      membershipId
    }
    availableBalance {
      value
      currency
      precision
    }
    limit {
      value
      currency
      precision
    }
    type
    status
    label
    purpose
    refNum
    brand
    pendingActions {
      name
    }
  }
`;

export const getPageBasedCardIssuerCardsQuery = gql`
  query getPageBasedCardIssuerCards(
    $input: CardIssuerPageBasedPaginationInput!
    $queries: CardIssuerCardsQueryInput
    $filters: CardIssuerCardsForCardManagerFiltersInput
    $sortBy: CardIssuerCardsSortInput
  ) {
    getPageBasedCardIssuerCards(
      input: $input
      queries: $queries
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        ...CardIssuerCardData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${cardIssuerCardData}
`;

export const getCardsInfiniteScrollPaginationQuery = gql`
  query getCardsInfiniteScrollPagination(
    $input: CardIssuerPageBasedPaginationInput!
    $queries: CardIssuerCardsQueryInput
    $filters: CardIssuerCardsForCardManagerFiltersInput
    $sortBy: CardIssuerCardsSortInput
  ) {
    getPageBasedCardIssuerCards(
      input: $input
      queries: $queries
      filters: $filters
      sortBy: $sortBy
    )
      @connection(
        key: "getCardsInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        ...CardIssuerCardData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${cardIssuerCardData}
`;

export const getSpendOverviewInsightsQuery = gql`
  query getSpendOverviewInsights(
    $input: SpendOverviewInsightsInput
    $filters: SpendOverviewInsightsFilterInput
    $dateRangeFilters: SpendOverviewInsightsDateFilterInput
  ) {
    getSpendOverviewInsights(
      input: $input
      filters: $filters
      dateRangeFilters: $dateRangeFilters
    ) {
      details {
        dateLabel
        spentAmount {
          amount
          currency
          precision
        }
      }
    }
  }
`;

export const merchantNamesInfinteScrollPagination = gql`
  query merchantNamesInfinteScrollPagination(
    $input: CardIssuerPageBasedPaginationInput!
    $queries: CardIssuerMerchantsQueryInput
    $sortBy: CardIssuerMerchantsSortInput
  ) {
    getPageBasedCardIssuerMerchants(
      input: $input
      queries: $queries
      sortBy: $sortBy
    )
      @connection(
        key: "merchantNamesInfinteScrollPagination"
        filter: ["queries", "sortBy"]
      ) {
      records {
        id
        name
        logoUrl
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const merchanNamePageBasedPagination = gql`
  query merchanNamePageBasedPagination(
    $input: CardIssuerPageBasedPaginationInput!
    $filters: CardIssuerMerchantsFiltersInput
    $sortBy: CardIssuerMerchantsSortInput
  ) {
    getPageBasedCardIssuerMerchants(
      input: $input
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        name
        logoUrl
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const getTransactionsInsightsQuery = gql`
  query getTransactionsInsights(
    $input: TransactionsInsightsInput
    $filters: TransactionsInsightsFilterInput
    $dateRangeFilters: CardIssuerTransactionDateFilterInput
  ) {
    getTransactionsInsights(
      input: $input
      filters: $filters
      dateRangeFilters: $dateRangeFilters
    ) {
      cardholders {
        aggregate {
          count
        }
        cardholder {
          id
          membershipId
          membershipEmail
          firstName
          lastName
          avatarUrl
        }
        cards {
          card {
            refNum
            label
          }
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const getCountCardsQuery = gql`
  query getCountCards($filters: CardIssuerCardsFiltersInput) {
    countCards(filters: $filters) {
      total
    }
  }
`;

export const getCountCardRequestsQuery = gql`
  query getCountCardRequests($filters: CardIssuerCardRequestsFiltersInput) {
    countCardRequests(filters: $filters) {
      total
      details {
        IssueNewVirtualCardRequest {
          count
          byStatus {
            PENDING
          }
        }
        IssueNewSingleUseVirtualCardRequest {
          count
          byStatus {
            PENDING
          }
        }
        IssueNewPhysicalCardRequest {
          count
          byStatus {
            PENDING
          }
        }
        ChangeCardLimitRequest {
          count
          byStatus {
            PENDING
          }
        }
      }
    }
  }
`;

export const getVendorSuggestionsForRecurringPaymentsQuery = gql`
  query getVendorSuggestionsForRecurringPayments(
    $input: GetVendorSuggestionsForRecurringPaymentsInput
    $filters: GetVendorSuggestionsForRecurringPaymentsFilterInput
    $dateRangeFilters: GetVendorSuggestionsForRecurringPaymentsDateFilterInput
    $sortBy: GetVendorSuggestionsForRecurringPaymentsSortInput
  ) {
    getVendorSuggestionsForRecurringPayments(
      input: $input
      filters: $filters
      dateRangeFilters: $dateRangeFilters
      sortBy: $sortBy
    ) {
      records {
        hasTransactions
        contactIds
        vendor {
          id
          name
          category
          logoUrl
          isIgnored
        }
        sum {
          details {
            value {
              amount
              currency
              precision
            }
            count
            exchangeRate {
              rate
              valueInBaseCurrency {
                amount
                currency
                precision
              }
            }
          }
          aggregate {
            count
            value {
              amount
              currency
              precision
            }
            exchangeRate {
              rate
              valueInBaseCurrency {
                amount
                currency
                precision
              }
            }
          }
        }
        maxMonthlyInvoiceAmount {
          amount
          currency
          precision
        }
      }
      linkedCount
      pageInfo {
        totalCount
      }
    }
  }
`;

export const getVendorsForRecurringPaymentsQuery = gql`
  query getVendorsForRecurringPayments(
    $input: GetVendorsForRecurringPaymentsInput
    $filters: GetVendorsForRecurringPaymentsFilterInput
    $sortBy: GetVendorsForRecurringPaymentsSortInput
  ) {
    getVendorsForRecurringPayments(
      input: $input
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        id
        vendor {
          id
          name
          logoUrl
        }
        linkedCards {
          id
          transactionState
          card {
            ...CardIssuerCardData
          }
        }
      }
      pageInfo {
        totalCount
        currentPage
        pageCount
        pageSize
      }
    }
  }
  ${cardIssuerCardData}
`;

export const setRecurringPaymentVendorIgnoredStateMutation = gql`
  mutation setRecurringPaymentVendorIgnoredState(
    $input: SetRecurringPaymentVendorIgnoredStateInput!
  ) {
    setRecurringPaymentVendorIgnoredState(input: $input) {
      id
      isIgnored
    }
  }
`;

export const linkCardForRecurringPaymentVendorMutation = gql`
  mutation linkCardForRecurringPaymentVendor(
    $input: LinkCardForRecurringPaymentVendorInput!
  ) {
    linkCardForRecurringPaymentVendor(input: $input) {
      id
    }
  }
`;

export const getVendorForRecurringPaymentsByIdQuery = gql`
  query getVendorForRecurringPaymentsById($id: ID!) {
    getVendorForRecurringPaymentsById(id: $id) {
      id
      linkedCards {
        id
        transactionState
        card {
          id
          brand
          status
          type
          label
          refNum
          cardholder {
            id
            membershipId
            firstName
            lastName
          }
        }
      }
      vendor {
        id
        name
        logoUrl
        category
        source
      }
    }
  }
`;

export const getVendorCardsSuggestionsForRecurringPaymentsQuery = gql`
  query getVendorCardsSuggestionsForRecurringPayments($id: ID!) {
    getVendorCardsSuggestionsForRecurringPayments(id: $id) {
      suggestions {
        card {
          id
          brand
          type
          label
          refNum
          cardholder {
            id
            membershipId
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const updateCardForRecurringPaymentVendorMutation = gql`
  mutation updateCardForRecurringPaymentVendor(
    $input: UpdateCardForRecurringPaymentVendorInput!
  ) {
    updateCardForRecurringPaymentVendor(input: $input) {
      id
    }
  }
`;

export const getVendorCardholdersSuggestionsForRecurringPaymentsQuery = gql`
  query getVendorCardholdersSuggestionsForRecurringPayments($id: ID!) {
    getVendorCardholdersSuggestionsForRecurringPayments(id: $id) {
      suggestions {
        cardholder {
          firstName
          lastName
          membershipId
        }
      }
    }
  }
`;

export const deleteCardForRecurringPaymentVendorMutation = gql`
  mutation deleteCardForRecurringPaymentVendor(
    $input: DeleteCardForRecurringPaymentVendorInput!
  ) {
    deleteCardForRecurringPaymentVendor(input: $input) {
      id
    }
  }
`;

export const createCreditCardGeneralLedgerAccountFieldItemsQuery = gql`
  query createCreditCardGeneralLedgerAccountFieldItems(
    $readableName: String
    $input: BookingAccountPaginationInput
  ) {
    creditCardGeneralLedgerAccountsPagination: bookingAccountPagination(
      input: $input
      filters: { isArchived: false }
      queries: { readableName: $readableName }
    )
      @connection(
        key: "creditCardGeneralLedgerAccountsPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        readableName
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const createCreditCardContactFieldItemsQuery = gql`
  query createCreditCardContactFieldItems(
    $name: String
    $input: ContactsPaginationInput
  ) {
    createCreditCardContactsPagination: contactsPagination(
      input: $input
      queries: { name: $name }
    )
      @connection(
        key: "createCreditCardContactsPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        name {
          value
        }
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const createCustomRecurringPaymentVendorMutation = gql`
  mutation createCustomRecurringPaymentVendor(
    $input: CreateCustomRecurringPaymentVendorInput!
  ) {
    createCustomRecurringPaymentVendor(input: $input) {
      id
    }
  }
`;

export const creditCardsAccountingInfoFieldItemsQuery = gql`
  query creditCardsAccountingInfoFieldItems {
    taxCodes: bookingKeysActive {
      id
      taxCode
      readableName
    }
    workflows: workflowTemplates(input: { isArchived: false }) {
      id
      name
      steps {
        resolvers {
          id
        }
      }
    }
    approvers {
      id
      name
      avatarUrl
    }
    getOrgMembersAbsence {
      fromDate
      membershipId
      note
      status
      toDate
    }
  }
`;

export const updateCardIssuerOrganizationSettingsMutation = gql`
  mutation updateCardIssuerOrganizationSettings(
    $input: UpdateCardIssuerOrganizationSettingsInput!
  ) {
    updateCardIssuerOrganizationSettings(input: $input) {
      tradeName
    }
  }
`;
