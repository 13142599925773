import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const nameSchema = z.object({
  name: z.string(),
});

export const nameErrorMessages: ErrorMessages<typeof nameSchema> = {
  name: { label: 'organizations.inputs.title' },
};

export type NameFormData = z.infer<typeof nameSchema>;
