import {
  DocumentCurrency,
  DocumentStatus,
  ExportableEntityInfo,
  ExportableEntityType,
  ProvisionFragment,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { DocumentExportStatus, ExportEntityRow } from '../../types';

export const toProvisionExportEntity =
  ({
    isDisabled,
    exportableEntitiesInfo,
  }: {
    isDisabled: boolean;
    exportableEntitiesInfo: ExportableEntityInfo[];
  }) =>
  (provision: ProvisionFragment): ExportEntityRow => {
    let exportableEntityInfo: ExportableEntityInfo | undefined = undefined;
    if (exportableEntitiesInfo && exportableEntitiesInfo.length > 0) {
      exportableEntityInfo = exportableEntitiesInfo.find(
        entityInfo => entityInfo.id === provision.id
      );
    }

    return {
      type: ExportableEntityType.Provision,
      id: provision.id,
      contact: provision.contact.name,
      amount: { amount: 0, currency: DocumentCurrency.Eur, precision: 0 },
      netAmount: provision.netAmount,
      invoiceDate: new Date(provision.deliveryDate),
      deliveryDate: new Date(provision.deliveryDate),
      invoiceNumber: '',
      paymentState: { isPaid: false },
      hasDocument: false,
      generalLedgerAccount: provision.bookings?.map(b => b.glAccount),
      account: provision.bookings?.map(b => b.account),
      provisionEntityType: provision.entity.type,
      provisionEntityId: provision.entity.id,
      provisionStatus: provision.entity.status as
        | TransactionStatus
        | DocumentStatus,
      isDisabled,
      exportStatus: generateExportStatus(
        exportableEntityInfo,
        ExportableEntityType.Provision
      ),
    };
  };

export const generateExportStatus = (
  exportableEntityInfo: ExportableEntityInfo | undefined,
  type: ExportableEntityType
): DocumentExportStatus | null | undefined => {
  if (exportableEntityInfo) {
    return {
      status: exportableEntityInfo?.status,
      details: exportableEntityInfo?.details
        ? exportableEntityInfo?.details.filter(notEmpty)
        : null,
      warnings: exportableEntityInfo?.warnings
        ? exportableEntityInfo?.warnings.filter(notEmpty)
        : null,
      entityType: type,
      exportMethod: exportableEntityInfo?.exportMethod,
      ...(exportableEntityInfo?.exportError
        ? {
            exportError: exportableEntityInfo?.exportError,
          }
        : null),
    };
  }

  return null;
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
