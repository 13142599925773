import { IconProps } from '@candisio/design-system';
import {
  useCountOpenApprovalsQuery,
  useCountPayableDocumentsQuery,
} from 'generated-types/graphql.types';
import {
  useCountDocumentsProcessing,
  useCountTransactionsAndDocumentsInbox,
} from 'hooks/useCounters';
import { useExportableEntitiesCounter } from 'hooks/useExportableEntitiesCounter';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { CreditCardsSpentOverviewTrackingEvents } from 'providers/AnalyticsProvider/events/CreditCardsSpentOverviewTrackingEvents';
import { travelExpenseEvents } from 'providers/AnalyticsProvider/events/TravelAndExpenses';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_PAYABLE_DOCUMENT_COUNT } from 'views/consts';
import { useInitialExportUrl } from 'views/Integrations/Export/Manifest';
import { useReimbursementsCount } from 'views/Reimbursement/hooks/useReimbursementsCount';
import { openReimbursementStatuses } from 'views/Reimbursement/Lists/OpenReimbursementListContainer';

export type NavIconProps = {
  icon: IconProps['icon'];
  label?: string;
  route?: string;
  count?: number;
  countLoading?: boolean;
  showMenuIcon?: boolean;
  onClick?: () => void;
  pulseIndicator?: ReactNode;
};

type NavIconPropsReturnType = {
  icons: NavIconProps[];
  intercomIcon: NavIconProps;
  notificationsIcon: NavIconProps;
};

export const MENU_SETTINGS_ICON = 'menuSettings';

export const useNavigationIcons = (): NavIconPropsReturnType => {
  const organization = useFullOrganization();
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const { track } = useAnalytics();
  const { showAllExtraFeatures } = useCreditCardsSetup();
  const { canUseReimbursement } = useReimbursement();
  const currentUser = useCurrentUser();

  const { ft, show } = useFeatureToggle();
  const exportViewDef = useInitialExportUrl();

  const { isAdmin, isAccountant, isCardManager, isRequester, hasRoles } =
    useUserRoles();

  const creditCardsSetup = useCreditCardsSetup();
  const { count: documentsProcessingCount, loading: loadingProcessing } =
    useCountDocumentsProcessing({
      fetchPolicy: 'cache-first',
    });

  const { totalCount: inboxTotalCount, loading: loadingInbox } =
    useCountTransactionsAndDocumentsInbox({
      processingDocuments: documentsProcessingCount,
      loadingProcessing,
      featureFlag: creditCardsSetup.isAddonBought,
    });

  const { data: approvalCount, loading: approvalCountLoading } =
    useCountOpenApprovalsQuery({
      fetchPolicy: 'cache-first',
    });

  const { data: paymentsCount, loading: paymentsCountLoading } =
    useCountPayableDocumentsQuery({
      skip: !(isAdmin || isAccountant),
      fetchPolicy: 'cache-first',
    });

  const payableDocumentsCount = paymentsCount?.countPayableDocuments.count
    ? Math.min(
        paymentsCount?.countPayableDocuments.count,
        MAX_PAYABLE_DOCUMENT_COUNT
      )
    : undefined;

  const {
    readyToExportEntitiesCount,
    loading: loadingExportableEntitiesCount,
  } = useExportableEntitiesCounter({
    fetchPolicy: 'cache-first',
    skip: !(isAdmin || isAccountant),
  });

  const {
    count: countReimbursements,
    loadingCount: loadingCountReimbursements,
  } = useReimbursementsCount({
    filters: {
      statuses: openReimbursementStatuses,
      targetMembershipIds: currentUser?.id ? [currentUser?.id] : undefined,
    },
  });

  const showCreditCardMenuItem = Boolean(
    organization?.creditCardsDetails && showAllExtraFeatures
  );

  const icons: NavIconProps[] = [
    {
      icon: 'menuDashboard',
      label: t('menuLabels.dashboard'),
      route: Routes.DASHBOARD,
      showMenuIcon: hasRoles,
    },
    {
      icon: 'menuInbox',
      label: t('menuLabels.inbox'),
      route: Routes.INBOX,
      count: inboxTotalCount || undefined,
      countLoading: loadingProcessing || loadingInbox,
      showMenuIcon: hasRoles,
      onClick: () =>
        track(CreditCardsSpentOverviewTrackingEvents.INSIGHTS_TAB_CLICKED),
    },
    {
      icon: 'menuApprovals',
      label: t('menuLabels.approvals'),
      route: Routes.APPROVALS,
      count: approvalCount?.countOpenApprovals.count || undefined,
      countLoading: approvalCountLoading,
      showMenuIcon: hasRoles,
    },
    {
      icon: 'menuPayments',
      label: t('menuLabels.payments'),
      route: Routes.PAYMENTS,
      count: payableDocumentsCount,
      countLoading: paymentsCountLoading,
      showMenuIcon: show(ft.SEPA_XML) ? isAccountant || isAdmin : false,
    },
    {
      icon: 'menuExport',
      label: t('menuLabels.export'),
      route: exportViewDef.url || Routes.EXPORTS,
      count: readyToExportEntitiesCount || undefined,
      countLoading: loadingExportableEntitiesCount,
      showMenuIcon: isAccountant || isAdmin,
    },
    {
      icon: 'menuArchive',
      label: t('menuLabels.archive'),
      route: Routes.ARCHIVE,
      showMenuIcon: hasRoles,
    },
    {
      icon: 'menuTravel',
      label: t('menuLabels.travelAndExpense'),
      route: Routes.REIMBURSEMENT,
      count: countReimbursements,
      countLoading: loadingCountReimbursements,
      showMenuIcon: canUseReimbursement && hasRoles,
      onClick: () => track(travelExpenseEvents.DASHBOARD_OPENED),
    },
    {
      icon: 'menuCreditCards',
      label: t('menuLabels.creditCards'),
      route: Routes.CREDIT_CARDS_DASHBOARD,
      showMenuIcon:
        showCreditCardMenuItem && (isAccountant || isAdmin || isCardManager),
    },
    {
      icon: 'menuContacts',
      label: t('menuLabels.contacts'),
      route: Routes.CONTACTS,
      showMenuIcon: isAccountant || isAdmin || isRequester,
    },
    {
      icon: MENU_SETTINGS_ICON,
      label: t('menuLabels.settings'),
      route: `${Routes.SETTINGS}/organization`,
      showMenuIcon: isAccountant || isAdmin,
    },
  ];

  const intercomIcon: NavIconProps = {
    icon: 'menuIntercomAlternative',
    label: t('menuLabels.support'),
    route: Routes.CUSTOMER_SUPPORT,
    showMenuIcon: hasRoles,
  };

  const notificationsIcon: NavIconProps = {
    icon: 'menuNotifications',
    label: t('menuLabels.notifications'),
    route: Routes.NOTIFICATIONS,
    showMenuIcon: hasRoles,
  };

  return {
    icons,
    intercomIcon,
    notificationsIcon,
  };
};
