import { Grid, Link, Text } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { useTranslation } from 'react-i18next';
import { Team } from './hooks/useTeamInvolvements';
import { TeamInvolvements } from './TeamInvolvements/TeamInvolvements';

export const INVOLVEMENT_ERROR_MESSAGE_ELEMENT_ID = 'involvement-error-message';

export const scrollToInvolvementInfo = () => {
  const ele = document.getElementById(
    INVOLVEMENT_ERROR_MESSAGE_ELEMENT_ID
  ) as HTMLTextAreaElement;

  if (ele) {
    ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

export interface MembershipInvolvementProps {
  errorMessage?: string;
  counter: {
    costCenterArchivedCount: number;
    costCenterCount: number;
    creditCardCount: number;
    notifyPersonCount: number;
    responsiblePersonCount: number;
    workflowTemplatesArchivedCount: number;
    workflowTemplatesCount: number;
    teamsCount: number;
  };
  url: {
    costCenterArchivedUrl: string;
    costCenterUrl: string;
    creditCardsUrl: string;
    notifyPersonUrl: string;
    responsiblePersonUrl: string;
    usersSubstitutedByTeamMemberUrls: {
      url: string;
      substitutedPersonName?: string;
    }[];
    workflowTemplatesUrl: string;
    workflowTemplatesArchivedUrl: string;
  };
  additionalInfo: {
    teams: Team[];
  };
}

export const MembershipInvolvement = ({
  counter,
  url,
  errorMessage,
  additionalInfo,
}: MembershipInvolvementProps) => {
  const [t] = useTranslation();
  const { isCardManager } = useUserRoles();

  const { teams } = additionalInfo ?? {};

  const {
    costCenterArchivedCount,
    costCenterCount,
    creditCardCount,
    notifyPersonCount,
    responsiblePersonCount,
    workflowTemplatesArchivedCount,
    workflowTemplatesCount,
    teamsCount,
  } = counter;

  const {
    costCenterArchivedUrl,
    costCenterUrl,
    creditCardsUrl,
    notifyPersonUrl,
    responsiblePersonUrl,
    usersSubstitutedByTeamMemberUrls,
    workflowTemplatesArchivedUrl,
    workflowTemplatesUrl,
  } = url;

  const costCenterText = t(
    'settings.teamMembers.form.involvement.links.costCenters',
    { count: costCenterCount }
  );

  const costCenterArchivedText = t(
    'settings.teamMembers.form.involvement.links.costCentersArchived',
    { count: costCenterArchivedCount }
  );

  const notifyPersonText = t(
    'settings.teamMembers.form.involvement.links.notifyPerson',
    { count: notifyPersonCount }
  );

  const responsiblePersonText = t(
    'settings.teamMembers.form.involvement.links.responsiblePerson',
    { count: responsiblePersonCount }
  );

  const workflowTemplatesText = t(
    'settings.teamMembers.form.involvement.links.workflowTemplates',
    { count: workflowTemplatesCount }
  );

  const workflowTemplatesArchivedText = t(
    'settings.teamMembers.form.involvement.links.workflowTemplatesArchived',
    { count: workflowTemplatesArchivedCount }
  );

  const creditCardInvolvementText = t(
    'settings.teamMembers.form.involvement.links.creditCard',
    { count: creditCardCount }
  );

  const showTeamInvolvement = Boolean(teams.length && teamsCount);

  return (
    <Grid
      width="fit-content"
      borderLeft={errorMessage ? 'space4 solid red500' : undefined}
      paddingLeft={errorMessage ? 'space12' : undefined}>
      {errorMessage && (
        <Text
          fontSize="large"
          color="red500"
          id={INVOLVEMENT_ERROR_MESSAGE_ELEMENT_ID}>
          {errorMessage}
        </Text>
      )}
      {costCenterCount > 0 && (
        <Link fontSize="basic" href={costCenterUrl} external>
          {costCenterText}
        </Link>
      )}
      {costCenterArchivedCount > 0 && (
        <Link fontSize="basic" href={costCenterArchivedUrl} external>
          {costCenterArchivedText}
        </Link>
      )}
      {notifyPersonCount > 0 && (
        <Link fontSize="basic" href={notifyPersonUrl} external>
          {notifyPersonText}
        </Link>
      )}
      {responsiblePersonCount > 0 && (
        <Link fontSize="basic" href={responsiblePersonUrl} external>
          {responsiblePersonText}
        </Link>
      )}
      {workflowTemplatesCount > 0 && (
        <Link fontSize="basic" href={workflowTemplatesUrl} external>
          {workflowTemplatesText}
        </Link>
      )}
      {workflowTemplatesArchivedCount > 0 && (
        <Link fontSize="basic" href={workflowTemplatesArchivedUrl} external>
          {workflowTemplatesArchivedText}
        </Link>
      )}
      {creditCardCount > 0 &&
        (isCardManager ? (
          <Link fontSize="basic" href={creditCardsUrl} external>
            {creditCardInvolvementText}
          </Link>
        ) : (
          <Text fontSize="basic">
            {t('settings.teamMembers.form.involvement.creditCardInfo', {
              count: creditCardCount,
            })}
          </Text>
        ))}
      {showTeamInvolvement && <TeamInvolvements teams={teams} />}
      {usersSubstitutedByTeamMemberUrls?.map(data => {
        return (
          <Link key={data.url} fontSize="basic" href={data.url} external>
            {t('settings.teamMembers.form.involvement.links.substitute', {
              substitutedPersonName: data.substitutedPersonName,
            })}
          </Link>
        );
      })}
    </Grid>
  );
};
