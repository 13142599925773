import { Card, Grid, Text } from '@candisio/design-system';
import { CardStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useGetCardById } from '../utils';

interface InfoCardIsLockedByPinProps {
  cardId: string;
}

export const InfoCardIsLockedByPin = ({
  cardId,
}: InfoCardIsLockedByPinProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { card } = useGetCardById({ cardId });

  if (!card) {
    return null;
  }

  const cardStatus = card.status === CardStatus.LockedPin;

  if (!cardStatus) {
    return null;
  }

  return (
    <Card background="yellowbg">
      <Grid gap="space4">
        <Text fontSize="basic" fontWeight="semibold">
          {t('drawer.cardLockedByPin.title')}
        </Text>
        <Text fontSize="basic">{t('drawer.cardLockedByPin.explanation')}</Text>
      </Grid>
    </Card>
  );
};
