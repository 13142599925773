import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { EmptyReimbursementDetailsCard } from './EmptyReimbursementDetailsCard';
import { LoadingReimbursementSection } from './LoadingReimbursementSection';
import { ReimbursementDetailsCard } from './ReimbursementDetailsCard';

export interface ReimbursementSectionProps {
  loading: boolean;
  totalAmount?: string;
  status?: ReimbursementCaseStatus;
  title?: string;
}

export const ReimbursementSection = ({
  loading,
  status,
  title,
  totalAmount,
}: ReimbursementSectionProps) => {
  const hasReimbursementDetails = !!title || !!totalAmount;

  if (loading) {
    return <LoadingReimbursementSection />;
  }

  if (!hasReimbursementDetails) {
    return <EmptyReimbursementDetailsCard />;
  }

  return (
    <ReimbursementDetailsCard
      title={title}
      totalAmount={totalAmount}
      status={status ?? ReimbursementCaseStatus.Draft}
    />
  );
};
