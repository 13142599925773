import { Maybe, WorkflowTemplate } from 'generated-types/graphql.types';

interface WorkTemplateData {
  defaultFormValues: any;
}

export const useWorkTemplateData = (
  template: Maybe<WorkflowTemplate>
): WorkTemplateData => {
  const defaultFormValues = template
    ? {
        name: template?.name ?? undefined,
        description: template?.description ?? undefined,
        steps:
          template?.steps &&
          template.steps.map(step => {
            return {
              approvers: step.resolvers
                ? step.resolvers.map(resolver => resolver?.id)
                : [],
              conditions: step.conditions
                ? step.conditions.map(condition => {
                    return {
                      operator: condition.operator ?? undefined,
                      values: condition.values
                        ? Number(condition.values[0])
                        : undefined,
                    };
                  })
                : [],
            };
          }),
      }
    : {
        name: undefined,
        description: '',
        steps: [
          {
            approvers: [],
            conditions: [],
          },
        ],
      };

  return { defaultFormValues };
};
