import {
  TagSortInput,
  TagSortOrder,
  useTagsQuery,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

export enum TagsSortBy {
  alphabetically = 'alphabetically',
  newestFirst = 'newestFirst',
  mostUsed = 'mostUsed',
}

export const mapSortToGql = (sortBy: TagsSortBy): TagSortInput => {
  if (sortBy === TagsSortBy.mostUsed) {
    return {
      documentCount: TagSortOrder.Desc,
    };
  }

  if (sortBy === TagsSortBy.newestFirst) {
    return {
      createdAt: TagSortOrder.Desc,
    };
  }

  return {
    name: TagSortOrder.Asc,
  };
};

type UseDocumentTagsOptions = {
  search?: string;
  sortBy?: TagsSortBy;
};

export type DocumentTags = ReturnType<typeof useDocumentTags>['tags'];

export const useDocumentTags = ({
  search,
  sortBy = TagsSortBy.alphabetically,
}: UseDocumentTagsOptions) => {
  const [searchParams] = useSearchParams();
  const isArchived = searchParams.get('archived') === 'true';

  const [searchValue, setSearchValue] = useState(search);
  const handleDebounceSearch = useMemo(() => {
    return debounce(setSearchValue, DEFAULT_DEBOUNCE_TIME, {
      trailing: true,
    });
  }, []);

  const { data: activeTags, loading: loadingActiveTags } = useTagsQuery({
    variables: {
      input: {
        isActive: true,
        includeDocumentCount: true,
        search: searchValue,
        sort: mapSortToGql(sortBy),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: archivedTags, loading: loadingArchivedTags } = useTagsQuery({
    variables: {
      input: {
        isActive: false,
        includeDocumentCount: true,
        search: searchValue,
        sort: mapSortToGql(sortBy),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const tagsToDisplay = useMemo(() => {
    return isArchived ? archivedTags?.tags ?? [] : activeTags?.tags ?? [];
  }, [isArchived, archivedTags, activeTags]);

  const countLoading = loadingActiveTags || loadingArchivedTags;
  const activeCount = activeTags?.tags?.length ?? 0;
  const archivedCount = archivedTags?.tags?.length ?? 0;

  return {
    activeCount,
    archivedCount,
    countLoading,
    handleDebounceSearch,
    tags: tagsToDisplay,
  };
};
