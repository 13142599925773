import { ContactTableColumnKeys } from '../ContactTableDS';

type GetAvailableColumnsParams = {
  columns: ContactTableColumnKeys[];
  showAccountsReceivableNumberColumn: boolean;
  showCreateTransferColumn: boolean;
  showPaymentMediumColumn: boolean;
};

export const getAvailableColumns = ({
  showAccountsReceivableNumberColumn,
  showCreateTransferColumn,
  showPaymentMediumColumn,
  columns,
}: GetAvailableColumnsParams) => {
  const columnConditions: Partial<Record<ContactTableColumnKeys, boolean>> = {
    accountsReceivableNumber: showAccountsReceivableNumberColumn,
    createTransfer: showCreateTransferColumn,
    paymentMedium: showPaymentMediumColumn,
    dueDateOffset: false, // TODO: add logic for showing dueDate
  };

  return columns.filter(column =>
    columnConditions.hasOwnProperty(column) ? columnConditions[column] : true
  );
};
