import {
  DocumentBookingAssociation,
  Maybe,
  PurchaseOrder,
  useGetDocumentBookingAssociationsByInvoiceIdQuery,
  useGetSapPurchaseOrdersQuery,
} from 'generated-types/graphql.types';
import { getThreeWayMatchResultNew } from 'utils/three-way-match';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { ThreeWayMatchRowData } from '../types';
import {
  getBookingsData,
  getGoodsReceiptsData,
  getPurchaseOrdersData,
} from '../utils';

type ThreeWayMatchProps = {
  isLoading?: boolean;
  result: ThreeWayMatchRowData[];
};

export const useGetThreeWayMatchData = (
  documentId?: string
): ThreeWayMatchProps => {
  const { data: sapPurchaseOrderDara, loading: loadingPOs } =
    useGetSapPurchaseOrdersQuery({
      variables: { id: documentId ?? '' },
      skip: !documentId,
    });

  const { data: bookingFormData } = useBookingsFormContext();
  const { data: bookingAssociations, loading: loadingBookingAssoc } =
    useGetDocumentBookingAssociationsByInvoiceIdQuery({
      variables: { invoiceId: documentId || '' },
      skip: !Boolean(documentId),
    });

  const isLoading = loadingPOs || loadingBookingAssoc;

  if (isLoading) {
    return {
      isLoading: true,
      result: [],
    };
  }

  const purchaseOrders = (sapPurchaseOrderDara?.getDocument?.purchaseOrders ??
    []) as Maybe<PurchaseOrder>[];

  if (!purchaseOrders?.length) {
    return { result: [] };
  }

  const bookingAssociationsData =
    (bookingAssociations?.getDocumentBookingAssociationsByInvoiceId ??
      []) as DocumentBookingAssociation[];

  const purchaseOrdersInfo = bookingAssociationsData.flatMap(
    bookingAssociation =>
      bookingAssociation?.purchaseOrdersInfo?.map(po => ({
        ...po,
        bookingId: bookingAssociation?.bookingId,
      })) ?? []
  );

  const goodsReceiptsInfo = bookingAssociationsData.flatMap(
    bookingAssociation =>
      bookingAssociation?.goodsReceiptsInfo?.map(gr => ({
        ...gr,
        bookingId: bookingAssociation?.bookingId,
      }))
  );

  const bookingsData = getBookingsData(
    bookingFormData,
    bookingAssociationsData
  );

  const purchaseOrdersData = getPurchaseOrdersData(
    purchaseOrders,
    purchaseOrdersInfo
  );

  const goodsReceiptsData = getGoodsReceiptsData(
    purchaseOrders,
    goodsReceiptsInfo
  );

  const result = getThreeWayMatchResultNew({
    bookingsData,
    purchaseOrdersData,
    goodsReceiptsData,
  });

  return {
    result,
  };
};
