import { Modal } from '@candisio/design-system';
import { ChangeEvent, createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachFileFromDiskButton } from './AttachButtons/AttachFileFromDiskButton';
import { AttachMenuButton } from './AttachButtons/AttachMenuButton';
import { AttachmentsTable } from './AttachmentsTable/AttachmentsTable';

interface AttachmentSectionProps {
  documentId: string;
  isAttachingFiles: boolean;
  onAttachFiles: (files: File[]) => void;
  canAttachFiles: {
    fromCandis: boolean;
    fromDisk: boolean;
  };
}

export const AttachmentSection = ({
  canAttachFiles,
  documentId,
  isAttachingFiles,
  onAttachFiles,
}: AttachmentSectionProps) => {
  const [t] = useTranslation();
  const fileUploadRef = createRef<HTMLInputElement>();
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);

  const onUploadClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const onFilesChanged = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      onAttachFiles(Array.from(ev.target.files));
      const ele = document.querySelector<HTMLInputElement>(
        '#attachment-upload-input'
      );

      if (ele) {
        ele.value = '';
      }
    }
  };

  return (
    <>
      {canAttachFiles?.fromCandis ? (
        <>
          <AttachMenuButton
            isAttachingFiles={isAttachingFiles}
            items={[
              {
                id: 'fromCandis',
                label: t('document.attachments.attachPdfFromDisk'),
                onAction: () => {
                  onUploadClick();
                },
              },
              {
                id: 'fromDisk',
                label: t('document.attachments.attachPdfFromCandis'),
                onAction: () => {
                  setAttachmentModalIsOpen(true);
                },
              },
            ]}
            canAttachFiles={canAttachFiles}
          />
          <Modal
            data-testid="attachments-table-modal"
            background="gray200"
            closeLabel={t('common.close')}
            isOpen={attachmentModalIsOpen}
            onClose={() => setAttachmentModalIsOpen(false)}
            padding={0}
            minHeight="65vh"
            scrollDirection="none"
            title={t('document.attachments.attachPdfFromCandis')}
            overflow="hidden"
            width="80vw">
            <AttachmentsTable
              documentId={documentId}
              onDocumentsAttached={() => {
                setAttachmentModalIsOpen(false);
              }}
              onUploadFileClick={() => {
                setAttachmentModalIsOpen(false);
                onUploadClick();
              }}
            />
          </Modal>
        </>
      ) : (
        <AttachFileFromDiskButton
          canAttachFiles={canAttachFiles}
          isAttachingFiles={isAttachingFiles}
          onUploadClick={onUploadClick}
        />
      )}

      <input
        id="attachment-upload-input"
        data-cy="attachment-upload-input"
        type="file"
        accept="application/pdf"
        ref={fileUploadRef}
        onChange={onFilesChanged}
        disabled={!canAttachFiles?.fromCandis && !canAttachFiles?.fromDisk}
        multiple
        hidden
      />
    </>
  );
};
