import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { ActiveMembersQuery, User } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const SelectTeamMembers = ({
  shareableUsers,
}: {
  shareableUsers: ActiveMembersQuery['organizationMemberships'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const toUserFieldItem = (
    user: { __typename?: 'User' | undefined } & Pick<
      User,
      'id' | 'name' | 'roles' | 'avatarUrl'
    >
  ) => {
    return {
      key: user.id,
      children: user.name,
      ...(user.avatarUrl && { avatarUrl: user.avatarUrl }),
    };
  };

  const users = shareableUsers.map(user => toUserFieldItem(user));

  return (
    <HookFormUsersField
      name="sharedWithUsers"
      placeholder={t(
        'dashboard.createNewWidget.sharing.userSelection.placeholder'
      )}
      defaultItems={users}
      dataCy="sharedWithUsers"
    />
  );
};
