import { RecurringPaymentVendorSource } from 'generated-types/graphql.types';
import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useGetVendorCardholderSuggestion } from 'views/CreditCards/hooks/useGetVendorCardholderSuggestion';
import { useGetVendorForRecurringPaymentsById } from 'views/CreditCards/hooks/useGetVendorForRecurringPaymentsById';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from './CreateCreditCardModalDataProvider';
import { useMostActiveCardholders } from './hooks/useMostActiveCardholders';
import { SelectMemberStep } from './SelectMemberStep';
import { SelectMemberStepLoading } from './SelectMemberStepLoading';
import {
  SelectMemberStepOutput,
  SelectMemberStepValues,
} from './utils/selectMemberStepSchema';

export const SelectMememberStepContainer = () => {
  const { vendorId } = useParams<{ vendorId?: string }>();

  const { users, loading } = useUsersFieldOptions({
    status: 'ActiveAndPending',
  });

  const { vendorDetails, loading: loadingVendor } =
    useGetVendorForRecurringPaymentsById({
      vendorId,
      fetchPolicy: 'cache-first',
    });

  const setModalState = useCreateCreditCardModalSetState();

  const { suggestedCardholder, loading: loadingSuggestion } =
    useGetVendorCardholderSuggestion({
      skip:
        loadingVendor ||
        vendorDetails.source === RecurringPaymentVendorSource.ManuallyAdded,
    });

  const { teamMemberId } = useCreateCreditCardModalState();

  const { isLoading, mostActiveCardholders } = useMostActiveCardholders();

  const fieldOptions = {
    loading,
    items: users,
  };

  const isCardForRecurringPayment = Boolean(vendorId);

  if (loadingSuggestion || loadingVendor) {
    return <SelectMemberStepLoading />;
  }

  const handleSubmit = (formValues: SelectMemberStepOutput) => {
    setModalState(prevState => ({
      ...prevState,
      formStep: 'CardTypeAndLimits',
      teamMemberId: formValues.teamMember,
    }));
  };

  const defaultValues: SelectMemberStepValues = {
    teamMember: teamMemberId ?? suggestedCardholder?.membershipId,
  };

  return (
    <SelectMemberStep
      onSubmit={handleSubmit}
      fieldOptions={fieldOptions}
      suggestedCardholder={teamMemberId ? undefined : suggestedCardholder}
      mostActiveCardholders={mostActiveCardholders}
      defaultValues={defaultValues}
      isLoading={isLoading}
      isLoadingSuggestion={loadingSuggestion}
      isCardForRecurringPayment={isCardForRecurringPayment}
    />
  );
};
