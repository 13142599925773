import { Loader } from 'components/Loader';
import { EcmDocumentStatus } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useState } from 'react';
import { useDeleteDocument } from '../../useDeleteDocument';
import { generateDefaultValuesForNewContact } from '../../util/generateDefaultValuesForNewContact';
import { AddContactContainer } from '../AddContact';
import { EditContactContainer } from '../EditContact/EditContactContainer';
import { StorageForm } from './StorageForm';
import { StorageFormValues } from './storageFormSchema';
import { useRemoveEcmDocument } from './useRemoveEcmDocument';
import { useStorageFormFieldOptions } from './useStorageFormFieldOptions';
import { useStorageFormInitialData } from './useStorageFormInitialData';
import { useStoreEcmDocument } from './useStoreEcmDocument';
import { useStoreSensitiveEcmDocument } from './useStoreSensitiveEcmDocument';
import { useUpdateEcmDocument } from './useUpdateEcmDocument';

interface StorageFormContainerProps {
  /** Current document ID */
  documentId: string;
  /** Determines which document data should be fetched/updated, invoice or ECM */
  isInvoice?: boolean;
  /** Called when document is successfully deleted */
  onDeleteDocument?: () => void;
  /** Called when document is successfully stored */
  onStoreDocument?: () => void;
}

/** Handles data fetching for StorageForm component */
export const StorageFormContainer = ({
  documentId,
  isInvoice,
  onDeleteDocument,
  onStoreDocument,
}: StorageFormContainerProps) => {
  const { bdsBought } = useDatev();

  const [isAddContactOpen, setIsAddContactOpen] = useState(false);
  const [isEditContactOpen, setIsEditContactOpen] = useState(false);

  const {
    defaultMetadata,
    defaultValues,
    extractedContact,
    createdBy,
    documentStatus,
    loading: loadingInitialData,
    globalDocumentId,
    tags,
    isDocumentReadOnly,
  } = useStorageFormInitialData({ documentId, isInvoice });

  const fieldOptions = useStorageFormFieldOptions(defaultValues);

  // Only documents uploaded as sensitive contracts have status "New",
  // the rest has status "Stored". Because it's not yet stored, it doesn't
  // have `isSensitive: true`, so we need to rely on the status here.
  const isNewSensitiveContract =
    defaultValues.isSensitive && documentStatus === EcmDocumentStatus.New;

  const { updateEcmDocument, loading: loadingUpdate } = useUpdateEcmDocument({
    documentId,
  });

  const { storeEcmDocument, loading: loadingStore } = useStoreEcmDocument({
    documentId,
  });

  const { storeSensitiveEcmDocument, loading: loadingStoreSensitive } =
    useStoreSensitiveEcmDocument({
      documentId,
      isNewSensitiveContract,
    });

  const { removeEcmDocument } = useRemoveEcmDocument(documentId);
  const { deleteDocument } = useDeleteDocument(documentId);

  const handleSubmit = async (values: StorageFormValues) => {
    const result = isInvoice
      ? await storeEcmDocument(values)
      : isNewSensitiveContract
      ? await storeSensitiveEcmDocument(values)
      : await updateEcmDocument(values);

    if (result.status === 'success') {
      onStoreDocument?.();
    }
  };

  const handleDeleteDocument = async () => {
    const { status } = isInvoice
      ? await deleteDocument()
      : await removeEcmDocument();

    if (status === 'success') {
      onDeleteDocument?.();
    }
  };

  const isLoading =
    loadingInitialData ||
    loadingUpdate ||
    loadingStore ||
    loadingStoreSensitive;

  return isLoading ? (
    <Loader />
  ) : (
    <StorageForm
      key={documentId} // Reset form state when switching document
      createdBy={createdBy}
      defaultMetadata={defaultMetadata}
      defaultValues={defaultValues}
      documentStatus={documentStatus}
      fieldOptions={fieldOptions}
      isInvoice={isInvoice}
      onAddContact={() => setIsAddContactOpen(true)}
      onDeleteDocument={handleDeleteDocument}
      onEditContact={() => {
        setIsEditContactOpen(true);
      }}
      onSubmit={handleSubmit}
      globalDocumentId={globalDocumentId}
      documentId={documentId}
      readOnly={isDocumentReadOnly}
      subform={
        isAddContactOpen ? (
          <AddContactContainer
            defaultValues={generateDefaultValuesForNewContact({
              extractedContact,
              bdsBought,
            })}
            onAddContact={() => setIsAddContactOpen(false)}
            onClose={() => setIsAddContactOpen(false)}
          />
        ) : isEditContactOpen ? (
          <EditContactContainer
            onClose={() => {
              setIsEditContactOpen(false);
            }}
          />
        ) : undefined
      }
      tags={tags}
    />
  );
};
