import { Grid, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const ApiSessionEmptyListItem = () => {
  const [t] = useTranslation();

  return (
    <Grid alignItems="center" gap="space4" key="no-key">
      <Text fontWeight="bold" fontSize="large">
        {t('settings:apiSettings.actionCard.sessionListItem.noSession.title')}
      </Text>
      <Text color="gray600" overflowWrap="anywhere">
        {t(
          'settings:apiSettings.actionCard.sessionListItem.noSession.description'
        )}
      </Text>
    </Grid>
  );
};
