import { TimeFrameInput } from 'generated-types/graphql.types';
import { TimeFrameOption } from '../../types';

export const serializeTimeFrame = (
  timeFrame: Pick<TimeFrameOption, 'year' | 'month'>
): string => {
  return `${timeFrame.year}-${timeFrame.month}`;
};

export const deSerializeTimeFrame = (value: string): TimeFrameOption => {
  const [year, month] = value.split('-');

  return {
    year: Number(year),
    month: Number(month),
    documentIds: [],
    transactionIds: [],
    cardSettlementIds: [],
    provisionIds: [],
    reversalIds: [],
    numberOfExportableEntities: NaN,
    numberOfDocuments: NaN,
    numberOfTxnBasedDocuments: NaN,
    numberOfTxnWithoutDocuments: NaN,
    numberOfSettlements: NaN,
    numberOfDocumentProvisions: NaN,
    numberOfTxnProvisions: NaN,
    numberOfDocumentReversals: NaN,
    numberOfTxnReversals: NaN,
  };
};

/**
 * The `TimeFrameInput` expects months numbered 1-12. Existing CSV exports already
 * have saved timeFrame in this way, so to keep all exports with a timeFrame
 * consistent they too should number the months 1-12 until a migration is done.
 */
export const getTimeFrameInputFromTimeFrameOption = (
  timeFrameOption: TimeFrameOption
): TimeFrameInput => {
  return {
    month: timeFrameOption.month + 1,
    year: timeFrameOption.year,
  };
};
