import {
  fetchPolicy,
  FilterOption,
  PaginationWithSearchFilterHook,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  ContactsInfiniteScrollPaginationQuery,
  ContactsInfiniteScrollPaginationQueryVariables,
  ContactSortField,
  SortDirection,
  useContactsPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { useIsContactsPageWithArchivedTab } from 'views/Contacts/ContactTable/ContactTableDS/hooks/useIsContactsPageWithArchivedTab';
import { contactsInfiniteScrollPaginationQuery } from 'views/Contacts/queries';

export const useContactNameFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const isArchivedContactTable = useIsContactsPageWithArchivedTab();

  const computeVariables = useCallback(
    (inputString?: string): ContactsInfiniteScrollPaginationQueryVariables => ({
      queries: {
        name: inputString,
      },
      filters: {
        isArchived: isArchivedContactTable,
      },
      sortBy: {
        direction: SortDirection.Asc,
        field: ContactSortField.Name,
      },
    }),
    [isArchivedContactTable]
  );

  const {
    data: allContacts,
    loading: allContactsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    ContactsInfiniteScrollPaginationQuery,
    ContactsInfiniteScrollPaginationQueryVariables
  >({
    query: contactsInfiniteScrollPaginationQuery,
    queryRootKey: 'contactsPagination',
    computeVariables,
  });

  const { data: selectedContacts } = useContactsPaginationQuery({
    variables: {
      input: { page: 1, limit: filteredValues.length },
      filters: { id: filteredValues },
    },
    fetchPolicy,
    skip: filteredValues.length === 0 || Boolean(searchStr),
  });

  const byIds = selectedContacts?.contactsPagination.records ?? [];
  const byName = allContacts?.contactsPagination.records ?? [];

  const filterOptions: FilterOption[] = [...byIds, ...byName].map(contact => ({
    label: contact.name.value,
    id: contact.id,
  }));

  return {
    filterOptions,
    handleDebounceSearch,
    loading: allContactsLoading,
    loadMore,
  };
};
