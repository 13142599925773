import { AccountingFieldItem } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/types';
import { ArtistSocialInsuranceCode } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useArtistInsuranceCodeFieldOptions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const mapCodeToTranslation: Record<ArtistSocialInsuranceCode, string> = {
    W: 'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.codes.W',
    BK: 'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.codes.BK',
    DK: 'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.codes.DK',
    M: 'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.codes.M',
  };

  const defaultItems = Object.values(ArtistSocialInsuranceCode).map(
    (
      artistSocialInsuranceCode: ArtistSocialInsuranceCode
    ): AccountingFieldItem => ({
      key: artistSocialInsuranceCode,
      children: t(mapCodeToTranslation[artistSocialInsuranceCode]),
    })
  );

  return { defaultItems };
};
