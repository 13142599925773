import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ImportPreviewTableData } from '../types';

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<ImportPreviewTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
      {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        t(`importPreviewTable.columns.${column.id}`, {
          context: getTranslationContext(),
        })
      }
    </TruncatedText>
  );
};
