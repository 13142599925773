import { isFunction, set } from 'lodash';
import {
  FieldError,
  FieldValues,
  ResolverError,
  ResolverResult,
} from 'react-hook-form';
import { z } from 'zod';
import { getZodErrorMap } from './getZodErrorMap';
import { ErrorMessages, Paths } from './types';

interface ZodResolverProps<
  TExludeKeys extends Paths<z.infer<TSchema>> | undefined = undefined,
  TSchema extends z.ZodTypeAny = z.ZodTypeAny,
  TContext extends Record<string, any> | undefined = undefined
> {
  /**
   * Either a `zod` schema, or a function that returns a `zod` schema.
   *
   * As `zod` does not directly allow for validation contexts, this can be useful
   * if your schema requires additional data to validate.
   *
   * The `validationContext` object will then be passed to the validation function
   * by `react-hook-form`
   *
   * Eg:
   *
   * ```
   * const context = { invoiceDate: new Date(2022,9,26) };
   *
   * const zodSchema = (validationContext: { invoiceDate: Date }) => {
   *    return z.object({
   *      dueDate: z.date().superRefine((dueDate, ctx) => {
   *        if (dueDate < validationContext.invoiceDate) {
   *          ctx.addIssue({
   *            code: 'custom',
   *            translationKey: 'custom.dueDate.error.translation.key'
   *          })
   *        }
   *      });
   *    })
   * }
   * ```
   */
  zodSchema: TSchema | ((validationContext: TContext) => TSchema);

  errorMessages: ErrorMessages<TSchema, TExludeKeys>;

  /**
   * Defaults to `common`
   * If the errorMessages have translation keys from multiple namespaces, the
   * ones not part of this `translationNamespace` can be prefixed with the namespace.
   *
   * ```
   * const translationNamespace = 'settings'
   * const errorMessages = {
   *   fieldOne: { label: 'some.settings.key' },
   *   fieldTwo: { label: 'split-bookings:some.sb.key' }
   * }
   * ```
   */
  translationNamespace?: string;

  /**
   * Defaults to `validation`
   */
  errorType?: string;
}

/**
 * Zod error resolver for use with `react-hook-form`
 */
export const zodResolver = <
  TExludeKeys extends Paths<z.infer<TSchema>> | undefined = undefined,
  TSchema extends z.ZodTypeAny = z.ZodTypeAny,
  TContext extends Record<string, any> | undefined = undefined
>({
  zodSchema,
  errorMessages,
  translationNamespace = 'common',
  errorType = 'validation',
}: ZodResolverProps<TExludeKeys, TSchema, TContext>) => {
  return async <TFieldValues extends FieldValues>(
    fieldValues: TFieldValues,
    validationContext: TContext
  ): Promise<ResolverResult<TFieldValues>> => {
    const schema = isFunction(zodSchema)
      ? zodSchema(validationContext ?? ({} as TContext))
      : zodSchema;

    // This creates a custom error map with our translations for the various form errors.
    const errorMap = getZodErrorMap(
      schema,
      errorMessages,
      translationNamespace
    );

    const result = await schema.safeParseAsync(fieldValues, { errorMap });

    if (result.success) {
      return {
        errors: {},
        values: result.data,
      };
    }

    const errors: ResolverError<TFieldValues>['errors'] = {};

    result.error.errors.forEach(({ message, path }) => {
      const error: FieldError = {
        message,
        type: errorType,
      };

      set(errors, path, error);
    });

    return {
      errors,
      values: {},
    };
  };
};
