import { FieldPolicy, TypePolicies } from '@apollo/client';
import { DocumentConnection } from 'generated-types/resolvers-types';
import { paginationWithRecordConnectionsMerge } from '../pagination-results-merge';

const documentsListPagination: FieldPolicy<DocumentConnection> = {
  merge: paginationWithRecordConnectionsMerge('DocumentConnection'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      listApprovalsDocuments: documentsListPagination,
      listInboxDocuments: documentsListPagination,
      archivedDocuments: documentsListPagination,
      searchDocuments: documentsListPagination,
    },
  },
};
