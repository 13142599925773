import {
  Text,
  useTooltip,
  Tooltip,
  Flex,
  mergeRefs,
  Icon,
  Skeleton,
  mergeProps,
  Box,
} from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { IconLinkProps } from './types';

const MotionText = motion(Text);
const MotionFlex = motion(Flex);

const motionTextVariants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: -10 },
};

const motionFlexVariants = {
  initial: {
    background: 'transparent',
    outline: 'none',
    color: colors.gray600,
    transition: { color: { duration: 0 } },
  },
  hover: {
    outline: 'none',
    background: colors.bluebg,
    color: colors.blue600,
    transition: { color: { duration: 0 } },
  },
};

export const IconLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  IconLinkProps
>(
  (
    {
      icon,
      avatar,
      as: Component,
      size = 'space20',
      count,
      countLoading,
      label,
      showLabelInTooltip = true,
      expanded = false,
      to,
      description,
      isActive,
      pulseIndicator,
      'aria-label': ariaLabel,
      ...restProps
    }: IconLinkProps,
    ref
  ) => {
    const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
      useTooltip({
        placement: 'right',
      });

    const LinkOrButton = Component === 'button' ? 'button' : Link;

    const showLabelTooltip = !expanded && showLabelInTooltip;

    const getGapValue = () => {
      if (expanded && icon) return 'space12';
      if (expanded && avatar) return 'space8';

      return undefined;
    };

    const textAnimation = {
      initial: expanded ? 'closed' : 'open',
      animate: expanded ? 'open' : 'closed',
    };

    return (
      <>
        <MotionFlex
          as={LinkOrButton}
          alignItems="center"
          justifyContent="space-between"
          textDecoration="none"
          background="transparent"
          borderRadius="medium"
          cursor="pointer"
          color="gray600"
          padding={icon ? 'space10 space12' : 'space4'}
          border="none"
          outline="none"
          gap={count ? 'space8' : undefined}
          width={expanded ? 'inherit' : 'fit-content'}
          to={to}
          tabIndex={0}
          type={Component === 'button' ? 'button' : undefined}
          variants={motionFlexVariants}
          initial={isActive ? 'hover' : 'initial'}
          animate={isActive ? 'hover' : 'initial'}
          whileHover="hover"
          whileFocus="hover"
          ref={mergeRefs([triggerRef, ref])}
          aria-label={ariaLabel ?? label}
          {...mergeProps(triggerProps, restProps)}>
          <Flex alignItems="center" gap={getGapValue()}>
            {icon && (
              <>
                <Icon icon={icon} size={size} />
                {pulseIndicator && (
                  <Box position="absolute" bottom={16} left={16}>
                    {pulseIndicator}
                  </Box>
                )}
              </>
            )}
            {avatar}
            {expanded && (
              <Flex
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start">
                <MotionText
                  aria-label={ariaLabel ?? label}
                  fontSize="basic"
                  fontWeight="bold"
                  lineHeight="space20"
                  initial={textAnimation.initial}
                  animate={textAnimation.animate}
                  exit="closed"
                  variants={motionTextVariants}
                  transition={{ duration: 0.1 }}>
                  {label}
                </MotionText>
                {description && (
                  <MotionText
                    aria-label={description}
                    fontSize="small"
                    fontWeight="regular"
                    lineHeight="space16"
                    initial={textAnimation.initial}
                    animate={textAnimation.animate}
                    exit="closed"
                    variants={motionTextVariants}
                    transition={{ duration: 0.1 }}>
                    {description}
                  </MotionText>
                )}
              </Flex>
            )}
          </Flex>

          {countLoading && <Skeleton width="space16" height="space16" />}

          {count && !countLoading && (
            <Text fontSize="x-small" fontWeight="bold" lineHeight="paragraph">
              {`${count > 99 ? '+' : ''}${Math.min(count, 99)}`}
            </Text>
          )}
        </MotionFlex>
        {isOpen && label && showLabelTooltip && (
          <Tooltip ref={tooltipRef} {...tooltipProps}>
            {label}
          </Tooltip>
        )}
      </>
    );
  }
);
