import { Card, Grid, Icon, Link } from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

interface CardReplacesInfoProps {
  id: string;
  replacedDate: string;
  refNumber?: string;
  replacedBy?: string;
}

export const CardReplaced = ({
  id,
  replacedDate,
  refNumber,
  replacedBy,
}: CardReplacesInfoProps) => {
  const orgId = useOrganizationId();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const linkToReplacedCard = `/${orgId}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/${id}?onCreditCardsView=true`;

  return (
    <Card background="bluebg">
      <Grid templateColumns="auto 1fr" gap="space8" alignItems="center">
        <Icon size="space20" icon="infoCircle" color="blue500" />
        <Trans
          t={t}
          parent="span"
          values={{
            cardRefNum: refNumber,
            date: replacedDate,
            userName: replacedBy,
          }}
          i18nKey="creditCardFormDataPreview.replaceCard.infoBox.replaces">
          Replacement card for
          <Link external href={linkToReplacedCard}>
            {{ cardRefNum: refNumber } as any}
          </Link>
          on
          {{ date: replacedDate } as any}
          by
          {{ userName: replacedBy } as any}
        </Trans>
      </Grid>
    </Card>
  );
};
