import { DocumentTagsFieldContainer } from 'containers/Tags/DocumentTagsFieldContainer';
import { useUserRoles } from 'hooks/useUserRoles';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { ReactNode } from 'react';
import { DocumentForm } from './DocumentForm';
import { useDocumentFormInitialData } from './toolkit/hooks/useDocumentFormInitialData';
import { ProcessedDocument } from './toolkit/types';

interface DocumentFormContainerProps {
  document: ProcessedDocument;
  allowInlineEditing?: boolean;
  footer?: ReactNode;
  reRequestApproval?: () => void;
}

export const DocumentFormContainer = ({
  document,
  allowInlineEditing,
  footer,
  reRequestApproval,
}: DocumentFormContainerProps) => {
  const { isAdmin, isAccountant } = useUserRoles();
  const { showDocumentTags } = useEcm();

  const { documentId, fieldConditions, initialValues, isLoading } =
    useDocumentFormInitialData({
      document,
    });

  const isEditable = allowInlineEditing && (isAdmin || isAccountant);

  return (
    <DocumentForm
      documentId={documentId}
      fieldConditions={fieldConditions}
      footer={footer}
      initialValues={initialValues}
      isEditable={isEditable}
      isLoading={isLoading}
      reRequestApproval={reRequestApproval}
      tagsField={
        showDocumentTags &&
        document?.globalDocumentId && (
          <DocumentTagsFieldContainer
            documentTags={document.tags?.map(t => t.id) ?? []}
            globalDocumentId={document.globalDocumentId}
            documentId={documentId}
            isInvoice
          />
        )
      }
    />
  );
};
