import gql from 'graphql-tag';

export const getFullOrganizationQuery = gql`
  query organization($id: ID) {
    organization(id: $id) {
      id
      realmName
      name
      hasCostCenters
      hasCostObjects
      hasExtraCostInfos
      hasActiveBookingAccounts
      hasActiveBookingKeys
      hasActiveWorkflowTemplates
      hasTransactionsActivated
      permissionsForMembership
      hasContacts
      slug
      salesforceAccountId
      locale
      chargebeeSubscriptionId
      permissionsForMembership
      payment {
        isActive
        startDate
      }
      creditCardsDetails {
        paymentFrequency
      }
      creditCardsSettings {
        tradeName
        legalForm
        foundationDate
        country
        accountType
        nationalRegisterCourt
        nationalRegisterId
        nationalRegisterType
        tradeRegisterNumber
        vatId
        billingEmail
        isCompanyNamePrintable
        deliveryAddressSameAsBillingAddress
        billingAddress {
          country
          city
          postalCode
          streetFull
          streetName
          streetNumber
          streetAdditional
        }
        deliveryAddress {
          country
          city
          postalCode
          streetFull
          streetName
          streetNumber
          streetAdditional
        }
        processingAccount {
          bankName
          iban
        }
      }
      exportConfiguration {
        isReweEnabled
        fiscalYearStartDay
        fiscalYearStartMonth
      }
      integrationName
      hasIdpSSO
      idpConfig {
        isMembershipRolesUpdatedViaSSO
        isUserDataUpdatedViaSSO
      }
    }
  }
`;
