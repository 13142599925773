import { Link, Text } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetCardById } from 'containers/credit-cards/utils';
import { useUserRoles } from 'hooks/useUserRoles';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { CancelCardRequest } from './CancelCardRequest';
import { HandleRequest } from './HandleRequest';
import { useApproveCardRequest } from './hooks/useApproveCardRequest';
import { useCancelRequest } from './hooks/useCancelRequest';
import { useDeclineRequest } from './hooks/useDeclineLimitRequest';

export interface HandleCardRequestContainerProps {
  cardId: string;
}

export const HandleCardRequestContainer = ({
  cardId,
}: HandleCardRequestContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();
  const orgId = useOrganizationId();
  const { card } = useGetCardById({ cardId });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isCardManager } = useUserRoles();
  const { decline, declining } = useDeclineRequest({ cardId });
  const { cancel, canceling } = useCancelRequest({ cardId });

  const { approveActionToCardType } = useApproveCardRequest({ cardId });

  if (!card) {
    return null;
  }

  const { pendingRequest, type } = card;

  const cardholder = {
    firstName: card.cardholder.firstName ?? undefined,
    lastName: card.cardholder.lastName ?? undefined,
  };

  if (
    !pendingRequest ||
    pendingRequest.__typename === 'CardLimitChangeRequest'
  ) {
    return null;
  }

  const approve = approveActionToCardType[type]?.approve;
  const isApprovePending = approveActionToCardType[type].approving;

  const cardManagerViewLink = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`,
    { organizationSlug: orgId ?? '' }
  );

  const handleDeclineRequest = async (declineReason: string | undefined) => {
    const { status } = await decline(pendingRequest.id, declineReason);

    if (status === 'success') {
      success(t('drawer.cancelRequestSuccess'));
    } else {
      error(t('dashboard.genericErrorMessage'));
    }

    navigate({
      pathname: cardManagerViewLink,
      search: searchParams.toString(),
    });
  };

  const handleApproveRequest = async () => {
    if (!approve) {
      return;
    }

    const { status } = await approve(pendingRequest.id);
    const cardLink = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/:creditCardId`,
      { organizationSlug: orgId ?? '', creditCardId: cardId }
    );

    const successMsgWithLink = (
      <Text>
        {t('drawer.requested.messages.approveSuccess')}{' '}
        <Link href={cardLink} external>
          {t('drawer.requested.messages.link')}
        </Link>
      </Text>
    );

    if (status === 'success') {
      success(successMsgWithLink);
    } else {
      error(t('dashboard.genericErrorMessage'));
    }

    navigate({
      pathname: cardManagerViewLink,
      search: searchParams.toString(),
    });
  };

  const handleCancelRequest = async () => {
    const { status } = await cancel(pendingRequest.id);

    if (status === 'success') {
      success(t('drawer.cancelRequestSuccess'));
    } else {
      error(t('dashboard.genericErrorMessage'));
    }

    navigate(
      {
        pathname: '..',
        search: searchParams.toString(),
      },
      { relative: 'path' }
    );
  };

  return isCardManager ? (
    <HandleRequest
      approving={isApprovePending}
      declining={declining}
      cardholder={cardholder}
      onApprove={handleApproveRequest}
      onDecline={handleDeclineRequest}
      requestType="cardRequest"
    />
  ) : (
    <CancelCardRequest canceling={canceling} onCancel={handleCancelRequest} />
  );
};
