import {
  Button,
  Flex,
  Grid,
  Paragraph,
  Popover,
  usePopover,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

type Props = {
  disconnect: () => void;
  loading: boolean;
};

export const DisconnectButton = ({ disconnect, loading }: Props) => {
  const [t] = useTranslation();

  const {
    close,
    isOpen,
    open,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'top' });

  const onDisconnect = () => {
    close();
    disconnect();
  };

  return (
    <>
      <Button
        variant="secondary"
        disabled={loading}
        onClick={open}
        {...triggerProps}
        ref={triggerRef}
        loading={loading}>
        {t('settings.datev.connect.actions.disconnect')}
      </Button>
      {isOpen && (
        <Popover
          data-testid="disconnect-button-popover"
          {...popoverProps}
          ref={popoverRef}>
          <Grid gap="space16" padding="space16" justifyItems="left">
            <Paragraph>
              {t('settings.datev.connect.actions.disconnectConfirm')}
            </Paragraph>
            <Flex gap="space8">
              <Button variant="secondary" onClick={close}>
                {t('no')}
              </Button>
              <Button color="red" onClick={onDisconnect}>
                {t('settings.datev.connect.actions.yes')}
              </Button>
            </Flex>
          </Grid>
        </Popover>
      )}
    </>
  );
};
