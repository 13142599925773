import { Box, Grid, Separator, Text } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { AggregatedCoreDataInfo } from '../../../../types';
import { ExportSummaryTitle } from '../../../components/ExportSummaryTitle';

export const CoreDataSummary = ({
  contacts,
  paymentConditions,
  loading,
}: {
  contacts?: AggregatedCoreDataInfo;
  paymentConditions?: AggregatedCoreDataInfo;
  loading?: boolean;
}) => {
  const [t] = useTranslation();

  const contactsSummary = [
    {
      title: (
        <ExportSummaryTitle
          title={t('export.exportMasterDataSummary.contactsCount', {
            count: contacts?.count,
          })}
          status={contacts?.status}
          tooltip={t('export.exportSummary.tooltips.contactsTooltip')}
          disabled={contacts?.status !== ExportStatus.Exported}
        />
      ),
      content: '',
      footer:
        contacts?.status !== ExportStatus.Exported ? (
          <Grid gap="space4">
            <Box paddingY="space4">
              <Separator />
            </Box>
            <Text fontSize="small" fontWeight="semibold">
              {t('export.exportHistorySummary.notExported')}
            </Text>
            <Text fontSize="small" fontWeight="normal">
              {t('export.exportHistorySummary.reExports.contacts')}
            </Text>
          </Grid>
        ) : null,
    },
  ];

  const paymentConditionsSummary = [
    {
      title: (
        <ExportSummaryTitle
          title={t('export.exportMasterDataSummary.paymentConditionsCount', {
            count: paymentConditions?.count,
          })}
          status={paymentConditions?.status}
          disabled={contacts?.status !== ExportStatus.Exported}
        />
      ),
      content: '',
      footer:
        contacts?.status !== ExportStatus.Exported ? (
          <Grid gap="space4">
            <Box paddingY="space4">
              <Separator />
            </Box>
            <Text fontSize="small" fontWeight="semibold">
              {t('export.exportHistorySummary.notExported')}
            </Text>
            <Text fontSize="small" fontWeight="normal">
              {t('export.exportHistorySummary.reExports.paymentConditions')}
            </Text>
          </Grid>
        ) : null,
    },
  ];

  return (
    <>
      {contacts && contacts.count > 0 ? (
        <SummaryAccordionCard
          items={contactsSummary}
          loading={loading}
          collapsable={false}
        />
      ) : null}
      {paymentConditions && paymentConditions.count > 0 ? (
        <SummaryAccordionCard
          items={paymentConditionsSummary}
          loading={loading}
          collapsable={false}
        />
      ) : null}
    </>
  );
};
