import { MAX_DIGITS } from '../consts';

export const radioFields = [
  {
    key: 'import.fileSelectionStep.maxDigitsFour',
    value: MAX_DIGITS.FOUR,
  },
  {
    key: 'import.fileSelectionStep.maxDigitsFive',
    value: MAX_DIGITS.FIVE,
  },
  {
    key: 'import.fileSelectionStep.maxDigitsSix',
    value: MAX_DIGITS.SIX,
  },
  {
    key: 'import.fileSelectionStep.maxDigitsSeven',
    value: MAX_DIGITS.SEVEN,
  },
  {
    key: 'import.fileSelectionStep.maxDigitsEight',
    value: MAX_DIGITS.EIGHT,
  },
];
