import { times } from 'lodash';

/**
 * Given a maximum digits a number can have, returns the highest possible number
 * len: 4 ==> 9999
 */
export const maxNumberFromMaxDigits = (len: number): number => {
  const nums = times(len).map(() => 9);

  return Number.parseInt(nums.join(''));
};
