import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { PaymentConditionsProps } from './PaymentConditions';
import { PaymentConditionsContainer } from './PaymentConditionsContainer';
import { PaymentConditionsDrawerContainer } from './PaymentConditionsDrawer/PaymentConditionsDrawerContainer';
import { PaymentConditionsImportContainer } from './PaymentConditionsImport/PaymentConditionsImportContainer';

export const PAYMENTS_IMPORT_HASH = '#import';

/** Handles routing for payment conditions settings view */
export const PaymentConditionsRoute = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const { id: paymentConditionId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const status = (searchParams.get('status') ??
    'active') as PaymentConditionsProps['status'];

  return (
    <>
      <PaymentConditionsContainer
        status={status}
        onShowImport={() => {
          navigate({
            hash: PAYMENTS_IMPORT_HASH,
            search: searchParams.toString(),
          });
        }}
        onShowImportHistory={() => {
          navigate('import_history');
        }}
        onChangeStatus={status => {
          setSearchParams(params => ({ ...params, status }));
        }}
        onShowItem={id => {
          navigate({ pathname: id, search: searchParams.toString() });
        }}
      />

      <PaymentConditionsDrawerContainer
        id={paymentConditionId}
        onClose={() => {
          navigate(
            { pathname: '..', search: searchParams.toString() },
            { relative: 'path' }
          );
        }}
      />

      {hash === PAYMENTS_IMPORT_HASH && (
        <PaymentConditionsImportContainer
          onClose={() => {
            navigate({ hash: '', search: searchParams.toString() });
          }}
        />
      )}
    </>
  );
};
