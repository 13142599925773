import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

export const AccountingArea = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="accountingArea.inputValue"
      label={t('document.requestApproval.inputs.accountingArea.label')}
      readOnly
    />
  );
};
