import { mergeProps } from '@candisio/design-system';
import {
  HookFormIbanField,
  HookFormIbanFieldProps,
} from 'components/HookFormFields/HookFormIbanField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

/** A `HookFormIbanField` with added field metadata */
export const ProcessingFormIbanField = ({
  name,
  readOnly,
  description,
  ...restProps
}: HookFormIbanFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormIbanField
      name={name}
      description={description}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
