import { MutationResult } from '@apollo/client';
import { getMemberships } from 'components/Comment/gql';
import { useDeactivateMembershipMutation } from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { useCallback } from 'react';
import {
  getApproversQuery,
  organizationMembershipCountByStatusQuery,
} from './queries';
import {
  MembershipInvolvedResponse,
  MembershipOnlyAdminResponse,
  MembershipPendingApprovalsResponse,
} from './responses';

type DeactivateMembershipCallback = (
  membershipId: string
) => Promise<
  | MembershipInvolvedResponse
  | MembershipOnlyAdminResponse
  | MembershipPendingApprovalsResponse
  | void
>;

type DeactivateMembershipHook = [DeactivateMembershipCallback, MutationResult];

export const useDeactivateMembership = (): DeactivateMembershipHook => {
  const [deactivateMembershipMutation, mutationProps] =
    useDeactivateMembershipMutation();

  const counterQueries = useCounterQueries();

  const deactivateMembership = useCallback<DeactivateMembershipCallback>(
    async membershipId => {
      const { errors } = await deactivateMembershipMutation({
        variables: { membershipId },
        refetchQueries: [
          ...counterQueries,
          'organizationMemberships',
          {
            query: getApproversQuery,
            variables: { name: '' },
          },
          {
            query: getMemberships,
            variables: membershipsQueryFilter.Active,
          },
          { query: organizationMembershipCountByStatusQuery },
        ],
      });

      if (errors?.length) {
        for (const e of errors) {
          if (e.extensions?.code === 'INVOLVED') {
            return { _tag: 'INVOLVED' };
          }

          if (e.extensions?.code === 'ONLY_ADMIN') {
            return { _tag: 'ONLY_ADMIN' };
          }

          if (e.extensions?.code === 'PENDING_APPROVALS') {
            return {
              _tag: 'PENDING_APPROVALS',
              numberOfPendingApprovals: (
                e.extensions?.exception as MembershipPendingApprovalsResponse
              )?.numberOfPendingApprovals,
            };
          }
        }
      }
    },
    [deactivateMembershipMutation, counterQueries]
  );

  return [deactivateMembership, mutationProps as MutationResult];
};
