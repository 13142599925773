import { useCalculateRoundingAmount } from 'components/Form/utils';
import { grossToNet, netToGross } from 'containers/SplitBookings/toolkit/utils';
import {
  DocumentCurrency,
  useBookingKeysActiveQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { get } from 'lodash';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { getDiscountAmount } from './getDiscountAmount';
import { getDiscountPercentage } from './getDiscountPercentage';
import { getDueDate } from './getDueDate';
import { ProcessingFormFieldOptions } from './ProcessingForm';
import { ProcessingFormFieldMetadata } from './ProcessingFormMetadataContext';
import { ProcessingFormValues } from './processingFormSchema';
import { useSetProcessingFormMetadata } from './ProcessingFormSetMetadataContext';
import { useUpdateProcessingFormField } from './useUpdateProcessingFormField';

export interface UseWatchProcessingFormOptions {
  /** Individual processing form field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /** Processing form instance */
  form: UseFormReturn<ProcessingFormValues>;

  showSplitList?: boolean;
}

/**
 * Watches for processing form changes and updates field values and metadata
 * accordingly
 */
export const useWatchProcessingForm = ({
  fieldOptions,
  showSplitList,
  form,
}: UseWatchProcessingFormOptions) => {
  const setMetadata = useSetProcessingFormMetadata();
  const updateField = useUpdateProcessingFormField({ form });
  const calculateRoundingAmount = useCalculateRoundingAmount();
  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery();
  const { shouldUseSapNetAmount, isActiveIntegration: isSapIntegration } =
    useSap();

  const [netAndTaxAmountFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.netAndTaxAmount,
  ]);

  const getContactFieldItem = fieldOptions?.contact?.getItem;
  const paymentConditionFieldItems = fieldOptions?.paymentCondition?.items;
  const workflowFieldItems = fieldOptions?.workflow?.props?.defaultItems;
  const hasLinkedTransaction = fieldOptions?.hasLinkedTransaction;

  useEffect(() => {
    const subscription = form.watch((values, { name: field, type }) => {
      if (!field || !updateField) {
        return;
      }

      const value = get(values, field);

      const changedByUser = type === 'change';

      const isSingleBooking = values.bookings?.length === 1;

      const isSingleBookingWithoutSapAndNetTax =
        values.bookings?.length === 1 &&
        !isSapIntegration &&
        !netAndTaxAmountFF;

      const isNetAndTaxtAmountForSingleBooking =
        values.bookings?.length === 1 && netAndTaxAmountFF;

      if (changedByUser) {
        // always clear field’s metadata when its value was changed by user
        setMetadata(field, undefined);
      }

      const updateTaxAmountOnNetAmountChange = () => {
        if (!netAndTaxAmountFF) return;
        if (isSapIntegration) {
          // For SAP integrations gross for split item is recalculated
          const netAmount = (values.bookings || [])[0]?.netAmount ?? 0;
          const amount = netToGross(
            netAmount,
            (values.bookings || [])[0]?.vatRate ?? 0
          );

          const taxAmount = roundToCurrencyPrecision(amount - netAmount);
          updateField('bookings.0.amount', amount, undefined);
          updateField('bookings.0.taxAmount', taxAmount, undefined);
          updateRoundingAmount();
        } else {
          updateAmounts();
        }
      };

      const updateAmounts = () => {
        if (!netAndTaxAmountFF) return;
        const amount = (values.bookings || [])[0]?.amount ?? 0;
        const netAmount = grossToNet(
          amount,
          (values.bookings || [])[0]?.vatRate ?? 0
        );

        const taxAmount = roundToCurrencyPrecision(amount - netAmount);

        updateField('bookings.0.netAmount', netAmount, undefined);
        updateField('bookings.0.taxAmount', taxAmount, undefined);
      };

      const updateRoundingAmount = () => {
        if (!shouldUseSapNetAmount) return;
        void calculateRoundingAmount({
          currency: values.currency as DocumentCurrency,
          grossAmount: values.grossAmount ?? 0,
          bookings: (values?.bookings ??
            []) as ProcessingFormValues['bookings'],
        }).then(roundingAmount => {
          updateField('roundingAmount', roundingAmount ?? 0, undefined);
        });
      };

      switch (true) {
        case field === 'contact.value': {
          // @TODO abort any previous getContactFieldItem call to prevent race
          // condition
          getContactFieldItem?.(value as string)
            .then(item => {
              if (!item) {
                return;
              }

              const {
                iban,
                swiftCode,
                createTransfer,
                paymentCondition,
                bankAccountNumber,
              } = item;

              const metadata: ProcessingFormFieldMetadata = {
                confidence: 1,
                source: 'contact',
              };

              if (bankAccountNumber) {
                // swift code update has to occur before iban update, because iban validation depends on swift code value
                updateField('swiftCode', null, undefined);
                updateField('iban', null, undefined);
              } else {
                updateField('swiftCode', swiftCode, metadata);
                updateField('iban', iban, metadata);
              }

              updateField('createTransfer', createTransfer ?? false, metadata);

              // Prevent cash discount and payment condition update/validation
              // when invoice has linked transaction
              if (hasLinkedTransaction) {
                updateField('paymentCondition', null, undefined);
                updateField('discountDate', null, undefined);
                updateField('discountPercentage', null, undefined);
                updateField('discountAmount', null, undefined);
              } else {
                updateField('paymentCondition', paymentCondition, metadata);

                const { discountOffset, discountPercentage, dueDateOffset } =
                  paymentConditionFieldItems?.find(
                    item => item.key === paymentCondition
                  ) ?? {};

                const discountDate = getDueDate(
                  values?.invoiceDate ?? undefined,
                  discountOffset
                );

                const discountAmount = getDiscountAmount(
                  values?.grossAmount ?? undefined,
                  discountPercentage
                );

                const dueDate = getDueDate(
                  values?.invoiceDate ?? undefined,
                  dueDateOffset
                );

                updateField('bookings.0.dueDate', dueDate, metadata);
                updateField('discountDate', discountDate, metadata);
                updateField('discountPercentage', discountPercentage, metadata);
                updateField('discountAmount', discountAmount, metadata);
              }
            })
            .catch(() => {
              console.error('Failed to get contact field item', value);
            });

          return;
        }

        case field === 'invoiceDate': {
          if (!values.paymentCondition) {
            return;
          }

          const { discountOffset, dueDateOffset } =
            paymentConditionFieldItems?.find(
              item => item.key === values.paymentCondition
            ) ?? {};

          const dueDate = getDueDate(value as string, dueDateOffset);
          const discountDate = getDueDate(value as string, discountOffset);

          updateField('bookings.0.dueDate', dueDate, undefined);
          updateField('discountDate', discountDate, undefined);

          return;
        }

        case field === 'grossAmount': {
          if (!showSplitList && isSingleBooking) {
            updateField('bookings.0.amount', value as number, undefined);
          }

          if (isSingleBookingWithoutSapAndNetTax) {
            // For SAP we don't adjust booking line item gross with document gross
            updateField('bookings.0.amount', value as number, undefined);
            updateAmounts();
          } else {
            updateRoundingAmount();
          }

          const discountAmount = getDiscountAmount(
            value as number,
            values.discountPercentage ?? undefined
          );

          updateField('discountAmount', discountAmount, undefined);

          return;
        }

        case field === 'bookings.0.taxCode': {
          if (isNetAndTaxtAmountForSingleBooking) {
            const selectedBookingKey =
              bookingKeysActiveData?.bookingKeysActive.find(
                bookingKey =>
                  bookingKey.id === (values.bookings || [])[0]?.taxCode
              );

            updateField(
              'bookings.0.vatRate',
              selectedBookingKey?.taxPercentage ?? 0,
              undefined
            );
          }

          return;
        }

        case field === 'bookings.0.vatRate': {
          if (isNetAndTaxtAmountForSingleBooking) {
            updateAmounts();
            updateRoundingAmount();
          }

          return;
        }

        case field === 'bookings.0.taxAmount': {
          if (values.bookings?.length === 1 && type) {
            const netAmount = (values.bookings || [])[0]?.netAmount ?? 0;
            const taxAmount = (values.bookings || [])[0]?.taxAmount ?? 0;

            updateField(
              'bookings.0.amount',
              roundToCurrencyPrecision(netAmount + taxAmount),
              undefined
            );
            updateRoundingAmount();
          }

          return;
        }

        case field === 'bookings.0.netAmount': {
          if (values.bookings?.length === 1 && type) {
            updateTaxAmountOnNetAmountChange();
          }

          return;
        }

        case field === 'paymentCondition': {
          if (!changedByUser) {
            return;
          }

          const { discountOffset, discountPercentage, dueDateOffset } =
            paymentConditionFieldItems?.find(item => item.key === value) ?? {};

          const dueDate = getDueDate(
            values?.invoiceDate ?? undefined,
            dueDateOffset
          );

          const discountDate = getDueDate(
            values?.invoiceDate ?? undefined,
            discountOffset
          );

          const discountAmount = getDiscountAmount(
            values?.grossAmount ?? undefined,
            discountPercentage
          );

          updateField('bookings.0.dueDate', dueDate, undefined);
          updateField('discountDate', discountDate, undefined);
          updateField('discountPercentage', discountPercentage, undefined);
          updateField('discountAmount', discountAmount, undefined);

          return;
        }

        case field === 'bookings.0.dueDate': {
          const numBookings = values.bookings?.length ?? 1;
          for (let index = 1; index < numBookings; index++) {
            // update due date of all other bookings
            updateField(
              `bookings.${index}.dueDate`,
              value as string,
              undefined
            );
          }

          if (changedByUser) {
            updateField('paymentCondition', null, undefined);
            updateField('discountDate', null, undefined);
            updateField('discountPercentage', null, undefined);
            updateField('discountAmount', null, undefined);
          }

          return;
        }

        case field === 'discountDate': {
          if (changedByUser) {
            updateField('paymentCondition', null, undefined);
          }

          return;
        }

        case field === 'discountPercentage': {
          if (!changedByUser) {
            return;
          }

          updateField('paymentCondition', null, undefined);

          const discountAmount = getDiscountAmount(
            values?.grossAmount ?? undefined,
            value as number
          );

          updateField('discountAmount', discountAmount, undefined);

          return;
        }

        case field === 'discountAmount': {
          if (!changedByUser) {
            return;
          }

          updateField('paymentCondition', null, undefined);

          const discountPercentage = getDiscountPercentage(
            values?.grossAmount ?? undefined,
            value as number
          );

          updateField('discountPercentage', discountPercentage, undefined);

          return;
        }

        case field === 'workflow': {
          if (!changedByUser) {
            return;
          }

          const workflowItem = workflowFieldItems?.find(
            item => item.key === value
          );

          updateField(
            'approvers',
            workflowItem?.approvers?.map(stepApprovers => ({
              approvers: stepApprovers,
            })) ?? [],
            undefined
          );

          return;
        }

        // approval *step* added/removed
        case field === 'approvers': {
          setMetadata('approvers', undefined); // clear metadata
          void form.trigger('approvers'); // trigger validation

          return;
        }

        // individual *approver* added/removed
        case /approvers.\d+\.approvers/.test(field): {
          if (changedByUser) {
            updateField('workflow', null, undefined);
          }

          return;
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    bookingKeysActiveData?.bookingKeysActive,
    calculateRoundingAmount,
    form,
    getContactFieldItem,
    hasLinkedTransaction,
    isSapIntegration,
    netAndTaxAmountFF,
    paymentConditionFieldItems,
    setMetadata,
    shouldUseSapNetAmount,
    updateField,
    workflowFieldItems,
    showSplitList,
  ]);
};
