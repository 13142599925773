import { HookFormIbanField } from 'components/HookFormFields/HookFormIbanField';
import { useTranslation } from 'react-i18next';

export const IBAN = () => {
  const [t] = useTranslation();

  return (
    <HookFormIbanField
      name="iban"
      label={t('document.requestApproval.inputs.iban.label')}
      readOnly
    />
  );
};
