import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export interface UpdateVirtualAndPhysicalCardLimitFormSchemaProps {
  isCardManager: boolean;
}

const limitsSchema = z
  .object({
    limit: z.number(),
    transactionLimit: z.number(),
  })
  .superRefine((values, ctx) => {
    if (values.transactionLimit > values.limit) {
      ctx.addIssue({ code: 'custom', path: ['transactionLimit'] });
    }
  });

export const updateVirtualAndPhysicalCardLimitFormSchema = ({
  isCardManager,
}: UpdateVirtualAndPhysicalCardLimitFormSchemaProps) => {
  const schema = z.object({
    cardLabel: z.string().optional().nullable(),
    limitInterval: z.string(),
    comment: isCardManager ? z.string().optional().nullable() : z.string(),
  });

  return schema.and(limitsSchema);
};

export type UpdateVirtualAndPhysicalCardLimitFormValues = z.infer<
  ReturnType<typeof updateVirtualAndPhysicalCardLimitFormSchema>
>;

export type UpdateVirtualAndPhysicalCardLimitFormErrorMessages = ErrorMessages<
  ReturnType<typeof updateVirtualAndPhysicalCardLimitFormSchema>
>;
