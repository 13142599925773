import { CustomEmptyStateProps, Flex } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import {
  CCWalletTrackingEvents,
  useAnalytics,
} from 'providers/AnalyticsProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useMemo } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { useCardIssuerCards } from '../hooks/useCardIssuerCards';
import { useCardIssuerCardsFilters } from '../hooks/useCardIssuerCardsFilters';
import { CreditCardsTableData } from '../types';
import { availableFilters } from '../utils/utils';
import { CreditCardsTable } from './components/CreditCardsTable';
import {
  ToolbarFilter,
  toolBarFilterParam,
} from './components/CreditCardsTable/CreditCardsTableToolbar';
import { EmptyState } from './components/CreditCardsTable/EmptyState';
import { useGetCreditCardsTableConfigs } from './components/CreditCardsTable/hooks/useGetCreditCardsTableConfigs';

export const CreditCardsTableTab = () => {
  const { track } = useAnalytics();
  const { updateSearchParam } = useMutateSearchParams();
  const { organizationSlug } = useParams<{
    organizationSlug: string;
  }>();

  const { searchParams } = useMutateSearchParams();
  const toolbarFilter: ToolbarFilter = (searchParams.get(toolBarFilterParam) ??
    'InUse') as ToolbarFilter;

  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const navigate = useNavigate();

  const { search } = useLocation();
  const queryStringfromUrl = qs.parse(search);
  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<CreditCardsTableData>({
      availableFilters,
    });

  const {
    creditCardsTableData,
    loading,
    onLoadMore,
    isTableEmpty,
    isTableFiltered,
    handleDebounceSearch,
  } = useCardIssuerCards({ filters, sortBy, toolbarFilter });

  const {
    isLoadingConfigs,
    isResetPending,
    availableCreditCardsColumnIds,
    configurationsTable,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useGetCreditCardsTableConfigs({ filters, sortBy });

  const { filterOptions, isFilterLoading } = useCardIssuerCardsFilters();

  const openDrawer = (cardId: string) => {
    const pathname = `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/${cardId}`;

    navigate({
      search: qs.stringify({
        ...queryStringfromUrl,
        onCreditCardsView: 'true',
      }),
      pathname,
    });
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <EmptyState
        isTableFiltered={isTableFiltered}
        isTableEmpty={isTableEmpty}
        resetFilters={resetFilters}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<CreditCardsTableData>) => {
      onFilter(filterVal);

      const columns = filterVal?.map(({ id }) => id).join(', ');
      const values = filterVal?.map(({ value }) => value);

      track(CCWalletTrackingEvents.CC_MANAGER_TABLE_FILTERS_APPLIED, {
        orgId: organizationSlug,
        columns,
        values,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<CreditCardsTableData>[]) => {
      onSort(sortVal);

      const columns = sortVal?.map(({ id }) => id).join(', ');
      const isSortedByDescOrder = sortVal?.map(({ desc }) => desc);

      track(CCWalletTrackingEvents.CC_MANAGER_TABLE_SORTING_APPLIED, {
        orgId: organizationSlug,
        columns,
        isSortedByDescOrder,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSort]);

  return (
    <Flex overflow="hidden" direction="column" height="100%">
      <CreditCardsTable
        isLoadingConfigs={isLoadingConfigs || isResetPending}
        data={creditCardsTableData}
        key={`credit-card_table-${isFilterLoading}-${toolbarFilter}-${availableCreditCardsColumnIds.join(
          '-'
        )}`}
        loading={loading}
        defaultFilters={filters}
        filterOptions={filterOptions}
        defaultSortBy={sortBy}
        onFilter={handleFilter}
        customEmptyState={emptyState}
        onEndReached={onLoadMore}
        onSort={handleSort}
        search={queryStringFilter}
        onSearch={search => {
          handleDebounceSearch(search);
          updateSearchParam(queryParameter, search);
        }}
        minWidth="100%"
        width="max-content"
        configurationsTable={configurationsTable}
        columns={availableCreditCardsColumnIds}
        onUpdateColumnsConfigurations={handleUpdateConfigurations}
        onResetConfigurations={handleResetTableConfigurations}
        onRowClick={val => {
          openDrawer(val.id);
        }}
      />
    </Flex>
  );
};
