import { isNull, isUndefined, round } from 'lodash';

const maxPercentage = 99.99;
const minPercentage = 0;

export const formatPercentage = (value?: string) => {
  if (!value) return;

  const numericValue = Number(value.replace('%', ''));
  if (isNaN(numericValue)) {
    return value;
  }

  if (numericValue > maxPercentage) {
    return maxPercentage.toString().concat('%');
  }

  if (numericValue < minPercentage) {
    return minPercentage.toString().concat('%');
  }

  return round(numericValue, 2).toString().replace('%', '').concat('%');
};

export const extractPercentageValue = (value?: string | number) => {
  // can be replaced with isNil but typescript is not smart enough
  // throwing 'possible undefined' error on line 32
  if (isUndefined(value) || isNull(value)) return value;

  if (typeof value === 'number') return value;

  return Number(value.replace('%', ''));
};
