import { Text } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { ExportEntity } from '../../../../types';
import { ExportSummaryTitle } from '../../../components/ExportSummaryTitle';
import { parseDocumentExportStatus } from '../../../utils';

export const ApprovalsSummary = ({
  approvals,
  loading,
}: {
  approvals: ExportEntity[];
  loading?: boolean;
}) => {
  const [t] = useTranslation();

  const approvalsCount = {
    exported: approvals.filter(
      approval =>
        parseDocumentExportStatus(approval.exportStatus) ===
        ExportStatus.Exported
    ).length,
    partiallyExported: approvals.filter(
      approval =>
        parseDocumentExportStatus(approval.exportStatus) ===
        ExportStatus.PartialyExported
    ).length,
    failed: approvals.filter(
      approval =>
        parseDocumentExportStatus(approval.exportStatus) === ExportStatus.Failed
    ).length,
  };

  const approvalsSummary = [
    ...(approvalsCount.exported
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.approvalsCount', {
                  count: approvalsCount.exported,
                })}
                status={ExportStatus.Exported}
              />
            ),
            content: '',
          },
        ]
      : []),
    ...(approvalsCount.partiallyExported
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.approvalsCount', {
                  count: approvalsCount.partiallyExported,
                })}
                status={ExportStatus.PartialyExported}
              />
            ),
            content: '',
            footer: (
              <Text fontSize="small" color="yellow700">
                {t('export.exportHistorySummary.partiallyExported')}
              </Text>
            ),
          },
        ]
      : []),
    ...(approvalsCount.failed
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.approvalsCount', {
                  count: approvalsCount.failed,
                })}
                status={ExportStatus.Failed}
              />
            ),
            content: '',
            footer: (
              <Text fontSize="small" color="red700">
                {t('export.exportHistorySummary.failed')}
              </Text>
            ),
          },
        ]
      : []),
  ];

  const totalApprovalsCount = approvals.length;

  return totalApprovalsCount > 0 ? (
    <SummaryAccordionCard
      items={approvalsSummary}
      loading={loading}
      collapsable={false}
    />
  ) : null;
};
