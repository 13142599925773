import { useGetDocumentFileQuery } from 'generated-types/graphql.types';
import { useEffect } from 'react';

interface Options {
  nextDocumentId?: string;
  prevDocumentId?: string;
}

export const usePrefetchPdfData = ({
  nextDocumentId,
  prevDocumentId,
}: Options) => {
  const { data: nextDocumentFileData } = useGetDocumentFileQuery({
    variables: {
      id: nextDocumentId as string,
    },
    skip: !nextDocumentId,
  });

  const { data: prevDocumentFileData } = useGetDocumentFileQuery({
    variables: {
      id: prevDocumentId as string,
    },
    skip: !prevDocumentId,
  });

  const nextDocumentUrl = nextDocumentFileData?.getDocument?.documentFile?.url;
  const prevDocumentUrl = prevDocumentFileData?.getDocument?.documentFile?.url;

  useEffect(() => {
    let nextLink: HTMLLinkElement;
    let prevLink: HTMLLinkElement;

    if (nextDocumentUrl) {
      nextLink = document.createElement('link');

      nextLink.href = nextDocumentUrl;
      nextLink.rel = 'prefetch';
      nextLink.as = 'fetch';
      nextLink.crossOrigin = 'crossorigin';

      document.head.appendChild(nextLink);
    }

    if (prevDocumentUrl) {
      prevLink = document.createElement('link');

      prevLink.href = prevDocumentUrl;
      prevLink.rel = 'prefetch';
      prevLink.as = 'fetch';
      prevLink.crossOrigin = 'crossorigin';

      document.head.appendChild(prevLink);
    }

    return () => {
      nextLink?.remove();
      prevLink?.remove();
    };
  }, [nextDocumentId, nextDocumentUrl, prevDocumentId, prevDocumentUrl]);
};
