import { InsightsWidgetPreference } from 'generated-types/graphql.types';
import { getFilterFromUrl } from 'utils/table/getFilterFromUrl';

export const MAX_WIDGETS_ON_DASHBOARD = 6;
export const MAX_WIDGETS_ON_SECONDARY_SECTION = 20;
export const EXTENDED_MAX_WIDGETS_ON_SECONDARY_SECTION = 94;
export const MAX_WIDGETS_ALLOWED = 26;

export enum WidgetModalTab {
  DETAILS = 'details',
  BUDGET = 'budget',
}
export enum WIDGET_PREFERENCES_PROPS {
  onDashboard = 'onDashboard',
  landingTab = 'landingTab',
}

export type WidgetPreferencesQueryParams = {
  [WIDGET_PREFERENCES_PROPS.onDashboard]?: string[];
  [WIDGET_PREFERENCES_PROPS.landingTab]?: WidgetModalTab[];
};

export const availableWidgetPreferences = [
  WIDGET_PREFERENCES_PROPS.onDashboard,
  WIDGET_PREFERENCES_PROPS.landingTab,
];

type GetPreferencesWidgetfromUrl = (search: string) => {
  [key in keyof Omit<
    InsightsWidgetPreference & { landingTab: WidgetModalTab },
    '__typename' | 'widgetId'
  >]: string | WidgetModalTab | null;
};

export const getPreferencesWidgetfromUrl: GetPreferencesWidgetfromUrl =
  search => {
    const query = getFilterFromUrl<WidgetPreferencesQueryParams>(
      search,
      availableWidgetPreferences
    );

    const widgetPreferencesValues = {
      onDashboard: (query.onDashboard && query.onDashboard[0]) || null,
      landingTab: (query.landingTab && query.landingTab[0]) || null,
    };

    return widgetPreferencesValues;
  };

export type InsightsWidgetsFiltersFromUrl = {
  invoiceDate?: string;
  onDashboard?: string;
  landingTab?: WidgetModalTab;
};
