import {
  SortDirection,
  TeamsFilterInput,
  TeamsQuery,
  TeamsQueryVariables,
  TeamsSortField,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { debounce } from 'lodash';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Key, useCallback, useMemo, useState } from 'react';
import { NAME_SEARCH_PARAM } from '../../elements/List/TeamsListContainer';
import { teamsQuery } from '../queries';

export interface Member {
  id: string;
  fullName: string;
  avatarUrl: string | undefined;
}

export interface Team {
  id: string | undefined;
  name: string;
  isArchived?: boolean;
  members: Member[] | undefined;
  teamAdmin: Partial<Member> | undefined;
  updatedAt?: string;
}

export const useGetTeams = (filters: TeamsFilterInput = {}) => {
  const [sort, setSort] = useState<TeamsSortField>(TeamsSortField.CreatedAt);
  const { searchParams } = useMutateSearchParams();
  const searchQuery = searchParams.get(NAME_SEARCH_PARAM);
  const [name, setName] = useState(searchQuery);

  const teamsManagementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.teamsManagement
  );

  const handleSetSort = (sort: Key[]) => {
    setSort(sort[0] as TeamsSortField);
  };

  const isDescendingSort = useCallback((field: TeamsSortField): boolean => {
    const descSortFields: TeamsSortField[] = [
      TeamsSortField.CreatedAt,
      TeamsSortField.MemberCount,
    ];

    return descSortFields.includes(field);
  }, []);

  const computeVariables = useCallback(
    (): TeamsQueryVariables => ({
      input: {
        page: 1,
        limit: 20,
      },
      filters: {
        isArchived: filters.isArchived ?? false,
        membershipIds: filters.membershipIds ?? undefined,
        adminMembershipIds: filters.adminMembershipIds ?? undefined,
        ids: filters.ids ?? undefined,
      },
      queries: {
        name: name ? name : undefined,
      },
      sortBy: {
        direction: isDescendingSort(sort)
          ? SortDirection.Desc
          : SortDirection.Asc,
        field: sort,
      },
    }),
    [filters, isDescendingSort, name, sort]
  );

  const { data, loading, loadMore } = useDebouncedSearchPagination<
    TeamsQuery,
    TeamsQueryVariables
  >({
    query: teamsQuery,
    queryRootKey: 'teams',
    computeVariables,
    options: {
      skip: !teamsManagementFF,
    },
  });

  const teams = data?.teams?.records ?? [];

  const mappedTeams: Team[] = teams.map(team => {
    const admin = team.members.find(member => member.isAdmin);

    const members = team.members
      .filter(member => !member.isAdmin)
      .map(member => ({
        id: member.membership.id,
        fullName: `${member.membership.firstName} ${member.membership.lastName}`,
        avatarUrl: member.membership.avatarUrl ?? undefined,
      }));

    return {
      id: team.id,
      name: team.name,
      isArchived: team.isArchived,
      members,
      teamAdmin: {
        id: admin?.membership.id,
        fullName: `${admin?.membership.firstName} ${admin?.membership.lastName}`,
        avatarUrl: admin?.membership.avatarUrl ?? undefined,
      },
    };
  });

  const handleDebounceSearch = useMemo(() => {
    return debounce(setName, DEFAULT_DEBOUNCE_TIME);
  }, []);

  return {
    teams: mappedTeams,
    loading,
    loadMore,
    handleDebounceSearch,
    handleSetSort,
    sort,
  };
};
