import {
  fetchPolicy,
  PaginationWithSearchFilterHook,
  FilterOption,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  useContactsPaginationQuery,
  VatIdInfiniteScrollPaginationQuery,
  VatIdInfiniteScrollPaginationQueryVariables,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { vatIdInfiniteScrollPaginationQuery } from '../utils/queries';

export const useVatIdFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const computeVariables = useCallback(
    (inputString?: string): VatIdInfiniteScrollPaginationQueryVariables => ({
      queries: {
        vatId: inputString,
      },
    }),
    []
  );

  const {
    data: allContacts,
    loading: allContactsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    VatIdInfiniteScrollPaginationQuery,
    VatIdInfiniteScrollPaginationQueryVariables
  >({
    query: vatIdInfiniteScrollPaginationQuery,
    queryRootKey: 'vatIdPagination',
    computeVariables,
  });

  const { data: selectedContacts } = useContactsPaginationQuery({
    variables: {
      input: { page: 1, limit: filteredValues.length },
      filters: { vatId: filteredValues },
    },
    fetchPolicy,
    skip: filteredValues.length === 0 || Boolean(searchStr),
  });

  const byIds = selectedContacts?.contactsPagination.records ?? [];
  const byAccounNumber = allContacts?.vatIdPagination.records ?? [];

  const filterOptions: FilterOption[] = [...byIds, ...byAccounNumber]
    .filter(contact => Boolean(contact.vatId))
    .map(contact => ({
      label: contact.vatId?.value ?? '',
      id: contact.vatId?.value ?? '',
    }));

  return {
    filterOptions: uniqBy(filterOptions, 'id'),
    handleDebounceSearch,
    loading: allContactsLoading,
    loadMore,
  };
};
