import { Grid, InlineSkeleton, Item, ListView } from '@candisio/design-system';
import { useSap } from 'orgConfig/sap';
import { useWatch } from 'react-hook-form';
import { DocumentFormValues } from '../DocumentForm/toolkit/schema';
import { useBookingsFieldArrayContext } from './BookingsFieldArrayContext';
import { useSumOfBookingsAmounts } from './hooks/useSumOfBookingsAmounts';
import { ProcessingFormValues } from './processingFormSchema';
import { ProcessingFormSplitDocumentActionButton } from './ProcessingFormSplitDocumentActionButton';
import { ProcessingFormSplitItem } from './ProcessingFormSplitItem';

export interface ProcessingFormSplitListProps {
  /** Called when split bookings form should be shown */
  onSplitDocument?: (index?: number) => void;
  /** Display list as read only */
  readOnly?: boolean;
  showSplitList: boolean;
  isLoading?: boolean | undefined;
}

/** Lists the values of the bookings field array */
export const ProcessingFormSplitList = ({
  onSplitDocument,
  readOnly,
  isLoading,
  showSplitList,
}: ProcessingFormSplitListProps) => {
  const { shouldUseSapNetAmount } = useSap();
  const [grossAmount, currency, bookings] =
    useWatch<
      ProcessingFormValues | DocumentFormValues,
      ['grossAmount', 'currency', 'bookings']
    >({
      name: ['grossAmount', 'currency', 'bookings'],
    }) ?? [];

  const bookingsFieldArray = useBookingsFieldArrayContext();
  const splits = bookingsFieldArray?.fields ?? [];

  /** make sure to use bookings from the watch hook instead of the context
   * to avoid data inconsistency issues */
  const sumOfBookingsAmounts = useSumOfBookingsAmounts({ bookings });
  const remainingAmount = grossAmount - sumOfBookingsAmounts;

  const hasItems = splits.length > 1;
  const hasRemainingAmount = Boolean(remainingAmount) && !shouldUseSapNetAmount;

  return (
    <InlineSkeleton isLoading={isLoading} isBlockLevel>
      <Grid borderRadius="medium" overflow="hidden">
        <Grid
          borderBottom={
            hasItems
              ? `1px solid ${!hasRemainingAmount ? 'gray200' : undefined}`
              : undefined
          }>
          {showSplitList && (
            <ListView
              onAction={key => {
                onSplitDocument?.(Number(key));
              }}>
              {splits.map(({ id }, index) => (
                <Item
                  key={String(index)} // passed to onAction
                  textValue={id}>
                  <ProcessingFormSplitItem
                    key={id} // used by React Hook Form to trigger rerenders
                    name={`bookings.${index}`}
                    number={index + 1}
                    onEdit={() => {
                      onSplitDocument?.(index);
                    }}
                    readOnly={readOnly}
                    hasRemainingAmount={hasRemainingAmount}
                  />
                </Item>
              ))}
            </ListView>
          )}
        </Grid>
        <ProcessingFormSplitDocumentActionButton
          currency={currency}
          hasItems={hasItems}
          readOnly={readOnly}
          remainingAmount={remainingAmount}
          onSplitDocument={onSplitDocument}
          isSapNetAmount={shouldUseSapNetAmount}
        />
      </Grid>
    </InlineSkeleton>
  );
};
