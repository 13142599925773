import { useContext } from 'react';
import { InitNewExport } from 'views/Integrations/Export/elements/sidebar/Init/InitNewExport';
import { ExportContext } from '../../Context';
import { useExportDataGigaFactory } from '../../toolkit/hooks/useExportDataGigaFactory';
import { Sidebar } from '../../types';
import { ExportHistoryDetails } from './History/History';
import { NotReady } from './NotReady/NotReady';

export const ExportSidebar = () => {
  const state = useContext(ExportContext);
  const {
    counts: { notReadyToExportEntitiesCount },
  } = useExportDataGigaFactory(state);

  if (state.sidebar === Sidebar.INIT_NEW_EXPORT) {
    return <InitNewExport />;
  }

  if (state.sidebar === Sidebar.HISTORY) {
    return <ExportHistoryDetails />;
  }

  if (
    state.sidebar === Sidebar.NOT_READY &&
    notReadyToExportEntitiesCount > 0
  ) {
    return <NotReady />;
  }

  return null;
};
