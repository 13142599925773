import { BookingKey } from 'generated-types/graphql.types';
import { escapeRegex } from 'utils/regex';

export const queryTaxCodes = (
  bookingAccounts: BookingKey[],
  queryString: string
) => {
  const regExp = new RegExp(escapeRegex(queryString), 'i');

  return bookingAccounts.filter(bookingKey => {
    if (
      bookingKey.taxCode?.match(regExp) ||
      bookingKey.description.match(regExp)
    ) {
      return true;
    }

    return null;
  });
};
