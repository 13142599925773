import { Grid, Item, ListView, Text, useTheme } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useGetSettlementsPageBasedData } from 'views/CreditCards/CreditCardsStatements/hooks/useGetSettlementsPageBasedData';
import { SettlementEmptyState } from '../SettlementCard/SettlementEmptyState';
import { SettlementLoadingState } from '../SettlementCard/SettlementLoadingState';
import { SettlementCard } from '../SettlementCard/SettlementmentCard';

interface SettlementsSectionProps {
  dateFrom?: Date;
  dateTo?: Date;
}

export const SettlementsSection = ({
  dateFrom,
  dateTo,
}: SettlementsSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { radii } = useTheme();

  const { hasMore, loading, onLoadMore, settlementList } =
    useGetSettlementsPageBasedData({
      dateFrom,
      dateTo,
      status: undefined,
    });

  const { settlements, totalCount } = settlementList;

  if (loading && totalCount === 0) {
    return (
      <Grid gap="space16" height="fit-content">
        <Text fontSize="xxlarge">{t('statements.detail.settlement')}</Text>
        <SettlementLoadingState />
      </Grid>
    );
  }

  if (totalCount === 0) {
    return (
      <Grid gap="space16" height="fit-content">
        <Text fontSize="xxlarge">{t('statements.detail.settlement')}</Text>
        <SettlementEmptyState />
      </Grid>
    );
  }

  const indexOfLastSettlement = settlements.length - 1;

  return (
    <Grid templateRows="auto 1fr" gap="space16">
      <Text fontSize="xxlarge">{t('statements.detail.settlement')}</Text>
      <ListView
        style={{
          borderRadius: radii.medium,
        }}
        height="100%"
        onEndReached={hasMore ? onLoadMore : undefined}
        isVirtualized>
        {settlements.map((s, i) => (
          <Item key={s.id} textValue={s.id}>
            <SettlementCard
              key={s.id}
              {...s}
              isLastItem={indexOfLastSettlement === i}
            />
          </Item>
        ))}
      </ListView>
    </Grid>
  );
};
