import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { useSap } from 'orgConfig/sap';
import { useWatch } from 'react-hook-form';
import { DOCUMENT_TYPES } from 'views/Inbox/DocumentProcessing/consts';

export const useShowCreditMemoFieldsDocumentApprovalsForm = () => {
  const sap = useSap();
  const [type] = useWatch<DocumentApprovalFormValues, ['category.type']>({
    name: ['category.type'],
  });

  const isCreditMemo =
    sap.isActiveIntegration && type === DOCUMENT_TYPES.EINGANGSGUTSCHRIFT;

  return !isCreditMemo;
};
