import {
  Box,
  Grid,
  Separator,
  Text,
  Button,
  Tag,
} from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { useUserRoles } from 'hooks/useUserRoles';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/consts';
import { ComponentProps, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContractCustomSubCategoryDetailsDrawer } from 'views/Settings/ContractSubCategories/elements/Drawer/ContractCustomSubCategoryDetailsDrawer';
import { StorageFormComboBoxField } from './StorageFormComboBoxField';
import { StorageFormValues } from './storageFormSchema';

type StorageFormContractTypeFieldProps = ComponentProps<
  typeof StorageFormComboBoxField
>;

export const StorageFormContractTypeField = (
  props: StorageFormContractTypeFieldProps
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { showContractCustomSubCategories } = useEcm();

  const [addNewContractTypeOpen, setAddNewContractTypeOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { isAdmin } = useUserRoles();
  const { setValue } = useFormContext<StorageFormValues>();

  if (!showContractCustomSubCategories) {
    return (
      <HookFormSelectField
        label={t('storageForm.fields.contractType.label')}
        name="documentSubCategory"
        readOnly={props.readOnly}
        placeholder={t('storageForm.fields.contractType.placeholder')}
        items={props.defaultItems}
      />
    );
  }

  const createNewContractTypeCTA = (
    <Grid gap="space8" padding="space16">
      <Grid gap="space8">
        <Tag color="blue" callout>
          {t('common:tag.new')}
        </Tag>
        <Grid>
          <Text fontWeight="semibold" fontSize="basic" lineHeight="paragraph">
            {t('storageForm.fields.contractType.addNew.title')}
          </Text>
          <Text fontSize="basic" lineHeight="paragraph">
            {t('storageForm.fields.contractType.addNew.subtitle')}
          </Text>
        </Grid>
      </Grid>
      <Button
        color="gray"
        variant="secondary"
        onPointerDown={() => {
          setAddNewContractTypeOpen(true);
        }}>
        {t('storageForm.fields.contractType.addNew.button')}
      </Button>
    </Grid>
  );

  return (
    <>
      <StorageFormComboBoxField
        allowsCustomValue
        readOnly={props.readOnly}
        placeholder={t('storageForm.fields.contractType.placeholder')}
        onInputChange={value => {
          setInputValue(value);
        }}
        renderCustomDropdown={
          isAdmin
            ? list => (
                <Grid
                  templateRows="1fr auto"
                  maxHeight="inherit"
                  overflow="hidden">
                  <Box overflow="hidden">{list}</Box>
                  <Separator />
                  {createNewContractTypeCTA}
                </Grid>
              )
            : undefined
        }
        emptyListPlaceholder={isAdmin ? createNewContractTypeCTA : undefined}
        {...props}
      />

      {addNewContractTypeOpen && (
        <ContractCustomSubCategoryDetailsDrawer
          compact
          isOpen={addNewContractTypeOpen}
          onCloseDrawer={() => setAddNewContractTypeOpen(false)}
          defaultName={inputValue}
          onSubtypeCreated={subCategory => {
            setValue('documentSubCategory', subCategory.id);
          }}
        />
      )}
    </>
  );
};
