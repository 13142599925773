import { Text } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { ExportEntity } from '../../../../types';
import { ExportSummaryTitle } from '../../../components/ExportSummaryTitle';
import { parseDocumentExportStatus } from '../../../utils';
import { formatCountByType } from '../utils';

export const TransactionsSummary = ({
  cardTransactions,
  cardSettlements,
  loading,
}: {
  cardTransactions: ExportEntity[];
  cardSettlements: ExportEntity[];
  loading?: boolean;
}) => {
  const [t] = useTranslation();

  const transactionsCount = {
    exported: cardTransactions.filter(
      transaction =>
        parseDocumentExportStatus(transaction.exportStatus) ===
        ExportStatus.Exported
    ).length,
    partiallyExported: cardTransactions.filter(
      transaction =>
        parseDocumentExportStatus(transaction.exportStatus) ===
        ExportStatus.PartialyExported
    ).length,
    failed: cardTransactions.filter(
      transaction =>
        parseDocumentExportStatus(transaction.exportStatus) ===
        ExportStatus.Failed
    ).length,
  };

  const settlementsCount = {
    exported: cardSettlements.filter(
      settlement =>
        parseDocumentExportStatus(settlement.exportStatus) ===
        ExportStatus.Exported
    ).length,
    partiallyExported: cardSettlements.filter(
      settlement =>
        parseDocumentExportStatus(settlement.exportStatus) ===
        ExportStatus.PartialyExported
    ).length,
    failed: cardSettlements.filter(
      settlement =>
        parseDocumentExportStatus(settlement.exportStatus) ===
        ExportStatus.Failed
    ).length,
  };

  const transactionsSummary = [
    ...(transactionsCount.exported + settlementsCount.exported
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.transactionsCount', {
                  count: transactionsCount.exported + settlementsCount.exported,
                })}
                status={ExportStatus.Exported}
              />
            ),
            content: formatCountByType({
              transactionsCount: transactionsCount.exported,
              settlementsCount: settlementsCount.exported,
              t,
            }),
          },
        ]
      : []),
    ...(transactionsCount.partiallyExported + settlementsCount.partiallyExported
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.transactionsCount', {
                  count:
                    transactionsCount.partiallyExported +
                    settlementsCount.partiallyExported,
                })}
                status={ExportStatus.PartialyExported}
              />
            ),
            content: formatCountByType({
              transactionsCount: transactionsCount.partiallyExported,
              settlementsCount: settlementsCount.partiallyExported,
              t,
            }),
            footer: (
              <Text fontSize="small" color="yellow700">
                {t('export.exportHistorySummary.partiallyExported')}
              </Text>
            ),
          },
        ]
      : []),
    ...(transactionsCount.failed + settlementsCount.failed
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.transactionsCount', {
                  count: transactionsCount.failed + settlementsCount.failed,
                })}
                status={ExportStatus.Failed}
              />
            ),
            content: formatCountByType({
              transactionsCount: transactionsCount.failed,
              settlementsCount: settlementsCount.failed,
              t,
            }),
            footer: (
              <Text fontSize="small" color="red700">
                {t('export.exportHistorySummary.failed')}
              </Text>
            ),
          },
        ]
      : []),
  ];

  const totalTransactionsCount =
    cardTransactions.length + cardSettlements.length;

  return totalTransactionsCount > 0 ? (
    <SummaryAccordionCard items={transactionsSummary} loading={loading} />
  ) : null;
};
