import { gql } from '@apollo/client';

// This is an example of creating client-side schemas to mock a non-existing backend API that is work in progress,
// to unblock the frontend development.
export const getExampleUrl = gql`
  mutation getExampleUrl($name: String!) {
    getExampleUrl(name: $name) @client {
      getExampleUrl {
        id
        url
      }
    }
  }
`;

export const schemas = [getExampleUrl];
