import { useBookingKeysQuery } from 'generated-types/graphql.types';

export const useGetExistingTaxCodes = (initialTaxCode: string | undefined) => {
  const { data: allBookingKeysData, loading } = useBookingKeysQuery();

  const existingTaxCodes = (allBookingKeysData?.bookingKeys ?? [])
    .filter(existingBookingKey => initialTaxCode !== existingBookingKey.taxCode)
    .map(existingBookingKey => existingBookingKey.taxCode);

  return {
    existingTaxCodes,
    isExistingTaxCodesLoading: loading,
  };
};
