import { Flex } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import settingsPageQRCode from 'components/MobileAppPromotionBanner/media/Settings_page_banner_QR_code.jpg';
import { MobileAppPromotionBannerWithOptions } from 'components/MobileAppPromotionBanner/MobileAppPromotionBannerWithOptions';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { FeedbackCard } from 'views/Settings/MobileApplication/FeedbackCard';

export const MobileApplicationContainer = () => {
  const [t] = useTranslation();

  const creditCardsSetup = useCreditCardsSetup();

  return (
    <SettingsLayout width="700px" title={t('settings:mobileApplication.title')}>
      <Flex direction="column" gap="space24">
        <MobileAppPromotionBannerWithOptions
          qrCode={settingsPageQRCode}
          creditCardSetup={creditCardsSetup.isInUse}
          trackingContext="settings-page"
        />
        <FeedbackCard />
      </Flex>
      <InfoPanel
        externalLink={t('settings:mobileApplication.infoPanel.externalLink', {
          returnObjects: true,
        })}
        sections={t('settings:mobileApplication.infoPanel.sections', {
          returnObjects: true,
        })}
        title={t('settings:mobileApplication.infoPanel.title')}
      />
    </SettingsLayout>
  );
};
