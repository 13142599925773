export const CreditCardsSpentOverviewTrackingEvents = {
  INSIGHTS_TAB_CLICKED: 'credit_cards_insights_tab_clicked',
  OVERVIEW_TAB_CLICKED: 'credit_cards_overview_tab_clicked',
  SPENDINGS_CHART: {
    BAR_CLICKED: 'credit_cards_spendings_chart_bar_clicked',
    CREDIT_CARDS_FILTER_APPLIED:
      'credit_cards_spendings_chart_credit_cards_filter_applied',
    CATEGORY_FILTER_APPLIED:
      'credit_cards_spendings_chart_category_filter_applied',
    MERCHANT_NAME_FILTER_APPLIED:
      'credit_cards_spendings_chart_merchant_name_filter_applied',
    FILTER_RESET: 'credit_cards_spendings_chart_filter_reset_cliked',
  },
};
