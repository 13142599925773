import { MenuItem, useModal } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { EcmDocumentType } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { DownloadMenuButton } from './DownloadMenuButton';
import { DownloadPdfModal } from './DownloadPdfModal';
import { useDownloadCSVFileEcm } from './hooks/useDownloadCSVFileEcm';
import { useDownloadPDFFilesEcm } from './hooks/useDownloadPDFFilesEcm';

interface DownloadMenuButtonContractsContainerProps {
  selectedDocumentsCount: number;
  isTableLoading: boolean;
  isTableFiltered: boolean;
}

export const DownloadMenuButtonContractsContainer = ({
  isTableLoading,
  selectedDocumentsCount,
  isTableFiltered,
}: DownloadMenuButtonContractsContainerProps) => {
  const [searchParams] = useSearchParams();
  const { modalProps, close, open } = useModal();
  const { handleCSVDownload, isLoading } = useDownloadCSVFileEcm({
    isTableLoading,
    defaultFilters: {
      documentType: [EcmDocumentType.Contract],
    },
  });

  const { onPrepareDownloadPdfs, createPdfZipFileLoading } =
    useDownloadPDFFilesEcm({
      isTableLoading,
      handleClosePdfDownloadModal: close,
      defaultFilters: {
        documentType: [EcmDocumentType.Contract],
      },
    });

  const [t] = useTranslation();

  const items: Array<MenuItem> = [
    {
      id: 'download-CSV',
      label: t('archive:download.menuButton.options.downloadCSV'),
      onAction: !isLoading ? handleCSVDownload : undefined,
    },
    {
      id: 'download-PDFs',
      label: t('archive:download.menuButton.options.downloadPDF'),
      onAction: open,
    },
  ];

  const isMenuButtonDisabled =
    isLoading ||
    isTableLoading ||
    !selectedDocumentsCount ||
    createPdfZipFileLoading;

  const hasSearchQuery = searchParams.has(queryParameter);

  return (
    <>
      <DownloadMenuButton
        items={items}
        isDisabled={isMenuButtonDisabled}
        isLoading={isTableLoading}
        isTableFiltered={isTableFiltered}
        isTableSearched={hasSearchQuery}
        selectedDocumentsCount={selectedDocumentsCount}
      />
      <DownloadPdfModal
        {...modalProps}
        isTableLoading={isLoading}
        selectedDocumentsCount={selectedDocumentsCount}
        createPdfZipFileLoading={createPdfZipFileLoading}
        onPrepareDownload={onPrepareDownloadPdfs}
      />
    </>
  );
};
