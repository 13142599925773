import { useTheme } from '@candisio/design-system';
import { useExportableEntitiesCounter } from 'hooks/useExportableEntitiesCounter';
import { useTranslation } from 'react-i18next';
import exportsIcon from '../../images/export-icon.svg';
import { MenuItemCount, MenuItemIcon } from '../../style';

export const Exports = () => {
  const [t] = useTranslation();
  const { fontSizes } = useTheme();

  const {
    readyToExportEntitiesCount,
    loading: loadingExportableEntitiesCount,
  } = useExportableEntitiesCounter({
    fetchPolicy: 'cache-first',
  });

  const count = readyToExportEntitiesCount;
  const loading = loadingExportableEntitiesCount;

  const isCountShown = !loading && count !== 0;

  return (
    <>
      {isCountShown && (
        <MenuItemCount
          style={{
            fontSize: fontSizes.xsmall,
            padding: '0.05rem 0.35rem',
          }}>
          {count}
        </MenuItemCount>
      )}
      <MenuItemIcon>
        <img src={exportsIcon} alt={t('navigation.export')} />
      </MenuItemIcon>
    </>
  );
};
