import {
  InsightsWidgetBudgetsInput,
  Maybe,
} from 'generated-types/graphql.types';
import moment from 'moment';
import { getTranslationContext } from 'orgConfig';
import { WIDGET_TYPE } from '../Modals/WidgetModal/components/WidgetTypeSelection/TypeSelection/types';
import { DateRangeDefinition } from './types';

export const sourceTranslations = {
  [WIDGET_TYPE.COST_CENTER]:
    getTranslationContext() === 'sap'
      ? 'dashboard.createNewWidget.types.costCenter.widgetTitle_sap'
      : 'dashboard.createNewWidget.types.costCenter.widgetTitle',
  [WIDGET_TYPE.CONTACT]: 'dashboard.createNewWidget.types.contact.widgetTitle',
  [WIDGET_TYPE.GENERAL_LEDGER_ACCOUNT]:
    'dashboard.createNewWidget.types.generalLedgerAccount.widgetTitle',
};

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const toMonthlyBudgets = (budgets: InsightsWidgetBudgetsInput[]) => {
  const budgetWithDates: Array<{ date: Date; value?: number }> = [];

  budgets.forEach(({ year, monthlyBudgets }) => {
    months.forEach((month, i) => {
      budgetWithDates.push({
        date: new Date(year, i, 15),
        // @ts-ignore
        value: monthlyBudgets[month],
      });
    });
  });

  return budgetWithDates;
};

export const determineBudgetForDateRange = (
  invoiceDate: DateRangeDefinition,
  budgets: Maybe<InsightsWidgetBudgetsInput[]> | undefined
) => {
  if (!budgets) {
    return null;
  }

  let hasMissingBudget = false;
  let hasAnyBudget = false;
  let budget = 0;

  const monthlyBudgets = toMonthlyBudgets(budgets);
  const {
    values: { dateTo, dateFrom },
  } = invoiceDate;

  const asJavaScriptDate = {
    dateTo: moment(dateTo, 'DD.MM.YYYY').toDate(),
    dateFrom: moment(dateFrom, 'DD.MM.YYYY').toDate(),
  };

  monthlyBudgets.forEach(({ date, value }) => {
    if (asJavaScriptDate.dateFrom < date && date < asJavaScriptDate.dateTo) {
      if (!value) {
        hasMissingBudget = true;

        return;
      }

      hasAnyBudget = true;
      budget += value;
    }
  });

  if (hasMissingBudget || !hasAnyBudget) {
    return null;
  }

  return budget;
};
