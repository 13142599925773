import {
  InsightsWidget,
  useGetPaginatedInsightsWidgetsQuery,
} from 'generated-types/graphql.types';

export const useInsightsWidgets = () => {
  const widgetsQuery = useGetPaginatedInsightsWidgetsQuery();
  const widgetDefinitions = (widgetsQuery.data?.getPaginatedInsightsWidgets
    ?.records ?? []) as InsightsWidget[];

  const widgetDefinitionsOnDashboard = widgetDefinitions.filter(
    widget => widget.preferences.onDashboard
  );

  const widgetDefinitionsOnSecondarySection = widgetDefinitions.filter(
    widget => !widget.preferences.onDashboard
  );

  return {
    loading: widgetsQuery.loading,
    totalCount: widgetsQuery.loading
      ? null
      : widgetDefinitionsOnDashboard.length +
        widgetDefinitionsOnSecondarySection.length,
    dashboard: {
      widgets: widgetDefinitionsOnDashboard,
      count: widgetDefinitionsOnDashboard.length,
    },
    secondary: {
      widgets: widgetDefinitionsOnSecondarySection,
      count: widgetDefinitionsOnSecondarySection.length,
    },
  };
};
