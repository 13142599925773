import { BDS } from 'views/Integrations/Export/elements/sidebar/Init/api/bds/BDS';
import { CandisApi } from 'views/Integrations/Export/elements/sidebar/Init/api/candisApi/CandisApi';
import { RDS } from 'views/Integrations/Export/elements/sidebar/Init/api/rds/RDS';
import { SapB1 } from 'views/Integrations/Export/elements/sidebar/Init/api/sap-b1/SapB1';
import { Addison } from 'views/Integrations/Export/elements/sidebar/Init/file/addison/Addison';
import { CandisCsv } from 'views/Integrations/Export/elements/sidebar/Init/file/candis-csv/CandisCsv';
import { DatevCsv } from 'views/Integrations/Export/elements/sidebar/Init/file/datev-csv/DatevCsv';
import { DatevXml } from 'views/Integrations/Export/elements/sidebar/Init/file/datev-xml/DatevXml';
import { SapCsv } from 'views/Integrations/Export/elements/sidebar/Init/file/sap-csv/SapCsv';
import { Zip } from 'views/Integrations/Export/elements/sidebar/Init/file/zip/Zip';
import { ExportType } from 'views/Integrations/Export/types';

export const ExportTypeComponent = ({
  exportType,
}: {
  exportType: ExportType;
}) => {
  const componentMap = {
    [ExportType.DATEV]: <RDS />,
    [ExportType.SAP_B1]: <SapB1 />,
    [ExportType.DATEV_BDS]: <BDS />,
    [ExportType.ADDISON_FORMAT]: <Addison />,
    [ExportType.ZIP]: <Zip />,
    [ExportType.CANDIS_CSV]: <CandisCsv />,
    [ExportType.DATEV_CSV]: <DatevCsv />,
    [ExportType.DATEV_DXSO_ZIP]: <DatevXml />,
    [ExportType.SAP_CSV]: <SapCsv />,
    [ExportType.API]: <CandisApi />,
  };

  return componentMap[exportType] || null;
};
