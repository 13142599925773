import {
  Box,
  CustomEmptyStateProps,
  Flex,
  Grid,
  SelectionOptions,
  Text,
  useTheme,
} from '@candisio/design-system';
import { SearchField } from 'components/SearchField/SearchField';
import { AssociateCard } from 'components/Transactions/Table/AssociateCard/AssociateCard';
import { AnimatePresence } from 'framer-motion';
import { useLinkInvoiceToPurchaseOrderMutation } from 'generated-types/graphql.types';
import { Maybe } from 'generated-types/resolvers-types';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { PurchaseOrdersEmptyState } from 'views/Archive/PurchaseOrders/PurchaseOrdersEmptyState';
import {
  PURCHASE_ORDER_FILTER,
  usePurchaseOrderListFilters,
} from 'views/Archive/PurchaseOrders/usePurchaseOrderListFilters';
import { purchaseOrderTableColumns } from 'views/Archive/PurchaseOrders/utils';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { documentQueries } from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';
import { PurchaseOrdersTable } from '../Table/PurchaseOrdersTable';
import {
  ColumnWidths,
  PurchaseOrdersTableData,
  ViewUsingPruchaseOrders,
} from '../Table/types';
import { usePurchaseOrdersData } from '../Table/usePurchaseOrdersData';
import { purchaseOrderToTableData } from '../Table/util';
import { getFiltersWithContact } from './utils';

export interface PurchaseOrderListProps {
  documentId: string;
  documentAmount?: number | null;
  accountsPayableNumber?: Maybe<string>;
  closeModal: () => void;
  contactName?: string;
}
export const PurchaseOrderList = ({
  documentId,
  documentAmount,
  accountsPayableNumber,
  closeModal,
  contactName,
}: PurchaseOrderListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { space } = useTheme();
  const [searchParam, setSearchParam] = useState('');

  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<PurchaseOrdersTableData>();

  const filtersWithContact = getFiltersWithContact({
    filters,
    accountsPayableNumber,
  });

  const {
    purchaseOrderList,
    isLoadingPurchaseOrderList,
    onLoadMore,
    isTableEmpty,
    handleDebounceSearch,
  } = usePurchaseOrdersData({
    documentAmount,
    routeType: ViewUsingPruchaseOrders.ARCHIVE,
    filters: filtersWithContact,
    sortBy,
  });

  const { filterOptions } = usePurchaseOrderListFilters([
    PURCHASE_ORDER_FILTER.invoiceAssociationStatus_archive,
  ]);

  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<PurchaseOrdersTableData | null>(null);

  const [linkInvoiceToPurchaseOrderMutation, { loading }] =
    useLinkInvoiceToPurchaseOrderMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: documentQueries.forDraftForm, variables: { id: documentId } },
        { query: documentHistoryQuery, variables: { id: documentId } },
      ],
    });

  const handleLinkPurchaseOrderToDocument = async () => {
    if (selectedPurchaseOrder) {
      await linkInvoiceToPurchaseOrderMutation({
        variables: {
          invoiceId: documentId,
          purchaseOrderId: selectedPurchaseOrder.id,
        },
      });
    }

    closeModal();
  };

  const selectionOptions: SelectionOptions<PurchaseOrdersTableData> =
    useMemo(() => {
      return {
        onSelectionRowChanged: purchaseOrders => {
          setSelectedPurchaseOrder(purchaseOrders[0]);
        },
        selectedRowsIds: selectedPurchaseOrder
          ? [selectedPurchaseOrder.id]
          : [],
      };
    }, [selectedPurchaseOrder]);

  const arePurchaseOrdersSelected = Boolean(selectedPurchaseOrder);

  const handleResetSearch = () => {
    setSearchParam('');
    handleDebounceSearch('');
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <PurchaseOrdersEmptyState
        isTableFiltered={false}
        arePurchaseOrdersSelected={arePurchaseOrdersSelected}
        isUsingSearchQuery={!!searchParam}
        resetFilters={resetFilters}
        resetSearch={handleResetSearch}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<PurchaseOrdersTableData>) => {
      onFilter(filterVal);
    };
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<PurchaseOrdersTableData>[]) => {
      onSort(sortVal);
    };
  }, [onSort]);

  const columnWidths: ColumnWidths = {
    connectedInvoices: space.space256,
    lineItems: space.space256,
  };

  const columns = useMemo(() => {
    return purchaseOrderTableColumns;
  }, []);

  const searchPlaceholder = contactName
    ? t('purchase-orders:search.placeholderWithContact', {
        contactName,
      })
    : t('purchase-orders:search.placeholder');

  return (
    <Box height="100%" overflow="hidden">
      <Flex
        direction="column"
        height="100%"
        overflow="hidden"
        paddingTop="space16"
        paddingBottom="space32"
        paddingX="space32">
        <Grid
          templateColumns="1fr auto"
          borderTopRadius="basic"
          overflow="hidden"
          alignItems="center"
          borderBottom="1px solid gray200"
          background="white"
          padding="space12">
          <Box width="24rem">
            <SearchField
              clearTooltip={t('purchase-orders:search.clearTooltip')}
              placeholder={searchPlaceholder}
              value={searchParam}
              onChange={query => {
                setSearchParam(query);
                handleDebounceSearch(query);
              }}
              inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
            />
          </Box>
          <SyncFromSap type={SAP_SYNC.PurchaseOrdersProcessing} />
        </Grid>
        <PurchaseOrdersTable
          isLoading={isLoadingPurchaseOrderList}
          documentAmount={documentAmount}
          columns={
            accountsPayableNumber
              ? columns.filter(col => col !== 'contact')
              : columns
          }
          onEndReached={onLoadMore}
          onFilter={handleFilter}
          onSort={handleSort}
          customEmptyState={emptyState}
          data={purchaseOrderToTableData(purchaseOrderList)}
          filterOptions={filterOptions}
          columnWidths={columnWidths}
          defaultFilters={filters}
          defaultSortBy={sortBy}
          selectionOptions={selectionOptions}
          borderTopRadius="none"
          tableFooter={
            !isTableEmpty && (
              <Grid placeContent="center" padding="space24">
                <Text>
                  {t('purchase-orders:addPurchaseOrderModal.listFooter')}
                </Text>
              </Grid>
            )
          }
        />
      </Flex>
      <AnimatePresence>
        {selectedPurchaseOrder && (
          <AssociateCard
            onAssociate={handleLinkPurchaseOrderToDocument}
            footerText={t('addPurchaseOrderModal.footer.info')}
            ctaText={t('addPurchaseOrderModal.footer.buttonCTA')}
            flex="none"
            width="100%"
            position="absolute"
            bottom={0}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: '50%', opacity: 0 },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            loading={loading}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};
