import { Badge, Button, Flex, useTheme } from '@candisio/design-system';
import { AddOnIcon } from 'containers/Entitlements/components/Upsell/components/AddOnIcon';
import { ProvisionEntityType } from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableEntity, ExportContext } from '../../Context';
import { useExportContactsFactory } from '../../toolkit/hooks/useExportContactsFactory';
import { useExportDataGigaFactory } from '../../toolkit/hooks/useExportDataGigaFactory';
import { useExportPaymentConditionsFactory } from '../../toolkit/hooks/useExportPaymentConditionsFactory';
import { Entity, Table, View } from '../../types';

const CustomBadge = ({
  count,
  isActive,
}: {
  count: number;
  isActive: boolean;
}) => {
  const { colors } = useTheme();

  return (
    <Badge
      style={{
        border: 'none',
        color: isActive ? colors.gray0 : colors.gray800,
        background: isActive ? colors.gray600 : colors.gray400,
      }}>
      {count}
    </Badge>
  );
};

export const EntityTabs = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const state = useContext(ExportContext);
  const { bdsBought, bdsConnected, exportProvisionsFF, isCoreDataAvailable } =
    useDatev(); // BDS-checked

  const { entity, exportId, view, setEntity, setTable, availableEntities } =
    state;

  const { colors } = useTheme();
  const { showPromo: showCcPromo } = useCreditCardsSetup();

  const {
    paginatedDocumentsTableData,
    exportRowEntities: {
      ready: {
        documentEntities,
        transactionEntities,
        settlementEntities,
        provisionEntities,
        reversalEntities,
      },
      notReady: {
        notReadyTransactionsAsExportRows,
        notReadySettlementEntitiesAsExportRows,
      },
    },
  } = useExportDataGigaFactory(state);

  const contacts = useExportContactsFactory();
  const paymentConditions = useExportPaymentConditionsFactory();

  const hasInfiniteScroll = !exportId;

  const getDocumentsCount = () => {
    if (view === View.NOT_READY) {
      return 0;
    }

    const invoices = hasInfiniteScroll
      ? paginatedDocumentsTableData.totalCount
      : documentEntities.length;

    const provisions = provisionEntities.filter(
      entity => entity.provisionEntityType === ProvisionEntityType.Document
    ).length;

    const reversals = reversalEntities.filter(
      entity => entity.provisionEntityType === ProvisionEntityType.Document
    ).length;

    if (!exportProvisionsFF || !bdsConnected) return invoices;

    return invoices + provisions + reversals;
  };

  const getCreditCardsCount = () => {
    if (view === View.NOT_READY) {
      return (
        notReadyTransactionsAsExportRows.length +
        notReadySettlementEntitiesAsExportRows.length
      );
    }

    const settlements = settlementEntities.length;
    const transactions = transactionEntities.length;

    const provisions = provisionEntities.filter(
      entity => entity.provisionEntityType === ProvisionEntityType.Transaction
    ).length;

    const reversals = reversalEntities.filter(
      entity => entity.provisionEntityType === ProvisionEntityType.Transaction
    ).length;

    return transactions + provisions + reversals + settlements;
  };

  const getCoreDataCount = () => {
    if (view === View.NOT_READY) {
      return 0;
    }

    return contacts.count.newAndUpdated + paymentConditions.count.newAndUpdated;
  };

  const DocumentsButton = () => {
    const onClick = () => setEntity(Entity.DOCUMENT);

    const count = getDocumentsCount();
    const isActive = entity === Entity.DOCUMENT;

    const background = isActive ? colors.gray400 : colors.gray250;

    return (
      <Button
        data-key={Entity.DOCUMENT}
        variant="secondary"
        style={{ background }}
        onClick={onClick}>
        <Flex gap="0.25rem" alignItems="center" justifyContent="space-evenly">
          {t('tabs.documents')}
          {count > 0 && <CustomBadge isActive={isActive} count={count} />}
        </Flex>
      </Button>
    );
  };

  const CreditCardsButton = () => {
    const onClick = () => {
      setEntity(Entity.TRANSACTION);
      setTable(Table.APPROVALS);
    };

    const count = getCreditCardsCount();
    const isActive = entity === Entity.TRANSACTION;

    const showCountBadge = !showCcPromo && count > 0;

    const background = isActive ? colors.gray400 : colors.gray250;

    return (
      <Button
        data-key={Entity.TRANSACTION}
        variant="secondary"
        style={{ background }}
        onClick={onClick}>
        <Flex gap="0.25rem" alignItems="center" justifyContent="space-evenly">
          {t('tabs.creditCards')}
          {showCcPromo && <AddOnIcon />}
          {showCountBadge && <CustomBadge isActive={isActive} count={count} />}
        </Flex>
      </Button>
    );
  };

  const CoreDataButton = () => {
    const onClick = () => {
      setEntity(Entity.CORE_DATA);
      setTable(Table.CONTACTS);
    };

    const count = getCoreDataCount();
    const isActive = entity === Entity.CORE_DATA;

    const showCountBadge = bdsBought && count > 0;

    const background = isActive ? colors.gray400 : colors.gray250;

    return (
      <Button
        data-key={Entity.CORE_DATA}
        variant="secondary"
        style={{ background }}
        onClick={onClick}>
        <Flex gap="0.25rem" alignItems="center" justifyContent="space-evenly">
          {t('tabs.coreData')}
          {!bdsBought && <AddOnIcon />}
          {showCountBadge && <CustomBadge isActive={isActive} count={count} />}
        </Flex>
      </Button>
    );
  };

  const entityComponentMap: Record<AvailableEntity, ReactNode> = {
    [Entity.DOCUMENT]: <DocumentsButton key="DocumentsButton" />,
    [Entity.TRANSACTION]: <CreditCardsButton key="CreditCardsButton" />,
    [Entity.CORE_DATA]: isCoreDataAvailable ? (
      <CoreDataButton key="CoreDataButton" />
    ) : null,
  };

  useEffect(() => {
    void paymentConditions.count.refetch();
    void contacts.count.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex gap="space16">
      {availableEntities.map(e => {
        return entityComponentMap[e];
      })}
    </Flex>
  );
};
