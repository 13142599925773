import { Box, Flex } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useUpdateDatevCreditCardsLedgerMutation } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { useDatevBookingAccountValidation } from 'views/Settings/Integrations/DATEV/useDatevBookingAccountValidation';
import { refetchDatevData } from '../../../../Integrations/DATEV/gql';
import { getPlaceholderBookingAccount } from './BookingAccountForm.helper';

interface BookingAccountFormProps {
  bookingAccount: string;
  accountLength: number;
}

type BookingAccountFormData = {
  bookingAccount: string;
};

export const BookingAccountForm = ({
  accountLength,
  bookingAccount,
}: BookingAccountFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { schema, errorMessages } =
    useDatevBookingAccountValidation('bookingAccount');

  const { bdsBought } = useDatev(); // BDS-checked
  const accountType = bdsBought ? 'creditCardAccount' : 'cashLedgerAccount';

  const form = useForm({
    defaultValues: { bookingAccount },
    context: { t },
    resolver: zodResolver({
      zodSchema: schema({ t }),
      errorMessages: errorMessages({
        t,
        label: `creditCardExporting.${accountType}.textInput.ariaLabel`,
      }),
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
    mode: 'onSubmit',
  });

  const [updateDatevCreditCardsLedger, { loading: isLoading }] =
    useUpdateDatevCreditCardsLedgerMutation();

  const onUpdateBookingAccount = useCallback(
    async ({ bookingAccount }: BookingAccountFormData) => {
      const result = await updateDatevCreditCardsLedger({
        variables: { bookingAccount, transitAccount: undefined },
        refetchQueries: refetchDatevData,
      });

      return result;
    },
    [updateDatevCreditCardsLedger]
  );

  const handleSubmit = async ({
    bookingAccount: value,
  }: BookingAccountFormData) => {
    const bookingAccount = !value ? '' : value;

    const result = await onUpdateBookingAccount({ bookingAccount });

    if (result.data) {
      form.resetField('bookingAccount', {
        defaultValue: bookingAccount,
      });
    }
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end">
        <HookFormTextField
          autoFocus={true}
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          label={t(`creditCardExporting.${accountType}.textInput.ariaLabel`)}
          name="bookingAccount"
          // eslint-disable-next-line candis/no-template-strings-inside-translation
          placeholder={t(
            `creditCardExporting.${accountType}.textInput.placeholder`,
            {
              accountNumber: getPlaceholderBookingAccount(accountLength),
            }
          )}
          clearable={false}
        />
        <Box paddingTop="space16">
          <QuickSaveButtons
            isFormDirty={isFormDirty}
            resetField={() => form.resetField('bookingAccount')}
            isLoading={isLoading}
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};
