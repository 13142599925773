import { Button, MenuButton, MenuItem } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

interface DownloadMenuButtonProps {
  isDisabled: boolean;
  isLoading: boolean;
  isTableFiltered: boolean;
  isTableSearched: boolean;
  items?: Array<MenuItem>;
  //* onClick can be provided if the menu button should not open a menu but instead trigger an action directly, do not pass `items` in this situation
  onClick?: () => void;
  selectedDocumentsCount: number;
}

export const DownloadMenuButton = ({
  isDisabled,
  isLoading,
  isTableFiltered,
  isTableSearched,
  items,
  onClick,
  selectedDocumentsCount,
}: DownloadMenuButtonProps) => {
  const [t] = useTranslation();

  const buttonText =
    !isTableFiltered && !isTableSearched
      ? t('archive:download.menuButton.label', {
          count: selectedDocumentsCount,
        })
      : t('archive:download.menuButton.labelFiltered', {
          count: selectedDocumentsCount,
        });

  if (onClick && !items) {
    return (
      <Button
        loading={isLoading}
        disabled={isDisabled}
        variant="tertiary"
        icon="arrowDown"
        iconPosition="left"
        onClick={onClick}>
        {buttonText}
      </Button>
    );
  }

  return (
    <MenuButton
      variant="tertiary"
      position="relative"
      loading={isLoading}
      disabled={isDisabled}
      icon="arrowDown"
      iconPosition="left"
      items={items}
      selectionMode="single">
      {buttonText}
    </MenuButton>
  );
};
