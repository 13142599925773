import Papa, { ParseResult } from 'papaparse';
import { CSVFileParseError } from 'views/Contacts/ContactImport/errors';

export enum FileEncoding {
  UTF_8 = 'utf-8',
  ISO_8859_1 = 'ISO-8859-1',
  WINDOWS_1252 = 'windows-1252',
  ASCII = 'ascii',
  LATIN_1 = 'latin1',
}

export const detectCSVEncoding = async (file: File): Promise<FileEncoding> => {
  const fileReader = new FileReader();
  const encoding = await new Promise<FileEncoding>(resolve => {
    fileReader.onload = event => {
      const csv = event.target?.result;
      if (typeof csv === 'string') {
        // � means its ASCII encoding that utf-8 cannot handle
        if (csv.includes('�')) {
          return resolve(FileEncoding.ASCII);
        }
      }

      return resolve(FileEncoding.UTF_8);
    };

    fileReader.readAsText(file);
  });

  return encoding;
};

export async function parseCSVFile(
  file: File,
  encoding: FileEncoding = FileEncoding.UTF_8
) {
  return new Promise<ParseResult<string[]>>((resolve, reject) =>
    Papa.parse<string[]>(file, {
      skipEmptyLines: 'greedy',
      delimiter: ';',
      complete: result => {
        if (result.errors.length) {
          return reject(new CSVFileParseError(result.errors));
        }

        return resolve(result);
      },
      encoding,
      error: reject,
    })
  );
}
