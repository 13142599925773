import {
  CostCenterDataFragment,
  CostCenterInfiniteScrollPaginationQuery,
  CostCenterInfiniteScrollPaginationQueryVariables,
  CostCenterSortField,
  CostCenterTypes,
  SortDirection,
  useCountCostCentersQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { costCenterInfiniteScrollPagination } from 'views/queries/cost-centers';

interface CostCenterData {
  activeCount: number;
  archivedCount: number;
  costCentersData: CostCenterDataFragment[];
  countLoading: boolean;
  handleDebounceSearch: (inputStr: string) => void;
  isLoading: boolean;
  onEndReached: () => void;
}

export const useCostCenterData = (
  archivedTabEnabled: boolean,
  sortBy: CostCenterSortField,
  type: CostCenterTypes | null
): CostCenterData => {
  const [search] = useSearchParams();
  const { data: activeCount, loading: activeCountLoading } =
    useCountCostCentersQuery({
      variables: {
        filters: {
          isArchived: false,
        },
      },
    });

  const { data: archivedCount, loading: archivedCountLoading } =
    useCountCostCentersQuery({
      variables: {
        filters: {
          isArchived: true,
        },
      },
    });

  const approvers = search.get('approvers');

  const computeVariables = useCallback(
    (inputStr?: string): CostCenterInfiniteScrollPaginationQueryVariables => ({
      filters: {
        isArchived: archivedTabEnabled,
        type: type ? [type] : undefined,
        ...(approvers ? { approvers: [approvers] } : {}),
      },
      sortBy: {
        direction:
          sortBy === CostCenterSortField.CreatedAt
            ? SortDirection.Desc
            : SortDirection.Asc,
        field: sortBy,
      },
      queries: {
        readableName: inputStr,
      },
    }),
    [archivedTabEnabled, type, sortBy, approvers]
  );

  const {
    data: costCentersData,
    handleDebounceSearch,
    loading: isLoading,
    loadMore: onEndReached,
  } = useDebouncedSearchPagination<
    CostCenterInfiniteScrollPaginationQuery,
    CostCenterInfiniteScrollPaginationQueryVariables
  >({
    query: costCenterInfiniteScrollPagination,
    queryRootKey: 'costCenterPagination',
    computeVariables,
  });

  return {
    activeCount: activeCount?.countCostCenters ?? 0,
    archivedCount: archivedCount?.countCostCenters ?? 0,
    costCentersData: costCentersData?.costCenterPagination.records ?? [],
    countLoading: activeCountLoading || archivedCountLoading,
    handleDebounceSearch,
    isLoading,
    onEndReached,
  };
};
