import { z } from 'zod';
import { DocumentTags } from '../../../toolkit/hooks/useDocumentTags';
import {
  MAX_DOCUMENT_TAG_NAME_LENGTH,
  MIN_DOCUMENT_TAG_NAME_LENGTH,
} from '../../Drawer/Form/documentTagDetailsFormSchema';

export const mergeTagsFormSchema = (
  { tags }: MergeTagsFormSchemaOptions = { tags: [] }
) =>
  z.object({
    targetTagId: z.string(),
    customName: z
      .string()
      .trim()
      .min(MIN_DOCUMENT_TAG_NAME_LENGTH)
      .max(MAX_DOCUMENT_TAG_NAME_LENGTH)
      .nullable()
      .superRefine((value, context) => {
        const isDuplicate = tags.some(({ name }) => name === value);
        if (isDuplicate) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            params: {
              translationKey: 'documentTags.drawer.toast.duplicateName',
            },
          });
        }
      }),
  });

export type MergeTagsFormOutput = z.infer<
  ReturnType<typeof mergeTagsFormSchema>
>;

export interface MergeTagsFormSchemaOptions {
  tags: DocumentTags;
}
