export const absenceFormErrorMessages = {
  fromDate: {
    label: 'header.profile.tabs.update.absence.form.errors.fromDate.label',
    custom: {
      translationKey:
        'header.profile.tabs.update.absence.form.errors.fromDate.custom',
    },
  },
  toDate: {
    label: 'header.profile.tabs.update.absence.form.errors.toDate.label',
    custom: {
      translationKey:
        'header.profile.tabs.update.absence.form.errors.toDate.custom',
    },
  },
  note: {
    label: 'header.profile.tabs.update.absence.form.errors.note.label',
    custom: {
      translationKey:
        'header.profile.tabs.update.absence.form.errors.note.custom',
    },
  },
  substitute: {
    label: 'header.profile.tabs.update.absence.form.errors.substitute.label',
  },
};
