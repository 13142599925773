import { IconProps } from '@candisio/design-system';
import { Routes } from 'models';
import { forwardRef, ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { IconLink } from './IconLink/IconLink';

export type NavigationIconType = {
  icon: IconProps['icon'];
  route?: string;
  label?: string;
  expanded?: boolean;
  showMenuIcon?: boolean;
  pulseIndicator?: ReactNode;
};

export const NavigationIcon = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  NavigationIconType
>(
  (
    {
      icon,
      route,
      label,
      expanded,
      showMenuIcon = true,
      pulseIndicator,
      ...restProps
    }: NavigationIconType,
    ref
  ) => {
    const { organizationSlug } = useParams();
    const location = useLocation();

    const isActive = () => {
      if (!route) return false;

      const targetRoute = route.includes(Routes.SETTINGS)
        ? Routes.SETTINGS
        : route;

      return location.pathname.includes(targetRoute);
    };

    if (!showMenuIcon) return null;

    if (route) {
      return (
        <IconLink
          icon={icon}
          label={label}
          expanded={expanded}
          to={`/${organizationSlug}${route}`}
          isActive={isActive()}
          pulseIndicator={pulseIndicator}
          ref={ref}
          {...restProps}
        />
      );
    }

    return (
      <IconLink
        icon={icon}
        label={label}
        expanded={expanded}
        pulseIndicator={pulseIndicator}
        isActive={isActive()}
        ref={ref}
        {...restProps}
        as="button"
      />
    );
  }
);
