import { Box } from '@candisio/design-system';
import { CreditCardRequestStateLayout } from 'containers/credit-cards/components/CreditCardsSection/CreditCardRequestStateLayout';
import { PendingCreditCardRequestBanner } from 'containers/credit-cards/components/CreditCardsSection/Requests/PendingCreditCardRequestBanner';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { ReactNode } from 'react';
import { CreditCardAdoptedInfoBanner } from 'views/Dashboard/components/CreditCardAdoptedInfoBanner';
import { CreditCardsNotAdoptedImagePlaceholder } from 'views/Dashboard/components/CreditCardsNotAdoptedImagePlaceholder';
import { RouteTypeProps } from '../CreditCardsSection/utils';

export interface CreditCardBannerContainerProps {
  children: ReactNode;
  routeType: RouteTypeProps;
}

export const CreditCardBannerContainer = ({
  children,
  routeType,
}: CreditCardBannerContainerProps) => {
  const creditCardsSetup = useCreditCardsSetup();
  const { isAdmin } = useUserRoles();

  if (
    !creditCardsSetup.isOnboardingStatusLoading &&
    !creditCardsSetup.isAddonBought
  ) {
    return <CreditCardsNotAdoptedImagePlaceholder routeType={routeType} />;
  }

  if (creditCardsSetup.isOnboardingNeeded && !isAdmin) {
    return <CreditCardsNotAdoptedImagePlaceholder routeType={routeType} />;
  }

  return (
    <Box height="100%">
      {creditCardsSetup.isOnboardingNeeded ? (
        <CreditCardRequestStateLayout routeType={routeType}>
          <CreditCardAdoptedInfoBanner routeType={routeType} />
        </CreditCardRequestStateLayout>
      ) : creditCardsSetup.isOnboardingInProgress ? (
        <CreditCardRequestStateLayout routeType={routeType}>
          <PendingCreditCardRequestBanner />
        </CreditCardRequestStateLayout>
      ) : (
        children
      )}
    </Box>
  );
};
