import { useSearchParams } from 'react-router-dom-v5-compat';

/** Gets/sets URL state for bank account drawer */
export const useBankAccountURLState = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openId = searchParams.get('iban');

  const isOpen = openId !== null;

  const setOpenId = (id: string | null) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (id) {
      newSearchParams.set('iban', id);
    } else {
      newSearchParams.delete('iban');
    }

    setSearchParams(newSearchParams);
  };

  return { isOpen, openId, setOpenId };
};
