import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';

export const getDiscountAmount = (
  grossAmount?: number,
  discountPercentage?: number
) => {
  if (
    typeof grossAmount !== 'number' ||
    typeof discountPercentage !== 'number'
  ) {
    return;
  }

  return roundToCurrencyPrecision((grossAmount / 100) * discountPercentage);
};
