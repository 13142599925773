import { useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useCreditCardOptions } from 'views/Settings/Teams/toolkit/hooks/useCreditCardOptions';
import { useGetTeamById } from 'views/Settings/Teams/toolkit/hooks/useGetTeamById';
import { useGetTeamSettingsForCreditCards } from 'views/Settings/Teams/toolkit/hooks/useGetTeamSettingsForCreditCards';
import { useSetTeamSettingsForCreditCard } from 'views/Settings/Teams/toolkit/hooks/useSetTeamSettingsForCreditCard';
import {
  CreditCardSetupFormOutput,
  CreditCardSetupFormValues,
} from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormSchema';
import { CreditCardsSetupForm } from './CreditCardsSetupForm';

interface CreditCardsSetupContainerProps {
  onGoBack: () => void;
  onClose: () => void;
}

export const CreditCardsSetupContainer = ({
  onGoBack,
  onClose,
}: CreditCardsSetupContainerProps) => {
  const { id } = useParams<{
    id: string;
  }>();

  const [search, setSearch] = useState('');

  const { loading, settingsForCreditCards } = useGetTeamSettingsForCreditCards({
    teamId: id,
  });

  const { team, loading: isLoadingTeamDetails } = useGetTeamById({ id });

  const { setTeamCreditCardSettings, teamCCSettingsPending } =
    useSetTeamSettingsForCreditCard();

  const teamMembersIds = (team.members ?? []).map(member => member.id);

  const teamAdminId = team.teamAdmin?.id ? [team.teamAdmin?.id] : [];

  const teamAdminName = team.teamAdmin?.fullName;

  const {
    creditCardOptions,
    handleDebounceSearch,
    loadMore,
    loading: isLoadingCreditCardOptions,
  } = useCreditCardOptions({
    searchStr: search,
    selectedValues: settingsForCreditCards.creditCards,
    teamMemberIds: [...teamMembersIds, ...teamAdminId],
  });

  const handleSearch = (value: string) => {
    setSearch(value);
    handleDebounceSearch(value);
  };

  const handleSubmit = async (formData: CreditCardSetupFormOutput) => {
    const response = await setTeamCreditCardSettings(formData);

    if (!response) {
      return;
    }

    onClose();
  };

  const defaultValues: CreditCardSetupFormValues = {
    cards: settingsForCreditCards.creditCards,
    limit: settingsForCreditCards.limit,
    oneTimeCardLimit: settingsForCreditCards.oneTimeCardsLimit,
    isTeamAdminAllowedToManageCards:
      settingsForCreditCards.isTeamAdminAllowedToManageCards,
    isACardAssignedToTeam: settingsForCreditCards.creditCards.length > 0,
  };

  return (
    <CreditCardsSetupForm
      isLoadingCreditCardOptions={
        isLoadingCreditCardOptions || loading || isLoadingTeamDetails
      }
      defaultValues={defaultValues}
      creditCardOptions={creditCardOptions}
      teamAdminName={teamAdminName}
      search={search}
      isUpdateCCTeamSettingsPending={teamCCSettingsPending}
      onGoBack={onGoBack}
      onSubmit={handleSubmit}
      onSearch={handleSearch}
      onLoadMore={loadMore}
    />
  );
};
