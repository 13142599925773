export const InboxContractManagementEvents = {
  TAB_OPENED: 'inbox_cm_tab_opened',
  DEMO_BTN_CLICKED: 'inbox_cm_demo_btn_clicked',
  MORE_INFO_CLICKED: 'inbox_cm_more_info_clicked',
  MODAL_DEMO_BTN_CLICKED: 'inbox_cm_modal_demo_btn_clicked',
};

export const ArchiveContractManagementEvents = {
  TAB_OPENED: 'archive_cm_tab_opened',
  DEMO_BTN_CLICKED: 'archive_cm_demo_btn_clicked',
  MORE_INFO_CLICKED: 'archive_cm_more_info_clicked',
  MODAL_DEMO_BTN_CLICKED: 'archive_cm_modal_demo_btn_clicked',
};
