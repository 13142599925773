import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const ACCOUNTING_AREAS_NAME = {
  MIN: 3,
  MAX: 30,
};

export const ACCOUNTING_AREAS_DESCRIPTION = {
  MIN: 3,
  MAX: 100,
};

export const accountingAreasFormSchema = z.object({
  name: z
    .string()
    .min(ACCOUNTING_AREAS_NAME.MIN)
    .max(ACCOUNTING_AREAS_NAME.MAX),
  description: z
    .string()
    .min(ACCOUNTING_AREAS_DESCRIPTION.MIN)
    .max(ACCOUNTING_AREAS_DESCRIPTION.MAX),
});

export type AccountingAreasFormOutput = z.infer<
  typeof accountingAreasFormSchema
>;
export type AccountingAreasFormValues = Partial<AccountingAreasFormOutput>;
export type AccountingAreasErrorMessages = ErrorMessages<
  typeof accountingAreasFormSchema
>;
