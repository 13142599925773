import {
  Button,
  Flex,
  Grid,
  Icon,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface SwitcherItem<TValue extends string> {
  /** Is item disabled? */
  disabled?: boolean;
  /** Item label displayed in button */
  label: string;
  /** Tooltip message */
  tooltip?: string;
  /** Item value */
  value: TValue;
  /** Show a tag next to the label
   *
   * `true` => "New"
   * `false` => "Upgrade"; show as promo
   * `undefined` => don't show a tag
   */
  isAddon?: boolean;
}

export interface SwitcherProps<TValue extends string> {
  /** (Uncontrolled) initially-selected value */
  defaultValue?: TValue;
  /** Available items */
  items: Array<SwitcherItem<TValue>>;
  /** Called when currently-selected value changes */
  onChange: (value: TValue) => void;
  /** (Controlled) currently-selected value */
  value?: TValue;
}

const AddonIcon = () => {
  const [t] = useTranslation();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  return (
    <>
      <Icon
        icon="arrowUpCircleFilled"
        color="purple500"
        size="space18"
        {...triggerProps}
        ref={triggerRef}
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('tag.addOn')}
        </Tooltip>
      )}
    </>
  );
};

/**
 * A button group that switches between different values
 *
 * @todo focus buttons using arrow keys
 * @todo move to design system?
 */
export const Switcher = <TValue extends string = string>({
  items,
  onChange,
  value,
}: SwitcherProps<TValue>) => {
  const { tooltipProps, tooltipRef, triggerProps, triggerRef } = useTooltip();

  return (
    <Grid columns={items.length} overflow="hidden" borderRadius="full">
      {items.map(item => {
        const hasTag = item.isAddon !== undefined;
        const showAddon = hasTag && item.isAddon;

        return (
          <Button
            key={item.value}
            {...triggerProps}
            ref={triggerRef}
            disabled={item.disabled}
            isPressed={value === item.value}
            style={{ borderRadius: 0 }}
            variant="secondary"
            onClick={() => onChange(item.value)}>
            <Flex gap="space4" alignItems="center">
              {item.label}
              {showAddon && <AddonIcon />}
            </Flex>
            {item.tooltip && tooltipProps.isOpen && (
              <Tooltip {...tooltipProps} ref={tooltipRef}>
                {item.tooltip}
              </Tooltip>
            )}
          </Button>
        );
      })}
    </Grid>
  );
};
