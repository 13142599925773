import { Flex, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { matchStates } from '../consts';
import { ThreeWayMatchGoodsReceiptDetail } from '../types';
import { ThreeWayMatchTableRow } from './ThreeWayMatchTable';

const { success, warning } = matchStates;

const GoodsReceiptsCountDetailsCard = ({
  data,
}: {
  data: ThreeWayMatchGoodsReceiptDetail;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <Flex direction="column" gap="space4">
      {data.map(item => (
        <Flex key={item.receiptNumber} direction="column">
          <Text color="gray800">
            {t('goodsReceipts.columns.receiptNumber')}: {item.receiptNumber}
          </Text>
          <Text>
            {t('threeWayMatch.tabs.quantity')} {item.quantity}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export const ThreeWayMatchQuantityCell = (
  props: CellProps<ThreeWayMatchTableRow, number>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  if (!props.value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const isMatched = props.row.original.isQuantityMatched;
  const detailsLength = props.row.original.goodsReceiptsDetails?.length;
  const shouldShowTooltip =
    props.column.id === 'goodsReceiptQuantity' &&
    !!detailsLength &&
    detailsLength > 1;

  return (
    <>
      <Flex>
        <Text
          color={isMatched ? success.color : warning.color}
          {...triggerProps}
          ref={triggerRef}
          style={{ cursor: shouldShowTooltip ? 'pointer' : 'default' }}>
          {props.value}
        </Text>
      </Flex>
      {isOpen && shouldShowTooltip && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <GoodsReceiptsCountDetailsCard
            data={props.row.original.goodsReceiptsDetails ?? []}
          />
        </Tooltip>
      )}
    </>
  );
};
