import { useDatev } from 'orgConfig/datev';
import { useContext } from 'react';
import { ExportContext } from 'views/Integrations/Export/Context';
import { Table, View } from 'views/Integrations/Export/types';

export const useShowExportAllCoreData = () => {
  const { table, view } = useContext(ExportContext);

  const { bdsBought, exportDatevBdsViaIntegrationSvcFF } = useDatev();

  const isReadyView = view === View.READY;

  const isCoreDataTableActive =
    table === Table.CONTACTS || table === Table.PAYMENT_CONDITIONS;

  const showExportAllCoreData =
    bdsBought &&
    exportDatevBdsViaIntegrationSvcFF &&
    isReadyView &&
    isCoreDataTableActive;

  return showExportAllCoreData;
};
