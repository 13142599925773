import { Text, Grid, Flex } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface NationalSettingsProps {
  nationalRegisterCourt: string | undefined;
  nationalRegisterId: string | undefined;
  nationalRegisterType: string | undefined;
}

export const NationalSettings = ({
  nationalRegisterCourt,
  nationalRegisterId,
  nationalRegisterType,
}: NationalSettingsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid gap="space8" fontSize="basic" alignItems="start" alignContent="start">
      <Text fontWeight="semibold">
        {t(
          'insights.organizationSettingsDrawer.form.readOnlySettings.registration'
        )}
      </Text>
      <Grid>
        {nationalRegisterCourt && <Text>{nationalRegisterCourt}</Text>}
        <Flex gap="space4">
          {nationalRegisterType && <Text>{nationalRegisterType}</Text>}
          {nationalRegisterId && <Text>{nationalRegisterId}</Text>}
        </Flex>
      </Grid>
    </Grid>
  );
};
