import { Grid, Item, Text } from '@candisio/design-system';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { TaxCodesKebabMenu } from 'components/Menu/BookingKeysKebabMenu/TaxCodesKebabMenu';
import { BookingKey } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { NoTaxCodes } from 'views/Settings/TaxCodes/components/NoTaxCodes';
import { TAX_CODES_SEARCH_PARAM } from 'views/Settings/TaxCodes/consts';
import { useTaxCodes } from 'views/Settings/TaxCodes/useTaxCodes';
import useTaxCodesNavigation from 'views/Settings/TaxCodes/useTaxCodesNavigation';
import { queryTaxCodes } from 'views/Settings/TaxCodes/utils/queryTaxCodes';
import {
  sortTaxCodes,
  SortBy,
} from 'views/Settings/TaxCodes/utils/sortTaxCodes';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';

const TAX_CODES_WIDTH = '50rem';

export const TaxCodeList = () => {
  const { shouldUseSapTaxCodes } = useSap();
  const { shouldUseCoreDataApiTaxCodes } = useOtherIntegration();
  const [queryString, setQueryString] = useState('');
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.taxCode);
  const { t } = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const [searchParams] = useSearchParams();
  const shouldUseTaxPercentage = useCandisFeatureFlags(
    FEATURE_FLAGS.bookingKeyTaxPercentage
  );

  const { bookingKeys, bookingKeysLoading } = useTaxCodes();
  const { navigateToBookingKey } = useTaxCodesNavigation();

  const isArchived =
    searchParams.get(TAX_CODES_SEARCH_PARAM.archive) === 'true';

  const activeBookingKeys = bookingKeys.filter(x => !x.isArchived);
  const archivedBookingKeys = bookingKeys.filter(x => x.isArchived);

  const showCreateAction =
    !shouldUseCoreDataApiTaxCodes && !shouldUseSapTaxCodes;

  const handleCreate = () => {
    navigateToBookingKey('create');
  };

  const sortItems = [
    {
      id: SortBy.taxCode,
      label: t('sortItems.taxCode'),
    },
    {
      id: SortBy.alphabetically,
      label: t('sortItems.alphabetically'),
    },
    {
      id: SortBy.lastAdded,
      label: t('sortItems.lastCreatedFirst'),
    },
  ];

  const sortButtonText = sortItems.find(
    item => sortBy.toString() === item.id
  )?.label;

  const handleSortBy = (value: Key[]) => {
    setSortBy(value.length ? (value[0] as SortBy) : sortBy);
  };

  const onSearchFilter = (filter: string) => {
    setQueryString(filter);
  };

  const handleSearchReset = () => {
    setQueryString('');
  };

  const currentTabBookingKeys = isArchived
    ? archivedBookingKeys
    : activeBookingKeys;

  const queriedBookingKeys = queryTaxCodes(currentTabBookingKeys, queryString);

  const sortedBookingKeys = sortTaxCodes(queriedBookingKeys, sortBy);

  return (
    <FilterableList
      customCreate={
        shouldUseSapTaxCodes && <SyncFromSap type={SAP_SYNC.TaxCodes} />
      }
      emptyDataState={
        <NoTaxCodes
          onCreate={handleCreate}
          hasArchivedTaxCodes={archivedBookingKeys.length > 0}
          hasActiveTaxCodes={activeBookingKeys.length > 0}
        />
      }
      emptySearchState={<EmptySearchState searchReset={handleSearchReset} />}
      isLoading={bookingKeysLoading}
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: handleSortBy,
          text: sortButtonText ?? '',
          menuButtonItems: sortItems,
        },
      ]}
      onCreate={
        showCreateAction
          ? {
              value: handleCreate,
              text: t('create'),
            }
          : undefined
      }
      kebabMenu={<TaxCodesKebabMenu />}
      searchField={{
        onSearchFilter,
        placeholder: t('details.searchPlaceholder'),
        searchQuery: queryString,
      }}
      width={TAX_CODES_WIDTH}>
      {sortedBookingKeys.map((bookingKey: BookingKey) => (
        <Item key={bookingKey.id} textValue={bookingKey.description}>
          <FilterableListItem
            itemId={bookingKey.id}
            onSelect={navigateToBookingKey}
            templateColumns="1fr">
            <Grid>
              <Text
                fontWeight="semibold"
                fontSize="basic"
                overflowWrap="anywhere">
                {renderQueryHighlight({
                  value: bookingKey.description ?? '',
                  queryString,
                })}
              </Text>
              <Text color="gray500">
                {renderQueryHighlight({
                  value: bookingKey.taxCode.toString() ?? '',
                  queryString,
                })}
                {shouldUseTaxPercentage &&
                  typeof bookingKey.taxPercentage === 'number' && (
                    <>
                      {' - '}
                      {renderQueryHighlight({
                        value: `${bookingKey.taxPercentage}`,
                        queryString,
                      })}
                      %
                    </>
                  )}
              </Text>
            </Grid>
          </FilterableListItem>
        </Item>
      ))}
    </FilterableList>
  );
};
