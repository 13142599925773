import {
  Grid,
  Icon,
  Paragraph,
  Skeleton,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { GetPinForCardQuery } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TOOLTIP_DELAY } from '../../../utils';

interface PinPopoverProps {
  pinCode?: GetPinForCardQuery | string;
  isLoading: boolean;
}

export const PinPopover = ({ pinCode, isLoading }: PinPopoverProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      delay: DEFAULT_TOOLTIP_DELAY,
    });

  return (
    <Grid gap="space16" padding="space4 space8" maxWidth="18rem">
      <Grid
        gap="space8"
        autoFlow="column"
        alignItems="center"
        justifyContent="start">
        <Text fontWeight="regular">{t('carousel.showPinPopover.title')}</Text>
        <Icon
          icon="infoOutline"
          size="space16"
          color="gray500"
          {...triggerProps}
          ref={triggerRef}
        />
        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef} maxWidth="20rem">
            {t('carousel.showPinPopover.infoIconTooltip')}
          </Tooltip>
        )}
      </Grid>
      <Paragraph>{t('carousel.showPinPopover.description')}</Paragraph>
      {isLoading ? (
        <Skeleton height="33.5px" width="50%" justifySelf="center" />
      ) : (
        <Paragraph
          textAlign="center"
          fontSize="xxlarge"
          fontWeight="regular"
          justifySelf="center"
          style={{ letterSpacing: '0.8rem' }}>
          {/** @ts-expect-error TODO: React upgrade props types mismatch */}
          {pinCode}
        </Paragraph>
      )}
    </Grid>
  );
};
