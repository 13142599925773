import { Box, Grid, Heading } from '@candisio/design-system';
import { History } from 'components/History/History';
import { useContext } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import { useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import { View } from 'views/Integrations/Export/types';
import { CrossLinedText } from '../../toolkit/components';
import { ExportHistory } from './History/History';
import { NotReady } from './NotReady/NotReady';
import { Ready } from './Ready/Ready';

export const ExportNavigation = () => {
  const { availableViews } = useContext(ExportContext);
  const [t] = useTranslation();

  const showReady = availableViews.includes(View.READY);
  const showNotReady = availableViews.includes(View.NOT_READY);
  const showHistory = availableViews.includes(View.HISTORY);

  return (
    <div>
      <ContainerDimensions>
        {({ top }) => (
          <Grid
            gap="space8"
            height={`calc(100vh - ${top}px)`}
            alignContent="start"
            overflow="hidden"
            paddingTop="space40"
            paddingBottom="space16">
            <Heading as="h1">{t('export.exportHistory.header')}</Heading>
            <>
              <Box overflow="hidden">
                <History>
                  <Box paddingBottom="space8">
                    <CrossLinedText
                      text={t('export.exportHistory.sections.notYetExported')}
                    />
                  </Box>
                  {showReady && <Ready />}
                  {showNotReady && <NotReady />}
                </History>
              </Box>
              <CrossLinedText
                text={t('export.exportHistory.sections.exportHistory')}
              />
              {showHistory && <ExportHistory />}
            </>
          </Grid>
        )}
      </ContainerDimensions>
    </div>
  );
};
