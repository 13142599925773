import { FieldErrors, FieldValues } from 'react-hook-form';

export const collectErrorMessages = <T extends FieldValues>(
  errorObject: FieldErrors<T>
): string[] => {
  let errorMessages: string[] = [];

  const traverseErrors = (obj: any) => {
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (obj[key].hasOwnProperty('message')) {
          errorMessages.push(obj[key].message);
        }

        traverseErrors(obj[key]);
      }
    }
  };

  traverseErrors(errorObject);

  return errorMessages;
};
