import {
  Card,
  Image,
  Grid,
  TruncatedText,
  Text,
  Flex,
} from '@candisio/design-system';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementItem as ReimbursementItemPropsType } from 'views/Reimbursement/hooks/useGetReimbursementExpenseItems';
import receiptIcon from '../../media/receipt.svg';

interface ReimbursementItemProps {
  reimbursementItem: ReimbursementItemPropsType;
  onClick: () => void;
}

const typeTextMap: Record<ReimbursementItemType, string> = {
  GENERAL_EXPENSE: 'reimbursementView.leftSection.reimbursementItems.general',
  HOSPITALITY_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.hospitality',
  MILEAGE: '',
  PER_DIEM: '',
};

export const ReimbursementItem = ({
  reimbursementItem,
  onClick,
}: ReimbursementItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { type, reason } = reimbursementItem;

  return (
    <Grid
      padding="space8 space16"
      templateColumns="auto 1fr"
      gap="space8"
      cursor="pointer"
      onClick={onClick}
      background="gray50"
      hover={{ background: 'gray200' }}>
      <Card background="pink100" padding="space4">
        <Image src={receiptIcon} alt="reciept icon" />
      </Card>
      <Grid
        templateColumns="auto 1fr"
        fontSize="large"
        fontWeight="semibold"
        width="fit-content"
        lineHeight="paragraph"
        placeItems="center"
        gap="space4">
        <Text>{t(typeTextMap[type])}</Text>
        {reason && (
          <Flex gap="space4">
            | <TruncatedText>{reason}</TruncatedText>
          </Flex>
        )}
      </Grid>
    </Grid>
  );
};
