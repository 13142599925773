import Aborted from 'assets/exports/export-aborted.svg';
import Failed from 'assets/exports/export-failed.svg';
import Partially from 'assets/exports/export-partially.svg';
import Progress from 'assets/exports/export-progress.svg';
import Exported from 'assets/timeline/done.svg';
import NotReadyToExport from 'assets/timeline/not-ready.svg';
import ReadyToExport from 'assets/timeline/ready.svg';
import { ExportStatus } from 'generated-types/graphql.types';

//TODO / FIXME: Please use icons from the design system instead of these
export const mapExportStatusIcon = (
  status?: ExportStatus | 'default' | 'not-ready'
) => {
  switch (status) {
    case ExportStatus.Unknown:
      return null;
    case ExportStatus.Exported:
      return Exported;
    case ExportStatus.Exporting:
      return Progress;
    case ExportStatus.Failed:
      return Failed;
    case ExportStatus.PartialyExported:
      return Partially;
    case ExportStatus.Aborted:
      return Aborted;
    case 'not-ready':
      return NotReadyToExport;
    default:
      return ReadyToExport;
  }
};
