import {
  DocumentFilters,
  DocumentStatus,
  InsightsErrorCodes,
  Maybe,
} from 'generated-types/graphql.types';

import { widgetTypeFilterMap } from './WidgetModal/components/WidgetTypeSelection/WidgetTypeSelection';

export const mappedErrorsToTranslations: Partial<
  Record<InsightsErrorCodes, string>
> = {
  [InsightsErrorCodes.MaxWidgetsLimitReached]:
    'dashboard.createNewWidget.toasts.maxLimitReached',
  [InsightsErrorCodes.MaxWidgetsNotOnDashboardLimitReached]:
    'dashboard.createNewWidget.toasts.maxLimitOnSecondarySectionReached',
  [InsightsErrorCodes.MaxWidgetsOnDashboardLimitReached]:
    'dashboard.createNewWidget.toasts.maxLimitOnDashboardReached',
};

export const getIdsFromFilters = (filters?: Maybe<DocumentFilters>) => {
  const costCenterIds = filters?.costCenters?.map(cc => cc.id) || [];
  const costObjectIds = filters?.costObjects?.map(cc => cc.id) || [];
  const contactIds = filters?.contacts?.map(cc => cc.id) || [];
  const generalLedgerAccountIds =
    filters?.generalLedgerAccounts?.map(cc => cc.id) || [];

  return {
    costCenterIds,
    costObjectIds,
    contactIds,
    generalLedgerAccountIds,
  };
};

export const validStatusesForAggregate = Object.values(DocumentStatus).filter(
  documentStatus => documentStatus !== DocumentStatus.Rejected
);

export const getWidgetType = (activeFilters?: Maybe<DocumentFilters>) => {
  if (
    activeFilters?.costCenters?.length ||
    activeFilters?.costObjects?.length
  ) {
    return widgetTypeFilterMap.costCenters;
  } else if (activeFilters?.contacts?.length) {
    return widgetTypeFilterMap.contacts;
  } else if (activeFilters?.generalLedgerAccounts?.length) {
    return widgetTypeFilterMap.generalLedgerAccounts;
  }

  return null;
};
