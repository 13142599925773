import { useCreateCreditCardModalState } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModalDataProvider';
import { useGetSelectedCarholder } from 'containers/credit-cards/CreateCreditCard/hooks/useGetSelectedCardholder';
import {
  MembershipStatus,
  useOrganizationMembershipsQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';

/**
 *
 * @returns the approvers to be pre-filled in the accounting form data while issuing / requesting a credit card
 * When the current user is not a card manager it means it's requesting a card and he will be the first approver.
 * When the current user is a card manager it means it's issuing a card and the first approver will be the selected cardholder as long as it's not a pending user in Candis.
 */

export const useGetApprovers = () => {
  const { cardDetails } = useCreateCreditCardModalState();
  const user = useCurrentUser();
  const { selectedCardholder } = useGetSelectedCarholder();
  const currentUserRoles = useUserRoles();

  // Query to check if the selected user is pending
  const { data, loading } = useOrganizationMembershipsQuery({
    variables: {
      filters: {
        emailVerified: false,
        status: MembershipStatus.Active,
        name: selectedCardholder.fullName,
      },
    },
  });

  const isSelectedCardholderPending =
    data?.organizationMemberships.length === 1 &&
    selectedCardholder.id === data.organizationMemberships?.[0]?.id;

  const firstApprover =
    (currentUserRoles.isCardManager
      ? !isSelectedCardholderPending && !loading
        ? selectedCardholder.id
        : undefined
      : user?.id) ?? undefined;

  return {
    approvers: cardDetails?.approvers?.length
      ? cardDetails?.approvers
      : firstApprover
      ? [{ approvers: [firstApprover] }]
      : [],
    approversLoading: loading,
  };
};
