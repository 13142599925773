import {
  HookFormSelectField,
  HookFormSelectFieldProps,
} from 'components/HookFormFields/HookFormSelectField';
import { ContactPaymentMedium } from 'generated-types/graphql.types';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface PaymentMediumSelectFieldProps<TFormValues extends FieldValues>
  extends HookFormSelectFieldProps<TFormValues> {
  /** Payment medium field options */
}
export const paymentMediumOptions = [
  {
    key: ContactPaymentMedium.NoInformation,
    children:
      'settings.contacts.details.edit.paymentMedium.options.noInformation',
  },
  {
    key: ContactPaymentMedium.SepaSingle,
    children: 'settings.contacts.details.edit.paymentMedium.options.sepaSingle',
  },
  {
    key: ContactPaymentMedium.SepaBulk,
    children: 'settings.contacts.details.edit.paymentMedium.options.sepaBulk',
  },
  {
    key: ContactPaymentMedium.NoPayment,
    children: 'settings.contacts.details.edit.paymentMedium.options.noPayment',
  },
];

export const PaymentMediumSelectField = <TFormValues extends FieldValues>({
  name,
  ...restProps
}: PaymentMediumSelectFieldProps<TFormValues>) => {
  const [t] = useTranslation();

  return (
    <HookFormSelectField<TFormValues>
      name={name}
      defaultSelectedKey={ContactPaymentMedium.NoInformation}
      items={paymentMediumOptions.map(option => ({
        ...option,
        children: t(option.children),
      }))}
      {...restProps}
    />
  );
};
