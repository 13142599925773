import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { DocumentCommentEventDataFragment } from 'generated-types/graphql.types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { CommentView } from '../styles';

export const CommentComponent = ({
  comment,
  clientInfo,
}: DocumentCommentEventDataFragment) => {
  const { isDocumentRelationsAvailable } = useEcm();

  return (
    <TimelineEventWithMobileIndication
      biggerPadding
      iconName="mobileComment"
      clientName={clientInfo?.name}
      eventComponent={
        <CommentView
          data-show-document-relations={isDocumentRelationsAvailable}>
          {comment?.text}
        </CommentView>
      }
    />
  );
};
