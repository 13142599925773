import { ProcessingFormSwiftCodeField } from 'components/Form/ProcessingForm/ProcessingFormSwiftCodeFields';
import { useTranslation } from 'react-i18next';

export const SwiftCode = () => {
  const [t] = useTranslation();

  return (
    <ProcessingFormSwiftCodeField
      name="swiftCode"
      label={t('document.requestApproval.inputs.swiftCode.label')}
      readOnly
    />
  );
};
