import { useContactQuery } from 'generated-types/graphql.types';

interface UseGetContactByIdParams {
  id: string | undefined;
}

export const useGetContactById = ({ id }: UseGetContactByIdParams) => {
  const { data, loading } = useContactQuery({
    skip: !id,
    fetchPolicy: 'network-only',
    variables: { input: { id: id as string } },
  });

  const contact = {
    name: data?.contact?.name.value,
  };

  return { contact, contactLoading: loading };
};
