import { useEffect, useState, FunctionComponent, ComponentType } from 'react';
import { AuthService } from 'services/AuthService';
import { SplashScreen } from 'views/AppContainer';

// This component only makes sure that you are logged in
// Anything else is beyond the scope of this.
export function ProtectedHOC<T extends object>(
  WrappedComponent: ComponentType<T>
  // eslint-disable-next-line @typescript-eslint/ban-types
): FunctionComponent<T> {
  // To prevent react crying about hooks in a callback
  // Mark this explicitly as a FunctionComponent
  const ProtectedComponent = ({ ...props }: T) => {
    // only uncomment this line to run locally
    // return <WrappedComponent {...props as T} />
    const [isAuthenticated, setAuthenticationStatus] = useState(false);
    useEffect(() => {
      AuthService.authKeycloak()
        .then(auth => {
          setAuthenticationStatus(auth || false);
        })
        .catch(() => setAuthenticationStatus(false));
    }, []);

    return isAuthenticated ? (
      <WrappedComponent {...(props as T)} />
    ) : (
      <SplashScreen />
    );
  };

  return ProtectedComponent;
}
