import {
  Checkbox,
  FieldContainer,
  Flex,
  InlineSkeleton,
  useTheme,
} from '@candisio/design-system';
import { useHookFormField } from 'components/HookFormFields/useHookFormField';
import { InfoOutlineIcon } from 'components/Icons/InfoOutlineIcon';
import { isNil } from 'lodash';

import { useTranslation } from 'react-i18next';
import { useProcessingFormField } from './useProcessingFormField';

export interface ProcessingFormInvoiceCorrectionFieldProps {
  label?: string;
  name: 'grossAmount';
  readOnly?: boolean;
  /** Boolean to display loading skeleton */
  isLoading?: boolean | undefined;
}

/** Flips the sign (+/-) of the gross amount field in processing form */
export const ProcessingFormInvoiceCorrectionField = ({
  label,
  name,
  readOnly: readOnlyProp,
  isLoading,
}: ProcessingFormInvoiceCorrectionFieldProps) => {
  const [t] = useTranslation();
  const { radii } = useTheme();
  const { fieldProps: fieldMetaProps } = useProcessingFormField(name);

  const {
    fieldContainerProps,
    inputProps: { onChange, value, readOnly, ...inputProps },
  } = useHookFormField({
    name,
    readOnly: readOnlyProp || fieldMetaProps.readOnly,
    message: fieldMetaProps.message,
    variant: fieldMetaProps.variant,
  });

  return (
    <FieldContainer transparent {...fieldContainerProps}>
      <Flex gap="space8">
        <Checkbox
          isDisabled={isNil(value) || value === 0}
          isReadOnly={readOnly}
          isSelected={value < 0}
          onChange={() => {
            if (isNil(value) || value === 0) {
              return;
            }

            const newValue = -value;
            onChange(newValue);
          }}
          isLoading={isLoading}
          {...inputProps}>
          {label}
        </Checkbox>

        <InlineSkeleton isLoading={isLoading} borderRadius={radii.full}>
          <InfoOutlineIcon
            message={t(
              'document.requestApproval.inputs.invoiceCorrection.info'
            )}
            disableTabbing
          />
        </InlineSkeleton>
      </Flex>
    </FieldContainer>
  );
};
