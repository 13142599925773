import { Button } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGenericErrorMessageHandler } from 'containers/credit-cards/utils';
import {
  CardStatus,
  CardType,
  useLockUnlockCardMutation,
} from 'generated-types/graphql.types';
import {
  CCWalletTrackingEvents,
  useAnalytics,
} from 'providers/AnalyticsProvider';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface LockAndUnlockCardProps {
  cardId: string;
  cardholderName: string;
  cardStatus: CardStatus;
  cardType: CardType;
}

export const LockAndUnlockCard = ({
  cardId,
  cardStatus,
  cardType,
  cardholderName,
}: LockAndUnlockCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const genericErrorMessageHandler = useGenericErrorMessageHandler();
  const { success, error } = useToastMessage();
  const { track } = useAnalytics();
  const { refetchQueries } = useCreditCardsRefetchQueries();

  const [lockOrUnlockCard, { loading }] = useLockUnlockCardMutation({
    onError: () => genericErrorMessageHandler(),
  });

  const isCardLocked = cardStatus === CardStatus.Locked;

  const handleClick = async () => {
    const { errors } = await lockOrUnlockCard({
      variables: {
        input: {
          cardId: cardId,
          locked: isCardLocked ? false : true,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: refetchQueries.cardIssuerCards(cardId),
    });

    if (!errors?.length) {
      track(CCWalletTrackingEvents.LOCK_UNLOCK_CARD_BTN_CLICKED, {
        cardType,
        cardholderName,
        cardStatus: isCardLocked ? 'card locked' : 'card unlocked',
      });

      success(
        isCardLocked
          ? t('dashboard.drawer.cardUnlocked')
          : t('dashboard.drawer.cardLocked')
      );
    } else {
      error(
        isCardLocked
          ? t('creditCardFormDataPreview.cardUnlockedFailed')
          : t('creditCardFormDataPreview.cardLockFailed')
      );
    }
  };

  return (
    <Button
      size="small"
      variant="tertiary"
      loading={loading}
      icon={isCardLocked ? 'unlock' : 'lock'}
      onClick={handleClick}>
      {isCardLocked
        ? t('dashboard.drawer.unlockCard')
        : t('dashboard.drawer.lockCard')}
    </Button>
  );
};
