import { useTheme } from '@candisio/design-system';
import { ReactNode } from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import './styles.css';

const CustomSlide = cssTransition({
  enter: 'fade-in',
  exit: 'fade-out',
});

export const MESSAGE_TOAST_CONTAINER_ID = 'message-toast-container';

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const { space, colors, fontFamilies } = useTheme();

  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={MESSAGE_TOAST_CONTAINER_ID}
        position="top-center"
        hideProgressBar
        closeButton={false}
        closeOnClick={false}
        transition={CustomSlide}
        pauseOnFocusLoss={false}
        bodyStyle={{
          padding: 0,
          color: colors.gray800,
          fontFamily: fontFamilies.body,
          width: 'auto',
        }}
        toastStyle={{
          padding: `${space.space10} ${space.space16}`,
          boxShadow:
            '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          minHeight: 0,
          borderRadius: '99px',
        }}
        style={{
          width: 'auto',
        }}
      />
      {children}
    </>
  );
};
