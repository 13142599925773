import { IntegrationName } from 'generated-types/graphql.types';
import { z } from 'zod';
import { nonEmptyString } from './nonEmptyString';
import { preprocessStringToNumber } from './preprocessStringToNumber';

export const DatevAccountsReceivableNumberRequirements = {
  MinNumber: 10000,
  MaxNumber: 699999999,
  FirstDigit: {
    MinNumber: 1,
    MaxNumber: 6,
  },
} as const;

export const DatevAdjacentAccountsReceivableNumberRequirements = {
  MinNumber: 10000,
  MaxNumber: 999999999,
};

/**
 * DATEV ADJACENT
 */

const datevAdjacentSchema = z
  .number()
  .int()
  .min(DatevAdjacentAccountsReceivableNumberRequirements.MinNumber)
  .max(DatevAdjacentAccountsReceivableNumberRequirements.MaxNumber)
  .transform(String);

/**
 * DATEV
 */

const datevSchema = z
  .number()
  .int()
  .min(DatevAccountsReceivableNumberRequirements.MinNumber)
  .max(DatevAccountsReceivableNumberRequirements.MaxNumber)
  .transform(String);

function createDatevSchema(glaLength?: number) {
  return z
    .number()
    .int()
    .min(DatevAccountsReceivableNumberRequirements.MinNumber)
    .max(DatevAccountsReceivableNumberRequirements.MaxNumber)
    .transform(String)
    .superRefine((value, ctx) => {
      const firstDigit = parseInt(value.toString()[0]);
      const length = value.length;

      const firstDigitValidation =
        firstDigit >=
          DatevAccountsReceivableNumberRequirements.FirstDigit.MinNumber &&
        firstDigit <=
          DatevAccountsReceivableNumberRequirements.FirstDigit.MaxNumber;

      if (!firstDigitValidation) {
        ctx.addIssue({
          code: 'custom',
          params: {
            translationKey:
              'settings.contacts.details.edit.accountsReceivableNumber.firstDigitInvalid',
            firstDigitMin:
              DatevAccountsReceivableNumberRequirements.FirstDigit.MinNumber,
            firstDigitMax:
              DatevAccountsReceivableNumberRequirements.FirstDigit.MaxNumber,
          },
        });
      } else if (glaLength) {
        if (length !== glaLength + 1) {
          const glaLengthAsZeroes = '0'.repeat(glaLength);
          const minNumberWithGla = parseInt(
            DatevAccountsReceivableNumberRequirements.FirstDigit.MinNumber +
              glaLengthAsZeroes
          );

          const glaLengthAsNines = '9'.repeat(glaLength);
          const maxNumberWithGla = parseInt(
            DatevAccountsReceivableNumberRequirements.FirstDigit.MaxNumber +
              glaLengthAsNines
          );

          ctx.addIssue({
            code: 'custom',
            params: {
              translationKey:
                'settings.contacts.details.edit.accountsReceivableNumber.glaLengthInvalid',
              minNumberWithGla,
              maxNumberWithGla,
            },
          });
        }
      } else {
        return;
      }
    });
}

// z.coerce uses `Number(value)` which converts invalid values to numbers
// Eg z.coerce.number().parse('070001') => 70001
// Using preprocess instead to prevent the above from happening.
const datevAccountsReceivableNumberSchema = (
  schema: ReturnType<typeof createDatevSchema> | typeof datevSchema
) => {
  return z.preprocess(preprocessStringToNumber, schema);
};

/**
 * SAP
 * Max 15 characters
 * Only alphanumeric characters
 */
// TODO TG-1886: update this once we know the min and max for SAP
export const SapAccountsReceivableNumberRequirements = {
  MinLength: 1,
  MaxLength: 15,
  Regex: /^\S*$/,
} as const;

const sapAccountsReceivableNumberSchema = z
  .string()
  .min(SapAccountsReceivableNumberRequirements.MinLength)
  .max(SapAccountsReceivableNumberRequirements.MaxLength)
  .regex(SapAccountsReceivableNumberRequirements.Regex);

/** IntegrationName: OTHER */
const otherAccountsReceivableNumberSchema = nonEmptyString;

export const accountsReceivableNumberSchema = (
  integration: IntegrationName,
  glaLength?: number
) => {
  switch (integration) {
    case IntegrationName.Sap:
      return sapAccountsReceivableNumberSchema;

    case IntegrationName.Other:
      return otherAccountsReceivableNumberSchema;

    case IntegrationName.DatevAdjacent:
      return datevAccountsReceivableNumberSchema(datevAdjacentSchema);

    case IntegrationName.Datev:
    default:
      return datevAccountsReceivableNumberSchema(createDatevSchema(glaLength));
  }
};

export const isAccountsReceivableNumberValid = (
  accountsPayableNumber: unknown,
  integration: IntegrationName
): boolean =>
  accountsReceivableNumberSchema(integration).safeParse(accountsPayableNumber)
    .success;
