import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';

export interface PurchaseOrdersKebabMenuProps {
  organizationSlug?: string;
}

export const PurchaseOrdersKebabMenu = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  const pathname = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}${Routes.PURCHASE_ORDERS_IMPORT_HISTORY}`,
    {
      organizationSlug,
    }
  );

  return (
    <KebabMenu
      items={[
        {
          id: 'history',
          label: t('history.title'),
          onAction: () => {
            navigate(pathname);
          },
        },
      ]}
      label={t('history.tooltip')}
    />
  );
};
