import { useTheme } from '@candisio/design-system';
import OtpInput from 'react-otp-input';

interface OtpNumberFieldProps {
  value: string;
  hasError?: boolean;
  isCandisCardBrand: boolean;
  onChange?: (val: string) => void;
}

export const OtpNumberField = ({
  value,
  hasError,
  isCandisCardBrand,
  onChange,
}: OtpNumberFieldProps) => {
  const { colors, radii, space, fontSizes } = useTheme();

  const otpNumFieldCount: number = isCandisCardBrand ? 4 : 6;

  return (
    <OtpInput
      shouldAutoFocus={true}
      hasErrored={hasError}
      containerStyle={{
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'stretch',
        alignSelf: 'end',
        gap: space.space8,
      }}
      errorStyle={{
        outline: 'none',
        border: `1px solid ${colors.red500}`,
      }}
      focusStyle={{
        outline: 'none',
        border: `1px solid ${colors.blue500}`,
      }}
      inputStyle={{
        width: '100%',
        border: 'none',
        background: colors.gray200,
        borderRadius: radii.medium,
        maxWidth: space.space24,
        height: space.space32,
        fontSize: fontSizes.xxlarge,
      }}
      isInputNum={true}
      value={value}
      onChange={onChange}
      numInputs={otpNumFieldCount}
    />
  );
};
