import { QueryHookOptions } from '@apollo/client';
import { AriaTabListProps, TabItem } from '@candisio/design-system';
import { InvoiceAssociationStatus } from 'generated-types/graphql.types';
import { Routes } from 'models';

export interface ActiveTabsProps extends AriaTabListProps<TabItem> {
  'aria-label': string;
}

export const options: QueryHookOptions = {
  fetchPolicy: 'cache-first',
};

export const useBannerLinks = (orgSlug: string) => {
  const autoMatchTransactionsLink = () => {
    const urlParams = new URLSearchParams();
    const invoiceStatusKey = 'invoiceAssociationStatus';

    urlParams.append(invoiceStatusKey, InvoiceAssociationStatus.AutoMatched);

    return `/${orgSlug}${Routes.ARCHIVE}${
      Routes.TRANSACTIONS
    }/?${urlParams.toString()}`;
  };

  return { autoMatchTransactionsLink };
};
