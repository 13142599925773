import { Button, Flex } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { AddProps } from '../types';

export const Add = ({ onAddWidget }: AddProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);

  return (
    <Flex
      background="gray100"
      borderRadius="medium"
      justifyContent="center"
      padding="5.2rem 2rem">
      <Button
        icon="plus"
        size="small"
        onClick={onAddWidget}
        alignSelf="center"
        variant="secondary">
        {t('insights.widgets.add.cta')}
      </Button>
    </Flex>
  );
};
