import { Card, useTheme, Text } from '@candisio/design-system';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardStatus, CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

export const VirtualX1CardWasUsedInfo = ({ cardId }: { cardId: string }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { colors } = useTheme();
  const { card } = useGetCardById({ cardId });

  const responsiveInfoBox = useMediaQuery({
    maxWidth: 1500,
  })
    ? '100px'
    : undefined;

  if (!card) {
    return null;
  }

  const { status, type, transactionLimit, availableBalance } = card;

  const isCardNotUsableAnymore =
    status === CardStatus.Active &&
    type === CardType.SingleUse &&
    (availableBalance?.value ?? 0) < (transactionLimit?.value ?? 0);

  if (!isCardNotUsableAnymore) {
    return null;
  }

  return (
    <Card
      background={colors.gray200}
      borderRadius="8px"
      padding="space12"
      maxWidth="261px"
      width={responsiveInfoBox}
      justifySelf="end"
      boxShadow="none"
      alignSelf="start">
      <Text color="gray600">
        {t('dashboard.drawer.cardIsNotUsableAnymore')}
      </Text>
    </Card>
  );
};
