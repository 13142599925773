import {
  useState,
  DragEvent,
  ChangeEvent,
  createRef,
  useCallback,
} from 'react';

const acceptedFileType = 'application/pdf';
interface DragAndDropProps {
  onFilesSelected: (
    ev: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
    files: File[]
  ) => any;
}

export const useDragAndDrop = ({ onFilesSelected }: DragAndDropProps) => {
  const [isAcceptedFileType, setIsAcceptedFileType] = useState<boolean>(false);
  const [isFileDraggable, setIsFileDraggable] = useState<boolean>(false);
  const [draggedFileCount, setDraggedFileCount] = useState<number>(0);

  const filesSelected = useCallback(
    (
      ev: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
      files: File[]
    ) => {
      onFilesSelected(ev, files);
    },
    [onFilesSelected]
  );

  const onFileDrop = useCallback(
    (ev: DragEvent<HTMLDivElement>, ...data: any[]) => {
      ev.stopPropagation();
      ev.preventDefault();
      setIsFileDraggable(false);
      setDraggedFileCount(0);
      filesSelected(ev, Array.from(ev.dataTransfer.files));
    },
    [filesSelected]
  );

  const onDragOver = useCallback((ev: DragEvent<HTMLDivElement>) => {
    const draggedOverFileCount = ev.dataTransfer.items.length;
    const supportedFileType = Array.from(ev.dataTransfer.items).every(
      itm => itm.type === acceptedFileType
    );
    // This event is only here so that chrome
    // doesn't open the file when it is dragged in

    ev.stopPropagation();
    ev.preventDefault();
    setIsAcceptedFileType(supportedFileType);
    setIsFileDraggable(true);
    setDraggedFileCount(draggedOverFileCount);
  }, []);

  const fileUploadRef = createRef<HTMLInputElement>();
  const onDropZoneClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const onFilesChanged = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      filesSelected(ev, Array.from(ev.target.files));
    }
  };

  const onDragLeave = useCallback(() => {
    setIsFileDraggable(false);
    setDraggedFileCount(0);
  }, []);

  const hasNoFile = draggedFileCount === 0;

  const canUserDropFile = hasNoFile || isAcceptedFileType;

  return {
    isFileDraggable,
    onFileDrop,
    onDragLeave,
    onDragOver,
    onDropZoneClick,
    onFilesChanged,
    canUserDropFile,
    fileUploadRef,
  };
};
