import {
  ReimbursementError,
  ReimbursementItemStatus,
  ReimbursementItemType,
  File,
  GeneralExpenseItem,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import DateFormatters from 'utils/date_formatter';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';

export interface GeneralExpense {
  id: string;
  reason?: string;
  type: ReimbursementItemType;
  status: ReimbursementItemStatus;
  createdAt: string;
  files: File[];
  totalAmount: number | null;
  location?: string;
  errors: ReimbursementError[];
  expenseDate?: string;
}

export const useFormattedGeneralExpense = () => {
  const { dateTimeStringToDateString } = useDateConverter();

  const generalExpenseItem = (data: GeneralExpenseItem): GeneralExpense => ({
    id: data.id,
    errors: data.errors,
    status: data.status,
    type: ReimbursementItemType.GeneralExpense,
    reason: data.title ?? undefined,
    createdAt: DateFormatters.compact(new Date(data.createdAt)),
    expenseDate: data.expenseDate
      ? dateTimeStringToDateString(data.expenseDate)
      : undefined,
    totalAmount: data.totalAmount
      ? formatFromCents(data.totalAmount.currency, data.totalAmount.amount)
      : 0,
    files: data.files?.length
      ? data.files.map(file => ({
          id: file.id,
          url: file.url,
          name: file.name,
        }))
      : [],
  });

  return generalExpenseItem;
};
