import { Text, Flex } from '@candisio/design-system';
import { ReactNode } from 'react';
import { StatusCircleStatus } from './types';

export interface StatusProps {
  status?: StatusCircleStatus;
  amount?: number;
  description: string;
  children?: ReactNode;
}

export const StatusCircle = ({
  status = StatusCircleStatus.PRIMARY,
  amount,
  description,
  children,
}: StatusProps) => {
  return (
    <Flex gap="space4">
      <Text fontSize="basic">{amount}</Text>
      <Text fontSize="basic">
        {description}
        {children}
      </Text>
    </Flex>
  );
};
