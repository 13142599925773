import { Box, Text, Flex, Grid, Skeleton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettlementLoadingState } from '../SettlementCard/SettlementLoadingState';

export const StatementLoadingState = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid templateRows="auto 1fr">
      <Grid padding="space16" gap="space16">
        <Grid gap="space16" templateColumns="1fr auto">
          <Grid gap="space4">
            <Flex gap="space4" alignItems="center">
              <Skeleton width="200px" height="space36" />
              <Skeleton width="space128" height="space24" />
            </Flex>
            <Skeleton width="150px" height="space18" />
          </Grid>
          <Box paddingTop="space8" justifySelf="end">
            <Skeleton width="space32" height="space32" borderRadius="full" />
          </Box>
        </Grid>
        <Grid
          autoFlow="column"
          padding="space16"
          borderRadius="medium"
          placeItems="center"
          gap="space16"
          border="1px solid gray300">
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton width="100%" height="43px" key={index} />
          ))}
        </Grid>
      </Grid>
      <Grid padding="0 space16" gap="space16" height="fit-content">
        <Text fontSize="xxlarge">{t('statements.detail.settlement')}</Text>
        <SettlementLoadingState />
      </Grid>
    </Grid>
  );
};
