import { Box } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { CoreDataImportHistoryEntry } from 'views/CoreDataImportHistory/elements/CoreDataImportHistoryListItem/CoreDataImportHistoryListItem';
import { useCoreDataTypeTranslation } from 'views/CoreDataImportHistory/toolkit/hooks/useCoreDataTypeTranslation';
interface ImportStatusProps {
  item: CoreDataImportHistoryEntry;
}

export const ImportStatus = ({ item }: ImportStatusProps) => {
  const importWasSuccessful = item.failedCandisAPICoreDataImportCount === 0;
  const failedCount = item?.failedCandisAPICoreDataImportCount ?? 0;
  const successCount = item?.successfulCandisAPICoreDataImportCount ?? 0;
  const importTotal = failedCount + successCount;

  const [t] = useTranslation();

  const coreDataTypeTranslation = useCoreDataTypeTranslation({
    coreDataType: item.type,
  });

  return (
    <Box>
      {importWasSuccessful
        ? t('common:importHistoryList.status.successCount', {
            successCount,
            dataType: coreDataTypeTranslation,
            count: successCount,
          })
        : t('common:importHistoryList.status.totalCount', {
            failedCount,
            importTotal,
            dataType: coreDataTypeTranslation,
            count: failedCount,
          })}
    </Box>
  );
};
