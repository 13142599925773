import { ProcessingFormTextField } from 'components/Form/ProcessingForm/ProcessingFormTextField';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DocumentDirection,
  inOutDocumentDirections,
} from 'views/utils/DocumentDirection';
import { ProcessingFormContactFieldItem } from './ProcessingFormContactField';
import { ProcessingFormValues } from './processingFormSchema';
import { ProcessingFormTypeFieldItem } from './ProcessingFormTypeField';

export interface ProcessingFormContactDetailsProps {
  /** Gets a contact field item from its value */
  getContactFieldItem?: (
    value: string
  ) => Promise<ProcessingFormContactFieldItem | undefined>;
  /** Document type field items */
  typeFieldItems?: ProcessingFormTypeFieldItem[];
  /** Contact item */
  contactItem?: ProcessingFormContactFieldItem;
  /** Callback function to get contact item */
  onGetContactItem?: (contactItem?: ProcessingFormContactFieldItem) => void;
  /** Boolean to display loading skeleton */
  isLoading?: boolean | undefined;
}

/**
 * Displays accounts payable/receivable number for the currently-selected
 * contact in processing form.
 *
 * Returns `null` if direction of currently-selected document type is other
 */
export const ProcessingFormContactDetails = ({
  getContactFieldItem,
  typeFieldItems = [],
  onGetContactItem,
  isLoading,
}: ProcessingFormContactDetailsProps) => {
  const [t] = useTranslation();
  const { friendlyFormatAccountsNumber } = useAccountingNumberFormatters();
  const { setValue } = useFormContext<ProcessingFormValues>();

  const [contact, type] =
    useWatch<ProcessingFormValues, ['contact', 'type']>({
      name: ['contact', 'type'],
    }) ?? [];

  const typeItem = typeFieldItems.find(({ key }) => key === type);
  const direction = typeItem?.direction;

  useEffect(() => {
    if (!contact) {
      return;
    }

    let abort = false;
    if (contact) {
      getContactFieldItem?.(contact?.value)
        .then(item => {
          if (!abort) {
            onGetContactItem?.(item);
            const value = item
              ? direction === DocumentDirection.invoices_received
                ? item.accountsPayableNumber
                : item.accountsReceivableNumber
              : '';

            if (direction === DocumentDirection.invoices_received) {
              setValue(
                'accountsPayableNumber',
                friendlyFormatAccountsNumber(value ?? ''),
                {
                  shouldValidate: true,
                }
              );
            } else if (direction === DocumentDirection.outgoing_invoices) {
              setValue(
                'accountsReceivableNumber',
                friendlyFormatAccountsNumber(value ?? ''),
                {
                  shouldValidate: true,
                }
              );
            }
          }
        })
        .catch(() => {
          console.error('Failed to get contact field item', contact);
        });

      return () => {
        abort = true;
      };
    }
  }, [
    contact,
    getContactFieldItem,
    onGetContactItem,
    direction,
    friendlyFormatAccountsNumber,
    setValue,
  ]);

  if (!direction || !inOutDocumentDirections.includes(direction)) {
    return null;
  }

  return (
    <ProcessingFormTextField
      name={
        direction === DocumentDirection.invoices_received
          ? 'accountsPayableNumber'
          : 'accountsReceivableNumber'
      }
      label={
        direction === DocumentDirection.invoices_received
          ? t('settings.contacts.details.edit.accountsPayableNumber.label')
          : t('settings.contacts.details.edit.accountsReceivableNumber.label')
      }
      readOnly
      isLoading={isLoading}
    />
  );
};
