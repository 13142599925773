import { Grid } from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CreditCardsWorkflowAndApproversFields } from './FormFields/CreditCardsWorkflowAndApproversFields';
import { AccountingInfoFieldOptions } from './utils/types';

interface AccountingInfoFormFieldsProps {
  readOnly?: boolean;
  fieldOptions: AccountingInfoFieldOptions;
}

export const AccountingInfoFormFields = ({
  readOnly,
  fieldOptions,
}: AccountingInfoFormFieldsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const {
    approvers,
    artistSocialInsuranceCode,
    contact,
    costCenter,
    costObject,
    extraCostInfo,
    generalLedgerAccount,
    taxCode,
    workflow,
  } = fieldOptions;

  return (
    <Grid gap="space16" height="fit-content">
      <HookFormPaginatedComboBoxField
        name="contact"
        readOnly={readOnly}
        placeholder={t(
          'recurringPaymentsModal.addAccountingData.field.contact.placeholder'
        )}
        label={t(
          'recurringPaymentsModal.addAccountingData.field.contact.label'
        )}
        emptyListPlaceholder={t(
          'recurringPaymentsModal.addAccountingData.field.contact.emptyPlaceholder'
        )}
        {...contact.options}
      />
      {generalLedgerAccount.isVisible && (
        <HookFormPaginatedComboBoxField
          name="generalLedgerAccount"
          readOnly={readOnly}
          label={t(
            'recurringPaymentsModal.addAccountingData.field.generalLedgerAccount.label'
          )}
          placeholder={t(
            'recurringPaymentsModal.addAccountingData.field.generalLedgerAccount.placeholder'
          )}
          emptyListPlaceholder={t(
            'recurringPaymentsModal.addAccountingData.field.generalLedgerAccount.emptyPlaceholder'
          )}
          {...generalLedgerAccount.options}
        />
      )}
      {taxCode.isVisible && (
        <HookFormComboBoxField
          name="taxCode"
          readOnly={readOnly}
          defaultItems={taxCode.options.defaultItems}
          label={t(
            'recurringPaymentsModal.addAccountingData.field.taxCode.label'
          )}
          placeholder={t(
            'recurringPaymentsModal.addAccountingData.field.taxCode.placeholder'
          )}
          emptyListPlaceholder={t(
            'recurringPaymentsModal.addAccountingData.field.taxCode.emptyPlaceholder'
          )}
        />
      )}
      {costCenter.isVisible && (
        <HookFormPaginatedComboBoxField
          name="costCenter"
          readOnly={readOnly}
          placeholder={t(
            'recurringPaymentsModal.addAccountingData.field.costCenter.placeholder'
          )}
          label={t(
            'recurringPaymentsModal.addAccountingData.field.costCenter.label'
          )}
          emptyListPlaceholder={t(
            'recurringPaymentsModal.addAccountingData.field.costCenter.emptyPlaceholder'
          )}
          {...costCenter.options}
        />
      )}
      {costObject.isVisible && (
        <HookFormPaginatedComboBoxField
          name="costObject"
          readOnly={readOnly}
          placeholder={t(
            'recurringPaymentsModal.addAccountingData.field.costObject.placeholder'
          )}
          label={t(
            'recurringPaymentsModal.addAccountingData.field.costObject.label'
          )}
          emptyListPlaceholder={t(
            'recurringPaymentsModal.addAccountingData.field.costObject.emptyPlaceholder'
          )}
          {...costObject.options}
        />
      )}

      {artistSocialInsuranceCode.isVisible && (
        <HookFormComboBoxField
          name="artistSocialInsuranceCode"
          readOnly={readOnly}
          defaultItems={artistSocialInsuranceCode.options.defaultItems}
          label={t(
            'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.label'
          )}
          placeholder={t(
            'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.placeholder'
          )}
          emptyListPlaceholder={t(
            'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.emptyPlaceholder'
          )}
        />
      )}
      {extraCostInfo.isVisible && (
        <HookFormPaginatedComboBoxField
          name="extraCostInfo"
          readOnly={readOnly}
          label={t(
            'recurringPaymentsModal.addAccountingData.field.extraCostInfo.label'
          )}
          placeholder={t(
            'recurringPaymentsModal.addAccountingData.field.extraCostInfo.placeholder'
          )}
          emptyListPlaceholder={t(
            'recurringPaymentsModal.addAccountingData.field.extraCostInfo.emptyPlaceholder'
          )}
          {...extraCostInfo}
        />
      )}
      <CreditCardsWorkflowAndApproversFields
        readOnly={readOnly}
        approversFieldOptions={approvers}
        workflowFieldOptions={workflow}
      />
    </Grid>
  );
};
