import { MenuButton, MenuItem } from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';

export const TransactionsKebabMenu = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const navigate = useNavigate();
  const organizationSlug = useOrganizationId();

  const handleAction = () => {
    const downloadHistoryUrl = generatePath(
      `/:organizationSlug/${Routes.TRANSACTIONS_IMPORT_HISTORY}`,
      { organizationSlug }
    );

    navigate(downloadHistoryUrl);
  };

  const menuItems: MenuItem[] = [
    {
      id: 'history',
      label: t('table.toolbar.history'),
      onAction: handleAction,
    },
  ];

  return <MenuButton items={menuItems} icon="kebab" variant="tertiary" />;
};
