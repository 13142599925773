import {
  CustomEmptyStateProps,
  Flex,
  Grid,
  useTheme,
} from '@candisio/design-system';
import { PurchaseOrdersTable } from 'components/PurchaseOrders/Table/PurchaseOrdersTable';
import {
  ColumnWidths,
  PurchaseOrdersTableData,
  ViewUsingPruchaseOrders,
} from 'components/PurchaseOrders/Table/types';
import { usePurchaseOrdersData } from 'components/PurchaseOrders/Table/usePurchaseOrdersData';
import {
  availableFilters,
  purchaseOrderToTableData,
} from 'components/PurchaseOrders/Table/util';
import { SearchField } from 'components/SearchField/SearchField';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { TabView } from 'views/Inbox/models';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { useGetPurchaseOrdersTableConfigs } from './hooks/useGetPurchaseOrdersTableConfigs';
import { usePurchaseOrdersTableFilterOptions } from './hooks/useTableFilterOptions';
import { PurchaseOrdersEmptyState } from './PurchaseOrdersEmptyState';
import { PurchaseOrdersKebabMenu } from './PurchaseOrdersKebabMenu';

export const PurchaseOrders = () => {
  const [t] = useTranslation();

  const { searchParams, updateSearchParam } = useMutateSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? '';
  const isUsingSearchQuery = Boolean(searchQuery);

  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<PurchaseOrdersTableData>({
      availableFilters,
    });

  const {
    availablePurchaseOrdersColumnIds,
    configurationsTable,
    isSavingConfigurations,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetPurchaseOrdersTableConfigs({ filters, sortBy });

  const {
    purchaseOrderList,
    isLoadingPurchaseOrderList,
    onLoadMore,
    isTableFiltered,
    isTableEmpty,
    handleDebounceSearch,
  } = usePurchaseOrdersData({
    routeType: ViewUsingPruchaseOrders.ARCHIVE,
    filters,
    sortBy,
  });
  // TODO: implement filters in release 2
  // const { filterOptions } = usePurchaseOrderListFilters([
  //   PURCHASE_ORDER_FILTER.invoiceAssociationStatus_archive,
  // ]);

  const { space } = useTheme();

  const handleResetSearch = () => {
    updateSearchParam(queryParameter, '');
    handleDebounceSearch('');
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <PurchaseOrdersEmptyState
        isTableFiltered={isTableFiltered}
        isUsingSearchQuery={isUsingSearchQuery}
        resetFilters={resetFilters}
        resetSearch={handleResetSearch}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<PurchaseOrdersTableData>) => {
      onFilter(filterVal);
    };
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<PurchaseOrdersTableData>[]) => {
      onSort(sortVal);
    };
  }, [onSort]);

  const columnWidths: ColumnWidths = {
    contact: space.space256,
    connectedInvoices: space.space256,
    lineItems: space.space256,
  };

  const filterOptions = usePurchaseOrdersTableFilterOptions();

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_PURCHASE_ORDERS}>
      <Flex height="100%" overflow="hidden" direction="column">
        <Grid
          templateColumns="24rem 1fr"
          borderTopRadius="basic"
          overflow="hidden"
          alignItems="center"
          borderBottom="1px solid gray200"
          background="white"
          padding="space12">
          <SearchField
            clearTooltip={t('purchase-orders:search.clearTooltip')}
            placeholder={t('purchase-orders:search.placeholder')}
            value={searchQuery}
            onChange={query => {
              handleDebounceSearch(query);
              updateSearchParam(queryParameter, query);
            }}
            inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
          />
          <Flex justifySelf="end" gap="space8">
            <ConfigurationsMenu
              isLoading={isSavingConfigurations || isResetPending}
              configurationItems={configurationsTable}
              onUpdateConfigurations={handleUpdateConfigurations}
              onResetConfigurations={handleResetTableConfigurations}
            />

            <SyncFromSap type={SAP_SYNC.PurchaseOrders} />
            <PurchaseOrdersKebabMenu />
          </Flex>
        </Grid>
        <PurchaseOrdersTable
          // Do not remove this please. Unfortunately we need to make sure that when we remove or add columns
          // we unmount and re-mount the component so that the filter/sort state inside the table is reset.
          // If we do not do that we will end up left with filters/sorts inside the table state for columns that are not rendered.
          key={`purchase-order-table-${availablePurchaseOrdersColumnIds.join(
            '-'
          )}}`}
          isLoading={isLoadingPurchaseOrderList}
          columns={availablePurchaseOrdersColumnIds}
          columnWidths={columnWidths}
          onEndReached={onLoadMore}
          onFilter={handleFilter}
          onSort={handleSort}
          customEmptyState={emptyState}
          data={purchaseOrderToTableData(purchaseOrderList)}
          filterOptions={filterOptions}
          defaultFilters={filters}
          defaultSortBy={sortBy}
          borderTopRadius="none"
        />
      </Flex>
    </ArchiveViewLayout>
  );
};
