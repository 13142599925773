import { UserProfileFormErrorMessages } from './userProfileFormSchema';

export const userProfileFormErrorMessages: UserProfileFormErrorMessages = {
  firstName: {
    label: 'header.profile.tabs.update.form.firstName.label',
  },
  lastName: {
    label: 'header.profile.tabs.update.form.lastName.label',
  },
  email: {
    label: 'header.profile.tabs.update.form.email.label',
  },
  locale: {
    label: 'header.profile.tabs.update.form.language.label',
  },
};
