import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useState, useCallback } from 'react';
import { UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { useDeleteExpense } from 'views/Reimbursement/hooks/useDeleteExpense';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { VIEW_URL_PARAM } from 'views/Reimbursement/Reimbursement';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';

type BooleanMap = { [key: string]: boolean };
interface UseExpensesFormActionsParams {
  onRemoveForm: UseFieldArrayRemove;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  createExpenseInvoice: (file: File) => Promise<ExpenseInvoice | undefined>;
  expenseType: 'general' | 'hospitality';
}

export const useExpensesFormActions = ({
  createExpenseInvoice,
  onRemoveForm,
  formMethods,
  expenseType,
}: UseExpensesFormActionsParams) => {
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<BooleanMap>({});
  const [isDeletingForm, setIsDeletingForm] = useState<BooleanMap>({});
  const [clearableField, setClearableField] = useState<string>();

  const { updateHospitalityExpense } = useUpdateHospitalityExpense();
  const { updateGeneralExpense } = useUpdateGeneralExpense();
  const { deleteExpense } = useDeleteExpense();
  const { updateSearchParam } = useMutateSearchParams();
  const { setValue, getValues, watch } = formMethods;

  const handleClearableField = useCallback(
    (field?: string) => () => {
      setClearableField(field);
    },
    []
  );

  const handleUpdateHospitality = useCallback(
    (index: number) => () => {
      const formValues = getValues(`hospitalityExpenses.${index}`);
      handleClearableField(undefined)();
      void updateHospitalityExpense(formValues);
    },
    [getValues, handleClearableField, updateHospitalityExpense]
  );

  const handleUpdateGeneralExpense = useCallback(
    (index: number) => () => {
      const formValues = getValues(`generalExpenses.${index}`);
      setClearableField(undefined);
      void updateGeneralExpense(formValues);
    },
    [getValues, updateGeneralExpense]
  );

  const handleDeleteForm = useCallback(
    (index: number, expenseId: string) => async () => {
      setIsDeletingForm(prev => ({ ...prev, [index]: true }));

      const deleted = await deleteExpense(expenseId);
      setIsDeletingForm(prev => ({ ...prev, [index]: false }));

      if (!deleted) return;
      onRemoveForm(index);
      const generalExpensesLength = watch('generalExpenses').length;
      const hospitalityExpensesLength = watch('hospitalityExpenses').length;
      if (!generalExpensesLength && !hospitalityExpensesLength) {
        updateSearchParam(VIEW_URL_PARAM, '');
      }
    },
    [deleteExpense, onRemoveForm, updateSearchParam, watch]
  );

  const handleInvoiceChange = useCallback(
    async (file: File, index: number) => {
      setIsCreatingInvoice(prev => ({ ...prev, [index]: true }));

      const invoice = await createExpenseInvoice(file);

      if (!invoice) {
        setIsCreatingInvoice(prev => ({ ...prev, [index]: false }));

        return;
      }

      if (expenseType === 'hospitality') {
        setValue(`hospitalityExpenses.${index}.files`, [invoice]);
      } else {
        setValue(`generalExpenses.${index}.files`, [invoice]);
      }

      setIsCreatingInvoice(prev => ({ ...prev, [index]: false }));
    },

    [createExpenseInvoice, expenseType, setValue]
  );

  const handleReplaceInvoice = useCallback(
    (index: number) => () => {
      if (expenseType === 'hospitality') {
        setValue(`hospitalityExpenses.${index}.files`, []);
        handleUpdateHospitality(index)();
      } else {
        setValue(`generalExpenses.${index}.files`, []);
        handleUpdateGeneralExpense(index)();
      }
    },
    [expenseType, handleUpdateGeneralExpense, handleUpdateHospitality, setValue]
  );

  return {
    isDeletingForm,
    clearableField,
    isCreatingInvoice,
    handleClearableField,
    handleUpdateHospitality,
    handleDeleteForm,
    handleInvoiceChange,
    handleReplaceInvoice,
    handleUpdateGeneralExpense,
  };
};
