import { Box, Grid } from '@candisio/design-system';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
import { POSTING_TEXT_FIELD_MAX_LENGTH } from 'views/consts';
import { useDatev } from '../../../orgConfig/datev';
import {
  ProcessingFormFieldOptions,
  ProcessingFormProps,
} from './ProcessingForm';
import { ProcessingFormAccountingFields } from './ProcessingFormAccountingFields';
import { ProcessingFormNoteField } from './ProcessingFormNoteField';
import { ProcessingFormTextField } from './ProcessingFormTextField';
import { useProcessingFormLengthWarning } from './useProcessingFormLengthWarning';

export interface ProcessingFormBookingFieldsProps {
  /** Individual field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /** Integration that the organization is using */
  integration: ProcessingFormProps['integration'];
  /** Display fields as read only */
  readOnly?: boolean;
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
}

/**
 * Displays booking-level fields (first booking only):
 * - posting text
 * - note
 * - accounting data fields:
 *   - general ledger account
 *   - tax code
 *   - cost center
 *   - cost object
 *   - artist social insurance code
 *   - extra cost info
 *
 * Individual fields can be configured or hidden with the `fieldOptions` prop.
 */
export const ProcessingFormBookingFields = ({
  fieldOptions,
  integration,
  readOnly,
  ...restProps
}: ProcessingFormBookingFieldsProps) => {
  const [t] = useTranslation();
  const { client, bdsBought, bdsConnected } = useDatev(); // BDS-checked
  const maxLength = client ? POSTING_TEXT_FIELD_MAX_LENGTH : Infinity;
  const { lengthExceeded } = useProcessingFormLengthWarning({
    name: 'bookings.0.postingText',
    maxLength,
  });

  return (
    <Grid gap="space16">
      <ProcessingFormAccountingFields
        fieldOptions={fieldOptions}
        integration={integration}
        readOnly={readOnly}
        {...restProps}
      />
      {!fieldOptions?.postingText?.hidden && (
        <Box>
          <ProcessingFormTextField
            name="bookings.0.postingText"
            label={t('document.requestApproval.inputs.postingText.label')}
            placeholder={t(
              'document.requestApproval.inputs.postingText.BdsPlaceholder'
            )}
            description={
              lengthExceeded
                ? {
                    text: t(
                      'document.requestApproval.inputs.postingText.maxLengthWarning',
                      {
                        maxLength,
                      }
                    ),
                    color: 'warning',
                  }
                : undefined
            }
            {...fieldOptions?.postingText?.props}
            readOnly={readOnly || fieldOptions?.postingText?.props?.readOnly}
            ignorePasswordManager={false}
            {...restProps}
          />
        </Box>
      )}
      <Box>
        <ProcessingFormNoteField
          name="bookings.0.note"
          label={t('document.requestApproval.inputs.note.label', {
            context: getTranslationContext(),
          })}
          placeholder={
            readOnly
              ? t('document.requestApproval.inputs.note.placeholder.notSet')
              : t('document.requestApproval.inputs.note.placeholder.enabled')
          }
          readOnly={readOnly}
          infoTooltip={
            readOnly
              ? undefined
              : {
                  message:
                    bdsBought && bdsConnected
                      ? t(
                          'document.requestApproval.inputs.note.infoTooltip.bds',
                          {
                            context: getTranslationContext(),
                          }
                        )
                      : t(
                          'document.requestApproval.inputs.note.infoTooltip.enabled',
                          {
                            context: getTranslationContext(),
                          }
                        ),
                }
          }
          {...restProps}
        />
      </Box>
    </Grid>
  );
};
