import { useUpdatePaymentDataMutation } from 'generated-types/graphql.types';
import { countPayableDocuments } from 'hooks/useCounterQueries';
import { getDocumentQuery } from 'views/Inbox/DocumentProcessing/queries';
import { PaymentStatus } from 'views/Payments/types';
import { documentHistoryQuery } from 'views/queries';
import { DocumentFormValues } from '../../toolkit/schema';

export interface UseUpdatePaymentDataOptions {
  documentId?: string;
}

type UpdateReturn = {
  status: 'success' | 'error';
};

export interface UseUpdatePaymentDataReturn {
  update: (
    values: DocumentFormValues,
    initialValues: DocumentFormValues | undefined
  ) => Promise<UpdateReturn>;
  updating: boolean;
}

export const useUpdatePaymentData = ({
  documentId,
}: UseUpdatePaymentDataOptions): UseUpdatePaymentDataReturn => {
  const [updatePaymentDataMutation, { loading: updating }] =
    useUpdatePaymentDataMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getDocumentQuery,
          variables: {
            id: documentId,
          },
        },
        {
          query: documentHistoryQuery,
          variables: {
            id: documentId,
          },
        },
        { query: countPayableDocuments },
      ],
    });

  return {
    update: async (values, initialValues) => {
      if (!documentId) {
        return { status: 'error' };
      }

      const { createTransfer, iban, paidAt, paymentStatus, swiftCode } =
        initialValues as DocumentFormValues;

      const removeWhitespace = (iban: string | undefined | null) =>
        iban?.replace(/\s/g, '');

      const hasIbanChanged =
        removeWhitespace(values.iban) !== removeWhitespace(iban);

      const hasSwiftCodeChanged = values.swiftCode !== swiftCode;
      const hasCreateTransferChanged = values.createTransfer !== createTransfer;
      const hasPaymentStatusChanged = values.paymentStatus !== paymentStatus;
      const hasPaidAtChanged = values.paidAt !== paidAt;
      const isPaymentStatusPaid = values.paymentStatus === PaymentStatus.PAID;

      const isPaid = () => {
        if (hasPaymentStatusChanged) {
          if (isPaymentStatusPaid) {
            return true;
          } else {
            return false;
          }
        }

        if (hasPaidAtChanged) {
          return true;
        }

        return undefined;
      };

      await updatePaymentDataMutation({
        variables: {
          id: documentId,
          input: {
            iban: hasIbanChanged ? values.iban : undefined,
            swiftCode: hasSwiftCodeChanged ? values.swiftCode : undefined,
            createTransfer: hasCreateTransferChanged
              ? values.createTransfer
              : undefined,
            isPaid: isPaid(),
            paidAt:
              isPaymentStatusPaid && hasPaidAtChanged && values.paidAt
                ? new Date(values.paidAt).toISOString()
                : undefined,
          },
        },
      });

      return { status: 'success' };
    },
    updating,
  };
};
