import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export interface LinkCardToVendorFormSchemaOptions {
  isVendorEditable: boolean;
}

export const linkCardToVendorFormSchema = ({
  isVendorEditable,
}: LinkCardToVendorFormSchemaOptions) =>
  z.object({
    creditCard: z.string(),
    vendorName: isVendorEditable ? z.string() : z.string().optional(),
  });

export type LinkCardToVendorFormOutput = z.infer<
  ReturnType<typeof linkCardToVendorFormSchema>
>;

export type LinkCardToVendorFormValues = Partial<LinkCardToVendorFormOutput>;

export type RequestVirtualCardFormErrorMessages = ErrorMessages<
  ReturnType<typeof linkCardToVendorFormSchema>
>;
