import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useListSsoConfigurationsQuery,
  useRemoveOrganizationSsoConfigurationMutation,
  useUpdateOrganizationSsoConfigurationMutation,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { SETTINGS_ROUTES } from 'views/Settings/types';

export const useOrganizationSSO = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const navigate = useNavigate();

  const { data, loading } = useListSsoConfigurationsQuery();
  const ssoConfigurationList = data?.listSSOConfigurations || [];

  const [updateOrganizationSSOMutation] =
    useUpdateOrganizationSsoConfigurationMutation();

  const [clearOrganizationSSOConfigurationMutation] =
    useRemoveOrganizationSsoConfigurationMutation();

  const updateOrganizationSSO = async (
    organizationSlug: string,
    ssoConfigurationId: string
  ) => {
    const result = await updateOrganizationSSOMutation({
      variables: {
        ssoConfigurationId: ssoConfigurationId,
        organizationSlug: organizationSlug,
      },
      refetchQueries: ['getOrganizations'],
    });

    if (result.errors) {
      error(t('sso.toastMessage.updateError'));
    } else {
      success(t('sso.toastMessage.updateSuccess'));
    }
  };

  const clearOrganizationSSOConfiguration = async (
    organizationSlug: string
  ) => {
    const result = await clearOrganizationSSOConfigurationMutation({
      variables: {
        organizationSlug,
      },
      refetchQueries: ['getOrganizations'],
    });

    if (result.errors) {
      error(t('sso.toastMessage.updateError'));
    }
  };

  const navigateToSSOConfiguration = (
    organizationSlug: string,
    ssoConfigurationId?: string
  ) => {
    const navigageTo = buildRouteSSO(organizationSlug, ssoConfigurationId);
    navigate({
      pathname: navigageTo,
    });
  };

  const buildRouteSSO = (
    organizationSlug: string,
    ssoConfigurationId?: string
  ): string => {
    let route = `/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.SINGLE_SIGN_ON}`;
    if (ssoConfigurationId) {
      route += `/${ssoConfigurationId}`;
    }

    return route;
  };

  return {
    ssoConfigurationList,
    loading,
    updateOrganizationSSO,
    clearOrganizationSSOConfiguration,
    navigateToSSOConfiguration,
  };
};
