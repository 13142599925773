import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { SETTINGS_ROUTES } from '../types';
import { SingleSignOnContainer } from './SingleSignOnContainer';

/** Handles routing for payment conditions settings view */
export const SingleSignOnRoute = () => {
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const { id: ssoConfigurationId } = useParams();

  const backToOrganization = () => {
    navigate({
      pathname: `/${organizationId}${Routes.SETTINGS}${Routes.ORGANIZATION}`,
    });
  };

  const navigateToSSOConfiguration = (ssoConfigurationId: string) => {
    navigate({
      pathname: `/${organizationId}${Routes.SETTINGS}/${SETTINGS_ROUTES.SINGLE_SIGN_ON}/${ssoConfigurationId}`,
    });
  };

  return (
    <SingleSignOnContainer
      id={ssoConfigurationId}
      organizationSlug={organizationId}
      backToOrganization={backToOrganization}
      navigateToSSOConfiguration={navigateToSSOConfiguration}
    />
  );
};
