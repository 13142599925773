import { Image, Text } from '@candisio/design-system';
import VisaLogo from 'containers/credit-cards/CreditCard/media/visa-black.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

export const CreditCardPayment = ({
  value,
}: CellProps<DocumentsTableData, boolean | undefined>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return <Image alt="Visa" src={VisaLogo} />;
};
