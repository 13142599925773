import gql from 'graphql-tag';

export const sapB1CredentialsQuery = gql`
  query sapB1Credentials {
    sapB1Credentials {
      companyDbId
      serviceLayerUrl
      username
    }
  }
`;

export const verifySapB1CredentialsQuery = gql`
  query verifySapB1Credentials($sapB1Credentials: SapB1CredentialsInput) {
    verifySapB1Credentials(sapB1Credentials: $sapB1Credentials) {
      code
    }
  }
`;

export const createSapB1CredentialsMutation = gql`
  mutation createSapB1Credentials($sapB1Credentials: SapB1CredentialsInput!) {
    createSapB1Credentials(sapB1Credentials: $sapB1Credentials) {
      code
    }
  }
`;

export const deleteSapB1CredentialsMutation = gql`
  mutation deleteSapB1Credentials {
    deleteSapB1Credentials {
      code
    }
  }
`;

export const refetchSapSettings = [
  { query: sapB1CredentialsQuery },
  { query: verifySapB1CredentialsQuery },
];
