import { useToastMessage } from 'components/Toast/useToastMessage';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/downloadFile';
import { usePaymentConditionsCounts } from './hooks/usePaymentConditionsCounts';
import { usePaymentConditionsExport } from './hooks/usePaymentConditionsExport';
import { usePaymentConditionsListData } from './hooks/usePaymentConditionsListData';
import { PaymentConditions, PaymentConditionsProps } from './PaymentConditions';

export interface PaymentConditionsContainerProps {
  onChangeStatus?: PaymentConditionsProps['onChangeStatus'];
  onShowItem?: PaymentConditionsProps['onShowItem'];
  onShowImport?: PaymentConditionsProps['onShowImport'];
  onShowImportHistory?: PaymentConditionsProps['onShowImportHistory'];
  status?: PaymentConditionsProps['status'];
}

/** Handles data fetching for PaymentConditions component */
export const PaymentConditionsContainerDefault = ({
  onChangeStatus,
  onShowItem,
  onShowImport,
  onShowImportHistory,
  status = 'active',
}: PaymentConditionsContainerProps) => {
  const [t] = useTranslation('settings');
  const { success, error } = useToastMessage();
  const {
    numActive,
    numArchived,
    loading: loadingCounts,
  } = usePaymentConditionsCounts();

  const { getExportFileUrl } = usePaymentConditionsExport();
  const { data, loading: loadingData } = usePaymentConditionsListData({
    status,
  });

  const handleStartExport = async () => {
    const result = await getExportFileUrl();

    if (result.status === 'success') {
      const { url: fileUrl } = result.file;

      downloadFile(fileUrl);
      success(t('paymentConditions.export.downloadSuccess'));
    } else {
      error(t('paymentConditions.export.downloadError'));
    }
  };

  return (
    <PaymentConditions
      data={data}
      loading={loadingCounts || loadingData}
      numActive={numActive}
      numArchived={numArchived}
      onShowImport={onShowImport}
      onChangeStatus={onChangeStatus}
      onShowItem={onShowItem}
      onShowImportHistory={onShowImportHistory}
      onStartExport={handleStartExport}
      status={status}
    />
  );
};
