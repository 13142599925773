import { UseFormSetError } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { SelectFileFormValues } from './formConfig';

export class PaymentConditionWithoutNumberError extends Error {
  constructor() {
    super('Found payment condition without code');
  }
}

export class PaymentConditionCSVWrongFormat extends Error {
  constructor() {
    super('Csv file is not in DATEV format');
  }
}

export const handlePCErrors = (
  error: unknown,
  setError: UseFormSetError<SelectFileFormValues>,
  t: TFunction<'settings'>
) => {
  switch (true) {
    case error instanceof PaymentConditionWithoutNumberError: {
      setError('file', {
        type: 'manual',
        message: t('paymentConditions.import.errors.pcNumberMissing'),
      });
      break;
    }

    case error instanceof PaymentConditionCSVWrongFormat: {
      setError('file', {
        type: 'manual',
        message: t('paymentConditions.import.errors.pcCsvWrongFormat'),
      });
      break;
    }

    default: {
      setError('file', {
        type: 'manual',
        message: t('paymentConditions.import.errors.unknownError'),
      });
    }
  }
};
