import { useOrganizationsByMembershipQuery } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export interface Organization {
  id: string;
  label: string;
}

export interface OrganizationsByMemmbershipList {
  loading: boolean;
  hasMoreThanOneOrganization: boolean;
  organizations: Organization[];
}

export const useOrganizationsByMembershipList =
  (): OrganizationsByMemmbershipList => {
    const user = useCurrentUser();

    const { data, loading } = useOrganizationsByMembershipQuery({
      variables: {
        membershipId: user?.id ?? '',
      },
    });

    const organizations = (data?.organizationsByMembership ?? []).map(d => ({
      id: d.id,
      label: d.name,
    }));

    const hasMoreThanOneOrganization = !loading && organizations.length > 1;

    return { organizations, hasMoreThanOneOrganization, loading };
  };
