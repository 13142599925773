import { Button, Grid, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface ArchivedActionsProps {
  onRestore?: () => void;
  restoring?: boolean;
}
export const ArchivedActions = ({
  onRestore,
  restoring = false,
}: ArchivedActionsProps) => {
  const [t] = useTranslation('settings');

  return (
    <Grid
      borderRadius="medium"
      padding="space16"
      background="bluebg"
      gap="space4">
      <Text fontSize="basic" fontWeight="semibold">
        {t('paymentConditions.archived.title')}
      </Text>
      <Text fontSize="small">
        {t('paymentConditions.archived.description')}
      </Text>

      <Grid justifyContent="end" paddingTop="space16">
        <Button color="blue" onClick={onRestore} loading={restoring}>
          {t('paymentConditions.form.buttons.restore')}
        </Button>
      </Grid>
    </Grid>
  );
};
