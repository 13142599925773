import { pdfjs } from 'react-pdf';

export const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

export const intersectionOptions = {
  threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
};

export const getPageId = (prefix: string, pageNumber: number) => {
  return `${prefix}_page_${pageNumber}`;
};

export const getHighestNumberVisiblePage = (
  visiblePages: Map<number, number>
) => {
  let highestNumber = 1;
  let highestVisibilityRatio = 0;
  for (const [number, visibilityRatio] of visiblePages.entries()) {
    if (visibilityRatio > highestVisibilityRatio) {
      highestNumber = number;
      highestVisibilityRatio = visibilityRatio;
    }
  }

  return highestNumber;
};

export const PAGE_CHANGE_VISIBILITY_WAIT_TIME_MS = 100;
export type PdfStatus = 'idle' | 'loading' | 'loaded' | 'error';
