import { useModal } from '@candisio/design-system';
import { PromotionPanel } from 'components/Promotions/PromotionPanel';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { BDSPromotionModal } from 'views/Integrations/Export/toolkit/components/BDSPromotion';
import { ModalSource } from 'views/Integrations/Export/types';
import provisionPromotionThumbnailDE from '../../assets/provisions-promotion-thumbnail-DE.svg';
import provisionPromotionThumbnailEN from '../../assets/provisions-promotion-thumbnail-EN.svg';

export const ProvisionPromotionDetails = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { modalProps, isOpen, open } = useModal();

  const modalSource = ModalSource.PROVISIONS_SETTINGS;

  const thumbnails = {
    EN: provisionPromotionThumbnailEN,
    DE: provisionPromotionThumbnailDE,
  };

  const slots = {
    header: t('provisions.lockedCard.header'),
    description: t('provisions.lockedCard.description'),
    button: t('provisions.lockedCard.button'),
  };

  return (
    <>
      <PromotionPanel
        thumbnails={thumbnails}
        slots={slots}
        onLearnMoreClick={open}
      />
      {isOpen && (
        <BDSPromotionModal modalSource={modalSource} {...modalProps} />
      )}
    </>
  );
};
