import {
  useSapB1SettingsQuery,
  useVerifySapB1CredentialsQuery,
} from '../../../../generated-types/graphql.types';
import { useSap } from '../../../../orgConfig/sap';
import { SapFormValues } from './components/Form/SapConnectionForm';

export const adminModalHash = '#contactAdmin';

export const sanitizeFormData = (formData: SapFormValues) => {
  return {
    ...formData,
    username: formData.username.replace(/\\/, '\\'),
  };
};

export const useNeedsSapConfig = () => {
  const { shouldUseSapConfig } = useSap();

  const { data: sapConfigData } = useVerifySapB1CredentialsQuery({
    skip: !shouldUseSapConfig,
  });

  // If we have a code, it means some kind of validation error happened, but we don't care about the
  // details here
  const sapConfigFaulty = Boolean(sapConfigData?.verifySapB1Credentials?.code);

  return shouldUseSapConfig && sapConfigFaulty;
};

export const useNeedsSapCreditCardConfig = () => {
  const { shouldUseSapConfig } = useSap();

  const { data: sapB1Settings, loading } = useSapB1SettingsQuery({
    skip: !shouldUseSapConfig,
  });

  const {
    moneyInTransitGeneralLedgerAccount,
    creditCardId,
    creditCardGeneralLedgerAccount,
  } = sapB1Settings?.sapB1Settings || {};

  const sapB1SettingsFaulty = !(
    moneyInTransitGeneralLedgerAccount &&
    creditCardId &&
    creditCardGeneralLedgerAccount
  );

  return loading ? false : shouldUseSapConfig && sapB1SettingsFaulty;
};
