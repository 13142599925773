import {
  CardStatus,
  CardType,
  DateFilterInput,
  DateFilterOption,
  useGetCountCardsQuery,
} from 'generated-types/graphql.types';
import moment from 'moment';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';

export const useGetCountExpiringSoonCards = () => {
  const todayDate = new Date();

  const timezone = useOrganizationTimezone();

  const nextMonthFilter: DateFilterInput = {
    dateFrom: moment.tz(todayDate.toISOString(), timezone).format('DD.MM.YYYY'),
    dateTo: moment
      .tz(todayDate.toISOString(), timezone)
      .add(30, 'days')
      .format('DD.MM.YYYY'),
    filterOption: DateFilterOption.Custom,
  };

  const { data, loading } = useGetCountCardsQuery({
    // We need to have cache-and-network policy because this query is used in the credit cards dashboard and we need to rely as much as possible on new data
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        statuses: [
          CardStatus.Active,
          CardStatus.Pending,
          CardStatus.Locked,
          CardStatus.LockedPin,
        ],
        types: [CardType.Physical, CardType.Virtual, CardType.SingleUse],
        dateRangeFilters: {
          expiryDate: nextMonthFilter,
        },
      },
    },
  });

  const countExpiringCards = data?.countCards?.total ?? 0;

  return { countExpiringCards, loading, nextMonthFilter };
};
