import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom-v5-compat';
import { useCheckTeamNameAvailability } from '../../../toolkit/hooks/useCheckTeamNameAvailability';
import { useGetTeamById } from '../../../toolkit/hooks/useGetTeamById';
import { useUpdateTeam } from '../../../toolkit/hooks/useUpdateTeam';
import { TeamDetailsFormOutput } from '../../../toolkit/utils/teamDetailsFormSchema';
import { UpdateTeamDetailsForm } from './UpdateTeamDetailsForm';

interface UpdateTeamDetailsFormContainerProps {
  onGoBack: () => void;
}

export const UpdateTeamDetailsFormContainer = ({
  onGoBack,
}: UpdateTeamDetailsFormContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const { id } = useParams<{ id: string }>();
  const { loading: isLoadingTeam, team } = useGetTeamById({ id });
  const { isUpdateTeamPending, updateTeam } = useUpdateTeam();
  const { checkTeamName } = useCheckTeamNameAvailability({
    originalValue: team.name,
    teamId: id,
  });

  const { users: teamAdminOptions, loading: isLoadingTeamAdminOptions } =
    useUsersFieldOptions({
      status: 'Active',
    });

  const { loading: isLoadingTeamMembersOptions, users: teamMembersOptions } =
    useUsersFieldOptions({
      status: 'ActiveAndPending',
    });

  const handleSubmit = async (formValues: TeamDetailsFormOutput) => {
    if (!id) {
      return;
    }

    const { status } = await updateTeam(id, formValues);

    if (status === 'error') {
      error(t('teams.editTeam.updateError'));

      return;
    }

    success(t('teams.editTeam.updateSuccess'));
    onGoBack();
  };

  const teamMemberIds = (team.members ?? []).map(member => member.id);

  const defaultValues: TeamDetailsFormOutput = {
    teamAdmin: team.teamAdmin?.id ?? '',
    teamName: team.name,
    teamMembers: teamMemberIds,
  };

  return (
    <UpdateTeamDetailsForm
      teamMembersOptions={teamMembersOptions}
      defaultValues={defaultValues}
      teamAdminOptions={teamAdminOptions}
      isUpdateTeamPending={isUpdateTeamPending}
      isLoading={
        isLoadingTeam ||
        isLoadingTeamAdminOptions ||
        isLoadingTeamMembersOptions
      }
      onGoBack={onGoBack}
      onSubmit={handleSubmit}
      checkTeamName={checkTeamName}
    />
  );
};
