import gql from 'graphql-tag';

export const organizationDataFragment = gql`
  fragment OrganizationData on Organization {
    id
    name
    slug
    locale
    realmName
    ssoConfigurationId
    organizationContactInformation {
      vatId
      taxNumber
    }
  }
`;

export const updateOrganizationContactInformationMutation = gql`
  mutation updateOrganizationContactInformation(
    $input: OrganizationContactInformationInput!
  ) {
    organizationContactInformation(input: $input) {
      __typename
      ... on OrganizationContactInformation {
        vatId
        taxNumber
      }
      ... on OrganizationContactInformationErrors {
        errors {
          ... on InvalidVatID {
            kind
            message
          }
          ... on InvalidTaxNumber {
            kind
            message
          }
        }
      }
    }
  }
`;
