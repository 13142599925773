import { useCurrentUser } from 'providers/CurrentUserProvider';
import { i18n } from 'providers/LocaleProvider';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';

export const useMonthFormatter = () => {
  const user = useCurrentUser();
  const timeZone = useOrganizationTimezone();
  const longNotation = new Intl.DateTimeFormat(user?.locale ?? i18n.language, {
    month: 'long',
    year: 'numeric',
    timeZone,
  });

  return { longNotation };
};
