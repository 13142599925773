import { ButtonProps } from '@candisio/design-system';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneNumberFormProps } from './PhoneNumberForm';

interface UsePhoneNumberCardStateProps {
  phoneNumberFormId: string;
  currentPhoneNumber: PhoneNumberFormProps['currentPhoneNumber'];
  otpFormId: string;
  isPhoneNumberFieldAlreadyVisible: boolean;
  onCancelPendingPhoneVerification: () => void;
}

interface CardStateButtonProps {
  color?: ButtonProps['color'];
  icon?: ButtonProps['icon'];
  handler?: (...args: any[]) => void;
  size?: ButtonProps['size'];
  text: string;
  type?: ButtonProps['type'];
  variant: ButtonProps['variant'];
}

type Mode =
  | 'no-phone-number'
  | 'phone-number-form'
  | 'otp-code-form'
  | 'verified-phone';

interface PhoneNumberCardState {
  mode: Mode;
  setMode: (mode: Mode) => void;
  justifyButtons?: CSSProperties['justifyContent'];
  primaryButton:
    | { show: false }
    | ({
        show: true;
        formId?: string;
      } & CardStateButtonProps);
  secondaryButton:
    | { show: false }
    | ({
        show: true;
      } & CardStateButtonProps);
}

export const usePhoneNumberCardState = ({
  currentPhoneNumber,
  phoneNumberFormId,
  isPhoneNumberFieldAlreadyVisible,
  onCancelPendingPhoneVerification,
  otpFormId,
}: UsePhoneNumberCardStateProps): PhoneNumberCardState => {
  const [t] = useTranslation();

  const [mode, setMode] = useState<PhoneNumberCardState['mode']>(
    isPhoneNumberFieldAlreadyVisible
      ? 'phone-number-form'
      : currentPhoneNumber
      ? 'verified-phone'
      : 'no-phone-number'
  );

  const handleCancelRequestPhoneVerification = () => {
    if (currentPhoneNumber) {
      return setMode('verified-phone');
    }

    setMode('no-phone-number');
  };

  const handleCancelPendingPhoneVerification = () => {
    onCancelPendingPhoneVerification();
    if (currentPhoneNumber) {
      return setMode('verified-phone');
    }

    setMode('no-phone-number');
  };

  switch (mode) {
    case 'otp-code-form':
      return {
        mode,
        setMode,
        primaryButton: {
          show: true,
          color: 'blue',
          formId: otpFormId,
          icon: 'check',
          text: t(
            'header.profile.tabs.update.accountSecurity.phoneNumberMfaCard.otpForm.submit'
          ),
          type: 'submit',
          variant: 'primary',
        },
        secondaryButton: {
          show: true,
          color: 'red',
          handler: handleCancelPendingPhoneVerification,
          text: t(
            'header.profile.tabs.update.accountSecurity.phoneNumberMfaCard.otpForm.cancel'
          ),
          variant: 'tertiary',
        },
      };

    case 'verified-phone':
      return {
        mode,
        setMode,
        primaryButton: {
          show: false,
        },
        secondaryButton: {
          show: false,
        },
      };

    case 'phone-number-form':
      return {
        mode,
        setMode,
        primaryButton: {
          show: true,
          color: 'blue',
          formId: phoneNumberFormId,
          icon: 'send',
          text: t(
            'header.profile.tabs.update.accountSecurity.phoneNumberMfaCard.phoneNumberForm.submit'
          ),
          type: 'submit',
          variant: 'primary',
        },
        secondaryButton: {
          show: true,
          color: 'red',
          text: t(
            'header.profile.tabs.update.accountSecurity.phoneNumberMfaCard.phoneNumberForm.cancel'
          ),
          variant: 'tertiary',
          handler: handleCancelRequestPhoneVerification,
        },
      };

    case 'no-phone-number':
    default:
      return {
        mode,
        setMode,
        justifyButtons: 'flex-start',
        primaryButton: {
          show: true,
          color: 'blue',
          handler: () => setMode('phone-number-form'),
          icon: 'plus',
          text: t('header.profile.tabs.update.accountSecurity.addMfa'),
          variant: 'secondary',
        },
        secondaryButton: {
          show: false,
        },
      };
  }
};
