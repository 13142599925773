import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { mapRelationshipToTableRows } from 'containers/document-relationships/DocumentRelationshipsModal';
import {
  EcmDocumentType,
  useGetDocumentForManageRelationshipsQueryQuery,
  useGetEcmDocumentForManageRelationshipsQueryQuery,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useEcmContractTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';
import { isEcmDocumentId } from './useManageRelationshipsData';

export const useSourceDocument = ({ documentId }: { documentId: string }) => {
  const isEcmDocument = isEcmDocumentId(documentId);
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  const { getContractTypeLabel } = useEcmContractTypeOptions();
  const { data: getDocumentData } =
    useGetDocumentForManageRelationshipsQueryQuery({
      variables: {
        id: documentId,
      },
      skip: isEcmDocument,
    });

  const { data: getEcmDocumentData } =
    useGetEcmDocumentForManageRelationshipsQueryQuery({
      variables: {
        id: documentId,
      },
      skip: !isEcmDocument,
    });

  const contractTypeId = isEcmDocument
    ? getEcmDocumentData?.getAggregatedEcmDocument.documentSubCategory?.id
    : undefined;

  let documentType: string | undefined =
    ecmDocumentTypeTranslationMap[EcmDocumentType.Invoice];

  if (isEcmDocument && contractTypeId) {
    documentType = getContractTypeLabel(contractTypeId);
  }

  if (isEcmDocument && !contractTypeId) {
    const type =
      getEcmDocumentData?.getAggregatedEcmDocument.type ??
      EcmDocumentType.Other;

    documentType = ecmDocumentTypeTranslationMap[type];
  }

  const documentName = isEcmDocument
    ? getEcmDocumentData?.getAggregatedEcmDocument.contactName
    : getDocumentData?.getDocument?.contact?.value.name.value;

  const sourceDocument: EcmDocumentsTableData = useMemo(() => {
    return {
      ...mapRelationshipToTableRows({
        id: documentId,
        linkedDocument: {
          id: documentId,
          __typename: 'RelatedDocument',
          document: isEcmDocument
            ? getEcmDocumentData?.getAggregatedEcmDocument
            : getDocumentData?.getDocument,
        },
      })[0],
      isDisabled: true,
    };
  }, [
    documentId,
    getDocumentData?.getDocument,
    getEcmDocumentData?.getAggregatedEcmDocument,
    isEcmDocument,
  ]);

  return {
    sourceDocument,
    documentName,
    documentType,
  };
};
