import { FieldPolicy, TypePolicies } from '@apollo/client';
import { PageBasedTransactionDownloadHistoryList } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const cardIssuerTransactionsHistory: FieldPolicy<PageBasedTransactionDownloadHistoryList> =
  {
    keyArgs: ['sortBy'],
    merge: simplePaginationMergeWithoutDuplicates(
      'PageBasedTransactionDownloadHistoryList'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      getPageBasedTransactionDownloadHistory: cardIssuerTransactionsHistory,
    },
  },
};
