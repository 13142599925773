import { Box, Link, Text } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import { nocontactData } from 'components/Lottie/Lottie';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { COST_CENTER_ROUTE_HASH } from '../consts';

interface EmptyDataStateProps {
  handleCreate: () => void;
  hasArchivedData: boolean;
}

export const EmptyDataState = ({
  handleCreate,
  hasArchivedData,
}: EmptyDataStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { shouldUseSapCostCenters } = useSap();

  return (
    <EmptyState animationData={nocontactData}>
      {shouldUseSapCostCenters ? (
        <Text>{t('costCenters.noDataSap')}</Text>
      ) : (
        <>
          <Text>{t('costCenters.noData.message')}</Text>
          <Box>
            {hasArchivedData ? (
              <Trans i18nKey="settings:costCenters.noData.activeCTAs">
                Please <Link onClick={handleCreate}>create</Link>
                or
                <Link
                  onClick={() => {
                    searchParams.set('archived', 'true');
                    navigate({
                      pathname: location.pathname,
                      search: searchParams.toString(),
                    });
                  }}>
                  reactivate
                </Link>
              </Trans>
            ) : (
              <Trans i18nKey="settings:costCenters.noData.archiveCTAs">
                Please <Link onClick={handleCreate}>create</Link>
                or
                <RouterLink to={COST_CENTER_ROUTE_HASH.import}>
                  import
                </RouterLink>
              </Trans>
            )}
          </Box>
        </>
      )}
    </EmptyState>
  );
};
