import {
  Absence,
  CardIssuerTransactionDateFilterInput,
  InvoiceAssociationStatus,
  useGetTransactionsInsightsQuery,
} from 'generated-types/graphql.types';
import { DateRange } from '../CreditCardsInsights/MissingInvoicesWidget/utils';

export interface CardHolderCCInsightsWidgetData {
  dateRange?: DateRange;
  cardholder: {
    id: string;
    firstName?: string;
    membershipId?: string;
    membershipEmail?: string;
    lastName?: string;
    avatarUrl?: string;
    absence?: Absence;
    cardsWithMissingInvoices?: Array<{
      refNum?: string;
      invoicesWithoutTransaction: number;
      label?: string;
    }>;
  };
  invoicesWithoutTransaction: number;
}

export const useTransactionsInsights = ({
  dateRangeFilters,
}: {
  dateRangeFilters?: CardIssuerTransactionDateFilterInput;
}) => {
  const { data, loading } = useGetTransactionsInsightsQuery({
    variables: {
      dateRangeFilters,
      filters: {
        invoiceAssociationStatus: [InvoiceAssociationStatus.Missing],
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const mappedCardholdersCCInsights: CardHolderCCInsightsWidgetData[] = (
    data?.getTransactionsInsights.cardholders ?? []
  ).map(insight => ({
    cardholder: {
      id: insight.cardholder.id,
      membershipId: insight.cardholder.membershipId ?? undefined,
      membershipEmail: insight.cardholder.membershipEmail ?? undefined,
      firstName: insight.cardholder.firstName ?? undefined,
      lastName: insight.cardholder.lastName ?? undefined,
      avatarUrl: insight.cardholder.avatarUrl ?? undefined,
      cardsWithMissingInvoices: insight.cards.length
        ? insight.cards.map(card => ({
            refNum: card.card.refNum ?? undefined,
            invoicesWithoutTransaction: card.aggregate.count,
            label: card.card.label ?? undefined,
          }))
        : undefined,
    },
    invoicesWithoutTransaction: insight.aggregate.count,
  }));

  return { loading, mappedCardholdersCCInsights };
};
