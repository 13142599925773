import { useDatevBookingAccountValidationQuery } from 'generated-types/graphql.types';
import { TFunction } from 'react-i18next';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const DEFAULT_BOOKING_ACCOUNT_NUMBER_MAX_LENGTH = 8;
export const DEFAULT_BOOKING_ACCOUNT_NUMBER_MIN_LENGTH = 4;
const DIGITS_REGEX = /^[0-9]*$/;
const ONLY_ZEROS_DIGITS = /^(0)\1{3,}$/;

export const useDatevProvisionsAccountValidation = (
  fieldName: 'provisionAccountNumber' | 'otherAssetsAccountNumber'
) => {
  const { data } = useDatevBookingAccountValidationQuery();

  const bookingValidation = {
    minLength:
      data?.datevBookingAccountValidation.minLength ||
      DEFAULT_BOOKING_ACCOUNT_NUMBER_MIN_LENGTH,
    maxLength:
      data?.datevBookingAccountValidation.maxLength ||
      DEFAULT_BOOKING_ACCOUNT_NUMBER_MAX_LENGTH,
  };

  const schema = ({ t }: { t: TFunction }) => {
    return z.object({
      [fieldName]: z
        .string()
        .regex(DIGITS_REGEX, {
          message: t('common:validation.fields.number.digit'),
        })
        .min(bookingValidation.minLength)
        .max(bookingValidation.maxLength)
        .refine(val => !ONLY_ZEROS_DIGITS.test(val ?? ''), {
          message: t('common:validation.fields.number.zeroRepeated'),
        })
        .or(z.string().length(0).nullable()),
    });
  };

  const errorMessages = ({
    t,
    label,
  }: {
    t: TFunction;
    label: string;
  }): ErrorMessages<ReturnType<typeof schema>> => {
    const { minLength, maxLength } = bookingValidation;

    const rangeValidationError = t(
      'common:validation.fields.number.rangeLength',
      {
        minLength,
        maxLength,
      }
    );

    const fixedLengthError = t('common:validation.fields.number.fixLength', {
      length: maxLength,
    });

    return {
      [fieldName]: {
        label,
        too_big: {
          translationKey:
            minLength === maxLength ? fixedLengthError : rangeValidationError,
        },
        too_small: {
          translationKey:
            minLength === maxLength ? fixedLengthError : rangeValidationError,
        },
      },
    };
  };

  return {
    schema,
    errorMessages,
    minLength: bookingValidation.minLength,
    maxLength: bookingValidation.maxLength,
  };
};
