import { RequestVirtualCardFormErrorMessages } from './linkCardToVendorFormSchema';

export const linkCardToVendorFormErrorMessages: RequestVirtualCardFormErrorMessages =
  {
    creditCard: {
      label: 'recurringPaymentsModal.linkCard.field.label',
    },
    vendorName: {
      label: 'recurringPaymentsModal.linkCard.vendorName.label',
    },
  };
