import { Flex, Grid } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { contactSupplierFormErrorMessages } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/contactSupplierFormErrorMessages';
import {
  contactSupplierFormSchema,
  ContactSupplierFormOutput,
} from 'views/Inbox/DocumentProcessing/components/ContactSupplier/contactSupplierFormSchema';
import { HookFormEmailEditorField } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/HookFormEmailEditorField';

interface ContactSupplierFormProps {
  defaultEmailText: string;
  defaultSubjectText: string;
  defaultEmailTo?: string;
  formId: string;
  onSubmit: (data: ContactSupplierFormOutput) => void;
}

export const ContactSupplierForm = ({
  defaultEmailText,
  defaultSubjectText,
  defaultEmailTo,
  formId,
  onSubmit,
}: ContactSupplierFormProps) => {
  const [t] = useTranslation();

  const userEmail = useCurrentUser()?.email;

  const emailFrom = 'no-reply@candis.io';

  const form = useForm<ContactSupplierFormOutput>({
    mode: 'onBlur',
    resolver: zodResolver({
      zodSchema: contactSupplierFormSchema,
      errorMessages: contactSupplierFormErrorMessages,
    }),
    defaultValues: {
      emailTo: defaultEmailTo ? defaultEmailTo : undefined,
      subject: defaultSubjectText,
      htmlText: defaultEmailText,
      emailFrom,
      emailCc: userEmail,
    },
  });

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        id={formId}
        onSubmit={form.handleSubmit(onSubmit)}
        direction="column"
        gap="space24"
        paddingBottom="space12"
        width="100%">
        <Grid templateColumns="1fr 2fr" gap="space8">
          <HookFormTextField
            name="emailFrom"
            label={t('emailEditor.email.from')}
            readOnly
            color="white"
          />
          <HookFormTextField
            name="emailCc"
            label={t('emailEditor.email.ccAndReplyTo')}
            readOnly
            color="white"
          />
        </Grid>
        <HookFormTextField
          label={t('emailEditor.email.to')}
          placeholder={t('contactSupplierFromCandis.emailToPlaceholder')}
          name="emailTo"
          color="white"
        />
        <HookFormTextField
          placeholder={t('contactSupplierFromCandis.subjectPlaceholder')}
          label={t('emailEditor.subject.label')}
          name="subject"
          color="white"
        />
        <HookFormEmailEditorField
          name="htmlText"
          label={t('emailEditor.body.label')}
        />
      </Flex>
    </FormProvider>
  );
};
