import { useToastMessage } from 'components/Toast/useToastMessage';
import { useIsCardManagerOnCreditCardsView } from 'containers/credit-cards/utils';
import {
  CardCategory,
  useIssueVirtualCardIssuerCardMutation,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { isNil } from 'lodash';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { getVendorSuggestionsForRecurringPaymentsQuery } from 'views/CreditCards/CreditCardsInsights/gql';
import { vendorSuggestionsQueryVariables } from 'views/CreditCards/hooks/useVendorSuggestionsData';
import { AccountingData } from '../types';
import { jumpToCreatedCreditCard } from '../utils/jumpToCreatedCreditCard';
import { sanitiseIssueVirtualCard } from '../utils/sanitiseIssueVirtualCard';
import { CreditCardData, Suggestions } from '../utils/types';

export interface UseIssueVirtualCreditCardOutput {
  issue: (values: IssueVirtualCardParams) => Promise<{
    status: string;
    errors?: undefined | readonly GraphQLError[];
  }>;
  issuing: boolean;
}

export interface IssueVirtualCardParams extends CreditCardData {
  selectedCategory?: CardCategory;
  currentUserId?: string;
  vendorId?: string;
  suggestions?: Suggestions;
  accountingData?: AccountingData;
  noInvoiceNeeded?: boolean;
}

export const useIssueVirtualCard = ({
  onCardCreatedSuccess,
}: {
  onCardCreatedSuccess: (cardId: string) => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();
  const isCardManagerOnCreditCardsView = useIsCardManagerOnCreditCardsView();
  const organizationId = useOrganizationId();
  const navigate = useNavigate();

  const [issueVirtualCardIssuerCard, { loading: issuing, client }] =
    useIssueVirtualCardIssuerCardMutation({
      onError: () => error(t('dashboard.genericErrorMessage')),
    });

  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  return {
    issue: async (values: IssueVirtualCardParams) => {
      const sanitisedValues = sanitiseIssueVirtualCard(values);
      const isRecurringPaymentsFlow = !isNil(
        values.suggestions?.suggestedAmount
      );

      const { data, errors } = await issueVirtualCardIssuerCard({
        variables: {
          input: sanitisedValues,
        },
        awaitRefetchQueries: true,
        refetchQueries: ({ data }) => {
          return [
            ...refetchQueries.cardIssuerCardsCardManager(),
            ...refetchQueries.cardIssuerCards(
              data?.issueVirtualCardIssuerCard.id
            ),
            ...(isRecurringPaymentsFlow
              ? [
                  {
                    query: getVendorSuggestionsForRecurringPaymentsQuery,
                    variables: vendorSuggestionsQueryVariables,
                  },
                ]
              : []),
          ];
        },
        onCompleted: data => {
          if (isNil(data)) {
            return;
          }

          if (values.vendorId) {
            // It is necessary to evict the cache since we do not know at exactly which point we are on the list and we do not know what to refetch
            client.cache.evict({ fieldName: 'getVendorsForRecurringPayments' });
          }

          const cardId = data.issueVirtualCardIssuerCard?.id;

          onCardCreatedSuccess(cardId);

          const { creditCardLink } = jumpToCreatedCreditCard({
            cardId,
            organizationId,
          });

          const canNavigateToIssuedCard =
            values.teamMember === values.currentUserId;

          evictPaginationResults();
          if (!isCardManagerOnCreditCardsView && canNavigateToIssuedCard) {
            navigate(creditCardLink);
          }
        },
      });

      if (errors?.length) {
        return { status: 'error', errors };
      }

      if (data) {
        return { status: 'success' };
      }

      return {
        status: 'error',
        errors,
      };
    },
    issuing,
  };
};
