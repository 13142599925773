import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface RemindCardholderEmailProps {
  emailLink: string;
  cardholderName?: string;
  refNum?: string;
  vendorName: string;
  availableBalance: string | null;
}

export const useRemindCardholderEmail = ({
  emailLink,
  refNum,
  cardholderName,
  availableBalance,
  vendorName,
}: RemindCardholderEmailProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const cardNumber = `•••• ${refNum}`;

  const mainBody = t('recurringPaymentList.reminderEmail.bodyText', {
    vendorName,
    cardholderName,
    refNum: cardNumber,
  });

  const cardDetails = t('recurringPaymentList.reminderEmail.vendorItem', {
    availableBalance,
    refNum: cardNumber,
  });

  const cardLink = t('recurringPaymentList.reminderEmail.cardLink', {
    cardUrl: `${window.location.hostname}${emailLink}`,
  });

  const emailSubject = t('recurringPaymentList.reminderEmail.subject');
  const emailBody = `${mainBody} \n\n\n ${cardDetails} \n\n ${cardLink} \n\n`;

  return { emailSubject, emailBody };
};
