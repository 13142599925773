import { VendorsForRecurringPaymentsSortField } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { Key } from 'react';
import { useRecurringPaymentData } from 'views/CreditCards/hooks/useRecurringPaymentData';
import { RecurringPaymentsList } from './RecurringPaymentsList';

interface RecurringPaymentsListContainerProps {
  onSetExpandibleCards: (
    toogle: 'vendorSuggestions' | 'recurringPayments'
  ) => void;
  showRecurringPayments: boolean;
  numOfVendorSuggestions: number;
}

export const RecurringPaymentsListContainer = ({
  onSetExpandibleCards,
  showRecurringPayments,
  numOfVendorSuggestions,
}: RecurringPaymentsListContainerProps) => {
  const [sortBy, setSortBy] =
    useLocalStorage<VendorsForRecurringPaymentsSortField>(
      'recurringPaymentListSortType',
      VendorsForRecurringPaymentsSortField.Name
    );

  const { recurringPaymentsDetails, onLoadMore, hasMore, loading } =
    useRecurringPaymentData({ sortBy });

  const handleSortClick = (value: Key[]) => {
    const selectedSort = value.length
      ? (value[0] as VendorsForRecurringPaymentsSortField)
      : sortBy;

    setSortBy(selectedSort);
  };

  return (
    <RecurringPaymentsList
      hasMore={hasMore}
      numOfVendorSuggestions={numOfVendorSuggestions}
      showRecurringPayments={showRecurringPayments}
      recurringPaymentsDetails={recurringPaymentsDetails}
      sortBy={sortBy}
      isLoading={loading}
      onSortClick={handleSortClick}
      onEndReached={onLoadMore}
      onSetExpandibleCards={onSetExpandibleCards}
    />
  );
};
