import { mergeProps } from '@candisio/design-system';
import {
  HookFormUsersField,
  HookFormUsersFieldProps,
} from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { Key, ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  MAX_NOTIFY_PEOPLE_LENGTH,
  MAX_RESPONSIBLE_PEOPLE_LENGTH,
  StorageFormValues,
} from './storageFormSchema';
import { useStorageFormField } from './useStorageFormField';

export interface StorageFormMultiplePeopleFieldItem {
  key: Key;
  children: ReactNode;
  avatarUrl?: string;
}

export const StorageFormNotifyPersonField = ({
  name,
  ...restProps
}: HookFormUsersFieldProps<StorageFormValues>) => {
  const [t] = useTranslation();
  const { fieldProps } = useStorageFormField(name);

  const notifyPeople = useWatch<StorageFormValues, 'notifyPerson'>({
    name: 'notifyPerson',
  });

  return (
    <HookFormUsersField
      name={name}
      label={t('ecm:storageForm.fields.notifyPerson.label', {
        currentCount: notifyPeople?.length ?? 0,
        maxCount: MAX_NOTIFY_PEOPLE_LENGTH,
      })}
      disabled={(notifyPeople ?? []).length >= MAX_NOTIFY_PEOPLE_LENGTH}
      {...mergeProps(fieldProps, restProps)}
    />
  );
};

export const StorageFormResponsiblePersonField = ({
  name,
  ...restProps
}: HookFormUsersFieldProps<StorageFormValues>) => {
  const [t] = useTranslation();
  const { fieldProps } = useStorageFormField(name);

  const responsiblePerson = useWatch<StorageFormValues, 'responsiblePerson'>({
    name: 'responsiblePerson',
  });

  return (
    <HookFormUsersField
      name={name}
      label={t('storageForm.fields.responsiblePerson.label')}
      disabled={
        (responsiblePerson ?? []).length >= MAX_RESPONSIBLE_PEOPLE_LENGTH
      }
      {...mergeProps(fieldProps, restProps)}
    />
  );
};
