import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetFileUploadUrlMutation } from 'generated-types/graphql.types';
import { useFileUpload } from '../utils/useFileUpload';

export interface ExpenseInvoice {
  id: string;
  name: string;
  url: string;
  size?: number | null;
}

export const useCreateExpenseInvoice = () => {
  const { error } = useToastMessage();
  const [getFileUploadUrl, { loading }] = useGetFileUploadUrlMutation();
  const { handleFileUpload } = useFileUpload();

  const handleCreateExpenseInvoice = async (file: File) => {
    const { errorMessage, fileData } = await handleFileUpload(file, {
      getFileUploadUrl,
    });

    if (errorMessage) {
      error(errorMessage);

      return;
    }

    if (fileData) {
      return {
        name: fileData.name,
        id: fileData.id,
        url: fileData.url,
      } satisfies ExpenseInvoice;
    }
  };

  return {
    isCreatingInvoice: loading,
    createExpenseInvoice: handleCreateExpenseInvoice,
  };
};
