import { useSetTeamIsArchivedMutation } from 'generated-types/graphql.types';
import { countTeamsQuery, teamQuery } from '../queries';

interface HandleSetTeamIsArchivedParams {
  id: string;
  isArchived: boolean;
}

export const useSetTeamIsArchived = () => {
  const [setTeamIsArchivedMutation, { loading, client }] =
    useSetTeamIsArchivedMutation();

  const setTeamIsArchived = async ({
    id,
    isArchived,
  }: HandleSetTeamIsArchivedParams) => {
    const response = await setTeamIsArchivedMutation({
      variables: { id, isArchived },
      awaitRefetchQueries: true,
      onCompleted: () => client.cache.evict({ fieldName: 'teams' }),
      refetchQueries: [
        { query: teamQuery, variables: { id } },
        {
          query: countTeamsQuery,
          variables: { filters: { isArchived: true } },
        },
        {
          query: countTeamsQuery,
          variables: { filters: { isArchived: false } },
        },
      ],
    });

    if ((response.errors ?? []).length > 0) {
      return { status: 'error' };
    }

    return { status: 'success' };
  };

  return { setTeamIsArchived, isHandleSetTeamIsArchivedPending: loading };
};
