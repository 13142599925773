import Paid from 'assets/timeline/done.svg';
import Unpaid from 'assets/timeline/ready.svg';
import { PaymentStatus } from '../types';

export const mapToPaymentStatusIcon = (status?: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.UNPAID:
      return Unpaid;
    default:
      return Paid;
  }
};
