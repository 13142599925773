import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import {
  CardIssuerTransactionSortField,
  SortDirection,
  useArchiveCardIssuerTransactionsQuery,
} from 'generated-types/graphql.types';
import { getCardIds } from './utils';

export interface CardId {
  linkedCardId?: string;
  replacedCardId?: string;
}

export const useLatestTransactionsByCardId = ({
  linkedCardId,
  replacedCardId,
}: CardId) => {
  const { data, loading } = useArchiveCardIssuerTransactionsQuery({
    variables: {
      input: {
        limit: 6,
      },
      filters: {
        cardIds: getCardIds({ linkedCardId, replacedCardId }),
      },
      sortBy: {
        direction: SortDirection.Desc,
        field: CardIssuerTransactionSortField.TransactionCreatedAt,
      },
    },
    // because of the `limit`, ensure that (filtered) results in Archive
    // transactions are NOT cached/affected by this query
    fetchPolicy: 'no-cache',
    skip: !linkedCardId,
  });

  const transactions = data?.archiveCardIssuerTransactions.edges ?? [];

  const linkedCardTransactions = transactions
    .filter(({ node }) => node.cardId === linkedCardId)
    .map(d => dataToTransactionDetails(d.node));

  const replacedCardTransactions = transactions
    .filter(({ node }) => node.cardId === replacedCardId)
    .map(d => dataToTransactionDetails(d.node));

  const hasTransactions = Boolean(transactions?.length);

  return {
    loading,
    hasTransactions,
    linkedCardTransactions,
    replacedCardTransactions,
  };
};
