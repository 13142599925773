import { mergeProps } from '@candisio/design-system';
import {
  HookFormSelectField,
  HookFormSelectFieldProps,
} from 'components/HookFormFields/HookFormSelectField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

export type ProcessingFormSelectFieldProps =
  HookFormSelectFieldProps<ProcessingFormValues> & {
    isLoading?: boolean | undefined;
  };

/** A `HookFormSelectField` with added field metadata */
export const ProcessingFormSelectField = ({
  name,
  readOnly,
  ...restProps
}: ProcessingFormSelectFieldProps) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormSelectField<ProcessingFormValues>
      name={name}
      isVirtualized
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
