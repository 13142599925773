import { CardLimitRenewFrequency } from 'generated-types/graphql.types';

export const textDescriptionLimitInterval = {
  [CardLimitRenewFrequency.Annual]:
    'dashboard.drawer.form.limitInterval.annual.text',
  [CardLimitRenewFrequency.Monthly]:
    'dashboard.drawer.form.limitInterval.monthly.text',
  [CardLimitRenewFrequency.Quarterly]:
    'dashboard.drawer.form.limitInterval.quarterly.text',
  [CardLimitRenewFrequency.Total]:
    'dashboard.drawer.form.limitInterval.total.text',
};

export const ERR_CODE_INACTIVE_USER =
  'CANNOT_ISSUE_CARD_FOR_INACTIVE_CARDHOLDER';
