import { Grid, Item, TruncatedText } from '@candisio/design-system';
import { ComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { SplitBookingsFormValues } from 'components/Form/SplitBookingsForm/schema';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { get } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { taxCodeExportValidationSchema } from 'views/utils/taxCodeExportValidationSchema';

export const TaxCode = ({
  readOnly,
  onChange,
  integration,
  documentDirection,
  ...props
}: {
  onChange: (val: Key | null) => void;
  integration?: string;
  documentDirection?: string;
} & ComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem> &
  Partial<HookFormComboBoxFieldProps<SplitBookingsFormValues>>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  const disabledKeys: string[] = [];

  const defaultItemsWithDescription = (props?.defaultItems || []).map(item => {
    const isTaxCodeDisabled =
      documentDirection &&
      item.code &&
      get(taxCodeExportValidationSchema, [item.code, documentDirection]) ===
        false;

    if (integration === 'DATEV' && isTaxCodeDisabled) {
      disabledKeys.push(item.key);

      return {
        ...item,
        description:
          documentDirection === DocumentDirection.invoices_received
            ? t(
                'common:document.requestApproval.inputs.bookingKey.items.description.invoices_received'
              )
            : t(
                'common:document.requestApproval.inputs.bookingKey.items.description.outgoing_invoices'
              ),
      };
    }

    return item;
  });

  return (
    <HookFormComboBoxField
      name="taxCode"
      isVirtualized={false}
      label={t('inputs.bookingKey.label')}
      {...props}
      defaultItems={defaultItemsWithDescription}
      disabledKeys={disabledKeys}
      placeholder={
        readOnly
          ? t('inputs.bookingKey.placeholder.notSet')
          : t('inputs.bookingKey.placeholder.enabled')
      }
      readOnly={readOnly}
      onChange={onChange}>
      {item => (
        <Item key={item.key} textValue={item.children as string}>
          <Grid>
            <TruncatedText>{item.children}</TruncatedText>
            {item.description && (
              <TruncatedText fontSize="small" fontStyle="italic">
                {item.description}
              </TruncatedText>
            )}
          </Grid>
        </Item>
      )}
    </HookFormComboBoxField>
  );
};
