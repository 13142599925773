import { defaultTheme } from '@candisio/design-system';
import { LottieAnimation } from 'components/Lottie/Lottie';
import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { EmptyDataWrapper } from './styles';

const EmptyDataText = styled.p`
  font-size: ${defaultTheme.fontSizes.large};
  color: ${defaultTheme.colors.gray800};
  margin: 0 auto;
  padding: 1rem 0;
  font-weight: 500;
  width: 12rem;
  min-width: 10rem;
  text-align: center;
  word-break: break-word;
`;

interface EmptyStateProps {
  emptyDataText: string;
  animationData: any;
  children: ReactNode;
}

export const EmptyState = ({
  children,
  emptyDataText,
  animationData,
}: EmptyStateProps) => {
  return (
    <EmptyDataWrapper>
      <LottieAnimation options={{ animationData }} />
      <EmptyDataText>{emptyDataText}</EmptyDataText>
      {children}
    </EmptyDataWrapper>
  );
};
