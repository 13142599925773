import { mergeProps } from '@candisio/design-system';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { useReadOnlyTransactionFormField } from '../hooks/useReadOnlyTransactionFormField';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';

export const ReadOnlyTransactionComboBoxField = ({
  name,
  ...restProps
}: HookFormComboBoxFieldProps<ReadOnlyTransactionFormValues>) => {
  const { fieldProps } = useReadOnlyTransactionFormField(name);

  return (
    <HookFormComboBoxField name={name} {...mergeProps(restProps, fieldProps)} />
  );
};
