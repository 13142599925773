import { Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { TransactionAmountChangedEventDataFragment } from '../../../../generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';
import { amountFormat } from '../../../../utils/format';

export const AmountChanged = ({
  properties,
}: TransactionAmountChangedEventDataFragment) => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  if (!properties || !properties.newAmount) {
    return null;
  }

  const {
    newAmount: { value, currency },
  } = properties;

  return (
    <Text>
      {tTransactions('documentHistoryCCEvents.transactionAmountChanged', {
        amount: amountFormat(value, currency),
      })}
    </Text>
  );
};
