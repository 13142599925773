import { Button, Flex, Grid, Popover, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface UnsetTeamCardsConfirmationPopoverProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const UnsetTeamCardsConfirmationPopover = forwardRef<
  HTMLDivElement,
  UnsetTeamCardsConfirmationPopoverProps
>(({ onClose, onConfirm, ...restProps }, ref) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const handleConfirmClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <Popover
      ref={ref}
      onClose={onClose}
      maxWidth="355px"
      padding="space16"
      {...restProps}>
      <Grid gap="space16">
        <Text fontSize="basic" fontWeight="semibold">
          {t('teams.editTeam.creditCardsSetup.unassignCard.title')}
        </Text>
        <Text fontSize="basic">
          {t('teams.editTeam.creditCardsSetup.unassignCard.description')}
        </Text>
        <Flex gap="space4" justifyContent="end">
          <Button size="small" variant="secondary" onClick={onClose}>
            {t('teams.editTeam.creditCardsSetup.unassignCard.cancelAction')}
          </Button>
          <Button size="small" color="red" onClick={handleConfirmClick}>
            {t('teams.editTeam.creditCardsSetup.unassignCard.confirmAction')}
          </Button>
        </Flex>
      </Grid>
    </Popover>
  );
});
