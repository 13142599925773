import { TruncatedText } from '@candisio/design-system';
import { Money } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { matchStates } from '../consts';
import { ThreeWayMatchTableRow } from './ThreeWayMatchTable';

const { success, warning } = matchStates;

type Value = Money | null | undefined;

export const ThreeWayMatchAmountCell = (
  props: CellProps<ThreeWayMatchTableRow, Value>
) => {
  const { documentMoneyFormatter } = useMoneyFormatter();
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const formattedAmount = props.value
    ? documentMoneyFormatter(props.value)
    : t('emptyCellPlaceholder');

  const isMatched = props.row.original.isAmountMatched;

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isMatched ? success.color : warning.color}>
      {formattedAmount}
    </TruncatedText>
  );
};
