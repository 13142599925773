import { Box, Card, Grid, ScrollBox, Separator } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { ReimbursementItem as ReimbursementItemPropsType } from 'views/Reimbursement/hooks/useGetReimbursementExpenseItems';
import { VIEW_URL_PARAM, VIEWS } from 'views/Reimbursement/Reimbursement';
import { AddReimbursementItemActionMenuButton } from './AddReimbursementItemActionMenuButton';
import { ReimbursementItem } from './ReimbursementItem';

interface ReimbursementItemsCardProps {
  creatingReimbursementItem: boolean;
  items: ReimbursementItemPropsType[];
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
}

export const ReimbursementItemsCard = ({
  items,
  creatingReimbursementItem,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: ReimbursementItemsCardProps) => {
  const { updateSearchParam } = useMutateSearchParams();

  const handleClick = (id: string) => () => {
    updateSearchParam(VIEW_URL_PARAM, VIEWS.Expenses);
    scrollToTarget(id);
  };

  return (
    <Grid overflowY="auto" borderRadius="medium">
      <ScrollBox scrollDirection="y" scrollbarGutter="stable" maxHeight="250px">
        <Box as="ul" listStyle="none" padding={0}>
          {items.map((item, index) => {
            const isLastItem = index === items.length - 1;

            return (
              <Box as="li" listStyle="none" key={item.id}>
                <ReimbursementItem
                  reimbursementItem={item}
                  onClick={handleClick(item.id)}
                />
                {!isLastItem && <Separator background="gray250" />}
              </Box>
            );
          })}
        </Box>
      </ScrollBox>
      <Card
        background="gray50"
        padding={0}
        borderTopRadius="none"
        style={{ marginRight: '8px' }}>
        <Separator color="gray250" />
        <AddReimbursementItemActionMenuButton
          creatingReimbursementItem={creatingReimbursementItem}
          onGeneralExpenseClick={onGeneralExpenseClick}
          onHospitalityExpenseClick={onHospitalityExpenseClick}
        />
      </Card>
    </Grid>
  );
};
