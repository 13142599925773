type GetAutoFocusFieldProps = {
  hasLinkedTransaction: boolean;
  hasLinkedPurchaseOrder: boolean;
  isUsingSAP: boolean;
};

export const getAutoFocusField = ({
  hasLinkedTransaction,
  hasLinkedPurchaseOrder,
  isUsingSAP,
}: GetAutoFocusFieldProps): 'contact' | 'invoiceDate' | 'documentType' => {
  // Non-SAP
  if (!isUsingSAP && !hasLinkedTransaction) return 'documentType';
  if (!isUsingSAP && hasLinkedTransaction) return 'contact';

  // SAP
  if (isUsingSAP && !hasLinkedPurchaseOrder) return 'contact';
  if (isUsingSAP && hasLinkedPurchaseOrder) return 'invoiceDate';

  return 'documentType';
};
