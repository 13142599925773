import {
  Grid,
  Text,
  Flex,
  Button,
  Heading,
  Box,
} from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { NoInvoiceNeededOnCardCheckBox } from 'containers/credit-cards/CreditCardSettings/CreditCardFormDataPreview/UpdateCreditCardDataFormDrawer/Forms/UpdateAccountingInfoContainer/NoInvoiceNeededOnCardCheckBox';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { KeyboardEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { useCreateCreditCardModalSetState } from '../CreateCreditCardModalDataProvider';
import { CreditCardsWorkflowAndApproversFields } from './FormFields/CreditCardsWorkflowAndApproversFields';
import {
  AccountingInfoFormSchemaFormValues,
  accountingInfoFormSchema,
} from './utils/accountingInfoFormSchema';
import { getAccountingInfoFormErrorMessages } from './utils/getAccountingInfoFormErrorMessages';
import { AccountingInfoFieldOptions } from './utils/types';

interface AccountingInfoFormProps {
  isActionLoading: boolean;
  defaultValues: AccountingInfoFormSchemaFormValues;
  fieldOptions: AccountingInfoFieldOptions;
  onSubmit: (values: AccountingInfoFormSchemaFormValues) => void;
}

export const AccountingInfoForm = ({
  defaultValues,
  isActionLoading,
  fieldOptions,
  onSubmit,
}: AccountingInfoFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const setModalState = useCreateCreditCardModalSetState();

  const [creditCardsInvoiceNotNeededOnCard] = useCandisFeatureFlags([
    FEATURE_FLAGS.creditCardsInvoiceNotNeededOnCard,
  ]);

  const {
    approvers,
    artistSocialInsuranceCode,
    contact,
    costCenter,
    costObject,
    extraCostInfo,
    generalLedgerAccount,
    taxCode,
    workflow,
  } = fieldOptions;

  const { isCardManager } = useUserRoles();

  const form = useForm<AccountingInfoFormSchemaFormValues>({
    defaultValues,
    values: defaultValues,
    resolver: zodResolver({
      zodSchema: accountingInfoFormSchema,
      errorMessages: getAccountingInfoFormErrorMessages(),
    }),
  });

  const showCheckBox = creditCardsInvoiceNotNeededOnCard && isCardManager;

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        overflow="hidden"
        padding="space24"
        paddingBottom="0px"
        onSubmit={form.handleSubmit(onSubmit)}
        onKeyDownCapture={(e: KeyboardEvent<HTMLFormElement>) => {
          if (
            e.key === 'Enter' &&
            e.target instanceof HTMLElement &&
            e.target.nodeName !== 'TEXTAREA' &&
            e.target.nodeName !== 'BUTTON'
          ) {
            e.preventDefault();
          }
        }}
        templateRows="1fr auto">
        <Grid
          gap="space32"
          paddingBottom="0px"
          borderRadius="medium"
          overflow="auto"
          height="100%"
          templateRows="auto 1fr">
          <Grid gap="space16" placeContent="start">
            <Grid gap="space8">
              <Heading as="h2">
                {t('recurringPaymentsModal.addAccountingData.heading')}
              </Heading>
              <Text fontSize="basic" color="gray600">
                {t('recurringPaymentsModal.addAccountingData.description')}
              </Text>
            </Grid>
            {showCheckBox && (
              <Box paddingLeft="space3">
                <NoInvoiceNeededOnCardCheckBox name="noInvoiceNeeded" />
              </Box>
            )}
          </Grid>
          <Grid
            gap="space32"
            height="fit-content"
            overflow="visible"
            paddingRight="space16"
            templateColumns="repeat(2, 1fr)">
            <Grid gap="space8" alignContent="start">
              <Heading as="h3">
                {t('recurringPaymentsModal.addAccountingData.accountingData')}
              </Heading>
              <HookFormPaginatedComboBoxField
                name="contact"
                placeholder={t(
                  'recurringPaymentsModal.addAccountingData.field.contact.placeholder'
                )}
                label={t(
                  'recurringPaymentsModal.addAccountingData.field.contact.label'
                )}
                emptyListPlaceholder={t(
                  'recurringPaymentsModal.addAccountingData.field.contact.emptyPlaceholder'
                )}
                {...contact.options}
              />

              {generalLedgerAccount.isVisible && (
                <HookFormPaginatedComboBoxField
                  name="generalLedgerAccount"
                  label={t(
                    'recurringPaymentsModal.addAccountingData.field.generalLedgerAccount.label'
                  )}
                  placeholder={t(
                    'recurringPaymentsModal.addAccountingData.field.generalLedgerAccount.placeholder'
                  )}
                  emptyListPlaceholder={t(
                    'recurringPaymentsModal.addAccountingData.field.generalLedgerAccount.emptyPlaceholder'
                  )}
                  {...generalLedgerAccount.options}
                />
              )}
              {taxCode.isVisible && (
                <HookFormComboBoxField
                  name="taxCode"
                  defaultItems={taxCode.options.defaultItems}
                  label={t(
                    'recurringPaymentsModal.addAccountingData.field.taxCode.label'
                  )}
                  placeholder={t(
                    'recurringPaymentsModal.addAccountingData.field.taxCode.placeholder'
                  )}
                  emptyListPlaceholder={t(
                    'recurringPaymentsModal.addAccountingData.field.taxCode.emptyPlaceholder'
                  )}
                />
              )}
              {costCenter.isVisible && (
                <HookFormPaginatedComboBoxField
                  name="costCenter"
                  placeholder={t(
                    'recurringPaymentsModal.addAccountingData.field.costCenter.placeholder'
                  )}
                  label={t(
                    'recurringPaymentsModal.addAccountingData.field.costCenter.label'
                  )}
                  emptyListPlaceholder={t(
                    'recurringPaymentsModal.addAccountingData.field.costCenter.emptyPlaceholder'
                  )}
                  {...costCenter.options}
                />
              )}
              {costObject.isVisible && (
                <HookFormPaginatedComboBoxField
                  name="costObject"
                  placeholder={t(
                    'recurringPaymentsModal.addAccountingData.field.costObject.placeholder'
                  )}
                  label={t(
                    'recurringPaymentsModal.addAccountingData.field.costObject.label'
                  )}
                  emptyListPlaceholder={t(
                    'recurringPaymentsModal.addAccountingData.field.costObject.emptyPlaceholder'
                  )}
                  {...costObject.options}
                />
              )}

              {artistSocialInsuranceCode.isVisible && (
                <HookFormComboBoxField
                  name="artistSocialInsuranceCode"
                  defaultItems={artistSocialInsuranceCode.options.defaultItems}
                  label={t(
                    'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.label'
                  )}
                  placeholder={t(
                    'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.placeholder'
                  )}
                  emptyListPlaceholder={t(
                    'recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.emptyPlaceholder'
                  )}
                />
              )}

              {extraCostInfo.isVisible && (
                <HookFormPaginatedComboBoxField
                  name="extraCostInfo"
                  label={t(
                    'recurringPaymentsModal.addAccountingData.field.extraCostInfo.label'
                  )}
                  placeholder={t(
                    'recurringPaymentsModal.addAccountingData.field.extraCostInfo.placeholder'
                  )}
                  emptyListPlaceholder={t(
                    'recurringPaymentsModal.addAccountingData.field.extraCostInfo.emptyPlaceholder'
                  )}
                  {...extraCostInfo.options}
                />
              )}
            </Grid>
            <Grid alignContent="start" gap="space8">
              <Heading as="h3">
                {t('recurringPaymentsModal.addAccountingData.approval')}
              </Heading>
              <CreditCardsWorkflowAndApproversFields
                approversFieldOptions={approvers}
                workflowFieldOptions={workflow}
              />
            </Grid>
          </Grid>
        </Grid>
        <Flex
          background="gray50"
          padding="space16"
          justifyContent="space-between"
          position="sticky"
          bottom={0}>
          <Button
            variant="secondary"
            disabled={isActionLoading}
            icon="arrowLeft"
            iconPosition="left"
            onClick={() =>
              setModalState(prevState => ({
                ...prevState,
                formStep: 'CardUsage',
                cardDetails: {
                  ...prevState.cardDetails,
                  ...form.getValues(),
                },
              }))
            }>
            {t('ccRequestForm.goBackCTA')}
          </Button>
          <Button type="submit" loading={isActionLoading}>
            {isCardManager
              ? t('ccRequestForm.issueNewCardCTA')
              : t('ccRequestForm.requestNewCardCTA')}
          </Button>
        </Flex>
      </Grid>
    </FormProvider>
  );
};
