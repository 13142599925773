import {
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Image,
  Paragraph,
  Tag,
  Text,
  useModal,
} from '@candisio/design-system';
import PlayIcon from 'assets/bds/play.svg';
import { INFO_PANEL_WIDTH } from 'components/InfoPanel/InfoPanel';
import { motion } from 'framer-motion';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useTranslation } from 'react-i18next';
import { BDSPromotionModal } from 'views/Integrations/Export/toolkit/components/BDSPromotion';
import { ModalSource } from 'views/Integrations/Export/types';
import BDSPromotionBackgroundDE from './BDSPromotionThumbnails/BDSPromotionThumbnail-DE.svg';
import BDSPromotionBackgroundEN from './BDSPromotionThumbnails/BDSPromotionThumbnail-EN.svg';

const modalSource = ModalSource.SETTINGS_CONNECTED_TO_DATEV;

type BdsInfoPanelProps = {
  closePromotion: () => void;
};
export const BdsInfoPanel = ({ closePromotion }: BdsInfoPanelProps) => {
  const [t] = useTranslation();
  const currentUser = useCurrentUser();
  const background = {
    [Locale.De]: BDSPromotionBackgroundDE,
    [Locale.En]: BDSPromotionBackgroundEN,
  };

  const MotionImage = motion(Image);

  const { triggerProps, modalProps, isOpen } = useModal();

  return (
    <Card
      alignSelf="start"
      background="purple100"
      width={INFO_PANEL_WIDTH}
      padding="0px"
      borderRadius="medium"
      overflow="hidden">
      <Grid
        height="170px"
        overflow="hidden"
        alignContent="center"
        justifyContent="center">
        <Grid
          zIndex={1}
          width="100%"
          position="absolute"
          paddingX="space16"
          paddingY="space12"
          autoFlow="column"
          justifyContent="space-between">
          <Tag color="purple" variant="primary">
            {t('tag.addOn')}
          </Tag>
          <Box cursor="pointer" onClick={closePromotion}>
            <Icon icon="close" size="space16" color="white" />
          </Box>
        </Grid>
        <Image
          src={background[currentUser?.locale || Locale.De]}
          style={{ position: 'absolute' }}
          width="100%"
          alt=""
        />
        <Grid alignContent="center" justifyContent="center">
          <Box data-key="promotion-video-play-button" as="a" {...triggerProps}>
            <MotionImage
              whileHover={{
                filter: 'brightness(75%)',
              }}
              width="35.23px"
              src={PlayIcon}
              alt="play-icon"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid gap="space10" paddingY="space20" paddingX="space16">
        <Text fontSize="large" color="gray800">
          {t('settings.datev.connect.connectedClient.promoCard.title')}
        </Text>
        <Paragraph fontSize="small" color="gray700" lineHeight="space20">
          {t('settings.datev.connect.connectedClient.promoCard.description')}
        </Paragraph>
        <Button color="purple" {...triggerProps} width="space128">
          {t('settings.datev.connect.connectedClient.promoCard.button')}
        </Button>
      </Grid>
      {isOpen ? (
        <BDSPromotionModal modalSource={modalSource} {...modalProps} />
      ) : null}
    </Card>
  );
};
