import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useForm } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation';
import { DocumentTags } from '../../../toolkit/hooks/useDocumentTags';
import { useMergeDocumentTags } from '../../../toolkit/hooks/useMergeDocumentTags';
import {
  MergeTagsFormOutput,
  MergeTagsFormSchemaOptions,
  mergeTagsFormSchema,
} from './mergeTagsFormSchema';

const errorMessages = {
  customName: { label: 'documentTags.mergeModal.customNamePlaceholder' },
  targetTagId: { label: '' },
};

type useMergeTagsFormArgs = {
  tags: DocumentTags;
  onMergeSuccess: () => void;
};

export const useMergeTagsForm = ({
  tags,
  onMergeSuccess,
}: useMergeTagsFormArgs) => {
  const context: MergeTagsFormSchemaOptions = { tags } as const;

  const form = useForm<MergeTagsFormOutput, MergeTagsFormSchemaOptions>({
    mode: 'onTouched',
    context,
    resolver: zodResolver({
      zodSchema: mergeTagsFormSchema,
      errorMessages,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
    defaultValues: {
      targetTagId: tags[0].id,
      customName: null,
    },
  });

  const { handleMergeTags, isMergeActionPending } = useMergeDocumentTags(
    tags,
    onMergeSuccess
  );

  return {
    form,
    mergeTags: form.handleSubmit(handleMergeTags),
    isMergeActionPending,
  };
};
