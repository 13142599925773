import {
  Grid,
  Flex,
  Icon,
  Text,
  Button,
  IconProps,
  useModal,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { getThreeWayMatchGeneralState } from 'utils/three-way-match';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import ThreeWayMatchModal from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchModal';

export default function ThreeWayMatchIndicator() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { open, ...modalProps } = useModal();
  const threeWayMatchResult = useThreeWayMatchContext();
  const variant = getThreeWayMatchGeneralState(threeWayMatchResult);

  const iconProps: {
    [variant: string]: IconProps;
  } = {
    success: { icon: 'check', color: 'green700' },
    failure: { icon: 'warning', color: 'yellow700' },
    default: {
      icon: 'failCircle',
      color: 'gray450',
    },
  };

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      padding="space4"
      paddingTop="unset">
      <Flex direction="row" padding="space3">
        <Grid paddingTop="space4" paddingRight="space3">
          <Icon size="space16" {...iconProps[variant]} />
        </Grid>
        <Grid flex="1" padding="space3">
          <Text fontWeight="regular">
            {t('threeWayMatch.indicator.header')}
          </Text>
          {variant === 'default' && (
            <Text color="gray600">
              {t('threeWayMatch.indicator.description')}
            </Text>
          )}
        </Grid>
      </Flex>
      {variant !== 'default' && (
        <Grid>
          <Button
            onClick={open}
            icon="fullScreen"
            iconSize="space16"
            variant="tertiary"
          />
          <ThreeWayMatchModal {...modalProps} />
        </Grid>
      )}
    </Flex>
  );
}
