import { Image } from '@candisio/design-system';
import { PaginationWithSearchFilterHook } from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import { TransactionCategory } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import computing from '../CreditCardsInsights/SpentOverview/media/computer.svg';
import entertainment from '../CreditCardsInsights/SpentOverview/media/entertain.svg';
import food from '../CreditCardsInsights/SpentOverview/media/food.svg';
import gift from '../CreditCardsInsights/SpentOverview/media/gift.svg';
import advertising from '../CreditCardsInsights/SpentOverview/media/marketing.svg';
import office from '../CreditCardsInsights/SpentOverview/media/office.svg';
import packaging from '../CreditCardsInsights/SpentOverview/media/packaging.svg';
import electronic from '../CreditCardsInsights/SpentOverview/media/software.svg';
import other from '../CreditCardsInsights/SpentOverview/media/sonstiges.svg';
import education from '../CreditCardsInsights/SpentOverview/media/training.svg';
import travel from '../CreditCardsInsights/SpentOverview/media/travel.svg';
import { CCFilterOption } from '../CreditCardsInsights/utils/paginationFilterHooks';

export const useCategoryFilter: PaginationWithSearchFilterHook = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const filterOptions: CCFilterOption[] = [
    {
      id: TransactionCategory.AdvertisingAndMarketing,
      label: t('insights.filters.category.list.AdvertisingAndMarketing'),
      icon: (
        <Image
          src={advertising}
          alt={t('insights.filters.category.list.AdvertisingAndMarketing')}
        />
      ),
    },
    {
      id: TransactionCategory.ComputingAndSoftware,
      label: t('insights.filters.category.list.ComputingAndSoftware'),
      icon: (
        <Image
          src={electronic}
          alt={t('insights.filters.category.list.ComputingAndSoftware')}
        />
      ),
    },
    {
      id: TransactionCategory.EducationAndTraining,
      label: t('insights.filters.category.list.EducationAndTraining'),
      icon: (
        <Image
          src={education}
          alt={t('insights.filters.category.list.EducationAndTraining')}
        />
      ),
    },
    {
      id: TransactionCategory.ElectronicsAndItEquipment,
      label: t('insights.filters.category.list.ElectronicsAndItEquipment'),
      icon: (
        <Image
          src={computing}
          alt={t('insights.filters.category.list.ElectronicsAndItEquipment')}
        />
      ),
    },
    {
      id: TransactionCategory.EntertainmentAndWellness,
      label: t('insights.filters.category.list.EntertainmentAndWellness'),
      icon: (
        <Image
          src={entertainment}
          alt={t('insights.filters.category.list.EntertainmentAndWellness')}
        />
      ),
    },
    {
      id: TransactionCategory.FoodAndDrinks,
      label: t('insights.filters.category.list.FoodAndDrinks'),
      icon: (
        <Image
          src={food}
          alt={t('insights.filters.category.list.FoodAndDrinks')}
        />
      ),
    },
    {
      id: TransactionCategory.GiftsAndVouchers,
      label: t('insights.filters.category.list.GiftsAndVouchers'),
      icon: (
        <Image
          src={gift}
          alt={t('insights.filters.category.list.GiftsAndVouchers')}
        />
      ),
    },
    {
      id: TransactionCategory.MaterialsAndPackaging,
      label: t('insights.filters.category.list.MaterialsAndPackaging'),
      icon: (
        <Image
          src={packaging}
          alt={t('insights.filters.category.list.AdvertisingAndMarketing')}
        />
      ),
    },
    {
      id: TransactionCategory.OfficeSuppliesAndEquipment,
      label: t('insights.filters.category.list.OfficeSuppliesAndEquipment'),
      icon: (
        <Image
          src={office}
          alt={t('insights.filters.category.list.OfficeSuppliesAndEquipment')}
        />
      ),
    },
    {
      id: TransactionCategory.Other,
      label: t('insights.filters.category.list.Other'),
      icon: (
        <Image src={other} alt={t('insights.filters.category.list.Other')} />
      ),
    },
    {
      id: TransactionCategory.Services,
      label: t('insights.filters.category.list.Services'),
      icon: (
        <Image
          src={packaging}
          alt={t('insights.filters.category.list.Services')}
        />
      ),
    },
    {
      id: TransactionCategory.TravelAndAccommodation,
      label: t('insights.filters.category.list.TravelAndAccommodation'),
      icon: (
        <Image
          src={travel}
          alt={t('insights.filters.category.list.TravelAndAccommodation')}
        />
      ),
    },
  ];

  return { filterOptions };
};
