import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardStatus } from 'generated-types/graphql.types';
import DateFormatters from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { CardRequest } from './CardRequest';

interface CardRequestContainerProps {
  cardId: string;
  onSetMode: (mode: DrawerMode) => void;
}

const toReadbleMonetaryUnit = (
  val: number | undefined,
  currency: string = 'EUR'
) =>
  amountFormat(val, currency, {
    minimumFractionDigits: 0,
    convertToBasicMonetaryUnit: true,
  });

export const CardRequestContainer = ({
  cardId,
  onSetMode,
}: CardRequestContainerProps) => {
  const { card } = useGetCardById({ cardId });
  if (!card) {
    return null;
  }

  const { pendingRequest } = card;

  const isNewCardRequest =
    pendingRequest && pendingRequest.__typename !== 'CardLimitChangeRequest';

  if (card.status !== CardStatus.Requested || !isNewCardRequest) {
    return null;
  }

  // Based on the type of request we will have only purpose or comment

  const purpose =
    'purpose' in pendingRequest ? pendingRequest.purpose : undefined;

  const comment =
    'comment' in pendingRequest ? pendingRequest.comment : undefined;

  const requestedLimit =
    'requestedLimit' in pendingRequest
      ? pendingRequest.requestedLimit
      : undefined;

  const requestedLimitRenewFrequency =
    'requestedLimitRenewFrequency' in pendingRequest
      ? pendingRequest.requestedLimitRenewFrequency
      : undefined;

  const cardRequestDetails = {
    comment,
    purpose,
    requestedAt: DateFormatters.compactDatetime(
      new Date(pendingRequest.createdAt)
    ),
  };

  const { createdAt, requestedTransactionLimit } = pendingRequest;

  const requestedLimitsDetails = {
    limit: requestedLimit,
    transactionLimit: requestedTransactionLimit ?? undefined,
    date: createdAt,
  };

  const requestedLimitsToShow = {
    limit: toReadbleMonetaryUnit(
      requestedLimitsDetails.limit?.value,
      requestedLimitsDetails.limit?.currency
    ),
    transactionLimit: toReadbleMonetaryUnit(
      requestedLimitsDetails.transactionLimit.value,
      requestedLimitsDetails.transactionLimit.currency
    ),
    interval: requestedLimitRenewFrequency ?? undefined,
    cardType: card.type,
  };

  return (
    <CardRequest
      {...cardRequestDetails}
      {...requestedLimitsToShow}
      onSetMode={onSetMode}
    />
  );
};
