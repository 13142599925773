import {
  Maybe,
  useGetOrganizationsInsightsDetailsQuery,
} from 'generated-types/graphql.types';

export interface SortedOrganization {
  id: string;
  overdueCount: number;
  taskCount: number;
  title: string;
  slug?: Maybe<string>;
  toApproveTotal?: Maybe<number>;
  toApproveOverdue?: Maybe<number>;
  toExportTotal?: Maybe<number>;
  toExportOverdue?: Maybe<number>;
  toProcessTotal?: Maybe<number>;
  toProcessOverdue?: Maybe<number>;
}

export interface OrganizationSummaries {
  organizations?: SortedOrganization[];
  loading: boolean;
}

export const useOrganizationSummary = (): OrganizationSummaries => {
  const { data, loading } = useGetOrganizationsInsightsDetailsQuery();

  const organizations = data?.getOrganizationsInsightsDetails
    ? data?.getOrganizationsInsightsDetails.map(detail => ({
        id: detail.organization.id,
        overdueCount: detail.summary.overdue,
        taskCount: detail.summary.total,
        title: detail.organization.name,
        toApproveTotal: detail.toApprove?.total,
        toApproveOverdue: detail.toApprove?.overdue,
        toExportTotal: detail.toExport?.total,
        toExportOverdue: detail.toExport?.overdue,
        toProcessTotal: detail.toProcess?.total,
        toProcessOverdue: detail.toProcess?.overdue,
        slug: detail.organization.slug,
      }))
    : undefined;

  return { organizations, loading };
};
