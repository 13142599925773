import { Grid, Image, Text, Flex } from '@candisio/design-system';
import DominikWestphal from 'assets/bds/dominik-westphal.png';
import IngoBrockmann from 'assets/bds/ingo_brockmann.png';
import { motion } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TESTIMONIALS_DELAY = 10000;
export const Testimonials = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const [testimonialIndex, setTestimonialIndex] = useState<number>(0);
  const MotionGrid = motion(Grid);
  const timerRef = useRef<ReturnType<typeof setInterval> | null>();

  const testimonials = [
    {
      image: DominikWestphal,
      employeeName: t(
        'bdsPromotionModal.rightSide.testimonials.dominik.employeeName'
      ),
      employeeTitle: t(
        'bdsPromotionModal.rightSide.testimonials.dominik.employeeTitle'
      ),
      quote: t('bdsPromotionModal.rightSide.testimonials.dominik.quote'),
    },
    {
      image: IngoBrockmann,
      employeeName: t(
        'bdsPromotionModal.rightSide.testimonials.ingo.employeeName'
      ),
      employeeTitle: t(
        'bdsPromotionModal.rightSide.testimonials.ingo.employeeTitle'
      ),
      quote: t('bdsPromotionModal.rightSide.testimonials.ingo.quote'),
    },
  ];

  const isSingleTestimonial = testimonials.length === 1;

  const stopAnimation = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const animate = useCallback(() => {
    if (isSingleTestimonial) return;
    timerRef.current = setInterval(function () {
      setTestimonialIndex(prevIndex => {
        let newIndex = prevIndex + 1;

        if (newIndex >= testimonials.length) {
          newIndex = 0;
        }

        return newIndex;
      });
    }, TESTIMONIALS_DELAY);
  }, [isSingleTestimonial, testimonials.length]);

  useEffect(() => {
    animate();

    return () => {
      stopAnimation();
    };
  }, [animate]);

  return (
    <MotionGrid
      animate={{
        opacity: 1,
      }}
      initial={{ opacity: isSingleTestimonial ? 1 : 0 }}
      transition={{ duration: 0.3 }}
      padding="space16"
      templateColumns="auto 1fr"
      gap="space12"
      onHoverStart={() => stopAnimation()}
      onHoverEnd={() => animate()}>
      <Image
        width={56}
        height={56}
        src={testimonials[testimonialIndex].image}
        alt="testimonial"
      />
      <Grid rowGap="space8">
        <Text fontSize="basic">{testimonials[testimonialIndex].quote}</Text>
        <Flex gap="space4">
          <Text color="gray600" fontWeight="semibold">
            {testimonials[testimonialIndex].employeeName}
          </Text>
          <Text color="gray600">
            ({testimonials[testimonialIndex].employeeTitle})
          </Text>
        </Flex>
      </Grid>
    </MotionGrid>
  );
};
