import { SplitBookingsFormValues } from 'components/Form/SplitBookingsForm/schema';
import {
  HookFormDatePickerField,
  HookFormDatePickerFieldProps,
} from 'components/HookFormFields/HookFormDatePickerField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const DueDate = ({
  readOnly,
  ...props
}: Partial<HookFormDatePickerFieldProps<SplitBookingsFormValues>>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  return (
    <HookFormDatePickerField
      name="dueDate"
      label={t('inputs.dueDate.label')}
      readOnly={readOnly}
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      placeholder={
        readOnly
          ? t('inputs.dueDate.placeholder.notSet')
          : t('inputs.dueDate.placeholder.enabled')
      }
      {...props}
    />
  );
};
