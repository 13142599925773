import {
  CreateCreditCardGeneralLedgerAccountFieldItemsQuery,
  CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { createCreditCardGeneralLedgerAccountFieldItemsQuery } from '../CreditCardsInsights/gql';

export const useAccountPayableFieldOptions = () => {
  const { friendlyFormatGLA, normalizeGLA } = useAccountingNumberFormatters();
  const computeVariables = useCallback(
    (
      inputString?: string
    ): CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables => {
      // Workaround because the query returns wrong value if we filter directly by readable name
      // TG-2082: https://candis.atlassian.net/browse/TG-2082
      const [code, name] = normalizeGLA(inputString ?? '');

      return {
        input: {
          limit: 20,
        },
        readableName: code || name,
      };
    },
    [normalizeGLA]
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<
      CreateCreditCardGeneralLedgerAccountFieldItemsQuery,
      CreateCreditCardGeneralLedgerAccountFieldItemsQueryVariables
    >({
      query: createCreditCardGeneralLedgerAccountFieldItemsQuery,
      queryRootKey: 'creditCardGeneralLedgerAccountsPagination',
      computeVariables,
    });

  const records =
    data?.creditCardGeneralLedgerAccountsPagination?.records ?? [];

  const items = records.map(generalLedgerAccount => {
    const [code, name] = normalizeGLA(generalLedgerAccount.readableName);
    const formattedGLA = `${friendlyFormatGLA(code)} - ${name}`;

    return {
      key: generalLedgerAccount.id,
      textValue: generalLedgerAccount.readableName,
      children: formattedGLA,
    };
  });

  return {
    loading,
    items,
    onEndReached: loadMore,
    onSearch: handleDebounceSearch,
  };
};
