import {
  EmptyDataS,
  EmptyDataText,
  EmptyDataWrapper,
} from 'components/EmptyViews/styles';
import { LottieAnimation, nocontactData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { TAX_CODES_ROUTE_HASH, TAX_CODES_SEARCH_PARAM } from '../../consts';

type Props = {
  onCreate: () => void;
  hasArchivedTaxCodes?: boolean;
};

export const NoActiveData = ({ onCreate, hasArchivedTaxCodes }: Props) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const { push } = useHistory();

  let ctaElement;
  if (hasArchivedTaxCodes) {
    ctaElement = (
      <>
        <EmptyDataText>{t('table.nodata.noActiveRecords')}</EmptyDataText>
        <EmptyDataText>
          <Trans t={t} i18nKey="table.nodata.pleaseCreateOrReactivateCTA">
            Please
            <EmptyDataS onClick={onCreate}>create</EmptyDataS>
            or
            <EmptyDataS
              onClick={e => {
                e.preventDefault();
                push({
                  search: qs.stringify({
                    [TAX_CODES_SEARCH_PARAM.archive]: true,
                  }),
                });
              }}>
              reactivate
            </EmptyDataS>
          </Trans>
        </EmptyDataText>
      </>
    );
  } else {
    ctaElement = (
      <EmptyDataText>
        <Trans t={t} i18nKey="table.nodata.createDefaultBookingKeys">
          No tax codes have been created yet.
          <EmptyDataS
            onClick={e => {
              e.preventDefault();
              push({
                hash: TAX_CODES_ROUTE_HASH.defaultImport,
              });
            }}>
            Create the default keys
          </EmptyDataS>
          or
          <EmptyDataS onClick={onCreate}>
            create your own custom keys.
          </EmptyDataS>
        </Trans>
      </EmptyDataText>
    );
  }

  return (
    <EmptyDataWrapper>
      <LottieAnimation options={{ animationData: nocontactData }} />
      {ctaElement}
    </EmptyDataWrapper>
  );
};
