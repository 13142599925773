import { Flex, Heading, Link, Modal } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface OnboardingModalProps {
  onCancel: () => void;
  visible?: boolean;
}

const documentSections = [
  {
    title: 'legalDocumentsModal.termsAndConditions.title',
    links: [
      {
        title: 'legalDocumentsModal.termsAndConditions.candis.title',
        url: 'legalDocumentsModal.termsAndConditions.candis.url',
      },
      {
        title: 'legalDocumentsModal.termsAndConditions.platform.title',
        url: 'legalDocumentsModal.termsAndConditions.platform.url',
      },
      {
        title: 'legalDocumentsModal.termsAndConditions.issuer.title',
        url: 'legalDocumentsModal.termsAndConditions.issuer.url',
      },
      {
        title: 'legalDocumentsModal.termsAndConditions.partnerBank.title',
        url: 'legalDocumentsModal.termsAndConditions.partnerBank.url',
      },
    ],
  },
  {
    title: 'legalDocumentsModal.datPrivacyPolicies.title',
    links: [
      {
        title: 'legalDocumentsModal.datPrivacyPolicies.candis.title',
        url: 'legalDocumentsModal.datPrivacyPolicies.candis.url',
      },
      {
        title: 'legalDocumentsModal.datPrivacyPolicies.issuer.title',
        url: 'legalDocumentsModal.datPrivacyPolicies.issuer.url',
      },
      {
        title: 'legalDocumentsModal.datPrivacyPolicies.partnerBank.title',
        url: 'legalDocumentsModal.datPrivacyPolicies.partnerBank.url',
      },
      {
        title: 'legalDocumentsModal.datPrivacyPolicies.pliantWeb.title',
        url: 'legalDocumentsModal.datPrivacyPolicies.pliantWeb.url',
      },
      {
        title: 'legalDocumentsModal.datPrivacyPolicies.pliantMobile.title',
        url: 'legalDocumentsModal.datPrivacyPolicies.pliantMobile.url',
      },
    ],
  },
  {
    title: 'legalDocumentsModal.other.title',
    links: [
      {
        title: 'legalDocumentsModal.other.creditCardsUsageAgreement.title',
        url: 'legalDocumentsModal.other.creditCardsUsageAgreement.url',
      },
    ],
  },
];

export const LegalDocumentsModal = ({
  onCancel,
  visible = false,
}: OnboardingModalProps) => {
  const [tCommon] = useTranslation();

  return (
    <Modal
      onClose={onCancel}
      isOpen={visible}
      closeLabel={tCommon('common.close')}
      title={tCommon('legalDocumentsModal.title')}
      width="710px"
      height="auto">
      <Flex direction="column" gap="space16">
        {documentSections.map(section => {
          return (
            <Flex direction="column" key={section.title} gap="space16">
              <Heading as="h3">{tCommon(section.title)}</Heading>
              {section.links.map(link => {
                return (
                  <Link external href={tCommon(link.url)} key={link.url}>
                    {tCommon(link.title)}
                  </Link>
                );
              })}
            </Flex>
          );
        })}
      </Flex>
    </Modal>
  );
};
