import {
  InsightBudgetType,
  InsightsWidgetBudgetsInput,
} from 'generated-types/graphql.types';
import { WidgetFormData } from '../../types';
import {
  BudgetYear,
  QUARTER,
  QUARTERS_IN_A_MONTH,
  WidgetFormAnnualBudget,
  WidgetFormQuarterBudget,
  YearDividedByQuarter,
} from '../types';

//Takes yearly Budgets from the form and translate it into quarterly budgets
export const getQuarterBudgetsFromYear = (
  formData: WidgetFormData,
  type: InsightBudgetType
) => {
  const annualBudgets: WidgetFormAnnualBudget = {
    year2022: formData.year2022,
    year2023: formData.year2023,
    year2024: formData.year2024,
  };

  const yearsToInclude = Object.values(BudgetYear);

  return yearsToInclude.map(
    translateYearBudgetsInQuarterBudgets(annualBudgets, type)
  );
};

const splitNumber = (value: number, splitCount: number) => {
  const splitFlatValue = !value ? undefined : Math.trunc(value / splitCount);
  const splitRemainderValue = !value ? undefined : value % splitCount;

  return {
    splitFlatValue,
    splitRemainderValue,
  };
};

// https://www.delftstack.com/howto/javascript/javascript-round-to-2-decimal-places/#use-the-math-round-function-to-round-a-number-to2-decimal-places-in-javascript
export const roundToTwoDigits = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const translateYearBudgetsInQuarterBudgets =
  (annualBudgets: WidgetFormAnnualBudget, type: InsightBudgetType) =>
  (year: string) => {
    const index = `year${year}`;
    const budget = annualBudgets[index as keyof WidgetFormAnnualBudget];

    const quarterBudget = budget
      ? Math.trunc(budget / QUARTERS_IN_A_MONTH)
      : undefined;

    const quarterBudgetReminder = budget
      ? budget % QUARTERS_IN_A_MONTH
      : undefined;

    return {
      year: Number(year),
      quarters: {
        q1: quarterBudget,
        q2: quarterBudget,
        q3: quarterBudget,
        q4: quarterBudget
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            roundToTwoDigits(quarterBudget + quarterBudgetReminder!)
          : undefined,
      },
      type,
    };
  };

//Takes the quarterly budgets from the form and maps it into quarters
export const assignBudgetsByQuarter = (
  formData: WidgetFormData,
  type: InsightBudgetType
) => {
  const quarterBudgets: WidgetFormQuarterBudget = {
    q1_2022: formData.q1_2022,
    q2_2022: formData.q2_2022,
    q3_2022: formData.q3_2022,
    q4_2022: formData.q4_2022,
    q1_2023: formData.q1_2023,
    q2_2023: formData.q2_2023,
    q3_2023: formData.q3_2023,
    q4_2023: formData.q4_2023,
    q1_2024: formData.q1_2024,
    q2_2024: formData.q2_2024,
    q3_2024: formData.q3_2024,
    q4_2024: formData.q4_2024,
  };

  const yearsToInclude = Object.values(BudgetYear);

  return yearsToInclude.map(mapQuarterBudgegtsFields(quarterBudgets, type));
};

export const mapQuarterBudgegtsFields =
  (quarterBudgets: WidgetFormQuarterBudget, type: InsightBudgetType) =>
  (year: string) => {
    const indexQ1 = `q1_${year}`;
    const indexQ2 = `q2_${year}`;
    const indexQ3 = `q3_${year}`;
    const indexQ4 = `q4_${year}`;

    return {
      year: Number(year),
      quarters: {
        q1: quarterBudgets[indexQ1 as keyof WidgetFormQuarterBudget],
        q2: quarterBudgets[indexQ2 as keyof WidgetFormQuarterBudget],
        q3: quarterBudgets[indexQ3 as keyof WidgetFormQuarterBudget],
        q4: quarterBudgets[indexQ4 as keyof WidgetFormQuarterBudget],
      },
      type,
    };
  };

export const prepareBudgetFormForMutation: (
  yearDividedByQuarter: YearDividedByQuarter
) => InsightsWidgetBudgetsInput = (
  yearDividedByQuarter: YearDividedByQuarter
) => {
  const manifest = [
    { key: QUARTER.Q1, months: ['january', 'february', 'march'] },
    { key: QUARTER.Q2, months: ['april', 'may', 'june'] },
    { key: QUARTER.Q3, months: ['july', 'august', 'september'] },
    { key: QUARTER.Q4, months: ['october', 'november', 'december'] },
  ];

  const monthlyBudgets = manifest.reduce((acc, { key, months }) => {
    const currentBudget = yearDividedByQuarter.quarters[key];

    if (!currentBudget) {
      return acc;
    }

    const { splitRemainderValue, splitFlatValue } = splitNumber(
      currentBudget,
      months.length
    );

    const remainderMonth = months.pop();
    const [first, second] = months;

    return {
      ...acc,
      [first]: splitFlatValue,
      [second]: splitFlatValue,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [remainderMonth!]: splitFlatValue
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          roundToTwoDigits(splitFlatValue + splitRemainderValue!)
        : undefined,
    };
  }, {});

  return {
    year: yearDividedByQuarter.year,
    monthlyBudgets,
    type: yearDividedByQuarter.type,
  };
};
