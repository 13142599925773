export enum CONTACT_SEARCH_PARAM {
  iban = 'iban',
  name = 'name',
  sort = 'sort',
  taxNumber = 'taxNumber',
  vatId = 'vatId',
  accountsPayableNumber = 'accountsPayableNumber',
  accountsReceivableNumber = 'accountsReceivableNumber',
  relationshipType = 'relationshipType',
  noAccountsPayableNumber = 'noAccountsPayableNumber',
  isArchived = 'isArchived',
}

export enum CONTACT_ROUTE_HASH {
  import = '#import',
}
