import gql from 'graphql-tag';

export const insightsPreferencesQuery = gql`
  query insightsPreferences {
    insightsPreferences {
      unit
    }
  }
`;

export const updateInsightsPreferencesMutation = gql`
  mutation updateInsightsPreferences($input: InsightsPreferencesInput!) {
    updateInsightsPreferences(input: $input) {
      unit
    }
  }
`;
