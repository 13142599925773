import { Maybe } from '@graphql-tools/utils';
import { User as GeneratedUser } from 'generated-types/graphql.types';
import moment from 'moment';
import { Nullable } from 'utils/extractors';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { z } from 'zod';
import { CheckCircularSubstitutionDependency } from './checkCircularSubstitutionDependency';

export const ABSENCE_NOTE_MAX_LENGTH = 50;

export interface AbsenceFormSchemaOptions {
  checkCircularSubstitutionDependency: CheckCircularSubstitutionDependency;
  selectedUser: Maybe<User | GeneratedUser>;
}

export const absenceFormSchema = ({
  checkCircularSubstitutionDependency,
  selectedUser,
}: AbsenceFormSchemaOptions) => {
  return z
    .object({
      fromDate: z.string().nullish(),
      toDate: z.string().nullish(),
      note: z.string().max(ABSENCE_NOTE_MAX_LENGTH).nullish(),
      substitute: z.string().optional().nullable(),
    })
    .superRefine(async ({ fromDate, toDate, substitute }, ctx) => {
      if (fromDate && toDate) {
        const fromDateParsed = moment(fromDate, 'YYYY-MM-DD');
        const toDateParsed = moment(toDate, 'YYYY-MM-DD');

        if (fromDateParsed.isAfter(toDateParsed, 'day')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['toDate'],
            params: {
              translationKey:
                'header.profile.tabs.update.absence.form.errors.toDate.custom',
            },
          });

          return;
        }
      }

      if (fromDate && !toDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['toDate'],
          params: {
            translationKey:
              'header.profile.tabs.update.absence.form.errors.toDate.empty',
          },
        });

        return;
      }

      if (!fromDate && toDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['fromDate'],
          params: {
            translationKey:
              'header.profile.tabs.update.absence.form.errors.fromDate.empty',
          },
        });

        return;
      }

      if (substitute && !fromDate && !toDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['fromDate'],
          params: {
            translationKey:
              'header.profile.tabs.update.absence.form.errors.substitute.dateMissing',
          },
        });

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['toDate'],
          params: {
            translationKey:
              'header.profile.tabs.update.absence.form.errors.substitute.dateMissing',
          },
        });

        return;
      }

      if (
        substitute &&
        fromDate &&
        toDate &&
        checkCircularSubstitutionDependency
      ) {
        const { isCircular, substituteUserName, isCurrentUser } =
          await checkCircularSubstitutionDependency(
            fromDate,
            toDate,
            substitute,
            selectedUser?.id
          );

        if (isCircular) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['substitute'],
            params: {
              translationKey: isCurrentUser
                ? 'header.profile.tabs.update.absence.form.errors.substitute.circularDependencyCurrentUser'
                : 'header.profile.tabs.update.absence.form.errors.substitute.circularDependency',
              userName: isCurrentUser ? undefined : selectedUser?.name,
              substituteUserName: substituteUserName,
            },
          });

          return;
        }
      }

      return;
    });
};

export type AbsenceFormOutput = z.infer<ReturnType<typeof absenceFormSchema>>;
export type AbsenceFormValues = Nullable<AbsenceFormOutput>;
