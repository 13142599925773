import { mergeProps } from '@candisio/design-system';
import { ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form';

export interface UseHookFormFieldOptions<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Content of field info tooltip */
  message?: ReactNode;
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Called when field value changed */
  onChange?: (value: any) => void;
  /** Is field read only? */
  readOnly?: boolean;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Register input's ref into the hook form, please note that it can have unintended side effects */
  forceInputFieldRef?: boolean;
}

/**
 * Returns props to hook up a field to React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const useHookFormField = <TFormValues extends FieldValues>({
  control,
  disabled,
  message,
  name,
  onChange,
  readOnly: readOnlyProp,
  variant,
  forceInputFieldRef = false,
}: UseHookFormFieldOptions<TFormValues>) => {
  const {
    field: inputProps,
    fieldState,
    formState,
  } = useController<TFormValues, typeof name>({ control, name });

  const { register, setFocus } = useFormContext();

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  return {
    inputProps: mergeProps(inputProps, {
      disabled,
      onChange,
      readOnly,
      ...(forceInputFieldRef && { ref: register(name).ref }),
    }),
    fieldContainerProps: {
      disabled,
      message: errorMessage ?? message,
      readOnly,
      variant: hasError ? 'error' : variant,
    },
    setFocus: () => setFocus(name),
  };
};
