import { defaultTheme } from '@candisio/design-system';

export const confidenceToColor = (
  confidence: number,
  isUserModified = false,
  isReadOnly = false
): {
  background?: string;
  corner?: string;
  warningColor?: string;
  warningIconColor?: string;
} => {
  if (confidence == null) {
    confidence = 0.5;
  }

  if (confidence < 0.9) {
    return {
      background: isReadOnly
        ? defaultTheme.colors.gray200
        : isUserModified
        ? defaultTheme.colors.gray0
        : defaultTheme.colors.yellow200,
      corner: defaultTheme.colors.yellow500,
      warningColor: defaultTheme.colors.red200,
    };
  } else {
    return {
      background: isReadOnly
        ? defaultTheme.colors.gray200
        : isUserModified
        ? defaultTheme.colors.gray0
        : defaultTheme.colors.green200,
      corner: defaultTheme.colors.green500,
      warningColor: isUserModified
        ? defaultTheme.colors.yellow200
        : defaultTheme.colors.green200,
      ...(isUserModified
        ? null
        : { warningIconColor: defaultTheme.colors.red200 }),
    };
  }
};
