import { useTranslation } from 'react-i18next';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { DocumentType } from '../../../../types';
import { formatCountByType } from '../utils';

export const TransactionsSummary = ({
  readyToExportEntitiesCount,
  loading,
}: {
  readyToExportEntitiesCount: Record<DocumentType, number>;
  loading?: boolean;
}) => {
  const [t] = useTranslation();

  const totalTransactionsCount =
    (readyToExportEntitiesCount.CARD_TRANSACTION ?? 0) +
    (readyToExportEntitiesCount.CARD_SETTLEMENT ?? 0);

  const transactionsSummary = [
    {
      title: t('export.exportSummary.transactionsCount', {
        count: totalTransactionsCount,
      }),
      content: formatCountByType({
        transactionsCount: readyToExportEntitiesCount.CARD_TRANSACTION,
        settlementsCount: readyToExportEntitiesCount.CARD_SETTLEMENT,
        t,
      }),
    },
  ];

  return totalTransactionsCount ? (
    <SummaryAccordionCard items={transactionsSummary} loading={loading} />
  ) : null;
};
