import { Button, InfoPanel, InfoPanelProps } from '@candisio/design-system';

interface ConfigureButtonProps {
  onClick: () => void;
  message: string;
  buttonLabel: string;
  variant?: InfoPanelProps['variant'];
  loading: boolean;
}

export const ConfigureButton = ({
  onClick,
  message,
  buttonLabel,
  loading,
  variant = 'error',
}: ConfigureButtonProps) => {
  return (
    <>
      <InfoPanel variant={variant} message={message}></InfoPanel>
      <Button width="100%" onClick={onClick} data-cy="submit" loading={loading}>
        {buttonLabel}
      </Button>
    </>
  );
};
