import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

export const PurchaseOrderNumber = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="purchaseOrderNumber"
      label={t('document.requestApproval.inputs.purchaseOrderNumber.label')}
      readOnly
    />
  );
};
