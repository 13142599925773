import { Description } from '@candisio/design-system';
import { useWatch } from 'react-hook-form';
import { ContactFormValues } from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';
import { useOrganizationOwnIbanWarning } from 'views/utils/useOrganizationOwnIbanWarning';

export const useContactFormIbanWarning = (): Description | undefined => {
  const [iban, name] = useWatch<ContactFormValues, ['iban', 'name']>({
    name: ['iban', 'name'],
  });

  const organizationOwnIbanWarning = useOrganizationOwnIbanWarning({
    iban,
    name,
  });

  if (organizationOwnIbanWarning) {
    return organizationOwnIbanWarning;
  }

  return undefined;
};
