import {
  Flex,
  useTheme,
  CustomEmptyStateProps,
  Grid,
} from '@candisio/design-system';
import { DocumentsTable } from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { SearchField } from 'components/SearchField/SearchField';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps } from 'react-router-dom';
import { appendParamsToQueryString } from 'utils/url-helper';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { DocumentsHeader } from '../components/DocumentsHeader';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { useInboxDocumentsColumnsConfigurations } from './hooks/useInboxDocumentsColumnsConfigurations';
import { useInboxDocumentsData } from './hooks/useInboxDocumentsData';
import { useInboxDocumentsFilters } from './hooks/useInboxDocumentsFilters';
import { InboxDocumentsEmptyState } from './InboxDocumentsEmptyState/InboxDocumentsEmptyState';

type Props = RouteComponentProps<{ organizationSlug: string }>;

export const InboxDocuments = ({
  history,
  location: { search },
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const [t] = useTranslation();

  const { space } = useTheme();

  const { searchParams, updateSearchParam } = useMutateSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? '';

  const {
    documentEdges,
    loading,
    onLoadMore,
    documentsTableData,
    filters,
    hasMoreData,
    onFilter,
    isTableEmpty,
    isTableFiltered,
    onSort,
    sortBy,
    handleDebounceSearch,
  } = useInboxDocumentsData();

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isLoadingConfigs,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useInboxDocumentsColumnsConfigurations({ sortBy, filters });

  const onClick = useCallback(
    ({ id, cursor }: DocumentsTableData) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? null,
      });

      history.push(
        `/${organizationSlug}${Routes.INBOX}/${id}?${searchWithCursor}`
      );
    },
    [history, search, organizationSlug]
  );

  const firstDocumentEdge = documentEdges[0];
  const searchWithCursor = appendParamsToQueryString(search, {
    cursor: firstDocumentEdge?.cursor,
  });

  const columnWidths: ColumnWidths = {
    contact: space.space256,
    discountDateWPercentage: space.space256,
    iban: space.space200,
    invoiceNumber: space.space200,
    purchaseOrderNumber: space.space200,
    goodsReceiptsCount: space.space200,
    sapPurchaseOrderNumber: space.space200,
  };

  const filterOptions = useInboxDocumentsFilters();
  const startProcessingLink = `/${organizationSlug}${Routes.INBOX}/${firstDocumentEdge?.node.id}?${searchWithCursor}`;

  const customEmptyState = memo(({ resetFilters }: CustomEmptyStateProps) => (
    <InboxDocumentsEmptyState
      isTableEmpty={isTableEmpty}
      isTableFiltered={isTableFiltered}
      resetFilters={resetFilters}
    />
  ));

  const showLoadingMoreSpinner = documentsTableData.length > 1;

  const infiniteScrollOptions = {
    dataLength: documentsTableData?.length,
    hasMore: hasMoreData,
    showLoadingMoreSpinner: !loading && showLoadingMoreSpinner,
    next: onLoadMore,
  };

  const columnsHash = availableDocumentColumnIds.join('-');
  const tableKey = `documents-inbox-table-filters-${columnsHash}`;

  return (
    <>
      <DocumentsHeader
        documentEdges={documentEdges}
        organizationSlug={organizationSlug}
        startProcessingLink={startProcessingLink}
      />

      <InboxViewContainer activeTab={TabView.INBOX_DOCUMENTS}>
        <Flex
          key={tableKey}
          height="100%"
          padding="0 space32 space24"
          overflow="hidden"
          direction="column">
          <Grid
            templateColumns="24rem 1fr"
            borderTopRadius="basic"
            overflow="hidden"
            borderBottom="1px solid gray200"
            background="white"
            padding="space12">
            <SearchField
              clearTooltip={t('archive:search.clearTooltip')}
              placeholder={t('archive:search.placeholder')}
              value={searchQuery}
              onChange={query => {
                handleDebounceSearch(query);
                updateSearchParam(queryParameter, query);
              }}
              inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
            />
            <Grid justifySelf="end">
              <Flex gap="space8" alignItems="center">
                <ConfigurationsMenu
                  isLoading={isLoadingConfigs || isResetPending}
                  configurationItems={configurationsTable}
                  onUpdateConfigurations={handleUpdateConfigurations}
                  onResetConfigurations={handleResetTableConfigurations}
                />
              </Flex>
            </Grid>
          </Grid>

          <DocumentsTable
            columns={availableDocumentColumnIds}
            columnWidths={columnWidths}
            customEmptyState={customEmptyState}
            data={documentsTableData}
            defaultFilters={filters}
            defaultSortBy={sortBy}
            filterOptions={filterOptions}
            infiniteScrollOptions={infiniteScrollOptions}
            isUsingSearchQuery={!!searchQuery}
            isLoading={loading}
            minWidth="100%"
            borderTopRadius="none"
            onEndReached={onLoadMore}
            onFilter={onFilter}
            onRowClick={onClick}
            onSort={onSort}
            width="max-content"
          />
        </Flex>
      </InboxViewContainer>
    </>
  );
};
