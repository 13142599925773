import { PDFDetails, useSelectedPdf } from 'components/DocumentViewer/utils';
import { Maybe } from 'generated-types/graphql.types';
import { useCanAddAttachments } from 'hooks/useCanAddAttachments/index';
import { useCanRemoveAttachment } from 'hooks/useCanAddAttachments/useCanRemoveAttachment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Attachment as MenuItem } from 'views/Inbox/DocumentProcessing/components/AttachmentItem';

export type DocumentAttachment = {
  id: string;
  name: string;
  url: string;
  previewUrl?: Maybe<string>;
  pages?: Array<string>;
  rank?: number;
  size?: Maybe<number>;
  isDetachable: boolean;
  attachedBy?: Maybe<{ id: string }>;
  originalDocumentId?: Maybe<string>;
};

export type Attachment = {
  id: string;
  name: string;
  url: string;
  size: number;
  isDetachable: boolean;
  isRemovable?: boolean;
  attachedBy: Maybe<{ id: string }>;
  originalDocumentId: Maybe<string>;
};

export const useAttachments = ({
  documentFile,
  documentId,
  documentAttachments,
}: {
  documentFile: PDFDetails;
  documentId: string | undefined;
  documentAttachments: DocumentAttachment[] | undefined | null;
}) => {
  const [t] = useTranslation();

  // @TODO TGE-51 simplify how we pass and use files with PdfViewer and Attachments
  const attachPermissions = useCanAddAttachments(documentId);
  const canAttachmentBeRemoved = useCanRemoveAttachment(documentId);

  const [selectedPdf, setSelectedPdf] = useSelectedPdf(documentFile);

  const canAttach = attachPermissions.fromDisk || attachPermissions.fromCandis;

  const canOnlyAttachFromDisk =
    attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const canOnlyAttachFromCandis =
    !attachPermissions.fromDisk && attachPermissions.fromCandis;

  const isAttachActionDisabled =
    !attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const mainDocumentListItem = {
    label: documentFile.name,
    id: documentFile.id ?? documentId ?? '',
    onAction: () => setSelectedPdf(documentFile),
  };

  const attachments = useMemo(
    () => (documentAttachments ?? []).map(toAttachment(canAttachmentBeRemoved)),
    [canAttachmentBeRemoved, documentAttachments]
  );

  const shouldDisplayAttachmentItems = canAttach || attachments.length > 0;

  const attachmentListItems: MenuItem[] = [
    {
      label: t('document.tabs.oldAttachments.document'),
      id: 'mainDocument',
      items: [mainDocumentListItem],
    },
    shouldDisplayAttachmentItems && {
      label: t('document.tabs.oldAttachments.attachments'),
      id: 'attachments',
      items: attachments.map(attachment =>
        toAttachmentListItem({
          attachment,
          canAttachmentBeRemoved,
          setSelectedPdf,
        })
      ),
    },
  ].filter(Boolean) as MenuItem[];

  return {
    canAttach,
    canOnlyAttachFromDisk,
    canOnlyAttachFromCandis,
    isAttachActionDisabled,
    attachPermissions,
    attachments,
    attachmentListItems,
    selectedPdf,
    setSelectedPdf,
  };
};

const toAttachment =
  (canAttachmentBeRemoved: (attachedById?: string | undefined) => boolean) =>
  (attachment: DocumentAttachment): Attachment => ({
    name: attachment.name,
    id: attachment.id,
    url: attachment.url,
    size: attachment.size ?? 0,
    attachedBy: attachment.attachedBy,
    isDetachable:
      attachment.isDetachable &&
      canAttachmentBeRemoved(attachment.attachedBy?.id),
    isRemovable: canAttachmentBeRemoved(attachment.attachedBy?.id),
    originalDocumentId: attachment.originalDocumentId,
  });

const toAttachmentListItem = ({
  attachment,
  setSelectedPdf,
  canAttachmentBeRemoved,
}: {
  attachment: DocumentAttachment;
  setSelectedPdf: (selectedPdf: PDFDetails) => void;
  canAttachmentBeRemoved: (attachedById?: string | undefined) => boolean;
}) => ({
  label: attachment.name,
  id: attachment.id,
  onAction: () => setSelectedPdf(attachment),
  isRemovable: canAttachmentBeRemoved(attachment.attachedBy?.id),
  isDetachable:
    attachment.isDetachable &&
    canAttachmentBeRemoved(attachment.attachedBy?.id),
  originalDocumentId: attachment.originalDocumentId,
});
