import { useTeamsByNameLazyQuery } from 'generated-types/graphql.types';
import { debounce } from 'lodash';

export type CheckTeamName = (
  value: string
) => Promise<{ isAvailable: boolean }>;

interface UseCheckTeamNameAvailability {
  originalValue?: string;
  teamId?: string;
}

const MAX_FETCHED_TEAMS = 1000;

/** Returns a (debounced) function that checks if team name is available. */
export const useCheckTeamNameAvailability = ({
  originalValue,
  teamId,
}: UseCheckTeamNameAvailability = {}) => {
  const [teamsByName] = useTeamsByNameLazyQuery();

  const checkTeamName = async (value: string) => {
    if (typeof value !== 'string' || value === originalValue) {
      return { isAvailable: true };
    }

    const { data } = await teamsByName({
      variables: { input: { limit: MAX_FETCHED_TEAMS, page: 1 }, name: value },
    });

    const teams = data?.teamsByName?.records ?? [];

    const matchingTeam = teams?.[0];
    const isAvailable = matchingTeam?.id === teamId || teams.length === 0;

    return { isAvailable };
  };

  return {
    checkTeamName: debounce(checkTeamName, 500, {
      leading: true,
    }),
  };
};
