import {
  Box,
  Card,
  Flex,
  Grid,
  Icon,
  Separator,
  Text,
} from '@candisio/design-system';
import { Entitlements } from 'hooks/useCandisFeatureFlags';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type SummaryRowProps = {
  text: string;
  highlight?: boolean;
};

type SummaryProps = {
  isPromoted?: boolean;
  isActive?: boolean;
  title: ReactNode;
  rows: SummaryRowProps[];
};

export const SummaryRow = ({ text, highlight }: SummaryRowProps) => {
  return (
    <Flex
      as="li"
      alignItems="baseline"
      gap="space8"
      color={highlight ? 'purple700' : ''}>
      <Box height="space12" width="space12">
        <Icon
          icon="check"
          //@ts-ignore
          viewBox="0 0 14 12"
        />
      </Box>
      <Text fontWeight={highlight ? 'semibold' : ''}>{text}</Text>
    </Flex>
  );
};

export const Summary = ({
  rows,
  title,
  isActive = false,
  isPromoted = false,
}: SummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const showPromotedAccent = isPromoted && !isActive;
  const showCurrentAccent = isActive;

  const hasAccent = isPromoted || isActive;

  return (
    <Card
      padding="0"
      border="1px solid gray300"
      height="fit-content"
      overflow="hidden"
      boxShadow={isPromoted ? 'elevatedShadow3' : ''}
      style={hasAccent ? {} : { marginTop: '2.25rem' }}>
      <Grid>
        {showPromotedAccent && (
          <Flex
            background="purple500"
            justifyContent="center"
            alignItems="center"
            height="space36">
            <Text color="white">{t('promo.recommendedEntitlement')}</Text>
          </Flex>
        )}
        {showCurrentAccent && (
          <Flex
            background="gray200"
            justifyContent="center"
            alignItems="center"
            height="space36">
            <Text color="gray600" fontWeight="lighter">
              {t('promo.currentActiveEntitlement')}
            </Text>
          </Flex>
        )}
        <Text
          fontSize="xlarge"
          textAlign="center"
          style={{ paddingBlock: '.75rem' }}>
          {title}
        </Text>
        <Separator orientation="horizontal" />
        <Grid gap="space16" padding="space16" paddingBottom="space18">
          <Text fontSize="small" fontWeight="semibold">
            {t('promo.summaryTitle')}
          </Text>
          <Grid as="ul" gap="space8" paddingLeft="0">
            {rows.map(r => (
              <SummaryRow text={r.text} highlight={r.highlight} key={r.text} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export type EntitlementsOverviewPromoProps = {
  activeEntitlement: Entitlements;
  feature: FEATURE;
};

export const EntitlementsOverviewPromo = ({
  activeEntitlement,
  feature,
}: EntitlementsOverviewPromoProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const summaries = [
    {
      id: Entitlements.BASE,
      isActive: activeEntitlement === Entitlements.BASE,
      isPromoted: false,
      title: t('promo.entitlements.basic'),
      rows: [
        {
          highlight: false,
          text: t('promo.readableDescription.workflows'),
        },
        {
          highlight: false,
          text: t('promo.readableDescription.reminder'),
        },
        {
          highlight: false,
          text: t('promo.readableDescription.dms'),
        },
      ] satisfies SummaryRowProps[],
    },
    {
      id: Entitlements.PLUS,
      isActive: activeEntitlement === Entitlements.PLUS,
      isPromoted: true,
      title: (
        <Text color="purple600" fontWeight="semibold">
          {t('promo.entitlements.plus')}
        </Text>
      ),
      rows: [
        {
          highlight: feature === FEATURE.ABSENCE_MANAGEMENT,
          text: t('promo.readableDescription.absence'),
        },
        {
          highlight:
            feature === FEATURE.DOCUMENT_RELATIONS ||
            feature === FEATURE.CONTRACTS,
          text: t('promo.readableDescription.documentRelations'),
        },
        {
          highlight: feature === FEATURE.CONTRACTS,
          text: t('promo.contractTable.modal.benefit1.heading'),
        },
        {
          highlight: feature === FEATURE.CONTRACTS,
          text: t('promo.contractTable.modal.benefit2.heading'),
        },
        {
          highlight: feature === FEATURE.CONTRACTS,
          text: t('promo.contractTable.modal.benefit3.heading'),
        },
        {
          highlight: feature === FEATURE.PROVISIONS,
          text: t('promo.readableDescription.provisions'),
        },
        {
          highlight: feature === FEATURE.SEPA_XML,
          text: t('promo.readableDescription.sepaXML'),
        },
        {
          highlight: feature === FEATURE.FULL_TEXT_SEARCH,
          text: t('promo.readableDescription.fullTextSearch'),
        },
      ] satisfies SummaryRowProps[],
    },
    {
      id: Entitlements.MAX,
      isPromoted: false,
      isActive: activeEntitlement === Entitlements.MAX,
      title: t('promo.entitlements.max'),
      rows: [
        {
          highlight: false,
          text: t('promo.readableDescription.sso'),
        },
        {
          highlight:
            feature === FEATURE.PURCHASE_ORDERS ||
            feature === FEATURE.INTEGRATION_SAP,
          text: t('promo.readableDescription.purchaseOrders'),
        },
        {
          highlight: false,
          text: t('promo.readableDescription.coreDataManagement'),
        },
      ] satisfies SummaryRowProps[],
    },
  ] as const;

  return (
    <Grid
      gap="space24"
      autoFlow="column"
      autoColumns="1fr 1fr 1fr"
      width="100%">
      {summaries.map(props => (
        <Summary
          key={props.id}
          title={props.title}
          rows={props.rows}
          isPromoted={props.isPromoted}
          isActive={props.isActive}
        />
      ))}
    </Grid>
  );
};
