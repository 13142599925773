import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { percentageFormat } from 'utils/format';
import {
  PaymentConditionCellValue,
  PaymentConditionsListData,
} from 'views/Settings/PaymentConditions/types';
import {
  queryPaymentConditions,
  SortBy,
  sortPaymentConditions,
} from 'views/Settings/PaymentConditions/utils';

export const usePaymentConditionsList = (data: PaymentConditionsListData[]) => {
  const [t] = useTranslation();

  const [sortBy, setSortBy] = useState<SortBy>(SortBy.id);

  const sortItems = [
    {
      id: SortBy.ascending,
      label: t('settings:paymentConditions.sortItems.ascending'),
    },
    {
      id: SortBy.id,
      label: t('settings:paymentConditions.sortItems.id'),
    },
    {
      id: SortBy.lastUpdated,
      label: t('settings:paymentConditions.sortItems.lastUpdated'),
    },
  ];

  const sortButtonText = sortItems.find(
    item => sortBy.toString() === item.id
  )?.label;

  const [queryString, setQueryString] = useState('');

  const onSearchFilter = (filter: string) => {
    setQueryString(filter);
  };

  const handleSearchReset = () => {
    setQueryString('');
  };

  const handleSortBy = (value: Key[]) => {
    setSortBy(value.length ? (value[0] as SortBy) : sortBy);
  };

  const renderPaymentCondition = (condition: PaymentConditionCellValue) => {
    const { discountOffset, discountPercentage, dueDateOffset } = condition;

    const hasDiscount =
      condition.discountOffset !== undefined &&
      condition.discountPercentage !== undefined;

    return hasDiscount
      ? t('settings:paymentConditions.table.values.conditionWithDiscount', {
          discountOffset,
          discountPercentage: percentageFormat(discountPercentage ?? 0),
          dueDateOffset,
        })
      : t('settings:paymentConditions.table.values.conditionWithoutDiscount', {
          dueDateOffset,
        });
  };

  const queriedPaymentConditions = queryPaymentConditions(
    data,
    renderPaymentCondition,
    queryString
  );

  const sortedPaymentConditions = sortPaymentConditions(
    queriedPaymentConditions,
    renderPaymentCondition,
    sortBy
  );

  return {
    sortedPaymentConditions,
    onSearchFilter,
    handleSearchReset,
    renderPaymentCondition,
    queryString,
    handleSortBy,
    sortButtonText,
    sortBy,
    sortItems,
  };
};
