import { ContractsTablePromo } from 'containers/Entitlements/components/Upsell/components/ContractsTablePromo';
import { EcmTablePromo } from 'containers/Entitlements/components/Upsell/components/EcmTablePromo';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { compiledRoutes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useSap } from 'orgConfig/sap';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Redirect, Switch } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { Route } from 'router/SentryRouting';
import { hasRole } from 'utils/authorization';
import { Approvals } from 'views/Approvals';
import { ArchiveDocuments } from 'views/Archive/Documents/ArchiveDocuments';
import { EcmContracts } from 'views/Archive/EcmContracts/EcmContracts';
import { EcmDocuments } from 'views/Archive/EcmDocuments/EcmDocuments';
import { GoodsReceipts } from 'views/Archive/GoodsReceipts/GoodsReceipts';
import { PurchaseOrders } from 'views/Archive/PurchaseOrders/PurchaseOrders';
import { PurchaseOrdersImportHistory } from 'views/Archive/PurchaseOrders/PurchaseOrdersImportHistory/PurchaseOrdersImportHistory';
import { ArchiveReimbursementsContainer } from 'views/Archive/Reimbursements/ArchiveReimbursementsContainer';
import { Transactions } from 'views/Archive/Transactions/Transactions';
import { AuthorizedAuthDatev } from 'views/AuthDatev';
import { Contacts } from 'views/Contacts';
import { ContactImportHistory } from 'views/Contacts/ContactImportHistory/ContactImportHistory';
import { ContactsDeprecated } from 'views/Contacts/ContactsDeprecated';
import { CardholderOverview } from 'views/CreditCards/CardholderOverview/CardholderOverview';
import { CreditCardsTableView } from 'views/CreditCards/CardManagerView/CreditCardsTableView';
import { CreditCardsInsights } from 'views/CreditCards/CreditCardsInsights/CreditCardsInsights';
import { CreditCardsStatements } from 'views/CreditCards/CreditCardsStatements/CreditCardsStatements';
import { Dashboard } from 'views/Dashboard';
import { AllInsightsWidgets } from 'views/Dashboard/AllInsightsWidgets';
import { DocumentDetails } from 'views/DocumentDetails';
import { DocumentDownloadHistory } from 'views/DocumentDownloadHistory/DocumentDownloadHistory';
import { Duplicate } from 'views/Duplicate';
import { EcmDocument } from 'views/EcmDocument/EcmDocument';
import { DocumentProcessing } from 'views/Inbox/DocumentProcessing';
import { EcmInboxSensitiveDocuments } from 'views/Inbox/EcmSensitiveDocuments/EcmInboxSensitiveDocuments';
import { EcmInboxSensitiveDocumentsPromo } from 'views/Inbox/EcmSensitiveDocuments/EcmInboxSensitiveDocumentsPromo';
import { InboxDocuments } from 'views/Inbox/InboxDocuments';
import { InboxReimbursementsContainer } from 'views/Inbox/Reimbursements/InboxReimbursementsContainer';
import { InboxTransactions } from 'views/Inbox/Transactions';
import { ManageRelationshipsView } from 'views/ManageRelationships/ManageRelationshipsView';
import { Payments } from 'views/Payments';
import { Reimbursement } from 'views/Reimbursement/Reimbursement';
import { ReimbursementDashboard } from 'views/Reimbursement/ReimbursementDashboard';
import { Settings } from 'views/Settings';
import { Organization } from 'views/Settings/Organization/Organization';
import { TransactionAssociationAdminRequester } from 'views/TransactionAssociation/TransactionAssociationAdminRequester';
import { TransactionAssociationApprover } from 'views/TransactionAssociation/TransactionAssociationApprover';
import { TransactionDownloadHistoryView } from 'views/TransactionDownloadHistory/TransactionDownloadHistoryView';
import { IntegrationName } from '../../generated-types/graphql.types';
import { useFullOrganization } from '../../providers/OrganizationProvider';
import { useInitialExportUrl } from '../Integrations/Export/Manifest';
import { ExportViewBuilder } from '../Integrations/Export/ViewBuilder';
import {
  useMenuBottomLinks,
  useMenuTopLinks,
} from './components/Sidebar/menuLinks';

interface AppRoutesProps {
  path: string;
  selectedOrganization: string | null;
}

export const AppRoutes = ({ path, selectedOrganization }: AppRoutesProps) => {
  const currentUser = useCurrentUser();
  const [manageDocumentRelationsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.manageDocumentRelations,
  ]);

  const organization = useFullOrganization();
  const { showAllExtraFeatures } = useCreditCardsSetup();
  const { isAccountant, isAdmin, isCardManager, isOnlyApprover } =
    useUserRoles();

  // Determines which document view to show when opening
  // an ECM document from Archive view
  const [searchParams] = useSearchParams();
  const {
    canAccessEcm,
    showEcm,
    showEcmContractManagement,
    showEcmSensitiveDocuments,
    showDocumentRelations,
  } = useEcm();

  const isInvoice = searchParams.get('isInvoice');

  const { shouldUseSapPurchaseOrder } = useSap();
  const { canUseReimbursement } = useReimbursement();

  const [creditCardsStatementsFF, contactsArchivingFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.creditCardsStatements,
    FEATURE_FLAGS.contactsArchiving,
  ]);

  const integrationName = organization?.integrationName;

  const exportViewDef = useInitialExportUrl();

  useEffect(() => {
    localStorage.setItem(
      'CANDIS_INTEGRATION',
      integrationName === IntegrationName.Sap ? 'SAP' : ''
    );
  }, [integrationName]);

  const menuTopLinks = useMenuTopLinks();
  const menuBottomLinks = useMenuBottomLinks();

  const visibleMenuTopLinks = menuTopLinks.filter(
    item => item.showMenuItem !== false
  );

  const visibleMenuBottomLinks = menuBottomLinks.filter(
    item => item.showMenuItem !== false
  );

  const allMenuLinks = [...visibleMenuTopLinks, ...visibleMenuBottomLinks];

  const hasPermissionsToVisit = (route: string) => {
    const { allowedRoles } = allMenuLinks.find(l => l.url === route) || {};

    const canView =
      currentUser &&
      allowedRoles &&
      allowedRoles.some(role => hasRole(currentUser, role));

    return canView;
  };

  const exportPath = exportViewDef.url;

  return (
    <Switch>
      {showDocumentRelations && manageDocumentRelationsFF && (
        <Route
          exact
          path={[
            `${path}${compiledRoutes.manageRelationshipsArchive}`,
            `${path}${compiledRoutes.manageRelationshipsInbox}`,
            `${path}${compiledRoutes.manageRelationshipsApprovals}`,
            `${path}${compiledRoutes.manageRelationshipsEcmArchive}`,
          ]}
          component={ManageRelationshipsView}
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxRoute}`}
          component={InboxDocuments}
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxTransactions}`}
          component={InboxTransactions}
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && canAccessEcm && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxSensitiveContractsECM}`}
          component={
            showEcmSensitiveDocuments
              ? EcmInboxSensitiveDocuments
              : EcmInboxSensitiveDocumentsPromo
          }
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) &&
        canAccessEcm &&
        showEcmSensitiveDocuments && (
          <Route
            exact
            path={`${path}${compiledRoutes.inboxSensitiveContractECM}`}
            component={EcmDocument}
          />
        )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxTransactionAssociationRoute}`}
          component={
            isOnlyApprover
              ? TransactionAssociationApprover
              : TransactionAssociationAdminRequester
          }
        />
      )}

      {hasPermissionsToVisit(compiledRoutes.inboxRoute) &&
        canUseReimbursement && (
          <Route
            exact
            path={`${path}${compiledRoutes.inboxReimbursementsRoute}`}
            component={InboxReimbursementsContainer}
          />
        )}

      {hasPermissionsToVisit(compiledRoutes.archiveRoute) &&
        canUseReimbursement && (
          <Route
            exact
            path={`${path}${compiledRoutes.archiveReimbursementsRoute}`}
            component={ArchiveReimbursementsContainer}
          />
        )}
      <Route
        path={`${path}${compiledRoutes.documentProcessingRoute}`}
        component={DocumentProcessing}
      />
      <Route
        path={`${path}${compiledRoutes.resolveDuplicateRoute}`}
        component={Duplicate}
      />
      {hasPermissionsToVisit(compiledRoutes.contactsRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.contactImportHistoryRoute}`}
          component={ContactImportHistory}
        />
      )}
      <Route
        exact
        path={`${path}${compiledRoutes.documentDataDownloadHistoryRoute}`}
        component={DocumentDownloadHistory}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.transactionsDownloadHistory}`}
        component={TransactionDownloadHistoryView}
      />
      {hasPermissionsToVisit(compiledRoutes.contactsRoute) && (
        <Route
          path={`${path}${compiledRoutes.editContactRoute}`}
          component={contactsArchivingFF ? Contacts : ContactsDeprecated}
        />
      )}
      {hasPermissionsToVisit(exportPath) && (
        <Route
          path={`${path}${compiledRoutes.exportsRoute}`}
          component={ExportViewBuilder}
        />
      )}
      {(isAdmin || isAccountant) && (
        <Route
          path={`${path}${compiledRoutes.settingsRoute}`}
          component={Settings}
        />
      )}
      <Route
        exact
        path={`${path}${compiledRoutes.allWidgetsRoute}`}
        component={AllInsightsWidgets}
      />
      {hasPermissionsToVisit(compiledRoutes.approvalsRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.approvalsRoute}`}
          component={Approvals}
        />
      )}
      <Route
        path={`${path}${compiledRoutes.documentApprovalRoute}`}
        component={DocumentProcessing}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.dashboardRoute}`}
        component={Dashboard}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.dashboardWidgetRoute}`}
        component={Dashboard}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.dashboardCreditCardRoute}`}
        component={Dashboard}
      />
      {hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveTransactionAssociationRoute}`}
          component={
            isOnlyApprover
              ? TransactionAssociationApprover
              : TransactionAssociationAdminRequester
          }
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveRoute}`}
          component={ArchiveDocuments}
        />
      )}
      {shouldUseSapPurchaseOrder &&
        hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.allPurchaseOrders}`}
            component={PurchaseOrders}
          />
        )}
      {shouldUseSapPurchaseOrder &&
        hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.arhiveGoodsReceiptsTableRoute}`}
            component={GoodsReceipts}
          />
        )}
      {shouldUseSapPurchaseOrder &&
        hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.allPurchaseOrdersImportHistoryRoute}`}
            component={PurchaseOrdersImportHistory}
          />
        )}
      {hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.allTransactions}`}
          component={Transactions}
        />
      )}
      {!showEcmContractManagement && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmContractsRoute}`}
          component={ContractsTablePromo}
        />
      )}
      {showEcmContractManagement && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmContractsRoute}`}
          component={EcmContracts}
        />
      )}
      {showEcmContractManagement && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmContractRoute}`}
          // We reuse the EcmDocument component to display a contract in the single document view, because contracts are also ECM documents.
          component={EcmDocument}
        />
      )}
      <Route
        exact
        path={`${path}${compiledRoutes.archiveEcmDocumentsRoute}`}
        component={showEcm ? EcmDocuments : EcmTablePromo}
      />
      {showEcm && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmDocumentRoute}`}
          component={isInvoice ? DocumentDetails : EcmDocument}
        />
      )}
      <Route
        path={`${path}${compiledRoutes.documentArchiveRoute}`}
        component={DocumentDetails}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.authorizeDatevRoute}`}
        component={AuthorizedAuthDatev}
      />
      {isAdmin && (
        <Route
          path={`${path}${compiledRoutes.editOrganizationRoute}`}
          component={Organization}
        />
      )}

      {hasPermissionsToVisit(compiledRoutes.paymentsRoute) &&
        organization?.payment.isActive && (
          <Route
            path={`${path}${compiledRoutes.paymentsRoute}`}
            component={Payments}
          />
        )}

      {(isCardManager || isAccountant || isAdmin) && showAllExtraFeatures && (
        <Route
          exact
          path={`${path}${compiledRoutes.creditCardsManagerOverviewRoute}`}
          component={CreditCardsTableView}
        />
      )}

      {(!isCardManager || !isAdmin) && showAllExtraFeatures && (
        <Route
          exact
          path={`${path}${compiledRoutes.creditCardsCardholderOverviewRoute}`}
          component={CardholderOverview}
        />
      )}

      {showAllExtraFeatures &&
        creditCardsStatementsFF &&
        (isCardManager || isAdmin || isAccountant) && (
          <Route
            exact
            path={`${path}${compiledRoutes.creditCardsStatements}`}
            component={CreditCardsStatements}
          />
        )}

      {showAllExtraFeatures && (isCardManager || isAdmin || isAccountant) && (
        <Route
          exact
          path={`${path}${compiledRoutes.insightsCreditCardsRoute}`}
          component={CreditCardsInsights}
        />
      )}

      {showAllExtraFeatures && (isCardManager || isAdmin || isAccountant) && (
        <Route
          exact
          path={`${path}${compiledRoutes.recurringPaymentsModalRoute}`}
          component={CreditCardsInsights}
        />
      )}

      {canUseReimbursement &&
        hasPermissionsToVisit(compiledRoutes.reimbursementDashboardRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.reimbursementDashboardRoute}`}
            component={ReimbursementDashboard}
          />
        )}

      {canUseReimbursement &&
        hasPermissionsToVisit(compiledRoutes.reimbursementDashboardRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.reimbursementRoute}`}
            component={Reimbursement}
          />
        )}

      <Route
        exact
        path={`${path}${compiledRoutes.customerSupportRoute}`}
        component={Dashboard}
      />

      <Route
        exact
        path={`${path}${compiledRoutes.notificationRoute}`}
        component={Dashboard}
      />

      <Route
        exact
        path={`${path}${compiledRoutes.userProfileRoute}`}
        component={Dashboard}
      />

      <Route
        path={path}
        component={() => (
          <Redirect
            to={`/${selectedOrganization}${compiledRoutes.dashboardRoute}`}
          />
        )}
      />
    </Switch>
  );
};
