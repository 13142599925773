import {
  CardIssuerAmountInput,
  CardLimitRenewFrequency,
  RequestVirtualCardInput,
} from 'generated-types/graphql.types';
import { RequestVirtualCard } from '../hooks/useRequestVirtualCard';

export const sanitiseRequestVirtualCard = ({
  limit,
  limitInterval,
  transactionLimit,
  validity,
  cardLabel,
  comment,
  selectedCategory,
  accountingData,
}: RequestVirtualCard): RequestVirtualCardInput => {
  const requestedTxLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: transactionLimit ?? 0,
  };

  const requestedLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: limit ?? 0,
  };

  return {
    comment: comment ?? '',
    validityPeriod: Number(validity),
    requestedTransactionLimit: requestedTxLimit,
    requestedLimit,
    label: cardLabel ?? null,
    requestedLimitRenewFrequency: limitInterval as CardLimitRenewFrequency,
    acceptedCardAgreement: true,
    category: selectedCategory,
    accountingData,
  };
};
