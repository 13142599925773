import { ContactValidationErrorType } from 'generated-types/graphql.types';
import { SingleParsedContact } from './types';

export const MAX_FILE_SIZE_MB = 20;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
export const MAX_NUMBER_OF_CONTACTS = 10002;

export const toContactAccountsPayableNumber = (c: SingleParsedContact) =>
  c.contactInputData.accountsPayableNumber;

export const getPreviousContacts = (
  allAccounts: SingleParsedContact[],
  index: number
) => allAccounts.slice(0, index);

export const contactCanBeImported = (contact: SingleParsedContact): boolean => {
  if (contact.errors && contact.errors.length > 0) {
    const contactHasOnlyNonCriticalErrors = contact.errors.every(error =>
      nonCriticalErrors.includes(error)
    );

    return contactHasOnlyNonCriticalErrors;
  } else {
    return true;
  }
};

export const nonCriticalErrors: Readonly<ContactValidationErrorType[]> = [
  ContactValidationErrorType.InvalidTaxNumber,
  ContactValidationErrorType.InvalidPhoneNumber,
  ContactValidationErrorType.InvalidEmail,
  ContactValidationErrorType.InvalidIban,
  ContactValidationErrorType.InvalidVatId,
  ContactValidationErrorType.PaymentConditionArchived,
  ContactValidationErrorType.PaymentConditionNotFound,
  ContactValidationErrorType.InvalidPaymentCondition,
  ContactValidationErrorType.InvalidBankAccountNumber,
  ContactValidationErrorType.SwiftCodeMissingIbanOrBankAccountNumber,
  ContactValidationErrorType.InvalidSwiftCode,
  ContactValidationErrorType.InvalidStreet,
  ContactValidationErrorType.InvalidPostOfficeBox,
  ContactValidationErrorType.InvalidPostalCode,
  ContactValidationErrorType.InvalidCity,
  ContactValidationErrorType.InvalidCountryCode,
  ContactValidationErrorType.BankAccountNumberAndIbanMismatch,
  ContactValidationErrorType.BankAccountNumberMissingSwiftCode,
  ContactValidationErrorType.InvalidCustomerNumber,
];
