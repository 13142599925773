import { lazy, Suspense } from 'react';

const GraphiQLEditor = lazy(
  () => import('../../../../../../../views/Settings/GraphiQL')
);

export const GraphiQL = () => {
  return (
    <Suspense fallback={null}>
      <GraphiQLEditor />
    </Suspense>
  );
};
