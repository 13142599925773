import { BookingAccountForm } from 'views/Settings/BookingAccounts/components/BookingAccountForm/BookingAccountForm';
import { BookingAccountFormSkeleton } from 'views/Settings/BookingAccounts/components/BookingAccountForm/BookingAccountFormSkeleton';

interface BookingAccountDetails {
  id: string;
  accountCode: string;
  name: string;
  isArchived?: boolean | null;
}

export type BookingAccountFormData = {
  accountCode: string;
  name: string;
};

type Props = {
  bookingAccount?: BookingAccountDetails;
  onChange: (formData: BookingAccountFormData) => Promise<any>;
  onStatusChange: (isArchived: boolean) => void;
  updateStatusLoading: boolean;
  loading: boolean;
};

export const BookingAccountChange = ({
  bookingAccount,
  onChange,
  onStatusChange,
  updateStatusLoading,
  loading,
}: Props) => {
  if (loading || updateStatusLoading) {
    return <BookingAccountFormSkeleton />;
  }

  return (
    <BookingAccountForm
      key={bookingAccount?.id}
      onChange={onChange}
      onStatusChange={onStatusChange}
      updateStatusLoading={updateStatusLoading}
      bookingAccount={bookingAccount}
    />
  );
};
