import {
  ThemeProvider as DSThemeProvider,
  defaultTheme,
} from '@candisio/design-system';
import { ReactNode } from 'react';

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return <DSThemeProvider theme={defaultTheme}>{children}</DSThemeProvider>;
};
