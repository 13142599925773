import { EventEmitter } from 'events';
import { useSetConsentForTrackingConfigurationMutation } from 'generated-types/graphql.types';
import { useSegmentDestinations } from 'providers/AnalyticsProvider/useSegmentDestinations';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { useState, useEffect } from 'react';
import { IntercomModal } from '../IntercomModal/IntercomModal';
import { CookieBanner } from './CookieBanner';
import { PreferencesModal } from './PreferencesModal';
import { ConsentManagerContainer } from './styles';

// So we can open the ConsentManager from the user settings
const emitter = new EventEmitter();

export function openConsentManager() {
  emitter.emit('openConsentManager');
}

const LOCAL_STORAGE_KEY = 'candis_privacy_consent_interacted';

const getPrivacyConsent = (analyticsId: string) =>
  localStorage.getItem(`${LOCAL_STORAGE_KEY}.${analyticsId}`);

const setPrivacyConsent = (analyticsId: string) => {
  localStorage.setItem(`${LOCAL_STORAGE_KEY}.${analyticsId}`, 'true');
};

export const DEFAULT_TRACKING_ENABLED = false;
export const DEFAULT_INTERCOM_COOKIES_ENABLED = false;

export const PrivacyConsentManager = () => {
  const destinations = useSegmentDestinations();
  const currentUser = useCurrentUser();
  const [dialogOpen, toggleDialogOpen] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState<boolean>(
    DEFAULT_TRACKING_ENABLED
  );

  const [intercomCookiesEnabled, setIntercomCookiesEnabled] = useState<boolean>(
    DEFAULT_INTERCOM_COOKIES_ENABLED
  );

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const currentUserTrackingEnabled =
      currentUser?.trackingConfiguration?.trackingEnabled;

    setTrackingEnabled(currentUserTrackingEnabled ?? DEFAULT_TRACKING_ENABLED);
  }, [currentUser]);

  useEffect(() => {
    const currentUserIntercomCookiesEnabled =
      currentUser?.trackingConfiguration?.intercom;

    setIntercomCookiesEnabled(
      currentUserIntercomCookiesEnabled ?? DEFAULT_INTERCOM_COOKIES_ENABLED
    );
  }, [currentUser]);

  useEffect(() => {
    const analyticsId = currentUser?.analyticsId;

    if (analyticsId) {
      const consent = getPrivacyConsent(analyticsId);
      setShowBanner(consent !== 'true');
    }
  }, [currentUser]);

  const [setConsentForTrackingConfiguration] =
    useSetConsentForTrackingConfigurationMutation();

  const setTrackingConsent = async (
    isTrackingEnabled: boolean,
    isIntercomCookieEnabled: boolean
  ) => {
    if (currentUser == null) {
      return;
    }

    await setConsentForTrackingConfiguration({
      variables: {
        intercom: isIntercomCookieEnabled,
        trackingEnabled: isTrackingEnabled,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: currentUserQuery, variables: { id: currentUser.id } },
      ],
    });
  };

  const hideBanner = () => {
    if (currentUser?.analyticsId) {
      setPrivacyConsent(currentUser.analyticsId);
    }

    setShowBanner(false);
  };

  const showConsentManager = () => {
    // Reset the state to what is currently on the user, as toggling a switch
    // and just closing the modal is not enough consent

    setTrackingEnabled(
      currentUser?.trackingConfiguration?.trackingEnabled ??
        DEFAULT_TRACKING_ENABLED
    );

    setIntercomCookiesEnabled(
      currentUser?.trackingConfiguration?.intercom ??
        DEFAULT_INTERCOM_COOKIES_ENABLED
    );
    toggleDialogOpen(true);
  };

  useEffect(() => {
    emitter.on('openConsentManager', showConsentManager);

    return () => {
      emitter.removeListener('openConsentManager', showConsentManager);
    };
  });

  const handleSavePreferences = async (
    isTrackingEnabled: boolean,
    isIntercomCookieEnabled: boolean
  ) => {
    hideBanner();
    toggleDialogOpen(false);
    if (
      currentUser?.trackingConfiguration?.trackingEnabled ===
        isTrackingEnabled &&
      currentUser?.trackingConfiguration?.intercom === isIntercomCookieEnabled
    ) {
      return;
    }

    await setTrackingConsent(isTrackingEnabled, isIntercomCookieEnabled).then(
      () => {
        // reload the page to rebuild the analytics provider's window object
        // otherwise this chagnes won't take effect and the user will still be tracked
        window.location.reload();
      }
    );
  };

  const handleAcceptAll = async () => {
    await handleSavePreferences(true, true);
  };

  const handleIntercomOn = async () => {
    await handleSavePreferences(trackingEnabled, true);
  };

  if (currentUser == null) {
    return null;
  }

  // We want to load the component even if there was an error while
  // fetching the segment destinations for any reason, for e.g. if user
  // has an ad-blocker enabled in the browser, we still want them to set their
  // preferences for backend tracking. Note, the Tools column will not be
  // displayed in this case
  if (destinations.loading) {
    return null;
  }

  const intercomIsNotEnabled = !currentUser.trackingConfiguration?.intercom;

  return (
    <div>
      {showBanner && (
        <ConsentManagerContainer>
          <CookieBanner
            onAcceptAll={handleAcceptAll}
            onOpenPreferences={showConsentManager}
          />
        </ConsentManagerContainer>
      )}
      {intercomIsNotEnabled && (
        <IntercomModal
          showConsentManager={showConsentManager}
          handleIntercomOn={handleIntercomOn}
        />
      )}
      <PreferencesModal
        onCancel={() => toggleDialogOpen(false)}
        onSaveSelected={() =>
          handleSavePreferences(trackingEnabled, intercomCookiesEnabled)
        }
        setTrackingEnabled={setTrackingEnabled}
        visible={dialogOpen}
        destinations={destinations.data}
        trackingEnabled={trackingEnabled}
        intercom={intercomCookiesEnabled}
        setIntercomEnabled={setIntercomCookiesEnabled}
      />
    </div>
  );
};
