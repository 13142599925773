import {
  HookFormTextField,
  HookFormTextFieldProps,
} from 'components/HookFormFields/HookFormTextField';
import { useFormContext } from 'react-hook-form';
import { OrganizationSettingsFormOutupt } from '../utils/organizationSettingsFormSchema';

interface DeliveryAddressTextFieldProps
  extends Omit<
    HookFormTextFieldProps<OrganizationSettingsFormOutupt>,
    'readOnly'
  > {}

export const DeliveryAddressTextField = ({
  name,
  label,
  placeholder,
  ...restProps
}: DeliveryAddressTextFieldProps) => {
  const { watch } = useFormContext<OrganizationSettingsFormOutupt>();

  const isCheckboxForSameAddressChecked = watch('deliveryAddressSameAsBilling');
  const isReadOnly = isCheckboxForSameAddressChecked;

  return (
    <HookFormTextField
      name={name}
      label={label}
      placeholder={placeholder}
      readOnly={isReadOnly}
      {...restProps}
    />
  );
};
