import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z } from 'zod';

const selectFieldSchema = z.object({
  value: z.string().nullish(),
  inputValue: z.string().nullish(),
});

export const reimbursementFormSchema = z.object({
  title: nonEmptyString,
  note: z.string().nullish(),
  targetMembership: z.array(z.string()).nullish(),
  suggestedCostCenter: selectFieldSchema.nullish(),
});

export type ReimbursementFormValues = z.infer<typeof reimbursementFormSchema>;
