import { nohistoryData } from 'components/Lottie/Lottie';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { Trans } from 'providers/LocaleProvider';
import { EmptyState } from './EmptyState';

interface EmptyDataStateProps {
  /** translation string must include JSON file prefix i.e. settings: */
  /** otherwise common: will be use by default */
  /** https://candis.atlassian.net/wiki/spaces/FG/pages/1247281159/Frontend+Code+Guidelines#i18next */
  translation: string;
  imageUrl?: string;
  lottieAnimation?: {};
  /** link to open route using RouterLink component */
  url?: string;
}

export const EmptyDataState = ({
  lottieAnimation,
  translation,
  imageUrl,
  url,
}: EmptyDataStateProps) => {
  return (
    <EmptyState
      animationData={
        imageUrl ? undefined : lottieAnimation ? lottieAnimation : nohistoryData
      }
      imageUrl={imageUrl}>
      <Trans i18nKey={translation}>
        No data.
        {url ? <RouterLink to={url} /> : null}
      </Trans>
    </EmptyState>
  );
};
