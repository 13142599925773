import { Grid, Modal, Paragraph } from '@candisio/design-system';
import { ImportPreviewTable } from 'components/ImportPreviewTable/ImportPreviewTable';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { DefaultImportFooter } from './DefaultImportFooter';

export interface DefaultBookingKey {
  taxCode: string;
  description: string;
}

interface DefaultImportProps {
  isVisible: boolean;
  handleImport: (defaultBookingKeys: DefaultBookingKey[]) => void;
  isImporting: boolean;
  closeModal: () => void;
  hasActiveBookingAccounts: boolean;
  goToBookingAccounts: () => void;
}

const getDefaultBookingKeys = (t: i18next.TFunction) => {
  return [
    {
      id: '0',
      taxCode: t('booking-keys:defaultBookingKeys.first.taxCode'),
      description: t('booking-keys:defaultBookingKeys.first.description'),
    },
    {
      id: '1',
      taxCode: t('booking-keys:defaultBookingKeys.second.taxCode'),
      description: t('booking-keys:defaultBookingKeys.second.description'),
    },
    {
      id: '2',
      taxCode: t('booking-keys:defaultBookingKeys.third.taxCode'),
      description: t('booking-keys:defaultBookingKeys.third.description'),
    },
    {
      id: '3',
      taxCode: t('booking-keys:defaultBookingKeys.fourth.taxCode'),
      description: t('booking-keys:defaultBookingKeys.fourth.description'),
    },
  ];
};

export const DefaultImport = ({
  isVisible,
  handleImport,
  isImporting,
  closeModal,
  hasActiveBookingAccounts,
  goToBookingAccounts,
}: DefaultImportProps) => {
  const [t] = useTranslation();
  const defaultBookingKeys = getDefaultBookingKeys(t);

  return (
    <Modal
      onClose={closeModal}
      isOpen={isVisible}
      title={t('booking-keys:import.defaultImport.title')}
      closeLabel={t('common.close')}
      width="60rem"
      height="calc(100vh - 4rem)"
      background="gray200"
      padding="0">
      <Grid templateRows="1fr auto" height="100%" overflow="hidden">
        <Grid
          gap="space16"
          templateRows="auto 1fr"
          padding="space32"
          overflow="hidden">
          <Paragraph>
            {t('booking-keys:import.defaultImport.subtitle')}
          </Paragraph>
          <ImportPreviewTable
            columns={['taxCode', 'description']}
            data={defaultBookingKeys}
          />
        </Grid>
        <DefaultImportFooter
          defaultBookingKeys={getDefaultBookingKeys(t)}
          isImporting={isImporting}
          handleImport={handleImport}
          hasActiveBookingAccounts={hasActiveBookingAccounts}
          goToBookingAccounts={goToBookingAccounts}
        />
      </Grid>
    </Modal>
  );
};
