import { QueryHookOptions } from '@apollo/client';
import { useTheme } from '@candisio/design-system';
import {
  useCountDocumentsProcessing,
  useCountTransactionsAndDocumentsInbox,
} from 'hooks/useCounters';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useTranslation } from 'react-i18next';
import inboxIcon from '../../images/inbox-icon.svg';
import { MenuItemCount, MenuItemIcon } from '../../style';

const options: QueryHookOptions = {
  // we use cache-first so that query can be re-fetched upon mutations eg. request approval
  fetchPolicy: 'cache-first',
};

export const Inbox = () => {
  const [t] = useTranslation();
  const { fontSizes } = useTheme();

  const creditCardsSetup = useCreditCardsSetup();
  const { count, loading: loadingProcessing } =
    useCountDocumentsProcessing(options);

  const { totalCount: inboxTotalCount, loading: loadingInbox } =
    useCountTransactionsAndDocumentsInbox({
      processingDocuments: count,
      loadingProcessing,
      featureFlag: creditCardsSetup.isAddonBought,
    });

  const isProcessingCountHidden = loadingProcessing || !Boolean(count);
  const isInboxCountHidden = loadingInbox || !Boolean(inboxTotalCount);

  if (!creditCardsSetup.isAddonBought) {
    return (
      <>
        <MenuItemCount
          aria-label="Inbox-menu-item-count"
          style={{
            fontSize: isProcessingCountHidden ? 0 : fontSizes.xsmall,
            padding: isProcessingCountHidden ? 0 : '0.05rem 0.35rem',
          }}>
          {count}
        </MenuItemCount>
        <MenuItemIcon>
          <img src={inboxIcon} alt={t('navigation.inbox')} />
        </MenuItemIcon>
      </>
    );
  }

  return (
    <>
      <MenuItemCount
        aria-label="Inbox-menu-item-count"
        style={{
          fontSize: isInboxCountHidden ? 0 : fontSizes.xsmall,
          padding: isInboxCountHidden ? 0 : '0.05rem 0.35rem',
        }}>
        {inboxTotalCount}
      </MenuItemCount>
      <MenuItemIcon>
        <img src={inboxIcon} alt={t('navigation.inbox')} />
      </MenuItemIcon>
    </>
  );
};
