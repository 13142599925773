import { Image } from '@candisio/design-system';
import { CardBrand, CardType } from 'generated-types/graphql.types';
import candisVirtualX1Card from './images/candis-1xvirtual-xs.svg';
import candisPhysicalCard from './images/candis-physical-xs.svg';
import candisVirtualCard from './images/candis-virtual-xs.svg';
import pliantVirtualX1Card from './images/pliant-1xvirtual-xs.svg';
import pliantBlackCard from './images/pliant-physical-xs.svg';
import pliantVirtualCard from './images/pliant-virtual-xs.svg';

type CardTypeToImage = Record<CardType, { candis: string; pliant: string }>;

const cardTypeToImage: CardTypeToImage = {
  VIRTUAL: {
    candis: candisVirtualCard,
    pliant: pliantVirtualCard,
  },
  SINGLE_USE: {
    candis: candisVirtualX1Card,
    pliant: pliantVirtualX1Card,
  },
  PHYSICAL: {
    candis: candisPhysicalCard,
    pliant: pliantBlackCard,
  },
  BLACK: {
    candis: candisPhysicalCard,
    pliant: pliantBlackCard,
  },
};

interface CreditCardImageProps {
  cardType?: CardType;
  cardBrand?: CardBrand;
}

export const CreditCardImage = ({
  cardType,
  cardBrand,
}: CreditCardImageProps) => {
  if (!cardType || !cardBrand) {
    return null;
  }

  const isCandisCardBrand = cardBrand === CardBrand.Candis;

  return (
    <Image
      width="45px"
      alt="credit card image"
      src={
        isCandisCardBrand
          ? cardTypeToImage[cardType].candis
          : cardTypeToImage[cardType].pliant
      }
    />
  );
};
