import { Grid, Icon, Link, Text, useModal } from '@candisio/design-system';
import { useDatev } from 'orgConfig/datev';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import {
  ExportType,
  ModalSource,
  Table,
} from 'views/Integrations/Export/types';
import { BDSPromotionModal } from './BDSPromotion';

const PromoButton = ({ promotionText }: { promotionText: string }) => {
  const [t] = useTranslation();

  const modalSource = ModalSource.EXPORT_SIDEBAR_INITIALIZED;
  const { triggerProps, modalProps, isOpen } = useModal();

  return (
    <Grid
      autoFlow="column"
      gap="space8"
      background="purplebg"
      paddingX="space8"
      paddingY="space12"
      borderRadius="medium">
      <Icon icon="infoCircle" color="purple500" size="space16" />
      <Grid alignItems="start" rowGap="space4">
        <Text color="gray800" fontSize="basic">
          {promotionText}
        </Text>
        <Link
          icon="newTab"
          iconPosition="right"
          color="purple"
          {...triggerProps}>
          {t('export.exportForm.exportDatevBds.learnMore')}
        </Link>
        {isOpen ? (
          <BDSPromotionModal modalSource={modalSource} {...modalProps} />
        ) : null}
      </Grid>
    </Grid>
  );
};

export const BdsPromotionButton = () => {
  const [t] = useTranslation();
  const { exportType, table } = useContext(ExportContext);
  const { bdsBought } = useDatev(); // BDS-checked

  const shouldShowProvisionsPromo =
    table === Table.PROVISIONS || table === Table.REVERSALS;

  // No need to show promo when the customer has bought the add-on
  // there will be a different CTA to activate the feature in case BDS is not connected yet
  if (bdsBought) {
    return null;
  }

  // Any selected Export Type should show Provisions Promo if one of the tabs is selected
  if (shouldShowProvisionsPromo) {
    return (
      <PromoButton
        promotionText={t(
          'export.exportForm.exportDatevBds.provisionsPromotion'
        )}
      />
    );
  }

  // if we are not in a provision/reversal tab and BDS is selected, but not connected
  if (exportType === ExportType.DATEV_BDS) {
    return (
      <PromoButton
        promotionText={t('export.exportForm.exportDatevBds.bdsPromotion')}
      />
    );
  }
};
