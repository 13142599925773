export const taxCodeExportValidationSchema: Record<
  string,
  {
    invoices_received: boolean;
    outgoing_invoices: boolean;
  }
> = {
  1: { invoices_received: true, outgoing_invoices: true },
  2: { invoices_received: false, outgoing_invoices: true },
  3: { invoices_received: false, outgoing_invoices: true },
  4: { invoices_received: false, outgoing_invoices: true },
  5: { invoices_received: false, outgoing_invoices: true },
  6: { invoices_received: true, outgoing_invoices: false },
  7: { invoices_received: true, outgoing_invoices: false },
  8: { invoices_received: true, outgoing_invoices: false },
  9: { invoices_received: true, outgoing_invoices: false },
  10: { invoices_received: false, outgoing_invoices: true },
  11: { invoices_received: false, outgoing_invoices: true },
  12: { invoices_received: false, outgoing_invoices: true },
  13: { invoices_received: false, outgoing_invoices: true },
  14: { invoices_received: false, outgoing_invoices: true },
  15: { invoices_received: false, outgoing_invoices: true },
  16: { invoices_received: true, outgoing_invoices: false },
  17: { invoices_received: true, outgoing_invoices: false },
  18: { invoices_received: true, outgoing_invoices: false },
  19: { invoices_received: true, outgoing_invoices: false },
  40: { invoices_received: true, outgoing_invoices: true },
  44: { invoices_received: false, outgoing_invoices: true },
  46: { invoices_received: false, outgoing_invoices: true },
  47: { invoices_received: false, outgoing_invoices: true },
  49: { invoices_received: true, outgoing_invoices: false },
  50: { invoices_received: true, outgoing_invoices: true },
  51: { invoices_received: true, outgoing_invoices: true },
  52: { invoices_received: true, outgoing_invoices: true },
  53: { invoices_received: true, outgoing_invoices: true },
  54: { invoices_received: true, outgoing_invoices: true },
  55: { invoices_received: true, outgoing_invoices: true },
  56: { invoices_received: true, outgoing_invoices: true },
  57: { invoices_received: true, outgoing_invoices: true },
  58: { invoices_received: true, outgoing_invoices: true },
  59: { invoices_received: true, outgoing_invoices: true },
  91: { invoices_received: true, outgoing_invoices: false },
  92: { invoices_received: true, outgoing_invoices: false },
  94: { invoices_received: true, outgoing_invoices: false },
  95: { invoices_received: true, outgoing_invoices: false },
  96: { invoices_received: false, outgoing_invoices: false },
  97: { invoices_received: true, outgoing_invoices: false },
  98: { invoices_received: true, outgoing_invoices: false },
  99: { invoices_received: true, outgoing_invoices: false },
  101: { invoices_received: false, outgoing_invoices: true },
  102: { invoices_received: false, outgoing_invoices: true },
  171: { invoices_received: false, outgoing_invoices: true },
  174: { invoices_received: false, outgoing_invoices: true },
  191: { invoices_received: false, outgoing_invoices: true },
  200: { invoices_received: false, outgoing_invoices: true },
  201: { invoices_received: false, outgoing_invoices: true },
  202: { invoices_received: false, outgoing_invoices: true },
  220: { invoices_received: false, outgoing_invoices: true },
  221: { invoices_received: false, outgoing_invoices: true },
  222: { invoices_received: false, outgoing_invoices: true },
  225: { invoices_received: false, outgoing_invoices: true },
  226: { invoices_received: false, outgoing_invoices: true },
  227: { invoices_received: false, outgoing_invoices: true },
  230: { invoices_received: false, outgoing_invoices: true },
  231: { invoices_received: false, outgoing_invoices: true },
  232: { invoices_received: false, outgoing_invoices: true },
  233: { invoices_received: false, outgoing_invoices: true },
  234: { invoices_received: false, outgoing_invoices: true },
  235: { invoices_received: false, outgoing_invoices: true },
  236: { invoices_received: false, outgoing_invoices: true },
  240: { invoices_received: false, outgoing_invoices: true },
  241: { invoices_received: false, outgoing_invoices: true },
  242: { invoices_received: false, outgoing_invoices: true },
  243: { invoices_received: false, outgoing_invoices: true },
  250: { invoices_received: false, outgoing_invoices: true },
  251: { invoices_received: false, outgoing_invoices: true },
  252: { invoices_received: false, outgoing_invoices: true },
  253: { invoices_received: false, outgoing_invoices: true },
  260: { invoices_received: false, outgoing_invoices: true },
  270: { invoices_received: false, outgoing_invoices: true },
  280: { invoices_received: false, outgoing_invoices: true },
  285: { invoices_received: false, outgoing_invoices: true },
  286: { invoices_received: false, outgoing_invoices: true },
  290: { invoices_received: false, outgoing_invoices: true },
  291: { invoices_received: false, outgoing_invoices: true },
  295: { invoices_received: false, outgoing_invoices: true },
  296: { invoices_received: false, outgoing_invoices: true },
  400: { invoices_received: true, outgoing_invoices: false },
  401: { invoices_received: true, outgoing_invoices: false },
  402: { invoices_received: true, outgoing_invoices: false },
  490: { invoices_received: true, outgoing_invoices: false },
  506: { invoices_received: true, outgoing_invoices: false },
  507: { invoices_received: true, outgoing_invoices: false },
  511: { invoices_received: true, outgoing_invoices: false },
  512: { invoices_received: true, outgoing_invoices: false },
  516: { invoices_received: true, outgoing_invoices: false },
  517: { invoices_received: true, outgoing_invoices: false },
  521: { invoices_received: true, outgoing_invoices: false },
  522: { invoices_received: true, outgoing_invoices: false },
  525: { invoices_received: true, outgoing_invoices: false },
  526: { invoices_received: true, outgoing_invoices: false },
  527: { invoices_received: true, outgoing_invoices: false },
  531: { invoices_received: true, outgoing_invoices: false },
  532: { invoices_received: true, outgoing_invoices: false },
  536: { invoices_received: true, outgoing_invoices: false },
  537: { invoices_received: true, outgoing_invoices: false },
  541: { invoices_received: true, outgoing_invoices: false },
  542: { invoices_received: true, outgoing_invoices: false },
  546: { invoices_received: true, outgoing_invoices: false },
  547: { invoices_received: true, outgoing_invoices: false },
  551: { invoices_received: true, outgoing_invoices: false },
  552: { invoices_received: true, outgoing_invoices: false },
  556: { invoices_received: true, outgoing_invoices: false },
  557: { invoices_received: true, outgoing_invoices: false },
  561: { invoices_received: true, outgoing_invoices: false },
  562: { invoices_received: true, outgoing_invoices: false },
  566: { invoices_received: true, outgoing_invoices: false },
  567: { invoices_received: true, outgoing_invoices: false },
  701: { invoices_received: true, outgoing_invoices: false },
  702: { invoices_received: true, outgoing_invoices: false },
  705: { invoices_received: true, outgoing_invoices: false },
  706: { invoices_received: true, outgoing_invoices: false },
  708: { invoices_received: true, outgoing_invoices: false },
  709: { invoices_received: true, outgoing_invoices: false },
  900: { invoices_received: true, outgoing_invoices: true },
  901: { invoices_received: true, outgoing_invoices: true },
  902: { invoices_received: true, outgoing_invoices: true },
  903: { invoices_received: true, outgoing_invoices: true },
  904: { invoices_received: true, outgoing_invoices: true },
  905: { invoices_received: true, outgoing_invoices: true },
  906: { invoices_received: true, outgoing_invoices: true },
  907: { invoices_received: true, outgoing_invoices: true },
  908: { invoices_received: true, outgoing_invoices: true },
  909: { invoices_received: true, outgoing_invoices: true },
  910: { invoices_received: true, outgoing_invoices: true },
  911: { invoices_received: true, outgoing_invoices: true },
  912: { invoices_received: true, outgoing_invoices: true },
  913: { invoices_received: true, outgoing_invoices: true },
  914: { invoices_received: true, outgoing_invoices: true },
  915: { invoices_received: true, outgoing_invoices: true },
  916: { invoices_received: true, outgoing_invoices: true },
  917: { invoices_received: true, outgoing_invoices: true },
  918: { invoices_received: true, outgoing_invoices: true },
  919: { invoices_received: true, outgoing_invoices: true },
  920: { invoices_received: true, outgoing_invoices: true },
  921: { invoices_received: true, outgoing_invoices: true },
  922: { invoices_received: true, outgoing_invoices: true },
  923: { invoices_received: true, outgoing_invoices: true },
  924: { invoices_received: true, outgoing_invoices: true },
  925: { invoices_received: true, outgoing_invoices: true },
  926: { invoices_received: true, outgoing_invoices: true },
  927: { invoices_received: true, outgoing_invoices: true },
  928: { invoices_received: true, outgoing_invoices: true },
  929: { invoices_received: true, outgoing_invoices: true },
  930: { invoices_received: true, outgoing_invoices: true },
  931: { invoices_received: true, outgoing_invoices: true },
  932: { invoices_received: true, outgoing_invoices: true },
  933: { invoices_received: true, outgoing_invoices: true },
  934: { invoices_received: true, outgoing_invoices: true },
  935: { invoices_received: true, outgoing_invoices: true },
  936: { invoices_received: true, outgoing_invoices: true },
  937: { invoices_received: true, outgoing_invoices: true },
  938: { invoices_received: true, outgoing_invoices: true },
  939: { invoices_received: true, outgoing_invoices: true },
  940: { invoices_received: true, outgoing_invoices: true },
  941: { invoices_received: true, outgoing_invoices: true },
  942: { invoices_received: true, outgoing_invoices: true },
  943: { invoices_received: true, outgoing_invoices: true },
  944: { invoices_received: true, outgoing_invoices: true },
  945: { invoices_received: true, outgoing_invoices: true },
  946: { invoices_received: true, outgoing_invoices: true },
  947: { invoices_received: true, outgoing_invoices: true },
  948: { invoices_received: true, outgoing_invoices: true },
  949: { invoices_received: true, outgoing_invoices: true },
  950: { invoices_received: true, outgoing_invoices: true },
  951: { invoices_received: true, outgoing_invoices: true },
  952: { invoices_received: true, outgoing_invoices: true },
  953: { invoices_received: true, outgoing_invoices: true },
  954: { invoices_received: true, outgoing_invoices: true },
  955: { invoices_received: true, outgoing_invoices: true },
  956: { invoices_received: true, outgoing_invoices: true },
  957: { invoices_received: true, outgoing_invoices: true },
  958: { invoices_received: true, outgoing_invoices: true },
  959: { invoices_received: true, outgoing_invoices: true },
  960: { invoices_received: true, outgoing_invoices: true },
  961: { invoices_received: true, outgoing_invoices: true },
  962: { invoices_received: true, outgoing_invoices: true },
  963: { invoices_received: true, outgoing_invoices: true },
  964: { invoices_received: true, outgoing_invoices: true },
  965: { invoices_received: true, outgoing_invoices: true },
  966: { invoices_received: true, outgoing_invoices: true },
  967: { invoices_received: true, outgoing_invoices: true },
  968: { invoices_received: true, outgoing_invoices: true },
  969: { invoices_received: true, outgoing_invoices: true },
  970: { invoices_received: true, outgoing_invoices: true },
  971: { invoices_received: true, outgoing_invoices: true },
  972: { invoices_received: true, outgoing_invoices: true },
  973: { invoices_received: true, outgoing_invoices: true },
  974: { invoices_received: true, outgoing_invoices: true },
  975: { invoices_received: true, outgoing_invoices: true },
  976: { invoices_received: true, outgoing_invoices: true },
  977: { invoices_received: true, outgoing_invoices: true },
  978: { invoices_received: true, outgoing_invoices: true },
  979: { invoices_received: true, outgoing_invoices: true },
  980: { invoices_received: true, outgoing_invoices: true },
  981: { invoices_received: true, outgoing_invoices: true },
  982: { invoices_received: true, outgoing_invoices: true },
  983: { invoices_received: true, outgoing_invoices: true },
  984: { invoices_received: true, outgoing_invoices: true },
  985: { invoices_received: true, outgoing_invoices: true },
  986: { invoices_received: true, outgoing_invoices: true },
  987: { invoices_received: true, outgoing_invoices: true },
  988: { invoices_received: true, outgoing_invoices: true },
  989: { invoices_received: true, outgoing_invoices: true },
  990: { invoices_received: true, outgoing_invoices: true },
  991: { invoices_received: true, outgoing_invoices: true },
  992: { invoices_received: true, outgoing_invoices: true },
  993: { invoices_received: true, outgoing_invoices: true },
  994: { invoices_received: true, outgoing_invoices: true },
  995: { invoices_received: true, outgoing_invoices: true },
  996: { invoices_received: true, outgoing_invoices: true },
  997: { invoices_received: true, outgoing_invoices: true },
  998: { invoices_received: true, outgoing_invoices: true },
  999: { invoices_received: true, outgoing_invoices: true },
  5000: { invoices_received: false, outgoing_invoices: true },
  6501: { invoices_received: true, outgoing_invoices: false },
  6502: { invoices_received: true, outgoing_invoices: false },
  6506: { invoices_received: true, outgoing_invoices: false },
  6507: { invoices_received: true, outgoing_invoices: false },
  6511: { invoices_received: true, outgoing_invoices: false },
  6512: { invoices_received: true, outgoing_invoices: false },
  6516: { invoices_received: true, outgoing_invoices: false },
  6517: { invoices_received: true, outgoing_invoices: false },
  6521: { invoices_received: true, outgoing_invoices: false },
  6522: { invoices_received: true, outgoing_invoices: false },
  6526: { invoices_received: true, outgoing_invoices: false },
  6527: { invoices_received: true, outgoing_invoices: false },
  6531: { invoices_received: true, outgoing_invoices: false },
  6532: { invoices_received: true, outgoing_invoices: false },
  6536: { invoices_received: true, outgoing_invoices: false },
  6537: { invoices_received: true, outgoing_invoices: false },
  6541: { invoices_received: true, outgoing_invoices: false },
  6542: { invoices_received: true, outgoing_invoices: false },
  6546: { invoices_received: true, outgoing_invoices: false },
  6547: { invoices_received: true, outgoing_invoices: false },
  6551: { invoices_received: true, outgoing_invoices: false },
  6552: { invoices_received: true, outgoing_invoices: false },
  6556: { invoices_received: true, outgoing_invoices: false },
  6557: { invoices_received: true, outgoing_invoices: false },
  6561: { invoices_received: true, outgoing_invoices: false },
  6562: { invoices_received: true, outgoing_invoices: false },
  6566: { invoices_received: true, outgoing_invoices: false },
  6567: { invoices_received: true, outgoing_invoices: false },
  6701: { invoices_received: true, outgoing_invoices: false },
  6702: { invoices_received: true, outgoing_invoices: false },
  6705: { invoices_received: true, outgoing_invoices: false },
  6706: { invoices_received: true, outgoing_invoices: false },
  6708: { invoices_received: true, outgoing_invoices: false },
  6709: { invoices_received: true, outgoing_invoices: false },
  9401: { invoices_received: true, outgoing_invoices: false },
  9402: { invoices_received: true, outgoing_invoices: false },
  9501: { invoices_received: true, outgoing_invoices: false },
  9506: { invoices_received: true, outgoing_invoices: false },
  9510: { invoices_received: true, outgoing_invoices: false },
  9701: { invoices_received: true, outgoing_invoices: false },
};
