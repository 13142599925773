import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { EcmDocumentsTableData } from '../types';

type Value = string | undefined;

/** Uses `TruncatedText` and sets `charsAfterEllipsis={4}` */
export const DocumentNameCell = ({
  value,
}: CellProps<EcmDocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return (
    <TruncatedText wordBreak="break-all" charsAfterEllipsis={4}>
      {value}
    </TruncatedText>
  );
};
