import { z } from 'zod';

export const createPaymentSchema = z.object({
  iban: z.string(),
});

export const errorMessages = {
  iban: {
    label: 'details.ibanHeading',
  },
};
