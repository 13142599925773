import { TabPanel, Tabs } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useTabs } from 'components/Tabs/useTabs';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { AccountingAreasDrawerContainer } from 'views/Settings/AccountingAreas/elements/Drawer/AccountingAreasDrawerContainer';
import { AccountingAreasList } from 'views/Settings/AccountingAreas/elements/List/AccountingAreasList';
import { useAccountingAreasData } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasData';
import { useAccountingAreasList } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasList';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';

const SETTINGS_WIDTH = '40rem';

export const ACCOUNTING_AREAS_TAB_KEYS = {
  active: 'active',
  archived: 'archived',
};

export const AccountingAreas = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { countLoading, activeCount, archivedCount } = useAccountingAreasData();

  const { onTabChanged, selectedKey } = useAccountingAreasList();

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: ACCOUNTING_AREAS_TAB_KEYS.active,
        title: t('accountingAreas.tabs.active'),
        badge: !countLoading ? String(activeCount) : undefined,
        children: <AccountingAreasList />,
      },
      {
        key: ACCOUNTING_AREAS_TAB_KEYS.archived,
        title: t('accountingAreas.tabs.archived'),
        badge: !countLoading ? String(archivedCount) : undefined,
        children: <AccountingAreasList />,
      },
    ],
    onSelectionChange: onTabChanged,
    selectedKey,
  });

  return (
    <SettingsLayout
      title={t('accountingAreas.title')}
      width={SETTINGS_WIDTH}
      actions={<Tabs {...tabsProps} />}>
      <TabPanel {...tabPanelProps} />
      <InfoPanel
        title={t('settings:accountingAreas.infoPanel.title')}
        sections={t('settings:accountingAreas.infoPanel.sections', {
          returnObjects: true,
        })}
      />
      <AccountingAreasDrawerContainer />
    </SettingsLayout>
  );
};
