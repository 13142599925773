import { Grid, Item, SelectField } from '@candisio/design-system';
import { useEffect, useState, Key } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { DateRangeProps } from '../../types';

const currentYear = new Date().getFullYear();
export const yearOptions = new Array(10).fill('').map((_, i) => ({
  id: String(currentYear - i),
  name: String(currentYear - i),
}));

export const SelectionYear = ({
  setReadableDateOption,
  invoiceDate,
}: DateRangeProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const [value, setValue] = useState(
    invoiceDate?.values.readable || String(currentYear)
  );

  useEffect(() => {
    setReadableDateOption(value);
  }, [setReadableDateOption, value]);

  const handleSelectionChange = (selectedValue: Key) => {
    setValue(selectedValue as string);
    setReadableDateOption(selectedValue as string);
  };

  return (
    <Grid templateColumns="33%" zIndex={1}>
      <SelectField
        label={t('dateRangePicker.inputs.year')}
        select={{
          children: item => <Item>{item.name}</Item>,
          items: yearOptions,
          name: 'year',
          onSelectionChange: handleSelectionChange,
          defaultSelectedKey: value,
        }}
      />
    </Grid>
  );
};
