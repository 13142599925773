import { Button, Grid, Heading } from '@candisio/design-system';
import { ReActivateRow } from 'components/ArchiveWrapper/ArchiveWrapper';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { EducationalLink } from 'components/EducationalLink';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation/zodResolver';
import { contractSubCategoryDetailsErrorMessages } from '../Form/contractSubCategoryDetailsErrorMessages';
import {
  ContractSubtypeDetailsForm,
  ContractSubCategoryDetailsFormProps,
} from '../Form/ContractSubCategoryDetailsForm';
import {
  ContractSubCategoryDetailsFormOutput,
  ContractSubCategoryDetailsFormValues,
  contractSubCategoryDetailsFormSchema,
} from '../Form/contractSubCategoryDetailsFormSchema';
import { ContractCustomSubCategoryDetailsDrawerProps } from './ContractCustomSubCategoryDetailsDrawer';

const CREATE_CONTRACT_SUBCATEGORY_FORM_ID = 'create-contract-subcategory';

const contractSubCategoryDefaultValues = {
  name: '',
  description: '',
};

export interface ContractCustomSubCategoryDetailsProps
  extends Omit<ContractCustomSubCategoryDetailsDrawerProps, 'isOpen'> {
  defaultValues?: ContractSubCategoryDetailsFormValues;
  onSubmit: ContractSubCategoryDetailsFormProps['onSubmit'];
  isSubmitPending: boolean;
  updatingStatus: boolean;
  onArchive: () => Promise<void>;
  onActivate: () => Promise<void>;
  isActive?: boolean;
}

export const ContractCustomSubCategoryDetails = ({
  onCloseDrawer,
  compact,
  contractSubCategoryId,
  onArchive,
  defaultValues,
  onSubmit,
  isSubmitPending,
  updatingStatus,
  isActive,
  onActivate,
}: ContractCustomSubCategoryDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const form = useForm<ContractSubCategoryDetailsFormOutput>({
    // Validation will trigger on the first blur event. After that, it will
    // trigger on every change event.
    mode: 'onTouched',
    shouldFocusError: true,
    defaultValues: {
      ...contractSubCategoryDefaultValues,
      ...defaultValues,
    },
    resolver: zodResolver({
      zodSchema: contractSubCategoryDetailsFormSchema,
      errorMessages: contractSubCategoryDetailsErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
  });

  const {
    formState: { dirtyFields },
  } = form;

  const hasNameChanged = dirtyFields.name;
  const showConfirmation = contractSubCategoryId && hasNameChanged;

  return (
    <DrawerLayout
      compact={compact}
      onClose={onCloseDrawer}
      header={
        <Heading as="h3">
          {contractSubCategoryId
            ? t('contractCustomSubCategories.drawer.titleEdit')
            : t('contractCustomSubCategories.drawer.titleNew')}
        </Heading>
      }
      footer={
        <Grid placeContent="space-between" autoFlow="column">
          {showConfirmation ? (
            <ConfirmationButton
              confirmButtonProps={{
                type: 'submit',
                form: CREATE_CONTRACT_SUBCATEGORY_FORM_ID,
                loading: isSubmitPending,
                disabled: isSubmitPending,
              }}
              confirmationTitle={t(
                'contractCustomSubCategories.drawer.actions.confirmNameChange'
              )}
              onConfirm={form.handleSubmit(onSubmit)}
              loading={isSubmitPending}
              disabled={isSubmitPending || !isActive}>
              {t('contractCustomSubCategories.drawer.actions.save')}
            </ConfirmationButton>
          ) : (
            <Button
              type="submit"
              form={CREATE_CONTRACT_SUBCATEGORY_FORM_ID}
              loading={isSubmitPending}
              disabled={isSubmitPending || !isActive}>
              {t('contractCustomSubCategories.drawer.actions.save')}
            </Button>
          )}
          {isActive && (
            <ConfirmationButton
              onConfirm={onArchive}
              confirmationTitle={t(
                'contractCustomSubCategories.drawer.actions.confirmArchive'
              )}
              variant="tertiary"
              color="red"
              loading={updatingStatus}
              disabled={updatingStatus}>
              {t('contractCustomSubCategories.drawer.actions.archive')}
            </ConfirmationButton>
          )}
        </Grid>
      }>
      {!isActive && (
        <ReActivateRow
          onRestore={onActivate}
          isSubmitting={updatingStatus}
          disabled={updatingStatus}
        />
      )}
      <ContractSubtypeDetailsForm
        form={form}
        formId={CREATE_CONTRACT_SUBCATEGORY_FORM_ID}
        onSubmit={onSubmit}
        disabled={!isActive}
      />
      <Grid top="space64" gap="space8" justifyItems="end">
        <EducationalLink
          icon="arrowRight"
          href={t(
            'contractCustomSubCategories.drawer.links.helpCenterArticle.url'
          )}
          label={t(
            'contractCustomSubCategories.drawer.links.helpCenterArticle.label'
          )}
        />
      </Grid>
    </DrawerLayout>
  );
};
