import { Button, Grid, Heading } from '@candisio/design-system';
import { ReActivateRow } from 'components/ArchiveWrapper/ArchiveWrapper';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { EducationalLink } from 'components/EducationalLink';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation/zodResolver';
import { DocumentTagDetailsDrawerProps } from './DocumentTagDetailsDrawer';
import { documentTagDetailsErrorMessages } from './Form/documentTagDetailsErrorMessages';
import {
  DocumentTagDetailsForm,
  DocumentTagDetailsFormProps,
} from './Form/DocumentTagDetailsForm';
import {
  DocumentTagDetailsFormOutput,
  DocumentTagDetailsFormValues,
  documentTagDetailsFormSchema,
} from './Form/documentTagDetailsFormSchema';

const CREATE_DOCUMENT_TAG_FORM_ID = 'create-document-tag';

const documentTagDefaultValues = {
  name: '',
  description: '',
};

export interface DocumentTagDetailsProps
  extends Omit<DocumentTagDetailsDrawerProps, 'isOpen'> {
  defaultValues?: DocumentTagDetailsFormValues;
  onSubmit: DocumentTagDetailsFormProps['onSubmit'];
  submitting: boolean;
  updatingStatus: boolean;
  onArchive: () => Promise<void>;
  onActivate: () => Promise<void>;
  isActive?: boolean;
}

export const DocumentTagDetails = ({
  onCloseDrawer,
  compact,
  documentTagId,
  onArchive,
  defaultValues,
  onSubmit,
  submitting,
  updatingStatus,
  isActive,
  onActivate,
}: DocumentTagDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [documentTagsSecondIterationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.documentTagsSecondIteration,
  ]);

  const form = useForm<DocumentTagDetailsFormOutput>({
    // Validation will trigger on the first blur event. After that, it will
    // trigger on every change event.
    mode: 'onTouched',
    shouldFocusError: true,
    defaultValues: {
      ...documentTagDefaultValues,
      ...defaultValues,
    },
    resolver: zodResolver({
      zodSchema: documentTagDetailsFormSchema,
      errorMessages: documentTagDetailsErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
  });

  const {
    formState: { dirtyFields },
  } = form;

  const hasNameChanged = dirtyFields.name;
  const showConfirmation = documentTagId && hasNameChanged;

  return (
    <DrawerLayout
      compact={compact}
      onClose={onCloseDrawer}
      header={
        <Heading as="h3">
          {documentTagId
            ? t('documentTags.drawer.titleEdit')
            : t('documentTags.drawer.titleNew')}
        </Heading>
      }
      footer={
        <Grid placeContent="space-between" autoFlow="column">
          {showConfirmation ? (
            <ConfirmationButton
              confirmButtonProps={{
                type: 'submit',
                form: CREATE_DOCUMENT_TAG_FORM_ID,
                loading: submitting,
                disabled: submitting,
              }}
              confirmationTitle={t(
                'documentTags.drawer.actions.confirmNameChange'
              )}
              onConfirm={form.handleSubmit(onSubmit)}
              loading={submitting}
              disabled={submitting}>
              {t('documentTags.drawer.actions.save')}
            </ConfirmationButton>
          ) : (
            <Button
              type="submit"
              form={CREATE_DOCUMENT_TAG_FORM_ID}
              loading={submitting}
              disabled={submitting}>
              {t('documentTags.drawer.actions.save')}
            </Button>
          )}
          {documentTagsSecondIterationFF && documentTagId && isActive && (
            <ConfirmationButton
              onConfirm={onArchive}
              confirmationTitle={t(
                'documentTags.drawer.actions.confirmArchive'
              )}
              variant="tertiary"
              color="red"
              loading={updatingStatus}
              disabled={updatingStatus}>
              {t('documentTags.drawer.actions.archive')}
            </ConfirmationButton>
          )}
        </Grid>
      }>
      {documentTagsSecondIterationFF && documentTagId && !isActive && (
        <ReActivateRow
          onRestore={onActivate}
          isSubmitting={updatingStatus}
          disabled={updatingStatus}
        />
      )}
      <DocumentTagDetailsForm
        form={form}
        formId={CREATE_DOCUMENT_TAG_FORM_ID}
        onSubmit={onSubmit}
      />
      <Grid top="space64" gap="space8" justifyItems="end">
        <EducationalLink
          icon="playOutline"
          href={t('documentTags.drawer.links.videoTour.url')}
          label={t('documentTags.drawer.links.videoTour.label')}
        />
        <EducationalLink
          icon="arrowRight"
          href={t('documentTags.drawer.links.helpCenterArticle.url')}
          label={t('documentTags.drawer.links.helpCenterArticle.label')}
        />
      </Grid>
    </DrawerLayout>
  );
};
