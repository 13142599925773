import { FieldPolicy, TypePolicies } from '@apollo/client';
import { GetEcmDocumentsResponse } from 'generated-types/graphql.types';
import { ecmSimplePaginationMergeWithoutDuplicates } from './pagination-results-merge';

const ecmDocumentsPaginationFieldPolicy: FieldPolicy<GetEcmDocumentsResponse> =
  {
    merge: ecmSimplePaginationMergeWithoutDuplicates('GetEcmDocumentsResponse'),
    keyArgs: ['filter', 'search', 'sort'],
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      getEcmDocuments: ecmDocumentsPaginationFieldPolicy,
    },
  },
};
