import { Grid, Text } from '@candisio/design-system';
import { UserRole } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { Controller, useFormContext } from 'react-hook-form';
import { orderRoles } from 'views/Settings/TeamMembers/utils';
import { TeamMemberFormValues } from '../teamMemberFormSchemaDeprecated';
import { TeamMemberRoleCheckboxLegacyRoles } from './TeamMemberRoleCheckboxLegacyRoles';

const ROLES_OVERRIDDEN_BY_ADMIN = [UserRole.Approver, UserRole.Requester];

export const TeamMemberRolesFieldLegacyRoles = ({
  readOnly,
}: {
  readOnly?: boolean;
}) => {
  const creditCardsSetup = useCreditCardsSetup();

  const {
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = useFormContext<TeamMemberFormValues>();

  const { error } = getFieldState('roles');

  const { roles: userRoles } = useUserRoles();

  const orderedRoles = orderRoles(creditCardsSetup.isInUse);

  return (
    <Grid
      gap="space16"
      paddingLeft={error ? 'space8' : undefined}
      borderLeft={error ? '4px solid red500' : undefined}>
      {error && <Text color="red500">{error.message}</Text>}
      <Controller<TeamMemberFormValues, 'roles'>
        name="roles"
        render={({ field: { onChange, value } }) => {
          return (
            <>
              {orderedRoles.map(role => (
                <TeamMemberRoleCheckboxLegacyRoles
                  readOnly={readOnly}
                  currentUserRoles={userRoles}
                  onChange={selectedRoles => {
                    if (selectedRoles.includes(UserRole.Admin)) {
                      // Internally Admin role takes precedence over Requester and Approver. Because our BE does not strip them out yet, we remove them here.
                      onChange(
                        selectedRoles.filter(
                          role => !ROLES_OVERRIDDEN_BY_ADMIN.includes(role)
                        )
                      );

                      return;
                    }

                    onChange(selectedRoles);
                  }}
                  role={role}
                  selectedRoles={value}
                  key={role}
                />
              ))}
            </>
          );
        }}></Controller>
    </Grid>
  );
};
