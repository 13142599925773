import { useGetUserInvolvementForEcmDocumentsLazyQuery } from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';

interface UseEcmDocumentsInvolvementCountOptions {
  membershipId: string;
}

export const useEcmDocumentsInvolvementCount = ({
  membershipId,
}: UseEcmDocumentsInvolvementCountOptions) => {
  const [
    fetchEcmDocumentsInvolvement,
    { data, loading: ecmDocumentsInvolvementLoading },
  ] = useGetUserInvolvementForEcmDocumentsLazyQuery({
    variables: { membershipId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
  });

  useFetchOnWindowFocus({
    fetch: fetchEcmDocumentsInvolvement,
  });

  const ecmDocumentsInvolvementData = data?.getUserInvolvementForEcmDocuments;

  const responsiblePersonCount =
    ecmDocumentsInvolvementData?.responsiblePerson.count ?? 0;

  const notifyPersonCount =
    ecmDocumentsInvolvementData?.notifyPerson.count ?? 0;

  const hasEcmInvolvement = responsiblePersonCount > 0 || notifyPersonCount > 0;

  return {
    hasEcmInvolvement,
    notifyPersonCount,
    responsiblePersonCount,
    ecmDocumentsInvolvementLoading,
  };
};
