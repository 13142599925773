import {
  CardIssuerOnboardingStatus,
  Maybe,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import {
  CreditCardsTrackingEvents,
  useAnalytics,
} from 'providers/AnalyticsProvider';
import { CreditCardAdoptedInfoBanner } from 'views/Dashboard/components/CreditCardAdoptedInfoBanner';
import { CreditCardNotAdoptedInfoBanner } from 'views/Dashboard/components/CreditCardNotAdoptedInfoBanner';
import { ActiveCreditCardRequestBanner } from './Requests/ActiveCreditCardRequestBanner';
import { ActiveCreditCardRequestCardPliant } from './Requests/ActivePliant';
import { PendingCreditCardRequestBanner } from './Requests/PendingCreditCardRequestBanner';

type GetLandingVariantProps = {
  userName: string;
  status?: Maybe<CardIssuerOnboardingStatus>;
};

export const creditCardInfoHash = '#info_about_cc';

export type RouteTypeProps =
  | 'Archive'
  | 'Inbox'
  | 'Dashboard'
  | 'Settings'
  | 'Export';

export const useButtonEventTracker = () => {
  const { track } = useAnalytics();

  const CCAdoptedEventTracker: Record<RouteTypeProps, () => void> = {
    Dashboard: () =>
      CreditCardsTrackingEvents.CC_ADVERT_CARD_APPLY_BTN_CLICKED(
        track,
        'dashboard'
      ),
    Inbox: () =>
      CreditCardsTrackingEvents.CC_ADVERT_CARD_APPLY_BTN_CLICKED(
        track,
        'inbox'
      ),
    Archive: () =>
      CreditCardsTrackingEvents.CC_ADVERT_CARD_APPLY_BTN_CLICKED(
        track,
        'archive'
      ),
    Settings: () =>
      CreditCardsTrackingEvents.CC_ADVERT_CARD_APPLY_BTN_CLICKED(
        track,
        'settings'
      ),
    Export: () =>
      CreditCardsTrackingEvents.CC_ADVERT_CARD_APPLY_BTN_CLICKED(
        track,
        'exports'
      ),
  };

  const CCNotAdoptedEventTracker: Record<
    RouteTypeProps,
    { primaryBtn: () => void; secondaryBtn: () => void }
  > = {
    Dashboard: {
      primaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_MORE_INFO_BTN_CLICKED(
          track,
          'dashboard'
        ),
      secondaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_BOOK_A_DEMO_BTN_CLICKED(
          track,
          'dashboard'
        ),
    },

    Inbox: {
      primaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_MORE_INFO_BTN_CLICKED(
          track,
          'inbox'
        ),
      secondaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_BOOK_A_DEMO_BTN_CLICKED(
          track,
          'inbox'
        ),
    },

    Archive: {
      primaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_MORE_INFO_BTN_CLICKED(
          track,
          'archive'
        ),
      secondaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_BOOK_A_DEMO_BTN_CLICKED(
          track,
          'archive'
        ),
    },

    Settings: {
      primaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_MORE_INFO_BTN_CLICKED(
          track,
          'settings'
        ),
      secondaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_BOOK_A_DEMO_BTN_CLICKED(
          track,
          'settings'
        ),
    },

    Export: {
      primaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_MORE_INFO_BTN_CLICKED(
          track,
          'exports'
        ),
      secondaryBtn: () =>
        CreditCardsTrackingEvents.CC_ADVERT_CARD_BOOK_A_DEMO_BTN_CLICKED(
          track,
          'exports'
        ),
    },
  };

  return { CCAdoptedEventTracker, CCNotAdoptedEventTracker };
};

export const getVariantMap = ({
  userName,
  isWalletEnabled,
  isAdmin,
}: {
  userName: string;
  isWalletEnabled?: boolean;
  isAdmin: boolean;
}) => {
  return {
    [CardIssuerOnboardingStatus.Pending]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.Active]: isWalletEnabled ? (
      <ActiveCreditCardRequestBanner showTitle />
    ) : (
      <ActiveCreditCardRequestCardPliant userName={userName} />
    ),
    [CardIssuerOnboardingStatus.OnboardingEmailFailed]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingEmailSent]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingDetails]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingRisk]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingSddMandate]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingTerms]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.OnboardingWait]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.Cancelled]: (
      <PendingCreditCardRequestBanner showTitle />
    ),
    [CardIssuerOnboardingStatus.Inactive]: isAdmin ? (
      <CreditCardAdoptedInfoBanner routeType="Dashboard" showTitle />
    ) : (
      <CreditCardNotAdoptedInfoBanner routeType="Dashboard" showTitle />
    ),
    unknown: isAdmin ? (
      <CreditCardAdoptedInfoBanner routeType="Dashboard" showTitle />
    ) : (
      <CreditCardNotAdoptedInfoBanner routeType="Dashboard" showTitle />
    ),
  };
};

export const useGetCardVariant = ({
  userName,
  status,
}: GetLandingVariantProps) => {
  const creditCardsSetup = useCreditCardsSetup();
  const { isAdmin } = useUserRoles();
  const variantMap = getVariantMap({
    userName,
    isWalletEnabled: creditCardsSetup.showAllExtraFeatures,
    isAdmin,
  });

  if (!creditCardsSetup.isAddonBought) {
    return <CreditCardNotAdoptedInfoBanner routeType="Dashboard" showTitle />;
  }

  return variantMap[status || 'unknown'];
};
