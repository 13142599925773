import { DocumentCurrency } from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { formatDecimalToIntegerAmount } from 'hooks/useMoneyFormatter';
import { BaseGeneralExpenseOutput } from '../toolkit/expensesFormSchema';

export const sanitizeGeneralExpenseFormOutput = (
  values: BaseGeneralExpenseOutput
) => ({
  fileIds: values.files.map(file => file.id),
  expenseDate: getDateConverted().dateStringToDateTimeString(
    values.expenseDate
  ),
  title: values.reason,
  totalAmount: {
    amount: formatDecimalToIntegerAmount(values.totalAmount),
    currency: DocumentCurrency.Eur,
    precision: 2,
  },
});
