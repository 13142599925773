import { BoxProps, Flex, Skeleton } from '@candisio/design-system';

export type HistoryLoadingProps = {
  itemCount?: number;
  props?: BoxProps;
};

const Item = () => (
  <Flex gap="space16">
    <Flex direction="column">
      <Skeleton height="15px" width="15px" />
      <Skeleton height="47px" width="2px" style={{ margin: '0 auto' }} />
    </Flex>
    <Flex direction="column" width="80%">
      <Skeleton height="15px" style={{ marginBottom: '5px' }} width="100%" />
      <Skeleton height="15px" style={{ marginBottom: '5px' }} width="80%" />
    </Flex>
  </Flex>
);

/**
 * Skeleton for history items loading
 */
export const HistoryLoading = ({
  props,
  itemCount = 3,
}: HistoryLoadingProps) => {
  return (
    <Flex direction="column" width="80%" paddingTop="space8" {...props}>
      {new Array(itemCount).fill('').map((_, i) => (
        <Item key={i} />
      ))}
      <Flex gap="space16">
        <Flex direction="column">
          <Skeleton height="15px" width="15px" />
        </Flex>
        <Flex direction="column" width="80%">
          <Skeleton
            height="15px"
            style={{ marginBottom: '5px' }}
            width="100%"
          />
          <Skeleton height="15px" style={{ marginBottom: '5px' }} width="80%" />
        </Flex>
      </Flex>
    </Flex>
  );
};
