import { mergeProps } from '@candisio/design-system';
import {
  HookFormTextField,
  HookFormTextFieldProps,
} from 'components/HookFormFields/HookFormTextField';
import { StorageFormValues } from './storageFormSchema';
import { useStorageFormField } from './useStorageFormField';

export const StorageFormTextField = ({
  name,
  ...restProps
}: HookFormTextFieldProps<StorageFormValues>) => {
  const { fieldProps } = useStorageFormField(name);

  return (
    <HookFormTextField name={name} {...mergeProps(fieldProps, restProps)} />
  );
};
