import gql from 'graphql-tag';

export const emailAccountsQuery = gql`
  query organizationsEmailAccounts {
    getOrganizations {
      id
      slug
      emailAccounts {
        records {
          id
          address
          isEnabled
        }
      }
    }
  }
`;

const emailFragment = gql`
  fragment Email on EmailInboxMessage {
    id
    status
    from
    subject
    createdAt
  }
`;

export const emailInboxMessageWithAttachments = gql`
  query EmailInboxMessageWithAttachments($id: ID!) {
    emailInboxMessage(id: $id) {
      ...Email
      htmlLink
      textLink
      sender
      recipient
      receivedAt
      history {
        id
        type
        createdAt
        events {
          id
          type
          data
        }
      }
      attachments {
        ... on EmailInboxMessageAttachmentWithDocument {
          documentFileName
          documentId
          url
        }
        ... on EmailInboxMessageAttachmentWithError {
          fileName
          fileUrl
          error {
            ... on AttachmentDocumentRemovedError {
              removedAt
              removedBy
              errorCode
            }
            ... on AttachmentDocumentNotFoundError {
              errorCode
            }
            ... on EmailMessageVirusDetected {
              errorCode
            }
            ... on UnprocessableAttachment {
              errorCode
            }
            ... on MaxAttachmentSizeExceeded {
              errorCode
            }
            ... on UnsupportedAttachmentType {
              errorCode
            }
            __typename
          }
        }
      }
    }
  }
  ${emailFragment}
`;

export const emailInboxMessage = gql`
  query EmailInboxMessage($id: ID!) {
    emailInboxMessage(id: $id) {
      ...Email
      htmlLink
      textLink
      sender
      recipient
      receivedAt
      history {
        id
        type
        createdAt
        events {
          id
          type
          data
        }
      }
    }
  }
  ${emailFragment}
`;

// query can be removed when refactorEmailTableFF is archived
export const emailInboxPaginationQuery = gql`
  query emailInboxPagination(
    $input: emailInboxPaginationInput
    $filters: emailInboxFilterInput
    $sortBy: emailInboxSortInput
  ) {
    emailInboxPagination(input: $input, filters: $filters, sortBy: $sortBy) {
      records {
        ...Email
        numberOfGeneratedDocuments
        numberOfErroneousAttachments
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${emailFragment}
`;

export const emailInboxInfiniteScrollPagination = gql`
  query emailInboxInfiniteScrollPagination(
    $input: emailInboxPaginationInput
    $filters: emailInboxFilterInput
    $sortBy: emailInboxSortInput
  ) {
    emailInboxPagination(input: $input, filters: $filters, sortBy: $sortBy)
      @connection(
        key: "emailInboxInfiniteScrollPagination"
        filter: ["filters", "sortBy"]
      ) {
      records {
        ...Email
        numberOfGeneratedDocuments
        numberOfErroneousAttachments
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${emailFragment}
`;
