import { Description } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { electronicFormatIBAN } from 'ibantools';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';

interface OrganizationOwnIbanWarningProps {
  iban?: string | null;
  name?: string;
}
export const useOrganizationOwnIbanWarning = ({
  iban,
  name,
}: OrganizationOwnIbanWarningProps): Description | undefined => {
  const organizationName = useFullOrganization()?.name;
  const { organizationOwnIBANs } = useOrganizationOwnIbansData();
  const [excludeOrgOwnIbanAndNameFromExtractionsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.excludeOrgOwnIbanAndNameFromExtractions,
  ]);

  const [t] = useTranslation();

  if (!iban || !excludeOrgOwnIbanAndNameFromExtractionsFF) return undefined;

  const ibanSanitized = electronicFormatIBAN(iban);
  const contactNameSanitized = name?.trim().toLowerCase();
  const organizationNameSanitized = organizationName?.trim().toLowerCase();

  const isSameName = contactNameSanitized === organizationNameSanitized;
  const isOrganizationOwnIban = organizationOwnIBANs.some(
    orgIban => electronicFormatIBAN(orgIban) === ibanSanitized
  );

  if (!isSameName && isOrganizationOwnIban) {
    return {
      text: t(
        'common:document.requestApproval.inputs.iban.warning.organizationOwnIBAN'
      ),
      color: 'warning',
    };
  }

  return undefined;
};
