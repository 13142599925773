import {
  Absence,
  useGetOrgMembersAbsenceQuery,
  useGetMembersQuery,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';

export const useGetUserAbsence = () => {
  const { data } = useGetOrgMembersAbsenceQuery({});
  const { data: members } = useGetMembersQuery();

  const getUserAbsence = useCallback(
    (userId?: string): Absence | undefined => {
      return data?.getOrgMembersAbsence.find(
        ({ membershipId }) => membershipId === userId
      );
    },
    [data?.getOrgMembersAbsence]
  );

  const getUsersSubstitute = useCallback(
    (substituteId?: string) => {
      if (!substituteId) return;

      return members?.organizationMemberships.find(
        teamMember => teamMember.id === substituteId
      );
    },
    [members?.organizationMemberships]
  );

  const getWhoUserIsSubstituteFor = useCallback(
    (userId?: string) => {
      if (!userId) return [];

      return (
        data?.getOrgMembersAbsence.flatMap(
          ({ substituteMembershipId, membershipId }) => {
            if (substituteMembershipId !== userId) return [];
            if (membershipId)
              return [
                {
                  id: membershipId,
                  name: members?.organizationMemberships.find(
                    teamMember => teamMember.id === membershipId
                  )?.name,
                },
              ];

            return [];
          }
        ) ?? []
      );
    },
    [data?.getOrgMembersAbsence, members?.organizationMemberships]
  );

  return { getUserAbsence, getUsersSubstitute, getWhoUserIsSubstituteFor };
};
