import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PurchaseOrdersTableData } from './types';

export const LineItemsCell = ({
  value,
}: {
  value?: PurchaseOrdersTableData['lineItems'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  if (!value) {
    return <Text>-</Text>;
  }

  const lineItemsArray: string[] | undefined = [];
  lineItemsArray.push(
    ...(value?.goodsLineItems?.map(({ quantity, description }) =>
      t('lineItems.goods', {
        quantity,
        description,
      })
    ) || [])
  );
  lineItemsArray.push(
    ...(value?.serviceLineItems?.map(({ description }) =>
      t('lineItems.service', {
        description,
      })
    ) || [])
  );

  const lineItemsText = lineItemsArray.join(', ');

  return <TruncatedText wordBreak="break-word">{lineItemsText}</TruncatedText>;
};
