import { useBookingAccountValidation } from 'views/Settings/BookingAccounts/components/BookingAccountDetails/useBookingAccountValidation';
import { z, ZodIssueCode } from 'zod';

export const useBookingAccountSchema = (
  bookingAccountCodeToBeUpdated?: string
) => {
  const { nameValidator, accountNumberValidator } = useBookingAccountValidation(
    bookingAccountCodeToBeUpdated
  );

  const formSchema = z.object({
    accountCode: z.string(),
    name: z.string(),
  });

  const schema = formSchema.superRefine(async ({ accountCode, name }, ctx) => {
    const nameError = nameValidator(name);

    if (nameError) {
      ctx.addIssue({
        path: ['name'],
        code: ZodIssueCode.custom,
        message: nameError,
      });
    }

    const accountCodeError = await accountNumberValidator(accountCode);

    if (accountCodeError) {
      ctx.addIssue({
        path: ['accountCode'],
        code: ZodIssueCode.custom,
        message: accountCodeError,
      });
    }
  });

  const errorMessages = {
    accountCode: {
      label: 'form.accountNumber.label',
    },
    name: { label: 'form.name.label' },
  };

  return {
    schema,
    errorMessages,
  };
};
