import {
  DatePicker,
  FieldContainer,
  FieldContainerProps,
  Input,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { isNil } from 'lodash';
import moment from 'moment';
import { ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'utils/useLocale';

export interface HookFormDatePickerFieldProps<TFormValues extends FieldValues>
  extends Omit<FieldContainerProps, 'onChange'> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Should field receive focus on mount? */
  autoFocus?: boolean;
  /** Is field disabled? */
  disabled?: boolean;
  /** Field label */
  label?: string;
  /** Maximum date that can be selected in ISO 8601 date format */
  maxValue?: string;
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Minimum date that can be selected in ISO 8601 date format */
  minValue?: string;
  /** Called when field value changes */
  onChange?: (newValue: string | null) => void;
  /** Is field read only? */
  readOnly?: boolean;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Whether to convert year to 20XX if inserted year is smaller */
  force2kYear?: boolean;
  clearable?: boolean;
}

/**
 * Controlled date-picker field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormDatePickerField = <TFormValues extends FieldValues>({
  autoFocus,
  control,
  disabled,
  force2kYear = true,
  id,
  label,
  maxValue,
  message,
  minValue,
  name,
  onChange: onChangeProp,
  readOnly: readOnlyProp,
  variant,
  isLoading,
  clearable = true,
  ...restProps
}: HookFormDatePickerFieldProps<TFormValues>) => {
  const [t] = useTranslation();
  const locale = useLocale();
  const { field, fieldState, formState } = useController({ control, name });
  const { onChange, value, ...fieldProps } = field;
  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;
  const readOnly = readOnlyProp || formState.isSubmitting;

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  return (
    <FieldContainer
      disabled={disabled}
      label={label}
      readOnly={readOnly}
      variant={hasError ? 'error' : variant}
      isLoading={isLoading}
      onBlur={() => {
        if (!force2kYear || isNil(value)) {
          return;
        }

        const valueYear = moment(value).year();
        if (valueYear < 100) {
          const newValue = moment(value)
            .add('years', 2000)
            .format(moment.HTML5_FMT.DATE);

          onChange(newValue);
        }
      }}
      {...(mergeProps(restProps, labelProps) as Omit<
        FieldContainerProps,
        'color'
      >)}>
      {readOnly && !value ? (
        // https://candis.atlassian.net/browse/TMI-953
        <Input
          value={undefined}
          placeholder="–"
          autoFocus={autoFocus}
          disabled={disabled}
          id={id}
          readOnly
          message={errorMessage ?? message}
          variant={hasError ? 'error' : variant}
          clearable={clearable}
          showMessageOnFocus={hasError || variant === 'error'}
          {...labelFieldProps}
        />
      ) : (
        <DatePicker
          autoFocus={autoFocus}
          calendarLabel={t('datePicker.calendar')}
          clearLabel={t('datePicker.clear')}
          disabled={disabled}
          id={id}
          locale={locale}
          onChange={newValue => {
            const transformedValue = newValue !== '' ? newValue : null;
            onChange(transformedValue);
            onChangeProp?.(transformedValue);
          }}
          maxValue={maxValue}
          minValue={minValue}
          nextMonthLabel={t('datePicker.nextMonth')}
          prevMonthLabel={t('datePicker.previousMonth')}
          readOnly={readOnly}
          todayLabel={t('datePicker.today')}
          value={value ?? ''}
          message={errorMessage ?? message}
          variant={hasError ? 'error' : variant}
          clearable={clearable}
          showMessageOnFocus={hasError || variant === 'error'}
          {...mergeProps(fieldProps, labelFieldProps)}
        />
      )}
    </FieldContainer>
  );
};
