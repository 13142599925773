import { parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (
  phoneNumber: string | null
): string | null => {
  if (!phoneNumber) {
    return null;
  }

  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

    if (!parsedPhoneNumber?.isValid()) {
      return phoneNumber;
    }

    return parsedPhoneNumber.formatInternational();
  } catch (_) {
    // `parsePhoneNumber` can throw an error if the phone number is too short/incomplete
    return phoneNumber;
  }
};

export const obfuscatePhoneNumber = (
  phoneNumber: string | null
): string | null => {
  if (!phoneNumber) return null;

  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    if (!parsedPhoneNumber?.isValid()) {
      return phoneNumber;
    }

    const numOfLastVisibleCharacters = 4;
    const countryCode = `+${parsedPhoneNumber.countryCallingCode}`;
    const nationalNumber = parsedPhoneNumber.formatNational();

    let numbersCharactersCount = 0;
    const obfuscatedNationalNumber = nationalNumber
      .split('')
      .toReversed()
      .map(char => {
        const charIsNotANumber = !/[0-9]/.test(char);
        if (charIsNotANumber) return char;

        if (numbersCharactersCount < numOfLastVisibleCharacters) {
          // if its one of last characters, do not obfuscate it
          numbersCharactersCount++;

          return char;
        }

        numbersCharactersCount++;

        return '•';
      })
      .toReversed()
      .join('');

    return `${countryCode} ${obfuscatedNationalNumber}`;
  } catch (_) {
    return phoneNumber;
  }
};
