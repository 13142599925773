import { noop } from 'lodash';
import { createContext, Dispatch, useContext, useReducer } from 'react';

type Action =
  | 'showModal'
  | 'hideModal'
  | 'showSensitiveModal'
  | 'hideSensitiveModal';

interface DocumentUploadContextProps {
  modalVisibility: boolean;
  isSensitive: boolean;
}

export const DocumentUploadContext = createContext<DocumentUploadContextProps>({
  modalVisibility: false,
  isSensitive: false,
});

export const DocumentUploadDispatchContext =
  createContext<Dispatch<Action>>(noop);

const reducer = (state: DocumentUploadContextProps, action: Action) => {
  switch (action) {
    case 'showModal': {
      return { ...state, modalVisibility: true };
    }

    case 'hideModal': {
      return { ...state, modalVisibility: false };
    }

    case 'showSensitiveModal': {
      return { ...state, modalVisibility: true, isSensitive: true };
    }

    case 'hideSensitiveModal': {
      return { ...state, modalVisibility: false, isSensitive: false };
    }

    default: {
      return state;
    }
  }
};

export const DocumentUploadContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialState: DocumentUploadContextProps = {
    modalVisibility: false,
    isSensitive: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DocumentUploadContext.Provider value={state}>
      <DocumentUploadDispatchContext.Provider value={dispatch}>
        {children}
      </DocumentUploadDispatchContext.Provider>
    </DocumentUploadContext.Provider>
  );
};

export const useDocumentUploadState = () => useContext(DocumentUploadContext);
export const useDocumentUploadDispatch = () =>
  useContext(DocumentUploadDispatchContext);
