import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  Grid,
  useTooltip,
} from '@candisio/design-system';
import { PercentageProgressBar } from 'components/PercentageProgressBar/PercentageProgressBar';
import { determineColorBar } from 'containers/credit-cards/CreditCardCarousel/components/AvailableBalance/utils';
import { LinkedCardTransactionState } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface TransactionStateProps {
  txState: LinkedCardTransactionState;
  percentage?: number;
  availableBalance?: string | null;
  limit?: string | null;
}

export const FailedTransaction = ({ txState }: TransactionStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const text: Partial<Record<LinkedCardTransactionState, string>> = {
    CARD_CHECK_FAILED: t('recurringPaymentList.CARD_CHECK_FAILED'),
    PURCHASE_FAILED: t('recurringPaymentList.PURCHASE_FAILED'),
  };

  return (
    <>
      <Icon icon="failCircle" size="space14" color="red500" />
      <Text>{text[txState]}</Text>
    </>
  );
};

export const SuccessTransaction = ({
  txState,
  percentage,
  availableBalance,
  limit,
}: TransactionStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ delay: 500 });

  const transactionStateWithProgressBar = [
    LinkedCardTransactionState.CannotCompute,
    LinkedCardTransactionState.PurchaseSucceeded,
  ];

  return (
    <Flex alignItems="center">
      {txState === LinkedCardTransactionState.CardCheckSucceeded && (
        <Flex gap="space8" alignItems="center">
          <Icon icon="checkCircle" size="space14" color="green500" />
          <Text>{t('recurringPaymentList.CARD_CHECK_SUCCEEDED')}</Text>
        </Flex>
      )}
      {transactionStateWithProgressBar.includes(txState) && (
        <>
          <Box width="space80" {...triggerProps} ref={triggerRef}>
            <PercentageProgressBar
              percentage={percentage ?? 0}
              color={determineColorBar(percentage ?? 0)}
            />
          </Box>
          {isOpen && (
            <Tooltip {...tooltipProps} ref={tooltipRef}>
              <Grid gap="space4">
                {limit ? (
                  <Text>
                    {t('recurringPaymentList.limitsTooltip.cardLimit', {
                      limit,
                    })}
                  </Text>
                ) : null}
                {availableBalance ? (
                  <Text fontWeight="semibold">
                    {t('recurringPaymentList.limitsTooltip.availableBalance', {
                      available: availableBalance,
                    })}
                  </Text>
                ) : null}
              </Grid>
            </Tooltip>
          )}
        </>
      )}
    </Flex>
  );
};
