import { useToastMessage } from 'components/Toast/useToastMessage';
import { useSetTeamSettingsForCreditCardsMutation } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom-v5-compat';
import { teamSettingsForCreditCardsQuery } from '../queries';
import { CreditCardSetupFormOutput } from '../utils/creditCardSetupFormSchema';
import { sanitizeLimitThresholds } from '../utils/sanitizeLimitThresholds';

export const useSetTeamSettingsForCreditCard = () => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const { id } = useParams<{ id: string }>();

  const [
    setTeamSettingsForCreditCardMutation,
    { loading: teamCCSettingsPending, client },
  ] = useSetTeamSettingsForCreditCardsMutation({
    onError: () => error(t('common:common.genericErrorMessage')),
    onCompleted: () => {
      client.cache.evict({ fieldName: 'teams' });
    },
  });

  const handleSetTeamCreditCardSettings = async (
    values: CreditCardSetupFormOutput
  ) => {
    if (!id) {
      return;
    }

    const { cards, limit, oneTimeCardLimit, isTeamAdminAllowedToManageCards } =
      values;

    const teamAdminCanManageCards = isTeamAdminAllowedToManageCards ?? false;

    const { data, errors } = await setTeamSettingsForCreditCardMutation({
      variables: {
        input: {
          teamId: id,
          teamAdminCanManageCards,
          linkedCardIds: cards ?? [],
          limitThresholds: sanitizeLimitThresholds({ limit, oneTimeCardLimit }),
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: teamSettingsForCreditCardsQuery,
          variables: { input: { teamId: id } },
        },
      ],
    });

    if (errors?.length) {
      error(t('settings:teams.createTeam.creditCardSetup.setupError'));
    }

    if (data?.setTeamSettingsForCreditCards) {
      success(t('settings:teams.createTeam.creditCardSetup.setupSuccess'));

      return true;
    }
  };

  return {
    teamCCSettingsPending,
    setTeamCreditCardSettings: handleSetTeamCreditCardSettings,
  };
};
