import { FullOrganizationContext } from 'providers/OrganizationProvider/FullOrganizationContext';
import { useContext } from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export const Helmet = () => {
  const [t] = useTranslation();
  const { currentOrganization } = useContext(FullOrganizationContext);

  const title = currentOrganization?.name
    ? `${currentOrganization?.name} - ${t('pageTitle')}`
    : t('pageTitle');

  return (
    <ReactHelmet>
      <title>{title}</title>
      <link rel="canonical" href={import.meta.env.PUBLIC_URL} />
    </ReactHelmet>
  );
};
