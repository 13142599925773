import { Description } from '@candisio/design-system';
import { ProcessingFormFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOrganizationOwnIbanWarning } from 'views/utils/useOrganizationOwnIbanWarning';

export const useProcessingFormIbanWarning = (
  fieldOptions: ProcessingFormFieldOptions | undefined
): Description | undefined => {
  const [t] = useTranslation();

  const [iban, contact] = useWatch<ProcessingFormValues, ['iban', 'contact']>({
    name: ['iban', 'contact'],
  });

  const contactNameSanitized = contact.inputValue
    ? contact.inputValue.trim().toLowerCase()
    : '';

  const processingFormIbanSanitized = electronicFormatIBAN(iban ?? '');

  const organizationOwnIbanWarning = useOrganizationOwnIbanWarning({
    iban,
    name: contactNameSanitized,
  });

  if (organizationOwnIbanWarning) {
    return organizationOwnIbanWarning;
  }

  const contactFormIban = contact.value
    ? fieldOptions?.contact?.items?.find(item => item.key === contact.value)
        ?.iban
    : undefined;

  if (!contactFormIban || !isValidIBAN(processingFormIbanSanitized ?? ''))
    return undefined;

  // Compare sanitized versions of the IBANs
  const contactFormIbanSanitized = electronicFormatIBAN(contactFormIban);
  const isSameIban = contactFormIbanSanitized === processingFormIbanSanitized;

  if (!isSameIban) {
    return {
      text: t(
        'common:document.requestApproval.inputs.iban.warning.doesNotMatchWithContactsIBAN'
      ),
      color: 'warning',
    };
  }

  return undefined;
};
