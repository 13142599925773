import { useRejectCardRequestMutation } from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';

export interface UseDeclineRequestParams {
  cardId: string;
}

export const useDeclineRequest = ({ cardId }: UseDeclineRequestParams) => {
  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  const options = {
    awaitRefetchQueries: true,
    refetchQueries: [
      ...refetchQueries.cardIssuerCards(cardId),
      ...refetchQueries.cardIssuerCardsCardManager(),
    ],
    onCompleted() {
      evictPaginationResults();
    },
  };

  const [rejectRequest, { loading }] = useRejectCardRequestMutation(options);

  return {
    decline: async (
      pendingRequestId: string,
      declineReason: string | undefined
    ) => {
      const { errors } = await rejectRequest({
        variables: {
          input: {
            cardRequestId: pendingRequestId,
            comment: declineReason ?? undefined,
          },
        },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    declining: loading,
  };
};
