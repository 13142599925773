import { z } from 'zod';

export const creditCardSetupFormSchema = z.object({
  limit: z.number().min(1).nullish(),
  oneTimeCardLimit: z.number().min(1).nullish(),
  cards: z.array(z.string()).nullish(),
  isTeamAdminAllowedToManageCards: z.boolean().optional(),
  isACardAssignedToTeam: z.boolean().optional(),
});

export type CreditCardSetupFormOutput = z.infer<
  typeof creditCardSetupFormSchema
>;

export type CreditCardSetupFormValues = Partial<CreditCardSetupFormOutput>;
