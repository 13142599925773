import { DocumentsTableData } from 'components/DocumentsTable/types';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useTrackConfigurations } from 'components/Table/Configurations/hooks/useTrackConfigurations';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TableType, useExportContext } from '../Context';
import { defaultExportFilters } from '../toolkit/components/DocumentsTable';
import { useGetDefaultConfigurationsMappedToTableType } from './useGetDefaultConfigurationsMappedToTableType';

interface UseGetColumnsConfigurationsParams {
  tableId: string;
  tableType: TableType;
}

export const useGetColumnsConfigurations = ({
  tableId,
  tableType,
}: UseGetColumnsConfigurationsParams) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();
  const { filters, sortBy } = useUrlBasedSortAndFilter<DocumentsTableData>({
    availableFilters: defaultExportFilters,
  });

  const { handleTrackHiddenColumns } = useTrackConfigurations();

  const { includeAllContacts, includeAllPaymentConditions, table } =
    useExportContext();

  const { defaultColumnsConfigurations } =
    useGetDefaultConfigurationsMappedToTableType({ tableType });

  const { configurations } = useGetTableConfigurations({
    tableId,
    defaultColumnsConfigurations,
  });

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
      handleTrackHiddenColumns(configurations, tableId);
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  useUpdateColumnsBE({
    tableId,
    defaultColumnsConfigurations,
  });

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  // For contacts configurations we need to look if includeAllContacts checkbox is checked.
  // If that is the case we want to partially hide the status configuration with isAvailable:false

  if (table === 'CONTACTS') {
    const updatedConfigurations = configurations.map(config => {
      if (config.id === 'status') {
        return {
          ...config,
          isAvailable: includeAllContacts ? false : true,
        };
      }

      return config;
    });

    return {
      configurationsTable: updatedConfigurations,
      handleUpdateConfigurations,
      handleResetTableConfigurations,
      isResetPending,
      isSavingConfigurations,
    };
  }

  if (table === 'PAYMENT_CONDITIONS') {
    const updatedConfigurations = configurations.map(config => {
      if (config.id === 'status') {
        return {
          ...config,
          isAvailable: includeAllPaymentConditions ? false : true,
        };
      }

      return config;
    });

    return {
      configurationsTable: updatedConfigurations,
      handleUpdateConfigurations,
      handleResetTableConfigurations,
      isResetPending,
      isSavingConfigurations,
    };
  }

  return {
    configurationsTable: configurations,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    isResetPending,
    isSavingConfigurations,
  };
};
