import { useApolloClient } from '@apollo/client';
import { Modal } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ContactDataFragment,
  StringField,
  useMergeContactsMutation,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { useContactRefetchQueries } from '../../queries/contacts';
import { contactFragment } from '../queries';
import { FormSubmitData, MergeContactsForm } from './MergeContactsForm';

type ConflictingContactProps = keyof Pick<
  ContactDataFragment,
  | 'accountsPayableNumber'
  | 'accountsReceivableNumber'
  | 'iban'
  | 'taxNumber'
  | 'vatId'
  | 'relationshipType'
>;

const isStringFieldValue = (
  contactProp?: number | string | Pick<StringField, 'value'> | null
): contactProp is Pick<StringField, 'value'> => {
  return (
    contactProp !== null &&
    typeof contactProp === 'object' &&
    'value' in contactProp
  );
};

const checkMergeConflict = (
  contacts: ContactDataFragment[],
  conflictingProps: ConflictingContactProps[] = [
    'iban',
    'vatId',
    'taxNumber',
    'accountsPayableNumber',
    'accountsReceivableNumber',
    'relationshipType',
  ]
): boolean => {
  const fields: Partial<Record<ConflictingContactProps, string | number>> = {};

  return contacts.some(contact =>
    conflictingProps.some(field => {
      const contactValue = contact[field];
      const value = isStringFieldValue(contactValue)
        ? contactValue.value
        : contactValue;

      if (value) {
        if (fields[field] && fields[field] !== value) {
          return true;
        } else if (!fields[field]) {
          fields[field] = value;

          return false;
        }
      }

      return false;
    })
  );
};

type Props = {
  isVisible: boolean;
  setIsVisible: (v: boolean) => void;
  contacts: ContactDataFragment[];
  resetSelectedContacts: () => void;
  setMergedContactId?: (id: string) => void;
};

export const MergeModal = ({
  setIsVisible,
  isVisible,
  contacts,
  resetSelectedContacts,
  setMergedContactId,
}: Props) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const client = useApolloClient();

  const { queries: contactRefetchQueries } = useContactRefetchQueries();

  const [mergeContacts, { loading }] = useMergeContactsMutation({
    refetchQueries: contactRefetchQueries,
    onCompleted(result) {
      if (result.mergeContacts?.id) {
        setMergedContactId?.(result.mergeContacts.id);
      }
    },
  });

  const hasMergeConflicts = checkMergeConflict(contacts);

  const onClose = () => {
    setIsVisible(false);
  };

  const onSubmit = async (formState: FormSubmitData) => {
    const { data } = await mergeContacts({
      variables: {
        input: {
          ids: contacts.map(contact => contact.id),
          targetContact: {
            id: formState.targetContactId,
            name: formState.targetContactName,
          },
        },
      },
    });

    const updatedContactId = data?.mergeContacts?.id;

    if (!updatedContactId) {
      error(t('settings.contacts.merge.errorMerge'));
    } else {
      // scroll to newly merged contact
      const targetContactRowElement = document.getElementById(updatedContactId);

      if (targetContactRowElement) {
        targetContactRowElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }

      // Replace old contact cache id with newly updated one
      // We have to do this because of cache id being id+name
      const oldContactInCache = contacts.find(c => c.id === updatedContactId);
      if (oldContactInCache) {
        const oldContactCacheId = client.cache.identify(oldContactInCache);
        client.cache.writeFragment({
          id: oldContactCacheId,
          data: data?.mergeContacts,
          fragment: contactFragment,
        });
      }

      // Remove deleted contacts from cache
      const contactIdsToEvict = contacts.filter(c => c.id !== updatedContactId);

      contactIdsToEvict.forEach(c => {
        const contactCacheId = client.cache.identify(c);
        client.cache.evict({ id: contactCacheId });
      });
    }

    success(t('settings.contacts.merge.successMerge'));
    resetSelectedContacts();
    setIsVisible(false);
  };

  return (
    <Modal
      width="max-content"
      closeLabel={t('common.close')}
      title={t('settings.contacts.merge.title')}
      isOpen={isVisible}
      onClose={onClose}>
      <MergeContactsForm
        submitting={loading}
        contacts={contacts}
        onSubmit={onSubmit}
        onClose={onClose}
        hasMergeConflicts={hasMergeConflicts}
      />
    </Modal>
  );
};
