import { PaginatedComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';

export const CostObject = ({
  readOnly,
  ...props
}: {
  onChange: (val: Key | null) => void;
} & PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  return (
    <HookFormPaginatedComboBoxField
      name="costObject"
      label={t('inputs.costObject.label')}
      {...props}
      placeholder={
        readOnly
          ? t('inputs.costObject.placeholder.notSet')
          : t('inputs.costObject.placeholder.enabled')
      }
      readOnly={readOnly}
    />
  );
};
