import { UpdateBookingAccountInput } from 'generated-types/resolvers-types';
import {
  BookingAccount,
  BookingAccountCommandResult,
  BookingAccountErrorType,
  CreateBookingAccountInput,
} from '../../../../generated-types/graphql.types';
import { BookingAccountFormData } from '../components/BookingAccountDetails/BookingAccountChange';
import { ParsedBookingAccount } from '../components/BookingAccountImport/utils/parseBookingAccountCSV';

export const sanitizeBookingAccountFormData = (
  formData: BookingAccountFormData
): Omit<
  CreateBookingAccountInput | UpdateBookingAccountInput,
  'accountNumber'
> => {
  const accountCode = formData.accountCode.trim();

  return {
    ...formData,
    accountCode,
  };
};

export const isCreated = (result?: BookingAccountCommandResult): boolean =>
  !!result?.__typename && result.__typename === 'BookingAccountSuccess';

export const isUpdated = (result?: BookingAccountCommandResult): boolean =>
  !!result?.__typename && result.__typename === 'BookingAccountSuccess';

export const isInvalid = (result: BookingAccountCommandResult): boolean =>
  !!result?.__typename &&
  result.__typename === 'BookingAccountError' &&
  result.kind === BookingAccountErrorType.Invalid;

export const isDuplicate = (result?: BookingAccountCommandResult): boolean =>
  !!result?.__typename &&
  result.__typename === 'BookingAccountError' &&
  result.kind === BookingAccountErrorType.Duplicate;

export const isNotFound = (result: BookingAccountCommandResult): boolean =>
  !!result?.__typename &&
  result.__typename === 'BookingAccountError' &&
  result.kind === BookingAccountErrorType.NotFound;

export const isForbidden = (result: BookingAccountCommandResult): boolean =>
  !!result?.__typename &&
  result.__typename === 'BookingAccountError' &&
  result.kind === BookingAccountErrorType.Forbidden;

export const toAccountCode = (a: Pick<BookingAccount, 'accountCode'>) =>
  a.accountCode;

export const contains = (accountCode: string) => (existingCode: string) =>
  accountCode === existingCode;

export const getPreviousAccounts = (
  allAccounts: ParsedBookingAccount[],
  index: number
) => allAccounts.slice(0, index);
