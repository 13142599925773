import { Text } from '@candisio/design-system';
import { TransactionStatusUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';

const translationKeys: Record<string, string> = {
  CONFIRMED: 'transactionDetailsCard.status.confirmed',
  PENDING: 'transactionDetailsCard.status.pending',
  DECLINED: 'transactionDetailsCard.status.declined',
  REVERSED: 'transactionDetailsCard.status.reversed',
};

export const StatusUpdated = ({
  properties,
}: TransactionStatusUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const newStatusTranslation = t(translationKeys[properties?.newStatus]);

  return (
    <Text>
      {t('documentHistoryCCEvents.transactionStatusUpdatedWithoutOldStatus', {
        newStatus: newStatusTranslation,
      })}
    </Text>
  );
};
