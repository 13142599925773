import {
  HospitalityExpenseItem,
  GeneralExpenseItem,
  useReimbursementItemsQuery,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import {
  GeneralExpense,
  useFormattedGeneralExpense,
} from './useFormattedGeneralExpense';
import {
  HospitalityExpense,
  useFormattedHospitalityExpense,
} from './useFormattedHospitalityExpense';

export type ReimbursementItem = HospitalityExpense | GeneralExpense;

export const useGetReimbursementExpenseItems = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();
  const toHospitalityExpenseItem = useFormattedHospitalityExpense();
  const toGeneralExpenseItem = useFormattedGeneralExpense();

  const { data, loading: loadingItems } = useReimbursementItemsQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    skip: !reimbursementId || !canUseReimbursement,
  });

  const fetchedItems = data?.reimbursementItems;

  const hospitalityExpenseItems: HospitalityExpense[] =
    fetchedItems
      ?.filter(
        (itm): itm is HospitalityExpenseItem =>
          itm.__typename === 'HospitalityExpenseItem'
      )
      .map(toHospitalityExpenseItem) ?? [];

  const generalExpenseItems: GeneralExpense[] =
    fetchedItems
      ?.filter(
        (itm): itm is GeneralExpenseItem =>
          itm.__typename === 'GeneralExpenseItem'
      )
      .map(toGeneralExpenseItem) ?? [];

  return {
    loadingItems,
    hospitalityExpenseItems,
    generalExpenseItems,
  };
};
