import { useApolloClient } from '@apollo/client';
import {
  Button,
  Card,
  Grid,
  Heading,
  Paragraph,
} from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  SapB1SettingsErrorCode,
  useCreateSapB1CredentialsMutation,
} from 'generated-types/graphql.types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { SendEmailModal } from '../../components/SendEmailModal';
import { refetchSapSettings, verifySapB1CredentialsQuery } from '../queries';
import { adminModalHash, sanitizeFormData } from '../utils';
import { SapConnectionForm } from './Form';
import { SapFormValues } from './Form/SapConnectionForm';
import { useModalConfig } from './utils';

export const SapConnectionConfiguration = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  const { success, error } = useToastMessage();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const client = useApolloClient();

  const [hasValidationCheckError, setValidationCheckError] =
    useState<boolean>(false);

  const [createSapB1Credentials] = useCreateSapB1CredentialsMutation({
    refetchQueries: refetchSapSettings,
  });

  const onCreateSapB1Credentials = async (sapB1Credentials: SapFormValues) => {
    setIsSubmitting(true);
    const query = await client.query({
      query: verifySapB1CredentialsQuery,
      variables: { sapB1Credentials: sanitizeFormData(sapB1Credentials) },
    });

    const hasError = !!query.data?.verifySapB1Credentials?.code;

    if (hasError) {
      setValidationCheckError(true);
    } else {
      setValidationCheckError(false);
      const { data } = await createSapB1Credentials({
        variables: { sapB1Credentials: sanitizeFormData(sapB1Credentials) },
      });

      if (!data?.createSapB1Credentials) {
        success(t('settings.form.submission.success'));
      } else if (
        data?.createSapB1Credentials.code ===
        SapB1SettingsErrorCode.UnexpectedError
      ) {
        error(t('settings.form.submission.errors.unexpected'));
      } else if (
        data?.createSapB1Credentials.code ===
        SapB1SettingsErrorCode.InvalidLoginCredentials
      ) {
        error(t('settings.form.submission.errors.invalidLogin'));
      }
    }

    setIsSubmitting(false);
  };

  const onCloseModal = () => history.replace({ hash: '' });
  const onOpenModal = () => history.replace({ hash: adminModalHash });
  const isOpen = window.location.hash === adminModalHash;

  const modalConfig = useModalConfig();

  return (
    <Card width="50rem">
      <Grid gap="space16">
        <Grid templateColumns="6fr 2fr" gap="space64">
          <Grid gap="space16">
            <Heading as="h3">{t('settings.form.title')}</Heading>
            <Paragraph>{t('settings.form.details')}</Paragraph>
            <Button
              variant="secondary"
              size="small"
              width="fit-content"
              onClick={onOpenModal}>
              {t('settings.form.adminCTAButton')}
            </Button>
            <SapConnectionForm
              onSubmit={onCreateSapB1Credentials}
              isSubmitting={isSubmitting}
              hasValidationCheckError={hasValidationCheckError}
            />
          </Grid>
        </Grid>
      </Grid>
      <SendEmailModal
        isOpen={isOpen}
        onCancel={onCloseModal}
        {...modalConfig}
      />
    </Card>
  );
};
