import { UpdateCardIssuerOrganizationSettingsInput } from 'generated-types/graphql.types';
import { OrganizationSettingsFormValues } from './organizationSettingsFormSchema';

export const sanitiseOrganizationSettings = (
  formData: OrganizationSettingsFormValues
): UpdateCardIssuerOrganizationSettingsInput | null => {
  if (formData.deliveryAddressSameAsBilling) {
    return {
      deliveryAddress: undefined,
      billingEmail: formData.billingEmailAddress,
      deliveryAddressSameAsBillingAddress: true,
      tradeName: formData.tradeName,
      vatId: formData.vatId,
    };
  }

  if (
    !formData.deliveryAddressCity ||
    !formData.deliveryAddressCountry ||
    !formData.deliveryAddressZipcode ||
    !formData.deliveryAddressNumber ||
    !formData.deliveryAddressStreet
  ) {
    return null;
  }

  return {
    deliveryAddress: {
      city: formData.deliveryAddressCity,
      country: formData.deliveryAddressCountry,
      postalCode: formData.deliveryAddressZipcode,
      streetName: formData.deliveryAddressStreet,
      streetNumber: formData.deliveryAddressNumber,
      streetAdditional: formData.deliveryAddressAdditionalInfo,
    },
    billingEmail: formData.billingEmailAddress,
    deliveryAddressSameAsBillingAddress: false,
    tradeName: formData.tradeName,
    vatId: formData.vatId,
  };
};
