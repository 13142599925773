import { Item, SelectField } from '@candisio/design-system';
import moment from 'moment/moment';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useLocation } from 'react-router-dom';
import { ExportContext } from '../../Context';
import { ExportType, TimeFrameOption } from '../../types';
import { getSelectedTimeFrameIndexFromQuery } from '../utils';
import { serializeTimeFrame } from '../utils/timeframe';

const validExportTypesForTimeSelection = [
  ExportType.DATEV_CSV,
  ExportType.SAP_CSV,
  ExportType.CANDIS_CSV,
  ExportType.DATEV_BDS,
];

const getTimeFrameMonth = (month: number) => {
  const monthString = String(month).padStart(2, '0');

  return moment(monthString, 'MM').format('MMMM');
};

const getNumberOfDocumentsForTimeframe = (
  timeFrame: TimeFrameOption,
  exportType: ExportType | null
): number => {
  switch (exportType) {
    case ExportType.DATEV_CSV:
    case ExportType.DATEV_BDS:
      return timeFrame.numberOfExportableEntities;

    case ExportType.SAP_CSV:
    case ExportType.CANDIS_CSV:
      return timeFrame.numberOfDocuments + timeFrame.numberOfTxnBasedDocuments;
  }

  return 0;
};

export const InitExportAvailableMonths = ({
  exportType,
  timeFrames,
}: {
  exportType: ExportType | null;
  timeFrames: TimeFrameOption[];
}) => {
  const [t] = useTranslation();
  const locationParams = useLocation();
  const { setTimeframe } = useContext(ExportContext);

  const timeFrameIndexFromQuery = getSelectedTimeFrameIndexFromQuery(
    timeFrames,
    qs.parse(locationParams.search)
  );

  const selectedKey =
    timeFrameIndexFromQuery === -1 ? 0 : timeFrameIndexFromQuery;

  const setSelectedOption = (index: number) => {
    const selectedTimeFrame = timeFrames[index];
    const timeFrameParam = serializeTimeFrame({
      year: selectedTimeFrame.year,
      month: selectedTimeFrame.month,
    });

    setTimeframe(timeFrameParam);
  };

  useEffect(() => {
    if (timeFrameIndexFromQuery === -1 && timeFrames.length) {
      const selectedTimeFrame = timeFrames[0];
      if (selectedTimeFrame) {
        const timeFrameParam = serializeTimeFrame({
          year: selectedTimeFrame.year,
          month: selectedTimeFrame.month,
        });

        setTimeframe(timeFrameParam);
      }
    }
  }, [setTimeframe, timeFrameIndexFromQuery, timeFrames]);

  if (exportType && !validExportTypesForTimeSelection.includes(exportType)) {
    return null;
  }

  const selectOptions = timeFrames.map((timeFrame, index) => {
    const numberOfExportableEntities = getNumberOfDocumentsForTimeframe(
      timeFrame,
      exportType
    );

    return (
      <Item key={index}>
        {t('export.exportForm.exportDatevFormat.timeFrameOption', {
          month: getTimeFrameMonth(timeFrame.month + 1),
          year: timeFrame.year,
          numberOfExportableEntities,
        })}
      </Item>
    );
  });

  return (
    <SelectField
      label={t('export.exportForm.exportDatevFormat.available_months')}
      select={{
        selectedKey: String(selectedKey),
        onSelectionChange: key => {
          const numberKey = Number(key);
          setSelectedOption(numberKey);
        },
        children: selectOptions,
      }}
    />
  );
};
