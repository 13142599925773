import { Card } from '@candisio/design-system';
import { ReactNode } from 'react';
import { getThreeWayMatchGeneralState } from 'utils/three-way-match';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import ThreeWayMatchIndicator from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchIndicator';

interface ThreeWayMatchIndicatorProps {
  children: ReactNode;
}

export default function ThreeWayMatchWrapper({
  children,
}: ThreeWayMatchIndicatorProps) {
  const result = useThreeWayMatchContext();

  const backgroundColor = {
    default: 'gray250',
    success: 'green100',
    failure: 'yellowbg',
  };

  return (
    <Card
      data-cy="three-way-match-wrapper"
      padding="space4"
      background={backgroundColor[getThreeWayMatchGeneralState(result)]}
      borderRadius="basic">
      <ThreeWayMatchIndicator />
      {children}
    </Card>
  );
}
