import { CardType } from 'generated-types/graphql.types';
import visaGrey from './media/visa-black.svg';
import visaWhite from './media/visa-light.svg';

const physicalPliantCard = 'rgba(3, 29, 62, 1)';
const physicalOpacBackground = 'rgba(3, 29, 62, 0.5)';

const whitePliantCardVirtual = 'rgb(255,255,255)';
const whitePpacVirtualBackground = 'rgba(255,255,255, 0.6)';

const blackPliantCard = 'black';
const blackOpacBackground = 'rgba(0,0,0, 0.6)';

export const defaultBackgroundCandis = 'rgba(43, 26, 46, 1)';
export const opacBackgroundCandis = 'rgba(43, 26, 46, 0.6)';

export const cardVariantPliant = {
  [CardType.Black]: {
    background: blackPliantCard,
    opacBackground: blackOpacBackground,
    color: whitePliantCardVirtual,
    border: undefined,
    visaLogo: visaWhite,
  },
  [CardType.Physical]: {
    background: physicalPliantCard,
    opacBackground: physicalOpacBackground,
    color: whitePliantCardVirtual,
    border: undefined,
    visaLogo: visaWhite,
  },
  [CardType.SingleUse]: {
    background: whitePliantCardVirtual,
    opacBackground: whitePpacVirtualBackground,
    color: 'gray800',
    border: '1px solid gray200',
    visaLogo: visaGrey,
  },
  [CardType.Virtual]: {
    background: whitePliantCardVirtual,
    opacBackground: whitePpacVirtualBackground,
    color: 'gray800',
    visaLogo: visaGrey,
    border: '1px solid gray200',
  },
};

const transition = { duration: 0.6, ease: 'easeInOut' };

export const variants = {
  visible: { y: 0, opacity: 1, transition },
  hidden: { y: 0, opacity: 0, transition },
};
