import { useTranslation } from 'react-i18next';
import { ExportSummaryTitle } from 'views/Integrations/Export/toolkit/components/ExportSummaryTitle';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { DocumentType } from 'views/Integrations/Export/types';

export const CoreDataSummary = ({
  readyToExportEntitiesCount,
}: {
  readyToExportEntitiesCount: Record<DocumentType, number>;
}) => {
  const [t] = useTranslation();

  const coreDataCount = {
    paymentConditions: {
      count: readyToExportEntitiesCount.PAYMENT_CONDITIONS ?? 0,
    },
    contacts: {
      count: readyToExportEntitiesCount.CONTACTS ?? 0,
    },
  };

  const totalPaymentConditionsCount =
    coreDataCount.paymentConditions.count ?? 0;

  const totalContactsCount = coreDataCount.contacts.count ?? 0;

  const showPaymentConditionAndContactSummary =
    totalPaymentConditionsCount > 0 || totalContactsCount > 0;

  const contactSummary = [
    {
      title: (
        <ExportSummaryTitle
          title={t('export.exportMasterDataSummary.contactsCount', {
            count: totalContactsCount,
          })}
          tooltip={t('export.exportSummary.tooltips.contactsTooltip')}
        />
      ),
      content: '',
    },
  ];

  const paymentConditionsSummary = [
    {
      title: t('export.exportMasterDataSummary.paymentConditionsCount', {
        count: totalPaymentConditionsCount,
      }),
      content: '',
    },
  ];

  if (!showPaymentConditionAndContactSummary) return null;

  return (
    <>
      {totalContactsCount > 0 && (
        <SummaryAccordionCard items={contactSummary} collapsable={false} />
      )}
      {totalPaymentConditionsCount > 0 && (
        <SummaryAccordionCard
          items={paymentConditionsSummary}
          collapsable={false}
        />
      )}
    </>
  );
};
