import { Heading } from '@candisio/design-system';

import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  Locale,
  useCancelInvitationMutation,
  useResendInvitationMutation,
} from 'generated-types/graphql.types';
import { useSSO } from 'orgConfig/sso/sso';
import { useTeams } from 'orgConfig/teams/useTeams';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { organizationMembershipCountByStatusQuery } from 'views/Settings/TeamMembers/queries';
import { isSuccess } from 'views/Settings/TeamMembers/responses';
import { useGetTeams } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { useUpdateMembership } from '../../../useUpdateMembership';
import { FormData } from '../types';
import { PendingTeamMemberFormLegacyRoles } from './PendingTeamMemberFormLegacyRoles';
import { PendingTeamMemberFormLegacyRolesFooter } from './PendingTeamMemberFormLegacyRolesFooter';

type Props = {
  closeDrawer: () => void;
  loading?: boolean;
  organizationLanguage: Locale | null;
  selectedUser: User;
};

const PENDING_TEAM_MEMBER_FORM_ID = 'pending-team-member';

export const PendingFormLegacyRolesContainer = ({
  closeDrawer,
  loading,
  selectedUser,
}: Props) => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();
  const { isTeamsFeatureEnabled } = useTeams();

  const sso = useSSO();

  const {
    updateMembership,
    mutationProps: { loading: isSubmittingUpdateMembership },
  } = useUpdateMembership();

  const [resendInvitation, { loading: isSubmittingResendInvite }] =
    useResendInvitationMutation();

  const [cancelInvitation, { loading: isSubmittingCancelInvite }] =
    useCancelInvitationMutation();

  const { teams, loading: isLoadingTeams } = useGetTeams({
    membershipIds: [selectedUser.id],
  });

  const onSubmit = useCallback(
    async (values: FormData) => {
      try {
        const updateMembershipResult = await updateMembership(selectedUser.id, {
          firstName: values.firstName,
          lastName: values.lastName,
          roles: values.roles,
          locale: values.locale,
        });

        if (isSuccess(updateMembershipResult)) {
          // update success
          success(t('settings.teamMembers.actions.userUpdated'));

          closeDrawer();
        }
      } catch (e) {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        error(t(`${(e as Error).message}`));

        return e;
      }

      return;
    },
    [closeDrawer, error, selectedUser.id, success, t, updateMembership]
  );

  const resendInvite = useCallback(async () => {
    try {
      if (!selectedUser) {
        return;
      }

      await resendInvitation({
        variables: { membershipId: selectedUser.id },
        refetchQueries: ['organizationMemberships'],
      });

      info(t('settings.teamMembers.actions.resendSuccess'));
    } catch (e) {
      // eslint-disable-next-line candis/no-template-strings-inside-translation
      error(t(`${(e as Error).message}`));
    }

    closeDrawer();
  }, [closeDrawer, selectedUser, resendInvitation, info, t, error]);

  const cancelInvite = useCallback(async () => {
    try {
      if (!selectedUser) {
        return;
      }

      await cancelInvitation({
        variables: { id: selectedUser.id },
        refetchQueries: [
          'organizationMemberships',
          { query: organizationMembershipCountByStatusQuery },
        ],
      });
      success(t('settings.teamMembers.actions.cancelInviteSuccess'));
    } catch (e) {
      // eslint-disable-next-line candis/no-template-strings-inside-translation
      error(t(`${(e as Error).message}`));
    }

    closeDrawer();
  }, [cancelInvitation, closeDrawer, error, selectedUser, success, t]);

  const [initialValues] = useState({
    firstName: selectedUser.firstName,
    lastName: selectedUser.lastName,
    locale: selectedUser.locale ?? Locale.De,
    email: selectedUser.email,
    roles: selectedUser.roles ?? [],
  });

  const isFormLoading = loading || isLoadingTeams;

  return (
    <DrawerLayout
      onClose={closeDrawer}
      header={
        <Heading as="h3">
          {isTeamsFeatureEnabled
            ? t('settings.users.details.tabs.edit')
            : t('settings.teamMembers.details.tabs.edit')}
        </Heading>
      }
      footer={
        <PendingTeamMemberFormLegacyRolesFooter
          formId={PENDING_TEAM_MEMBER_FORM_ID}
          loading={isSubmittingUpdateMembership}
          disabled={
            isFormLoading ||
            isSubmittingCancelInvite ||
            isSubmittingResendInvite ||
            isSubmittingUpdateMembership
          }
        />
      }>
      <PendingTeamMemberFormLegacyRoles
        initialValues={initialValues}
        isSubmittingCancelInvite={isSubmittingCancelInvite}
        isSubmittingResendInvite={isSubmittingResendInvite}
        isSubmittingUpdateUser={isSubmittingUpdateMembership}
        loading={isFormLoading}
        onSubmit={onSubmit}
        resendInvite={resendInvite}
        cancelInvite={cancelInvite}
        selectedUser={selectedUser}
        formId={PENDING_TEAM_MEMBER_FORM_ID}
        hide={{
          roles: sso.provides.roles,
          firstName: sso.provides.firstName,
          lastName: sso.provides.lastName,
        }}
        teams={teams}
      />
    </DrawerLayout>
  );
};
