import { Flex } from '@candisio/design-system';
import { useNew_DatevSettingsQuery } from 'generated-types/graphql.types';
import { useDatevBookingAccountValidation } from 'views/Settings/Integrations/DATEV/useDatevBookingAccountValidation';
import { BookingAccountForm } from './BookingAccountForm/BookingAccountForm';
import { TransitAccountForm } from './TransitAccountForm';

export const CreditCardExportingForm = () => {
  const { data: datevCCData } = useNew_DatevSettingsQuery();
  const { bookingAccount, transitAccount } =
    datevCCData?.new_datevSettings?.creditCardsLedger || {};

  const { maxLength: accountLength } =
    useDatevBookingAccountValidation('bookingAccount');

  return (
    <Flex gap="space16" direction="row">
      <BookingAccountForm
        accountLength={accountLength}
        bookingAccount={bookingAccount ?? ''}
      />
      <TransitAccountForm
        accountLength={accountLength}
        transitAccount={transitAccount ?? ''}
      />
    </Flex>
  );
};
