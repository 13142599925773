import { memo, ReactNode, useCallback } from 'react';
import { FilterMenuItem } from './FilterMenuItem';

interface ItemOptionMemoizedProps {
  currentFilters: string[];
  setCurrentFilters: (filters: string[]) => void;
  label: string | ReactNode;
  id: string;
}

export const ItemOptionMemoized = memo(
  ({
    currentFilters,
    id,
    label,
    setCurrentFilters,
  }: ItemOptionMemoizedProps) => {
    const isSelected = currentFilters.includes(id);

    const onSelect = useCallback(() => {
      if (isSelected) {
        setCurrentFilters(
          currentFilters.filter(idSelected => idSelected !== id)
        );
      } else {
        setCurrentFilters([...currentFilters, id]);
      }
    }, [currentFilters, id, isSelected, setCurrentFilters]);

    return (
      <FilterMenuItem
        key={id}
        isSelected={isSelected}
        onSelect={onSelect}
        label={label}
      />
    );
  }
);
