import { CellProps, Grid, Tag, TagProps, Text } from '@candisio/design-system';
import { StatusIcon } from 'components/DocumentsTable/Cells/StatusCell';
import { EcmDocumentContractStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useEcmContractStatusOptions } from '../hooks/useEcmContractStatusOptions';
import { ContractStatus, EcmDocumentsTableData } from '../types';

const statuses: Record<EcmDocumentContractStatus, TagProps['color']> = {
  [EcmDocumentContractStatus.Active]: 'green',
  [EcmDocumentContractStatus.Inactive]: 'red',
};

type Value = ContractStatus | undefined;

/**
 * A cell that displays the status of an ECM contract.
 */
export const ContractStatusCell = ({
  value,
}: CellProps<EcmDocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { ecmContractStatusTranslationMap } = useEcmContractStatusOptions();

  if (!value?.status) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return (
    <Grid
      alignItems="center"
      autoFlow="column"
      justifyContent="start"
      gap="space4">
      <Tag color={statuses[value.status]} variant="secondary">
        {ecmContractStatusTranslationMap[value.status]}
      </Tag>
      {value.isSensitive && (
        <StatusIcon
          label={t('contractStatus.sensitive')}
          icon="lockFilled"
          color="gray500"
        />
      )}
      {value.isOverdue && (
        <StatusIcon
          label={t('contractStatus.overdue')}
          icon="bell"
          color="red500"
        />
      )}
    </Grid>
  );
};
