import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardUsageFormFormValues } from './CardUsageStep/cardUsageFormSchema';

interface CreateCreditCardTextFieldProps {
  name: keyof CardUsageFormFormValues;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  optionalHint?: string;
  suggestion?: string;
  onChange?: (newValue: string | null) => void;
  autoFocus?: boolean;
}

export const CreateCreditCardTextField = ({
  name,
  label,
  maxLength,
  optionalHint,
  placeholder,
  suggestion,
  onChange,
  autoFocus = false,
}: CreateCreditCardTextFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { formState, getFieldState } =
    useFormContext<CardUsageFormFormValues>();

  const isDirty = getFieldState(name, formState).isDirty;

  const variant = !isDirty && suggestion ? 'success' : 'default';

  const message =
    !isDirty && suggestion
      ? t('recurringPaymentsModal.createNewCard.suggestionMessage')
      : undefined;

  return (
    <HookFormTextField
      name={name}
      label={label}
      variant={variant}
      autoFocus={autoFocus}
      placeholder={placeholder}
      maxLength={maxLength}
      message={message}
      optionalHint={optionalHint}
      onChange={onChange}
    />
  );
};
