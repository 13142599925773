import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useVendorSuggestionsCounters } from 'views/Dashboard/hooks/useVendorSuggestionsCounters';
import { RecurringPayments } from './RecurringPayments';

export const RecurringPaymentsContainer = () => {
  const { loading, counters } = useVendorSuggestionsCounters();
  const creditCardsSetup = useCreditCardsSetup();

  const { convertedVendors, vendorsToConvert } = counters;

  return (
    <RecurringPayments
      isCreditCardsInUse={creditCardsSetup.isInUse}
      convertedVendors={convertedVendors}
      vendorsToConvert={vendorsToConvert}
      loading={loading}
    />
  );
};
