import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ChangeCardLimitInput,
  RequestCardLimitChangeInput,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { UpdateVirtualAndPhysicalCardLimitFormValues } from '../UpdateCardLabelAndLimit/UpdateVirtualAndPhysicalCardLimitFormContainer/updateVirtualAndPhysicalCardLimitFormSchema';
import {
  sanitizeVirtualAndPhysicalLimitInputsForCardManager,
  sanitizeVirtualAndPhysicalLimitInputsForCardholder,
} from '../UpdateCardLabelAndLimit/utils/utils';
import { useChangeCardLimit } from './useChangeCardLimit';
import { useRequestCardLimitChange } from './useRequestCardLimit';
import { useUpdateCardLabel } from './useUpdateCardLabel';

export const useUpdateCardLabelAndCardLimit = ({
  cardId,
  onClose,
  initialValues,
}: {
  cardId: string;
  onClose: () => void;

  initialValues: UpdateVirtualAndPhysicalCardLimitFormValues;
}) => {
  let resErrors: readonly GraphQLError[] | undefined;
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();
  const { isCardManager } = useUserRoles();

  const { updatingLabel, updateCardLabel } = useUpdateCardLabel();

  const { loadingRequest, requestLimitChange } = useRequestCardLimitChange();

  const { changingLimit, changeCardLimit } = useChangeCardLimit();

  const handleLimitChangeRequest = async (
    formData: UpdateVirtualAndPhysicalCardLimitFormValues
  ) => {
    const requestToSubmit: RequestCardLimitChangeInput = {
      cardId,
      ...sanitizeVirtualAndPhysicalLimitInputsForCardholder(formData),
    };

    const newCardLabel = formData?.cardLabel ?? '';

    const hasCardLabelChanged =
      (initialValues.cardLabel ?? '') !== newCardLabel;

    const hasOtherFormValuesChanged =
      requestToSubmit.requestedLimit.value !== initialValues.limit ||
      requestToSubmit.requestedTransactionLimit.value !==
        initialValues.transactionLimit ||
      requestToSubmit.requestedLimitRenewFrequency !==
        initialValues.limitInterval ||
      requestToSubmit.comment !== initialValues.comment;

    if (!hasCardLabelChanged && !hasOtherFormValuesChanged) {
      error(t('creditCardFormDataPreview.duplicateValue'));

      return;
    }

    if (hasCardLabelChanged) {
      const { errors, status } = await updateCardLabel({
        cardId,
        label: newCardLabel,
      });

      if (status === 'error') {
        resErrors = errors;
      } else {
        resErrors = undefined;
      }
    }

    if (hasOtherFormValuesChanged) {
      const { errors, status } = await requestLimitChange({
        ...requestToSubmit,
      });

      if (status === 'error') {
        resErrors = errors;
      } else {
        resErrors = undefined;
      }
    }

    if (resErrors && resErrors?.length > 0) {
      if (resErrors?.[0].message.includes('MFA')) {
        error(t('dashboard.drawer.form.failureMfa'));
      } else error(t('creditCardFormDataPreview.errorMsg'));
    } else {
      success(t('creditCardFormDataPreview.successMsg'));
      onClose();
    }
  };

  const handleLimitChange = async (
    formData: UpdateVirtualAndPhysicalCardLimitFormValues
  ) => {
    const changesToSubmit: ChangeCardLimitInput = {
      cardId,
      ...sanitizeVirtualAndPhysicalLimitInputsForCardManager(formData),
    };

    const newCardLabel = formData?.cardLabel ?? '';

    const hasCardLabelChanged =
      (initialValues.cardLabel ?? '') !== newCardLabel;

    const hasOtherFormValuesChanged =
      changesToSubmit.limit.value !== initialValues.limit ||
      changesToSubmit.transactionLimit.value !==
        initialValues.transactionLimit ||
      changesToSubmit.limitRenewFrequency !== initialValues.limitInterval;

    if (!hasCardLabelChanged && !hasOtherFormValuesChanged) {
      error(t('creditCardFormDataPreview.duplicateValue'));

      return;
    }

    if (hasCardLabelChanged) {
      const { errors, status } = await updateCardLabel({
        cardId,
        label: newCardLabel,
      });

      if (status === 'error') {
        resErrors = errors;
      } else {
        resErrors = undefined;
      }
    }

    if (hasOtherFormValuesChanged) {
      const { errors, status } = await changeCardLimit({
        ...changesToSubmit,
      });

      if (status === 'error') {
        resErrors = errors;
      } else {
        resErrors = undefined;
      }
    }

    if (resErrors && resErrors?.length > 0) {
      if (resErrors?.[0].message.includes('MFA')) {
        error(t('dashboard.drawer.form.failureMfa'));
      } else error(t('creditCardFormDataPreview.errorMsg'));
    } else {
      success(t('creditCardFormDataPreview.successMsg'));
      onClose();
    }
  };

  return {
    loading: updatingLabel || loadingRequest || changingLimit,
    handleSubmit: isCardManager ? handleLimitChange : handleLimitChangeRequest,
  };
};
