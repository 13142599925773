import { Flex, Image } from '@candisio/design-system';
import {
  ExportStatus,
  ExportType as GqlExportType,
  useExportCandisCsvMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDatev } from 'orgConfig/datev';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import { BdsSetupHintPanel } from 'views/Integrations/Export/toolkit/components/BdsSetupHint';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { InitExportAvailableMonths } from 'views/Integrations/Export/toolkit/components/InitExportAvailableMonths';
import { InitExportSummary } from 'views/Integrations/Export/toolkit/containers/ExportableEntitiesSummary/Init';
import { InitExportTypeLabel } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/InitExportTypeLabel';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { useGetReadyToExportCountByTimeframe } from 'views/Integrations/Export/toolkit/hooks/useGetReadyToExportCountByTimeframe';
import { useSelectedTimeFrame } from 'views/Integrations/Export/toolkit/hooks/useSelectedTimeFrame';
import candisCsvIcon from 'views/Integrations/Export/toolkit/images/icon-candis-csv.svg';
import { getRefetchExportsQueries } from 'views/Integrations/Export/toolkit/queries';
import { getDatevExportableEntities } from 'views/Integrations/Export/toolkit/utils';
import { getTimeFrameInputFromTimeFrameOption } from 'views/Integrations/Export/toolkit/utils/timeframe';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { CandisCsvExportButton } from './CandisCsvExportButton';

export const CandisCsv = () => {
  const state = useContext(ExportContext);
  const counterQueries = useCounterQueries();
  const { addExport } = useContext(ExportNotificationsContext);
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const { exportType, exportId, includeEntitiesWithoutDoc } = state;
  const { bdsBoughtButNotConnected } = useDatev(); // BDS-checked

  const {
    exportEntities: {
      ready: { all: allEntities },
    },
    exportAll,
  } = useExportDataGigaFactory(state);

  const { selectedTimeFrame, timeFrames } = useSelectedTimeFrame({
    entities: allEntities,
    exportAll,
    exportType,
    exportId,
  });

  const documentIds = selectedTimeFrame?.documentIds;

  const [requestCandisCsvExport, { loading }] = useExportCandisCsvMutation({
    refetchQueries: [
      ...getRefetchExportsQueries({ documentIds }),
      ...counterQueries,
    ],
    awaitRefetchQueries: true,
  });

  const datevCSVExportEntities = getDatevExportableEntities(
    selectedTimeFrame,
    exportId ?? '',
    includeEntitiesWithoutDoc,
    false,
    []
  );

  const hasExportableEntity =
    exportAll ||
    !!datevCSVExportEntities.documentIds.length ||
    !!datevCSVExportEntities.reversalIds.length;

  const onExport = async () => {
    const { data: candisCsvExportResp } = await requestCandisCsvExport({
      variables: {
        documentIds: exportAll ? [] : datevCSVExportEntities.documentIds,
        reversalIds: datevCSVExportEntities.reversalIds,
        timeFrame: getTimeFrameInputFromTimeFrameOption(selectedTimeFrame),
        originExportId: exportId,
        exportAll,
      },
    });

    if (candisCsvExportResp?.exportCandisCsv) {
      addExport({
        hash: candisCsvExportResp.exportCandisCsv.hash,
        organization: orgId ?? '',
        status: ExportStatus.Exporting,
        documents: allEntities.length,
        type: GqlExportType.CandisCsv,
      });

      const viewDef = defineExportView(
        {
          ...state,
          sidebar: Sidebar.HISTORY,
          view: View.HISTORY,
          exportId: candisCsvExportResp.exportCandisCsv.id,
          shouldTriggerDownload: true,
        },
        { orgId }
      );

      navigate(viewDef.navigate);
    }
  };

  const readyToExportEntitiesCountByTimeframe =
    useGetReadyToExportCountByTimeframe({
      selectedTimeFrame,
      entities: allEntities,
    });

  return (
    <Flex direction="column" justifyContent="space-between">
      <Flex direction="column" gap="space24">
        <ExportInfo>
          <Image
            src={candisCsvIcon}
            alt={exportType ?? ''}
            height="84px"
            width="84px"
            alignSelf="center"
          />
          <InitExportTypeLabel />
        </ExportInfo>
        {timeFrames.length > 0 && (
          <InitExportAvailableMonths
            exportType={exportType}
            timeFrames={timeFrames}
          />
        )}
        <InitExportSummary
          readyToExportEntitiesCount={readyToExportEntitiesCountByTimeframe}
        />
        {bdsBoughtButNotConnected && <BdsSetupHintPanel />}
      </Flex>
      <CandisCsvExportButton
        hasExportableEntity={hasExportableEntity}
        onExport={onExport}
        loading={loading}
      />
    </Flex>
  );
};
