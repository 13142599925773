import { AppRouteParams, Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { createSearchParams, generatePath } from 'react-router-dom-v5-compat';

export const useTransactionsLinks = (cardId?: string) => {
  const orgId = useOrganizationId();

  if (!cardId) return null;

  const links = {
    cardManagerView: {
      pathname: generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/:creditCardId?`,
        { organizationSlug: orgId, creditCardId: cardId }
      ),
      search: createSearchParams({ onCreditCardsView: 'true' }).toString(),
    },
    dashboardWallet: {
      pathname: generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.DASHBOARD_CREDIT_CARD}/:creditCardId?`,
        { organizationSlug: orgId, creditCardId: cardId }
      ),
      search: createSearchParams({ cardId }).toString(),
    },
    archiveTransactions: {
      pathname: generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`,
        { organizationSlug: orgId }
      ),
      search: createSearchParams({ cardRefNum: cardId }).toString(),
    },
  };

  return links;
};
