import {
  ReadOnlySettings,
  useGetOrganizationSettings,
} from 'views/CreditCards/CreditCardsInsights/OrganizationSettingsDrawer/hooks/useGetOrganizationSettings';
import { OrganizationSettingsFormValues } from '../utils/organizationSettingsFormSchema';

export const useGetSettingsFormDefaultValues = () => {
  const { organizationSettings } = useGetOrganizationSettings();

  const defaultValues: OrganizationSettingsFormValues = {
    billingEmailAddress: organizationSettings.billingEmail,
    deliveryAddressStreet: organizationSettings.deliveryAddress.streetName,
    deliveryAddressNumber: organizationSettings.deliveryAddress.streetNumber,
    deliveryAddressAdditionalInfo:
      organizationSettings.deliveryAddress.streetAdditional,
    deliveryAddressZipcode: organizationSettings.deliveryAddress.postalCode,
    deliveryAddressCity: organizationSettings.deliveryAddress.city,
    deliveryAddressCountry: organizationSettings.deliveryAddress.country,
    tradeName: organizationSettings.tradeName,
    vatId: organizationSettings.vatId,
    vatIdAvailable: organizationSettings.vatId ? 'Yes' : 'No',
    deliveryAddressSameAsBilling:
      organizationSettings.isDeliveryAddressandBillingSame,
  };

  const readOnlySettings: ReadOnlySettings = {
    billingAddress: organizationSettings.billingAddress,
    nationalRegisterCourt: organizationSettings.nationalRegisterCourt,
    nationalRegisterId: organizationSettings.nationalRegisterId,
    nationalRegisterType: organizationSettings.nationalRegisterType,
    processingAccount: organizationSettings.processingAccount,
  };

  return { readOnlySettings, defaultValues };
};
