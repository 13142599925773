import { useTeamSettingsForCreditCardsQuery } from 'generated-types/graphql.types';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';

interface UseGetTeamSettingsForCreditCardsParams {
  teamId: string | undefined;
}

export interface SettingsForCreditCards {
  creditCards: string[];
  isTeamAdminAllowedToManageCards: boolean;
  limit: number | undefined;
  oneTimeCardsLimit: number | undefined;
}

export const useGetTeamSettingsForCreditCards = ({
  teamId,
}: UseGetTeamSettingsForCreditCardsParams) => {
  const { data, loading } = useTeamSettingsForCreditCardsQuery({
    variables: { input: { teamId: teamId as string } },
    skip: !teamId,
  });

  const creditCardsTeamSettings = data?.teamSettingsForCreditCards;

  const limit = {
    value: creditCardsTeamSettings?.limitThresholds?.VIRTUAL?.value,
    precision: creditCardsTeamSettings?.limitThresholds?.VIRTUAL?.precision,
    currency: creditCardsTeamSettings?.limitThresholds?.VIRTUAL?.currency,
  };

  const oneTimeCardsLimit = {
    value: creditCardsTeamSettings?.limitThresholds?.SINGLE_USE?.value,
    precision: creditCardsTeamSettings?.limitThresholds?.SINGLE_USE?.precision,
    currency: creditCardsTeamSettings?.limitThresholds?.VIRTUAL?.currency,
  };

  const formattedLimit =
    limit.value && limit.precision
      ? formatIntegerAmountToDecimal(limit.value, limit.precision)
      : undefined;

  const oneTimeCardsLimitFormatted =
    oneTimeCardsLimit.value && oneTimeCardsLimit.precision
      ? formatIntegerAmountToDecimal(
          oneTimeCardsLimit.value,
          oneTimeCardsLimit.precision
        )
      : undefined;

  const settingsForCreditCards: SettingsForCreditCards = {
    creditCards: data?.teamSettingsForCreditCards.linkedCardIds ?? [],
    isTeamAdminAllowedToManageCards:
      data?.teamSettingsForCreditCards.teamAdminCanManageCards ?? false,
    limit: formattedLimit,
    oneTimeCardsLimit: oneTimeCardsLimitFormatted,
  };

  return { settingsForCreditCards, loading };
};
