import { Flex, Grid, Heading } from '@candisio/design-system';
import { LottieAnimation, successData } from 'components/Lottie/Lottie';
import { useTranslation } from 'react-i18next';

export const SuccessfulSetupMessage = () => {
  const [t] = useTranslation();

  return (
    <Grid placeContent="center" placeItems="center" height="100%">
      <LottieAnimation options={{ animationData: successData }} />
      <Flex alignItems="center" direction="column" maxWidth="400px">
        <Heading as="h2">
          {t(
            'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.setupComplete.title'
          )}
        </Heading>
        {t(
          'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.setupComplete.description'
        )}
      </Flex>
    </Grid>
  );
};
