import { useToastMessage } from 'components/Toast/useToastMessage';
import { useApproveCardRequest } from 'containers/credit-cards/CreditCardSettings/HandleRequest/hooks/useApproveCardRequest';
import { useApproveLimitRequest } from 'containers/credit-cards/CreditCardSettings/HandleRequest/hooks/useApproveLimitRequest';
import { ModifiedLimitDetails } from 'containers/credit-cards/CreditCardSettings/types';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import { useGetCardById } from 'containers/credit-cards/utils';
import {
  CardLimitRenewFrequency,
  CardStatus,
} from 'generated-types/graphql.types';
import { isEmpty } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';
import { useUpdateCardLabel } from '../../Hooks/useUpdateCardLabel';
import { useUpdateCardLabelAndCardLimit } from '../../Hooks/useUpdateCardLabelAndCardLimit';
import { UpdateCardLimitFormLoading } from '../../UpdateAccountingInfoContainer/UpdateCardLimitFormLoading';
import { UpdateVirtualAndPhysicalCardLimitForm } from './UpdateVirtualAndPhysicalCardLimitForm';
import { UpdateVirtualAndPhysicalCardLimitFormValues } from './updateVirtualAndPhysicalCardLimitFormSchema';

interface UpdateVirtualAndPhysicalCardLimitFormContainerProps {
  cardId: string;
  cardStatus: CardStatus;
  onClose: () => void;
}

export const UpdateVirtualAndPhysicalCardLimitFormContainer = ({
  cardId,
  cardStatus,
  onClose,
}: UpdateVirtualAndPhysicalCardLimitFormContainerProps) => {
  const { success, error } = useToastMessage();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { canManageCards } = usePermissionsForCreditCards();
  const { updatingLabel, updateCardLabel } = useUpdateCardLabel();
  const { card, loading: loadingCard } = useGetCardById({ cardId });

  const hasPendingRequest =
    !isEmpty(card?.pendingRequest) &&
    card?.pendingRequest?.__typename === 'CardLimitChangeRequest';

  const limit =
    hasPendingRequest &&
    card?.pendingRequest?.__typename === 'CardLimitChangeRequest'
      ? card?.pendingRequest?.requestedLimit?.value ?? 0
      : card?.limit?.value ?? 0;

  const transactionLimit =
    hasPendingRequest &&
    card?.pendingRequest?.__typename === 'CardLimitChangeRequest'
      ? card?.pendingRequest?.requestedTransactionLimit?.value ?? 0
      : card?.transactionLimit?.value ?? 0;

  const limitFrequency =
    hasPendingRequest &&
    card?.pendingRequest?.__typename === 'CardLimitChangeRequest'
      ? card?.pendingRequest?.requestedLimitRenewFrequency
      : card?.limitRenewFrequency;

  const limitsInEuro = {
    perInterval: formatFromCents(card?.limit?.currency, limit),
    transaction: formatFromCents(
      card?.transactionLimit?.currency,
      transactionLimit
    ),
  };

  const initialValues: UpdateVirtualAndPhysicalCardLimitFormValues = {
    cardLabel: card?.label ?? '',
    limit: limitsInEuro.perInterval ?? 0,
    transactionLimit: limitsInEuro.transaction ?? 0,
    comment: undefined,
    limitInterval: limitFrequency ?? CardLimitRenewFrequency.Monthly,
  };

  const { loading, handleSubmit } = useUpdateCardLabelAndCardLimit({
    cardId,
    initialValues,
    onClose,
  });

  const { approve, approving } = useApproveLimitRequest({ cardId });
  const { approveActionToCardType } = useApproveCardRequest({ cardId });

  const approveCardRequest = card?.type
    ? approveActionToCardType[card?.type]?.approve
    : undefined;

  const isApproveCardRequestPending = card?.type
    ? approveActionToCardType[card?.type]?.approving
    : false;

  const isModifyingAndApprovingLimitRequest =
    canManageCards &&
    hasPendingRequest &&
    [CardStatus.Active, CardStatus.Locked, CardStatus.LockedPin].includes(
      cardStatus
    );

  const isModifyingAndApprovingCardRequest =
    canManageCards && cardStatus === CardStatus.Requested;

  const handleModifyAndApproveLimitRequest = async (
    formData: UpdateVirtualAndPhysicalCardLimitFormValues
  ) => {
    if (!card?.pendingRequest?.id) {
      return;
    }

    const modifiedLimitDetails: ModifiedLimitDetails = {
      approvedLimit: {
        currency: 'EUR',
        value: formData.limit,
      },
      approvedLimitRenewFrequency:
        formData.limitInterval as CardLimitRenewFrequency,
      approvedTransactionLimit: {
        currency: 'EUR',
        value: formData.transactionLimit,
      },
      approvedLabel: formData.cardLabel ?? undefined,
    };

    const { status } = await approve(
      card?.pendingRequest?.id,
      modifiedLimitDetails
    );

    if (status === 'success') {
      success(t('drawer.approveRequestSuccess'));
      onClose();
    } else {
      error(t('dashboard.genericErrorMessage'));
    }
  };

  const handleModifyAndApproveCardRequest = async (
    formData: UpdateVirtualAndPhysicalCardLimitFormValues
  ) => {
    if (!card?.pendingRequest?.id || !approveCardRequest) {
      return;
    }

    const modifiedLimitDetails: ModifiedLimitDetails = {
      approvedLabel: formData.cardLabel ?? undefined,
      approvedLimit: {
        currency: 'EUR',
        value: formData.limit,
      },
      approvedLimitRenewFrequency:
        formData.limitInterval as CardLimitRenewFrequency,
      approvedTransactionLimit: {
        currency: 'EUR',
        value: formData.transactionLimit,
      },
    };

    const { status } = await approveCardRequest(
      card?.pendingRequest?.id,
      modifiedLimitDetails
    );

    if (status === 'success') {
      success(t('drawer.approveRequestSuccess'));
      onClose();
    } else {
      error(t('dashboard.genericErrorMessage'));
    }
  };

  if (loadingCard) {
    return <UpdateCardLimitFormLoading />;
  }

  let onSubmit = handleSubmit;

  if (isModifyingAndApprovingLimitRequest) {
    onSubmit = handleModifyAndApproveLimitRequest;
  }

  if (isModifyingAndApprovingCardRequest) {
    onSubmit = handleModifyAndApproveCardRequest;
  }

  return (
    <UpdateVirtualAndPhysicalCardLimitForm
      cardType={card?.type}
      cardStatus={cardStatus}
      updatingLabel={updatingLabel}
      defaultValues={initialValues}
      isSubmitting={loading || approving || isApproveCardRequestPending}
      hasPendingRequest={hasPendingRequest}
      cardId={cardId}
      updateCardLabel={updateCardLabel}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
