import { BookingAccountPaginationQueryVariables } from 'generated-types/graphql.types';
import { getCurrentPage } from 'utils/table/getCurrentPage';
import { getFilterFromUrl } from 'utils/table/getFilterFromUrl';
import { BOOKING_ACCOUNTS_SEARCH_PARAM } from '../consts';

type BookingAccountListQueryParams = {
  [BOOKING_ACCOUNTS_SEARCH_PARAM.accountCode]?: string[];
  [BOOKING_ACCOUNTS_SEARCH_PARAM.name]?: string[];
  [BOOKING_ACCOUNTS_SEARCH_PARAM.sort]?: string;
};

const availableBookingAccountsFilters = [
  BOOKING_ACCOUNTS_SEARCH_PARAM.accountCode,
  BOOKING_ACCOUNTS_SEARCH_PARAM.name,
  BOOKING_ACCOUNTS_SEARCH_PARAM.sort,
];

export const getBookingAccountPaginationVariables = (
  search: string,
  isArchived: boolean
): BookingAccountPaginationQueryVariables => {
  const { accountCode, name } = getFilterFromUrl<BookingAccountListQueryParams>(
    search,
    availableBookingAccountsFilters
  );

  const filters: BookingAccountPaginationQueryVariables['filters'] = {
    id: accountCode,
    name,
    isArchived,
  };

  const DEFAULT_PAGE_SIZE = 20;

  const input = { page: getCurrentPage(), limit: DEFAULT_PAGE_SIZE };

  return {
    input,
    filters,
  };
};
