import { useToastMessage } from 'components/Toast/useToastMessage';
import { UpdateCardIssuerOrganizationSettingsInput } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useGetSettingsFormDefaultValues } from './hooks/useGetSettingsFormDefaultValues';
import { SettingsForm } from './SettingsForm';
import { OrganizationSettingsFormValues } from './utils/organizationSettingsFormSchema';
import { sanitiseOrganizationSettings } from './utils/sanitiseOrganizationSettings';

interface SettingsFormContainerProps {
  formId: string;
  onClose: () => void;
  update: (
    formData: UpdateCardIssuerOrganizationSettingsInput
  ) => Promise<{ status: 'success' | 'error' } | undefined>;
}

export const SettingsFormContainer = ({
  formId,
  update,
  onClose,
}: SettingsFormContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();
  const { defaultValues, readOnlySettings } = useGetSettingsFormDefaultValues();

  const handleSubmit = async (formData: OrganizationSettingsFormValues) => {
    const sanitisedFormData = sanitiseOrganizationSettings(formData);

    if (!sanitisedFormData) {
      return;
    }

    const result = await update(sanitisedFormData);

    if (result?.status === 'error') {
      error(t('insights.organizationSettingsDrawer.form.failureToast'));
    } else if (result?.status === 'success') {
      success(t('insights.organizationSettingsDrawer.form.successToast'));
      onClose();
    }
  };

  return (
    <SettingsForm
      defaultValues={defaultValues}
      readOnlySettings={readOnlySettings}
      formId={formId}
      onSubmit={handleSubmit}
    />
  );
};
