import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { Key, useState } from 'react';
import { useInAppNotificationsPageBasedData } from './hooks/useInAppNotificationsPageBasedData';
import { Organization } from './hooks/useOrganizationsByMembershipList';
import { NotificationSummary } from './NotificationSummary';
import { ALL_ORGANIZATIONS } from './utils';

interface NotificationSummaryContainerProps {
  onClose: () => void;
}

const localStorageKey = 'notifications_toggle';

export const NotificationSummaryContainer = ({
  onClose,
}: NotificationSummaryContainerProps) => {
  const [showOnlyUnread, setShowOnlyUnread] = useLocalStorage(
    localStorageKey,
    false
  );

  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<Organization['id']>(ALL_ORGANIZATIONS);

  const organizationId =
    selectedOrganizationId === ALL_ORGANIZATIONS
      ? undefined
      : selectedOrganizationId;

  const { notificationDetails, hasMore, onLoadMore, loading } =
    useInAppNotificationsPageBasedData({
      organizationId,
      showOnlyUnread,
    });

  const { notifications, totalCount, unreadNotifications } =
    notificationDetails;

  const handleSelectionChange = (key: Key[]) => {
    const option = key.length ? (key[0] as string) : selectedOrganizationId;
    setSelectedOrganizationId(option);
  };

  const notificationList = showOnlyUnread ? unreadNotifications : notifications;

  const handleShowOnlyUnread = (toogled: boolean) => {
    setShowOnlyUnread(toogled);
  };

  return (
    <NotificationSummary
      loading={loading}
      hasMore={hasMore}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      onClose={onClose}
      showOnlyUnread={showOnlyUnread}
      notifications={notificationList}
      onSelectionChange={handleSelectionChange}
      onShowOnlyUnread={handleShowOnlyUnread}
      selectedOrganization={selectedOrganizationId}
    />
  );
};
