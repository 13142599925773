import { isValidBIC } from 'ibantools';
import { z } from 'zod';

const SWIFT_CODE_COUNTRY_START = 4;
const COUNTRY_CODE_LENGTH = 2;
const IBAN_COUNTRY_START = 0;

/**
 * Zod schema to validate Swift Code using `ibantools`
 *
 */
export const swiftCodeSchema = z.string().refine(isValidBIC);

export const isSwiftCountryCodeMatch = ({
  iban,
  swiftCode,
}: {
  iban: string;
  swiftCode: string;
}): boolean => {
  const swiftCountryCode = swiftCode?.substring(
    SWIFT_CODE_COUNTRY_START,
    SWIFT_CODE_COUNTRY_START + COUNTRY_CODE_LENGTH
  );

  const ibanCountryCode = iban?.substring(
    IBAN_COUNTRY_START,
    IBAN_COUNTRY_START + COUNTRY_CODE_LENGTH
  );

  return swiftCountryCode === ibanCountryCode;
};
