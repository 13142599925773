import { useToastMessage } from 'components/Toast/useToastMessage';
import { useDetachAttachmentsMutation } from 'generated-types/graphql.types';
import { Attachment as AttachmentEntity } from 'hooks/useCanAddAttachments/useAttachments';
import { countDocumentsByStatusNew } from 'hooks/useCounterQueries';
import { getInboxDocumentNavigationQueries } from 'hooks/useFetchDocNavigationData/queries';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { RouteParams } from 'views/Inbox/Context';
import { documentHistoryQuery } from 'views/queries';
import { documentQueries } from '../../queries';
import { Attachment } from '../AttachmentItem';

const useDetachAttachments = (
  currentDocumentCursor: string | null,
  documentId: string,
  originalDocumentId?: string
) =>
  useDetachAttachmentsMutation({
    refetchQueries: [
      {
        query: countDocumentsByStatusNew,
      },
      {
        query: getInboxDocumentNavigationQueries,
        variables: { cursor: currentDocumentCursor },
      },
      {
        query: documentQueries.forDraftForm,
        variables: { id: documentId },
      },
      {
        query: documentHistoryQuery,
        variables: { id: documentId },
      },
      {
        query: documentHistoryQuery,
        variables: { id: originalDocumentId },
      },
    ],
  });

export const useDetachAction = (
  item: Attachment | AttachmentEntity,
  setIsDetaching: (arg: boolean) => void
) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const { documentId } = useParams<RouteParams>();

  const [searchParams] = useSearchParams();

  const currentDocumentCursor = searchParams.get('cursor');
  const [detachAttachments] = useDetachAttachments(
    currentDocumentCursor,
    documentId,
    item.originalDocumentId ?? undefined
  );

  const handleDetach = async () => {
    setIsDetaching(true);

    const { errors } = await detachAttachments({
      variables: {
        input: {
          documentId: documentId ?? '',
          attachmentIds: [String(item.id)],
        },
      },
    });

    if (errors) {
      error(t('document.compact.history.documentAttachment.detach.error'));
    } else {
      success(
        t('document.compact.history.documentAttachment.detach.success', {
          fileName: 'label' in item ? item.label : item.name,
        })
      );
    }

    setIsDetaching(false);
  };

  return handleDetach;
};
