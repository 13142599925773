import {
  Button,
  Flex,
  Grid,
  Popover,
  PopoverProps,
  usePopover,
} from '@candisio/design-system';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import moment from 'moment';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';
import { forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ColumnInstance } from 'react-table';
import { zodResolver } from 'utils/zodFormValidation/zodResolver';
import { dateRangeFilterErrorMessages } from './dateRangeFilterErrorMessages';
import {
  DateRangeFilterFormOutput,
  dateRangeFilterSchema,
} from './dateRangeFilterSchema';

export const DEFAULT_DATE_FROM = moment('2015-01-01');
export const DEFAULT_DATE_TO = moment('2099-12-31');

export interface DateRangePanelProps extends PopoverProps {
  onApply: (filters?: string[]) => void;
  onReset: () => void;
  filterValue: string[];
}

export interface FilterProps<TableDataType extends object> {
  column: ColumnInstance<TableDataType>;
}

export interface DateRangeFilterProps<TableDataType extends object> {
  column: Pick<
    FilterProps<TableDataType>['column'],
    'filterValue' | 'setFilter'
  >;
  onUpdateIsFilterBeingUsed?: (value: boolean) => void;
}

export const DateRangeFilter = <TableDataType extends object>({
  column: { filterValue, setFilter },
  onUpdateIsFilterBeingUsed,
}: DateRangeFilterProps<TableDataType>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);

  const { isOpen, close, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({
      placement: 'bottom',
      // The buttons (sort, filter) in the header are conditionally rendered only when the user hovers on top of them
      // and the popover would disappear as soon as the user moves the mouse in the filter popover.
      // This will allow us to prevent the behaviour described.
      onOpenChange: isOpen => onUpdateIsFilterBeingUsed?.(isOpen),
    });

  const buttonVariant =
    filterValue && filterValue.length > 0 ? 'primary' : 'secondary';

  return (
    <>
      <Button
        icon="filterOutlined"
        color="blue"
        label={t('filterLabel')}
        size="xsmall"
        variant={buttonVariant}
        {...triggerProps}
        ref={triggerRef}
      />

      {isOpen && (
        <DateRangePanel
          {...popoverProps}
          filterValue={filterValue}
          ref={popoverRef}
          onApply={(filters: any) => {
            setFilter(filters);
            close();
          }}
          onReset={() => {
            setFilter(undefined);
            close();
          }}
        />
      )}
    </>
  );
};

export const DateRangePanel = forwardRef<HTMLDivElement, DateRangePanelProps>(
  ({ onApply, onReset, filterValue, ...restProps }, ref) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
    const timezone = useOrganizationTimezone();

    const activeFilterValues = filterValue?.[0] as string | undefined;
    const [from, to] = activeFilterValues?.split('-') || [];

    const dateFrom = from
      ? moment.tz(from, 'DD.MM.YYYY', timezone).format(moment.HTML5_FMT.DATE)
      : null;

    const dateTo = to
      ? moment.tz(to, 'DD.MM.YYYY', timezone).format(moment.HTML5_FMT.DATE)
      : null;

    const form = useForm<DateRangeFilterFormOutput>({
      defaultValues: {
        fromDate: dateFrom,
        toDate: dateTo,
      },
      mode: 'onTouched',
      resolver: zodResolver({
        zodSchema: dateRangeFilterSchema,
        errorMessages: dateRangeFilterErrorMessages,
      }),
    });

    const handleSubmit = ({ fromDate, toDate }: DateRangeFilterFormOutput) => {
      const localFrom = fromDate
        ? moment.tz(fromDate, timezone).format('DD.MM.YYYY')
        : null;

      const localTo = toDate
        ? moment.tz(toDate, timezone).format('DD.MM.YYYY')
        : null;

      if (fromDate && !toDate) {
        onApply([`${localFrom}-${DEFAULT_DATE_TO.format('DD.MM.YYYY')}`]);
      } else if (!fromDate && toDate) {
        onApply([`${DEFAULT_DATE_FROM.format('DD.MM.YYYY')}-${localTo}`]);
      } else if (fromDate && toDate) {
        onApply([`${localFrom}-${localTo}`]);
      } else {
        onApply(undefined);
      }
    };

    return (
      <Popover {...restProps} ref={ref} autoFocus={false}>
        <FormProvider {...form}>
          <Grid
            as="form"
            onSubmit={form.handleSubmit(handleSubmit)}
            gap="space16"
            padding="space8">
            <Grid gap="space12" templateColumns="repeat(2, 10rem)">
              <HookFormDatePickerField label={t('dateFrom')} name="fromDate" />
              <HookFormDatePickerField label={t('dateTo')} name="toDate" />
            </Grid>
            <Flex justifyContent="space-between">
              <Button variant="tertiary" onClick={onReset}>
                {t('filterReset')}
              </Button>
              <Button variant="secondary" type="submit">
                {t('filterApply')}
              </Button>
            </Flex>
          </Grid>
        </FormProvider>
      </Popover>
    );
  }
);
