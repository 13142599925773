import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

interface ArtistSocialProps {
  value?: string;
}

export const ArtistSocial = ({ value }: ArtistSocialProps) => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="artistSocialInsuranceCode"
      inputProps={{
        value: value,
      }}
      label={t(
        'document.requestApproval.inputs.artistSocialInsuranceCode.label'
      )}
      readOnly
    />
  );
};
