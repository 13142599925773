import { Card, Grid, Paragraph, Tag } from '@candisio/design-system';
import { useIsFourteenDaysPassed } from 'containers/credit-cards/CreditCardCarousel/components/States/Activate/hooks/useIsFourteenDaysPassed';
import { useCheckPendingStatus } from 'containers/credit-cards/hooks/useCheckPendingStatus';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';

interface PendingInfoBoxProps {
  cardId: string;
}

const translations = {
  missingPhoneNumber:
    'creditCardFormDataPreview.cardManager.missingPhoneNumber',
  cardNeedsToBeActivated: 'creditCardFormDataPreview.cardManager.pendingText',
  cardDidNotArrive: 'creditCardFormDataPreview.cardManager.cardDidNotArrive',
  cardShipped: 'creditCardFormDataPreview.cardManager.cardShipped',
  cardShippedWithDate:
    'creditCardFormDataPreview.cardManager.cardShippedWithDate',
};

export const PendingInfoBox = ({ cardId }: PendingInfoBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { availablePendingActions, loading, shippingDate } =
    useCheckPendingStatus(cardId);

  const { isFourteenDaysPassed } = useIsFourteenDaysPassed({ shippingDate });

  if (loading) {
    return null;
  }

  const text = !isNil(availablePendingActions.missingPhoneNumber)
    ? t(translations.missingPhoneNumber)
    : isFourteenDaysPassed
    ? t(translations.cardDidNotArrive)
    : shippingDate
    ? t(translations.cardShippedWithDate, {
        shippingDate: DateFormatters.compact(new Date(shippingDate)),
      })
    : t(translations.cardShipped);

  return (
    <Card background="bluebg">
      <Grid gap="space8">
        <Tag color="blue">
          {t('creditCardFormDataPreview.cardManager.pending')}
        </Tag>
        <Paragraph fontSize="basic">{text}</Paragraph>
      </Grid>
    </Card>
  );
};
