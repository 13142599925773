import { Grid, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import { DocumentGrossNetTaxRoundingAmountChangedEvent } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { amountFormatWithoutCurrency } from 'utils/format';
import { MetaDataWrapper } from '../MetaDataWrapper';

export const DocumentGrossNetTaxRoundingChangedComponent = ({
  documentGrossAmount,
  documentNetAmount,
  documentTaxAmount,
  documentRoundingAmount,
}: DocumentGrossNetTaxRoundingAmountChangedEvent) => {
  const [t] = useTranslation();

  const amounts = [
    {
      key: 'documentGrossAmount',
      value: documentGrossAmount,
    },
    {
      key: 'documentNetAmount',
      value: documentNetAmount,
    },
    {
      key: 'documentTaxAmount',
      value: documentTaxAmount,
    },
    {
      key: 'documentRoundingAmount',
      value: documentRoundingAmount,
    },
  ].filter(amount => !isNil(amount.value));

  const title = t('document.compact.history.documentGrossAmountChanged.title', {
    count: amounts.length,
  });

  return (
    <Grid>
      <Text fontSize="small" lineHeight="paragraph">
        {title}
      </Text>
      <Collapse>
        <MetaDataWrapper>
          {amounts.map(amount => {
            return (
              <Grid key={amount.key}>
                <Text
                  color="gray500"
                  lineHeight="paragraph"
                  fontWeight="regular"
                  fontSize="small">
                  {t(
                    'document.compact.history.documentGrossAmountChanged.' +
                      amount.key
                  )}
                </Text>
                <Text color="gray800" fontSize="small" lineHeight="paragraph">
                  {amountFormatWithoutCurrency(amount.value as number)}
                </Text>
              </Grid>
            );
          })}
        </MetaDataWrapper>
      </Collapse>
    </Grid>
  );
};
