import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ChangeCardLimitInput,
  RequestCardLimitChangeInput,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { UpdateSingleUseCardLimitFormValues } from '../UpdateCardLabelAndLimit/UpdateSingleUseCardLimitFormContainer/updateSingleUseCardLimitFormSchema';
import {
  sanitizeSingleUseLimitInputsForCardManager,
  sanitizeSingleUseLimitInputsForCardholder,
} from '../UpdateCardLabelAndLimit/utils/utils';
import { useChangeCardLimit } from './useChangeCardLimit';
import { useRequestCardLimitChange } from './useRequestCardLimit';
import { useUpdateCardPurpose } from './useUpdateCardPurpose';

export const useUpdateCardPurposeAndCardLimit = ({
  cardId,
  onClose,
  initialValues,
}: {
  cardId: string;
  onClose: () => void;
  initialValues: UpdateSingleUseCardLimitFormValues;
}) => {
  let resErrors: readonly GraphQLError[] | undefined;

  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();
  const { isCardManager } = useUserRoles();

  const { updatingPurpose, updateCardPurpose } = useUpdateCardPurpose();

  const { loadingRequest, requestLimitChange } = useRequestCardLimitChange();

  const { changingLimit, changeCardLimit } = useChangeCardLimit();

  const handleLimitChangeRequest = async (
    formData: UpdateSingleUseCardLimitFormValues
  ) => {
    const requestToSubmit: RequestCardLimitChangeInput = {
      cardId,
      ...sanitizeSingleUseLimitInputsForCardholder(formData),
    };

    const newPurpose = formData.purpose;

    const hasPurposeChanged = initialValues.purpose !== newPurpose;

    const hasTransactionLimitChanged =
      initialValues.transactionLimit !==
      requestToSubmit.requestedTransactionLimit.value;

    const hasCommentChanged = initialValues.comment !== requestToSubmit.comment;

    const hasOtherFormValuesChanged =
      hasTransactionLimitChanged || hasCommentChanged;

    if (!hasPurposeChanged && !hasOtherFormValuesChanged) {
      error(t('creditCardFormDataPreview.duplicateValue'));

      return;
    }

    if (hasPurposeChanged) {
      const { errors, status } = await updateCardPurpose({
        cardId,
        purpose: newPurpose,
      });

      if (status === 'error') {
        resErrors = errors;
      } else {
        resErrors = undefined;
      }
    }

    if (hasOtherFormValuesChanged) {
      const { errors, status } = await requestLimitChange({
        ...requestToSubmit,
      });

      if (status === 'error') {
        resErrors = errors;
      } else {
        resErrors = undefined;
      }
    }

    if (resErrors && resErrors?.length > 0) {
      if (resErrors?.[0].message.includes('MFA')) {
        error(t('dashboard.drawer.form.failureMfa'));
      } else error(t('creditCardFormDataPreview.errorMsg'));
    } else {
      success(t('creditCardFormDataPreview.successMsg'));
      onClose();
    }
  };

  const handleLimitChange = async (
    formData: UpdateSingleUseCardLimitFormValues
  ) => {
    const changesToSubmit: ChangeCardLimitInput = {
      cardId,
      ...sanitizeSingleUseLimitInputsForCardManager(formData),
    };

    const newPurpose = formData.purpose;

    const hasPurposeChanged = initialValues.purpose !== newPurpose;

    const hasTransactionLimitChanged =
      initialValues.transactionLimit !== changesToSubmit.transactionLimit.value;

    if (!hasPurposeChanged && !hasTransactionLimitChanged) {
      error(t('creditCardFormDataPreview.duplicateValue'));

      return;
    }

    if (hasPurposeChanged) {
      const { errors, status } = await updateCardPurpose({
        cardId,
        purpose: newPurpose,
      });

      if (status === 'error') {
        resErrors = errors;
      } else {
        resErrors = undefined;
      }
    }

    if (hasTransactionLimitChanged) {
      const { errors, status } = await changeCardLimit({
        ...changesToSubmit,
      });

      if (status === 'error') {
        resErrors = errors;
      } else {
        resErrors = undefined;
      }
    }

    if (resErrors && resErrors?.length > 0) {
      if (resErrors?.[0].message.includes('MFA')) {
        error(t('dashboard.drawer.form.failureMfa'));
      } else error(t('creditCardFormDataPreview.errorMsg'));
    } else {
      success(t('creditCardFormDataPreview.successMsg'));
      onClose();
    }
  };

  return {
    loading: updatingPurpose || loadingRequest || changingLimit,
    handleSubmit: isCardManager ? handleLimitChange : handleLimitChangeRequest,
  };
};
