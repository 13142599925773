import { Flex, Button } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ShowTransactionsProps {
  loading?: boolean;
  onShowAll: () => void;
  onShowMissing: () => void;
  transactionsWithoutDocumentCount: number;
}

export const ShowTransactions = ({
  loading,
  onShowAll,
  onShowMissing,
  transactionsWithoutDocumentCount,
}: ShowTransactionsProps) => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Flex gap="space8" direction="column">
      <Button
        size="small"
        alignSelf="end"
        variant="secondary"
        icon="arrowRight"
        iconPosition="right"
        onClick={onShowAll}
        disabled={loading}>
        {tTransactions('limit.showAllTransactions')}
      </Button>
      {transactionsWithoutDocumentCount ? (
        <Button
          size="small"
          disabled={loading}
          variant="secondary"
          alignSelf="end"
          iconPosition="right"
          color="red"
          icon="arrowRight"
          onClick={onShowMissing}>
          {tTransactions('limit.showTransactionsWithoutDocuments', {
            count: transactionsWithoutDocumentCount,
          })}
        </Button>
      ) : null}
    </Flex>
  );
};
