import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

export interface StatementCellProps extends CellProps<DocumentsTableData> {
  value: string | undefined;
}

export const StatementCell = ({ value, row }: StatementCellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  if (!value || !row.original.hasDocument) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return <TruncatedText>{t('statementAvailable')}</TruncatedText>;
};
