import { Routes } from 'models';

export const jumpToCreatedCreditCard = ({
  cardId,
  organizationId,
}: {
  cardId: string;
  organizationId: string | null;
}) => {
  const creditCardLink = `/${organizationId}${Routes.DASHBOARD}/credit_card/request?cardId=${cardId}`;

  return { creditCardLink };
};
