import { Grid, ScrollBox } from '@candisio/design-system';
import { HistoryLoading } from 'components/History/HistoryLoading';
import { ContactHistory } from 'views/Contacts/ContactDetails/ContactHistory/elements/ContactHistory';
import { useContactHistoryData } from 'views/Contacts/ContactDetails/ContactHistory/toolkit/hooks/useContactHistoryData';
import { useShowContactHistoryInfoPanel } from 'views/Contacts/ContactDetails/ContactHistory/toolkit/hooks/useContactHistoryInfoPanel';

interface ContactHistoryContainerProps {
  contactId?: string;
}

export const ContactHistoryContainer = ({
  contactId,
}: ContactHistoryContainerProps) => {
  const { contactHistoryItems, isContactHistoryLoading } =
    useContactHistoryData(contactId ?? '');

  const showInfoPanel = useShowContactHistoryInfoPanel(contactId);

  return (
    <ScrollBox height="100%" scrollDirection="y" paddingTop="space16">
      <Grid gap="space16" templateRows="auto 1fr" overflow="hidden">
        {isContactHistoryLoading ? (
          <HistoryLoading itemCount={6} />
        ) : (
          <ContactHistory
            contactHistoryItems={contactHistoryItems}
            showInfoPanel={showInfoPanel}
          />
        )}
      </Grid>
    </ScrollBox>
  );
};
