import { useAccountingAreaByIdQuery } from 'generated-types/graphql.types';

interface UseGetAccountingAreaByIdParams {
  id?: string;
}

export const useGetAccountingAreaById = ({
  id,
}: UseGetAccountingAreaByIdParams) => {
  const { data: accountingAreaData, loading: accountingAreaLoading } =
    useAccountingAreaByIdQuery({
      variables: {
        id: id ?? '',
      },
      skip: !id || id === 'create',
    });

  const accountingArea =
    accountingAreaData?.accountingAreaById.__typename === 'AccountingArea'
      ? accountingAreaData.accountingAreaById
      : undefined;

  return { accountingArea, accountingAreaLoading };
};
