import { useSplitBookingsContext } from 'containers/SplitBookings/SplitBookingsContext';
import {
  bookingWithNetAmount,
  calculateRestAmount,
  getRoundingDifference,
} from 'containers/SplitBookings/toolkit/utils';

export const useHasInitialRemainingAmountError = () => {
  const { initialGrossAmount, initialBookings } = useSplitBookingsContext();

  const initialBookingsRemainingAmount = calculateRestAmount({
    grossAmount: initialGrossAmount,
    bookings: (initialBookings || []).map(bookingWithNetAmount),
  });

  const initialBookingsRoundingDifference = getRoundingDifference({
    grossAmount: initialGrossAmount,
    bookings: (initialBookings || []).map(bookingWithNetAmount),
  });

  return (
    Math.abs(initialBookingsRemainingAmount) >
    Math.abs(initialBookingsRoundingDifference ?? 0)
  );
};
