import { CardStatus, DateFilterInput } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardQueryParams } from 'views/CreditCards/utils/utils';
import { InfoCard } from './InfoCard';

interface ExpiringCardsProps {
  value: number;
  nextMonthFilter: DateFilterInput;
}

const statusesFilters = [
  CardStatus.Active,
  CardStatus.Pending,
  CardStatus.Locked,
  CardStatus.LockedPin,
];

export const ExpiringCards = ({
  value,
  nextMonthFilter,
}: ExpiringCardsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const { isCardManager } = useUserRoles();

  const handleClick = () => {
    const searchParams = new URLSearchParams();
    statusesFilters.forEach(status =>
      searchParams.append(CreditCardQueryParams.status, status)
    );
    searchParams.append(
      CreditCardQueryParams.expiryDate,
      `${nextMonthFilter.dateFrom}-${nextMonthFilter.dateTo}`
    );

    const linkToExpiringCards = `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`;

    navigate({
      pathname: linkToExpiringCards,
      search: searchParams.toString(),
    });
  };

  return (
    <InfoCard
      value={value}
      text={t('insights.requestsOverview.expiringCards', { count: value })}
      color="red500"
      icon="warning"
      onClick={isCardManager ? handleClick : undefined}
    />
  );
};
