import { Validity } from '../CreditCardDrawer/Tabs/Request/utils/validityFieldOptions';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from './CreateCreditCardModalDataProvider';
import { SingleUseCardLimitsForm } from './SingleUseCardLimitsForm';
import {
  SingleUseCardLimitsFormOutput,
  SingleUseCardLimitsFormValues,
} from './utils/singleUseCardLimitsFormSchema';

export const SingleUseVirtualCardLimitsFormContainer = () => {
  const setModalState = useCreateCreditCardModalSetState();
  const { cardDetails } = useCreateCreditCardModalState();

  const handleSubmit = (formValues: SingleUseCardLimitsFormOutput) => {
    const { transactionLimit } = formValues;

    setModalState(prevState => ({
      ...prevState,
      formStep: 'CardUsage',
      cardDetails: {
        ...prevState.cardDetails,
        transactionLimit,
        validity: Validity.months12,
      },
    }));
  };

  const defaultValues: SingleUseCardLimitsFormValues = {
    transactionLimit: cardDetails?.transactionLimit,
  };

  return (
    <SingleUseCardLimitsForm
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    />
  );
};
