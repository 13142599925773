import { Checkbox } from '@candisio/design-system';
import { useHookFormField } from 'components/HookFormFields/useHookFormField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface NoInvoiceNeededOnCardCheckBoxProps {
  name: string;
  readOnly?: boolean;
}

export const NoInvoiceNeededOnCardCheckBox = ({
  name,
  readOnly,
}: NoInvoiceNeededOnCardCheckBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const {
    inputProps: { value, onChange, ...inputProps },
  } = useHookFormField({
    name,
  });

  return (
    <Checkbox
      defaultSelected={value}
      isReadOnly={readOnly}
      onChange={val => {
        onChange(val);
      }}
      {...inputProps}>
      {t('creditCardFormDataPreview.accounting.card_needs_no_invoice')}
    </Checkbox>
  );
};
