export const quarters = [
  {
    year: 'dashboard.createNewWidget.tabs.quarterBudgetForm.tags.2022',
    fields: [
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q1Label',
        key: 'q1_2022',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q2Label',
        key: 'q2_2022',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q3Label',
        key: 'q3_2022',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q4Label',
        key: 'q4_2022',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
    ],
  },
  {
    year: 'dashboard.createNewWidget.tabs.quarterBudgetForm.tags.2023',
    fields: [
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q1Label',
        key: 'q1_2023',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q2Label',
        key: 'q2_2023',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q3Label',
        key: 'q3_2023',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q4Label',
        key: 'q4_2023',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
    ],
  },
  {
    year: 'dashboard.createNewWidget.tabs.quarterBudgetForm.tags.2024',
    fields: [
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q1Label',
        key: 'q1_2024',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q2Label',
        key: 'q2_2024',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q3Label',
        key: 'q3_2024',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
      {
        label:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.q4Label',
        key: 'q4_2024',
        placeholder:
          'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.placeHolder',
      },
    ],
  },
];
