import gql from 'graphql-tag';

export const costCenterData = gql`
  fragment CostCenterData on CostCenter {
    id
    name
    type
    code
    readableName
    isArchived
    approverIds
    createdAt
    createdBy {
      name
      avatarUrl
    }
    approvers {
      id
      name
      avatarUrl
    }
  }
`;

export const costCenterSearchQuery = gql`
  query costCenterSearch($input: CostCenterSearchInput!) {
    costCenterSearch(input: $input) {
      ...CostCenterData
    }
  }
  ${costCenterData}
`;

export const costCenterPagination = gql`
  query costCenterPagination(
    $input: CostCenterPaginationInput
    $filters: CostCenterFilterInput
    $sortBy: CostCenterSortInput
  ) {
    costCenterPagination(input: $input, filters: $filters, sortBy: $sortBy) {
      records {
        ...CostCenterData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const costCenterInfiniteScrollPagination = gql`
  query costCenterInfiniteScrollPagination(
    $input: CostCenterPaginationInput
    $filters: CostCenterFilterInput
    $queries: CostCenterQueryInput
    $sortBy: CostCenterSortInput
  ) {
    costCenterPagination(
      input: $input
      filters: $filters
      queries: $queries
      sortBy: $sortBy
    )
      @connection(
        key: "costCenterInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        ...CostCenterData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }

  ${costCenterData}
`;

export const countCostCenters = gql`
  query countCostCenters($filters: CostCenterFilterInput) {
    countCostCenters(filters: $filters)
  }
`;

export const costCenterNameFilterQuery = gql`
  query costCenterNameFilter(
    $name: String
    $filter: CostCenterFilterOptionsInput
  ) {
    costCenterNameFilter(name: $name, filter: $filter)
  }
`;

export const costCenterCodeFilterQuery = gql`
  query costCenterCodeFilter(
    $code: String
    $filter: CostCenterFilterOptionsInput
  ) {
    costCenterCodeFilter(code: $code, filter: $filter)
  }
`;
