import { AttachmentDeletedFromDocumentEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const AttachmentDeletedComponent = ({
  fileName,
}: AttachmentDeletedFromDocumentEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <div>
      {t('document.compact.history.documentAttachment.deleted', {
        fileName: fileName,
      })}
    </div>
  );
};
