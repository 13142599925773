import { DocumentResetManualEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const DocumentResetManualComponent = ({
  user,
}: DocumentResetManualEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <>
      {t('document.compact.history.documentResetManual.details', {
        username: user?.name,
      })}
    </>
  );
};
