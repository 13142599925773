import { Flex, Grid, Link, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import {
  LottieAnimation,
  noresultsData,
} from '../../../../../../components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from '../../../../../../providers/LocaleProvider';

export const FilteredAndEmpty = ({
  resetFilters,
}: {
  resetFilters: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);

  return (
    <Grid gap="space16">
      <LottieAnimation
        options={{
          animationData: noresultsData,
        }}
      />
      <Flex maxWidth="space256" alignItems="center" direction="column">
        <>
          <Paragraph textAlign="center" whiteSpace="pre-wrap">
            {t('settings.contacts.nodata.filtered.title')}
          </Paragraph>
          <Link as="button" onClick={() => resetFilters()}>
            {t('settings.contacts.nodata.filtered.resetFilters')}
          </Link>
        </>
      </Flex>
    </Grid>
  );
};
