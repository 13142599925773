import { ErrorMessages } from 'utils/zodFormValidation';
import { creditCardSetupFormSchema } from './creditCardSetupFormSchema';

type ExcludeKeys =
  | 'cards'
  | 'isTeamAdminAllowedToManageCards'
  | 'isACardAssignedToTeam';

export const creditCardSetupFormErrorMessages: ErrorMessages<
  typeof creditCardSetupFormSchema,
  ExcludeKeys
> = {
  limit: {
    label: 'teams.editTeam.creditCardsSetup.form.fields.limit.label',
  },
  oneTimeCardLimit: {
    label: 'teams.editTeam.creditCardsSetup.form.fields.oneTimeCardLimit.label',
  },
};
