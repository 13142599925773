import { mergeProps } from '@candisio/design-system';
import {
  HookFormAmountField,
  HookFormAmountFieldProps,
} from 'components/HookFormFields/HookFormAmountField';
import { useWatch } from 'react-hook-form';
import { StorageFormValues } from './storageFormSchema';
import { useStorageFormField } from './useStorageFormField';

/**
 * Storage form monetary amount field
 *
 * Formats the amount using the current value of the currency field
 */
export const StorageFormAmountField = ({
  name,
  ...restProps
}: HookFormAmountFieldProps<StorageFormValues>) => {
  const currency = useWatch<StorageFormValues, 'currency'>({
    name: 'currency',
  });

  const { fieldProps } = useStorageFormField(name);

  return (
    <HookFormAmountField
      name={name}
      currency={currency ?? undefined}
      {...mergeProps(fieldProps, restProps)}
    />
  );
};
