export enum MEA_WALLET_EVENT {
  CARD_DATA_CARDHOLDER_COPIED = 'CARD_DATA_CARDHOLDER_COPIED',
  CARD_DATA_CLEARED = 'CARD_DATA_CLEARED',
  CARD_DATA_COPYING_FAILED = 'CARD_DATA_COPYING_FAILED',
  CARD_DATA_CVV_COPIED = 'CARD_DATA_CVV_COPIED',
  CARD_DATA_EXPIRY_COPIED = 'CARD_DATA_EXPIRY_COPIED',
  CARD_DATA_LOADING_FAILED = 'CARD_DATA_LOADING_FAILED',
  CARD_DATA_LOADING_STARTED = 'CARD_DATA_LOADING_STARTED',
  CARD_DATA_LOADING_SUCCESS = 'CARD_DATA_LOADING_SUCCESS',
  CARD_DATA_PAN_COPIED = 'CARD_DATA_PAN_COPIED',
  LOADER_OVERLAY_ADDED = 'LOADER_OVERLAY_ADDED',
  LOADER_OVERLAY_REMOVED = 'LOADER_OVERLAY_REMOVED',
  MISSING_PARAMETERS = 'MISSING_PARAMETERS',
  STYLE_URL_ADDED = 'STYLE_URL_ADDED',
}

export type MeaWalletMessageEvent = MessageEvent<{
  event: MEA_WALLET_EVENT;
  code: number;
}>;
