import {
  IntegrationName,
  DocumentCategory,
  DocumentCurrency,
} from 'generated-types/graphql.types';
import { isValidBIC, isValidIBAN } from 'ibantools';
import { get, isNil } from 'lodash';
import moment from 'moment';
import { ValuesType } from 'utility-types';
import { ibanSchema } from 'utils/zodFormValidation';
import {
  isSwiftCountryCodeMatch,
  swiftCodeSchema,
} from 'utils/zodFormValidation/Schemas/swiftCodeSchema';
import { taxCodeExportValidationSchema } from 'views/utils/taxCodeExportValidationSchema';
import { z } from 'zod';
import { TaxPresentation } from '../SplitBookingsForm/types';
import { deliveryDateSchema } from './deliveryDateSchema';
import { invoiceDateSchema } from './invoiceDateSchema';
import { ProcessingFormProps } from './ProcessingForm';
import { ProcessingFormAccountingDataFieldItem } from './ProcessingFormAccountingFields';
import { ProcessingFormTypeFieldItem } from './ProcessingFormTypeField';

export const EXTRACTED_CONTACT_ID = 'extracted-contact';
const MAX_YEARS_IN_PAST = 3;

export interface ProcessingFormSchemaOptions {
  /** Integration that the organization is using */
  integration: ProcessingFormProps['integration'];
  /** List of available document categories */
  availableDocumentCategories?: DocumentCategory[];
  /** List of tax code field items */
  taxCodeItems?: ProcessingFormAccountingDataFieldItem[];
  /** List of (document) type field items */
  typeItems?: ProcessingFormTypeFieldItem[];
  /** Whether the document has transaction linked to it */
  hasLinkedTransaction?: boolean;
  /** Does form have cost center field? */
  hasCostCenterField?: boolean;
  /** Does form have cost object field? */
  hasCostObjectField?: boolean;
  /** Whether accounting area is used or not */
  shouldUseAccountingArea?: boolean;
  /** whether GLA will be required or not */
  shouldRequireGeneralLedgerAccount?: boolean;
  /** whether tax code is required or not */
  shouldRequireTaxCode?: boolean;
  /** whether accounts payable number is required or not */
  shouldRequireAccountsPayableNumber?: boolean;
  /** whether netAmount is required or not */
  shouldUseSapNetAmount?: boolean;
  /** Whether the form is in loading state */
  isLoading?: boolean;
  isFormReadOnly?: boolean;
}

/** Base schema for processing form */
const formSchema = z.object({
  mode: z.enum(['approve', 'requestApproval']),
  approvalMode: z.enum(['approvers', 'workflow']),
  type: z.string(),
  contact: z.object({
    value: z.preprocess(
      // treat EXTRACTED_CONTACT_ID as empty field
      value => (value === EXTRACTED_CONTACT_ID ? null : value),
      z.string()
    ),
    inputValue: z.string().optional(),
  }),
  accountsPayableNumber: z.string().nullish(),
  accountsReceivableNumber: z.string().nullish(),
  invoiceDate: invoiceDateSchema({ yearsInPast: MAX_YEARS_IN_PAST }),
  deliveryDate: deliveryDateSchema({
    yearsInPast: MAX_YEARS_IN_PAST,
  }).nullish(),
  postingDate: z.string().nullish(),
  invoiceNumber: z.string().trim().min(1),
  purchaseOrderNumber: z.string().trim().nullish(),
  grossAmount: z
    .number()
    .step(0.01)
    .and(z.number().min(0.01).or(z.number().max(-0.01))),
  currency: z.string(),
  roundingAmount: z.number().nullish(),
  netAmount: z.number().nullish(),
  taxAmount: z.number().nullish(),
  bookings: z.array(
    z.object({
      /**
       * We use `bookingId` instead of `id` to avoid clashing with the `id`
       * property set by React Hook Form’s `useFieldArray` hook
       */
      bookingId: z.string().nullish(),
      amount: z.number(),
      netAmount: z.number().nullish(),
      taxAmount: z.number().nullish(),
      vatRate: z.number().min(0).max(99.99).step(0.01).nullish(),
      dueDate: z.string().nullish(),
      postingText: z.string().nullish(),
      note: z.string().nullish(),
      taxCode: z.string().nullish(),
      costCenter: z.object({
        value: z.string().nullish(),
        inputValue: z.string().optional(),
      }),
      costObject: z.object({
        value: z.string().nullish(),
        inputValue: z.string().optional(),
      }),
      generalLedgerAccount: z.object({
        value: z.string().nullish(),
        inputValue: z.string().optional(),
      }),
      artistSocialInsuranceCode: z.string().nullish(),
      extraCostInfo: z.object({
        value: z.string().nullish(),
        inputValue: z.string().optional(),
      }),
      quantity: z.union([z.number().min(1), z.undefined()]),
      taxPresentation: z.nativeEnum(TaxPresentation).optional(),
    })
  ),
  iban: ibanSchema.nullish(),
  swiftCode: swiftCodeSchema.nullish(),
  createTransfer: z.boolean(),
  paymentCondition: z.string().nullish(),
  accountingArea: z.object({
    value: z.string().nullish(),
    inputValue: z.string().optional(),
  }),
  discountDate: z.string().nullish(),
  discountPercentage: z
    .number()
    .min(0.01)
    .max(99.99)
    .step(0.01)
    .nullable()
    .optional(),
  discountAmount: z.number().step(0.01).nullish(),
  approvers: z.array(z.object({ approvers: z.array(z.string()) })),
  workflow: z.string().nullish(),
});

/** Schema to validate accountsPayableNumber */
const accountsPayableNumberSchema = ({
  shouldRequireAccountsPayableNumber,
}: Pick<ProcessingFormSchemaOptions, 'shouldRequireAccountsPayableNumber'>) => {
  return formSchema
    .pick({ accountsPayableNumber: true })
    .superRefine(({ accountsPayableNumber }, ctx) => {
      if (shouldRequireAccountsPayableNumber && !accountsPayableNumber) {
        ctx.addIssue({
          code: 'invalid_type',
          path: ['accountsPayableNumber'],
          expected: 'string',
          received: 'null',
        });
      }
    });
};

/** Schema to validate bank info */
const bankInfoSchema = ({
  hasLinkedTransaction,
}: Pick<ProcessingFormSchemaOptions, 'hasLinkedTransaction'>) => {
  return formSchema
    .pick({ iban: true, swiftCode: true })
    .superRefine(({ iban, swiftCode }, ctx) => {
      if (isNil(iban) && swiftCode) {
        ctx.addIssue({
          code: 'custom',
          path: ['iban'],
          params: {
            translationKey:
              'document.requestApproval.inputs.errors.ibanEmptySwiftPresent',
          },
        });

        return;
      }

      if (
        !hasLinkedTransaction &&
        iban &&
        iban.startsWith('GB') &&
        !swiftCode
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['swiftCode'],
          params: {
            translationKey:
              'document.requestApproval.inputs.errors.swiftCodeRequiredForGBIban',
          },
        });

        return;
      }

      if (
        iban &&
        swiftCode &&
        isValidIBAN(iban) &&
        isValidBIC(swiftCode) &&
        !isSwiftCountryCodeMatch({ iban, swiftCode })
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['swiftCode'],
          params: {
            translationKey:
              'document.requestApproval.inputs.errors.swiftCodeIbanCountryCodeMismatch',
          },
        });

        return;
      }
    });
};

/** Schema to validate delivery date relative to invoice date  */
const deliveryDateSameOrBeforeInvoiceDateSchema = formSchema
  .pick({ deliveryDate: true, invoiceDate: true })
  .superRefine(({ deliveryDate, invoiceDate }, ctx) => {
    if (!deliveryDate || !invoiceDate) {
      return;
    }

    const invoiceDateMoment = moment(invoiceDate);
    const deliveryDateMoment = moment(deliveryDate);

    if (deliveryDateMoment.isAfter(invoiceDateMoment)) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey:
            'document.requestApproval.inputs.errors.deliveryDate.max',
        },
        path: ['deliveryDate'],
      });
    }
  });

/* Schema to validate due date relative to invoice date  */
const dueDateSameOrAfterInvoiceDateSchema = formSchema
  .pick({ bookings: true, invoiceDate: true })
  .superRefine(({ invoiceDate, bookings }, ctx) => {
    if (!invoiceDate) {
      return;
    }

    const invoiceDateMoment = moment(invoiceDate);

    bookings.forEach(({ dueDate }, index) => {
      if (!dueDate) {
        return;
      }

      const dueDateMoment = moment(dueDate);

      if (dueDateMoment.isBefore(invoiceDateMoment)) {
        ctx.addIssue({
          code: 'custom',
          params: {
            translationKey: 'document.requestApproval.inputs.errors.dueDate',
          },
          path: ['bookings', index, 'dueDate'],
        });
      }
    });
  });

/** Schema to validate discount date relative to invoice date */
const discountDateSameOrAfterInvoiceDateSchema = formSchema
  .pick({ discountDate: true, invoiceDate: true })
  .superRefine(({ discountDate, invoiceDate }, ctx) => {
    if (!discountDate || !invoiceDate) {
      return;
    }

    const discountDateMoment = moment(discountDate);
    const invoiceDateMoment = moment(invoiceDate);

    if (invoiceDateMoment && discountDateMoment.isBefore(invoiceDateMoment)) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey:
            'document.requestApproval.inputs.errors.cashDiscount.dueDate.min',
        },
        path: ['discountDate'],
      });
    }
  });

/** Schema to validate cash discount fields */
const cashDiscountFieldsSchema = formSchema
  .pick({
    discountDate: true,
    discountPercentage: true,
    discountAmount: true,
  })
  .superRefine(({ discountDate, discountPercentage, discountAmount }, ctx) => {
    const isPartialDiscountApplied =
      (discountDate || discountPercentage || discountAmount) &&
      !(discountDate && discountPercentage && discountAmount);

    if (isPartialDiscountApplied) {
      ctx.addIssue({
        code: 'custom',
        path: ['discountAmount'],
        params: {
          translationKey:
            'document.requestApproval.inputs.errors.cashDiscount.required',
        },
      });
    }
  });

/** Schema to validate discount date relative to due date */
const discountDateBeforeDueDateSchema = formSchema
  .pick({ bookings: true, discountDate: true })
  .superRefine(({ bookings, discountDate }, ctx) => {
    if (!discountDate) {
      return;
    }

    const discountDateMoment = moment(discountDate);

    let earliestDueDateMoment = bookings[0]?.dueDate
      ? moment(bookings[0]?.dueDate)
      : undefined;

    bookings.forEach(({ dueDate }) => {
      if (!dueDate) {
        return;
      }

      const dueDateMoment = moment(dueDate);
      if (
        !earliestDueDateMoment ||
        dueDateMoment.isBefore(earliestDueDateMoment)
      ) {
        earliestDueDateMoment = dueDateMoment;
      }
    });

    if (
      earliestDueDateMoment &&
      discountDateMoment.isSameOrAfter(earliestDueDateMoment)
    ) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey:
            'document.requestApproval.inputs.errors.cashDiscount.dueDate.less',
        },
        path: ['discountDate'],
      });
    }
  });

/** Schema to validate discount date relative to invoice date and due date */
const discountDateSchema = discountDateBeforeDueDateSchema.and(
  discountDateSameOrAfterInvoiceDateSchema
);

/** Schema for cash discount amount */
const discountAmountSchema = z
  .object({
    grossAmount: z.number().min(0.01),
    discountAmount: z.number().min(0.01).nullish(),
  })
  .or(z.object({ grossAmount: z.number().max(-0.01) }));

/** Schema to validate tax code based on (document) type */
const taxCodeSchema = ({
  integration,
  taxCodeItems = [],
  typeItems = [],
  shouldRequireTaxCode = false,
}: Pick<
  ProcessingFormSchemaOptions,
  'integration' | 'taxCodeItems' | 'typeItems' | 'shouldRequireTaxCode'
>) => {
  return formSchema
    .pick({ bookings: true, type: true, mode: true })
    .superRefine(({ bookings, type, mode }, ctx) => {
      bookings.forEach(({ taxCode }, index) => {
        if (mode === 'approve') {
          if (shouldRequireTaxCode && !taxCode) {
            ctx.addIssue({
              code: 'invalid_type',
              path: ['bookings', index, 'taxCode'],
              expected: 'string',
              received: 'null',
            });

            return;
          }
        }
      });
      const typeItem = typeItems.find(item => item.key === type);

      if (!typeItem || integration !== 'DATEV') {
        return;
      }

      const direction = typeItem.direction;

      bookings.forEach(({ taxCode }, index) => {
        if (!taxCode) {
          return;
        }

        const taxCodeItem = taxCodeItems.find(item => item.key === taxCode);

        if (!taxCodeItem?.code) {
          return;
        }

        if (
          get(taxCodeExportValidationSchema, [taxCodeItem.code, direction]) ===
          false
        ) {
          ctx.addIssue({
            code: 'custom',
            path: ['bookings', index, 'taxCode'],
            params: {
              translationKey:
                'document.requestApproval.inputs.errors.bookingKeyInvalid',
            },
          });
        }
      });
    });
};

interface ApprovalSchemaOptions {
  hasCostCenterField?: boolean;
  hasCostObjectField?: boolean;
  shouldUseAccountingArea?: boolean;
  integration?: ProcessingFormProps['integration'];
}

/**
 * Validates the form according to the current `mode` and `approvalMode`.
 *
 * Ideally this would be implemented using Zod’s `discriminatedUnion` type but
 * that’s currently impossible since Zod doesn’t support nested unions
 *
 * @todo implement as some sort of union type if Zod ever supports it
 */
const approvalSchema = ({
  hasCostCenterField = false,
  hasCostObjectField = false,
  shouldUseAccountingArea = false,
  integration = 'DATEV',
}: ApprovalSchemaOptions) =>
  formSchema
    .pick({
      accountingArea: true,
      approvalMode: true,
      approvers: true,
      bookings: true,
      mode: true,
      workflow: true,
    })
    .superRefine(
      (
        { accountingArea, approvalMode, approvers, mode, workflow, bookings },
        ctx
      ) => {
        if (mode === 'approve') {
          if (shouldUseAccountingArea && integration === 'OTHER') {
            if (typeof accountingArea.value !== 'string') {
              ctx.addIssue({
                code: 'invalid_type',
                path: ['accountingArea', 'value'],
                expected: 'string',
                received: 'null',
              });
            }
          }

          if (integration !== 'SAP') {
            if (hasCostCenterField) {
              bookings.forEach(({ costCenter }, index) => {
                if (typeof costCenter.value !== 'string') {
                  ctx.addIssue({
                    code: 'invalid_type',
                    path: ['bookings', index, 'costCenter', 'value'],
                    expected: 'string',
                    received: 'null',
                  });

                  return;
                }
              });
            } else if (hasCostObjectField) {
              bookings.forEach(({ costObject }, index) => {
                if (typeof costObject.value !== 'string') {
                  ctx.addIssue({
                    code: 'invalid_type',
                    path: ['bookings', index, 'costObject', 'value'],
                    expected: 'string',
                    received: 'null',
                  });

                  return;
                }
              });
            }
          }
        } else {
          if (approvalMode === 'approvers') {
            if (approvers.length < 1) {
              ctx.addIssue({
                code: 'invalid_type',
                path: ['approvers'],
                expected: 'array',
                received: 'null',
              });
            } else {
              approvers.forEach((approver, index) => {
                if (approver.approvers.length < 1) {
                  ctx.addIssue({
                    code: 'invalid_type',
                    path: ['approvers', index, 'approvers'],
                    expected: 'array',
                    received: 'null',
                  });
                }
              });
            }
          } else {
            if (typeof workflow !== 'string') {
              ctx.addIssue({
                code: 'invalid_type',
                path: ['workflow'],
                expected: 'string',
                received: 'null',
              });
            }
          }
        }
      }
    );

const generalLedgerAccountSchema = ({
  shouldRequireGeneralLedgerAccount,
}: Pick<ProcessingFormSchemaOptions, 'shouldRequireGeneralLedgerAccount'>) => {
  return formSchema
    .pick({
      bookings: true,
      mode: true,
    })
    .superRefine(({ bookings, mode }, ctx) => {
      bookings.forEach(({ generalLedgerAccount }, index) => {
        if (mode === 'approve') {
          if (
            shouldRequireGeneralLedgerAccount &&
            typeof generalLedgerAccount.value !== 'string'
          ) {
            ctx.addIssue({
              code: 'invalid_type',
              path: ['bookings', index, 'generalLedgerAccount', 'value'],
              expected: 'string',
              received: 'null',
            });

            return;
          }
        }
      });
    });
};

/** Schema to validate cash ledger currency */
const cashLedgerCurrencySchema = ({
  integration,
  availableDocumentCategories,
}: Pick<
  ProcessingFormSchemaOptions,
  'integration' | 'availableDocumentCategories'
>) => {
  return formSchema
    .pick({ currency: true, type: true })
    .superRefine(({ currency, type }, ctx) => {
      const selectedDocumentCategory = availableDocumentCategories?.find(
        c => c.documentType === type
      );

      const isOnlyEurAllowed =
        selectedDocumentCategory?.supportedCurrencies?.length === 1 &&
        selectedDocumentCategory?.supportedCurrencies[0] ===
          DocumentCurrency.Eur;

      if (integration === IntegrationName.Datev) {
        if (isOnlyEurAllowed && currency !== DocumentCurrency.Eur) {
          ctx.addIssue({
            code: 'custom',
            path: ['currency'],
            params: {
              translationKey:
                'document.requestApproval.inputs.document.rds1-0-currency-error.text',
            },
          });
        }
      }
    });
};

/** Schema to validate posting date */
const postingDateSchema = (
  integration: ProcessingFormSchemaOptions['integration']
) => {
  return formSchema
    .pick({ postingDate: true })
    .superRefine(({ postingDate }, ctx) => {
      if (integration === 'SAP' && !postingDate) {
        ctx.addIssue({
          code: 'custom',
          path: ['postingDate'],
          params: {
            translationKey:
              'document.requestApproval.inputs.invoicePostingDate.requiredError',
          },
        });
      }
    });
};

// Schema to validate non-negative values for SAP integration
const validateNonNegativeAmountSapSchema = ({
  integration,
}: Pick<ProcessingFormSchemaOptions, 'integration'>) => {
  return formSchema
    .pick({
      bookings: true,
      grossAmount: true,
    })
    .superRefine(({ bookings, grossAmount }, ctx) => {
      if (integration !== 'SAP') return;

      if (grossAmount < 0) {
        ctx.addIssue({
          code: 'custom',
          path: ['grossAmount'],
          params: {
            translationKey:
              'document.requestApproval.inputs.errors.negativeAmount',
          },
        });
      }

      bookings.forEach(({ amount }, index) => {
        if (amount < 0) {
          ctx.addIssue({
            code: 'custom',
            path: ['bookings', index, 'amount', 'value'],
            params: {
              translationKey:
                'split-bookings:inputs.grossAmount.noNegativeNumber',
            },
          });

          return;
        }
      });
    });
};

const netAmountSchema = ({
  shouldUseSapNetAmount,
  isFormReadOnly,
}: Pick<
  ProcessingFormSchemaOptions,
  'shouldUseSapNetAmount' | 'isFormReadOnly'
>) => {
  return formSchema
    .pick({ bookings: true })
    .superRefine(({ bookings }, ctx) => {
      if (!shouldUseSapNetAmount || isFormReadOnly) {
        return;
      }

      bookings.forEach(({ netAmount }, index) => {
        if (!netAmount) {
          ctx.addIssue({
            code: 'custom',
            path: ['bookings', index, 'netAmount'],
            params: {
              translationKey: 'split-bookings:inputs.netAmount.nonZeroError',
            },
          });
        } else if (netAmount < 0) {
          ctx.addIssue({
            code: 'custom',
            path: ['bookings', index, 'netAmount'],
            params: {
              translationKey:
                'document.requestApproval.inputs.errors.negativeAmount',
            },
          });
        }
      });
    });
};

/** Zod schema for processing form */
export const processingFormSchema = (
  {
    integration,
    availableDocumentCategories,
    taxCodeItems,
    typeItems,
    hasLinkedTransaction,
    hasCostCenterField,
    hasCostObjectField,
    shouldUseAccountingArea,
    shouldRequireGeneralLedgerAccount,
    shouldRequireTaxCode,
    shouldRequireAccountsPayableNumber,
    shouldUseSapNetAmount,
    isFormReadOnly,
  }: ProcessingFormSchemaOptions = {
    integration: 'DATEV',
    isLoading: false,
    hasLinkedTransaction: false,
  }
) =>
  formSchema
    .and(deliveryDateSameOrBeforeInvoiceDateSchema)
    .and(dueDateSameOrAfterInvoiceDateSchema)
    .and(bankInfoSchema({ hasLinkedTransaction }))
    .and(discountDateSchema)
    .and(discountAmountSchema)
    .and(cashDiscountFieldsSchema)
    .and(accountsPayableNumberSchema({ shouldRequireAccountsPayableNumber }))
    .and(
      taxCodeSchema({
        integration,
        taxCodeItems,
        typeItems,
        shouldRequireTaxCode,
      })
    )
    .and(
      approvalSchema({
        shouldUseAccountingArea,
        hasCostCenterField,
        hasCostObjectField,
        integration,
      })
    )
    .and(
      generalLedgerAccountSchema({
        shouldRequireGeneralLedgerAccount,
      })
    )
    .and(cashLedgerCurrencySchema({ integration, availableDocumentCategories }))
    .and(postingDateSchema(integration))
    .and(validateNonNegativeAmountSapSchema({ integration }))
    .and(
      netAmountSchema({
        shouldUseSapNetAmount,
        isFormReadOnly,
      })
    );

export type ProcessingFormValues = z.infer<
  ReturnType<typeof processingFormSchema>
>;

export type BookingFieldValues = ValuesType<ProcessingFormValues['bookings']>;
