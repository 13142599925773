import { defaultTheme } from '@candisio/design-system';
import { SvgIcon, SvgProps } from './SvgIcon';

/**
 * @deprecated please use Icon from design system instead.
 * @see https://candisio.github.io/design-system/
 */
export const ExportDone = ({
  fill = defaultTheme.colors.gray200,
  ...props
}: SvgProps) => {
  return (
    <SvgIcon {...props} fill={fill}>
      <path
        fillRule="nonzero"
        d="M8 2c3.327 0 6 2.673 6 6s-2.673 6-6 6-6-2.673-6-6 2.673-6 6-6zm.382 2.205h-.743a.363.363 0 0 0-.371.37V8.82c-.027.185-.239.238-.345.132l-.265-.265c-.16-.16-.371-.16-.53 0l-.504.504c-.16.159-.16.371 0 .53l2.148 2.148c.159.16.37.16.53 0l2.121-2.121c.16-.16.16-.372 0-.53l-.53-.531c-.16-.16-.371-.16-.53 0l-.265.265c-.133.133-.345.027-.345-.132V4.576a.362.362 0 0 0-.371-.371z"
      />
    </SvgIcon>
  );
};
