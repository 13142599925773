import { defaultTheme, Color } from '@candisio/design-system';
import { UserRole } from 'generated-types/graphql.types';

const colors = {
  alert: '#ff5a5a',
  blankBackground: '#ffffff',
  border: '#dbdbdb',
  borderLight: '#e8e8e8',
  borderLightest: '#ffffff',
  confidenceBackgroundColorLow: '#ffecbc',
  confidenceBackgroundColorHigh: '#d1f2d8',
  confidenceIconColor: '#8BC34A',
  darkBlue: '#008cfa',
  darkGray: '#707070',
  defaultBackground: '#F3EFEE',
  defaultHoverBackground: '#fbfbfb',
  defaultText: '#3d3d3d',
  grayText: '#3d3d3d',
  disabled: '#9e9e9e',
  error: '#ffa900',
  errorDefault: '#ff5a5a',
  highlight: '#f0f7ff',
  inactiveText: '#dbdbdb',
  invertText: '#ffffff',
  lightBlue: '#55a8fb',
  lightGray: '#9e9e9e',
  lightPrimary: '#f7fbff',
  primary: '#6F94FF',
  primaryLight: '#333333',
  quaternaryBackground: '#ffffff',
  secondaryBackground: '#ededed',
  secondaryText: '#979797',
  success: '#00b827',
  tertiaryBackground: '#dedbdb',
  warningBoxBorder: '#ffa900',
  warningText: '#ffa900',
  warningTextDark: '#DA9D38',
  washedBrown: '#eabd87',
  washedGreen: '#68cf7e',
  washedPrimary: '#61a1e2',
  lightPink: '#ffc8c8',
};

const tabs = {
  underline: colors.defaultText,
  active: colors.defaultText,
  inactive: colors.secondaryText,
};

const tabLink = {
  underline: colors.darkBlue,
  active: colors.darkBlue,
  onHoverOpacity: 0.75,
  inactiveHoverColor: colors.primary,
  textColor: colors.secondaryText,

  badge: {
    backgroundColor: '#ffffff',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
    marginTop: '-0.125rem',
    minWidth: '0.9375rem',
    height: '0.875rem',
    padding: '0 0.25rem',
    lineHeight: '0.875rem',
    fontSize: '0.6875rem',

    active: {
      backgroundColor: '#1c8bfa',
      boxShadow: '0 0 0 1px #1c8bfa inset',
      textColor: '#ffffff',
    },
  },
};

const statuses = {
  approved: colors.success,
  awaiting: colors.warningTextDark,
  denied: colors.alert,
  regular: colors.darkGray,
  exported: colors.primary,
  warning: colors.warningTextDark,
};

const inputs = {
  label: colors.disabled,
  placeholder: defaultTheme.colors.gray400,
  optional: colors.primary,
  obligatory: colors.primary,
  alert: colors.alert,
  disabledText: colors.disabled,
};

const history = {
  comparison: '#f5f5f5',
};

const pagination = {
  defaultColor: '#878787',
  hover: colors.primary,
};

const table = {
  missingData: '#ff9c04',
};

const roleTags: { [key: string]: Color } = {
  [UserRole.Admin]: 'blue',
  [UserRole.Approver]: 'green',
  [UserRole.Requester]: 'yellow',
};

const shadows = {
  light: '0px 1px 4px rgba(0, 0, 0, 0.2);',
  middle: '0px 4px 8px rgba(0, 0, 0, 0.12);',
  large: '0px 4px 8px rgba(0, 0, 0, 0.1);',
  heavy: '0px 4px 8px rgba(0, 0, 0, 0.16);',
};

/**
 * @deprecated Please use the design system instead.
 * @see https://candisio.github.io/design-system
 */
export const DefaultTheme = {
  colors,
  inputs,
  pagination,
  tabs,
  tabLink,
  roleTags,
  history,
  statuses,
  table,
  shadows,
  typography: {
    baseFontSize: '0.75rem',
    smallFontSize: '0.625rem',
    tinyFontSize: '0.5rem',
    bigFontSize: '0.875rem',
    headingFontSize: '1.5rem',
    headingMediumFontSize: '1.125rem',
    headingSmallFontSize: '0.813rem',
    fontFamily: "'Inter', sans-serif",
    fontFamilyCode: "'courier'",
  },
  designSystem: defaultTheme,
};
