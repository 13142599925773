import { CardLimitRenewFrequency } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { Validity } from '../CreditCardDrawer/Tabs/Request/utils/validityFieldOptions';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from './CreateCreditCardModalDataProvider';
import { useGetSelectedCarholder } from './hooks/useGetSelectedCardholder';
import { PhysicalCardLimitsForm } from './PhysicalCardLimitsForm';
import {
  PhysicalCardLimitsFormOutput,
  PhysicalCardLimitsFormValues,
} from './utils/physicalCardFormSchema';

export const PhysicalCardLimitsFormContainer = ({
  hasPhysicalCard,
  suggestedAmount,
}: {
  hasPhysicalCard?: boolean;
  suggestedAmount?: number;
} = {}) => {
  const { isCardManager } = useUserRoles();
  const user = useCurrentUser();
  const { cardDetails } = useCreateCreditCardModalState();
  const setModalState = useCreateCreditCardModalSetState();

  const { selectedCardholder } = useGetSelectedCarholder();

  const hasSelectedUserPhoneNumber = isCardManager
    ? Boolean(selectedCardholder.phoneNumber)
    : Boolean(user?.phoneNumber);

  const handleSubmit = (formValues: PhysicalCardLimitsFormOutput) => {
    const { limit, limitInterval, transactionLimit, comment } = formValues;
    setModalState(prevState => ({
      ...prevState,
      formStep: 'CardUsage',
      cardDetails: {
        ...prevState.cardDetails,
        limit,
        limitInterval,
        transactionLimit,
        comment,
        validity: prevState.cardDetails?.validity ?? Validity.months36,
      },
    }));
  };

  const defaultValues: PhysicalCardLimitsFormValues = {
    comment: cardDetails?.comment,
    limit: cardDetails?.limit ?? suggestedAmount,
    transactionLimit: cardDetails?.transactionLimit ?? suggestedAmount,
    limitInterval: CardLimitRenewFrequency.Monthly,
  };

  const isSuggestionVisible =
    typeof cardDetails?.limit === 'undefined' ||
    typeof cardDetails?.transactionLimit === 'undefined';

  return (
    <PhysicalCardLimitsForm
      suggestedAmount={isSuggestionVisible ? suggestedAmount : undefined}
      isCardManager={isCardManager}
      defaultValues={defaultValues}
      hasPhysicalCard={hasPhysicalCard}
      hasSelectedUserPhoneNumber={hasSelectedUserPhoneNumber}
      onSubmit={handleSubmit}
    />
  );
};
