import { Buffer } from 'buffer';

export const parseOrganizationId = (
  state: string | undefined
): string | undefined => {
  if (state) {
    try {
      const decodedState = Buffer.from(state, 'base64').toString();
      const parsedState = JSON.parse(decodedState);

      return parsedState.organizationId;
    } catch {}
  }
};
