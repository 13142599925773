import { ComponentProps, useCallback } from 'react';
import { Reducer, useReducer } from 'react';
import { Document, DocumentProps } from 'react-pdf';
import { ProtectedPdf } from './ProtectedPdf';

type Action =
  | { type: 'PASSWORD_REQUIRED' }
  | { type: 'SUCCESS' }
  | {
      type: 'PASSWORD_SUCCESS';
      payload: {
        password: string;
      };
    };

type DocumentWrapperState = {
  status: DocumentStatus;
  password: string | null;
};

type DocumentStatus =
  | 'idle'
  | 'passwordRequired'
  | 'passwordSuccess'
  | 'success';

const initialState: DocumentWrapperState = {
  status: 'idle',
  password: null,
};

const reducer: Reducer<DocumentWrapperState, Action> = (
  state: DocumentWrapperState,
  action: Action
): DocumentWrapperState => {
  switch (action.type) {
    case 'PASSWORD_REQUIRED':
      return {
        ...state,
        status: 'passwordRequired',
        password: null,
      };

    case 'PASSWORD_SUCCESS':
      return {
        ...state,
        status: 'passwordSuccess',
        password: action.payload.password,
      };

    case 'SUCCESS':
      return {
        ...state,
        status: 'success',
        password: null,
      };

    default:
      return {
        ...state,
        password: null,
      };
  }
};

export interface DocumentWrapperProps
  extends Omit<DocumentProps, 'onPassword'> {
  onPassword?: () => void;
  fileUrl?: string;
  changePageVisibility: (pageNumber: number, visibilityRatio: number) => void;
  protectedPageId: string;
  protectedPageNumber: number;
}

export const DocumentWrapper = ({
  onPassword,
  onLoadSuccess,
  protectedPageId,
  changePageVisibility,
  fileUrl,
  protectedPageNumber,
  ...restProps
}: DocumentWrapperProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOnSuccess = useCallback((password: string) => {
    dispatch({
      type: 'PASSWORD_SUCCESS',
      payload: {
        password,
      },
    });
  }, []);

  const handleOnPassword = useCallback(
    (callback: (passowrd: string | null) => void) => {
      if (state.status === 'passwordSuccess') {
        callback(state.password);
      } else {
        dispatch({
          type: 'PASSWORD_REQUIRED',
        });
      }

      onPassword?.();
    },
    [onPassword, state.password, state.status]
  );

  const handleOnLoadSuccess: NonNullable<
    ComponentProps<typeof Document>['onLoadSuccess']
  > = useCallback(
    doc => {
      dispatch({
        type: 'SUCCESS',
      });
      onLoadSuccess?.(doc);
    },
    [onLoadSuccess]
  );

  if (state.status === 'passwordRequired') {
    return (
      <ProtectedPdf
        id={protectedPageId}
        changePageVisibility={changePageVisibility}
        fileUrl={fileUrl}
        onSuccess={handleOnSuccess}
        protectedPageNumber={protectedPageNumber}
      />
    );
  }

  return (
    <Document
      {...restProps}
      onPassword={handleOnPassword}
      onLoadSuccess={handleOnLoadSuccess}
    />
  );
};
