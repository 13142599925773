import { ImportHistoryItem } from 'components/HistoryList/HistoryList';
import { useBookingAccountsImportHistoryQuery } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { BOOKING_ACCOUNT_ROUTE_HASH } from 'views/Settings/BookingAccounts/consts';

export const useBookingAccountImportHistoryData = () => {
  const organizationSlug = useOrganizationId();
  const bookingAccountUrl = `/${organizationSlug}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}`;
  const bookingAccountImportUrl = `/${organizationSlug}${Routes.SETTINGS}${Routes.BOOKING_ACCOUNT}${BOOKING_ACCOUNT_ROUTE_HASH.import}`;

  const { data, loading: isLoading } = useBookingAccountsImportHistoryQuery({
    fetchPolicy: 'network-only',
  });

  const bookingAccountHistoryItems: ImportHistoryItem[] = data
    ? data.bookingAccountsImportHistory.map(item => {
        return {
          createdAt: item.createdAt,
          file: {
            name: item.file.name,
            url: item.file.url,
          },
          username: item.createdBy.name,
        };
      })
    : [];

  return {
    bookingAccountHistoryItems,
    bookingAccountImportUrl,
    bookingAccountUrl,
    isLoading,
  };
};
