import {
  Grid,
  Icon,
  Paragraph,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TradeName = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isOpen, tooltipProps, triggerProps, tooltipRef, triggerRef } =
    useTooltip({ passiveTrigger: true });

  return (
    <Grid templateColumns="1fr auto" alignItems="center" gap="space4">
      <HookFormTextField
        name="tradeName"
        placeholder={t(
          'insights.organizationSettingsDrawer.form.fields.tradeName.placeholder'
        )}
        label={t(
          'insights.organizationSettingsDrawer.form.fields.tradeName.label'
        )}
      />
      <Icon
        size="space20"
        icon="infoOutline"
        {...triggerProps}
        ref={triggerRef}
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef} padding="space12">
          <Paragraph>
            {t(
              'insights.organizationSettingsDrawer.form.fields.tradeName.tooltip'
            )}
          </Paragraph>
        </Tooltip>
      )}
    </Grid>
  );
};
