import { Flex, Grid, Text, Button, Image, Card } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { UserRole } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import creditCards from '../media/mini card.png';

export const PendingCreditCardRequestBanner = ({
  showTitle,
}: {
  showTitle?: boolean;
}) => {
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);

  return (
    <Grid width="fit-content" gap="space8">
      {showTitle && (
        <Text fontSize="xlarge">{t('ccInformationalCard.headerTitle')}</Text>
      )}
      <Card background="gray50">
        <Grid left="-36px" autoFlow="column" alignItems="center" gap="space64">
          <Image src={creditCards} alt="cards" height="300px" />
          <Flex
            gap="space24"
            alignItems="start"
            justifyContent="center"
            direction="column">
            <Text fontSize="xxlarge" lineHeight="paragraph">
              {t('ccRequestState.pendingRequest.description')}
            </Text>
            <UserRoleAccessWrapper
              allowedRoles={[UserRole.Admin, UserRole.Accountant]}>
              <Button
                variant="secondary"
                onClick={() => {
                  navigate(
                    `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`
                  );
                }}>
                {t('ccRequestState.pendingRequest.ctaButton')}
              </Button>
            </UserRoleAccessWrapper>
          </Flex>
        </Grid>
      </Card>
    </Grid>
  );
};
