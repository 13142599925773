import {
  availableEcmDateFilters,
  availableEcmDocumentFilters,
  ecmDocumentFieldsToGQLFieldsNameMap,
  sortableEcmFields,
} from 'components/EcmDocumentsTable/constants';
import { queryParameter } from 'components/Table/consts';
import {
  EcmFilterInput,
  EcmSortInput,
  EcmSortOrder,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { useSearchParams } from 'react-router-dom-v5-compat';

export const usePrepareEcmFiltersSortingFromUrl = () => {
  const [searchParams] = useSearchParams();
  const { dateStringToIsoDateFilterFormat } = useDateConverter();

  const filters = availableEcmDocumentFilters
    .filter(filter => searchParams.has(filter))
    .reduce<EcmFilterInput>((acc, filter) => {
      const mappedFieldName = ecmDocumentFieldsToGQLFieldsNameMap[filter];

      if (availableEcmDateFilters.find(dateFilter => dateFilter === filter)) {
        const [fromDate, toDate] = searchParams.get(filter)?.split('-') ?? [];

        return {
          ...acc,
          [mappedFieldName]: {
            fromDate: dateStringToIsoDateFilterFormat(fromDate),
            toDate: dateStringToIsoDateFilterFormat(toDate),
          },
        };
      }

      return {
        ...acc,
        [mappedFieldName]: searchParams.getAll(filter),
      };
    }, {});

  const sort = Array.from(searchParams.entries()).reduce<EcmSortInput>(
    (acc: EcmSortInput, [key, value]) => {
      if (key !== 'sort') return acc;

      const [fieldName, order] = value.split(':');

      const sanitizedSortableField = sortableEcmFields.find(
        sortableField => sortableField === fieldName
      );

      if (!sanitizedSortableField) {
        return acc;
      }

      const mappedFieldName: keyof EcmSortInput =
        ecmDocumentFieldsToGQLFieldsNameMap[sanitizedSortableField];

      acc[mappedFieldName] =
        order === 'ascend' ? EcmSortOrder.Asc : EcmSortOrder.Desc;

      return acc;
    },
    {}
  );

  const search = searchParams.get(queryParameter);

  return { filters, sort, search };
};
