import { z } from 'zod';

const formSchema = z.object({
  alias: z.string(),
  idpEntityId: z.string(),
  entityId: z.string().nonempty(),
  singleSignOnServiceUrl: z.string(),
  nameIDPolicyFormat: z.string(),
  principalType: z.string(),
  validateSignature: z.boolean(),
  signingCertificate: z.string().nullable(),
  syncMode: z.string().nullable(),
  postBindingAuthnRequest: z.boolean(),
  postBindingResponse: z.boolean(),
});

export const samlFormSchema = formSchema;
export type IdpSamlFormValues = z.infer<typeof samlFormSchema>;
