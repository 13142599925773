import {
  fetchPolicy,
  FilterOption,
  getOptionsFromNetworkData,
  PaginationWithSearchFilterHook,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  BookingAccountInfiniteScrollPaginationQuery,
  BookingAccountInfiniteScrollPaginationQueryVariables,
  BookingAccountSortField,
  SortDirection,
  useBookingAccountPaginationQuery,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { bookingAccountInfiniteScrollPagination } from 'views/queries/booking-accounts';

export const useBookingAccountReadableNameFilter: PaginationWithSearchFilterHook =
  ({ filteredValues, searchStr }) => {
    const { friendlyFormatGLA, normalizeGLA } = useAccountingNumberFormatters();
    const computeVariables = useCallback(
      (
        inputString?: string
      ): BookingAccountInfiniteScrollPaginationQueryVariables => ({
        queries: {
          readableName: inputString,
        },
        sortBy: {
          direction: SortDirection.Asc,
          field: BookingAccountSortField.AccountCode,
        },
      }),
      []
    );

    const {
      data: allBookingAccountsData,
      loading: allBookingAccountsLoading,
      loadMore,
      handleDebounceSearch,
    } = useDebouncedSearchPagination<
      BookingAccountInfiniteScrollPaginationQuery,
      BookingAccountInfiniteScrollPaginationQueryVariables
    >({
      query: bookingAccountInfiniteScrollPagination,
      queryRootKey: 'bookingAccountPagination',
      computeVariables,
    });

    const { data: selectedBookingAccounts } = useBookingAccountPaginationQuery({
      variables: {
        input: { page: 1, limit: filteredValues.length },
        filters: {
          id: filteredValues,
        },
      },
      fetchPolicy,
      skip: filteredValues.length === 0 || Boolean(searchStr),
    });

    const filterOptions: FilterOption[] = getOptionsFromNetworkData(
      selectedBookingAccounts?.bookingAccountPagination.records,
      allBookingAccountsData?.bookingAccountPagination.records
    ).map(option => {
      if (typeof option.label !== 'string') return option;

      const [code, name] = normalizeGLA(option.label);
      const result = {
        id: option.id,
        label: `${friendlyFormatGLA(code)} - ${name}`,
      };

      return result;
    });

    return {
      filterOptions,
      handleDebounceSearch,
      loading: allBookingAccountsLoading,
      loadMore,
    };
  };
