import {
  Maybe,
  WorkflowTemplate,
  useWorkflowTemplatesQuery,
} from 'generated-types/graphql.types';

export const useExistingWorkflowTemplates = ({
  template,
}: {
  template?: Maybe<WorkflowTemplate>;
}): { existingWorkflowTemplates: string[] } => {
  const archivedWorkflowTemplates = useWorkflowTemplatesQuery({
    variables: {
      input: {
        isArchived: true,
      },
    },
  });

  const activeWorkflowTemplates = useWorkflowTemplatesQuery({
    variables: {
      input: {
        isArchived: false,
      },
    },
  });

  const allTemplates = [
    ...(activeWorkflowTemplates?.data?.workflowTemplates || []),
    ...(archivedWorkflowTemplates?.data?.workflowTemplates || []),
  ];

  const existingWorkflowTemplates = allTemplates
    .map(({ name }) => name)
    .filter(name => name !== template?.name);

  return { existingWorkflowTemplates };
};
