import { useToastMessage } from 'components/Toast/useToastMessage';
import { useSetRecurringPaymentVendorIgnoredStateMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface RemoveVendor {
  vendorId: string;
}

export const useRemoveVendor = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();

  const [setRecurringPaymentVendorIgnoredState, { loading }] =
    useSetRecurringPaymentVendorIgnoredStateMutation({
      awaitRefetchQueries: true,
      refetchQueries: ['getVendorSuggestionsForRecurringPayments'],
    });

  const handleRemoveVendor = async ({ vendorId }: RemoveVendor) => {
    const result = await setRecurringPaymentVendorIgnoredState({
      variables: {
        input: {
          vendorId,
          ignored: true,
        },
      },
    });

    if (
      result.data?.setRecurringPaymentVendorIgnoredState.__typename ===
      'RecurringPaymentVendor'
    ) {
      success(t('vendorListSuggestion.removeVendor.successMsg'));
    }

    if (
      result.data?.setRecurringPaymentVendorIgnoredState.__typename ===
      undefined
    ) {
      error(t('vendorListSuggestion.removeVendor.errorMsg'));

      return;
    }
  };

  return {
    loading,
    handleRemoveVendor,
  };
};
