import { DocumentResetByCandisEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const DocumentResetByCandisComponent = ({
  reason,
}: DocumentResetByCandisEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <>
      {t('document.compact.history.documentResetByCandis.title', {
        ticket: reason,
      })}
    </>
  );
};
