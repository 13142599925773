import { Grid, Image, LottieAnimation, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import placeholderTransactions from '../media/placeholder-tx.svg';

interface TransactionEmptyStateProps {
  hasCard: boolean;
}

export const TransactonEmptyState = ({
  hasCard,
}: TransactionEmptyStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid
      background="gray100"
      borderRadius="medium"
      padding="space16"
      alignItems="center">
      {hasCard ? (
        <Grid
          background="gray100"
          borderRadius="medium"
          padding="space16"
          placeContent="center"
          placeItems="center">
          <LottieAnimation animationData="successData" />
          <Text fontSize="large" fontWeight="500">
            {t('drawer.noTransactions')}
          </Text>
        </Grid>
      ) : (
        <Image
          src={placeholderTransactions}
          alt="no transactions placeholder"
        />
      )}
    </Grid>
  );
};
