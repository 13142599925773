import { AppRouteParams, Routes } from 'models';
import { generatePath } from 'react-router-dom-v5-compat';
import { NotificationProps } from '../getNotificationInfo';

export const getLinkToTransaction = (
  notification: NotificationProps<
    | 'TransactionDeclinedInAppNotification'
    | 'TransactionCommentCreatedInAppNotification'
  >
) => {
  const link = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}/:transactionId`,
      {
        organizationSlug: notification.organizationId,
        transactionId: notification.transactionId,
      }
    ),
  };

  return { link };
};
