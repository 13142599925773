import { useCreditCardInvolvementsLazyQuery } from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';

interface UseCreditCardInvolvementCountProps {
  membershipId: string;
}

export const useCreditCardInvolvementCount = ({
  membershipId,
}: UseCreditCardInvolvementCountProps) => {
  const [
    fetchCreditCardInvolvement,
    { data: creditCardsData, loading: creditCardsDataLoading },
  ] = useCreditCardInvolvementsLazyQuery({
    variables: { membershipId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
  });

  useFetchOnWindowFocus({
    fetch: fetchCreditCardInvolvement,
  });

  const creditCardCount =
    creditCardsData?.creditCardInvolvements.cards?.total ?? 0;

  const cardholderId = creditCardsData?.creditCardInvolvements.cardholder?.id;

  return {
    cardholderId,
    creditCardCount,
    creditCardsDataLoading,
  };
};
