import { useOtherIntegration } from 'orgConfig/other';
import { PaymentConditionsDrawerContainerCoreDataApi } from '../CoreDataApi/PaymentConditionsDrawerContainerCoreDataApi';
import { PaymentConditionsDrawerProps } from './PaymentConditionsDrawer';
import { PaymentConditionsDrawerContainerDefault } from './PaymentConditionsDrawerContainerDefault';

export interface PaymentConditionsDrawerContainerProps {
  id?: 'create' | string;
  onClose?: PaymentConditionsDrawerProps['onClose'];
}

/** Handles data fetching for PaymentConditionsDrawer component */
export const PaymentConditionsDrawerContainer = ({
  id,
  onClose,
}: PaymentConditionsDrawerContainerProps) => {
  const { shouldUseCoreDataApi } = useOtherIntegration();

  if (shouldUseCoreDataApi) {
    return (
      <PaymentConditionsDrawerContainerCoreDataApi id={id} onClose={onClose} />
    );
  }

  return <PaymentConditionsDrawerContainerDefault id={id} onClose={onClose} />;
};
