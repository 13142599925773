import {
  Card,
  Flex,
  Grid,
  Heading,
  Separator,
  Text,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useGetSpentOverviewFilters } from 'views/CreditCards/hooks/useGetSpentOverviewFilters';
import { CategoryFilter } from './CategoryFilter';
import { CreditCardFilter } from './CreditCardFilter/CreditCardFilter';
import { MerchantNameFilter } from './MerchantNameFilter';
import { SpentOverviewContainer } from './SpentOverviewContainer';

export const SpentOverviewWidget = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { allAppliedFilters, filtersFromUrl, onFilter } =
    useGetSpentOverviewFilters();

  return (
    <Card background="gray50">
      <Grid templateRows="auto min-content 1fr" gap="space4">
        <Flex
          gap="space8"
          paddingBottom="space16"
          direction="column"
          alignSelf="center">
          <Heading as="h2">{t('insights.spentWidget.title')}</Heading>
          <Flex justifyContent="space-between" alignItems="baseline">
            <Flex gap="space16" alignItems="center">
              <CreditCardFilter
                allFilters={filtersFromUrl}
                onFilter={onFilter}
                appliedFilters={allAppliedFilters.cardRefNum}
              />
              <CategoryFilter
                allFilters={filtersFromUrl}
                onFilter={onFilter}
                appliedFilters={allAppliedFilters.category}
              />
              <MerchantNameFilter
                allFilters={filtersFromUrl}
                onFilter={onFilter}
                appliedFilters={allAppliedFilters.merchantNames}
              />
            </Flex>
            <Text color="gray600" fontSize="basic">
              {t('insights.spentWidget.explanation')}
            </Text>
          </Flex>
        </Flex>
        <Separator orientation="horizontal" />
        <SpentOverviewContainer />
      </Grid>
    </Card>
  );
};
