import { useToastMessage } from 'components/Toast/useToastMessage';
import { getCardIssuerCardByIdQuery } from 'containers/credit-cards/components/CreditCardsSection/gql';
import { useGetCardById } from 'containers/credit-cards/utils';
import { useActivateCardMutation } from 'generated-types/graphql.types';
import { Routes } from 'models';
import {
  CCWalletTrackingEvents,
  useAnalytics,
} from 'providers/AnalyticsProvider';
import { usePhysicalCreditCardDispatch } from 'providers/CreditCardsProvider/PhysicalCard/PhysicalCreditCardProvider';
import { cardQueryArgs } from 'providers/EntityLoader/EntityLoader';
import { getCardIssuerCardsForCardholderQuery } from 'providers/EntityLoader/queries';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

export const useActivateCard = ({ cardId }: { cardId: string }) => {
  const [t] = useTranslation([
    LOCALE_NAME_SPACE.CREDIT_CARDS,
    LOCALE_NAME_SPACE.TRANSACTIONS,
  ]);

  const { success, error } = useToastMessage();

  const { track } = useAnalytics();
  const organizationId = useOrganizationId();
  const navigate = useNavigate();
  const location = useLocation();

  const [hasError, setHasError] = useState<boolean>(false);

  const { card, loading: isLoadingCard } = useGetCardById({ cardId });

  const dispatch = usePhysicalCreditCardDispatch();

  const handleShowCardPin = () => {
    navigate({
      pathname: `/${organizationId}${Routes.DASHBOARD_CREDIT_CARD}/${cardId}`,
      search: location.search,
    });
    dispatch({ isPinVisible: true });
  };

  const [activateCard, { loading: isActivatingCard }] =
    useActivateCardMutation();

  const onActivateCard = async (val: string) => {
    const { data, errors } = await activateCard({
      variables: {
        input: {
          cardId,
          activationCode: val,
        },
      },
      refetchQueries: [
        {
          query: getCardIssuerCardByIdQuery,
          variables: { id: cardId },
        },
        {
          query: getCardIssuerCardsForCardholderQuery,
          ...cardQueryArgs,
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: data => {
        if (data?.activateCard) {
          handleShowCardPin();
        }
      },
    });

    if (data?.activateCard) {
      success(t('transactions:carousel.activate.successMsg'));

      track(CCWalletTrackingEvents.ACTIVATE_CARD_BTN_CLICKED, {
        organizationId,
        cardType: card?.type,
        refNum: card?.refNum,
      });
    } else if (errors?.[0].message.includes('MFA')) {
      error(t('credit-cards:dashboard.drawer.form.failureMfa'));
      setHasError(true);
    } else {
      error(t('transactions:carousel.activate.errorMsg'));
      setHasError(true);
    }
  };

  return {
    isLoadingCard,
    isActivatingCard,
    hasError,
    setHasError,
    onActivateCard,
    cardBrand: card?.brand,
  };
};
