import { FieldPolicy, TypePolicies } from '@apollo/client';
import { CostCenterPaginationResult } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const costCenterPaginationFieldPolicy: FieldPolicy<CostCenterPaginationResult> =
  {
    merge: simplePaginationMergeWithoutDuplicates('CostCenterPaginationResult'),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      costCenterPagination: costCenterPaginationFieldPolicy,
    },
  },
};
