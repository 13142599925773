import i18next from 'i18next';

export const formatCountByType = ({
  documentsCount,
  transactionsCount,
  settlementsCount,
  t,
}: {
  documentsCount: number;
  transactionsCount: number;
  settlementsCount?: number;
  t: i18next.TFunction;
}) => {
  const array = [];
  if (documentsCount)
    array.push(
      t('export.provisionsSummary.types.documents', {
        count: documentsCount,
      })
    );

  if (transactionsCount)
    array.push(
      t('export.provisionsSummary.types.creditCardPayments', {
        count: transactionsCount,
      })
    );

  if (settlementsCount)
    array.push(
      t('export.provisionsSummary.types.cardSettlements', {
        count: settlementsCount,
      })
    );

  return array.join(', ');
};
