import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { qaUtilsOverwrites } from 'views/AppContainer/components/Header/components/QaUtils/utils';

const apply = (
  overwrite: boolean | null,
  fallback: boolean | null | undefined
) => {
  return Boolean(overwrite !== null ? overwrite : fallback);
};

const useOverwrites = () => {
  const [isActive] = useLocalStorage(qaUtilsOverwrites.sso.isActive, null);

  const [lastName] = useLocalStorage(
    qaUtilsOverwrites.sso.provides.lastName,
    null
  );

  const [firstName] = useLocalStorage(
    qaUtilsOverwrites.sso.provides.firstName,
    null
  );

  const [roles] = useLocalStorage(qaUtilsOverwrites.sso.provides.roles, null);

  return {
    isActive,
    lastName,
    firstName,
    roles,
  };
};

export const useLocalSSO = () => {
  const organization = useFullOrganization();
  const { isActive, firstName, lastName, roles } = useOverwrites();

  return {
    isActive: apply(isActive, organization?.hasIdpSSO),
    provides: {
      firstName: apply(
        firstName,
        organization?.idpConfig?.isUserDataUpdatedViaSSO
      ),
      lastName: apply(
        lastName,
        organization?.idpConfig?.isUserDataUpdatedViaSSO
      ),
      roles: apply(
        roles,
        organization?.idpConfig?.isMembershipRolesUpdatedViaSSO
      ),
    },
  };
};

export const useProductionSSO = () => {
  const organization = useFullOrganization();

  return {
    isActive: organization?.hasIdpSSO,
    provides: {
      firstName: organization?.idpConfig?.isUserDataUpdatedViaSSO,
      lastName: organization?.idpConfig?.isUserDataUpdatedViaSSO,
      roles: organization?.idpConfig?.isMembershipRolesUpdatedViaSSO,
    },
  };
};

export const useSSO = () => {
  const isProduction = import.meta.env.REACT_APP_STAGE === 'prod';

  const prod = useProductionSSO();
  const local = useLocalSSO();

  return isProduction ? prod : local;
};
