import { mergeProps } from '@candisio/design-system';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

export const ProcessingFormComboBoxField = ({
  name,
  readOnly,
  ...restProps
}: HookFormComboBoxFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormComboBoxField
      name={name}
      isVirtualized
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
