import {
  Maybe,
  useGetPurchaseOrderByInvoiceIdQuery,
} from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { getIsQuantityRequired } from './util/getIsQuantityRequired';

/** Fetches linked purchase order for given invoice id */
export const useLinkedPurchaseOrder = (documentId: Maybe<string>) => {
  const { shouldUseSapPurchaseOrder } = useSap();

  const { data, loading } = useGetPurchaseOrderByInvoiceIdQuery({
    variables: { invoiceId: documentId || '' },
    skip: !Boolean(documentId && shouldUseSapPurchaseOrder),
    fetchPolicy: 'cache-and-network',
  });

  const purchaseOrder = data?.getPurchaseOrderByInvoiceId;

  return {
    purchaseOrder,
    loading,
    isQuantityRequired: getIsQuantityRequired(purchaseOrder?.purchaseOrderType),
  };
};
