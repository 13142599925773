import image from 'assets/icon-email-import.svg';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { BaseItem, BaseItemProps } from './BaseItem';

const StyledEmail = styled(BaseItem)`
  & .ant-timeline-item-head {
    width: 24px;
    height: 24px;
  }
  & .ant-timeline-item-tail {
    top: 2rem;
  }

  text-transform: none !important;
`;

export const Email = (props: BaseItemProps) => {
  const [t] = useTranslation();

  return (
    <StyledEmail {...props} src={image} author={t('inbox.actions.import')} />
  );
};
