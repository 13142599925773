import { ProcessingFormExtractedContactFieldItem } from '../../../../components/Form/ProcessingForm/ProcessingFormContactField';
import { ContactFormValues } from '../../../Contacts/ContactDetails/ContactForm/contactFormSchema';

interface GenerateDefaultValuesForNewContactParams {
  extractedContact: ProcessingFormExtractedContactFieldItem | undefined;
  bdsBought: boolean;
}
export const generateDefaultValuesForNewContact = ({
  extractedContact,
  bdsBought,
}: GenerateDefaultValuesForNewContactParams): ContactFormValues => {
  return {
    createTransfer: extractedContact?.createTransfer,
    iban: extractedContact?.iban,
    taxNumber: extractedContact?.taxNumber,
    vatId: extractedContact?.vatId,
    email: extractedContact?.email,
    phoneNumber: extractedContact?.phoneNumber,
    street: extractedContact?.street,
    city: extractedContact?.city,
    postOfficeBox: extractedContact?.postOfficeBox,
    postalCode: extractedContact?.postalCode,
    bankAccountNumber: bdsBought
      ? extractedContact?.bankAccountNumber
      : undefined,
    swiftCode: extractedContact?.swiftCode,
    bankInfoType:
      extractedContact?.bankAccountNumber &&
      !extractedContact?.iban &&
      bdsBought
        ? 'NON_SEPA'
        : 'SEPA',
    country: extractedContact?.countryISOCode,
    customerNumber: extractedContact?.customerNumber,
  };
};
