import { Grid } from '@candisio/design-system';
import { PhoneNumberField } from 'components/HookFormFields/HookFormPhoneNumberField/PhoneNumberField';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { removeWhitespace } from 'utils/removeWhitespace';
import { zodResolver } from 'utils/zodFormValidation';
import { phoneNumberSchema as zodPhoneNumberSchema } from 'utils/zodFormValidation/Schemas/phoneNumberSchema';
import { z } from 'zod';

export interface PhoneNumberFormProps {
  formId: string;
  onSubmit: (phoneNumber: PhoneNumberFormValues['phoneNumber']) => void;
  isPhoneRemovalAllowed: boolean;
  currentPhoneNumber?: string;
}

export interface PhoneNumberSchemaOptions {
  isPhoneRemovalAllowed: boolean;
}

const phoneNumberSchema = ({
  isPhoneRemovalAllowed,
}: PhoneNumberSchemaOptions) => {
  return z.object({
    phoneNumber: isPhoneRemovalAllowed
      ? zodPhoneNumberSchema
      : zodPhoneNumberSchema.nullish(),
  });
};

type PhoneNumberFormValues = z.infer<ReturnType<typeof phoneNumberSchema>>;

const phoneNumberFormResolver = zodResolver({
  zodSchema: phoneNumberSchema,
  errorMessages: {
    phoneNumber: {
      label: 'header.profile.tabs.update.form.phoneNumber.label',
      custom: {
        translationKey:
          'header.profile.tabs.update.form.phoneNumber.errorInvalidPhoneNumber',
      },
    },
  },
});

export const PhoneNumberForm = ({
  isPhoneRemovalAllowed,
  formId,
  onSubmit,
  currentPhoneNumber,
}: PhoneNumberFormProps) => {
  const [t] = useTranslation();

  const context: PhoneNumberSchemaOptions = {
    isPhoneRemovalAllowed,
  };

  const defaultValues: DefaultValues<PhoneNumberFormValues> = {
    phoneNumber: currentPhoneNumber ?? null,
  };

  const form = useForm<PhoneNumberFormValues, PhoneNumberSchemaOptions>({
    context,
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // @ts-expect-error react-hook-form types the context as `Context | undefined`, but it is not `undefined` here
    resolver: phoneNumberFormResolver,
  });

  const handleSubmit = async ({ phoneNumber }: PhoneNumberFormValues) => {
    if (
      phoneNumber &&
      currentPhoneNumber &&
      removeWhitespace(phoneNumber) === removeWhitespace(currentPhoneNumber)
    ) {
      form.setError('phoneNumber', {
        message: t(
          'header.profile.tabs.update.form.phoneNumber.errorNewPhoneNumberSameAsOld'
        ),
      });

      return;
    }

    return onSubmit(phoneNumber);
  };

  return (
    <FormProvider<PhoneNumberFormValues> {...form}>
      <Grid as="form" id={formId} onSubmit={form.handleSubmit(handleSubmit)}>
        <PhoneNumberField<PhoneNumberFormValues>
          autoFocus
          name="phoneNumber"
          label={t('header.profile.tabs.update.form.phoneNumber.label')}
          dialingCodeLabel={t(
            'header.profile.tabs.update.form.dialingCode.label'
          )}
        />
      </Grid>
    </FormProvider>
  );
};
