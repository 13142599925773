import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { UsePurchaseOrderFieldOptionsResult } from 'components/PurchaseOrders/hooks/usePurchaseOrderNumberFieldOptions';
import { useHover } from 'react-aria';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EmptyListState } from './EmptyListState';
import { getFormattedPurchaseOrderItem } from './getFormattedPurchaseOrderItem';
import { PurchaseOrderDropdownMenu } from './PurchaseOrderDropdownMenu';

export interface PurchaseOrderFieldProps {
  contactName?: string;
  fieldOptions?: UsePurchaseOrderFieldOptionsResult;
}

export const PurchaseOrderListField = ({
  contactName,
  fieldOptions,
}: PurchaseOrderFieldProps) => {
  const [t] = useTranslation();
  const { isHovered, hoverProps } = useHover({});
  const form = useForm();

  const props = fieldOptions?.props;
  const linkPurchaseOrder = fieldOptions?.linkPurchaseOrder;

  const handlePurchaseOrderSelection = (value: string | null) => {
    if (value) {
      void linkPurchaseOrder?.(value);
    }
  };

  return (
    <FormProvider {...form}>
      <HookFormPaginatedComboBoxField
        showSeparator
        name="purchaseOrderNumber"
        itemHeight="space64"
        loading={props?.isLoading}
        emptyListPlaceholder={
          <EmptyListState hoverProps={hoverProps} contactName={contactName} />
        }
        label={t('document.requestApproval.inputs.purchaseOrderNumber.label')}
        placeholder={t(
          'document.requestApproval.inputs.purchaseOrderNumber.listPlaceholder'
        )}
        {...props}
        items={getFormattedPurchaseOrderItem({ items: props?.items })}
        persistDropdown={isHovered}
        renderCustomDropdown={listbox => (
          <PurchaseOrderDropdownMenu
            hoverProps={hoverProps}
            listbox={listbox}
          />
        )}
        onChange={val => {
          handlePurchaseOrderSelection(val);
        }}
      />
    </FormProvider>
  );
};
