import { Chip } from '@candisio/design-system';
import { HookFormChipsFieldItem } from './HookFormChipsField';

export interface ChipItemProps {
  id: string;
  defaultItems: HookFormChipsFieldItem[];
  onRemove: () => void;
  isDisabled?: boolean;
}

export const ChipItem = ({
  id,
  isDisabled,
  defaultItems,
  onRemove,
}: ChipItemProps) => {
  const chip = defaultItems.find(chip => chip.id === id);
  if (!chip) return null;

  return (
    <Chip
      size="xsmall"
      variant="lightGray"
      type="removable"
      isDisabled={isDisabled}
      onClickButton={onRemove}>
      {chip.name}
    </Chip>
  );
};
