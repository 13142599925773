import { Card, Grid, Tag, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import DateFormatters from 'utils/date_formatter';
import { useIsFourteenDaysPassed } from './hooks/useIsFourteenDaysPassed';

interface InformationBoxProps {
  shippingDate: string | undefined;
}

const translations = {
  withoutShippingDate: 'carousel.activate.description',
  withShippingDate: 'carousel.activate.descriptionWithShippingDate',
  cardDidNotArrive: 'carousel.activate.cardNotArrivedYet',
};

export const InformationCard = ({ shippingDate }: InformationBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const compactShippingDate = shippingDate
    ? DateFormatters.compact(new Date(shippingDate))
    : undefined;

  const { isFourteenDaysPassed } = useIsFourteenDaysPassed({ shippingDate });

  const responsiveWidth = useMediaQuery({
    maxWidth: 1500,
  })
    ? '144px'
    : undefined;

  const translation = Boolean(compactShippingDate)
    ? isFourteenDaysPassed
      ? t(translations.cardDidNotArrive)
      : t(translations.withShippingDate, { shippingDate: compactShippingDate })
    : t(translations.withoutShippingDate);

  return (
    <Card
      background="gray200"
      borderRadius="8px"
      justifySelf="end"
      alignSelf="start"
      maxWidth="260px"
      width={responsiveWidth}
      padding="space12"
      boxShadow="none">
      <Grid gap="space8">
        <Tag color="yellow" height="18px">
          {t('carousel.activate.tag')}
        </Tag>
        <Text color="gray600">{translation}</Text>
      </Grid>
    </Card>
  );
};
