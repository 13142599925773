import { Button, ButtonProps, mergeProps } from '@candisio/design-system';
import { AcknowledgePopover } from 'components/AcknowledgePopover/AcknowledgePopover';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { useTranslation } from 'react-i18next';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';

export interface DeactivateMembershipButtonLegacyRolesProps {
  involvedCount: number;
  onDeactivateMembership: () => void;
  disabled: boolean;
  loading: boolean;
  pendingCount: number;
  onForceDeactivateMembership: (membershipId: string) => void;
  selectedUser: User;
}

export const DeactivateMembershipButtonLegacyRoles = ({
  involvedCount,
  onDeactivateMembership,
  disabled,
  loading,
  pendingCount,
  onForceDeactivateMembership,
  selectedUser,
}: DeactivateMembershipButtonLegacyRolesProps) => {
  const [t] = useTranslation();
  const buttonProps: ButtonProps = {
    color: 'red',
    disabled,
    loading,
    variant: 'tertiary',
    children: t('settings.teamMembers.form.actions.deactivateMembership'),
  };

  if (pendingCount > 0 && involvedCount < 1) {
    return (
      <AcknowledgePopover
        onAcknowledged={() => {
          onForceDeactivateMembership(selectedUser.id);
        }}
        title={t(
          'settings.teamMembers.form.involvement.hasPendingApprovalsTitle'
        )}
        acknowledgeText={t(
          'settings.teamMembers.form.involvement.hasPendingApprovalsMessage',
          { numberOfPendingApprovals: pendingCount }
        )}
        confirmButtonProps={{ loading }}
        trigger={({ triggerProps, triggerRef }) => (
          <Button {...mergeProps(buttonProps, triggerProps)} ref={triggerRef} />
        )}></AcknowledgePopover>
    );
  }

  return (
    <ConfirmationButton
      {...buttonProps}
      confirmationTitle={t(
        'settings.teamMembers.form.actions.deactivateMembershipAreYouSure'
      )}
      onConfirm={onDeactivateMembership}
    />
  );
};
