import {
  Card,
  Image,
  Grid,
  Text,
  Separator,
  Flex,
} from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { VIEW_URL_PARAM, VIEWS } from 'views/Reimbursement/Reimbursement';
import recieptIcon from '../../media/receipt.svg';
import { CreateExpenseActionMenuButton } from '../CreateExpenseActionMenuButton';

interface ExpenseDetailsCardWithActionProps {
  totalAmount: string;
  expenseCount: number;
  creatingExpense: boolean;
  onGeneralExpenseClick: () => void;
  onHospitalityExpenseClick: () => void;
}

export const ExpenseDetailsCardWithAction = ({
  totalAmount,
  expenseCount,
  creatingExpense,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: ExpenseDetailsCardWithActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();

  const count = expenseCount || undefined;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    updateSearchParam(VIEW_URL_PARAM, VIEWS.Expenses);
  };

  return (
    <Flex direction="column" cursor="pointer" onClick={handleClick}>
      <Card
        padding="space24"
        borderBottomRadius="none"
        hover={{ background: 'gray100' }}>
        <Grid templateColumns="auto 1fr auto" gap="space24" placeItems="center">
          <Card background="pink100">
            <Image
              src={recieptIcon}
              alt="reciept icon"
              height="space32"
              width="space32"
            />
          </Card>
          <Grid gap="space8">
            <Grid
              autoFlow="column"
              width="fit-content"
              gap="space2"
              fontSize="xlarge"
              fontWeight="semibold">
              <Text>
                {t('reimbursementView.middleSection.emptyDetails.title')}
              </Text>
              {count && <Text>({count})</Text>}
            </Grid>
            <Text fontSize="basic">
              {t('reimbursementView.middleSection.emptyDetails.description')}
            </Text>
          </Grid>
          <Text fontSize="basic" fontWeight="semibold">
            {totalAmount}
          </Text>
        </Grid>
      </Card>
      <Separator color="gray100" />
      <Card borderTopRadius="none" padding="space12">
        <Grid placeItems="center">
          <CreateExpenseActionMenuButton
            creatingExpense={creatingExpense}
            onGeneralExpenseClick={onGeneralExpenseClick}
            onHospitalityExpenseClick={onHospitalityExpenseClick}
          />
        </Grid>
      </Card>
    </Flex>
  );
};
