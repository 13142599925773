import { Grid, Image, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import NoInvoice from '../media/rejected.png';

export const InvoiceUploadDisabledState = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <>
      <Grid placeItems="center">
        <Image src={NoInvoice} alt="No invoice" />
        <Text fontSize="basic">
          {t('transactionAssociation.noInvoiceNeeded.title')}
        </Text>
      </Grid>
    </>
  );
};
