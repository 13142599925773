import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { ReimbursementLayout } from './components/ReimbursementLayout';
import { ReimbursementFormsProvider } from './context/ReimbursementFormsProvider';
import { LeftSectionContainer } from './LeftSectionContainer';
import { MiddleSectionContainer } from './MiddleSectionContainer';
import { RightSectionContainer } from './RightSectionContainer';

export const VIEW_URL_PARAM = 'view';
export const VIEWS = {
  Expenses: 'expenses',
} as const;

export const Reimbursement = () => {
  const formHelpers = useExpenseFormsHelpers();

  return (
    <ReimbursementFormsProvider>
      <ReimbursementLayout
        leftSection={<LeftSectionContainer formHelpers={formHelpers} />}
        middleSection={<MiddleSectionContainer formHelpers={formHelpers} />}
        rightSection={<RightSectionContainer />}
      />
    </ReimbursementFormsProvider>
  );
};
