import { AppRouteParams, Routes } from 'models';
import { generatePath } from 'react-router-dom-v5-compat';
import { NotificationProps } from '../getNotificationInfo';

export const getLinkToDocument = (
  notification: NotificationProps<'DocumentApprovalRequestedInAppNotification'>
) => {
  const link = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}/:documentId`,
      {
        documentId: notification.documentId,
        organizationSlug: notification.organizationId,
      }
    ),
  };

  return { link };
};
