import { ContactFormErrorMessages } from './contactFormSchema';

/** Error message translation keys for contact form */
export const contactFormErrorMessages: ContactFormErrorMessages = {
  name: {
    label: 'settings.contacts.details.edit.name.label',
    custom: {
      translationKey: 'settings.contacts.details.edit.errors.duplicate',
    },
  },
  accountsPayableNumber: {
    label: 'settings.contacts.details.edit.accountsPayableNumber.label',
    custom: {
      translationKey:
        'settings.contacts.details.edit.accountsPayableNumber.errorDuplicate',
    },
  },
  accountsReceivableNumber: {
    label: 'settings.contacts.details.edit.accountsReceivableNumber.label',
    custom: {
      translationKey:
        'settings.contacts.details.edit.accountsReceivableNumber.errorDuplicate',
    },
  },
  customerNumber: {
    label: 'settings.contacts.details.edit.customerNumber.label',
  },
  email: {
    label: 'settings.contacts.details.edit.email.label',
  },
  phoneNumber: {
    label: 'settings.contacts.details.edit.phoneNumber.label',
  },
  iban: {
    label: 'settings.contacts.details.edit.iban.label',
  },
  vatId: {
    label: 'settings.contacts.details.edit.vatId.label',
  },
  taxNumber: {
    label: 'settings.contacts.details.edit.taxNumber.label',
  },
  createTransfer: {
    label: 'settings.contacts.details.edit.createTransfer.label',
  },
  paymentCondition: {
    label: 'settings.contacts.details.edit.paymentCondition.label',
  },
  paymentMedium: {
    label: 'settings.contacts.details.edit.paymentMedium.label',
  },
  companyName: {
    label: 'settings.contacts.details.edit.companyName.label',
    custom: {
      translationKey: 'settings.contacts.details.edit.errors.duplicate',
    },
  },
  contactType: {
    label: 'settings.contacts.details.edit.contactType.label',
  },
  individualFirstName: {
    label: 'settings.contacts.details.edit.individualFirstName.label',
  },
  individualLastName: {
    label: 'settings.contacts.details.edit.individualLastName.label',
    custom: {
      translationKey: 'settings.contacts.details.edit.errors.duplicate',
    },
  },
  notSpecifiedName: {
    label: 'settings.contacts.details.edit.notSpecifiedName.label',
    custom: {
      translationKey: 'settings.contacts.details.edit.errors.duplicate',
    },
  },
  relationshipType: {
    label: 'settings.contacts.details.edit.relationshipType.label',
  },
  bankAccountNumber: {
    label: 'settings.contacts.details.edit.bankInfo.bankAccountNumber',
    invalid_string: {
      translationKey:
        'settings.contacts.details.edit.errors.onlyNumbersAllowed',
    },
  },
  bankInfoType: {
    label: 'settings.contacts.details.edit.bankInfo.type',
  },
  swiftCode: {
    label: 'settings.contacts.details.edit.bankInfo.swiftCode',
  },
  addressInfoType: {
    label: 'settings.contacts.details.edit.addressInfo.type.label',
  },
  city: {
    label: 'settings.contacts.details.edit.addressInfo.city',
  },
  country: {
    label: 'settings.contacts.details.edit.addressInfo.country',
  },
  postalCode: {
    label: 'settings.contacts.details.edit.addressInfo.postalCode',
  },
  postOfficeBox: {
    label: 'settings.contacts.details.edit.addressInfo.poBox',
  },
  street: {
    label: 'settings.contacts.details.edit.addressInfo.street',
  },
};
