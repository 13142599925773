import { ReactNode } from 'react';
import { ReadOnlyTransactionFormContainer } from './ReadOnlyAccountingDataForm/ReadOnlyTransactionFormContainer';
import { TransactionReadOnlySideBar } from './TransactionReadOnlySidebar';

export interface RightSectionApproverProps {
  associationBox: ReactNode;
  isInvoicedNotNeeded: boolean;
  isTxExported: boolean;
}

export const RightSectionApprover = ({
  associationBox,
  isInvoicedNotNeeded,
  isTxExported,
}: RightSectionApproverProps) => {
  return (
    <TransactionReadOnlySideBar
      actionBox={associationBox}
      isTxExported={isTxExported}
      isInvoicedNotNeeded={isInvoicedNotNeeded}>
      <ReadOnlyTransactionFormContainer isTransactionExported={isTxExported} />
    </TransactionReadOnlySideBar>
  );
};
