import { mergeProps } from '@candisio/design-system';
import {
  HookFormUsersField,
  HookFormUsersFieldProps,
} from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { useReadOnlyTransactionFormField } from '../hooks/useReadOnlyTransactionFormField';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';

export const ReadOnlyTransactionApproversField = ({
  name,
  ...restProps
}: HookFormUsersFieldProps<ReadOnlyTransactionFormValues>) => {
  const { fieldProps } = useReadOnlyTransactionFormField('approvers');

  return (
    <HookFormUsersField name={name} {...mergeProps(restProps, fieldProps)} />
  );
};
