import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Image,
  Link,
  Paragraph,
  Text,
} from '@candisio/design-system';
import appleStoreLogoDE from 'containers/credit-cards/components/CreditCardsSection/media/applestore-logo-de.svg';
import appleStoreLogoEN from 'containers/credit-cards/components/CreditCardsSection/media/applestore-logo-en.svg';
import googlePlayLogoDE from 'containers/credit-cards/components/CreditCardsSection/media/googleplay-de.svg';
import googlePlayLogoEN from 'containers/credit-cards/components/CreditCardsSection/media/googleplay-en.svg';
import { CreditCard } from 'containers/credit-cards/CreditCard';
import { extractCardDetailsPlaceholder } from 'containers/credit-cards/utils';
import { CardStatus, CardType } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { pliantWalletLink } from '../consts';

const appleStoreLogo: Record<string, string> = {
  de: appleStoreLogoDE,
  en: appleStoreLogoEN,
};

const bulletListItems = [
  'ccRequestState.activeRequest.pliantAppSection.listItem1',
  'ccRequestState.activeRequest.pliantAppSection.listItem2',
  'ccRequestState.activeRequest.pliantAppSection.listItem3',
];

const googlePlayLogo: Record<string, string> = {
  de: googlePlayLogoDE,
  en: googlePlayLogoEN,
};

const appleStoreLink = 'https://apps.apple.com/de/app/pliant/id1535026245';
const googlePlayLink =
  'https://play.google.com/store/apps/details?id=com.getpliant';

export const ActiveCreditCardRequestCardPliant = ({
  userName,
}: {
  userName: string;
}) => {
  const [tDashboard] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);

  const placeholderDetails = extractCardDetailsPlaceholder(userName);

  return (
    <>
      <Flex alignItems="center" gap="space10" paddingBottom="space12">
        <Text fontSize="xlarge" fontWeight="semibold">
          {tDashboard('ccRequestState.activeRequest.pliantAppSection.title')}
        </Text>
      </Flex>
      <Grid
        minHeight="300px"
        alignItems="start"
        gap="space16"
        templateColumns="auto 1fr">
        <CreditCard
          initialState={CardStatus.Active}
          creditCardType={CardType.Virtual}
          isCandisCreditCard={true}
          details={placeholderDetails}
        />
        <Flex gap="space8" direction="column">
          <Card
            boxShadow="noShadow"
            background="gray0"
            paddingX="space16"
            paddingY="space10">
            <Flex
              direction="column"
              gap="space8"
              justifyContent="space-between">
              <Flex direction="column" gap="space4">
                <Text fontWeight="semibold" fontSize="basic">
                  {tDashboard(
                    'ccRequestState.activeRequest.pliantAppSection.cardTitle'
                  )}
                </Text>
                <Box fontWeight="regular">
                  <Paragraph>
                    {tDashboard(
                      'ccRequestState.activeRequest.pliantAppSection.cardDescription'
                    )}
                  </Paragraph>
                  <Text>
                    {tDashboard(
                      'ccRequestState.activeRequest.pliantAppSection.cardHelpText'
                    )}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" width="100%">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() =>
                    window.open(
                      pliantWalletLink,
                      '_blank',
                      'noopener noreferrer'
                    )
                  }>
                  {tDashboard(
                    'ccRequestState.activeRequest.pliantAppSection.pliantCtaButton'
                  )}
                </Button>
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() =>
                    window.open(
                      pliantWalletLink,
                      '_blank',
                      'noopener noreferrer'
                    )
                  }>
                  {tDashboard(
                    'ccRequestState.activeRequest.pliantAppSection.requestCardCtaButton'
                  )}
                </Button>
              </Flex>
            </Flex>
          </Card>
          <Card
            boxShadow="noShadow"
            background="bluebg"
            paddingY="space8"
            paddingX="space16">
            <Flex direction="column" gap="space4">
              <Text fontWeight="semibold" fontSize="basic">
                {tDashboard(
                  'ccRequestState.activeRequest.pliantAppSection.listTitle'
                )}
              </Text>
              <Box as="ul" paddingLeft="space20" lineHeight="space24">
                {bulletListItems.map((listItem, index) => (
                  <li key={index}>{tDashboard(listItem)}</li>
                ))}
              </Box>
            </Flex>
            <Flex gap="space4">
              {/* We don't pass `external` prop here to avoid
                  showing newTab icon next to the images */}
              <Link
                href={appleStoreLink}
                target="_blank"
                rel="noopener noreferrer">
                <Image
                  height="space24"
                  src={appleStoreLogo[i18next.language]}
                  alt="apple store logo"
                />
              </Link>
              <Link
                href={googlePlayLink}
                target="_blank"
                rel="noopener noreferrer">
                <Image
                  height="space24"
                  src={googlePlayLogo[i18next.language]}
                  alt="google play logo"
                />
              </Link>
            </Flex>
          </Card>
        </Flex>
      </Grid>
    </>
  );
};
