import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { useCalculateRoundingAmount } from 'components/Form/utils';
import {
  grossToNet,
  netToGross,
  sumNetAmounts,
  sumTaxAmounts,
} from 'containers/SplitBookings/toolkit/utils';
import {
  DocumentCurrency,
  useBookingKeysActiveQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useEffect, useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';

export default function useWatchApprovalForm(
  form: UseFormReturn<DocumentApprovalFormValues>
) {
  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery();
  const calculateRoundingAmount = useCalculateRoundingAmount();
  const { isActiveIntegration: isSapIntegration, shouldUseSapNetAmount } =
    useSap();

  const [netAndTaxAmountFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.netAndTaxAmount,
  ]);

  if (netAndTaxAmountFF) {
    form.watch('bookings.0.taxCode');
  }

  const updateRoundingAmount = useCallback(
    async (values: DocumentApprovalFormValues) => {
      if (!shouldUseSapNetAmount) return;
      const roundingAmount = await calculateRoundingAmount({
        currency: values.currency as DocumentCurrency,
        grossAmount: values.grossAmount ?? 0,
        bookings: values.bookings ?? [],
      });

      form.setValue('roundingAmount', roundingAmount ?? 0);
    },
    [calculateRoundingAmount, form, shouldUseSapNetAmount]
  );

  const updateAmounts = useCallback(
    async (values: DocumentApprovalFormValues) => {
      if (isSapIntegration) {
        // For SAP integrations gross is recalculated based on net
        const netAmount = (values.bookings || [])[0]?.netAmount ?? 0;
        const amount = netToGross(
          netAmount,
          (values.bookings || [])[0]?.vatRate ?? 0
        );

        const taxAmount = roundToCurrencyPrecision(amount - netAmount);

        form.setValue('bookings.0.amount', amount);
        form.setValue('bookings.0.taxAmount', taxAmount);
        await updateRoundingAmount(values);
      } else {
        const amount = (values.bookings || [])[0]?.amount ?? 0;
        const netAmount = grossToNet(
          amount,
          (values.bookings || [])[0]?.vatRate ?? 0
        );

        const taxAmount = roundToCurrencyPrecision(amount - netAmount);

        form.setValue('bookings.0.netAmount', netAmount);
        form.setValue('bookings.0.taxAmount', taxAmount);
      }

      if (!values.bookings) return;

      form.setValue(
        'netAmount',
        sumNetAmounts(
          values.bookings.map(booking => ({
            netAmount: booking?.netAmount ?? 0,
          }))
        )
      );
      form.setValue(
        'taxAmount',
        sumTaxAmounts(
          values.bookings.map(booking => ({
            taxAmount: booking?.taxAmount ?? 0,
          }))
        )
      );
    },
    [form, isSapIntegration, updateRoundingAmount]
  );

  const handleTaxCodeChange = useCallback(
    /** @ts-expect-error TODO: React upgrade props types mismatch */
    values => {
      if (values.bookings?.length === 1 && netAndTaxAmountFF) {
        const selectedBookingKey =
          bookingKeysActiveData?.bookingKeysActive.find(
            bookingKey => bookingKey.id === (values.bookings || [])[0]?.taxCode
          );

        form.setValue(
          'bookings.0.vatRate',
          selectedBookingKey?.taxPercentage ?? 0
        );
      }
    },
    [bookingKeysActiveData, form, netAndTaxAmountFF]
  );

  const handleVatRateChange = useCallback(
    /** @ts-expect-error TODO: React upgrade props types mismatch */
    async values => {
      if (values.bookings?.length === 1 && netAndTaxAmountFF) {
        await updateAmounts(values);
      }
    },
    [netAndTaxAmountFF, updateAmounts]
  );

  useEffect(() => {
    const subscription = form.watch(async (values, { name }) => {
      // Type guard for bookings and netAndTaxAmountFF
      if (values.bookings?.length !== 1 || !netAndTaxAmountFF) return;

      switch (name) {
        case 'bookings.0.taxCode':
          handleTaxCodeChange(values);
          break;
        case 'bookings.0.vatRate':
          await handleVatRateChange(values);
          break;
        default:
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [
    bookingKeysActiveData?.bookingKeysActive,
    calculateRoundingAmount,
    form,
    form.watch,
    handleTaxCodeChange,
    handleVatRateChange,
    isSapIntegration,
    netAndTaxAmountFF,
    shouldUseSapNetAmount,
  ]);
}
