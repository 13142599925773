import { useToastMessage } from 'components/Toast/useToastMessage';
import { useConvertDocumentsToAttachmentsMutation } from 'generated-types/graphql.types';
import { countDocumentsByStatusNew } from 'hooks/useCounterQueries';
import { getInboxDocumentNavigationQueries } from 'hooks/useFetchDocNavigationData/queries';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import useRouter from 'use-react-router';
import { extractCursorFromQs } from 'utils/url-helper';
import { documentQueries } from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';
import { documentFileQuery } from '../gql';
import { AttachmentsValidationsError } from './useAttachmentsValidations';

export const useAttachDocuments = (
  errorType: AttachmentsValidationsError | null
) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const [convertDocumentsToAttachments, { loading: isAttachingDocuments }] =
    useConvertDocumentsToAttachmentsMutation();

  const router = useRouter();
  const currentDocumentCursor = extractCursorFromQs(router.location.search);

  const attachDocuments = useCallback(
    async (documentId: string, selectedDocumentsIds: string[]) => {
      if (errorType !== null) {
        return;
      }

      const { errors } = await convertDocumentsToAttachments({
        variables: {
          input: {
            documentId,
            // filter undefined documents
            documentsToConvertIds: selectedDocumentsIds,
          },
        },
        refetchQueries: [
          {
            query: getInboxDocumentNavigationQueries,
            variables: { cursor: currentDocumentCursor },
          },
          {
            query: countDocumentsByStatusNew,
          },
          {
            query: documentQueries.forDraftForm,
            variables: { id: documentId },
          },
          {
            query: documentHistoryQuery,
            variables: { id: documentId },
          },
          {
            query: documentFileQuery,
            variables: {
              id: documentId,
            },
          },
        ],
      });

      if (errors) {
        error(
          t(
            'document.attachments.errors.couldNotConvertDocumentsToAttachments',
            {
              count: selectedDocumentsIds.length,
            }
          )
        );

        return;
      }

      success(
        t('document.attachments.success.convertedDocumentsToAttachments', {
          count: selectedDocumentsIds.length,
        })
      );
    },
    [
      errorType,
      convertDocumentsToAttachments,
      currentDocumentCursor,
      success,
      t,
      error,
    ]
  );

  return {
    attachDocuments,
    isAttachingDocuments,
  };
};
