import { CardType, useGetCountCardsQuery } from 'generated-types/graphql.types';
import { activeStatuses } from './useCardIssuerCardsForCardManagerData';

export const useCreditCardCountData = () => {
  const { data, loading } = useGetCountCardsQuery({
    variables: {
      filters: {
        statuses: activeStatuses,
        types: [CardType.Physical, CardType.Virtual, CardType.SingleUse],
      },
    },
  });

  const totalNumberOfActiveCards = data?.countCards?.total ?? 0;

  return {
    loading,
    totalNumberOfActiveCards,
  };
};
