import { Button, Flex, Grid } from '@candisio/design-system';
import { ArchiveWrapper } from 'components/ArchiveWrapper/ArchiveWrapper';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormEvent, RefCallback, useRef } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { ApproversField } from './toolkit/fields/ApproversField';
import { ConditionFields } from './toolkit/fields/ConditionFields';
import { TitleFields } from './toolkit/fields/TitleFields';
import {
  workflowTemplateSchema,
  WorkflowTemplateFormValues,
  getWorkflowTemplatesErrorMessages,
} from './toolkit/schema';
import { WorkflowTemplateFormProps } from './toolkit/types';

export const WorkflowTemplatesForm = ({
  onArchive,
  onRestore,
  onSubmit,
  approverDropdownOptions,
  canArchive,
  defaultFormValues,
  existingWorkflowTemplates,
  isArchived,
  isDisabled,
  isSubmitting,
}: WorkflowTemplateFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const form = useForm<WorkflowTemplateFormValues>({
    defaultValues: defaultFormValues,
    mode: 'onTouched',
    resolver: zodResolver({
      zodSchema: workflowTemplateSchema(existingWorkflowTemplates ?? []),
      errorMessages: getWorkflowTemplatesErrorMessages(),
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
    shouldFocusError: true,
  });

  const {
    insert,
    fields: steps,
    remove,
  } = useFieldArray<WorkflowTemplateFormValues>({
    name: 'steps',
    control: form.control,
  });

  const buttonRefs = useRef<HTMLButtonElement[]>([]);
  const addRef =
    (idx: number): RefCallback<HTMLButtonElement> =>
    (el: HTMLButtonElement) =>
      (buttonRefs.current[idx] = el);

  const insertStep = (atIndex: number, shouldScroll = true) => {
    insert(atIndex, {
      approvers: [],
      conditions: [],
    });

    if (shouldScroll) {
      setTimeout(() => {
        buttonRefs.current[atIndex]?.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <FormProvider {...form}>
      <Flex
        height="calc(100vh - 120px)"
        direction="column"
        as="form"
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          void handleSubmit(e);
        }}>
        <ArchiveWrapper
          onArchive={onArchive}
          isSubmitting={isSubmitting}
          onRestore={onRestore}
          archived={isArchived}
          disable={!canArchive}
          actions={
            <Button type="submit" disabled={!!isArchived}>
              {t('workflows.details.edit.actions.save')}
            </Button>
          }>
          <TitleFields isArchived={isArchived} />
          <Grid gap="space40" padding="space24 space40 0">
            {steps.map((step, index) => {
              const showConditions = index > 0;
              const showDelete = steps.length > 1 && !isDisabled;
              const showInsertStepOne =
                steps.length > 1 && index === 0 && !isDisabled;

              return (
                <Grid key={step.id}>
                  {showInsertStepOne && (
                    <Flex paddingBottom="space24" justifyContent="center">
                      <Button
                        ref={addRef(0)}
                        variant="tertiary"
                        icon="plus"
                        onClick={() => insertStep(0, false)}>
                        {t('workflows.details.edit.steps.add')}
                      </Button>
                    </Flex>
                  )}
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    as="h3"
                    fontSize="xlarge"
                    gap="space8">
                    {`${t('workflows.details.edit.steps.title')} ${index + 1}`}
                    {showDelete && (
                      <Button
                        icon="trash"
                        label={t('workflows.details.edit.steps.remove')}
                        onClick={() => {
                          remove(index);
                        }}
                        variant="tertiary"
                      />
                    )}
                  </Flex>
                  {showConditions && (
                    <ConditionFields
                      stepsIndex={index}
                      isArchived={isArchived}
                    />
                  )}
                  <ApproversField
                    approverDropdownOptions={approverDropdownOptions}
                    showConditions={showConditions}
                    isArchived={isArchived}
                    stepIndex={index}
                  />
                  {!isDisabled && (
                    <Flex paddingTop="space24" justifyContent="center">
                      <Button
                        ref={addRef(index + 1)}
                        variant="tertiary"
                        icon="plus"
                        onClick={() => insertStep(index + 1)}>
                        {t('workflows.details.edit.steps.add')}
                      </Button>
                    </Flex>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </ArchiveWrapper>
      </Flex>
    </FormProvider>
  );
};
