import { FilterOptionsAccessor } from '@candisio/design-system';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountingAreasList } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasList';
import { useStatusFilters } from './consts';

interface UseApprovalDocumentsDataFiltersProps {
  approvalsTableData: DocumentsTableData[];
}

export const useApprovalDocumentsDataFilters = ({
  approvalsTableData,
}: UseApprovalDocumentsDataFiltersProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const statusFilters = useStatusFilters();

  const {
    allAccountingAreasListData,
    listDataLoading: accountingAreasLoading,
  } = useAccountingAreasList();

  const accountingAreas = useMemo(() => {
    return allAccountingAreasListData?.map(area => ({
      id: area.id,
      label: area.name,
    }));
  }, [allAccountingAreasListData]);

  const filterOptions: FilterOptionsAccessor<DocumentsTableData> =
    useMemo(() => {
      return {
        status: {
          data: statusFilters?.map(statusFilter => ({
            id: statusFilter.id,
            // eslint-disable-next-line candis/no-template-strings-inside-translation
            label: t(`${statusFilter.label}`),
          })),
        },
        accountingArea: {
          data: accountingAreas,
          isLoading: accountingAreasLoading,
        },
        creditCardPayment: {
          data: [
            {
              id: 'true',
              label: t(
                'documents-table:creditCardPaymentFilter.creditCardPayment'
              ),
            },
            {
              id: 'false',
              label: t(
                'documents-table:creditCardPaymentFilter.noCreditCardPayment'
              ),
            },
          ],
        },
      };
    }, [statusFilters, t, accountingAreas, accountingAreasLoading]);

  return {
    filterOptions,
    filtersLoading: accountingAreasLoading,
  };
};
