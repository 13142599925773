import { Grid, Text } from '@candisio/design-system';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { VatIdAvailable } from '../utils/organizationSettingsFormSchema';

export const VatIdRadioGroup = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const options: Array<{ value: VatIdAvailable; children: string }> = [
    {
      value: 'Yes',
      children: t(
        'insights.organizationSettingsDrawer.form.fields.vatIdRadioGroup.yes'
      ),
    },
    {
      value: 'No',
      children: t(
        'insights.organizationSettingsDrawer.form.fields.vatIdRadioGroup.no'
      ),
    },
  ];

  return (
    <Grid templateColumns="auto 1fr" gap="space16">
      <Text fontSize="basic">
        {t(
          'insights.organizationSettingsDrawer.form.fields.vatIdRadioGroup.label'
        )}
      </Text>
      <HookFormRadioGroupField
        name="vatIdAvailable"
        options={options}
        orientation="horizontal"
      />
    </Grid>
  );
};
