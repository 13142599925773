import gql from 'graphql-tag';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';

export const getEcmSensitiveDocumentsQuery = gql`
  query getEcmSensitiveDocuments(
    $next: String
    $limit: Int
    $search: String
    $sort: EcmSortInput
    $filter: EcmFilterInput
  ) {
    getEcmDocuments(
      next: $next
      limit: $limit
      search: $search
      sort: $sort
      filter: $filter
    ) {
      edges {
        cursor
        record {
          contactId
          contactName
          createdAt
          documentName
          documentNumber
          endDate
          id
          invoiceId
          tags {
            ...Tag
          }
        }
      }
      pageInfo {
        hasNextPage
        nextPageCursor
        totalCount
      }
    }
  }
  ${tagFragment}
`;

export const getEcmSensitiveDocumentsCountQuery = gql`
  query getEcmSensitiveDocumentsCount($filter: EcmFilterInput) {
    getEcmDocuments(filter: $filter) {
      edges {
        cursor
      }
      pageInfo {
        totalCount
      }
    }
  }
`;
