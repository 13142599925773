import {
  AriaTabListProps,
  Box,
  Grid,
  TabItem as DSTabItem,
  TabPanel,
  Tabs as DSTabs,
  useTabListState,
} from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface TabItem extends Pick<DSTabItem, 'title' | 'children'> {
  tabTooltip?: string;
}

interface TabsProps extends AriaTabListProps<TabItem> {
  chatBox?: ReactNode;
}

export const Tabs = ({ chatBox, ...props }: TabsProps) => {
  const [t] = useTranslation();
  const state = useTabListState(props);

  const showChatBox =
    chatBox && state.selectedKey === t('document.tabs.history');

  return (
    <Grid overflow="hidden">
      {/* focus should only be visible on tab, not on click */}
      <Box borderBottom="1px solid gray300">
        <DSTabs alignment="stretch" {...props} state={state} />
      </Box>
      {showChatBox && (
        <Box zIndex={2} paddingTop="space16">
          {chatBox}
        </Box>
      )}
      <Box overflow="auto" paddingTop={showChatBox ? 'space8' : 'space16'}>
        <TabPanel
          key={state.selectedItem?.key}
          state={state}
          overflow="auto"
          height="100%"
          borderRadius="medium"
        />
      </Box>
    </Grid>
  );
};
