import { isNil, sum } from 'lodash';
import moment from 'moment';
import {
  SimplifiedPaidDocument,
  SimplifiedPayableDocument,
} from 'views/Payments/types';

/**
 * Gets the sum of cash discounts (in cents) applicable on a given payment date
 * in an array of payable or paid documents
 */
export const sumCashDiscounts = (
  /** Payable or paid documents */
  documents: SimplifiedPayableDocument[] | SimplifiedPaidDocument[],
  /** Payment date to base the calculation on (defaults to today if omitted) */
  date?: Date
) => {
  const today = moment();
  const paymentDate = date ? moment(date) : today;

  return sum(
    documents.map(document => {
      if (
        isNil(document.discountPaymentDate) ||
        isNil(document.discountAmount)
      ) {
        return 0;
      }

      const discountAmountInCents = document.discountAmount.amount;
      const discountDueDate = moment(document.discountPaymentDate);

      return paymentDate.isSameOrBefore(discountDueDate, 'day')
        ? discountAmountInCents
        : 0;
    })
  );
};
