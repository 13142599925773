import {
  IdentityProviderInput,
  NameIdPolicyFormat,
  PrincipalType,
} from 'generated-types/resolvers-types';

import { IdpSamlFormValues } from '../samlFormSchema';
import { FistLoginFlow, IdpConfigProviders } from '../types';

export const mapSamlFormValues2GqlInput = (
  formValues: IdpSamlFormValues
): IdentityProviderInput => {
  const idpInput: IdentityProviderInput = {
    alias: formValues.alias,
    displayName: formValues.alias,
    enabled: true,
    firstBrokerLoginFlowAlias: FistLoginFlow.EXIST_AND_FIRST_LOGIN,
    providerId: IdpConfigProviders.SAML,
    configSAML: {
      entityId: formValues.entityId,
      idpEntityId: formValues.idpEntityId,
      nameIDPolicyFormat: formValues.nameIDPolicyFormat as NameIdPolicyFormat,
      principalType: formValues.principalType as PrincipalType,
      singleSignOnServiceUrl: formValues.singleSignOnServiceUrl,
      validateSignature: formValues.validateSignature,
      signingCertificate: formValues.signingCertificate,
      postBindingAuthnRequest: formValues.postBindingAuthnRequest,
      postBindingResponse: formValues.postBindingResponse,
    },
  };

  return idpInput;
};
