import { useToastMessage } from 'components/Toast/useToastMessage';
import { GQLError, GQLErrorTypes } from 'gql';
import { useCallback } from 'react';

interface BookingError {
  [key: string]: string;
}

interface BookingsError {
  bookings?: BookingError[];
}

export const parseError = (
  errors: BookingsError | GQLError
): string | undefined => {
  if ('bookings' in errors) {
    if (!errors.bookings || !errors.bookings.length) {
      return undefined;
    }

    return errors.bookings
      .map(booking => Object.values(booking).join('. '))
      .join('. ');
  } else if ('graphQLErrors' in errors) {
    if (!errors.graphQLErrors) {
      return undefined;
    }

    const globalErrors = errors.graphQLErrors
      .filter(
        error =>
          error.extensions &&
          error.extensions.code !== GQLErrorTypes.VALIDATION_ERROR
      )
      .map(error => error.message)
      .join('. ');

    if (errors.graphQLErrors.length && globalErrors) {
      return globalErrors;
    }

    if (!errors.graphQLErrors || !errors.graphQLErrors.length) {
      return errors.message;
    }
  }

  return undefined;
};

export const useShowError = () => {
  const { error } = useToastMessage();

  const showError = useCallback(
    (errors: GQLError) => {
      const content = parseError(errors);
      if (content) {
        error(content);
      }
    },
    [error]
  );

  return showError;
};
