import { useListIbansQuery } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { toIbanItem } from 'views/Settings/PaymentsSettings/toIbanItem';

export const useOrganizationOwnIbansData = () => {
  const { data: listIbansData, loading: isIbansListDataLoading } =
    useListIbansQuery();

  const [excludeOrgOwnIbanAndNameFromExtractionsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.excludeOrgOwnIbanAndNameFromExtractions,
  ]);

  const ibanItems = listIbansData?.listIbans.map(toIbanItem);

  const organizationOwnIBANs = listIbansData?.listIbans.map
    ? listIbansData.listIbans.map(({ iban }) => iban)
    : [];

  const hasOrgOwnIBANs = organizationOwnIBANs.length > 0;

  const showMissingIBANIndicator =
    !hasOrgOwnIBANs && excludeOrgOwnIbanAndNameFromExtractionsFF;

  return {
    ibanItems,
    organizationOwnIBANs,
    isIbansListDataLoading,
    hasOrgOwnIBANs,
    showMissingIBANIndicator,
  };
};
