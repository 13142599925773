import { PaginatedComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { getTranslationContext } from 'orgConfig';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { NOTE_FIELD_MAX_LENGTH } from 'views/consts';

export const Note = ({
  readOnly,
  currentValueLength,
  ...props
}: {
  onChange: (val: Key | null) => void;
  currentValueLength?: number;
} & PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { client } = useDatev();
  const maxLength = client ? NOTE_FIELD_MAX_LENGTH : Infinity;

  return (
    <HookFormTextareaField
      name="note"
      label={t('inputs.note.label', {
        context: getTranslationContext(),
      })}
      {...props}
      placeholder={
        readOnly
          ? t('inputs.note.placeholder.notSet')
          : t('inputs.note.placeholder.enabled')
      }
      readOnly={readOnly}
      warning={
        (currentValueLength ?? 0) > maxLength
          ? t('inputs.note.maxLengthWarning', {
              maxLength,
            })
          : undefined
      }
      infoTooltip={
        readOnly
          ? undefined
          : {
              message: t('inputs.note.infoTooltip.enabled', {
                context: getTranslationContext(),
              }),
            }
      }
    />
  );
};
