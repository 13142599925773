import { DocumentsTableData } from 'components/DocumentsTable/types';
import {
  DateFilterInput,
  DateFilterOption,
  DocumentDateFilterInput,
} from 'generated-types/graphql.types';
import { Filters } from 'react-table';

const extractDates = (dateFilter: string[]): DateFilterInput => {
  const activeFilterValues = dateFilter?.[0] as string;
  const [from, to] = activeFilterValues?.split('-');

  return {
    dateFrom: from,
    dateTo: to,
    filterOption: DateFilterOption.Custom,
  };
};

export const mapToDocumentDateFilterInput = (
  filters: Filters<DocumentsTableData>
): DocumentDateFilterInput => {
  const reducedFilters = filters.reduce<DocumentDateFilterInput>(
    (dateFilterInput, filter) => {
      if (filter.id === 'createdAt') {
        return { ...dateFilterInput, createdAt: extractDates(filter.value) };
      }

      if (filter.id === 'deliveryDate') {
        return {
          ...dateFilterInput,
          invoiceDeliveryDate: extractDates(filter.value),
        };
      }

      if (filter.id === 'dueDate') {
        return {
          ...dateFilterInput,
          invoiceDueDate: extractDates(filter.value),
        };
      }

      if (filter.id === 'paidAt') {
        return { ...dateFilterInput, paidAt: extractDates(filter.value) };
      }

      if (filter.id === 'invoiceDate') {
        return { ...dateFilterInput, invoiceDate: extractDates(filter.value) };
      }

      return dateFilterInput;
    },
    {}
  );

  return reducedFilters;
};
