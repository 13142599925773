import { Button, Flex, Link, Text } from '@candisio/design-system';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { PromotionId } from 'components/NewPromotions/types';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  PromotionLocation,
  PromotionsTrackingEvents,
} from 'providers/AnalyticsProvider/events/PromotionsTrackingEvents';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CallInActionSection = ({
  promotionId,
}: {
  promotionId: PromotionId;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { promotionDetails } = usePromotionDetailsModalContent(promotionId);
  const { ctaButton } = promotionDetails;
  const { track } = useAnalytics();

  const trackEvent = () => {
    track(PromotionsTrackingEvents.PROMOTION_CTA_BUTTON_CLICKED, {
      placement: PromotionLocation.DASHBOARD,
      promotionId,
    });
  };

  const onClick = () => {
    window.open(ctaButton?.url, '_blank', 'noopener noreferrer');
    trackEvent();
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="start"
      padding="space24 space24 space8 0"
      gap="space16"
      borderRadius="medium"
      position="sticky"
      bottom={0}
      style={{
        background:
          'linear-gradient(180deg, rgba(248, 246, 245, 0.2) 0%, #F8F6F5 20%)',
      }}>
      {ctaButton && (
        <Button
          color="purple"
          icon="arrowRight"
          iconPosition="right"
          onClick={onClick}>
          {ctaButton?.text}
        </Button>
      )}
      <Text color="gray500" width="80%">
        <Trans t={t} i18nKey="promotionDetailsModal.ctaMarketingLink.text">
          These features are part of our new package structure
          <Link
            as="a"
            color="gray"
            target="_blank"
            href={t('promotionDetailsModal.ctaMarketingLink.url')}
            style={{
              textDecoration: 'underline',
            }}>
            candis.io/en/price
          </Link>
        </Trans>
      </Text>
    </Flex>
  );
};
