import { Grid } from '@candisio/design-system';
import { ProcessingFormSwiftCodeField } from 'components/Form/ProcessingForm/ProcessingFormSwiftCodeFields';
import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormIbanField } from 'components/HookFormFields/HookFormIbanField';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { isNull } from 'lodash';
import moment from 'moment';
import { Key, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaymentStatus } from 'views/Payments/types';
import {
  useShowCreateTransferField,
  useShowPaymentStatusField,
  useShowSwiftCodeField,
} from 'views/utils/useShouldShowField';
import { useShowCreditMemoFieldsDocumentForm } from '../hooks/useShouldShowField';

interface BankingDetailsProps {
  allowInlineEditing?: boolean;
  paymentStatus?: PaymentStatus;
}

export const BankingDetails = ({
  allowInlineEditing = true,
  paymentStatus,
}: BankingDetailsProps) => {
  const [t] = useTranslation();
  const form = useFormContext();
  const [isPaid, setIsPaid] = useState(paymentStatus === PaymentStatus.PAID);
  const hasPaymentDate = isNull(form.getValues('paidAt'));

  const showCreditMemoFieldsDocumentForm =
    useShowCreditMemoFieldsDocumentForm();

  const showSwiftCodeField = useShowSwiftCodeField();
  const showCreateTransferField = useShowCreateTransferField();
  const showPaymentStatusField = useShowPaymentStatusField();

  const handlePaymentStatusChange = (event: Key | null) => {
    setIsPaid(event === PaymentStatus.PAID);

    if (hasPaymentDate) {
      form.setValue(
        'paidAt',
        moment(new Date(Date.now())).format('YYYY-MM-DD')
      );
    }
  };

  return (
    <Grid
      gap="space16"
      alignContent="start"
      background={allowInlineEditing ? 'gray0' : 'transparent'}
      padding={allowInlineEditing ? 'space16' : undefined}
      borderRadius="medium">
      {showCreditMemoFieldsDocumentForm && (
        <HookFormIbanField
          name="iban"
          label={t('document.requestApproval.inputs.iban.label')}
          readOnly={!allowInlineEditing}
        />
      )}
      {showCreditMemoFieldsDocumentForm && showSwiftCodeField && (
        <ProcessingFormSwiftCodeField
          name="swiftCode"
          label={t('document.requestApproval.inputs.swiftCode.label')}
          readOnly={!allowInlineEditing}
        />
      )}
      {allowInlineEditing && showPaymentStatusField ? (
        <HookFormSelectField
          name="paymentStatus"
          label={t('document.requestApproval.inputs.paymentStatus.label')}
          readOnly={!allowInlineEditing}
          onChange={handlePaymentStatusChange}
          items={[
            {
              key: 'PAID',
              children: t('document.requestApproval.inputs.paymentStatus.paid'),
            },
            {
              key: 'UNPAID',
              children: t(
                'document.requestApproval.inputs.paymentStatus.unpaid'
              ),
            },
          ]}
        />
      ) : (
        <HookFormTextField
          name="paymentStatus"
          label={t('document.requestApproval.inputs.paymentStatus.label')}
          inputProps={{
            value:
              paymentStatus === PaymentStatus.PAID
                ? t('document.requestApproval.inputs.paymentStatus.paid')
                : t('document.requestApproval.inputs.paymentStatus.unpaid'),
          }}
          readOnly
        />
      )}

      {isPaid && (
        <HookFormDatePickerField
          name="paidAt"
          readOnly={!allowInlineEditing}
          label={t('document.requestApproval.inputs.paidAt.label')}
        />
      )}

      {showCreditMemoFieldsDocumentForm && showCreateTransferField && (
        <HookFormCheckboxField
          name="createTransfer"
          readOnly={!allowInlineEditing}
          disabled={paymentStatus === PaymentStatus.PAID}
          label={t('document.requestApproval.inputs.createTransfer.label')}
        />
      )}
    </Grid>
  );
};
