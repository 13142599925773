/**
 * Checks if the given client name indicates a mobile client.
 * @param clientName - The name of the client to check, which can be undefined or a string.
 * @returns True if the client name includes 'mobile', false otherwise.
 */
export const isMobileClient = (clientName?: string | null): boolean => {
  if (!clientName) return false;

  return clientName.toLowerCase().includes('mobile');
};
