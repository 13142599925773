import {
  Checkbox,
  FieldContainer,
  Flex,
  mergeProps,
} from '@candisio/design-system';
import { InfoOutlineIcon } from 'components/Icons/InfoOutlineIcon';
import { ReactNode } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export interface HookFormCheckboxFieldProps<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Should field focus on mount? */
  autoFocus?: boolean;
  ariaLabel?: string;
  /** Is field disabled? */
  disabled?: boolean;
  /** Message to display in info icon tooltip */
  infoMessage?: ReactNode;
  /** Message to display in field corner tooltip */
  message?: ReactNode;
  /** Field label */
  label: string;
  /** Called when field value changes */
  onChange?: (newValue: boolean) => void;
  /** Is field read only? */
  readOnly?: boolean;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
}

/**
 * Controlled checkbox field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormCheckboxField = <TFormValues extends FieldValues>({
  autoFocus,
  ariaLabel,
  control,
  disabled,
  infoMessage,
  label,
  message,
  name,
  readOnly: readOnlyProp,
  variant,
  isLoading,
  ...restProps
}: HookFormCheckboxFieldProps<TFormValues>) => {
  const { field, fieldState, formState } = useController({ control, name });
  const { ref, value, ...fieldProps } = field;

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  return (
    <FieldContainer
      transparent
      disabled={disabled}
      readOnly={readOnly}
      variant={hasError ? 'error' : variant}
      isLoading={isLoading}>
      <Flex gap="space8">
        <Checkbox
          autoFocus={autoFocus}
          aria-label={ariaLabel}
          isDisabled={disabled}
          isReadOnly={readOnly}
          isSelected={value}
          message={errorMessage ?? message}
          variant={hasError ? 'error' : variant}
          {...mergeProps(fieldProps, restProps)}
          showMessageOnFocus={hasError || variant === 'error'}
          ref={ref}>
          {label}
        </Checkbox>
        {infoMessage !== undefined && (
          <InfoOutlineIcon message={infoMessage} disableTabbing />
        )}
      </Flex>
    </FieldContainer>
  );
};
