import { Color, HeaderProps, TruncatedText } from '@candisio/design-system';
import {
  CardIssuerTransactionEdge,
  TransactionCategory,
} from 'generated-types/graphql.types';
import {
  CardIssuerTransactionDateFilterInput,
  CardIssuerTransactionFilterInput,
  CardIssuerTransactionSortField,
  CardIssuerTransactionSortInput,
  DateFilterOption,
  InvoiceAssociationStatus,
  MemberInfo,
  SortDirection,
} from 'generated-types/resolvers-types';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import computing from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/computer.svg';
import entertainment from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/entertain.svg';
import food from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/food.svg';
import gift from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/gift.svg';
import advertising from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/marketing.svg';
import office from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/office.svg';
import packaging from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/packaging.svg';
import electronic from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/software.svg';
import other from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/sonstiges.svg';
import education from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/training.svg';
import travel from 'views/CreditCards/CreditCardsInsights/SpentOverview/media/travel.svg';
import {
  AvailableFiltersTransactionTable,
  TRANSACTIONS_QUERY_PARAMS,
  TransactionsTableData,
  TransactionsTableDataKeys,
  UseControlledStateOptions,
} from './types';

export const queryParams = { allDone: 'allDone' };

export type InvoiceStatusTag = Record<
  InvoiceAssociationStatus,
  { description: string; color: Color; showTag?: boolean }
>;

const mapSortIdsToTransactionSortFields: {
  [index in TransactionsTableDataKeys]: CardIssuerTransactionSortField;
} = {
  merchantName: CardIssuerTransactionSortField.MerchantName,
  transactionCreatedAt: CardIssuerTransactionSortField.TransactionCreatedAt,
  grossAmount: CardIssuerTransactionSortField.TransactionAmount,
  status: CardIssuerTransactionSortField.Status,
  cardRefNum: CardIssuerTransactionSortField.CardRefNum,
  cardholderName: CardIssuerTransactionSortField.CardholderFullName,
};

export const transactionToTableData = (
  transactions: CardIssuerTransactionEdge[]
): TransactionsTableData[] => {
  return transactions.map(
    ({
      cursor,
      node: {
        billingAmount,
        cardRefNum,
        localizedDeclineReason,
        id,
        invoiceAssociationStatus,
        member,
        merchantLegalName,
        status,
        transactionCreatedAt,
        type,
        category,
        transactionAmount,
        merchantLogoUrl,
      },
    }) => ({
      cursor,
      status,
      id,
      merchantName: merchantLegalName,
      transactionAmount: transactionAmount,
      grossAmount: billingAmount
        ? {
            // TODO change schema to be non optional?
            // TODO We might be using transactionAmount
            value: billingAmount.value,
            // TODO change schema to be non optional?
            // TODO We might be using transactionAmount
            currency: billingAmount.currency,
          }
        : null,
      transactionCreatedAt: transactionCreatedAt
        ? new Date(transactionCreatedAt)
        : null,
      cardRefNum: cardRefNum ? `•••• ${cardRefNum}` : null,
      cardholderName: member
        ? {
            firstName: member.firstName,
            lastName: member.lastName,
            avatarUrl: member.avatarUrl,
            membershipId: member.membershipId,
          }
        : null,
      invoiceAssociationStatus,
      localizedDeclineReason: {
        en: localizedDeclineReason?.description?.en ?? undefined,
        de: localizedDeclineReason?.description?.de ?? undefined,
      },
      type,
      category: category ?? undefined,
      merchantLogoUrl: merchantLogoUrl ?? undefined,
    })
  );
};

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<TransactionsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <TruncatedText
      wordBreak="break-word"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
      {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        t(`columns.${column?.id}`, {
          context: getTranslationContext(),
        })
      }
    </TruncatedText>
  );
};

export const getFullName = (value: MemberInfo) => {
  return `${value?.firstName} ${value?.lastName}`;
};

export const getInvoiceStatusTag = ({
  status,
  showTag,
}: {
  status: InvoiceAssociationStatus;
  showTag?: boolean;
}) => {
  const invoiceStatusTag: InvoiceStatusTag = {
    NOT_NEEDED: {
      description: 'transactions:documentStatus.notNeeded',
      color: 'gray',
    },
    MISSING: {
      description: 'transactions:documentStatus.missing',
      color: 'yellow',
    },
    UPLOADED: {
      description: 'transactions:documentStatus.uploaded',
      color: 'green',
    },
    AUTO_MATCHED: {
      description: 'transactions:documentStatus.autoMatched',
      color: 'blue',
      showTag,
    },
  };

  const color = invoiceStatusTag[status].color;
  const description = invoiceStatusTag[status].description;
  const hideTag = invoiceStatusTag[status].showTag === false;

  if (hideTag) {
    return null;
  }

  return { color, description };
};

/**
 * Map TransactionTable sort state to SortInput.
 * Only considers the first sorted column.
 */
export const mapToSortInput = (
  sort: Array<SortingRule<TransactionsTableData>>
): CardIssuerTransactionSortInput | undefined => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  return {
    field:
      mapSortIdsToTransactionSortFields[
        firstSort.id as TransactionsTableDataKeys
      ],
    direction: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};

export const mapToDateRangeFilters: (
  dateFilter: string[]
) => CardIssuerTransactionDateFilterInput = (dateFilter: string[]) => {
  const activeFilterValues = dateFilter?.[0] as string;
  const [from, to] = activeFilterValues?.split('-');

  return {
    transactionCreatedAt: {
      dateFrom: from,
      dateTo: to,
      filterOption: DateFilterOption.Custom,
    },
  };
};

export const mapToFilterInput = (
  filters: Filters<TransactionsTableData>
): CardIssuerTransactionFilterInput => {
  return filters.reduce<CardIssuerTransactionFilterInput>(
    (documentFilterInput, filter) => {
      if (filter.id === availableFiltersTransactionTable.status) {
        return { ...documentFilterInput, status: filter.value };
      }

      if (filter.id === availableFiltersTransactionTable.cardRefNum) {
        return { ...documentFilterInput, cardIds: filter.value };
      }

      if (filter.id === availableFiltersTransactionTable.cardholderName) {
        return { ...documentFilterInput, cardholderIds: filter.value };
      }

      if (filter.id === availableFiltersTransactionTable.transactionCreatedAt) {
        return {
          ...documentFilterInput,
          dateRangeFilters: mapToDateRangeFilters(filter.value),
        };
      }

      if (
        filter.id === availableFiltersTransactionTable.invoiceAssociationStatus
      ) {
        return {
          ...documentFilterInput,
          invoiceAssociationStatus: filter.value,
        };
      }

      if (filter.id === availableFiltersTransactionTable.merchantName) {
        return {
          ...documentFilterInput,
          merchantNames: filter.value,
        };
      }

      if (filter.id === availableFiltersTransactionTable.category) {
        return {
          ...documentFilterInput,
          categories: filter.value,
        };
      }

      if (filter.id === availableFiltersTransactionTable.type) {
        return {
          ...documentFilterInput,
          type: filter.value,
        };
      }

      return documentFilterInput;
    },
    {}
  );
};

export const useControlledState = ({
  onSort,
  sortBy,
  onFilter,
  filters,
}: UseControlledStateOptions) => {
  //we do not want to trigger any useEffect on first render
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    onSort?.(sortBy);
    // the sortBy value comes from react table so we cannot memoize it to prevent calling onSort even though just the filters were changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSort, JSON.stringify(sortBy)]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    onFilter?.(filters);
    // the filters value comes from react table so we cannot memoize it to prevent calling onFilter even though just the sorting was changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFilter, JSON.stringify(filters)]);

  // this needs to come last, after the other useEffects
  useEffect(() => {
    isFirstRender.current = false;
  }, []);
};

export const availableFiltersTransactionTable: {
  [key in keyof AvailableFiltersTransactionTable]-?: keyof AvailableFiltersTransactionTable;
} = {
  invoiceAssociationStatus: 'invoiceAssociationStatus',
  status: 'status',
  transactionCreatedAt: 'transactionCreatedAt',
  cardholderName: 'cardholderName',
  type: 'type',
  cardRefNum: 'cardRefNum',
  merchantName: 'merchantName',
  category: 'category',
};

export const availableFilters = [
  TRANSACTIONS_QUERY_PARAMS.cardholderName,
  TRANSACTIONS_QUERY_PARAMS.cardRefNum,
  TRANSACTIONS_QUERY_PARAMS.invoiceAssociationStatus,
  TRANSACTIONS_QUERY_PARAMS.status,
  TRANSACTIONS_QUERY_PARAMS.transactionCreatedAt,
  TRANSACTIONS_QUERY_PARAMS.type,
  TRANSACTIONS_QUERY_PARAMS.merchantName,
  TRANSACTIONS_QUERY_PARAMS.category,
];

export const mappedCategoriesIcons: Record<TransactionCategory, string> = {
  ADVERTISING_AND_MARKETING: advertising,
  COMPUTING_AND_SOFTWARE: electronic,
  EDUCATION_AND_TRAINING: education,
  ELECTRONICS_AND_IT_EQUIPMENT: computing,
  ENTERTAINMENT_AND_WELLNESS: entertainment,
  FOOD_AND_DRINKS: food,
  GIFTS_AND_VOUCHERS: gift,
  MATERIALS_AND_PACKAGING: packaging,
  OFFICE_SUPPLIES_AND_EQUIPMENT: office,
  OTHER: other,
  SERVICES: packaging,
  TRAVEL_AND_ACCOMMODATION: travel,
};
