import {
  fetchPolicy,
  FilterOption,
  PaginationWithSearchFilterHook,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  AccountsReceivableNumberInfiniteScrollPaginationQuery,
  AccountsReceivableNumberInfiniteScrollPaginationQueryVariables,
  ContactSortField,
  SortDirection,
  useContactsPaginationQuery,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { isNil, uniqBy } from 'lodash';
import { useCallback } from 'react';
import { accountsReceivableNumberInfiniteScrollPaginationQuery } from '../utils/queries';
import { useIsContactsPageWithArchivedTab } from './useIsContactsPageWithArchivedTab';

export const useAccountsReceivableNumberFilter: PaginationWithSearchFilterHook =
  ({ filteredValues, searchStr }) => {
    const { friendlyFormatAccountsNumber } = useAccountingNumberFormatters();
    const isArchivedContactTable = useIsContactsPageWithArchivedTab();

    const computeVariables = useCallback(
      (
        inputString?: string
      ): AccountsReceivableNumberInfiniteScrollPaginationQueryVariables => ({
        queries: {
          accountsReceivableNumber: inputString?.replaceAll(' ', ''),
        },
        filters: {
          isArchived: isArchivedContactTable,
        },
        sortBy: {
          direction: SortDirection.Asc,
          field: ContactSortField.AccountsReceivableNumber,
        },
      }),
      [isArchivedContactTable]
    );

    const {
      data: allContacts,
      loading: allContactsLoading,
      loadMore,
      handleDebounceSearch,
    } = useDebouncedSearchPagination<
      AccountsReceivableNumberInfiniteScrollPaginationQuery,
      AccountsReceivableNumberInfiniteScrollPaginationQueryVariables
    >({
      query: accountsReceivableNumberInfiniteScrollPaginationQuery,
      queryRootKey: 'accountsReceivableNumberPagination',
      computeVariables,
    });

    const { data: selectedContacts } = useContactsPaginationQuery({
      variables: {
        input: { page: 1, limit: filteredValues.length },
        filters: { accountsReceivableNumber: filteredValues },
        sortBy: {
          direction: SortDirection.Asc,
          field: ContactSortField.AccountsReceivableNumber,
        },
      },
      fetchPolicy,
      skip: filteredValues.length === 0 || Boolean(searchStr),
    });

    const byIds = selectedContacts?.contactsPagination.records ?? [];
    const byAccountsReceivableNumber =
      allContacts?.accountsReceivableNumberPagination.records ?? [];

    const filterOptions: FilterOption[] = [
      ...byIds,
      ...byAccountsReceivableNumber,
    ]
      .filter(contact => Boolean(contact.accountsReceivableNumber))
      .map(contact => ({
        label: !isNil(contact.accountsReceivableNumber)
          ? friendlyFormatAccountsNumber(
              String(contact.accountsReceivableNumber)
            )
          : '',
        id: !isNil(contact.accountsReceivableNumber)
          ? String(contact.accountsReceivableNumber)
          : '',
      }));

    return {
      filterOptions: uniqBy(filterOptions, 'id'),
      handleDebounceSearch,
      loading: allContactsLoading,
      loadMore,
    };
  };
