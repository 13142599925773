import gql from 'graphql-tag';

const bookingKeyData = gql`
  fragment BookingKeyData on BookingKey {
    id
    createdBy {
      id
      name
      avatarUrl
    }
    taxCode
    description
    readableName
    createdAt
    isArchived
    taxPercentage
  }
`;

export const bookingKeysQuery = gql`
  query bookingKeys {
    bookingKeys {
      ...BookingKeyData
    }
  }
  ${bookingKeyData}
`;

export const bookingKeyQuery = gql`
  query bookingKey($id: ID!) {
    bookingKey(id: $id) {
      __typename
      ...BookingKeyData
      ... on BookingKeyError {
        kind
      }
    }
  }
  ${bookingKeyData}
`;

export const bookingKeyActiveQuery = gql`
  query bookingKeysActive {
    bookingKeysActive {
      ...BookingKeyData
    }
  }
  ${bookingKeyData}
`;

export const bookingKeyArchivedQuery = gql`
  query bookingKeysArchived {
    bookingKeysArchived {
      ...BookingKeyData
    }
  }
  ${bookingKeyData}
`;

export const createBookingKey = gql`
  mutation createBookingKey($input: CreateBookingKeyInput!) {
    createBookingKey(input: $input) {
      __typename
      ... on BookingKeySuccess {
        id
      }
      ... on BookingKeyError {
        taxCode
        kind
      }
    }
  }
`;

export const updateBookingKey = gql`
  mutation updateBookingKey($input: UpdateBookingKeyInput!) {
    updateBookingKey(input: $input) {
      __typename
      ... on BookingKeySuccess {
        id
      }
      ... on BookingKeyError {
        taxCode
        kind
      }
    }
  }
`;

export const archiveBookingKey = gql`
  mutation archiveBookingKey($id: ID!) {
    archiveBookingKey(id: $id) {
      __typename
      ... on BookingKeySuccess {
        id
      }
      ... on BookingKeyError {
        taxCode
        kind
      }
    }
  }
`;

export const activateBookingKey = gql`
  mutation activateBookingKey($id: ID!) {
    activateBookingKey(id: $id) {
      __typename
      ... on BookingKeySuccess {
        id
      }
      ... on BookingKeyError {
        taxCode
        kind
      }
    }
  }
`;

export const bulkCreateBookingKeys = gql`
  mutation bulkCreateBookingKeys($input: [CreateBookingKeyInput!]!) {
    bulkCreateBookingKeys(input: $input) {
      ... on BookingKeysBulkImportReport {
        imported {
          id
        }
        errors {
          taxCode
          kind
          message
        }
      }
      ... on BookingKeyError {
        taxCode
        kind
        message
      }
    }
  }
`;
