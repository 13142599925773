import {
  Avatar,
  Grid,
  Icon,
  Tooltip,
  useTheme,
  useTooltip,
} from '@candisio/design-system';
import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Locale } from 'generated-types/graphql.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { TeamMemberWithAbsenceFormOutput } from '../active/ActiveTeamMemberFormLegacyRoles';
import { TeamMemberFormSchemaOptions } from '../teamMemberFormSchemaDeprecated';
import { InactiveStatusBar } from './InactiveStatusBar';

export interface InactiveLegacyRolesTeamMemberFormProps {
  isSubmittingActivateMembership?: boolean;
  onActivateMembership: () => void;
  selectedUser: User;
}

export const InactiveLegacyRolesTeamMemberForm = ({
  onActivateMembership,
  selectedUser,
  isSubmittingActivateMembership,
}: InactiveLegacyRolesTeamMemberFormProps) => {
  const [t] = useTranslation();
  const { colors } = useTheme();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const locales = Object.values(Locale).map(value => ({
    // eslint-disable-next-line candis/no-template-strings-inside-translation
    children: t(`locales.${value}`),
    key: value,
  }));

  const form = useForm<
    TeamMemberWithAbsenceFormOutput,
    TeamMemberFormSchemaOptions
  >({
    mode: 'all',
    defaultValues: {
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      locale: selectedUser.locale!,
      email: selectedUser.email,
      roles: selectedUser?.roles || [],
    },
  });

  const disabled = true;

  return (
    <FormProvider {...form}>
      <Grid as="form" gap="space24" templateRows="1fr auto">
        <InactiveStatusBar
          onRestore={onActivateMembership}
          loading={Boolean(isSubmittingActivateMembership)}
        />
        <Grid gap="space24">
          <AvatarUpload readonly>
            <Avatar
              name={selectedUser?.name}
              img={selectedUser?.avatarUrl ?? undefined}
              size="large"
              hasTooltip={false}
            />
          </AvatarUpload>
          <Grid
            background="gray100"
            borderRadius="8px"
            padding="space16"
            rowGap="space12"
            columnGap="space32"
            templateColumns="1fr 1fr 1fr 1fr"
            templateRows="auto auto auto">
            <Grid gridRow="1" gridColumn="1/3">
              <HookFormTextField
                disabled={disabled}
                autoFocus
                inputProps={{
                  style: {
                    color: colors.gray500, // TODO this should be possible from top lvl prop
                  },
                }}
                label={t('settings.teamMembers.form.firstName.label')}
                name="firstName"
              />
            </Grid>
            <Grid gridRow="1" gridColumn="3/5">
              <HookFormTextField
                name="lastName"
                inputProps={{
                  style: {
                    color: colors.gray500, // TODO this should be possible from top lvl prop
                  },
                }}
                disabled={disabled}
                label={t('settings.teamMembers.form.lastName.label')}
              />
            </Grid>
            <Grid
              gridRow="2"
              gridColumn="1/4"
              autoFlow="column"
              gap="space20"
              alignItems="center">
              <HookFormTextField
                name="email"
                label={t('settings.teamMembers.form.email.label')}
                inputProps={{
                  type: 'email',
                  style: {
                    color: colors.gray500, // TODO this should be possible from top lvl prop
                  },
                }}
                disabled={disabled}
                readOnly={true}
              />
              <Icon
                icon="infoOutline"
                size="space18"
                {...triggerProps}
                ref={triggerRef}
              />
              {isOpen && (
                <Tooltip {...tooltipProps} ref={tooltipRef}>
                  {t('settings.teamMembers.form.email.description')}
                </Tooltip>
              )}
            </Grid>
            <Grid gridRow="3" gridColumn="1/3">
              <HookFormSelectField
                name="locale"
                disabled={disabled}
                label={t('settings.teamMembers.form.locale')}
                items={locales}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
