import { FilterOptionsAccessor } from '@candisio/design-system';
import { PurchaseOrdersTableData } from 'components/PurchaseOrders/Table/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { goodsReceiptSapStatuses } from '../consts';

export const useGetGoodsReceiptsTableFilterOptions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const filterOptions: FilterOptionsAccessor<PurchaseOrdersTableData> =
    useMemo(() => {
      return {
        status: {
          data: goodsReceiptSapStatuses.map(status => ({
            id: status.id,
            label: t('goodsReceipts.' + status.label),
          })),
        },
      };
    }, [t]);

  return filterOptions;
};
