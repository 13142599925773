import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { defaultRolesTranslations } from 'views/Settings/Roles/toolkit/utils/defaultRolesTranslations';

/**
 *
 * @returns the list of membership roles and the sorted list of the translated membershipRoles
 */
export const useCurrentUserMembershipRoles = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const user = useCurrentUser();

  const membershipRoles = user?.membershipRoles ?? [];

  const sortedMembershipRoleNames = membershipRoles
    .map(role => {
      if (role.isBuiltinRole) {
        return t(defaultRolesTranslations[role.name]);
      }

      return role.name;
    })
    .sort((current, next) => current.localeCompare(next));

  return { membershipRoles, sortedMembershipRoleNames };
};
