import { Card, Grid, Heading, Skeleton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const LinkExistingCardToVendorFormLoading = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid templateColumns="1fr 1fr" height="100%">
      <Grid gap="space128" padding="space24" height="100%" alignContent="start">
        <Heading as="h3">
          {t('recurringPaymentsModal.linkCard.modalTitle')}
        </Heading>
        <Card background="gray100" height="400px">
          <Grid gap="space8">
            <Skeleton height="space40" width="100%" />
            <Grid templateColumns="auto 1fr" gap="space16">
              <Skeleton width="space200" height="280px" />
              <Skeleton height="space48" width="100%"></Skeleton>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid
        background="gray50"
        padding="space24"
        height="100%"
        gap="space16"
        alignContent="start"
        templateRows="auto auto auto">
        <Grid paddingTop="162px" gap="space16">
          <Card>
            <Grid gap="space8">
              <Skeleton width="space40" height="space20" />
              <Skeleton width="space128" height="space20" />
            </Grid>
          </Card>
          <Card>
            <Grid gap="space8">
              <Skeleton width="space40" height="space20" />
              <Skeleton width="space128" height="space20" />
            </Grid>
          </Card>
          <Card>
            <Grid gap="space8">
              <Skeleton width="space40" height="space20" />
              <Skeleton width="space128" height="space20" />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
