import { Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrganizationSettingsFormOutupt } from '../utils/organizationSettingsFormSchema';
import { VatId } from './VatId';
import { VatIdRadioGroup } from './VatIdRadioGroup';

export const VatIdFields = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { watch } = useFormContext<OrganizationSettingsFormOutupt>();

  const isVatIdSelected = watch('vatIdAvailable');

  return (
    <Grid gap="space16">
      <Text fontSize="basic" fontWeight="semibold">
        {t('insights.organizationSettingsDrawer.form.vatId')}
      </Text>
      <VatIdRadioGroup />
      {isVatIdSelected === 'Yes' && <VatId />}
    </Grid>
  );
};
