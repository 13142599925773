import { Grid, Link, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNumberFormat } from 'utils/useNumberFormat';
import { ReadOnlyTransactionFormFieldMetadataSource } from './hooks/useReadOnlyTransactionFormFieldsProps';

interface ReadOnlyFormFieldMetadataMessageProps {
  confidence: number;
  source: ReadOnlyTransactionFormFieldMetadataSource;
}

const sourceTypeTranslations: Record<
  ReadOnlyTransactionFormFieldMetadataSource,
  { type: string; inforUrl: string }
> = {
  creditCard: {
    type: 'accountingDataReadOnlyForm.fieldRecognition.source.type.creditCard',
    inforUrl:
      'accountingDataReadOnlyForm.fieldRecognition.source.infoUrl.creditCard',
  },
  transaction: {
    type: 'accountingDataReadOnlyForm.fieldRecognition.source.type.transaction',
    inforUrl:
      'accountingDataReadOnlyForm.fieldRecognition.source.infoUrl.transaction',
  },
};

export const ReadOnlyFormFieldMetadataMessage = ({
  confidence,
  source,
}: ReadOnlyFormFieldMetadataMessageProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const percentFormatter = useNumberFormat({ style: 'percent' });

  return (
    <Grid fontSize="small">
      <Text fontWeight="bold">
        {t('accountingDataReadOnlyForm.fieldRecognition.title')}
      </Text>
      <Text>
        {t('accountingDataReadOnlyForm.fieldRecognition.confidence', {
          confidence: percentFormatter.format(confidence),
        })}
      </Text>
      <Text>
        {t('accountingDataReadOnlyForm.fieldRecognition.source.message')}
        <Link external href={t(sourceTypeTranslations[source].inforUrl)}>
          {t(sourceTypeTranslations[source].type)}
        </Link>
      </Text>
    </Grid>
  );
};
