import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ContactFilterOptionsPaginationResult } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const contactFilterOptionsPaginationFieldPolicy: FieldPolicy<ContactFilterOptionsPaginationResult> =
  {
    merge: simplePaginationMergeWithoutDuplicates(
      'ContactFilterOptionsPaginationResult'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      contactFilterOptionsPagination: contactFilterOptionsPaginationFieldPolicy,
    },
  },
};
