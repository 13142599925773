import {
  CardIssuerOnboardingStatus,
  useGetCardIssuerOrganizationOnboardingQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';

export const useCreditCardsSetup = () => {
  const organization = useFullOrganization();

  const [creditCardsFF, creditCardsHideSpecialFeatureFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.creditCards,
      FEATURE_FLAGS.creditCardsHideSpecialFeatures,
    ]);

  const { data, loading: isOnboardingStatusLoading } =
    useGetCardIssuerOrganizationOnboardingQuery({
      skip: !creditCardsFF || !organization?.id,
    });

  const hide = false;
  const isAddonBought = creditCardsFF;
  const showAllExtraFeatures =
    isAddonBought && !creditCardsHideSpecialFeatureFF;

  const hasTransactionsActivated = !!organization?.hasTransactionsActivated;

  const isInUse = isAddonBought && hasTransactionsActivated;

  const onboardingStatus = data?.getCardIssuerOrganizationOnboarding?.status;
  const isOnboardingNeeded = !onboardingStatus && !hasTransactionsActivated;
  const isOnboardingInProgress =
    !!onboardingStatus &&
    onboardingStatus !== CardIssuerOnboardingStatus.Active;

  return {
    hide,
    isAddonBought,
    showPromo: !isAddonBought,
    showAllExtraFeatures,
    isInUse,
    isOnboardingNeeded,
    isOnboardingStatusLoading,
    isOnboardingInProgress,
  };
};
