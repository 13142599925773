import { Comment } from 'components/Comment/Comment';
import {
  Maybe,
  useGetMembershipsQuery,
  UserRole,
} from 'generated-types/graphql.types';

interface CommentFieldTransactionContainerProps {
  isSubmitting: boolean;
  onCreateComment: ({
    mention,
    mentionedUserIds,
  }: {
    mention: string | undefined;
    mentionedUserIds: Array<string>;
  }) => Promise<null | undefined>;
  ownerTxMembershipId: string | undefined;
}

const isOnlyApprover = (memberRoles: Maybe<UserRole[]> | undefined) => {
  return memberRoles?.includes(UserRole.Approver) && memberRoles.length === 1;
};

export const CommentFieldTransactionContainer = ({
  onCreateComment,
  isSubmitting,
  ownerTxMembershipId,
}: CommentFieldTransactionContainerProps) => {
  // XXX CAN-2124 we have two problems with the Mentions input:
  // 1. the onSelect does not trigger when one types the name of a user
  // 2. it does not have a way of removing a user if it is deleted in the text
  // Therefore we need to manually fetch all users and compute the mentions on submit
  // we could put this logic in the BE as well
  const { data, loading: loadingMemberships } = useGetMembershipsQuery();

  const { organizationMemberships = [] } = data || {};

  // XXX: WE should have a dedicated query that does not return approvers and avoid filtering in here
  const allowedMembershipsToMention = organizationMemberships.filter(member => {
    return !isOnlyApprover(member.roles) || ownerTxMembershipId === member.id;
  });

  return (
    <Comment
      loadingMemberships={loadingMemberships}
      memberships={allowedMembershipsToMention}
      onCreateComment={onCreateComment}
      isSubmitting={isSubmitting}
    />
  );
};
