import { SfsSettingSwitchMethod } from 'generated-types/graphql.types';
import forOwn from 'lodash/forOwn';
import { SfsSettingsType } from './useSwitchSfsField';

export const checkIfAnyFieldWasSwitchedOfAutomatically = (
  settings: SfsSettingsType
): boolean => {
  let wasSwitchedOffAutomatically = false;
  const sfsSettings = [settings.REQUESTER, settings.APPROVER];

  sfsSettings.forEach(sfsSetting => {
    forOwn(sfsSetting, (value: any) => {
      if (value?.method === SfsSettingSwitchMethod.Automatic) {
        wasSwitchedOffAutomatically = true;

        return;
      }
    });
  });

  return wasSwitchedOffAutomatically;
};
