import { MenuItem } from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { useTranslation } from 'react-i18next';

export interface PaymentConditionsKebabMenuProps {
  /** Called when “import history” action is selected */
  onImportHistoryAction?: () => void;
  /** Called when "generic CSV export" action is selected */
  onExportGenericAction?: () => void;
}

export const PaymentConditionsKebabMenu = ({
  onImportHistoryAction,
  onExportGenericAction,
}: PaymentConditionsKebabMenuProps) => {
  const [t] = useTranslation();

  const items: MenuItem[] = [
    {
      id: 'history',
      label: t('settings:paymentConditions.contextMenu.history'),
      showSeparator: true,
      onAction: onImportHistoryAction,
    },
    {
      id: 'export-generic',
      label: t('settings:paymentConditions.contextMenu.exportGeneric'),
      onAction: onExportGenericAction,
    },
  ];

  return (
    <KebabMenu
      items={items}
      label={t('settings:paymentConditions.contextMenu.label')}
    />
  );
};
