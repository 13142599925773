import {
  DocumentTableColumnKeys,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { useDatev } from 'orgConfig/datev';
import { useMemo } from 'react';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { isDatevExportType } from 'views/Integrations/Export/toolkit/utils';
import { ExportEntityRow } from 'views/Integrations/Export/types';

export const toDocumentTableData =
  (failedDocuments: { id: string; invoiceNumber: string }[]) =>
  (row: ExportEntityRow) => {
    const { currency } = row.amount;
    const { amount = 0, precision = 0 } = row?.netAmount ?? {};
    const normalizedAmount = amount / 10 ** precision;

    const exportErrorStatus = {
      exportError: {
        ...row.exportStatus?.exportError,
        failedDocuments,
      },
    };

    return {
      id: row.id,
      provisionStatus: row.provisionStatus,
      contact: row.contact,
      account: row.account,
      generalLedgerAccount: row.generalLedgerAccount,
      deliveryDate: row.deliveryDate,
      hasDocument: row.hasDocument,
      netAmount: { amount: normalizedAmount, currency },
      isDisabled: row.isDisabled,
      canBeSelectedTooltipText: row.disableReason,
      exportStatus: {
        ...row.exportStatus,
        ...(row.exportStatus?.exportError && exportErrorStatus),
      },
    };
  };

const defaultColumns: Array<DocumentTableColumnKeys> = [
  'provisionStatus',
  'contact',
  'account',
  'generalLedgerAccount',
  'deliveryDate',
  'netAmount',
] as const;

export const useGetColumns = () => {
  const state = useExportContext();
  const { exportDetails } = useExportDataGigaFactory(state);
  const exportType = exportDetails?.type;

  const hasExportStatus = !!state.exportId && !state.isReExport;

  const hasExportMethod =
    hasExportStatus && !!exportType && isDatevExportType(exportType);

  const columns = Array<DocumentTableColumnKeys | false>()
    .concat(
      hasExportStatus && 'exportStatus',
      hasExportMethod && 'exportMethod',
      defaultColumns
    )
    .filter(Boolean) as DocumentTableColumnKeys[];

  return columns;
};

export const useSelectionOptions = () => {
  const state = useExportContext();
  const { selectableProvisionIds } = useExportDataGigaFactory(state);
  const { exportProvisionsFF } = useDatev(); // BDS-checked

  const { selectedProvisions, setSelectedProvisions } = state;

  const selectionOptions = useMemo(
    () => ({
      selectedRowsIds: selectedProvisions,
      onSelectionRowChanged: (provisions: DocumentsTableData[]) => {
        setSelectedProvisions(provisions.map(({ id }) => id));
      },
      canBeSelected: ({ id }: DocumentsTableData) =>
        exportProvisionsFF && selectableProvisionIds.includes(id),
    }),
    [
      selectedProvisions,
      setSelectedProvisions,
      selectableProvisionIds,
      exportProvisionsFF,
    ]
  );

  return {
    selectionOptions,
    handleAllRowsSelected: selectionOptions.onSelectionRowChanged,
  };
};
