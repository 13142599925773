import moment from 'moment';
import { ErrorMessages } from 'utils/zodFormValidation/types';
import { z } from 'zod';

export const dateRangeFilterSchema = z
  .object({
    fromDate: z.string().optional().nullable(),
    toDate: z.string().optional().nullable(),
  })
  .superRefine(({ fromDate, toDate }, ctx) => {
    if (fromDate && toDate) {
      const fromDateParsed = moment(fromDate, 'YYYY-MM-DD');
      const toDateParsed = moment(toDate, 'YYYY-MM-DD');

      if (fromDateParsed.isAfter(toDateParsed, 'day')) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['fromDate'],
          params: {
            translationKey: 'table:dateFromAfterToError',
          },
        });

        return;
      }
    }
  });

export type DateRangeFilterFormOutput = z.infer<typeof dateRangeFilterSchema>;
export type DateRangeFilterFormValues = Partial<DateRangeFilterFormOutput>;
export type DateRangeFilterFormErrorMessages = ErrorMessages<
  typeof dateRangeFilterSchema
>;
