import { Modal } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useSendEmailMutation } from 'generated-types/graphql.types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailEditor } from '../../../../components/EmailEditor/EmailEditor';
import { FormData } from '../../../../components/EmailEditor/types';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';

export const SendEmailModal = ({
  modalTitle,
  isOpen,
  subjectText,
  body,
  senderEmail,
  senderName,
  onCancel,
  onAfterSubmit,
}: {
  modalTitle: string;
  isOpen: boolean;
  subjectText: string;
  senderEmail?: string;
  senderName?: string;
  body: string;
  onCancel: () => void;
  onAfterSubmit?: (formData: FormData) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const [sendEmail] = useSendEmailMutation();
  const currentUser = useCurrentUser();
  const onSubmit = async ({ emailTo, subject, htmlText }: FormData) => {
    setLoading(true);
    const { errors } = await sendEmail({
      variables: {
        payload: {
          recipients: emailTo.map(email => ({
            email,
          })),
          ...(senderEmail
            ? {
                replyTo: {
                  email: senderEmail,
                  name: senderName || currentUser?.name,
                },
              }
            : {}),
          subject,
          htmlBody: htmlText,
        },
      },
    });

    setLoading(false);
    if (!errors) {
      success(
        t(
          'settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.sendEmailSuccess'
        )
      );
      onCancel();
      if (onAfterSubmit) {
        onAfterSubmit({ emailTo, htmlText, subject });
      }
    } else {
      error(
        t(
          'settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.sendEmailError'
        )
      );
    }
  };

  return (
    <Modal
      width="max-content"
      closeLabel={t('common.close')}
      title={modalTitle}
      isOpen={isOpen}
      onClose={onCancel}>
      <EmailEditor
        emailToPlaceholder={t(
          'settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.emailToPlaceholder'
        )}
        loading={loading}
        subject={subjectText}
        htmlText={body}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
