import { Button, Grid, Item, Text } from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormUsersFieldOption } from 'components/HookFormFields/HookFormUsersField/HookFormUsersFieldOption';
import { UserFieldItem } from 'hooks/useUsersFieldOptions';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamAdmin } from './CreateTeamModalForm';

interface TeamDetailsProps {
  isLoadingActiveUsers: boolean;
  activeUsers: UserFieldItem[];
  onClick: () => void;
  onSetTeamAdmin: (admin: TeamAdmin) => void;
}

export const TeamDetails = ({
  onClick,
  activeUsers,
  isLoadingActiveUsers,
  onSetTeamAdmin,
}: TeamDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const bulletList: string[] = t('teams.createTeam.teamDetails.bulletPoints', {
    returnObjects: true,
  });

  const handleTeamAdminChange = (val: Key | null) => {
    if (!val) {
      onSetTeamAdmin(undefined);

      return;
    }

    const user = activeUsers.find(user => user.key === val);
    if (!user) return;

    const { key: id, children: fullName, avatarUrl } = user;
    onSetTeamAdmin({ id, fullName, avatarUrl });
  };

  return (
    <Grid gap="space24" height="100%">
      <Grid gap="space24" height="fit-content">
        <Text fontSize="xxlarge">
          {t('teams.createTeam.teamDetails.heading')}
        </Text>
        <Grid gap="space8" width="50%">
          <Text fontSize="xlarge">
            {t('teams.createTeam.teamDetails.fields.teamName.heading')}
          </Text>
          <HookFormTextField
            autoFocus
            name="teamName"
            label={t('teams.createTeam.teamDetails.fields.teamName.label')}
            ariaLabel={t('teams.createTeam.teamDetails.fields.teamName.label')}
            placeholder={t(
              'teams.createTeam.teamDetails.fields.teamName.placeholder'
            )}
          />
        </Grid>
        <Grid gap="space8" width="50%">
          <Text fontSize="xlarge">
            {t('teams.createTeam.teamDetails.fields.teamAdmin.heading')}
          </Text>
          <HookFormComboBoxField
            name="teamAdmin"
            label={t('teams.createTeam.teamDetails.fields.teamAdmin.label')}
            aria-label={t(
              'teams.createTeam.teamDetails.fields.teamAdmin.label'
            )}
            aria-labelledby={t(
              'teams.createTeam.teamDetails.fields.teamAdmin.label'
            )}
            placeholder={t(
              'teams.createTeam.teamDetails.fields.teamAdmin.placeholder'
            )}
            defaultItems={activeUsers}
            onChange={handleTeamAdminChange}
            isLoading={isLoadingActiveUsers}
            children={({ key, children, avatarUrl }) => (
              <Item key={key} textValue={children}>
                <HookFormUsersFieldOption
                  children={children}
                  avatarUrl={avatarUrl}
                />
              </Item>
            )}
          />
          <Grid gap="space8">
            <Text color="gray600" fontSize="basic">
              {t('teams.createTeam.teamDetails.permissionsForAdminTitle')}
            </Text>
            <Grid as="ul" padding={0} listStyle="none" gap="space4">
              {bulletList.map((item, index) => (
                <li key={index}>
                  <Text color="gray600">{item}</Text>
                </li>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button justifySelf="end" alignSelf="end" onClick={onClick}>
        {t('teams.createTeam.teamDetails.cta')}
      </Button>
    </Grid>
  );
};
