import {
  CreateBookingAccountInput,
  IntegrationName,
} from 'generated-types/graphql.types';
import Papa, { ParseResult } from 'papaparse';
import { accountCodeSchema } from 'utils/zodFormValidation/Schemas/bookingAccountCode';
import { v4 as uuidV4 } from 'uuid';
import { MAX_NUMBER_OF_BOOKING_ACCOUNTS } from '../consts';
import { CSVFileParseError, CSVMaxBookingAccountsError } from '../errors';
import { maxNumberFromMaxDigits } from './maxNumberFromMaxDigits';

export enum ValidationErrors {
  NAME_MISSING = 'NAME MISSING',
  ACCOUNT_NUMBER_MISSING = 'ACCOUNT NUMBER MISSING',
  TOO_LONG = 'TOO_LONG',
  IS_DUPLICATE = 'IS_DUPLICATE',
}
export type ParsedBookingAccount = Omit<
  CreateBookingAccountInput,
  'accountNumber'
> & {
  errors: ValidationErrors[];
  key: string; // Just used to keep unique identifies for each row in DOM/when manipulating, not saved with the record
};
export type ParsedBookingAccounts = {
  parsedBookingAccounts: ParsedBookingAccount[];
};

export async function parseBookingAccountCSV(
  file: File,
  maxDigits: number,
  validateBasedOffIntegrationFF: boolean,
  integration: IntegrationName
): Promise<ParsedBookingAccounts> {
  const { data } = await parseFile(file);

  const parsedBookingAccounts = parseBookingAccounts(
    data,
    maxDigits,
    validateBasedOffIntegrationFF,
    integration
  );

  return {
    parsedBookingAccounts,
  };
}

async function parseFile(file: File) {
  return new Promise<ParseResult<string[]>>((resolve, reject) =>
    Papa.parse<string[]>(file, {
      skipEmptyLines: 'greedy',
      complete: result => {
        if (result.errors.length) {
          return reject(new CSVFileParseError(result.errors));
        }

        return resolve(result);
      },
      encoding: 'windows-1252',
      error: reject,
    })
  );
}

function removeDatevHeaderAndLinesAbove(csvData: string[][]): string[][] {
  return csvData.slice(1);
}

export function parseBookingAccounts(
  data: string[][],
  maxDigits: number,
  validateBasedOffIntegrationFF: boolean,
  integration: IntegrationName
): ParsedBookingAccount[] {
  const rawData = removeDatevHeaderAndLinesAbove(data);

  if (doesExceedMaximumRows(rawData)) {
    throw new CSVMaxBookingAccountsError();
  }

  return rawData.map(
    parseBookingAccount(maxDigits, validateBasedOffIntegrationFF, integration)
  );
}

export const parseBookingAccount =
  (
    maxDigits: number,
    validateBasedOffIntegrationFF: boolean,
    integration: IntegrationName
  ) =>
  ([accountCode, name]: string[]): ParsedBookingAccount => {
    const isMissingName = !name;
    const isMissingAccountCode = !accountCode;
    const errors: ValidationErrors[] = [];

    if (isMissingName) {
      errors.push(ValidationErrors.NAME_MISSING);
    }

    if (isMissingAccountCode) {
      errors.push(ValidationErrors.ACCOUNT_NUMBER_MISSING);
    }

    const maxNumber = maxNumberFromMaxDigits(maxDigits);
    const accountCodeParser = accountCodeSchema({
      maxNumber,
      validateBasedOffIntegrationFF,
    });

    const parsedAccountCode = accountCodeParser.safeParse({
      accountCode,
      integration,
    });

    if (parsedAccountCode.success) {
      accountCode = parsedAccountCode.data.accountCode;
    } else if (!isMissingAccountCode) {
      errors.push(ValidationErrors.TOO_LONG);
    }

    return {
      accountCode,
      name,
      key: uuidV4(),
      errors,
    };
  };

function doesExceedMaximumRows(parsedBookingAccounts: string[][]) {
  return parsedBookingAccounts.length > MAX_NUMBER_OF_BOOKING_ACCOUNTS;
}
