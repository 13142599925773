import { Box, Tag } from '@candisio/design-system';
import { PromotionCard } from 'components/NewPromotions/PromotionCard/PromotionCard';
import { Promotion } from 'components/NewPromotions/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';

const DOCUMENT_TAGS_GRID_WIDTH = '50rem';

export const ContractSubCategoriesPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const promotion: Promotion = t(
    'promotions:contractCustomSubCategoriesPromo',
    { returnObjects: true }
  );

  promotion.label = (
    <Tag color="purple" variant="secondary">
      {t('common:tag.addOn')}
    </Tag>
  );

  return (
    <SettingsLayout
      title={t('contractCustomSubCategories.title')}
      width={DOCUMENT_TAGS_GRID_WIDTH}>
      <Box height="450px">
        <PromotionCard promotion={promotion} />
      </Box>
    </SettingsLayout>
  );
};
