import { Paragraph, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface WorkflowTitleProps {
  name: string;
  description?: string;
}

export const WorkflowTitle = ({ name, description }: WorkflowTitleProps) => {
  const [t] = useTranslation();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      passiveTrigger: true,
      delay: 2000,
      isDisabled: description === undefined,
    });

  return (
    <Paragraph fontSize="xsmall">
      {t('document.requestApproval.inputs.workflow.label')}:&nbsp;
      <Text
        fontStyle="italic"
        color="blue500"
        {...triggerProps}
        ref={triggerRef}>
        "{name}"
      </Text>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Paragraph fontWeight="bold">{name}</Paragraph>
          <Paragraph>{description}</Paragraph>
        </Tooltip>
      )}
    </Paragraph>
  );
};
