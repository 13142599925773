import { SplitBookingsFormValues } from 'components/Form/SplitBookingsForm/schema';
import { HookFormNumberFieldProps } from 'components/HookFormFields/HookFormNumberField';
import { HookFormPercentField } from 'components/HookFormFields/HookFormPercentField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const VatRate = ({
  readOnly,
  ...props
}: Partial<HookFormNumberFieldProps<SplitBookingsFormValues>>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  return (
    <HookFormPercentField
      name="vatRate"
      label={t('inputs.vatRate.label')}
      readOnly={readOnly}
      placeholder={
        readOnly
          ? t('inputs.vatRate.placeholder.notSet')
          : t('inputs.vatRate.placeholder.enabled')
      }
      minValue={0}
      {...props}
    />
  );
};
