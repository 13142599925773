import { CellProps, TruncatedText } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { ContactImportPreviewTableData } from 'views/Contacts/ContactImport/ContactImportPreviewTable/ContactImportPreviewTable';
import { genericFilter } from 'views/Contacts/ContactImport/ContactImportPreviewTable/filterUtils';
import { GenericCell } from 'views/Contacts/ContactImport/ContactImportPreviewTable/GenericCell';
import { ColumnType } from 'views/Contacts/ContactImport/ContactImportPreviewTable/useColumns';

export const createColumn = <
  K extends keyof Omit<ContactImportPreviewTableData, 'id' | 'errors'>
>({
  titleKey,
  accessor,
  width = '180px',
  errorMap = [],
  format,
}: ColumnType<K>): Column<ContactImportPreviewTableData> =>
  ({
    Header: ({ isFilteredOrSorted }) => (
      <ColumnTitle title={titleKey} isFilteredOrSorted={isFilteredOrSorted} />
    ),
    Cell: (props: CellProps<ContactImportPreviewTableData>) => (
      <GenericCell
        {...props}
        accessor={accessor}
        errorMap={errorMap}
        format={format}
      />
    ),
    filter: genericFilter(accessor),
    accessor,
    width,
    sortType: 'alphanumeric',
  } as Column<ContactImportPreviewTableData>);

interface ColumnTitleProps {
  title: string;
  isFilteredOrSorted: boolean;
}

const ColumnTitle = ({ title, isFilteredOrSorted }: ColumnTitleProps) => {
  const [t] = useTranslation();

  return (
    <TruncatedText color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
      {t(title)}
    </TruncatedText>
  );
};
