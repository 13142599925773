import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Filters, SortingRule } from 'react-table';
import { getHiddenColumnsWithFiltersApplied } from 'utils/table/getHiddenColumnsWithFiltersApplied';
import { hasHiddenColumnWithSortApplied } from 'utils/table/hasHiddenColumnWithSortApplied';

interface UseGetHandleUpdateConfigurationsParams<TableDataType extends object> {
  filters: Filters<TableDataType>;
  sortBy: SortingRule<TableDataType>[];
  updateConfigurationsTable: (configurations: Configuration[]) => Promise<void>;
}

export const useGetHandleUpdateConfigurations = <TableDataType extends object>({
  filters,
  sortBy,
  updateConfigurationsTable,
}: UseGetHandleUpdateConfigurationsParams<TableDataType>) => {
  const { searchParams, setSearchParams } = useMutateSearchParams();
  const handleUpdateConfigurations = (newConfigurations: Configuration[]) => {
    const search = new URLSearchParams(searchParams);

    const hiddenColumnsWithFiltersApplied = getHiddenColumnsWithFiltersApplied({
      filters,
      newConfigurations,
    });

    const hasHiddenColumnWithSort = hasHiddenColumnWithSortApplied({
      newConfigurations,
      sortBy,
    });

    hiddenColumnsWithFiltersApplied.forEach(hiddenColumn =>
      search.delete(hiddenColumn)
    );

    if (hasHiddenColumnWithSort) {
      search.delete('sort');
    }

    setSearchParams(search);
    void updateConfigurationsTable(newConfigurations);
  };

  return { handleUpdateConfigurations };
};
