import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { getTranslationContext } from 'orgConfig';
import { useDatev } from 'orgConfig/datev';
import { useTranslation } from 'react-i18next';
import { NOTE_FIELD_MAX_LENGTH } from 'views/consts';

export const Note = ({
  readOnly,
  currentValueLength,
}: {
  readOnly?: boolean;
  currentValueLength?: number;
}) => {
  const [t] = useTranslation();
  const { client } = useDatev();
  const maxLength = client ? NOTE_FIELD_MAX_LENGTH : Infinity;

  return (
    <HookFormTextareaField
      name="bookings.0.note"
      label={t('document.requestApproval.inputs.note.label', {
        context: getTranslationContext(),
      })}
      placeholder={
        readOnly
          ? t('split-bookings:inputs.note.placeholder.notSet')
          : t('split-bookings:inputs.note.placeholder.enabled')
      }
      readOnly={readOnly}
      warning={
        (currentValueLength ?? 0) > maxLength
          ? t('split-bookings:inputs.note.maxLengthWarning', {
              maxLength,
            })
          : undefined
      }
      infoTooltip={
        readOnly
          ? undefined
          : {
              message: t('split-bookings:inputs.note.infoTooltip.enabled', {
                context: getTranslationContext(),
              }),
            }
      }
    />
  );
};
