import { useToastMessage } from 'components/Toast/useToastMessage';
import { useApproveCardRequest } from 'containers/credit-cards/CreditCardSettings/HandleRequest/hooks/useApproveCardRequest';
import { useApproveLimitRequest } from 'containers/credit-cards/CreditCardSettings/HandleRequest/hooks/useApproveLimitRequest';
import { ModifiedLimitDetails } from 'containers/credit-cards/CreditCardSettings/types';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardStatus } from 'generated-types/graphql.types';
import { isEmpty } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';
import { useUpdateCardPurpose } from '../../Hooks/useUpdateCardPurpose';
import { useUpdateCardPurposeAndCardLimit } from '../../Hooks/useUpdateCardPurposeAndCardLimit';
import { UpdateCardLimitFormLoading } from '../../UpdateAccountingInfoContainer/UpdateCardLimitFormLoading';
import { UpdateSingleUseCardLimitForm } from './UpdateSingleUseCardLimitForm';
import { UpdateSingleUseCardLimitFormValues } from './updateSingleUseCardLimitFormSchema';

interface UpdateSingleUseeCardLimitFormContainerProps {
  cardId: string;
  cardStatus: CardStatus;
  onClose: () => void;
}

export const UpdateSingleUseCardLimitFormContainer = ({
  cardId,
  cardStatus,
  onClose,
}: UpdateSingleUseeCardLimitFormContainerProps) => {
  const { success, error } = useToastMessage();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { canManageCards } = usePermissionsForCreditCards();
  const { updatingPurpose, updateCardPurpose } = useUpdateCardPurpose();
  const { card, loading: loadingCard } = useGetCardById({ cardId });

  const hasPendingLimitRequest =
    !isEmpty(card?.pendingRequest) &&
    card?.pendingRequest?.__typename === 'CardLimitChangeRequest';

  const transactionLimit =
    hasPendingLimitRequest &&
    card?.pendingRequest?.__typename === 'CardLimitChangeRequest'
      ? card?.pendingRequest?.requestedTransactionLimit?.value ?? 0
      : card?.transactionLimit?.value ?? 0;

  const limitsInEuro = {
    transaction: formatFromCents(
      card?.transactionLimit?.currency,
      transactionLimit
    ),
  };

  const initialValues: UpdateSingleUseCardLimitFormValues = {
    purpose: card?.purpose ?? '',
    transactionLimit: limitsInEuro.transaction ?? 0,
    comment: undefined,
  };

  const { handleSubmit, loading } = useUpdateCardPurposeAndCardLimit({
    cardId,
    initialValues,
    onClose,
  });

  const isCardNotUsableAnymore =
    (card?.availableBalance?.value ?? 0) <
      (card?.transactionLimit?.value ?? 0) &&
    card?.status === CardStatus.Active;

  const { approve, approving } = useApproveLimitRequest({ cardId });
  const { approveActionToCardType } = useApproveCardRequest({ cardId });

  const approveCardRequest = card?.type
    ? approveActionToCardType[card?.type]?.approve
    : undefined;

  const isApproveCardRequestPending = card?.type
    ? approveActionToCardType[card?.type]?.approving
    : false;

  const isModifyingAndApprovingCardRequest =
    canManageCards && cardStatus === CardStatus.Requested;

  const isModifyingAndApprovingLimitRequest =
    canManageCards &&
    hasPendingLimitRequest &&
    [CardStatus.Active, CardStatus.Locked, CardStatus.LockedPin].includes(
      cardStatus
    );

  const handleModifyAndApproveLimitRequest = async (
    formData: UpdateSingleUseCardLimitFormValues
  ) => {
    if (!card?.pendingRequest?.id) {
      return;
    }

    const modifiedLimitDetails: ModifiedLimitDetails = {
      approvedTransactionLimit: {
        currency: 'EUR',
        value: formData.transactionLimit,
      },
      approvedPurpose: formData.purpose,
    };

    const { status } = await approve(
      card?.pendingRequest?.id,
      modifiedLimitDetails
    );

    if (status === 'success') {
      success(t('drawer.approveRequestSuccess'));
      onClose();
    } else {
      error(t('dashboard.genericErrorMessage'));
    }
  };

  const handleModifyAndApproveCardRequest = async (
    formData: UpdateSingleUseCardLimitFormValues
  ) => {
    if (!card?.pendingRequest?.id || !approveCardRequest) {
      return;
    }

    const modifiedLimitDetails: ModifiedLimitDetails = {
      approvedTransactionLimit: {
        currency: 'EUR',
        value: formData.transactionLimit,
      },
      approvedPurpose: formData.purpose,
    };

    const { status } = await approveCardRequest(
      card?.pendingRequest?.id,
      modifiedLimitDetails
    );

    if (status === 'success') {
      success(t('drawer.approveRequestSuccess'));
      onClose();
    } else {
      error(t('dashboard.genericErrorMessage'));
    }
  };

  if (loadingCard) {
    return <UpdateCardLimitFormLoading />;
  }

  let onSubmit = handleSubmit;

  if (isModifyingAndApprovingLimitRequest) {
    onSubmit = handleModifyAndApproveLimitRequest;
  }

  if (isModifyingAndApprovingCardRequest) {
    onSubmit = handleModifyAndApproveCardRequest;
  }

  return (
    <UpdateSingleUseCardLimitForm
      hasPendingLimitRequest={hasPendingLimitRequest}
      cardStatus={cardStatus}
      cardType={card?.type}
      onClose={onClose}
      updatePurpose={updateCardPurpose}
      updatingPurpose={updatingPurpose}
      isCardNotUsableAnymore={isCardNotUsableAnymore}
      defaultValues={initialValues}
      isSubmitting={loading || isApproveCardRequestPending || approving}
      cardId={cardId}
      onSubmit={onSubmit}
    />
  );
};
