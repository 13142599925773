import { WatchQueryFetchPolicy } from '@apollo/client';
import { useGetVendorForRecurringPaymentsByIdQuery } from 'generated-types/graphql.types';
import { createVendor } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/RecurringPaymentsList';

interface UseGetVendorForRecurringPaymentsByIdProps {
  vendorId?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export const useGetVendorForRecurringPaymentsById = ({
  fetchPolicy = 'cache-and-network',
  vendorId,
}: UseGetVendorForRecurringPaymentsByIdProps = {}) => {
  const { data, loading } = useGetVendorForRecurringPaymentsByIdQuery({
    variables: {
      id: vendorId as string,
    },
    skip: !vendorId || vendorId === createVendor,
    fetchPolicy,
  });

  const vendorDetails = {
    id: data?.getVendorForRecurringPaymentsById.vendor.id,
    name: data?.getVendorForRecurringPaymentsById.vendor.name,
    source: data?.getVendorForRecurringPaymentsById.vendor.source,
    logoUrl:
      data?.getVendorForRecurringPaymentsById.vendor.logoUrl ?? undefined,
    category:
      data?.getVendorForRecurringPaymentsById.vendor.category ?? undefined,
    linkedCards: (
      data?.getVendorForRecurringPaymentsById.linkedCards ?? []
    ).map(linkedCard => ({
      id: linkedCard.card.id,
      linkageId: linkedCard.id,
      label: linkedCard.card.label ?? '',
      suggestedCardholder: {
        id: linkedCard.card.cardholder.id,
        membershipId: linkedCard.card.cardholder.membershipId ?? undefined,
        name:
          linkedCard.card.cardholder.firstName ??
          '' + linkedCard.card.cardholder.lastName ??
          '',
      },
      cardNumber: linkedCard.card.refNum ?? '',
      brand: linkedCard.card.brand ?? undefined,
      type: linkedCard.card.type,
    })),
  };

  return { loading, vendorDetails };
};
