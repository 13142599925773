import { Button, Flex, Image, Tag, Text } from '@candisio/design-system';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import {
  FeatureBenefit,
  PromotionCards,
} from 'containers/Entitlements/components/Upsell/components/PromotionCard';
import { VideoCard } from 'containers/Entitlements/components/Upsell/components/VideoCard';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { thumbnails } from 'containers/Entitlements/components/Upsell/utils';
import { appointmentLinks } from 'containers/Entitlements/toolkit/utils';
import { Locale } from 'generated-types/graphql.types';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { ArchiveDocumentManagementEvents } from 'providers/AnalyticsProvider/events/DocumentManagementEvents';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ArchiveViewLayout } from 'views/Archive/components/ArchiveViewLayout';
import { TabView } from 'views/Inbox/models';

const ecmBenefits: FeatureBenefit[] = [
  {
    icon: 'creditCardOutline',
    heading: 'promo.ecm.modal.benefit1.heading',
    description: 'promo.ecm.modal.benefit1.description',
  },
  {
    icon: 'linkOutlined',
    heading: 'promo.ecm.modal.benefit2.heading',
    description: 'promo.ecm.modal.benefit2.description',
  },
  {
    icon: 'payments',
    heading: 'promo.ecm.modal.benefit3.heading',
    description: 'promo.ecm.modal.benefit3.description',
  },
  {
    icon: 'search',
    heading: 'promo.ecm.modal.benefit4.heading',
    description: 'promo.ecm.modal.benefit4.description',
  },
] as const;

const { MODAL_DEMO_BTN_CLICKED, MORE_INFO_CLICKED, DEMO_BTN_CLICKED } =
  ArchiveDocumentManagementEvents;

export const EcmTablePromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const { track } = useAnalytics();

  const trackModalDemoClick = useCallback(
    () => track(MODAL_DEMO_BTN_CLICKED),
    [track]
  );

  const trackDemoClick = useCallback(
    () => track(DEMO_BTN_CLICKED), // ignoring prettier
    [track]
  );

  const trackMoreInfoClick = useCallback(
    () => track(MORE_INFO_CLICKED),
    [track]
  );

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[FEATURE.ECM][locale];

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}>
      <Flex
        direction="column"
        gap="space16"
        paddingX="space12"
        maxWidth="1300px">
        <Tag variant="secondary" color="purple">
          {t('promo.tag')}
        </Tag>
        <Text as="h2" fontSize="xxlarge">
          <Trans t={t} i18nKey="promo.ecm.title">
            <Text fontWeight="semibold">Dokumentenmanagement</Text>
            Alle wichtigen Dokumente im Überblick.
          </Trans>
        </Text>
        <VideoCard feature={FEATURE.ECM} withFading>
          <Image src={thumbnail} alt="" opacity=".7" />
        </VideoCard>
        <Flex
          position="absolute"
          bottom="-4vmax"
          direction="column"
          alignItems="center"
          gap="space16"
          alignSelf="center"
          width="fit-content">
          <PromotionLink
            trackClick={trackDemoClick}
            href={t(appointmentLinks[FEATURE.ECM])}
            variant="calendar">
            {t('promo.modal.bookAppointment')}
          </PromotionLink>
          <UpsellPromo
            feature={FEATURE.ECM}
            CustomPromoMaterial={
              <PromotionCards benefits={ecmBenefits} feature={FEATURE.ECM} />
            }
            trackMoreInfo={trackMoreInfoClick}
            trackDemo={trackModalDemoClick}
            hidePackages>
            <Button variant="tertiary" color="promo" width="100%">
              {t('promo.ecm.moreInfoCTA')}
            </Button>
          </UpsellPromo>
        </Flex>
      </Flex>
    </ArchiveViewLayout>
  );
};
