import checkImage from 'assets/icon-check.svg';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from 'views/Inbox/models';
import { ItemApprovalStatus } from '../styles';

export const UserApprovalWorkflowStepComponent = () => {
  const [t] = useTranslation();

  return (
    <ItemApprovalStatus status={ApprovalStatus.APPROVED}>
      <img src={checkImage} alt={ApprovalStatus.APPROVED} />
      {t('document.compact.history.approvedWorkflowStep')}
    </ItemApprovalStatus>
  );
};
