import { Button, Flex } from '@candisio/design-system';
import {
  WidgetFormAnnualBudget,
  WidgetFormQuarterBudget,
} from 'components/Insights/Modals/types';
import { InsightBudgetType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnnualOption } from './AnnualOption/AnnualOption';
import {
  assignBudgetsToFormFields,
  cleanQuarterBudgetsForm,
  cleanYearBudgetsForm,
} from './BudgetTab.helper';
import { NoBudgetOption } from './NoBudgetOption/NoBudgetOption';
import { QuarterOption } from './QuarterOption/QuarterOption';
import { BudgetOptionHandlerProps } from './types';

const buttonOptions = [
  {
    key: 'default',
    onClick: ({ setBudgetView, form }: BudgetOptionHandlerProps) => {
      cleanYearBudgetsForm(form);
      cleanQuarterBudgetsForm(form);
      setBudgetView('default');
    },
    translation: 'dashboard.createNewWidget.budgetType.noBudget',
  },
  {
    key: InsightBudgetType.Yearly,
    onClick: ({
      setBudgetView,
      form,
      budgetType,
      budgets,
    }: BudgetOptionHandlerProps) => {
      if (budgetType === InsightBudgetType.Yearly) {
        assignBudgetsToFormFields(budgets, form);
      }

      cleanQuarterBudgetsForm(form);
      setBudgetView(InsightBudgetType.Yearly);
    },
    translation: 'dashboard.createNewWidget.budgetType.year',
  },
  {
    key: InsightBudgetType.Quarterly,
    onClick: ({
      setBudgetView,
      form,
      budgetType,
      budgets,
    }: BudgetOptionHandlerProps) => {
      if (budgetType === InsightBudgetType.Quarterly) {
        assignBudgetsToFormFields(budgets, form);
      }

      cleanYearBudgetsForm(form);
      setBudgetView(InsightBudgetType.Quarterly);
    },
    translation: 'dashboard.createNewWidget.budgetType.quarter',
  },
];

const budgetOption = [
  { key: 'default', Component: NoBudgetOption },
  { key: InsightBudgetType.Yearly, Component: AnnualOption },
  { key: InsightBudgetType.Quarterly, Component: QuarterOption },
];

export const BudgetTab = ({
  budgetType,
  budgets,
}: {
  budgetType: InsightBudgetType | undefined;
  budgets: WidgetFormAnnualBudget | WidgetFormQuarterBudget;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const [budgetView, setBudgetView] = useState<InsightBudgetType | 'default'>(
    budgetType || 'default'
  );

  const form = useFormContext();

  const renderOption = () => {
    const view = budgetOption.find(option => option.key === budgetView);
    if (!view) {
      return;
    }

    const { Component } = view;

    return <Component />;
  };

  return (
    <Flex gap="space16" direction="column">
      <Flex gap="space16">
        {buttonOptions.map(option => (
          <Button
            variant="secondary"
            key={option.key}
            isPressed={option.key === budgetView}
            aria-pressed={option.key === budgetView}
            onClick={() =>
              option.onClick({
                setBudgetView,
                budgetType,
                budgets,
                form,
              })
            }>
            {t(option.translation)}
          </Button>
        ))}
      </Flex>
      {renderOption()}
    </Flex>
  );
};
