import { useToastMessage } from 'components/Toast/useToastMessage';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps } from 'react-router-dom';
import { DatevConnectionModal } from './DatevConnectionModal';
import { parseOrganizationId } from './utils/parseOrganizationId';

const parseError = (e?: string | null | string[]) => {
  switch (e) {
    case 'access_denied':
      return 'datev.accessError';
    default:
      return 'datev.authError';
  }
};

export const PendingAuthDatev = ({
  location,
  history,
}: RouteComponentProps) => {
  const [t] = useTranslation();
  const { error: errorToast } = useToastMessage();
  const { error, state } = qs.parse(location.search);
  const organizationId = parseOrganizationId(state as string | undefined);

  const redirectUserBack = useCallback(() => {
    if (organizationId) {
      history.push(`/${organizationId}/settings/datev`);
    } else {
      history.push('/');
    }
  }, [organizationId, history]);

  useEffect(() => {
    if (error || !organizationId) {
      redirectUserBack();
      errorToast(t(parseError(error)));

      return;
    }

    window.location.assign(
      `/${organizationId}/auth/datev/authorize${location.search}`
    );
  }, [redirectUserBack, organizationId, location, error, t, errorToast]);

  return <DatevConnectionModal isVisible />;
};
