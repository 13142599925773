import { useCostCenterRequestImportFileUploadUrlMutation } from 'generated-types/graphql.types';
import { useState } from 'react';
import { uploadFile } from 'utils/upload';

interface usePaymentConditionsImportFileUploadProps {
  file: File | null;
}

export const usePaymentConditionsImportFileUpload = ({
  file,
}: usePaymentConditionsImportFileUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [requestFileUploadUrl] =
    useCostCenterRequestImportFileUploadUrlMutation();

  const upload = async (): Promise<{ fileId: string | null }> => {
    setLoading(true);
    let fileId: string | null = null;

    try {
      if (!file) throw new Error('No file to upload');

      const { data } = await requestFileUploadUrl({
        variables: {
          fileName: file.name,
          contentType: 'text/csv',
        },
      });

      if (!data) throw new Error('No upload data.');

      const { costCenterRequestImportFileUploadURL: uploadConfig } = data;

      if (!uploadConfig || !uploadConfig?.url)
        throw new Error('No upload data.');
      await uploadFile(file, uploadConfig.postOptions, uploadConfig.url);

      fileId = uploadConfig.id;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    return { fileId };
  };

  return { upload, loading };
};
