import {
  AnExportableDocumentFragment,
  Document,
  DocumentCurrency,
  ExportableDocument,
  PaymentMethod,
  AnExportableTransactionFragment,
  ExportableEntityType,
  InvoiceAssociationStatus,
} from 'generated-types/graphql.types';
import { DeepPartial } from 'utility-types';
import { ExportEntity } from '../../types';
import { ExportTransaction } from './toTransactionExportEntity';

export const gqlDocumentToExportEntity = (
  gqlDocument: DeepPartial<Document>
): ExportEntity => ({
  id: gqlDocument.id || '',
  /**
   * GQL's Document type doesn't return Money yet, so we simulate it here
   * creating a Money object with precision 0
   */
  amount: {
    amount: gqlDocument.amount?.value || 0,
    currency: gqlDocument.currency?.value || DocumentCurrency.Eur,
    // hack: here amount is a number with two decimals, so we don't want to change the precision
    precision: 0,
  },
  invoiceDate: new Date(gqlDocument.invoiceDate?.value as string),
  contact: gqlDocument.contact?.value?.name?.value || '',
  invoiceNumber: gqlDocument.invoiceNumber?.value || '',
  exportGuid: gqlDocument.datevGuid?.value,
  direction: gqlDocument.category?.direction?.value,
  paymentState:
    gqlDocument.paymentState?.__typename === 'PaidDocumentState'
      ? {
          isPaid: gqlDocument.paymentState?.isPaid || true,
          method: gqlDocument.paymentState?.method,
        }
      : {
          isPaid: gqlDocument.paymentState?.isPaid || false,
        },
  hasDocument: true,
  type: ExportableEntityType.Document,
});

export const documentToExportEntity = (
  document: AnExportableDocumentFragment | ExportableDocument
): ExportEntity => ({
  id: document.id,
  amount: document.amount,
  invoiceNumber: document.invoiceNumber,
  invoiceDate: new Date(document.invoiceDate),
  contact: document.contact?.name?.value,
  exportGuid: document.documentGuid,
  paymentState: document.paymentState,
  transactionStatus: document.transactionStatus,
  hasDocument: true,
  type: ExportableEntityType.Document,
  direction: document.direction,
  documentType: document.documentType,
});

export const txnWithoutDocumentToExportEntity = (
  transaction: AnExportableTransactionFragment | ExportTransaction
): ExportEntity => {
  return {
    id: transaction.id,
    amount: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      amount: transaction.billingAmount?.value!,
      currency: transaction.billingAmount?.currency as DocumentCurrency,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      precision: transaction.billingAmount?.precision!,
    },
    invoiceNumber: '',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    invoiceDate: new Date(transaction.transactionCreatedAt!),
    contact:
      (transaction.invoiceAssociationStatus ===
        InvoiceAssociationStatus.NotNeeded &&
      transaction.accountingData?.contact?.name.value
        ? transaction.accountingData?.contact?.name.value
        : transaction.merchantLegalName) ?? '',
    exportGuid: null,
    paymentState: {
      isPaid: true,
      method: PaymentMethod.CardTransaction,
    },
    transactionStatus: transaction.status,
    hasDocument: false,
    type: ExportableEntityType.CardTransaction,
  };
};
