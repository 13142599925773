import { Checkbox, Flex, Text, Grid } from '@candisio/design-system';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import { useExcludeTransactionsEnabled } from '../hooks/useExcludeTransactionsEnabled';
import { useExportDataGigaFactory } from '../hooks/useExportDataGigaFactory';
import { useSelectedTimeFrame } from '../hooks/useSelectedTimeFrame';

export const IncludeEntitiesWithoutDocument = () => {
  const state = useContext(ExportContext);
  const {
    exportType,
    exportId,
    includeEntitiesWithoutDoc,
    setIncludeEntitiesWithoutDoc,
  } = state;

  const {
    exportEntities: {
      ready: { all: allEntities },
    },
    exportEntitiesIds,
    exportAll,
  } = useExportDataGigaFactory(state);

  const { selectedTimeFrame } = useSelectedTimeFrame({
    entities: allEntities,
    exportAll,
    exportType,
    exportId,
  });

  const [t] = useTranslation();

  const { excludeTransactionsEnabled } = useExcludeTransactionsEnabled({
    selectedTimeFrame,
    exportEntitiesIds,
    context: state,
  });

  if (!excludeTransactionsEnabled) return null;

  return (
    <Flex background="gray100" alignItems="center" padding="space16">
      <Checkbox
        isSelected={includeEntitiesWithoutDoc}
        onChange={setIncludeEntitiesWithoutDoc}>
        <Grid>
          <Text>
            {t(
              'export.exportDetails.summaryContent.includeAllEntitiesWithoutDocs'
            )}
          </Text>
          <Text fontSize="small" color="gray600">
            {t(
              'export.exportDetails.summaryContent.includeAllEntitiesWithoutDocsDesc'
            )}
          </Text>
        </Grid>
      </Checkbox>
    </Flex>
  );
};
