import { Maybe } from '@graphql-tools/utils';
import { useBookingsFieldArrayContext } from 'components/Form/ProcessingForm/BookingsFieldArrayContext';
import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useSetProcessingFormMetadata } from 'components/Form/ProcessingForm/ProcessingFormSetMetadataContext';
import { ProcessingFormTypeFieldItem } from 'components/Form/ProcessingForm/ProcessingFormTypeField';
import { useUpdateProcessingFormField } from 'components/Form/ProcessingForm/useUpdateProcessingFormField';
import {
  AcceptedValuesDS,
  IsFieldEditable,
  ShouldShowField,
  Split,
  SplitBookingFields,
  SplitBookingsFormPropsDS,
  SplitDS,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { useCalculateRoundingAmount } from 'components/Form/utils';
import { SplitBookings } from 'containers/SplitBookings/SplitBookings';
import {
  grossToNet,
  netToGross,
  sumBookings,
} from 'containers/SplitBookings/toolkit/utils';
import {
  ArtistSocialInsuranceCode,
  PurchaseOrderType,
  DocumentCurrency,
  useBookingKeysActiveQuery,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useDateConverter } from 'hooks/useDateConverter';
import { isNil } from 'lodash';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFormContext } from 'react-hook-form';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { useShouldShowField } from '../../utils/useShouldShowField';
import { useLinkedPurchaseOrder } from './useLinkedPurchaseOrder';

interface AcceptedValues {
  bookings: Split[];
  amount?: Maybe<number>;
  currency?: Maybe<string>;
}

export interface SplitBookingsFormContainerProps {
  /** Initial open split index */
  defaultSplitIndex?: number;
  /** @todo shouldn’t be needed here */
  documentId: string;
  /** Called when values accepted */
  onAcceptBookings?: (values: AcceptedValues) => void;
  /** Called when splits should be discarded */
  onDiscardBookings?: () => void;
  /** Display form as read only */
  readOnly?: boolean;
  /** List of tax code field items */
  taxCodeFieldItems?: ProcessingFormAccountingDataFieldItem[];
  /** List of (document) type field items */
  typeFieldItems?: ProcessingFormTypeFieldItem[];
}

interface SplitBookingsFormProps {
  purchaseOrderType?: PurchaseOrderType;
  documentDirection?: string;
  initialOpenIndex?: number;
  initialValues: {
    grossAmount: Maybe<number>;
    currency: Maybe<string>;
    invoiceDate: Maybe<Date>;
    bookings?: Split[];
    roundingAmount?: Maybe<number>;
  };
  isFieldEditable: IsFieldEditable;
  mode?: ProcessingFormValues['mode'];
  onAcceptBookings?: (values: AcceptedValues) => void;
  onDiscardBookings?: () => void;
  readOnly?: boolean;
  shouldShowField: ShouldShowField;
}

const SplitBookingsForm = (props: SplitBookingsFormProps) => {
  const { data } = useBookingKeysActiveQuery();

  const isFormReadOnly = props.readOnly;

  const { dateToDateString } = useDateConverter();

  const toBooking = (b: SplitDS): Split => {
    const selectedBookingKey = data?.bookingKeysActive?.find(
      k => k.id === b.taxCode
    );

    const initialCalculatedNetAmount = isFormReadOnly
      ? b.netAmount
      : grossToNet(b.amount ?? 0, b.vatRate ?? 0);

    const initialCalculatedTaxAmount = isNil(initialCalculatedNetAmount)
      ? b.taxAmount
      : roundToCurrencyPrecision((b.amount ?? 0) - initialCalculatedNetAmount);

    return {
      ...b,
      dueDate: b.dueDate ? new Date(b.dueDate) : null,
      bookingKeyId: selectedBookingKey
        ? {
            value: selectedBookingKey.id,
            label: selectedBookingKey.readableName,
          }
        : undefined,
      extraCostInfoId: b.extraCostInfo?.value
        ? {
            value: b.extraCostInfo?.value,
            label: b.extraCostInfo?.inputValue,
          }
        : undefined,
      generalLedgerAccountId: b.generalLedgerAccount?.value
        ? {
            value: b.generalLedgerAccount?.value,
            label: b.generalLedgerAccount?.inputValue,
          }
        : undefined,
      costCenterId: b.costCenter?.value
        ? {
            value: b.costCenter?.value,
            label: b.costCenter?.inputValue,
          }
        : undefined,
      costObjectId: b.costObject?.value
        ? {
            value: b.costObject?.value,
            label: b.costObject?.inputValue,
          }
        : undefined,
      taxPresentation: b.taxPresentation,
      amount:
        b.taxPresentation === TaxPresentation.Gross
          ? b.amount
          : netToGross(b.amount ?? 0, b.vatRate ?? 0),
      netAmount: b.netAmount ?? initialCalculatedNetAmount,
      taxAmount: b.taxAmount ?? initialCalculatedTaxAmount,
    };
  };

  const toBookingDS = (b: Split): SplitDS => {
    const initialCalculatedNetAmount = isFormReadOnly
      ? b.netAmount
      : grossToNet(b.amount ?? 0, b.vatRate ?? 0);

    const initialCalculatedTaxAmount = isNil(initialCalculatedNetAmount)
      ? b.taxAmount
      : roundToCurrencyPrecision((b.amount ?? 0) - initialCalculatedNetAmount);

    return {
      ...b,
      dueDate: b.dueDate ? dateToDateString(b.dueDate) : null,
      taxPresentation: b.taxPresentation || TaxPresentation.Gross,
      generalLedgerAccount: {
        value: b.generalLedgerAccountId?.value,
        inputValue: (b.generalLedgerAccountId?.label as string) ?? null,
      },
      taxCode: b.bookingKeyId?.value ?? b.bookingKey?.value,
      costCenter: {
        value: b.costCenterId?.value,
        inputValue: (b.costCenterId?.label as string) ?? null,
      },
      costObject: {
        value: b.costObjectId?.value,
        inputValue: (b.costObjectId?.label as string) ?? null,
      },
      extraCostInfo: {
        value: b.extraCostInfoId?.value,
        inputValue: (b.extraCostInfoId?.label as string) ?? null,
      },
      netAmount: b.netAmount ?? initialCalculatedNetAmount,
      taxAmount: b.taxAmount ?? initialCalculatedTaxAmount,
      amount:
        (b.taxPresentation || TaxPresentation.Gross) === TaxPresentation.Gross
          ? b.amount
          : grossToNet(b.amount ?? 0, b.vatRate ?? 0),
    };
  };

  const lastBooking = (props.initialValues.bookings || []).length - 1;

  const controlledIndex = props.initialOpenIndex ?? -1;
  const initialOpenIndex = controlledIndex > -1 ? controlledIndex : lastBooking;

  const splitBookingsFormProps: SplitBookingsFormPropsDS = {
    purchaseOrderType: props.purchaseOrderType,
    documentDirection: props.documentDirection,
    initialOpenIndex,
    isFieldEditable: props.isFieldEditable,
    // XXX think about how to handle a mode that is neither approve nor request
    // right now we just want to ensure that validation is not visible when there is no mode
    mode: props.mode || 'requestApproval',
    onAcceptBookings: props.onAcceptBookings
      ? (values: AcceptedValuesDS) => {
          if (props.onAcceptBookings) {
            return props.onAcceptBookings({
              ...values,
              bookings: values.bookings.map(toBooking),
            });
          }
        }
      : undefined,
    onDiscardBookings: props.onDiscardBookings,
    readOnly: props.readOnly,
    shouldShowField: props.shouldShowField,
    initialValues: {
      grossAmount: props.initialValues.grossAmount,
      currency: props.initialValues.currency,
      invoiceDate: props.initialValues.invoiceDate
        ? dateToDateString(props.initialValues.invoiceDate)
        : null,
      bookings: (props.initialValues.bookings || []).map(toBookingDS),
      roundingAmount: props.initialValues.roundingAmount,
    },
  };

  return <SplitBookings {...splitBookingsFormProps} />;
};

export const SplitBookingsFormContainer = ({
  defaultSplitIndex,
  documentId,
  onAcceptBookings,
  onDiscardBookings,
  readOnly,
  taxCodeFieldItems,
  typeFieldItems,
}: SplitBookingsFormContainerProps) => {
  const { dateStringToDate, dateToDateString } = useDateConverter();

  const [netAndTaxAmountFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.netAndTaxAmount,
  ]);

  const { shouldUseSapPurchaseOrder, shouldUseSapNetAmount } = useSap();

  const { data: documentData } = useGetDocumentForDraftQuery({
    variables: { id: documentId },
  });

  const document = documentData?.getDocument ?? undefined;
  const hasTransactions = document?.hasTransactionLinked ?? false;

  const hasPurchaseOrder =
    shouldUseSapPurchaseOrder && !isNil(document?.purchaseOrderData);

  const { showAccountingDataField } = useShouldShowField(document ?? {});

  const { purchaseOrder, isQuantityRequired: isPurhcaseOrderQuantityRequired } =
    useLinkedPurchaseOrder(documentId);

  const shouldHaveQuanity =
    shouldUseSapPurchaseOrder && isPurhcaseOrderQuantityRequired;

  const processingForm = useFormContext<ProcessingFormValues>();
  const processingFormValues = processingForm?.getValues();

  const setProcessingFormMetadata = useSetProcessingFormMetadata();
  const updateProcessingFormField = useUpdateProcessingFormField();
  const calculateRoundingAmount = useCalculateRoundingAmount();

  const bookingsFieldArray = useBookingsFieldArrayContext();

  const defaultValues: SplitBookingsFormProps['initialValues'] = {
    currency: processingFormValues?.currency,
    grossAmount: processingFormValues?.grossAmount,
    invoiceDate: dateStringToDate(processingFormValues?.invoiceDate),
    bookings: processingFormValues?.bookings.map(
      ({
        artistSocialInsuranceCode,
        bookingId,
        costCenter,
        costObject,
        dueDate,
        extraCostInfo,
        generalLedgerAccount,
        taxCode,
        quantity,
        taxPresentation,
        ...restFields
      }) => {
        const taxCodeItem = taxCodeFieldItems?.find(
          item => item.key === taxCode
        );

        return {
          id: bookingId,
          dueDate: dueDate ? dateStringToDate(dueDate) : undefined,
          generalLedgerAccountId: generalLedgerAccount.value
            ? {
                label: generalLedgerAccount.inputValue,
                value: generalLedgerAccount.value,
              }
            : undefined,
          bookingKeyId: taxCodeItem && {
            label: taxCodeItem.children,
            value: taxCodeItem.key,
          },
          costCenterId: costCenter.value
            ? {
                label: costCenter.inputValue,
                value: costCenter.value,
              }
            : undefined,
          costObjectId: costObject.value
            ? {
                label: costObject.inputValue,
                value: costObject.value,
              }
            : undefined,
          artistSocialInsuranceCode:
            artistSocialInsuranceCode as ArtistSocialInsuranceCode,
          extraCostInfoId: extraCostInfo.value
            ? {
                label: extraCostInfo.inputValue,
                value: extraCostInfo.value,
              }
            : undefined,
          taxPresentation: taxPresentation,
          ...(shouldHaveQuanity ? { quantity } : null),
          ...restFields,
        };
      }
    ),
    roundingAmount: processingFormValues?.roundingAmount,
  };

  const typeFieldItem = typeFieldItems?.find(
    item => item.key === processingFormValues.type
  );

  const handleRoundingAmount = ({
    currency,
    grossAmount,
    bookings,
  }: {
    currency: DocumentCurrency;
    grossAmount: ProcessingFormValues['grossAmount'];
    bookings: SplitDS[] | ProcessingFormValues['bookings'];
  }) => {
    if (!shouldUseSapNetAmount) {
      return;
    }

    void calculateRoundingAmount({
      currency,
      grossAmount: grossAmount ?? 0,
      bookings,
    }).then(roundingAmount => {
      updateProcessingFormField?.('roundingAmount', roundingAmount, undefined);
    });
  };

  const handleAcceptBookings = (values: AcceptedValues) => {
    if (processingForm) {
      const newBookings: ProcessingFormValues['bookings'] = values.bookings.map(
        booking => ({
          amount: booking.amount as number,
          taxAmount: booking.taxAmount,
          netAmount: booking.netAmount ?? 0,
          artistSocialInsuranceCode: booking.artistSocialInsuranceCode ?? null,
          costCenter: {
            value: booking.costCenterId?.value ?? null,
            inputValue:
              (booking.costCenterId?.label as string | undefined) ?? '',
          },
          costObject: {
            value: booking.costObjectId?.value ?? null,
            inputValue:
              (booking.costObjectId?.label as string | undefined) ?? '',
          },
          dueDate: booking.dueDate ? dateToDateString(booking.dueDate) : null,
          extraCostInfo: {
            value: booking.extraCostInfoId?.value ?? null,
            inputValue:
              (booking.extraCostInfoId?.label as string | undefined) ?? '',
          },
          generalLedgerAccount: {
            value: booking.generalLedgerAccountId?.value ?? null,
            inputValue:
              (booking.generalLedgerAccountId?.label as string | undefined) ??
              '',
          },
          bookingId: booking.id ?? null,
          note: booking.note ?? null,
          postingText: booking.postingText ?? null,
          taxCode: booking.bookingKeyId?.value ?? null,
          vatRate: booking.vatRate ?? null,
          quantity: booking.quantity as number,
          taxPresentation: booking.taxPresentation,
        })
      );

      const sumOfSplitAmounts = sumBookings(newBookings);
      const difference = roundToCurrencyPrecision(
        (values.amount ?? 0) - sumOfSplitAmounts
      );

      if (difference && !shouldUseSapNetAmount) {
        newBookings[newBookings.length - 1].amount =
          (newBookings[newBookings.length - 1].amount ?? 0) + difference;
      }

      if (values.currency !== defaultValues.currency) {
        updateProcessingFormField?.(
          'currency',
          values.currency as string,
          undefined
        );
      }

      if (values.bookings.length > 1 && netAndTaxAmountFF) {
        const totalTaxAmount = values.bookings.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue.taxAmount ?? 0),
          0
        );

        const totalNetAmount = values.bookings.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue.netAmount ?? 0),
          0
        );

        updateProcessingFormField?.('taxAmount', totalTaxAmount, undefined);
        updateProcessingFormField?.('netAmount', totalNetAmount, undefined);
      }

      handleRoundingAmount({
        currency: values.currency as DocumentCurrency,
        grossAmount: values.amount as number,
        bookings: newBookings,
      });

      // Using setValue on the bookings field array causes unpredictable results
      // The recommended way to replace the values is to use the replace method:
      // https://react-hook-form.com/docs/useform/setvalue
      bookingsFieldArray?.replace(newBookings);
      void processingForm.trigger('bookings'); // trigger validation
      void processingForm.trigger('grossAmount');
      // grossAmount update has calculation with the bookings so we need to update it after bookings update
      if (values.amount !== defaultValues.grossAmount) {
        updateProcessingFormField?.(
          'grossAmount',
          values.amount as number,
          undefined
        );
      }

      setProcessingFormMetadata('bookings', undefined);
      if (hasTransactions) {
        // if we have a linked transaction, due date field needs to keep
        // 'transaction' as its source
        // @TODO this should be handled in a more robust way
        setProcessingFormMetadata('bookings.0.dueDate', {
          confidence: 1,
          source: 'transaction',
        });
      }
    }

    onAcceptBookings?.(values);
  };

  const handleDiscardBookings = () => {
    // Using setValue on the bookings field array causes unpredictable results
    // The recommended way to replace the values is to use the replace method:
    // https://react-hook-form.com/docs/useform/setvalue
    const newBookings = [
      {
        ...processingFormValues.bookings[0],
        amount: processingFormValues.grossAmount,
      },
    ];

    handleRoundingAmount({
      currency: processingFormValues.currency as DocumentCurrency,
      grossAmount: processingFormValues.grossAmount,
      bookings: newBookings,
    });

    bookingsFieldArray?.replace(newBookings);

    setProcessingFormMetadata('bookings', undefined);
    if (hasTransactions) {
      // if we have a linked transaction, due date field needs to keep
      // 'transaction' as its source
      // @TODO this should be handled in a more robust way
      setProcessingFormMetadata('bookings.0.dueDate', {
        confidence: 1,
        source: 'transaction',
      });
    }

    onDiscardBookings?.();
  };

  return (
    <SplitBookingsForm
      documentDirection={typeFieldItem?.direction}
      initialOpenIndex={defaultSplitIndex}
      initialValues={defaultValues}
      isFieldEditable={field => {
        if (readOnly) {
          return false;
        }

        if (hasTransactions) {
          if (
            field === SplitBookingFields.currency ||
            field === SplitBookingFields.dueDate ||
            field === SplitBookingFields.grossAmount
          ) {
            return false;
          }
        }

        if (hasPurchaseOrder) {
          if (
            field === SplitBookingFields.generalLedgerAccountId ||
            field === SplitBookingFields.note
          ) {
            return false;
          }
        }

        return true;
      }}
      onAcceptBookings={onAcceptBookings ? handleAcceptBookings : undefined}
      onDiscardBookings={handleDiscardBookings}
      mode={processingFormValues?.mode}
      readOnly={readOnly}
      shouldShowField={showAccountingDataField}
      purchaseOrderType={purchaseOrder?.purchaseOrderType}
    />
  );
};
