import * as Sentry from '@sentry/react';
import { UserRealm } from 'utils/authorization-helper';
import { prefix } from 'utils/localStorage';

const localStorageKey = `${prefix}-previousOrganizations`;

class Service {
  updateStoredOrganizations(slug: string) {
    const storedOrganizations = this.getStoredOrganizations();
    if (slug) {
      storedOrganizations.unshift(slug);
    }

    localStorage.setItem(
      localStorageKey,
      JSON.stringify(Array.from(new Set(storedOrganizations)))
    );
  }

  getStoredOrganizations(): string[] {
    try {
      return JSON.parse(localStorage.getItem(localStorageKey) as string) ?? [];
    } catch (error) {
      Sentry.captureException(error);
    }

    return [];
  }

  getLatestOrganization() {
    const storedOrganizations = this.getStoredOrganizations();

    return storedOrganizations.length ? storedOrganizations[0] : null;
  }

  reconcileOrganizations(realms: UserRealm[]) {
    const storedOrganizations = this.getStoredOrganizations();
    const filteredOrganizations = storedOrganizations.filter(org =>
      realms.find(realm => realm.realm === org)
    );

    localStorage.setItem(
      localStorageKey,
      JSON.stringify(filteredOrganizations)
    );
  }
}

export const OrganizationHistoryService = new Service();
