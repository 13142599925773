import { useInsightsPreferences } from 'components/Insights/hooks/useInsightsPreferences';
import { useInsightsWidgets } from 'components/Insights/hooks/useInsightsWidgets';
import { WidgetModalContainerProps } from 'components/Insights/Modals/types';
import {
  getWidgetType,
  getIdsFromFilters,
  validStatusesForAggregate,
  mappedErrorsToTranslations,
} from 'components/Insights/Modals/utils';
import { WidgetModalComponent } from 'components/Insights/Modals/WidgetModal/components/WidgetModalComponent';
import { WidgetTypeSelection } from 'components/Insights/Modals/WidgetModal/components/WidgetTypeSelection/WidgetTypeSelection';
import { extractInvoiceDateFromUrl } from 'components/Insights/Widgets/DateRangePicker/util';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DateFilterOption,
  DocumentCurrency,
  DocumentFilters,
  InsightsErrorCodes,
  Maybe,
  useCreateInsightsWidgetMutation,
  useFinancialInsightsLazyQuery,
  useGetInsightsWidgetQuery,
  useUpdateInsightsWidgetMutation,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { refetchInsightsWidgets } from 'views/InsightsWidgets/queries';
import { WidgetForm, WidgetFormSkeleton } from './WidgetForm';
import { getInitialBudgetFields } from './WidgetForm/BudgetTab/utils';
import { WIDGET_TYPE, WidgetFormData } from './WidgetForm/types';
import {
  getInitialFilterOptions,
  sanitizeWidgetFormData,
  toInitialSharedUsersValue,
} from './WidgetFormContainer.helper';

type WidgetRouteParams = {
  organizationSlug: string;
  widgetId?: string;
};

export const WidgetFormContainer = ({
  onCloseWidgetModal,
  visible,
}: WidgetModalContainerProps) => {
  const { widgetId } = useParams<WidgetRouteParams>();
  const { success, error } = useToastMessage();
  const showError = useShowError();
  const currentUser = useCurrentUser();
  const organization = useFullOrganization();
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const [isWidgetShared, setIsWidgetShared] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<WIDGET_TYPE | null>(null);
  const { preferences, loading: loadingPreferences } = useInsightsPreferences();

  const [
    getAggregateCostCenters,
    {
      data: financialInsightsData,
      loading: getSumAggregateCostCentersIsLoading,
    },
  ] = useFinancialInsightsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setTotalAmount(data.financialInsights.sum?.aggregate.amount ?? 0);
    },
  });

  const { totalCount } = useInsightsWidgets();
  const { data: widgetData, loading: loadingWidgetData } =
    useGetInsightsWidgetQuery({
      variables: { id: widgetId as string },
      skip: widgetId === 'create' || !widgetId,
      onCompleted: data => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const isShared = data?.getInsightsWidget?.sharedWithUsers?.length! > 0;
        setIsWidgetShared(isShared);

        const activeFilters = data?.getInsightsWidget?.filters;
        const widgetType = getWidgetType(activeFilters as DocumentFilters);

        if (widgetType) {
          setSelectedType(widgetType);
        }

        const invoiceDate = extractInvoiceDateFromUrl({
          search: window.location.search,
          userId: currentUser?.id,
          forceDefault: totalCount === 0,
        });

        const { dateFrom, dateTo } = invoiceDate.values;

        const {
          costCenterIds,
          costObjectIds,
          contactIds,
          generalLedgerAccountIds,
        } = getIdsFromFilters(activeFilters as Maybe<DocumentFilters>);

        void getAggregateCostCenters({
          variables: {
            filters: {
              costCenterIds,
              costObjectIds,
              contactIds,
              generalLedgerAccountIds,
              status: validStatusesForAggregate,
            },
            dateRangeFilters: {
              invoiceDate: {
                dateFrom,
                dateTo,
                filterOption: DateFilterOption.Custom,
              },
            },
            input: {
              preferences: {
                unit: preferences.unit,
              },
            },
          },
        });
      },
    });

  const [createInsightsWidget] = useCreateInsightsWidgetMutation();
  const [updateInsightsWidget] = useUpdateInsightsWidgetMutation();

  const [totalAmount, setTotalAmount] = useState<number>(0);
  const currency =
    financialInsightsData?.financialInsights.sum?.aggregate.currency ??
    DocumentCurrency.Eur;

  const filters = widgetData?.getInsightsWidget
    ?.filters as Maybe<DocumentFilters>;

  const { costCenters, contacts, costObjects, generalLedgerAccounts } =
    getInitialFilterOptions(filters);

  const budgets = getInitialBudgetFields(widgetData);
  const budgetType = widgetData?.getInsightsWidget?.budget?.[0]?.type;
  const sharedWithUsers =
    widgetData?.getInsightsWidget?.sharedWithUsers?.map(
      toInitialSharedUsersValue
    ) || [];

  const initialValues: WidgetFormData = {
    costCenters: costCenters.map(costCenter => costCenter.key),
    contacts: contacts.map(costCenter => costCenter.key),
    costObjects: costObjects.map(costCenter => costCenter.key),
    generalLedgerAccounts: generalLedgerAccounts.map(
      costCenter => costCenter.key
    ),
    title: widgetData?.getInsightsWidget?.title || '',
    sharedWithUsers: sharedWithUsers.map(costCenter => costCenter.key),
    ...budgets,
  };

  const resetModalValues = ({ isCloseModal }: { isCloseModal: boolean }) => {
    setTotalAmount(0);

    if (isCloseModal) {
      setIsWidgetShared(false);
      setSelectedType(null);
    }
  };

  const isLoading = loadingWidgetData || loadingPreferences;

  const handleCreate = useCallback(
    async (formData: WidgetFormData, widgetShared: boolean) => {
      const input = sanitizeWidgetFormData(formData, widgetShared);
      try {
        const result = await createInsightsWidget({
          variables: { input },
          refetchQueries: refetchInsightsWidgets,
        });

        if (result.data?.createInsightsWidget) {
          success(t('dashboard.createNewWidget.toasts.created'));
        } else if (result.errors?.length) {
          result.errors.forEach(err => {
            const toastMessage =
              mappedErrorsToTranslations[
                err.extensions?.code as InsightsErrorCodes
              ];

            if (toastMessage) {
              error(t(toastMessage));
            }
          });
        }

        onCloseWidgetModal();
        resetModalValues({ isCloseModal: true });
      } catch (error) {
        showError(error as GQLError);

        return validationErrorsExtractor(error);
      }
    },
    [createInsightsWidget, error, onCloseWidgetModal, showError, success, t]
  );

  const handleUpdate = useCallback(
    async (formData: WidgetFormData, widgetShared: boolean) => {
      const input = sanitizeWidgetFormData(formData, widgetShared);
      try {
        const result = await updateInsightsWidget({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          variables: { input, id: widgetId! },
          refetchQueries: refetchInsightsWidgets,
        });

        if (result.data?.updateInsightsWidget) {
          success(
            t('dashboard.createNewWidget.toasts.updated', {
              title: input.title,
            })
          );
        } else if (result.errors?.length) {
          error(
            t('dashboard.createNewWidget.toasts.updateFailed', {
              title: input.title,
            })
          );
        }

        onCloseWidgetModal();
        resetModalValues({ isCloseModal: true });
      } catch (error) {
        showError(error as GQLError);

        return validationErrorsExtractor(error);
      }
    },
    [
      updateInsightsWidget,
      widgetId,
      onCloseWidgetModal,
      success,
      t,
      error,
      showError,
    ]
  );

  if (
    (widgetId !== 'create' && loadingWidgetData) ||
    !organization ||
    isLoading
  ) {
    return (
      <WidgetModalComponent
        aria-label="Category options for Insights"
        onCloseWidgetModal={onCloseWidgetModal}
        visible={visible}
        resetModalValues={resetModalValues}
        selectedWidgetType={selectedType}>
        <WidgetFormSkeleton />
      </WidgetModalComponent>
    );
  }

  if (!selectedType) {
    return (
      <WidgetModalComponent
        aria-label="Category options for Insights"
        onCloseWidgetModal={onCloseWidgetModal}
        visible={visible}
        resetModalValues={resetModalValues}
        selectedWidgetType={selectedType}>
        <WidgetTypeSelection
          hasActiveBookingAccounts={organization?.hasActiveBookingAccounts}
          hasContacts={organization?.hasContacts}
          hasCostCenters={organization?.hasCostCenters}
          hasCostObjects={organization?.hasCostObjects}
          setSelectedType={setSelectedType}
        />
      </WidgetModalComponent>
    );
  }

  return (
    <WidgetModalComponent
      aria-label="Category options for Insights"
      onCloseWidgetModal={onCloseWidgetModal}
      visible={visible}
      resetModalValues={resetModalValues}
      selectedWidgetType={selectedType}>
      <WidgetForm
        filters={filters}
        currency={currency}
        calculateWidgetSum={getAggregateCostCenters}
        sumAggregateCostCenters={totalAmount}
        budgetType={budgetType}
        resetModalValues={resetModalValues}
        budgets={budgets}
        getSumAggregateCostCentersIsLoading={
          getSumAggregateCostCentersIsLoading
        }
        isWidgetShared={isWidgetShared}
        handleSetIsWidgetShared={setIsWidgetShared}
        selectedType={selectedType || WIDGET_TYPE.COST_CENTER}
        onSubmit={
          widgetId !== 'create'
            ? formData => handleUpdate(formData, isWidgetShared)
            : formData => handleCreate(formData, isWidgetShared)
        }
        initialValues={initialValues}
      />
    </WidgetModalComponent>
  );
};
