import { Flex, Link, Text } from '@candisio/design-system';
import { DocumentFileReplacedEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const DocumentFileReplaced = ({
  oldFile,
}: DocumentFileReplacedEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <Flex gap="space2" direction="column">
      <Text fontSize="small">
        {t(
          'document.compact.history.fileReplaced.documentAutomaticallyUpdated'
        )}
      </Text>
      <Link external href={oldFile?.url ?? undefined} fontSize="small">
        {t('document.compact.history.fileReplaced.viewPreviousDocument')}
      </Link>
    </Flex>
  );
};
