import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateOptionMissingInvoicesWidget } from '../CreditCardsInsights/MissingInvoicesWidget/utils';
import { CardHolderCCInsightsWidgetData } from './useTransactionsInsights';

interface MissingInvoicesEmailParams extends CardHolderCCInsightsWidgetData {
  emailLink: string;
  dateOption: DateOptionMissingInvoicesWidget;
}

const mappedDateOptionTranslations: Partial<
  Record<DateOptionMissingInvoicesWidget, string>
> = {
  CurrentMonth: 'insights.missingInvoicesWidget.email.period.currentMonth',
  PreviousMonth: 'insights.missingInvoicesWidget.email.period.previousMonth',
  Last14Days: 'insights.missingInvoicesWidget.email.period.last14Days',
  Last7Days: 'insights.missingInvoicesWidget.email.period.last7Days',
  OlderThan2Months:
    'insights.missingInvoicesWidget.email.period.olderThan2Months',
};

export const useMissingInvoicesEmailText = ({
  cardholder,
  invoicesWithoutTransaction,
  emailLink,
  dateOption,
}: MissingInvoicesEmailParams) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const subjectEmail = t('insights.missingInvoicesWidget.email.subject', {
    count: invoicesWithoutTransaction,
  });

  const fullName = `${cardholder.firstName} ${cardholder.lastName}`;

  const dateOptionTranslation = mappedDateOptionTranslations[dateOption];

  const bodyTranslation =
    dateOption !== 'All'
      ? 'insights.missingInvoicesWidget.email.bodyText'
      : 'insights.missingInvoicesWidget.email.bodyTextNoPeriod';

  const bodyText = t(bodyTranslation, {
    name: fullName,
    count: invoicesWithoutTransaction,
    ...(dateOption !== 'All' &&
      dateOptionTranslation && {
        period: t(dateOptionTranslation),
      }),
  });

  const cardsList = (cardholder.cardsWithMissingInvoices ?? [])
    .map(card => {
      const cardWithMissingInvoices = {
        count: card.invoicesWithoutTransaction,
        refNum: card.refNum ? `•••• ${card.refNum}` : null,
        label: card.label ?? null,
      };

      const cardItem = t(
        'insights.missingInvoicesWidget.email.cardListItem',
        cardWithMissingInvoices
      );

      return cardItem + '\n';
    })
    .join('');

  const transactionViewFilteredURL = window.location.hostname + emailLink;

  const body =
    bodyText + '\n\n' + cardsList + '\n\n ' + transactionViewFilteredURL;

  return { subjectEmail, body };
};
