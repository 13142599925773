import { HistoryLoading } from 'components/History/HistoryLoading';
import { useGetTransactionHistoryQuery } from 'generated-types/graphql.types';
import { TransactionHistory } from '../TransactionHistory';

interface TransactionHistoryContainerProps {
  transactionId: string;
  isTransactionLoading?: boolean;
}

export const TransactionHistoryContainer = ({
  transactionId,
  isTransactionLoading,
}: TransactionHistoryContainerProps) => {
  const { loading: historyLoading, data } = useGetTransactionHistoryQuery({
    variables: { id: transactionId },
  });

  const transaction = data?.getCardIssuerTransactionById;
  if (historyLoading || isTransactionLoading) {
    return <HistoryLoading itemCount={4} />;
  }

  if (!transaction) {
    return null;
  }

  const supportedEvents = transaction.timeline || [];

  return <TransactionHistory events={supportedEvents} />;
};
