import {
  Item,
  Flex,
  Text,
  Icon,
  Heading,
  Switch,
} from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  localFeatureFlagPrefix,
  useCandisFeatureFlags,
} from 'hooks/useCandisFeatureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { escapeRegex } from 'utils/regex';
import { sortByStringProp } from 'utils/sorting';

type FeatureFlagItem = { name: string; value: boolean; found: boolean };
enum Action {
  NO_ACTION = 'NO_ACTION',
  RESET = 'RESET',
  ALL_ON = 'ALL_ON',
  ALL_OFF = 'ALL_OFF',
}

const RECENTLY_USED_FF_KEY = 'CANDIS_RECENTLY_USED_FFS';
const getRecentlyUsedFFs = () => {
  const recentlyUsed = localStorage.getItem(RECENTLY_USED_FF_KEY);

  return recentlyUsed ? recentlyUsed.split(',') : [];
};

const setRecentlyUsedFFs = (ffs: string[]) => {
  localStorage.setItem(RECENTLY_USED_FF_KEY, ffs.join(','));
};

const toggleAllFeatureFlagsInLS = (ffs: FeatureFlagItem[], action: Action) => {
  if (action === Action.NO_ACTION) {
    return null;
  }

  ffs.forEach(ff => {
    if (action === Action.RESET) {
      localStorage.removeItem(`${localFeatureFlagPrefix}.${ff.name}`);
      window.location.reload();
    }

    if (action === Action.ALL_ON) {
      localStorage.setItem(`${localFeatureFlagPrefix}.${ff.name}`, 'true');
    }

    if (action === Action.ALL_OFF) {
      localStorage.setItem(`${localFeatureFlagPrefix}.${ff.name}`, 'false');
    }
  });
};

export const FeatureFlags = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.QA_UTILS);
  const { success } = useToastMessage();
  const ffItems: FeatureFlagItem[] = [];
  const featureFlagValues = useCandisFeatureFlags(Object.values(FEATURE_FLAGS));
  const ldFlags = useFlags();
  Object.entries(FEATURE_FLAGS).forEach(([_, v], i) => {
    ffItems.push({
      name: v,
      value: featureFlagValues[i],
      found: !isNil(ldFlags[v]),
    });
  });

  const [featureFlagItems, setFeatureFlagItems] =
    useState<FeatureFlagItem[]>(ffItems);

  const [queryString, setQueryString] = useState('');
  const [action, setAction] = useState<Action>(Action.NO_ACTION);

  useEffect(() => {
    toggleAllFeatureFlagsInLS(featureFlagItems, action);

    if (action === Action.ALL_ON) {
      const items = featureFlagItems.map(ff => ({
        ...ff,
        value: true,
      }));

      setFeatureFlagItems(items);
      setAction(Action.NO_ACTION);
    }

    if (action === Action.ALL_OFF) {
      const items = featureFlagItems.map(ff => ({
        ...ff,
        value: false,
      }));

      setFeatureFlagItems(items);
      setAction(Action.NO_ACTION);
    }
  }, [action, featureFlagItems, setFeatureFlagItems]);

  const onSearchFilter = (filter: string) => {
    setQueryString(filter);
  };

  const recentlyUsedFFs = getRecentlyUsedFFs();

  const sortedFFItems = featureFlagItems
    ?.sort(sortByStringProp('name'))
    .filter(ff => !recentlyUsedFFs.includes(ff.name));

  const enrichedUsedFFs = recentlyUsedFFs
    .map(ff => featureFlagItems.find(f => f.name === ff))
    .filter(Boolean) as FeatureFlagItem[];

  const mergedFFItems = [...enrichedUsedFFs, ...sortedFFItems].filter(i => !!i);

  const sortedMergedFilteredFFItems = mergedFFItems.filter(
    (ff: FeatureFlagItem) =>
      ff?.name?.match(new RegExp(escapeRegex(queryString), 'i'))
  );

  const ffAction = [
    {
      id: Action.NO_ACTION,
      label: t('featureFlags.actions.noAction'),
    },
    {
      id: Action.RESET,
      label: t('featureFlags.actions.reset'),
    },
    {
      id: Action.ALL_ON,
      label: t('featureFlags.actions.toggleAllOn'),
    },
    {
      id: Action.ALL_OFF,
      label: t('featureFlags.actions.toggleAllOff'),
    },
  ];

  const onToggleFF = (item: FeatureFlagItem) => {
    return () => {
      const flagName = item.name;
      localStorage.setItem(
        `${localFeatureFlagPrefix}.${item.name}`,
        (!item.value).toString()
      );

      item.value = !item.value;

      const recentlyUsed = getRecentlyUsedFFs().filter(ff => ff !== flagName);

      const adjusted =
        recentlyUsed.length >= 5
          ? [
              flagName,
              recentlyUsed[0],
              recentlyUsed[1],
              recentlyUsed[2],
              recentlyUsed[3],
            ]
          : [flagName, ...recentlyUsed];

      setRecentlyUsedFFs(adjusted);

      setFeatureFlagItems([...featureFlagItems]);

      success(
        t('featureFlags.updated', {
          value: item.value ? '"on"' : '"off"',
        })
      );
    };
  };

  return (
    <Flex alignItems="center" direction="column" width="500px" height="80vh">
      <Heading as="h3">{t('featureFlags.title')}</Heading>
      <FilterableList
        searchFieldProps={{ autoFocus: true }}
        children={sortedMergedFilteredFFItems.map(
          (item: FeatureFlagItem, i) => {
            return (
              <Item key={item.name} textValue={item.name}>
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="space8"
                  background={!item.found ? 'redbg' : ''}>
                  <Text>
                    {item.name.replace(/candis_features./, '')}
                    {!item.found ? ' *not found' : ''}
                  </Text>

                  <Flex gap="space16">
                    <Icon
                      size="space32"
                      icon={item.value ? 'check' : 'failCircle'}
                      color={item.value ? 'green500' : 'red500'}
                    />
                    <Switch
                      style={{ marginTop: '6px' }}
                      label="?!?!"
                      checked={item.value}
                      onChange={onToggleFF(item)}
                    />
                  </Flex>
                </Flex>
              </Item>
            );
          }
        )}
        searchField={{
          onSearchFilter,
          placeholder: '',
          searchQuery: queryString,
        }}
        width="500px"
        menuButtons={[
          {
            actionValue: [action],
            onClick: value =>
              setAction(value.length ? (value[0] as Action) : action),
            text: t('featureFlags.actions.title'),
            menuButtonItems: ffAction,
          },
        ]}
        isLoading={false}
      />
    </Flex>
  );
};
