import { useState } from 'react';
import { useCreditCardOptions } from 'views/Settings/Teams/toolkit/hooks/useCreditCardOptions';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { AssignCards } from './AssignCards';

interface AssignCardsContainerProps {
  isSubmitting: boolean;
  team: Team;
  onSubmit: () => void;
  onGoBack: () => void;
}

export const AssignCardsContainer = ({
  isSubmitting,
  team,
  onGoBack,
  onSubmit,
}: AssignCardsContainerProps) => {
  const [search, setSearch] = useState('');
  const teamAdminId = team.teamAdmin?.id ? [team.teamAdmin?.id] : [];
  const teamMembersIds = (team.members ?? []).map(member => member.id);

  const {
    creditCardOptions,
    handleDebounceSearch,
    loadMore,
    loading: isLoadingCreditCardOptions,
  } = useCreditCardOptions({
    searchStr: search,
    selectedValues: [],
    teamMemberIds: [...teamAdminId, ...teamMembersIds],
  });

  const handleSearch = (value: string) => {
    setSearch(value);
    handleDebounceSearch(value);
  };

  return (
    <AssignCards
      onClick={onSubmit}
      isSubmitting={isSubmitting}
      isLoadingCreditCardOptions={isLoadingCreditCardOptions}
      onGoBack={onGoBack}
      onLoadMore={loadMore}
      search={search}
      onSearch={handleSearch}
      creditCardOptions={creditCardOptions}
    />
  );
};
