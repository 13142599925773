import { useQuery } from '@apollo/client';
import { Query } from 'generated-types/graphql.types';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { emailAccountsQuery } from '../../queries';
import { emailLabels } from '../../utils/emailLabels';
import { ErrorCard } from './ErrorCard';
import { ImportCard } from './ImportCard';
import { NoEmailCard } from './NoEmailCard';

export const Cards = () => {
  const organizationId = useOrganizationId();

  // TODO: ideally we should use only fetch the e-mail accounts of a single
  // organization but such an endpoint does not exist yet.
  const { data, error, loading } = useQuery<Pick<Query, 'getOrganizations'>>(
    emailAccountsQuery,
    {
      // 5 minutes
      pollInterval: 1000 * 60 * 5,
    }
  );

  if (error) {
    return <ErrorCard loading={loading} />;
  }

  const orgEmailLabels = emailLabels(data?.getOrganizations).for(
    organizationId ?? ''
  );

  if (!orgEmailLabels.hasEmail()) {
    return <NoEmailCard loading={loading} />;
  }

  const emails = orgEmailLabels.listAll();

  return <ImportCard emails={emails} loading={loading} />;
};
