import { FieldPolicy, TypePolicies } from '@apollo/client';
import { MerchantsConnectionPageBased } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const cardIssuerMerchant: FieldPolicy<MerchantsConnectionPageBased> = {
  keyArgs: ['sortBy', 'queries'],
  merge: simplePaginationMergeWithoutDuplicates('MerchantsConnectionPageBased'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      getPageBasedCardIssuerMerchants: cardIssuerMerchant,
    },
  },
};
