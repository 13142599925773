import { Box, Drawer, Flex } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { CreateCreditCardModal } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModal';
import { CreditCardDrawer } from 'containers/credit-cards/CreditCardDrawer';
import { UserRole } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardsHeader } from '../CreditCardsHeader';
import {
  OrganizationSettingsDrawer,
  orgSettingsParam,
} from '../CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import {
  CreditCardsTab,
  CreditCardsTabs,
} from '../CreditCardsTabs/CreditCardsTabs';
import { CreditCardsTableTab } from './CreditCardsTableTab';

export const CreditCardsTableView = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { creditCardId, organizationSlug } = useParams<{
    creditCardId: string;
    organizationSlug: string;
  }>();

  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const closeDrawer = () => {
    const pathname = `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`;

    navigate({
      search,
      pathname,
    });
  };

  return (
    <CreditCardsProvider>
      <Box height="100%" overflow="hidden">
        <Flex
          overflow="hidden"
          height="100%"
          gap="space24"
          direction="column"
          paddingX="space32"
          paddingTop="space40 "
          paddingBottom="space8">
          <Flex direction="column" gap="space16">
            <CreditCardsHeader selectedTab={CreditCardsTab.Table} />
            {creditCardsStatementsFF ? (
              <CreditCardsTabs defaultSelectedKey={CreditCardsTab.Table} />
            ) : (
              <UserRoleAccessWrapper
                allowedRoles={[UserRole.CardManager, UserRole.Accountant]}
                mode="hidden">
                <CreditCardsTabs defaultSelectedKey={CreditCardsTab.Table} />
              </UserRoleAccessWrapper>
            )}
          </Flex>
          <CreditCardsTableTab />
        </Flex>
      </Box>
      <>
        {creditCardId === 'request' ? (
          <CreateCreditCardModal
            isOpen={creditCardId === 'request'}
            onClose={closeDrawer}
          />
        ) : undefined}
        <Drawer
          width="40.5rem"
          isOpen={
            !isNil(creditCardId) &&
            creditCardId !== 'request' &&
            creditCardId !== orgSettingsParam
          }
          onClose={closeDrawer}>
          <CreditCardDrawer cardId={creditCardId} onClose={closeDrawer} />
        </Drawer>
      </>
      <OrganizationSettingsDrawer />
    </CreditCardsProvider>
  );
};
