import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

export const GeneralLedgerAccount = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="bookings[0].generalLedgerAccount.inputValue"
      label={t('document.requestApproval.inputs.generalLedger.label')}
      readOnly
    />
  );
};
