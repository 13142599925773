import { Loader } from 'components/Loader';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import {
  DocumentStatus,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
// eslint-disable-next-line no-restricted-imports
import { Redirect } from 'react-router-dom';
import { ProcessingFormContainer } from 'views/Inbox/DocumentProcessing/ProcessingFormContainer';

export interface DocumentTransactionProcessingProps {
  cycleTransaction: () => void;
  documentId: string;
  editRequestDocumentMode: boolean;
  isArchivedTx: boolean;
  organizationSlug: string;
  setEditRequestedDocumentMode: (state: boolean) => void;
  toggleEditRequestedDocumentMode: () => void;
  transactionCursor: string | undefined;
  transactionId: string;
}

export const DocumentTransactionProcessing = ({
  cycleTransaction,
  documentId,
  isArchivedTx,
  organizationSlug,
  transactionId,
}: DocumentTransactionProcessingProps) => {
  const { data } = useGetDocumentForDraftQuery({
    variables: { id: documentId },
    skip: !documentId,
  });

  const document = data?.getDocument;

  if (!document) {
    return <Loader />;
  } else if (
    document.status !== DocumentStatus.New &&
    document.status !== DocumentStatus.Rejected &&
    !isArchivedTx
  ) {
    return (
      <Redirect
        to={`/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}/${transactionId}`}
      />
    );
  } else {
    return (
      <ProcessSidebar documentId={documentId} documentStatus={document.status}>
        <ProcessingFormContainer
          documentId={documentId}
          onApprove={cycleTransaction}
          onDeleteDocument={cycleTransaction}
          onRequestApproval={cycleTransaction}
        />
      </ProcessSidebar>
    );
  }
};
