import { useApproversQuery } from 'generated-types/graphql.types';

import { useToApproverFieldItem } from 'views/Inbox/DocumentProcessing/useToApproverFieldItem';

export const useApproversFieldOptions = () => {
  const { data } = useApproversQuery({ variables: { name: '' } });

  const toApproverFieldItem = useToApproverFieldItem([], data?.approvers ?? []);

  return {
    defaultItems: data?.approvers.map(toApproverFieldItem),
  };
};
