import { Drawer } from '@candisio/design-system';
import { DrawerLayoutProps } from 'components/DrawerLayout/DrawerLayout';
import {
  UseDocumentTagsOptions,
  useMutateDocumentTags,
} from '../../toolkit/hooks/useMutateDocumentTags';
import { DocumentTagDetails } from './DocumentTagDetails';
import { DocumentTagDetailsFormValues } from './Form/documentTagDetailsFormSchema';

export interface DocumentTagDetailsDrawerProps
  extends Pick<DrawerLayoutProps, 'compact'> {
  isOpen: boolean;
  onCloseDrawer: () => void;
  documentTagId?: string;
  defaultName?: string;
  onTagCreated?: UseDocumentTagsOptions['onTagCreated'];
}

export const DocumentTagDetailsDrawer = ({
  isOpen,
  onCloseDrawer,
  compact,
  documentTagId,
  defaultName,
  onTagCreated,
}: DocumentTagDetailsDrawerProps) => {
  const {
    creatingTag,
    handleSubmit,
    archiveTag,
    activateTag,
    loadingTag,
    tag,
    updatingTag,
    updatingTagStatus,
  } = useMutateDocumentTags({
    onCloseDrawer,
    documentTagId,
    onTagCreated,
  });

  const defaultValues: DocumentTagDetailsFormValues = tag ?? {
    name: defaultName ?? '',
  };

  return (
    <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
      {!loadingTag && (
        <DocumentTagDetails
          onCloseDrawer={onCloseDrawer}
          compact={compact}
          documentTagId={documentTagId}
          onArchive={archiveTag}
          onActivate={activateTag}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          submitting={creatingTag || updatingTag}
          updatingStatus={updatingTagStatus}
          isActive={tag?.isActive}
        />
      )}
    </Drawer>
  );
};
