import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useGetRolesForUser } from '../hooks/useGetRolesForUser';
import { useGetUsers } from '../hooks/useGetUsers';
import { ROLE_FILTER_PARAM, UsersListView } from './UsersListView';

interface UsersListViewContainerProps {
  openInviteForm: () => void;
  toggleDrawer: (
    id: string & {
      emailVerified: boolean;
    }
  ) => void;
}

export const UsersListViewContainer = ({
  openInviteForm,
  toggleDrawer,
}: UsersListViewContainerProps) => {
  const { searchParams } = useMutateSearchParams();
  const selectedRoleName = searchParams.get(ROLE_FILTER_PARAM);
  const roleNames = selectedRoleName ? [selectedRoleName] : undefined;

  const {
    handleSearch,
    handleSetSortBy,
    isLoadingUsers,
    queryString,
    users,
    sortBy,
    activeTab,
  } = useGetUsers({
    filters: { roleNames },
  });

  const { roles, loading } = useGetRolesForUser();

  return (
    <UsersListView
      onSearch={handleSearch}
      toggleDrawer={toggleDrawer}
      onSetSortBy={handleSetSortBy}
      onOpenInviteForm={openInviteForm}
      isLoadingRoles={loading}
      isLoadingUsers={isLoadingUsers}
      queryString={queryString}
      users={users}
      roles={roles}
      sortBy={sortBy}
      activeTab={activeTab}
    />
  );
};
