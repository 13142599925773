import { UseFormReturn, FieldValues } from 'react-hook-form';
import {
  WidgetFormAnnualBudget,
  WidgetFormQuarterBudget,
  quarterFields,
  yearsFields,
} from './types';

export const assignBudgetsToFormFields = (
  budgets: WidgetFormAnnualBudget | WidgetFormQuarterBudget,
  form: UseFormReturn<FieldValues, any, undefined>
) => {
  Object.entries(budgets).forEach(([formField, value]) =>
    form.setValue(formField, value)
  );
};

export const cleanQuarterBudgetsForm = (
  form: UseFormReturn<FieldValues, any, undefined>
) => {
  quarterFields.forEach(quarter => form.setValue(quarter, undefined));
};

export const cleanYearBudgetsForm = (
  form: UseFormReturn<FieldValues, any, undefined>
) => {
  yearsFields.forEach(year => form.setValue(year, undefined));
};
