import { CardType } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCreateCreditCardModalState } from '../CreateCreditCardModalDataProvider';
import { formatValidityPeriod } from '../utils';
import { useGetSelectedCarholder } from './useGetSelectedCardholder';

export const useCreditCardPlaceholderDetails = () => {
  const currentUser = useCurrentUser();
  const { selectedCardholder } = useGetSelectedCarholder();
  const { isCardManager } = useUserRoles();
  const modalState = useCreateCreditCardModalState();

  const isSingleUseCard = modalState.cardType === CardType.SingleUse;

  const expiration = modalState.cardDetails?.validity
    ? formatValidityPeriod(Number(modalState.cardDetails?.validity))
    : undefined;

  const currentUserAvatarUrl = currentUser?.avatarUrl as string | undefined;

  const cardDetails = {
    avatarUrl: isCardManager
      ? selectedCardholder.avatarUrl
      : currentUserAvatarUrl,
    name: isCardManager ? selectedCardholder.fullName : currentUser?.name,
    cardType: modalState.cardType,
    label: isSingleUseCard
      ? modalState.cardDetails?.purpose
      : modalState.cardDetails?.cardLabel,
    expiration,
  };

  return { cardDetails };
};
