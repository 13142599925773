// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { orgSettingsParam } from 'views/CreditCards/CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import { StatementDetailsContainer } from './StatementDetailsContainer';
import { StatementDetailsDrawerLayout } from './StatementDetailsDrawerLayout';

interface StatementDetailsDrawerProps {
  onClose: () => void;
}

export const StatementDetailsDrawer = ({
  onClose,
}: StatementDetailsDrawerProps) => {
  const { statementId } = useParams<{ statementId: string | undefined }>();

  const isOpen =
    Boolean(statementId) &&
    statementId !== 'request' &&
    statementId !== orgSettingsParam;

  return (
    <StatementDetailsDrawerLayout onClose={onClose} isOpen={isOpen}>
      {/** When isOpen goes from true to false the drawer takes some time to close and it shows wrong data because we do not have an id
       * that is why we just do not render anything when it is closing
       */}
      {Boolean(statementId) ? (
        <StatementDetailsContainer key={`isOpen-${isOpen}`} />
      ) : null}
    </StatementDetailsDrawerLayout>
  );
};
