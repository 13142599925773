import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export type RelativeReminderDateKey =
  | '1weeks'
  | '2weeks'
  | '4weeks'
  | '2months'
  | '3months'
  | 'custom';

interface RelativeReminderDateItem {
  key: RelativeReminderDateKey;
  children: string;
  amount: number | undefined;
  unit: string | undefined;
}

export const useRelativeReminderDateItems = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const relativeReminderDate = [
    {
      key: '1weeks',
      children: t('storageForm.fields.relativeDate.1week'),
      amount: 1,
      unit: 'week',
    },
    {
      key: '2weeks',
      children: t('storageForm.fields.relativeDate.2weeks'),
      amount: 2,
      unit: 'weeks',
    },
    {
      key: '4weeks',
      children: t('storageForm.fields.relativeDate.4weeks'),
      amount: 4,
      unit: 'weeks',
    },
    {
      key: '2months',
      children: t('storageForm.fields.relativeDate.2months'),
      amount: 2,
      unit: 'months',
    },
    {
      key: '3months',
      children: t('storageForm.fields.relativeDate.3months'),
      amount: 3,
      unit: 'months',
    },
    {
      key: 'custom',
      children: t('storageForm.fields.relativeDate.custom'),
      amount: undefined,
      unit: undefined,
    },
  ] as const satisfies readonly RelativeReminderDateItem[];

  return relativeReminderDate;
};
