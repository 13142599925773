import {
  Locale,
  Money,
  TransactionAmount,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export const formatIntegerAmountToDecimal = (
  integerAmount: number,
  precision: number
): number => integerAmount / 10 ** precision;

export const formatDecimalToIntegerAmount = (
  decimalAmount: number,
  precision: number = 2
): number => Math.round(decimalAmount * 10 ** precision);

/**
 * Use @documentMoneyFormatter if the `currency` type is `DocumentCurrency`
 *
 * Use @transactionAmountFormatter if the `currency` type is `Scalars['String']`
 *
 * @returns A formatter functions to display the money object as should be in the user's currency
 */
export const useMoneyFormatter = () => {
  const user = useCurrentUser();
  const locale = user?.locale === Locale.En ? 'en-US' : 'de-DE';

  /**
   * @param money Money object with amount, currency and precision
   * @returns string
   */
  const documentMoneyFormatter = (money: Money): string =>
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: money.currency,
    }).format(formatIntegerAmountToDecimal(money.amount, money.precision));

  /**
   * @param money TransactionAmount object with value, currency and precision
   * @returns string
   */
  const transactionAmountFormatter = (
    money: TransactionAmount,
    options?: Intl.NumberFormatOptions | undefined
  ): string =>
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: money.currency,
      ...options,
    }).format(formatIntegerAmountToDecimal(money.value, money.precision));

  return { documentMoneyFormatter, transactionAmountFormatter };
};
