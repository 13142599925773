import { useResetTableConfigurationsMutation } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { useCallback } from 'react';

interface ResetTableConfigurationsParams {
  tableId: string;
}

interface UseResetTableConfigurationsReturn {
  resetTableConfigurations: ({
    tableId,
  }: ResetTableConfigurationsParams) => Promise<{
    status: 'error' | 'success';
  }>;
  isResetPending: boolean;
}

export const useResetTableConfigurations =
  (): UseResetTableConfigurationsReturn => {
    const user = useCurrentUser();

    const [resetTableConfigurations, { loading }] =
      useResetTableConfigurationsMutation({
        refetchQueries: [
          {
            query: currentUserQuery,
            variables: {
              id: user?.id,
            },
          },
        ],
      });

    const handleResetTableConfigurations: UseResetTableConfigurationsReturn['resetTableConfigurations'] =
      useCallback(
        async ({ tableId }: ResetTableConfigurationsParams) => {
          const response = await resetTableConfigurations({
            variables: { input: { tableId } },
          });

          if (response.errors?.length) {
            return { status: 'error' };
          }

          return { status: 'success' };
        },
        [resetTableConfigurations]
      );

    return {
      resetTableConfigurations: handleResetTableConfigurations,
      isResetPending: loading,
    };
  };
