import { Text } from '@candisio/design-system';
import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { DocumentTransactionUnlinkedEventDataFragment } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { amountFormat } from 'utils/format';

export const DocumentTransactionUnlinkedComponent = ({
  merchantName,
  amount,
  createdAt,
  clientInfo,
}: DocumentTransactionUnlinkedEventDataFragment) => {
  const [t] = useTranslation();

  if (!createdAt || !merchantName || isNil(amount)) {
    return (
      <Text>
        {t('transactions:documentHistoryCCEvents.transactionUnlinked')}
      </Text>
    );
  }

  const formattedAmount =
    amountFormat(amount.amount, amount.currency, {
      convertToBasicMonetaryUnit: true,
    }) || '-';

  const formattedDate = DateFormatters.compact(new Date(createdAt));

  return (
    <TimelineEventWithMobileIndication
      iconName="mobileDelete"
      clientName={clientInfo?.name}
      eventComponent={
        <Text>
          {t(
            'transactions:documentHistoryCCEvents.transactionUnlinkedWithDetails',
            {
              merchantName: merchantName,
              amount: formattedAmount,
              createdAt: formattedDate,
            }
          )}
        </Text>
      }
    />
  );
};
