import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useNewsCardContent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  const newsContentCards = [
    {
      imageUrl: t('newsCarousel.card1.imageUrl'),
      imageAlt: 'quartalsbericht',
      tag: t('newsCarousel.card1.tag'),
      title: t('newsCarousel.card1.title'),
      description: t('newsCarousel.card1.description'),
      buttonText: t('newsCarousel.card1.buttonText'),
      buttonUrl: t('newsCarousel.card1.buttonUrl'),
      backgroundColor: 'purple700',
    },
    {
      imageUrl: t('newsCarousel.card2.imageUrl'),
      imageAlt: 'candis-akademie',
      tag: t('newsCarousel.card2.tag'),
      title: t('newsCarousel.card2.title'),
      buttonText: t('newsCarousel.card2.buttonText'),
      buttonUrl: t('newsCarousel.card2.buttonUrl'),
      backgroundColor: 'gray800',
    },
    {
      imageUrl: t('newsCarousel.card3.imageUrl'),
      imageAlt: 'quartalsbericht',
      tag: t('newsCarousel.card3.tag'),
      title: t('newsCarousel.card3.title'),
      description: t('newsCarousel.card3.description'),
      buttonText: t('newsCarousel.card3.buttonText'),
      buttonUrl: t('newsCarousel.card3.buttonUrl'),
      backgroundColor: 'yellow450',
    },
  ];

  return { newsContentCards };
};
