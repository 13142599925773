import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

export const AccountsPayable = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="accountsPayableNumber"
      label={t('settings.contacts.details.edit.accountsPayableNumber.label')}
      readOnly
    />
  );
};
