import { useDatevSettings } from 'hooks/useDatevSettings';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { isNil } from 'lodash';
import { accountsNumberSchemaWithDuplicateCheck } from 'views/Contacts/ContactDetails/ContactForm/accountsNumberSchema';
import { z } from 'zod';

export const useContactSettingsFormSchema = () => {
  const integration = useIntegrationSettings();

  const { data: datevSettings } = useDatevSettings();
  const glaLength = !isNil(
    datevSettings?.new_datevSettings?.chartOfAccount?.accountLength
  )
    ? Number(datevSettings?.new_datevSettings?.chartOfAccount?.accountLength)
    : undefined;

  const accountsPayableNumberSchema = accountsNumberSchemaWithDuplicateCheck({
    integration,
    accountType: 'accountsPayable',
    glaLength,
  }).nullable();

  return z.object({
    startValue: z.preprocess(value => {
      if (typeof value === 'string') return value.replaceAll(' ', '');

      return value;
    }, accountsPayableNumberSchema),
  });
};

export type ContactSettingsFormOutput = z.infer<
  ReturnType<typeof useContactSettingsFormSchema>
>;
export type ContactSettingsFormValues = Partial<ContactSettingsFormOutput>;
