import { Box, Button, Flex } from '@candisio/design-system';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  PromotionLocation,
  PromotionsTrackingEvents,
} from 'providers/AnalyticsProvider/events/PromotionsTrackingEvents';
import { usePromotionsContext } from '../context/PromotionsProvider';
import { Promotion } from '../types';
import { PromotionCallInAction } from './PromotionCallInAction/PromotionCallInAction';
import { PromotionContent } from './PromotionContent/PromotionContent';
import { PromotionHeader } from './PromotionHeader/PromotionHeader';
import { PromotionImages } from './PromotionImages/PromotionImages';

export type PromotionCardProps = { promotion: Promotion };

export const PromotionCard = ({ promotion }: PromotionCardProps) => {
  const { hidePromotionCard } = usePromotionsContext();

  const { track } = useAnalytics();

  const trackEvent = () => {
    track(PromotionsTrackingEvents.PROMOTION_HIDDEN_CLICKED, {
      placement: PromotionLocation.DASHBOARD,
      promotionId: promotion.id,
    });
  };

  const onClickCloseIcon = () => {
    hidePromotionCard?.(promotion?.id);
    trackEvent();
  };

  return (
    <Flex
      width="100%"
      height="331px"
      justifyContent="stretch"
      alignItems="start"
      align-self="stretch"
      borderRadius="medium"
      overflow="hidden">
      <Flex
        width="40%"
        height="100%"
        direction="column"
        justifyContent="space-between"
        color="gray800"
        fontSize="large"
        lineHeight="space32"
        paddingTop="space20"
        background="gray0"
        flexGrow="1">
        <Flex direction="column" gap="space24">
          <PromotionHeader promotion={promotion} />
          <PromotionContent promotion={promotion} />
        </Flex>

        <Flex direction="column" padding="space20">
          <PromotionCallInAction promotion={promotion} />
        </Flex>
      </Flex>
      <PromotionImages promotion={promotion} />
      <Box
        position="absolute"
        top="0"
        right="0"
        style={{ padding: '10px 10px 0', opacity: '0.5' }}>
        <Button
          icon="close"
          variant="tertiary"
          color="gray"
          onClick={onClickCloseIcon}
        />
      </Box>
    </Flex>
  );
};
