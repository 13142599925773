import { CardIssuerTransactionInvoiceNotNeededReason } from 'generated-types/graphql.types';

export enum UploadPermissions {
  Upload_Allowed = 'UPLOAD_ALLOWED',
  Upload_Not_Allowed = 'UPLOAD_NOT_ALLOWED',
}

export const noInvoiceNeededOptions = [
  {
    value: CardIssuerTransactionInvoiceNotNeededReason.NeedsNoInvoice,
    message:
      'transactionAssociation.noInvoiceNeeded.popover.options.needs_no_invoice',
  },
  {
    value: CardIssuerTransactionInvoiceNotNeededReason.InvoiceAlreadyExported,
    message:
      'transactionAssociation.noInvoiceNeeded.popover.options.invoice_already_exported',
  },
  {
    value: CardIssuerTransactionInvoiceNotNeededReason.Other,
    message: 'transactionAssociation.noInvoiceNeeded.popover.options.other',
  },
];

export const getColorCode = (style: string, isFileDroppable: boolean) => {
  const colorCode: Record<
    string,
    {
      UPLOAD_ALLOWED: string;
      UPLOAD_NOT_ALLOWED: string;
    }
  > = {
    color: {
      UPLOAD_ALLOWED: 'blue500',
      UPLOAD_NOT_ALLOWED: 'red500',
    },
    background: {
      UPLOAD_ALLOWED: 'bluebg',
      UPLOAD_NOT_ALLOWED: 'redbg',
    },
  };

  if (isFileDroppable)
    return colorCode[style][UploadPermissions.Upload_Allowed];

  return colorCode[style][UploadPermissions.Upload_Not_Allowed];
};
