import { Grid, ScrollBox } from '@candisio/design-system';
import {
  WorkflowStepResolutionTypes,
  WorkflowVisualizationFragment,
} from 'generated-types/graphql.types';
import { ApprovalStep } from './ApprovalStep';
import { WorkflowTitle } from './WorkflowTitle';

export interface WorkflowVisualizationProps {
  workflow: WorkflowVisualizationFragment;
}

export const WorkflowVisualization = ({
  workflow,
}: WorkflowVisualizationProps) => {
  const steps = workflow.steps ?? [];

  const activeIndex =
    steps.findIndex(
      step => step.resolution?.type === WorkflowStepResolutionTypes.Pending
    ) ?? 0;

  return (
    <Grid gap="space4">
      {workflow.name && (
        <WorkflowTitle
          name={workflow.name}
          description={workflow.description ?? undefined}
        />
      )}
      <ScrollBox scrollDirection="y" maxHeight="250px">
        <Grid gap="space4">
          {steps.map((step, index) => {
            const isActive = index === activeIndex;
            const isShadowed = index > activeIndex;

            return (
              <ApprovalStep
                key={index}
                step={step}
                orderNumber={index + 1}
                isActive={isActive}
                isShadowed={isShadowed}
              />
            );
          })}
        </Grid>
      </ScrollBox>
    </Grid>
  );
};
