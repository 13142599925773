import { FieldPolicy, TypePolicies } from '@apollo/client';
import { EmailInboxPaginationResponse } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const emailInboxPaginationFieldPolicy: FieldPolicy<EmailInboxPaginationResponse> =
  {
    merge: simplePaginationMergeWithoutDuplicates(
      'emailInboxPaginationResponse'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      emailInboxPagination: emailInboxPaginationFieldPolicy,
    },
  },
};
