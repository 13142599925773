import {
  getThreeWayMatchResult,
  ThreeWayMatchResult,
} from 'utils/three-way-match';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { useLinkedPurchaseOrder } from 'views/Inbox/DocumentProcessing/useLinkedPurchaseOrder';

export default function useThreeWayMatch(documentId?: string): {
  isLoading?: boolean;
  purchaseOrder?: ReturnType<typeof useLinkedPurchaseOrder>['purchaseOrder'];
  result: ThreeWayMatchResult;
} {
  const { data: bookingFormData } = useBookingsFormContext();
  const { purchaseOrder, loading } = useLinkedPurchaseOrder(documentId);

  if (loading) {
    return {
      isLoading: true,
      result: {
        data: [],
      },
    };
  }

  if (!purchaseOrder) {
    return {
      result: {
        data: [],
      },
    };
  }

  const result = getThreeWayMatchResult({
    bookingFormData,
    purchaseOrderData:
      purchaseOrder.goodsLineItems?.map(item => ({
        price: item.lineTotal,
        quantity: item.quantity || 0,
        description: item.description || '',
        articleNumber: item.itemNumber || '',
      })) || [],
  });

  return {
    result,
    purchaseOrder,
  };
}
