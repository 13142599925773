import { WizardModal } from 'components/WizardModal/WizardModal';
import { WizardModalGenerationFooter } from 'components/WizardModal/WizardModalGenerationFooter';
import { WizardModalPreviewFooter } from 'components/WizardModal/WizardModalPreviewFooter';
import { AnimateSharedLayout } from 'framer-motion';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactImportConfirmationModal } from 'views/Contacts/ContactImport/ContactImportConfirmationModal';
import { ContactImportInfoList } from 'views/Contacts/ContactImport/ContactImportInfoList';
import { ContactImportPreviewTable } from 'views/Contacts/ContactImport/ContactImportPreviewTable/ContactImportPreviewTable';
import {
  CONTACT_IMPORT_ACTION,
  ImportSteps,
} from 'views/Contacts/ContactImport/contactImportReducer';
import { useContactImport } from 'views/Contacts/ContactImport/useContactImport';
import { ContactImportForm } from './ContactImportForm';

export const ContactImportContainer = () => {
  const [t] = useTranslation();

  const {
    isParsingCSV,
    closeModal,
    form,
    visible,
    handleSubmit,
    contactImportState,
    dispatchContactsImportAction,
    numberOfContactsToBeImported,
    onUpdateFilter,
    handleImport,
    canImport,
    importHistoryData,
  } = useContactImport();

  return (
    <FormProvider {...form}>
      <WizardModal
        onClose={closeModal}
        isOpen={visible}
        title={t('contacts:import.title')}
        stepperPadding={
          contactImportState.importStep === ImportSteps.Preview
            ? 'space48'
            : 'space32'
        }
        currentIndex={contactImportState.importStep}
        gap="0"
        width={
          contactImportState.importStep === ImportSteps.Preview
            ? 'calc(100vw - space64)'
            : '60rem'
        }
        steps={[
          t('contacts:import.steps.selectFile'),
          t('contacts:import.steps.preview'),
        ]}
        footer={
          form.formState.isSubmitting ||
          !form.formState.isSubmitSuccessful ||
          (contactImportState.importStep > 0 && (
            <AnimateSharedLayout>
              {contactImportState.importStep === ImportSteps.SelectFile ? (
                <WizardModalGenerationFooter
                  canGoBack={!form.formState.isSubmitting}
                  onGoBack={form.reset}
                />
              ) : (
                <WizardModalPreviewFooter
                  canImport={canImport}
                  importing={contactImportState.importing}
                  onGoBack={() => {
                    dispatchContactsImportAction({
                      type: CONTACT_IMPORT_ACTION.RESET,
                    });
                  }}
                  onImport={() => {
                    dispatchContactsImportAction({
                      type: CONTACT_IMPORT_ACTION.SHOW_CONFIRMATION,
                    });
                  }}
                  infoList={
                    <ContactImportInfoList
                      importStep={contactImportState.importStep}
                      contacts={contactImportState.parsedContacts}
                      onUpdateFilter={onUpdateFilter}
                    />
                  }
                />
              )}
            </AnimateSharedLayout>
          ))
        }>
        {contactImportState.importStep === ImportSteps.SelectFile && (
          <ContactImportForm onSubmit={handleSubmit} />
        )}
        {contactImportState.importStep === ImportSteps.Preview && (
          <ContactImportPreviewTable
            filters={contactImportState.previewFilters}
            contacts={contactImportState.parsedContacts}
            loading={form.formState.isLoading || isParsingCSV}
          />
        )}
        <ContactImportConfirmationModal
          visible={contactImportState.showConfirmation}
          importing={contactImportState.importing}
          successCount={numberOfContactsToBeImported}
          onImport={handleImport}
          onClose={() => {
            dispatchContactsImportAction({
              type: CONTACT_IMPORT_ACTION.RESET,
            });
          }}
          numberOfContactsToBeImported={numberOfContactsToBeImported}
          numberOfImportedContacts={
            importHistoryData?.contactsImportHistoryById?.importedContacts
              ?.length ?? 0
          }
        />
      </WizardModal>
    </FormProvider>
  );
};
