import { useMemo } from 'react';

const tableHeaderHeight = 56;
const rowHeight = 43;

// Firefox is rendering scrollbar as an overlay and dont take up space - good
// Chrome is rendering scrollbar inside the component taking up height - not good
// TODO: find a better way around it
const scrollbarWidth = 8;

const maxAllowedHeight = '100%';

export const useExportTableMaxHeight = (
  rowsCount: number
): `${number}px` | typeof maxAllowedHeight => {
  const height = useMemo<number>(
    () =>
      Math.round(tableHeaderHeight + rowsCount * rowHeight + scrollbarWidth),
    [rowsCount]
  );

  return rowsCount === 0 ? maxAllowedHeight : `${height}px`;
};
