import { MenuButton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface CreateExpenseActionMenuButtonProps {
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
  creatingExpense: boolean;
}

export const CreateExpenseActionMenuButton = ({
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
  creatingExpense,
}: CreateExpenseActionMenuButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <MenuButton
      icon="plus"
      iconPosition="left"
      variant="tertiary"
      selectionMode="single"
      size="small"
      loading={creatingExpense}
      items={[
        {
          id: 'hospitality-expense',
          label: t('reimbursementView.middleSection.form.hospitality.addCta'),
          onAction: onHospitalityExpenseClick,
        },
        {
          id: 'general-expense',
          label: t('reimbursementView.middleSection.form.general.addCta'),
          onAction: onGeneralExpenseClick,
        },
      ]}>
      {t('reimbursementView.middleSection.emptyDetails.createAction')}
    </MenuButton>
  );
};
