import { PurchaseOrderFieldItem } from 'components/PurchaseOrders/PurchaseOrderItem';
import { PurchaseOrderEdge } from 'generated-types/graphql.types';

/** Maps data to `PurchaseOrderFieldItem` */
export const toPurchaseOrderNumberFieldItem = (value: {
  node: Pick<
    PurchaseOrderEdge['node'],
    | '_id'
    | 'amount'
    | 'orderNumber'
    | 'postingDate'
    | 'goodsLineItems'
    | 'serviceLineItems'
  >;
}): PurchaseOrderFieldItem => {
  const {
    _id,
    amount,
    orderNumber,
    postingDate,
    goodsLineItems,
    serviceLineItems,
  } = value.node;

  return {
    key: _id,
    amount,
    orderNumber,
    postingDate,
    goodsLineItems,
    serviceLineItems,
  };
};
