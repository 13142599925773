import {
  EmailInboxMessage,
  EmailInboxPaginationQuery,
  EmailInboxPaginationQueryVariables,
  EmailInboxSortField,
  SortDirection,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { FilterBy, SearchParam } from '../MailSync';
import { emailInboxInfiniteScrollPagination } from '../queries';

interface MailSyncData {
  hasMore: boolean;
  isLoading: boolean;
  mailSyncData: EmailInboxMessage[];
  onEndReached: () => void;
}

const PAGE_LIMIT = 30;

export const useMailSyncData = (sortBy: EmailInboxSortField): MailSyncData => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const status = searchParams.get(SearchParam.Status);

  // TODO: add functionality to sort and search via the query
  const computeVariables = useCallback(
    (): EmailInboxPaginationQueryVariables => ({
      filters: {
        id: SearchParam.Status,
        name:
          !status || status === FilterBy.NoInvoice
            ? [FilterBy.All]
            : [status as string],
      },
      input: {
        limit: PAGE_LIMIT,
      },
      // ERROR: query only returns valid sorted response from EmailInboxSortField.Receivedat
      sortBy: EmailInboxSortField.Receivedat && {
        direction: SortDirection.Desc,
        field: sortBy,
      },
    }),
    [sortBy, status]
  );

  // remove emailInboxPaginationQuery when refactorEmailTableFF is archived
  const {
    data: mailSyncData,
    hasMore,
    loading: isLoading,
    loadMore: onEndReached,
  } = useDebouncedSearchPagination<
    EmailInboxPaginationQuery,
    EmailInboxPaginationQueryVariables
  >({
    query: emailInboxInfiniteScrollPagination,
    queryRootKey: 'emailInboxPagination',
    computeVariables,
  });

  return {
    hasMore,
    isLoading,
    mailSyncData:
      (mailSyncData?.emailInboxPagination?.records as EmailInboxMessage[]) ??
      [],
    onEndReached,
  };
};
