import { Table, Cell } from '@candisio/design-system';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import { ThreeWayMatchAmountCell } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchAmountCell';
import { ThreeWayMatchTableHeader } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchTableHeader';
import { ThreeWayMatchModalTab } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchTableWrapper';
import { matchStates } from '../consts';
import { ThreeWayMatchRowData } from '../types';
import { ThreeWayMatchQuantityCell } from './ThreeWayMatchQuantityCell';

export type ThreeWayMatchTableRow = ThreeWayMatchRowData;

export interface ThreeWayMatchTableProps {
  activeTab: ThreeWayMatchModalTab;
  searchString: string;
}
const quantityColumnIds = [
  'purchaseOrderQuantity',
  'goodsReceiptQuantity',
  'invoiceQuantity',
];

const amountColumnIds = ['purchaseOrderAmount', 'invoiceAmount'];
const { success, warning } = matchStates;

const getMatchingStyles = (isMatched: boolean) => ({
  backgroundColor: isMatched
    ? success.backgroundColor
    : warning.backgroundColor,
});

const getColumns = (
  activeTab: ThreeWayMatchModalTab
): Array<Column<ThreeWayMatchTableRow>> => {
  const defaultColumns: Column<ThreeWayMatchTableRow>[] = [
    { accessor: 'articleNumber', width: '20%' },
    { accessor: 'description', width: '30%' },
  ];

  const customColumns: {
    [key in ThreeWayMatchModalTab]: Column<ThreeWayMatchTableRow>[];
  } = {
    amount: amountColumnIds.map(
      id =>
        ({
          accessor: id,
          width: '24%',
          disableSortBy: true,
          Cell: ThreeWayMatchAmountCell,
        } as Column<ThreeWayMatchTableRow>)
    ),
    quantity: quantityColumnIds.map(
      id =>
        ({
          accessor: id,
          width: '16%',
          disableSortBy: true,
          Cell: ThreeWayMatchQuantityCell,
        } as Column<ThreeWayMatchTableRow>)
    ),
  };

  return [...defaultColumns, ...customColumns[activeTab]];
};

export function ThreeWayMatchTable({
  activeTab,
  searchString,
}: ThreeWayMatchTableProps) {
  const columns = useMemo(() => getColumns(activeTab), [activeTab]);
  const { newData: data } = useThreeWayMatchContext();

  const defaultColumn = useMemo(
    (): Partial<Column<ThreeWayMatchTableRow>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: ThreeWayMatchTableHeader,
      Cell,
      disableFilters: true,
    }),
    []
  );

  const filteredData = useMemo(() => {
    return searchString
      ? data.filter(
          row =>
            row.description
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            row.articleNumber.toLowerCase().includes(searchString.toLowerCase())
        )
      : data;
  }, [data, searchString]);

  return (
    <Table<ThreeWayMatchTableRow>
      height="100%"
      borderBottomRadius="medium"
      borderTopRadius="none"
      defaultColumn={defaultColumn}
      columns={columns}
      data={filteredData}
      getCellStyles={cellInfo => {
        const { id } = cellInfo.column;
        const { isQuantityMatched, isAmountMatched } = cellInfo.row.original;

        if (quantityColumnIds.includes(id)) {
          return getMatchingStyles(isQuantityMatched);
        }

        if (amountColumnIds.includes(id)) {
          return getMatchingStyles(isAmountMatched);
        }

        return {};
      }}
    />
  );
}
