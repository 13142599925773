import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ComponentProps } from 'react';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useGetColumnIds } from 'views/Integrations/Export/hooks/useGetColumnIds';
import { DocumentsTable } from 'views/Integrations/Export/toolkit/components/DocumentsTable';
import { TableCustomEmptyState } from 'views/Integrations/Export/toolkit/components/EmptyState/components/TableCustomEmptyState';
import { EmptyExportView } from 'views/Integrations/Export/toolkit/components/EmptyState/ExportEmptyState';
import {
  ExportTablesWithSepartors,
  ExportedDocumentsTable,
  groupExportDocuments,
  mapGroupToTables,
} from 'views/Integrations/Export/toolkit/components/ExportedDocumentsTable';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { ExportEntityRow, View } from 'views/Integrations/Export/types';
import {
  addTypeAndPaymentPeriod,
  toDocumentTableData,
  useSettlementRowClick,
} from './utils';

type SettlementsTableProps = {
  data: ExportEntityRow[];
};

export const SettlementsComponent = ({ data }: SettlementsTableProps) => {
  const [errorVisibilityImprovementFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.errorVisibilityImprovement,
  ]);

  const organization = useFullOrganization();
  const state = useExportContext();

  const { failedDocuments } = useExportDataGigaFactory(state);

  const handleRowClick = useSettlementRowClick();

  const tableData = data
    .map(addTypeAndPaymentPeriod(organization))
    .map(toDocumentTableData(failedDocuments));

  const tableId = state.tableId;

  const columnIds = useGetColumnIds<DocumentTableColumnKeys>({
    tableId,
  });

  if (!errorVisibilityImprovementFF || state.view !== View.HISTORY) {
    return (
      <DocumentsTable
        columns={columnIds}
        data={tableData}
        onRowClick={handleRowClick}
        customEmptyState={TableCustomEmptyState}
        key={`${tableId}-${columnIds.join('-')}`}
      />
    );
  }

  const {
    exportedDocuments,
    partiallyExportedDocuments,
    groupedFailedDocuments,
  } = groupExportDocuments(tableData);

  const Table = (props: ComponentProps<typeof ExportedDocumentsTable>) => (
    <ExportedDocumentsTable
      columns={columnIds}
      data={props.data}
      onRowClick={handleRowClick}
      variant={props.variant}
      code={props.code}
    />
  );

  const tables = [
    ...mapGroupToTables(groupedFailedDocuments, Table, 'error'),
    ...mapGroupToTables(partiallyExportedDocuments, Table, 'warning'),
    ...mapGroupToTables(exportedDocuments, Table, 'success'),
  ];

  return <ExportTablesWithSepartors tables={tables} />;
};

export const Settlements = () => {
  const state = useExportContext();

  const {
    exportRowEntities: {
      ready: { settlementEntities },
      notReady: { notReadySettlementEntitiesAsExportRows },
    },
    isLoading: { loadingCardSettlementsReady, loadingCardSettlementsNotReady },
  } = useExportDataGigaFactory(state);

  const isNotReadyView = state.view === View.NOT_READY;

  const data = isNotReadyView
    ? notReadySettlementEntitiesAsExportRows
    : settlementEntities;

  const isEmpty = data.length === 0;
  const isLoading =
    loadingCardSettlementsReady || loadingCardSettlementsNotReady;

  if (isLoading || isEmpty) {
    return <EmptyExportView isLoading={isLoading} />;
  }

  return <SettlementsComponent data={data} />;
};
