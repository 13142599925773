import {
  Box,
  Flex,
  useTheme,
  CustomEmptyStateProps,
  SelectionOptions,
} from '@candisio/design-system';
import { DocumentsTable } from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { AnimatePresence } from 'framer-motion';
import { useLinkDocumentAndTransactionsMutation } from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TransactionsTrackingEvents } from 'providers/AnalyticsProvider/events/TransactionsTrackingEvents';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { documentHistoryQuery } from 'views/queries';
import { getCardIssuerTransactionById } from '../gql';
import { AssociateBanner } from './AssociateBanner';
import { DocumentsWithoutTransactionEmptyState } from './DocumentsWithoutTransactionEmptyState';
import { useDocumentWithoutTransactionData } from './hooks/useDocumentWithoutTransactionData';

export interface DocumentsWithoutTransactionProps {
  transactionId: string;
  closeModal: () => void;
}

export const DocumentsWithoutTransaction = ({
  transactionId,
  closeModal,
}: DocumentsWithoutTransactionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const showError = useShowError();
  const { success, error } = useToastMessage();
  const { track } = useAnalytics();
  const { space } = useTheme();
  const counterQueries = useCounterQueries();
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentsTableData | null>(null);

  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<DocumentsTableData>();

  const {
    tableData,
    loadingDocuments,
    onLoadMore,
    hasMore,
    isTableEmpty,
    isTableFiltered,
    filterOptions,
  } = useDocumentWithoutTransactionData({
    filters,
    sortBy,
  });

  const [linkTransactionsWithDocument, { loading }] =
    useLinkDocumentAndTransactionsMutation({
      refetchQueries: [
        {
          query: getCardIssuerTransactionById,
          variables: { id: transactionId },
        },
        {
          query: documentHistoryQuery,
          variables: { id: selectedDocument?.id },
        },
        ...counterQueries,
      ],
    });

  const showTableLoadingSpinner = loadingDocuments && tableData.length < 1;

  const handleLinkTransactionWithDocument = async () => {
    try {
      if (!selectedDocument?.id) {
        return;
      }

      const result = await linkTransactionsWithDocument({
        variables: {
          documentId: selectedDocument?.id,
          transactionIds: [transactionId],
        },
      });

      if (result.data?.linkDocumentAndTransactions) {
        success(t('transactionAssociation.documentsModal.toastMessageSucces'));

        track(TransactionsTrackingEvents.ADD_TO_TRANSACTION_BTN_CLCIKED, {
          transactionId,
          docId: selectedDocument?.id,
        });
      } else {
        error('There was an error');
      }
    } catch (error) {
      showError(error as GQLError);

      return validationErrorsExtractor(error);
    }

    closeModal();
  };

  const selectionOption: SelectionOptions<DocumentsTableData> = {
    onSelectionRowChanged: documents => {
      setSelectedDocument(documents[0]);
    },
    selectedRowsIds: selectedDocument ? [selectedDocument.id] : [],
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <DocumentsWithoutTransactionEmptyState
        isTableFiltered={isTableFiltered}
        isDocumentSelected={!!selectedDocument}
        isTableEmpty={isTableEmpty}
        resetFilters={resetFilters}
      />
    ) : null;

  const columnWidths: ColumnWidths = {
    contact: 'auto',
    fileName: space.space256,
  };

  const showLoadingMoreSpinner = tableData.length > 1;

  const infiniteScrollOptions = {
    dataLength: tableData?.length,
    hasMore: hasMore,
    showLoadingMoreSpinner: !showTableLoadingSpinner && showLoadingMoreSpinner,
    next: onLoadMore,
  };

  const selectedDocumentStatus = selectedDocument?.status?.status;

  return (
    <Box height="100%" overflow="hidden">
      <Flex direction="column" height="100%" padding="space16 space32 space32">
        <DocumentsTable
          columns={[
            'status',
            'contact',
            'grossAmount',
            'invoiceNumber',
            'invoiceDate',
            'fileName',
          ]}
          columnWidths={columnWidths}
          customEmptyState={emptyState}
          data={tableData}
          filterOptions={filterOptions}
          infiniteScrollOptions={infiniteScrollOptions}
          isLoading={showTableLoadingSpinner}
          isSingleSelect
          onEndReached={onLoadMore}
          onFilter={onFilter}
          onSort={onSort}
          selectionOptions={selectionOption}
        />
      </Flex>
      <AnimatePresence>
        {selectedDocument && (
          <AssociateBanner
            selectedDocumentStatus={selectedDocumentStatus}
            isAssociating={loading}
            onAssociate={handleLinkTransactionWithDocument}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};
