import {
  InAppNotificationSortField,
  InAppNotificationsPageBasedQuery,
  InAppNotificationsPageBasedQueryVariables,
  SortDirection,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { getNotificationTypes } from '../getNotificationInfo';
import { getInAppNotificationsPageBasedQuery } from '../gql';

interface UseInAppNotificationsPageBasedDataProps {
  limit?: number;
  showOnlyUnread?: boolean;
  organizationId?: string;
}

export const useInAppNotificationsPageBasedData = ({
  limit,

  showOnlyUnread,
  organizationId,
}: UseInAppNotificationsPageBasedDataProps) => {
  const inAppNotificationsFF = useCandisFeatureFlags([
    FEATURE_FLAGS.inAppNotifications,
  ]);

  const notificationTypes = getNotificationTypes();

  const { data, loading, onLoadMore } = usePagePagination<
    InAppNotificationsPageBasedQuery,
    InAppNotificationsPageBasedQueryVariables
  >(getInAppNotificationsPageBasedQuery, 'inAppNotifications', {
    variables: {
      input: {
        limit: limit ?? undefined,
      },
      filters: {
        ...(showOnlyUnread ? { isRead: false } : undefined),
        organizationIds: organizationId ? [organizationId] : undefined,
        types: notificationTypes,
      },
      sortBy: {
        direction: SortDirection.Desc,
        field: InAppNotificationSortField.CreatedAt,
      },
    },
    skip: !inAppNotificationsFF,
  });

  const totalCount = data?.inAppNotifications?.pageInfo?.totalCount ?? 0;

  const hasMore =
    (data?.inAppNotifications?.pageInfo.pageCount ?? 0) >
    (data?.inAppNotifications?.pageInfo.currentPage ?? 0);

  const notifications = data?.inAppNotifications.records ?? [];

  const unreadNotifications = notifications.filter(({ isRead }) => !isRead);

  const notificationDetails = {
    notifications,
    unreadNotifications,
    totalCount,
  };

  return { loading, notificationDetails, onLoadMore, hasMore };
};
