import { ErrorMessages } from 'utils/zodFormValidation';
import { ZodIssueCode, z } from 'zod';
import { phoneNumberPattern } from './utils';

const formSchema = z.object({
  acceptTerms: z.boolean(),
  companyName: z.string().nonempty(),
  annualRevenue: z.number().nullable(),
  zipCode: z.string().nonempty(),
  city: z.string().nonempty(),
  employeeCount: z.string().nonempty(),
  foundingYear: z.string().nonempty(),
  address: z.string().nonempty(),
  country: z.string().nonempty(),
  authorizedRepresentativeFirstName: z.string().nonempty(),
  authorizedRepresentativeLastName: z.string().nonempty(),
  authorizedRepresentativeEmail: z.string().email().nonempty(),
  monthlyCardLimit: z.number().nullable(),
  paymentFrequency: z.string().nonempty(),
  contactFirstName: z.string().nonempty(),
  contactLastName: z.string().nonempty(),
  contactPhone: z.string().nonempty(),
  contactEmail: z.string(),
});

export const getCredCardApplicationFormSchema = () => {
  return formSchema.superRefine(
    (
      {
        acceptTerms,
        annualRevenue,
        zipCode,
        employeeCount,
        foundingYear,
        monthlyCardLimit,
        contactPhone,
      },
      ctx
    ) => {
      const isNumber = (value: string) => /^\d+$/.test(value);
      const isValidPhoneNumber = (value: string) =>
        phoneNumberPattern.test(value);

      if (acceptTerms === false) {
        ctx.addIssue({
          path: ['acceptTerms'],
          code: ZodIssueCode.custom,
          params: {
            translationKey: 'modal.form.errors.acceptedTerms',
          },
        });
      }

      if (annualRevenue === null) {
        ctx.addIssue({
          path: ['annualRevenue'],
          code: ZodIssueCode.custom,
        });
      }

      if (zipCode === null) {
        ctx.addIssue({
          path: ['zipCode'],
          code: ZodIssueCode.custom,
        });
      }

      if (zipCode && !isNumber(zipCode)) {
        ctx.addIssue({
          path: ['zipCode'],
          code: ZodIssueCode.custom,
          params: {
            translationKey: 'modal.form.errors.invalidNumber',
          },
        });
      }

      if (employeeCount === null) {
        ctx.addIssue({
          path: ['employeeCount'],
          code: ZodIssueCode.custom,
        });
      }

      if (employeeCount && !isNumber(employeeCount)) {
        ctx.addIssue({
          path: ['employeeCount'],
          code: ZodIssueCode.custom,
          params: {
            translationKey: 'modal.form.errors.invalidNumber',
          },
        });
      }

      if (foundingYear === null) {
        ctx.addIssue({
          path: ['foundingYear'],
          code: ZodIssueCode.custom,
        });
      }

      if (foundingYear && !isNumber(foundingYear)) {
        ctx.addIssue({
          path: ['foundingYear'],
          code: ZodIssueCode.custom,
          params: {
            translationKey: 'modal.form.errors.invalidNumber',
          },
        });
      }

      if (monthlyCardLimit === null) {
        ctx.addIssue({
          path: ['monthlyCardLimit'],
          code: ZodIssueCode.custom,
        });
      }

      if (contactPhone === null) {
        ctx.addIssue({
          path: ['contactPhone'],
          code: ZodIssueCode.custom,
        });
      }

      if (contactPhone && !isValidPhoneNumber(contactPhone)) {
        ctx.addIssue({
          path: ['contactPhone'],
          code: ZodIssueCode.custom,
          params: {
            translationKey: 'modal.form.errors.invalidNumber',
          },
        });
      }
    }
  );
};

export const credCardApplicationFormSchema = () =>
  formSchema.and(getCredCardApplicationFormSchema());

type ExcludedKeys = 'paymentFrequency' | 'contactEmail';

export const getCredCardApplicationFormErrorMessages = (): ErrorMessages<
  ReturnType<typeof credCardApplicationFormSchema>,
  ExcludedKeys
> => {
  return {
    companyName: {
      label: 'modal.form.companyInfoForm.companyName.label',
    },
    annualRevenue: {
      label: 'modal.form.companyInfoForm.annualRevenue.label',
    },
    zipCode: {
      label: 'modal.form.companyInfoForm.zipCode.label',
    },
    city: {
      label: 'modal.form.companyInfoForm.city.label',
    },
    employeeCount: {
      label: 'modal.form.companyInfoForm.employeeCount.label',
    },
    foundingYear: {
      label: 'modal.form.companyInfoForm.foundingYear.label',
    },
    address: {
      label: 'modal.form.companyInfoForm.address.label',
    },
    country: {
      label: 'modal.form.companyInfoForm.country.label',
    },
    authorizedRepresentativeFirstName: {
      label:
        'modal.form.companyInfoForm.authorizedRepresentativeFirstName.label',
    },
    authorizedRepresentativeLastName: {
      label:
        'modal.form.companyInfoForm.authorizedRepresentativeLastName.label',
    },
    authorizedRepresentativeEmail: {
      label: 'modal.form.companyInfoForm.authorizedRepresentativeEmail.label',
    },
    monthlyCardLimit: {
      label: 'modal.form.companyInfoForm.monthlyCardLimit.label',
    },
    contactFirstName: {
      label: 'modal.form.requesterInfoForm.contactFirstName.label',
    },
    contactLastName: {
      label: 'modal.form.requesterInfoForm.contactFirstName.label',
    },
    contactPhone: {
      label: 'modal.form.requesterInfoForm.phoneNumber.label',
    },
    acceptTerms: {
      label: '',
    },
  };
};

export type CredCardApplicationFormValues = z.infer<typeof formSchema>;
