import { Link, LinkProps, Flex, Box } from '@candisio/design-system';
import {
  IndicatorStatus,
  PulseIndicator,
} from 'components/PulseIndicator/PulseIndicator';
import { createClickHandler } from 'components/RouterLink/createClickHandler';
import { forwardRef, Ref } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';

export interface NavLinkProps
  extends Pick<
      RouterNavLinkProps,
      'exact' | 'isActive' | 'location' | 'replace' | 'strict' | 'to'
    >,
    Omit<LinkProps, 'href' | 'ref'> {
  showIndicator?: boolean;
  requiresUpgrade?: boolean;
  source?: string;
  indicatorStatus?: IndicatorStatus;
}

/**
 * React Router NavLink that uses the Link component from the Candis design
 * system
 * - Styled to indicate when linked page is the current page
 * - Supports all design system Link props except `href` (use `to` instead)
 * - Supports a subset of the React Router NavLink props: `exact`, 'isActive'
 *   'location', 'replace', 'strict' and 'to'
 *
 * @example <NavLink to="/the-past">A (Nav)Link to the Past</Link>
 */
export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (
    {
      showIndicator,
      indicatorStatus,
      source,
      children,
      ...restProps
    }: NavLinkProps,
    ref
  ) => {
    return (
      <Flex alignItems="center" gap="space2">
        <RouterNavLink component={NavLinkAdaptor} innerRef={ref} {...restProps}>
          {children}
        </RouterNavLink>
        {showIndicator && (
          <Box bottom={0.5}>
            <PulseIndicator status={indicatorStatus} source={source} />
          </Box>
        )}
      </Flex>
    );
  }
);

interface NavLinkAdaptorProps extends LinkProps {
  innerRef: Ref<HTMLAnchorElement>;
  navigate: () => void;
  requiresUpgrade?: boolean;
}

const NavLinkAdaptor = ({
  'aria-current': ariaCurrent,
  children,
  fontSize = 'small',
  innerRef,
  navigate,
  onClick,
  target,
  style,
  requiresUpgrade,
  ...restProps
}: NavLinkAdaptorProps) => {
  const selectedNavigationColor = ariaCurrent
    ? requiresUpgrade
      ? 'purple'
      : 'blue'
    : 'gray';

  return (
    <Link
      aria-current={ariaCurrent}
      fontSize={fontSize}
      onClick={createClickHandler({ target, onClick, navigate })}
      target={target}
      color={selectedNavigationColor}
      {...restProps}
      ref={innerRef}>
      {children}
    </Link>
  );
};
