import { useTranslation } from 'react-i18next';
import contactsIcon from '../../images/contact-icon.svg';
import { MenuItemIcon } from '../../style';
export const Contacts = () => {
  const [t] = useTranslation();

  return (
    <MenuItemIcon>
      <img src={contactsIcon} alt={t('navigation.contacts')} />
    </MenuItemIcon>
  );
};
