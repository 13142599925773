import {
  Card,
  Flex,
  Grid,
  GridProps,
  Heading,
  Icon,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { defaultRolesTranslations } from 'views/Settings/Roles/toolkit/utils/defaultRolesTranslations';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
} from '../../teamMemberFormSchema';

interface RolesSectionProps {
  onShowRolesStep: () => void;
  readOnly?: boolean;
}

export const TEST_ID_ROLE_SECTION = 'roles-section';

export const RolesSection = ({
  onShowRolesStep,
  readOnly,
}: RolesSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { getFieldState, formState, getValues } = useFormContext<
    TeamMemberFormOutput,
    TeamMemberFormSchemaOptions
  >();

  const rolesValue = getValues('roles') ?? [];

  // Subscribe to errors for roles
  const { error } = getFieldState('roles', formState);

  const roleNames = rolesValue.map(role => role.name);

  const sortedRoleNames = roleNames.sort((a, b) => a.localeCompare(b));

  const readableRoles = sortedRoleNames.map(role =>
    defaultRolesTranslations[role] ? t(defaultRolesTranslations[role]) : role
  );

  const rolesGridProps: GridProps<'div'> = readOnly
    ? {
        style: { cursor: 'not-allowed' },
      }
    : {
        onClick: onShowRolesStep,
        style: { cursor: 'pointer' },
      };

  const isNotLastRole = (index: number) => index !== readableRoles.length - 1;

  const text =
    rolesValue.length > 0 ? (
      <Flex gap="space8">
        {readableRoles.map((role, i) => (
          <Flex key={role} gap="space8" alignItems="center">
            <TruncatedText key={role}>{role}</TruncatedText>
            {isNotLastRole(i) && <div>•</div>}
          </Flex>
        ))}
      </Flex>
    ) : (
      <Text>{t('common:settings.teamMembers.form.selectRoles')}</Text>
    );

  return (
    <Grid gap="space8">
      <Heading as="h3">{t('common:settings.teamMembers.form.roles')}</Heading>
      <Card background="gray100" data-testid={TEST_ID_ROLE_SECTION}>
        <Grid
          gap="space4"
          borderLeft={error ? '4px solid red500' : undefined}
          paddingLeft="space8">
          {error ? <Text color="red500">{error.message}</Text> : null}
          <Grid
            templateColumns="1fr auto"
            alignItems="center"
            gap="space4"
            fontSize="basic"
            {...rolesGridProps}>
            {text}
            <Icon icon="arrowRight" />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
