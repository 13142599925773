import { Grid, Item, Link, TruncatedText } from '@candisio/design-system';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { ErrorIcon, WarningIcon } from 'components/Icons/DefaultIcons';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { nocontactData } from 'components/Lottie/Lottie';
import {
  EmailInboxMessageAttachment,
  EmailInboxMessageAttachmentWithError,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { AttachmentErrorTooltipContent } from './AttachmentErrorTooltipContent';

const AttachmentWithError = ({
  record,
}: {
  record: EmailInboxMessageAttachmentWithError;
}) => {
  const [t] = useTranslation();
  switch (record.error.__typename) {
    case 'MaxAttachmentSizeExceeded':
      return (
        <TruncatedText>
          {t(
            'settings.mailSync.details.tabs.metadata.attachments.errors.maxSizeExceeded'
          )}
        </TruncatedText>
      );
    case 'AttachmentDocumentRemovedError':
      const {
        error: { removedAt, removedBy },
      } = record;

      if (removedAt && removedBy) {
        const removedByFormatted = DateFormatters.compact(new Date(removedAt));

        return (
          <TruncatedText>
            {t(
              'settings.mailSync.details.tabs.metadata.attachments.errors.documentRemovedWithInfo',
              { removedAt: removedByFormatted, removedBy }
            )}
          </TruncatedText>
        );
      }

      return (
        <TruncatedText>
          {t(
            'settings.mailSync.details.tabs.metadata.attachments.errors.documentRemoved'
          )}
        </TruncatedText>
      );
    case 'AttachmentDocumentNotFoundError':
      return (
        <TruncatedText>
          {t(
            'settings.mailSync.details.tabs.metadata.attachments.errors.documentNotFound'
          )}
        </TruncatedText>
      );
    case 'EmailMessageVirusDetected':
      return (
        <TruncatedText>
          {t(
            'settings.mailSync.details.tabs.metadata.attachments.errors.virusDetected'
          )}
        </TruncatedText>
      );
    case 'UnprocessableAttachment':
      return (
        <TruncatedText>
          {t(
            'settings.mailSync.details.tabs.metadata.attachments.errors.unprocessable'
          )}
        </TruncatedText>
      );
    case 'UnsupportedAttachmentType':
      return (
        <TruncatedText>
          {t(
            'settings.mailSync.details.tabs.metadata.attachments.errors.unsupported'
          )}
        </TruncatedText>
      );
    default:
      return null;
  }
};

const isErrorRecord = (
  record: EmailInboxMessageAttachment
): record is EmailInboxMessageAttachmentWithError => {
  return record.__typename === 'EmailInboxMessageAttachmentWithError';
};

const FileName = ({ record }: { record: EmailInboxMessageAttachment }) => {
  const [t] = useTranslation();

  if (
    isErrorRecord(record) &&
    record.error.__typename !== 'UnprocessableAttachment' &&
    record.error.__typename !== 'MaxAttachmentSizeExceeded'
  ) {
    return (
      <Link fontSize="basic" disabled>
        {record.fileName ? (
          <TruncatedText>{record.fileName}</TruncatedText>
        ) : (
          t(
            'settings.mailSync.details.tabs.metadata.attachments.unknownFileName'
          )
        )}
      </Link>
    );
  }

  const href = isErrorRecord(record) ? record.fileUrl : record.url;
  const name = isErrorRecord(record)
    ? record.fileName
    : record.documentFileName;

  return (
    <Link fontSize="basic" download href={href ?? undefined}>
      {!isNil(name) && <TruncatedText>{name}</TruncatedText>}
    </Link>
  );
};

const RenderLink = ({ record }: { record: EmailInboxMessageAttachment }) => {
  const organizationSlug = useOrganizationId();
  const [t] = useTranslation();

  if (isErrorRecord(record)) {
    return (
      <Grid
        whiteSpace="normal"
        autoFlow="column"
        placeContent="start"
        alignItems="center"
        gap="space8"
        fontSize="small"
        lineHeight="0.8125rem"
        color={
          record.error.__typename === 'UnsupportedAttachmentType'
            ? 'yellow500'
            : 'red500'
        }>
        {record.error.__typename === 'UnsupportedAttachmentType' ? (
          <WarningIcon top="space2" size="space20" minWidth="space12" />
        ) : (
          <ErrorIcon top="space2" size="space20" minWidth="space12" />
        )}
        {record.error.__typename === 'AttachmentDocumentRemovedError' ? (
          <TruncatedText>
            {t(
              'settings.mailSync.details.tabs.metadata.attachments.errors.documentRemoved'
            )}
          </TruncatedText>
        ) : (
          <AttachmentWithError record={record} />
        )}

        <InfoIcon
          size="space20"
          message={
            record.error.__typename === 'AttachmentDocumentRemovedError' ? (
              <Grid>
                <AttachmentWithError record={record} />
                <Link
                  external
                  href={t(
                    'settings.mailSync.details.tabs.metadata.attachments.errorInfo.documentRemoved.helpCenterUrl'
                  )}>
                  {t(
                    'settings.mailSync.details.tabs.metadata.attachments.errorInfo.moreInfo'
                  )}
                </Link>
              </Grid>
            ) : (
              <AttachmentErrorTooltipContent err={record.error} />
            )
          }
        />
      </Grid>
    );
  }

  return (
    <Link
      external
      fontSize="basic"
      href={`/${organizationSlug}${Routes.ARCHIVE}/${record.documentId}`}>
      {t('settings.mailSync.details.tabs.metadata.attachments.documentLink')}
    </Link>
  );
};

interface AttachmentsListProps {
  attachments: EmailInboxMessageAttachment[];
  isLoading: boolean;
}

export const AttachmentsList = ({
  attachments,
  isLoading,
}: AttachmentsListProps) => (
  <Grid height="20rem">
    <FilterableList
      children={attachments.map(
        (attachment: EmailInboxMessageAttachment, index: number) => {
          return (
            <Item key={index}>
              <Grid
                gap="space32"
                paddingY="space8"
                paddingRight="space16"
                borderBottom="1px solid gray200"
                templateColumns="1fr auto">
                <FileName record={attachment} />
                <RenderLink record={attachment} />
              </Grid>
            </Item>
          );
        }
      )}
      emptyDataState={
        <EmptyDataState
          lottieAnimation={nocontactData}
          translation="settings.mailSync.details.tabs.metadata.attachments.noData"
        />
      }
      isLoading={isLoading}
    />
  </Grid>
);
