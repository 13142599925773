import { Button, Grid } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface PendingTeamMemberFormFooterProps {
  formId: string;
  loading?: boolean;
  disabled?: boolean;
}

export const PendingTeamMemberFormFooter = ({
  formId,
  disabled,
  loading,
}: PendingTeamMemberFormFooterProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      autoFlow="column"
      justifyContent="space-between"
      data-cy="team-member-invite-action">
      <Button type="submit" form={formId} loading={loading} disabled={disabled}>
        {t('settings.teamMembers.form.actions.update')}
      </Button>
    </Grid>
  );
};
