import { Box, Modal, defaultTheme, useModal } from '@candisio/design-system';
import { Loader } from 'components/Loader';
import promoPlayIconAlt from 'containers/Entitlements/toolkit/assets/play-icon-promo-alt.svg';
import promoPlayIcon from 'containers/Entitlements/toolkit/assets/play-icon-promo.svg';
import playIcon from 'containers/Entitlements/toolkit/assets/play-icon.svg';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

const StyledLink = styled.a<{ ['data-alternative']?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0.5rem;
  border-radius: 50%;

  & {
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
  }

  &:hover,
  &:focus {
    background-color: ${({ 'data-alternative': alternative }) =>
      alternative ? defaultTheme.colors.graybg : defaultTheme.colors.purple200};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${defaultTheme.colors.blue400};
  }

  & img {
    transition: filter 0.3s ease-in-out;
    filter: brightness(100%);
  }

  &:hover img,
  &:focus img {
    filter: brightness(90%);
  }
`;

type PlayButtonProps = {
  href: string;
  navatticId: string;
  title: string;
  promo?: boolean;
  alternative?: boolean;
};

export const PlayButton = memo(
  ({
    href,
    title,
    navatticId,
    promo = false,
    alternative = false,
  }: PlayButtonProps) => {
    const [t] = useTranslation();
    const { open: openModal, modalProps } = useModal();

    let src = playIcon;
    if (promo) src = promoPlayIcon;
    if (promo && alternative) src = promoPlayIconAlt;

    return (
      <>
        <StyledLink
          data-alternative={alternative || !promo}
          onClick={openModal}>
          <img src={src} alt="" />
        </StyledLink>
        <Modal
          closeLabel={t('common.close')}
          width="max-content"
          title={title}
          {...modalProps}>
          <Box
            width="70vw"
            height="70vh"
            maxWidth="70rem"
            maxHeight="40rem"
            borderRadius="medium"
            overflow="hidden">
            <Box
              position="absolute"
              top="45%"
              left="50%"
              style={{ transform: 'translate(-50%, -50%)' }}>
              <Loader />
            </Box>
            <Box
              as="iframe"
              id="navattic-embed"
              src={href}
              title={navatticId}
              width="100%"
              height="100%"
              border="none"
              allow="fullscreen"
            />
          </Box>
        </Modal>
      </>
    );
  }
);
