export const durationInSeconds = {
  fadeView: 0.4,
  slideIn: 0.5,
  fadeDashboard: 0.3,
};

export const totalDurationInSeconds = Object.values(durationInSeconds).reduce(
  (acc, el) => acc + el,
  0
);

export const fadeView = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      duration: durationInSeconds.fadeView,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
};

export const slide = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: durationInSeconds.slideIn, delay: 0.4 },
  },
  hidden: { opacity: 0, x: 100, transition: { duration: 0.5 } },
};

export const fade = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
  visible: {
    opacity: 1,
    transition: { duration: durationInSeconds.fadeDashboard },
  },
};
