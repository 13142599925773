import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { useExportContext } from '../../Context';
import { useGetColumnsConfigurations } from '../../hooks/useGetColumnsConfigurations';

export const ConfigurationsMenuContainer = () => {
  const state = useExportContext();
  const { table, tableId } = state;

  const {
    configurationsTable,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    isSavingConfigurations,
    isResetPending,
  } = useGetColumnsConfigurations({
    tableType: table,
    tableId,
  });

  return (
    <ConfigurationsMenu
      configurationItems={configurationsTable}
      onUpdateConfigurations={handleUpdateConfigurations}
      onResetConfigurations={handleResetTableConfigurations}
      isLoading={isSavingConfigurations || isResetPending}
    />
  );
};
