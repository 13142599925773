import {
  Flex,
  useTheme,
  CustomEmptyStateProps,
  Grid,
} from '@candisio/design-system';
import { DocumentsTable } from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { SearchField } from 'components/SearchField/SearchField';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { Maybe } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { isNil } from 'lodash';
import { Routes } from 'models';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory, useParams } from 'react-router-dom';
import { appendParamsToQueryString } from 'utils/url-helper';
import { ApprovalsEmptyStateMobilePromo } from 'views/Approvals/ApprovalsEmptyStateMobilePromo';
import { useShouldShowMobileAppPromotion } from 'views/Approvals/hooks/useShouldShowMobileAppPromotion';
import { availableFilters } from 'views/Archive/ArchiveDocumentsTable/consts';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { ApprovalEmptyState } from './ApprovalEmptyState';
import { useApprovalDocumentsData } from './hooks/useApprovalDocumentsData';
import { useGetApprovalDocumentsTableConfigurations } from './hooks/useGetApprovalDocumentsTableConfigurations';

const useSearchParamAllDone = (totalCount: Maybe<number> | undefined) => {
  const history = useHistory();
  const { filters: gqlFilters } = useUrlBasedSortAndFilter({
    availableFilters,
  });

  useEffect(() => {
    const isAllDone = qs.parse(window.location.search).allDone;
    const hasActiveFilter = Object.values(gqlFilters).some(v => !isNil(v));

    if (totalCount === 0 && !hasActiveFilter && !isAllDone) {
      history.push({
        search: qs.stringify({ allDone: true }),
      });
    }
  }, [totalCount, history, gqlFilters]);
};

export const ApprovalTable = () => {
  const [t] = useTranslation();
  const { space } = useTheme();
  const history = useHistory();
  const params = useParams<{ organizationSlug: string }>();
  const { organizationSlug } = params;
  const { search } = history.location;

  const { searchParams, updateSearchParam } = useMutateSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? '';

  const {
    approvalsTableData,
    filters,
    filterOptions,
    filtersLoading,
    hasMoreData,
    isTableEmpty,
    isTableFiltered,
    loading,
    onFilter,
    onLoadMore,
    onSort,
    result,
    showLoadingMoreSpinner,
    sortBy,
    handleDebounceSearch,
  } = useApprovalDocumentsData();

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isLoadingConfigs,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetApprovalDocumentsTableConfigurations({ filters, sortBy });

  const totalCount = result.data?.listApprovalsDocuments.pageInfo?.totalCount;

  const { shouldShowMobileAppPromotion } = useShouldShowMobileAppPromotion();

  useSearchParamAllDone(totalCount);

  const columnWidths: ColumnWidths = {
    contact: space.space256,
    grossAmount: space.space128,
    invoiceNumber: space.space200,
    invoiceDate: space.space200,
    requester: space.space128,
    approvers: space.space128,
    requestedAt: space.space200,
    discountDateWPercentage: space.space200,
    iban: space.space200,
    paymentInfo: space.space200,
  };

  const infiniteScrollOptions = {
    dataLength: approvalsTableData?.length,
    hasMore: hasMoreData,
    showLoadingMoreSpinner: !loading && showLoadingMoreSpinner,
    next: onLoadMore,
  };

  const onClick = useCallback(
    ({ id, cursor }: DocumentsTableData) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? null,
      });

      const pathForOneApprovalDocumentWithCursor = `/${organizationSlug}${Routes.APPROVALS}/${id}?${searchWithCursor}`;
      history.push(pathForOneApprovalDocumentWithCursor);
    },
    [history, organizationSlug, search]
  );

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return shouldShowMobileAppPromotion ? (
      <ApprovalsEmptyStateMobilePromo />
    ) : (
      <ApprovalEmptyState
        isTableEmpty={isTableEmpty}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const tableKey = `table-filters-${filtersLoading}-${availableDocumentColumnIds.join(
    '-'
  )}`;

  return (
    <Flex height="100%" overflow="hidden" direction="column" key={tableKey}>
      <Grid
        templateColumns="24rem 1fr"
        borderTopRadius="basic"
        overflow="hidden"
        background="white"
        borderBottom="1px solid gray200"
        padding="space12">
        <SearchField
          clearTooltip={t('archive:search.clearTooltip')}
          placeholder={t('archive:search.placeholder')}
          value={searchQuery}
          onChange={query => {
            handleDebounceSearch(query);
            updateSearchParam(queryParameter, query);
          }}
          inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
        />
        <Grid justifySelf="end">
          <Flex gap="space8" alignItems="center">
            <ConfigurationsMenu
              configurationItems={configurationsTable}
              isLoading={isLoadingConfigs}
              onUpdateConfigurations={handleUpdateConfigurations}
              onResetConfigurations={handleResetTableConfigurations}
            />
          </Flex>
        </Grid>
      </Grid>
      <DocumentsTable
        columns={availableDocumentColumnIds}
        columnWidths={columnWidths}
        customEmptyState={customEmptyState}
        data={approvalsTableData}
        defaultFilters={filters}
        defaultSortBy={sortBy}
        filterOptions={filterOptions}
        isUsingSearchQuery={!!searchQuery}
        infiniteScrollOptions={infiniteScrollOptions}
        isLoading={loading}
        minWidth="100%"
        onEndReached={onLoadMore}
        onFilter={onFilter}
        onRowClick={onClick}
        onSort={onSort}
        borderTopRadius="none"
        overscan={100}
        width="max-content"
      />
    </Flex>
  );
};
