import { Button, Flex } from '@candisio/design-system';

interface CaretNavigatorsProps {
  isLoading: boolean | undefined;
  arrowLeftTooltip: string;
  arrowRightTooltip: string;
  onPrev: (() => void) | undefined;
  onNext: (() => void) | undefined;
}

export const CaretNavigators = ({
  isLoading,
  arrowLeftTooltip,
  arrowRightTooltip,
  onNext,
  onPrev,
}: CaretNavigatorsProps) => {
  const shouldHideNavigators = !onNext && !onPrev;
  if (isLoading) {
    return (
      <Flex>
        <Button
          disabled={true}
          icon="arrowLeft"
          size="small"
          variant="tertiary"
        />
        <Button
          disabled={true}
          icon="arrowRight"
          size="small"
          variant="tertiary"
        />
      </Flex>
    );
  }

  if (shouldHideNavigators) {
    return null;
  }

  return (
    <Flex>
      <Button
        disabled={!onPrev}
        icon="arrowLeft"
        label={arrowLeftTooltip}
        onClick={onPrev}
        size="small"
        variant="tertiary"
      />
      <Button
        disabled={!onNext}
        icon="arrowRight"
        label={arrowRightTooltip}
        onClick={onNext}
        size="small"
        variant="tertiary"
      />
    </Flex>
  );
};
