import { Card, Grid, Separator, Text } from '@candisio/design-system';
import { TransactionDetails } from 'components/Transactions/TransactionDetailsCard/TransactionDetails';
import { Transaction } from 'components/Transactions/TransactionDetailsCard/utils';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface TransactionsContentProps {
  linkedCardTransactions: (Transaction | undefined)[];
  replacedCardTransactions: (Transaction | undefined)[];
}

export const TransactionsContent = ({
  linkedCardTransactions,
  replacedCardTransactions,
}: TransactionsContentProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const hasTransactionsFromAllCards = Boolean(
    linkedCardTransactions?.length && replacedCardTransactions?.length
  );

  const replacedCardRefNum =
    replacedCardTransactions?.[0]?.cardRefNum ?? undefined;

  return (
    <Grid>
      <Grid gap="space10">
        {linkedCardTransactions?.map((t, i) => (
          <Card key={i} background="gray100" borderRadius="medium">
            <TransactionDetails transaction={t} />
          </Card>
        ))}
      </Grid>
      {replacedCardTransactions && (
        <Grid>
          {hasTransactionsFromAllCards && (
            <Grid
              autoFlow="column"
              gap="space10"
              placeItems="center"
              paddingY="space16"
              templateColumns="1fr auto 1fr">
              <Separator orientation="horizontal" color="gray300" />
              <Text color="gray600" fontWeight="semibold">
                {t('recurringPaymentsModal.linkCard.card', {
                  replacedCardRefNum,
                })}
              </Text>
              <Separator orientation="horizontal" color="gray300" />
            </Grid>
          )}
          <Grid gap="space10">
            {replacedCardTransactions?.map((t, i) => (
              <Card key={i} background="gray100" borderRadius="medium">
                <TransactionDetails transaction={t} />
              </Card>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
