import {
  CustomEmptyStateProps,
  Flex,
  Separator,
} from '@candisio/design-system';
import {
  DocumentsTable,
  DocumentsTableProps,
} from 'components/DocumentsTable/DocumentsTable';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { ExportStatus } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { ComponentProps, Fragment, ReactNode } from 'react';
import {
  useDefaultOnRowClick,
  useExportTableFilters,
} from 'views/Integrations/Export/elements/table/utils';
import { useExportTableMaxHeight } from '../hooks/useExportTableMaxHeight';
import { defaultExportFilters as availableFilters } from './DocumentsTable';
import { ExportTableCustomEmptyState } from './EmptyState/components/TableCustomEmptyState';
import {
  Code,
  ErrorCode,
  ExportTableHeading,
  ExportTableHeadingProps,
  Variant,
  WarningCode,
  isErrorCode,
  isWarningCode,
} from './ExportTableHeading';

export type GroupedDocumentTableData = Partial<{
  [code in Code]: DocumentsTableData[];
}>;

export const groupExportDocuments = (tableData: DocumentsTableData[]) => {
  const failedDocuments: GroupedDocumentTableData = {};
  const partiallyExportedDocuments: GroupedDocumentTableData = {};
  const exportedDocuments: GroupedDocumentTableData = { success: [] };

  for (const entry of tableData) {
    const { status, exportError } = entry.exportStatus ?? {};
    if (!status) continue;

    if (status === ExportStatus.Exported) {
      exportedDocuments.success?.push(entry);
      continue;
    }

    if (status === ExportStatus.PartialyExported) {
      const code: WarningCode = isWarningCode(exportError?.errorCode)
        ? exportError?.errorCode
        : 'UNKNOWN';

      if (!partiallyExportedDocuments[code]) {
        partiallyExportedDocuments[code] = [entry];
        continue;
      }

      partiallyExportedDocuments[code]?.push(entry);
      continue;
    }

    if (status === ExportStatus.Failed) {
      const code: ErrorCode = isErrorCode(exportError?.errorCode)
        ? exportError?.errorCode
        : 'UNKNOWN';

      if (!failedDocuments[code]) {
        failedDocuments[code] = [entry];
        continue;
      }

      failedDocuments[code]?.push(entry);
    }
  }

  return {
    groupedFailedDocuments: failedDocuments,
    partiallyExportedDocuments,
    exportedDocuments,
  };
};

export const mapGroupToTables = (
  groupedExportedDocuments: GroupedDocumentTableData,
  Table: (props: ExportedDocumentTableProps) => JSX.Element,
  variant: Variant
) =>
  Object.entries(groupedExportedDocuments).map(([code, tableData]) => ({
    id: code,
    content: <Table data={tableData} code={code as Code} variant={variant} />,
  }));

export const ExportTablesWithSepartors = ({
  tables,
}: {
  tables: { id: string; content: ReactNode }[];
}) => (
  <Flex rowGap="space32" flexGrow="1" direction="column">
    {tables.map((table, i) => (
      <Fragment key={table.id}>
        {i > 0 && <Separator />}
        {table.content}
      </Fragment>
    ))}
  </Flex>
);

type ExportedDocumentTableProps = DocumentsTableProps &
  Omit<ExportTableHeadingProps, 'count'>;

export type HeadingProps = {
  [K in Variant]: ComponentProps<typeof ExportTableHeading>;
};

export const ExportedDocumentsTable = (props: ExportedDocumentTableProps) => {
  const { columns, data, onEndReached, code, variant } = props;

  const { filters, onFilter } = useUrlBasedSortAndFilter<DocumentsTableData>({
    availableFilters,
  });

  const { filterOptions } = useExportTableFilters(availableFilters, data);

  const onRowClick = useDefaultOnRowClick();

  const height = useExportTableMaxHeight(data.length);

  const isTableFiltered = (filters ?? []).length > 0;
  const isEmpty = data.length === 0;

  if (isEmpty && !isTableFiltered) return null;

  const headingProps: HeadingProps = {
    success: {
      variant: 'success',
      count: data.length as number,
      code: 'success',
    },
    warning: {
      variant: 'warning',
      count: data.length as number,
      code: code as WarningCode,
    },
    error: {
      variant: 'error',
      count: data.length as number,
      code: code as ErrorCode,
    },
  };

  return (
    <Flex direction="column" flexGrow="1" rowGap="space16">
      <ExportTableHeading {...headingProps[variant]} />
      <DocumentsTable
        borderBottomRadius="none"
        borderTopRadius="none"
        height={height}
        columns={columns}
        data={data}
        defaultFilters={filters}
        filterOptions={filterOptions}
        onEndReached={onEndReached}
        onFilter={onFilter}
        onRowClick={onRowClick}
        customEmptyState={({ resetFilters }: CustomEmptyStateProps) => (
          <ExportTableCustomEmptyState
            resetFilters={resetFilters}
            isTableFiltered={isTableFiltered}
          />
        )}
      />
    </Flex>
  );
};
