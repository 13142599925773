import { Grid } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useDownloadCSV } from '../hooks/useDownloadCSV';
import { useGenerateCSV } from '../hooks/useGenerateCSV';
import { useGetStatementById } from '../hooks/useGetStatementById';
import { SettlementsSection } from './SettlementsSection';
import { StatementLoadingState } from './StatementLoadingState';
import { StatementSection } from './StatementSection';

export const StatementDetailsContainer = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();
  const { statementId } = useParams<{ statementId: string | undefined }>();

  const { loading, statementDetail, startFullDate, endFullDate } =
    useGetStatementById({
      id: statementId,
    });

  const { generateCSV, csvFileRecordId, isGenerateCSVPending } =
    useGenerateCSV();

  const { isDownloadingCSV } = useDownloadCSV({ csvFileRecordId, statementId });

  const handleGenerateCSV = async () => {
    if (!statementId) {
      return;
    }

    const response = await generateCSV(statementId);

    if (response.status === 'error') {
      error(t('statements.detail.errorToastMessage'));
    }
  };

  if (loading) {
    return <StatementLoadingState />;
  }

  return (
    <Grid templateRows="auto 1fr" padding="space16 space40" gap="space32">
      <StatementSection
        {...statementDetail}
        onDownloadCSV={handleGenerateCSV}
        isDownloadingCSV={isGenerateCSVPending || isDownloadingCSV}
      />
      <SettlementsSection dateFrom={startFullDate} dateTo={endFullDate} />
    </Grid>
  );
};
