import gql from 'graphql-tag';

export const sfsSettingsForRequester = gql`
  query sfsSettingsForRequester {
    sfsSettings {
      costCenter {
        on
        method
      }
      costObject {
        on
        method
      }
      generalLedgerAccount {
        on
        method
      }
      bookingKey {
        on
        method
      }
      generalLedgerAccount {
        on
        method
      }
      bookingKey {
        on
        method
      }
    }
  }
`;

export const sfsSettingsForApprover = gql`
  query sfsSettingsForApprover {
    sfsSettingsForApprover {
      costCenter {
        on
        method
      }
      costObject {
        on
        method
      }
      generalLedgerAccount {
        on
        method
      }
      bookingKey {
        on
        method
      }
      generalLedgerAccount {
        on
        method
      }
      bookingKey {
        on
        method
      }
    }
  }
`;

export const sfsSwitchCostCenterOffManually = gql`
  mutation sfsSwitchCostCenterOffManually {
    sfsSwitchCostCenterOffManually
  }
`;

export const sfsSwitchCostCenterOnManually = gql`
  mutation sfsSwitchCostCenterOnManually {
    sfsSwitchCostCenterOnManually
  }
`;

export const sfsSwitchCostObjectOffManually = gql`
  mutation sfsSwitchCostObjectOffManually {
    sfsSwitchCostObjectOffManually
  }
`;

export const sfsSwitchCostObjectOnManually = gql`
  mutation sfsSwitchCostObjectOnManually {
    sfsSwitchCostObjectOnManually
  }
`;

export const sfsSwitchGeneralLedgerAccountOnManually = gql`
  mutation sfsSwitchGeneralLedgerAccountOnManually {
    sfsSwitchGeneralLedgerAccountOnManually
  }
`;

export const sfsSwitchGeneralLedgerAccountOffManually = gql`
  mutation sfsSwitchGeneralLedgerAccountOffManually {
    sfsSwitchGeneralLedgerAccountOffManually
  }
`;

export const sfsSwitchBookingKeyOnManually = gql`
  mutation sfsSwitchBookingKeyOnManually {
    sfsSwitchBookingKeyOnManually
  }
`;

export const sfsSwitchBookingKeyOffManually = gql`
  mutation sfsSwitchBookingKeyOffManually {
    sfsSwitchBookingKeyOffManually
  }
`;

export const sfsSwitchCostObjectForApproverOffManually = gql`
  mutation sfsSwitchCostObjectForApproverOffManually {
    sfsSwitchCostObjectForApproverOffManually
  }
`;

export const sfsSwitchCostObjectForApproverOnManually = gql`
  mutation sfsSwitchCostObjectForApproverOnManually {
    sfsSwitchCostObjectForApproverOnManually
  }
`;

export const sfsSwitchCostCenterForApproverOffManually = gql`
  mutation sfsSwitchCostCenterForApproverOffManually {
    sfsSwitchCostCenterForApproverOffManually
  }
`;

export const sfsSwitchCostCenterForApproverOnManually = gql`
  mutation sfsSwitchCostCenterForApproverOnManually {
    sfsSwitchCostCenterForApproverOnManually
  }
`;

export const sfsSwitchGeneralLedgerAccountForApproverOffManually = gql`
  mutation sfsSwitchGeneralLedgerAccountForApproverOffManually {
    sfsSwitchGeneralLedgerAccountForApproverOffManually
  }
`;

export const sfsSwitchGeneralLedgerAccountForApproverOnManually = gql`
  mutation sfsSwitchGeneralLedgerAccountForApproverOnManually {
    sfsSwitchGeneralLedgerAccountForApproverOnManually
  }
`;

export const sfsSwitchBookingKeyForApproverOffManually = gql`
  mutation sfsSwitchBookingKeyForApproverOffManually {
    sfsSwitchBookingKeyForApproverOffManually
  }
`;

export const sfsSwitchBookingKeyForApproverOnManually = gql`
  mutation sfsSwitchBookingKeyForApproverOnManually {
    sfsSwitchBookingKeyForApproverOnManually
  }
`;
