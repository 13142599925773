import { Button, Card, Flex, Grid, Image, Text } from '@candisio/design-system';
import creditCards from 'containers/credit-cards/components/CreditCardsSection/media/mini card.png';
import {
  RouteTypeProps,
  useButtonEventTracker,
} from 'containers/credit-cards/components/CreditCardsSection/utils';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ApplyForCreditCardsModalHash } from 'views/Settings/CreditCards/CreditCardApplication/CreditCardApplicationSteps/toolkit/utils';

interface CreditCardAdoptedInfoBannerProps {
  showTitle?: boolean;
  routeType: RouteTypeProps;
}

export const CreditCardAdoptedInfoBanner = ({
  showTitle,
  routeType,
}: CreditCardAdoptedInfoBannerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const orgId = useOrganizationId();
  const navigate = useNavigate();

  const { CCAdoptedEventTracker } = useButtonEventTracker();

  const handleClick = () => {
    navigate({
      pathname: `/${orgId}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`,
      hash: `${ApplyForCreditCardsModalHash.APPLY_FOR_CC}`,
    });

    CCAdoptedEventTracker[routeType]();
  };

  return (
    <Grid width="fit-content" gap="space8">
      {showTitle && (
        <Text fontSize="xlarge">{t('ccInformationalCard.headerTitle')}</Text>
      )}
      <Card background="gray50">
        <Grid autoFlow="column" alignItems="center" gap="space64">
          <Image src={creditCards} alt="cards" height="300px" />
          <Flex
            gap="space24"
            alignItems="start"
            justifyContent="center"
            direction="column">
            <Flex direction="column" gap="space8">
              <Text fontSize="xxlarge" lineHeight="paragraph">
                {t('ccInformationalCardFFOn.title')}
              </Text>
              <Text fontSize="basic" lineHeight="paragraph">
                {t('ccInformationalCardFFOn.description')}
              </Text>
            </Flex>
            <Button alignSelf="start" onClick={handleClick}>
              {t('ccInformationalCardFFOn.ctaButton')}
            </Button>
          </Flex>
        </Grid>
      </Card>
    </Grid>
  );
};
