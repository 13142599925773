import { DocumentTagDetailsErrorMessages } from './documentTagDetailsFormSchema';

export const documentTagDetailsErrorMessages: DocumentTagDetailsErrorMessages =
  {
    name: {
      label: 'documentTags.drawer.fields.name.label',
    },
    description: {
      label: 'documentTags.drawer.fields.name.label',
    },
  };
