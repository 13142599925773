import {
  Checkbox,
  Grid,
  Tag,
  Tooltip,
  Text,
  useTooltip,
} from '@candisio/design-system';
import { UserRole } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { roleMap } from 'views/Settings/TeamMembers/utils';

export interface TeamMemberRoleCheckboxLegacyRolesProps {
  currentUserRoles: UserRole[];
  onChange: (selectedRoles: UserRole[]) => void;
  readOnly?: boolean;
  role: UserRole;
  selectedRoles?: UserRole[];
}

export const TeamMemberRoleCheckboxLegacyRoles = ({
  currentUserRoles,
  onChange,
  readOnly,
  role,
  selectedRoles,
}: TeamMemberRoleCheckboxLegacyRolesProps) => {
  const [t] = useTranslation();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'left',
    });

  const isAllowedToChangeRole = roleMap[role].permissionsRequiredToChange.every(
    r => currentUserRoles.includes(r)
  );

  const isSelected = selectedRoles?.includes(role);
  const isDisabled = !isAllowedToChangeRole;
  const isReadOnly =
    readOnly ||
    (selectedRoles?.includes(UserRole.Admin) && role === UserRole.Requester) ||
    (selectedRoles?.includes(UserRole.Admin) && role === UserRole.Approver);

  return (
    <Grid
      key={role}
      templateColumns="auto 1fr"
      gap="space8"
      {...triggerProps}
      ref={triggerRef}>
      <Checkbox
        value={role}
        isSelected={
          isSelected || ((selectedRoles || []).length > 0 && isReadOnly)
        }
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        onChange={isSelected => {
          if (isSelected) {
            if (selectedRoles) onChange([...selectedRoles, role]);
            else onChange([role]);
          } else {
            if (selectedRoles)
              onChange(
                selectedRoles.filter(currentRole => currentRole !== role)
              );
            else onChange([]);
          }
        }}>
        <Grid>
          <Tag color={roleMap[role].color}>{t(roleMap[role].roleName)}</Tag>
          <Text color="gray800" fontSize="basic" lineHeight="heading">
            {t(roleMap[role].roleDescription)}
          </Text>
        </Grid>
      </Checkbox>
      {isOpen && isReadOnly && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {
            // eslint-disable-next-line candis/no-template-strings-inside-translation
            t(
              readOnly
                ? 'settings.teamMembers.roles.readOnlySSO'
                : `settings.teamMembers.roles.${role}.activeReadonlyTooltip`
            )
          }
        </Tooltip>
      )}
    </Grid>
  );
};
