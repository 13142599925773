import { Flex, TruncatedText } from '@candisio/design-system';
import { BackButton } from 'components/BackButton/BackButton';
import { CaretNavigators } from './CaretNavigators';

export interface ListNavigatorProps {
  backToListText: string;
  arrowLeftTooltip: string;
  arrowRightTooltip: string;
  listNavigatorCounter?: string;
  loading?: boolean;
  onBackToList?: () => void;
  onNext?: () => void;
  onPrev?: () => void;
}

export const ListNavigator = ({
  backToListText,
  arrowLeftTooltip,
  arrowRightTooltip,
  listNavigatorCounter,
  loading,
  onBackToList,
  onNext,
  onPrev,
}: ListNavigatorProps) => {
  return (
    <Flex justifyContent="space-between">
      <BackButton onClick={onBackToList}>{backToListText}</BackButton>
      <Flex gap="space8" alignItems="center">
        {listNavigatorCounter && (
          <TruncatedText>{listNavigatorCounter}</TruncatedText>
        )}

        <CaretNavigators
          isLoading={loading}
          arrowLeftTooltip={arrowLeftTooltip}
          arrowRightTooltip={arrowRightTooltip}
          onNext={onNext}
          onPrev={onPrev}
        />
      </Flex>
    </Flex>
  );
};
