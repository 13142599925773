import { Tag, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

export const IsPaidCell = ({
  value,
}: CellProps<ReimbursementTableRow, boolean>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  if (!value) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  return (
    <Tag color="green" variant="secondary">
      {t('table.paid')}
    </Tag>
  );
};
