import { Box, Grid, Separator } from '@candisio/design-system';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { DOMAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { commonSapBtnStyles } from './PurchaseOrderDropdownMenu';

interface EmptyListStateProps {
  contactName?: string;
  hoverProps: DOMAttributes<HTMLDivElement>;
}

export const EmptyListState = ({
  contactName,
  hoverProps,
}: EmptyListStateProps) => {
  const [t] = useTranslation();

  const emptyListPlaceholder = contactName
    ? t(
        'document.requestApproval.inputs.purchaseOrderNumber.emptyListPlaceholderWithContactName',
        { contactName }
      )
    : t(
        'document.requestApproval.inputs.purchaseOrderNumber.emptyListPlaceholder'
      );

  return (
    <Grid templateRows="1fr auto">
      <Box color="gray500" padding="space24 space16">
        {emptyListPlaceholder}
      </Box>
      <Grid {...hoverProps}>
        <Separator />
        <Grid padding="space16">
          <SyncFromSap
            type={SAP_SYNC.PurchaseOrdersProcessing}
            btnProps={commonSapBtnStyles}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
