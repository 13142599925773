import { useApolloClient } from '@apollo/client';
import {
  BookingAccountsDuplicateCheckQuery,
  BookingAccountsDuplicateCheckQueryVariables,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { isNil } from 'lodash';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { accountCodeSchema } from 'utils/zodFormValidation/Schemas/bookingAccountCode';
import { ZodErrorMap, ZodParsedType } from 'zod';
import { bookingAccountsDuplicateCheckQuery } from '../BookingAccountImport/gql';

export const useBookingAccountValidation = (
  bookingAccountCodeToBeUpdated?: string
) => {
  const [t] = useTranslation([
    LOCALE_NAME_SPACE.BOOKING_ACCOUNTS,
    LOCALE_NAME_SPACE.COMMON,
  ]);

  const integration = useIntegrationSettings();
  const validateBasedOffIntegrationFF = useCandisFeatureFlags(
    FEATURE_FLAGS.validateBookingAccountBaseOffIntegration
  );

  const accountCodeParser = accountCodeSchema({
    validateBasedOffIntegrationFF,
  });

  const errorMap: ZodErrorMap = issue => {
    if (issue.code === 'too_big') {
      return { message: t('form.accountNumber.overMax') };
    }

    const invalidTypes: ZodParsedType[] = ['nan', 'undefined', 'null'];

    if (
      issue.code === 'invalid_type' &&
      invalidTypes.includes(issue.received)
    ) {
      return { message: t('form.accountNumber.required') };
    }

    return {
      message: t('form.accountNumber.invalid', {
        context: integration.toLowerCase(),
      }),
    };
  };

  const client = useApolloClient();

  const nameValidator = (name?: string | null) => {
    if (!name?.trim()) {
      return t('form.name.required');
    }
  };

  const accountNumberValidator = async (
    accountNumber?: string | null
  ): Promise<string | undefined> => {
    if (
      !isNil(bookingAccountCodeToBeUpdated) &&
      accountNumber === bookingAccountCodeToBeUpdated
    ) {
      return;
    }

    const parsedAccountCode = accountCodeParser.safeParse(
      {
        accountCode: accountNumber,
        integration,
      },
      { errorMap }
    );

    if (!parsedAccountCode.success) {
      const errorMessage = parsedAccountCode.error.issues[0].message;

      return errorMessage;
    }

    const accountCode = parsedAccountCode.data.accountCode;

    const { data } = await client.query<
      BookingAccountsDuplicateCheckQuery,
      BookingAccountsDuplicateCheckQueryVariables
    >({
      query: bookingAccountsDuplicateCheckQuery,
      variables: { accountCodes: [accountCode] },
      fetchPolicy: 'no-cache',
    });

    const existing = data.bookingAccountsDuplicateCheck.find(
      filterResult => accountCode === filterResult
    );

    if (existing) {
      return t('form.accountNumber.duplicate');
    }
  };

  return { nameValidator, accountNumberValidator };
};
