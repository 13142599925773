import { Button, Card, Grid, Item, Text } from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { HookFormUsersFieldOption } from 'components/HookFormFields/HookFormUsersField/HookFormUsersFieldOption';
import { UserFieldItem } from 'hooks/useUsersFieldOptions';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { CheckTeamName } from 'views/Settings/Teams/toolkit/hooks/useCheckTeamNameAvailability';
import { teamDetailsFormErrorMessages } from 'views/Settings/Teams/toolkit/utils/teamDetailsFormErrorMessages';
import {
  TeamDetailsFormOutput,
  TeamDetailsSchemaOptions,
  teamDetailsFormSchema,
} from 'views/Settings/Teams/toolkit/utils/teamDetailsFormSchema';
import { SecondaryLayoutDrawer } from '../../components/SecondaryLayoutDrawer';

const UPDATE_TEAM_DEATILS_FORM_ID = 'update-team-details-form';

interface UpdateTeamDetailsFormProps {
  defaultValues: TeamDetailsFormOutput;
  teamAdminOptions: UserFieldItem[];
  teamMembersOptions: UserFieldItem[];
  isUpdateTeamPending: boolean;
  isLoading: boolean;
  onGoBack: () => void;
  checkTeamName: CheckTeamName;
  onSubmit: (formValues: TeamDetailsFormOutput) => Promise<void>;
}

export const UpdateTeamDetailsForm = ({
  defaultValues,
  teamAdminOptions,
  teamMembersOptions,
  isUpdateTeamPending,
  isLoading,
  onGoBack,
  onSubmit,
  checkTeamName,
}: UpdateTeamDetailsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const context: TeamDetailsSchemaOptions = {
    checkTeamName,
  };

  const form = useForm<TeamDetailsFormOutput>({
    defaultValues,
    values: defaultValues,
    mode: 'onTouched',
    context,
    resolver: zodResolver({
      zodSchema: teamDetailsFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
      errorMessages: teamDetailsFormErrorMessages,
    }),
  });

  const bulletList: string[] = t('teams.createTeam.teamDetails.bulletPoints', {
    returnObjects: true,
  });

  return (
    <SecondaryLayoutDrawer
      onGoBack={onGoBack}
      footer={
        <Button
          type="submit"
          form={UPDATE_TEAM_DEATILS_FORM_ID}
          disabled={isUpdateTeamPending}
          loading={isUpdateTeamPending}>
          {t('teams.editTeam.teamDetails.saveCTA')}
        </Button>
      }>
      <FormProvider {...form}>
        <Grid
          gap="space24"
          as="form"
          id={UPDATE_TEAM_DEATILS_FORM_ID}
          onSubmit={form.handleSubmit(onSubmit)}>
          <Card background="gray100">
            <Grid gap="space16">
              <HookFormTextField
                autoFocus
                name="teamName"
                label={t('teams.createTeam.teamDetails.fields.teamName.label')}
                ariaLabel={t(
                  'teams.createTeam.teamDetails.fields.teamName.label'
                )}
                placeholder={t(
                  'teams.createTeam.teamDetails.fields.teamName.placeholder'
                )}
              />
              <HookFormComboBoxField
                name="teamAdmin"
                label={t('teams.createTeam.teamDetails.fields.teamAdmin.label')}
                aria-label={t(
                  'teams.createTeam.teamDetails.fields.teamAdmin.label'
                )}
                aria-labelledby={t(
                  'teams.createTeam.teamDetails.fields.teamAdmin.label'
                )}
                placeholder={t(
                  'teams.createTeam.teamDetails.fields.teamAdmin.placeholder'
                )}
                defaultItems={teamAdminOptions}
                isLoading={isLoading}
                children={({ key, children, avatarUrl }) => (
                  <Item key={key} textValue={children}>
                    <HookFormUsersFieldOption
                      children={children}
                      avatarUrl={avatarUrl}
                    />
                  </Item>
                )}
              />

              <Grid gap="space8">
                <Text color="gray600" fontSize="basic">
                  {t('teams.createTeam.teamDetails.permissionsForAdminTitle')}
                </Text>
                <Grid as="ul" padding={0} listStyle="none" gap="space4">
                  {bulletList.map((item, index) => (
                    <li key={index}>
                      <Text color="gray600" fontSize="basic">
                        {item}
                      </Text>
                    </li>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Grid gap="space8">
            <Text fontSize="xlarge">
              {t('teams.createTeam.teamMembers.heading')}
            </Text>
            <Card background="gray100">
              <Grid gap="space16">
                <HookFormUsersField
                  name="teamMembers"
                  isLoading={isLoading}
                  defaultItems={teamMembersOptions}
                  label={t('teams.createTeam.teamMembers.field.label')}
                  placeholder={t(
                    'teams.createTeam.teamMembers.field.placeholder'
                  )}
                  emptyListPlaceholder={t(
                    'teams.createTeam.teamMembers.field.noUserFound'
                  )}
                />
                <Grid as="ul" padding={0} listStyle="none" gap="space4">
                  {bulletList.map((item, index) => (
                    <li key={index}>
                      <Text color="gray600" fontSize="basic">
                        {item}
                      </Text>
                    </li>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </SecondaryLayoutDrawer>
  );
};
