import {
  CardActionName,
  CardStatus,
  CardType,
  GetVendorsForRecurringPaymentsQuery,
  GetVendorsForRecurringPaymentsQueryVariables,
  LinkedCardTransactionState,
  SortDirection,
  TransactionAmount,
  VendorsForRecurringPaymentsSortField,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { getVendorsForRecurringPaymentsQuery } from '../CreditCardsInsights/gql';

export interface RecurringPayment {
  vendor: {
    id: string;
    vendorName: string;
    logoUrl?: string;
  };
  linkedCard: {
    id: string;
    transactionState: LinkedCardTransactionState;
    card: {
      id: string;
      type: CardType;
      status: CardStatus;
      refNum?: string;
      cardholder?: {
        id: string;
        email: string;
        name: string;
      };
      limit?: TransactionAmount;
      availableBalance?: TransactionAmount;
      pendingActions?: CardActionName[];
    };
  };
}

interface UseRecurringPaymentDataParams {
  sortBy: VendorsForRecurringPaymentsSortField;
}

const mappedDirectionSortFields: Record<
  VendorsForRecurringPaymentsSortField,
  SortDirection
> = {
  CARD_REF_NUM: SortDirection.Asc,
  CARDHOLDER_FULL_NAME: SortDirection.Asc,
  NAME: SortDirection.Asc,
  RECENTLY_LINKED: SortDirection.Desc,
};

export const useRecurringPaymentData = ({
  sortBy,
}: UseRecurringPaymentDataParams) => {
  const creditCardsSetup = useCreditCardsSetup();
  const { data, loading, onLoadMore } = usePagePagination<
    GetVendorsForRecurringPaymentsQuery,
    GetVendorsForRecurringPaymentsQueryVariables
  >(getVendorsForRecurringPaymentsQuery, 'getVendorsForRecurringPayments', {
    variables: {
      filters: {
        includeIgnoredVendors: false,
      },
      sortBy: {
        direction: mappedDirectionSortFields[sortBy],
        field: sortBy,
      },
    },
    skip: !creditCardsSetup.showAllExtraFeatures,
    fetchPolicy: 'cache-and-network',
  });

  const totalCount =
    data?.getVendorsForRecurringPayments.pageInfo.totalCount ?? 0;

  const hasMore =
    (data?.getVendorsForRecurringPayments.records.length ?? 0) < totalCount;

  const recurringPayments: RecurringPayment[] = (
    data?.getVendorsForRecurringPayments.records ?? []
  ).map(v => {
    const card = v.linkedCards[0].card;

    const pendingActions = (card?.pendingActions ?? []).map(
      action => action.name
    );

    return {
      vendor: {
        id: v.vendor.id,
        vendorName: v.vendor.name,
        logoUrl: v.vendor.logoUrl ?? undefined,
      },
      linkedCard: {
        id: v.linkedCards[0].id,
        transactionState: v.linkedCards[0].transactionState,
        card: {
          id: card.id,
          type: card.type,
          status: card.status,
          refNum: card.refNum ?? undefined,
          pendingActions,
          cardholder: !isNil(card.cardholder)
            ? {
                id: card.cardholder.membershipId ?? '',
                email: card.cardholder.membershipEmail ?? '',
                name: `${card.cardholder.firstName ?? ''} ${
                  card.cardholder.lastName ?? ''
                }`,
              }
            : undefined,
          limit: !isNil(card.limit)
            ? {
                value: card.limit?.value,
                currency: card.limit?.currency,
                precision: card.limit?.precision,
              }
            : undefined,
          availableBalance: !isNil(card.availableBalance)
            ? {
                value: card.availableBalance.value,
                currency: card.availableBalance?.currency,
                precision: card.availableBalance?.precision,
              }
            : undefined,
        },
      },
    };
  });

  const recurringPaymentsDetails = {
    recurringPayments,
    totalCount,
  };

  return { loading, recurringPaymentsDetails, onLoadMore, hasMore };
};
