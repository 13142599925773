import {
  CardStatus,
  LinkedCardTransactionState,
} from 'generated-types/graphql.types';
import { ReactNode } from 'react';
import { RecurringPayment } from 'views/CreditCards/hooks/useRecurringPaymentData';
import { ExpiredTag } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/LinkedCardState/CardStatusTag/ExpiredTag';
import { LockedByPinTag } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/LinkedCardState/CardStatusTag/LockedByPinTag';
import { LockedTag } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/LinkedCardState/CardStatusTag/LockedTag';
import { PendingTag } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/LinkedCardState/CardStatusTag/PendingTag';
import { ProcessingTag } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/LinkedCardState/CardStatusTag/ProcessingTag';
import { TerminatedTag } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/LinkedCardState/CardStatusTag/TerminatedTag';
import { NoTransactionMadeState } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/LinkedCardState/NoTransactionMadeState';
import {
  FailedTransaction,
  SuccessTransaction,
} from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsList/LinkedCardState/TransactionState';
interface LinkedCardStateProps {
  isHovered: boolean;
  percentage: number;
  vendorName: string;
  availableBalance: string | null;
  limit: string | null;
  card: RecurringPayment['linkedCard']['card'];
  transactionState: LinkedCardTransactionState;
}

export const useLinkedCardState = ({
  percentage,
  availableBalance,
  isHovered,
  card,
  vendorName,
  limit,
  transactionState,
}: LinkedCardStateProps) => {
  const mapCardStatusToLinkedCard: Partial<Record<CardStatus, ReactNode>> = {
    LOCKED: <LockedTag />,
    LOCKED_PIN: <LockedByPinTag />,
    EXPIRED: <ExpiredTag />,
    TERMINATED: <TerminatedTag />,
    PENDING: <PendingTag isOpen={isHovered} card={card} />,
    PROCESSING: <ProcessingTag isOpen={isHovered} card={card} />,
  };

  const mapTransactionStateToLinkedCard: Record<
    LinkedCardTransactionState,
    ReactNode
  > = {
    CARD_CHECK_FAILED: <FailedTransaction txState={transactionState} />,
    CARD_CHECK_SUCCEEDED: <SuccessTransaction txState={transactionState} />,
    PURCHASE_FAILED: <FailedTransaction txState={transactionState} />,
    CARD_CHECK_NOT_DONE: (
      <NoTransactionMadeState
        card={card}
        vendorName={vendorName}
        isHovered={isHovered}
        availableBalance={availableBalance}
      />
    ),
    PURCHASE_SUCCEEDED: (
      <SuccessTransaction
        txState={transactionState}
        percentage={percentage}
        availableBalance={availableBalance}
        limit={limit}
      />
    ),
    CANNOT_COMPUTE: (
      <SuccessTransaction
        txState={transactionState}
        percentage={percentage}
        availableBalance={availableBalance}
        limit={limit}
      />
    ),
  };

  const linkedCardState =
    card.status !== CardStatus.Active
      ? mapCardStatusToLinkedCard[card.status]
      : mapTransactionStateToLinkedCard[transactionState];

  return linkedCardState;
};
