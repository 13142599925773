import { Box, Grid, Link } from '@candisio/design-system';
import { HookFormApproversFieldArray } from 'components/HookFormFields/HookFormApproversFieldArray/HookFormApproversFieldArray';
import { isNil } from 'lodash';
import { AppRouteParams, Routes } from 'models';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom-v5-compat';
import { useWorkflowTemplateData } from 'views/CreditCards/hooks/AccountingInfoFormFieldData/useWorkflowTemplateData';
import { ReadOnlyTransactionApproversField } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/Fields/ReadOnlyTransactionApproversField';
import { ReadOnlyTransactionWorkflowField } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/Fields/ReadOnlyTransactionWorkflowField';
import { ReadOnlyTransactionFormValues } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/utils/readOnlyTransactionFormSchema';
import { AccountingInfoFormSchemaFormValues } from '../utils/accountingInfoFormSchema';
import { AccountingInfoFieldOptions } from '../utils/types';

export type ApprovalMode = AccountingInfoFormSchemaFormValues['approvalMode'];

export interface CreditCardsWorkflowAndApproversFieldsProps {
  approversFieldOptions?: AccountingInfoFieldOptions['approvers'];
  workflowFieldOptions?: AccountingInfoFieldOptions['workflow'];
  readOnly?: boolean;
}

export const CreditCardsWorkflowAndApproversFields = ({
  approversFieldOptions,
  workflowFieldOptions,
  readOnly,
}: CreditCardsWorkflowAndApproversFieldsProps) => {
  const [t] = useTranslation();
  const { organizationSlug } = useParams<AppRouteParams>();

  const { setValue } = useFormContext<AccountingInfoFormSchemaFormValues>();
  const approvalMode = useWatch<AccountingInfoFormSchemaFormValues>({
    name: 'approvalMode',
  });

  const workflow = useWatch<
    Pick<AccountingInfoFormSchemaFormValues, 'workflow'>
  >({
    name: 'workflow',
  });

  const { approversFromWorkflow } = useWorkflowTemplateData({
    workflow,
  });

  const hasWorkflows = Boolean(
    workflowFieldOptions?.options.defaultItems?.length
  );

  const showWorkflowField = hasWorkflows && approvalMode === 'workflow';

  const handleCreateWorkflow = () => {
    if (!organizationSlug) {
      return;
    }

    const workflowSettingsPath = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}${Routes.WORKFLOWS}/create`,
      { organizationSlug }
    );

    window.open(workflowSettingsPath, '_blank', 'rel=noopener noreferrer');
  };

  return (
    <Grid gap="space4" alignContent="start">
      {showWorkflowField ? (
        <ReadOnlyTransactionWorkflowField
          name="workflow"
          label={t('document.requestApproval.inputs.workflow.label')}
          placeholder={t(
            'document.requestApproval.inputs.workflow.placeholder'
          )}
          readOnly={readOnly}
          defaultItems={workflowFieldOptions?.options.defaultItems}
        />
      ) : (
        <HookFormApproversFieldArray<ReadOnlyTransactionFormValues>
          name="approvers"
          readOnly={readOnly}
          approversFieldOptions={approversFieldOptions?.options}
          renderApprovalStep={props => {
            return <ReadOnlyTransactionApproversField {...props} />;
          }}
        />
      )}
      {!readOnly && (
        <Box>
          <Link
            as="button"
            external={hasWorkflows ? false : true}
            onClick={() => {
              if (hasWorkflows) {
                setValue(
                  'approvalMode',
                  approvalMode === 'workflow' ? 'approvers' : 'workflow'
                );
              } else {
                handleCreateWorkflow();
              }

              if (approversFromWorkflow) {
                setValue('approvers', approversFromWorkflow);
              }

              if (approvalMode === 'workflow') {
                setValue('workflow', undefined);
              }
            }}>
            {hasWorkflows
              ? approvalMode === 'workflow'
                ? isNil(workflow)
                  ? t(
                      'document.requestApproval.inputs.workflow.selectApproversManually'
                    )
                  : t(
                      'document.requestApproval.inputs.workflow.editWorkflowLocally'
                    )
                : t(
                    'document.requestApproval.inputs.approvalStep.selectExistingWorkflow'
                  )
              : t(
                  'document.requestApproval.inputs.approvalStep.createWorkflow'
                )}
          </Link>
        </Box>
      )}
    </Grid>
  );
};
