import { CsvParser } from 'services/CSVParser';
import { DTVF_SCHEMA, EXTF_SCHEMA } from './constants';
import {
  PaymentConditionCSVWrongFormat,
  PaymentConditionWithoutNumberError,
} from './errors';

export interface ParsedPaymentCondition {
  discountOffset?: number;
  discountPercentage?: number;
  dueDateOffset: number;
  dueType: number;
  paymentConditionNumber: number;
}

export interface PaymentsConditionsParser {
  csvParser: CsvParser;
  parsePaymentConditionCSV: (file: File) => Promise<ParsedPaymentCondition[]>;
}

export class PaymentsConditionsParser implements PaymentsConditionsParser {
  constructor(csvParser: CsvParser) {
    this.csvParser = csvParser;
  }

  public parsePaymentConditionCSV = async (
    file: File
  ): Promise<ParsedPaymentCondition[]> => {
    const fileWithoutHeaderRows = await this.csvParser.removeRows({
      file,
      indexStart: 0,
      deleteCount: 2,
    });

    const parsedPaymentsConditions = fileWithoutHeaderRows.map(pc =>
      this.parsePaymentConditionRow(pc)
    );

    return parsedPaymentsConditions;
  };

  private validateDTVFCSVFormat = async (file: File) => {
    const { data } = await this.csvParser.parseCsv({
      file,
      encoding: 'windows-1252',
    });

    const validationResult = DTVF_SCHEMA.safeParse(data[1]);

    if (!validationResult.success) {
      throw new PaymentConditionCSVWrongFormat();
    }
  };

  private validateEXTFCSVFormat = async (file: File) => {
    const { data } = await this.csvParser.parseCsv({
      file,
      encoding: 'windows-1252',
    });

    const result = EXTF_SCHEMA.safeParse(data[1]);

    if (!result.success) {
      throw new PaymentConditionCSVWrongFormat();
    }
  };

  public validateCSVFormat = async (file: File) => {
    const isDTVF = await this.csvParser.isCellValueEqual({
      file,
      rowIndex: 0,
      columnIndex: 0,
      value: 'DTVF',
    });

    const isEXTF = await this.csvParser.isCellValueEqual({
      file,
      rowIndex: 0,
      columnIndex: 0,
      value: 'EXTF',
    });

    if (isDTVF) {
      return await this.validateDTVFCSVFormat(file);
    }

    if (isEXTF) {
      return await this.validateEXTFCSVFormat(file);
    }

    throw new PaymentConditionCSVWrongFormat();
  };

  private parsePaymentConditionRow = (
    row: string[]
  ): ParsedPaymentCondition => {
    const fieldIndexes = {
      paymentConditionNumber: 0,
      dueType: 2,
      discountPercentage: 3,
      discountOffset: 4,
      dueDateOffset: 7,
    };

    const paymentConditionNumber = Number(
      row[fieldIndexes.paymentConditionNumber]
    );

    const dueType = Number(row[fieldIndexes.dueType]);
    const discountOffset = Number(row[fieldIndexes.discountOffset]);
    const discountPercentage =
      Number(row[fieldIndexes.discountPercentage]) / 100;

    const dueDateOffset = Number(row[fieldIndexes.dueDateOffset]);

    if (!paymentConditionNumber) {
      throw new PaymentConditionWithoutNumberError();
    }

    if (discountOffset === 0 && discountPercentage === 0) {
      // If both discount offset and discount percentage are 0, treat as empty.
      // This seems to be how Datev does it...
      return {
        discountOffset: undefined,
        discountPercentage: undefined,
        dueDateOffset,
        dueType,
        paymentConditionNumber,
      };
    }

    return {
      discountOffset,
      discountPercentage,
      dueDateOffset,
      dueType,
      paymentConditionNumber,
    };
  };
}
