import { QueryLazyOptions } from '@apollo/client';
import { useEffect } from 'react';
import { useWindowFocus } from './useWindowFocus';

export interface UsePollingOptions {
  fetch: (options?: QueryLazyOptions<any>) => void;
  skip?: boolean;
}

/**
 * Fetch a lazy query when the window has, or regains, focus.
 */
export const useFetchOnWindowFocus = ({
  fetch,
  skip = false,
}: UsePollingOptions) => {
  const isFocused = useWindowFocus();

  useEffect(() => {
    if (isFocused && !skip) {
      fetch();
    }
  }, [fetch, isFocused, skip]);
};
