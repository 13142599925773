import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useDraggedFileStatus = (
  draggedFileCount: number,
  isAcceptedFileType: boolean
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const hasNoFile = draggedFileCount === 0;
  const hasOneFile = draggedFileCount === 1;
  const hasMoreThanOneFile = draggedFileCount > 1;

  const canUserDropFile = hasNoFile || (hasOneFile && isAcceptedFileType);

  const statusText = () => {
    if (canUserDropFile) {
      return t(
        'transactionAssociation.invoiceEmptyState.documentUpload.dropZone'
      );
    } else if (hasMoreThanOneFile) {
      return t(
        'transactionAssociation.invoiceEmptyState.documentUpload.dropZoneWarningText'
      );
    }

    return t(
      'transactionAssociation.invoiceEmptyState.documentUpload.dropZoneWarningTextForUnacceptedFiles'
    );
  };

  return { canUserDropFile, statusText };
};
