import { AppRouteParams, Routes, compiledRoutes } from 'models';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import {
  useNavigate,
  generatePath,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { BookingKeyRouteParams } from 'views/Settings/TaxCodes/utils';

const url = `/:${AppRouteParams.organizationSlug}/${Routes.SETTINGS}/${compiledRoutes.editTaxCodeRoute}`;

export default function useTaxCodesNavigation() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { organizationSlug, bookingKeyId } = useParams<BookingKeyRouteParams>();

  const detailsPath = generatePath(url, {
    organizationSlug,
    bookingKeyId,
    search: searchParams.toString(),
  });

  const listPath = generatePath(url, {
    organizationSlug,
    bookingKeyId: undefined,
    search: searchParams.toString(),
  });

  const navigateBackToList = () =>
    navigate({
      pathname: listPath,
      search: searchParams.toString(),
      hash: '',
    });

  const navigateToBookingKey = (
    id: string,
    isArchived = searchParams.get('archive') === 'true'
  ) => {
    const params = new URLSearchParams(searchParams);
    params.set('archive', isArchived.toString());

    navigate({
      pathname: generatePath(url, {
        organizationSlug,
        bookingKeyId: id,
      }),
      search: params.toString(),
      hash: '',
    });
  };

  return {
    detailsPath,
    listPath,
    bookingKeyId,
    navigateBackToList,
    navigateToBookingKey,
  };
}
