import { Tag, TagProps } from '@candisio/design-system';
import {
  DocumentStatus,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from 'views/Inbox/models';
import { StatusType } from './DocumentsTable/types';

type SupportedStatuses =
  | StatusType
  | EcmDocumentStatus
  | ApprovalStatus
  | DocumentStatus;

const statuses: Record<Lowercase<SupportedStatuses>, TagProps['color']> = {
  approved: 'green',
  archived: 'gray',
  declined: 'red',
  exported: 'blue',
  exporting: 'yellow',
  new: 'gray',
  open: 'yellow',
  pending: 'yellow',
  processing: 'yellow',
  rejected: 'red',
  stored: 'gray',
};

const statusTranslationMap: Record<Lowercase<SupportedStatuses>, string> = {
  approved: 'statuses.approved',
  archived: 'statuses.archived',
  declined: 'statuses.declined',
  exported: 'statuses.exported',
  exporting: 'statuses.exporting',
  new: 'statuses.new',
  open: 'statuses.open',
  pending: 'statuses.pending',
  processing: 'statuses.processing',
  rejected: 'statuses.rejected',
  stored: 'statuses.stored',
};

export const StatusTag = ({
  status,
  variant = 'secondary',
}: {
  status: SupportedStatuses;
  variant?: TagProps['variant'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const statusString = status.toLowerCase() as Lowercase<SupportedStatuses>;

  return (
    <Tag color={statuses[statusString]} variant={variant}>
      {t(statusTranslationMap[statusString])}
    </Tag>
  );
};
