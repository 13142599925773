import { Grid, Text } from '@candisio/design-system';
import {
  CardIssuerTransactionInvoiceNotNeededReason,
  TransactionMarkedAsInvoiceNotNeededEventDataFragment,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';

const reasonTextMap: Record<
  CardIssuerTransactionInvoiceNotNeededReason,
  string
> = {
  INVOICE_ALREADY_EXPORTED: 'documentHistoryCCEvents.invoice_already_exported',
  NEEDS_NO_INVOICE: 'documentHistoryCCEvents.needs_no_invoice',
  OTHER: 'documentHistoryCCEvents.other',
  CARD_NEEDS_NO_INVOICE: 'documentHistoryCCEvents.card_needs_no_invoice',
};

export const NoInvoiceNeededActivated = ({
  properties,
}: Pick<
  TransactionMarkedAsInvoiceNotNeededEventDataFragment,
  'properties'
>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const reasonComment = properties?.reasonComment;

  const comment = reasonComment ? ` - ${reasonComment}` : undefined;

  const reason = properties?.reason
    ? t(reasonTextMap[properties.reason])
    : undefined;

  return (
    <Grid gap="space4">
      <Text>
        {t('documentHistoryCCEvents.transactionWithNoInvoiceNeededActived')}
      </Text>
      <Text>
        {reason}
        {comment}
      </Text>
    </Grid>
  );
};

export const NoInvoiceNeededDeactivated = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Text>
      {t('documentHistoryCCEvents.transactionWithNoInvoiceNeededDeactived')}
    </Text>
  );
};
