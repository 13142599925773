import {
  Button,
  Card,
  Flex,
  Grid,
  Paragraph,
  Radio,
  RadioGroup,
  Spinner,
  Tag,
  Text,
  useModal,
} from '@candisio/design-system';
import {
  DatevConnectionType,
  New_DatevSettingsQuery,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { BDSPromotionModal } from 'views/Integrations/Export/toolkit/components/BDSPromotion';
import { ModalSource } from 'views/Integrations/Export/types';
import { urlParams } from '../../utils';
import { DcsSettingsContainer } from '../DcsContainer/DcsSettingsContainer';

export type ConnectToDatevContainerProps = {
  settingsData?: New_DatevSettingsQuery;
  settingsLoading?: boolean;
  authLoadingForClients?: boolean;
  authorizeForClients: (connectionType?: DatevConnectionType) => Promise<void>;
};

export const ConnectToDatevContainer = ({
  settingsData,
  authLoadingForClients,
  authorizeForClients,
}: ConnectToDatevContainerProps) => {
  const [t] = useTranslation();
  const [search, setSearch] = useSearchParams();
  const { bdsBought } = useDatev(); // BDS-checked

  const datevConnectionType = (search.get(urlParams.datevConnectionType) ||
    (bdsBought
      ? DatevConnectionType.DatevRewe
      : DatevConnectionType.DatevDuo)) as DatevConnectionType;

  const hasAuthTokenForReadClients =
    settingsData?.new_datevSettings?.hasAuthTokenForReadClients;

  const hasAuthTokenForExport =
    settingsData?.new_datevSettings?.hasAuthTokenForExportDocuments;

  const hasConnectedClient =
    hasAuthTokenForExport && !!settingsData?.new_datevSettings?.client;

  const modalSource = ModalSource.SETTINGS_CONNECT_TO_DATEV;

  const { triggerProps, modalProps, isOpen } = useModal();

  return (
    <DcsSettingsContainer
      cardLoading={settingsData === undefined}
      title={t('settings.datev.connect.cardTitle')}
      details={
        authLoadingForClients ? (
          <Flex gap="space8">
            <Spinner color="blue600" size="space16" />
            <Text fontSize="small">
              {t('settings.datev.connect.connectToDatev.loadingText')}
            </Text>
          </Flex>
        ) : (
          <>
            <Paragraph>
              {t('settings.datev.connect.connectToDatev.description')}
            </Paragraph>
            <Grid gap="space16" width="100%" justifyItems="left">
              <Grid gap="space4">
                <RadioGroup
                  aria-label="datev connection variants"
                  label={
                    <Paragraph fontSize="basic" color="gray500">
                      {t(
                        'settings.datev.connect.connectToDatev.selectConnectionTypeText'
                      )}
                    </Paragraph>
                  }
                  onChange={(e: string) => {
                    search.set(urlParams.datevConnectionType, e);
                    setSearch(search);
                  }}
                  value={datevConnectionType}
                  defaultValue={DatevConnectionType.DatevDuo}>
                  <Grid gap="space8">
                    <Radio
                      order={bdsBought ? 2 : 1}
                      key="DatevDuo"
                      value={DatevConnectionType.DatevDuo}
                      aria-label={DatevConnectionType.DatevDuo}>
                      <Flex direction="column">
                        <Text fontSize="basic">
                          {t('settings.datev.connect.connectToDatev.datevDUO')}
                        </Text>
                        <Text fontSize="small" color="gray500">
                          {t(
                            'settings.datev.connect.connectToDatev.datevDUOSub'
                          )}
                        </Text>
                      </Flex>
                    </Radio>
                    <Radio
                      order={bdsBought ? 1 : 2}
                      key="DatevRewe"
                      value={DatevConnectionType.DatevRewe}
                      aria-label={DatevConnectionType.DatevRewe}>
                      <Flex direction="column">
                        <Flex alignItems="center" gap="space12">
                          <Text fontSize="basic">
                            {t(
                              'settings.datev.connect.connectToDatev.datevRewe'
                            )}
                          </Text>
                          {!bdsBought && (
                            <Tag color="purple" variant="secondary">
                              {t('tag.addOn')}
                            </Tag>
                          )}
                        </Flex>
                        <Text fontSize="small" color="gray500">
                          {t(
                            'settings.datev.connect.connectToDatev.datevReweSub'
                          )}
                        </Text>
                      </Flex>
                    </Radio>
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          </>
        )
      }
      actions={
        !hasConnectedClient &&
        !hasAuthTokenForReadClients && (
          <Button
            name="connectToDatev"
            loading={authLoadingForClients}
            size="medium"
            disabled={
              !bdsBought &&
              datevConnectionType === DatevConnectionType.DatevRewe
            }
            onClick={() => authorizeForClients(datevConnectionType)}>
            {t('settings.datev.connect.actions.login')}
          </Button>
        )
      }
      bottomActions={
        !bdsBought &&
        datevConnectionType === DatevConnectionType.DatevRewe && (
          <Card background="purplebg">
            <Grid templateColumns="1fr auto" gap="space8" alignItems="center">
              <Text fontSize="basic">
                {t('settings.datev.connect.connectToDatev.promoCard.title')}
              </Text>
              <Button color="purple" {...triggerProps}>
                {t('settings.datev.connect.connectToDatev.promoCard.button')}
              </Button>
            </Grid>

            {isOpen ? (
              <BDSPromotionModal modalSource={modalSource} {...modalProps} />
            ) : null}
          </Card>
        )
      }
    />
  );
};
