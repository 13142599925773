import { useToastMessage } from 'components/Toast/useToastMessage';
import { useDeleteByIdMutation } from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useTranslation } from 'react-i18next';
import { List, getRefetchListQueries } from 'views/queries';
import { LINKED_DOCUMENTS_PARTIAL_ERROR_MESSAGE } from './components/Ecm/useRemoveEcmDocument';

/** Deletes document with documentId */
export const useDeleteDocument = (documentId: string) => {
  const [t] = useTranslation();
  const { success, error: errorToast } = useToastMessage();

  const counterQueries = useCounterQueries();
  const [mutation, { loading }] = useDeleteByIdMutation({
    variables: { id: documentId },
    refetchQueries: [
      ...counterQueries,
      ...getRefetchListQueries(List.INBOX),
      // Specifying the query only by string we make sure it is refetched only when active in the view
      'getCardIssuerTransactionById',
    ],
  });

  const deleteDocument = async () => {
    if (!documentId) {
      return { status: 'error' };
    }

    const { errors } = await mutation();

    if (errors) {
      errors.forEach(error => {
        error.message.includes(LINKED_DOCUMENTS_PARTIAL_ERROR_MESSAGE)
          ? errorToast(t('ecm:linkedDocumentError'))
          : errorToast(error.message);
      });

      return { status: 'error' };
    } else {
      success(t('document.removeSuccessful'));

      return { status: 'success' };
    }
  };

  return { deleteDocument, isDeletingDocument: loading };
};
