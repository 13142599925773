import { ContractSubCategoryDetailsErrorMessages } from './contractSubCategoryDetailsFormSchema';

export const contractSubCategoryDetailsErrorMessages: ContractSubCategoryDetailsErrorMessages =
  {
    name: {
      label: 'contractCustomSubCategories.drawer.fields.name.label',
    },
    description: {
      label: 'contractCustomSubCategories.drawer.fields.name.label',
    },
  };
