import { Grid, LottieAnimation, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CreditCardsFieldEmptyState = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid
      placeContent="center"
      gap="space16"
      height="100%"
      justifyItems="center"
      templateRows="min-content min-content"
      alignItems="center">
      <LottieAnimation animationData="noresultsData" />
      <Text textAlign="center" fontSize="basic" alignSelf="start">
        {t('cardManagerView.table.emptyState.isEmpty')}
      </Text>
    </Grid>
  );
};
