import { z } from 'zod';

export const creditCardTransitAccountSchema = z.object({
  moneyTransitAccountNumber: z.string().min(1).max(100),
});

export const errorMessages = {
  moneyTransitAccountNumber: {
    label:
      'creditCardExportingSap.moneyTransitAccountNumber.textInput.ariaLabel',
  },
};
