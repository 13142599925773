import { CellProps, Text } from '@candisio/design-system';
import { AmountCell } from 'components/Table/Cells/Amount';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import {
  ReimbursementAmount,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';

export const AmountCellReimbursements = ({
  value,
}: CellProps<ReimbursementTableRow, ReimbursementAmount>) => {
  const { documentMoneyFormatter } = useMoneyFormatter();
  if (!value?.amount && !value?.currency && !value?.precision) {
    return <Text> - </Text>;
  }

  const formattedAmount = documentMoneyFormatter({
    amount: value?.amount,
    currency: value?.currency,
    precision: value?.precision,
  });

  return <AmountCell formattedAmount={formattedAmount} />;
};
