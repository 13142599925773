import { TextField } from '@candisio/design-system';
import { useSap } from 'orgConfig/sap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPES } from 'views/Inbox/DocumentProcessing/consts';
import { DocumentFormValues } from '../schema';

export const DocumentType = () => {
  const [t] = useTranslation();
  const sap = useSap();

  const [type] = useWatch<DocumentFormValues, ['category.type']>({
    name: ['category.type'],
  });

  const shouldTranslateValue = sap.isActiveIntegration && type;

  let value = type ?? undefined;

  if (shouldTranslateValue) {
    switch (type) {
      case DOCUMENT_TYPES.RECHNUNGSEINGANG:
        value = t(
          'document.requestApproval.defaultDocumentTypes.Rechnungseingang'
        );
        break;
      case DOCUMENT_TYPES.EINGANGSGUTSCHRIFT:
        value = t(
          'document.requestApproval.defaultDocumentTypes.Eingangsgutschrift'
        );
        break;
      default:
        value = type;
        break;
    }
  }

  return (
    <TextField
      input={{
        readOnly: true,
        placeholder: '–',
        value: value,
      }}
      label={t('document.requestApproval.inputs.document.label')}
      readOnly
    />
  );
};
