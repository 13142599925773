import { TabPanel, Tabs } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useTabs } from 'components/Tabs/useTabs';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps } from 'react-router-dom';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { Match } from 'router/Match';
import { usePath } from 'utils/hooks';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { WorkflowTemplateDetails } from './components/WorkflowTemplateDetails/WorkflowTemplateDetails';
import {
  WorkflowTemplatesQueryProps,
  useWorkflowData,
} from './hooks/useWorkflowData';
import { WorkflowTemplatesListView } from './WorkflowTemplatesListView';

export const WORKFlOWS_LIST_WIDTH = '50rem';

export type WorkflowRouteParams = {
  workflowId?: string;
};

export type WorkflowQueryParams = {
  archive?: boolean;
};

export type WorkflowTemplatesProps = RouteComponentProps<WorkflowRouteParams>;

export const WorkflowTemplates = ({
  history,
  location: { search },
}: WorkflowTemplatesProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const isArchiveActive =
    searchParams.get(WorkflowTemplatesQueryProps.archive) === 'true';

  const { workflowData, loading, workflowCounts, workflowUser } =
    useWorkflowData({
      isArchiveActive,
    });

  const newSearchParams = new URLSearchParams(searchParams);
  const { pathGenerator } = usePath<WorkflowRouteParams, WorkflowQueryParams>();

  const list = (
    <WorkflowTemplatesListView
      data={workflowData}
      archiveCount={workflowCounts?.archived}
      isLoading={loading}
      workflowUser={workflowUser}
    />
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('workflows.actions.active'),
        badge: !loading ? String(workflowCounts?.active ?? 0) : undefined,
        children: list,
      },
      {
        key: 'archived',
        title: t('workflows.actions.archive'),
        badge: !loading ? String(workflowCounts?.archived ?? 0) : undefined,
        children: list,
      },
    ],
    onSelectionChange: key => {
      newSearchParams.set(
        WorkflowTemplatesQueryProps.archive,
        key === 'archived' ? 'true' : 'false'
      );
      navigate({
        pathname: location.pathname,
        search: newSearchParams.toString(),
      });
    },
    selectedKey: isArchiveActive ? 'archived' : 'active',
  });

  return (
    <SettingsLayout
      actions={<Tabs {...tabsProps} />}
      title={t('workflows.title')}
      width={WORKFlOWS_LIST_WIDTH}>
      <TabPanel {...tabPanelProps} />
      <InfoPanel
        externalLink={t('workflows.infoPanel.externalLink', {
          returnObjects: true,
        })}
        sections={t('workflows.infoPanel.sections', {
          returnObjects: true,
        })}
        title={t('workflows.infoPanel.title')}
      />
      <Match
        paths={[
          pathGenerator.stringify({ workflowId: 'create' }),
          pathGenerator.stringify({ workflowId: ':workflowId' }),
        ]}
        component={WorkflowTemplateDetails}
      />
    </SettingsLayout>
  );
};
