import { mergeProps } from '@candisio/design-system';
import { HookFormTextareaFieldProps } from 'components/HookFormFields/HookFormTextareaField';
import { useDatev } from 'orgConfig/datev';
import { useTranslation } from 'react-i18next';
import { NOTE_FIELD_MAX_LENGTH } from 'views/consts';
import { ProcessingFormValues } from './processingFormSchema';
import { ProcessingFormTextareaField } from './ProcessingFormTextareaField';
import { useProcessingFormField } from './useProcessingFormField';
import { useProcessingFormLengthWarning } from './useProcessingFormLengthWarning';

/** A `ProcessingFormTextareaField` for entering a note */
export const ProcessingFormNoteField = ({
  name,
  readOnly,
  ...restProps
}: HookFormTextareaFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);
  const [t] = useTranslation();
  const { client } = useDatev();
  const maxLength = client ? NOTE_FIELD_MAX_LENGTH : Infinity;
  const { lengthExceeded } = useProcessingFormLengthWarning({
    name: 'bookings.0.note',
    maxLength,
  });

  return (
    <ProcessingFormTextareaField
      name={name}
      warning={
        lengthExceeded
          ? t('document.requestApproval.inputs.note.maxLengthWarning', {
              maxLength,
            })
          : undefined
      }
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
