import { useSapB1CredentialsQuery } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';

export const useGetSapB1Credentials = () => {
  const sap = useSap();
  const { data, loading } = useSapB1CredentialsQuery({
    skip: !sap.isActiveIntegration,
  });

  return { data, loading };
};
