export type SelectionProps = {
  image: string;
  setSelectedType: (type: WIDGET_TYPE) => void;
};

export enum WIDGET_TYPE {
  COST_CENTER = 'COST_CENTER',
  CONTACT = 'CONTACT',
  GENERAL_LEDGER_ACCOUNT = 'GENERAL_LEDGER_ACCOUNT',
}
