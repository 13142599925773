import { Locale } from 'generated-types/graphql.types';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const localeSchema = z.object({
  locale: z.nativeEnum(Locale),
});

export const localeErrorMessages: ErrorMessages<typeof localeSchema> = {
  locale: { label: 'organizations.inputs.locale' },
};
