import { mergeProps } from '@candisio/design-system';
import {
  HookFormUsersField,
  HookFormUsersFieldProps,
} from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

export const ProcessingFormApproversField = ({
  name,
  readOnly,
  isLoading,
  ...restProps
}: HookFormUsersFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormUsersField
      name={name}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
      isLoading={isLoading}
    />
  );
};
