import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z } from 'zod';

const MIN_AMOUNT = 0.01;

const fileSchema = z.object({
  name: z.string(),
  url: z.string(),
  id: z.string(),
});

const baseGeneralExpenseFormSchema = z.object({
  expenseId: z.string().uuid(),
  reason: nonEmptyString,
  totalAmount: z.number().min(MIN_AMOUNT),
  expenseDate: nonEmptyString,
  files: z.array(fileSchema).min(1),
});

const baseGuestsFormSchema = z
  .object({
    internalGuests: z.string().nullish(),
    externalGuests: z.string().nullish(),
  })
  .superRefine((value, context) => {
    const { internalGuests, externalGuests } = value;
    if (!internalGuests && !externalGuests) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['externalGuests'],
      });
      context.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['internalGuests'],
      });
    }
  });

const baseHospitalityFormSchema = z.object({
  expenseId: z.string().uuid(),
  reason: nonEmptyString,
  location: nonEmptyString,
  dayOfExpense: nonEmptyString,
  totalAmount: z.number().min(MIN_AMOUNT),
  receiptAmount: z.number().min(MIN_AMOUNT),
  tipAmount: z.number().min(MIN_AMOUNT).nullish(),
  files: z.array(fileSchema).min(1),
});

const hospitalityExpenseSchema =
  baseHospitalityFormSchema.and(baseGuestsFormSchema);

export const expensesFormSchema = z.object({
  hospitalityExpenses: z.array(hospitalityExpenseSchema),
  generalExpenses: z.array(baseGeneralExpenseFormSchema),
});

export type ExpensesFormOutput = z.infer<typeof expensesFormSchema>;

export type ExpensesFormValues = Partial<ExpensesFormOutput>;

export type BaseHospitalityOutput = z.infer<typeof hospitalityExpenseSchema>;

export type BaseHospitalityValues = Partial<BaseHospitalityOutput>;

export type BaseGeneralExpenseOutput = z.infer<
  typeof baseGeneralExpenseFormSchema
>;
