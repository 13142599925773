import gql from 'graphql-tag';

export const uploadedFilesQuery = gql`
  query fileUploads {
    fileUploads @client {
      id
      fileName
      state
      errorDetails
      documentId
    }
  }
`;

export const uploadStatusQuery = gql`
  query fileUploadModal {
    fileUploadModal @client {
      uploadModalVisible
    }
  }
`;
