import type I18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { languageToMomentLocale } from './update-app-locale';

/** Returns the current locale */
export const useLocale = () => {
  const { i18n } = useTranslation();
  // `i18n` from `useTranslation` hook has wrong type
  const language = (i18n as unknown as I18next.i18n).language;

  return languageToMomentLocale[language];
};
