import {
  Flex,
  Icon,
  Skeleton,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TOOLTIP_DELAY } from '../../utils';
import { LimitTextProps } from './types';

export const AmountLoader = <Skeleton height="2.325rem" width="4.5rem" />;

const LimitTooltip = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
      delay: DEFAULT_TOOLTIP_DELAY,
    });

  return (
    <>
      <Icon
        icon="infoOutline"
        color="gray500"
        size="space16"
        {...triggerProps}
        ref={triggerRef}
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef} width="22rem">
          {t('ccOverviewCard.limitTooltip')}
        </Tooltip>
      )}
    </>
  );
};

export const LimitText = ({ loading, limit }: LimitTextProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);

  return (
    <Flex direction="column">
      <Flex gap="space4" alignItems="center">
        <Text fontSize="basic" fontWeight="regular" color="gray600">
          {t('ccOverviewCard.limit')}
        </Text>
        <LimitTooltip />
      </Flex>
      {loading ? (
        AmountLoader
      ) : (
        <Text fontSize="xxlarge" color="gray500">
          {limit}
        </Text>
      )}
    </Flex>
  );
};
