import { IconKey } from '@candisio/design-system';
import { UserRole } from 'generated-types/graphql.types';

export type ProfileMenuLinkItemRefactored = {
  title: string;
  description: string;
  allowedRoles: UserRole[];
  url?: string;
  hash?: string;
  icon?: IconKey;
  saveQuery?: boolean;
  dataTestId?: string;
};

export const profileMenuLinks: ProfileMenuLinkItemRefactored[] = [
  {
    title: 'header.profile.menu.items.user.title',
    description: 'header.profile.menu.items.user.description',
    hash: '#userProfile',
    allowedRoles: [
      UserRole.Requester,
      UserRole.Approver,
      UserRole.Admin,
      UserRole.Accountant,
    ],
    icon: 'profile',
    dataTestId: 'profile-nav-profile',
    saveQuery: true,
  },
];
