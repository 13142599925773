import { TypePolicies, FieldPolicy } from '@apollo/client';
import { CardIssuerSettlementsConnectionPageBased } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const cardIssuerSettlementsRecords: FieldPolicy<CardIssuerSettlementsConnectionPageBased> =
  {
    keyArgs: ['filters', 'dateRangeFilters', 'sortBy'],
    merge: simplePaginationMergeWithoutDuplicates(
      'CardIssuerSettlementsConnectionPageBased'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      cardIssuerSettlementsPageBased: cardIssuerSettlementsRecords,
    },
  },
};
