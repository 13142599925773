import { mergeProps } from '@candisio/design-system';
import {
  HookFormTextareaField,
  HookFormTextareaFieldProps,
} from 'components/HookFormFields/HookFormTextareaField';
import { useReadOnlyTransactionFormField } from '../hooks/useReadOnlyTransactionFormField';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';

export const ReadOnlyTransactionFormTextareaField = ({
  name,
  ...restProps
}: HookFormTextareaFieldProps<ReadOnlyTransactionFormValues>) => {
  const { fieldProps } = useReadOnlyTransactionFormField(name);

  return (
    <HookFormTextareaField name={name} {...mergeProps(restProps, fieldProps)} />
  );
};
