import { PercentageProgressBar } from 'components/PercentageProgressBar/PercentageProgressBar';
import { determineColor } from './utils';

export const threshold = 75;
export const maximum = 100;
export const ProgressBarWidget = ({ percentage }: { percentage: number }) => {
  return (
    <PercentageProgressBar
      color={determineColor(percentage)}
      percentage={percentage}
    />
  );
};
