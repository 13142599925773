import {
  Box,
  Grid,
  Popover,
  PopoverProps,
  TextField,
  Text,
  Flex,
  Button,
  Spinner,
} from '@candisio/design-system';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { CreditCardsSpentOverviewTrackingEvents } from 'providers/AnalyticsProvider/events/CreditCardsSpentOverviewTrackingEvents';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ccPaginationFiltersHooks } from '../../utils/paginationFilterHooks';
import { WidgetFilter } from '../utils';
import { VirtualizedListBox } from './VirtualizedListBox';

export interface FilterProps extends PopoverProps {
  filterValue: string[];
  onApply: (filters: string[]) => void;
  onReset: () => void;
  filterName: WidgetFilter;
}

const MotionGrid = motion(Grid);

const spinnerVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0.5,
    },
  },
  show: {
    opacity: 1,
    transition: { duration: 0.3, type: 'tween' },
  },
};

const getTrackingEvent = (appliedFilterLabels: string | ReactNode[]) => {
  return {
    [WidgetFilter.cardRefNum]: {
      event:
        CreditCardsSpentOverviewTrackingEvents.SPENDINGS_CHART
          .CREDIT_CARDS_FILTER_APPLIED,
      args: { counterCards: appliedFilterLabels.length },
    },
    [WidgetFilter.category]: {
      event:
        CreditCardsSpentOverviewTrackingEvents.SPENDINGS_CHART
          .CATEGORY_FILTER_APPLIED,
      args: { categoryApplied: appliedFilterLabels },
    },
    [WidgetFilter.merchantName]: {
      event:
        CreditCardsSpentOverviewTrackingEvents.SPENDINGS_CHART
          .MERCHANT_NAME_FILTER_APPLIED,
      args: { merchantNameApplied: appliedFilterLabels },
    },
  };
};

export const Filter = forwardRef<HTMLDivElement, FilterProps>(
  ({ filterValue = [], onApply, onReset, filterName, ...restProps }, ref) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
    const { track } = useAnalytics();
    const [currentFilters, setCurrentFilters] = useState<string[]>(filterValue);
    const [searchStr, setSearchStr] = useState('');

    const usePaginationWithSearchFilter = ccPaginationFiltersHooks[filterName];

    const { filterOptions, handleDebounceSearch, loadMore, loading } =
      usePaginationWithSearchFilter({ filteredValues: filterValue, searchStr });

    const sortedOptions = [
      ...filterOptions.filter(f => filterValue.includes(f.id)),
      ...filterOptions.filter(f => !filterValue.includes(f.id)),
    ];

    const searchInputId = `filter-input-${filterName}`;

    const resetSearch = () => {
      handleDebounceSearch?.('');
    };

    const submitSearch = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (sortedOptions.length !== 1) {
        return;
      }

      onApply([sortedOptions[0].id]);
    };

    const handleApply = () => {
      const appliedFilterLabels = sortedOptions.reduce<string[] | ReactNode[]>(
        (acc, option) => {
          if (currentFilters.includes(option.id)) {
            return [...acc, option.label];
          }

          return acc;
        },
        []
      );

      const trackingEvent = getTrackingEvent(appliedFilterLabels)[filterName];

      track(trackingEvent.event, { ...trackingEvent.args });

      resetSearch();
      onApply(currentFilters);
    };

    const handleReset = () => {
      track(
        CreditCardsSpentOverviewTrackingEvents.SPENDINGS_CHART.FILTER_RESET,
        { filter: filterName }
      );
      resetSearch();
      onReset();
    };

    return (
      <Popover {...restProps} ref={ref} minWidth="300px" padding={0}>
        <Grid height="100%" padding="space16" maxHeight="inherit" gap="space8">
          {handleDebounceSearch ? (
            <Box as="form" onSubmit={submitSearch}>
              <TextField
                flex="none"
                layout="compact"
                id={searchInputId}
                input={{
                  value: searchStr,
                  placeholder: t('filterSearchPlaceholder'),
                  onChange: e => {
                    handleDebounceSearch(e.target.value);
                    setSearchStr(e.target.value);
                  },
                }}
              />
            </Box>
          ) : null}
          {sortedOptions.length === 0 && !loading ? (
            <Text justifySelf="center">
              {t('filterWithPagination.nothingFound')}
            </Text>
          ) : (
            <VirtualizedListBox
              items={sortedOptions}
              aria-labelledby={`filter-${filterName}`}
              sortedOptions={sortedOptions}
              loadMore={loadMore}
              onSetCurrentFilters={setCurrentFilters}
              currentFilters={currentFilters}
            />
          )}
          <Flex justifyContent="space-between">
            <Button variant="tertiary" onClick={handleReset}>
              {t('filterReset')}
            </Button>

            <AnimatePresence initial={false}>
              {loading && (
                <MotionGrid
                  variants={spinnerVariants}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  key="loading-indicator"
                  placeContent="center">
                  <Spinner size="space24" />
                </MotionGrid>
              )}
            </AnimatePresence>

            <Button variant="secondary" onClick={handleApply}>
              {t('filterApply')}
            </Button>
          </Flex>
        </Grid>
      </Popover>
    );
  }
);
