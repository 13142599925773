import {
  CardRequestStatus,
  useGetCountCardRequestsQuery,
} from 'generated-types/graphql.types';

export const counterRequestsVariables = {
  filters: {
    requestStatuses: [CardRequestStatus.Pending],
  },
};

export const useCreditCardRequestCountData = () => {
  const { data, loading } = useGetCountCardRequestsQuery({
    variables: counterRequestsVariables,
  });

  const {
    ChangeCardLimitRequest,
    IssueNewPhysicalCardRequest,
    IssueNewSingleUseVirtualCardRequest,
    IssueNewVirtualCardRequest,
  } = data?.countCardRequests?.details ?? {};

  const pendingCardRequests =
    (IssueNewPhysicalCardRequest?.byStatus?.PENDING ?? 0) +
    (IssueNewSingleUseVirtualCardRequest?.byStatus?.PENDING ?? 0) +
    (IssueNewVirtualCardRequest?.byStatus?.PENDING ?? 0);

  const cardLimitChangeRequests = ChangeCardLimitRequest?.count ?? 0;

  const totalRequests = cardLimitChangeRequests + pendingCardRequests;

  return {
    loading,
    totalRequests,
    pendingCardRequests,
    cardLimitChangeRequests,
  };
};
