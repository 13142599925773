import { ApolloError } from '@apollo/client';
import {
  ImportAndUpdateContactInput,
  useBulkCreateOrUpdateContactsInBackgroundMutation,
  useContactsImportFileUploadUrlMutation,
} from 'generated-types/graphql.types';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useCallback, useState } from 'react';
import { uploadFile } from 'utils/upload';

type UseImportOrUpdateContactsHook = [
  (
    contacts: ImportAndUpdateContactInput[],
    file: File
  ) => Promise<{ importId: string | null }>,
  {
    loading: boolean;
    errors: ApolloError[];
  }
];

export const useImportAndUpdateContacts = (): UseImportOrUpdateContactsHook => {
  const [bulkCreateOrUpdateContactsInBackground] =
    useBulkCreateOrUpdateContactsInBackgroundMutation();

  const [requestFileUploadUrl] = useContactsImportFileUploadUrlMutation();
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [importError, setImportError] = useState<ApolloError | null>(null);

  const executeImport = useCallback(
    async (contacts: ImportAndUpdateContactInput[], file: File) => {
      let importId = null;

      setImportLoading(true);
      setImportError(null);

      try {
        let fileId: string | null = null;
        const { data: requestFileUploadUrlData } = await requestFileUploadUrl({
          variables: {
            fileName: file.name,
            contentType: 'text/csv',
          },
        });

        const uploadOptions =
          requestFileUploadUrlData?.contactsImportFileUploadURL;

        if (uploadOptions && uploadOptions.url) {
          await uploadFile(
            file,
            uploadOptions.postOptions || [],
            uploadOptions.url
          );
          fileId = uploadOptions.id;
        }

        if (fileId) {
          const { data } = await bulkCreateOrUpdateContactsInBackground({
            variables: { input: contacts, fileId },
            refetchQueries: [{ query: getFullOrganizationQuery }],
          });

          if (
            data?.bulkCreateOrUpdateContactsInBackground?.__typename ===
            'ContactsBulkImportRecord'
          ) {
            importId = data?.bulkCreateOrUpdateContactsInBackground.importId;
          }
        }
      } catch (e) {
        setImportError(e as ApolloError);
      } finally {
        setImportLoading(false);
      }

      return { importId };
    },
    [bulkCreateOrUpdateContactsInBackground, requestFileUploadUrl]
  );

  const errors = importError ? [importError] : [];

  return [executeImport, { loading: importLoading, errors }];
};
