import { ScrollBox, mergeProps, mergeRefs } from '@candisio/design-system';
import { forwardRef } from 'react';
import { GridComponents } from 'react-virtuoso';

/** @ts-expect-error TODO: React upgrade props types mismatch */
export const VirtuosoScroller: GridComponents['Scroller'] = forwardRef(
  (
    {
      context,
      style: _, // ignore Virtuoso’s own inline styles
      ...restProps
    },
    forwardedRef
  ) => {
    const { ref, ...scrollerProps } = context.scrollerProps;

    return (
      <ScrollBox
        {...mergeProps(scrollerProps, restProps)}
        height="100%"
        ref={ref ? mergeRefs([ref, forwardedRef]) : forwardedRef}
      />
    );
  }
);
