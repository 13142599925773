import { merge } from 'lodash';
import * as BookingAccount from './BookingAccountPagination';
import * as CardIssuerCards from './CardIssuerCards';
import * as CardIssuerMerchant from './CardIssuerMerchant';
import * as CardIssuerSettlements from './CardIssuerSettlements';
import * as CardIssuerStatement from './CardIssuerStatement';
import * as CardIssuerTransactions from './CardIssuerTransactions';
import * as Contacts from './ContactsPagination';
import * as CostCenter from './CostCenterPagination';
import * as DocumentsListPagination from './DocumentsListPagination';
import * as EcmDocuments from './EcmDocumentsPagination';
import * as EmailInbox from './EmailInboxPagination';
import * as Exports from './Exports';
import * as DocumentFile from './FileDownloadUrl';
import * as FileUploads from './FileUploads';
import * as GoodsReceipts from './GoodsReceipts';
import * as InAppNotifications from './InAppNotifications';
import * as Payments from './Payments';
import * as PurchaseOrders from './PurchaseOrders';
import * as Reimbursements from './Reimbursements';
import * as Roles from './Roles';
import * as TableColumn from './TableColumn';
import * as Teams from './Teams';
import * as VendorsForRecurringPayment from './VendorsForRecurringPayment';

const mergedTypePolicies = merge(
  BookingAccount.bookingAccountPaginationTypePolicy,
  CardIssuerCards.typePolicy,
  CardIssuerMerchant.typePolicy,
  CardIssuerSettlements.typePolicy,
  CardIssuerStatement.typePolicy,
  CardIssuerTransactions.typePolicy,
  CardIssuerTransactions.cardIssuerTransactionsDownloadHistoryTypePolicy,
  Contacts.contactsPaginationTypePolicy,
  Contacts.contactFilterOptionsPaginationTypePolicy,
  CostCenter.costCenterPaginationTypePolicy,
  DocumentsListPagination.typePolicy,
  PurchaseOrders.purchaseOrdersListTypePolicy,
  PurchaseOrders.purchaseOrdersImportHistoryTypePolicy,
  EcmDocuments.typePolicy,
  EmailInbox.emailInboxPaginationTypePolicy,
  Exports.documentsForExportTypePolicy,
  Exports.exportHistoryTypePolicy,
  FileUploads.typePolicies,
  InAppNotifications.typePolicy,
  Payments.payableTypePolicy,
  Payments.paymentTypePolicy,
  Roles.typePolicy,
  Reimbursements.typePolicy,
  TableColumn.typePolicy,
  Teams.typePolicy,
  VendorsForRecurringPayment.typePolicy,
  GoodsReceipts.goodsReceiptListPaginationTypePolicy
);

export const initState = {
  ...FileUploads.initState,
};

export const resolvers = {
  ...FileUploads.resolvers,
};

export const typePolicies = {
  ...mergedTypePolicies,
  ...DocumentFile.FieldPolicy,
};
