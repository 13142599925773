import { UserDropdownItem } from 'components/UserDropdownItem/UserDropdownItem';
import { HookFormUserFieldItem } from './HookFormUsersField';

interface HookFormUsersFieldOptionProps {
  /** User absence information */
  absence?: HookFormUserFieldItem['absence'];
  /** User avatar image URL */
  avatarUrl?: string;
  /** Option text (user name) */
  children: string;
}

/**
 * Individual option in `HookFormUsersField`
 *
 * Displays user avatar, name and optional absence information.
 */
export const HookFormUsersFieldOption = ({
  absence,
  avatarUrl,
  children,
}: HookFormUsersFieldOptionProps) => (
  <UserDropdownItem name={children} avatarUrl={avatarUrl} absence={absence} />
);
