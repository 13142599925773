import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useUpdateProcessingFormField } from 'components/Form/ProcessingForm/useUpdateProcessingFormField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ContactDataFragment,
  useUpdateContactMutation,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { DefaultValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactDetailsProps } from 'views/Contacts/ContactDetails/ContactDetails';
import { ContactFormOutput } from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';
import { getGraphQLValidationErrorMessage } from 'views/Contacts/ContactDetails/ContactForm/getGraphQLValidationErrorMessage';
import { useCheckAccountsNumber } from 'views/Contacts/ContactDetails/useCheckAccountsNumber';
import { useCheckContactName } from 'views/Contacts/ContactDetails/useCheckContactName';
import { useContactFormDefaultValues } from 'views/Contacts/ContactDetails/useContactFormDefaultValues';
import { useContactMutationInput } from 'views/Contacts/ContactDetails/useContactMutationInput';
import { useNextFreeAccountsPayableNumber } from 'views/Contacts/ContactDetails/useNextFreeAccountsPayableNumber';
import { usePaymentConditionItems } from 'views/Contacts/ContactDetails/usePaymentConditionItems';
import { useContactRefetchQueries } from 'views/queries/contacts';
import { PaymentConditionsFormOutput } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm';
import { useCreatePaymentCondition } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/useCreatePaymentCondition';
import { EditContact } from './EditContact';

export interface EditContactContainerProps {
  /** Contact to edit */
  contactId?: string;
  /** Called when contact details drawer should close */
  onClose?: ContactDetailsProps['onClose'];
  /** Called after contact has been modified successfully */
  onEditContact?: (contact: ContactDataFragment) => void;
}

export const EditContactContainer = ({
  onClose,
  contactId: contactIdProp,
  onEditContact,
}: EditContactContainerProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const { isAdmin, isAccountant } = useUserRoles();

  const processingForm = useFormContext<ProcessingFormValues>();

  const processingFormValues = processingForm?.getValues() as
    | DefaultValues<ProcessingFormValues>
    | undefined;

  const updateProcessingFormField = useUpdateProcessingFormField();

  const contactId = contactIdProp ?? processingFormValues?.contact?.value;

  const { queries: contactRefetchQueries, evictPaginationResults } =
    useContactRefetchQueries({ contactId });

  const {
    defaultValues,
    legacyDueDateOffset,
    loading: loadingDefaultValues,
  } = useContactFormDefaultValues({ contactId });

  const [updateContact, { loading: updating }] = useUpdateContactMutation({
    refetchQueries: contactRefetchQueries,
    onCompleted: () => {
      evictPaginationResults();
    },
  });

  const { checkAccountsReceivableNumber, checkAccountsPayableNumber } =
    useCheckAccountsNumber({
      originalPayableValue: defaultValues.accountsPayableNumber,
      originalReceivableValue: defaultValues.accountsReceivableNumber,
      contactId,
    });

  const checkContactName = useCheckContactName({
    originalValue: defaultValues.name,
    contactId,
  });

  const {
    items: paymentConditionItems,
    loading: loadingPaymentConditionItems,
  } = usePaymentConditionItems();

  const {
    nextFreeAccountsPayableNumber,
    loading: loadingNextFreeAccountsPayableNumber,
  } = useNextFreeAccountsPayableNumber();

  const { getMutateContactInput } = useContactMutationInput();

  const handleSubmit = async (formOutput: ContactFormOutput) => {
    if (!contactId) {
      return;
    }

    const input = getMutateContactInput(formOutput, legacyDueDateOffset);

    const { data, errors } = await updateContact({
      variables: { id: contactId, input },
    });

    if (data?.updateContact?.__typename === 'Contact') {
      success(t('settings.contacts.messages.updated'));

      const updatedContact = data.updateContact;

      updateProcessingFormField?.(
        'contact.value',
        updatedContact.id,
        undefined
      );
      updateProcessingFormField?.(
        'contact.inputValue',
        updatedContact.name.value,
        undefined
      );

      onEditContact?.(updatedContact);
      onClose?.();
    } else {
      const validationErrorMessage = getGraphQLValidationErrorMessage(
        errors || []
      );

      error(
        validationErrorMessage ?? t('settings.contacts.messages.updateFailed')
      );
    }
  };

  const { create: createPaymentCondition } = useCreatePaymentCondition();

  const handleCreatePaymentCondition = async (
    values: PaymentConditionsFormOutput
  ) => {
    const result = await createPaymentCondition(values);
    if (result.status === 'success') {
      success(t('settings:paymentConditions.messages.created'));

      return result.id;
    } else {
      error(t('settings:paymentConditions.messages.createFailed'));
    }
  };

  return (
    <EditContact
      contactId={contactId}
      checkContactName={checkContactName}
      checkAccountsPayableNumber={checkAccountsPayableNumber}
      checkAccountsReceivableNumber={checkAccountsReceivableNumber}
      action="edit"
      defaultValues={defaultValues}
      loading={
        loadingDefaultValues ||
        loadingNextFreeAccountsPayableNumber ||
        loadingPaymentConditionItems
      }
      onClose={onClose}
      onCreatePaymentCondition={
        isAdmin || isAccountant ? handleCreatePaymentCondition : undefined
      }
      onSubmit={handleSubmit}
      paymentConditionItems={paymentConditionItems}
      submitting={updating}
      suggestedAccountsPayableNumber={nextFreeAccountsPayableNumber}
    />
  );
};
