import { MessageBox } from '@candisio/design-system';
import { useSap } from 'orgConfig/sap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProcessingFormValues } from './processingFormSchema';

/**
 * Displays a warning if the rounding amount more than 1
 */
export const RoundingAmountWarningMessage = () => {
  const { shouldUseSapNetAmount } = useSap();
  const roundingAmount = useWatch<ProcessingFormValues, 'roundingAmount'>({
    name: 'roundingAmount',
  });

  const [t] = useTranslation();

  const showWarning =
    shouldUseSapNetAmount && roundingAmount && Math.abs(roundingAmount) > 1;

  if (!showWarning) return null;

  return (
    <MessageBox
      variant="warning"
      message={t(
        'document.requestApproval.inputs.roundingAmount.warningMessage'
      )}
    />
  );
};
