import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import { Filters, SortingRule } from 'react-table';
import { ToolbarFilter } from '../CardManagerView/components/CreditCardsTable/CreditCardsTableToolbar';
import { CreditCardsTableData } from '../types';
import { useCardIssuerCardsForCardManagerData } from './useCardIssuerCardsForCardManagerData';
import { useCardIssuerCardsForCarholderData } from './useCardIssuerCardsForCarholderData';

interface UseCardIssuerCardsProps {
  sortBy: SortingRule<CreditCardsTableData>[];
  filters: Filters<CreditCardsTableData>;
  toolbarFilter: ToolbarFilter;
}

export const useCardIssuerCards = ({
  filters,
  sortBy,
  toolbarFilter,
}: UseCardIssuerCardsProps) => {
  const { canManageCards } = usePermissionsForCreditCards();
  const cardsForCardManagerOptions = useCardIssuerCardsForCardManagerData({
    filters,
    sortBy,
    toolbarFilter,
    skip: !canManageCards,
  });

  const cardsForCardholderOptions = useCardIssuerCardsForCarholderData({
    filters,
    sortBy,
    toolbarFilter,
    skip: canManageCards,
  });

  return canManageCards
    ? cardsForCardManagerOptions
    : cardsForCardholderOptions;
};
