import { ErrorMessages } from 'utils/zodFormValidation';
import { MAX_FILE_SIZE } from 'views/Contacts/ContactImport/consts';
import { z } from 'zod';

export const contactImportFormSchema = z.object({
  file: z.object({
    type: z.string().regex(new RegExp('text/csv')),
    size: z.number().lt(MAX_FILE_SIZE),
  }),
});

export type ContactImportFormOutput = z.infer<typeof contactImportFormSchema>;

export type ContactImportFormValues = Partial<ContactImportFormOutput>;

export type ContactImportFormErrorMessages = ErrorMessages<
  typeof contactImportFormSchema
>;
