import { Routes } from 'models';
import { useLocation, useMatch, useNavigate } from 'react-router-dom-v5-compat';
import { ContractCustomSubCategoryDetailsDrawer } from './ContractCustomSubCategoryDetailsDrawer';

export const ContractSubCategoryDetailsRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const match = useMatch(
    `:organizationSlug${Routes.SETTINGS}${Routes.CONTRACT_SUBCATEGORIES}/:id`
  );

  const contractSubCategoryId = match?.params.id;

  return (
    <ContractCustomSubCategoryDetailsDrawer
      contractSubCategoryId={
        contractSubCategoryId !== 'create' ? contractSubCategoryId : undefined
      }
      isOpen={match !== null}
      onCloseDrawer={() => {
        navigate(
          {
            pathname: '..',
            search: location.search,
          },
          {
            relative: 'path',
          }
        );
      }}
    />
  );
};
