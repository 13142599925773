import {
  ExportStatus,
  useExportArchiveDownloadUrlMutation,
  useExportArchiveDownloadUrlsMutation,
} from 'generated-types/graphql.types';
import { useCallback, useContext, useEffect } from 'react';
import { ExportContext } from '../../Context';
import { ExportDetails, Sidebar } from '../../types';
import { FileExportDetails } from './FileExportDetails';

interface FileExportProps {
  exportDetails: ExportDetails;
}

export const FileExport = ({ exportDetails }: FileExportProps) => {
  const state = useContext(ExportContext);

  const [downloadArchiveFile] = useExportArchiveDownloadUrlMutation({
    variables: { id: exportDetails.id },
  });

  const [downloadArchiveFiles] = useExportArchiveDownloadUrlsMutation({
    variables: { id: exportDetails.id },
  });

  const onDownloadArchiveFile = useCallback(async () => {
    const { data: archiveFileData } = await downloadArchiveFile();
    const { data: archiveFilesData } = await downloadArchiveFiles();

    state.setShouldTriggerDownload(false);
    if (
      archiveFilesData &&
      archiveFilesData?.exportArchiveDownloadUrls.length > 0
    ) {
      archiveFilesData.exportArchiveDownloadUrls
        .filter(Boolean)
        .forEach((url, i) => {
          setTimeout(() => window.location.assign(url ?? ''), 1000 * i);
        });
    } else if (archiveFileData?.exportArchiveDownloadUrl) {
      window.location.assign(archiveFileData.exportArchiveDownloadUrl);
    }
  }, [downloadArchiveFile, downloadArchiveFiles, state]);

  const shouldTriggerDownload = state.shouldTriggerDownload;

  useEffect(() => {
    if (
      [ExportStatus.Exported, ExportStatus.PartialyExported].includes(
        exportDetails.status
      ) &&
      shouldTriggerDownload
    ) {
      void onDownloadArchiveFile();
    }
  }, [shouldTriggerDownload, exportDetails.status, onDownloadArchiveFile]);

  return (
    <FileExportDetails
      exportDetails={exportDetails}
      onExportAgain={() => {
        state.setSidebar(Sidebar.INIT_NEW_EXPORT);
        state.setExportType(exportDetails.type);
        state.setIsReExport(true);
      }}
      onDownloadArchive={onDownloadArchiveFile}
    />
  );
};
