import { mergeProps } from '@candisio/design-system';
import {
  HookFormDatePickerField,
  HookFormDatePickerFieldProps,
} from 'components/HookFormFields/HookFormDatePickerField';
import { StorageFormValues } from './storageFormSchema';
import { useStorageFormField } from './useStorageFormField';

export const StorageFormDateField = ({
  name,
  readOnly,
  ...restProps
}: HookFormDatePickerFieldProps<StorageFormValues>) => {
  const { fieldProps } = useStorageFormField(name);

  return (
    <HookFormDatePickerField
      name={name}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
