import { IntegrationName } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';

type UseSapResult = {
  loading: boolean;
  shouldUseSapB1Export: boolean;
  shouldUseSapConfig: boolean;
  shouldUseSapContacts: boolean;
  shouldUseSapCostCenters: boolean;
  shouldUseSapGL: boolean;
  shouldUseSapTaxCodes: boolean;
  shouldUseSapPaymentConditions: boolean;
  shouldUseSapPurchaseOrder: boolean;
  shouldUsePackageFreight: boolean;
  isActiveIntegration: boolean;
  isActive: boolean;
  shouldUseSapNetAmount: boolean;
};

export const useSap = (): UseSapResult => {
  const [
    sapB1ExportFF,
    sapConfigFF,
    sapContactsFF,
    sapCostCentersFF,
    sapGeneralLedgerAccountsFF,
    sapTaxCodesFF,
    sapPaymentConditionsFF,
    sapPurchaseOrderFF,
    sapPackageFreightFF,
    sapNetAmountFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.sapB1Export,
    FEATURE_FLAGS.sapConfig,
    FEATURE_FLAGS.sapContacts,
    FEATURE_FLAGS.sapCostCenters,
    FEATURE_FLAGS.sapGeneralLedgerAccounts,
    FEATURE_FLAGS.sapTaxCodes,
    FEATURE_FLAGS.sapPaymentConditions,
    FEATURE_FLAGS.sapPurchaseOrder,
    FEATURE_FLAGS.sapPackageFreight,
    FEATURE_FLAGS.sapNetAmounts,
  ]);

  const organization = useFullOrganization();
  const { ft, show } = useFeatureToggle();

  if (
    organization?.integrationName !== IntegrationName.Sap ||
    !show(ft.INTEGRATION_SAP)
  ) {
    return {
      loading: false,
      shouldUseSapB1Export: false,
      shouldUseSapConfig: false,
      shouldUseSapContacts: false,
      shouldUseSapCostCenters: false,
      shouldUseSapGL: false,
      shouldUseSapTaxCodes: false,
      shouldUseSapPaymentConditions: false,
      shouldUseSapPurchaseOrder: false,
      shouldUsePackageFreight: false,
      isActive: false,
      isActiveIntegration: false,
      shouldUseSapNetAmount: false,
    };
  }

  return {
    loading: false,
    isActive:
      sapContactsFF &&
      sapCostCentersFF &&
      sapGeneralLedgerAccountsFF &&
      sapTaxCodesFF &&
      sapPaymentConditionsFF,
    isActiveIntegration: true,
    shouldUseSapB1Export: sapB1ExportFF,
    shouldUseSapConfig: sapConfigFF,
    shouldUseSapContacts: sapContactsFF,
    shouldUseSapCostCenters: sapCostCentersFF,
    shouldUseSapGL: sapGeneralLedgerAccountsFF,
    shouldUseSapTaxCodes: sapTaxCodesFF,
    shouldUseSapPaymentConditions: sapPaymentConditionsFF,
    shouldUseSapPurchaseOrder: sapPurchaseOrderFF && show(ft.PURCHASE_ORDERS),
    shouldUsePackageFreight: sapPackageFreightFF,
    shouldUseSapNetAmount: sapNetAmountFF,
  };
};
