import { Comment } from 'components/Comment/Comment';
import { useGetMembershipsQuery } from 'generated-types/graphql.types';

interface CommentFieldContainerProps {
  isSubmitting: boolean;
  onCreateComment: ({
    mention,
    mentionedUserIds,
  }: {
    mention: string | undefined;
    mentionedUserIds: Array<string>;
  }) => Promise<null | undefined>;
}

export const CommentFieldContainer = ({
  onCreateComment,
  isSubmitting,
}: CommentFieldContainerProps) => {
  const { data, loading: loadingMemberships } = useGetMembershipsQuery();

  const { organizationMemberships = [] } = data ?? {};

  return (
    <Comment
      loadingMemberships={loadingMemberships}
      memberships={organizationMemberships}
      onCreateComment={onCreateComment}
      isSubmitting={isSubmitting}
    />
  );
};
