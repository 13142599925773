import { defaultTheme } from '@candisio/design-system';
import dotFilledIcon from 'assets/icon-history-dot-filled.svg';
import dotIcon from 'assets/icon-history-dot.svg';
import { ExportDone } from '../../Icons/ExportDone';
import { BaseItem, BaseItemProps } from './BaseItem';

type Props = BaseItemProps & {
  isFilled?: boolean;
};

const icons: Record<string, JSX.Element> = {
  ExportedEvent: (
    <ExportDone
      fill={defaultTheme.colors.gray500}
      style={{ marginLeft: '1px' }}
    />
  ),
};

export const Neutral = ({ isFilled, children, type, ...rest }: Props) => {
  const customDot = (type && icons[type]) || null;

  return (
    <BaseItem
      src={isFilled ? dotFilledIcon : dotIcon}
      customDot={customDot}
      type={type}
      {...rest}>
      {children}
    </BaseItem>
  );
};
