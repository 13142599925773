import { DATE_RANGE_OPTION } from 'components/Insights/Widgets/types';
import { ReactNode } from 'react';
import {
  WidgetFormAnnualBudget,
  WidgetFormQuarterBudget,
} from './BudgetTab/types';

export type SelectionProps = {
  image: string;
  setSelectedType: (type: WIDGET_TYPE) => void;
};

export enum WIDGET_TYPE {
  COST_CENTER = 'COST_CENTER',
  CONTACT = 'CONTACT',
  GENERAL_LEDGER_ACCOUNT = 'GENERAL_LEDGER_ACCOUNT',
}

export interface WidgetModalContainerProps {
  visible: boolean;
  onCloseWidgetModal: () => void;
}

export type WidgetModalComponentProps = {
  selectedWidgetType: WIDGET_TYPE | null;
  children: ReactNode;
  visible: boolean;
  onCloseWidgetModal: () => void;
  resetModalValues: ({ isCloseModal }: { isCloseModal: boolean }) => void;
};

export interface WidgetFormData
  extends WidgetFormAnnualBudget,
    WidgetFormQuarterBudget {
  contacts: string[];
  costCenters: string[];
  costObjects: string[];
  generalLedgerAccounts: string[];
  sharedWithUsers: string[];
  title: string;
}

export type DateRangeDefinition = {
  type: DATE_RANGE_OPTION;
  values: {
    readable: string;
    dateFrom: string;
    dateTo: string;
    prev?: string;
    next?: string;
  };
};
