import { Tooltip, useTooltip } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';

export const CodeField = ({ disabled }: { disabled: boolean }) => {
  const [t] = useTranslation();
  const { shouldUseSapCostCenters } = useSap();

  const { isOpen, triggerRef, triggerProps, tooltipRef, tooltipProps } =
    useTooltip({
      placement: 'top',
      isDisabled: !shouldUseSapCostCenters,
    });

  return (
    <>
      <div
        ref={triggerRef}
        {...(shouldUseSapCostCenters ? triggerProps : {})}
        data-cy="codeTooltipTrigger">
        <HookFormTextField
          autoFocus
          name="code"
          label={t('settings.costCenter.details.form.code.label')}
          placeholder={t('settings.costCenter.details.form.code.placeholder')}
          readOnly={disabled}
        />
      </div>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('settings.costCenter.readOnlySyncHint')}
        </Tooltip>
      )}
    </>
  );
};
