import { Icon, Tooltip, useTooltip } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface PaymentConditionsStatusIconProps {
  status: 'new' | 'updated' | 'invalid';
}

export const PaymentConditionsStatusIcon = ({
  status,
}: PaymentConditionsStatusIconProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const statusMap = {
    new: {
      icon: 'checkCircle',
      color: 'green700',
      text: t('paymentConditions.import.previewTable.statusIcons.new'),
    },
    updated: {
      icon: 'warning',
      color: 'yellow700',
      text: t('paymentConditions.import.previewTable.statusIcons.updated'),
    },
    invalid: {
      icon: 'failCircle',
      color: 'red700',
      text: t('paymentConditions.import.previewTable.statusIcons.invalid'),
    },
  } as const;

  return (
    <>
      <Icon
        aria-label={statusMap[status].text}
        icon={statusMap[status].icon}
        color={statusMap[status].color}
        size="space20"
        {...triggerProps}
        ref={triggerRef}
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {statusMap[status].text}
        </Tooltip>
      )}
    </>
  );
};
