import { escapeRegex } from 'utils/regex';
import { TransactionDownloadItem } from '../hooks/useTransactionDownloadHistoryData';

export const queryFn = (
  items: TransactionDownloadItem[],
  queryString: string
) => {
  const regExp = new RegExp(escapeRegex(queryString), 'i');

  if (queryString.length === 0) {
    return items;
  }

  return items.filter(item => item.file?.name?.match(regExp));
};
