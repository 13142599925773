import {
  CardIssuerAmountInput,
  CardLimitRenewFrequency,
  IssuePhysicalCardInput,
} from 'generated-types/graphql.types';
import { IssuePhysicalCard } from '../hooks/useIssuePhysicalCard';

export const sanitiseIssuePhysicalCard = ({
  limit,
  cardLabel,
  limitInterval,
  teamMember,
  transactionLimit,
  currentUserId,
  selectedCategory,
  vendorId,
  suggestions,
  accountingData,
  noInvoiceNeeded,
}: IssuePhysicalCard): IssuePhysicalCardInput => {
  const txLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: transactionLimit ?? 0,
  };

  const issuingLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: limit ?? 0,
  };

  const suggestedAmount = {
    value: suggestions?.suggestedAmount ?? 0,
    currency: 'EUR',
  };

  const suggestionValues =
    suggestions?.suggestedAmount ||
    suggestions?.suggestedCardholder?.membershipId
      ? {
          cardholderId: suggestions?.suggestedCardholder?.membershipId,
          limit: suggestions?.suggestedAmount ? suggestedAmount : undefined,
        }
      : undefined;

  const settingsData = {
    isInvoiceNeeded: false,
  };

  return {
    label: cardLabel ?? null,
    limit: issuingLimit,
    transactionLimit: txLimit,
    membershipId: teamMember,
    limitRenewFrequency: limitInterval as CardLimitRenewFrequency,
    category: selectedCategory,
    vendorId,
    acceptedCardAgreement: teamMember === currentUserId ? true : false,
    suggestions: suggestionValues,
    accountingData,
    settingsData: noInvoiceNeeded ? settingsData : undefined,
  };
};
