import { New_DatevDocumentType } from '../../../../generated-types/graphql.types';

const isCashLedger = (type: New_DatevDocumentType) =>
  type.category === 'other_documents';

const hasConfigIsMissing = (l: New_DatevDocumentType) => !l.bookingAccount;

export const isCashLedgersMissingConfig = (
  documentTypes: New_DatevDocumentType[]
) => {
  const cashLedgers = documentTypes.filter(isCashLedger);

  return cashLedgers.length > 0 && cashLedgers.every(hasConfigIsMissing);
};

export const urlParams = {
  datevConnectionType: 'datevConnectionType',
};
