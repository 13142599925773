import { FieldContainerProps } from '@candisio/design-system';
import { ProcessingFormFieldMetadataMessage } from 'components/Form/ProcessingForm/ProcessingFormFieldMetadataMessage';
import { FieldPath } from 'react-hook-form';
import { StorageFormValues } from './storageFormSchema';
import { useStorageFormFieldMetadata } from './useStorageFormFieldMetadata';

/**
 * Returns `fieldProps` to pass to form fields used in storage form based on
 * field metadata
 */
export const useStorageFormField = (name: FieldPath<StorageFormValues>) => {
  const fieldMetadata = useStorageFormFieldMetadata(name);

  // @TODO This should be shared generic component between the storage and
  // processing form.
  const message = fieldMetadata ? (
    <ProcessingFormFieldMetadataMessage {...fieldMetadata} />
  ) : undefined;

  const variant: FieldContainerProps['variant'] | undefined = fieldMetadata
    ? fieldMetadata.confidence < 0.9
      ? 'warning'
      : 'success'
    : undefined;

  const readOnly =
    fieldMetadata !== undefined && fieldMetadata.source === 'transaction';

  return {
    fieldProps: {
      message,
      variant,
      readOnly,
    },
  };
};
