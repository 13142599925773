import {
  DocumentCommentEventDataFragment,
  ActivityUser,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { v4 as uuidV4 } from 'uuid';

interface GetOptimisticCommentProps {
  text: string;
}

export const useOptimisticComment = () => {
  const eventId = uuidV4();
  const commentId = uuidV4();

  const currentUser = useCurrentUser();

  const author: ActivityUser = {
    id: currentUser?.id ?? '',
    name: currentUser?.name ?? '',
    email: currentUser?.email,
    avatarUrl: currentUser?.avatarUrl,
    __typename: 'ActivityUser',
  };

  const commentMetadata = {
    id: eventId,
    createdAt: new Date().toISOString(),
  };

  const createdComment = (text: string): DocumentCommentEventDataFragment => ({
    __typename: 'DocumentCommentEvent',
    user: author,
    clientInfo: null,
    comment: {
      id: commentId,
      text: text,
      author: {
        avatarUrl: currentUser?.avatarUrl,
      },
      __typename: 'ActivityComment',
    },
  });

  const getOptimisticComment = ({ text }: GetOptimisticCommentProps) => ({
    ...createdComment(text),
    ...commentMetadata,
  });

  return { getOptimisticComment };
};
