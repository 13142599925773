import promotionThumbnailDE from 'containers/Entitlements/toolkit/assets/absense-promo-de.svg';
import promotionThumbnailEN from 'containers/Entitlements/toolkit/assets/absense-promo-en.svg';
import contractPromoDE from 'containers/Entitlements/toolkit/assets/contracts-promo-de.svg';
import contractPromoEN from 'containers/Entitlements/toolkit/assets/contracts-promo-en.svg';
import contractVideoTourDE from 'containers/Entitlements/toolkit/assets/contracts-video-tour-de.png';
import contractVideoTourEN from 'containers/Entitlements/toolkit/assets/contracts-video-tour-en.png';
import dmsPromoDE from 'containers/Entitlements/toolkit/assets/dms-promo-de.svg';
import dmsPromoEN from 'containers/Entitlements/toolkit/assets/dms-promo-en.svg';
import dmsVideoTourDE from 'containers/Entitlements/toolkit/assets/dms-video-tour-de.png';
import dmsVideoTourEN from 'containers/Entitlements/toolkit/assets/dms-video-tour-en.png';
import sepaVideoTourDE from 'containers/Entitlements/toolkit/assets/sepa-promo-de.svg';
import sepaVideoTourEN from 'containers/Entitlements/toolkit/assets/sepa-promo-en.svg';
import { FEATURE } from 'providers/FeatureToggleProvider/types';

export const thumbnails = {
  [FEATURE.ABSENCE_MANAGEMENT]: {
    EN: promotionThumbnailEN,
    DE: promotionThumbnailDE,
  },
  [FEATURE.CONTRACTS]: {
    EN: contractPromoEN,
    DE: contractPromoDE,
  },
  [FEATURE.ECM]: {
    EN: dmsPromoEN,
    DE: dmsPromoDE,
  },
} as const;

export const videoTours = {
  [FEATURE.CONTRACTS]: {
    EN: contractVideoTourEN,
    DE: contractVideoTourDE,
  },
  [FEATURE.ECM]: {
    EN: dmsVideoTourEN,
    DE: dmsVideoTourDE,
  },
  [FEATURE.SEPA_XML]: {
    EN: sepaVideoTourEN,
    DE: sepaVideoTourDE,
  },
} as const;
