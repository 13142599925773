import { Skeleton, Text } from '@candisio/design-system';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { History } from '../../../../../../components/History/History';
import { ExportContext } from '../../../Context';
import { useDefineExportView } from '../../../Manifest';
import { useExportableEntitiesCounter } from '../../../toolkit/hooks/useExportableEntitiesCounter';
import { Sidebar, View } from '../../../types';

export const NotReady = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { view, ...state } = useContext(ExportContext);

  const viewDef = useDefineExportView({
    ...state,
    sidebar: Sidebar.NOT_READY,
    view: View.NOT_READY,
  });

  const { loading, notReadyToExportEntitiesCount } =
    useExportableEntitiesCounter({
      fetchPolicy: 'network-only',
    });

  const isActive = (view === View.NOT_READY).toString();

  return (
    <History.Export
      data-cy="history-notReady-item"
      header={t('export.exportHistory.title.notReady')}
      onClick={() => navigate(viewDef.navigate)}
      status="not-ready"
      active={isActive}>
      {loading ? (
        <Skeleton height="15px" width="50%" />
      ) : (
        <Text fontSize="small">
          {t('export.exportHistory.counter.posting', {
            count: notReadyToExportEntitiesCount,
          })}
        </Text>
      )}
    </History.Export>
  );
};
