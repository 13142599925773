import { MAX_DIGITS_DEPRECATED } from './consts';
import { ParsedBookingAccount } from './utils/parseBookingAccountCSV';

export type FormData = {
  selectedFile?: File;
  maxDigitsGeneralLedger: MAX_DIGITS_DEPRECATED;
};

export interface FilterState {
  name: string[] | null;
  accountCode: string[] | null;
}

export enum BOOKING_ACCOUNT_IMPORT_ACTION {
  SET_BOOKING_ACCOUNTS = 'SET_BOOKING_ACCOUNTS',
  SHOW_PREVIEW = 'SHOW_PREVIEW',
  SHOW_CONFIRMATION = 'SHOW_CONFIRMATION',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  BACK = 'BACK',
  RESET = 'RESET',
}

export enum ImportSteps {
  SelectFile,
  Preview,
}

export interface BookingAccountsImportState {
  importStep: ImportSteps;
  parsedBookingAccounts: ParsedBookingAccount[];
  previewFilters: FilterState | null;
  selectedFile: File | null;
  showSummary: boolean;
  showConfirmation: boolean;
}

export type SetBookingAccounts = {
  action: BOOKING_ACCOUNT_IMPORT_ACTION.SET_BOOKING_ACCOUNTS;
  payload: {
    parsedBookingAccounts: ParsedBookingAccount[];
    selectedFile: File;
  };
};

export type ShowPreview = {
  action: BOOKING_ACCOUNT_IMPORT_ACTION.SHOW_PREVIEW;
};

export type ShowConfirmation = {
  action: BOOKING_ACCOUNT_IMPORT_ACTION.SHOW_CONFIRMATION;
};

export type UpdateFilters = {
  action: BOOKING_ACCOUNT_IMPORT_ACTION.UPDATE_FILTERS;
  payload: { filters: FilterState };
};

export type SetImportStep = {
  action: BOOKING_ACCOUNT_IMPORT_ACTION.BACK;
};

export type Reset = {
  action: BOOKING_ACCOUNT_IMPORT_ACTION.RESET;
};

export interface BookingAccountsImportProps {
  errorPreviewDelay?: number;
}
