import { MenuButton } from '@candisio/design-system';
import { InsightsWidgetReportingUnit } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface NetGrossSelectorProps {
  unit: InsightsWidgetReportingUnit;
  updating: boolean;
  onUpdate: (unit: InsightsWidgetReportingUnit) => Promise<void>;
}

const translations: Record<InsightsWidgetReportingUnit, string> = {
  GROSS: 'netGrossSelector.gross',
  NET: 'netGrossSelector.net',
};

export const NetGrossSelector = ({
  unit,
  updating,
  onUpdate,
}: NetGrossSelectorProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const handleChange = async (values: Key[]) => {
    const unit = values[0];
    if (!unit) {
      return;
    }

    await onUpdate(unit as InsightsWidgetReportingUnit);
  };

  const items = useMemo(() => {
    return [
      { id: InsightsWidgetReportingUnit.Net, label: t('netGrossSelector.net') },
      {
        id: InsightsWidgetReportingUnit.Gross,
        label: t('netGrossSelector.gross'),
      },
    ];
  }, [t]);

  return (
    <MenuButton
      variant="secondary"
      loading={updating}
      disabled={updating}
      size="small"
      value={[unit]}
      selectionMode="single"
      onChange={handleChange}
      items={items}>
      {t(translations[unit])}
    </MenuButton>
  );
};
