import { Button, Flex, Grid, Link } from '@candisio/design-system';
import {
  ArchiveButton,
  ReActivateRow,
} from 'components/ArchiveWrapper/ArchiveWrapper';
import { TaxPercentageField } from 'components/Form/TaxCodeForm/toolkit/fields/TaxPercentageField';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { taxCodeSchema, errorMessages } from './schema';
import { DescriptionField } from './toolkit/fields/DescriptionField';
import { TaxCodeField } from './toolkit/fields/TaxCodeField';
import { BookingKeyFormData, BookingKeyFormProps } from './types';

export const TaxCodeForm = ({
  updateStatusLoading,
  onStatusChange,
  hasActiveBookingAccounts,
  goToBookingAccounts,
  onSubmit,
  bookingKey,
  existingTaxCodes,
}: BookingKeyFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const { shouldUseSapTaxCodes } = useSap();

  const shouldUseTaxPercentage = useCandisFeatureFlags(
    FEATURE_FLAGS.bookingKeyTaxPercentage
  );

  const { shouldUseCoreDataApiTaxCodes } = useOtherIntegration();

  const { id, taxCode, description, isArchived, taxPercentage } =
    bookingKey || {};

  const integration = useIntegrationSettings();

  const disabledInputField =
    Boolean(isArchived) ||
    !hasActiveBookingAccounts ||
    shouldUseCoreDataApiTaxCodes;

  const disabledSubmitButton =
    Boolean(isArchived) || shouldUseCoreDataApiTaxCodes;

  const disabledReActivateRow =
    updateStatusLoading || !hasActiveBookingAccounts;

  const showReActivateRow =
    id && isArchived && !shouldUseSapTaxCodes && !shouldUseCoreDataApiTaxCodes;

  const showArchiveButton = id && !isArchived;

  const form = useForm<BookingKeyFormData>({
    defaultValues: {
      taxCode,
      description,
      taxPercentage: taxPercentage ?? undefined,
    },
    mode: 'onTouched',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.TAX_CODES,
      zodSchema: taxCodeSchema({ integration, existingTaxCodes }),
      errorMessages,
    }),
    shouldFocusError: true,
  });

  const handleSubmit = form.handleSubmit(async values => {
    await onSubmit(values);
  });

  return (
    <FormProvider {...form}>
      <form
        data-testid="new-booking-key-form"
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          void handleSubmit(e);
        }}>
        <Flex
          justifyContent="space-between"
          direction="column"
          height="calc(100vh - 120px)">
          {showReActivateRow && (
            <Grid paddingBottom="space32">
              <ReActivateRow
                onRestore={() => onStatusChange(false)}
                isSubmitting={updateStatusLoading}
                disabled={disabledReActivateRow}
              />
            </Grid>
          )}
          <Grid flexGrow={1}>
            <Flex direction="column" gap="space16">
              <TaxCodeField disabled={disabledInputField} />
              <DescriptionField disabled={disabledInputField} />
              {shouldUseTaxPercentage && (
                <TaxPercentageField disabled={disabledInputField} />
              )}
            </Flex>
          </Grid>

          {!shouldUseSapTaxCodes && !shouldUseCoreDataApiTaxCodes && (
            <Flex alignItems="center" justifyContent="space-between">
              {hasActiveBookingAccounts ? (
                <>
                  <Button
                    type="submit"
                    disabled={disabledSubmitButton}
                    data-cy="booking-key-save-button">
                    {t('form.actions.save')}
                  </Button>
                  {showArchiveButton && (
                    <ArchiveButton
                      onStatusChange={onStatusChange}
                      updateStatusLoading={updateStatusLoading}
                    />
                  )}
                </>
              ) : (
                <Grid color="red500" fontSize="small">
                  {t('form.warnings.noBookingAccounts')}
                  <Link external as="button" onClick={goToBookingAccounts}>
                    {t('form.warnings.goToBookingAccounts')}
                  </Link>
                </Grid>
              )}
            </Flex>
          )}
        </Flex>
      </form>
    </FormProvider>
  );
};
