import { Grid, Icon, IconProps } from '@candisio/design-system';
import { ReactNode } from 'react';

export type ToastVariant = 'success' | 'info' | 'error' | 'warning';

export const variantToIconMap: Record<
  ToastVariant,
  {
    icon: IconProps['icon'];
    color: IconProps['color'];
  }
> = {
  success: {
    icon: 'checkCircle',
    color: '#52c41a',
  },
  info: {
    icon: 'infoCircle',
    color: '#1890ff',
  },
  warning: {
    icon: 'warning',
    color: '#faad14',
  },
  error: {
    icon: 'failCircle',
    color: '#ff4d4f',
  },
};

export const ToastWrapper = ({
  children,
  variant,
}: {
  children: ReactNode;
  variant: ToastVariant;
}) => {
  const icon = <Icon size="space20" {...variantToIconMap[variant]} />;

  return (
    <Grid
      autoFlow="column"
      gap="space4"
      justifyContent="center"
      alignItems="center"
      fontSize="basic">
      {icon}
      <span>{children}</span>
    </Grid>
  );
};
