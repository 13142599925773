import { Flex } from '@candisio/design-system';
import { HookFormNumberField } from 'components/HookFormFields/HookFormNumberField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useWatchFormValues } from '../hooks/useWatchFormValues';
import { WarningBox } from '../WarningBox/WarningBox';
import { years } from './AnnualOption.helper';

export const AnnualOption = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { hasCompleteWidgetDefinition } = useWatchFormValues();

  if (!hasCompleteWidgetDefinition) {
    return <WarningBox />;
  }

  return (
    <>
      {years.map(year => (
        <Flex gap="space4" direction="column" width="space128" key={year.key}>
          <HookFormNumberField
            label={t(year.labelTag)}
            placeholder={t(year.placeholder)}
            name={year.key}
            formatOptions={{
              currency: 'EUR',
              style: 'currency',
              useGrouping: false,
            }}
          />
        </Flex>
      ))}
    </>
  );
};
