import { useToastMessage } from 'components/Toast/useToastMessage';
import { FocusEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const INITIAL_COUNTER_VALUE = 60;
const COUNTER_TIMEOUT = 1000;

const useCounter = (initialValue: number, timeout: number) => {
  const [counter, setCounter] = useState(initialValue);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(prevCounter => (prevCounter > 0 ? prevCounter - 1 : 0));
    }, timeout);

    return () => clearInterval(interval);
  }, [timeout]);

  return counter;
};

export const useApiAuthCode = () => {
  const counter = useCounter(INITIAL_COUNTER_VALUE, COUNTER_TIMEOUT);
  const { success, error: errorToast } = useToastMessage();
  const params = new URLSearchParams(window.location.search);

  const [t] = useTranslation();

  const authCode = params.get('code');
  const error = params.get('error');
  const errorDescription = params.get('error_description');

  const handleFocus: FocusEventHandler<HTMLTextAreaElement> = event => {
    event.target.select();
  };

  const handleCopy = () => {
    if (authCode?.length) {
      void navigator.clipboard.writeText(authCode);
      success(t('common:apiAuthCodePage.copyToClipboard.successMessage'));
    } else {
      errorToast(t('common:apiAuthCodePage.copyToClipboard.errorMessage'));
    }
  };

  const authCodeExpired = counter === 0;

  const showError = Boolean(error || errorDescription || authCodeExpired);
  const showQueryStingError = Boolean(error || errorDescription);

  return {
    counter,
    params,
    authCode,
    handleCopy,
    handleFocus,
    error,
    errorDescription,
    authCodeExpired,
    showError,
    showQueryStingError,
  };
};
