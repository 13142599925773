import { Box, Button, Flex, Grid, ScrollBox } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface SecondaryLayoutDrawerProps {
  onGoBack: () => void;
  children?: ReactNode;
  footer?: ReactNode;
}

export const SecondaryLayoutDrawer = ({
  onGoBack,
  footer,
  children,
}: SecondaryLayoutDrawerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <Grid templateRows="auto 1fr" height="100%">
      <Flex padding="space16 space40 space8" justifySelf="start">
        <Button
          icon="arrowLeft"
          iconPosition="left"
          variant="tertiary"
          onClick={onGoBack}>
          {t('teams.editTeam.teamDetails.backButton')}
        </Button>
      </Flex>
      <ScrollBox height="100%">
        <Grid height="100%" templateRows="1fr auto">
          <Box padding="space16 space40" height="100%">
            {children}
          </Box>
          {footer !== undefined && (
            <Box
              padding="space16 space40"
              background="white"
              position="sticky"
              bottom={0}
              zIndex={1}>
              {footer}
            </Box>
          )}
        </Grid>
      </ScrollBox>
    </Grid>
  );
};
