import {
  useShowCreateTransferField,
  useShowSwiftCodeField,
} from 'views/utils/useShouldShowField';
import { CreateTransfer } from '../toolkit/fields/CreateTransfer';
import { IBAN } from '../toolkit/fields/IBAN';
import { SwiftCode } from '../toolkit/fields/SwiftCode';
import { useShowCreditMemoFieldsDocumentApprovalsForm } from '../toolkit/hooks/useShouldShowField';

export const BankingDetails = () => {
  const showCreditMemoFieldsDocumentApprovalsForm =
    useShowCreditMemoFieldsDocumentApprovalsForm();

  const showSwiftCodeField = useShowSwiftCodeField();
  const showCreateTransferField = useShowCreateTransferField();

  return (
    <>
      {showCreditMemoFieldsDocumentApprovalsForm && <IBAN />}
      {showCreditMemoFieldsDocumentApprovalsForm && showSwiftCodeField && (
        <SwiftCode />
      )}
      {showCreditMemoFieldsDocumentApprovalsForm && showCreateTransferField && (
        <CreateTransfer />
      )}
    </>
  );
};
