import { FieldPolicy, TypePolicies } from '@apollo/client';
import { PaymentsResponse } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const paymentsHistoryPagination: FieldPolicy<PaymentsResponse> = {
  merge: simplePaginationMergeWithoutDuplicates('PaymentsResponse'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      payments: paymentsHistoryPagination,
    },
  },
};
