import { Flex, Grid, ScrollBox, Text } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { Member } from '../../toolkit/hooks/useGetTeams';

interface HiddenMembersProps {
  hiddenMembers: Member[];
}

export const HiddenMembers = ({ hiddenMembers }: HiddenMembersProps) => {
  return (
    <ScrollBox paddingBottom="space24" maxHeight="inherit" height="inherit">
      <Grid gap="space4">
        {hiddenMembers.map((member, index) => (
          <Flex alignItems="center" gap="space4" key={index}>
            <AvatarWithStatusContainer
              name={member.fullName}
              size="xsmall"
              img={member.avatarUrl}
              userId={member.id}
              hasTooltip
            />
            <Text>{member.fullName}</Text>
          </Flex>
        ))}
      </Grid>
    </ScrollBox>
  );
};
