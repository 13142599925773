import {
  ReimbursementCase,
  ReimbursementCaseDataFragment,
  ReimbursementCaseError,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import DateFormatters from 'utils/date_formatter';

export interface Reimbursement {
  id: string;
  title?: string;
  createTransfer: boolean;
  createdAt: string;
  isPaid: boolean;
  note?: string;
  organizationId: string;
  paidAt?: string;
  totalAmount: string;
  status: ReimbursementCaseStatus;
  errors: ReimbursementCaseError[];
  createdByMembership: {
    id: string;
    fullName: string;
    avatarUrl?: string;
  };
  targetMembership?: {
    id: string;
    fullName: string;
    avatarUrl?: string;
  };
  suggestedCostCenter?: {
    id: string;
    readableName: string;
  };
}

export const useFormattedReimbursement = () => {
  const { documentMoneyFormatter } = useMoneyFormatter();

  const reimbursement = (
    data: ReimbursementCase | ReimbursementCaseDataFragment
  ): Reimbursement => ({
    id: data.id,
    organizationId: data.organizationId,
    createTransfer: data.createTransfer,
    errors: data.errors,
    isPaid: data.isPaid,
    status: data.status,
    title: data.title ?? undefined,
    note: data.note ?? undefined,
    createdAt: DateFormatters.compact(new Date(data.createdAt)),
    paidAt: data.paidAt
      ? DateFormatters.compact(new Date(data.paidAt))
      : undefined,
    totalAmount: documentMoneyFormatter({
      amount: data.totalAmount.amount,
      currency: data.totalAmount.currency,
      precision: data.totalAmount.precision,
    }),
    createdByMembership: {
      id: data.createdByMembership.id,
      fullName: `${data.createdByMembership.firstName} ${data.createdByMembership.lastName}`,
      avatarUrl: data.createdByMembership.avatarUrl ?? undefined,
    },
    targetMembership: data.targetMembership
      ? {
          id: data.targetMembership.id,
          fullName: `${data.targetMembership.firstName} ${data.targetMembership.lastName}`,
          avatarUrl: data.targetMembership.avatarUrl ?? undefined,
        }
      : undefined,
    suggestedCostCenter: data.suggestedCostCenter
      ? {
          id: data.suggestedCostCenter.id,
          readableName: data.suggestedCostCenter.readableName,
        }
      : undefined,
  });

  return reimbursement;
};
