import { useRestorePaymentConditionMutation } from 'generated-types/graphql.types';
import { paymentConditionsCountQuery, paymentConditionsQuery } from '../gql';

export interface UseRestorePaymentConditionOptions {
  id?: string;
}

type RestoreStatus = { status?: 'success' | 'error' };

export interface UseRestorePaymentConditionReturn {
  restore: () => Promise<RestoreStatus>;
  restoring: boolean;
}

/**
 * Returns a callback that restores a payment condition
 */
export const useRestorePaymentCondition = ({
  id,
}: UseRestorePaymentConditionOptions): UseRestorePaymentConditionReturn => {
  const [restorePaymentCondition, { loading: restoring }] =
    useRestorePaymentConditionMutation({
      refetchQueries: [
        {
          query: paymentConditionsQuery,
          variables: { filter: { isArchived: true } },
        },
        {
          query: paymentConditionsQuery,
          variables: { filter: { isArchived: false } },
        },
        {
          query: paymentConditionsCountQuery,
          variables: { filter: { isArchived: true } },
        },
        {
          query: paymentConditionsCountQuery,
          variables: { filter: { isArchived: false } },
        },
      ],
    });

  return {
    restore: async () => {
      if (id === undefined) {
        return { status: 'error' };
      }

      const { data } = await restorePaymentCondition({ variables: { id } });
      if (data?.unarchivePaymentCondition.__typename === 'PaymentCondition') {
        return { status: 'success' };
      }

      return { status: 'error' };
    },
    restoring,
  };
};
