import { Locale, User } from 'generated-types/graphql.types';
import {
  DEFAULT_INTERCOM_COOKIES_ENABLED,
  DEFAULT_TRACKING_ENABLED,
} from 'views/AppContainer/components/ConsentManager/PrivacyConsentManager';
import { generateId } from 'views/Integrations/Export/__tests__/factories/valueObjects';
import { getAvailableRoles } from 'views/Settings/TeamMembers/utils';

export const createUser = ({ user }: { user: Partial<User> }): User => {
  return {
    id: generateId(),
    locale: Locale.En,
    avatarUrl: 'test.png',
    firstName: 'first',
    email: 'devtools@candis.io',
    name: 'first last',
    lastName: 'last',
    username: 'devtools@candis.io',
    createdTimestamp: new Date('2020-01-01T00:00Z').toString(),
    roles: getAvailableRoles({
      isCreditCardsInUse: false,
    }),
    membershipRoles: [],
    trackingConfiguration: {
      trackingEnabled: DEFAULT_TRACKING_ENABLED,
      intercom: DEFAULT_INTERCOM_COOKIES_ENABLED,
    },
    preferences: {
      tables: [],
    },
    ...user,
  };
};
