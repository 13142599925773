import { Box, Grid, ScrollBox } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { TransactionDetailsCardContextMenu } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCardContextMenu';
import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import { DocumentSummarySection } from 'containers/document-summary/DocumentSummarySection';
import {
  DocumentStatus,
  EcmDocumentStatus,
  GetDocumentForDraftQuery,
  Money,
  useGetDocumentFileQuery,
  useGetDocumentForDraftQuery,
  useUnlinkTransactionFromDocumentMutation,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useAttachments } from 'hooks/useCanAddAttachments/useAttachments';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useFetchDocNavigationData } from 'hooks/useFetchDocNavigationData/useFetchDocNavigationData';
import { useUserRoles } from 'hooks/useUserRoles';
import { AppRouteParams, DocumentProcessingRouteParams, Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useSap } from 'orgConfig/sap';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TransactionsTrackingEvents } from 'providers/AnalyticsProvider/events/TransactionsTrackingEvents';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useLocation, useParams } from 'react-router-dom';
import {
  generatePath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { DocumentSummaryInformation } from 'views/Inbox/DocumentProcessing/components/DocumentSummaryInformation';
import {
  LeftSectionContentWrapper,
  LeftSectionOuterWrapper,
} from 'views/Inbox/DocumentProcessing/LeftSection';
import { getDocumentQuery } from 'views/Inbox/DocumentProcessing/queries';
import { usePrefetchPdfData } from 'views/Inbox/DocumentProcessing/util/usePrefetchPdfData';
import { documentHistoryQuery } from 'views/queries';
import { getCardIssuerTransactionById } from 'views/TransactionAssociation/gql';
import { userCanApprove, userIsMonitoring } from '../Approvals';
import { Approved } from './components/Approved/Approved';
import { Archived } from './components/Archived/Archived';
import { Exported } from './components/Exported/Exported';
import { Monitoring } from './components/Monitoring';
import { New } from './components/New';
import { Open } from './components/Open';
import { PurchaseOrderSectionContainer } from './components/PurchaseOrderSection/PurchaseOrderSectionContainer';
import { Rejected } from './components/Rejected';
import { TransactionSection } from './components/TransactionSection';

interface RouteParams {
  [AppRouteParams.organizationSlug]: string;
  documentId: string;
  type: Routes;
}

const editableStatuses = [
  DocumentStatus.New,
  DocumentStatus.Open,
  DocumentStatus.Approved,
  DocumentStatus.Rejected,
] as const;

export const DocumentDetails = () => {
  const [t] = useTranslation();
  const showGqlError = useShowError();
  const { success, error } = useToastMessage();

  const currentUser = useCurrentUser();
  const creditCardsSetup = useCreditCardsSetup();
  const { isOnlyApprover } = useUserRoles();
  const { shouldUseSapPurchaseOrder } = useSap();

  const { organizationSlug, documentId } = useParams<RouteParams>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { track } = useAnalytics();

  const [contactItem, setContactItem] =
    useState<ProcessingFormContactFieldItem>();

  const { data, loading } = useGetDocumentForDraftQuery({
    variables: {
      id: documentId,
    },
  });

  const document = data?.getDocument;
  const documentStatus = document?.status ?? EcmDocumentStatus.New;
  const documentAmount = document?.amount?.value ?? undefined;
  const documentCurrency = document?.currency?.value ?? undefined;
  const transactions = data?.getDocument?.transactions;
  const transaction = transactions?.[0];
  const transactionId = transaction?.id;
  const purchaseOrderId = data?.getDocument?.purchaseOrderData?.purchaseOrderId;
  const contactName =
    data?.getDocument?.contact?.value.name?.value ??
    data?.getDocument?.extractedContact?.name?.value;

  const accountsPayableNumber =
    data?.getDocument?.contact?.value?.accountsPayableNumber;

  const { data: documentData, loading: isDocumentFileLoading } =
    useGetDocumentFileQuery({
      variables: { id: documentId },
      // Document file URL expires after 12 hours but the user’s session can
      // last much longer. If we use the default `cache-first` fetch policy we
      // might try to download the PDF from an expired URL.
      //
      // We use `cache-and-network` instead so that we can still show the PDF
      // viewer immediately but then rerender if the URL has changed.
      fetchPolicy: 'cache-and-network',
    });

  const isTransactionEditable: boolean =
    !!document?.status && editableStatuses.includes(document.status as any);

  const canAddTransaction =
    creditCardsSetup.isInUse && isTransactionEditable && !transaction;

  const toggleEditMode = () => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.INBOX}/:${DocumentProcessingRouteParams.documentId}`,
      { organizationSlug, documentId }
    );

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('isArchived', '1');

    navigate({ pathname, search: newSearchParams.toString() });
  };

  const currentDocumentCursor = searchParams.get('cursor');
  const isInvoice = searchParams.get('isInvoice');

  const {
    prevDocumentId,
    prevDocumentLink,
    nextDocumentId,
    nextDocumentLink,
    linkBackToList,
    navigationLoading,
  } = useFetchDocNavigationData({
    cursor: currentDocumentCursor,
    route: isInvoice ? Routes.ECM_DOCUMENTS : Routes.ARCHIVE,
    organizationSlug,
  });

  const gotoPreviousDocument = () => {
    if (prevDocumentLink) {
      navigate(prevDocumentLink);
    }
  };

  const gotoNextDocument = () => {
    if (nextDocumentLink) {
      navigate(nextDocumentLink);
    }
  };

  const redirectTo = (url: string) => {
    navigate(url);
  };

  // If there's no next document and no previous document mark as all Done!
  const cycleDocument = () => {
    if (nextDocumentLink) {
      gotoNextDocument();
    } else if (prevDocumentLink) {
      gotoPreviousDocument();
    } else {
      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}`,
        { organizationSlug }
      );

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('isArchived', '1');

      navigate({
        pathname,
        search: newSearchParams.toString(),
      });
    }
  };

  usePrefetchPdfData({ nextDocumentId, prevDocumentId });

  const handleGetContactItem = useCallback(
    (value?: ProcessingFormContactFieldItem) => {
      setContactItem(value);
    },
    []
  );

  const componentArgs = {
    key: documentId,
    redirectTo,
    organizationSlug,
    loading,
    document,
    prevId: prevDocumentId,
    nextId: nextDocumentId,
    toggleEditMode,
    gotoNextDocument,
    gotoPreviousDocument,
    cycleDocument,
    purchaseOrderId,
    contactItem,
    onGetContactItem: handleGetContactItem,
  };

  const renderComponent = (doc?: GetDocumentForDraftQuery['getDocument']) => {
    if (!doc) {
      return null;
    }

    if (
      doc.status === DocumentStatus.Open &&
      (userIsMonitoring(doc, currentUser) || !userCanApprove(doc, currentUser))
    ) {
      return <Monitoring {...componentArgs} />;
    } else if (doc.status === DocumentStatus.New) {
      return <New {...componentArgs} />;
    } else if (doc.status === DocumentStatus.Open) {
      return <Open {...componentArgs} />;
    } else if (doc.status === DocumentStatus.Approved) {
      return <Approved {...componentArgs} isLoading={loading} />;
    } else if (doc.status === DocumentStatus.Exported) {
      return <Exported {...componentArgs} isLoading={loading} />;
    } else if (doc.status === DocumentStatus.Rejected) {
      return <Rejected {...componentArgs} />;
    } else if (doc.status === DocumentStatus.Archived) {
      return <Archived {...componentArgs} isLoading={loading} />;
    }

    return null;
  };

  const mainDocumentFile: PDFDetails = useMemo(
    () => ({
      name: documentData?.getDocument?.documentFile?.name ?? '',
      url: documentData?.getDocument?.documentFile?.url ?? '',
      id: documentData?.getDocument?.documentFile?.id ?? documentId,
      size: documentData?.getDocument?.documentFile?.size ?? 0,
    }),
    [documentData, documentId]
  );

  const documentAttachments = documentData?.getDocument?.attachments;

  const { attachPermissions, attachments, selectedPdf, setSelectedPdf } =
    useAttachments({
      documentFile: mainDocumentFile,
      documentId,
      documentAttachments,
    });

  const [
    unlinkTransactionFromDocumentMutation,
    { loading: isUnlinkingLoading },
  ] = useUnlinkTransactionFromDocumentMutation();

  const counterQueries = useCounterQueries();

  const unlinkTransaction = async () => {
    try {
      if (!transaction?.id) {
        return;
      }

      const { data, errors } = await unlinkTransactionFromDocumentMutation({
        variables: { transactionId: transaction.id, documentId },
        refetchQueries: [
          { query: getDocumentQuery, variables: { id: documentId } },
          { query: documentHistoryQuery, variables: { id: documentId } },
          {
            query: getCardIssuerTransactionById,
            variables: { id: transaction.id },
          },
          ...counterQueries,
        ],
      });

      if (data?.unlinkTransactionFromDocument) {
        success(
          t('transactions:transactionDetailsCardContextMenu.successMessage')
        );

        track(
          TransactionsTrackingEvents.YES_UNLINK_TRANSACTION_FROM_DOCUMENT_CLICKED,
          {
            transactionId: transaction.id,
            documentId,
          }
        );
      } else if (errors?.length) {
        error(t('transactions:transactionDetailsCardContextMenu.errorMessage'));
      }
    } catch (err) {
      showGqlError(err as GQLError);

      return validationErrorsExtractor(err);
    }
  };

  const { state } = useLocation<{ from: string; search: string }>();

  const goBackToList = () => {
    if (state?.from) {
      return navigate({
        pathname: state.from,
        search: state.search,
      });
    }

    navigate(linkBackToList);
  };

  const isDocumentOpen = document?.status === DocumentStatus.Open;
  const { showDocumentRelations } = useEcm();

  const hasDocumentAmount =
    documentCurrency !== undefined && documentAmount !== undefined;

  const amount = useMemo(
    () =>
      !hasDocumentAmount
        ? null
        : ({
            __typename: 'Money',
            amount: documentAmount,
            currency: documentCurrency,
            precision: 2,
          } satisfies Money),
    [documentAmount, documentCurrency, hasDocumentAmount]
  );

  const showTransactionSection =
    !showDocumentRelations && creditCardsSetup.isInUse && !purchaseOrderId;

  const showPurchaseOrderSection = shouldUseSapPurchaseOrder && !transactionId;

  return (
    <DetailsLayout
      key={documentId}
      leftSection={
        <LeftSectionOuterWrapper>
          <ListNavigator
            backToListText={t('document.backToDocumentList')}
            arrowLeftTooltip={t('document.previousDocument')}
            arrowRightTooltip={t('document.nextDocument')}
            onBackToList={goBackToList}
            onNext={nextDocumentLink ? gotoNextDocument : undefined}
            onPrev={prevDocumentLink ? gotoPreviousDocument : undefined}
            loading={navigationLoading}
          />
          <LeftSectionContentWrapper>
            {/*
            // cursed: Windows Chrome renders scrollbars outside component's border
            // we add static ScrollBox to reserve same gutter space as for
            // scrollable DocumentSummarySection 
            */}
            <ScrollBox scrollDirection="none" scrollbarGutter="stable">
              <DocumentSummaryInformation
                documentId={documentId}
                amount={amount}
                invoiceDate={document?.invoiceDate?.value}
                invoiceNumber={document?.invoiceNumber?.value}
                documentStatus={document?.status}
                contactName={contactName}
                selectedContactEmail={contactItem?.email}
                isLoading={isDocumentFileLoading}
                documentFile={mainDocumentFile}
                attachments={attachments}
                attachPermissions={attachPermissions}
                onAttachmentClick={setSelectedPdf}
                selectedPdf={selectedPdf}
              />
            </ScrollBox>
            <ScrollBox scrollDirection="y" scrollbarGutter="stable">
              <Grid gap="space8">
                {showPurchaseOrderSection && (
                  <PurchaseOrderSectionContainer
                    loading={loading}
                    documentId={documentId}
                    documentStatus={document?.status}
                    documentAmount={document?.amount?.value}
                    contactName={
                      isDocumentOpen ? contactName : contactItem?.children
                    }
                    accountsPayableNumber={
                      isDocumentOpen
                        ? accountsPayableNumber
                        : contactItem?.accountsPayableNumber
                    }
                  />
                )}
                {showTransactionSection && (
                  <TransactionSection
                    loading={loading}
                    documentId={documentId}
                    transaction={dataToTransactionDetails(transaction)}
                    canAddTransaction={canAddTransaction}
                    contextMenu={
                      isTransactionEditable &&
                      !isOnlyApprover &&
                      document?.status && (
                        <TransactionDetailsCardContextMenu
                          documentStatus={document.status}
                          onUnlinkTransaction={unlinkTransaction}
                          isUnlinkPending={isUnlinkingLoading}
                        />
                      )
                    }
                  />
                )}
                <DocumentSummarySection
                  documentId={documentId}
                  documentName={mainDocumentFile.name}
                  canAddTransaction={canAddTransaction}
                  documentStatus={documentStatus}
                />
              </Grid>
            </ScrollBox>
          </LeftSectionContentWrapper>
        </LeftSectionOuterWrapper>
      }
      middleSection={
        <Box
          height="100%"
          width="100%"
          paddingX="space16"
          paddingTop="space32"
          overflowY="auto">
          <DocumentViewer
            documentId={documentId}
            documentFile={mainDocumentFile}
            selectedFile={selectedPdf}
            attachments={attachments}
            onSelectDocument={setSelectedPdf}
            canAttachFiles={attachPermissions}
            isLoadingPdf={isDocumentFileLoading}
          />
        </Box>
      }
      rightSection={renderComponent(document)}
    />
  );
};
