import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ReactNode, useEffect, useState } from 'react';
import { SplashScreen } from 'views/AppContainer/components/SplashScreen/index';
import { useCurrentUser } from '../CurrentUserProvider';

const PRIVATE_ATTRIBUTES = ['avatar', 'email', 'name'];

const { REACT_APP_LAUNCH_DARKLY_CLIENT_ID: launchDarklyClientId } = import.meta
  .env;

interface LDProviderProps {
  children?: ReactNode;
}

export const LDProvider = ({ children }: LDProviderProps) => {
  const ldClient = useLDClient();
  const currentUser = useCurrentUser();
  const currentOrg = useOrganizationId();
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (ldClient) {
      if (currentUser && currentOrg) {
        const ldUser: LDSingleKindContext = {
          kind: 'user',
          key: currentUser.id,
          email: currentUser.email,
          name: currentUser.name,
          organization: currentOrg,
          avatar: currentUser.avatarUrl ? currentUser.avatarUrl : undefined,
          _meta: {
            privateAttributes: PRIVATE_ATTRIBUTES,
          },
        };

        ldClient.identify(ldUser).finally(() => setIsInitialized(true));
        if (currentUser.id === (ldClient.getContext() || {}).key) {
          ldClient
            .waitForInitialization()
            .finally(() => setIsInitialized(true));
        }
      }
    }
  }, [currentUser, currentOrg, ldClient, setIsInitialized]);

  return isInitialized ? <>{children}</> : <SplashScreen />;
};

export const FeatureFlagProvider = withLDProvider({
  clientSideID: launchDarklyClientId as string,
  options: { bootstrap: 'localStorage' },
  reactOptions: { useCamelCaseFlagKeys: false },
})(LDProvider);
