import { Grid, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { documenterrorData, LottieAnimation } from '../../Lottie/Lottie';

export const PdfViewerError = () => {
  const [t] = useTranslation();

  return (
    <Grid height="100%" placeContent="center">
      <LottieAnimation options={{ animationData: documenterrorData }} />
      <Text color="red500" justifySelf="center">
        {t('document.downloadFailed')}
      </Text>
    </Grid>
  );
};
