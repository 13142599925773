import { Button, Grid, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface PendingStatusLegacyRolesBarProps {
  disabled: boolean;
  sendInviteLoading: boolean;
  onSendInviteAgain: () => void;
  onCancelInvite: () => void;
  cancelInviteLoading: boolean;
}

export const PendingStatusLegacyRolesBar = ({
  disabled,
  sendInviteLoading,
  onSendInviteAgain,
  onCancelInvite,
  cancelInviteLoading,
}: PendingStatusLegacyRolesBarProps) => {
  const [t] = useTranslation();

  return (
    <Grid borderRadius="medium" padding="space16" background="bluebg">
      <Text fontSize="basic" fontWeight="semibold">
        {t('settings.teamMembers.form.invitationProgress')}
      </Text>
      <Text fontSize="small">
        {t('settings.teamMembers.form.invitationProgressDescription')}
      </Text>
      <Grid
        justifyContent="end"
        paddingTop="space16"
        gap="space8"
        templateColumns="repeat(2, max-content)">
        <Button
          variant="tertiary"
          color="red"
          onClick={onCancelInvite}
          loading={cancelInviteLoading}
          disabled={disabled}>
          {t('settings.teamMembers.form.actions.cancelInvite')}
        </Button>
        <Button
          loading={sendInviteLoading}
          disabled={disabled}
          type="button"
          color="blue"
          onClick={onSendInviteAgain}>
          {t('settings.teamMembers.form.actions.sendAgain')}
        </Button>
      </Grid>
    </Grid>
  );
};
