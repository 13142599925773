import {
  Flex,
  Grid,
  Icon,
  Text,
  Modal,
  Separator,
  Image,
  Link,
  Card,
  Button,
  Box,
  useTheme,
} from '@candisio/design-system';
import creditCards from 'containers/credit-cards/components/CreditCardsSection/media/mini card.png';
import {
  RouteTypeProps,
  useButtonEventTracker,
} from 'containers/credit-cards/components/CreditCardsSection/utils';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { blogArticleManifest, displayText } from './utils';

export const demoLinks: Record<RouteTypeProps, string> = {
  Archive: 'ccInformationalCard.demoLink.archive',
  Dashboard: 'ccInformationalCard.demoLink.dashboard',
  Export: 'ccInformationalCard.demoLink.export',
  Inbox: 'ccInformationalCard.demoLink.inbox',
  Settings: 'ccInformationalCard.demoLink.settings',
};

export const CreditCardInfoModal = ({
  isOpen,
  onClose,
  routeType,
}: {
  isOpen: boolean;
  onClose: () => void;
  routeType: RouteTypeProps;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const { CCNotAdoptedEventTracker } = useButtonEventTracker();

  const { colors } = useTheme();

  const handleClick = () => {
    CCNotAdoptedEventTracker[routeType].secondaryBtn();
  };

  return (
    <Modal
      width="835px"
      background="gray200"
      title={t('ccInformationalCard.creditCardInfoModal.title')}
      isOpen={isOpen}
      closeLabel={t('ccInformationalCard.creditCardInfoModal.close')}
      onClose={onClose}>
      <Grid gap="space24">
        <Grid
          alignItems="center"
          autoFlow="column"
          gap="space32"
          width="fit-content">
          <Image src={creditCards} alt="cards" height="190px" />
          <Flex direction="column" gap="space10" alignItems="start">
            {displayText.map(({ text }, index) => {
              return (
                <Grid
                  key={index}
                  gap="space8"
                  justifyContent="center"
                  templateColumns="1fr auto">
                  <Icon color="blue500" size="space24" icon="check" />
                  <Text fontSize="basic" color="gray800">
                    {t(text)}
                  </Text>
                </Grid>
              );
            })}
          </Flex>
        </Grid>
        <Separator />
        <Flex gap="space10" alignItems="start" paddingBottom="space16">
          {blogArticleManifest.map(({ blogLink, thumbnail }, index) => {
            return (
              <Link
                key={index}
                fontSize="basic"
                color="gray"
                href={t(blogLink)}
                external>
                <Box paddingBottom="space8">
                  <Image alt="blog thumbnail" src={thumbnail} height="140px" />
                </Box>
                {t('ccInformationalCard.creditCardInfoModal.linkTitle')}
              </Link>
            );
          })}
        </Flex>
        <Card background="purplebg">
          <Grid autoFlow="column" gap="space16" alignItems="center">
            <Text fontSize="basic" color="gray800">
              {t('ccInformationalCard.creditCardInfoModal.footNote')}
            </Text>
            <Button
              as="a"
              color="purple"
              target="_blank"
              style={{ color: colors.gray0 }}
              onClick={handleClick}
              href={t(demoLinks[routeType])}>
              {t('ccInformationalCard.creditCardInfoModal.footerCta')}
            </Button>
          </Grid>
        </Card>
      </Grid>
    </Modal>
  );
};
