import { Avatar, Flex, TruncatedText } from '@candisio/design-system';
import { CardholderMembership } from 'generated-types/graphql.types';
import { getCardholderName } from '../../../../../../../containers/credit-cards/utils';

export const CardholderWithStatusCell = ({
  value,
}: {
  value: Pick<
    CardholderMembership,
    'avatarUrl' | 'firstName' | 'lastName' | 'membershipEmail' | 'membershipId'
  >;
}) => {
  if (!value) {
    return null;
  }

  const cardholderName = getCardholderName(value);

  return (
    <Flex alignItems="center" justifySelf="center" gap="space8">
      <Avatar
        flex="none"
        img={value.avatarUrl ?? undefined}
        name={cardholderName}
        size="small"
        hasTooltip
      />
      <TruncatedText>{cardholderName}</TruncatedText>
    </Flex>
  );
};
