import approvalsDE from '../../locales/de/approvals.json';
import archiveDE from '../../locales/de/archive.json';
import bookingAccountsDE from '../../locales/de/booking-accounts.json';
import bookingKeysDE from '../../locales/de/booking-keys.json';
import cashDiscountsDE from '../../locales/de/cash-discounts.json';
import commonDE from '../../locales/de/common.json';
import consentManagerDE from '../../locales/de/consent-manager.json';
import contactsDE from '../../locales/de/contacts.json';
import coreDataApiDE from '../../locales/de/core-data-api.json';
import creditCardsDE from '../../locales/de/credit-cards.json';
import dashboardDE from '../../locales/de/dashboard.json';
import documentsTableDE from '../../locales/de/documents-table.json';
import ecmDE from '../../locales/de/ecm.json';
import entitlementsDE from '../../locales/de/entitlements.json';
import errorsDE from '../../locales/de/errors.json';
import exportsDE from '../../locales/de/exports.json';
import inboxDE from '../../locales/de/inbox.json';
import insightsDE from '../../locales/de/insights.json';
import navigationDE from '../../locales/de/navigation.json';
import notificationsDE from '../../locales/de/notifications.json';
import paymentsDE from '../../locales/de/payments.json';
import promotionsDE from '../../locales/de/promotions.json';
import purchaseOrdersDE from '../../locales/de/purchase-orders.json';
import qaUtilsDE from '../../locales/de/qa-utils.json';
import reimbursementDE from '../../locales/de/reimbursement.json';
import sapDE from '../../locales/de/sap.json';
import settingsDE from '../../locales/de/settings.json';
import splitBookingsDE from '../../locales/de/split-bookings.json';
import tableDE from '../../locales/de/table.json';
import transactionsDE from '../../locales/de/transactions.json';

import approvalsEN from '../../locales/en/approvals.json';
import archiveEN from '../../locales/en/archive.json';
import bookingAccountsEN from '../../locales/en/booking-accounts.json';
import bookingKeysEN from '../../locales/en/booking-keys.json';
import cashDiscountsEN from '../../locales/en/cash-discounts.json';
import commonEN from '../../locales/en/common.json';
import consentManagerEN from '../../locales/en/consent-manager.json';
import contactsEN from '../../locales/en/contacts.json';
import coreDataApiEN from '../../locales/en/core-data-api.json';
import creditCardsEN from '../../locales/en/credit-cards.json';
import dashboardEN from '../../locales/en/dashboard.json';
import documentsTableEN from '../../locales/en/documents-table.json';
import ecmEN from '../../locales/en/ecm.json';
import entitlementsEN from '../../locales/en/entitlements.json';
import errorsEN from '../../locales/en/errors.json';
import exportsEN from '../../locales/en/exports.json';
import inboxEN from '../../locales/en/inbox.json';
import insightsEN from '../../locales/en/insights.json';
import navigationEN from '../../locales/en/navigation.json';
import notificationsEN from '../../locales/en/notifications.json';
import paymentsEN from '../../locales/en/payments.json';
import promotionsEN from '../../locales/en/promotions.json';
import purchaseOrdersEN from '../../locales/en/purchase-orders.json';
import qaUtilsEN from '../../locales/en/qa-utils.json';
import reimbursementEN from '../../locales/en/reimbursement.json';
import sapEN from '../../locales/en/sap.json';
import settingsEN from '../../locales/en/settings.json';
import splitBookingsEN from '../../locales/en/split-bookings.json';
import tableEN from '../../locales/en/table.json';
import transactionsEN from '../../locales/en/transactions.json';

type Languages = 'en' | 'de';
type Namespaces = Record<LOCALE_NAME_SPACE, object>;

export enum LOCALE_NAME_SPACE {
  APPROVALS = 'approvals',
  ARCHIVE = 'archive',
  BOOKING_ACCOUNTS = 'booking-accounts',
  TAX_CODES = 'booking-keys',
  CORE_DATA_API = 'core-data-api',
  CASH_DISCOUNTS = 'cash-discounts',
  COMMON = 'common',
  CONSENT_MANAGER = 'consent-manager',
  CONTACTS = 'contacts',
  CREDIT_CARDS = 'credit-cards',
  DASHBOARD = 'dashboard',
  DOCUMENTS_TABLE = 'documents-table',
  ECM = 'ecm',
  ENTITLEMENTS = 'entitlements',
  ERRORS = 'errors',
  EXPORTS = 'exports',
  INBOX = 'inbox',
  INSIGHTS = 'insights',
  NAVIGATION = 'navigation',
  NOTIFICATIONS = 'notifications',
  PAYMENTS = 'payments',
  PURCHASE_ORDERS = 'purchase-orders',
  QA_UTILS = 'qa-utils',
  REIMBURSEMENT = 'reimbursement',
  SAP = 'sap',
  SETTINGS = 'settings',
  SPLIT_BOOKINGS = 'split-bookings',
  TABLE = 'table',
  TRANSACTIONS = 'transactions',
  PROMOTIONS = 'promotions',
}

export const resources: Record<Languages, Namespaces> = {
  de: {
    'booking-accounts': bookingAccountsDE,
    'booking-keys': bookingKeysDE,
    'core-data-api': coreDataApiDE,
    'cash-discounts': cashDiscountsDE,
    'consent-manager': consentManagerDE,
    'credit-cards': creditCardsDE,
    'documents-table': documentsTableDE,
    'purchase-orders': purchaseOrdersDE,
    'qa-utils': qaUtilsDE,
    'split-bookings': splitBookingsDE,
    approvals: approvalsDE,
    archive: archiveDE,
    common: commonDE,
    contacts: contactsDE,
    dashboard: dashboardDE,
    ecm: ecmDE,
    entitlements: entitlementsDE,
    exports: exportsDE,
    errors: errorsDE,
    inbox: inboxDE,
    insights: insightsDE,
    navigation: navigationDE,
    notifications: notificationsDE,
    payments: paymentsDE,
    reimbursement: reimbursementDE,
    sap: sapDE,
    settings: settingsDE,
    table: tableDE,
    transactions: transactionsDE,
    promotions: promotionsDE,
  },
  en: {
    'booking-accounts': bookingAccountsEN,
    'booking-keys': bookingKeysEN,
    'core-data-api': coreDataApiEN,
    'cash-discounts': cashDiscountsEN,
    'consent-manager': consentManagerEN,
    'credit-cards': creditCardsEN,
    'documents-table': documentsTableEN,
    'purchase-orders': purchaseOrdersEN,
    'qa-utils': qaUtilsEN,
    'split-bookings': splitBookingsEN,
    approvals: approvalsEN,
    archive: archiveEN,
    common: commonEN,
    contacts: contactsEN,
    dashboard: dashboardEN,
    ecm: ecmEN,
    entitlements: entitlementsEN,
    exports: exportsEN,
    errors: errorsEN,
    inbox: inboxEN,
    insights: insightsEN,
    navigation: navigationEN,
    notifications: notificationsEN,
    payments: paymentsEN,
    reimbursement: reimbursementEN,
    sap: sapEN,
    settings: settingsEN,
    table: tableEN,
    transactions: transactionsEN,
    promotions: promotionsEN,
  },
};
