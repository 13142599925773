import { Paragraph, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import { DocumentPaymentInfoUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { friendlyFormatIBAN } from 'ibantools';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { MetaDataWrapper } from '../MetaDataWrapper';

interface FormattedUpdatedFieldProps {
  name: string;
  value: string | boolean;
}

const FormattedUpdatedField = ({ name, value }: FormattedUpdatedFieldProps) => {
  const fieldValue =
    typeof value === 'boolean' ? (
      <Trans
        i18nKey={`document.compact.history.metaExtracted.${value.toString()}`}
      />
    ) : (
      value
    );

  return (
    <div>
      <Text fontWeight="regular" color="gray500">
        {name}
      </Text>
      <Paragraph>{fieldValue}</Paragraph>
    </div>
  );
};

export const DocumentPaymentInfoUpdated = ({
  updatedPaymentFields,
}: DocumentPaymentInfoUpdatedEventDataFragment) => {
  const fieldCount = updatedPaymentFields ? updatedPaymentFields.length : 0;
  const [t] = useTranslation();

  const title = t('document.compact.history.documentPaymentInfoUpdated.title', {
    count: fieldCount,
  });

  return (
    <>
      <Text fontSize="small" lineHeight="paragraph">
        {title}
      </Text>
      <Collapse>
        <MetaDataWrapper>
          {updatedPaymentFields &&
            updatedPaymentFields.map(field => {
              switch (field.__typename) {
                case 'UpdatedIBANField':
                  if (field.iban) {
                    const formattedIban = friendlyFormatIBAN(field.iban);

                    if (formattedIban) {
                      return (
                        <FormattedUpdatedField
                          key="iban"
                          name={t(
                            'document.compact.history.documentPaymentInfoUpdated.labels.iban'
                          )}
                          value={formattedIban}
                        />
                      );
                    }
                  }

                  return (
                    <FormattedUpdatedField
                      key="iban"
                      name={t(
                        'document.compact.history.documentPaymentInfoUpdated.labels.iban'
                      )}
                      value={t(
                        'document.compact.history.documentPaymentInfoUpdated.labels.ibanRemoved'
                      )}
                    />
                  );

                case 'UpdatedSwiftCodeField':
                  if (field.swiftCode) {
                    return (
                      <FormattedUpdatedField
                        key="swiftCode"
                        name={t(
                          'document.compact.history.documentPaymentInfoUpdated.labels.swiftCode'
                        )}
                        value={field.swiftCode}
                      />
                    );
                  }

                  return (
                    <FormattedUpdatedField
                      key="swiftCode"
                      name={t(
                        'document.compact.history.documentPaymentInfoUpdated.labels.swiftCode'
                      )}
                      value={t(
                        'document.compact.history.documentPaymentInfoUpdated.labels.swiftCodeRemoved'
                      )}
                    />
                  );

                case 'UpdatedCreateTransferField':
                  return (
                    <FormattedUpdatedField
                      key="createTransfer"
                      name={t(
                        'document.compact.history.documentPaymentInfoUpdated.labels.createTransfer'
                      )}
                      value={field.createTransfer}
                    />
                  );

                case 'UpdatedPaymentStateField':
                  return (
                    <FormattedUpdatedField
                      key="paymentStatus"
                      name={t(
                        'document.compact.history.documentPaymentInfoUpdated.labels.paymentStatus'
                      )}
                      value={
                        field.paymentState?.isPaid
                          ? field.paymentState.paidAt
                            ? t(
                                'document.compact.history.documentPaymentInfoUpdated.labels.paidWithDate',
                                {
                                  date: DateFormatters.compact(
                                    new Date(field.paymentState.paidAt)
                                  ),
                                }
                              )
                            : t(
                                'document.compact.history.documentPaymentInfoUpdated.labels.paidWithoutDate'
                              )
                          : t(
                              'document.compact.history.documentPaymentInfoUpdated.labels.unpaid'
                            )
                      }
                    />
                  );
                default:
                  return null;
              }
            })}
        </MetaDataWrapper>
      </Collapse>
    </>
  );
};
