import { Box, Button, Grid, Image, Modal, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import mobileAppPromotionModalBackground from './media/mobile-app-promotion-modal-background.png';

export interface MobileAppPromotionModalProps {
  onCancel: () => void;
  visible?: boolean;
  qrCode: string;
}
export const MobileAppPromotionModal = ({
  onCancel,
  visible = false,
  qrCode,
}: MobileAppPromotionModalProps) => {
  const [tCommon] = useTranslation();

  return (
    <Modal
      onClose={onCancel}
      isOpen={visible}
      padding="0"
      width="520px"
      height="460px">
      <Grid height="230px">
        <Box position="relative" height="230px" width="100%">
          <Image
            src={mobileAppPromotionModalBackground}
            alt={tCommon('mobileAppPromotion.modal.backgroundImageAlt')}
            position="absolute"
            style={{
              borderRadius: '8px 8px 0 0', // todo: remove this when DS supports borderRadius on Image
            }}
            top="0"
            left="0"
            width="100%"
            height="230px"
          />
          <Button
            icon="close"
            position="absolute"
            top="space12"
            label={tCommon('common.close')}
            right="space12"
            variant="tertiary"
            onClick={onCancel}
          />
        </Box>
        <Grid
          position="relative"
          height="230px"
          width="100%"
          justifyContent="center"
          alignItems="center"
          padding="space32"
          gap="space32"
          borderRadius="0 0 8px 8px"
          background="gray200"
          templateColumns="1fr 2fr">
          <Image
            style={{
              borderRadius: '8px', // todo: remove this when DS supports borderRadius on Image
            }}
            width="150px"
            height="150px"
            src={qrCode}
            alt={tCommon('mobileAppPromotion.modal.qrCodeAlt')}
          />
          <Grid width="260px" fontSize="basic" gap="space8">
            <Text fontSize="xlarge" fontWeight="semibold">
              {tCommon('mobileAppPromotion.modal.title')}
            </Text>
            <Text>{tCommon('mobileAppPromotion.modal.description')}</Text>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
