import { Flex, Text, Icon, Grid, Image } from '@candisio/design-system';
import { PromotionDetail } from 'components/NewPromotions/types';
// import { WistiaVideo } from 'components/WistiaVideo/WistiaVideo';

export const PromotionBenefitsSection = ({
  title,
  benefits,
  imageUrl,
}: // wistiaId,
{
  title: PromotionDetail['title'];
  benefits: PromotionDetail['benefits'];
  imageUrl: PromotionDetail['imageUrl'];
  // wistiaId: string;
}) => {
  return (
    <Flex direction="column" padding="space32">
      <Text fontSize="xxlarge" fontWeight={400} width="20rem">
        {title}
      </Text>

      <Flex direction="column" justifyContent="space-between" height="100%">
        <Grid
          columns={2}
          gap="space10"
          justifyContent="center"
          padding="space32 0">
          {benefits?.map((benefit, index) => (
            <Flex
              justifyContent="flex-start"
              alignItems="baseline"
              background="purplebg"
              padding="space16"
              direction="column"
              border="1px solid purple100"
              borderRadius="medium"
              gap="space8"
              key={benefit.title}>
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                gap="space8">
                <Flex
                  alignItems="center"
                  background="purple100"
                  padding="space5"
                  borderRadius="small">
                  <Icon icon="check" color="purple700" size="space16" />
                </Flex>
                <Flex key={benefit.title} direction="column">
                  <Text fontWeight={500} fontSize="basic">
                    {benefit.title}
                  </Text>
                </Flex>
              </Flex>
              <Text fontWeight={400} fontSize="basic" color="gray500">
                {benefit.description}
              </Text>
            </Flex>
          ))}
        </Grid>

        <Flex
          position="relative"
          justifyContent="center"
          alignItems="center"
          height="100%">
          {/* <WistiaVideo wistiaId={wistiaId} width="100%" /> */}
          <Image
            src={imageUrl}
            width="30rem"
            alt="Image Hannah"
            height="auto"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
