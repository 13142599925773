import { Routes } from 'models';
import { useLocation, useSearchParams } from 'react-router-dom-v5-compat';
import { CONTACT_SEARCH_PARAM } from 'views/Contacts/types';

export const useIsContactsPageWithArchivedTab = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isArchived = new URLSearchParams(searchParams).get(
    CONTACT_SEARCH_PARAM.isArchived
  );

  return location.pathname.includes(Routes.CONTACTS) && isArchived === 'true';
};
