import {
  FieldContainer,
  FieldContainerProps,
  NumberInput,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { identity } from 'lodash';
import { FocusEventHandler, ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useLocale } from 'utils/useLocale';

export interface HookFormNumberFieldProps<TFormValues extends FieldValues>
  extends Omit<FieldContainerProps, 'onChange'> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Should field focus on mount? */
  autoFocus?: boolean;
  /** Is field disabled? */
  disabled?: boolean;
  /** Number format options */
  formatOptions?: Intl.NumberFormatOptions;
  /** Field label */
  label?: string | ReactNode;
  /** Maximum allowed value */
  maxValue?: number;
  /** Minimum allowed value */
  minValue?: number;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Called when field value changes */
  onChange?: (newValue: number | null) => void;
  /** Transform functions for field input (`value`) and output (`onChange`) */
  transform?: {
    input: (value: number | null) => number | null;
    output: (value: number | null) => number | null;
  };
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** For Test Selection */
  dataCy?: string;
  /** For tooltip text */
  info?: ReactNode;
}

/**
 * Controlled number field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormNumberField = <TFormValues extends FieldValues>({
  autoFocus,
  control,
  disabled,
  formatOptions,
  label,
  maxValue,
  message,
  minValue,
  name,
  onChange: onChangeProp,
  placeholder,
  readOnly: readOnlyProp,
  transform = { input: identity, output: identity },
  variant,
  isLoading,
  dataCy,
  info,
  ...restProps
}: HookFormNumberFieldProps<TFormValues>) => {
  const locale = useLocale();

  const { field, fieldState, formState } = useController({ control, name });

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  const { onChange, value, ...fieldProps } = field;

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  const handleFocus: FocusEventHandler<HTMLInputElement> = event => {
    event.target.select();
  };

  return (
    <FieldContainer
      disabled={disabled}
      label={label}
      readOnly={readOnly}
      variant={hasError ? 'error' : variant}
      isLoading={isLoading}
      {...(mergeProps(labelProps, restProps) as Omit<
        FieldContainerProps,
        'color'
      >)}>
      <NumberInput
        autoFocus={autoFocus}
        disabled={disabled}
        formatOptions={formatOptions}
        locale={locale}
        maxValue={maxValue}
        minValue={minValue}
        onChange={newValue => {
          const transformedValue = transform.output(newValue);
          onChange(transformedValue);
          onChangeProp?.(transformedValue);
        }}
        placeholder={readOnly ? '–' : placeholder}
        readOnly={readOnly}
        value={transform.input(value)}
        message={errorMessage ?? message}
        variant={hasError ? 'error' : variant}
        showMessageOnFocus={hasError || variant === 'error'}
        onFocus={handleFocus}
        data-cy={dataCy}
        {...mergeProps(labelFieldProps, fieldProps)}
      />
      {info}
    </FieldContainer>
  );
};
