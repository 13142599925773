import { extractCardLimits } from 'containers/credit-cards/CreditCardCarousel/utils';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardStatus } from 'generated-types/resolvers-types';
import { isNil } from 'lodash';
import { amountFormat } from 'utils/format';
import { ActiveCardDetails } from './ActiveCardDetails';
import { CardToLinkDetailsLoading } from './CardToLinkDetailsLoading';
import { NotActionableCardDetails } from './NotActionableCardDetails';

interface CardToLinkDetailsProps {
  cardId: string;
}

export const CardToLinkDetails = ({ cardId }: CardToLinkDetailsProps) => {
  const { card, loading } = useGetCardById({
    cardId,
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <CardToLinkDetailsLoading />;
  }

  if (!card) {
    return null;
  }

  const { limit, availableBalance } = extractCardLimits(card);
  const cardStatus = card.status;

  if (!limit.value || isNil(availableBalance.value)) {
    return null;
  }

  const formattedAvailableBalance = amountFormat(
    availableBalance.value,
    availableBalance.currency,
    {
      minimumFractionDigits: 0,
      convertToBasicMonetaryUnit: true,
    }
  );

  const formattedLimit = amountFormat(limit.value, limit.currency, {
    minimumFractionDigits: 0,
    convertToBasicMonetaryUnit: true,
  });

  const percentage = (availableBalance.value / limit.value) * 100;

  if (
    cardStatus === CardStatus.Terminated ||
    cardStatus === CardStatus.Expired
  ) {
    return (
      <NotActionableCardDetails
        formattedAvailableBalance={formattedAvailableBalance}
        formattedLimit={formattedLimit}
      />
    );
  }

  return (
    <ActiveCardDetails
      formattedAvailableBalance={formattedAvailableBalance}
      formattedLimit={formattedLimit}
      percentage={percentage}
    />
  );
};
