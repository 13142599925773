import gql from 'graphql-tag';

export const bookingAccountData = gql`
  fragment BookingAccountData on BookingAccount {
    id
    createdBy {
      id
      name
      avatarUrl
    }
    type
    accountCode
    name
    readableName
    createdAt
    isArchived
  }
`;

export const bookingAccountInfiniteScrollPagination = gql`
  query bookingAccountInfiniteScrollPagination(
    $input: BookingAccountPaginationInput
    $filters: BookingAccountFilterInput
    $queries: BookingAccountQueryInput
    $sortBy: BookingAccountSortInput
  ) {
    bookingAccountPagination(
      input: $input
      filters: $filters
      queries: $queries
      sortBy: $sortBy
    )
      @connection(
        key: "bookingAccountInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        ...BookingAccountData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${bookingAccountData}
`;
