import gql from 'graphql-tag';

export const getCountExportableEntities = gql`
  query getCountExportableEntities {
    getCountExportableEntities {
      documentReadyForExportCount {
        count
      }
      transactionReadyForExportCount {
        count
      }
      pendingTransactionCount {
        count
      }
      settlementsReadyForExportCount {
        count
      }
      settlementsNotReadyForExportCount {
        count
      }
      provisionsReadyForExportCount {
        count
      }
      reversalsReadyForExportCount {
        count
      }
    }
  }
`;

export const countDocumentsByStatusNew = gql`
  query countDocumentsByStatusNew {
    countDocumentsByStatus(status: NEW) {
      count
    }
  }
`;

export const countDocumentsByStatusRejected = gql`
  query countDocumentsByStatusRejectedForSidebar {
    countDocumentsByStatus(status: REJECTED) {
      count
    }
  }
`;

export const countDocumentsByStatusApproved = gql`
  query countDocumentsByStatusApprovedForApprovalBlock {
    countDocumentsByStatus(status: APPROVED) {
      count
    }
  }
`;

export const countDocumentsIRejected = gql`
  query countDocumentsIRejected {
    countDocumentsIRejected {
      count
    }
  }
`;

export const countOpenApprovals = gql`
  query countOpenApprovals {
    countOpenApprovals {
      count
    }
  }
`;

export const countPayableDocuments = gql`
  query countPayableDocuments($hasIban: Boolean) {
    countPayableDocuments(hasIban: $hasIban) {
      count
    }
  }
`;

export const countCardIssuerTransactions = gql`
  query countCardIssuerTransactions(
    $input: CardIssuerTransactionCountInput!
    $filters: CardIssuerTransactionFilterInput
  ) {
    countCardIssuerTransactions(input: $input, filters: $filters) {
      count
    }
  }
`;
