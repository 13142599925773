import {
  FieldContainer,
  FieldContainerProps,
  Flex,
  MenuButton,
  Input as TextInput,
  mergeRefs,
} from '@candisio/design-system';
import AllFlags from 'country-flag-icons/react/3x2';
import { AsYouType } from 'libphonenumber-js';
import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { getCountryName } from 'utils/get-country-name';
import { formatPhoneNumber } from './formatPhoneNumber';
import { defaultDialingCode, useDialingCodes } from './useDialingCodes';

export interface PhoneNumberFieldProps<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Should field receive focus on mount? */
  autoFocus?: boolean;
  /** Field label for the phone number input */
  label?: string;
  /** Label for the dialing code drop down */
  dialingCodeLabel?: string;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Custom props for input */
  inputProps?: React.ComponentProps<typeof TextInput>;
  /** FieldContainer layout */
  layout?: FieldContainerProps['layout'];
}

export const phoneNumberPlaceholder = `+${defaultDialingCode.code}`;

export const PhoneNumberField = <TFormValues extends FieldValues>({
  name,
  autoFocus,
  control,
  dialingCodeLabel: dialingLabelProp,
  disabled,
  inputProps,
  label,
  layout,
  placeholder,
  readOnly: readOnlyProp,
}: PhoneNumberFieldProps<TFormValues>) => {
  const {
    field,
    fieldState,
    formState: { isSubmitting },
  } = useController<TFormValues>({ control, name });

  const { onChange, value, ...fieldProps } = field;

  const phoneNumberInputRef = useRef<HTMLInputElement>(null);

  const { dialingCode, dialingCodes, setDialingCode } = useDialingCodes({
    phoneNumber: value,
    onChangePhoneNumber: onChange,
    phoneNumberInputRef,
  });

  // We want to auto focus the field after the first render only to ensure the
  // field is registered with react-hook-form before we call onChange in the
  // onFocus handler, otherwise the change won't cause a rerender and we won't
  // see the value in the form field.
  useEffect(() => {
    if (autoFocus) {
      phoneNumberInputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocus: FocusEventHandler<HTMLInputElement> = event => {
    event.target.select();
    if (!value) {
      onChange(phoneNumberPlaceholder);
      setDialingCode(defaultDialingCode);
    }
  };

  const handleBlur = () => {
    if (value === phoneNumberPlaceholder) {
      onChange(null);
      setDialingCode(null);
    }

    fieldProps.onBlur();
  };

  const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ev => {
      const phoneNumber = new AsYouType();
      phoneNumber.input(ev.target.value);

      const processedValue = ev.target.value !== '' ? ev.target.value : null;
      onChange(processedValue);

      const dialingCode = phoneNumber.getCallingCode();
      const dialingCountry = phoneNumber.getCountry();

      if (dialingCode && dialingCountry) {
        setDialingCode({
          code: dialingCode,
          country: dialingCountry,
        });
      }
    },
    [onChange, setDialingCode]
  );

  const FlagIcon = dialingCode?.country
    ? AllFlags[dialingCode.country]
    : AllFlags[defaultDialingCode.country];

  const dialingCodeLabel = dialingCode?.country
    ? getCountryName(dialingCode.country)
    : dialingLabelProp;

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || isSubmitting;

  return (
    <FieldContainer
      label={label}
      disabled={disabled}
      readOnly={readOnly}
      variant={hasError ? 'error' : 'default'}
      layout={layout}>
      <Flex gap="space4">
        <MenuButton
          gridRow="1"
          gridColumn="1/2"
          variant="tertiary"
          selectionMode="single"
          size="xsmall"
          label={dialingCodeLabel}
          items={dialingCodes}>
          <Flex height="space16">
            <FlagIcon style={{ height: '100%' }} />
          </Flex>
        </MenuButton>

        <TextInput
          style={{
            gridRow: '1',
            gridColumn: '2/2',
          }}
          placeholder={placeholder ?? phoneNumberPlaceholder}
          {...fieldProps}
          onChange={handleOnChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value ? formatPhoneNumber(value) ?? '' : ''}
          message={errorMessage}
          variant={hasError ? 'error' : undefined}
          ref={mergeRefs([fieldProps.ref, phoneNumberInputRef])}
          {...inputProps}
        />
      </Flex>
    </FieldContainer>
  );
};
