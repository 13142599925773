import { Link, Text } from '@candisio/design-system';
import { FileAttachedToDocumentEventDataFragment } from 'generated-types/graphql.types';
import { Trans } from 'providers/LocaleProvider';

export const FileAttachedComponent = ({
  file,
  sinceDetachedOrDeleted,
  originalDocumentId,
}: FileAttachedToDocumentEventDataFragment) => {
  const fileName = file?.name ?? undefined;
  const fileUrl = file?.url ?? undefined;

  const i18nKey = originalDocumentId
    ? 'document.compact.history.documentAttachment.attachedFromDocument'
    : 'document.compact.history.documentAttachment.attachedFromFile';

  if (sinceDetachedOrDeleted) {
    return (
      <Trans parent={Text} i18nKey={i18nKey} values={{ fileName }}>
        {{ fileName } as any} attached
      </Trans>
    );
  }

  return (
    <Trans parent={Text} i18nKey={i18nKey} values={{ fileName }}>
      <Link external href={fileUrl} download={fileName}>
        {{ fileName } as any}
      </Link>{' '}
      attached
    </Trans>
  );
};
