import { defaultTheme } from '@candisio/design-system';
import logoutIcon from 'assets/icon-logout.svg';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export const UserProfileLayout = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: ${defaultTheme.colors.blue500};
  }
`;

export const USER_PROFILE_NAME_MAX_WIDTH = '11.25rem';

export const UserProfileMenu = styled.nav`
  margin: 0 -1rem;
  width: 17rem;
  padding-bottom: 0;
`;

export const UserProfileMenuList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const UserProfileMenuListItem = styled.li`
  list-style: none;
`;

export const UserLogoutIcon = styled.img.attrs({
  src: logoutIcon,
})`
  width: 24px;
  height: 24px;
`;

export const UserLogoutButton = styled.li`
  border: 0;
  cursor: pointer;
  outline: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 19px 8px 19px;
  &:hover span {
    color: ${defaultTheme.colors.blue500};
  }
`;
