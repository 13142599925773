import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useRevokeCandisApiSessionMutation,
  useListCandisApiSessionsQuery,
  RevokeCandisApiSessionErrorKind,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { listCandisAPISessionsQuery } from 'views/Settings/ApiSettings/toolkit/gql';

export const useApiSettings = () => {
  const [revokeCandisApiSession, { loading: isRevokeCandisApiSessionLoading }] =
    useRevokeCandisApiSessionMutation({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: listCandisAPISessionsQuery }],
    });

  const {
    data: listCandisAPISessionsData,
    loading: listCandisAPISessionsDataLoading,
  } = useListCandisApiSessionsQuery();

  const listCandisAPISessions =
    listCandisAPISessionsData?.listCandisAPISessions;

  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const unknownErrorMessage = t(
    'settings:apiSettings.actionCard.sessionListItem.action.revokeSession.error.unknown'
  );

  const errorKindMap: Record<RevokeCandisApiSessionErrorKind, string> = {
    NOT_FOUND: t(
      'settings:apiSettings.actionCard.sessionListItem.action.revokeSession.error.notFound'
    ),
    FORBIDDEN: t(
      'settings:apiSettings.actionCard.sessionListItem.action.revokeSession.error.forbidden'
    ),
    UNKNOWN: unknownErrorMessage,
  };

  const revokeApiSession = async (sessionId: string) => {
    const { data, errors } = await revokeCandisApiSession({
      variables: {
        sessionId,
      },
    });

    if (errors?.length) {
      error(unknownErrorMessage);

      return;
    }

    if (
      data?.revokeCandisAPISession?.__typename ===
        'RevokeCandisAPISessionSuccessful' &&
      data.revokeCandisAPISession.isRevoked
    ) {
      success(
        t(
          'settings:apiSettings.actionCard.sessionListItem.action.revokeSession.success'
        )
      );
    } else if (
      data?.revokeCandisAPISession?.__typename === 'RevokeCandisAPISessionError'
    ) {
      const errorType = data.revokeCandisAPISession.kind || 'UNKNOWN';
      error(errorKindMap[errorType]);
    } else {
      error(unknownErrorMessage);
    }
  };

  return {
    listCandisAPISessions,
    listCandisAPISessionsDataLoading,
    revokeApiSession,
    isRevokeCandisApiSessionLoading,
  };
};
