import { Grid, Separator, Text } from '@candisio/design-system';
import { StatusCircle } from 'components/StatusCircle/StatusCircle';
import { Money } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PaymentCreator, PaymentCreatorProps } from './PaymentCreator';

export interface PaymentSummaryProps {
  /** Total cash discount amount */
  discountAmount: Money;
  /** Total invoice amount before discount */
  grossAmount: Money;
  /** Number of document selected/paid */
  numDocuments: number;
  /** Total payment amount after discount */
  paymentAmount: Money;
  /** Details of user who initiated the payment */
  paymentCreator?: PaymentCreatorProps;
}

/**
 * Summary displayed when the user is selecting payable documents
 */
export const PaymentSummary = ({
  discountAmount,
  grossAmount,
  numDocuments,
  paymentAmount,
  paymentCreator,
}: PaymentSummaryProps) => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  const hasCashDiscount = discountAmount.amount > 0;
  const { documentMoneyFormatter } = useMoneyFormatter();

  return (
    <Grid
      gap="space16"
      color="gray700"
      background="gray100"
      padding="space16"
      borderRadius="basic">
      <Text color="gray600">{tPayments('details.selectionSummary')}</Text>
      {paymentCreator && <PaymentCreator {...paymentCreator} />}
      <StatusCircle
        amount={numDocuments}
        description={tPayments(
          paymentCreator ? 'details.invoicesPaid' : 'details.invoicesSelected',
          { count: numDocuments }
        )}
      />
      <Separator />
      <Grid gap="space16">
        {hasCashDiscount && (
          <Grid gap="space8" templateColumns="1fr auto">
            <Text>{tPayments('details.totalAmount')}</Text>
            <Text textAlign="right">{documentMoneyFormatter(grossAmount)}</Text>
            <Text>{tPayments('details.discountAmount')}</Text>
            <Text textAlign="right">
              {documentMoneyFormatter(discountAmount)}
            </Text>
          </Grid>
        )}
        <Grid templateColumns="1fr auto" fontSize="large">
          <Text>{tPayments('details.paymentAmount')}</Text>
          <Text textAlign="right">{documentMoneyFormatter(paymentAmount)}</Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
