import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { extractInvoiceDateFromUrl } from 'components/Insights/Widgets/DateRangePicker/util';
import { BudgetInsightsWithProgressBar } from 'components/Insights/Widgets/Sum/BudgetInsightsWithProgressBar';
import { LottieAnimation, successData } from 'components/Lottie/Lottie';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useInsightsWidgets } from '../../../hooks/useInsightsWidgets';
import { Sum } from '../../../Widgets/Sum/Sum';
import { UserInfo } from '../../../Widgets/types';
import { FadeAnimation } from '../styles';

type WidgetPreviewProps = {
  sumWidgetSource: string;
  sumWidgetName?: string;
  sumWidgetFormattedSum: string | null;
  loading: boolean;
  sharedWithUsers?: UserInfo[];
  executeAnimation: boolean;
  budgetAmount: number | null;
  sumAggregateCostCenters: number;
  currency: DocumentCurrency | undefined;
};

export const WidgetPreview = ({
  sumAggregateCostCenters,
  currency,
  sumWidgetSource,
  sumWidgetName,
  sumWidgetFormattedSum,
  loading,
  sharedWithUsers,
  executeAnimation,
  budgetAmount,
}: WidgetPreviewProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  const currentUser = useCurrentUser();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef, close } =
    useTooltip({ placement: 'bottom' });

  const { totalCount } = useInsightsWidgets();

  const invoiceDate = extractInvoiceDateFromUrl({
    search: window.location.search,
    userId: currentUser?.id,
    forceDefault: totalCount === 0,
  });

  return (
    <Flex width="100%" gap="space12" direction="column">
      <FadeAnimation executeAnimation={executeAnimation}>
        <Flex justifyContent="space-between">
          <Text fontWeight="semibold">
            {t('dashboard.createNewWidget.preview')}
          </Text>
          <Flex alignItems="center" gap="space4">
            <Text color="gray500">{invoiceDate.values.readable}</Text>
            <Flex
              {...triggerProps}
              ref={triggerRef}
              onMouseLeave={() => close(true)}>
              <Icon icon="caretDown" color="gray500" size="space16" />
            </Flex>

            {isOpen && (
              <Tooltip {...tooltipProps} ref={tooltipRef}>
                <Box width="350px">
                  <Text color="gray800" fontSize="basic" fontWeight="regular">
                    {t('dashboard.createNewWidget.dateTooltip')}
                  </Text>
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </FadeAnimation>
      <Sum
        source={sumWidgetSource}
        loading={loading}
        sharedWithUsers={sharedWithUsers}
        progressBar={
          <Flex
            paddingBottom="space8"
            direction="column"
            justifyContent="left"
            alignItems="left">
            <BudgetInsightsWithProgressBar
              budget={budgetAmount}
              aggregateSum={sumAggregateCostCenters || 0}
              currency={currency}
            />
          </Flex>
        }
        name={
          sumWidgetName
            ? sumWidgetName
            : t('dashboard.createNewWidget.widgetPlaceholderName')
        }
        formattedSum={
          <Flex
            color="gray800"
            justifyContent="left"
            fontWeight="regular"
            fontSize="42px">
            {sumWidgetFormattedSum}
          </Flex>
        }
      />
      {executeAnimation ? (
        <Flex alignItems="center">
          <LottieAnimation options={{ animationData: successData }} />
        </Flex>
      ) : null}
    </Flex>
  );
};
