import { IntegrationName } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { formatStringWithSpaces } from 'utils/formatStringWithSpaces';
import { useIntegrationSettings } from './useIntegrationSettings';

const INTEGRATIONS_WITH_FORMATTABLE_ACCOUNTING_DATA = [
  IntegrationName.Datev,
  IntegrationName.DatevAdjacent,
];

export const normalizeGLA = (readableName: string) => {
  const separator = ' - ';
  const separatorIndex = readableName.indexOf(separator);
  if (separatorIndex === -1) return [readableName];

  const code = readableName.substring(0, separatorIndex).replaceAll(' ', '');
  const name = readableName.substring(separatorIndex + separator.length);

  return [code, name];
};

export const useAccountingNumberFormatters = () => {
  const integrationName = useIntegrationSettings();
  const isIntegrationWithFormattableAccountingData =
    INTEGRATIONS_WITH_FORMATTABLE_ACCOUNTING_DATA.includes(integrationName);

  const formatters = useMemo(() => {
    const removeSpacesConditionally = (accountsNumber: string) => {
      return isIntegrationWithFormattableAccountingData
        ? accountsNumber.replaceAll(' ', '')
        : accountsNumber;
    };

    if (!isIntegrationWithFormattableAccountingData) {
      return {
        friendlyFormatAccountsNumber: (accountsNumber: string) =>
          accountsNumber,
        friendlyFormatGLA: (gla: string) => gla,
        normalizeGLA,
        removeSpacesConditionally,
      };
    }

    return {
      friendlyFormatAccountsNumber: (accountsNumber: string) =>
        formatStringWithSpaces(accountsNumber, 5, 4),
      friendlyFormatGLA: (gla: string) => formatStringWithSpaces(gla, 4, 4),
      normalizeGLA,
      removeSpacesConditionally,
    };
  }, [isIntegrationWithFormattableAccountingData]);

  return formatters;
};
