import moment from 'moment';
import { RelativeReminderDateKey } from './useRelativeReminderDateItems';

export const calculateRelativeDateInitialValue = (
  terminationDate?: string | null,
  terminationReminderDate?: string | null
): RelativeReminderDateKey | undefined => {
  if (!terminationDate && !terminationReminderDate) {
    return undefined;
  }

  const weeks = moment(terminationDate).diff(terminationReminderDate, 'weeks');

  const months = moment(terminationDate).diff(
    terminationReminderDate,
    'months'
  );

  if (weeks === 1 || weeks === 2 || weeks === 4) {
    return `${weeks}weeks`;
  }

  if (months === 2 || months === 3) {
    return `${months}months`;
  }

  return 'custom';
};
