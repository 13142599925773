import {
  PurchaseOrderImportHistoryEntryStatus,
  PurchaseOrderImportHistoryPaginationQuery,
  PurchaseOrderImportHistoryPaginationQueryVariables,
} from 'generated-types/graphql.types';

import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { PurchaseOrderImportHistoryItemProps } from '../PurchaseOrdersImportHistory/PurchaseOrdersImportHistoryItem';
import { purchaseOrderImportHistoryPaginationQuery } from '../queries';

/** Fetches items for purchase order import history */
export const usePurchaseOrdersImportHistoryPaginationData = () => {
  const { data, loading, onLoadMore } = usePagePagination<
    PurchaseOrderImportHistoryPaginationQuery,
    PurchaseOrderImportHistoryPaginationQueryVariables
  >(
    purchaseOrderImportHistoryPaginationQuery,
    'listPurchaseOrderImportHistoryEntries',
    {
      variables: {
        input: {
          page: 1,
          pageSize: 20,
        },
      },
    }
  );

  const items: PurchaseOrderImportHistoryItemProps[] = (
    data?.listPurchaseOrderImportHistoryEntries?.records ?? []
  )?.map(item => {
    return {
      id: item?.id,
      createdAt: item?.createdAt,
      importStatus: {
        failedToImportDocumentsCount:
          item?.importResult?.failedToImportDocumentsCount,
        successfullyCreatedDocumentsCount:
          item?.importResult?.successfullyCreatedDocumentsCount,
        successfullyUpdatedDocumentsCount:
          item?.importResult?.successfullyUpdatedDocumentsCount,
      },
      status: item?.status ?? PurchaseOrderImportHistoryEntryStatus.Success,
      username: item?.createdBy?.name,
    };
  });

  const totalCount =
    data?.listPurchaseOrderImportHistoryEntries.pageInfo?.totalCount ?? 0;

  const hasMore =
    (data?.listPurchaseOrderImportHistoryEntries.records?.length ?? 0) <
    totalCount;

  return {
    items,
    hasMore,
    loading,
    onLoadMore,
  };
};
