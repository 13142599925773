import {
  CardIssuerAmountInput,
  CreditCardLimitThresholdsInput,
  DocumentCurrency,
} from 'generated-types/graphql.types';

type SanitizeLimitThresholdsValues = CreditCardLimitThresholdsInput | undefined;
interface SanitizeLimitThresholdsProps {
  limit: number | null | undefined; // limit for physical and virtual cards
  oneTimeCardLimit: number | null | undefined; // limit for single-use cards
}

export const sanitizeLimitThresholds = ({
  limit,
  oneTimeCardLimit,
}: SanitizeLimitThresholdsProps): SanitizeLimitThresholdsValues => {
  if (!limit && !oneTimeCardLimit) {
    return undefined;
  }

  const createAmountInput = (
    value?: number | null
  ): CardIssuerAmountInput | undefined =>
    value ? { value, currency: DocumentCurrency.Eur } : undefined;

  const thresholds: CreditCardLimitThresholdsInput = {
    PHYSICAL: createAmountInput(limit),
    VIRTUAL: createAmountInput(limit),
    SINGLE_USE: createAmountInput(oneTimeCardLimit),
  };

  return thresholds;
};
