import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const paymentConditionsFormCoreDataApiSchema = z.object({
  conditionName: z.string().nullish(),
  dueDateOffset: z.number().nullish(),
  discountPercentage: z.number().nullish(),
  discountOffset: z.number().nullish(),
});

export type PaymentConditionsFormOutput = z.infer<
  typeof paymentConditionsFormCoreDataApiSchema
>;
export type PaymentConditionsFormValues = Partial<PaymentConditionsFormOutput>;

export type PaymentConditionsFormErrorMessages = ErrorMessages<
  typeof paymentConditionsFormCoreDataApiSchema
>;

export const paymentConditionsFormErrors: PaymentConditionsFormErrorMessages = {
  conditionName: {
    label: 'paymentConditions.form.fields.conditionNumber.label',
  },
  dueDateOffset: {
    label: 'paymentConditions.form.fields.dueDateOffset.label',
  },
  discountPercentage: {
    label: 'paymentConditions.form.fields.discountPercentage.label',
  },
  discountOffset: {
    label: 'paymentConditions.form.fields.discountOffset.label',
  },
};
