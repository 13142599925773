import { defaultTheme } from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { Mentions } from 'antd/es';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export const MentionsInput = styled(Mentions)<{
  ['data-show-document-relations']?: boolean;
}>`
  .public-DraftEditor-content {
    word-break: break-all;
  }
  &.ant-mentions {
    border-radius: 8px;
  }
  &.ant-mentions > textarea {
    padding: 0.5rem ${p => ((p.value ?? '').length > 0 ? 5 : 3)}rem 0.5rem 1rem;
    font-size: ${defaultTheme.fontSizes.basic};
    color: ${defaultTheme.colors.gray800};
    &:focus,
    &:focus-within {
      outline: none;
    }
    &::placeholder {
      font-size: ${defaultTheme.fontSizes.basic};
      color: ${defaultTheme.colors.gray400};
      font-style: ${({
        'data-show-document-relations': showDocumentRelations,
      }) => (showDocumentRelations ? 'initial' : 'italic')};
    }
    &:hover:not(:focus) {
      ${({ 'data-show-document-relations': showDocumentRelations }) =>
        showDocumentRelations
          ? `background: ${defaultTheme.colors.gray50};`
          : ''}
    }
  }
  &.ant-mentions-focused,
  &.ant-mentions:focus {
    box-shadow: ${({ 'data-show-document-relations': showDocumentRelations }) =>
      showDocumentRelations ? 'none' : defaultTheme.shadows.elevatedShadow2};
    ${({ 'data-show-document-relations': showDocumentRelations }) =>
      showDocumentRelations
        ? `border: 1px solid ${defaultTheme.colors.blue500}`
        : ''}
  }

  &.ant-mentions:hover:not(.ant-mentions-focused) {
    ${({ 'data-show-document-relations': showDocumentRelations }) =>
      showDocumentRelations
        ? `border: 1px solid ${defaultTheme.colors.gray250};`
        : ''}
  }
`;
