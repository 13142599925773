import { Button, useTheme } from '@candisio/design-system';
import { HelpCenterArticleLink } from 'components/HelpCenterArticleLink/HelpCenterArticleLink';
import { FormFileField } from 'components/HookFormFields/HookFormFileField';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { CostCenterTypes } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectFileFormValues } from '../CostCenterImport.schema';
import { InfoList } from '../InfoList';
import {
  Generation,
  HalfWidth,
  ProgressLabel,
  SelectFileDescriptionLayout,
  SelectFileFormContainer,
  SelectFileInfoLinksWrapper,
} from '../styles';
import {
  defaultTypeOptions,
  typeOptionsWithExtraCostInfo,
} from './SelectFileForm.helper';

export interface SelectFileFormProps {
  costCentersCount: number;
  costCenterType: CostCenterTypes;
  duplicateCount: number;
  isDatevFormat: boolean;
  didCheckDuplicates: boolean;
  errorPreviewDelay: number;
  errors: string[];
  isSubmitting: boolean;
  disabledButton: boolean;
  control: Control<SelectFileFormValues>;
}

export const SelectFileForm = ({
  costCentersCount,
  costCenterType,
  duplicateCount,
  isDatevFormat,
  didCheckDuplicates,
  errorPreviewDelay,
  isSubmitting,
  errors,
  disabledButton,
  control,
}: SelectFileFormProps) => {
  const [t] = useTranslation();
  const { colors } = useTheme();

  const artistSocialInsuranceAndExtraCostInfoFF = useCandisFeatureFlags(
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo
  );

  const hasError = errors.length > 0;

  const showGeneration = isSubmitting || hasError;

  const [showError, setShowError] = useState(false);
  const progressBarLabelColor = showError ? colors.gray800 : undefined;

  useEffect(() => {
    setTimeout(() => setShowError(hasError), errorPreviewDelay);
  }, [hasError, errorPreviewDelay]);

  return (
    <SelectFileFormContainer>
      {!showGeneration && (
        <SelectFileDescriptionLayout>
          <p>
            {t('settings.costCenter.wizardModal.fileSelectionStep.description')}
          </p>
          <SelectFileInfoLinksWrapper>
            <HelpCenterArticleLink
              articleUrl={t(
                'settings.costCenter.wizardModal.fileSelectionStep.importHelpCenterLink.url'
              )}>
              {t(
                'settings.costCenter.wizardModal.fileSelectionStep.importHelpCenterLink.text'
              )}
            </HelpCenterArticleLink>
            <HelpCenterArticleLink
              articleUrl={t(
                'settings.costCenter.wizardModal.fileSelectionStep.exportHelpCenterLink.url'
              )}>
              {t(
                'settings.costCenter.wizardModal.fileSelectionStep.exportHelpCenterLink.text'
              )}
            </HelpCenterArticleLink>
          </SelectFileInfoLinksWrapper>
        </SelectFileDescriptionLayout>
      )}
      <HalfWidth>
        <FormFileField
          accept="text/csv"
          buttonLabel={t(
            'settings.costCenter.wizardModal.fileSelectionStep.chooseFileButton'
          )}
          name="file"
          placeholder={t('common.select')}
          control={control}
        />
        {!showGeneration && (
          <>
            <HookFormRadioGroupField
              orientation="horizontal"
              showGroupLabel={true}
              label={t(
                'settings.costCenter.wizardModal.fileSelectionStep.chooseCostCenterType'
              )}
              name="costCenterType"
              options={
                artistSocialInsuranceAndExtraCostInfoFF
                  ? typeOptionsWithExtraCostInfo(t)
                  : defaultTypeOptions(t)
              }
              control={control}
            />
            <div>
              <Button type="submit" disabled={disabledButton}>
                {t(
                  'settings.costCenter.wizardModal.fileSelectionStep.uploadButton'
                )}
              </Button>
            </div>
          </>
        )}
      </HalfWidth>
      {showGeneration && (
        <Generation>
          <ProgressBar static={showError} error={showError}>
            <ProgressLabel labelColor={progressBarLabelColor}>
              {showError
                ? t(
                    'settings.costCenter.wizardModal.fileSelectionStep.encounteredErrorInFileReading'
                  )
                : t(
                    'settings.costCenter.wizardModal.fileSelectionStep.readingFile'
                  )}
            </ProgressLabel>
          </ProgressBar>
          {(didCheckDuplicates || showError) && (
            <HalfWidth>
              <InfoList
                animate
                costCentersCount={costCentersCount}
                costCenterType={costCenterType}
                duplicateCount={duplicateCount}
                errors={showError ? errors : []}
                isDatevFormat={isDatevFormat}
              />
            </HalfWidth>
          )}
        </Generation>
      )}
    </SelectFileFormContainer>
  );
};
