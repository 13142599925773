import { z } from 'zod';

export const MAX_FILE_SIZE_MB = 20;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

export const EXTF_SPECIFIC_CSV_COLUMNS = {
  emptyField: /^Leerfeld$/,
};

export const DTVF_SPECIFIC_CSV_COLUMNS = {
  awGuid: /^AWGuid$/,
};

export const COMMON_CSV_COLUMNS = {
  number: /^Nummer$/,
  description: /^Bezeichnung$/,
  dueType: /^Fälligkeitstyp$/,
  discount1Percent: /^Skonto 1%$/,
  discount1Days: /^Skonto 1 Tage$/,
  discount2Percent: /^Skonto 2 %$/,
  discount2Days: /^Skonto 2 Tage$/,
  dueDays: /^Fällig Tage$/,
  invoiceUntilPeriod1: /^Rechnung bis \/ Zeitraum 1$/,
  discount1DatePeriod1: /^Skonto1 Datum \/ Zeitraum 1$/,
  discount1MonthPeriod1: /^Skonto 1 Monat \/ Zeitraum 1$/,
  discount2DatePeriod1: /^Skonto 2 Datum \/ Zeitraum 1$/,
  discount2MonthPeriod1: /^Skonto 2 Monat \/ Zeitraum 1$/,
  dueDatePeriod1: /^Fällig Datum \/ Zeitraum 1$/,
  dueMonthPeriod1: /^Fällig Monat \/ Zeitraum 1$/,
  invoiceUntilPeriod2: /^Rechnung bis \/ Zeitraum 2$/,
  discount1DatePeriod2: /^Skonto1 Datum \/ Zeitraum 2$/,
  discount1MonthPeriod2: /^Skonto 1 Monat \/ Zeitraum 2$/,
  discount2DatePeriod2: /^Skonto 2 Datum \/ Zeitraum 2$/,
  discount2MonthPeriod2: /^Skonto 2 Monat \/ Zeitraum 2$/,
  dueDatePeriod2: /^Fällig Datum \/ Zeitraum 2$/,
  dueMonthPeriod2: /^Fällig Monat \/ Zeitraum 2$/,
  invoiceUntilPeriod3: /^Rechnung bis \/ Zeitraum 3$/,
  discount1DatePeriod3: /^Skonto1 Datum \/ Zeitraum 3$/,
  discount1MonthPeriod3: /^Skonto1 Monat \/ Zeitraum 3$/,
  discount2DatePeriod3: /^Skonto 2 Datum \/ Zeitraum 3$/,
  discount2MonthPeriod3: /^Skonto 2 Monat \/ Zeitraum 3$/,
  dueDatePeriod3: /^Fällig Datum \/ Zeitraum 3$/,
  dueMonthPeriod3: /^Fällig Monat \/ Zeitraum 3$/,
  // DTVF_SPECIFIC_CSV_COLUMNS.awGuid || EXTF_SPECIFIC_CSV_COLUMNS.emptyField
  usage: /^Verwendung$/,
};

export const DTVF_SCHEMA = z.tuple([
  z.string().regex(COMMON_CSV_COLUMNS.number),
  z.string().regex(COMMON_CSV_COLUMNS.description),
  z.string().regex(COMMON_CSV_COLUMNS.dueType),
  z.string().regex(COMMON_CSV_COLUMNS.discount1Percent),
  z.string().regex(COMMON_CSV_COLUMNS.discount1Days),
  z.string().regex(COMMON_CSV_COLUMNS.discount2Percent),
  z.string().regex(COMMON_CSV_COLUMNS.discount2Days),
  z.string().regex(COMMON_CSV_COLUMNS.dueDays),
  z.string().regex(COMMON_CSV_COLUMNS.invoiceUntilPeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.discount1DatePeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.discount1MonthPeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.discount2DatePeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.discount2MonthPeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.dueDatePeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.dueMonthPeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.invoiceUntilPeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.discount1DatePeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.discount1MonthPeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.discount2DatePeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.discount2MonthPeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.dueDatePeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.dueMonthPeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.invoiceUntilPeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.discount1DatePeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.discount1MonthPeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.discount2DatePeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.discount2MonthPeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.dueDatePeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.dueMonthPeriod3),
  z.string().regex(DTVF_SPECIFIC_CSV_COLUMNS.awGuid),
  z.string().regex(COMMON_CSV_COLUMNS.usage),
]);

export const EXTF_SCHEMA = z.tuple([
  z.string().regex(COMMON_CSV_COLUMNS.number),
  z.string().regex(COMMON_CSV_COLUMNS.description),
  z.string().regex(COMMON_CSV_COLUMNS.dueType),
  z.string().regex(COMMON_CSV_COLUMNS.discount1Percent),
  z.string().regex(COMMON_CSV_COLUMNS.discount1Days),
  z.string().regex(COMMON_CSV_COLUMNS.discount2Percent),
  z.string().regex(COMMON_CSV_COLUMNS.discount2Days),
  z.string().regex(COMMON_CSV_COLUMNS.dueDays),
  z.string().regex(COMMON_CSV_COLUMNS.invoiceUntilPeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.discount1DatePeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.discount1MonthPeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.discount2DatePeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.discount2MonthPeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.dueDatePeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.dueMonthPeriod1),
  z.string().regex(COMMON_CSV_COLUMNS.invoiceUntilPeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.discount1DatePeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.discount1MonthPeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.discount2DatePeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.discount2MonthPeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.dueDatePeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.dueMonthPeriod2),
  z.string().regex(COMMON_CSV_COLUMNS.invoiceUntilPeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.discount1DatePeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.discount1MonthPeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.discount2DatePeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.discount2MonthPeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.dueDatePeriod3),
  z.string().regex(COMMON_CSV_COLUMNS.dueMonthPeriod3),
  z.string().regex(EXTF_SPECIFIC_CSV_COLUMNS.emptyField),
  z.string().regex(COMMON_CSV_COLUMNS.usage),
]);
