import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  EcmDocumentType,
  EcmFilterInput,
  useCreateDataFileForEcmDocumentsMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/downloadFile';
import { refetchDocumentDataFileRecords } from 'views/Archive/Documents/queries';
import { usePrepareEcmFiltersSortingFromUrl } from './usePrepareEcmFiltersSortingFromUrl';

type DefaultFilters = EcmFilterInput & {
  documentType: Array<EcmDocumentType> | undefined;
};

interface UseDownloadCSVFileEcmParams {
  isTableLoading: boolean;
  defaultFilters?: DefaultFilters;
}

export const useDownloadCSVFileEcm = ({
  isTableLoading,
  defaultFilters,
}: UseDownloadCSVFileEcmParams) => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const [createDownloadFile, { loading: isLoading }] =
    useCreateDataFileForEcmDocumentsMutation();

  const { filters, sort, search } = usePrepareEcmFiltersSortingFromUrl();

  const handleCSVDownload = useCallback(async () => {
    if (isTableLoading) {
      info(t('insights:download.waitForLoading'));
    }

    const results = await createDownloadFile({
      refetchQueries: refetchDocumentDataFileRecords,
      variables: {
        filter: defaultFilters ? { ...filters, ...defaultFilters } : filters,
        sort,
        search,
      },
    });

    if (!results.data?.createDataFileForEcmDocuments?.file?.url) {
      error(t('insights:download.errorWhenDownloading'));
    } else {
      try {
        const fileName =
          results.data?.createDataFileForEcmDocuments?.file?.name;

        const fileUrl = results.data?.createDataFileForEcmDocuments?.file?.url;
        downloadFile(fileUrl, fileName);
        success(t('insights:download.successfullyDownloaded'));
      } catch (err) {
        error(t('insights:download.errorWhenDownloading'));
      }
    }
  }, [
    createDownloadFile,
    defaultFilters,
    error,
    filters,
    info,
    isTableLoading,
    search,
    sort,
    success,
    t,
  ]);

  return {
    handleCSVDownload,
    isLoading,
  };
};
