import { Flex, Grid, Tag, Text } from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Statement } from '../hooks/useGetStatements';

export const StatementItem = ({
  closingBalance,
  endDate,
  isClosed,
  month,
  startDate,
  id: statementId,
}: Statement) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const orgId = useOrganizationId();
  const navigate = useNavigate();

  const datePeriod = isClosed ? `${startDate} - ${endDate}` : `${startDate}`;

  const handleClick = () => {
    navigate(
      `/${orgId}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.STATEMENTS}/${statementId}`
    );
  };

  return (
    <Grid
      padding="space16"
      borderBottom="1px solid gray200"
      paddingRight="70px"
      onClick={handleClick}
      style={{
        cursor: 'pointer',
      }}>
      <Flex justifyContent="space-between">
        <Flex alignItems="start" gap="space16">
          <Flex direction="column" gap="space4">
            <Text fontSize="basic">{month}</Text>
            <Text color="gray600">{datePeriod}</Text>
          </Flex>
          {!isClosed && (
            <Tag variant="secondary" color="blue">
              {t('statements.list.onGoingTag')}
            </Tag>
          )}
        </Flex>
        <Flex>
          <Flex direction="column" alignItems="end">
            <Text fontSize="basic">{closingBalance}</Text>
            <Text color={isClosed ? 'gray600' : 'gray800'}>
              {isClosed
                ? t('statements.list.closingBalance')
                : t('statements.list.openingBalance')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Grid>
  );
};
