import {
  DatevClientExportType,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';
import { useCreditCardExportRequirements } from 'orgConfig/creditCards/useCreditCardExportRequirements';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';

export const useCheckDatevSetup = () => {
  const { data, loading } = useNew_DatevSettingsQuery();

  const datevSettings = data?.new_datevSettings;

  const {
    bdsConnected,
    rdsConnected,
    exportProvisionsFF,
    bdsSetupCompleted,
    isLoadingDatev,
    hide: hideDatev,
  } = useDatev(); // BDS-checked

  const creditCardsSetup = useCreditCardsSetup();
  const creditCardRequirements = useCreditCardExportRequirements();

  const {
    client,
    chartOfAccount,
    provisionsLedger,
    hasAuthTokenForReadClients,
    hasAuthTokenForExportDocuments,
  } = datevSettings ?? {};

  const hasDocumentDataAPI =
    client?.exportType === DatevClientExportType.DxsoJobs;

  const hasRdsAndRequiresCashLedgerOrDocumentData =
    rdsConnected &&
    (!hasDocumentDataAPI || !client?.isDxsoCashLedgerConfigured);

  const hasBdsAndRequiresPermission = bdsConnected && !bdsSetupCompleted;

  const hasCreditCardsAndRequires = creditCardsSetup.isInUse
    ? {
        rds: hasRdsAndRequiresCashLedgerOrDocumentData,
        bds: hasBdsAndRequiresPermission,
        accounts: !creditCardRequirements.hasAccountNumbers,
      }
    : null;

  const indicateDatevCreditCardSetupNeeded =
    !!hasCreditCardsAndRequires?.rds ||
    !!hasCreditCardsAndRequires?.bds ||
    !!hasCreditCardsAndRequires?.accounts;

  const hasReadClientsButNotSelected =
    !!hasAuthTokenForReadClients && !hasAuthTokenForExportDocuments;

  const hasProvisions = !hideDatev && bdsConnected && exportProvisionsFF;

  const hasProvisionAccountNumbers =
    !!provisionsLedger?.provisionAccountNumber &&
    !!provisionsLedger?.otherAssetsAccountNumber;

  const hasProvisionsAndRequiresSetup =
    hasProvisions && !hasProvisionAccountNumbers;

  const isAccountLengthMissing = !chartOfAccount?.accountLength;
  const isAccountCodeMissing = !chartOfAccount?.code;

  const accountingCoreDataMissing =
    (rdsConnected && isAccountLengthMissing) ||
    (bdsConnected && (isAccountLengthMissing || isAccountCodeMissing));

  const loadingSettings =
    loading || isLoadingDatev || creditCardsSetup.isOnboardingStatusLoading;

  return {
    hasReadClientsButNotSelected,
    hasCreditCardsAndRequires,
    hasProvisionsAndRequiresSetup,
    indicateDatevCreditCardSetupNeeded,
    accountingCoreDataMissing,
    loadingSettings,
  };
};
