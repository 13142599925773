import { DocumentsTableData } from 'components/DocumentsTable/types';
import { DocumentFilterInput } from 'generated-types/graphql.types';
import { Filters } from 'react-table';

export const columnIdsToAvailableGqlFilter = {
  contact: 'contactIds',
} as const;

const isKeyOfFilterMap = (
  key: string
): key is keyof typeof columnIdsToAvailableGqlFilter => {
  return key in columnIdsToAvailableGqlFilter;
};

export const mapExportTableFiltersToGqlFilterInput = (
  filters: Filters<DocumentsTableData>
): DocumentFilterInput => {
  const reducedFilters = (filters ?? [])?.reduce<DocumentFilterInput>(
    (filterInput, filter) => {
      if (isKeyOfFilterMap(filter.id)) {
        return {
          ...filterInput,
          [columnIdsToAvailableGqlFilter[filter.id]]: filter.value,
        };
      }

      return filterInput;
    },
    {}
  );

  return reducedFilters;
};
