import { Tag } from '@candisio/design-system';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { Cards } from './components/Cards';
import { DatevProvisionSettings } from './components/DatevProvisionSettings/DatevProvisionSettings';
import { ProvisionPromotionCard } from './components/ProvisionPromotionCard/ProvisionPromotionCard';

export const PROVISION_SETTINGS_WIDTH = '40rem';

export const ProvisionsSettings = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { bdsBought } = useDatev();

  return (
    <SettingsLayout
      title={t('provisions.header')}
      width={PROVISION_SETTINGS_WIDTH}
      titleTag={
        !bdsBought && (
          <Tag color="purple" variant="secondary">
            {t('common:tag.addOn')}
          </Tag>
        )
      }>
      {!bdsBought ? <ProvisionPromotionCard /> : <DatevProvisionSettings />}
      <Cards />
    </SettingsLayout>
  );
};
