import {
  Button,
  Drawer,
  DrawerProps,
  Grid,
  Heading,
  useId,
} from '@candisio/design-system';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IbanForm } from './IbanForm';
import { IbanFormValues } from './ibanFormSchema';

export interface IbanListViewDrawerProps extends Omit<DrawerProps, 'onSubmit'> {
  /** Form action */
  action?: 'edit' | 'create';
  /** Initial form values */
  defaultValues?: DefaultValues<IbanFormValues>;
  /** Is deleting IBAN? */
  deleting?: boolean;
  /** Called when form is submitted */
  onSubmit: (values: IbanFormValues) => Promise<void>;
  /** Called when IBAN should be deleted */
  onDelete?: () => void;
  /** Is form submitting? */
  submitting?: boolean;
}

/** Drawer containing IBAN form */
export const IbanListViewDrawer = ({
  action = 'create',
  defaultValues = {},
  deleting,
  onClose,
  onDelete,
  onSubmit,
  submitting,
  ...restProps
}: IbanListViewDrawerProps) => {
  const [t] = useTranslation('payments');
  const formId = useId();

  return (
    <Drawer onClose={onClose} {...restProps}>
      <DrawerLayout
        header={
          <Heading as="h3">
            {action === 'edit' ? t('bankAccounts.edit') : t('bankAccounts.new')}
          </Heading>
        }
        footer={
          <Grid autoFlow="column" justifyContent="space-between">
            <Grid autoFlow="column" gap="space8">
              <Button type="submit" form={formId} loading={submitting}>
                {t('bankAccounts.save')}
              </Button>
            </Grid>
            {action === 'edit' && (
              <ConfirmationButton
                color="red"
                disabled={submitting}
                loading={deleting}
                onConfirm={onDelete}
                variant="secondary">
                {t('bankAccounts.delete')}
              </ConfirmationButton>
            )}
          </Grid>
        }
        onClose={onClose}>
        <IbanForm
          defaultValues={defaultValues}
          id={formId}
          onSubmit={onSubmit}
        />
      </DrawerLayout>
    </Drawer>
  );
};
