import { ContactImportPreviewTableData } from 'views/Contacts/ContactImport/ContactImportPreviewTable/ContactImportPreviewTable';
import { SingleParsedContact } from 'views/Contacts/ContactImport/types';

export const transformContactsData = (
  contacts: SingleParsedContact[]
): ContactImportPreviewTableData[] => {
  return contacts.map(contact => ({
    id: contact.id,
    errors: contact.errors,
    ...contact.contactInputData,
  }));
};
