import {
  DateFilterOption,
  GetCreditCardsStatementsQuery,
  GetCreditCardsStatementsQueryVariables,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import DateFormatters from 'utils/date_formatter';
import { cardIssuerStatementsPageBasedQuery } from '../gql';
import { useGetDateRange } from './useGetDateRange';
import { useMonthFormatter } from './useMonthFormatter';

interface UseGetStatementsParams {
  filter: number;
}

export interface Statement {
  id: string;
  month: string;
  startDate: string;
  endDate: string;
  isClosed: boolean;
  closingBalance: string;
}

export const useGetStatements = ({ filter }: UseGetStatementsParams) => {
  const { dateFrom, dateTo } = useGetDateRange(filter);
  const { longNotation } = useMonthFormatter();

  const { transactionAmountFormatter } = useMoneyFormatter();

  const { data, loading, onLoadMore } = usePagePagination<
    GetCreditCardsStatementsQuery,
    GetCreditCardsStatementsQueryVariables
  >(cardIssuerStatementsPageBasedQuery, 'cardIssuerStatementsPageBased', {
    variables: {
      dateRangeFilters: {
        ongoingDuringDateFilter: {
          dateFrom: dateFrom,
          dateTo: dateTo,
          filterOption: DateFilterOption.Custom,
        },
      },
    },
  });

  const totalCount =
    data?.cardIssuerStatementsPageBased.pageInfo?.totalCount ?? 0;

  const hasMore =
    (data?.cardIssuerStatementsPageBased.records?.length ?? 0) < totalCount;

  const statements: Statement[] = (
    data?.cardIssuerStatementsPageBased.records ?? []
  ).map(statement => {
    const startFullDate = new Date(statement.cardIssuer.statementPeriodStart);
    const endFullDate = new Date(statement.cardIssuer.statementPeriodEnd);

    return {
      id: statement.id,
      month: longNotation.format(
        new Date(statement.cardIssuer.statementPeriodStart)
      ),
      startDate: DateFormatters.compact(startFullDate),
      endDate: DateFormatters.compact(endFullDate),
      isClosed: statement.cardIssuer.isClosed,
      closingBalance: transactionAmountFormatter({
        currency: statement.cardIssuer.closingBalance.currency,
        precision: statement.cardIssuer.closingBalance.precision,
        value: statement.cardIssuer.closingBalance.value,
      }),
    };
  });

  return { statements, hasMore, loading, onLoadMore };
};
