import { usePaymentConditionQuery } from 'generated-types/graphql.types';
import { PaymentConditionsFormValues } from 'views/Settings/PaymentConditions/CoreDataApi/paymentConditionsFormFormShema';

export interface UsePaymentConditionDataOptions {
  id?: string;
}

export interface UsePaymentConditionDataReturn {
  data?: PaymentConditionsFormValues;
  isArchived: boolean;
  error: undefined;
  loading: boolean;
}

export interface UsePaymentConditionErrorReturn {
  data: undefined;
  isArchived: false;
  error?: {
    message?: string;
    kind: string;
  };
  loading: boolean;
}

/**
 * Fetches payment condition with given id and returns
 * PaymentConditionsFormValues
 */
export const usePaymentConditionDataCoreDataApi = ({
  id,
}: UsePaymentConditionDataOptions):
  | UsePaymentConditionDataReturn
  | UsePaymentConditionErrorReturn => {
  const { data, loading } = usePaymentConditionQuery({
    variables: { id: id as string },
    skip: id === undefined,
  });

  if (data?.paymentCondition.__typename === 'PaymentCondition') {
    return {
      data: {
        conditionName: data.paymentCondition?.paymentConditionName,
        dueDateOffset: data.paymentCondition.dueDateOffset,
        discountOffset: data.paymentCondition.discountOffset,
        discountPercentage: data.paymentCondition.discountPercentage,
      },
      isArchived: data.paymentCondition.isArchived ?? false,
      error: undefined,
      loading,
    };
  }

  if (data?.paymentCondition.__typename === 'PaymentConditionError') {
    return {
      data: undefined,
      isArchived: false,
      error: {
        message: data.paymentCondition.message ?? undefined,
        kind: data.paymentCondition.kind,
      },
      loading,
    };
  }

  return {
    data: undefined,
    error: undefined,
    isArchived: false,
    loading,
  };
};
