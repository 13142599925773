import { ImportHistoryItem } from 'components/HistoryList/HistoryList';
import { useCostCenterGetImportHistoryQuery } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { COST_CENTER_ROUTE_HASH } from 'views/Settings/CostCenters/consts';

export const useCostCenterImportHistoryData = () => {
  const organizationSlug = useOrganizationId();
  const costCenterUrl = `/${organizationSlug}${Routes.SETTINGS}${Routes.COST_CENTER}`;
  const costCenterImportUrl = `/${organizationSlug}${Routes.SETTINGS}${Routes.COST_CENTER}${COST_CENTER_ROUTE_HASH.import}`;

  const { data, loading: isLoading } = useCostCenterGetImportHistoryQuery({
    fetchPolicy: 'network-only',
  });

  const costCenterHistoryItems: ImportHistoryItem[] = data
    ? data.costCenterGetImportHistory.map(item => {
        return {
          createdAt: item.importedAt,
          filters: undefined,
          file: {
            name: item.file.name,
            url: item.file.url,
          },
          username: item.membership.name,
        };
      })
    : [];

  return {
    costCenterHistoryItems,
    costCenterImportUrl,
    costCenterUrl,
    isLoading,
  };
};
