import {
  Flex,
  Icon,
  Switch,
  Tooltip,
  TruncatedText,
  useTooltip,
  Text,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export interface HandleChangeParams {
  isChecked: boolean;
  switchValue: string;
  switchName: string;
}

interface RoleSwitchProps {
  label: string;
  value: string;
  name: string;
  description: string | undefined;
  isToggled: boolean;
  isReadOnly: boolean;
  onChange: (params: HandleChangeParams) => void;
}

export const RoleSwitch = ({
  label,
  value,
  isToggled,
  name,
  isReadOnly,
  onChange,
  description,
}: RoleSwitchProps) => {
  const descriptionTooltip = useTooltip();

  const ssoTooltip = useTooltip({ placement: 'left' });
  const [t] = useTranslation();

  return (
    <Flex padding="space16" justifyContent="space-between" alignItems="center">
      <TruncatedText fontSize="basic">{label}</TruncatedText>
      <Flex gap="space8" alignItems="center">
        {description && (
          <Icon
            icon="infoOutline"
            {...descriptionTooltip.triggerProps}
            ref={descriptionTooltip.triggerRef}
            size="space16"
          />
        )}
        {descriptionTooltip.isOpen && (
          <Tooltip
            {...descriptionTooltip.tooltipProps}
            ref={descriptionTooltip.tooltipRef}>
            <Text>{description}</Text>
          </Tooltip>
        )}
        {/** There is a type mistmatchment between the props coming from the tooltip and the prop from the switch thus the next line for expecting an error*/}
        {/**@ts-expect-error */}
        <Switch
          {...ssoTooltip.triggerProps}
          ref={ssoTooltip.triggerRef}
          disabled={isReadOnly}
          value={value}
          label={label}
          name={name}
          checked={isToggled}
          onChange={isChecked =>
            onChange({ isChecked, switchValue: value, switchName: name })
          }
        />
        {ssoTooltip.isOpen && isReadOnly && (
          <Tooltip {...ssoTooltip.tooltipProps} ref={ssoTooltip.tooltipRef}>
            {t('settings.teamMembers.roles.readOnlySSO')}
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};
