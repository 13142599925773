import { Money } from 'generated-types/graphql.types';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { isEmpty, keyBy } from 'lodash';
import { BookingsFormPartialData } from 'views/DocumentDetails/BookingsFormContext';
import {
  ThreeWayMatchBookingData,
  ThreeWayMatchGoodsReceiptData,
  ThreeWayMatchPurchaseOrderData,
  ThreeWayMatchRowData,
} from 'views/DocumentDetails/components/ThreeWayMatch/types';

type PurchaseOrderPartialData = {
  price?: Money | null;
  quantity: number;
  description: string;
  articleNumber: string;
}[];

export type ThreeWayMatchResultData = {
  id: string;
  articleNumber: string;
  description: string;
  purchaseOrderQuantity: number;
  purchaseOrderAmount?: Money | null;
  invoiceQuantity: number;
  invoiceAmount: Money;
  goodsReceiptQuantity: number;
}[];

export type ThreeWayMatchResult = {
  isAmountMatched?: boolean;
  isQuantityMatched?: boolean;
  data: ThreeWayMatchResultData;
};
// TODO : remove this one once all migrated and ready to use getThreeWayMatchResultNew
export function getThreeWayMatchResult({
  bookingFormData,
  purchaseOrderData,
}: {
  bookingFormData: BookingsFormPartialData;
  purchaseOrderData: PurchaseOrderPartialData;
}): ThreeWayMatchResult {
  const result: ThreeWayMatchResult = {
    isQuantityMatched: true,
    isAmountMatched: true,
    data: [],
  };

  if (bookingFormData.length !== purchaseOrderData.length) {
    result.isQuantityMatched = false;
    result.isAmountMatched = false;
  }

  const purchaseOrderDataHashMap = keyBy(purchaseOrderData, 'description');
  const bookingFormDataHashMap = keyBy(bookingFormData, 'description');

  const mergedKeys = new Set([
    ...Object.keys(purchaseOrderDataHashMap),
    ...Object.keys(bookingFormDataHashMap),
  ]);

  if (bookingFormData.length !== mergedKeys.size) {
    result.isQuantityMatched = false;
    result.isAmountMatched = false;
  }

  for (const [index, key] of mergedKeys.entries()) {
    result.isAmountMatched = isAmountMatched(
      purchaseOrderDataHashMap[key],
      bookingFormDataHashMap[key]
    );

    result.isQuantityMatched =
      purchaseOrderDataHashMap[key]?.quantity ===
      bookingFormDataHashMap[key]?.quantity;

    result.data.push({
      id: index,
      description: key,
      goodsReceiptQuantity: purchaseOrderDataHashMap[key]?.quantity,
      invoiceAmount: bookingFormDataHashMap[key]?.price,
      invoiceQuantity: bookingFormDataHashMap[key]?.quantity,
      purchaseOrderAmount: purchaseOrderDataHashMap[key]?.price,
      purchaseOrderQuantity: purchaseOrderDataHashMap[key]?.quantity,
      articleNumber: purchaseOrderDataHashMap[key]?.articleNumber || '-',
    });
  }

  return result;
}

export const getThreeWayMatchResultNew = ({
  bookingsData,
  purchaseOrdersData,
  goodsReceiptsData,
}: {
  bookingsData: ThreeWayMatchBookingData[];
  purchaseOrdersData: ThreeWayMatchPurchaseOrderData[];
  goodsReceiptsData: ThreeWayMatchGoodsReceiptData[];
}): ThreeWayMatchRowData[] => {
  const result: ThreeWayMatchRowData[] = [];
  if (!bookingsData.length || !purchaseOrdersData.length) {
    return result;
  }

  const purchaseOrderDataHashMap = keyBy(purchaseOrdersData, 'bookingId');
  const bookingFormDataHashMap = keyBy(bookingsData, 'bookingId');
  const goodsReceiptsDataHashMap = keyBy(goodsReceiptsData, 'bookingId');

  const mergedKeys = new Set([
    ...Object.keys(purchaseOrderDataHashMap),
    ...Object.keys(bookingFormDataHashMap),
    ...Object.keys(goodsReceiptsDataHashMap),
  ]);

  for (const [index, key] of mergedKeys.entries()) {
    result.push({
      id: index,
      description: bookingFormDataHashMap[key]?.description ?? '',
      goodsReceiptQuantity: goodsReceiptsDataHashMap[key]?.quantity ?? 0,
      invoiceAmount: bookingFormDataHashMap[key]?.price || {
        amount: 0,
        currency: '',
      },
      invoiceQuantity: bookingFormDataHashMap[key]?.quantity ?? 0,
      purchaseOrderAmount: purchaseOrderDataHashMap[key]?.price,
      purchaseOrderQuantity: purchaseOrderDataHashMap[key]?.quantity ?? 0,
      articleNumber: purchaseOrderDataHashMap[key]?.articleNumber ?? '-',
      isAmountMatched: isAmountMatched(
        purchaseOrderDataHashMap[key],
        bookingFormDataHashMap[key]
      ),
      isQuantityMatched:
        purchaseOrderDataHashMap[key]?.quantity ===
          bookingFormDataHashMap[key]?.quantity &&
        goodsReceiptsDataHashMap[key]?.quantity ===
          bookingFormDataHashMap[key]?.quantity,
      goodsReceiptsDetails: goodsReceiptsDataHashMap[key]?.items ?? [],
    });
  }

  return result;
};

export const getThreeWayMatchGeneralState = (
  result: ThreeWayMatchResult
): 'success' | 'failure' | 'default' => {
  if (isEmpty(result.data)) return 'default';

  return result.isAmountMatched && result.isQuantityMatched
    ? 'success'
    : 'failure';
};

const isAmountMatched = (
  purchaseOrder: PurchaseOrderPartialData[number],
  bookingFormData: BookingsFormPartialData[number]
) => {
  if (
    (!purchaseOrder && !bookingFormData) ||
    (!purchaseOrder && bookingFormData) ||
    (purchaseOrder && !bookingFormData)
  ) {
    return false;
  }

  const bookingFormDecimalAmount = formatIntegerAmountToDecimal(
    bookingFormData.price.amount,
    0
  );

  const purchaseOrderDecimalAmount = purchaseOrder.price
    ? formatIntegerAmountToDecimal(
        purchaseOrder.price.amount,
        purchaseOrder.price.precision
      )
    : 0;

  return (
    bookingFormDecimalAmount === purchaseOrderDecimalAmount &&
    bookingFormData.price.currency === purchaseOrder.price?.currency
  );
};
