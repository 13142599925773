import { Button, Grid, Icon, TruncatedText } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { openIntercomModal } from 'views/AppContainer/components/IntercomModal/IntercomModal';

interface VerifiedDeviceProps {
  deviceName: string;
  onContactSupport: () => void;
}

export const VerifiedDevice = ({
  deviceName,
  onContactSupport,
}: VerifiedDeviceProps) => {
  const [t] = useTranslation();

  const handleContactSupport = () => {
    onContactSupport();
    openIntercomModal();
  };

  return (
    <Grid
      fontSize="basic"
      gap="space8"
      alignItems="center"
      templateColumns="max-content auto max-content"
      justifyContent="start">
      <Icon
        alignSelf="center"
        size="space18"
        icon="checkCircle"
        color="green500"
      />
      <TruncatedText>
        {t(
          'header.profile.tabs.update.accountSecurity.authenticatorAppCard.verifiedDevice.description',
          { deviceName }
        )}
      </TruncatedText>
      <Button
        as="button"
        icon="mail"
        size="xsmall"
        color="blue"
        variant="secondary"
        onClick={handleContactSupport}
        data-intercom="intercom-chat">
        {t(
          'header.profile.tabs.update.accountSecurity.authenticatorAppCard.verifiedDevice.changeDeviceCta'
        )}
      </Button>
    </Grid>
  );
};
