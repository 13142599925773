import { TruncatedText } from '@candisio/design-system';
import moment from 'moment';

export const ValidUntilCell = ({ value }: { value: Date | null }) => {
  if (!value) {
    return <TruncatedText> - </TruncatedText>;
  }

  return <TruncatedText>{moment.utc(value).format('MM/YY')}</TruncatedText>;
};
