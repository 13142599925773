import { Comment } from 'components/Comment/Comment';
import { useGetMembershipsQuery } from 'generated-types/graphql.types';

interface CommentFieldDocumentContainerProps {
  isSubmitting: boolean;
  onCreateComment: ({
    mention,
    mentionedUserIds,
  }: {
    mention: string | undefined;
    mentionedUserIds: Array<string>;
  }) => Promise<null | undefined>;
  restrictMentions: boolean;
}

export const CommentFieldDocumentContainer = ({
  onCreateComment,
  isSubmitting,
  restrictMentions,
}: CommentFieldDocumentContainerProps) => {
  // XXX CAN-2124 we have two problems with the Mentions input:
  // 1. the onSelect does not trigger when one types the name of a user
  // 2. it does not have a way of removing a user if it is deleted in the text
  // Therefore we need to manually fetch all users and compute the mentions on submit
  // we could put this logic in the BE as well
  const { data, loading: loadingMemberships } = useGetMembershipsQuery();

  const { organizationMemberships = [] } = data || {};

  return (
    <Comment
      loadingMemberships={loadingMemberships}
      memberships={organizationMemberships}
      onCreateComment={onCreateComment}
      isSubmitting={isSubmitting}
      restrictMentions={restrictMentions}
    />
  );
};
