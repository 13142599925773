import { Box, TruncatedText } from '@candisio/design-system';
import DateFormatters from 'utils/date_formatter';

export const DateCell = ({ value }: { value: Date | null }) => {
  if (!value) {
    return <Box>-</Box>;
  }

  const date = value;

  return (
    <TruncatedText wordBreak="break-word">
      {DateFormatters.compact(date)}
    </TruncatedText>
  );
};
