import {
  Box,
  Button,
  Flex,
  Grid,
  Tag,
  Text,
  Tooltip,
  usePopover,
} from '@candisio/design-system';
import {
  LottieAnimation,
  pulseBlue,
  pulsePurple,
} from 'components/Lottie/Lottie';
import {
  DateFilterOption,
  DocumentCurrency,
  useGetForeignCurrencyFeeSavingsQuery,
} from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import {
  ForeignCurrencyFeesModal,
  ForeignCurrencyFeesPromoModal,
} from './ForeignCurrencyFeesModals';

export const FOREIGN_CURRENCY_FEES_KEY = 'showForeignCurrencyFees';

export const TODAY = new Date();
export const PREVIOUS_YEAR = new Date(
  new Date().getFullYear(),
  new Date().getMonth() - 12,
  new Date().getDate()
);

export interface ForeignCurrencyFeesProps {
  isPromo?: boolean;
}

export const ForeignCurrencyFees = ({ isPromo }: ForeignCurrencyFeesProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowFxFees, setShouldShowFxFees] = useLocalStorage(
    FOREIGN_CURRENCY_FEES_KEY,
    true
  );

  const { isOpen, popoverProps, popoverRef, triggerRef, triggerProps, close } =
    usePopover();

  const { data } = useGetForeignCurrencyFeeSavingsQuery({
    variables: {
      dateRangeFilters: {
        invoiceDate: {
          dateFrom: DateFormatters.compact(PREVIOUS_YEAR),
          dateTo: DateFormatters.compact(TODAY),
          filterOption: DateFilterOption['Custom'],
        },
      },
    },
  });

  const foreignCurrencyFees = data?.getForeignCurrencyFeeSavings.aggregate;

  const estimatedFeesSaving =
    amountFormat(
      foreignCurrencyFees?.estimatedFeesSaving ?? 0,
      foreignCurrencyFees?.currency ?? DocumentCurrency.Eur
    ) ?? '';

  const totalAmount =
    amountFormat(
      foreignCurrencyFees?.totalAmount ?? 0,
      foreignCurrencyFees?.currency ?? DocumentCurrency.Eur
    ) ?? '';

  const handleClose = () => {
    setIsModalOpen(false);
    close();
  };

  const handleDismiss = () => {
    setIsModalOpen(false);
    setShouldShowFxFees(false);
    close();
  };

  const handleViewMoreInfo = () => {
    setIsModalOpen(true);
    close();
  };

  if (!shouldShowFxFees) return null;

  return (
    <>
      <Box
        ref={triggerRef}
        {...triggerProps}
        style={{ cursor: 'pointer' }}
        width="space16"
        height="space16">
        <LottieAnimation
          height="100%"
          width="100%"
          options={{
            animationData: isPromo ? pulsePurple : pulseBlue,
            loop: true,
          }}
        />
      </Box>

      {isOpen && (
        <Tooltip ref={popoverRef} {...popoverProps}>
          <Grid gap="space12">
            {isPromo && <Tag color="purple">{t('common:tag.addOn')}</Tag>}
            <Text fontSize="medium">
              {t('foreignCurrencyFees.tooltip.title')}
            </Text>

            <Text>{t('foreignCurrencyFees.tooltip.text')}</Text>

            <Flex gap="space8">
              <Button variant="secondary" onClick={handleDismiss}>
                {t('foreignCurrencyFees.tooltip.dismissButton')}
              </Button>
              {isPromo ? (
                <Button onClick={handleViewMoreInfo} color="purple">
                  {t('foreignCurrencyFees.tooltip.moreButton')}
                </Button>
              ) : (
                <Button color="blue" onClick={handleViewMoreInfo}>
                  {t('foreignCurrencyFees.tooltip.moreButton')}
                </Button>
              )}
            </Flex>
          </Grid>
        </Tooltip>
      )}

      {isPromo ? (
        <ForeignCurrencyFeesPromoModal
          isOpen={isModalOpen}
          onClose={handleClose}
          estimatedFeesSaving={estimatedFeesSaving}
          totalAmount={totalAmount}
        />
      ) : (
        <ForeignCurrencyFeesModal
          isOpen={isModalOpen}
          onClose={handleClose}
          estimatedFeesSaving={estimatedFeesSaving}
          totalAmount={totalAmount}
        />
      )}
    </>
  );
};
