import { useGetCoreDataExportStatusQuery } from 'generated-types/graphql.types';
import { useContext } from 'react';
import { ExportContext } from 'views/Integrations/Export/Context';
import { Table } from '../../types';

export const useCoreDataExportStatus = () => {
  const { table } = useContext(ExportContext);

  const { data: dbsExports, loading: isLoading } =
    useGetCoreDataExportStatusQuery({
      fetchPolicy: 'network-only',
    });

  const { hasExportedContacts = false, hasExportedPaymentConditions = false } =
    dbsExports?.evaluateCoreDataExport?.coreDataExportStatus ?? {};

  const isContactsTableActive = table === Table.CONTACTS;
  const isPaymentConditionsTableActive = table === Table.PAYMENT_CONDITIONS;

  const showExportAllContacts = isContactsTableActive && hasExportedContacts;
  const showExportAllPaymentConditions =
    isPaymentConditionsTableActive && hasExportedPaymentConditions;

  return { showExportAllContacts, showExportAllPaymentConditions, isLoading };
};
