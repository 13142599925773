import { useApolloClient } from '@apollo/client';
import { useOptimisticCommentCacheUpdate } from 'components/Comment/useOptimisticCommentCacheUpdate';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useCreateCommentOnEcmDocumentMutation,
  CommentCreateErrorKind,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import { getCommentCreateErrorTranslationKey } from './utils';

interface UseCreateCommentOnECMDocumentProps {
  documentId: string;
}

export const useCreateCommentOnEcmDocument = ({
  documentId,
}: UseCreateCommentOnECMDocumentProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const [createCommentOnEcmDocument, { loading }] =
    useCreateCommentOnEcmDocumentMutation({});

  const { cache } = useApolloClient();
  const { optimisticCommentCacheUpdate } = useOptimisticCommentCacheUpdate();

  const onCreateCommentOnEcmDocument = async ({
    mention,
    mentionedUserIds,
  }: {
    mention: string | undefined;
    mentionedUserIds?: string[];
  }) => {
    if (!mention) return null;

    const commentId = uuidV4();

    try {
      await createCommentOnEcmDocument({
        variables: {
          input: {
            text: mention,
            // TODO: GRM-108 add mentionedUserIds when we enable mentions on ECM documents
            entityId: documentId,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createCommentOnECMDocument: {
            __typename: 'CommentCreated',
            commentId: commentId,
          },
        },
        update: optimisticCommentCacheUpdate({ documentId, mention }),
        onCompleted: data => {
          if (
            data?.createCommentOnECMDocument.__typename ===
            'CommentCreatedError'
          ) {
            const errorMessage = getCommentCreateErrorTranslationKey(
              data.createCommentOnECMDocument.kind ??
                CommentCreateErrorKind.UnexpectedError
            );

            cache.evict({ fieldName: 'getAggregatedEcmDocument' });
            error(t(errorMessage));
          } else {
            success(t('comment.created'));
          }
        },
      });
    } catch (e) {
      error(t('comment.creationFailed'));
    }

    return null;
  };

  return { onCreateCommentOnEcmDocument, isSubmitting: loading };
};
