const TABLE_ROW_AVG_HEIGHT_PX = 43;
const MAX_ROWS = 40;
const MIN_AMOUNT_ROW = 1;

export const getTableCanvasDistanceFromTop = () => {
  const FALLBACK_DISTANCE_FROM_TOP = 100;
  const tableWrapper = document.querySelectorAll('[role="table"]');
  const el = tableWrapper?.length && tableWrapper[0];

  return (el && el.getBoundingClientRect().top) || FALLBACK_DISTANCE_FROM_TOP;
};

export const getApproximateNumberOfRowForTable = () => {
  const tableDistanceFromTop = getTableCanvasDistanceFromTop();
  const visibleRows = Math.floor(
    (window.innerHeight - tableDistanceFromTop) / TABLE_ROW_AVG_HEIGHT_PX
  );

  if (visibleRows < MIN_AMOUNT_ROW) {
    return MIN_AMOUNT_ROW;
  }

  return visibleRows > MAX_ROWS ? MAX_ROWS : visibleRows;
};
