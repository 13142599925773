import { useTeamQuery } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import DateFormatters from 'utils/date_formatter';
import { Member, Team } from './useGetTeams';

interface UseGetTeamByIdParams {
  id: string | undefined;
}

export const useGetTeamById = ({ id }: UseGetTeamByIdParams) => {
  const teamsManagementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.teamsManagement
  );

  const { data, loading } = useTeamQuery({
    variables: { id: id ?? '' },
    skip: !id || !teamsManagementFF,
  });

  const fetchedTeam = data?.team;

  const teamAdmin = (fetchedTeam?.members ?? []).find(member => member.isAdmin);

  const members: Member[] = (fetchedTeam?.members ?? [])
    .filter(member => !member.isAdmin)
    .map(member => ({
      id: member.membership.id,
      fullName: `${member.membership.firstName} ${member.membership.lastName}`,
      avatarUrl: member.membership.avatarUrl ?? undefined,
    }));

  const team: Team = {
    id: fetchedTeam?.id ?? '',
    isArchived: fetchedTeam?.isArchived ?? false,
    members,
    name: fetchedTeam?.name ?? '',
    updatedAt: fetchedTeam?.updatedAt
      ? DateFormatters.compactDatetime(new Date(fetchedTeam?.updatedAt))
      : undefined,
    teamAdmin: {
      id: teamAdmin?.membership.id,
      fullName: `${teamAdmin?.membership.firstName} ${teamAdmin?.membership.lastName}`,
      avatarUrl: teamAdmin?.membership.avatarUrl ?? undefined,
    },
  };

  return { team, loading };
};
