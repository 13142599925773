import { TruncatedText, Text } from '@candisio/design-system';
import { friendlyFormatIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

export const IbanCell = ({
  value,
}: CellProps<ReimbursementTableRow, string | undefined>) => {
  const [t] = useTranslation();

  if (isNil(value)) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  const formattedIban = friendlyFormatIBAN(value);

  if (!formattedIban) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  return <TruncatedText>{formattedIban}</TruncatedText>;
};
