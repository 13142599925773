import gql from 'graphql-tag';

export const notificationsQuery = gql`
  query getNotifications {
    notifications {
      records {
        id
        data {
          ... on EmailImportErrorNotification {
            fromAddress
            emailMessageId
          }
        }
      }
    }
  }
`;

export const clearAllNotifications = gql`
  mutation clearAllNotifications {
    clearAllNotifications
  }
`;
