import { Grid } from '@candisio/design-system';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { AccountingAreaFormSkeleton } from 'views/Settings/AccountingAreas/elements/Drawer/AccountingAreaFormSkeleton';
import { AccountingAreasDrawerProps } from 'views/Settings/AccountingAreas/elements/Drawer/AccountingAreasDrawer';
import {
  AccountingAreasFormOutput,
  accountingAreasFormSchema,
} from 'views/Settings/AccountingAreas/elements/Drawer/Form/accountingAreasFormSchema';
import { accountingAreasFormErrorMessages } from 'views/Settings/AccountingAreas/elements/Drawer/Form/accountingAreasFromErrorMessages';

export interface AccountingAreasFormProps {
  /** Unique identifier of this form */
  formId: string;
  /** Called when form is submitted */
  onSubmit: (values: AccountingAreasFormOutput) => Promise<void>;
  /** Form is read-only */
  readOnly?: boolean;
  /** Default values for the form */
  defaultAccountingArea?: AccountingAreasDrawerProps['accountArea'];
  /** Loading state of the form */
  loading?: boolean;
}

export const AccountingAreasForm = ({
  formId,
  onSubmit,
  readOnly,
  defaultAccountingArea,
  loading,
}: AccountingAreasFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const form = useForm<AccountingAreasFormOutput>({
    defaultValues: {
      name: defaultAccountingArea?.name ?? '',
      description: defaultAccountingArea?.description ?? '',
    },
    mode: 'onSubmit',
    resolver: zodResolver({
      zodSchema: accountingAreasFormSchema,
      errorMessages: accountingAreasFormErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
  });

  if (loading) {
    return <AccountingAreaFormSkeleton />;
  }

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        gap="space24"
        id={formId}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.stopPropagation();
          void form.handleSubmit(values => {
            return onSubmit(values);
          })(e);
        }}
        paddingTop="space36">
        <HookFormTextField
          autoFocus
          label={t('accountingAreas.drawer.form.fields.name.label')}
          name="name"
          placeholder={t('accountingAreas.drawer.form.fields.name.placeholder')}
          readOnly={readOnly}
        />
        <HookFormTextareaField
          label={t('accountingAreas.drawer.form.fields.description.label')}
          name="description"
          placeholder={t(
            'accountingAreas.drawer.form.fields.description.placeholder'
          )}
          minRows={4}
          readOnly={readOnly}
        />
      </Grid>
    </FormProvider>
  );
};
