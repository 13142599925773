import { Button, Flex, Image, Paragraph, Tag } from '@candisio/design-system';
import { creditCardInfoHash } from 'containers/credit-cards/components/CreditCardsSection/utils';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardInfoModal } from 'views/Dashboard/components/CreditCardInfoModal';
import SettlementsDE from '../../../toolkit/images/cc-promo/settlements-de.svg';
import SettlementsEN from '../../../toolkit/images/cc-promo/settlements-en.svg';

export const CreditCardsSettlementsPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const user = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();

  const locale = user?.locale;

  const handleClick = () => {
    navigate({
      pathname: location.pathname,
      search: location.search,
      hash: `${creditCardInfoHash}`,
    });
  };

  const openModal = location.hash === creditCardInfoHash;

  return (
    <Flex direction="column" gap="1rem" padding="1rem">
      <Flex direction="column">
        <Tag variant="secondary" color="purple">
          {t('common:tag.addOn')}
        </Tag>
        <Paragraph fontSize="xxlarge">
          {t('promo.creditCards.settlements.title')}
        </Paragraph>
      </Flex>
      <Image
        src={locale === Locale.En ? SettlementsEN : SettlementsDE}
        alt="credit-card-rows"
        width="100%"
        alignSelf="center"
      />
      <Flex justifyContent="center" gap="1rem">
        <Button
          color="purple"
          icon="creditCardOutline"
          width="fit-content"
          onClick={handleClick}>
          {t('promo.creditCards.settlements.buttonCta')}
        </Button>
      </Flex>

      <CreditCardInfoModal
        isOpen={openModal}
        onClose={() => {
          navigate(
            {
              pathname: location.pathname,
              search: location.search,
            },
            { replace: true }
          );
        }}
        routeType="Export"
      />
    </Flex>
  );
};
