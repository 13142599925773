import {
  Card,
  Grid,
  Image,
  ScrollBox,
  Separator,
  Text,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import recieptIcon from '../../media/receipt.svg';

export const EmptyReimbursementDetailsCard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <ScrollBox scrollDirection="none" scrollbarGutter="stable">
      <Card padding="space24">
        <Grid gap="space12">
          <Text fontSize="large" fontWeight="semibold">
            {t('reimbursementView.leftSection.emptyDetails.title')}
          </Text>
          <Separator background="gray250" />
          <Grid
            gap="space12"
            templateColumns="repeat(3, auto)"
            placeContent="center">
            <Card background="pink100" opacity="50%">
              <Image src={recieptIcon} alt="reciept icon" />
            </Card>
            <Card background="pink100">
              <Image src={recieptIcon} alt="reciept icon" />
            </Card>
            <Card background="pink100" opacity="50%">
              <Image src={recieptIcon} alt="reciept icon" />
            </Card>
          </Grid>
          <Text textAlign="center" fontSize="basic">
            {t('reimbursementView.leftSection.emptyDetails.description')}
          </Text>
        </Grid>
      </Card>
    </ScrollBox>
  );
};
