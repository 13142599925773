import {
  CardIssuerSettlementMethod,
  CardIssuerSettlementStatus,
  CardIssuerSettlementType,
} from 'generated-types/graphql.types';

export const settlementMethodTranslationKey: Record<
  CardIssuerSettlementMethod,
  string
> = {
  DIRECT_DEBIT_B2B: 'statements.settlementWidget.method.DIRECT_DEBIT_B2B',
  DIRECT_DEBIT_CORE: 'statements.settlementWidget.method.DIRECT_DEBIT_CORE',
  WIRE_TRANSFER: 'statements.settlementWidget.method.WIRE_TRANSFER',
};

export const settlementTypeTranslationKey: Record<
  CardIssuerSettlementType,
  string
> = {
  BILL: 'statements.settlementWidget.typeWithId.BILL',
  BILL_PAYMENT_REVERSAL:
    'statements.settlementWidget.typeWithId.BILL_PAYMENT_REVERSAL',
  DIRECT_DEBIT_REVERSAL:
    'statements.settlementWidget.typeWithId.DIRECT_DEBIT_REVERSAL',
  OTHER: 'statements.settlementWidget.typeWithId.OTHER',
  PAYOUT: 'statements.settlementWidget.typeWithId.PAYOUT',
  TOPUP: 'statements.settlementWidget.typeWithId.TOPUP',
};

export const settlementStatusTranslationKey: Record<
  CardIssuerSettlementStatus,
  string
> = {
  BOOKED: 'statements.settlementWidget.status.BOOKED',
  DEFERRED: 'statements.settlementWidget.status.DEFERRED',
  DISCARDED: 'statements.settlementWidget.status.DISCARDED',
  FAILED: 'statements.settlementWidget.status.FAILED',
  PAID: 'statements.settlementWidget.status.PAID',
  PENDING: 'statements.settlementWidget.status.PENDING',
  PLANNED: 'statements.settlementWidget.status.PLANNED',
  READY_FOR_PMT: 'statements.settlementWidget.status.READY_FOR_PMT',
  REVERSED: 'statements.settlementWidget.status.REVERSED',
  UPCOMING: 'statements.settlementWidget.status.UPCOMING',
};
