import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { SortingRule } from 'react-table';

interface Params<TableDataType extends object> {
  sortBy: SortingRule<TableDataType>[];
  newConfigurations: Configuration[];
}

export const hasHiddenColumnWithSortApplied = <TableDataType extends object>({
  newConfigurations,
  sortBy,
}: Params<TableDataType>) => {
  if (!sortBy.length) {
    return false;
  }

  const hiddenColumns = newConfigurations
    .filter(configuration => !configuration.isVisible)
    .map(configuration => configuration.id);

  const hasHiddenColumnWithSortApplied = hiddenColumns.some(
    column => column === sortBy[0].id
  );

  return hasHiddenColumnWithSortApplied;
};
