import { useApolloClient } from '@apollo/client';
import { Grid } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { Header } from './components/LeftSection/Header';
import { HistoryAndCommentSection } from './components/LeftSection/HistoryAndCommentSection';
import { ReimbursementItemsCardContainer } from './components/LeftSection/ReimbursementItemsCardContainer';
import { ReimbursementSection } from './components/LeftSection/ReimbursementSection';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { SectionLayout } from './components/SectionLayout';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';
import { VIEW_URL_PARAM } from './Reimbursement';

interface LeftSectionContainerProps {
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
}

export const LeftSectionContainer = ({
  formHelpers,
}: LeftSectionContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const isViewFormMode = !!searchParams.get(VIEW_URL_PARAM);
  const { reimbursementItems } = useReimbursementExpenseItemsList();
  const { reimbursement, loading } = useGetReimbursementById({
    reimbursementId,
  });

  const { cache } = useApolloClient();

  const { title, totalAmount, status } = reimbursement ?? {};

  const handleBack = () => {
    if (isViewFormMode) {
      updateSearchParam(VIEW_URL_PARAM, '');

      return;
    }

    navigate({ pathname: '..', search }, { relative: 'path' });

    // It Makes sure that the scrollable list is always up to date when leaving the view and re-entering

    cache.evict({ fieldName: 'reimbursementItems' });
  };

  return (
    <SectionLayout
      headerElement={
        <Header
          backText={t('reimbursementView.leftSection.header.backAction')}
          onBackClick={handleBack}
        />
      }>
      <Grid gap="space16" overflowY="auto" templateRows="auto auto 1fr">
        <ReimbursementSection
          loading={loading}
          title={title}
          status={status}
          totalAmount={totalAmount}
        />
        {Boolean(reimbursementItems.length) && isViewFormMode && (
          <ReimbursementItemsCardContainer
            reimbursementItems={reimbursementItems}
            formHelpers={formHelpers}
          />
        )}
        <HistoryAndCommentSection />
      </Grid>
    </SectionLayout>
  );
};
