import { z } from 'zod';

const approvalSchema = () => {
  return formSchema
    .pick({
      approvalMode: true,
      approvers: true,
      workflow: true,
    })
    .superRefine(({ approvalMode, approvers, workflow }, ctx) => {
      if (approvalMode === 'approvers') {
        if ((approvers ?? []).length > 1) {
          (approvers ?? []).forEach((approver, index) => {
            if (approver.approvers.length < 1) {
              ctx.addIssue({
                code: 'invalid_type',
                path: ['approvers', index, 'approvers'],
                expected: 'array',
                received: 'null',
              });
            }
          });
        }
      }
    });
};

export const formSchema = z.object({
  approvalMode: z.enum(['approvers', 'workflow']),
  costCenter: z.object({
    value: z.string().nullish(),
    inputValue: z.string().nullish(),
  }),
  costObject: z.object({
    value: z.string().nullish(),
    inputValue: z.string().nullish(),
  }),
  contact: z.object({
    value: z.string().nullish(),
    inputValue: z.string().nullish(),
  }),
  taxCode: z.string().nullish(),
  workflow: z.string().nullish(),
  generalLedgerAccount: z.object({
    value: z.string().nullish(),
    inputValue: z.string().nullish(),
  }),
  artistSocialInsuranceCode: z.string().nullish(),
  extraCostInfo: z.object({
    value: z.string().nullish(),
    inputValue: z.string().nullish(),
  }),
  approvers: z.array(z.object({ approvers: z.array(z.string()) })).optional(),
  noInvoiceNeeded: z.boolean().optional(),
});

export const accountingInfoFormSchema = () => formSchema.and(approvalSchema());

export type AccountingInfoFormSchemaFormValues = z.infer<
  ReturnType<typeof accountingInfoFormSchema>
>;
