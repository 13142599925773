import { CoreDataTypes } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { CoreDataImportHistory } from 'views/CoreDataImportHistory/CoreDataImportHistory';

export const TaxCodeImportHistory = () => {
  const { shouldUseCoreDataApiTaxCodes } = useOtherIntegration();

  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);

  const organizationSlug = useOrganizationId();
  const taxCodeUrl = `/${organizationSlug}${Routes.SETTINGS}${Routes.TAX_CODES}`;

  const coreDataType = CoreDataTypes.BookingKey;

  if (shouldUseCoreDataApiTaxCodes) {
    return (
      <CoreDataImportHistory
        coreDataType={coreDataType}
        title={t('importHistory.title')}
        backButtonLabel={t('booking-keys:importHistory.backToTaxCodes')}
        backButtonLink={taxCodeUrl}
        localeNamespace={LOCALE_NAME_SPACE.TAX_CODES}
      />
    );
  }
};
