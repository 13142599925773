import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import {
  HookFormAmountField,
  HookFormAmountFieldProps,
} from 'components/HookFormFields/HookFormAmountField';
import { useTranslation } from 'react-i18next';

export const TaxAmount = ({
  currency,
  flex,
}: Partial<HookFormAmountFieldProps<DocumentApprovalFormValues>>) => {
  const [t] = useTranslation();

  return (
    <HookFormAmountField
      flex={flex}
      name="taxAmount"
      currency={currency}
      label={t('document.requestApproval.inputs.taxAmount.label')}
      placeholder={t('document.requestApproval.inputs.taxAmount.placeholder')}
      readOnly
    />
  );
};
