import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

export const useEcm = () => {
  const [
    contractsCustomSubtypesFF,
    documentRelationsFF,
    documentTagsFF,
    ecmContractManagementFF,
    ecmDocumentsDownloadFF,
    ecmSensitiveDocumentsFF,
    enterpriseContentManagementFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.contractsCustomSubtypes,
    FEATURE_FLAGS.documentRelations,
    FEATURE_FLAGS.documentTags,
    FEATURE_FLAGS.ecmContractManagement,
    FEATURE_FLAGS.ecmDocumentsDownload,
    FEATURE_FLAGS.ecmSensitiveDocuments,
    FEATURE_FLAGS.enterpriseContentManagement,
  ]);

  const { isAdmin, isRequester } = useUserRoles();
  const entitlements = useEntitlementsFlag();
  const { ft, show, hide } = useFeatureToggle();

  const canAccessEcm = isAdmin || isRequester;
  const ecmAvailable = entitlements !== Entitlements.LEGACY;

  if (ecmAvailable) {
    return {
      showEcm: true,
      isDocumentRelationsAvailable: !hide(ft.DOCUMENT_RELATIONS),
      showContractCustomSubCategories:
        contractsCustomSubtypesFF && show(ft.CONTRACTS),
      showDocumentRelations: show(ft.DOCUMENT_RELATIONS),
      showEcmContractManagement: show(ft.CONTRACTS),
      showEcmDocumentsDownload: show(ft.ECM),
      showEcmSensitiveDocuments: show(ft.CONTRACTS),
      showDocumentTags: documentTagsFF,
      showImprovedSearch: show(ft.FULL_TEXT_SEARCH),
      canAccessEcm,
    };
  }

  return {
    showEcm: enterpriseContentManagementFF,
    isDocumentRelationsAvailable:
      enterpriseContentManagementFF && documentRelationsFF,
    showContractCustomSubCategories:
      enterpriseContentManagementFF &&
      ecmContractManagementFF &&
      contractsCustomSubtypesFF,
    showDocumentRelations: enterpriseContentManagementFF && documentRelationsFF,
    showEcmContractManagement:
      enterpriseContentManagementFF && ecmContractManagementFF,
    showEcmDocumentsDownload:
      enterpriseContentManagementFF && ecmDocumentsDownloadFF,
    showEcmSensitiveDocuments:
      enterpriseContentManagementFF &&
      ecmContractManagementFF &&
      ecmSensitiveDocumentsFF,
    showDocumentTags: enterpriseContentManagementFF && documentTagsFF,
    showImprovedSearch: enterpriseContentManagementFF,
    canAccessEcm,
  };
};
