import {
  DocumentsTableData,
  DocumentTableColumnKeys,
} from 'components/DocumentsTable/types';
import { ExportStatus } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useOtherIntegration } from 'orgConfig/other';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { ComponentProps } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useGetColumnIds } from 'views/Integrations/Export/hooks/useGetColumnIds';
import {
  defaultExportFilters,
  DocumentsTable,
} from 'views/Integrations/Export/toolkit/components/DocumentsTable';
import { EmptyExportView } from 'views/Integrations/Export/toolkit/components/EmptyState/ExportEmptyState';
import {
  ExportTablesWithSepartors,
  ExportedDocumentsTable,
  groupExportDocuments,
  mapGroupToTables,
} from 'views/Integrations/Export/toolkit/components/ExportedDocumentsTable';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { ExportEntityRow, View } from 'views/Integrations/Export/types';
import { buildExportFilters } from '../utils';
import { toDocumentTableData } from './utils';

type ApprovalsTableProps = {
  data: ExportEntityRow[];
  isLoading: boolean;
  isExporting: boolean;
  onEndReached?: () => void;
};

export const Approvals = ({
  data,
  isLoading,
  isExporting,
  onEndReached,
}: ApprovalsTableProps) => {
  const [errorVisibilityImprovementFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.errorVisibilityImprovement,
  ]);

  const [search] = useSearchParams();
  const state = useExportContext();
  const tableId = state.tableId;
  const { failedDocuments } = useExportDataGigaFactory(state);

  const columnIds = useGetColumnIds<DocumentTableColumnKeys>({
    tableId,
  });

  const tableDataForNewExport = data.map(toDocumentTableData(failedDocuments));

  const searchFilter = buildExportFilters(defaultExportFilters, search);
  const tableDataForReExports = data
    .filter(searchFilter)
    .map(toDocumentTableData(failedDocuments));

  if (
    isLoading ||
    isExporting ||
    state.view !== View.HISTORY ||
    !errorVisibilityImprovementFF
  ) {
    return (
      <DocumentsTable
        columns={columnIds}
        data={tableDataForNewExport}
        isLoading={isLoading}
        onEndReached={onEndReached}
        key={`${tableId}-${columnIds.join('-')}`}
      />
    );
  }

  const {
    exportedDocuments,
    partiallyExportedDocuments,
    groupedFailedDocuments,
  } = groupExportDocuments(tableDataForReExports);

  const Table = (props: ComponentProps<typeof ExportedDocumentsTable>) => (
    <ExportedDocumentsTable
      columns={columnIds}
      data={props.data}
      variant={props.variant}
      code={props.code}
    />
  );

  const tables = [
    ...mapGroupToTables(groupedFailedDocuments, Table, 'error'),
    ...mapGroupToTables(partiallyExportedDocuments, Table, 'warning'),
    ...mapGroupToTables(exportedDocuments, Table, 'success'),
  ];

  return <ExportTablesWithSepartors tables={tables} />;
};

export const ApprovalsTransactionsContainer = () => {
  const state = useExportContext();
  const {
    exportRowEntities: {
      ready: { transactionEntities },
      notReady: { notReadyTransactionsAsExportRows },
    },
    isLoading: { loadingCardTransactions, loadingCardTransactionsNotReady },
    exportDetails,
  } = useExportDataGigaFactory(state);

  const isNotReadyView = state.view === View.NOT_READY;

  const data = isNotReadyView
    ? notReadyTransactionsAsExportRows
    : transactionEntities;

  const isEmpty = data.length === 0;
  const isLoading = loadingCardTransactions || loadingCardTransactionsNotReady;

  const isExporting = exportDetails?.status === ExportStatus.Exporting;

  const { shouldUseCandisApi } = useOtherIntegration();

  if (!isLoading && isEmpty) {
    return <EmptyExportView displayExportOptionIcons={!shouldUseCandisApi} />;
  }

  return (
    <Approvals data={data} isLoading={isLoading} isExporting={isExporting} />
  );
};

export const ApprovalsDocumentsContainer = () => {
  const state = useExportContext();
  const { filters } = useUrlBasedSortAndFilter<DocumentsTableData>({
    availableFilters: defaultExportFilters,
  });

  const {
    paginatedDocumentsTableData,
    exportRowEntities: {
      ready: { documentEntities },
    },
    isLoading: { loadingExportById, loadingExportTableData },
    exportDetails,
  } = useExportDataGigaFactory(state);

  const isNotReadyView = state.view === View.NOT_READY;

  const data = isNotReadyView ? [] : documentEntities;

  const isEmpty = data.length === 0;

  const isLoading = loadingExportById || loadingExportTableData;

  const isExporting = exportDetails?.status === ExportStatus.Exporting;

  const isTableFiltered = Boolean(filters.length);

  if (!isLoading && isEmpty && !isTableFiltered) {
    return <EmptyExportView />;
  }

  return (
    <Approvals
      data={data}
      onEndReached={paginatedDocumentsTableData.onLoadMore}
      isLoading={isLoading}
      isExporting={isExporting}
    />
  );
};
