import { Box, Image, Tag } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { PromotionCard } from 'components/NewPromotions/PromotionCard/PromotionCard';
import { Promotion } from 'components/NewPromotions/types';
import { VideoCard } from 'containers/Entitlements/components/Upsell/components/VideoCard';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import teaserDe from './toolkit/assets/teaser-de.png';
import teaserEn from './toolkit/assets/teaser-en.png';

const DOCUMENT_TAGS_GRID_WIDTH = '50rem';

const thumbnails = {
  [Locale.De]: teaserDe,
  [Locale.En]: teaserEn,
};

export const DocumentTagsPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const videoTourThumbnail = thumbnails[locale];

  const promotion: Promotion = t('promotions:documentTagsPromo', {
    returnObjects: true,
  });

  promotion.label = (
    <Tag color="purple" variant="secondary">
      {t('common:tag.addOn')}
    </Tag>
  );

  return (
    <SettingsLayout
      title={t('documentTags.title')}
      width={DOCUMENT_TAGS_GRID_WIDTH}>
      <Box height="450px">
        <PromotionCard promotion={promotion} />
      </Box>
      <InfoPanel
        sections={t('documentTags.infoPanel.sections', { returnObjects: true })}
        title={t('documentTags.infoPanel.title')}
        intercomLink={
          <IntercomLink
            translation={t('documentTags.infoPanel.feedbackButton')}
            id="settings-tags-intercom-link"
          />
        }>
        <VideoCard
          videoTourUrl={t('documentTags.drawer.links.videoTour.url')}
          videoTourTitle={t('documentTags.drawer.links.videoTour.label')}
          withFilter>
          <Image src={videoTourThumbnail} alt="" height="10rem" />
        </VideoCard>
      </InfoPanel>
    </SettingsLayout>
  );
};
