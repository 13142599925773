import { Box, Flex, Grid } from '@candisio/design-system';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { TooltipIcon } from 'components/Icons/TooltipIcon';
import { IntegrationName } from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatFromCentsWithCurrency } from 'views/CreditCards/utils/formatFromCentsWithCurrency';

export default function useAmountFieldsTooltip(
  bookings: ProcessingFormValues['bookings']
) {
  const [t] = useTranslation();
  const integration = useIntegrationSettings();
  const bookingWithTaxAmounts = bookings.filter(booking => booking.taxAmount);
  const currency = useWatch<ProcessingFormValues, 'currency'>({
    name: 'currency',
  });

  if (integration === IntegrationName.Sap) {
    return {
      roundingAmount: (
        <TooltipIcon>
          <Box width="space256">
            {t('document.requestApproval.inputs.roundingAmount.tooltip')}
          </Box>
        </TooltipIcon>
      ),
      netAmount: (
        <TooltipIcon>
          <Box width="space256">
            {t('document.requestApproval.inputs.netAmount.tooltip_sap')}
          </Box>
        </TooltipIcon>
      ),
    };
  }

  return {
    netAmount: (
      <TooltipIcon>
        <Box width="space256" paddingBottom="space4">
          {t('document.requestApproval.inputs.netAmount.tooltip')}
        </Box>
      </TooltipIcon>
    ),
    taxAmount:
      bookingWithTaxAmounts.length > 1 ? (
        <TooltipIcon>
          <Box width="space256">
            {bookingWithTaxAmounts.map(booking => (
              <Flex key={booking.bookingId}>
                <Grid width="space96">
                  {booking.vatRate}%{' '}
                  {t('document.requestApproval.inputs.taxAmount.taxPostfix')}.
                </Grid>
                <Grid>
                  {formatFromCentsWithCurrency(
                    currency,
                    (booking.taxAmount ?? 0) * 100
                  )}
                </Grid>
              </Flex>
            ))}
            <Flex color="gray500" paddingY="space4">
              {t('document.requestApproval.inputs.taxAmount.tooltip')}
            </Flex>
          </Box>
        </TooltipIcon>
      ) : (
        <TooltipIcon>
          <Box width="space256" paddingBottom="space4">
            {t('document.requestApproval.inputs.taxAmount.tooltip')}
          </Box>
        </TooltipIcon>
      ),
  };
}
