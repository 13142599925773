export enum BOOKING_ACCOUNT_ROUTE_HASH {
  import = '#import',
}

export enum BOOKING_ACCOUNTS_SEARCH_PARAM {
  accountCode = 'accountCode',
  archive = 'archive',
  name = 'name',
  sort = 'sort',
}

export const BOOKING_ACCOUNT_CREATE_ROUTE = 'create';
export const GENERAL_LEDGER_ACCOUNTS_WIDTH = '50rem';
