import { Grid, Text } from '@candisio/design-system';
import { ReactNode } from 'react';

interface SetupStepProps {
  stepNumber: `${number}`;
  children: ReactNode;
}

export const SetupStep = ({ stepNumber, children }: SetupStepProps) => {
  return (
    <Grid
      templateColumns="max-content auto"
      columnGap="space8"
      rowGap="space128">
      <Text>{stepNumber}.</Text>
      <Grid gap="space24">{children}</Grid>
    </Grid>
  );
};
