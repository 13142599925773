import { Box, Button, Grid, Heading, ScrollBox } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useProcessingFormOverlayContext } from 'views/Inbox/DocumentProcessing/components/AddContact/ProcessingFormOverlay';

export interface SplitBookingsLayoutProps {
  children: ReactNode;
  header?: ReactNode;
  title: string;
}

/** Layout for split bookings overlay (instance of ProcessingFormOverlay) */
export const SplitBookingsLayout = ({
  children,
  header,
  title,
}: SplitBookingsLayoutProps) => {
  const [t] = useTranslation();
  const { onClose, headingId } = useProcessingFormOverlayContext();

  return (
    <Grid height="100%" templateRows="auto 1fr" borderTop="1px solid gray250">
      <Box
        boxShadow="elevatedShadow1"
        background="white"
        padding="space16 space24"
        zIndex={1}>
        <Grid gap="space24">
          <Grid alignItems="center" templateColumns="1fr auto">
            <Heading as="h2" id={headingId} left="0">
              {title}
            </Heading>

            <Button
              right="0"
              icon="close"
              label={t('common.close')}
              onClick={onClose}
              variant="tertiary"
            />
          </Grid>
          <Box right="0">{header}</Box>
        </Grid>
      </Box>
      <ScrollBox
        scrollDirection="y"
        background="gray200"
        paddingTop="space16"
        paddingX="space12">
        {children}
      </ScrollBox>
    </Grid>
  );
};
