import { FieldPolicy, TypePolicies } from '@apollo/client';
import { PurchaseOrderConnection } from 'generated-types/graphql.types';
import { paginationWithRecordConnectionsMergeNoNodes } from '../pagination-results-merge';

const purchaseOrdersListPagination: FieldPolicy<PurchaseOrderConnection> = {
  keyArgs: ['query', 'queryFields', 'filters', 'sortBy'],

  merge: paginationWithRecordConnectionsMergeNoNodes('PurchaseOrderConnection'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      inboxPurchaseOrders: purchaseOrdersListPagination,
    },
  },
};
