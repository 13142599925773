import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';

export const ExportingProgressBar = () => {
  const [t] = useTranslation();

  return (
    <ProgressBar completion={0}>
      {t('export.exportForm.exportDatev.exporting')}
    </ProgressBar>
  );
};
