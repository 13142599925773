import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useInitialReimbursementFormValues } from 'views/Reimbursement/hooks/useInitialReimbursementFormValues';
import { useReimbursementFormFieldOptions } from '../../hooks/useReimbursementFormFieldOptions';
import { ReimbursementForm } from './ReimbursementForm';

interface ReimbursementFormContainerProps {
  reimbursement?: Reimbursement;
}

export const ReimbursementFormContainer = ({
  reimbursement,
}: ReimbursementFormContainerProps) => {
  const fieldOptions = useReimbursementFormFieldOptions();
  const { values, defaultValues } =
    useInitialReimbursementFormValues(reimbursement);

  return (
    <ReimbursementForm
      values={values}
      defaultValues={defaultValues}
      fieldOptions={fieldOptions}
    />
  );
};
