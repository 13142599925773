import { Flex, Skeleton } from '@candisio/design-system';

export const WidgetFormSkeleton = () => {
  return (
    <Flex>
      <Flex direction="column" width="100%">
        <Skeleton height="1rem" width="80%" style={{ marginBottom: '1rem' }} />
        <Skeleton height="2rem" width="80%" style={{ marginBottom: '1rem' }} />
        <Skeleton height="2rem" width="80%" style={{ marginBottom: '2rem' }} />
        <Skeleton height="1rem" width="80%" style={{ marginBottom: '1rem' }} />
        <Skeleton height="2rem" width="80%" style={{ marginBottom: '1rem' }} />
        <Skeleton height="3rem" width="80%" />
      </Flex>
      <Flex direction="column" width="100%">
        <Skeleton
          height="10rem"
          width="100%"
          style={{ marginBottom: '13rem' }}
        />

        <Skeleton height="2rem" width="100%" />
      </Flex>
    </Flex>
  );
};
