import { Grid, Spinner } from '@candisio/design-system';
import { AccountingArea } from 'components/Form/DocumentForm/toolkit/fields/AccountingArea';
import { NetAmount } from 'components/Form/DocumentForm/toolkit/fields/NetAmount';
import { InvoiceNumber } from 'components/Form/DocumentForm/toolkit/fields/Number';
import { PurchaseOrderNumber } from 'components/Form/DocumentForm/toolkit/fields/PurchaseOrderNumber';
import { RoundingAmount } from 'components/Form/DocumentForm/toolkit/fields/RoundingAmount';
import { SplitGrossAmount } from 'components/Form/DocumentForm/toolkit/fields/SplitGrossAmount';
import { TaxAmount } from 'components/Form/DocumentForm/toolkit/fields/TaxAmount';
import SectionSeparator from 'components/SectionSeparator/SectionSeparator';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { has, isEmpty } from 'lodash';
import { FormEvent, ReactNode, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { ProcessingFormOverlay } from 'views/Inbox/DocumentProcessing/components/AddContact/ProcessingFormOverlay';
import { SplitBookingsFormContainer } from 'views/Inbox/DocumentProcessing/SplitBookingsFormContainer';
import { useMakeBankingDetailsReadOnly } from 'views/utils/useShouldShowField';
import { getProcessingFormErrorMessages } from '../ProcessingForm/processingFormErrorMessages';
import { CashDiscountFieldValue } from '../types';
import { DocumentFormActions } from './DocumentFormActions';
import { DocumentFormProvider } from './DocumentFormProvider';
import { AccountsPayable } from './toolkit/fields/AccountsPayable';
import { AccountsReceivable } from './toolkit/fields/AccountsReceivable';
import { ArtistSocial } from './toolkit/fields/ArtistSocial';
import { BankingDetails } from './toolkit/fields/BankingDetails';
import { BookingKey } from './toolkit/fields/BookingKey';
import { CashDiscount } from './toolkit/fields/CashDiscount';
import { Contact } from './toolkit/fields/Contact';
import { InvoiceCorrection } from './toolkit/fields/Correction';
import { CostCenter } from './toolkit/fields/CostCenter';
import { CostObject } from './toolkit/fields/CostObject';
import { InvoiceDates } from './toolkit/fields/Dates';
import { DocumentType } from './toolkit/fields/DocumentType';
import { DueDate } from './toolkit/fields/DueDate';
import { ExtraCostInfo } from './toolkit/fields/ExtraCostInfo';
import { GeneralLedgerAccount } from './toolkit/fields/GeneralLedgerAccount';
import { GrossAmount } from './toolkit/fields/GrossAmount';
import { Note } from './toolkit/fields/Note';
import { PostingText } from './toolkit/fields/PostingText';
import { SplitBookingsList } from './toolkit/fields/SplitBookingsList';
import { VatRate } from './toolkit/fields/VatRate';
import { useUpdatePaymentData } from './toolkit/hooks/useUpdateDocumentData';
import {
  DocumentFormSchemaOptions,
  DocumentFormValues,
  documentFormSchema,
} from './toolkit/schema';
import { FieldConditions } from './toolkit/types';

export interface DocumentFormProps {
  allowInlineEditing?: boolean;
  documentId?: string;
  fieldConditions?: FieldConditions;
  footer?: ReactNode;
  initialValues?: DocumentFormValues;
  isEditable?: boolean;
  isLoading?: boolean;
  reRequestApproval?: () => void;
  tagsField?: ReactNode;
}

export const DocumentForm = ({
  documentId,
  fieldConditions,
  footer = null,
  initialValues,
  isEditable,
  isLoading = false,
  reRequestApproval,
  tagsField,
}: DocumentFormProps) => {
  const [t] = useTranslation();
  const [defaultSplitIndex, setDefaultSplitIndex] = useState(-1);
  const [mode, setMode] = useState<'default' | 'split-bookings'>('default');

  const makeBankingDetailsReadOnly = useMakeBankingDetailsReadOnly();

  const form = useForm<DocumentFormValues, DocumentFormSchemaOptions>({
    context: {
      hasTransactionLinked: has(initialValues, 'transactions'),
    },
    defaultValues: initialValues,
    mode: 'all',
    resolver: isEditable
      ? zodResolver({
          zodSchema: documentFormSchema,
          errorMessages: getProcessingFormErrorMessages(),
        })
      : undefined,
  });

  const { update, updating: isUpdatingDocument } = useUpdatePaymentData({
    documentId: documentId,
  });

  const bookingsFieldArray = useFieldArray<DocumentFormValues>({
    control: form.control,
    name: 'bookings',
  });

  const {
    bookings,
    cashDiscount,
    contactId,
    currency,
    hasTransactions,
    invoiceDate,
    invoiceDeliveryDate,
    paymentStatus,
    invoicePostingDate,
  } = initialValues ?? {};

  const {
    showAccountsPayableNumberField,
    showAccountsReceivableNumberField,
    shouldUseSapPurchaseOrder,
    showDueDateField,
    showCashDiscountFields,
    showFormField,
    showSplitPostingText,
    showGeneralLedgerAccountId,
    showBookingAccount,
    showCostCenter,
    showCostObject,
    showArtistSocial,
    showExtraCostInfo,
    showDeliveryDate,
    showPostingDate,
    showInvoiceCorrection,
    showPurchaseOrderNumber,
    shouldUseSapNetAmount,
    showNetAndTaxAmountFields,
    showAccountingArea,
  } = fieldConditions ?? {};

  const handleSubmit = form.handleSubmit(async (values: DocumentFormValues) => {
    await update(values, initialValues);

    form.reset({
      ...initialValues,
      ...values,
    });
  });

  const isEditing = !isEmpty(form.formState.dirtyFields) && isEditable;
  const splits = form.getValues().bookings;
  const { setData: setBookingsFormData } = useBookingsFormContext();

  useEffect(() => {
    if (shouldUseSapPurchaseOrder) {
      setBookingsFormData(
        splits.map(split => ({
          bookingId: split?.bookingId ?? '',
          price: {
            amount: split.amount ?? 0,
            currency: form.getValues('currency') as DocumentCurrency,
            precision: 0,
          },
          quantity: split.quantity ?? 0,
          description: split.note ?? '',
        }))
      );
    }
  }, [shouldUseSapPurchaseOrder, splits, setBookingsFormData, form]);

  return isLoading ? (
    <Grid alignContent="center" height="100%" justifyContent="center">
      <Spinner size="space64" />
    </Grid>
  ) : (
    <DocumentFormProvider bookingsFieldArray={bookingsFieldArray} form={form}>
      <Grid
        as="form"
        gap="space16"
        alignContent="start"
        height="100%"
        padding="0 space8 60px"
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          void handleSubmit(event);
        }}>
        {!hasTransactions && <DocumentType />}
        <Contact contactId={contactId ?? ''} />
        {showAccountsPayableNumberField && <AccountsPayable />}
        {showAccountsReceivableNumberField && <AccountsReceivable />}
        {tagsField}
        <InvoiceDates
          deliveryDate={invoiceDeliveryDate}
          invoiceDate={invoiceDate}
          invoicePostingDate={invoicePostingDate}
          showDeliveryDate={showDeliveryDate}
          showPostingDate={showPostingDate}
        />
        <InvoiceNumber />
        {(shouldUseSapPurchaseOrder || showPurchaseOrderNumber) && (
          <PurchaseOrderNumber />
        )}
        {showInvoiceCorrection && <InvoiceCorrection />}
        {showNetAndTaxAmountFields ? (
          <>
            <NetAmount
              currency={currency}
              hasMultipleBookings={bookingsFieldArray.fields.length > 1}
            />

            <Grid columns={!showFormField ? 2 : 1} gap="space8">
              <TaxAmount
                currency={currency}
                hasMultipleBookings={bookingsFieldArray.fields.length > 1}
              />
              {!showFormField &&
                (showBookingAccount ? <BookingKey /> : <VatRate />)}
            </Grid>
            <Grid columns={shouldUseSapNetAmount ? 2 : 1} gap="space8">
              <GrossAmount currency={currency} />
              {shouldUseSapNetAmount && <RoundingAmount currency={currency} />}
            </Grid>
          </>
        ) : (
          <Grid autoFlow="column" gap="space8">
            <GrossAmount currency={currency} />
            {showFormField ? <SplitGrossAmount /> : <VatRate />}
          </Grid>
        )}
        {showFormField && (
          <SplitBookingsList
            onSplitDocument={splitIndex => {
              setDefaultSplitIndex(splitIndex ?? -1);
              setMode('split-bookings');
            }}
            readOnly
          />
        )}
        {showDueDateField && (
          <DueDate dueDate={bookings ? bookings[0].dueDate : undefined} />
        )}
        {showCashDiscountFields && (
          <CashDiscount
            cashDiscount={(cashDiscount as CashDiscountFieldValue) || {}}
            currency={currency}
          />
        )}
        {!showFormField && (
          <>
            <SectionSeparator
              header={t('document.requestApproval.section.preAccounting')}
            />
            <Grid gap="space16">
              {showAccountingArea && <AccountingArea />}

              {showGeneralLedgerAccountId && <GeneralLedgerAccount />}

              {!showNetAndTaxAmountFields && showBookingAccount && (
                <BookingKey />
              )}

              {showCostCenter && <CostCenter />}

              {showCostObject && <CostObject />}

              {showArtistSocial && bookings && (
                <ArtistSocial
                  value={
                    bookings && bookings[0]?.artistSocialInsuranceCode
                      ? // eslint-disable-next-line candis/no-template-strings-inside-translation
                        t(
                          `document.requestApproval.inputs.artistSocialInsuranceCode.codes.${bookings[0]?.artistSocialInsuranceCode}`
                        )
                      : undefined
                  }
                />
              )}

              {showExtraCostInfo && <ExtraCostInfo />}

              {showSplitPostingText && <PostingText />}

              <Note />
            </Grid>
          </>
        )}

        {!hasTransactions && (
          <>
            <SectionSeparator
              header={t('document.requestApproval.section.payment')}
            />
            <BankingDetails
              allowInlineEditing={isEditable && !makeBankingDetailsReadOnly}
              paymentStatus={paymentStatus}
            />
          </>
        )}

        {footer}

        {isEditable && (
          <DocumentFormActions
            allowInlineEditing={isEditable}
            isEditing={isEditing}
            isUpdatingDocument={isUpdatingDocument}
            reRequestApproval={reRequestApproval}
          />
        )}
      </Grid>
      <ProcessingFormOverlay
        isOpen={mode === 'split-bookings'}
        onClose={() => setMode('default')}>
        {/* TODO: validation, leave this until the split bookings form is completed */}
        <SplitBookingsFormContainer
          defaultSplitIndex={defaultSplitIndex}
          documentId={documentId ?? ''}
          readOnly
        />
      </ProcessingFormOverlay>
    </DocumentFormProvider>
  );
};
