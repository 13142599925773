import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Link,
  Paragraph,
  Text,
} from '@candisio/design-system';
import {
  DatevClientExportType,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { exportCardId, scrollToTarget } from 'views/CreditCards/utils/utils';
import { CheckList, CheckProps } from './CheckList/CheckList';
import { CreditCardExportingForm } from './CreditCardExportingForm/CreditCardExportingForm';
import { getChecks } from './states';

export const CreditCardExportingCard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  //this is required to fetch CC ledger for Datev not connected users
  const { data: datevCC, loading: ccLoading } = useNew_DatevSettingsQuery();

  const { rdsConnected, bdsConnected, bdsSetupCompleted } = useDatev(); // BDS-checked

  const { data: datevSettings, loading } = useNew_DatevSettingsQuery();

  const organizationSlug = useOrganizationId();
  const history = useHistory();

  const { hash } = history?.location;

  const [checks, setChecks] = useState<CheckProps | undefined>([
    getChecks(t).loading,
  ]);

  const [showForm, toggleShowForm] = useState(false);

  const client = datevSettings?.new_datevSettings?.client;
  const creditCardsLedger = datevCC?.new_datevSettings?.creditCardsLedger;

  const isDatevDuoSetupComplete =
    rdsConnected &&
    client?.exportType === DatevClientExportType.DxsoJobs &&
    client?.isDxsoCashLedgerConfigured;

  const isDatevReweSetupComplete = bdsConnected && bdsSetupCompleted;

  const areCreditCardAccountsPresent =
    !!creditCardsLedger?.bookingAccount && !!creditCardsLedger?.transitAccount;

  let creditCardExportingDescription: string;
  let creditCardExportingLink: string;

  if (bdsConnected) {
    creditCardExportingDescription = t('creditCardExporting.bds.description');
    creditCardExportingLink = t('creditCardExporting.bds.linkUrl');
  } else {
    creditCardExportingDescription = t('creditCardExporting.description');
    creditCardExportingLink = t('creditCardExporting.linkUrl');
  }

  useEffect(() => {
    if (loading || ccLoading) {
      return;
    }

    if (hash !== '') {
      scrollToTarget(hash);
    }

    if (
      datevSettings?.new_datevSettings &&
      datevSettings?.new_datevSettings?.hasAuthTokenForExportDocuments
    ) {
      // client is connected
      if (isDatevDuoSetupComplete || isDatevReweSetupComplete) {
        // RDS & Cash Ledger activated.. user is able to configure account number
        toggleShowForm(true);
      }

      if (
        (isDatevDuoSetupComplete && areCreditCardAccountsPresent) ||
        (isDatevReweSetupComplete && areCreditCardAccountsPresent)
      ) {
        // all done. service connected and configured with booking account number
        setChecks([getChecks(t).serviceConnected.success]);
      } else if (bdsConnected) {
        setChecks([
          getChecks(t).client.success,
          getChecks(t).bds[bdsSetupCompleted ? 'success' : 'error'],
          getChecks(t).creditCardFF[
            areCreditCardAccountsPresent ? 'success' : 'error'
          ],
        ]);
      } else {
        setChecks([
          getChecks(t).client.success,
          getChecks(t).rds[
            client?.exportType === DatevClientExportType.DxsoJobs
              ? 'success'
              : 'error'
          ],
          getChecks(t).cashLedger[
            client?.isDxsoCashLedgerConfigured ? 'success' : 'error'
          ],
          getChecks(t).creditCardFF[
            areCreditCardAccountsPresent ? 'success' : 'error'
          ],
        ]);
      }
    }
    // client is not connected
    else {
      // user should be able to configure account number
      // when Datev is  not connected
      // this is required for Datev CSV export users
      toggleShowForm(true);
      setChecks([
        getChecks(t).creditCardFF[
          areCreditCardAccountsPresent ? 'success' : 'error'
        ],
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datevSettings, datevCC, loading, ccLoading, hash]);

  return (
    <Card
      id={exportCardId}
      padding="space16"
      borderRadius="medium"
      alignSelf="start">
      <Grid gap="space14" paddingBottom="space12">
        {!areCreditCardAccountsPresent && (
          <Grid
            autoFlow="column"
            background="redbg"
            gap="space4"
            paddingY="space12"
            paddingX="space16"
            borderRadius="medium">
            <Icon icon="infoCircle" color="red500" size="space16" />
            <Text color="red500" fontSize="small">
              {t('creditCardExporting.missingAccountNumber')}
            </Text>
          </Grid>
        )}
        <Heading as="h3">{t('creditCardExporting.title')}</Heading>
        <Paragraph>{creditCardExportingDescription}</Paragraph>
        <Flex>
          <Link external href={creditCardExportingLink}>
            {t('creditCardExporting.linkText')}
          </Link>
        </Flex>
        {checks && <CheckList checks={checks} />}
        {showForm ? (
          <CreditCardExportingForm />
        ) : (
          <Button
            width="max-content"
            size="small"
            variant="secondary"
            onClick={() =>
              history.push(
                `/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}`
              )
            }>
            {t('creditCardExporting.accountNumbers.crossLink')}
          </Button>
        )}
      </Grid>
    </Card>
  );
};
