import { useMemo } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/table/getFilterFromUrl';

export const useTranslateFiltersFromUrl = <
  QueryParamsType extends { sort: string }
>({
  availableFilters,
}: {
  availableFilters: string[];
}) => {
  const location = useLocation();

  const filtersAndSortFromUrl = useMemo(() => {
    return getFilterFromUrl<QueryParamsType>(location.search, [
      ...availableFilters,
      'sort',
    ]);
  }, [location.search, availableFilters]);

  const mappedFiltersToTable = useMemo(() => {
    const { sort, ...filtersFromUrl } = filtersAndSortFromUrl;

    return (Object.entries(filtersFromUrl) || []).map(filter => ({
      id: filter[0],
      value: filter[1],
    }));
  }, [filtersAndSortFromUrl]);

  const sort = filtersAndSortFromUrl.sort;

  const mappedSortToTable = useMemo(() => {
    const [sortField, sortDirection] = (sort || '').split(':');

    return sort
      ? [{ id: sortField, desc: sortDirection === 'ascend' ? false : true }]
      : [];
  }, [sort]);

  return { filters: mappedFiltersToTable, sortBy: mappedSortToTable };
};
