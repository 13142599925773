import { ReactElement } from 'react';

export type DateRangeDefinition = {
  type: DATE_RANGE_OPTION;
  values: {
    readable: string;
    dateFrom: string;
    dateTo: string;
    prev?: string;
    next?: string;
  };
};

export enum DATE_RANGE_OPTION {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
}

export enum QUARTER_OPTION {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export type WidgetContextProps = {
  isOwnedByCurrentUser: boolean;
  isOnDashboard: boolean;
  onDeleteWidget: () => Promise<void>;
  onEditWidget: () => void;
  onUnsubscribeWidget: () => Promise<void>;
  onMoveWidget: () => Promise<void>;
};

export type UserInfo = { id: string; name: string; avatarUrl?: string | null };

export type SumProps = {
  contextMenu?: WidgetContextProps;
  creatorName?: string;
  formattedSum?: string | ReactElement;
  isPreview?: boolean;
  loading?: boolean;
  name: string;
  readOnly?: boolean;
  sharedWithUsers?: UserInfo[];
  source: string;
  toFilteredDocumentsList?: string;
  unsubscribedUsers?: UserInfo[];
  progressBar?: JSX.Element;
};

export type AddProps = {
  onAddWidget: () => void;
};

export type DateRangeProps = {
  setReadableDateOption: (val: string) => void;
  invoiceDate?: DateRangeDefinition;
};

export type InitialStateProps = {
  onAddWidget: () => void;
};
