import { CreditCardBannerContainer } from 'containers/credit-cards/components/CreditCardBannerContainer/CreditCardBannerContainer';
import { creditCardInfoHash } from 'containers/credit-cards/components/CreditCardsSection/utils';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardInfoModal } from 'views/Dashboard/components/CreditCardInfoModal';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TransactionsHeader } from '../components/TransactionsHeader';
import { TabView } from '../models';
import { TransactionsMainContent } from './TransactionsMainContent';

export const InboxTransactions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const openModal = location.hash === creditCardInfoHash;

  return (
    <>
      <TransactionsHeader />
      <InboxViewContainer activeTab={TabView.INBOX_TRANSACTIONS}>
        <CreditCardBannerContainer routeType="Inbox">
          <TransactionsMainContent />
        </CreditCardBannerContainer>
      </InboxViewContainer>
      <CreditCardInfoModal
        isOpen={openModal}
        onClose={() => {
          navigate(
            {
              pathname: location.pathname,
              search: location.search,
            },
            { replace: true }
          );
        }}
        routeType="Inbox"
      />
    </>
  );
};
