import { Grid, Link, Text } from '@candisio/design-system';
import { WarningIcon } from 'components/Icons/DefaultIcons';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const DocumentsExceededWarning = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  return (
    <Grid autoFlow="column" gap="space4" justifyContent="start">
      <WarningIcon size="space20" />
      <Text color="yellow500">{t('table.maxNumberOfDocuments.warning')}</Text>
      <InfoIcon
        message={
          <Trans t={t} i18nKey="table.maxNumberOfDocuments.warningTooltip">
            Mehr über die Funktionsweise von Zahlungen kannst du
            <Link href={t('table.maxNumberOfDocuments.helpUrl')} external>
              hier erfahren
            </Link>
            .
          </Trans>
        }
      />
    </Grid>
  );
};
