import gql from 'graphql-tag';

export const getMemberships = gql`
  query getMemberships {
    organizationMemberships(filters: { status: ACTIVE, emailVerified: true }) {
      id
      name
      roles
      avatarUrl
    }
  }
`;

export const createDocumentCommentMutation = gql`
  mutation createDocumentComment(
    $id: ID!
    $text: String!
    $mentionedUserIds: [String!]
  ) {
    createDocumentComment(
      id: $id
      text: $text
      mentionedUserIds: $mentionedUserIds
    ) {
      id
    }
  }
`;

export const createCommentOnInvoice = gql`
  mutation createCommentOnInvoice($input: CreateCommentInput!) {
    createCommentOnInvoice(input: $input) {
      ... on CommentCreated {
        commentId
      }
      ... on CommentCreatedError {
        message
        kind
      }
    }
  }
`;

export const createCommentOnEcmDocument = gql`
  mutation createCommentOnEcmDocument($input: CreateCommentInput!) {
    createCommentOnECMDocument(input: $input) {
      ... on CommentCreated {
        commentId
      }
      ... on CommentCreatedError {
        message
        kind
      }
    }
  }
`;
