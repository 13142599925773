import { TimelineProps } from 'antd/es/timeline';
import { NoHistoryData } from 'components/History/NoData/NoHistoryData';
import { ReactNode } from 'react';
import { Collapse } from './items/Collapse';
import { Content } from './items/Content';
import { Email } from './items/Email';
import { Export } from './items/Export';
import { Neutral } from './items/Neutral';
import { Payment } from './items/Payment';
import { RenderItem } from './items/RenderItem';
import { System } from './items/System';
import { User } from './items/User';
import { TimelineView } from './styles';

interface HistoryProps extends TimelineProps {
  children?: ReactNode;
}

export const History = ({ children, ...rest }: HistoryProps) =>
  children ? (
    <TimelineView {...rest}>{children}</TimelineView>
  ) : (
    <NoHistoryData />
  );

History.System = System;
History.Export = Export;
History.Payment = Payment;
History.Email = Email;
History.Neutral = Neutral;
History.User = User;
History.Collapse = Collapse;
History.Render = RenderItem;
History.Content = Content;
