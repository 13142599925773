import {
  BuiltinRoleName,
  useMembersByRoleQuery,
} from 'generated-types/graphql.types';
import { useToApproverFieldItem } from '../../useToApproverFieldItem';
import { StorageFormFieldOptions } from './StorageForm';

export const usePeopleFieldOptions =
  (): StorageFormFieldOptions['notifyPerson'] => {
    const { data } = useMembersByRoleQuery({
      variables: {
        filters: {
          roleNames: [
            BuiltinRoleName.Approver,
            BuiltinRoleName.CardManager,
            BuiltinRoleName.Admin,
            BuiltinRoleName.Requester,
          ],
        },
      },
    });

    const teamMembers = data?.organizationMemberships;

    const toApproverFieldItem = useToApproverFieldItem([], teamMembers ?? []);

    return {
      defaultItems: teamMembers?.map(toApproverFieldItem),
    };
  };
