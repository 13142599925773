import { FieldContainer } from '@candisio/design-system';
import { WysiwygEditor } from 'components/EmailEditor/components/WysiwygEditor';
import { useCallback } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface HookFormEmailEditorProps<TFormValues extends FieldValues>
  extends UseControllerProps<TFormValues> {
  /** Field label */
  label: string;
  /** Called when field value changes */
  onChange?: (newValue: string | null) => void;
}

export const HookFormEmailEditorField = <TFormValues extends FieldValues>({
  control,
  name,
  label,
  onChange: onChangeProp,
}: HookFormEmailEditorProps<TFormValues>) => {
  const { field, formState, fieldState } = useController({ control, name });
  const { onChange } = field;

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const handleOnChange = useCallback(
    (value: string) => {
      const newValue = value !== '' ? value : null;
      onChange(newValue);
      onChangeProp?.(newValue);
    },
    [onChange, onChangeProp]
  );

  return (
    <FieldContainer
      label={label}
      message={errorMessage}
      readOnly={formState.isSubmitting}
      color="white"
      variant={hasError ? 'error' : undefined}
      wrapperGridProps={{
        height: '100%',
      }}
      wrapperBoxProps={{
        height: '100%',
      }}
      height="100%"
      style={{
        overflow: 'auto',
        justifyContent: 'start',
      }}>
      <WysiwygEditor value={field.value ?? ''} onChange={handleOnChange} />
    </FieldContainer>
  );
};
