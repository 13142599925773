import { useWorkflowTemplatesQuery } from 'generated-types/graphql.types';

export const useWorkflowTemplateData = ({
  workflow,
}: {
  workflow?: string | null;
}) => {
  const { data: workflowTemplatesData } = useWorkflowTemplatesQuery({
    variables: { input: { isArchived: false } },
  });

  const approversFromWorkflow = workflowTemplatesData?.workflowTemplates
    ?.find(({ id }) => id === workflow)
    ?.steps?.map(step => ({
      approvers:
        step.resolvers?.reduce<string[]>((result, approver) => {
          if (approver?.id) {
            result.push(approver.id);
          }

          return result;
        }, []) ?? [],
    }));

  return { approversFromWorkflow };
};
