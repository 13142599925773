import { Flex, Grid, Heading, ScrollBox, Text } from '@candisio/design-system';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CardTypeField } from './CardTypeField';
import { useCreateCreditCardModalState } from './CreateCreditCardModalDataProvider';
import { PhysicalCardLimitsFormContainer } from './PhysicalCardLimitsFormContainer';
import { SingleUseVirtualCardLimitsFormContainer } from './SingleUseVirtualCardLimitsFormContainer';
import { VirtualCardLimitsFormContainer } from './VirtualCardLimitsFormContainer';

export interface LimitsAndCardTypeStepProps {
  hasPhysicalCard: boolean;
}

type CardTypeDetails = Record<
  Exclude<CardType, CardType.Black>,
  { bulletList: string[]; form: JSX.Element }
>;

export const LimitsAndCardTypeStep = ({
  hasPhysicalCard,
}: LimitsAndCardTypeStepProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { cardType } = useCreateCreditCardModalState();

  const cardTypeDetails: CardTypeDetails = {
    VIRTUAL: {
      bulletList: [
        'ccRequestForm.bulletList.virtualCard.point1',
        'ccRequestForm.bulletList.virtualCard.point2',
      ],
      form: <VirtualCardLimitsFormContainer />,
    },
    SINGLE_USE: {
      bulletList: [
        'ccRequestForm.bulletList.singleUseCard.point1',
        'ccRequestForm.bulletList.singleUseCard.point2',
      ],
      form: <SingleUseVirtualCardLimitsFormContainer />,
    },
    PHYSICAL: {
      bulletList: [
        'ccRequestForm.bulletList.physicalCard.point1',
        'ccRequestForm.bulletList.physicalCard.point2',
      ],
      form: (
        <PhysicalCardLimitsFormContainer hasPhysicalCard={hasPhysicalCard} />
      ),
    },
  };

  return (
    <ScrollBox>
      <Grid
        gap="space24"
        height="100%"
        templateRows="auto 1fr"
        padding="space24"
        paddingBottom="0px">
        <Grid gap="space16" alignItems="start">
          <Heading as="h2">{t('ccRequestForm.limitsForm.cardType')}</Heading>
          <CardTypeField />
          <Flex direction="column" gap="space2">
            {cardTypeDetails[cardType].bulletList.map((point, index) => (
              <Text color="gray600" key={index}>
                {t(point)}
              </Text>
            ))}
          </Flex>
        </Grid>
        {cardTypeDetails[cardType].form}
      </Grid>
    </ScrollBox>
  );
};
