import { TruncatedText, Text, Grid } from '@candisio/design-system';
import { ContactValidationErrorType } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { ContactImportPreviewTableData } from 'views/Contacts/ContactImport/ContactImportPreviewTable/ContactImportPreviewTable';
interface ErrorMap {
  error: ContactValidationErrorType;
  translation: string;
}

interface GenericCellProps extends CellProps<ContactImportPreviewTableData> {
  accessor: keyof Omit<ContactImportPreviewTableData, 'id' | 'errors'>;
  errorMap: ErrorMap[];
  format?: (value: any) => string | null;
}

const getErrorTranslationKey = (
  errors: ContactValidationErrorType[],
  errorMap: ErrorMap[]
): string | undefined => {
  for (const error of errors) {
    const matchingError = errorMap.find(e => e.error === error);
    if (matchingError) {
      return matchingError.translation;
    }
  }

  return undefined;
};

export const GenericCell = ({
  row: { original },
  accessor,
  errorMap,
  format,
}: GenericCellProps) => {
  const { t } = useTranslation();

  const fieldValue = original[accessor];

  if (isNil(fieldValue) || fieldValue === '') {
    return (
      <Text fontSize="small">{t('contacts:import.preview.emptyValue')}</Text>
    );
  }

  const formattedValue = format ? format(fieldValue) : fieldValue;

  const translationKey = getErrorTranslationKey(original.errors, errorMap);

  if (translationKey) {
    return (
      <Grid autoFlow="column" gap="space2" justifyContent="start">
        <Trans
          i18nKey={translationKey}
          values={{
            value: formattedValue,
          }}>
          <TruncatedText>{String(formattedValue)}</TruncatedText>
          <TruncatedText color="red700">(invalid)</TruncatedText>
        </Trans>
      </Grid>
    );
  }

  return <TruncatedText>{String(formattedValue)}</TruncatedText>;
};
