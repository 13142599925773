import gql from 'graphql-tag';
import {
  transactionDetailsFragment,
  transactionListFragment,
  documentDataFragment,
  pageInfoDataFragment,
  purchaseOrderListFragment,
  documentBookingAssociationFragment,
} from 'views/queries/fragments';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';

export const documentFastApproveMutation = gql`
  mutation documentFastApprove(
    $id: ID!
    $input: DocumentFastApproveInput!
    $options: DocumentFastApproveOptionsInput
  ) {
    documentFastApprove(id: $id, input: $input, options: $options) {
      __typename
      ...DocumentData

      ... on DocumentFastApproveErrors {
        errors {
          ...DocumentCannotBeModifedErrorData
          ...DocumentIsAlreadyRequestedForApprovalErrorData
          ...InvalidInvoiceDateErrorData
          ...InvalidDeliveryDateErrorData

          ...BookingsErrorData

          ... on DocumentApprovalError {
            kind
            message
          }
        }
      }
    }
  }
  ${documentDataFragment}
`;

export const requestApprovalMutation = gql`
  mutation documentRequestApproval(
    $id: ID!
    $value: DocumentRequestApprovalInput!
    $options: DocumentRequestApprovalOptionsInput
  ) {
    documentRequestApprovalUnionResponse(
      id: $id
      input: $value
      options: $options
    ) {
      __typename
      ...DocumentData

      ... on DocumentRequestApprovalErrors {
        errors {
          ...DocumentCannotBeModifedErrorData
          ...DocumentIsAlreadyRequestedForApprovalErrorData
          ...InvalidInvoiceDateErrorData
          ...InvalidDeliveryDateErrorData
          ...WorkflowTemplateArchivedErrorData

          ...BookingsErrorData

          ... on DocumentApprovalError {
            kind
            message
          }
        }
      }
    }
  }
  ${documentDataFragment}
`;

export const documentQueries = {
  forDraftForm: gql`
    query getDocumentForDraft($id: ID!) {
      getDocument(id: $id) {
        id
        isDuplicate
        isOverdue
        isReadOnly
        isWaitingForClarification
        hasAttachments
        hasTransactionLinked
        transactions {
          ...TransactionDetailsData
        }
        purchaseOrderData {
          orderNumber
          purchaseOrderId
          integrationType
        }
        category {
          direction {
            value
            confidence
          }
          documentType {
            value
            confidence
            source
          }
        }
        contact {
          value {
            id
            accountsPayableNumber
            accountsReceivableNumber
            name {
              value
            }
            iban {
              value
            }
            swiftCode
            taxNumber {
              value
            }
            vatId {
              value
            }
            createTransfer {
              value
            }
            dueDateOffset {
              value
            }
            paymentConditionId
            email
          }
          source
          confidence
        }
        status
        roundingAmount
        accountingArea {
          value {
            id
            name
          }
          confidence
          source
        }
        invoiceNumber {
          value
          confidence
          source
        }
        invoiceDate {
          value
          confidence
          source
        }
        invoiceDeliveryDate {
          value
          confidence
          source
        }
        invoicePostingDate {
          value
          confidence
          source
        }
        amount {
          value
          confidence
          source
        }
        currency {
          value
          confidence
          source
        }
        iban {
          value
          confidence
          source
        }
        swiftCode {
          value
          confidence
          source
        }
        createTransfer {
          value
          confidence
          source
        }
        paymentState {
          __typename
          ... on PaidDocumentState {
            paidAt
          }
        }
        approvers {
          id
          name
          avatarUrl
        }
        suggestedApprovers {
          value {
            id
            name
            avatarUrl
          }
          confidence
          source
        }
        documentFile {
          id
          name
        }
        approvers {
          id
        }
        requester {
          id
        }
        rolesThatAreEligibleToApprove
        originalDocumentId {
          value
          confidence
        }
        extractedContact {
          id
          cachingKey
          name {
            value
            confidence
            source
          }
          iban {
            value
            confidence
            source
          }
          bankAccountNumber {
            value
            confidence
            source
          }
          swiftCode {
            value
            confidence
            source
          }
          taxNumber {
            value
            confidence
            source
          }
          vatId {
            value
            confidence
            source
          }
          createTransfer {
            value
            confidence
            source
          }
          email {
            value
            confidence
            source
          }
          phoneNumber {
            value
            confidence
            source
          }
          street {
            value
            confidence
            source
          }
          postOfficeBox {
            value
            confidence
            source
          }
          postalCode {
            value
            confidence
            source
          }
          city {
            value
            confidence
            source
          }
          countryISOCode {
            value
            confidence
            source
          }
          customerNumber {
            value
            confidence
            source
          }
        }
        currentWorkflowStep {
          id
        }
        workflow {
          id
          name
          description
          workflowTemplate {
            id
            name
            steps {
              resolvers {
                id
                name
              }
            }
          }
          steps {
            id
            resolution {
              approvers {
                id
                name
              }
              type
            }
            approvers {
              id
              name
              substitute {
                id
                name
              }
            }
          }
        }
        bookings {
          id
          amount {
            value
          }
          taxAmount
          netAmount
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
          dueDate {
            value
            confidence
            source
          }
          generalLedgerAccount {
            value {
              id
              accountCode
              readableName
              name
            }
            confidence
            source
          }
          bookingKey {
            value {
              id
              taxCode
              readableName
            }
            confidence
            source
          }
          costCenter {
            value {
              id
              code
              name
              readableName
              approvers {
                id
                name
                avatarUrl
              }
            }
            confidence
            source
          }
          costObject {
            value {
              id
              code
              name
              readableName
            }
            confidence
            source
          }
          extraCostInfo {
            value {
              id
              code
              name
              readableName
            }
            confidence
            source
          }
          artistSocialInsuranceCode
          discountAmount {
            value
            confidence
            source
          }
          discountPercentage {
            value
            confidence
            source
          }
          discountPaymentDate {
            value
            confidence
            source
          }
          paymentConditionId {
            value
            confidence
            source
          }
        }
        globalDocumentId
        tags {
          ...Tag
        }
      }
    }
    ${transactionDetailsFragment}
    ${tagFragment}
  `,
};

export const getDocumentTransactionQuery = gql`
  query getDocumentTransaction($id: ID!) {
    getDocument(id: $id) {
      transactions {
        ...TransactionDetailsData
      }
    }
  }
  ${transactionDetailsFragment}
`;

export const getDocumentQuery = gql`
  query getDocument($id: ID!) {
    getDocument(id: $id) {
      ...DocumentData
      transactions {
        ...TransactionDetailsData
      }
      purchaseOrderData {
        orderNumber
        purchaseOrderId
        integrationType
      }
      paymentState {
        __typename
        ... on PaidDocumentState {
          paidAt
        }
      }
      currentWorkflowStep {
        id
      }
      rolesThatAreEligibleToApprove
      isOverdue
      isWaitingForClarification
      workflow {
        id
        steps {
          id
          resolution {
            approvers {
              id
              name
            }
            type
          }
          approvers {
            id
            name
            substitute {
              id
              name
            }
          }
        }
      }
      bookings {
        id
        amount {
          value
        }
        taxAmount
        netAmount
        vatRate {
          value
        }
        note {
          value
        }
        postingText
        dueDate {
          value
        }
        generalLedgerAccount {
          value {
            id
            accountCode
            readableName
          }
        }
        bookingKey {
          value {
            id
            taxCode
            readableName
          }
        }
        costCenter {
          value {
            id
            name
            code
            readableName
          }
        }
        costObject {
          value {
            id
            name
            code
            readableName
          }
        }
        extraCostInfo {
          value {
            id
            name
            code
            readableName
          }
        }
        paymentConditionId {
          value
        }
        artistSocialInsuranceCode
      }
    }
  }
  ${documentDataFragment}
  ${transactionDetailsFragment}
`;

export const getHasLinkedTransactionQuery = gql`
  query getHasLinkedTransaction($id: ID!) {
    getDocument(id: $id) {
      hasTransactionLinked
    }
  }
`;

export const getWorkflowTemplatesQuery = gql`
  query getWorkflowTemplates($name: String, $isArchived: Boolean = false) {
    workflowTemplates(input: { name: $name, isArchived: $isArchived }) {
      id
      name
      description
    }
  }
`;

export const getTeamMembersQuery = gql`
  query getMembers {
    organizationMemberships @connection(key: "membersForAbsence") {
      id
      name
      roles
      avatarUrl
    }
  }
`;

export const removeDocumentMutation = gql`
  mutation deleteById($id: ID!) {
    deleteById(id: $id) {
      count
    }
  }
`;

export const smartFieldSuggestionsForRequesterQuery = gql`
  query smartFieldSuggestionsForRequester(
    $documentId: String!
    $contactId: String!
  ) {
    sfsSuggestionForRequester(documentId: $documentId, contactId: $contactId) {
      costCenter1 {
        confidence
        value {
          id
          name
          code
          readableName
          approverIds
        }
      }
      costCenter2 {
        confidence
        value {
          id
          name
          code
          readableName
        }
      }
      generalLedgerAccount {
        confidence
        value {
          id
          name
          code: accountCode
          readableName
        }
      }
      bookingKey {
        confidence
        value {
          id
          readableName
        }
      }
      workflowTemplate {
        confidence
        value {
          id
          name
        }
      }
      approverUserIds {
        confidence
        value
      }
    }
  }
`;

export const unassociatedCardIssuerTransactionsQuery = gql`
  query unassociatedCardIssuerTransactions(
    $input: CardIssuerTransactionPaginationInput!
    $filters: InboxCardIssuerTransactionFiltersInput
    $sortBy: CardIssuerTransactionSortInput
  ) {
    unassociatedCardIssuerTransactions(
      input: $input
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          ...transactionListData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${transactionListFragment}
`;

export const linkDocumentAndTransactionsMutation = gql`
  mutation linkDocumentAndTransactions(
    $documentId: String!
    $transactionIds: [String!]!
  ) {
    linkDocumentAndTransactions(
      documentId: $documentId
      transactionIds: $transactionIds
    ) {
      id
    }
  }
`;

/**
 * Gets all non-paginated field items for processing form:
 * - (document) types
 * - payment conditions
 * - tax codes
 * - workflows
 * - approvers
 *
 * The following field items use paginated queries and are **not** fetched by
 * this query:
 * - contacts
 * - general ledger accounts
 * - cost centers
 * - cost objects
 * - extra cost infos
 */
export const processingFormFieldItemsQuery = gql`
  query processingFormFieldItems {
    types: availableDocumentCategories {
      documentType
      direction
      isDefault
      isRds1_0Exportable
    }

    paymentConditions(filter: { isArchived: false }) {
      id
      paymentConditionNumber
      paymentConditionName
      dueDateOffset
      discountOffset
      discountPercentage
    }

    taxCodes: bookingKeysActive {
      id
      taxCode
      readableName
    }

    accountingAreas: accountingAreas(filter: { isArchived: false }) {
      id
      name
    }

    workflows: workflowTemplates(input: { isArchived: false }) {
      id
      name
      steps {
        resolvers {
          id
        }
      }
    }

    approvers {
      id
      name
      avatarUrl
    }

    getOrgMembersAbsence {
      fromDate
      membershipId
      note
      status
      toDate
      substituteMembershipId
    }
  }
`;

const processingFormContactDataFragment = gql`
  fragment ProcessingFormContactData on Contact {
    id
    name {
      value
    }
    accountsPayableNumber
    accountsReceivableNumber
    createTransfer {
      value
    }
    iban {
      value
    }
    bankAccountNumber
    paymentCondition {
      id
    }
    taxNumber {
      value
    }
    vatId {
      value
    }
    swiftCode
    email
    phoneNumber
  }
`;

export const processingFormContactFieldItemsQuery = gql`
  query processingFormContactFieldItems(
    $name: String
    $page: Int
    $filters: ContactsFilterInput
  ) {
    contactsPagination(
      input: { page: $page, limit: 20 }
      queries: { name: $name }
      sortBy: { field: NAME, direction: ASC }
      filters: $filters
    )
      @connection(
        key: "processingFormContactFieldItems"
        filter: ["filters", "queries"]
      ) {
      records {
        ...ProcessingFormContactData
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
  ${processingFormContactDataFragment}
`;

export const processingFormGeneralLedgerAccountFieldItemsQuery = gql`
  query processingFormGeneralLedgerAccountFieldItems(
    $readableName: String
    $page: Int
  ) {
    generalLedgerAccountsPagination: bookingAccountPagination(
      input: { page: $page, limit: 20 }
      filters: { isArchived: false }
      queries: { readableName: $readableName }
      sortBy: { field: ACCOUNT_NUMBER, direction: ASC }
    )
      @connection(
        key: "processingFormGeneralLedgerAccountFieldItems"
        filter: ["filters", "queries"]
      ) {
      records {
        id
        readableName
        accountCode
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;

export const processingFormGeneralLedgerAccountFieldItemsByIdsQuery = gql`
  query processingFormGeneralLedgerAccountFieldItemsByIds(
    $ids: [String!]
    $page: Int
  ) {
    generalLedgerAccountsPagination: bookingAccountPagination(
      input: { page: $page, limit: 20 }
      filters: { id: $ids }
    )
      @connection(
        key: "processingFormGeneralLedgerAccountFieldItemsByIds"
        filter: ["filters", "queries"]
      ) {
      records {
        id
        readableName
        accountCode
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;

export const processingFormCostCenterFieldItemsQuery = gql`
  query processingFormCostCenterFieldItems(
    $type: CostCenterTypes!
    $readableName: String
    $page: Int
  ) {
    costCentersPagination: costCenterPagination(
      input: { page: $page, limit: 20 }
      filters: { isArchived: false, type: [$type] }
      queries: { readableName: $readableName }
      sortBy: { field: CODE, direction: ASC }
    )
      @connection(
        key: "processingFormCostCenterFieldItems"
        filter: ["filters", "queries"]
      ) {
      records {
        id
        readableName
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;

export const processingFormCostCenterFieldItemsByIdsQuery = gql`
  query processingFormCostCenterFieldItemsByIds($ids: [String!], $page: Int) {
    costCentersPagination: costCenterPagination(
      input: { page: $page, limit: 20 }
      filters: { id: $ids }
    )
      @connection(
        key: "processingFormCostCenterFieldItemsByIds"
        filter: ["filters", "queries"]
      ) {
      records {
        id
        readableName
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;

export const processingFormActionsQuery = gql`
  query processingFormActions($documentId: ID!) {
    document: getDocument(id: $documentId) {
      isDuplicate
      originalDocumentId {
        value
      }
      status
      transactions {
        id
      }
    }
  }
`;

export const updateWaitingForClarificationMutation = gql`
  mutation updateWaitingForClarification(
    $documentId: ID!
    $isWaiting: Boolean!
  ) {
    updateWaitingForClarification(id: $documentId, isWaiting: $isWaiting)
  }
`;

export const fileUploadedEventData = gql`
  fragment FileUploadedEventData on FileUploadedEvent {
    user {
      id
      avatarUrl
      name
    }
    file {
      name
      url
    }
    clientInfo {
      name
    }
    # outdated
    uploaderEmail
  }
`;

export const mobilePromotionData = gql`
  query mobilePromotionData($id: ID!) {
    getDocument(id: $id) {
      id
      status
      sourceClassification {
        value
      }
      hasTransactionLinked
      timeline {
        __typename
        id
        createdAt
        ...FileUploadedEventData
      }
    }
  }
  ${fileUploadedEventData}
`;

export const getPurchaseOrderByInvoiceIdQuery = gql`
  query getPurchaseOrderByInvoiceId($invoiceId: String!) {
    getPurchaseOrderByInvoiceId(invoiceId: $invoiceId) {
      ...purchaseOrderListData
    }
  }
  ${purchaseOrderListFragment}
`;

export const getDocumentBookingAssociationsByInvoiceId = gql`
  query getDocumentBookingAssociationsByInvoiceId($invoiceId: String!) {
    getDocumentBookingAssociationsByInvoiceId(invoiceId: $invoiceId) {
      ...DocumentBookingAssociationData
    }
  }
  ${documentBookingAssociationFragment}
`;

export const linkInvoiceToPurchaseOrderMutation = gql`
  mutation linkInvoiceToPurchaseOrder(
    $invoiceId: String!
    $purchaseOrderId: String!
  ) {
    linkInvoiceToPurchaseOrder(
      invoiceId: $invoiceId
      purchaseOrderId: $purchaseOrderId
    )
  }
`;

export const unlinkInvoiceFromPurchaseOrderMutation = gql`
  mutation unlinkInvoiceFromPurchaseOrder(
    $invoiceId: String!
    $purchaseOrderId: String!
  ) {
    unlinkInvoiceFromPurchaseOrder(
      invoiceId: $invoiceId
      purchaseOrderId: $purchaseOrderId
    )
  }
`;

export const getSapPurchaseOrdersQuery = gql`
  query getSapPurchaseOrders($id: ID!) {
    getDocument(id: $id) {
      purchaseOrders {
        ...purchaseOrderListData
        goodsReceipts {
          _id
          receiptNumber
          deliveryDate
          status
          goodsLineItems {
            description
            quantity
            itemNumber
          }
        }
      }
    }
  }
  ${purchaseOrderListFragment}
`;
