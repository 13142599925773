import { useDocumentCollabsSubscription } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import DateFormatters from 'utils/date_formatter';

interface UseSubscribeDocumentCollabsParams {
  documentId: string | undefined;
}

export interface Collaborator {
  lastActivity: string | undefined;
  currentField: string | undefined;
  membership: {
    id: string | undefined;
    name: string | undefined;
    avatarUrl: string | undefined;
  };
}

export const useSubscribeDocumentCollabs = ({
  documentId,
}: UseSubscribeDocumentCollabsParams) => {
  const showOthersHaveOpenedSameDocumentFF = useCandisFeatureFlags(
    FEATURE_FLAGS.showOthersHaveOpenedSameDocument
  );

  const { data, loading } = useDocumentCollabsSubscription({
    skip: !documentId || !showOthersHaveOpenedSameDocumentFF,
    variables: { documentId: documentId as string },
  });

  const documentCollaborators = data?.documentCollaborators;

  const mappedDocumentEditors: Collaborator[] = (
    documentCollaborators?.editors ?? []
  ).map(editor => ({
    lastActivity: editor.lastActivity
      ? DateFormatters.compact(new Date(editor.lastActivity))
      : undefined,
    currentField: editor.currentField ?? undefined,
    membership: {
      id: editor.membership?.id,
      name: editor.membership?.name,
      avatarUrl: editor.membership?.avatarUrl ?? undefined,
    },
  }));

  const mappedViewers: Collaborator[] = (
    documentCollaborators?.viewers ?? []
  ).map(viewer => ({
    lastActivity: viewer.lastActivity
      ? DateFormatters.compact(new Date(viewer.lastActivity))
      : undefined,
    currentField: viewer.currentField ?? undefined,
    membership: {
      id: viewer.membership?.id,
      name: viewer.membership?.name,
      avatarUrl: viewer.membership?.avatarUrl ?? undefined,
    },
  }));

  return { mappedDocumentEditors, loading, mappedViewers };
};
