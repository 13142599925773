import {
  UpdateCardIssuerOrganizationSettingsInput,
  useUpdateCardIssuerOrganizationSettingsMutation,
} from 'generated-types/graphql.types';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';

interface UseUpdateOrganizationSettingsReturn {
  updating: boolean;
  update: (
    organizationSettings: UpdateCardIssuerOrganizationSettingsInput
  ) => Promise<
    | {
        status: 'success' | 'error';
      }
    | undefined
  >;
}

export const useUpdateOrganizationSettings =
  (): UseUpdateOrganizationSettingsReturn => {
    const [updateCardIssuerOrganizationSettings, { loading }] =
      useUpdateCardIssuerOrganizationSettingsMutation({
        refetchQueries: [{ query: getFullOrganizationQuery }],
      });

    return {
      updating: loading,
      update: async (
        organizationSettings: UpdateCardIssuerOrganizationSettingsInput
      ) => {
        const response = await updateCardIssuerOrganizationSettings({
          variables: { input: organizationSettings },
        });

        if (response.errors?.length) {
          return { status: 'error' };
        }

        if (response.data?.updateCardIssuerOrganizationSettings) {
          return { status: 'success' };
        }
      },
    };
  };
