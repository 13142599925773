import { Button, Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CreditCardOption } from 'views/Settings/Teams/toolkit/hooks/useCreditCardOptions';
import { CreditCardsField } from '../../components/CreditCardsField';

interface AssignCardsProps {
  isSubmitting: boolean;
  isLoadingCreditCardOptions?: boolean;
  creditCardOptions: CreditCardOption[];
  search: string;
  onSearch: (value: string) => void;
  onLoadMore: () => void;
  onClick: () => void;
  onGoBack: () => void;
}

export const AssignCards = ({
  onGoBack,
  isLoadingCreditCardOptions,
  creditCardOptions,
  onLoadMore,
  onSearch,
  search,
  isSubmitting,
  onClick,
}: AssignCardsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const bulletList: string[] = t(
    'teams.createTeam.creditCardSetup.form.cards.bulletPoints',
    {
      returnObjects: true,
    }
  );

  return (
    <Grid gap="space16" templateRows="auto 1fr auto" height="100%">
      <Grid gap="space16" height="fit-content">
        <Text fontSize="xxlarge">
          {t('teams.createTeam.creditCardSetup.form.cards.heading')}
        </Text>
        <Grid gap="space8">
          <Text color="gray600" fontSize="basic" fontWeight="semibold">
            {t('teams.createTeam.creditCardSetup.form.cards.title')}
          </Text>
          <Grid as="ul" padding={0} listStyle="none" gap="space4">
            {bulletList.map((item, index) => (
              <li key={index}>
                <Text color="gray600">{item}</Text>
              </li>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid background="gray100" paddingY="space16" borderRadius="medium">
        <CreditCardsField
          autoFocus
          name="cards"
          isLoadingCreditCardOptions={isLoadingCreditCardOptions}
          creditCardOptions={creditCardOptions}
          search={search}
          onSearch={onSearch}
          onEndReached={onLoadMore}
        />
      </Grid>
      <Grid autoFlow="column" placeContent="space-between">
        <Button onClick={onGoBack} variant="secondary" disabled={isSubmitting}>
          {t('teams.createTeam.teamMembers.backCta')}
        </Button>
        <Button
          onClick={onClick}
          loading={isSubmitting}
          disabled={isSubmitting}>
          {t('teams.createTeam.creditCardSetup.form.cards.cta')}
        </Button>
      </Grid>
    </Grid>
  );
};
