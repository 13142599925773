import { Skeleton } from '@candisio/design-system';
import { AutoMatchBanner } from '../components/AutoMatchBanner/AutoMatchBanner';

interface AutoMatchBannerContainerProps {
  loading: boolean;
  autoMatchCount: number;
}

export const AutoMatchBannerContainer = ({
  loading,
  autoMatchCount,
}: AutoMatchBannerContainerProps) => {
  if (loading) {
    return <Skeleton width="100%" height="55px" />;
  }

  if (autoMatchCount === 0) {
    return null;
  }

  return <AutoMatchBanner autoMatchCount={autoMatchCount} />;
};
