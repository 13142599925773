import { mergeProps } from '@candisio/design-system';
import {
  HookFormTextareaField,
  HookFormTextareaFieldProps,
} from 'components/HookFormFields/HookFormTextareaField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

/** A `HookFormTextareaField` with added field metadata */
export const ProcessingFormTextareaField = ({
  name,
  readOnly,
  ...restProps
}: HookFormTextareaFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormTextareaField
      name={name}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
