import {
  HookFormNumberField,
  HookFormNumberFieldProps,
} from 'components/HookFormFields/HookFormNumberField';
import { isNil } from 'lodash';
import { FieldValues } from 'react-hook-form';
import { roundNumber } from 'utils/roundNumber';

// useNumberField's formatOptions should be memoized:
// https://github.com/adobe/react-spectrum/issues/1893
const formatOptions = { maximumFractionDigits: 2, style: 'percent' };

/**
 * A `HookFormNumberField` for entering percentages with up to 2 decimal places
 */
export const HookFormPercentField = <TFormValues extends FieldValues>({
  minValue,
  maxValue,
  ...restProps
}: HookFormNumberFieldProps<TFormValues>) => {
  return (
    <HookFormNumberField
      formatOptions={formatOptions}
      minValue={!isNil(minValue) ? roundNumber(minValue / 100, 4) : minValue}
      maxValue={!isNil(maxValue) ? roundNumber(maxValue / 100, 4) : maxValue}
      transform={{
        input: value => (!isNil(value) ? roundNumber(value / 100, 4) : value),
        output: value => (!isNil(value) ? roundNumber(value * 100, 2) : value),
      }}
      {...restProps}
    />
  );
};
