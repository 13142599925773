import {
  CreateWorkflowTemplateInput,
  UpdateWorkflowTemplateInput,
  WorkflowStepConditionFields,
  WorkflowTemplateStepType,
} from 'generated-types/graphql.types';
import { v4 as uuidv4 } from 'uuid';
import { WorkflowTemplateFormValues } from '../../WorkflowTemplateForm/toolkit/schema';

export const formToSubmitValues = (
  formValues: WorkflowTemplateFormValues
): CreateWorkflowTemplateInput & UpdateWorkflowTemplateInput => {
  return {
    idempotencyKey: uuidv4(),
    name: formValues.name,
    description: formValues.description,
    steps: formValues.steps.map((step, idx) => ({
      order: idx + 1,
      type: WorkflowTemplateStepType.UserApproval,
      resolvers: step.approvers,
      conditions: step.conditions.map(condition => ({
        field: WorkflowStepConditionFields.Amount,
        operator: condition.operator,
        values: [condition.values.toString()],
      })),
    })),
  };
};
