import {
  EcmDocumentStatus,
  useGetEcmSensitiveDocumentsCountQuery,
} from 'generated-types/graphql.types';

export interface UseEcmSensitiveDocumentsCountReturn {
  hasNoData: boolean;
  isLoading: boolean;
  sensitiveDocumentsCount: number;
}

/**
 * Fetches ECM sensitive documents and returns the count
 */
export const useEcmSensitiveDocumentsCount =
  (): UseEcmSensitiveDocumentsCountReturn => {
    const sensitiveDocumentsQueryParams = {
      filter: {
        documentStatus: [EcmDocumentStatus.New],
        isSensitive: true,
      },
    };

    const { data, loading: isLoading } = useGetEcmSensitiveDocumentsCountQuery({
      variables: sensitiveDocumentsQueryParams,
    });

    const hasNoData = !isLoading && data?.getEcmDocuments?.edges?.length === 0;
    const sensitiveDocumentsCount =
      data?.getEcmDocuments?.pageInfo?.totalCount ?? 0;

    return {
      hasNoData,
      isLoading,
      sensitiveDocumentsCount,
    };
  };
