import { ContactDataFragment } from 'generated-types/graphql.types';
import {
  GraphQLError as OriginalGraphQLError,
  GraphQLErrorExtensions,
} from 'graphql';

type ContactDataKeys = keyof ContactDataFragment;

interface ValidationError {
  message: string;
}

interface ErrorExtension extends GraphQLErrorExtensions {
  validationErrors?: Partial<Record<ContactDataKeys, ValidationError>>[];
  code?: string;
}

interface CustomError extends OriginalGraphQLError {
  extensions: ErrorExtension;
}

export const getGraphQLValidationErrorMessage = (
  errors: ReadonlyArray<CustomError> | undefined
): string | undefined => {
  if (!errors || errors.length === 0) {
    return undefined;
  }

  const firstError = errors[0];
  const validationErrorMessages = firstError.extensions?.validationErrors
    ?.map(errorObj => Object.values(errorObj).map(error => error.message))
    .flat()
    .join(', ');

  const generalErrorMessage = `${firstError.message}: ${firstError.extensions?.code}`;

  return validationErrorMessages || generalErrorMessage || undefined;
};
