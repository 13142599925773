import { Checkbox } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrganizationSettingsFormOutupt } from '../utils/organizationSettingsFormSchema';

export const SameAddressCheckbox = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { clearErrors } = useFormContext<OrganizationSettingsFormOutupt>();

  const handleChange = (isSelected: boolean) => {
    if (isSelected) {
      clearErrors([
        'deliveryAddressCity',
        'deliveryAddressCountry',
        'deliveryAddressNumber',
        'deliveryAddressStreet',
        'deliveryAddressZipcode',
      ]);
    }
  };

  return (
    <Controller
      name="deliveryAddressSameAsBilling"
      render={({ field: { onChange, value } }) => {
        return (
          <Checkbox
            onChange={isSelected => {
              handleChange(isSelected);
              onChange(isSelected);
            }}
            value={value}
            isSelected={value}>
            {t(
              'insights.organizationSettingsDrawer.form.fields.sameAsBillingAddress.label'
            )}
          </Checkbox>
        );
      }}
    />
  );
};
