import {
  Checkbox,
  Grid,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { isSepaExportableDocument, SimplifiedPayableDocument } from '../types';
import { PaymentTableData } from './types';

export const isDisabledRow = (
  record: SimplifiedPayableDocument,
  selectedCurrency?: DocumentCurrency
) => {
  return (
    !isSepaExportableDocument(record) ||
    (selectedCurrency && record.currency !== selectedCurrency)
  );
};

interface SelectionHeaderProps {
  isAllRowsSelected: boolean;
  selectedFlatRows: Row<PaymentTableData>[];
  setSelectedRows: Dispatch<SetStateAction<SimplifiedPayableDocument[]>>;
  selectableDocuments: SimplifiedPayableDocument[];
}

export const SelectionHeader = ({
  setSelectedRows,
  selectedFlatRows,
  selectableDocuments,
}: SelectionHeaderProps) => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const hasSelectedRows = selectedFlatRows && selectedFlatRows.length > 0;
  const areAllSelectableRowsSelected =
    selectedFlatRows?.length === selectableDocuments.length;

  const hasSelectableDocuments = selectableDocuments.length > 0;

  const toggleAllRowsSelected = () => {
    if (hasSelectedRows) {
      return setSelectedRows([]);
    }

    return setSelectedRows(selectableDocuments);
  };

  return (
    <Grid>
      <Checkbox
        height="100%"
        width="100%"
        isDisabled={!hasSelectableDocuments}
        children={tPayments('table.headers.selected')}
        labelHidden
        onChange={() => toggleAllRowsSelected()}
        isIndeterminate={!areAllSelectableRowsSelected && hasSelectedRows}
        isSelected={areAllSelectableRowsSelected && hasSelectableDocuments}
      />
    </Grid>
  );
};

interface SelectionCellProps {
  row: Row<PaymentTableData>;
  setSelectedRows: Dispatch<SetStateAction<SimplifiedPayableDocument[]>>;
  selectableDocuments: SimplifiedPayableDocument[];
}

export const SelectionCell = ({
  row,
  setSelectedRows,
  selectableDocuments,
}: SelectionCellProps) => {
  const [tPayments] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({});

  const document = row.original.document as SimplifiedPayableDocument;
  const selectedCurrency = row.original.selectedCurrency;
  let warningText = tPayments('table.warning.creditorIbanMissing');
  if (selectedCurrency && document.currency !== selectedCurrency) {
    warningText = tPayments('table.warning.multipleCurrencies');
  }

  const handleSelect = () => {
    setSelectedRows(prevSelectedDocuments => {
      const prevSelectedRowIds = prevSelectedDocuments.map(
        (row: { id: string }) => row.id
      );

      if (prevSelectedRowIds.includes(row.id)) {
        const newSelectedDocuments = prevSelectedDocuments.filter(
          doc => doc.id !== row.id
        );

        return newSelectedDocuments;
      }

      const docToBeAdded = selectableDocuments.filter(doc => doc.id === row.id);
      const newSelectedDocuments = [...prevSelectedDocuments, ...docToBeAdded];

      return newSelectedDocuments;
    });
  };

  return isDisabledRow(document, selectedCurrency) ? (
    <>
      <Grid inline {...triggerProps} ref={triggerRef}>
        <Checkbox isDisabled labelHidden children="" />
      </Grid>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Text width="25rem">{warningText}</Text>
        </Tooltip>
      )}
    </>
  ) : (
    <Checkbox
      isSelected={row.isSelected}
      onChange={() => handleSelect()}
      children=""
      labelHidden
    />
  );
};
