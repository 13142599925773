import { useCostCenterCountForApproverLazyQuery } from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';

interface UseCostCenterCountProps {
  membershipId: string;
}

export const useCostCenterCount = ({
  membershipId,
}: UseCostCenterCountProps) => {
  const [
    fetchCostCenterCount,
    { data: costCenterData, loading: costCenterCountLoading },
  ] = useCostCenterCountForApproverLazyQuery({
    variables: { membershipId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
  });

  useFetchOnWindowFocus({ fetch: fetchCostCenterCount });

  const costCenterCount = costCenterData?.costCenterCountForApprover ?? 0;

  return { costCenterCount, costCenterCountLoading };
};
