import { TruncatedText } from '@candisio/design-system';
import { ImportPreviewTableData } from 'components/ImportPreviewTable/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { ValidationErrors } from 'views/Settings/BookingAccounts/components/BookingAccountImport/utils/parseBookingAccountCSV';

export const GeneralLedgerAccountsName = ({
  value,
}: CellProps<ImportPreviewTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);

  return (
    <TruncatedText>
      {value.errors.includes(ValidationErrors.NAME_MISSING)
        ? t('import.preview.errors.nameMissing.tableText')
        : value.value}
    </TruncatedText>
  );
};
