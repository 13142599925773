import { Button, usePopover } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { ColumnInstance } from 'react-table';
import { FilterPopover } from './FilterPopover';
import { PaginationFiltersHooksType } from './hooks/paginationFiltersHook';

export interface FilterWithSearchAndPaginationProps<TTableData extends object> {
  column: ColumnInstance<TTableData>;
  onUpdateIsFilterBeingUsed?: (value: boolean) => void;
  customPaginationFilterHooks?: PaginationFiltersHooksType; // allows to pass custom filters hooks
}

export const FilterWithSearchAndPagination = <TTableData extends object>({
  column: { setFilter, filterValue, id },
  customPaginationFilterHooks,
  onUpdateIsFilterBeingUsed,
}: FilterWithSearchAndPaginationProps<TTableData>) => {
  const [t] = useTranslation('table');

  const { isOpen, close, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({
      placement: 'bottom',
      // The buttons (sort, filter) in the header are conditionally rendered only when the user hovers on top of them
      // and the popover would disappear as soon as the user moves the mouse in the filter popover.
      // This will allow us to prevent the behaviour described.
      onOpenChange: isOpen => onUpdateIsFilterBeingUsed?.(isOpen),
    });

  const columnName: string = id;

  const buttonVariant =
    filterValue && filterValue.length > 0 ? 'primary' : 'secondary';

  return (
    <>
      <Button
        icon="filterOutlined"
        label={t('filterLabel')}
        size="xsmall"
        color="blue"
        variant={buttonVariant}
        {...triggerProps}
        ref={triggerRef}
      />

      {isOpen && (
        <FilterPopover
          {...popoverProps}
          ref={popoverRef}
          filterValue={filterValue}
          columnName={columnName}
          onApply={(filters: any) => {
            setFilter(filters);
            close();
          }}
          onReset={() => {
            setFilter(undefined);
            close();
          }}
          paginationFiltersHooks={customPaginationFilterHooks}
        />
      )}
    </>
  );
};
