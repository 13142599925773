import { Flex, Image } from '@candisio/design-system';
import {
  ExportStatus,
  ExportType as GqlExportType,
  useExportDatevFormatMutation,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDatev } from 'orgConfig/datev';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import { BdsSetupHintPanel } from 'views/Integrations/Export/toolkit/components/BdsSetupHint';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { InitExportAvailableMonths } from 'views/Integrations/Export/toolkit/components/InitExportAvailableMonths';
import { InitExportSummary } from 'views/Integrations/Export/toolkit/containers/ExportableEntitiesSummary/Init';
import { InitExportTypeLabel } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/InitExportTypeLabel';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { useGetReadyToExportCountByTimeframe } from 'views/Integrations/Export/toolkit/hooks/useGetReadyToExportCountByTimeframe';
import { useSelectedTimeFrame } from 'views/Integrations/Export/toolkit/hooks/useSelectedTimeFrame';
import datevCsvIcon from 'views/Integrations/Export/toolkit/images/icon-datev-csv.svg';
import { getRefetchExportsQueries } from 'views/Integrations/Export/toolkit/queries';
import { getDatevExportableEntities } from 'views/Integrations/Export/toolkit/utils';
import { getTimeFrameInputFromTimeFrameOption } from 'views/Integrations/Export/toolkit/utils/timeframe';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { DatevCsvExportButton } from './DatevCsvExportButton';

export const DatevCsv = () => {
  const state = useExportContext();
  const counterQueries = useCounterQueries();
  const { addExport } = useContext(ExportNotificationsContext);
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const { bdsBoughtButNotConnected } = useDatev(); // BDS-checked

  const [exportProvisionsWithDATEVFormatFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.exportProvisionsDatevFormatInternal,
  ]);

  const {
    exportType,
    exportId,
    includeEntitiesWithoutDoc,
    selectedProvisions,
  } = state;

  const {
    exportEntities: {
      ready: { all: allEntities },
    },
    exportAll,
  } = useExportDataGigaFactory(state);

  const { selectedTimeFrame, timeFrames } = useSelectedTimeFrame({
    entities: allEntities,
    exportAll,
    exportType,
    exportId,
  });

  const documentIds = selectedTimeFrame?.documentIds;

  const [requestDatevFormatExport, { loading: datevFormatExportLoading }] =
    useExportDatevFormatMutation({
      refetchQueries: [
        ...getRefetchExportsQueries({ documentIds }),
        ...counterQueries,
      ],
      awaitRefetchQueries: true,
    });

  const datevCSVExportEntities = getDatevExportableEntities(
    selectedTimeFrame,
    exportId ?? '',
    includeEntitiesWithoutDoc,
    exportProvisionsWithDATEVFormatFF,
    selectedProvisions
  );

  const hasExportableEntity =
    exportAll ||
    !!datevCSVExportEntities.documentIds.length ||
    !!datevCSVExportEntities.provisionIds.length ||
    !!datevCSVExportEntities.reversalIds.length ||
    !!datevCSVExportEntities.transactionIds.length ||
    !!datevCSVExportEntities.cardSettlementIds.length;

  const onExport = async () => {
    const { data: datevFormatResp } = await requestDatevFormatExport({
      variables: {
        documentIds: exportAll ? [] : datevCSVExportEntities.documentIds,
        transactionIds: exportAll ? [] : datevCSVExportEntities.transactionIds,
        cardSettlementIds: exportAll
          ? []
          : datevCSVExportEntities.cardSettlementIds,
        provisionIds: datevCSVExportEntities.provisionIds,
        reversalIds: datevCSVExportEntities.reversalIds,
        timeFrame: getTimeFrameInputFromTimeFrameOption(selectedTimeFrame),
        originalExportGuid: exportId,
        exportAll,
      },
    });

    if (!datevFormatResp?.exportDatevFormat) return;

    addExport({
      hash: datevFormatResp.exportDatevFormat,
      organization: orgId ?? '',
      status: ExportStatus.Exporting,
      documents: allEntities.length,
      type: GqlExportType.DatevCsv,
    });

    const viewDef = defineExportView(
      {
        ...state,
        sidebar: Sidebar.HISTORY,
        view: View.HISTORY,
        exportId: datevFormatResp.exportDatevFormat,
        shouldTriggerDownload: true,
      },
      { orgId }
    );

    navigate(viewDef.navigate);
  };

  const readyToExportEntitiesCountByTimeframe =
    useGetReadyToExportCountByTimeframe({
      selectedTimeFrame,
      entities: allEntities,
    });

  return (
    <Flex direction="column" justifyContent="space-between">
      <Flex direction="column" gap="space24">
        <ExportInfo>
          <Image
            src={datevCsvIcon}
            alt={exportType ?? ''}
            height="84px"
            width="84px"
            alignSelf="center"
          />
          <InitExportTypeLabel />
        </ExportInfo>
        {timeFrames.length > 0 && (
          <InitExportAvailableMonths
            exportType={exportType}
            timeFrames={timeFrames}
          />
        )}
        <InitExportSummary
          readyToExportEntitiesCount={readyToExportEntitiesCountByTimeframe}
        />
        {bdsBoughtButNotConnected && <BdsSetupHintPanel />}
      </Flex>
      <DatevCsvExportButton
        loading={datevFormatExportLoading}
        onExport={onExport}
        hasExportableEntity={hasExportableEntity}
        entities={allEntities}
      />
    </Flex>
  );
};
