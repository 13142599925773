import i18next from 'i18next';

export const getFormattedNote = (
  t: i18next.TFunction,
  note: string | null | undefined
) => {
  const translationMap: Record<string, string> = {
    Freight: 'freight',
    Packaging: 'packaging',
  };

  const translatedNote = note ? t(translationMap[note]) : null;

  return translatedNote;
};
