import { AmountCell } from 'components/Table/Cells/Amount';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { PaymentTableData } from './types';

export type PaymentAmountCellProps = CellProps<
  PaymentTableData,
  PaymentTableData['amount']
>;

export const AmountCellPayment = ({ value }: PaymentAmountCellProps) => {
  const [t] = useTranslation('payments');
  const { documentMoneyFormatter } = useMoneyFormatter();

  if (value === undefined) {
    return <AmountCell formattedAmount={null} />;
  }

  const { amount, discountAmount } = value;
  const hasDiscount = discountAmount && discountAmount.amount > 0;

  const formattedAmount = hasDiscount
    ? t('table.values.paidAmount', {
        amount: documentMoneyFormatter(amount),
        discountAmount: documentMoneyFormatter(discountAmount),
      })
    : documentMoneyFormatter(amount);

  return <AmountCell formattedAmount={formattedAmount} />;
};
