import { Flex, Grid, Heading, Paragraph } from '@candisio/design-system';

import { motion } from 'framer-motion';
import { InsightsWidget } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  EXTENDED_MAX_WIDGETS_ON_SECONDARY_SECTION,
  MAX_WIDGETS_ON_SECONDARY_SECTION,
} from 'views/InsightsWidgets/utils';
import { slide } from '../../styles';
import { VirtualizedGridList } from '../../VirtualizedGridList';

const MotionGrid = motion(Grid);

export interface SecondaryWidgetsSectionProps {
  onAddWidget: () => void;
  secondarySectionWidgets: InsightsWidget[];
}

export const SecondaryWidgetsSection = ({
  onAddWidget,
  secondarySectionWidgets,
}: SecondaryWidgetsSectionProps) => {
  const increasedLimitForWidgetsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.increasedLimitForWidgets
  );

  const maxWiddgetsOnSecondarySection = increasedLimitForWidgetsFF
    ? EXTENDED_MAX_WIDGETS_ON_SECONDARY_SECTION
    : MAX_WIDGETS_ON_SECONDARY_SECTION;

  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const hasFreeSpace =
    secondarySectionWidgets.length >= 0 &&
    secondarySectionWidgets.length < maxWiddgetsOnSecondarySection;

  const totalWidgetsInSecondarySection = secondarySectionWidgets.length;

  const counterTranslation = increasedLimitForWidgetsFF
    ? t('allWidgets.secondarySection.extendedCounter', {
        count: totalWidgetsInSecondarySection,
      })
    : t('allWidgets.secondarySection.counter', {
        count: totalWidgetsInSecondarySection,
      });

  return (
    <Grid gap="space16" id="secondary-widgets-section">
      <MotionGrid
        variants={slide}
        initial={slide.hidden}
        templateRows="auto 1fr"
        data-testid="secondary-widgets-section">
        <Flex direction="row" gap="space16" alignItems="baseline">
          <Heading as="h3">{t('allWidgets.secondarySection.title')}</Heading>
          <Paragraph color="gray500">{counterTranslation}</Paragraph>
        </Flex>
        <VirtualizedGridList
          secondarySectionWidgets={secondarySectionWidgets}
          onAddWidget={onAddWidget}
          hasFreeSpace={hasFreeSpace}
        />
      </MotionGrid>
    </Grid>
  );
};
