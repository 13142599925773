import { Grid } from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { CreditCardsWorkflowAndApproversFields } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/FormFields/CreditCardsWorkflowAndApproversFields';
import { AccountingInfoFieldOptions } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/types';
import { getTranslationContext } from 'orgConfig';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReadOnlyTransactionComboBoxField } from './Fields/ReadOnlyTransactionComboBoxField.js';
import { ReadOnlyTransactionFormPaginatedComboBoxField } from './Fields/ReadOnlyTransactionFormPaginatedComboBoxField.js';
import { ReadOnlyTransactionFormTextareaField } from './Fields/ReadOnlyTransactionFormTextareaField.js';
import { ReadOnlyTransactionFormTextField } from './Fields/ReadOnlyTransactionFormTextField.js';
import { TransactionFieldOptions } from './hooks/useTransactionFieldOptions.js';
import { InfoBox } from './InfoBox.js';
import {
  ReadOnlyTransactionFormSchemaContext,
  ReadOnlyTransactionFormValues,
} from './utils/readOnlyTransactionFormSchema.js';

export interface ReadOnlyTransactionFormProps {
  defaultValues: ReadOnlyTransactionFormValues;
  fieldOptions: AccountingInfoFieldOptions;
  transactionFieldOptions: TransactionFieldOptions;
  isTransactionExported: boolean;
  formContext: ReadOnlyTransactionFormSchemaContext;
  creditCardId?: string;
  cardRefNumber?: string;
}

export const ReadOnlyTransactionForm = ({
  defaultValues,
  fieldOptions,
  transactionFieldOptions,
  creditCardId,
  isTransactionExported,
  formContext,
  cardRefNumber,
}: ReadOnlyTransactionFormProps) => {
  const form = useForm({
    defaultValues,
    context: formContext,
  });

  const [t] = useTranslation();

  return (
    <FormProvider {...form}>
      <Grid gap="space16">
        <Grid gap="space16">
          {formContext.isNameComingFromCreditCard ? (
            <ReadOnlyTransactionFormPaginatedComboBoxField
              name="contact"
              readOnly
              placeholder={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.contact.readOnlyPlaceholder'
              )}
              label={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.contact.label'
              )}
              {...fieldOptions.contact}
            />
          ) : (
            <ReadOnlyTransactionFormTextField
              name="contact"
              readOnly
              label={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.contact.label'
              )}
            />
          )}

          {transactionFieldOptions.transactionId.isVisible && (
            <ReadOnlyTransactionFormTextField
              name="transactionId"
              readOnly
              label={t('transactions:transactionWithoutDoc.txId')}
            />
          )}

          {transactionFieldOptions.transactionDate.isVisible &&
            transactionFieldOptions.billingAmount.isVisible && (
              <Grid columns={2} alignContent="start" gap="space8">
                <ReadOnlyTransactionFormTextField
                  name="transactionDate"
                  readOnly
                  label={t('transactions:transactionWithoutDoc.txDate')}
                />
                <ReadOnlyTransactionFormTextField
                  name="transactionAmount"
                  readOnly
                  label={t('transactions:transactionWithoutDoc.txAmount')}
                />
              </Grid>
            )}

          <ReadOnlyTransactionFormTextareaField
            name="note"
            readOnly
            label={t('common:document.requestApproval.inputs.note.label', {
              context: getTranslationContext(),
            })}
            placeholder={t(
              'common:document.requestApproval.inputs.note.placeholder',
              {
                context: getTranslationContext(),
              }
            )}
          />
          {transactionFieldOptions.comment.isVisible && (
            <ReadOnlyTransactionFormTextareaField
              name="comment"
              readOnly
              label={t('transactions:transactionWithoutDoc.comment')}
            />
          )}

          {fieldOptions.generalLedgerAccount.isVisible && (
            <ReadOnlyTransactionFormPaginatedComboBoxField
              name="generalLedgerAccount"
              readOnly
              label={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.generalLedgerAccount.label'
              )}
              placeholder={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.generalLedgerAccount.readOnlyPlaceholder'
              )}
              {...fieldOptions.generalLedgerAccount}
            />
          )}

          {fieldOptions.taxCode.isVisible && (
            <HookFormComboBoxField
              name="taxCode"
              readOnly
              label={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.taxCode.label'
              )}
              placeholder={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.taxCode.placeholder'
              )}
              {...fieldOptions.taxCode.options}
            />
          )}
          {fieldOptions.costCenter.isVisible && (
            <ReadOnlyTransactionFormPaginatedComboBoxField
              name="costCenter"
              readOnly
              placeholder={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.costCenter.readOnlyPlaceholder'
              )}
              label={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.costCenter.label',
                { context: getTranslationContext() }
              )}
              {...fieldOptions.costCenter}
            />
          )}

          {fieldOptions.costObject.isVisible && (
            <ReadOnlyTransactionFormPaginatedComboBoxField
              name="costObject"
              readOnly
              placeholder={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.costObject.readOnlyPlaceholder'
              )}
              label={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.costObject.label',
                { context: getTranslationContext() }
              )}
              {...fieldOptions.costObject}
            />
          )}

          {fieldOptions.artistSocialInsuranceCode.isVisible && (
            <ReadOnlyTransactionComboBoxField
              name="artistSocialInsuranceCode"
              readOnly
              label={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.artistInsurranceCode.label'
              )}
              {...fieldOptions.artistSocialInsuranceCode.options}
            />
          )}

          {fieldOptions.extraCostInfo.isVisible && (
            <ReadOnlyTransactionFormPaginatedComboBoxField
              name="extraCostInfo"
              readOnly
              label={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.extraCostInfo.label'
              )}
              placeholder={t(
                'credit-cards:recurringPaymentsModal.addAccountingData.field.extraCostInfo.readOnlyPlaceholder'
              )}
              {...fieldOptions.extraCostInfo}
            />
          )}

          <CreditCardsWorkflowAndApproversFields
            readOnly={true}
            approversFieldOptions={fieldOptions.approvers}
            workflowFieldOptions={fieldOptions.workflow}
          />
        </Grid>
        <InfoBox
          cardRefNumber={cardRefNumber}
          creditCardId={creditCardId}
          isTransactionExported={isTransactionExported}
        />
      </Grid>
    </FormProvider>
  );
};
