import moment from 'moment';
import {
  DEFAULT_TIMEZONE,
  useOrganizationTimezone,
} from 'providers/OrganizationProvider';

export const getDateConverted = (
  organizationTimezone: string = DEFAULT_TIMEZONE
) => {
  /**
   * Converts DateString to DateTimeString using
   * organization's timezone
   *
   * @returns DateTime as ISOString
   *
   */
  const dateStringToDateTimeString = (isoDateString: string) => {
    const isoDateTimeString = moment
      .tz(isoDateString, organizationTimezone)
      .toISOString();

    return isoDateTimeString;
  };

  /**
   * Converts DateTimeString to DateString using
   * organization's timezone
   *
   * @returns Date as ISOString
   *
   */
  const dateTimeStringToDateString = (isoDateTimeString: string) => {
    const isoDateString = moment
      .tz(isoDateTimeString, moment.ISO_8601, organizationTimezone)
      .format(moment.HTML5_FMT.DATE);

    return isoDateString;
  };

  /**
   * Converts DateString to `Date` object using
   * organization's timezone
   *
   * @returns `Date` object
   *
   */
  const dateStringToDate = (isoDateString: string) => {
    const dateObject = moment
      .tz(isoDateString, moment.ISO_8601, organizationTimezone)
      .toDate();

    return dateObject;
  };

  /**
   * Converts `Date` object to DateString using
   * organization's timezone
   *
   * @returns Date as ISOString
   *
   */
  const dateToDateString = (dateObject: Date) => {
    const isoDateString = moment
      .tz(dateObject, organizationTimezone)
      .format(moment.HTML5_FMT.DATE);

    return isoDateString;
  };

  /**
   * Converts DateString to ISODate that is applied
   * when using DateFilters
   *
   * @param dateString
   * @returns Date as ISOString used for DateFilters
   */
  const dateStringToIsoDateFilterFormat = (dateString: string) => {
    const [day, month, year] = dateString.split('.');
    const formattedDate = `${year}-${month}-${day}`;
    const isoDate = dateStringToDateTimeString(formattedDate);

    return isoDate;
  };

  return {
    dateStringToDateTimeString,
    dateTimeStringToDateString,
    dateStringToDate,
    dateToDateString,
    dateStringToIsoDateFilterFormat,
  };
};

export const useDateConverter = () => {
  const organizationTimezone = useOrganizationTimezone();

  return getDateConverted(organizationTimezone);
};
