import { useTranslation } from 'react-i18next';
import archiveIcon from '../../images/archive-icon.svg';
import { MenuItemIcon } from '../../style';

export const Archive = () => {
  const [t] = useTranslation();

  return (
    <MenuItemIcon>
      <img src={archiveIcon} alt={t('navigation.archive')} />
    </MenuItemIcon>
  );
};
