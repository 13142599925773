import { useOtherIntegration } from 'orgConfig/other';
import { PaymentConditionsContainerCoreDataApi } from 'views/Settings/PaymentConditions/CoreDataApi/PaymentConditionsContainerCoreDataApi';
import { PaymentConditionsContainerDefault } from 'views/Settings/PaymentConditions/PaymentConditionsContainerDefault';
import { PaymentConditionsProps } from './PaymentConditions';

export interface PaymentConditionsContainerProps {
  onChangeStatus?: PaymentConditionsProps['onChangeStatus'];
  onShowItem?: PaymentConditionsProps['onShowItem'];
  onShowImport?: PaymentConditionsProps['onShowImport'];
  onShowImportHistory?: PaymentConditionsProps['onShowImportHistory'];
  status?: PaymentConditionsProps['status'];
}

/** Handles data fetching for PaymentConditions component */
export const PaymentConditionsContainer = ({
  onChangeStatus,
  onShowItem,
  onShowImport,
  onShowImportHistory,
  status = 'active',
}: PaymentConditionsContainerProps) => {
  const { shouldUseCoreDataApi } = useOtherIntegration();

  if (shouldUseCoreDataApi) {
    return (
      <PaymentConditionsContainerCoreDataApi
        status={status}
        onShowImportHistory={onShowImportHistory}
        onChangeStatus={onChangeStatus}
        onShowItem={onShowItem}
      />
    );
  }

  return (
    <PaymentConditionsContainerDefault
      onChangeStatus={onChangeStatus}
      onShowItem={onShowItem}
      onShowImport={onShowImport}
      onShowImportHistory={onShowImportHistory}
      status={status}
    />
  );
};
