import { HookFormWorkflowFieldItem } from 'components/HookFormFields/HookFormWorkFlowField';
import { CreditCardsAccountingInfoFieldItemsQuery } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { ValuesType } from 'utility-types';

export const mapToWorkflowFieldItem = (
  workflow: ValuesType<CreditCardsAccountingInfoFieldItemsQuery['workflows']>,
  orgMembersAbsence: CreditCardsAccountingInfoFieldItemsQuery['getOrgMembersAbsence'] = []
): HookFormWorkflowFieldItem => {
  const hasAbsence = orgMembersAbsence.some(member => {
    if (isNil(member.status)) return false;

    return workflow.steps?.some(step =>
      step.resolvers?.some(resolver => resolver?.id === member.membershipId)
    );
  });

  return {
    key: workflow.id,
    children: workflow.name,
    hasAbsence,
  };
};
