import {
  ContactRelationshipType,
  ContactsInfiniteScrollPaginationQuery,
  ContactsInfiniteScrollPaginationQueryVariables,
} from 'generated-types/graphql.types';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { contactsInfiniteScrollPaginationQuery } from 'views/Contacts/queries';

export const useContactsCountByRelationshipType = (
  relationshipTypes: ContactRelationshipType[]
) => {
  const { data, loading } = usePagePagination<
    ContactsInfiniteScrollPaginationQuery,
    ContactsInfiniteScrollPaginationQueryVariables
  >(contactsInfiniteScrollPaginationQuery, 'contactsPagination', {
    variables: {
      filters: {
        relationshipType: relationshipTypes,
      },
    },
  });

  return {
    count: data?.contactsPagination.pageInfo.totalCount ?? 0,
    loading,
  };
};
