import { ReactNode, RefObject, createContext, useContext, useRef } from 'react';

const RefContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const useStickyCardRefContext = () => useContext(RefContext);

export const StickyCardRefProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const cardRef = useRef<HTMLDivElement>(null);

  return <RefContext.Provider value={cardRef}>{children}</RefContext.Provider>;
};
