import { Grid, Link, Text, Switch, Flex } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface NotificationSummaryFooterProps {
  isToogled: boolean;
  onToogle: (value: boolean) => void;
}

export const NotificationSummaryFooter = ({
  isToogled,
  onToogle,
}: NotificationSummaryFooterProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  return (
    <Grid
      autoFlow="column"
      gap="space8"
      bottom={0}
      padding="space16 space16"
      background="gray100"
      alignItems="center"
      width="100%"
      position="sticky">
      <Link
        data-intercom="intercom-chat"
        icon="megaphone"
        href="containers/notifications/NotificationSummaryFooter">
        {t('popover.giveFeedback')}
      </Link>
      <Flex alignItems="center" gap="space10" justifySelf="end">
        <Text color="gray500">{t('popover.showOnlyUnread')}</Text>
        <Switch
          checked={isToogled}
          label={t('popover.showOnlyUnread')}
          onChange={onToogle}
        />
      </Flex>
    </Grid>
  );
};
