import { VirtualCardLimitsErrorMessages } from './virtualCardLimitsFormSchema';

export const virtualCardLimitsFormErrorMessages: VirtualCardLimitsErrorMessages =
  {
    comment: { label: 'ccRequestForm.fields.comment.label' },
    limitInterval: {
      label: 'ccRequestForm.fields.limitInterval.label',
    },
    transactionLimit: {
      label: 'ccRequestForm.fields.transactionLimit.label',
      custom: {
        translationKey: 'ccRequestForm.fields.transactionLimit.txLimitTooHigh',
      },
    },
    limit: {
      label: 'ccRequestForm.fields.limit.label.errorLabel',
    },
  };
