import { z } from 'zod';

export const BANK_ACCOUNT_NUMBER_MAX_LENGTH = 30;
/**
 * Zod schema to validate Non-SEPA bank account number
 *
 */
export const bankAccountNumberSchema = z
  .string()
  .max(BANK_ACCOUNT_NUMBER_MAX_LENGTH)
  .regex(/^[0-9]*$/);
