import {
  Color,
  Flex,
  Grid,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { motion } from 'framer-motion';
import { User, UserRole } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { LocalMembershipStatus } from '..';
import { getAvailableRoles } from '../../utils';
import { MobileAppPromotion } from './MobileAppPromotion';
import { TagWithTooltip } from './TagWithTooltip';

interface TeamMemberItemDeprecatedProps {
  user: Pick<User, 'avatarUrl' | 'email' | 'name' | 'roles' | 'id'>;
  queryString: string;
  toggleDrawer: (id: string & { emailVerified: boolean }) => void;
  membershipStatus?: LocalMembershipStatus;
}

const MotionGrid = motion(Grid);

const RolesList = ({ roles }: { roles?: UserRole[] }) => {
  const creditCardsSetup = useCreditCardsSetup();
  const [t] = useTranslation();
  const roleMap: Record<string, Color> = {
    [UserRole.Admin]: 'blue',
    [UserRole.Approver]: 'green',
    [UserRole.Requester]: 'yellow',
    [UserRole.CardManager]: 'pink',
  };

  const rolesOrder = [
    UserRole.Admin,
    UserRole.CardManager,
    UserRole.Requester,
    UserRole.Approver,
  ];

  const availableRoles =
    getAvailableRoles({
      isCreditCardsInUse: creditCardsSetup.isInUse,
    }) ?? [];

  const orderedRoles = [...(availableRoles || [])]?.sort(
    (a, b) => rolesOrder.indexOf(a) - rolesOrder.indexOf(b)
  );

  return (
    <Flex gap="space8" wrap="wrap">
      {orderedRoles?.map(
        role =>
          roles?.includes(role) && (
            <TagWithTooltip
              key={role}
              // eslint-disable-next-line candis/no-template-strings-inside-translation
              label={t(`settings.teamMembers.roles.${role}.title`)}
              // eslint-disable-next-line candis/no-template-strings-inside-translation
              description={t(`settings.teamMembers.roles.${role}.description`)}
              color={roleMap[role]}
            />
          )
      )}
    </Flex>
  );
};

export const TeamMemberItemDeprecated = ({
  user,
  queryString,
  toggleDrawer,
  membershipStatus,
}: TeamMemberItemDeprecatedProps) => {
  const mobileAppPromotionFF = useCandisFeatureFlags(
    FEATURE_FLAGS.mobileAppPromotion
  );

  const { hoverProps, isHovered } = useHover({});

  const { avatarUrl, email, name, roles, id } = user;

  return (
    <MotionGrid
      padding="space12 space20"
      templateColumns="auto 1fr 1.2fr"
      gap="space16"
      autoFlow="column"
      justifyContent="start"
      alignItems="center"
      borderBottom="1px solid gray200"
      onClick={() => toggleDrawer(id as string & { emailVerified: boolean })}
      style={{ cursor: 'pointer' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.25 }}
      {...hoverProps}>
      <AvatarWithStatusContainer
        name={name}
        img={avatarUrl ?? undefined}
        hasTooltip={false}
        userId={id}
      />
      <Grid>
        {/* @ts-ignore */}
        <TruncatedText
          fontWeight="semibold"
          fontSize="basic"
          overflowWrap="anywhere">
          {renderQueryHighlight({
            value: name,
            queryString: queryString,
          })}
        </TruncatedText>
        <Text color="gray500" overflowWrap="anywhere">
          {renderQueryHighlight({
            value: email,
            queryString: queryString,
          })}
        </Text>
      </Grid>
      <RolesList roles={roles ?? []} />
      {mobileAppPromotionFF && membershipStatus === 'ACTIVE' && isHovered ? (
        <Flex width="160px" justifyContent="right">
          <MobileAppPromotion user={user} />
        </Flex>
      ) : (
        <Flex width="160px" />
      )}
    </MotionGrid>
  );
};
