import { useApolloClient } from '@apollo/client';
import {
  DocumentTagsForm,
  DocumentTagsFormProps,
} from 'components/Tags/DocumentTagsForm';
import {
  EcmDocumentHistoryQueryVariables,
  EcmStorageFormInitialDataQueryVariables,
  GetDocumentForDraftQueryVariables,
  GetDocumentHistoryQueryVariables,
  GetDocumentQueryVariables,
  useTagsQuery,
  useUpdateDocumentTagsMutation,
} from 'generated-types/graphql.types';
import { useCallback, useMemo } from 'react';
import { ecmDocumentHistoryQuery } from 'views/EcmDocument/queries';
import { ecmStorageFormInitialDataQuery } from 'views/Inbox/DocumentProcessing/components/Ecm/gql';
import {
  documentQueries,
  getDocumentQuery,
} from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';

const sendWithDelay = (ms: number, resolve: () => Promise<unknown> | void) =>
  new Promise(res =>
    setTimeout(async () => {
      await resolve();
      res(null);
    }, ms)
  );

export interface DocumentTagsFieldContainerProps {
  globalDocumentId: string;
  documentId?: string;
  isInvoice?: boolean;
  documentTags: string[];
  readOnly?: boolean;
}

const WAIT_BEFORE_REFETCHING_DOCUMENT_HISTORY_MS = 5000;
export const DocumentTagsFieldContainer = ({
  globalDocumentId,
  documentId,
  isInvoice,
  documentTags,
  readOnly,
}: DocumentTagsFieldContainerProps) => {
  const client = useApolloClient();

  const { data, loading } = useTagsQuery({
    variables: { input: { isActive: true } },
    skip: !globalDocumentId,
  });

  const [
    updateDocumentTags,
    { loading: updating, reset, data: updateDocumentTagsData },
  ] = useUpdateDocumentTagsMutation({
    onCompleted: () => {
      client.cache.evict({ fieldName: 'documentTags' });
      void sendWithDelay(WAIT_BEFORE_REFETCHING_DOCUMENT_HISTORY_MS, () => {
        reset();
      });
    },
    onQueryUpdated: observableQuery => {
      // BE resolves mutation before updating timeline, so we cannot guarantee that if we refetch right away, the new protocol entry will be there. Hence this *temporary* solution to wait 5 seconds before refetching.
      void sendWithDelay(WAIT_BEFORE_REFETCHING_DOCUMENT_HISTORY_MS, () =>
        observableQuery.refetch()
      );
    },
    refetchQueries: () => {
      if (!documentId) return [];
      const id = documentId;

      if (isInvoice) {
        // We need to clear document data cache to ensure we show latest tags
        client.cache.evict({ fieldName: 'getDocument' });
        client.cache.evict({ fieldName: 'getDocumentForDraft' });

        return [
          {
            query: documentHistoryQuery,
            variables: { id } satisfies GetDocumentHistoryQueryVariables,
          },
          {
            query: getDocumentQuery,
            variables: { id } satisfies GetDocumentQueryVariables,
          },
          {
            query: documentQueries.forDraftForm,
            variables: { id } satisfies GetDocumentForDraftQueryVariables,
          },
        ];
      }

      // We need to clear document data cache to ensure we show latest tags
      client.cache.evict({ fieldName: 'getAggregatedEcmDocument' });

      return [
        {
          query: ecmDocumentHistoryQuery,
          variables: { id } satisfies EcmDocumentHistoryQueryVariables,
        },
        {
          query: ecmStorageFormInitialDataQuery,
          variables: { id } satisfies EcmStorageFormInitialDataQueryVariables,
        },
      ];
    },
  });

  const handleOnChange = useCallback(
    async (tagIds: string[]) => {
      const updateDocumentTagsResult = await updateDocumentTags({
        variables: { input: { globalDocumentId, tagIds } },
      });

      return (
        updateDocumentTagsResult.data?.updateDocumentTags?.map(
          tags => tags.id
        ) ?? []
      );
    },
    [globalDocumentId, updateDocumentTags]
  );

  const defaultValues: DocumentTagsFormProps['defaultValues'] = useMemo(
    () => ({
      tags: documentTags,
    }),
    [documentTags]
  );

  if (loading || !globalDocumentId) return null;

  return (
    <DocumentTagsForm
      defaultValues={defaultValues}
      onChange={handleOnChange}
      submitting={updating}
      success={Boolean(updateDocumentTagsData?.updateDocumentTags)}
      tags={updateDocumentTagsData?.updateDocumentTags ?? data?.tags ?? []}
      readOnly={readOnly}
    />
  );
};
