import checkImage from 'assets/icon-check.svg';
import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { ApprovedEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from 'views/Inbox/models';
import { ItemApprovalStatus } from '../styles';

export const DocumentApprovedComponent = ({
  clientInfo,
}: ApprovedEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <TimelineEventWithMobileIndication
      iconName="mobileApprove"
      clientName={clientInfo?.name}
      eventComponent={
        <ItemApprovalStatus status={ApprovalStatus.APPROVED}>
          <img src={checkImage} alt={ApprovalStatus.APPROVED} />
          {t('document.compact.history.approvedDocument')}
        </ItemApprovalStatus>
      }
    />
  );
};
