import { ApolloError, QueryHookOptions, useQuery } from '@apollo/client';
import { Query } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { getCountExportableEntities } from './useCounterQueries';

interface ExportCounterProps {
  loading: boolean;
  readyToExportEntitiesCount: number;
  notReadyToExportEntitiesCount: number;
  provisionsCount: number;
  error: ApolloError | undefined;
  refetch: ReturnType<typeof useQuery>['refetch'];
}
interface ExportCounterQueryProps extends QueryHookOptions {}

export const useExportableEntitiesCounter = (
  queryOptions: ExportCounterQueryProps
): ExportCounterProps => {
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'getCountExportableEntities'>
  >(getCountExportableEntities, {
    ...queryOptions,
  });

  const { exportProvisionsFF, bdsConnected } = useDatev(); // BDS-checked

  const {
    documentReadyForExportCount,
    transactionReadyForExportCount,
    pendingTransactionCount,
    settlementsReadyForExportCount,
    settlementsNotReadyForExportCount,
    provisionsReadyForExportCount,
    reversalsReadyForExportCount,
  } = data?.getCountExportableEntities ?? {};

  const readyToExportEntitiesCount =
    (documentReadyForExportCount?.count ?? 0) +
    (transactionReadyForExportCount?.count ?? 0) +
    (settlementsReadyForExportCount?.count ?? 0) +
    (exportProvisionsFF && bdsConnected
      ? (provisionsReadyForExportCount?.count ?? 0) +
        (reversalsReadyForExportCount?.count ?? 0)
      : 0);

  const notReadyToExportEntitiesCount =
    (pendingTransactionCount?.count ?? 0) +
    (settlementsNotReadyForExportCount?.count ?? 0);

  const provisionsCount =
    (provisionsReadyForExportCount?.count ?? 0) +
    (reversalsReadyForExportCount?.count ?? 0);

  return {
    refetch,
    loading,
    readyToExportEntitiesCount,
    notReadyToExportEntitiesCount,
    provisionsCount,
    error,
  };
};
