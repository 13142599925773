import { Grid, Box, TruncatedText } from '@candisio/design-system';
import { HookFormCountrySelectField } from 'components/HookFormFields/HookFormCountrySelectField';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactFormValues } from './contactFormSchema';

interface ContactFormAddressInfoFieldsProps {
  disabled?: boolean;
}

export const ContactFormAddressInfoFields = ({
  disabled,
}: ContactFormAddressInfoFieldsProps) => {
  const [t] = useTranslation();

  const { watch } = useFormContext<ContactFormValues>();

  const [addressInfoType] = watch(['addressInfoType']);

  return (
    <Grid gap="space16">
      <Box fontSize="basic" paddingX="space8">
        <HookFormRadioGroupField
          name="addressInfoType"
          label={t('settings.contacts.details.edit.addressInfo.type.label')}
          options={[
            {
              value: 'STREET',
              children: t(
                'settings.contacts.details.edit.addressInfo.type.street'
              ),
              isDisabled: false,
            },
            {
              value: 'POST_OFFICE_BOX',
              children: t(
                'settings.contacts.details.edit.addressInfo.type.poBox'
              ),
              isDisabled: false,
            },
          ]}
          orientation="horizontal"
          disabled={disabled}
        />
      </Box>
      {addressInfoType === 'STREET' && (
        <HookFormTextField
          name="street"
          inputProps={{
            autoComplete: 'off',
          }}
          label={t('settings.contacts.details.edit.addressInfo.street')}
          disabled={disabled}
        />
      )}
      {addressInfoType === 'POST_OFFICE_BOX' && (
        <HookFormTextField
          name="postOfficeBox"
          inputProps={{
            autoComplete: 'off',
          }}
          label={t('settings.contacts.details.edit.addressInfo.poBox')}
          disabled={disabled}
        />
      )}
      <Grid autoFlow="column" gap="space12" templateColumns="15ch 3fr">
        <Grid overflow="hidden">
          <HookFormTextField
            name="postalCode"
            inputProps={{
              autoComplete: 'off',
            }}
            label={
              <TruncatedText
                style={{
                  pointerEvents: 'all', // override DS to display tooltip
                }}>
                {t('settings.contacts.details.edit.addressInfo.postalCode')}
              </TruncatedText>
            }
            disabled={disabled}
          />
        </Grid>
        <HookFormTextField
          name="city"
          inputProps={{
            autoComplete: 'off',
          }}
          label={t('settings.contacts.details.edit.addressInfo.city')}
          disabled={disabled}
        />
      </Grid>
      <HookFormCountrySelectField
        name="country"
        label={t('settings.contacts.details.edit.addressInfo.country')}
        disabled={disabled}
      />
    </Grid>
  );
};
