import { ContactType } from 'generated-types/graphql.types';
import { electronicFormatIBAN } from 'ibantools';
import { ContactFormOutput } from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';

export const getContactFieldValuesToSubmit = (
  initialValues: ContactFormOutput
) => {
  const inputCompany: ContactFormOutput = {
    ...initialValues,
    individualFirstName: null,
    individualLastName: null,
    notSpecifiedName: null,
    iban: initialValues.iban ? electronicFormatIBAN(initialValues.iban) : null,
    addressInfoType: initialValues.addressInfoType || 'STREET',
    postOfficeBox:
      initialValues.addressInfoType === 'POST_OFFICE_BOX'
        ? initialValues.postOfficeBox
        : null,
    street:
      initialValues.addressInfoType === 'STREET' ? initialValues.street : null,
  };

  const inputNotSpecified: ContactFormOutput = {
    ...initialValues,
    companyName: null,
    individualFirstName: null,
    individualLastName: null,
    iban: initialValues.iban ? electronicFormatIBAN(initialValues.iban) : null,
    addressInfoType: initialValues.addressInfoType || 'STREET',
    postOfficeBox:
      initialValues.addressInfoType === 'POST_OFFICE_BOX'
        ? initialValues.postOfficeBox
        : null,
    street:
      initialValues.addressInfoType === 'STREET' ? initialValues.street : null,
  };

  const inputIndividual: ContactFormOutput = {
    ...initialValues,
    companyName: null,
    notSpecifiedName: null,
    iban: initialValues.iban ? electronicFormatIBAN(initialValues.iban) : null,
    addressInfoType: initialValues.addressInfoType || 'STREET',
    postOfficeBox:
      initialValues.addressInfoType === 'POST_OFFICE_BOX'
        ? initialValues.postOfficeBox
        : null,
    street:
      initialValues.addressInfoType === 'STREET' ? initialValues.street : null,
  };

  const contactType = initialValues.contactType;

  switch (contactType) {
    case ContactType.Company:
      return inputCompany;
    case ContactType.Individual:
      return inputIndividual;
    case ContactType.NotSpecified:
      return inputNotSpecified;
    default:
      return initialValues;
  }
};
