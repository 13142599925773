import { AccountingInfoFormSchemaFormValues } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/accountingInfoFormSchema';
import { CardAccountingData } from 'generated-types/graphql.types';

const sanitizeInput = (initialValue?: string | null, newValue?: string) => {
  if (initialValue === newValue) return undefined;
  else if (!initialValue && !newValue) return undefined;
  else if (initialValue && !newValue) return null;
  else return newValue;
};

const sanitizeArrInput = (
  initialArr?: string[][] | null,
  newArr?: string[][]
) => {
  const formattedInitialArr = initialArr?.toString();
  const formattedNewArr = newArr?.toString();

  if (formattedInitialArr === formattedNewArr) return undefined;
  else if (!formattedInitialArr && !formattedNewArr) return undefined;
  else if (formattedInitialArr && !formattedNewArr) return null;
  else return newArr;
};

export const sanitizeAccountingData = ({
  formValues,
  initialValues,
}: {
  formValues: AccountingInfoFormSchemaFormValues;
  initialValues?: CardAccountingData | null;
}) => {
  const approvers = formValues.approvers?.map(itm => itm?.approvers);

  const newApproverIds =
    formValues.approvalMode === 'approvers' && approvers
      ? approvers
      : undefined;

  const newWorkflowId =
    formValues.approvalMode === 'workflow' && formValues.workflow
      ? formValues.workflow
      : undefined;

  const accountingData = {
    approverIds: sanitizeArrInput(initialValues?.approverIds, newApproverIds),
    costCenterId: sanitizeInput(
      initialValues?.costCenterId,
      formValues.costCenter.value ?? undefined
    ),
    costObjectId: sanitizeInput(
      initialValues?.costObjectId,
      formValues.costObject.value ?? undefined
    ),
    generalLedgerAccountId: sanitizeInput(
      initialValues?.generalLedgerAccountId,
      formValues.generalLedgerAccount.value ?? undefined
    ),
    contactId: sanitizeInput(
      initialValues?.contactId,
      formValues.contact.value ?? undefined
    ),
    bookingKeyId: sanitizeInput(
      initialValues?.bookingKeyId,
      formValues.taxCode ?? undefined
    ),
    approvalWorkflowTemplateId: sanitizeInput(
      initialValues?.approvalWorkflowTemplateId,
      newWorkflowId
    ),
    artistSocialInsuranceCode: sanitizeInput(
      initialValues?.artistSocialInsuranceCode,
      formValues.artistSocialInsuranceCode ?? undefined
    ),
    extraCostInfoId: sanitizeInput(
      initialValues?.extraCostInfoId,
      formValues.extraCostInfo.value ?? undefined
    ),
  };

  return accountingData;
};
