import { useQuery } from '@apollo/client';
import {
  Query,
  useWorkflowTemplatesCountersQuery,
  useWorkflowTemplatesQuery,
} from 'generated-types/graphql.types';
import { uniqBy } from 'lodash';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { useSearchParams } from 'react-router-dom-v5-compat';

export interface Workflow {
  name: string;
  id: string;
  createdAt?: Date;
  description?: string;
  users?: {
    avatarUrl?: string;
    name?: string;
    id?: string;
  }[];
}

interface useWorkflowDataProps {
  isArchiveActive: boolean;
}

export interface WorkflowData {
  workflowData: Workflow[];
  loading: boolean;
  workflowCounts?: { active: number; archived: number };
  workflowUser?: string;
}

export const enum WorkflowTemplatesQueryProps {
  archive = 'archive',
  workflowUser = 'workflowUser',
  sort = 'sort',
}

export const useWorkflowData = ({
  isArchiveActive,
}: useWorkflowDataProps): WorkflowData => {
  const { data: counts } = useWorkflowTemplatesCountersQuery();

  const { data, loading } = useWorkflowTemplatesQuery({
    variables: {
      input: {
        isArchived: isArchiveActive,
      },
    },
  });

  const [searchParams] = useSearchParams();
  const approver = searchParams.get(WorkflowTemplatesQueryProps.workflowUser);

  const { data: workflowUserData } = useQuery<Pick<Query, 'currentUser'>>(
    currentUserQuery,
    {
      variables: {
        id: approver,
      },
    }
  );

  const workflowUser = workflowUserData?.currentUser?.name;
  const workflowCounts = counts?.workflowTemplatesCounters;

  const workflowData = data
    ? data?.workflowTemplates.map(workflow => {
        const usersList = (workflow.steps ?? [])
          .map(workFlow => workFlow.resolvers)
          .flat()
          .map(resolver => ({
            avatarUrl: resolver?.avatarUrl ?? undefined,
            id: resolver?.id ?? undefined,
            name: resolver?.name ?? undefined,
          }));

        return {
          name: workflow.name,
          id: workflow.id,
          createAt: workflow.createdAt
            ? new Date(workflow.createdAt)
            : undefined,
          description: workflow.description ?? undefined,
          users: uniqBy(usersList, 'id'),
        };
      })
    : [];

  return { workflowData, loading, workflowCounts, workflowUser };
};
