import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

export const SplitGrossAmount = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name=""
      inputProps={{
        value: t('document.requestApproval.taxPresentations.gross'),
      }}
      label={t('document.requestApproval.inputs.taxPresentation.label')}
      readOnly
    />
  );
};
