import { defaultTheme, Grid, Tag } from '@candisio/design-system';
import { styled } from '../../../../../styles/themes';
export const ExportCardLayout = styled.div`
  cursor: pointer;
  padding: 1rem;
  color: ${defaultTheme.colors.gray800};
  background-color: ${defaultTheme.colors.gray100};
  font-size: ${defaultTheme.fontSizes.basic};
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: max-content auto 1fr;
  gap: 1rem;
  align-items: center;
  border-radius: 0.5rem;
  transition: 0.25s cubic-bezier(0.87, 0, 0.13, 1);

  &:hover {
    box-shadow: ${defaultTheme.shadows.elevatedShadow3};
  }

  svg {
    fill: #aeaeae;
    justify-self: end;
  }
`;

export const IconArrow = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.26329e-07 1.40625L1.42405 1.69816e-08L7.5 6L1.42405 12L1.67694e-08 10.5938L4.6519 6L1.26329e-07 1.40625Z"
    />
  </svg>
);
interface ExportCardProps {
  title: string;
  icon: string;
  onClick: () => void;
  tag?: string;
  isDisabled?: boolean;
}

export const ExportCard = ({
  title,
  onClick,
  icon,
  isDisabled,
  tag,
  ...props
}: ExportCardProps) => (
  <ExportCardLayout
    onClick={onClick}
    {...props}
    aria-label={title}
    role="button">
    <img src={icon} height={55} width={55} alt={title} />
    <Grid gap="space8">
      {tag && (
        <Tag color="blue" variant="secondary">
          {tag}
        </Tag>
      )}
      <span>{title}</span>
    </Grid>
    <IconArrow />
  </ExportCardLayout>
);
