import { CardUsageFormErrorMessages } from './cardUsageFormSchema';

export const cardUsageFormErrorMessages: CardUsageFormErrorMessages = {
  validity: {
    label: 'ccRequestForm.fields.validity.label',
  },
  cardLabel: {
    label: 'ccRequestForm.fields.name.label',
  },
  purpose: {
    label: 'ccRequestForm.fields.purpose.label',
  },
};
