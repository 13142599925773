import {
  Box,
  Flex,
  Icon,
  Link,
  Modal,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';

import { CommentContainer } from 'components/Comment/CommentContainer';
import { DocumentHistoryContainer } from 'components/DocumentHistory/DocumentHistoryContainer';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { PdfInvoiceSkeleton } from 'components/DocumentViewer/PdfViewer/PdfInvoiceSkeleton';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { Loader } from 'components/Loader';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { queryParams } from 'components/Transactions/Table/util';
import { TransactionsDetailsCardContainer } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCard';
import {
  Document,
  DocumentStatus,
  InvoiceAssociationStatus,
  useAddFileUploadMutation,
  useDocumentFileUploadUrlMutation,
  useGetCardIssuerTransactionByIdQuery,
  useProcessDocumentMutation,
  useUpdateFileUploadMutation,
} from 'generated-types/graphql.types';

import { useAttachments } from 'hooks/useCanAddAttachments/useAttachments';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { isNil } from 'lodash';
import { AppRouteParams, Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import {
  MAX_FILE_SIZE_MB,
  fileUploadUtil,
} from 'views/AppContainer/components/Header/components/DocumentUploadModal/fileUploadUtil';
import { DocumentHistoryTabsContainer } from 'views/Inbox/DocumentProcessing/components/DocumentHistoryTabs/DocumentHistoryTabsContainer';
import { Attachments } from 'views/Inbox/DocumentProcessing/components/OldAttachments';
import { DOCUMENT_PROCESSING_SEARCH_PARAMS } from 'views/Inbox/DocumentProcessing/consts';
import {
  LeftSectionContentWrapper,
  LeftSectionOuterWrapper,
} from 'views/Inbox/DocumentProcessing/LeftSection';
import { DocumentTransactionDetails } from 'views/Inbox/Transactions/DocumentTransactionDetails';
import { DocumentTransactionProcessing } from 'views/Inbox/Transactions/DocumentTransactionProcessing';
import {
  DocumentDetailsPanel,
  TransactionViewEmptyState,
} from '../DocumentDetails/components/DocumentDetailsPanel';
import { CommentTransactionContainer } from './component/CommentTransactionContainer';
import { InvoiceLayout } from './component/InvoiceUploadContainer';
import { InvoiceUploaderContainer } from './component/InvoiceUploadContainer/InvoiceUploaderContainer';
import { TransactionHistoryContainer } from './component/TransactionHistoryContainer';
import { DocumentsWithoutTransaction } from './DocumentsWithoutTransaction/DocumentsWithoutTransaction';
import { getRefetchCardIssuerTransaction } from './gql';
import { InvoiceUploadDisabledState } from './InvoiceState/InvoiceUploadDisabledState';
import { RightInformationContent } from './RightInformationContent';
import { ReadOnlyFormFromDeprecated } from './RightInformationContent/ReadOnlyAccountingDataForm/ReadOnlyFormDeprecated';
import { ReadOnlyTransactionFormContainer } from './RightInformationContent/ReadOnlyAccountingDataForm/ReadOnlyTransactionFormContainer';
import { TransactionReadOnlySideBar } from './RightInformationContent/TransactionReadOnlySidebar';
import {
  TransactionAssociationView,
  usePrefetchQueries,
  useTransactionAssociationNavigation,
} from './utils';

interface RouteParams {
  transactionId: string;
  organizationSlug: string;
  type: TransactionAssociationView;
}

const TitleWithInfo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'bottom' });

  return (
    <Flex alignItems="center" gap="space8">
      <Text>{t('transactionAssociation.documentsModal.title')}</Text>
      <Flex {...triggerProps} ref={triggerRef}>
        <Icon icon="infoCircle" color="gray700" size="space12" />

        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            <Text fontWeight="regular" color="gray800" fontSize="basic">
              {t('transactionAssociation.documentsModal.titleInfo')}
            </Text>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export const TransactionAssociationAdminRequester = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();

  const {
    transactionId,
    organizationSlug,
    type: routeType,
  } = useParams<RouteParams>();

  const { state } = useLocation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const [isDocumentChanging, setIsDocumentChanging] = useState<boolean>(false);

  const isArchivedTx = searchParams.get('isArchivedTx') !== null;
  const [editRequestedDocumentMode, setEditRequestedDocumentMode] = useState(
    isArchivedTx ?? false
  );

  const route =
    editRequestedDocumentMode && isArchivedTx ? Routes.ARCHIVE : routeType;

  const [addFile] = useAddFileUploadMutation();
  const [updateFile] = useUpdateFileUploadMutation();
  const [documentsNoTxModalIsOpen, setDocumentsNoTxModalIsOpen] =
    useState(false);

  const [documentFileUploadUrl] = useDocumentFileUploadUrlMutation();

  const [processDocument, { loading: isProcessDocumentLoading }] =
    useProcessDocumentMutation({
      awaitRefetchQueries: true,
      onCompleted: () => {
        setIsDocumentChanging(currentState => !currentState);
      },
    });

  const counterQueries = useCounterQueries();

  const cursor = searchParams.get(DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR);
  const transactionListView = generatePath(
    `/:${AppRouteParams.organizationSlug}${routeType}${Routes.TRANSACTIONS}`,
    { organizationSlug }
  );

  const {
    nextTransactionLink,
    prevTransactionLink,
    nextTransactionId,
    prevTransactionId,
    isLoadingNavigationData,
    transactionListCount,
    linkBackToList,
  } = useTransactionAssociationNavigation({
    cursor,
    organizationSlug,
    routeType: route,
  });

  usePrefetchQueries(prevTransactionId, nextTransactionId);

  const listNavigatorTitle: string = isLoadingNavigationData
    ? t('transactionAssociation.listNavigator.loading')
    : t('transactionAssociation.listNavigator.title', {
        count: transactionListCount,
      });

  const nextTransactionPage = () => {
    if (nextTransactionLink) {
      navigate(nextTransactionLink);
    }
  };

  const prevTransactionPage = () => {
    if (prevTransactionLink) {
      navigate(prevTransactionLink);
    }
  };

  const toAllDoneTransactionList = () => {
    navigate({
      pathname: transactionListView,
      search: new URLSearchParams({ [queryParams.allDone]: 'true' }).toString(),
    });
  };

  const cycleTransactions = () => {
    if (nextTransactionLink) {
      nextTransactionPage();
    } else if (prevTransactionLink) {
      prevTransactionPage();
    } else {
      toAllDoneTransactionList();
    }
  };

  const { data, loading: isLoadingCurrentCardTransaction } =
    useGetCardIssuerTransactionByIdQuery({
      variables: { id: transactionId },
    });

  const handleUploaderChange = async (file: File) => {
    if (file) {
      setIsDocumentChanging(true);
      const selectedFile = file;
      const uploadResult = await fileUploadUtil(selectedFile, {
        addFile,
        documentFileUploadUrl,
        updateFile,
        onUploaded: async () => ({}),
      });

      if (uploadResult?.errorMessage) {
        setIsDocumentChanging(false);
        const errorMessage = `common:${uploadResult.errorMessage}`;

        error(
          t(errorMessage, {
            size: MAX_FILE_SIZE_MB,
          })
        );

        return;
      }

      if (uploadResult?.file) {
        const fileId = uploadResult.file.id;
        if (fileId && uploadResult?.file.name && transactionId) {
          const { data } = await processDocument({
            variables: {
              fileId,
              transactionIds: [transactionId],
            },
            refetchQueries: [
              ...getRefetchCardIssuerTransaction(transactionId),
              ...counterQueries,
            ],
          });

          const createdDocumentId = data?.processDocument?.id;
          const fileName = uploadResult?.file.name;
          if (createdDocumentId) {
            const linkToCreatedDocument = (
              <Trans
                values={{ fileName }}
                i18nKey="transactions:transactionAssociation.successToastMessage">
                <Link
                  href={`/${organizationSlug}${Routes.INBOX}/${createdDocumentId}`}>
                  {{ fileName } as any}
                </Link>
                uploaded and linked successfully to transaction
              </Trans>
            );

            success(linkToCreatedDocument);
          } else {
            error(t('transactionAssociation.errorToastMessage'));
          }
        }
      }
    }
  };

  const document = data?.getCardIssuerTransactionById.documents[0];

  const transaction = data?.getCardIssuerTransactionById;

  const ownerTxMembershipId = transaction?.member.membershipId ?? undefined;
  const invoiceAssociationStatus =
    data?.getCardIssuerTransactionById?.invoiceAssociationStatus;

  const linkedDocumentStatus =
    data?.getCardIssuerTransactionById.documents[0]?.status;

  const transactionAmount =
    data?.getCardIssuerTransactionById.transactionAmount ?? undefined;

  const isNoInvoiceNeededToggleActive = !isNil(
    data?.getCardIssuerTransactionById.invoiceNotNeeded
  );

  const isTransactionExported =
    (linkedDocumentStatus &&
      [DocumentStatus.Exported, DocumentStatus.Exporting].includes(
        linkedDocumentStatus
      )) ??
    data?.getCardIssuerTransactionById?.isExported;

  const canUserLinkInvoice =
    invoiceAssociationStatus === InvoiceAssociationStatus.Missing ||
    (isNoInvoiceNeededToggleActive && !isTransactionExported);

  const documentId = data?.getCardIssuerTransactionById.documents[0]?.id;

  const mainDocumentFile: PDFDetails = useMemo(
    () => ({
      name: document?.documentFile?.name ?? '',
      url: document?.documentFile?.url ?? '',
      id: document?.documentFile?.id ?? documentId,
      size: document?.documentFile?.size ?? 0,
    }),
    [document, documentId]
  );

  const documentAttachments = document?.attachments;

  const {
    attachPermissions,
    attachments,
    attachmentListItems,
    selectedPdf,
    setSelectedPdf,
  } = useAttachments({
    documentFile: mainDocumentFile,
    documentId,
    documentAttachments,
  });

  const toggleEditRequestedDocumentMode = () => {
    const editMode = !editRequestedDocumentMode;

    if (editMode) {
      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}/:transactionId`,
        { organizationSlug, transactionId }
      );

      navigate({ pathname, search: searchParams.toString() });
    } else {
      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}/:transactionId`,
        { organizationSlug, transactionId }
      );

      navigate({ pathname, search: searchParams.toString() });
    }

    setEditRequestedDocumentMode(editMode);
  };

  const onToggleEditDocumentMode = () => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}/:transactionId`,
      { organizationSlug, transactionId }
    );

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('isArchivedTx', '1');

    navigate({ pathname, search: newSearchParams.toString() });
    setEditRequestedDocumentMode(true);
  };

  const renderDocumentForm = (documentId: string) => {
    if (routeType === Routes.INBOX) {
      return (
        <DocumentTransactionProcessing
          documentId={documentId}
          transactionId={transactionId}
          organizationSlug={organizationSlug}
          transactionCursor={cursor ?? undefined}
          editRequestDocumentMode={editRequestedDocumentMode}
          setEditRequestedDocumentMode={setEditRequestedDocumentMode}
          cycleTransaction={cycleTransactions}
          toggleEditRequestedDocumentMode={toggleEditRequestedDocumentMode}
          isArchivedTx={isArchivedTx}
        />
      );
    } else {
      return (
        <DocumentTransactionDetails
          documentId={documentId}
          organizationSlug={organizationSlug}
          cycleTransactions={cycleTransactions}
          onToggleEditDocumentMode={onToggleEditDocumentMode}
        />
      );
    }
  };

  useEffect(() => {
    if (
      !isLoadingCurrentCardTransaction &&
      !transaction &&
      !editRequestedDocumentMode
    ) {
      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${routeType}${Routes.TRANSACTIONS}`,
        { organizationSlug }
      );

      navigate(pathname);
    }
  }, [
    editRequestedDocumentMode,
    isLoadingCurrentCardTransaction,
    navigate,
    organizationSlug,
    routeType,
    transaction,
  ]);

  const closeModal = () => {
    setDocumentsNoTxModalIsOpen(false);
  };

  const isSectionLoading =
    isLoadingCurrentCardTransaction ||
    isDocumentChanging ||
    isProcessDocumentLoading;

  const handleBackToList = () => {
    if (state?.from) {
      return navigate({
        pathname: state.from,
        search: state.search,
      });
    }

    navigate(linkBackToList);
  };

  const attachmentsTabTitle = t('common:document.tabs.oldAttachments.title');
  const protocolTabTitle = t('common:document.tabs.history');

  return (
    <DetailsLayout
      key={transactionId}
      leftSection={
        <LeftSectionOuterWrapper>
          <ListNavigator
            backToListText={t('common:document.backToDocumentList')}
            arrowLeftTooltip={t(
              'transactionAssociation.listNavigator.prevTransaction'
            )}
            arrowRightTooltip={t(
              'transactionAssociation.listNavigator.nextTransaction'
            )}
            onBackToList={handleBackToList}
            onPrev={prevTransactionLink ? prevTransactionPage : undefined}
            onNext={nextTransactionLink ? nextTransactionPage : undefined}
            listNavigatorCounter={listNavigatorTitle}
            loading={isLoadingNavigationData}
          />
          <LeftSectionContentWrapper>
            <DocumentDetailsPanel
              loadingDoc={isLoadingCurrentCardTransaction}
              document={document as Document}
              emptyState={<TransactionViewEmptyState />}
            />
            <TransactionsDetailsCardContainer
              transactionId={transactionId}
              documentId={documentId}
              linkedDocumentStatus={linkedDocumentStatus}
            />
            {documentId && (
              <DocumentHistoryTabsContainer
                key="tabs"
                defaultSelectedKey={protocolTabTitle}
                chatBox={<CommentContainer documentId={documentId} />}
                tabItems={[
                  {
                    title: protocolTabTitle,
                    children: (
                      <DocumentHistoryContainer documentId={documentId} />
                    ),
                  },
                  {
                    title: attachmentsTabTitle,
                    children: (
                      <Attachments
                        aria-labelledby="attachments"
                        documentId={documentId}
                        items={attachmentListItems}
                        selectedFile={selectedPdf}
                        documentFile={mainDocumentFile}
                        attachments={attachments}
                        canAttachFiles={attachPermissions}
                      />
                    ),
                  },
                ]}
              />
            )}
            {!documentId && (
              <DocumentHistoryTabsContainer
                key="tabs"
                defaultSelectedKey={protocolTabTitle}
                disabledTitles={[attachmentsTabTitle]}
                chatBox={
                  <CommentTransactionContainer
                    transactionId={transactionId}
                    ownerTxMembershipId={ownerTxMembershipId}
                  />
                }
                tabItems={[
                  {
                    title: protocolTabTitle,
                    children: (
                      <TransactionHistoryContainer
                        transactionId={transactionId}
                        isTransactionLoading={isSectionLoading}
                      />
                    ),
                  },
                  {
                    title: attachmentsTabTitle,
                    tabTooltip: t('transactionAssociation.tabs.disabled'),
                  },
                ]}
              />
            )}
          </LeftSectionContentWrapper>
        </LeftSectionOuterWrapper>
      }
      middleSection={
        isSectionLoading ? (
          <Box paddingTop="72px" paddingX="space24" height="100%">
            <PdfInvoiceSkeleton />
          </Box>
        ) : (
          <>
            {documentId ? (
              <Box
                height="100%"
                width="100%"
                paddingX="space16"
                paddingTop="space32"
                overflowY="auto">
                <DocumentViewer
                  documentId={documentId ?? ''}
                  attachments={attachments}
                  canAttachFiles={attachPermissions}
                  documentFile={mainDocumentFile}
                  selectedFile={selectedPdf}
                  onSelectDocument={setSelectedPdf}
                />
              </Box>
            ) : (
              <InvoiceLayout canUserLinkInvoice={canUserLinkInvoice}>
                {canUserLinkInvoice ? (
                  <InvoiceUploaderContainer
                    txId={transactionId}
                    onChange={handleUploaderChange}
                    cycleTransaction={cycleTransactions}
                    transactionAmount={transactionAmount}
                    isNoInvoiceNeededToggleActive={
                      isNoInvoiceNeededToggleActive
                    }
                    setDocumentsNoTxModalIsOpen={setDocumentsNoTxModalIsOpen}
                  />
                ) : (
                  <InvoiceUploadDisabledState />
                )}
              </InvoiceLayout>
            )}
          </>
        )
      }
      rightSection={
        isSectionLoading ? (
          <Loader />
        ) : documentId ? (
          renderDocumentForm(documentId)
        ) : showAllExtraFeatures ? (
          // We need to force the component to re-render when the transactionId changes
          <TransactionReadOnlySideBar
            key={transactionId}
            isInvoicedNotNeeded={
              transaction?.invoiceAssociationStatus ===
              InvoiceAssociationStatus.NotNeeded
            }
            isTxExported={isTransactionExported ?? false}>
            <ReadOnlyTransactionFormContainer
              isTransactionExported={isTransactionExported ?? false}
            />
          </TransactionReadOnlySideBar>
        ) : isNoInvoiceNeededToggleActive ? (
          <ReadOnlyFormFromDeprecated
            transaction={transaction}
            isTxExported={isTransactionExported}
          />
        ) : (
          <RightInformationContent
            isLoadingCurrentCardTransaction={isSectionLoading}
          />
        )
      }>
      <Modal
        closeLabel={t('transactionAssociation.documentsModal.close')}
        background="gray200"
        width="80vw"
        // @ts-ignore
        title={<TitleWithInfo />}
        minHeight="60vh"
        padding={0}
        overflow="hidden"
        shouldCloseOnBlur={true}
        onClose={closeModal}
        isOpen={documentsNoTxModalIsOpen}>
        <DocumentsWithoutTransaction
          transactionId={transactionId}
          closeModal={closeModal}
        />
      </Modal>
    </DetailsLayout>
  );
};
