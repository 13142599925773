import { QueryLazyOptions } from '@apollo/client';
import { Flex } from '@candisio/design-system';
import { HookFormChipsFieldItem } from 'components/HookFormFields/HookFormTagsField/HookFormChipsField';
import { useInsightsPreferences } from 'components/Insights/hooks/useInsightsPreferences';
import { validStatusesForAggregate } from 'components/Insights/Modals/utils';
import { WIDGET_TYPE } from 'components/Insights/Modals/WidgetModal/components/WidgetTypeSelection/TypeSelection/types';
import { extractInvoiceDateFromUrl } from 'components/Insights/Widgets/DateRangePicker/util';
import {
  DateFilterOption,
  DocumentFilterInput,
  FinancialInsightsQueryVariables,
  Maybe,
  User,
} from 'generated-types/graphql.types';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { WidgetFormData } from '../types';
import { WidgetContacts } from './WidgetContacts';
import { WidgetCostCenters } from './WidgetCostCenters';
import { WidgetGeneralLedgerAccount } from './WidgetGeneralLegerAccount';
import { WidgetTitle } from './WidgetTitle';

type WidgetDetailsProps = {
  changeTitle: (options: HookFormChipsFieldItem[]) => void;
  resetModalValues: ({ isCloseModal }: { isCloseModal: boolean }) => void;
  currentUser?: Maybe<User>;
  selectedType?: WIDGET_TYPE;
  calculateWidgetSum: (
    options?: QueryLazyOptions<FinancialInsightsQueryVariables> | undefined
  ) => void;
  initialValues: WidgetFormData;
};

export const WidgetDetails = ({
  changeTitle,
  currentUser,
  calculateWidgetSum,
  resetModalValues,
  selectedType,
  initialValues,
}: WidgetDetailsProps) => {
  const organization = useFullOrganization();
  const { preferences } = useInsightsPreferences();

  const handleGetWidgetSumForFilters = (filters: DocumentFilterInput) => {
    const hasNoFilters =
      !filters.costCenterIds?.length &&
      !filters.contactIds?.length &&
      !filters.costObjectIds?.length &&
      !filters.generalLedgerAccountIds?.length;

    if (hasNoFilters) {
      resetModalValues({ isCloseModal: false });

      return;
    }

    const { values: invoiceDateValues } = extractInvoiceDateFromUrl({
      search: window.location.search,
      userId: currentUser?.id,
      forceDefault: false,
    });

    const { dateFrom, dateTo } = invoiceDateValues;

    calculateWidgetSum({
      variables: {
        filters: {
          ...filters,
          status: validStatusesForAggregate,
        },
        dateRangeFilters: {
          invoiceDate: {
            dateFrom,
            dateTo,
            filterOption: DateFilterOption.Custom,
          },
        },
        input: {
          preferences: {
            unit: preferences.unit,
          },
        },
      },
    });
  };

  return (
    <Flex direction="column" gap="space12">
      {selectedType === WIDGET_TYPE.COST_CENTER && (
        <Flex direction="column" gap="space12">
          {(organization?.hasCostCenters || organization?.hasCostObjects) && (
            <WidgetCostCenters
              changeTitle={changeTitle}
              handleGetWidgetSumForFilters={handleGetWidgetSumForFilters}
              hasCostCenters={!!organization?.hasCostCenters}
              hasCostObjects={!!organization?.hasCostObjects}
              initialValues={{
                costCenters: initialValues.costCenters,
                costObjects: initialValues.costObjects,
              }}
            />
          )}
        </Flex>
      )}
      {selectedType === WIDGET_TYPE.GENERAL_LEDGER_ACCOUNT && (
        <WidgetGeneralLedgerAccount
          changeTitle={changeTitle}
          handleGetWidgetSumForFilters={handleGetWidgetSumForFilters}
          initialGeneralLedgerAccounts={initialValues.generalLedgerAccounts}
        />
      )}
      {selectedType === WIDGET_TYPE.CONTACT && (
        <WidgetContacts
          changeTitle={changeTitle}
          handleGetWidgetSumForFilters={handleGetWidgetSumForFilters}
          initialContacts={initialValues.contacts}
        />
      )}
      <WidgetTitle />
    </Flex>
  );
};
