import {
  ReimbursementCaseSortField,
  ReimbursementCaseSortInput,
  SortDirection,
} from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from '../toolkit/types';

type SortableColumns = Extract<
  ReimbursementTableColumnKeys,
  'status' | 'createdAt'
>;

const mappedColumnAccessorsToSortInput: Record<
  SortableColumns,
  ReimbursementCaseSortField
> = {
  status: ReimbursementCaseSortField.Status,
  createdAt: ReimbursementCaseSortField.CreatedAt,
};

export const mapToReimbursementSortInput = (
  sortBy: SortingRule<ReimbursementTableRow>[]
): ReimbursementCaseSortInput | undefined => {
  if (sortBy.length < 1) {
    return undefined;
  }

  const [firstSort] = sortBy;

  const { id, desc } = firstSort;

  return {
    field: mappedColumnAccessorsToSortInput[id as SortableColumns],
    direction: desc ? SortDirection.Desc : SortDirection.Asc,
  };
};
