import { getFourteenDaysAfter } from '../utils/getFourteenDaysAfter';

interface UseHaveFourteenDaysPassedParams {
  shippingDate: string | undefined;
}

export const useIsFourteenDaysPassed = ({
  shippingDate,
}: UseHaveFourteenDaysPassedParams) => {
  if (!shippingDate) {
    return { isFourteenDaysPassed: false };
  }

  const todayDate = new Date();

  const shippingDatePhysicalCard = new Date(shippingDate);

  const fourteenDaysFromShippingDate = getFourteenDaysAfter(
    shippingDatePhysicalCard
  );

  return {
    isFourteenDaysPassed: todayDate >= fourteenDaysFromShippingDate,
  };
};
