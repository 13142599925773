import { useModal } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { DownloadMenuButton } from './DownloadMenuButton';
import { DownloadPdfModal } from './DownloadPdfModal';
import { useDownloadPDFFilesEcm } from './hooks/useDownloadPDFFilesEcm';

interface DownloadMenuButtonEcmDocumentsContainerProps {
  selectedDocumentsCount: number;
  isTableLoading: boolean;
  isTableFiltered: boolean;
}

export const DownloadMenuButtonEcmDocumentsContainer = ({
  isTableLoading,
  selectedDocumentsCount,
  isTableFiltered,
}: DownloadMenuButtonEcmDocumentsContainerProps) => {
  const [searchParams] = useSearchParams();
  const { triggerProps, modalProps, close, open } = useModal();

  const { onPrepareDownloadPdfs, createPdfZipFileLoading } =
    useDownloadPDFFilesEcm({
      isTableLoading,
      handleClosePdfDownloadModal: close,
    });

  const isMenuButtonDisabled =
    isTableLoading || !selectedDocumentsCount || createPdfZipFileLoading;

  const hasSearchQuery = searchParams.has(queryParameter);

  return (
    <>
      <DownloadMenuButton
        {...triggerProps}
        isDisabled={isMenuButtonDisabled}
        isLoading={isTableLoading}
        isTableFiltered={isTableFiltered}
        isTableSearched={hasSearchQuery}
        selectedDocumentsCount={selectedDocumentsCount}
        onClick={open}
      />
      <DownloadPdfModal
        {...modalProps}
        isTableLoading={isTableLoading}
        selectedDocumentsCount={selectedDocumentsCount}
        createPdfZipFileLoading={createPdfZipFileLoading}
        onPrepareDownload={onPrepareDownloadPdfs}
      />
    </>
  );
};
