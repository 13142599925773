import { Button, Grid, Separator } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { memo } from 'react';
import {
  FieldArray,
  FormProvider as HookFormProvider,
  UseFieldArrayRemove,
  UseFormReturn,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { ExpenseFormWrapper } from './ExpenseFormWrapper';
import { ExpenseInvoiceInputField } from './ExpenseInvoiceInputField';
import { ExpenseInvoicePreviewContainer } from './ExpenseInvoicePreviewContainer';
import { generateFieldNames } from './utils/generateHospitalityFormFieldNames';
import { useExpensesFormActions } from './utils/useExpensesFormActions';

interface HospitalityExpenseFormProps {
  createExpenseInvoice: (file: File) => Promise<ExpenseInvoice | undefined>;
  onRemoveForm: UseFieldArrayRemove;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  forms: FieldArray<ExpensesFormOutput, 'hospitalityExpenses'>[];
}

export const HospitalityExpenseForm = memo(
  ({
    createExpenseInvoice,
    onRemoveForm,
    forms,
    formMethods,
  }: HospitalityExpenseFormProps) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
    const { watch } = formMethods;
    const hospitalityExpenses = watch('hospitalityExpenses');

    const {
      clearableField,
      handleClearableField,
      isCreatingInvoice,
      isDeletingForm,
      handleUpdateHospitality,
      handleDeleteForm,
      handleInvoiceChange,
      handleReplaceInvoice,
    } = useExpensesFormActions({
      createExpenseInvoice,
      onRemoveForm,
      formMethods,
      expenseType: 'hospitality',
    });

    return (
      <HookFormProvider {...formMethods}>
        <Grid gap="space24">
          {(forms ?? []).map((form, index) => {
            const fields = generateFieldNames(index);
            const invoice = hospitalityExpenses[index].files[0];
            const showInvoicePreview = isCreatingInvoice[index] || !!invoice;

            return (
              <ExpenseFormWrapper
                key={form.expenseId}
                expenseId={form.expenseId}
                totalAmount={form.totalAmount}
                expenseType="hospitality"
                reason={form.reason}>
                <Grid as="form" gap="space24">
                  <Grid columns={2} gap="space8">
                    <HookFormTextField
                      name={fields.reason}
                      label={t(
                        'reimbursementView.middleSection.form.hospitality.reason.label'
                      )}
                      placeholder={t(
                        'reimbursementView.middleSection.form.hospitality.reason.placeholder'
                      )}
                      clearable={clearableField === fields.reason}
                      onFocus={handleClearableField(fields.reason)}
                      inputProps={{
                        onBlur: handleUpdateHospitality(index),
                      }}
                    />
                    <HookFormTextField
                      name={fields.location}
                      label={t(
                        'reimbursementView.middleSection.form.hospitality.location.label'
                      )}
                      placeholder={t(
                        'reimbursementView.middleSection.form.hospitality.location.placeholder'
                      )}
                      clearable={clearableField === fields.location}
                      onFocus={handleClearableField(fields.location)}
                      inputProps={{
                        onBlur: handleUpdateHospitality(index),
                      }}
                    />
                  </Grid>
                  <Grid columns={4} gap="space8">
                    <HookFormDatePickerField
                      name={fields.dayOfExpense}
                      label={t(
                        'reimbursementView.middleSection.form.hospitality.date.label'
                      )}
                      clearable={clearableField === fields.dayOfExpense}
                      onFocus={handleClearableField(fields.dayOfExpense)}
                      onBlur={handleUpdateHospitality(index)}
                    />
                    <HookFormAmountField
                      name={fields.receiptAmount}
                      label={t(
                        'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
                      )}
                      placeholder={t(
                        'reimbursementView.middleSection.form.hospitality.receiptAmount.placeholder'
                      )}
                      currency="EUR"
                      minValue={0}
                      onBlur={handleUpdateHospitality(index)}
                    />
                    <HookFormAmountField
                      name={fields.tipAmount}
                      label={t(
                        'reimbursementView.middleSection.form.hospitality.tipAmount.label'
                      )}
                      placeholder={t(
                        'reimbursementView.middleSection.form.hospitality.tipAmount.placeholder'
                      )}
                      currency="EUR"
                      minValue={0}
                      onBlur={handleUpdateHospitality(index)}
                    />
                    <HookFormAmountField
                      name={fields.totalAmount}
                      label={t(
                        'reimbursementView.middleSection.form.hospitality.totalAmount.label'
                      )}
                      placeholder={t(
                        'reimbursementView.middleSection.form.hospitality.totalAmount.placeholder'
                      )}
                      currency="EUR"
                      minValue={0}
                      onBlur={handleUpdateHospitality(index)}
                    />
                  </Grid>
                  <Grid columns={2} gap="space8">
                    <HookFormTextField
                      name={fields.internalGuests}
                      label={t(
                        'reimbursementView.middleSection.form.hospitality.internalGuests.label'
                      )}
                      placeholder={t(
                        'reimbursementView.middleSection.form.hospitality.internalGuests.placeholder'
                      )}
                      infoTooltip={{
                        message: t(
                          'reimbursementView.middleSection.form.hospitality.internalGuests.tooltip'
                        ),
                      }}
                      clearable={clearableField === fields.internalGuests}
                      onFocus={handleClearableField(fields.internalGuests)}
                      inputProps={{
                        onBlur: handleUpdateHospitality(index),
                      }}
                    />
                    <HookFormTextField
                      name={fields.externalGuests}
                      label={t(
                        'reimbursementView.middleSection.form.hospitality.externalGuests.label'
                      )}
                      placeholder={t(
                        'reimbursementView.middleSection.form.hospitality.externalGuests.placeholder'
                      )}
                      clearable={clearableField === fields.externalGuests}
                      onFocus={handleClearableField(fields.externalGuests)}
                      inputProps={{
                        onBlur: handleUpdateHospitality(index),
                      }}
                    />
                  </Grid>

                  {showInvoicePreview && (
                    <ExpenseInvoicePreviewContainer
                      invoice={invoice}
                      createInvoicePending={isCreatingInvoice[index]}
                      onReplaceInvoice={handleReplaceInvoice(index)}
                    />
                  )}
                  {!showInvoicePreview && (
                    <ExpenseInvoiceInputField
                      name={fields.files}
                      id={`invoice-input-field-${index}`}
                      onChange={file => handleInvoiceChange(file, index)}
                      onIvoiceUpdate={handleUpdateHospitality(index)}
                    />
                  )}
                  <Separator />
                  <Button
                    size="small"
                    variant="tertiary"
                    justifySelf="end"
                    color="error"
                    disabled={isDeletingForm[index]}
                    loading={isDeletingForm[index]}
                    onClick={handleDeleteForm(index, form.expenseId)}>
                    {t(
                      'reimbursementView.middleSection.form.hospitality.deleteCta'
                    )}
                  </Button>
                </Grid>
              </ExpenseFormWrapper>
            );
          })}
        </Grid>
      </HookFormProvider>
    );
  }
);
