import { Text } from '@candisio/design-system';
import {
  PurchaseOrderLinkedEvent,
  PurchaseOrderUnlinkedEvent,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

type PurchaseOrderEventComponentProps =
  | Pick<PurchaseOrderLinkedEvent, '__typename' | 'orderNumber'>
  | Pick<PurchaseOrderUnlinkedEvent, '__typename' | 'orderNumber'>;

export default function PurchaseOrderEventComponent({
  __typename,
  orderNumber,
}: PurchaseOrderEventComponentProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  if (__typename === 'PurchaseOrderLinkedEvent') {
    return <Text>{t('documentHistoryEvents.linked', { orderNumber })}</Text>;
  }

  if (__typename === 'PurchaseOrderUnlinkedEvent') {
    return <Text>{t('documentHistoryEvents.unlinked', { orderNumber })}</Text>;
  }

  return null;
}
