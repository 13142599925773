import {
  Button,
  Flex,
  Tabs,
  TabPanel,
  useTheme,
  useTabListState,
  Item,
} from '@candisio/design-system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../providers/LocaleProvider';
import { UpdateCreditCardDataOptions } from '../CreditCardSettings/CreditCardFormDataPreview/UpdateCreditCardDataFormDrawer/UpdateCreditCardDataOptions';
import { SettingsTab, TransactionsTab } from './Tabs';
import { CreditCardDrawerProps, DrawerLayoutProps } from './types';

export type DrawerMode =
  | 'cardLimits'
  | 'cardControls'
  | 'accountingSettings'
  | 'replaceCard'
  | 'cardDetails';

export const DrawerLayout = ({ children, onClose }: DrawerLayoutProps) => {
  const { colors } = useTheme();
  const state = useTabListState({ children });
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Flex direction="column" height="100%">
      <Flex
        borderBottom={`1px solid ${colors.gray300}`}
        alignItems="center"
        justifyContent="space-between"
        padding="space16"
        paddingBottom={0}>
        <Tabs aria-label="Drawer Tab Contents" state={state}>
          {children}
        </Tabs>

        <Button
          icon="close"
          label={t('drawer.close')}
          onClick={onClose}
          variant="tertiary"
        />
      </Flex>

      <TabPanel
        key={state.selectedItem?.key}
        state={state}
        flexGrow={1}
        overflow="auto"
      />
    </Flex>
  );
};

export const CreditCardDrawer = ({
  cardId,
  onClose,
  onCardTermination,
}: CreditCardDrawerProps) => {
  const [tCreditCards] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const [mode, setMode] = useState<DrawerMode>('cardDetails');

  const tabArgs = {
    cardId,
    onClose: onCardTermination ?? onClose,
    onSetMode: setMode,
  };

  if (mode === 'cardDetails') {
    return (
      <DrawerLayout onClose={onClose}>
        <Item
          key="Tab"
          textValue={tCreditCards('drawer.tabs.cardDetails')}
          title={tCreditCards('drawer.tabs.cardDetails')}>
          <SettingsTab {...tabArgs} />
        </Item>
        <Item
          key="transactions"
          textValue={tCreditCards('drawer.tabs.transactions')}
          title={tCreditCards('drawer.tabs.transactions')}>
          <TransactionsTab {...tabArgs} />
        </Item>
      </DrawerLayout>
    );
  }

  return (
    <UpdateCreditCardDataOptions
      cardId={cardId}
      mode={mode}
      onSetMode={setMode}
    />
  );
};
