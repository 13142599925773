import { useGetNextFreeAccountsPayableNumberLazyQuery } from 'generated-types/graphql.types';
import { useCallback, useEffect } from 'react';
import { useApnSuggestionSettings } from 'views/Settings/SuggestionsAndExtractions/components/ContactSettings/useApnSuggestionSettings';
import { AccountsPayableNumberActions } from './AccountsPayableNumberActions';

export interface AccountsPayableNumberActionsContainerProps {
  /** APN that is currently in the input */
  currentNumber?: string;
  /** Set APN to suggested one */
  setCurrentNumber?: (input: string) => void;
  /** Whether to already generate a number right after rendering */
  findNextNumberOnRender?: boolean;
  /** Whether to show the suggestion or not */
  showSuggestion?: boolean;
  disabled?: boolean;
}

/** Displays actions for suggested accounts payable number  */
export const AccountsPayableNumberActionsContainer = ({
  currentNumber,
  setCurrentNumber,
  findNextNumberOnRender,
  disabled,
  showSuggestion,
}: AccountsPayableNumberActionsContainerProps) => {
  const { enabled } = useApnSuggestionSettings();

  const [getNextFreeAccountsPayableNumber, { loading, called }] =
    useGetNextFreeAccountsPayableNumberLazyQuery({
      variables: {
        input: currentNumber?.replaceAll(' ', ''),
      },
      fetchPolicy: 'network-only',
    });

  const handleFindNext = useCallback(async () => {
    const { data: refetchData } = await getNextFreeAccountsPayableNumber({
      variables: {
        input: currentNumber?.replaceAll(' ', ''),
      },
    });

    if (refetchData?.getNextFreeAccountsPayableNumber) {
      setCurrentNumber?.(refetchData.getNextFreeAccountsPayableNumber);
    }
  }, [currentNumber, getNextFreeAccountsPayableNumber, setCurrentNumber]);

  useEffect(() => {
    if (findNextNumberOnRender && !called && enabled) void handleFindNext();
  }, [findNextNumberOnRender, handleFindNext, called, enabled]);

  return (
    <>
      {showSuggestion && enabled && (
        <AccountsPayableNumberActions
          onFindNext={handleFindNext}
          disabled={disabled || loading}
        />
      )}
    </>
  );
};
