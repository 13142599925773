import { Grid, LottieAnimation, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const EmptyState = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid justifyItems="center">
      <LottieAnimation animationData="noresultsData" />
      <Text>{t('statements.list.emptyState')}</Text>
    </Grid>
  );
};
