import { roundNumber } from 'utils/roundNumber';

export const getDiscountPercentage = (
  grossAmount?: number,
  discountAmount?: number
) => {
  if (typeof grossAmount !== 'number' || typeof discountAmount !== 'number') {
    return;
  }

  return roundNumber((discountAmount / grossAmount) * 100, 2);
};
