import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { createContext, useContext } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';

export type BookingsFormContextValue = UseFieldArrayReturn<
  DocumentApprovalFormValues,
  'bookings'
> | null;

export const BookingsFieldArrayContext =
  createContext<BookingsFormContextValue>(null);

export const useBookingsFieldArrayContext = () => {
  return useContext(BookingsFieldArrayContext);
};
