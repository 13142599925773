import { Button, Flex, Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InformationCard } from '../InformationCard';
import { LoadingState } from '../LoadingState';
import { OtpNumberField } from '../OtpNumberField';

type ActivateProps = {
  isLoading: boolean;
  isActivatingCard?: boolean;
  hasError?: boolean;
  isCandisCardBrand: boolean;
  shippingDate: string | undefined;
  setHasError?: Dispatch<SetStateAction<boolean>>;
  onActivate: (val: string) => Promise<void>;
};

export const ActivateForm = ({
  isLoading,
  isActivatingCard,
  hasError,
  isCandisCardBrand,
  shippingDate,
  setHasError,
  onActivate,
}: ActivateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const [otp, setOtp] = useState<string>('');

  const otpNumFieldCount: number = isCandisCardBrand ? 4 : 6;

  const handleChange = (val: string) => {
    setOtp(val);

    if (hasError && val.length < otpNumFieldCount) {
      setHasError?.(false);
    }
  };

  const displayText = isCandisCardBrand
    ? 'carousel.activate.helpTextForCandisCard'
    : 'carousel.activate.helpTextForPliantCard';

  return (
    <Grid gap="space40" height="100%">
      <InformationCard shippingDate={shippingDate} />
      <Grid
        height="100%"
        alignSelf="end"
        justifySelf="end"
        templateRows="auto auto">
        <Flex direction="column" alignSelf="end" gap="space4">
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              <Text color="gray600">{t(displayText)}</Text>
              <OtpNumberField
                value={otp}
                hasError={hasError}
                isCandisCardBrand={isCandisCardBrand}
                onChange={handleChange}
              />
            </>
          )}
        </Flex>
        <Button
          onClick={() =>
            otp.length === otpNumFieldCount ? onActivate(otp) : undefined
          }
          icon="arrowRight"
          size="small"
          iconPosition="right"
          alignSelf="end"
          justifySelf="end"
          loading={isActivatingCard}
          style={{ marginTop: '10px' }}>
          {t('carousel.activate.cta')}
        </Button>
      </Grid>
    </Grid>
  );
};
