import { Button, Flex, Grid, Text } from '@candisio/design-system';
import { ConfirmationPopoverDeprecated } from 'components/ConfirmationPopover/ConfirmationPopoverDeprecated';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentLayout } from './styles';

type Props = {
  onArchive?: () => void;
  onRestore?: (args: any) => any;
  archived?: boolean | null;
  actions?: ReactElement;
  disable?: boolean | null;
  loading?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  customPadding?: string;
  children: ReactNode;
};

type ArchiveButtonProps = {
  updateStatusLoading: boolean;
  onStatusChange: (isActive: boolean) => any;
};

export const ArchiveButton = ({
  onStatusChange,
  updateStatusLoading,
}: ArchiveButtonProps) => {
  const [t] = useTranslation();

  return (
    <>
      <ConfirmationPopoverDeprecated
        title={t('areYouSure')}
        onConfirm={() => onStatusChange(true)}
        closeText={t('no')}
        confirmText={t('yes')}
        placement="top right"
        trigger={({ triggerProps, triggerRef }) => (
          <Button
            color="red"
            loading={updateStatusLoading}
            disabled={updateStatusLoading}
            variant="tertiary"
            data-cy="archive-button"
            {...triggerProps}
            ref={triggerRef}>
            {t('archive.wrapper.actions.archive')}
          </Button>
        )}
      />
    </>
  );
};

interface ReActivateRowProps {
  disabled?: boolean;
  onRestore?: (args: any) => any;
  isSubmitting?: boolean;
  customPadding?: string;
}

export const ReActivateRow = ({
  disabled,
  onRestore,
  isSubmitting,
}: ReActivateRowProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      gap="space4"
      borderRadius="medium"
      padding="space16"
      background="bluebg">
      <Text fontSize="basic" fontWeight="semibold">
        {t('archive.wrapper.title')}
      </Text>
      <Text fontSize="small">{t('archive.wrapper.description')}</Text>

      {Boolean(onRestore) && (
        <Grid justifyContent="end" paddingTop="space16">
          <Button
            disabled={disabled}
            loading={isSubmitting}
            onClick={onRestore}
            color="blue"
            data-cy="activate-button">
            {t('archive.wrapper.actions.restore')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export const ArchiveWrapper = ({
  children,
  archived,
  actions,
  onRestore,
  isSubmitting,
  onArchive,
  disable,
}: Props) => {
  const [t] = useTranslation();

  return (
    <Flex direction="column" height="100%" width="100%">
      {archived && !disable && (
        <Grid padding="space40">
          <ReActivateRow onRestore={onRestore} isSubmitting={isSubmitting} />
        </Grid>
      )}
      <ContentLayout id="archive-layout">{children}</ContentLayout>
      <Flex
        position="absolute"
        bottom="0"
        width="100%"
        padding="space40 space40 0"
        justifyContent="space-between"
        background={`linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 51%,
          rgba(255, 255, 255, 1) 100%
        )`}>
        {actions}
        {!archived && !!onArchive && !disable && (
          <ConfirmationPopoverDeprecated
            title={t('areYouSure')}
            onConfirm={onArchive}
            closeText={t('no')}
            confirmText={t('yes')}
            placement="top right"
            trigger={({ triggerProps, triggerRef }) => (
              <Button
                variant="tertiary"
                color="red"
                loading={isSubmitting}
                {...triggerProps}
                ref={triggerRef}>
                {t('archive.wrapper.actions.archive')}
              </Button>
            )}></ConfirmationPopoverDeprecated>
        )}
      </Flex>
    </Flex>
  );
};
