import { usePaymentConditionsQuery } from 'generated-types/graphql.types';
import { PaymentConditionsListData } from '../types';

export interface UsePaymentConditionsDataOptions {
  status: 'active' | 'archived';
}

export interface UsePaymentConditionsDataReturn {
  data?: PaymentConditionsListData[];
  loading: boolean;
}

/** Fetches payment conditions and maps to PaymentConditionsTableData */
export const usePaymentConditionsListData = ({
  status = 'active',
}: UsePaymentConditionsDataOptions): UsePaymentConditionsDataReturn => {
  const { data, loading } = usePaymentConditionsQuery({
    variables: { filter: { isArchived: status === 'archived' } },
  });

  return {
    data: data?.paymentConditions
      ? data.paymentConditions.map(
          (paymentCondition): PaymentConditionsListData => ({
            id: paymentCondition.id,
            conditionNumber: paymentCondition.paymentConditionNumber,
            conditionName: paymentCondition.paymentConditionName ?? undefined,
            condition: {
              discountPercentage:
                paymentCondition.discountPercentage ?? undefined,
              discountOffset: paymentCondition.discountOffset ?? undefined,
              dueDateOffset: paymentCondition.dueDateOffset,
            },
            updatedAt: paymentCondition.updatedAt
              ? new Date(paymentCondition.updatedAt)
              : undefined,
            updatedBy: paymentCondition.updatedBy
              ? {
                  name: paymentCondition.updatedBy.name,
                  imageUrl: paymentCondition.updatedBy.avatarUrl ?? undefined,
                }
              : undefined,
          })
        )
      : undefined,
    loading,
  };
};
