import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { TEAM_STATUS_URL_PARAM } from '../../Teams';
import { useGetTeams } from '../../toolkit/hooks/useGetTeams';
import { TeamsList } from './TeamsList';

export const NAME_SEARCH_PARAM = 'name';

export const TeamsListContainer = () => {
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const searchQuery = searchParams.get(NAME_SEARCH_PARAM) ?? '';
  const teamStatus = searchParams.get(TEAM_STATUS_URL_PARAM);
  const isArchived = teamStatus === 'archived' ? true : false;

  const {
    handleDebounceSearch,
    loadMore,
    loading,
    teams,
    sort,
    handleSetSort,
  } = useGetTeams({ isArchived });

  const handleSearch = (search: string) => {
    handleDebounceSearch(search);
    updateSearchParam(NAME_SEARCH_PARAM, search);
  };

  const handleResetSearch = () => {
    handleDebounceSearch('');
    updateSearchParam(NAME_SEARCH_PARAM, '');
  };

  return (
    <TeamsList
      teams={teams}
      search={searchQuery}
      isLoading={loading}
      sort={sort}
      onSort={handleSetSort}
      onSearch={handleSearch}
      onLoadMore={loadMore}
      onResetSearch={handleResetSearch}
    />
  );
};
