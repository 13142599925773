import { isEqual } from 'lodash';
import { useState } from 'react';

/**
 * Memoizes a value using React state. The passed `value` is cached and only
 * updated when new value does not deep equal the cached value according to
 * Lodash `isEqual`.
 *
 * **Warning: passing a complex value (e.g. an object including functions) to
 * this hook could lead to an infinite loop!**
 *
 * Useful for memoizing *incoming* props so that we don’t rely on them being
 * `useMemo`’d before being passed in.
 */
export const useMemoizedState = <TState extends unknown>(
  value: TState
): TState => {
  const [cachedValue, setCachedValue] = useState(value);
  if (!isEqual(cachedValue, value)) {
    // Setting state during render means that React will immediately throw away
    // the result after render and start again. This isn’t perfect but it’s
    // better than committing the render result to the DOM before starting
    // again, as would happen if we were to set state in an effect.
    // See: https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
    setCachedValue(value);
  }

  return cachedValue;
};
