import { AttachmentConvertedToDocumentEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const AttachmentConvertedToDocumentComponent = ({
  source,
}: AttachmentConvertedToDocumentEventDataFragment) => {
  const [t] = useTranslation();

  const translationKey = source?.fileName
    ? 'document.compact.history.documentAttachment.history.attachmentConvertedToDocument'
    : 'document.compact.history.documentAttachment.history.attachmentConvertedToDocument_fallback';

  return (
    <div>
      {t(translationKey, {
        fileName: source?.fileName,
      })}
    </div>
  );
};
