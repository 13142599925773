import {
  CardIssuerCardholderSortField,
  SortDirection,
  useGetMostActiveCardholdersQuery,
} from 'generated-types/graphql.types';

export interface MostActiveCardholder {
  id: string;
  fullName: string;
  avatarUrl: string | undefined;
  memberships: Array<{
    membershipId: string | undefined;
    candisOrganizationId: string | undefined;
  }>;
}

export const useMostActiveCardholders = () => {
  const { data, loading } = useGetMostActiveCardholdersQuery({
    variables: {
      input: { limit: 5 },
      filters: { hasCards: true, hasCandisMembership: true },
      sortBy: {
        direction: SortDirection.Desc,
        field: CardIssuerCardholderSortField.CardCreatedAt,
      },
    },
  });

  const mostActiveCardholders: MostActiveCardholder[] = (
    data?.getMostActiveCardholders.edges ?? []
  ).map(cardholder => ({
    id: cardholder.node.id,
    fullName: `${cardholder.node.firstName} ${cardholder.node.lastName}`,
    avatarUrl: cardholder.node.avatarUrl ?? undefined,
    memberships: (cardholder.node.memberships ?? []).map(membership => ({
      membershipId: membership?.membershipId ?? undefined,
      candisOrganizationId: membership?.candisOrganizationId,
    })),
  }));

  return { mostActiveCardholders, isLoading: loading };
};
