import { Notification } from 'generated-types/graphql.types';
import { NotificationsContainer as NotificationsListContainer } from '../styles';
import { EmailImportErrorNotificationItem } from './EmailImportErrorNotificationItem';
import { UploadFileNotificationItem } from './UploadFileNotificationItem';

type Props = {
  notifications: Notification[];
  onClose: () => void;
  organizationSlug: string;
};

export const NotificationsList = ({
  notifications,
  onClose,
  organizationSlug,
}: Props) => {
  return (
    <NotificationsListContainer>
      {notifications.map(notification => {
        switch (notification.data.__typename) {
          case 'UploadFileItem':
            return (
              <UploadFileNotificationItem
                key={notification.data.id}
                data={notification.data}
                organizationSlug={organizationSlug}
                onClose={onClose}
              />
            );
          case 'EmailImportErrorNotification':
            return (
              <EmailImportErrorNotificationItem
                key={notification.id}
                organizationSlug={organizationSlug}
                data={notification.data}
                onEmailLinkClick={onClose}
              />
            );
          default:
            return null;
        }
      })}
    </NotificationsListContainer>
  );
};
