import { Box } from '@candisio/design-system';
import { CardStatus, CardType } from 'generated-types/graphql.types';
import { ReactNode } from 'react';
import { UpdateSingleUseCardLimitFormContainer } from './UpdateSingleUseCardLimitFormContainer/UpdateSingleUseCardLimitFormContainer';
import { UpdateVirtualAndPhysicalCardLimitFormContainer } from './UpdateVirtualAndPhysicalCardLimitFormContainer/UpdateVirtualAndPhysicalCardLimitFormContainer';

interface UpdateCardLabelAndLimitProps {
  cardId: string;
  cardType: CardType;
  cardStatus: CardStatus;
  onClose: () => void;
}

export const UpdateCardLabelAndLimit = ({
  cardId,
  cardType,
  cardStatus,
  onClose,
}: UpdateCardLabelAndLimitProps) => {
  const props = {
    cardId,
    cardStatus,
    onClose,
  };

  const updateCardLimitForm: Record<CardType, ReactNode> = {
    BLACK: <UpdateVirtualAndPhysicalCardLimitFormContainer {...props} />,
    PHYSICAL: <UpdateVirtualAndPhysicalCardLimitFormContainer {...props} />,
    VIRTUAL: <UpdateVirtualAndPhysicalCardLimitFormContainer {...props} />,
    SINGLE_USE: <UpdateSingleUseCardLimitFormContainer {...props} />,
  };

  return <Box>{updateCardLimitForm[cardType ?? CardType.Virtual]}</Box>;
};
