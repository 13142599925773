import { Nullable } from 'utils/extractors';
import { ErrorMessages, vatIdSchema } from 'utils/zodFormValidation';
import { z } from 'zod';

export const vatIdNumberSchema = z.object({
  vatId: vatIdSchema.nullish(),
});

export const vatIdErrorMessages: ErrorMessages<typeof vatIdNumberSchema> = {
  vatId: { label: 'organizations.contactInformation.vatId.label' },
};

export type VatIdFormDataOutput = z.infer<typeof vatIdNumberSchema>;
export type VatIdFormData = Nullable<VatIdFormDataOutput>;
