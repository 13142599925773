import { Flex, mergeProps } from '@candisio/design-system';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { Filename } from 'components/Filename/Filename';
import {
  ElementRef,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { AriaMenuItemProps, useFocus, useHover, useOption } from 'react-aria';
import { ListState, SingleSelectListState } from 'react-stately';
import { DeleteActionButton } from './DeleteActionButton';
import { DetachActionButton } from './DetachActionButton';

export interface Attachment {
  disabled?: boolean;
  label: string;
  id: string;
  onAction?: AriaMenuItemProps['onAction'];
  items?: Attachment[];
  isRemovable?: boolean;
  isDetachable?: boolean;
  originalDocumentId?: string | null;
}

export interface AttachmentItemProps {
  item: SingleSelectListState<Attachment>['selectedItem'];
  onAction: AriaMenuItemProps['onAction'];
  state: ListState<Attachment>;
  isRemovable?: boolean;
  isDetachable?: boolean;
  originalDocumentId?: string | null;
  selectedFile: PDFDetails;
}

export const attachmentItemTransition =
  'all 0.25s cubic-bezier(0.87, 0, 0.13, 1) 0.2s';

type AttachmentItemWrapperProps = {
  item: SingleSelectListState<Attachment>['selectedItem'];
  state: ListState<Attachment>;
  isSelected: boolean;
  children: (props: { isFocused: boolean }) => ReactNode;
};

const AttachmentItemWrapper = ({
  item,
  state,
  isSelected,
  children,
}: AttachmentItemWrapperProps) => {
  const ref = useRef<ElementRef<'li'>>(null);
  const { optionProps } = useOption(
    {
      isDisabled: item?.value?.disabled,
      isSelected,
      key: item?.key,
      shouldFocusOnHover: true,
    },
    state,
    ref
  );

  const [isFocused, setFocused] = useState(false);

  const { hoverProps, isHovered } = useHover({
    onHoverChange: setFocused,
  });

  const { focusProps } = useFocus({
    onFocusChange: setFocused,
  });

  useLayoutEffect(() => {
    const handleMouseMovement = () => {
      if (!isHovered) {
        setFocused(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMovement);

    return () => {
      document.removeEventListener('mousemove', handleMouseMovement);
    };
  }, [isHovered]);

  let background = 'transparent';
  if (isSelected) background = 'gray0';
  if (isFocused) background = 'rgba(255, 255, 255, 0.6)';

  return (
    <Flex
      as="li"
      {...mergeProps(optionProps, focusProps, hoverProps)}
      ref={ref}
      gap="space8"
      justifyContent="space-between"
      alignItems="center"
      paddingY="space8"
      paddingX="space16"
      width="100%"
      background={background}
      color={isFocused && !isSelected ? 'blue500' : 'gray700'}
      borderRadius="medium"
      style={{
        outline: 'none',
        cursor: 'pointer',
        transition: attachmentItemTransition,
      }}>
      {children({ isFocused })}
    </Flex>
  );
};

export const AttachmentItem = ({
  item,
  state,
  isRemovable,
  selectedFile,
  isDetachable,
}: AttachmentItemProps) => {
  const isSelected = item.key === selectedFile.id;

  return (
    <AttachmentItemWrapper isSelected={isSelected} item={item} state={state}>
      {({ isFocused }) => (
        <>
          <Filename
            fontSize="small"
            lineHeight="body"
            style={{ transition: attachmentItemTransition }}>
            {item.textValue}
          </Filename>
          <Flex gap="space8">
            {isDetachable && item?.value && (
              <DetachActionButton
                attachment={item.value}
                isFocused={isFocused}
                isSelected={isSelected}
              />
            )}

            {item?.value && (
              <DeleteActionButton
                attachment={item.value}
                isRemovable={isRemovable}
                isFocused={isFocused}
                isSelected={isSelected}
              />
            )}
          </Flex>
        </>
      )}
    </AttachmentItemWrapper>
  );
};
