import { CardType } from 'generated-types/graphql.types';
import { ModifiedLimitDetails } from '../../types';
import { useApprovePhysicalCardRequest } from './useApprovePhysicalCardRequest';
import { useApproveSingleUseCardRequest } from './useApproveSingleUseCardRequest';
import { useApproveVirtualCardRequest } from './useApproveVirtualCardRequest';

interface UseApproveCardRequestProps {
  cardId: string;
}

type Action = {
  approve?: (
    cardRequestId: string,
    updatedCardDetails?: ModifiedLimitDetails
  ) => Promise<{ status: string }>;
  approving: boolean;
};

export const useApproveCardRequest = ({
  cardId,
}: UseApproveCardRequestProps) => {
  const { approveVirtualCard, approvingVirtualCard } =
    useApproveVirtualCardRequest({ cardId });

  const { approveSingleUseCard, approvingSingleUseCard } =
    useApproveSingleUseCardRequest({ cardId });

  const { approvePhysicalCard, approvingPhysicalCard } =
    useApprovePhysicalCardRequest({ cardId });

  const approveActionToCardType: Record<CardType, Action> = {
    PHYSICAL: {
      approve: approvePhysicalCard,
      approving: approvingPhysicalCard,
    },
    SINGLE_USE: {
      approve: approveSingleUseCard,
      approving: approvingSingleUseCard,
    },
    VIRTUAL: {
      approve: approveVirtualCard,
      approving: approvingVirtualCard,
    },
    BLACK: {
      approving: false,
    },
  };

  return { approveActionToCardType };
};
