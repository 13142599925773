export const StorageDocumentManagementEvents = {
  MORE_INFO_CLICKED: 'storage_dms_more_info_clicked',
  MODAL_DEMO_BTN_CLICKED: 'storage_dms_modal_demo_btn_clicked',
};

export const ArchiveDocumentManagementEvents = {
  TAB_OPENED: 'archive_dms_tab_opened',
  DEMO_BTN_CLICKED: 'archive_dms_demo_btn_clicked',
  MORE_INFO_CLICKED: 'archive_dms_more_info_clicked',
  MODAL_DEMO_BTN_CLICKED: 'archive_dms_modal_demo_btn_clicked',
};
