import { Drawer } from '@candisio/design-system';
import { Locale, MembershipStatus } from 'generated-types/graphql.types';
import { User } from '../hooks/useGetUsers';
import { ActiveFormContainer } from './containers/active/ActiveNewRoles/ActiveFormContainer';
import { InactiveFormContainer } from './containers/inactive/InactiveNewRoles/InactiveFormContainer';
import { InviteFormContainer } from './containers/invite/InviteNew/InviteFormContainer';
import { PendingFormContainer } from './containers/pending/PendingNewRoles/PendingFormContainer';

export interface UserDetailsProps {
  isOpen: boolean;
  loading?: boolean;
  organizationLanguage: Locale | null;
  closeDrawer: () => void;
  allUsers: Array<User>;
  selectedUserId?: string;
  onOpenIssueCardModal: (createdUserId: string) => void;
}

export const UserDetails = (props: UserDetailsProps) => {
  const { allUsers, closeDrawer, isOpen, selectedUserId } = props;

  const selectedUser =
    allUsers.find(user => user.id === selectedUserId) ?? null;

  return (
    <Drawer isOpen={isOpen} onClose={closeDrawer}>
      {!selectedUser ? (
        <InviteFormContainer {...props} />
      ) : !selectedUser.emailVerified ? (
        <PendingFormContainer selectedUser={selectedUser} {...props} />
      ) : selectedUser.status === MembershipStatus.Active ? (
        <ActiveFormContainer selectedUser={selectedUser} {...props} />
      ) : (
        <InactiveFormContainer selectedUser={selectedUser} {...props} />
      )}
    </Drawer>
  );
};
