import { Grid, Spinner, Text, useTooltip } from '@candisio/design-system';
import { SapB1ExportStatusTooltip } from 'components/DocumentsTable/Cells/ExportStatusCell/SapB1ExportStatusTooltip';
import {
  ExportStatus,
  ExportableEntityType,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentExportStatus } from 'views/Integrations/Export/types';
import { DocumentsTableData } from '../../types';
import { StatusIcon } from '../StatusCell';
import { BdsExportStatusTooltip } from './BdsExportStatusTooltip';
import { ExportStatusTooltip } from './ExportStatusTooltip';

const translationMap = {
  [ExportStatus.Aborted]: 'export.table.columns.status.statuses.aborted',
  [ExportStatus.Exported]: 'export.table.columns.status.statuses.exported',
  [ExportStatus.Exporting]: 'export.table.columns.status.statuses.exporting',
  [ExportStatus.Failed]: 'export.table.columns.status.statuses.failed',
  [ExportStatus.PartialyExported]:
    'export.table.columns.status.statuses.partialy_exported',
  [ExportStatus.Pending]: 'export.table.columns.status.statuses.pending',
  [ExportStatus.Unknown]: 'export.table.columns.status.statuses.unknown',
  // [ExportStatus.Skipped]: "skipped", // doesn't exist ??
};

const useStatusTooltip = (value: DocumentExportStatus) => {
  const { exportError, details, warnings, exportMethod, entityType } = value;

  const isBdsExport = exportMethod === 'bds';
  const isSapB1Export = exportMethod === 'SAP_B1';

  const isProvisionOrReversal =
    entityType === ExportableEntityType.Provision ||
    entityType === ExportableEntityType.ProvisionReversal;

  const hasExportError = !!exportError;
  const hasDetails = Array.isArray(details) && details.length > 0;
  const hasWarnings = Array.isArray(warnings) && warnings.length > 0;
  const hasTooltip =
    hasDetails || hasWarnings || hasExportError || isProvisionOrReversal;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ passiveTrigger: true, isDisabled: !hasTooltip });

  if (!isOpen)
    return {
      tooltip: null,
      triggerProps,
      triggerRef,
    };

  let tooltip = (
    <ExportStatusTooltip
      exportStatus={value}
      {...tooltipProps}
      ref={tooltipRef}
    />
  );

  if (isBdsExport) {
    tooltip = (
      <BdsExportStatusTooltip
        exportStatus={value}
        {...tooltipProps}
        ref={tooltipRef}
      />
    );
  }

  if (isSapB1Export) {
    tooltip = (
      <SapB1ExportStatusTooltip
        exportStatus={value}
        {...tooltipProps}
        ref={tooltipRef}
      />
    );
  }

  return { tooltip, triggerProps, triggerRef };
};

export const ExportStatusCell = ({
  value = {},
}: CellProps<DocumentsTableData, DocumentExportStatus>) => {
  const [t] = useTranslation();
  const { tooltip, triggerProps, triggerRef } = useStatusTooltip(value);

  const { status: exportStatus } = value;

  if (!exportStatus) {
    return <Text>{t('export.table.emptyCellPlaceholder')}</Text>;
  }

  return (
    <>
      <Grid
        autoFlow="column"
        alignItems="center"
        gap="space4"
        width="fit-content"
        {...triggerProps}
        ref={triggerRef}>
        <StatusIndicator status={exportStatus} />
        <Text fontSize="xsmall" color="gray700" whiteSpace="nowrap">
          {t(translationMap[exportStatus])}
        </Text>
      </Grid>
      {tooltip}
    </>
  );
};

const StatusIndicator = ({ status }: { status: ExportStatus }) => {
  const [t] = useTranslation();

  switch (status) {
    case ExportStatus.Pending:
      return <Spinner size="space16" color="gray500" />;
    case ExportStatus.Exporting:
      return <Spinner size="space16" color="gray500" />;
    case ExportStatus.Failed:
      return (
        <StatusIcon
          hasTooltip={false}
          label={t('export.table.columns.status.statuses.failed')}
          icon="failCircle"
          color="red700"
        />
      );
    // typo in "partially" already exists in db schema >_>
    case ExportStatus.PartialyExported:
      return (
        <StatusIcon
          hasTooltip={false}
          label={t('export.table.columns.status.statuses.partialy_exported')}
          icon="warning"
          color="yellow700"
        />
      );
    case ExportStatus.Exported:
      return (
        <StatusIcon
          hasTooltip={false}
          label={t('export.table.columns.status.statuses.exported')}
          icon="checkCircle"
          color="green700"
        />
      );
    default:
      return null;
  }
};
