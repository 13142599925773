import { ErrorMessages } from 'utils/zodFormValidation';
import { paymentConditionsFormSchema } from './paymentConditionsFormSchema';

export const paymentConditionsFormErrors: ErrorMessages<
  typeof paymentConditionsFormSchema
> = {
  conditionNumber: {
    label: 'paymentConditions.form.fields.conditionNumber.label',
  },
  dueDateOffset: {
    label: 'paymentConditions.form.fields.dueDateOffset.label',
  },
  discountPercentage: {
    label: 'paymentConditions.form.fields.discountPercentage.label',
  },
  discountOffset: {
    label: 'paymentConditions.form.fields.discountOffset.label',
    custom: {
      translationKey: 'paymentConditions.form.validation.discountOffset.custom',
    },
  },
};
