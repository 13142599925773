import { ReactNode, useRef } from 'react';
import {
  FormRegistration,
  ReimbursementFormsContext,
} from './ReimbursementFormsContext';

interface ReimbursementFormsProviderProps {
  children: ReactNode;
}

export const ReimbursementFormsProvider = ({
  children,
}: ReimbursementFormsProviderProps) => {
  const formsRef = useRef<Record<string, FormRegistration>>({});

  const registerForm = (formKey: string, registration: FormRegistration) => {
    formsRef.current = {
      ...formsRef.current,
      [formKey]: registration,
    };
  };

  const triggerAllValidations = () => {
    Object.values(formsRef.current).forEach(form => form.trigger?.());
  };

  return (
    <ReimbursementFormsContext.Provider
      value={{
        registerForm,
        triggerAllValidations,
      }}>
      {children}
    </ReimbursementFormsContext.Provider>
  );
};
