import { Flex, Grid, Switch } from '@candisio/design-system';
import {
  DatevClientExportType,
  New_DatevDocumentType,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { DATEV_SETTINGS_WIDTH } from '../DatevSettings';
import { BookingAccountCell } from './BookingAccountCell';
import { DataTable } from './DataTable';
import { DocumentTypesTableDescription } from './DocumentTypesTableDescription';
import { TableHeaderWithTooltip } from './TableHeaderWithTooltip';

export interface TableData {
  name: string;
  category: string;
  cashAccount: JSX.Element;
  isActive: JSX.Element;
}

type DocumentTypesProps = {
  documentTypes: New_DatevDocumentType[];
  onToggleIsActive: (name: string, category: string, isActive: boolean) => void;
  exportType: DatevClientExportType;
  isCashLedgerSwitchTriggered: boolean;
};

type CategoryReadable = {
  [index: string]: string;
};

export const DocumentTypesTable = ({
  documentTypes,
  onToggleIsActive,
  exportType,
  isCashLedgerSwitchTriggered,
}: DocumentTypesProps) => {
  const [t] = useTranslation();

  const isBookingAccountsAvailable =
    isCashLedgerSwitchTriggered &&
    exportType === DatevClientExportType.DxsoJobs;

  const columns: Array<Column<TableData>> = useMemo(() => {
    const nameColumn: Column<TableData> = {
      Header: t('settings.datev.connect.documentTypes.name'),
      accessor: 'name',
    };

    const categoryColumn: Column<TableData> = {
      Header: t('settings.datev.connect.documentTypes.category.heading'),
      accessor: 'category',
    };

    const cashAccountColumn: Column<TableData> = {
      Header: <TableHeaderWithTooltip />,
      accessor: 'cashAccount',
      // 👇 we add width to prevent icons with tooltips from overflowing (and thus not triggering)
      width: 200,
    };

    const isActiveColumn: Column<TableData> = {
      Header: '',
      accessor: 'isActive',
    };

    if (isBookingAccountsAvailable) {
      return [nameColumn, categoryColumn, cashAccountColumn, isActiveColumn];
    }

    return [nameColumn, categoryColumn, isActiveColumn];
  }, [t, isBookingAccountsAvailable]);

  const data: TableData[] = useMemo(() => {
    const categoryReadable: CategoryReadable = {
      invoices_received: t(
        'settings.datev.connect.documentTypes.category.receivedInvoices'
      ),
      outgoing_invoices: t(
        'settings.datev.connect.documentTypes.category.outgoingInvoices'
      ),
      personnel_documents: t(
        'settings.datev.connect.documentTypes.category.personnelDocuments'
      ),
      travel_expense_documents: t(
        'settings.datev.connect.documentTypes.category.travelExpenseDocuments'
      ),
      other_documents: t(
        'settings.datev.connect.documentTypes.category.otherDocuments'
      ),
    };

    return documentTypes.map(docType => {
      return {
        name: docType.name,
        category: categoryReadable[docType.category],
        cashAccount: <BookingAccountCell documentType={docType} />,
        isActive: (
          <Flex justifyContent="end">
            <Switch
              name={docType.name}
              label={`switch ${docType.name} on and off`}
              checked={docType.isActive}
              onChange={isToggleActive =>
                onToggleIsActive(docType.name, docType.category, isToggleActive)
              }
            />
          </Flex>
        ),
      };
    });
  }, [documentTypes, onToggleIsActive, t]);

  return (
    <Grid maxWidth={DATEV_SETTINGS_WIDTH} gap="space24">
      <DocumentTypesTableDescription />
      <DataTable columns={columns} data={data} />
    </Grid>
  );
};
