import {
  CardLimitRenewFrequency,
  ChangeCardLimitInput,
  DocumentCurrency,
  RequestCardLimitChangeInput,
} from 'generated-types/graphql.types';
import { UpdateSingleUseCardLimitFormValues } from '../UpdateSingleUseCardLimitFormContainer/updateSingleUseCardLimitFormSchema';
import { UpdateVirtualAndPhysicalCardLimitFormValues } from '../UpdateVirtualAndPhysicalCardLimitFormContainer/updateVirtualAndPhysicalCardLimitFormSchema';

export const limitPeriodOptions: Array<{
  key: CardLimitRenewFrequency;
  children: string;
}> = [
  {
    key: CardLimitRenewFrequency.Monthly,
    children: 'ccRequestForm.fields.limitInterval.monthly.label',
  },
  {
    key: CardLimitRenewFrequency.Quarterly,
    children: 'ccRequestForm.fields.limitInterval.quarterly.label',
  },
  {
    key: CardLimitRenewFrequency.Annual,
    children: 'ccRequestForm.fields.limitInterval.annual.label',
  },
  {
    key: CardLimitRenewFrequency.Total,
    children: 'ccRequestForm.fields.limitInterval.total.label',
  },
];

export const limitIntervalLabel: Record<CardLimitRenewFrequency, string> = {
  ANNUAL: 'dashboard.drawer.form.limitInterval.annual.text',
  MONTHLY: 'dashboard.drawer.form.limitInterval.monthly.text',
  QUARTERLY: 'dashboard.drawer.form.limitInterval.quarterly.text',
  TOTAL: 'dashboard.drawer.form.limitInterval.total.text',
};

export const limitLabel: Record<CardLimitRenewFrequency, string> = {
  MONTHLY: 'ccRequestForm.fields.limit.label.monthly',
  QUARTERLY: 'ccRequestForm.fields.limit.label.quarterly',
  ANNUAL: 'ccRequestForm.fields.limit.label.annual',
  TOTAL: 'ccRequestForm.fields.limit.label.total',
};

export const sanitizeSingleUseLimitInputsForCardholder = (
  formData: UpdateSingleUseCardLimitFormValues
): Omit<RequestCardLimitChangeInput, 'cardId'> => {
  return {
    // We set limit the same as transactionLimit for single use cards
    requestedLimit: {
      currency: DocumentCurrency.Eur,
      value: formData.transactionLimit ?? 0,
    },
    requestedTransactionLimit: {
      currency: DocumentCurrency.Eur,
      value: formData.transactionLimit ?? 0,
    },
    comment: formData.comment ?? '',
  };
};

export const sanitizeSingleUseLimitInputsForCardManager = (
  formData: UpdateSingleUseCardLimitFormValues
): Omit<ChangeCardLimitInput, 'cardId'> => {
  return {
    limit: {
      currency: DocumentCurrency.Eur,
      value: formData.transactionLimit ?? 0,
    },
    transactionLimit: {
      currency: DocumentCurrency.Eur,
      value: formData.transactionLimit ?? 0,
    },
  };
};

export const sanitizeVirtualAndPhysicalLimitInputsForCardholder = (
  formData: UpdateVirtualAndPhysicalCardLimitFormValues
): Omit<RequestCardLimitChangeInput, 'cardId'> => {
  return {
    // We set limit the same as transactionLimit for single use cards
    requestedLimit: {
      currency: DocumentCurrency.Eur,
      value: formData.limit ?? 0,
    },
    requestedTransactionLimit: {
      currency: DocumentCurrency.Eur,
      value: formData.transactionLimit ?? 0,
    },
    requestedLimitRenewFrequency:
      formData.limitInterval as CardLimitRenewFrequency,
    comment: formData.comment ?? '',
  };
};

export const sanitizeVirtualAndPhysicalLimitInputsForCardManager = (
  formData: UpdateVirtualAndPhysicalCardLimitFormValues
): Omit<ChangeCardLimitInput, 'cardId'> => {
  return {
    limitRenewFrequency: formData.limitInterval as CardLimitRenewFrequency,
    limit: {
      currency: DocumentCurrency.Eur,
      value: formData.limit ?? 0,
    },
    transactionLimit: {
      currency: DocumentCurrency.Eur,
      value: formData.transactionLimit ?? 0,
    },
  };
};
