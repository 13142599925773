import { History } from 'components/History/History';
import { BaseItemProps } from 'components/History/items/BaseItem';
import {
  TransactionAmountChangedEventDataFragment,
  TransactionCommentEventDataFragment,
  TransactionMarkedAsExportedEventDataFragment,
  TransactionMarkedAsInvoiceNeededEventDataFragment,
  TransactionMarkedAsInvoiceNotNeededEventDataFragment,
  TransactionReconciledEventDataFragment,
  TransactionStatusUpdatedEventDataFragment,
  TransactionTimeLineItem,
} from 'generated-types/graphql.types';
import { FunctionComponent } from 'react';
import {
  AmountChanged,
  Comment,
  StatusUpdated,
  TransactionReconciled,
} from './component/CommentHistory';
import {
  NoInvoiceNeededActivated,
  NoInvoiceNeededDeactivated,
} from './component/NoInvoiceNeededHistory';
import { TransactionExported } from './component/TransactionExportedHistory';

export type OnAssociateDocument = () => Promise<void>;
export type OnDeleteFile = () => Promise<void>;

export enum ASSOCIATION {
  // no file available at all
  NONE = 'NONE',
  // file uploaded, but link not persisted yet
  FILE = 'FILE',
  // link is persisted on the DB
  DOCUMENT = 'DOCUMENT',
}

export enum InfoBoxVariant {
  approver = 'approver',
  invalid = 'invalid',
  requesterAdmin = 'requesterAdmin',
}

export type SupportedFragment =
  | TransactionStatusUpdatedEventDataFragment
  | TransactionReconciledEventDataFragment
  | TransactionAmountChangedEventDataFragment
  | TransactionCommentEventDataFragment
  | TransactionMarkedAsInvoiceNeededEventDataFragment
  | TransactionMarkedAsInvoiceNotNeededEventDataFragment
  | TransactionMarkedAsExportedEventDataFragment;

export type SupportedTransactionEvent = TransactionTimeLineItem &
  SupportedFragment;

export type SupportedTransactionEventKeys =
  Required<SupportedTransactionEvent>['__typename'];

export const mapWithSupportedTransactionEventKeys = (obj: {
  [k in SupportedTransactionEventKeys]: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    item: FunctionComponent<BaseItemProps>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    content: FunctionComponent<any>;
    collapsible?: boolean;
    isInvoiceNotNeededActivated?: boolean;
  };
}) => obj;

export const activityTypeToTransactionHistoryComponent =
  mapWithSupportedTransactionEventKeys({
    TransactionCommentEvent: {
      item: History.User,
      content: Comment,
      collapsible: false,
    },
    TransactionStatusUpdatedEvent: {
      item: History.User,
      content: StatusUpdated,
      collapsible: false,
    },
    TransactionAmountChangedEvent: {
      item: History.User,
      content: AmountChanged,
      collapsible: false,
    },
    TransactionReconciledEvent: {
      item: History.User,
      content: TransactionReconciled,
      collapsible: true,
    },
    TransactionMarkedAsInvoiceNeededEvent: {
      item: History.User,
      content: NoInvoiceNeededDeactivated,
      collapsible: false,
    },
    TransactionMarkedAsInvoiceNotNeededEvent: {
      item: History.User,
      content: NoInvoiceNeededActivated,
      collapsible: false,
      isInvoiceNotNeededActivated: true,
    },
    TransactionMarkedAsExportedEvent: {
      item: History.Export,
      content: TransactionExported,
      collapsible: false,
    },
  });
