export const truncatedText = (
  translationString: string | undefined,
  // length of string before it is truncated
  maxCharacterLength: number = 34
) => {
  if (translationString && translationString?.length < maxCharacterLength) {
    return translationString;
  }

  const truncationLength = maxCharacterLength / 2 - 1.5;

  return `${translationString?.slice(
    0,
    truncationLength
  )} ... ${translationString?.slice(
    translationString.length - truncationLength
  )}`;
};
