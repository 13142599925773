import { Locale } from 'generated-types/graphql.types';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const userProfileFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  locale: z.nativeEnum(Locale),
});

export type UserProfileFormOutput = z.infer<typeof userProfileFormSchema>;
export type UserProfileFormValues = Partial<UserProfileFormOutput>;
export type UserProfileFormErrorMessages = ErrorMessages<
  typeof userProfileFormSchema
>;
