import {
  AvatarProps,
  Color,
  HeaderProps,
  Tag,
  TruncatedText,
} from '@candisio/design-system';
import { amountCellProps } from 'components/Table/Cells/Amount';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import {
  PurchaseOrderEdge,
  PurchaseOrderSortInput,
} from 'generated-types/graphql.types';
import {
  PurchaseOrderFilterInput,
  PurchaseOrderSortField,
  PurchaseOrdersInvoiceAssociationStatus,
  SortDirection,
} from 'generated-types/resolvers-types';
import { castArray } from 'lodash';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Column, Filters, Row, SortingRule } from 'react-table';
import { paginationFiltersHooks } from 'views/Archive/PurchaseOrders/hooks/paginationFilterHooks';
import { DateText as DateCell } from '../DateText/DateText';
import { PurchaseOrderAmountCell } from '../PurchaseOrderAmountCell/PurchaseOrderAmountCell';
import { PurchaseOrderStatusTag } from '../PurchaseOrderStatusTag/PurchaseOrderStatusTag';
import { GenericCell as Cell } from './GenericCell/GenericCell';
import { InvoiceListCell } from './InvoiceListCell';
import { LineItemsCell } from './LineItemsCell';
import {
  AvailableFiltersPurchaseOrdersTable,
  ColumnWidths,
  PURCHASE_ORDERS_QUERY_PARAMS,
  PurchaseOrdersTableData,
  PruchaseOrdersTableDataKeys as PurchaseOrdersTableDataKeys,
} from './types';

export const queryParams = { allDone: 'allDone' };

// this is a workaround to style the remaining amount properly in the index.html
// if you change this property make sure that index.html is also updated
export const remainingAmountCellProps = {
  width: '9.99rem',
};

export type InvoiceStatusTag = Record<
  PurchaseOrdersInvoiceAssociationStatus,
  { description: string; color: Color }
>;

const mapSortIdsToPurchaseOrderSortFields: {
  [index in PurchaseOrdersTableDataKeys]: PurchaseOrderSortField;
} = {
  invoiceAssociationStatus: PurchaseOrderSortField.InvoiceAssociationStatus,
  postingDate: PurchaseOrderSortField.PostingDate,
  status: PurchaseOrderSortField.Status,
  grossAmount: PurchaseOrderSortField.TotalAmount,
};

export const purchaseOrderToTableData = (
  purchaseOrders: PurchaseOrderEdge[]
): PurchaseOrdersTableData[] => {
  return purchaseOrders.map(
    ({
      cursor,
      node: {
        _id: id,
        contactName: contact,
        status,
        amount,
        orderNumber,
        postingDate,
        invoiceAssociationStatus,
        connectedInvoices,
        goodsLineItems,
        serviceLineItems,
        accountsPayableNumber,
        remainingAmount,
      },
    }) => ({
      cursor,
      id,
      contact,
      status,
      grossAmount: amount
        ? {
            value: amount.amount,
            currency: amount.currency,
          }
        : null,
      orderNumber,
      postingDate: postingDate ? new Date(postingDate) : null,
      invoiceAssociationStatus,
      connectedInvoices: connectedInvoices || [],
      lineItems: { goodsLineItems, serviceLineItems },
      accountsPayableNumber,
      remainingAmount: remainingAmount
        ? {
            value: remainingAmount.amount,
            currency: remainingAmount.currency,
          }
        : null,
    })
  );
};

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<PurchaseOrdersTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <TruncatedText color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
      {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        t(`columns.${column?.id}`, {
          context: getTranslationContext(),
        })
      }
    </TruncatedText>
  );
};

const useInvoiceStatusTag = ({
  status,
}: {
  status?: PurchaseOrdersInvoiceAssociationStatus | null;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const invoiceStatusTag: InvoiceStatusTag = {
    MISSING: {
      description: t('documentStatus.missing'),
      color: 'yellow',
    },
    INCOMPLETE: {
      description: t('documentStatus.incomplete'),
      color: 'gray',
    },
    ATTACHED: {
      description: t('documentStatus.attached'),
      color: 'green',
    },
  };

  if (!status) {
    return;
  }

  const color = invoiceStatusTag[status].color;
  const description = invoiceStatusTag[status].description;

  return { color, description };
};

export const purchaseOrderColumnDefinition = ({
  columnWidths,
  documentAmount,
}: {
  columnWidths: ColumnWidths;
  documentAmount?: number | null;
}) => {
  const allColumns: Array<Column<PurchaseOrdersTableData>> = [
    {
      accessor: 'contact',
      disableSortBy: true,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell,
      Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
        <FilterWithSearchAndPagination
          column={column}
          onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          customPaginationFilterHooks={paginationFiltersHooks}
        />
      ),
      width: columnWidths?.contact,
    },
    {
      accessor: 'status',
      Cell: ({ value }) => {
        if (!value) {
          return null;
        }

        return <PurchaseOrderStatusTag status={value} />;
      },
      width: columnWidths?.status,
    },
    {
      accessor: 'grossAmount',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <PurchaseOrderAmountCell
          value={value}
          documentAmount={documentAmount}
        />
      ),
      ...amountCellProps,
    },
    {
      accessor: 'remainingAmount',
      disableFilters: true,
      disableSortBy: true,
      Cell: PurchaseOrderAmountCell,
      // this is a workaround to style the remaining amount properly in the index.html
      ...remainingAmountCellProps,
    },
    {
      accessor: 'orderNumber',
      disableSortBy: true,
      disableFilters: true,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell,
      width: columnWidths?.orderNumber,
    },
    {
      accessor: 'postingDate',
      disableFilters: true,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: DateCell,
      width: columnWidths?.postingDate,
    },
    {
      accessor: 'invoiceAssociationStatus',
      Cell: ({ value }) => {
        const tag = useInvoiceStatusTag({
          status: value,
        });

        if (!tag) {
          return null;
        }

        return (
          <Tag color={tag.color} variant="secondary">
            {tag.description}
          </Tag>
        );
      },
      width: columnWidths?.invoiceAssociationStatus,
    },
    {
      accessor: 'connectedInvoices',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ value }) => (
        <InvoiceListCell
          connectedInvoices={value}
          columnWidth={columnWidths?.connectedInvoices}
        />
      ),
      width: columnWidths?.connectedInvoices,
    },
    {
      accessor: 'lineItems',
      Cell: LineItemsCell,
      disableSortBy: true,
      disableFilters: true,
      width: columnWidths?.lineItems,
    },
  ];

  return allColumns;
};

/**
 * Map TransactionTable sort state to SortInput.
 * Only considers the first sorted column.
 */
export const mapToSortInput = (
  sort: Array<SortingRule<PurchaseOrdersTableData>>,
  documentAmount?: number | null
): PurchaseOrderSortInput | undefined => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  return {
    field:
      mapSortIdsToPurchaseOrderSortFields[
        firstSort.id as PurchaseOrdersTableDataKeys
      ],
    direction: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
    ...(documentAmount ? { targetValue: documentAmount } : undefined),
  };
};

export const mapToFilterInput = (
  filters: Filters<PurchaseOrdersTableData>
): PurchaseOrderFilterInput => {
  return filters.reduce<PurchaseOrderFilterInput>(
    (documentFilterInput, filter) => {
      if (filter.id === availableFiltersPurchaseOrdersTable.contact) {
        return { ...documentFilterInput, accountsPayableNumbers: filter.value };
      }

      if (
        filter.id === availableFiltersPurchaseOrdersTable.accountsPayableNumber
      ) {
        return { ...documentFilterInput, accountPayableNumber: filter.value };
      }

      if (filter.id === availableFiltersPurchaseOrdersTable.status) {
        return { ...documentFilterInput, statuses: filter.value };
      }

      if (
        filter.id ===
        availableFiltersPurchaseOrdersTable.invoiceAssociationStatus
      ) {
        return {
          ...documentFilterInput,
          invoiceAssociationStatuses: filter.value,
        };
      }

      return documentFilterInput;
    },
    {}
  );
};

export const includesArray = (
  rows: Row<PurchaseOrdersTableData>[],
  columnIds: string[],
  filterArray: string[]
) => {
  return rows.filter(row => {
    return filterArray.some(filter => {
      const headerId: string = columnIds[0];
      if (Array.isArray(row.values[headerId])) {
        return row.values[headerId].some(
          (value: AvatarProps) => value.name?.includes(filter) ?? false
        );
      }

      // the properties on row.values are typed 'any' by react table
      // so it's safer to go with 'unknown' here
      const rowValues: unknown =
        row.values[headerId].status || row.values[headerId];

      return castArray(rowValues).includes(filter);
    });
  });
};

export const availableFiltersPurchaseOrdersTable: {
  [key in keyof AvailableFiltersPurchaseOrdersTable]-?: keyof AvailableFiltersPurchaseOrdersTable;
} = {
  contact: 'contact',
  invoiceAssociationStatus: 'invoiceAssociationStatus',
  status: 'status',
  accountsPayableNumber: 'accountsPayableNumber',
};

export const availableFilters = [
  PURCHASE_ORDERS_QUERY_PARAMS.contact,
  PURCHASE_ORDERS_QUERY_PARAMS.invoiceAssociationStatus,
  PURCHASE_ORDERS_QUERY_PARAMS.status,
  PURCHASE_ORDERS_QUERY_PARAMS.accountPayableNumber,
];
