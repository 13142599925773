import { ContactsFilterInput } from 'generated-types/graphql.types';
import { Filters } from 'react-table';
import { ContactsTableData } from '../ContactTableDS';
import {
  IbanOrBanFilter,
  toIbanOrBanContactFilterInput,
} from '../hooks/usePaymentInfoFilter';

export const mapToContactsInputFilter = (
  filters: Filters<ContactsTableData>
): ContactsFilterInput => {
  const reducedFilters = filters.reduce<ContactsFilterInput>(
    (contactFilterInput, filter) => {
      switch (filter.id) {
        case 'iban': {
          return { ...contactFilterInput, iban: filter.value };
        }

        case 'paymentInfo': {
          const ibansOrBANsFilters: IbanOrBanFilter[] = filter.value;

          return {
            ...contactFilterInput,
            ibanOrBankAccountNumber:
              toIbanOrBanContactFilterInput(ibansOrBANsFilters),
          };
        }

        case 'taxNumber': {
          return { ...contactFilterInput, taxNumber: filter.value };
        }

        case 'vatId': {
          return { ...contactFilterInput, vatId: filter.value };
        }

        case 'accountsPayableNumber': {
          return { ...contactFilterInput, accountsPayableNumber: filter.value };
        }

        case 'accountsReceivableNumber': {
          return {
            ...contactFilterInput,
            accountsReceivableNumber: filter.value,
          };
        }

        case 'relationshipType': {
          return { ...contactFilterInput, relationshipType: filter.value };
        }

        case 'contact': {
          return { ...contactFilterInput, id: filter.value };
        }

        case 'isArchived': {
          return { ...contactFilterInput, isArchived: filter.value };
        }
      }

      return contactFilterInput;
    },
    {}
  );

  return reducedFilters;
};
