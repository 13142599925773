import { Link } from '@candisio/design-system';
import { DocumentResetUserDeactivationEventDataFragment } from 'generated-types/graphql.types';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Collapse } from '../../History/items/Collapse';

export const DocumentResetUserDeactivationComponent = ({
  targetUser,
}: DocumentResetUserDeactivationEventDataFragment) => {
  const [t] = useTranslation();
  const email = targetUser?.email;

  return (
    <>
      {t('document.compact.history.documentResetUserDeactivation.title')}
      <Collapse>
        <br />
        <Trans
          i18nKey="document.compact.history.documentResetUserDeactivation.details"
          values={{ email }}>
          Der Freigabeprozess wurde zurückgesetzt, da ein beteiligter Freigeber{' '}
          <Link href={`mailto:${email}`}>{{ email } as any}</Link> aus dem
          System deaktiviert wurde.
        </Trans>
      </Collapse>
    </>
  );
};
