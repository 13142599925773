import { Grid } from '@candisio/design-system';

import { useProgressBar } from 'react-aria';
import { StepperItem } from './StepperItem';

export interface StepperProps {
  steps: string[];
  currentStep: number;
  showStepNumbers?: boolean;
}

export const Stepper = ({
  currentStep,
  steps,
  showStepNumbers = false,
}: StepperProps) => {
  const currentProgress = (100 / (steps.length - 1)) * currentStep;

  const { progressBarProps } = useProgressBar({
    minValue: 0,
    maxValue: 100,
    value: currentProgress,
  });

  const { 'aria-valuetext': ariaValueText, ...restProps } = progressBarProps;

  return (
    <Grid as="ul" {...restProps} paddingLeft="0px" autoFlow="column">
      {Array.from({ length: steps.length }).map((_, index) => {
        const isCurrentStepActive = index === currentStep;
        const isStepCompleted = currentStep > index;

        const isLastStep = steps.length - 1 === index;

        return (
          <StepperItem
            key={index}
            description={steps[index]}
            isCurrentStepActive={isCurrentStepActive}
            isItemPositionVisible={showStepNumbers}
            isLastStep={isLastStep}
            isStepCompleted={isStepCompleted}
            itemPosition={index}
          />
        );
      })}
    </Grid>
  );
};
