import { useNew_DatevSettingsQuery } from 'generated-types/graphql.types';

export const useDatevSettings = () => {
  const { data: datevSettingsData, loading } = useNew_DatevSettingsQuery();

  const datevSettings = datevSettingsData?.new_datevSettings;

  return {
    loading,
    datevSettings,
  };
};
