import { ExportType } from 'generated-types/graphql.types';

export const exportTypeTranslationMap: Record<
  Exclude<ExportType, 'DATEV_DCO' | 'DATEV_DXSO' | 'API'>,
  string
> = {
  DATEV: 'notification.exportTypes.datev',
  SAP_B1: 'notification.exportTypes.sap_b1',
  DATEV_BDS: 'notification.exportTypes.datev_bds',
  ADDISON_FORMAT: 'notification.exportTypes.addison_format',
  ZIP: 'notification.exportTypes.zip',
  CANDIS_CSV: 'notification.exportTypes.candis_csv',
  DATEV_CSV: 'notification.exportTypes.datev_csv',
  DATEV_DXSO_ZIP: 'notification.exportTypes.datev_dxso_zip',
  SAP_CSV: 'notification.exportTypes.sap_csv',
};
