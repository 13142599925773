import { Button, Grid, Separator } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import {
  FormProvider,
  UseFieldArrayRemove,
  UseFormReturn,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { ExpenseFormWrapper } from '../ExpenseFormWrapper';
import { ExpenseInvoiceInputField } from '../ExpenseInvoiceInputField';
import { ExpenseInvoicePreviewContainer } from '../ExpenseInvoicePreviewContainer';
import { generateGeneralExpenseFormFieldNames } from '../utils/generateGeneralExpenseFormFieldNames';
import { useExpensesFormActions } from '../utils/useExpensesFormActions';

interface GeneralExpensesFormProps {
  createInvoice: (file: File) => Promise<ExpenseInvoice | undefined>;
  onRemoveForm: UseFieldArrayRemove;
  generalExpensesForms: ExpensesFormOutput['generalExpenses'];
  formMethods: UseFormReturn<ExpensesFormOutput>;
}
export const GeneralExpensesForm = ({
  createInvoice,
  formMethods,
  generalExpensesForms,
  onRemoveForm,
}: GeneralExpensesFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { watch } = formMethods;
  const generalExpenses = watch('generalExpenses');

  const {
    isCreatingInvoice,
    clearableField,
    handleClearableField,
    isDeletingForm,
    handleUpdateGeneralExpense,
    handleDeleteForm,
    handleInvoiceChange,
    handleReplaceInvoice,
  } = useExpensesFormActions({
    createExpenseInvoice: createInvoice,
    onRemoveForm,
    formMethods,
    expenseType: 'general',
  });

  return (
    <FormProvider {...formMethods}>
      <Grid gap="space24">
        {(generalExpensesForms ?? []).map((form, index) => {
          const fields = generateGeneralExpenseFormFieldNames(index);
          const invoice = generalExpenses[index].files[0];
          const showInvoicePreview = isCreatingInvoice[index] || !!invoice;

          return (
            <ExpenseFormWrapper
              key={form.expenseId}
              expenseId={form.expenseId}
              totalAmount={form.totalAmount}
              expenseType="general"
              reason={form.reason}>
              <Grid as="form" gap="space24">
                <Grid columns={3} gap="space8">
                  <HookFormTextField
                    name={fields.reason}
                    label={t(
                      'reimbursementView.middleSection.form.general.reason.label'
                    )}
                    ariaLabel={t(
                      'reimbursementView.middleSection.form.general.reason.label'
                    )}
                    placeholder={t(
                      'reimbursementView.middleSection.form.general.reason.placeholder'
                    )}
                    clearable={clearableField === fields.reason}
                    onFocus={() => handleClearableField(fields.reason)}
                    inputProps={{
                      onBlur: handleUpdateGeneralExpense(index),
                    }}
                  />

                  <HookFormAmountField
                    name={fields.totalAmount}
                    label={t(
                      'reimbursementView.middleSection.form.general.totalAmount.label'
                    )}
                    placeholder={t(
                      'reimbursementView.middleSection.form.general.totalAmount.placeholder'
                    )}
                    currency="EUR"
                    minValue={0}
                    onBlur={handleUpdateGeneralExpense(index)}
                  />
                  <HookFormDatePickerField
                    name={fields.expenseDate}
                    label={t(
                      'reimbursementView.middleSection.form.general.date.label'
                    )}
                    onBlur={handleUpdateGeneralExpense(index)}
                  />
                </Grid>
                {showInvoicePreview ? (
                  <ExpenseInvoicePreviewContainer
                    invoice={invoice}
                    createInvoicePending={isCreatingInvoice[index]}
                    onReplaceInvoice={handleReplaceInvoice(index)}
                  />
                ) : (
                  <ExpenseInvoiceInputField
                    name={fields.files}
                    id={`invoice-input-field-${index}`}
                    onChange={file => handleInvoiceChange(file, index)}
                    onIvoiceUpdate={handleUpdateGeneralExpense(index)}
                  />
                )}

                <Separator />
                <Button
                  size="small"
                  variant="tertiary"
                  justifySelf="end"
                  color="error"
                  disabled={isDeletingForm[index]}
                  loading={isDeletingForm[index]}
                  onClick={handleDeleteForm(index, form.expenseId)}>
                  {t(
                    'reimbursementView.middleSection.form.hospitality.deleteCta'
                  )}
                </Button>
              </Grid>
            </ExpenseFormWrapper>
          );
        })}
      </Grid>
    </FormProvider>
  );
};
