import {
  Box,
  mergeProps,
  Tooltip,
  useTooltip,
  mergeRefs,
  BoxProps,
  useOverflow,
} from '@candisio/design-system';

const DEFAULT_ELEMENT = 'div';

type TruncatedCellContentProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT
> = BoxProps<TElement> & {
  children: React.ReactNode | React.ReactNode[];
  maxWidth?: string;
};

export const TruncatedCellContent = <TElement extends React.ElementType>({
  children,
  maxWidth,
  ref,
  ...restProps
}: TruncatedCellContentProps<TElement>) => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      passiveTrigger: true,
      delay: 500,
    });

  const { overflowing, overflowRef } = useOverflow();

  return (
    <>
      <Box
        as={DEFAULT_ELEMENT}
        maxWidth={maxWidth}
        overflow="hidden"
        whiteSpace="nowrap"
        color="blue500"
        style={{
          textOverflow: 'ellipsis',
        }}
        ref={mergeRefs([triggerRef, overflowRef])}
        {...(mergeProps(restProps, triggerProps) as Record<string, unknown>)}>
        {children}
      </Box>
      {overflowing && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {children}
        </Tooltip>
      )}
    </>
  );
};
