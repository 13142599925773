import {
  CardIssuerTransactionInvoiceNotNeededReason,
  InvoiceAssociationStatus,
  useGetCardIssuerTransactionByIdQuery,
} from 'generated-types/graphql.types';
import DateFormatters from 'utils/date_formatter';

export interface Transaction {
  id?: string;
  merchantName?: string;
  merchantLegalName?: string;
  cardRefNumber?: string;
  cardId?: string;
  invoiceAssociationStatus?: InvoiceAssociationStatus;
  transactionCreatedAt?: string;
  invoiceNotNeeded: {
    reason?: CardIssuerTransactionInvoiceNotNeededReason;
    comment?: string;
    transactionCreatedAt?: string;
  };
  billingAmount: {
    value: number | undefined;
    precision: number | undefined;
    currency: string | undefined;
  };
  linkedDocument: {
    id?: string;
  };
  accountingData: {
    contactId?: string;
    contact: {
      id?: string;
      name?: string;
    };
    costCenterId?: string;
    costObjectId?: string;
    generalLedgerAccountId?: string;
    bookingKeyId?: string;
    artistSocialInsuranceCode?: string;
    extraCostInfoId?: string;
    paymentConditionId?: string;
    approvalWorkflowTemplateId?: string;
    approvers?: {
      approvers: string[];
    }[];
  };

  note?: string;
}

export interface UseGetTransactionByIdReturn {
  transaction: Transaction;
  isTransactionLoading: boolean;
}

export interface UseGetTransactionByIdParams {
  id: string | undefined;
}

export const useGetTransactionById = ({
  id,
}: UseGetTransactionByIdParams): UseGetTransactionByIdReturn => {
  const { data, loading } = useGetCardIssuerTransactionByIdQuery({
    variables: { id: id as string },
    skip: !id,
  });

  const transaction: Transaction = {
    id: data?.getCardIssuerTransactionById.id,
    merchantName: data?.getCardIssuerTransactionById.merchantName ?? undefined,
    merchantLegalName:
      data?.getCardIssuerTransactionById.merchantLegalName ?? undefined,
    cardRefNumber: data?.getCardIssuerTransactionById.cardRefNum ?? undefined,
    cardId: data?.getCardIssuerTransactionById.cardId,
    transactionCreatedAt: data?.getCardIssuerTransactionById
      .transactionCreatedAt
      ? DateFormatters.compact(
          new Date(data?.getCardIssuerTransactionById.transactionCreatedAt)
        )
      : undefined,
    invoiceAssociationStatus:
      data?.getCardIssuerTransactionById.invoiceAssociationStatus ?? undefined,
    invoiceNotNeeded: {
      reason:
        data?.getCardIssuerTransactionById.invoiceNotNeeded?.reason ??
        undefined,
      comment:
        data?.getCardIssuerTransactionById.invoiceNotNeeded?.comment ??
        undefined,
      transactionCreatedAt:
        data?.getCardIssuerTransactionById.transactionCreatedAt ?? undefined,
    },
    billingAmount: {
      currency: data?.getCardIssuerTransactionById.transactionAmount?.currency,
      value: data?.getCardIssuerTransactionById.transactionAmount?.value,
      precision:
        data?.getCardIssuerTransactionById.transactionAmount?.precision,
    },
    linkedDocument: {
      id: data?.getCardIssuerTransactionById.documents[0]?.id,
    },
    accountingData: {
      contact: {
        id: data?.getCardIssuerTransactionById.accountingData?.contact?.id,
        name: data?.getCardIssuerTransactionById.accountingData?.contact?.name
          .value,
      },
      contactId:
        data?.getCardIssuerTransactionById.accountingData?.contactId ??
        undefined,
      costCenterId:
        data?.getCardIssuerTransactionById.accountingData?.costCenterId ??
        undefined,
      costObjectId:
        data?.getCardIssuerTransactionById.accountingData?.costObjectId ??
        undefined,
      generalLedgerAccountId:
        data?.getCardIssuerTransactionById.accountingData
          ?.generalLedgerAccountId ?? undefined,
      bookingKeyId:
        data?.getCardIssuerTransactionById.accountingData?.bookingKeyId ??
        undefined,
      artistSocialInsuranceCode:
        data?.getCardIssuerTransactionById.accountingData
          ?.artistSocialInsuranceCode ?? undefined,
      extraCostInfoId:
        data?.getCardIssuerTransactionById.accountingData?.extraCostInfoId ??
        undefined,
      paymentConditionId:
        data?.getCardIssuerTransactionById.accountingData?.paymentConditionId ??
        undefined,
      approvalWorkflowTemplateId:
        data?.getCardIssuerTransactionById.accountingData
          ?.approvalWorkflowTemplateId ?? undefined,
      approvers:
        data?.getCardIssuerTransactionById.accountingData?.approvers?.map(
          approvers => ({
            approvers: approvers.map(approver => approver.id),
          })
        ),
    },
    note: data?.getCardIssuerTransactionById.note ?? undefined,
  };

  return {
    transaction,
    isTransactionLoading: loading,
  };
};
