import {
  fetchPolicy,
  getOptionsFromNetworkData,
  PaginationWithSearchFilterHook,
  FilterOption,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';

import {
  CostCenterInfiniteScrollPaginationQuery,
  CostCenterInfiniteScrollPaginationQueryVariables,
  CostCenterSortField,
  CostCenterTypes,
  SortDirection,
  useCostCenterPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { costCenterInfiniteScrollPagination } from 'views/queries/cost-centers';

export const useExtraCostInfoReadableNameFilter: PaginationWithSearchFilterHook =
  ({ filteredValues, searchStr }) => {
    const computeVariables = useCallback(
      (
        inputString?: string
      ): CostCenterInfiniteScrollPaginationQueryVariables => ({
        filters: {
          type: [CostCenterTypes.ExtraCostInfo],
        },
        queries: {
          readableName: inputString,
        },
        sortBy: {
          direction: SortDirection.Asc,
          field: CostCenterSortField.Name,
        },
      }),
      []
    );

    const {
      data: allExtraCostInfosData,
      loading: allExtraCostInfosLoading,
      loadMore,
      handleDebounceSearch,
    } = useDebouncedSearchPagination<
      CostCenterInfiniteScrollPaginationQuery,
      CostCenterInfiniteScrollPaginationQueryVariables
    >({
      query: costCenterInfiniteScrollPagination,
      queryRootKey: 'costCenterPagination',
      computeVariables,
    });

    const { data: selectedCostCenters } = useCostCenterPaginationQuery({
      variables: {
        input: { page: 1, limit: filteredValues.length },
        filters: {
          id: filteredValues,
        },
      },
      fetchPolicy,
      skip: filteredValues.length === 0 || Boolean(searchStr),
    });

    const filterOptions: FilterOption[] = getOptionsFromNetworkData(
      selectedCostCenters?.costCenterPagination.records,
      allExtraCostInfosData?.costCenterPagination.records
    );

    return {
      filterOptions,
      handleDebounceSearch,
      loading: allExtraCostInfosLoading,
      loadMore,
    };
  };
