import { useTranslation } from 'react-i18next';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { DocumentType } from '../../../../types';

export const ApprovalsSummary = ({
  readyToExportEntitiesCount,
  loading,
}: {
  readyToExportEntitiesCount: Record<DocumentType, number>;
  loading?: boolean;
}) => {
  const [t] = useTranslation();

  const totalApprovalsCount = readyToExportEntitiesCount.DOCUMENT ?? 0;

  const approvalsSummary = [
    {
      title: t('export.exportSummary.approvalsCount', {
        count: totalApprovalsCount,
      }),
      content: '',
    },
  ];

  return totalApprovalsCount ? (
    <SummaryAccordionCard
      items={approvalsSummary}
      loading={loading}
      collapsable={false}
    />
  ) : null;
};
