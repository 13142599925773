import { addBreadcrumb, setTag } from '@sentry/react';
import { meaWalletBaseUrl } from '../components/CardColumn/CreditCardSensitiveDataContainer';
import { MeaWalletMessageEvent } from '../components/CardColumn/events/types';

interface Error {
  name: string;
  message: string;
}

interface HandleErrorsProps {
  cardId: string;
  token?: string | null;
  event: MeaWalletMessageEvent;
}

const errorGenerator = ({ name, message }: Error) => {
  const error = new Error(message);
  error.name = name;

  return error;
};

export const useMeaWalletErrorToSentryIntegration = () => {
  const handleError = ({ event, cardId, token }: HandleErrorsProps) => {
    const errorName = 'Credit Card Sensitive Data Error';
    const errorMessage = `MeaWallet failed while making a request to ${meaWalletBaseUrl}`;

    setTag('source', 'mea-wallet-iframe');
    addBreadcrumb({
      type: 'error',
      level: 'error',
      category: 'error',
      message: errorMessage,
      data: {
        cardId,
        cardToken: token,
        errorCode: event.data.code,
        eventId: event.data.event,
        timestamp: new Date().toISOString(),
      },
    });

    throw errorGenerator({
      name: errorName,
      message: errorMessage,
    });
  };

  return handleError;
};
