import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { useTranslation } from 'react-i18next';

interface GrossAmountProps {
  currency?: string;
}

export const GrossAmount = ({ currency }: GrossAmountProps) => {
  const [t] = useTranslation();

  return (
    <HookFormAmountField
      name="grossAmount"
      currency={currency}
      label={t('document.requestApproval.inputs.amount.label')}
      readOnly
    />
  );
};
