import { defaultTheme } from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { SvgIcon, SvgProps } from './SvgIcon';

const Wrapper = styled(SvgIcon)`
  @keyframes a0_t {
    0% {
      transform: translate(8.00027px, 7.99995px) rotate(0deg)
        translate(-5.57254px, -5.99995px);
    }
    10% {
      transform: translate(8.00027px, 7.99995px) rotate(0deg)
        translate(-5.57254px, -5.99995px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    95% {
      transform: translate(8.00027px, 7.99995px) rotate(720deg)
        translate(-5.57254px, -5.99995px);
    }
    100% {
      transform: translate(8.00027px, 7.99995px) rotate(720deg)
        translate(-5.57254px, -5.99995px);
    }
  }
`;

/**
 * @deprecated please use Icon from design system instead.
 * @see https://candisio.github.io/design-system/
 */
export const Refresh = ({
  fill = defaultTheme.colors.blue700,
  animated,
  ...props
}: SvgProps & { animated?: boolean }) => {
  return (
    <Wrapper
      {...props}
      fill={fill}
      width="14px"
      height="14px"
      viewBox="0 0 14 14">
      <g
        style={animated ? { animation: '2s linear infinite both a0_t' } : {}}
        transform="translate(8.00027,7.99995) translate(-5.57254,-5.99995)">
        <path
          d="M12.2849,2C12.0482,2,11.8563,2.19188,11.8563,2.42858L11.8563,3.99414C9.64984,1.85797,6.12947,1.91497,3.9933,4.1214C2.98658,5.16125,2.42496,6.55266,2.42774,7.99999C2.42774,8.23669,2.61962,8.42857,2.85632,8.42857C3.09303,8.42857,3.28488,8.23669,3.28488,7.99999C3.28541,5.39637,5.39646,3.28612,8.00011,3.28664C9.32241,3.28689,10.5838,3.84249,11.4766,4.81785L9.57804,5.45083C9.3532,5.52564,9.23153,5.76855,9.30634,5.9934C9.38114,6.21825,9.62405,6.33991,9.8489,6.2651L12.4203,5.40797C12.5959,5.34951,12.7141,5.18498,12.7135,4.99998L12.7135,2.42855C12.7135,2.19188,12.5216,2,12.2849,2Z"
          fill={fill}
          transform="translate(5.14289,3.21428) translate(-7.57062,-5.21428)"
        />
        <path
          d="M13.1443,7.57129C12.9076,7.57129,12.7157,7.76317,12.7157,7.99987C12.7152,10.6035,10.6041,12.7137,8.00048,12.7132C6.67818,12.713,5.4168,12.1574,4.52398,11.182L6.42255,10.549C6.64739,10.4742,6.76906,10.2313,6.69425,10.0065C6.61945,9.78161,6.37654,9.65995,6.15169,9.73475L3.58026,10.5919C3.40473,10.6503,3.28653,10.8149,3.28711,10.9999L3.28711,13.5713C3.28711,13.808,3.47899,13.9999,3.71569,13.9999C3.95239,13.9999,4.14427,13.808,4.14427,13.5713L4.14427,12.0057C6.35073,14.1419,9.87112,14.0849,12.0073,11.8785C13.014,10.8386,13.5756,9.4472,13.5728,7.99987C13.5729,7.76317,13.381,7.57129,13.1443,7.57129Z"
          fill={fill}
          transform="translate(6.00223,8.7856) translate(-8.42996,-10.7856)"
        />
      </g>
    </Wrapper>
  );
};
