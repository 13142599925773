import {
  DocumentCurrency,
  ReimbursementCase,
  ReimbursementCaseEdge,
} from 'generated-types/graphql.types';
import { ReimbursementTableRow } from '../toolkit/types';

export const mapToReimbursementsTableData = (
  reimbursementEdge: Omit<ReimbursementCaseEdge, 'node'> & {
    node: Pick<
      ReimbursementCase,
      | 'id'
      | 'totalAmount'
      | 'isPaid'
      | 'status'
      | 'paidAt'
      | 'createdAt'
      | 'title'
      | 'targetMembership'
    >;
  }
): ReimbursementTableRow => {
  const node = reimbursementEdge.node;

  return {
    // //To be implemented properly
    approver: undefined,
    grossAmount: {
      amount: node.totalAmount.amount,
      currency: node.totalAmount.currency as DocumentCurrency,
      precision: node.totalAmount.precision,
    },
    id: node.id,
    isPaid: 'isPaid' in node ? node.isPaid : false,
    status: node.status,
    cursor: reimbursementEdge.cursor,
    // To be implemented properly
    iban: undefined,
    createdAt: node.createdAt ? new Date(node.createdAt) : undefined,
    paidAt: 'paidAt' in node && node.paidAt ? new Date(node.paidAt) : undefined,
    title: node.title ?? undefined,
    requesterOfTheReimbursement: {
      avatarUrl: node.targetMembership?.avatarUrl ?? undefined,
      firstName: node.targetMembership?.firstName,
      lastName: node.targetMembership?.lastName,
      membershipId: node.targetMembership?.id,
    },
  };
};
