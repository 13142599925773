import { Grid } from '@candisio/design-system';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { useExportContext } from 'views/Integrations/Export/Context';
import { SapB1 } from 'views/Integrations/Export/elements/sidebar/Init/api/sap-b1/SapB1';
import { SelectExportType } from 'views/Integrations/Export/toolkit/components/SelectExportType';
import { ExportTypeSelector } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/ExportTypeSelector';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { ExportType } from 'views/Integrations/Export/types';
import { BDS } from './api/bds/BDS';
import { RDS } from './api/rds/RDS';
import { Addison } from './file/addison/Addison';
import { CandisCsv } from './file/candis-csv/CandisCsv';
import { DatevCsv } from './file/datev-csv/DatevCsv';
import { DatevXml } from './file/datev-xml/DatevXml';
import { SapCsv } from './file/sap-csv/SapCsv';
import { Zip } from './file/zip/Zip';

const ExportTypeComponent = ({ exportType }: { exportType: ExportType }) => {
  if (exportType === ExportType.DATEV) {
    return <RDS />;
  }

  if (exportType === ExportType.SAP_B1) {
    return <SapB1 />;
  }

  if (exportType === ExportType.DATEV_BDS) {
    return <BDS />;
  }

  if (exportType === ExportType.ADDISON_FORMAT) {
    return <Addison />;
  }

  if (exportType === ExportType.ZIP) {
    return <Zip />;
  }

  if (exportType === ExportType.CANDIS_CSV) {
    return <CandisCsv />;
  }

  if (exportType === ExportType.DATEV_CSV) {
    return <DatevCsv />;
  }

  if (exportType === ExportType.DATEV_DXSO_ZIP) {
    return <DatevXml />;
  }

  if (exportType === ExportType.SAP_CSV) {
    return <SapCsv />;
  }

  return null;
};

export const InitNewExportWithoutCandisAPI = () => {
  const [t] = useTranslation();
  const state = useExportContext();
  const {
    exportDetails,
    isLoading: { loadingExportById },
  } = useExportDataGigaFactory(state);

  const {
    availableExportTypes,
    exportType,
    isReExport,
    timeframe,
    setSelectedProvisions,
  } = state;

  useEffect(() => {
    setSelectedProvisions([]);
  }, [timeframe, setSelectedProvisions]);

  const heading =
    isReExport && exportDetails
      ? t('export.exportForm.exportAgainTitle', {
          date: DateFormatters.compact(exportDetails.createdAt),
        })
      : t('export.exportForm.newExport');

  return (
    <Sidebar isLoading={loadingExportById} heading={heading}>
      {exportType === null ? (
        <SelectExportType exportTypeOptions={availableExportTypes} />
      ) : (
        <>
          <ExportTypeSelector exportTypeOptions={availableExportTypes} />
          <Grid height="100%" paddingTop="space24" gap="space24">
            <ExportTypeComponent exportType={exportType} />
          </Grid>
        </>
      )}
    </Sidebar>
  );
};
