import { Box } from '@candisio/design-system';
import { HookFormRadioGroupField } from 'components/HookFormFields/HookFormRadioGroupField';
import {
  CardType,
  CardIssuerCardReplacementReason,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface ReplaceReasonRadioGroupProps {
  cardType: CardType;
}

const radioButtons: Record<
  CardIssuerCardReplacementReason,
  { id: CardIssuerCardReplacementReason; label: string }
> = {
  DAMAGED: {
    id: CardIssuerCardReplacementReason.Damaged,
    label: 'creditCardFormDataPreview.replaceCard.replacementReason.damaged',
  },
  EXPIRING_SOON: {
    id: CardIssuerCardReplacementReason.ExpiringSoon,
    label:
      'creditCardFormDataPreview.replaceCard.replacementReason.expiringSoon',
  },
  LOST: {
    id: CardIssuerCardReplacementReason.Lost,
    label: 'creditCardFormDataPreview.replaceCard.replacementReason.lost',
  },
  OTHER: {
    id: CardIssuerCardReplacementReason.Other,
    label: 'creditCardFormDataPreview.replaceCard.replacementReason.other',
  },
  STOLEN: {
    id: CardIssuerCardReplacementReason.Stolen,
    label: 'creditCardFormDataPreview.replaceCard.replacementReason.stolen',
  },
};

const compromisedRadioButton = {
  id: CardIssuerCardReplacementReason.Stolen,
  label: 'creditCardFormDataPreview.replaceCard.replacementReason.compromised',
};

export const mappedRadioButtonsToCardType: Record<
  CardType,
  undefined | Array<{ id: CardIssuerCardReplacementReason; label: string }>
> = {
  BLACK: undefined,
  PHYSICAL: [
    radioButtons.STOLEN,
    radioButtons.LOST,
    radioButtons.DAMAGED,
    radioButtons.EXPIRING_SOON,
    radioButtons.OTHER,
  ],
  VIRTUAL: [
    compromisedRadioButton,
    radioButtons.EXPIRING_SOON,
    radioButtons.OTHER,
  ],
  SINGLE_USE: undefined,
};

export const ReplaceReasonRadioGroup = ({
  cardType,
}: ReplaceReasonRadioGroupProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const radioButtons = mappedRadioButtonsToCardType[cardType];

  return (
    <Box fontSize="basic">
      <HookFormRadioGroupField
        name="replaceReason"
        options={(radioButtons ?? []).map(radioButton => ({
          value: radioButton.id,
          children: t(radioButton.label),
        }))}
      />
    </Box>
  );
};
