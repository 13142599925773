import {
  Box,
  Button,
  Grid,
  Icon,
  Text,
  TruncatedText,
  useId,
} from '@candisio/design-system';
import { obfuscatePhoneNumber } from 'components/HookFormFields/HookFormPhoneNumberField/formatPhoneNumber';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { AnimatePresence, AnimationProps, motion } from 'framer-motion';
import {
  usePendingPhoneNumberVerificationQuery,
  useRequestPhoneNumberVerificationMutation,
  useCancelPendingPhoneNumberVerificationMutation,
  useResendPendingPhoneNumberVerificationMutation,
  useVerifyPhoneNumberMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom-v5-compat';
import { OtpForm } from './OtpForm';
import { PhoneNumberForm, PhoneNumberFormProps } from './PhoneNumberForm';
import { usePhoneNumberCardState } from './usePhoneNumberCardState';

export interface PhoneNumberCardProps {
  isPhoneRemovalAllowed: PhoneNumberFormProps['isPhoneRemovalAllowed'];
  currentPhoneNumber?: PhoneNumberFormProps['currentPhoneNumber'];
}

const animationProps: AnimationProps = {
  initial: { opacity: 0, height: '0' },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: '0' },
  transition: { duration: 0.25, ease: 'easeInOut' },
};

export const phoneNumberCardId = 'phoneNumber';
export const scrollToTargetParam: 'scrollToTarget' = 'scrollToTarget';

export const PhoneNumberCard = ({
  isPhoneRemovalAllowed,
  currentPhoneNumber,
}: PhoneNumberCardProps) => {
  const { search } = useLocation();
  const { success } = useToastMessage();

  const urlSearchParams = new URLSearchParams(search);

  const targetToScroll = urlSearchParams.get(scrollToTargetParam) ?? undefined;

  const isPhoneNumberFieldAlreadyVisible = Boolean(targetToScroll);
  const [requestPhoneVerification, { loading: submittingPhoneNumber }] =
    useRequestPhoneNumberVerificationMutation({
      refetchQueries: ['pendingPhoneNumberVerification'],
      awaitRefetchQueries: true,
    });

  const [cancelPendingPhoneVerification] =
    useCancelPendingPhoneNumberVerificationMutation({
      refetchQueries: ['currentUser', 'pendingPhoneNumberVerification'],
    });

  const [handleResendOtpCode] =
    useResendPendingPhoneNumberVerificationMutation();

  const [verifyPhoneNumber, { loading: submittingOtpCode }] =
    useVerifyPhoneNumberMutation({
      refetchQueries: ['currentUser', 'pendingPhoneNumberVerification'],
      awaitRefetchQueries: true,
    });

  const [t] = useTranslation();
  const phoneNumberFormId = useId();
  const otpFormId = useId();

  const { mode, justifyButtons, primaryButton, secondaryButton, setMode } =
    usePhoneNumberCardState({
      currentPhoneNumber,
      otpFormId,
      phoneNumberFormId,
      isPhoneNumberFieldAlreadyVisible,
      onCancelPendingPhoneVerification: cancelPendingPhoneVerification,
    });

  const { data: pendingPhoneVerificationData } =
    usePendingPhoneNumberVerificationQuery({
      onCompleted: ({ pendingPhoneNumberVerification }) => {
        if (pendingPhoneNumberVerification?.phoneNumber) {
          setMode('otp-code-form');
        }
      },
    });

  const newPhoneNumber =
    pendingPhoneVerificationData?.pendingPhoneNumberVerification?.phoneNumber;

  const handleSubmitPhoneNumber = useCallback(
    async (phoneNumber?: string | null) => {
      if (phoneNumber) {
        await requestPhoneVerification({
          variables: {
            phoneNumber,
          },
        });

        setMode('otp-code-form');
      }
    },
    [requestPhoneVerification, setMode]
  );

  const handleSubmitOtpCode = async ({ otpCode }: { otpCode: string }) => {
    const { data } = await verifyPhoneNumber({
      variables: { otpCode },
    });

    const isVerified = Boolean(data?.verifyPhoneNumber.verified);

    if (isVerified) {
      success(t('header.profile.tabs.update.form.otpCode.success'));

      setMode('verified-phone');
    }

    return { isVerified };
  };

  return (
    <Box
      as="div"
      borderRadius="medium"
      padding="space16"
      background="gray100"
      id={phoneNumberCardId}>
      <Grid gap="space16">
        <Text fontSize="basic" fontWeight="semibold">
          {t(
            'header.profile.tabs.update.accountSecurity.phoneNumberMfaCard.title'
          )}
        </Text>

        <AnimatePresence>
          {mode === 'phone-number-form' && (
            <motion.div {...animationProps}>
              <PhoneNumberForm
                formId={phoneNumberFormId}
                isPhoneRemovalAllowed={isPhoneRemovalAllowed}
                onSubmit={handleSubmitPhoneNumber}
                currentPhoneNumber={currentPhoneNumber}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {mode === 'otp-code-form' && newPhoneNumber && (
            <motion.div {...animationProps}>
              <OtpForm
                formId={otpFormId}
                newPhoneNumber={newPhoneNumber}
                onResendOtpCode={handleResendOtpCode}
                onSubmit={handleSubmitOtpCode}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {mode === 'verified-phone' && currentPhoneNumber && (
          <Grid
            fontSize="basic"
            gap="space8"
            alignItems="center"
            justifyContent="start"
            templateColumns="max-content auto max-content">
            <Icon
              alignSelf="center"
              size="space18"
              icon="checkCircle"
              color="green500"
            />
            <TruncatedText>
              {t(
                'header.profile.tabs.update.accountSecurity.phoneNumberMfaCard.verifiedPhoneNumber.description',
                { phoneNumber: obfuscatePhoneNumber(currentPhoneNumber) }
              )}
            </TruncatedText>
            <Button
              icon="edit"
              variant="secondary"
              color="blue"
              size="xsmall"
              onClick={() => setMode('phone-number-form')}>
              {t(
                'header.profile.tabs.update.accountSecurity.phoneNumberMfaCard.verifiedPhoneNumber.edit'
              )}
            </Button>
          </Grid>
        )}

        {(primaryButton.show || secondaryButton.show) && (
          <Grid
            gap="space16"
            justifyContent={justifyButtons ?? 'flex-end'}
            templateColumns="repeat(2, max-content)">
            {secondaryButton.show && (
              <Button
                key={`${mode}-secondary`}
                color={secondaryButton.color}
                icon={secondaryButton.icon}
                iconPosition="left"
                onClick={secondaryButton.handler}
                size={secondaryButton.size ?? 'small'}
                variant={secondaryButton.variant}
                width="max-content">
                {secondaryButton.text}
              </Button>
            )}

            {primaryButton.show && (
              <Button
                key={`${mode}-primary`}
                form={primaryButton.formId}
                color={primaryButton.color}
                icon={primaryButton.icon}
                iconPosition="left"
                loading={submittingPhoneNumber || submittingOtpCode}
                onClick={primaryButton.handler}
                size={primaryButton.size ?? 'small'}
                type={primaryButton.type}
                variant={primaryButton.variant}
                width="max-content">
                {primaryButton.text}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
