import { noop } from 'lodash';
import { createContext, useContext } from 'react';
import { FieldPath } from 'react-hook-form';
import { ProcessingFormFieldMetadata } from './ProcessingFormMetadataContext';
import { ProcessingFormValues } from './processingFormSchema';

export type ProcessingFormSetFieldMetadata = (
  path: FieldPath<ProcessingFormValues>,
  meta?: ProcessingFormFieldMetadata
) => void;

export const ProcessingFormSetMetadataContext =
  createContext<ProcessingFormSetFieldMetadata>(noop);

/** Returns function to set individual processing form field metadata */
export const useSetProcessingFormMetadata = () => {
  return useContext(ProcessingFormSetMetadataContext);
};
