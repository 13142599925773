import { Flex, Grid } from '@candisio/design-system';
import { CreditCardGLAForm } from './CreditCardGLAForm';
import { CreditCardIdForm } from './CreditCardIdForm';
import { CreditCardTransitAccountForm } from './CreditCardTransitAccountForm';

export const CreditCardExportingFormSap = () => {
  return (
    <Grid gap="space8">
      <Flex gap="space16" direction="row">
        <CreditCardIdForm />
        <CreditCardGLAForm />
      </Flex>
      <Flex gap="space16" direction="row">
        <CreditCardTransitAccountForm />
      </Flex>
    </Grid>
  );
};
