import { ErrorMessages } from 'utils/zodFormValidation';
import { samlFormSchema } from './samlFormSchema';

export const samlFormErrors: ErrorMessages<typeof samlFormSchema> = {
  alias: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  idpEntityId: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  entityId: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  singleSignOnServiceUrl: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  nameIDPolicyFormat: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  principalType: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  validateSignature: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  syncMode: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  signingCertificate: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  postBindingAuthnRequest: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
  postBindingResponse: {
    label: 'sso.saml.form.fieldsTypeValidationError',
  },
};
