import {
  GridProps,
  Radio,
  RadioGroup,
  RadioProps,
} from '@candisio/design-system';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

interface RadioGroupGridProps
  extends Omit<
    GridProps,
    | 'children'
    | 'defaultValue'
    | 'disabled'
    | 'name'
    | 'onBlur'
    | 'onChange'
    | 'onFocus'
    | 'ref'
  > {}

export interface HookFormRadioGroupFieldProps<TFormValues extends FieldValues>
  extends RadioGroupGridProps {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Options to exclude when field value is not set to the option */
  excludeOptions?: string[];
  /** Field label */
  label?: string;
  /** Radio button options */
  options: RadioProps[];
  /** Layout of the radio buttons */
  orientation?: 'horizontal' | 'vertical';
  /** Is field read only? */
  readOnly?: boolean;
  /** Is field disbaled? */
  disabled?: boolean;
  /** Show group label? */
  showGroupLabel?: boolean;
}

/**
 * Controlled radio group field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormRadioGroupField = <TFormValues extends FieldValues>({
  control,
  excludeOptions,
  label,
  name,
  options,
  orientation,
  readOnly,
  showGroupLabel = false,
  disabled,
  ...gridProps
}: HookFormRadioGroupFieldProps<TFormValues>) => {
  const { field, formState } = useController({ control, name });
  const { value, ...fieldProps } = field;
  const isReadOnly = formState.isSubmitting ?? readOnly;

  const filteredOptions = options.filter(
    option => !excludeOptions?.includes(option.value) || value === option.value
  );

  return (
    <RadioGroup
      aria-label={label}
      isReadOnly={isReadOnly}
      orientation={orientation}
      value={value}
      label={showGroupLabel ? label : undefined}
      isDisabled={disabled}
      {...gridProps}
      {...fieldProps}>
      {filteredOptions.map(({ value, isDisabled, children, ...option }) => (
        <Radio
          key={value}
          value={value}
          isDisabled={isDisabled}
          isReadOnly={isDisabled}
          {...option}>
          {children}
        </Radio>
      ))}
    </RadioGroup>
  );
};
