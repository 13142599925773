import { Button, defaultTheme, Grid } from '@candisio/design-system';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessingFormRemainingAmountErrorCard } from './ProcessingFormRemainingAmountErrorCard';

type SplitDocumentActionButtonCommonProps = {
  hasItems: boolean;
  readOnly?: boolean;
  onSplitDocument?: (index?: number) => void;
};
interface ProcessingFormSplitDocumentActionButtonProps
  extends SplitDocumentActionButtonCommonProps {
  currency: string;
  remainingAmount: number;
  isSapNetAmount?: boolean;
}

const commonButtonProps: Omit<ComponentProps<typeof Button>, 'as'> = {
  flexGrow: '1',
  style: {
    padding: '12px 1rem',
    borderRadius: '0',
    borderEndStartRadius: defaultTheme.radii.medium,
    borderEndEndRadius: defaultTheme.radii.medium,
  },
} as const;

const SplitDocumentActionButtonForSapNetAmount = ({
  hasItems,
  readOnly,
  onSplitDocument,
}: SplitDocumentActionButtonCommonProps) => {
  const [t] = useTranslation();
  if (readOnly) {
    return null;
  }

  return (
    <Grid background={hasItems ? 'gray50' : undefined}>
      <Button
        onClick={() => onSplitDocument?.()}
        size={hasItems ? 'small' : undefined}
        variant="tertiary"
        color="gray"
        icon="plus"
        iconPosition="left"
        {...(hasItems && commonButtonProps)}>
        {t(
          hasItems
            ? 'document.requestApproval.splitBookings.editSplitCTA'
            : 'document.requestApproval.splitBookings.splitButton'
        )}
      </Button>
    </Grid>
  );
};

export const ProcessingFormSplitDocumentActionButton = ({
  currency,
  hasItems,
  remainingAmount,
  readOnly,
  onSplitDocument,
  isSapNetAmount,
}: ProcessingFormSplitDocumentActionButtonProps) => {
  const [t] = useTranslation();
  const hasRemainingAmount = Boolean(remainingAmount);

  if (isSapNetAmount) {
    return (
      <SplitDocumentActionButtonForSapNetAmount
        hasItems={hasItems}
        readOnly={readOnly}
        onSplitDocument={onSplitDocument}
      />
    );
  }

  if (!hasRemainingAmount && !readOnly) {
    return (
      <Grid background={hasItems ? 'gray50' : undefined}>
        <Button
          icon="plus"
          iconPosition="left"
          variant="tertiary"
          color="gray"
          onClick={() => onSplitDocument?.()}
          {...(hasItems && { ...commonButtonProps })}>
          {t('document.requestApproval.splitBookings.splitButton')}
        </Button>
      </Grid>
    );
  }

  if (hasRemainingAmount) {
    return (
      <ProcessingFormRemainingAmountErrorCard
        currency={currency}
        readOnly={readOnly}
        remainingAmount={remainingAmount}
        onSplitDocument={onSplitDocument}
      />
    );
  }

  return null;
};
