import { getFiltersWithContact } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/utils';
import { ViewUsingPruchaseOrders } from 'components/PurchaseOrders/Table/types';
import { usePurchaseOrdersData } from 'components/PurchaseOrders/Table/usePurchaseOrdersData';
import { PurchaserOrderQueryField } from 'generated-types/graphql.types';
import { toPurchaseOrderNumberFieldItem } from 'views/Inbox/DocumentProcessing/toPurchaseOrderNumberFieldItem';

interface UsePurchaseOrderFieldItemsProps {
  documentAmount?: number | null;
  accountsPayableNumber?: string | null;
}

/** Fetches items for purchase order field */
export const usePurchaseOrderFieldItems = ({
  documentAmount,
  accountsPayableNumber,
}: UsePurchaseOrderFieldItemsProps) => {
  const filtersWithContact = getFiltersWithContact({
    accountsPayableNumber,
  });

  const {
    purchaseOrderList,
    hasMoreData,
    onLoadMore,
    handleDebounceSearch,
    isLoadingPurchaseOrderList,
  } = usePurchaseOrdersData({
    documentAmount,
    routeType: ViewUsingPruchaseOrders.ARCHIVE,
    filters: filtersWithContact,
    sortBy: [],
    queryFields: [PurchaserOrderQueryField.OrderNumber],
  });

  const items = purchaseOrderList.map(toPurchaseOrderNumberFieldItem);

  const fetchMoreItems = async () => {
    if (hasMoreData) {
      void onLoadMore();
    }
  };

  return {
    items,
    handleDebounceSearch,
    fetchMoreItems,
    isLoading: isLoadingPurchaseOrderList,
  };
};
