import { Button, Grid, Text, useTheme } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { zodResolver } from 'utils/zodFormValidation';
import { z } from 'zod';
import { usePageVisibility } from './usePageVisibility';
import { intersectionOptions } from './utils';

export interface ProtectedPdfProps {
  fileUrl?: string;
  onSuccess: (password: string) => void;
  changePageVisibility: (pageNumber: number, visibilityRatio: number) => void;
  id: string;
  protectedPageNumber: number;
}

export const A4_PAGE_HEIGHT = '842px';
export const ProtectedPdf = ({
  onSuccess,
  fileUrl,
  changePageVisibility,
  id,
  protectedPageNumber,
}: ProtectedPdfProps) => {
  const [t] = useTranslation();
  const { space } = useTheme();
  const form = useForm<ProtectedPdfFormOutput>({
    resolver: zodResolver({
      zodSchema: protectedPdfSchema,
      errorMessages: {
        password: {
          label: 'document.protectedPdf.passwordLabel',
        },
      },
    }),
  });

  const handleCheckPassword = async (password: string) => {
    try {
      const loadingTask = pdfjs.getDocument({ url: fileUrl, password });
      await loadingTask.promise;
      onSuccess(password);
    } catch (error) {
      form.setError('password', {
        message: t('document.protectedPdf.invalidPassword'),
      });
    }
  };

  const handleOnIntersecting = useCallback<IntersectionObserverCallback>(
    entries => {
      entries.forEach(entry => {
        changePageVisibility(protectedPageNumber, entry.intersectionRatio);
      });
    },
    [changePageVisibility, protectedPageNumber]
  );

  const { ref } = usePageVisibility({
    onIntersecting: handleOnIntersecting,
    options: intersectionOptions,
  });

  const handleOnSubmit = form.handleSubmit(async values => {
    await handleCheckPassword(values.password);
  });

  return (
    <Grid
      id={id}
      background="white"
      borderRadius={space.space16}
      placeContent="center"
      placeItems="center"
      height={A4_PAGE_HEIGHT}
      ref={ref}>
      <FormProvider {...form}>
        <Grid as="form" onSubmit={handleOnSubmit}>
          <Text>{t('document.protectedPdf.info')}</Text>
          <Grid autoFlow="column" placeItems="center">
            <HookFormTextField
              label={t('document.protectedPdf.passwordLabel')}
              name="password"
              inputProps={{
                type: 'password',
              }}
            />
            <Button size="small" type="submit">
              {t('document.protectedPdf.submit')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Grid>
  );
};

const protectedPdfSchema = z.object({
  password: z.string(),
});

export type ProtectedPdfFormOutput = z.infer<typeof protectedPdfSchema>;
export type ProtectedPdfFormValues = Partial<ProtectedPdfFormOutput>;
