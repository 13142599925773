import { useToastMessage } from 'components/Toast/useToastMessage';
import { AccountingInfoFormSchemaFormValues } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/accountingInfoFormSchema';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useAccountingInfoFormFieldOptions } from 'views/CreditCards/hooks/useAccountingInfoFormFieldOptions';
import { sanitizeAccountingData } from '../../utils/sanitizeAccountingData';
import { useUpdateAccountingInfo } from '../Hooks/useUpdateAccountingInfo';
import { useUpdateCardSettings } from '../Hooks/useUpdateCardSettings';
import { UpdateAccountingInfoLoading } from './UpdateAccountInfoLoading';
import { UpdateAccountingInfoForm } from './UpdateAccountingInfoForm';
import { useUpdateAccountingInfoFormDefaultValues } from './useUpdateAccountingInfoFormDefaultValues';

interface UpdateAccountingInfoContainerProps {
  cardId: string;
  cardStatus: CardStatus;
  hasAccountingData: boolean;
  onClose: () => void;
}

export const UpdateAccountingInfoContainer = ({
  cardId,
  cardStatus,
  hasAccountingData,
  onClose,
}: UpdateAccountingInfoContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();

  const { card, loading: loadingCard } = useGetCardById({ cardId });

  const { loading, updateAccountingInfo } = useUpdateAccountingInfo();

  const updateCardAction = useUpdateCardSettings();

  const { defaultValues } = useUpdateAccountingInfoFormDefaultValues({
    accountingData: card?.accountingData,
    noInvoiceNeeded: !card?.isInvoiceNeeded,
  });

  const fieldOptions = useAccountingInfoFormFieldOptions();

  const pendingRequest = card?.pendingRequest?.__typename;

  const hasPendingLimitRequest = pendingRequest === 'CardLimitChangeRequest';

  const isNewCardRequest =
    pendingRequest === 'PhysicalCardRequest' ||
    pendingRequest === 'SingleUseVirtualCardRequest' ||
    pendingRequest === 'VirtualCardRequest';

  const handleSubmit = async (
    formValues: AccountingInfoFormSchemaFormValues
  ) => {
    const accountingData = sanitizeAccountingData({
      formValues,
      initialValues: card?.accountingData,
    });

    const { status } = await updateAccountingInfo({
      cardId,
      accountingData,
    });

    const updateCard = await updateCardAction?.updateCardSettings({
      cardId,
      noInvoiceNeeded: formValues.noInvoiceNeeded ?? false,
    });

    if ([status, updateCard?.status].includes('error')) {
      error(t('creditCardFormDataPreview.errorMsg'));
    } else {
      success(t('creditCardFormDataPreview.successMsg'));
      onClose();
    }
  };

  if (loadingCard) {
    return <UpdateAccountingInfoLoading />;
  }

  const isSubmitting = loading || (updateCardAction?.loading ?? false);

  return (
    <UpdateAccountingInfoForm
      cardStatus={cardStatus}
      fieldOptions={fieldOptions}
      defaultValues={defaultValues}
      hasAccountingData={hasAccountingData}
      hasPendingLimitRequest={hasPendingLimitRequest}
      isNewCardRequest={isNewCardRequest}
      isSubmitting={isSubmitting}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
