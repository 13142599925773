import { useCardSubscription } from 'generated-types/graphql.types';

interface UseUpdateCreditCardStatusParams {
  cardId: string;
}

export const useSubscribeToCreditCard = (
  params?: UseUpdateCreditCardStatusParams
) => {
  const variables = params ? { input: params } : undefined;

  useCardSubscription({
    variables,
  });
};
