import { Flex, Separator, Text, Grid } from '@candisio/design-system';

export default function SectionSeparator({ header }: { header: string }) {
  return (
    <Flex alignItems="center" paddingTop="space4">
      <Grid paddingRight="space8">
        <Text
          width="max-content"
          fontSize="basic"
          fontWeight="semibold"
          lineHeight="space20">
          {header}
        </Text>
      </Grid>
      <Separator orientation="horizontal" />
    </Flex>
  );
}
