import { Button } from '@candisio/design-system';
import {
  NewSplitForPurchaseOrders,
  CustomBookingItem,
} from 'containers/SplitBookings/components/NewSplit/NewSplitForPurchaseOrders';
import { PurchaseOrderType } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface AddNewSplitProps {
  type?: PurchaseOrderType;
  onClick: (item?: CustomBookingItem) => void;
  isLoading?: boolean;
}

export const NewSplit = ({ type, onClick, isLoading }: AddNewSplitProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { shouldUseSapPurchaseOrder, shouldUsePackageFreight } = useSap();

  const isPurchaseOrderConnected =
    shouldUseSapPurchaseOrder && type !== undefined;

  if (isPurchaseOrderConnected && shouldUsePackageFreight) {
    return (
      <NewSplitForPurchaseOrders onClick={onClick} purchaseOrderType={type} />
    );
  }

  if (isPurchaseOrderConnected) {
    return null;
  }

  return (
    <Button
      onClick={() => onClick()}
      icon="plus"
      variant="secondary"
      disabled={isLoading}
      loading={isLoading}>
      {t('actions.addNewSplitButton')}
    </Button>
  );
};
