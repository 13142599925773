import { useCreateCustomRecurringPaymentVendorMutation } from 'generated-types/graphql.types';

export const useCreateCustomRecurringPaymentVendor = () => {
  const [createCustomRecurringPaymentVendor, { loading }] =
    useCreateCustomRecurringPaymentVendorMutation();

  const createCustomVendor = async ({ name }: { name: string }) => {
    const { data } = await createCustomRecurringPaymentVendor({
      variables: {
        input: {
          name,
        },
      },
    });

    if (data?.createCustomRecurringPaymentVendor.__typename === undefined) {
      return { status: 'error' };
    }

    if (
      data?.createCustomRecurringPaymentVendor.__typename ===
      'RecurringPaymentVendor'
    ) {
      const vendorId = data.createCustomRecurringPaymentVendor.id;

      return { status: 'success', vendorId };
    }
  };

  return { createCustomVendor, loading };
};
