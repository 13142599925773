import {
  InsightsWidgetReportingUnit,
  useInsightsPreferencesQuery,
} from 'generated-types/graphql.types';

export const useInsightsPreferences = () => {
  const { data, loading } = useInsightsPreferencesQuery();

  const unit =
    data?.insightsPreferences.unit ?? InsightsWidgetReportingUnit.Gross;

  return { preferences: { unit }, loading };
};
