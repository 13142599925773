import {
  Grid,
  Icon,
  Popover,
  ScrollBox,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { OrganizationsListView } from '../../Header/components/MultiOrganizationOverview/OrganizationsListView';
import {
  MotionOrganizationDetails,
  MotionOrganizationSwitcher,
  organizationDetailsVariants,
  organizationSwitcherVariants,
} from '../animations';

interface OrganizationSwitcherProps {
  isNavigationOpen: boolean;
  toggleSwitcher: () => void;
}

export const OrganizationSwitcher = ({
  isNavigationOpen,
  toggleSwitcher,
}: OrganizationSwitcherProps) => {
  const organization = useFullOrganization();
  const organizationName = organization?.name;
  const truncateAt = organizationName && organizationName.length / 2;

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'right' });

  return (
    <MotionOrganizationSwitcher
      {...triggerProps}
      ref={triggerRef}
      initial={false}
      variants={organizationSwitcherVariants}
      animate={isNavigationOpen ? 'open' : 'closed'}
      transition={{ duration: 0.3 }}
      onMouseEnter={toggleSwitcher}
      onMouseLeave={toggleSwitcher}
      alignItems="center"
      background={isOpen ? 'bluebg' : isNavigationOpen ? 'gray0' : 'gray200'}
      borderRadius="small"
      cursor="pointer"
      gap="space8"
      overflow="hidden"
      padding="space12"
      templateColumns="auto 1fr">
      <Grid
        alignContent="center"
        background="purple500"
        borderRadius="small"
        color="gray0"
        fontSize="xLarge"
        fontWeight="bold"
        height="40px"
        justifyContent="center"
        padding="space4"
        width="40px">
        {/* Place Holder */}
        PH
      </Grid>
      <MotionOrganizationDetails
        templateColumns="1fr auto"
        gap="space8"
        alignItems="center"
        opacity={0}
        variants={organizationDetailsVariants}
        animate={isNavigationOpen ? 'open' : 'closed'}>
        <Grid>
          <TruncatedText
            fontSize="basic"
            fontWeight="bold"
            color="gray800"
            charsAfterEllipsis={truncateAt ? truncateAt : undefined}>
            {organizationName ?? ''}
          </TruncatedText>
          <TruncatedText fontSize="small" fontWeight="regular" color="gray500">
            Description
          </TruncatedText>
        </Grid>
        <Icon icon="sortInactive" size="space32" />
      </MotionOrganizationDetails>
      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          paddingX="0"
          paddingY="0"
          width="440px">
          <ScrollBox maxHeight="inherit" padding="0">
            <OrganizationsListView handleCurrentOrganization={close} />
          </ScrollBox>
        </Popover>
      )}
    </MotionOrganizationSwitcher>
  );
};
