import { CostCenter } from 'generated-types/graphql.types';
import { CostCenterForm as CostCenterFormDS } from 'views/Settings/CostCenters/components/CostCenterForm/CostCenterForm';
import { CostCenterFormSkeleton } from 'views/Settings/CostCenters/components/CostCenterForm/CostCenterFormSkeleton';

type Props = {
  costCenter: CostCenter | undefined;
  onChange: (data: any) => Promise<any>;
  onStatusChange: (isArchived: boolean) => Promise<any>;
  updateStatusLoading: boolean;
  loading: boolean;
};

export const CostCenterChange = ({
  costCenter,
  onChange,
  onStatusChange,
  updateStatusLoading,
  loading,
}: Props) => {
  if (loading) {
    return <CostCenterFormSkeleton />;
  }

  return (
    <CostCenterFormDS
      onSubmit={onChange}
      costCenter={costCenter}
      onStatusChange={onStatusChange}
      updateStatusLoading={updateStatusLoading}
    />
  );
};
