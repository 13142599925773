import { TransactionsTableDataKeys } from 'components/Transactions/Table/types';
import moment from 'moment';
import { MonthSpentOverview } from 'views/CreditCards/hooks/useGetSpendOverviewInsights';

const DATE_FORMAT = 'DD.MM.YYYY';

export enum WidgetFilter {
  cardRefNum = 'cardRefNum',
  merchantName = 'merchantName',
  category = 'category',
}

export type PopoverFilters = {
  id: string;
  value: unknown;
};

export type UrlSearchParams = {
  cardRefNum?: TransactionsTableDataKeys;
  category?: TransactionsTableDataKeys;
  merchantName?: TransactionsTableDataKeys;
  transactionCreatedAt?: TransactionsTableDataKeys;
};

export const getDateRange = ({
  monthSpentOverview,
}: {
  monthSpentOverview: MonthSpentOverview;
}) => {
  const fullDate = monthSpentOverview.fullDate;
  const startDate = moment(fullDate).format(DATE_FORMAT);
  const endDate = moment(fullDate).endOf('month').format(DATE_FORMAT);

  const dateRange = `${startDate}-${endDate}`;

  return { dateRange };
};
