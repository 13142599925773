import { TransactionCategory } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useReadableTransactionCategory = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const category = useMemo(
    (): Record<TransactionCategory, string> => ({
      ADVERTISING_AND_MARKETING: t(
        'insights.filters.category.list.AdvertisingAndMarketing'
      ),
      COMPUTING_AND_SOFTWARE: t(
        'insights.filters.category.list.ComputingAndSoftware'
      ),
      EDUCATION_AND_TRAINING: t(
        'insights.filters.category.list.EducationAndTraining'
      ),
      ELECTRONICS_AND_IT_EQUIPMENT: t(
        'insights.filters.category.list.ElectronicsAndItEquipment'
      ),
      ENTERTAINMENT_AND_WELLNESS: t(
        'insights.filters.category.list.EntertainmentAndWellness'
      ),
      FOOD_AND_DRINKS: t('insights.filters.category.list.FoodAndDrinks'),
      GIFTS_AND_VOUCHERS: t('insights.filters.category.list.GiftsAndVouchers'),
      MATERIALS_AND_PACKAGING: t(
        'insights.filters.category.list.MaterialsAndPackaging'
      ),
      OFFICE_SUPPLIES_AND_EQUIPMENT: t(
        'insights.filters.category.list.OfficeSuppliesAndEquipment'
      ),
      OTHER: t('insights.filters.category.list.Other'),
      SERVICES: t('insights.filters.category.list.Services'),
      TRAVEL_AND_ACCOMMODATION: t(
        'insights.filters.category.list.TravelAndAccommodation'
      ),
    }),
    [t]
  );

  return (cat: TransactionCategory) => category[cat];
};
