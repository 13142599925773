import {
  ContractSubCategoriesSortOrder,
  ContractSubCategoriesSortInput,
  useContractSubCategoriesWithDocumentCountQuery,
  ContractSubCategory,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce, isNil } from 'lodash';
import { Key, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { staticContractSubCategories } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { ContractSubcategoriesSortBy } from '../ContractSubCategories';

export const mapSortToGql = (
  sortBy: ContractSubcategoriesSortBy
): ContractSubCategoriesSortInput => {
  if (sortBy === ContractSubcategoriesSortBy.mostUsed) {
    return {
      documentCount: ContractSubCategoriesSortOrder.Desc,
    };
  }

  if (sortBy === ContractSubcategoriesSortBy.newestFirst) {
    return {
      createdAt: ContractSubCategoriesSortOrder.Desc,
    };
  }

  return {
    name: ContractSubCategoriesSortOrder.Asc,
  };
};

type UseContractCustomSubCategoriesOptions = {
  search?: string;
};

export const useContractCustomSubCategoryData = ({
  search,
}: UseContractCustomSubCategoriesOptions) => {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();
  const [debounceSearchValue, setDebounceSearchValue] = useState(search);
  const [activeWithStatic, setActiveWithStatic] = useState<
    | (Pick<
        ContractSubCategory,
        'name' | 'description' | 'id' | 'documentCount'
      > & { isStatic?: boolean })[]
    | null
  >(null);

  const [sortBy, setSortBy] = useState(
    ContractSubcategoriesSortBy.alphabetically
  );

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME, {
      trailing: true,
    });
  }, []);

  const isArchived = searchParams.get('archived') === 'true';

  const { data: activeSubCategories, loading: loadingActiveSubCategories } =
    useContractSubCategoriesWithDocumentCountQuery({
      variables: {
        input: {
          includeDocumentCount: true,
          isActive: true,
          search: debounceSearchValue,
          sort: mapSortToGql(sortBy),
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const customSubcategories = data.contractSubCategories;
        const staticSubcategories = staticContractSubCategories
          .flatMap(({ id, translationKey }) => {
            const name = t(translationKey);
            const searchIncludedInName =
              isNil(debounceSearchValue) ||
              name.toLowerCase().includes(debounceSearchValue.toLowerCase());

            if (searchIncludedInName) {
              return [
                {
                  id,
                  name,
                  isStatic: true,
                  documentCount: undefined,
                },
              ];
            }

            return [];
          })
          .toSorted((a, b) => a.name.localeCompare(b.name));

        setActiveWithStatic([...customSubcategories, ...staticSubcategories]);
      },
    });

  const { data: archivedSubCategories, loading: loadingArchivedSubCategories } =
    useContractSubCategoriesWithDocumentCountQuery({
      variables: {
        input: {
          includeDocumentCount: true,
          isActive: false,
          search: debounceSearchValue,
          sort: mapSortToGql(sortBy),
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const contractSubCategoriesToBeDisplayed: typeof activeWithStatic =
    useMemo(() => {
      if (isArchived) return archivedSubCategories?.contractSubCategories ?? [];

      return activeWithStatic ?? [];
    }, [
      activeWithStatic,
      archivedSubCategories?.contractSubCategories,
      isArchived,
    ]);

  const countLoading =
    loadingActiveSubCategories || loadingArchivedSubCategories;

  const activeCount = isArchived
    ? (activeSubCategories?.contractSubCategories?.length ?? 0) +
      staticContractSubCategories.length
    : contractSubCategoriesToBeDisplayed.length;

  const archivedCount =
    archivedSubCategories?.contractSubCategories?.length ?? 0;

  const onSortBy = useCallback((key: Key) => {
    setSortBy(key as ContractSubcategoriesSortBy);
  }, []);

  return {
    activeCount,
    archivedCount,
    countLoading,
    handleDebounceSearch,
    contractSubCategories: contractSubCategoriesToBeDisplayed,
    sortBy,
    onSortBy,
  };
};
