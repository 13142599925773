import { useArchivePaymentConditionMutation } from 'generated-types/graphql.types';
import { paymentConditionsCountQuery, paymentConditionsQuery } from '../gql';

export interface UseArchivePaymentConditionOptions {
  id?: string;
}

type ArchiveStatus = { status?: 'success' | 'error' };

export interface UseArchivePaymentConditionReturn {
  archive: () => Promise<ArchiveStatus>;
  archiving: boolean;
}

/**
 * Returns a callback that archives a payment condition
 */
export const useArchivePaymentCondition = ({
  id,
}: UseArchivePaymentConditionOptions): UseArchivePaymentConditionReturn => {
  const [archivePaymentCondition, { loading: archiving }] =
    useArchivePaymentConditionMutation({
      refetchQueries: [
        {
          query: paymentConditionsQuery,
          variables: { filter: { isArchived: true } },
        },
        {
          query: paymentConditionsQuery,
          variables: { filter: { isArchived: false } },
        },
        {
          query: paymentConditionsCountQuery,
          variables: { filter: { isArchived: true } },
        },
        {
          query: paymentConditionsCountQuery,
          variables: { filter: { isArchived: false } },
        },
      ],
    });

  return {
    archive: async () => {
      if (id === undefined) {
        return { status: 'error' };
      }

      const { data } = await archivePaymentCondition({ variables: { id } });

      if (data?.archivePaymentCondition.__typename === 'PaymentCondition') {
        return { status: 'success' };
      }

      return { status: 'error' };
    },
    archiving,
  };
};
