import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { GoodsReceiptsTableData } from '../types';

export const TableHeader = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<GoodsReceiptsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <TruncatedText color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
      {t('goodsReceipts.columns.' + column?.id, {
        context: getTranslationContext(),
      })}
    </TruncatedText>
  );
};
