import { AvatarStack, Text } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { User } from 'generated-types/graphql.types';
import { isEmpty, isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

type Value =
  | Pick<User, 'avatarUrl' | 'name' | 'id'>
  | Pick<User, 'avatarUrl' | 'name' | 'id'>[]
  | undefined;

export const UserWithStatusCell = ({
  value,
}: CellProps<DocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  if (isNil(value) || (Array.isArray(value) && isEmpty(value))) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  if (Array.isArray(value)) {
    return (
      <AvatarStack size="small">
        {value.map((user, index) => (
          <AvatarWithStatusContainer
            img={user.avatarUrl ?? undefined}
            key={index}
            size="small"
            name={user.name}
            userId={user.id}
          />
        ))}
      </AvatarStack>
    );
  }

  return value?.name ? (
    <AvatarWithStatusContainer
      img={value.avatarUrl ?? undefined}
      name={value.name}
      size="small"
      userId={value.id}
    />
  ) : (
    <Text>{t('emptyCellPlaceholder')}</Text>
  );
};
