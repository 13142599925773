import { CustomEmptyStateProps } from '@candisio/design-system';
import {
  DocumentsTable as BaseTable,
  DocumentsTableProps as BaseTableProps,
} from 'components/DocumentsTable/DocumentsTable';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { ExportTableCustomEmptyState } from 'views/Integrations/Export/toolkit/components/EmptyState/components/TableCustomEmptyState';
import {
  TableFilters,
  useDefaultOnRowClick,
  useExportTableFilters,
} from '../../elements/table/utils';
import { useExportTableMaxHeight } from '../hooks/useExportTableMaxHeight';

export const defaultExportFilters: Array<TableFilters> = [
  'contact',
  'exportStatus',
  'exportMethod',
] as const;

export const DocumentsTable = ({
  data,
  availableFilters = defaultExportFilters,
  isLoading,
  onEndReached,
  ...rest
}: BaseTableProps & {
  data: Array<DocumentsTableData>;
  availableFilters?: Array<TableFilters>;
  onEndReached?: () => void;
}) => {
  const { filters, onFilter, sortBy, onSort } =
    useUrlBasedSortAndFilter<DocumentsTableData>({
      availableFilters: defaultExportFilters,
    });

  const { filterOptions } = useExportTableFilters(availableFilters, data);

  const onRowClick = useDefaultOnRowClick();

  const height = useExportTableMaxHeight(data.length);

  const isTableFiltered = (filters ?? []).length > 0;

  return (
    <BaseTable
      borderBottomRadius="none"
      borderTopRadius="none"
      height={height}
      data={data}
      defaultFilters={filters}
      onFilter={onFilter}
      defaultSortBy={sortBy}
      onSort={onSort}
      filterOptions={filterOptions}
      onRowClick={onRowClick}
      onEndReached={onEndReached}
      customEmptyState={({ resetFilters }: CustomEmptyStateProps) => (
        <ExportTableCustomEmptyState
          resetFilters={resetFilters}
          isTableFiltered={isTableFiltered}
        />
      )}
      {...rest}
    />
  );
};
