import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ContactsPaginationResult } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const contactsPaginationFieldPolicy: FieldPolicy<ContactsPaginationResult> = {
  merge: simplePaginationMergeWithoutDuplicates('ContactsPaginationResult'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      contactsPagination: contactsPaginationFieldPolicy,
    },
  },
};
