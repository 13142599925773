import {
  DateFilterInput,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { TRANSACTION_COLUMNS } from 'views/Inbox/Transactions/useTransactionListFilters';
import { InfoCard } from './InfoCard';

interface DeclinedTransactionsProps {
  value: number;
  lastWeekFilter: DateFilterInput;
}

export const DeclinedTransactions = ({
  value,
  lastWeekFilter,
}: DeclinedTransactionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  const handleClick = () => {
    const searchParams = new URLSearchParams();
    searchParams.append(
      'transactionCreatedAt',
      `${lastWeekFilter.dateFrom}-${lastWeekFilter.dateTo}`
    );
    searchParams.append(TRANSACTION_COLUMNS.status, TransactionStatus.Declined);

    const linkToDeclinedTransactions = `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`;

    navigate({
      pathname: linkToDeclinedTransactions,
      search: searchParams.toString(),
    });
  };

  return (
    <InfoCard
      value={value}
      text={t('insights.requestsOverview.declinedTransactions', {
        count: value,
      })}
      color="red500"
      icon="failCircle"
      onClick={handleClick}
    />
  );
};
