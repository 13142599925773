import { Button, Grid, Text } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreditCardSetupFormOutput } from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormSchema';

interface CreditCardRulesProps {
  teamAdmin: string | undefined;
  onClick: () => void;
}

export const CreditCardRules = ({
  teamAdmin,
  onClick,
}: CreditCardRulesProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { trigger } = useFormContext<CreditCardSetupFormOutput>();

  const handleClick = async () => {
    const areLimitFieldsValid = await trigger(['limit', 'oneTimeCardLimit']);

    if (!areLimitFieldsValid) {
      return;
    }

    onClick();
  };

  return (
    <Grid gap="space24" height="100%">
      <Grid gap="space24" height="fit-content">
        <Text fontSize="xxlarge">
          {t('teams.createTeam.creditCardSetup.form.heading')}
        </Text>
        <Grid gap="space12" width="70%">
          <Text fontSize="xlarge">
            {t('teams.createTeam.creditCardSetup.form.title', {
              teamAdmin,
            })}
          </Text>
          <Grid autoFlow="column" gap="space16" templateColumns="1fr 1fr">
            <HookFormAmountField
              autoFocus
              name="limit"
              label={t('teams.createTeam.creditCardSetup.form.limit.label')}
              placeholder={t(
                'teams.createTeam.creditCardSetup.form.limit.placeholder'
              )}
              minValue={0}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              currency="EUR"
            />
            <HookFormAmountField
              name="oneTimeCardLimit"
              label={t(
                'teams.createTeam.creditCardSetup.form.oneTimeCardLimit.label'
              )}
              placeholder={t(
                'teams.createTeam.creditCardSetup.form.oneTimeCardLimit.placeholder'
              )}
              minValue={0}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              currency="EUR"
            />
          </Grid>
          <Text fontSize="small">
            {t('teams.createTeam.creditCardSetup.form.footNote')}
          </Text>
        </Grid>
      </Grid>
      <Button justifySelf="end" alignSelf="end" onClick={handleClick}>
        {t('teams.createTeam.creditCardSetup.form.cta')}
      </Button>
    </Grid>
  );
};
