import { defaultTheme } from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { Timeline } from 'antd/es';
import { TimelineItemProps } from 'antd/es/timeline';
import { HTMLAttributes } from 'react';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { Collapse } from './Collapse';

const { Item: TimelineItem } = Timeline;

interface ItemProps extends TimelineItemProps, HTMLAttributes<HTMLDivElement> {
  isCollapsible?: boolean;
  opened?: string;
}

export const Item = styled(({ isCollapsible, ...rest }: ItemProps) => (
  <TimelineItem {...rest} />
))`
  width: 100%;

  &.ant-timeline-item {
    padding: 0.8125rem 0.3125rem !important;
    border-radius: 8px;
    background: transparent;
  }

  .ant-timeline-item-content {
    left: unset;
    margin-left: 4rem;
    padding: 0;
    top: unset;
  }

  .ant-timeline-item-tail {
    top: 2rem;
    height: calc(100% - 0.9rem);
    border-left: 1px dashed ${defaultTheme.colors.gray400};
    left: 2.22rem;
  }

  .ant-timeline-item-head {
    top: 1.4rem;
    left: 2.25rem;
    width: 1.125rem;
    height: 1.125rem;
    background-color: transparent;
    padding: 0;
  }

  // reset lib css
  &.ant-timeline-item-last > .ant-timeline-item-content {
    min-height: unset;
  }

  ${p =>
    p.isCollapsible &&
    `
      cursor: pointer;
      ${Collapse} {
        display: block;
        max-height: ${
          p.opened ? '100rem' : '0'
        }; /* css animations require a fixed value for max-height */
        overflow-y: hidden;
        transition: max-height .5s ${
          p.opened ? 'ease-in-out' : 'cubic-bezier(0, 1, 0, 1)'
        };
        &.inverted {
          max-height: ${p.opened ? '0' : '100rem'};
          transition: max-height .5s ${
            p.opened ? 'cubic-bezier(0, 1, 0, 1)' : 'ease-in-out'
          };
        }
      }
    `}
`;
