export const CCWalletTrackingEvents = {
  REQUEST_CARD_BTN_CLICKED: 'request_card_btn_clicked',

  APPROVE_CREDIT_CARD_REQUEST_BTN_CLICKED:
    'approve_credit_card_request_btn_clicked',

  CANCEL_CREDIT_CARD_REQUEST_BTN_CLICKED:
    'cancel_credit_card_request_btn_clicked',

  APPROVE_LIMIT_REQUEST_BTN_CLICKED: 'approve_limit_request_btn_clicked',

  CANCEL_LIMIT_REQUEST_BTN_CLICKED: 'cancel_limit_request_btn_clicked',

  TERMINATE_CARD_BTN_CLICKED: 'terminate_card_btn_clicked',

  AVAILABLE_LIMIT_PROGRESS_BAR_CLICKED: 'available_limit_progress_bar_clicked',
  AVAILABLE_PREFUND_LIMIT_PROGRESS_BAR_CLICKED:
    'available_prefund_limit_clicked',

  CHANGE_LIMIT_BTN_CLICKED: 'change_limit_btn_clicked',
  SEND_LIMIT_REQUEST_BTN_CLICKED: 'send_limit_request_btn_clicked',

  LOCK_UNLOCK_CARD_BTN_CLICKED: 'lock_unlock_card_btn_clicked',

  SHOW_TRANSACTIONS_BTN_CLICKED: 'show_transactions_btn_clicked',
  SHOW_CARD_NUMBER_BTN_CLICKED: 'show_card_number_clicked',

  ACTIVATE_CARD_BTN_CLICKED: 'activate_card_btn_clicked',

  CC_MANAGER_TABLE_FILTERS_APPLIED: 'cc_manager_table_filters_applied',
  CC_MANAGER_TABLE_SORTING_APPLIED: 'cc_manager_table_sorting_applied',
};
