import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

interface UseReimbursementResult {
  canUseReimbursement: boolean;
}

export const useReimbursement = (): UseReimbursementResult => {
  const entitlements = useEntitlementsFlag();
  const { ft, show } = useFeatureToggle();

  const [travelExpenseManagementFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.travelExpenseManagement,
  ]);

  if (entitlements === Entitlements.LEGACY) {
    return {
      canUseReimbursement: false,
    };
  }

  return {
    canUseReimbursement: show(ft.REIMBURSEMENT) && travelExpenseManagementFF,
  };
};
