import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { useContext, useMemo } from 'react';
import { ExportContext } from 'views/Integrations/Export/Context';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { isDatevExportType } from 'views/Integrations/Export/toolkit/utils';
import { ExportEntityRow } from 'views/Integrations/Export/types';

export const toDocumentTableData =
  (failedDocuments: { id: string; invoiceNumber: string }[]) =>
  (row: ExportEntityRow) => {
    const { currency } = row.amount;
    const { amount = 0, precision = 0 } = row?.netAmount ?? {};
    const normalizedAmount = amount / 10 ** precision;

    const exportErrorStatus = {
      exportError: {
        ...row.exportStatus?.exportError,
        failedDocuments,
      },
    };

    return {
      id: row.id,
      provisionStatus: row.provisionStatus,
      contact: row.contact,
      account: row.account,
      generalLedgerAccount: row.generalLedgerAccount,
      deliveryDate: row.deliveryDate,
      netAmount: { amount: normalizedAmount, currency },
      isDisabled: row.isDisabled,
      canBeSelectedTooltipText: row.disableReason,
      hasDocument: row.hasDocument,
      exportStatus: {
        ...row.exportStatus,
        ...(row.exportStatus?.exportError && exportErrorStatus),
      },
    };
  };

const defaultColumns: Array<DocumentTableColumnKeys> = [
  'provisionStatus',
  'contact',
  'account',
  'generalLedgerAccount',
  'deliveryDate',
  'netAmount',
] as const;

export const useGetColumns = () => {
  const state = useContext(ExportContext);
  const { exportId, isReExport } = state;
  const { exportDetails } = useExportDataGigaFactory(state);
  const exportType = exportDetails?.type;

  const hasExportStatus = !!exportId && !isReExport;

  const hasExportMethod =
    hasExportStatus && !!exportType && isDatevExportType(exportType);

  const columns = useMemo(
    () =>
      Array<DocumentTableColumnKeys | false>()
        .concat(
          hasExportStatus && 'exportStatus',
          hasExportMethod && 'exportMethod',
          defaultColumns
        )
        .filter(Boolean) as DocumentTableColumnKeys[],
    [hasExportStatus, hasExportMethod]
  );

  return columns;
};
