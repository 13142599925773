import { IntegrationName } from 'generated-types/graphql.types';
import { z } from 'zod';
import { nonEmptyString } from './nonEmptyString';
import { preprocessStringToNumber } from './preprocessStringToNumber';

export const MAX_BOOKING_ACCOUNT_NUMBER = 99999999;

export const datevAccountCodeSchema = (
  maxNumber = MAX_BOOKING_ACCOUNT_NUMBER
) =>
  z.preprocess(
    preprocessStringToNumber,
    z.number().int().min(1).max(maxNumber).transform(String)
  );

export const datevAdjacentAccountCodeSchema = z
  .string()
  .trim()
  .min(1)
  .max(8)
  .regex(/^\d{1,8}$/);

export const sapAccountCodeSchema = z.string().trim().min(1).max(15);

const otherAccountCodeSchema = nonEmptyString;

interface AccountCodeSchemaOptions {
  maxNumber?: number;
  validateBasedOffIntegrationFF: boolean;
}

/** Validates a `BookingAccount.accountCode` */
export const accountCodeSchema = ({
  maxNumber = MAX_BOOKING_ACCOUNT_NUMBER,
  validateBasedOffIntegrationFF,
}: AccountCodeSchemaOptions) => {
  if (!validateBasedOffIntegrationFF) {
    return z.object({ accountCode: datevAccountCodeSchema(maxNumber) });
  }

  return z.discriminatedUnion('integration', [
    z.object({
      integration: z.literal(IntegrationName.Datev),
      accountCode: datevAccountCodeSchema(maxNumber),
    }),
    z.object({
      integration: z.literal(IntegrationName.DatevAdjacent),
      accountCode: datevAdjacentAccountCodeSchema,
    }),
    z.object({
      integration: z.literal(IntegrationName.Sap),
      accountCode: sapAccountCodeSchema,
    }),
    z.object({
      integration: z.literal(IntegrationName.Other),
      accountCode: otherAccountCodeSchema,
    }),
  ]);
};
