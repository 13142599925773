import { Grid } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { WistiaModalLink } from 'components/WistiaModalLink/WistiaModalLink';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const Cards = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { bdsBought } = useDatev();
  const displayFeedbackCard = bdsBought;

  return (
    <Grid gap="space32" alignContent="start">
      <InfoPanel
        title={t('provisions.infoPanel.title')}
        sections={t('provisions.infoPanel.sections', { returnObjects: true })}
        externalLink={t('provisions.infoPanel.externalLink', {
          returnObjects: true,
        })}
        buttonTrigger={
          <WistiaModalLink
            wistiaId={t('provisions.videoModal.wistiaId')}
            isLink={true}>
            {t('provisions.videoModal.label')}
          </WistiaModalLink>
        }
      />

      {displayFeedbackCard && (
        <InfoPanel
          title={t('provisions.feedbackPanel.title')}
          sections={t('provisions.feedbackPanel.sections', {
            returnObjects: true,
          })}
          intercomLink={
            <IntercomLink translation={t('provisions.feedbackButton')} />
          }
        />
      )}
    </Grid>
  );
};
