import { Flex, Grid } from '@candisio/design-system';
import {
  LottieAnimation,
  noDocumentsInTable,
  noresultsData,
} from 'components/Lottie/Lottie';
import { ReactNode } from 'react';

export const TransactionsTableEmptyStateLayout = ({
  children,
  isTableFiltered,
}: {
  children: ReactNode;
  isTableFiltered: boolean;
}) => {
  return (
    <Grid
      color="gray800"
      paddingTop="6.5rem"
      paddingBottom="4.5rem"
      minHeight="100%"
      justifyContent="center"
      gap="space16"
      alignItems="center">
      <LottieAnimation
        options={{
          animationData: isTableFiltered ? noresultsData : noDocumentsInTable,
        }}
      />
      <Flex maxWidth="space256" alignItems="center" direction="column">
        {children}
      </Flex>
    </Grid>
  );
};
