import { Grid, Image, Link } from '@candisio/design-system';
import { isEmpty } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Team } from '../../toolkit/hooks/useGetTeams';
import teamImage from '../../toolkit/media/team-image.png';
import { TeamDetails } from '../List/TeamDetails';

interface TeamDetailsInfoCardProps {
  team: Team;
  additionalDetails?: ReactNode;
}

export const TeamDetailsInfoCard = ({
  team,
  additionalDetails,
}: TeamDetailsInfoCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const showTeamPreview = Object.values(team).some(value => !isEmpty(value));

  return (
    <Grid gap="space16" justifyContent="normal" alignSelf="end">
      <Image
        src={teamImage}
        width="260px"
        height="180px"
        alt="team-image"
        justifySelf="center"
        top={showTeamPreview ? 'space80' : 0}
        style={showTeamPreview ? { filter: 'blur(8px)' } : undefined}
      />
      {showTeamPreview && (
        <TeamDetails
          isCreateMode
          team={team}
          additionalDetails={additionalDetails}
        />
      )}
      <Link href="/nothing" fontSize="basic" justifySelf="center" external>
        {t('teams.createTeam.linkText')}
      </Link>
    </Grid>
  );
};
