import {
  Flex,
  Grid,
  Link,
  Paragraph,
  CustomEmptyStateProps,
} from '@candisio/design-system';
import {
  LottieAnimation,
  noresultsData,
  successData,
} from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ApprovalEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
}

export const ApprovalEmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: ApprovalEmptyStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const isFilteredAndEmpty = isTableEmpty && isTableFiltered;

  return (
    <Grid gap="space16">
      <LottieAnimation
        options={{
          animationData: isTableFiltered ? noresultsData : successData,
        }}
      />
      <Flex maxWidth="space256" alignItems="center" direction="column">
        {isFilteredAndEmpty ? (
          <>
            <Paragraph textAlign="center" whiteSpace="pre-wrap">
              {t('emptyState.content.filtered')}
            </Paragraph>
            <Link as="button" onClick={() => resetFilters()}>
              {t('emptyState.resetFilters')}
            </Link>
          </>
        ) : (
          <Paragraph
            textAlign="center"
            fontWeight="semibold"
            wordBreak="break-word">
            {t('emptyState.content.allDoneInbox')}
          </Paragraph>
        )}
      </Flex>
    </Grid>
  );
};
