import { Skeleton } from '@candisio/design-system';
import { AddTransactionsAction } from 'components/Transactions/AddTransactions/AddTransactionsModal';
import { TransactionDetailsCard } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCard';
import { Transaction } from 'components/Transactions/TransactionDetailsCard/utils';
import { ReactNode } from 'react';

interface TransactionSectionProps {
  documentId: string;
  loading: boolean;
  transaction?: Transaction;
  canAddTransaction: boolean;
  contextMenu?: ReactNode;
}

export const TransactionSection = ({
  documentId,
  loading,
  transaction,
  canAddTransaction,
  contextMenu,
}: TransactionSectionProps) => {
  if (loading) {
    return <Skeleton height="space80" width="100%" borderRadius="medium" />;
  }

  return (
    <>
      {canAddTransaction && <AddTransactionsAction documentId={documentId} />}
      {transaction && (
        <TransactionDetailsCard
          loading={loading}
          transaction={transaction}
          contextMenu={contextMenu}
        />
      )}
    </>
  );
};
