import { Grid, Item, Skeleton, TabItem } from '@candisio/design-system';
import { useInboxTransactionCount } from 'hooks/useCounterQueries';
import { useCountDocumentsProcessing } from 'hooks/useCounters';
import { AppRouteParams } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { InboxContractManagementEvents } from 'providers/AnalyticsProvider/events/ContractManagementEvents';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { TabsContainer } from 'views/Archive/components/ArchiveTabs';
import { tabViewToUrlMap } from 'views/Archive/components/utils';
import { useEcmSensitiveDocumentsCount } from '../EcmSensitiveDocuments/useGetEcmSensitiveDocumentsCount';
import { TabView } from '../models';
import { options } from './utils';

type TabConfigItem = TabItem & {
  isAvailable: boolean;
};

const TAB_EVENTS = {
  [TabView.INBOX_ECM_SENSITIVE_DOCUMENTS]:
    InboxContractManagementEvents.TAB_OPENED,
} as const;

export const InboxTabs = ({ activeTab }: { activeTab: TabView }) => {
  const [t] = useTranslation();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const organizationSlug = useOrganizationId();
  const creditCards = useCreditCardsSetup();
  const { canUseReimbursement } = useReimbursement();

  const { showEcmSensitiveDocuments, canAccessEcm } = useEcm();

  const { count: inboxDocumentCount } = useCountDocumentsProcessing(options);

  const documentCount = Boolean(inboxDocumentCount)
    ? inboxDocumentCount?.toLocaleString()
    : undefined;

  const { inboxTransactionCount } = useInboxTransactionCount();
  const transactionCount = Boolean(inboxTransactionCount)
    ? inboxTransactionCount.toLocaleString()
    : undefined;

  const { sensitiveDocumentsCount } = useEcmSensitiveDocumentsCount();
  const sensitiveDocumentsCountString = Boolean(sensitiveDocumentsCount)
    ? sensitiveDocumentsCount.toLocaleString()
    : undefined;

  const isConfigLoading = creditCards.isOnboardingStatusLoading;

  const tabConfigs: TabConfigItem[] = [
    {
      key: TabView.INBOX_DOCUMENTS,
      title: t('archive.tabs.documents'),
      badge: documentCount,
      badgeColor: 'darkGray',
      isAvailable: true,
    },
    {
      key: TabView.INBOX_TRANSACTIONS,
      title: t('archive.tabs.transactions'),
      isAvailable: !isConfigLoading,
      ...(creditCards.isAddonBought
        ? { badge: transactionCount, badgeColor: 'darkGray' }
        : {
            icon: 'arrowUpCircleFilled',
            iconColor: 'purple500',
            iconTooltip: t('tag.addOn'),
          }),
    },
    {
      key: TabView.INBOX_ECM_SENSITIVE_DOCUMENTS,
      title: t('archive.tabs.ecmSensitiveDocuments'),
      isAvailable: canAccessEcm,
      ...(showEcmSensitiveDocuments
        ? {
            badge: sensitiveDocumentsCountString,
            badgeColor: 'darkGray',
          }
        : {
            icon: 'arrowUpCircleFilled',
            iconColor: 'purple500',
            iconTooltip: t('tag.addOn'),
          }),
    },
    {
      key: TabView.INBOX_REIMBURSEMENTS,
      title: t('archive.tabs.reimbursements'),
      isAvailable: canUseReimbursement,
    },
  ];

  if (isConfigLoading) {
    return (
      <Grid paddingLeft="space32">
        <Skeleton height="46px" width="10rem" />
      </Grid>
    );
  }

  const handleSelectionChange = (value: Key) => {
    if (typeof value === 'bigint' || typeof value === 'number') return;

    const tab = value as TabView;
    if (tab in TAB_EVENTS) track(TAB_EVENTS[tab as keyof typeof TAB_EVENTS]);

    const url = tabViewToUrlMap[tab];

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${url}`,
      { organizationSlug }
    );

    navigate(pathname);
  };

  const tabs: TabItem[] = tabConfigs.filter(item => item.isAvailable);

  return (
    <TabsContainer
      items={tabs}
      onSelectionChange={handleSelectionChange}
      defaultSelectedKey={activeTab}>
      {item => (
        <Item key={item.key} title={item.title} textValue={item.title}>
          {item.children}
        </Item>
      )}
    </TabsContainer>
  );
};
