import { ApolloLink, FetchResult, Observable, Operation } from '@apollo/client';
import { print } from 'graphql';
import { Client, createClient } from 'graphql-sse';
import { AuthService } from 'services/AuthService';

interface SubscriptionOptions {
  endpoint: string;
}

export class SubscriptionLink extends ApolloLink {
  private readonly client: Client;

  constructor(options: SubscriptionOptions) {
    super();
    this.client = createClient({
      url: options.endpoint,
      headers: async () => {
        const token = await AuthService.authorizedToken();
        const urlParams = new URLSearchParams(window.location.pathname);
        const [organizationId] = Array.from(urlParams.keys())[0]
          .split('/')
          .filter(Boolean);

        return {
          'x-realm-name': organizationId,
          Authorization: `Bearer ${token}`,
          'Apollographql-Client-Name': import.meta.env
            .REACT_APP_APOLLO_CLIENT_NAME,
          'Apollographql-Client-Version': import.meta.env
            .REACT_APP_APOLLO_CLIENT_VERSION,
        };
      },
    });
  }

  request(operation: Operation): Observable<FetchResult> | null {
    return new Observable(sink => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}
