import {
  CostCenterInfiniteScrollPaginationQuery,
  CostCenterInfiniteScrollPaginationQueryVariables,
  CostCenterSortField,
  CostCenterTypes,
  SortDirection,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { costCenterInfiniteScrollPagination } from 'views/queries/cost-centers';

export interface UseCostCenterFieldOptionsParams {
  type: CostCenterTypes;
  skip?: boolean;
}

export const useCostCenterFieldOptions = ({
  type,
  skip,
}: UseCostCenterFieldOptionsParams) => {
  const computeVariables = useCallback(
    (
      inputString?: string
    ): CostCenterInfiniteScrollPaginationQueryVariables => {
      const [code, name] = (inputString ?? '').split(' - ');

      return {
        input: { limit: 20 },
        filters: {
          type: [type],
          isArchived: false,
        },
        queries: {
          // Workaround because the query returns wrong value if we filter directly by readable name
          // TG-2082: https://candis.atlassian.net/browse/TG-2082
          readableName: code || name,
        },
        sortBy: {
          direction: SortDirection.Asc,
          field: CostCenterSortField.Name,
        },
      };
    },
    [type]
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<
      CostCenterInfiniteScrollPaginationQuery,
      CostCenterInfiniteScrollPaginationQueryVariables
    >({
      options: {
        skip,
      },
      query: costCenterInfiniteScrollPagination,
      queryRootKey: 'costCenterPagination',
      computeVariables,
    });

  const records = data?.costCenterPagination.records ?? [];

  const items = records.map(cc => ({
    key: cc.id,
    textValue: cc.readableName,
    children: cc.readableName,
  }));

  return {
    loading,
    items,
    onEndReached: loadMore,
    onSearch: handleDebounceSearch,
  };
};
