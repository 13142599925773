import i18next from 'i18next';
import { isOlderThanDaysAgo } from 'utils/dateIsOlderThanDays';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';
import { MAX_DAYS_AGO } from '../constants';

export const schema = (t: i18next.TFunction) =>
  z.object({
    startDate: z
      .string()
      .refine(value => !isOlderThanDaysAgo(new Date(value), MAX_DAYS_AGO), {
        message: t('payments.activation.startDate.invalid'),
      }),
  });

export const errorMessages: ErrorMessages<ReturnType<typeof schema>> = {
  startDate: {
    label: 'payments.activation.startDate.label',
  },
};
