import { Flex, Image } from '@candisio/design-system';
import {
  ExportStatus,
  ExportType as GqlExportType,
  useExportDatevXmlMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDatev } from 'orgConfig/datev';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import { BdsSetupHintPanel } from 'views/Integrations/Export/toolkit/components/BdsSetupHint';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { InitExportSummary } from 'views/Integrations/Export/toolkit/containers/ExportableEntitiesSummary/Init';
import { InitExportTypeLabel } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/InitExportTypeLabel';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import datevXmlIcon from 'views/Integrations/Export/toolkit/images/icon-datev-xml.svg';
import { getRefetchExportsQueries } from 'views/Integrations/Export/toolkit/queries';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { DatevXmlExportButton } from './DatevXmlExportButton';

export const DatevXml = () => {
  const state = useExportContext();
  const { addExport } = useContext(ExportNotificationsContext);
  const counterQueries = useCounterQueries();
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const { bdsBoughtButNotConnected } = useDatev(); // BDS-checked

  const { exportType, exportId } = state;

  const {
    exportEntities: {
      ready: { all: allEntities },
    },
    exportEntitiesIds,
    exportAll,
    readyToExportEntitiesCountByType,
    onlyUnsupportedDatevDocumentsAvailable,
  } = useExportDataGigaFactory(state);

  const { documentIds, transactionIds, cardSettlementIds } = exportEntitiesIds;

  const hasExportableEntity =
    exportAll ||
    !!documentIds.length ||
    !!transactionIds.length ||
    !!cardSettlementIds.length;

  const [exportDatevXml, { loading: loadingExportDatevXml }] =
    useExportDatevXmlMutation({
      refetchQueries: [
        ...getRefetchExportsQueries({ documentIds }),
        ...counterQueries,
      ],
      awaitRefetchQueries: true,
    });

  const onExport = async () => {
    const { data: exportDatevXmlResp } = await exportDatevXml({
      variables: {
        documentIds: exportAll ? [] : documentIds,
        transactionIds: exportAll ? [] : transactionIds,
        cardSettlementIds: exportAll ? [] : cardSettlementIds,
        originalExportGuid: exportId,
        exportAll,
      },
    });

    const createdExportId = exportDatevXmlResp?.exportDatevXml;

    if (!createdExportId) return;

    addExport({
      hash: createdExportId,
      organization: orgId ?? '',
      status: ExportStatus.Exporting,
      documents: allEntities.length,
      type: GqlExportType.DatevDxsoZip,
    });

    const viewDef = defineExportView(
      {
        ...state,
        sidebar: Sidebar.HISTORY,
        view: View.HISTORY,
        exportId: createdExportId,
        shouldTriggerDownload: true,
      },
      { orgId }
    );

    navigate(viewDef.navigate);
  };

  return (
    <Flex direction="column" justifyContent="space-between">
      <Flex direction="column" gap="space24">
        <ExportInfo>
          <Image
            src={datevXmlIcon}
            alt={exportType ?? ''}
            height="84px"
            width="84px"
            alignSelf="center"
          />
          <InitExportTypeLabel />
        </ExportInfo>
        <InitExportSummary
          readyToExportEntitiesCount={readyToExportEntitiesCountByType}
        />
        {bdsBoughtButNotConnected && <BdsSetupHintPanel />}
      </Flex>
      <DatevXmlExportButton
        onlyUnsupportedDatevDocumentsAvailable={
          onlyUnsupportedDatevDocumentsAvailable
        }
        hasExportableEntity={hasExportableEntity}
        onExport={onExport}
        loading={loadingExportDatevXml}
      />
    </Flex>
  );
};
