import { isNil } from 'lodash';
import { i18n } from 'providers/LocaleProvider';
import { toBasicMonetaryUnit } from 'utils/format';

export const formatFromCents = (
  currency: string | null | undefined,
  amount: number | null | undefined,
  locale?: string
) => {
  if (isNil(amount) || !currency) {
    return null;
  }

  const currencyDefinition = new Intl.NumberFormat(locale || i18n.language, {
    minimumFractionDigits: 0,
    currency,
    style: 'currency',
  });

  amount = toBasicMonetaryUnit(currencyDefinition, amount);

  return amount;
};
