import gql from 'graphql-tag';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';

export const GetEcmDocumentsQuery = gql`
  query GetEcmDocuments(
    $next: String
    $limit: Int
    $search: String
    $sort: EcmSortInput
    $filter: EcmFilterInput
  ) {
    getEcmDocuments(
      next: $next
      limit: $limit
      search: $search
      sort: $sort
      filter: $filter
    ) {
      edges {
        cursor
        record {
          id
          contactId
          contactName
          documentDate
          documentName
          documentNumber
          documentStatus
          documentType
          invoiceId
          isSensitive
          notes
          responsiblePerson {
            primary {
              id
              name
              avatarUrl
            }
          }
          tags {
            ...Tag
          }
        }
      }
      pageInfo {
        hasNextPage
        nextPageCursor
        totalCount
      }
    }
  }
  ${tagFragment}
`;
