import {
  FieldContainer,
  FieldContainerProps,
  IconProps,
  Input as TextInput,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { identity, isEmpty } from 'lodash';
import { ComponentProps, FocusEventHandler, ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
export interface HookFormTextFieldProps<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Custom props for input */
  inputProps?: ComponentProps<typeof TextInput>;
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean;
  /** Should field receive focus on mount? */
  autoFocus?: boolean;
  /** Field label */
  label?: string | ReactNode;
  /** Field label in case label is not just text */
  ariaLabel?: string;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** FieldContainer background color */
  color?: FieldContainerProps['color'];
  /** FieldContainer layout */
  layout?: FieldContainerProps['layout'];
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Field description */
  description?: FieldContainerProps['description'];
  /** Called when field value changes */
  onChange?: (newValue: string | null) => void;
  onFocus?: () => void;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Max number of chars inside the field */
  maxLength?: number;
  /** Optional hint shown next to the label */
  optionalHint?: string;
  /** Transform functions for field input (`value`) and output (`onChange`) */
  transform?: {
    input: (value: string | null) => string | null;
    output: (value: string | null) => string | null;
  };
  width?: string;
  clearable?: boolean;
  ignorePasswordManager?: boolean;
  infoTooltip?: {
    message: ReactNode;
    iconProps?: IconProps;
  };
}

/**
 * Controlled text field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormTextField = <TFormValues extends FieldValues>({
  autoFocus,
  control,
  disabled,
  label,
  ariaLabel,
  message,
  name,
  onChange: onChangeProp,
  onFocus: onFocusProp,
  placeholder,
  readOnly: readOnlyProp,
  variant,
  color,
  inputProps,
  layout,
  maxLength,
  optionalHint,
  transform = { input: identity, output: identity },
  width,
  isLoading,
  description,
  clearable = true,
  ignorePasswordManager,
  infoTooltip,
}: HookFormTextFieldProps<TFormValues>) => {
  const [t] = useTranslation();
  const { field, fieldState, formState } = useController({ control, name });
  const { onChange, value, ...fieldProps } = field;

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': ariaLabel ?? labelInput,
  });

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;
  const readOnly = readOnlyProp || formState.isSubmitting;

  const handleFocus: FocusEventHandler<HTMLInputElement> = event => {
    event.target.select();
    onFocusProp?.();
  };

  return (
    <FieldContainer
      disabled={disabled}
      label={label}
      aria-label={ariaLabel}
      layout={layout}
      readOnly={readOnly}
      optionalHint={optionalHint}
      width={width}
      color={color}
      variant={hasError ? 'error' : variant}
      description={description}
      isLoading={isLoading}
      infoTooltip={infoTooltip}
      {...(labelProps as Omit<FieldContainerProps, 'color'>)}>
      <TextInput
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={e => {
          const newValue =
            e.target.value !== '' ? transform.output(e.target.value) : null;

          onChange(newValue);
          onChangeProp?.(newValue);
        }}
        placeholder={readOnly ? '–' : placeholder}
        readOnly={readOnly}
        maxLength={maxLength}
        value={transform.input(value) ?? ''}
        variant={hasError ? 'error' : variant}
        message={errorMessage ?? message}
        clearable={clearable && !isEmpty(value)}
        clearLabel={t('common.clear')}
        onClear={() => {
          onChange(null);
          onChangeProp?.(null);
        }}
        onFocus={handleFocus}
        showMessageOnFocus={hasError || variant === 'error'}
        ignorePasswordManager={ignorePasswordManager}
        {...(inputProps
          ? mergeProps(fieldProps, inputProps, labelFieldProps)
          : fieldProps)}
      />
    </FieldContainer>
  );
};
