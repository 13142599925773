import { useTheme } from '@candisio/design-system';
import { useCountPayableDocumentsQuery } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useTranslation } from 'react-i18next';
import { MAX_PAYABLE_DOCUMENT_COUNT } from 'views/consts';
import paymentsIcon from '../../images/payments-icon.svg';
import { MenuItemCount, MenuItemIcon } from '../../style';

const getPayableDocumentCount = (count: number): number => {
  return Math.min(count, MAX_PAYABLE_DOCUMENT_COUNT);
};

export const Payments = () => {
  const { isAdmin, isAccountant } = useUserRoles();
  const [t] = useTranslation();
  const { fontSizes } = useTheme();
  const {
    data: {
      countPayableDocuments: { count: payableDocsCount = null } = {},
    } = {},
    loading,
  } = useCountPayableDocumentsQuery({
    skip: !(isAdmin || isAccountant),
    fetchPolicy: 'cache-first',
  });

  const count = payableDocsCount
    ? getPayableDocumentCount(payableDocsCount)
    : null;

  const isCountHidden = loading || !Boolean(count);

  return (
    <>
      <MenuItemCount
        aria-label="Payments-menu-item-count"
        style={{
          fontSize: isCountHidden ? 0 : fontSizes.xsmall,
          padding: isCountHidden ? 0 : '0.05rem 0.35rem',
        }}>
        {count}
      </MenuItemCount>
      <MenuItemIcon>
        <img src={paymentsIcon} alt={t('navigation.payments')} />
      </MenuItemIcon>
    </>
  );
};
