import { AppRouteParams, Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import {
  CREATE_TEAM_URL_PARAM,
  TEAM_ID_URL_PARAM,
} from 'views/Settings/Teams/toolkit/hooks/useModalVisibility';
import { useSetTeamSettingsForCreditCard } from 'views/Settings/Teams/toolkit/hooks/useSetTeamSettingsForCreditCard';
import { CreditCardSetupFormOutput } from 'views/Settings/Teams/toolkit/utils/creditCardSetupFormSchema';
import { SETTINGS_ROUTES } from 'views/Settings/types';
import { useGetTeamById } from '../../../toolkit/hooks/useGetTeamById';
import { TeamCreditCardSetupModal } from './TeamCreditCardSetupModal';

interface TeamCreditCardSetupModalContainerProps {
  isOpen: boolean;
}

export const TeamCreditCardSetupModalContainer = ({
  isOpen,
}: TeamCreditCardSetupModalContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const { team } = useGetTeamById({ id });
  const { setTeamCreditCardSettings, teamCCSettingsPending } =
    useSetTeamSettingsForCreditCard();

  const handleClose = () => {
    navigate(`/${organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}`);
  };

  const handleSubmit = async (values: CreditCardSetupFormOutput) => {
    if (!id) {
      return;
    }

    const isSetupSuccessful = await setTeamCreditCardSettings(values);
    if (!isSetupSuccessful) {
      return;
    }

    const searchParams = new URLSearchParams();
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}/:id?`,
      { organizationSlug, id: CREATE_TEAM_URL_PARAM }
    );

    searchParams.set(TEAM_ID_URL_PARAM, id);
    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    // Passing a unique key forces the component to re-render,
    // ensuring the modal form is reset when it is reopened.
    <TeamCreditCardSetupModal
      key={`${isOpen}-${id}`}
      isOpen={isOpen}
      team={team}
      isSubmitting={teamCCSettingsPending}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};
