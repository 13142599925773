import { Box, Grid, Separator, Tag } from '@candisio/design-system';
import {
  HookFormChipsField,
  HookFormChipsFieldProps,
} from 'components/HookFormFields/HookFormTagsField/HookFormChipsField';
import { useUserRoles } from 'hooks/useUserRoles';
import { ComponentProps, ReactNode, useState } from 'react';
import { useFocusWithin } from 'react-aria';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentTagDetailsDrawer } from 'views/Settings/DocumentTags/elements/Drawer/DocumentTagDetailsDrawer';
import { AddNewTagCTA } from './AddNewTagCTA';
import { NoTagsAvailable } from './NoTagsAvailable';

export interface DocumentTagsFieldProps<TFormValues extends FieldValues>
  extends HookFormChipsFieldProps<TFormValues> {
  statusIndicator?: ReactNode;
  onFocusChange: (isFocused: boolean) => void;
  onTagCreated?: ComponentProps<
    typeof DocumentTagDetailsDrawer
  >['onTagCreated'];
}

export const DocumentTagsField = <TFormValues extends FieldValues>({
  statusIndicator,
  onFocusChange,
  onTagCreated,
  ...restProps
}: DocumentTagsFieldProps<TFormValues>) => {
  const [inputValue, setInputValue] = useState('');

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: onFocusChange,
  });

  const [t] = useTranslation();
  const { isAdmin } = useUserRoles();
  const [addNewTagDrawerOpen, setAddNewTagDrawerOpen] = useState(false);
  const availableTagsCount = restProps.defaultItems?.length ?? 0;

  const label = (
    <Grid
      gap="space4"
      autoFlow="column"
      alignItems="center"
      justifyContent="start">
      {t('documentTags.field.label')}
      {/* TODO: adjust status indicator spacings after Tag "NEW" is removed */}
      <Tag callout color="blue">
        {t('tag.new')}
      </Tag>
    </Grid>
  );

  const openDrawer = () => setAddNewTagDrawerOpen(true);

  let emptyList: ReactNode;
  if (availableTagsCount < 1) emptyList = <NoTagsAvailable />;
  if (isAdmin) emptyList = <AddNewTagCTA onClick={openDrawer} />;

  return (
    <Box position="relative" {...focusWithinProps}>
      <HookFormChipsField
        label={label}
        renderCustomDropdown={
          isAdmin
            ? list => (
                <Grid
                  templateRows="1fr auto"
                  maxHeight="inherit"
                  overflow="hidden">
                  <Box overflow="hidden">{list}</Box>
                  <Separator />
                  <AddNewTagCTA onClick={openDrawer} />
                </Grid>
              )
            : undefined
        }
        emptyListPlaceholder={emptyList}
        placeholder={t('common.select')}
        onInputChange={setInputValue}
        {...restProps}
      />
      {statusIndicator && (
        <Box
          position="absolute"
          right="0"
          /* TODO: adjust spacings after Tag "NEW" is removed */
          top="-space4"
          /* TODO: adjust spacings after Tag "NEW" is removed */
        >
          {statusIndicator}
        </Box>
      )}
      <DocumentTagDetailsDrawer
        compact
        isOpen={addNewTagDrawerOpen}
        onCloseDrawer={() => setAddNewTagDrawerOpen(false)}
        defaultName={inputValue}
        onTagCreated={onTagCreated}
      />
    </Box>
  );
};
