import {
  Button,
  Flex,
  Grid,
  Paragraph,
  TableBody,
  TableElement,
  TableRow,
  Tag,
} from '@candisio/design-system';
import { creditCardInfoHash } from 'containers/credit-cards/components/CreditCardsSection/utils';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardInfoModal } from 'views/Dashboard/components/CreditCardInfoModal';

export const CreditCardsTransactionsPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate({
      pathname: location.pathname,
      search: location.search,
      hash: `${creditCardInfoHash}`,
    });
  };

  const openModal = location.hash === creditCardInfoHash;

  const promotionData = [
    {
      contact: 'Google Ads',
      invoiceDate: '14/08/2023',
      grossAmount: '-4.167,18 €',
    },
    {
      contact: 'Indeed',
      invoiceDate: '14/08/2023',
      grossAmount: '-2.393,39 €',
    },
    {
      contact: 'AWS',
      invoiceDate: '14/08/2023',
      grossAmount: '-3.938,50 €',
    },
    {
      contact: 'Meta',
      invoiceDate: '14/08/2023',
      grossAmount: '-19.339,97 €',
    },
    {
      contact: 'LinkedIn',
      invoiceDate: '13/08/2023',
      grossAmount: '-2.404,34 €',
    },
  ];

  return (
    <Flex direction="column" gap="1rem" padding="1rem">
      <Grid gap="space16">
        <Grid>
          <Tag variant="secondary" color="purple">
            {t('common:tag.addOn')}
          </Tag>
          <Paragraph fontSize="xxlarge">
            {t('promo.creditCards.transactions.title')}
          </Paragraph>
          <Paragraph fontSize="xxlarge">
            {t('promo.creditCards.transactions.title2')}
          </Paragraph>
        </Grid>
        <TableElement>
          <TableBody>
            {promotionData.map((data, index) => (
              <TableRow key={index}>
                <TableDataRecord
                  colSpan={2}
                  customStyle={{ textAlign: 'left' }}>
                  {data.contact}
                </TableDataRecord>
                <TableDataRecord
                  customStyle={{
                    textShadow: '0 0 12px black',
                    color: 'transparent',
                    textAlign: 'right',
                  }}>
                  &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
                </TableDataRecord>
                <TableDataRecord>{data.invoiceDate}</TableDataRecord>
                <TableDataRecord>{data.grossAmount}</TableDataRecord>
              </TableRow>
            ))}
          </TableBody>
        </TableElement>
      </Grid>
      <Flex justifyContent="center">
        <Button
          color="purple"
          icon="creditCardOutline"
          width="fit-content"
          onClick={handleClick}>
          {t('promo.creditCards.transactions.buttonCta')}
        </Button>
      </Flex>

      <CreditCardInfoModal
        isOpen={openModal}
        onClose={() => {
          navigate(
            {
              pathname: location.pathname,
              search: location.search,
            },
            { replace: true }
          );
        }}
        routeType="Export"
      />
    </Flex>
  );
};

const TableDataRecord = ({
  children,
  customStyle,
  colSpan,
}: {
  children: ReactNode;
  customStyle?: CSSProperties;
  colSpan?: number;
}) => (
  <td
    style={{
      textAlign: 'right',
      ...customStyle,
    }}
    colSpan={colSpan}>
    {children}
  </td>
);
