import { useEffect } from 'react';

/**
 * Set a cookie to identify existing customers so that Marketing can exclude them from tracking
 */
export const useCandisAppCookie = (
  analyticsId: string,
  trackingEnabled: boolean
) => {
  useEffect(() => {
    const maxAge = trackingEnabled ? 60 * 60 * 24 * 60 : 0;

    document.cookie = `candis_app=${analyticsId};domain=.candis.io;path=/;max-age=${maxAge}`;
  }, [analyticsId, trackingEnabled]);
};
