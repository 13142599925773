import { TrackFunction } from '../AnalyticsProvider';

export const CreditCardsTrackingEvents = {
  REQUEST_VIRTUAL_CREDIT_CARD_CLICKED:
    'request_virtual_credit_card_button_clicked',

  CC_ADVERT_CARD_APPLY_BTN_CLICKED: (
    track: TrackFunction,
    target: 'dashboard' | 'inbox' | 'archive' | 'settings' | 'exports'
  ) => track('cc_advert_card_apply_btn_clicked', { target }),
  CC_ADVERT_CARD_MORE_INFO_BTN_CLICKED: (
    track: TrackFunction,
    target:
      | 'dashboard'
      | 'inbox'
      | 'archive'
      | 'settings'
      | 'promptDashboard'
      | 'exports'
  ) => track('cc_advert_card_more_info_btn_clicked', { target }),
  CC_ADVERT_CARD_BOOK_A_DEMO_BTN_CLICKED: (
    track: TrackFunction,
    target: 'dashboard' | 'inbox' | 'archive' | 'settings' | 'exports'
  ) => track('cc_advert_card_book_a_demo_btn_clicked', { target }),
  CC_HELP_CENTER_LINK_CARD_MANAGER_INFO_BOX_CLICKED: (track: TrackFunction) =>
    track('cc_help_center_link_wallet_info_box_clicked'),
  CC_HELP_CENTER_LINK_ADMIN_INFO_BOX_CLICKED: (track: TrackFunction) =>
    track('cc_help_center_link_wallet_info_box_clicked_admin'),

  MARKETING_BANNER: {
    FEATURE_FLAG_OFF: {
      CREDIT_CARD_INFO_CLICKED:
        'credit_cards_marketing_banner_feature_flag_off_info_button_clicked',
    },
  },
};
