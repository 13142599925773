// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export const FiltersPopoverContainer = styled.div`
  display: flex;
  max-width: 25rem;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
`;

export const FilterNameContainer = styled.div`
  margin: 0.2rem;
`;
