import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { ACCOUNTING_AREAS_TAB_KEYS } from 'views/Settings/AccountingAreas/AccountingAreas';
import { useAccountingAreasData } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasData';
import {
  AccountingAreaSortBy,
  queryAccountingAreas,
  sortAccountingAreas,
} from 'views/Settings/AccountingAreas/toolkit/utils/listUtils';

export interface AccountingAreasListData {
  id: string;
  name: string;
  description: string;
  createdAt: string;
}

export const useAccountingAreasList = () => {
  const [searchParams] = useSearchParams();

  const [t] = useTranslation();

  const navigate = useNavigate();

  const isArchived =
    searchParams.get(ACCOUNTING_AREAS_TAB_KEYS.archived) === 'true';

  const {
    activeAccountingAreas,
    archivedAccountingAreas,
    loadingActiveAccountingAreas,
    loadingArchivedAccountingAreas,
  } = useAccountingAreasData();

  const activeAccountingAreasListData =
    activeAccountingAreas?.accountingAreas.map(accountingArea => ({
      id: accountingArea.id,
      name: accountingArea.name,
      description: accountingArea.description,
      createdAt: accountingArea.createdAt,
    }));

  const archivedAccountingAreasListData =
    archivedAccountingAreas?.accountingAreas.map(accountingArea => ({
      id: accountingArea.id,
      name: accountingArea.name,
      description: accountingArea.description,
      createdAt: accountingArea.createdAt,
    }));

  const allAccountingAreasListData = [
    ...(activeAccountingAreasListData || []),
    ...(archivedAccountingAreasListData || []),
  ];

  const listDataLoading =
    loadingActiveAccountingAreas || loadingArchivedAccountingAreas;

  const onTabChanged = (key: Key) => {
    if (key === ACCOUNTING_AREAS_TAB_KEYS.archived) {
      searchParams.set(ACCOUNTING_AREAS_TAB_KEYS.archived, 'true');
    } else {
      searchParams.delete(ACCOUNTING_AREAS_TAB_KEYS.archived);
    }

    navigate({
      search: searchParams.toString(),
    });
  };

  const [queryString, setQueryString] = useState('');

  const organization = useOrganizationId();

  const [sortBy, setSortBy] = useState<AccountingAreaSortBy>(
    AccountingAreaSortBy.alphabetically
  );

  const sortItems = [
    {
      id: AccountingAreaSortBy.alphabetically,
      label: t('settings:accountingAreas.actions.sortBy.alphabetically'),
    },
    {
      id: AccountingAreaSortBy.lastAdded,
      label: t('settings:accountingAreas.actions.sortBy.lastAdded'),
    },
  ];

  const sortButtonText = sortItems.find(
    item => sortBy.toString() === item.id
  )?.label;

  const handleSortBy = (value: Key[]) => {
    setSortBy(value.length ? (value[0] as AccountingAreaSortBy) : sortBy);
  };

  const handleSearchReset = () => {
    setQueryString('');
  };

  const onSearchFilter = (filter: string) => {
    setQueryString(filter);
  };

  const handleCreateAccountingArea = () => {
    searchParams.delete(ACCOUNTING_AREAS_TAB_KEYS.archived);
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.ACCOUNTING_AREAS}/create`,
      search: searchParams.toString(),
    });
  };

  const onShowItem = (id: string) => {
    navigate({
      pathname: `/${organization}${Routes.SETTINGS}${Routes.ACCOUNTING_AREAS}/${id}`,
      search: searchParams.toString(),
    });
  };

  const queriedAccountingAreas = queryAccountingAreas({
    queryString,
    accountingAreas: isArchived
      ? archivedAccountingAreasListData
      : activeAccountingAreasListData,
  });

  const sortedAccountingAreas = sortAccountingAreas({
    sortBy: sortBy,
    accountingAreas: queriedAccountingAreas,
  });

  return {
    allAccountingAreasListData,
    activeAccountingAreasListData,
    archivedAccountingAreasListData,
    listDataLoading,
    onTabChanged,
    selectedKey: isArchived
      ? ACCOUNTING_AREAS_TAB_KEYS.archived
      : ACCOUNTING_AREAS_TAB_KEYS.active,
    queryString,
    onSearchFilter,
    handleSearchReset,
    handleCreateAccountingArea,
    sortedAccountingAreas,
    onShowItem,
    sortItems,
    sortBy,
    handleSortBy,
    sortButtonText,
  };
};
