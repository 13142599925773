import { FieldContainer, Flex, Textarea } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useApiAuthCode } from 'views/ApiAuthCodePage/hooks/useApiAuthCode';

export const ApiAuthCodeField = () => {
  const [t] = useTranslation();

  const {
    authCode,
    showError,
    handleFocus,
    errorDescription,
    error,
    showQueryStingError,
  } = useApiAuthCode();

  return (
    <Flex wordBreak="break-all" width="100%">
      <FieldContainer
        disabled
        label={t('common:apiAuthCodePage.fieldLabel')}
        width="100%"
        description={
          showQueryStingError
            ? {
                text: errorDescription ?? error ?? '',
                color: 'error',
              }
            : undefined
        }
        variant={showError ? 'error' : 'default'}>
        <Textarea
          onFocus={handleFocus}
          value={authCode ?? ''}
          showMessageOnFocus={showError}
        />
      </FieldContainer>
    </Flex>
  );
};
