import { Box, Link, Text } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import { nocontactData } from 'components/Lottie/Lottie';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';

interface BookingAccountsEmptyStateProps {
  handleCreate: () => void;
  hasArchivedData: boolean;
}

export const BookingAccountsEmptyState = ({
  handleCreate,
  hasArchivedData,
}: BookingAccountsEmptyStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { shouldUseSapGL } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  return (
    <EmptyState animationData={nocontactData}>
      {shouldUseSapGL ? (
        <Text>{t('table.nodata.sap')}</Text>
      ) : hasArchivedData ? (
        <>
          <Text>{t('table.nodata.noActiveRecords')}</Text>
          <Box>
            <Trans t={t} i18nKey="table.nodata.pleaseCreateOrReactivateCTA">
              Please
              <Link onClick={handleCreate}>create</Link>
              or
              <Link
                onClick={() => {
                  searchParams.set('archived', 'true');
                  navigate({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                  });
                }}>
                reactivate
              </Link>
            </Trans>
          </Box>
        </>
      ) : (
        <>
          <Text>{t('table.nodata.noActiveRecords')}</Text>
          {!shouldUseCoreDataApi && (
            <Box>
              <Trans t={t} i18nKey="table.nodata.pleaseCreateCTA">
                Please <Link onClick={handleCreate}>create</Link>
              </Trans>
            </Box>
          )}
        </>
      )}
    </EmptyState>
  );
};
