import { Grid } from '@candisio/design-system';
import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TransactionDownloadHistoryContainer } from './TransactionDownloadHistoryContainer';

export const TransactionDownloadHistoryView = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Grid height="100%" padding="space40 space32 space32 space32">
      <ImportHistoryLayout
        title={t('table.toolbar.historyViewTitle')}
        titleAs="h1"
        backButtonLabel={t('table.toolbar.backAction')}>
        <TransactionDownloadHistoryContainer />
      </ImportHistoryLayout>
    </Grid>
  );
};
