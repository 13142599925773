import { AccountingInfoFormSchemaFormValues } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/accountingInfoFormSchema';
import { CardAccountingData } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useGetContactById } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/hooks/useGetContactById';
import { useGetCostCenterById } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/hooks/useGetCostCenterById';
import { useGetBookingAccountById } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/hooks/useGetGeneralLedgerAccountById';

interface UpdateAccountingInfoFormDefaultValuesProps {
  accountingData?: CardAccountingData | null;
  noInvoiceNeeded?: boolean;
}

export const useUpdateAccountingInfoFormDefaultValues = ({
  accountingData,
  noInvoiceNeeded,
}: UpdateAccountingInfoFormDefaultValuesProps) => {
  const fullOrg = useFullOrganization();

  const artistSocialInsuranceAndExtraCostInfoFF = useCandisFeatureFlags(
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo
  );

  const { contact, contactLoading } = useGetContactById({
    id: accountingData?.contactId ?? undefined,
  });

  const { costCenter, costCenterLoading } = useGetCostCenterById({
    id: accountingData?.costCenterId ?? undefined,
  });

  const { costCenter: extraCostInfo, costCenterLoading: extraCostInfoLoading } =
    useGetCostCenterById({
      id: accountingData?.extraCostInfoId ?? undefined,
      skip:
        !artistSocialInsuranceAndExtraCostInfoFF || !fullOrg?.hasExtraCostInfos,
    });

  const { costCenter: costObject, costCenterLoading: costObjectLoading } =
    useGetCostCenterById({
      id: accountingData?.costObjectId ?? undefined,
    });

  const { bookingAccount, bookingAccountLoading } = useGetBookingAccountById({
    id: accountingData?.generalLedgerAccountId ?? undefined,
  });

  const approversIds = (accountingData?.approverIds ?? []).map(itm => ({
    approvers: itm,
  }));

  const defaultValues: AccountingInfoFormSchemaFormValues = {
    approvalMode: accountingData?.approvalWorkflowTemplateId
      ? 'workflow'
      : 'approvers',
    contact: {
      inputValue: contact.name,
      value: accountingData?.contactId ?? undefined,
    },
    costCenter: {
      inputValue: costCenter.readableName,
      value: accountingData?.costCenterId ?? undefined,
    },
    costObject: {
      inputValue: costObject.readableName,
      value: accountingData?.costObjectId ?? undefined,
    },
    generalLedgerAccount: {
      inputValue: bookingAccount.readableName,
      value: accountingData?.generalLedgerAccountId ?? undefined,
    },
    extraCostInfo: {
      inputValue: extraCostInfo.readableName,
      value: accountingData?.extraCostInfoId ?? undefined,
    },
    taxCode: accountingData?.bookingKeyId ?? undefined,
    workflow: accountingData?.approvalWorkflowTemplateId ?? undefined,
    artistSocialInsuranceCode:
      accountingData?.artistSocialInsuranceCode ?? undefined,
    approvers: approversIds ?? undefined,
    noInvoiceNeeded: noInvoiceNeeded ?? false,
  };

  return {
    defaultValues,
    loadingDefaultValues:
      contactLoading ||
      costCenterLoading ||
      costObjectLoading ||
      bookingAccountLoading ||
      extraCostInfoLoading,
  };
};
