import { UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import { useCreateExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { GeneralExpensesForm } from './GeneralExpensesForm';

interface GeneralExpensesFormContainerProps {
  onRemoveForm: UseFieldArrayRemove;
  generalExpensesForms: ExpensesFormOutput['generalExpenses'];
  formMethods: UseFormReturn<ExpensesFormOutput>;
}

export const GeneralExpensesFormContainer = ({
  formMethods,
  generalExpensesForms,
  onRemoveForm,
}: GeneralExpensesFormContainerProps) => {
  const { createExpenseInvoice } = useCreateExpenseInvoice();

  return (
    <GeneralExpensesForm
      createInvoice={createExpenseInvoice}
      formMethods={formMethods}
      generalExpensesForms={generalExpensesForms}
      onRemoveForm={onRemoveForm}
    />
  );
};
