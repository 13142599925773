import { Link, Paragraph } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';

interface ErrorCardProps {
  loading: boolean;
}

export const ErrorCard = ({ loading }: ErrorCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const email = t('emailInbox.infoPanel.importError.supportAddress');
  const timestamp = DateFormatters.longDatetime(new Date());
  const subject = t('emailInbox.infoPanel.importError.subject', {
    timestamp: timestamp,
  });

  const href = `mailto:${email}?subject=${subject}`;

  return (
    <InfoPanel
      backgroundColor="redbg"
      emailInbox={
        <Paragraph>
          <Trans i18nKey="emailInbox.infoPanel.importError.paragraph">
            An error occurred while trying to fetch the import email. Please
            contact <Link href={href}>support@candis.io</Link>.
          </Trans>
        </Paragraph>
      }
      loading={loading}
    />
  );
};
