import { FilterOptionsAccessor } from '@candisio/design-system';
import { TransactionsTableData } from 'components/Transactions/Table/types';
import {
  InvoiceAssociationStatus,
  PurchaseOrdersInvoiceAssociationStatus,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum PURCHASE_ORDER_COLUMNS {
  invoiceAssociationStatus = 'invoiceAssociationStatus',
}

export enum PURCHASE_ORDER_FILTER {
  invoiceAssociationStatus_inbox = 'invoiceAssociationStatus_inbox',
  invoiceAssociationStatus_archive = 'invoiceAssociationStatus_archive',
}

export const usePurchaseOrderListFilters = (
  filters: PURCHASE_ORDER_FILTER[]
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const filterMap = useMemo(
    () => ({
      [PURCHASE_ORDER_FILTER.invoiceAssociationStatus_inbox]: {
        [PURCHASE_ORDER_COLUMNS.invoiceAssociationStatus]: {
          data: [
            {
              id: InvoiceAssociationStatus.Missing,
              label: t('purchaseOrderLinkedDocumentStatus.missing'),
            },
            {
              id: PurchaseOrdersInvoiceAssociationStatus.Incomplete,
              label: t('purchaseOrderLinkedDocumentStatus.incomplete'),
            },
          ],
        },
      },
      [PURCHASE_ORDER_FILTER.invoiceAssociationStatus_archive]: {
        [PURCHASE_ORDER_COLUMNS.invoiceAssociationStatus]: {
          data: [
            {
              id: PurchaseOrdersInvoiceAssociationStatus.Missing,
              label: t('purchaseOrderLinkedDocumentStatus.missing'),
            },
            {
              id: PurchaseOrdersInvoiceAssociationStatus.Incomplete,
              label: t('purchaseOrderLinkedDocumentStatus.incomplete'),
            },
            {
              id: PurchaseOrdersInvoiceAssociationStatus.Attached,
              label: t('purchaseOrderLinkedDocumentStatus.attached'),
            },
          ],
        },
      },
    }),
    [t]
  );

  const filterOptions: FilterOptionsAccessor<TransactionsTableData> =
    useMemo(() => {
      return filters.reduce(
        (allFilters, currentFilter) => ({
          ...allFilters,
          ...filterMap[currentFilter],
        }),
        {}
      );
    }, [filterMap, filters]);

  return {
    filterOptions,
    isFilterLoading: false,
  };
};
