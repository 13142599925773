import {
  Card,
  Image,
  Flex,
  Text,
  Grid,
  Separator,
  Skeleton,
} from '@candisio/design-system';
import iconCard from 'assets/credit-cards/icon-card.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ReplaceCardFormLoading = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background="gray100" padding="0" alignSelf="start">
      <Grid templateColumns="auto 1fr" padding="space16" gap="space12">
        <Image
          src={iconCard}
          alt={t('creditCardFormDataPreview.replaceCard.altIcon')}
        />
        <Flex alignItems="baseline" gap="space8">
          <Text fontSize="large">
            {t('creditCardFormDataPreview.replaceCard.title')}
          </Text>
          <Text>{t('creditCardFormDataPreview.replaceCard.subtitle')}</Text>
        </Flex>
      </Grid>
      <Separator />
      <Grid padding="space16" gap="space16">
        <Text fontSize="large">
          {t('creditCardFormDataPreview.replaceCard.formHeader')}
        </Text>
        <Grid autoFlow="row" gap="space12">
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton height="space20" width="space256" key={index} />
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};
