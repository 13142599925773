import { AmountCell } from 'components/Table/Cells/Amount';
import { TransactionAmount } from 'generated-types/graphql.types';
import { Row } from 'react-table';
import { amountFormat } from 'utils/format';
import { CreditCardsTableData } from 'views/CreditCards/types';
import {
  CellVariant,
  mapPendingRequestToCellVariant,
} from 'views/CreditCards/utils/utils';

export const TransactionLimitCell = ({
  value,
  row,
}: {
  value: TransactionAmount;
  row: Row<CreditCardsTableData>;
}) => {
  const requestType = row.original.pendingRequest?.__typename;
  const formattedLimitRequestedAmount = amountFormat(
    row.original.pendingRequest?.requestedTransactionLimit.value,
    row.original.pendingRequest?.requestedTransactionLimit.currency,
    {
      minimumFractionDigits: 0,
      convertToBasicMonetaryUnit: true,
    }
  );

  const formattedLimitAmount = amountFormat(value?.value, value?.currency, {
    minimumFractionDigits: 0,
    convertToBasicMonetaryUnit: true,
  });

  if (!requestType) {
    return <AmountCell formattedAmount={formattedLimitAmount || null} />;
  }

  const cellVariant = mapPendingRequestToCellVariant[requestType];

  if (cellVariant === CellVariant.newRequest) {
    return (
      <AmountCell
        formattedAmount={formattedLimitRequestedAmount || null}
        color="gray600"
      />
    );
  }

  return <AmountCell formattedAmount={formattedLimitAmount || null} />;
};
