import { Grid, Skeleton } from '@candisio/design-system';

export const LoadingMembershipInvolvement = () => {
  return (
    <Grid gap="space4">
      <Skeleton height="10px" width="40%" />
      <Skeleton height="10px" width="40%" />
    </Grid>
  );
};
