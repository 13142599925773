import {
  CardLimitChangeRequest,
  physicalCardRequestFragment,
  singleUseVirtualCardRequestFragment,
  virtualCardRequestFragment,
} from 'containers/credit-cards/components/CreditCardsSection/gql';
import gql from 'graphql-tag';
import { pageInfoDataFragment } from 'views/queries/fragments';

export const cardIssuerCardholders = gql`
  query getCardIssuerCardholders(
    $input: CardIssuerPaginationInput!
    $filters: CardIssuerCardholdersFiltersInput
  ) {
    getCardIssuerCardholders(input: $input, filters: $filters) {
      edges {
        node {
          id
          firstName
          lastName
          memberships {
            membershipId
            organizationId
          }
        }
      }
    }
  }
`;

export const getCardIssuerCardsForCardholderQuery = gql`
  query getCardIssuerCardsForCardholder(
    $input: CardIssuerPaginationInput!
    $filters: CardIssuerCardsForCardholderFiltersInput
    $queries: CardIssuerCardsQueryInput
    $sortBy: CardIssuerCardsSortInput
  ) {
    getCardIssuerCardsForCardholder(
      input: $input
      filters: $filters
      queries: $queries
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          id
          cardholder {
            id
            membershipId
            membershipEmail
            avatarUrl
            firstName
            lastName
          }
          lastTransactionCreatedAt
          type
          status
          label
          refNum
          issuingDate
          expiryDate
          purpose
          limitRenewFrequency
          limit {
            value
            precision
            currency
          }
          spendingBalance {
            value
            precision
            currency
          }
          availableBalance {
            value
            precision
            currency
          }
          transactionLimit {
            value
            precision
            currency
          }
          brand
          hasAccountingData
          pendingRequest {
            __typename
            id
            status
            createdAt
            ...VirtualCardRequestData
            ...SingleUseVirtualCardRequestData
            ...PhysicalCardRequestData
            ...CardLimitChangeRequestData
          }
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${virtualCardRequestFragment}
  ${singleUseVirtualCardRequestFragment}
  ${physicalCardRequestFragment}
  ${CardLimitChangeRequest}
`;

export const cardManagerDataFragment = gql`
  fragment CardIssuerCardEdgeData on CardIssuerCardEdge {
    cursor
    node {
      id
      cardholder {
        id
        firstName
        lastName
        avatarUrl
        membershipEmail
        membershipId
      }
      type
      status
      label
      refNum
      category
      issuingDate
      lastTransactionCreatedAt
      purpose
      expiryDate
      limit {
        value
        precision
        currency
      }
      spendingBalance {
        value
        precision
        currency
      }
      availableBalance {
        value
        precision
        currency
      }
      transactionLimit {
        value
        precision
        currency
      }
      brand
      limitRenewFrequency
      hasAccountingData
      pendingRequest {
        __typename
        id
        status
        createdAt
        ...VirtualCardRequestData
        ...SingleUseVirtualCardRequestData
        ...PhysicalCardRequestData
        ...CardLimitChangeRequestData
      }
    }
  }
  ${virtualCardRequestFragment}
  ${singleUseVirtualCardRequestFragment}
  ${physicalCardRequestFragment}
  ${CardLimitChangeRequest}
`;

export const getCardIssuerCardsForCardManagerQuery = gql`
  query getCardIssuerCardsForCardManager(
    $input: CardIssuerPaginationInput!
    $filters: CardIssuerCardsForCardManagerFiltersInput
    $sortBy: CardIssuerCardsSortInput
    $queries: CardIssuerCardsQueryInput
  ) {
    getCardIssuerCardsForCardManager(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    ) {
      edges {
        ...CardIssuerCardEdgeData
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${cardManagerDataFragment}
  ${pageInfoDataFragment}
`;

export const getCardIssuerCardsRefNumbersAndLabelsQuery = gql`
  query getCardIssuerCardsRefNumbersAndLabels(
    $input: CardIssuerPaginationInput!
  ) {
    getCardIssuerCardsRefNumbersAndLabels: getCardIssuerCardsForCardManager(
      input: $input
    ) {
      edges {
        node {
          id
          refNum
          label
        }
      }
    }
  }
`;

export const getCardIssuerCardholderByMembershipIdQuery = gql`
  query getCardIssuerCardholderByMembershipId($membershipId: ID!) {
    getCardIssuerCardholderByMembershipId(membershipId: $membershipId) {
      id
    }
  }
`;
