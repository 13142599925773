import { Card, Grid, Heading, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface BankAccountProps {
  bankName: string | undefined;
  iban: string | undefined;
}

export const BankAccount = ({ bankName, iban }: BankAccountProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid gap="space8">
      <Heading as="h3">
        {t('insights.organizationSettingsDrawer.form.bankAccount.heading')}
      </Heading>
      <Card background="gray100">
        <Grid>
          {bankName && <Text fontSize="basic">{bankName}</Text>}
          {iban && <Text fontSize="basic">{iban}</Text>}
        </Grid>
      </Card>
    </Grid>
  );
};
