import { Box } from '@candisio/design-system';
import { isNil } from 'lodash';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { truncatedText } from '../utils/truncatedText';

interface TruncatedTitleProps {
  dataString: string;
  queryString: string;
  /**  length of string before it is truncated */
  maxCharacterLength?: number;
}

export const TruncatedTitle = ({
  dataString,
  maxCharacterLength,
  queryString,
}: TruncatedTitleProps) => {
  const DEFAULT_MAXIMUM_CHARACTERS = 34;

  const stringLength = !isNil(maxCharacterLength)
    ? maxCharacterLength
    : DEFAULT_MAXIMUM_CHARACTERS;

  return (
    <Box as="span">
      {renderQueryHighlight({
        value: truncatedText(dataString, stringLength),
        queryString: queryString ?? '',
      })}
    </Box>
  );
};
