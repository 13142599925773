import { Flex, Box } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { useUpdateSapB1Settings } from '../hooks/useUpdateSapB1Settings';
import { creditCardTransitAccountSchema, errorMessages } from './schema';

type CreditCardTransitAccountData = {
  moneyTransitAccountNumber: string;
};

export const CreditCardTransitAccountForm = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isLoading, onUpdateSapB1Settings, sapB1Settings } =
    useUpdateSapB1Settings();

  const form = useForm({
    defaultValues: {
      moneyTransitAccountNumber:
        sapB1Settings.sapB1Settings?.moneyInTransitGeneralLedgerAccount ?? '',
    },
    resolver: zodResolver({
      zodSchema: creditCardTransitAccountSchema,
      errorMessages: errorMessages,
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
    mode: 'onSubmit',
  });

  const handleSubmit = async ({
    moneyTransitAccountNumber: value,
  }: CreditCardTransitAccountData) => {
    const moneyTransitAccountNumber = !value ? '' : value;

    const result = await onUpdateSapB1Settings({
      moneyTransitAccountNumber,
    });

    if (result?.data) {
      form.resetField('moneyTransitAccountNumber', {
        defaultValue: moneyTransitAccountNumber,
      });
    }
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end">
        <HookFormTextField
          label={t(
            'creditCardExportingSap.moneyTransitAccountNumber.textInput.ariaLabel'
          )}
          name="moneyTransitAccountNumber"
          placeholder={t(
            'creditCardExportingSap.moneyTransitAccountNumber.textInput.placeholder'
          )}
          clearable={false}
        />
        <Box paddingTop="space16">
          <QuickSaveButtons
            isLoading={isLoading}
            isFormDirty={isFormDirty}
            resetField={() => form.resetField('moneyTransitAccountNumber')}
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};
