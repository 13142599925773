import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  UpdateCardPurposeInput,
  useUpdateCardPurposeMutation,
} from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useUpdateCardPurpose = () => {
  let status: 'error' | 'success';
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();

  const [updateCardPurpose, { loading: updatingPurpose }] =
    useUpdateCardPurposeMutation({
      onError: () => error(t('dashboard.genericErrorMessage')),
    });

  const { refetchQueries } = useCreditCardsRefetchQueries();

  return {
    updatingPurpose,
    updateCardPurpose: async ({ cardId, purpose }: UpdateCardPurposeInput) => {
      const { errors } = await updateCardPurpose({
        variables: {
          input: { cardId, purpose },
        },
        awaitRefetchQueries: true,
        refetchQueries: refetchQueries.cardIssuerCards(cardId),
      });

      if (errors?.length) {
        status = 'error';
      } else {
        status = 'success';
      }

      return {
        status,
        errors,
      };
    },
  };
};
