import { createContext, useContext } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { ProcessingFormValues } from './processingFormSchema';

export type BookingsFieldArrayContextValue = UseFieldArrayReturn<
  ProcessingFormValues,
  'bookings'
> | null;

export const BookingsFieldArrayContext =
  createContext<BookingsFieldArrayContextValue>(null);

/** Returns bookings field array instance */
export const useBookingsFieldArrayContext = () => {
  return useContext(BookingsFieldArrayContext);
};
