import { useOrganizationMembershipCountByStatusQuery } from 'generated-types/graphql.types';
import { LocalMembershipStatus } from '../UsersListView';

export const useGetUsersCountersByStatus = () => {
  const { data, loading } = useOrganizationMembershipCountByStatusQuery();

  const counters = data?.organizationMembershipCountByStatus;

  const mappedCounters: Record<LocalMembershipStatus, number> = {
    ACTIVE: counters?.active ?? 0,
    INACTIVE: counters?.inActive ?? 0,
    PENDING: counters?.pending ?? 0,
  };

  return { loading, counters: mappedCounters };
};
