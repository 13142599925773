import {
  ComboBoxField,
  FieldContainerProps,
  Item,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { useHookFormField } from 'components/HookFormFields/useHookFormField';
import { isNil } from 'lodash';
import { FieldPath, FieldValues } from 'react-hook-form';
import { HookFormUserFieldItem } from '../HookFormUsersField/HookFormUsersField';
import { HookFormUsersFieldOption } from '../HookFormUsersField/HookFormUsersFieldOption';

export interface HookFormNextApproversFieldProps<
  TFormValues extends FieldValues
> {
  /** List of initial approvers field items */
  defaultItems?: HookFormUserFieldItem[];
  /** Shown when list box is empty */
  emptyListPlaceholder?: string;
  /** Field label */
  label?: string;
  /** Called when new approval step should be appended to the list */
  onAppend?: (value: { approvers: string[] }) => void;
  /** Shown when field is empty */
  placeholder?: string;
  name: FieldPath<TFormValues>;
  isLoading?: boolean | undefined;
  /** Register input's ref into the hook form, please note that it can have unintended side effects */
  forceInputFieldRef?: boolean;
}

/** Dummy combo box field for the “next” approval step */
export const HookFormNextApproversField = <TFormValues extends FieldValues>({
  name,
  defaultItems = [],
  emptyListPlaceholder,
  label,
  onAppend,
  placeholder,
  isLoading,
  forceInputFieldRef,
}: HookFormNextApproversFieldProps<TFormValues>) => {
  const { fieldContainerProps, inputProps } = useHookFormField({
    name,
    forceInputFieldRef,
  });

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  return (
    <ComboBoxField
      label={label}
      width="100%"
      isLoading={isLoading}
      select={{
        children: ({ key, children, absence, avatarUrl }) => (
          <Item key={key} textValue={children}>
            <HookFormUsersFieldOption absence={absence} avatarUrl={avatarUrl}>
              {children}
            </HookFormUsersFieldOption>
          </Item>
        ),
        defaultItems,
        emptyListPlaceholder,
        onSelectionChange: value => {
          if (!isNil(value)) {
            onAppend?.({ approvers: [value as string] });
          }
        },
        placeholder,
        ...mergeProps(inputProps, labelFieldProps),
      }}
      showMessageOnFocus={fieldContainerProps.variant === 'error'}
      {...(mergeProps(labelProps, fieldContainerProps) as Omit<
        FieldContainerProps,
        'color' | 'label'
      >)}
    />
  );
};
