import { Card, Grid, Heading, Skeleton } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { ContactSettingsForm } from './ContactSettingsForm';
import { useApnSuggestionSettings } from './useApnSuggestionSettings';

export const ContactSettingsContainer = () => {
  const [t] = useTranslation();
  const {
    loadingInitialValues,
    submitting,
    readOnly,
    setReadOnly,
    setStartValue,
    enabled,
    setEnabled,
    startValue,
  } = useApnSuggestionSettings();

  return (
    <Grid gap="space16">
      <Heading as="h3">{t('settings.contacts.title')}</Heading>
      <Card paddingX="space24" paddingY="space16">
        {loadingInitialValues ? (
          <Grid gap="space16">
            <Skeleton height="space40" width="100%" />
            <Grid autoFlow="column" justifyContent="start" gap="space16">
              <Skeleton height="space40" width="space256" />
              <Skeleton height="space40" width="space64" />
            </Grid>
          </Grid>
        ) : (
          <ContactSettingsForm
            submitting={submitting}
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            enabled={enabled}
            setEnabled={setEnabled}
            startValue={startValue}
            setStartValue={setStartValue}
          />
        )}
      </Card>
    </Grid>
  );
};
