import {
  fetchPolicy,
  PaginationWithSearchFilterHook,
  FilterOption,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  TaxNumberInfiniteScrollPaginationQuery,
  TaxNumberInfiniteScrollPaginationQueryVariables,
  useContactsPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { taxNumberInfiniteScrollPaginationQuery } from '../utils/queries';

export const useTaxNumberFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const computeVariables = useCallback(
    (
      inputString?: string
    ): TaxNumberInfiniteScrollPaginationQueryVariables => ({
      queries: {
        taxNumber: inputString,
      },
    }),
    []
  );

  const {
    data: allContacts,
    loading: allContactsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    TaxNumberInfiniteScrollPaginationQuery,
    TaxNumberInfiniteScrollPaginationQueryVariables
  >({
    query: taxNumberInfiniteScrollPaginationQuery,
    queryRootKey: 'taxNumberPagination',
    computeVariables,
  });

  const { data: selectedContacts } = useContactsPaginationQuery({
    variables: {
      input: { page: 1, limit: filteredValues.length },
      filters: { taxNumber: filteredValues },
    },
    fetchPolicy,
    skip: filteredValues.length === 0 || Boolean(searchStr),
  });

  const byIds = selectedContacts?.contactsPagination.records ?? [];
  const byAccounNumber = allContacts?.taxNumberPagination.records ?? [];

  const filterOptions: FilterOption[] = [...byIds, ...byAccounNumber]
    .filter(contact => Boolean(contact.taxNumber))
    .map(contact => ({
      label: contact?.taxNumber?.value ?? '',
      id: contact.taxNumber?.value ?? '',
    }));

  return {
    filterOptions: uniqBy(filterOptions, 'id'),
    handleDebounceSearch,
    loading: allContactsLoading,
    loadMore,
  };
};
