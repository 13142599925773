import { Button, Drawer, Grid, Heading, useId } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useUpdateOrganizationSettings } from './hooks/useUpdateOrganizationSettings';
import { SettingsFormContainer } from './SettingsFormContainer';

interface Params {
  creditCardId?: string;
  statementId?: string;
  organizationSlug: string;
}

export const orgSettingsParam: 'organizationSettings' = 'organizationSettings';

export const OrganizationSettingsDrawer = () => {
  const { creditCardId, statementId } = useParams<Params>();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { update, updating } = useUpdateOrganizationSettings();
  const navigate = useNavigate();

  const formId = useId();

  const handleClose = () => {
    navigate('..', { relative: 'path' });
  };

  const isOpen =
    creditCardId === orgSettingsParam || statementId === orgSettingsParam;

  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerLayout
        onClose={handleClose}
        header={
          <Heading as="h3">
            {t('insights.organizationSettingsDrawer.title')}
          </Heading>
        }
        footer={
          <Grid>
            <Button
              justifySelf="start"
              form={formId}
              type="submit"
              loading={updating}
              disabled={updating}>
              {t('insights.organizationSettingsDrawer.form.cta')}
            </Button>
          </Grid>
        }>
        <SettingsFormContainer
          formId={formId}
          update={update}
          onClose={handleClose}
        />
      </DrawerLayout>
    </Drawer>
  );
};
