import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { noop } from 'lodash';
import { entitlementsToFeatureMap } from 'providers/FeatureToggleProvider/entitlements';
import {
  FEATURE_STATE,
  FEATURE,
  FeatureToggleContextResult,
  FeatureToggle,
} from 'providers/FeatureToggleProvider/types';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useShowQaUtils } from 'views/AppContainer/components/Header/components/QaUtils/utils';

export const initialFeatureToggleState = {
  [FEATURE.ABSENCE_MANAGEMENT]: FEATURE_STATE.VISIBLE,
  [FEATURE.BDS]: FEATURE_STATE.VISIBLE,
  [FEATURE.CONTRACTS]: FEATURE_STATE.VISIBLE,
  [FEATURE.DOCUMENT_RELATIONS]: FEATURE_STATE.VISIBLE,
  [FEATURE.FULL_TEXT_SEARCH]: FEATURE_STATE.VISIBLE,
  [FEATURE.INTEGRATION_SAP]: FEATURE_STATE.VISIBLE,
  [FEATURE.POSTING_TEXT]: FEATURE_STATE.VISIBLE,
  [FEATURE.PROVISIONS]: FEATURE_STATE.VISIBLE,
  [FEATURE.PURCHASE_ORDERS]: FEATURE_STATE.VISIBLE,
  [FEATURE.SEPA_XML]: FEATURE_STATE.VISIBLE,
  [FEATURE.ECM]: FEATURE_STATE.VISIBLE,
  [FEATURE.REIMBURSEMENT]: FEATURE_STATE.VISIBLE,
};

export const FeatureToggleContext = createContext<FeatureToggleContextResult>({
  ft: initialFeatureToggleState,

  overrideFeature: noop,

  show: () => false,
  promo: () => false,
  hide: () => false,
});

interface FeatureToggleProviderProps {
  children: ReactNode;
}

export const FeatureToggleProvider = ({
  children,
}: FeatureToggleProviderProps) => {
  const showQaUtils = useShowQaUtils();
  const entitlements = useEntitlementsFlag();
  const initialFeatureState = entitlementsToFeatureMap[entitlements];

  const [localFeatureState, setLocalFeatureState] =
    useLocalStorage<FeatureToggle>('feature-toggle', initialFeatureState);

  const featureState =
    localFeatureState && showQaUtils ? localFeatureState : initialFeatureState;

  const overrideFeatureToggle = useCallback(
    (featureToggle: FeatureToggle) => {
      setLocalFeatureState(featureToggle);
    },
    [setLocalFeatureState]
  );

  const value: FeatureToggleContextResult = useMemo(
    () => ({
      ft: featureState,

      overrideFeature: overrideFeatureToggle,

      show: state => state === FEATURE_STATE.VISIBLE,
      promo: state => state === FEATURE_STATE.PROMO,
      hide: state => state === FEATURE_STATE.HIDDEN,
    }),
    [featureState, overrideFeatureToggle]
  );

  return (
    <FeatureToggleContext.Provider value={value}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export const useFeatureToggle = () => useContext(FeatureToggleContext);
