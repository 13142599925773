import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateTeamMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { countTeamsQuery } from '../queries';
import { sanitizeMembers } from '../utils/sanitizeMembers';
import { TeamDetailsFormOutput } from '../utils/teamDetailsFormSchema';

export const useCreateTeam = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const [createTeamMutation, { loading: createTeamPending, client }] =
    useCreateTeamMutation({
      onError: () => error(t('common.genericErrorMessage')),
      onCompleted: () => {
        client.cache.evict({ fieldName: 'teams' });
      },
    });

  const handleCreateTeam = async (values: TeamDetailsFormOutput) => {
    const { teamName, teamAdmin, teamMembers } = values;

    const { data, errors } = await createTeamMutation({
      variables: {
        input: {
          name: teamName,
          members: sanitizeMembers({ teamAdmin, teamMembers }),
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        'teams',
        {
          query: countTeamsQuery,
          variables: { filters: { isArchived: false } },
        },
      ],
    });

    if (errors?.length) {
      error(t('teams.createTeam.teamCreatedError'));
    }

    if (data?.createTeam) {
      success(t('teams.createTeam.teamCreatedSuccess'));

      return data.createTeam.id;
    }
  };

  return {
    createTeamPending,
    createTeam: handleCreateTeam,
  };
};
