import { Tooltip, useTooltip } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const NameField = ({ readOnly }: { readOnly: boolean }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const { shouldUseSapGL } = useSap();

  // Should be moved to DS
  const { isOpen, triggerRef, triggerProps, tooltipRef, tooltipProps } =
    useTooltip({
      placement: 'top',
      isDisabled: !shouldUseSapGL,
    });

  return (
    <>
      <div
        ref={triggerRef}
        {...(shouldUseSapGL ? triggerProps : {})}
        data-cy="nameTooltipTrigger">
        <HookFormTextField
          name="name"
          placeholder={t('form.name.placeholder')}
          label={t('form.name.label')}
          readOnly={readOnly}
        />
      </div>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {t('form.readOnlySyncHint')}
        </Tooltip>
      )}
    </>
  );
};
