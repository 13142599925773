import { FieldContainer, Input as TextInput } from '@candisio/design-system';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { isNil } from 'lodash';
import { ReactNode, useState } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

export interface StartValueFieldsProps<TFormValues extends FieldValues> {
  readOnly?: boolean;
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Field label */
  label?: string | ReactNode;
}

export const StartValueField = <TFormValues extends FieldValues>({
  readOnly: readOnlyProp,
  name,
  disabled,
  label,
}: StartValueFieldsProps<TFormValues>) => {
  const { field, fieldState, formState } = useController({ name });
  const { friendlyFormatAccountsNumber } = useAccountingNumberFormatters();
  const { onChange, value, onBlur, ...fieldProps } = field;

  const [friendlyValue, setFriendlyValue] = useState(
    value ? friendlyFormatAccountsNumber(value) : ''
  );

  if (isNil(value) && friendlyValue.trim() !== '') {
    setFriendlyValue('');
  } else if (friendlyValue.replaceAll(' ', '') !== value.replaceAll(' ', '')) {
    setFriendlyValue(friendlyFormatAccountsNumber(value ?? ''));
  }

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  return (
    <FieldContainer
      disabled={disabled}
      label={label}
      message={errorMessage}
      readOnly={readOnly}
      variant={hasError ? 'error' : undefined}>
      <TextInput
        disabled={disabled}
        onChange={e => {
          const result = e.target.value !== '' ? e.target.value : null;

          setFriendlyValue(result ?? '');
          onChange(result?.replaceAll(' ', '') ?? '');
        }}
        placeholder={readOnly ? '–' : undefined}
        readOnly={readOnly}
        value={friendlyValue}
        variant={hasError ? 'error' : undefined}
        message={hasError ? errorMessage : undefined}
        onBlur={e => {
          onBlur();

          setFriendlyValue(friendlyFormatAccountsNumber(e.target.value) ?? '');
        }}
        {...fieldProps}
      />
    </FieldContainer>
  );
};
