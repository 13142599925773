import { useTheme } from '@candisio/design-system';
import { ContactValidationErrorType } from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { friendlyFormatIBAN } from 'ibantools';
import { useMemo } from 'react';
import { useDatev } from '../../../../orgConfig/datev';
import { createColumn } from './Column';
import { ContactImportPreviewTableData } from './ContactImportPreviewTable';

export type ColumnType<K extends keyof ContactImportPreviewTableData> = {
  titleKey: string;
  accessor: K;
  width?: string;
  errorMap?: {
    error: ContactValidationErrorType;
    translation: string;
  }[];
  format?: (value: any) => string | null;
};

export const useColumns = () => {
  const { space } = useTheme();
  const { friendlyFormatAccountsNumber } = useAccountingNumberFormatters();

  const { bdsBought } = useDatev();

  const columns = useMemo(() => {
    return [
      createColumn({
        titleKey: 'contacts:import.preview.columns.accountsPayableNumber',
        accessor: 'accountsPayableNumber',
        width: space.space128,
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidAccountsPayableNumber,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
        format: friendlyFormatAccountsNumber,
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.companyName',
        accessor: 'companyName',
        width: space.space256,
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.individualLastName',
        accessor: 'individualLastName',
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.individualFirstName',
        accessor: 'individualFirstName',
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.notSpecifiedName',
        accessor: 'notSpecifiedName',
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.contactType.label',
        accessor: 'contactTypeNumber',
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.shortName',
        accessor: 'shortName',
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.vatId',
        accessor: 'vatId',
        width: space.space200,
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidVatId,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.street',
        accessor: 'street',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidStreet,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.postOfficeBox',
        accessor: 'postOfficeBox',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidPostOfficeBox,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),

      createColumn({
        titleKey: 'contacts:import.preview.columns.postalCode',
        accessor: 'postalCode',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidPostalCode,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),

      createColumn({
        titleKey: 'contacts:import.preview.columns.city',
        accessor: 'city',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidCity,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),

      createColumn({
        titleKey: 'contacts:import.preview.columns.countryISOCode',
        accessor: 'countryISOCode',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidCountryCode,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),

      createColumn({
        titleKey: 'contacts:import.preview.columns.phoneNumber',
        accessor: 'phoneNumber',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidPhoneNumber,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),

      createColumn({
        titleKey: 'contacts:import.preview.columns.email',
        accessor: 'email',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidEmail,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),
      ...(bdsBought
        ? [
            createColumn({
              titleKey: 'contacts:import.preview.columns.bankAccountNumber',
              accessor: 'bankAccountNumber',
              errorMap: [
                {
                  error: ContactValidationErrorType.InvalidBankAccountNumber,
                  translation: 'contacts:import.preview.errors.invalidLabel',
                },
                {
                  error:
                    ContactValidationErrorType.BankAccountNumberAndIbanMismatch,
                  translation:
                    'contacts:import.preview.errors.ibanMismatchLabel',
                },
                {
                  error:
                    ContactValidationErrorType.BankAccountNumberMissingSwiftCode,
                  translation:
                    'contacts:import.preview.errors.missingSwiftCodeLabel',
                },
              ],
            }),
          ]
        : []),
      createColumn({
        titleKey: 'contacts:import.preview.columns.iban',
        accessor: 'iban',
        width: space.space256,
        format: friendlyFormatIBAN,
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidIban,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.swiftCode',
        accessor: 'swiftCode',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidSwiftCode,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
          {
            error:
              ContactValidationErrorType.SwiftCodeMissingIbanOrBankAccountNumber,
            translation:
              'contacts:import.preview.errors.missingAccountDetailsLabel',
          },
        ],
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.customerNumber',
        accessor: 'customerNumber',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidCustomerNumber,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.taxNumber',
        accessor: 'taxNumber',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidTaxNumber,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
        ],
      }),
      createColumn({
        titleKey: 'contacts:import.preview.columns.paymentConditionNumber',
        accessor: 'paymentConditionNumber',
        errorMap: [
          {
            error: ContactValidationErrorType.InvalidPaymentCondition,
            translation: 'contacts:import.preview.errors.invalidLabel',
          },
          {
            error: ContactValidationErrorType.PaymentConditionNotFound,
            translation: 'contacts:import.preview.errors.nonExistentLabel',
          },
          {
            error: ContactValidationErrorType.PaymentConditionArchived,
            translation: 'contacts:import.preview.errors.archivedLabel',
          },
        ],
      }),
    ];
  }, [
    bdsBought,
    friendlyFormatAccountsNumber,
    space.space128,
    space.space200,
    space.space256,
  ]);

  return { columns };
};
