import {
  ReimbursementCaseFiltersInput,
  ReimbursementCaseSortField,
  ReimbursementCaseSortInput,
  ReimbursementCasesPageBasedQuery,
  ReimbursementCasesPageBasedQueryVariables,
  SortDirection,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCallback } from 'react';
import { reimbursementCasesPageBasedQuery } from '../toolkit/queries';
import { useFormattedReimbursement } from './useFormattedReimbursement';

export const defaultSort: ReimbursementCaseSortInput = {
  field: ReimbursementCaseSortField.UpdatedAt,
  direction: SortDirection.Desc,
};

interface UseReimbursementPageBasedDataProps {
  sortBy?: ReimbursementCaseSortInput;
  filters?: ReimbursementCaseFiltersInput;
}

export const useReimbursementPageBasedData = ({
  filters,
  sortBy,
}: UseReimbursementPageBasedDataProps = {}) => {
  const { canUseReimbursement } = useReimbursement();
  const toReimbursement = useFormattedReimbursement();

  const computeVariables = useCallback(
    (): ReimbursementCasesPageBasedQueryVariables => ({
      input: {
        page: 1,
        limit: 20,
      },
      filters,
      sortBy: sortBy ?? defaultSort,
    }),
    [filters, sortBy]
  );

  const { data, loading, loadMore } = useDebouncedSearchPagination<
    ReimbursementCasesPageBasedQuery,
    ReimbursementCasesPageBasedQueryVariables
  >({
    query: reimbursementCasesPageBasedQuery,
    queryRootKey: 'reimbursementCasesPageBased',
    computeVariables,
    options: {
      skip: !canUseReimbursement,
    },
  });

  const { pageInfo, records } = data?.reimbursementCasesPageBased ?? {};

  const totalReimbursements = pageInfo?.totalCount ?? 0;

  const reimbursementList = (records ?? []).map(toReimbursement);

  return {
    loading,
    loadMore,
    reimbursementList,
    totalReimbursements,
  };
};
