import { IntegrationName } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

type UseOtherIntegrationResult = {
  isActive: boolean;
  showDocumentTypesForOtherIntegrations: boolean;
  shouldUseCoreDataApi: boolean;
  shouldUseCoreDataApiTaxCodes: boolean;
  shouldUseCandisApi: boolean;
  shouldUseAccountingAreas: boolean;
};

export const useOtherIntegration = (): UseOtherIntegrationResult => {
  const integration = useIntegrationSettings();

  const isOtherIntegrationActive = integration === IntegrationName.Other;

  const [
    documentTypesForOtherIntegrationsFF,
    coreDataApiFF,
    coreDataApiHideTaxCodeCreationFF,
    candisApiFF,
    accountingAreasFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.documentTypesForOtherIntegrations,
    FEATURE_FLAGS.coreDataApi,
    FEATURE_FLAGS.coreDataApiHideTaxCodeCreation,
    FEATURE_FLAGS.candisApi,
    FEATURE_FLAGS.accountingAreas,
  ]);

  const showDocumentTypesForOtherIntegrations =
    isOtherIntegrationActive && documentTypesForOtherIntegrationsFF;

  const shouldUseCoreDataApi = isOtherIntegrationActive && coreDataApiFF;

  const shouldUseCandisApi = isOtherIntegrationActive && candisApiFF;

  const shouldUseCoreDataApiTaxCodes =
    shouldUseCoreDataApi && coreDataApiHideTaxCodeCreationFF;

  const shouldUseAccountingAreas =
    isOtherIntegrationActive && accountingAreasFF;

  return {
    isActive: isOtherIntegrationActive,
    showDocumentTypesForOtherIntegrations,
    shouldUseCoreDataApi,
    shouldUseCoreDataApiTaxCodes,
    shouldUseCandisApi,
    shouldUseAccountingAreas,
  };
};
