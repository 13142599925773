import { WatchQueryFetchPolicy } from '@apollo/client';
import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import {
  DocumentCurrency,
  useCalculateDocumentGrossNetTaxRoundingAmountsLazyQuery,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { ProcessingFormValues } from '../ProcessingForm/processingFormSchema';
import { SplitDS } from '../SplitBookingsForm/types';

type CalculateRoundingAmountCallBackProps = {
  currency: DocumentCurrency;
  grossAmount: number;
  bookings:
    | SplitDS[]
    | DocumentApprovalFormValues['bookings']
    | ProcessingFormValues['bookings'];
};
type UseCalculateRoundingAmountProps = {
  fetchPolicy?: WatchQueryFetchPolicy;
};
export const useCalculateRoundingAmount = ({
  fetchPolicy = 'no-cache',
}: UseCalculateRoundingAmountProps = {}) => {
  const [calculateDocumentGrossNetTaxRoundingAmounts] =
    useCalculateDocumentGrossNetTaxRoundingAmountsLazyQuery({ fetchPolicy });

  return useCallback(
    async ({
      currency,
      grossAmount,
      bookings,
    }: CalculateRoundingAmountCallBackProps) => {
      const { data } = await calculateDocumentGrossNetTaxRoundingAmounts({
        variables: {
          input: {
            currency,
            grossAmount,
            splitBookingsAmounts: bookings.map(booking => ({
              netAmount: booking.netAmount ?? 0,
              taxAmount: booking.taxAmount ?? 0,
            })),
          },
        },
      });

      if (
        data?.calculateDocumentGrossNetTaxRoundingAmounts.__typename ===
        'CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult'
      ) {
        return data.calculateDocumentGrossNetTaxRoundingAmounts.rounding;
      }

      return null;
    },
    [calculateDocumentGrossNetTaxRoundingAmounts]
  );
};
