import { Theme, useTheme } from '@candisio/design-system';
import { CSSProperties } from 'react';

export interface ClockProps {
  outlineColor?: keyof Theme['colors'];
  backgroundColor?: keyof Theme['colors'];
  size?: string;
  style?: CSSProperties;
}

export const Clock = ({
  backgroundColor = 'red700',
  outlineColor = 'gray0',
  size,
  style,
}: ClockProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <g clipPath="url(#clip0_47122_405087)">
        <circle
          cx="8.00525"
          cy="8.0054"
          r="6.37378"
          fill={colors[backgroundColor]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 1.61798C4.47531 1.61798 1.61798 4.47531 1.61798 8C1.61798 11.5247 4.47531 14.382 8 14.382C11.5247 14.382 14.382 11.5247 14.382 8C14.382 4.47531 11.5247 1.61798 8 1.61798Z"
          fill={colors[outlineColor]}
        />
        <path
          d="M7 4C7.59572 4 8 4.48293 8 5.07865C8 5.07865 8 7.24698 8 7.8427C8 8.43842 7.64067 10 7.04495 10C6.44922 10 6.02098 9.51707 6.02098 8.92135C6.02098 8.32563 6.02102 5.07865 6.02102 5.07865C6.02102 4.48293 6.40428 4 7 4Z"
          fill={colors[outlineColor]}
        />
        <path
          d="M6.02098 8.92135C6.02098 8.32563 6.44922 8 7.04495 8H9.92135C10.5171 8 11 8.40428 11 9C11 9.59572 10.5171 10 9.92135 10H7.04495C6.44922 10 6.02098 9.51707 6.02098 8.92135Z"
          fill={colors[outlineColor]}
        />
      </g>
      <defs>
        <clipPath id="clip0_47122_405087">
          <rect width="16" height="16" fill={colors[outlineColor]} />
        </clipPath>
      </defs>
    </svg>
  );
};
