import { HookFormChipsFieldItem } from 'components/HookFormFields/HookFormTagsField/HookFormChipsField';

export const MAX_TITLE_LENGTH = 30;

export const buildTitle = (options: HookFormChipsFieldItem[]) => {
  const newTitle = options.reduce((acc: string, option) => {
    if (!acc) {
      return option.name;
    }

    return `${acc} & ${option.name}`;
  }, '');

  return newTitle.substring(0, MAX_TITLE_LENGTH);
};
