import { HookFormUserFieldItem } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import {
  Absence,
  ProcessingFormFieldItemsQuery,
  User,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import moment from 'moment';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';

/** Returns a function that maps `User` to `ProcessingFormApprovalFieldItem` */
export const useToApproverFieldItem = (
  orgMembersAbsence: Absence[],
  approvers: ProcessingFormFieldItemsQuery['approvers']
) => {
  const organizationTimezone = useOrganizationTimezone();

  const toApproverFieldItem = (
    user: Pick<User, 'id' | 'name' | 'avatarUrl'>
  ): HookFormUserFieldItem => {
    const absence = orgMembersAbsence.find(
      orgMember => orgMember.membershipId === user.id
    );

    const substitute = approvers.find(
      approver => approver.id === absence?.substituteMembershipId
    );

    return {
      key: user.id,
      children: user.name,
      ...(user.avatarUrl && { avatarUrl: user.avatarUrl }),
      ...(absence &&
        !isNil(absence.fromDate) &&
        !isNil(absence.status) &&
        !isNil(absence.toDate) && {
          absence: {
            fromDate: moment
              .tz(absence.fromDate, organizationTimezone)
              .toDate(),
            note: absence?.note ?? undefined,
            status: absence.status,
            toDate: moment.tz(absence.toDate, organizationTimezone).toDate(),
            substitute,
          },
        }),
    };
  };

  return toApproverFieldItem;
};
