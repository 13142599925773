import { Box, Modal, ModalProps } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const wistiaEmbedClass = 'wistia_embed';

interface WistiaModalProps extends ModalProps {
  wistiaId: string;
}

export const WistiaModal = ({ wistiaId, ...restProps }: WistiaModalProps) => {
  const [t] = useTranslation();

  return (
    <Modal closeLabel={t('common.close')} width="max-content" {...restProps}>
      <Box
        className={`${wistiaEmbedClass} ${wistiaId}`}
        width={640}
        height={360}
      />
    </Modal>
  );
};
