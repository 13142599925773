import { Text, TruncatedText } from '@candisio/design-system';
import { friendlyFormatIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps as ReactTableCellProps } from 'react-table';

export type IbanCellProps<
  TTableData extends object,
  TCellValue = ReactNode
> = ReactTableCellProps<TTableData, TCellValue | undefined>;

/** IBAN cell */
export const IbanCell = <TTableData extends object>({
  value,
}: IbanCellProps<TTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  if (isNil(value)) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  if (typeof value === 'string') {
    const formattedIban = friendlyFormatIBAN(value);

    if (formattedIban) return <TruncatedText>{formattedIban}</TruncatedText>;
  }

  return <>{value}</>;
};
