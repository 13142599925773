import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { ReactNode } from 'react';
import {
  FormProvider,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { BookingsFieldArrayContext } from './BookingsFieldArrayContext';

export interface DocumentApprovalFormProps {
  bookingsFieldArray: UseFieldArrayReturn<
    DocumentApprovalFormValues,
    'bookings'
  >;
  children: ReactNode;
  form: UseFormReturn<DocumentApprovalFormValues>;
}

export const DocumentApprovalsFormProvider = ({
  children,
  bookingsFieldArray,
  form,
}: DocumentApprovalFormProps) => {
  return (
    <BookingsFieldArrayContext.Provider value={bookingsFieldArray}>
      <FormProvider {...form}>{children}</FormProvider>
    </BookingsFieldArrayContext.Provider>
  );
};
