import {
  Button,
  Flex,
  MenuButton,
  MenuItem,
  defaultTheme,
} from '@candisio/design-system';
import { ComponentProps, ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

const StyledButton = styled(Button)`
  & svg {
    top: -2px;
    margin-right: 0.25rem;
  }
`;

const commonButtonProps: Omit<ComponentProps<typeof Button>, 'as'> = {
  color: 'gray',
  icon: 'plus',
  iconPosition: 'left',
  variant: 'tertiary',
  style: {
    flexGrow: '1',
    padding: '.75rem 1rem',
    borderRadius: '0',
    borderEndStartRadius: defaultTheme.radii.medium,
    borderEndEndRadius: defaultTheme.radii.medium,
  },
} as const;

const ActionWrapper = ({ children }: { children: ReactNode }) => (
  <Flex
    height="fit-content"
    bottom="0"
    position="sticky"
    alignItems="center"
    justifyContent="center"
    background="gray50"
    borderBottomLeftRadius="medium"
    borderBottomRightRadius="medium">
    {children}
  </Flex>
);

type DocumentSummaryCardActionMenuProps = {
  children?: ReactNode;
  items: MenuItem[];
} & Pick<ComponentProps<typeof MenuButton>, 'loading' | 'disabled'>;

export const DocumentSummaryCardActionMenuPromo = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode;
}) => (
  <ActionWrapper>
    <StyledButton
      {...commonButtonProps}
      onClick={onClick}
      icon="calendar"
      variant="tertiary"
      color="promo">
      {children}
    </StyledButton>
  </ActionWrapper>
);

export const DocumentSummaryCardActionMenu = ({
  loading = false,
  disabled = false,
  items,
  children,
}: DocumentSummaryCardActionMenuProps) => {
  const hasSingleAction = items?.length === 1;

  return (
    <ActionWrapper>
      {hasSingleAction && (
        <Button
          onPointerDown={items[0].onAction}
          loading={loading}
          disabled={disabled}
          {...commonButtonProps}>
          {items[0].label}
        </Button>
      )}
      {!hasSingleAction && (
        <MenuButton
          items={items}
          loading={loading}
          disabled={disabled}
          selectionMode="single"
          {...commonButtonProps}>
          {children}
        </MenuButton>
      )}
    </ActionWrapper>
  );
};
