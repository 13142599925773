import {
  DocumentCurrency,
  DocumentStatus,
  ExportableEntityInfo,
  ExportableEntityType,
  ReversalFragment,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { ExportEntityRow } from '../../types';
import { generateExportStatus } from './toProvisionExportEntity';

export const toReversalExportEntity =
  ({
    isDisabled,
    exportableEntitiesInfo,
  }: {
    isDisabled: boolean;
    exportableEntitiesInfo: ExportableEntityInfo[];
  }) =>
  (reversal: ReversalFragment): ExportEntityRow => {
    let exportableEntityInfo: ExportableEntityInfo | undefined = undefined;
    if (exportableEntitiesInfo && exportableEntitiesInfo.length > 0) {
      exportableEntityInfo = exportableEntitiesInfo.find(
        entityInfo => entityInfo.id === reversal.id
      );
    }

    return {
      type: ExportableEntityType.ProvisionReversal,
      id: reversal.id,
      contact: reversal.contact.name,
      amount: { amount: 0, currency: DocumentCurrency.Eur, precision: 0 },
      netAmount: reversal.netAmount,
      invoiceDate: new Date(reversal.invoiceDate),
      deliveryDate: new Date(reversal.deliveryDate),
      invoiceNumber: '',
      paymentState: { isPaid: false },
      hasDocument: false,
      generalLedgerAccount: reversal.bookings?.map(b => b.glAccount),
      account: reversal.bookings?.map(b => b.account),
      provisionEntityType: reversal.entity.type,
      provisionEntityId: reversal.entity.id,
      provisionStatus: reversal.entity.status as
        | TransactionStatus
        | DocumentStatus,
      isDisabled,
      exportStatus: generateExportStatus(
        exportableEntityInfo,
        ExportableEntityType.ProvisionReversal
      ),
    };
  };
