import { ApolloCache } from '@apollo/client';
import { useOptimisticComment } from 'components/Comment/useOptimisticComment';
import { Query } from 'generated-types/graphql.types';
import { ecmDocumentHistoryQuery } from 'views/EcmDocument/queries';
import { documentHistoryQuery } from 'views/queries';

interface UseOptimisticCommentCacheUpdateProps {
  documentId: string;
  mention: string;
}

export const useOptimisticCommentCacheUpdate = () => {
  const { getOptimisticComment } = useOptimisticComment();

  const optimisticCommentCacheUpdate = ({
    documentId,
    mention,
  }: UseOptimisticCommentCacheUpdateProps) => {
    return (proxy: ApolloCache<any>) => {
      try {
        const docCacheData = proxy.readQuery<Pick<Query, 'getDocument'>>({
          query: documentHistoryQuery,
          variables: { id: documentId },
        });

        const ecmCacheData = proxy.readQuery<
          Pick<Query, 'getAggregatedEcmDocument'>
        >({
          query: ecmDocumentHistoryQuery,
          variables: { id: documentId },
        });

        if (docCacheData?.getDocument) {
          const optimisticComment = getOptimisticComment({ text: mention });
          const updatedTimeline = [
            optimisticComment,
            ...(docCacheData.getDocument.timeline ?? []),
          ];

          proxy.writeQuery({
            query: documentHistoryQuery,
            variables: { id: documentId },
            data: {
              ...docCacheData,
              getDocument: {
                ...docCacheData.getDocument,
                timeline: updatedTimeline,
              },
            },
          });
        }

        if (ecmCacheData?.getAggregatedEcmDocument) {
          const optimisticComment = getOptimisticComment({ text: mention });
          const updatedTimeline = [
            optimisticComment,
            ...(ecmCacheData.getAggregatedEcmDocument.timeline ?? []),
          ];

          proxy.writeQuery({
            query: ecmDocumentHistoryQuery,
            variables: { id: documentId },
            data: {
              getAggregatedEcmDocument: {
                ...ecmCacheData.getAggregatedEcmDocument,
                timeline: updatedTimeline,
              },
            },
          });
        }
      } catch (e) {
        console.error('Error updating cache:', e);
      }
    };
  };

  return { optimisticCommentCacheUpdate };
};
