import { useDocumentDataFileRecordsQuery } from 'generated-types/graphql.types';

export const useDocumentDownloadHistory = () => {
  const { data, loading: isLoading } = useDocumentDataFileRecordsQuery();

  const documentHistoryItems = data
    ? data.documentDataFileRecords?.map(item => {
        return {
          createdAt: item.createdAt,
          dateRangeFilters: item.dateRangeFilters,
          file: {
            name: item.file?.name,
            url: item.file?.url,
            size: item.file?.size,
          },
          fileType: item.fileType,
          filters: item.filters,
          ecmFilters: item.ecmFilters,
          isExpired: item.isExpired,
          query: item.query,
          status: item.status,
          username: item.creator?.name,
        };
      })
    : [];

  return {
    documentHistoryItems,
    isLoading,
  };
};
