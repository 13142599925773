import { ErrorMessages } from 'utils/zodFormValidation';
import { reimbursementFormSchema } from './reimbursementFormSchema';

type ExcludeKeys =
  | 'note'
  | 'targetMembership'
  | 'suggestedCostCenter'
  | 'suggestedCostCenter.value'
  | 'suggestedCostCenter.inputValue';

/** Gets reimbursement form validation error messages */
export const reimbursementFormErrorMessages: ErrorMessages<
  typeof reimbursementFormSchema,
  ExcludeKeys
> = {
  title: {
    label: 'reimbursementView.rightSection.form.title.label',
  },
};
