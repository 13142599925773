import { Box, Table } from '@candisio/design-system';
import {
  ContactValidationErrorType,
  CreateOrUpdateContactInput,
} from 'generated-types/graphql.types';
import { transformContactsData } from 'views/Contacts/ContactImport/ContactImportPreviewTable/dataTransformUtils';
import { useColumns } from 'views/Contacts/ContactImport/ContactImportPreviewTable/useColumns';
import {
  FilterState,
  SingleParsedContact,
} from 'views/Contacts/ContactImport/types';
import { transformFilterState } from './filterUtils';

export interface ContactImportPreviewTableData
  extends CreateOrUpdateContactInput {
  id: string;
  errors: ContactValidationErrorType[];
}

interface ContactImportPreviewTableProps {
  contacts: SingleParsedContact[];
  filters: FilterState | null;
  loading?: boolean;
}

export const ContactImportPreviewTable = ({
  contacts = [],
  filters,
  loading,
}: ContactImportPreviewTableProps) => {
  const { columns } = useColumns();

  const transformedData = transformContactsData(contacts);

  return (
    <Box paddingX="space40" height="100%">
      <Table<ContactImportPreviewTableData>
        columns={columns}
        initialState={{
          filters: transformFilterState(filters),
        }}
        data={transformedData}
        isLoading={loading}
      />
    </Box>
  );
};
