import { Button, Flex, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';

export type CyclerProps = {
  position: {
    current: number;
    total: number;
  };
  onNext: () => void;
  onPrev: () => void;
  loading: boolean;
};

export const Cycler = ({
  onPrev,
  onNext,
  loading,
  position: { current, total },
}: CyclerProps) => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const isFirst = current === 1;
  const isLast = current === total;

  const titleHeader = useMediaQuery({
    maxWidth: 1452,
  })
    ? 'carousel.cyclerShortened'
    : 'carousel.cyclerNew';

  const showCycler = total > 1;

  if (!showCycler) {
    return null;
  }

  return (
    <Flex gap="space4" alignItems="center" height="space24">
      <Text fontSize="basic" color="gray800">
        {tTransactions(titleHeader, { current, total })}
      </Text>
      <Button
        label={tTransactions('carousel.cycleLeft')}
        onClick={onPrev}
        disabled={isFirst || loading}
        variant="tertiary"
        size="xsmall"
        icon="arrowLeft"
        alignSelf="center"
      />
      <Button
        label={tTransactions('carousel.cycleRight')}
        onClick={onNext}
        variant="tertiary"
        icon="arrowRight"
        size="xsmall"
        disabled={isLast || loading}
        alignSelf="center"
      />
    </Flex>
  );
};
