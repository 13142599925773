import { Grid, Item, Text } from '@candisio/design-system';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { useTranslation } from 'react-i18next';
import { EmptyDataState } from 'views/Settings/PaymentConditions/components/EmptyDataState';
import { PAYMENT_CONDITIONS_ACCOUNTS_WIDTH } from 'views/Settings/PaymentConditions/CoreDataApi/PaymentConditions';
import { PaymentConditionsKebabMenu } from 'views/Settings/PaymentConditions/CoreDataApi/PaymentConditionsKebabMenu';
import { PaymentConditionsListData } from 'views/Settings/PaymentConditions/types';
import { usePaymentConditionsList } from 'views/Settings/PaymentConditions/usePaymentConditionsList';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';

interface PaymentConditionsListProps {
  data: PaymentConditionsListData[];
  loading: boolean;
  onShowItem?: (id: string | 'new') => void;
  onShowImportHistory?: () => void;
  onStartExport?: () => void;
}

export const PaymentConditionsList = ({
  data,
  loading,
  onShowItem,
  onShowImportHistory,
  onStartExport,
}: PaymentConditionsListProps) => {
  const [t] = useTranslation();

  const {
    sortedPaymentConditions,
    onSearchFilter,
    handleSearchReset,
    renderPaymentCondition,
    queryString,
  } = usePaymentConditionsList(data);

  return (
    <FilterableList
      children={sortedPaymentConditions.map(paymentCondition => (
        <Item key={paymentCondition.id} textValue={paymentCondition.id}>
          <FilterableListItem
            itemId={paymentCondition.id}
            onSelect={() => onShowItem && onShowItem(paymentCondition.id)}>
            <Grid>
              <Text fontWeight="semibold" fontSize="basic">
                {renderQueryHighlight({
                  value: renderPaymentCondition(paymentCondition.condition),
                  queryString: queryString,
                })}
              </Text>
              {paymentCondition.conditionName && (
                <Text color="gray500">
                  {renderQueryHighlight({
                    value: paymentCondition.conditionName,
                    queryString: queryString,
                  })}
                </Text>
              )}
            </Grid>
          </FilterableListItem>
        </Item>
      ))}
      emptyDataState={
        <EmptyDataState message="paymentConditions.noData.importMessage" />
      }
      emptySearchState={<EmptySearchState searchReset={handleSearchReset} />}
      isLoading={loading}
      kebabMenu={
        <PaymentConditionsKebabMenu
          onImportHistoryAction={onShowImportHistory}
          onExportGenericAction={onStartExport}
        />
      }
      searchField={{
        onSearchFilter,
        placeholder: t('settings:paymentConditions.searchPlaceholder'),
        searchQuery: queryString,
      }}
      width={PAYMENT_CONDITIONS_ACCOUNTS_WIDTH}
    />
  );
};
