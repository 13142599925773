import { MenuButton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface AddReimbursementItemActionMenuButtonProps {
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
  creatingReimbursementItem: boolean;
}

const customStyle = {
  padding: '.9rem 1rem',
  borderRadius: '0',
  borderEndStartRadius: '8px',
  borderEndEndRadius: '8px',
  width: '100%',
};

export const AddReimbursementItemActionMenuButton = ({
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
  creatingReimbursementItem,
}: AddReimbursementItemActionMenuButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <MenuButton
      icon="plus"
      iconPosition="left"
      variant="tertiary"
      selectionMode="single"
      size="small"
      loading={creatingReimbursementItem}
      style={{ ...customStyle }}
      items={[
        {
          id: 'hospitality-expense',
          label: t('reimbursementView.middleSection.form.hospitality.addCta'),
          onAction: onHospitalityExpenseClick,
        },
        {
          id: 'general-expense',
          label: t('reimbursementView.middleSection.form.general.addCta'),
          onAction: onGeneralExpenseClick,
        },
      ]}>
      {t('reimbursementView.leftSection.reimbursementItems.addExpenseCta')}
    </MenuButton>
  );
};
