import {
  CardActionName,
  CardType,
  LinkedCardTransactionState,
} from 'generated-types/graphql.types';
import i18next from 'i18next';
import { RecurringPayment } from 'views/CreditCards/hooks/useRecurringPaymentData';

export const transactionStateWithAction = [
  LinkedCardTransactionState.PurchaseFailed,
  LinkedCardTransactionState.CardCheckFailed,
];

interface Tooltip {
  t: i18next.TFunction;
  card: RecurringPayment['linkedCard']['card'];
}

export const getTooltipText = ({ t, card }: Tooltip) => {
  const { type, pendingActions, cardholder } = card;

  const mapCardTypeTooltip: Record<CardType, string> = {
    BLACK: t('recurringPaymentList.cardType.BLACK'),
    VIRTUAL: t('recurringPaymentList.cardType.VIRTUAL'),
    PHYSICAL: t('recurringPaymentList.cardType.PHYSICAL'),
    SINGLE_USE: t('recurringPaymentList.cardType.SINGLE_USE'),
  };

  const cardType = mapCardTypeTooltip[type];

  const isPhoneNumberMissing = (pendingActions ?? []).includes(
    CardActionName.CardholderMustHave_2Fa
  );

  const isUsageAgreementNotAccepted = (pendingActions ?? []).includes(
    CardActionName.UsageAgreementMustBeAgreed
  );

  if (isPhoneNumberMissing) {
    return t('recurringPaymentList.processingTooltipWithNoPhone', {
      cardholder: cardholder?.name,
      cardType,
    });
  } else {
    if (isUsageAgreementNotAccepted) {
      return t('recurringPaymentList.processingTooltipWithNoAgreement', {
        cardholder: cardholder?.name,
        cardType,
      });
    }

    return t('recurringPaymentList.processingTooltipWithNoCardholder', {
      cardholder: card.cardholder?.name,
      cardType,
    });
  }
};
