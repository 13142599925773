import { Icon, Flex, Tooltip, useTooltip } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';

export const ExportSummaryTitle = ({
  title,
  tooltip,
  status,
  disabled,
}: {
  title: string;
  tooltip?: string;
  status?: ExportStatus;
  disabled?: boolean;
}) => {
  const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
    useTooltip();

  const renderExportStatusIcon = (status: ExportStatus) => {
    if (status === ExportStatus.Exported)
      return (
        <Icon
          size="space16"
          icon="checkCircle"
          color={disabled ? 'gray500' : 'green700'}
        />
      );
    if (status === ExportStatus.PartialyExported)
      return (
        <Icon
          size="space16"
          icon="warning"
          color={disabled ? 'gray500' : 'yellow700'}
        />
      );
    if (status === ExportStatus.Failed)
      return (
        <Icon
          size="space16"
          icon="failCircle"
          color={disabled ? 'gray500' : 'red700'}
        />
      );

    return null;
  };

  return (
    <Flex gap="space4" alignItems="center">
      {status && renderExportStatusIcon(status)}
      {title}
      {tooltip && (
        <>
          <Icon
            icon="infoCircle"
            color="gray500"
            size="space16"
            {...triggerProps}
            ref={triggerRef}></Icon>
          {isOpen && (
            <Tooltip
              maxWidth="space256"
              key="provisionsTooltip"
              {...tooltipProps}
              ref={tooltipRef}>
              {tooltip}
            </Tooltip>
          )}
        </>
      )}
    </Flex>
  );
};
