import { useApolloClient } from '@apollo/client';
import { Grid, Paragraph, Text } from '@candisio/design-system';
import { FileDropZone } from 'components/FileDropZone/FileDropZone';
import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  EcmDocumentType,
  useAddFileUploadMutation,
  useDocumentFileUploadUrlMutation,
  useFileUploadsQuery,
  useProcessDocumentMutation,
  useProcessEcmDocumentMutation,
  useUpdateFileUploadMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { AppRouteParams, Routes } from 'models';
import { useDocumentUploadState } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ChangeEvent, DragEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { tabViewToUrlMap } from 'views/Archive/components/utils';
import { delayRefetchedSensitiveContractsQueries } from 'views/Inbox/DocumentProcessing/components/Ecm/delayRefetchedSensitiveContractsQueries';
import {
  getEcmSensitiveDocumentsCountQuery,
  getEcmSensitiveDocumentsQuery,
} from 'views/Inbox/EcmSensitiveDocuments/gql';
import { List, useGetRefetchListQueries } from 'views/queries';
import { FileUploadsList } from './FileUploadsList';
import { fileUploadUtil } from './fileUploadUtil';

interface DocumentUploadModalContentProps {
  onModalClose: () => void;
}

export const DocumentUploadModalContent = ({
  onModalClose,
}: DocumentUploadModalContentProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const client = useApolloClient();
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const { isSensitive } = useDocumentUploadState();
  const navigate = useNavigate();

  const [addFile] = useAddFileUploadMutation();
  const [documentFileUploadUrl] = useDocumentFileUploadUrlMutation();
  const [processDocument] = useProcessDocumentMutation();
  const [processEcmDocument] = useProcessEcmDocumentMutation();
  const [updateFile] = useUpdateFileUploadMutation();
  const counterQueries = useCounterQueries();
  const queriesToRefetch = useGetRefetchListQueries(List.INBOX_TABLE);
  const { data: { fileUploads = [] } = {} } = useFileUploadsQuery();

  const switchToContractsTab = () => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${tabViewToUrlMap.INBOX_ECM_SENSITIVE_DOCUMENTS}`,
      { organizationSlug }
    );

    // ensure document is extracted and shows up in contracts table
    setTimeout(() => navigate(pathname), 1500);
  };

  const onUploadFile = async (file: File) => {
    await fileUploadUtil(file, {
      addFile,
      documentFileUploadUrl,
      updateFile,
      onUploaded: async fileId => {
        // TGB-858 only Contracts can be uploaded as sensitive documents
        // `isSensitive` means that we opened a modal for uploading
        // sensitive contracts
        if (isSensitive) {
          const { data: processRes } = await processEcmDocument({
            variables: {
              input: {
                fileId: fileId,
                documentType: EcmDocumentType.Contract,
                isSensitive: true,
              },
            },
            refetchQueries: [
              getEcmSensitiveDocumentsQuery,
              getEcmSensitiveDocumentsCountQuery,
            ],
            onCompleted: switchToContractsTab,
            onQueryUpdated: delayRefetchedSensitiveContractsQueries,
          });

          return { documentId: processRes?.processEcmDocument?.ecmDocumentId };
        }

        const { data: processRes } = await processDocument({
          variables: {
            fileId: fileId,
          },
          refetchQueries: [...queriesToRefetch, ...counterQueries],
          onCompleted() {
            // make sure all listInboxDocuments queries will be refetched
            client.cache.evict({
              fieldName: 'listInboxDocuments',
            });
          },
        });

        return { documentId: processRes?.processDocument?.id };
      },
    });
  };

  const onFilesSelected = (
    _: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
    selectedFiles: File[]
  ) => {
    selectedFiles.forEach(onUploadFile);

    if (fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
  };

  const mailLink = generatePath(
    `/:organizationSlug${Routes.SETTINGS}${Routes.MAIL_SYNC}`,
    { organizationSlug }
  );

  return (
    <Grid rowGap="space16" paddingTop="space14">
      {isSensitive && (
        <Paragraph color="gray500" fontSize="small">
          {t('ecm:documentUploadModal.description')}
        </Paragraph>
      )}

      <FileDropZone
        onFilesSelected={onFilesSelected}
        accept={['application/pdf']}
        icon={isSensitive ? 'eyeLock' : 'upload'}
        ref={fileUploadRef}>
        <Paragraph>
          <Trans
            i18nKey="documentUploadModal.browse"
            tOptions={{ context: isSensitive && 'contracts' }}>
            <Text fontWeight="bold">Browse</Text>
            to upload invoices.
          </Trans>
        </Paragraph>
        <Paragraph>
          <Trans i18nKey="documentUploadModal.dragAndDrop">
            or
            <Text fontWeight="bold">Drag & Drop</Text>
          </Trans>
        </Paragraph>
      </FileDropZone>

      <Paragraph color="gray500" fontSize="small">
        {t('documentUploadModal.supportedFiles')}
        {!isSensitive && (
          <>
            <br />
            <Trans i18nKey="documentUploadModal.emailImport">
              You can also
              <RouterLink to={mailLink} onClick={onModalClose}>
                import documents using the E-Mail import.
              </RouterLink>
            </Trans>
          </>
        )}
      </Paragraph>
      {fileUploads.length > 0 && (
        <FileUploadsList
          fileUploads={fileUploads}
          isSensitive={isSensitive}
          onModalClose={onModalClose}
        />
      )}
    </Grid>
  );
};
