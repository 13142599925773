import { useCreditCardsAccountingInfoFieldItemsQuery } from 'generated-types/graphql.types';
import { mapToWorkflowFieldItem } from './utils';

export const useWorkFlowsFieldOptions = () => {
  const { data } = useCreditCardsAccountingInfoFieldItemsQuery();

  const { workflows = [], getOrgMembersAbsence: orgMembersAbsence = [] } =
    data ?? {};

  const defaultItems = workflows.map(workflow =>
    mapToWorkflowFieldItem(workflow, orgMembersAbsence)
  );

  return { defaultItems };
};
