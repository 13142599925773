import gql from 'graphql-tag';

export const getOrgMembersAbsenceQuery = gql`
  query getOrgMembersAbsence {
    getOrgMembersAbsence {
      fromDate
      toDate
      note
      status
      membershipId
      substituteMembershipId
    }
  }
`;
