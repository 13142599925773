import { Text } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { ExportEntity } from '../../../../types';
import { ExportSummaryTitle } from '../../../components/ExportSummaryTitle';
import { parseDocumentExportStatus } from '../../../utils';

export const BdsSummary = ({
  provisions,
  reversals,
  loading,
}: {
  provisions: ExportEntity[];
  reversals: ExportEntity[];
  loading?: boolean;
}) => {
  const [t] = useTranslation();

  const provisionsCount = {
    exported: provisions.filter(
      provision =>
        parseDocumentExportStatus(provision.exportStatus) ===
        ExportStatus.Exported
    ).length,
    partiallyExported: provisions.filter(
      provision =>
        parseDocumentExportStatus(provision.exportStatus) ===
        ExportStatus.PartialyExported
    ).length,
    failed: provisions.filter(
      provision =>
        parseDocumentExportStatus(provision.exportStatus) ===
        ExportStatus.Failed
    ).length,
  };

  const reversalsCount = {
    exported: reversals.filter(
      reversal =>
        parseDocumentExportStatus(reversal.exportStatus) ===
        ExportStatus.Exported
    ).length,
    partiallyExported: reversals.filter(
      reversal =>
        parseDocumentExportStatus(reversal.exportStatus) ===
        ExportStatus.PartialyExported
    ).length,
    failed: reversals.filter(
      reversal =>
        parseDocumentExportStatus(reversal.exportStatus) === ExportStatus.Failed
    ).length,
  };

  const bdsSummary = [
    provisionsCount.exported && {
      title: (
        <ExportSummaryTitle
          title={t('export.exportSummary.provisionsCount', {
            count: provisionsCount.exported,
          })}
          status={ExportStatus.Exported}
        />
      ),
      content: '',
    },
    provisionsCount.partiallyExported && {
      title: (
        <ExportSummaryTitle
          title={t('export.exportSummary.provisionsCount', {
            count: provisionsCount.partiallyExported,
          })}
          status={ExportStatus.PartialyExported}
        />
      ),
      content: '',
      footer: (
        <Text fontSize="small" color="yellow700">
          {t('export.exportHistorySummary.partiallyExported')}
        </Text>
      ),
    },
    provisionsCount.failed && {
      title: (
        <ExportSummaryTitle
          title={t('export.exportSummary.provisionsCount', {
            count: provisionsCount.failed,
          })}
          status={ExportStatus.Failed}
        />
      ),
      content: '',
      footer: (
        <Text fontSize="small" color="red700">
          {t('export.exportHistorySummary.failed')}
        </Text>
      ),
    },
    reversalsCount.exported && {
      title: (
        <ExportSummaryTitle
          title={t('export.exportSummary.reversalsCount', {
            count: reversalsCount.exported,
          })}
          status={ExportStatus.Exported}
        />
      ),
      content: '',
    },
    reversalsCount.partiallyExported && {
      title: (
        <ExportSummaryTitle
          title={t('export.exportSummary.reversalsCount', {
            count: reversalsCount.partiallyExported,
          })}
          status={ExportStatus.PartialyExported}
        />
      ),
      content: '',
      footer: (
        <Text fontSize="small" color="yellow700">
          {t('export.exportHistorySummary.partiallyExported')}
        </Text>
      ),
    },
    reversalsCount.failed && {
      title: (
        <ExportSummaryTitle
          title={t('export.exportSummary.reversalsCount', {
            count: reversalsCount.failed,
          })}
          status={ExportStatus.Failed}
        />
      ),
      content: '',
      footer: (
        <Text fontSize="small" color="red700">
          {t('export.exportHistorySummary.failed')}
        </Text>
      ),
    },
  ].filter(Boolean) as {
    title: ReactNode;
    content?: ReactNode;
    footer?: ReactNode;
  }[];

  const totalProvisionsCount = provisions.length + reversals.length;

  return totalProvisionsCount > 0 ? (
    <SummaryAccordionCard
      items={bdsSummary}
      loading={loading}
      collapsable={false}
    />
  ) : null;
};
