import { useGetHasLinkedTransactionQuery } from 'generated-types/graphql.types';

export const useHasLinkedTransaction = (documentId?: string) => {
  const { data } = useGetHasLinkedTransactionQuery({
    variables: { id: documentId ?? '' },
    skip: !documentId,
  });

  const hasLinkedTransaction = data?.getDocument?.hasTransactionLinked ?? false;

  return hasLinkedTransaction;
};
