import { SvgIcon, SvgProps } from '../SvgIcon';

export const UploadFileErrorIcon = ({ ...props }: SvgProps) => {
  return (
    <SvgIcon {...props} width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M9.24097 20.2539H3.24097C2.41254 20.2539 1.74097 19.5823 1.74097 18.7539V2.25391C1.74097 1.42548 2.41254 0.753906 3.24097 0.753906H13.869C14.2669 0.753726 14.6486 0.911643 14.93 1.19291L17.8 4.06391C18.0816 4.34519 18.2399 4.72687 18.24 5.12491V8.25391"
        stroke="#9E9E9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0006 20.0002C17.7935 20.0002 17.6256 20.1681 17.6256 20.3752C17.6256 20.5824 17.7935 20.7502 18.0006 20.7502C18.2077 20.7502 18.3756 20.5824 18.3756 20.3752C18.3756 20.1681 18.2077 20.0002 18.0006 20.0002"
        stroke="#F44034"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0006 17.7502V14.7502"
        stroke="#F44034"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8136 20.4322C24.0889 20.9822 24.0596 21.6356 23.7363 22.1588C23.413 22.682 22.8417 23.0004 22.2266 23.0002H13.7746C13.1595 23.0004 12.5883 22.682 12.2649 22.1588C11.9416 21.6356 11.9123 20.9822 12.1876 20.4322L16.4136 11.9812C16.714 11.3799 17.3284 11 18.0006 11C18.6728 11 19.2872 11.3799 19.5876 11.9812L23.8136 20.4322Z"
        stroke="#F44034"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
