import { CoreDataTypes } from 'generated-types/resolvers-types';
import { useTranslation } from 'react-i18next';

interface UseCoreDataTypeTranslationProps {
  coreDataType?: CoreDataTypes;
}

export const coreDataTypeTranslationKeys: Record<CoreDataTypes, string> = {
  [CoreDataTypes.BookingKey]: 'importHistoryList.coreDataTypes.taxCode',
  [CoreDataTypes.Contact]: 'importHistoryList.coreDataTypes.contact',
  [CoreDataTypes.CostCenter]: 'importHistoryList.coreDataTypes.costCenter',
  [CoreDataTypes.GeneralLedger]:
    'importHistoryList.coreDataTypes.generalLedgerAccount',
  /**
   * @deprecated cost object is part of cost center *
   */
  [CoreDataTypes.CostObject]: 'importHistoryList.coreDataTypes.costObject',
  [CoreDataTypes.PaymentCondition]:
    'importHistoryList.coreDataTypes.paymentCondition',
};

export const useCoreDataTypeTranslation = ({
  coreDataType,
}: UseCoreDataTypeTranslationProps) => {
  const [t] = useTranslation();

  const translationKey = coreDataType
    ? coreDataTypeTranslationKeys[coreDataType]
    : 'importHistoryList.coreDataTypes.unknown';

  return t(translationKey);
};
