import { useTheme } from '@candisio/design-system';
import { ReactNode } from 'react';
import {
  ProgressBarContainer,
  ProgressChildrenContainer,
  StripedBackground,
} from './styles';

type ProgressBarProps = {
  className?: string;
  completion?: number;
  error?: boolean;
  static?: boolean;
  childStyles?: {
    padding?: string;
  };
  children?: ReactNode;
};

// Probably add animation and types later
export const ProgressBar = ({
  children,
  className,
  completion,
  static: statical,
  error,
  childStyles,
}: ProgressBarProps) => {
  const { colors } = useTheme();
  const stripeColor = error ? colors.red200 : colors.gray200;

  const stripeOpacity = error ? '30%' : 'unset';

  return (
    <ProgressBarContainer className={className}>
      <StripedBackground
        completion={completion}
        static={statical}
        color={stripeColor}
        opacity={stripeOpacity}
      />
      <ProgressChildrenContainer {...childStyles}>
        {children}
      </ProgressChildrenContainer>
    </ProgressBarContainer>
  );
};
