import { Button, Card, Grid, Text } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { SETTINGS_ROUTES } from 'views/Settings/types';
import {
  CC_SETUP_URL_PARAM,
  TEAM_ID_URL_PARAM,
} from '../../toolkit/hooks/useModalVisibility';

export const CreditCardSetupCard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const { searchParams } = useMutateSearchParams();

  const bulletList: string[] = t(
    'teams.createTeam.successModal.creditCardSetup.bulletPoints',
    {
      returnObjects: true,
    }
  );

  const handleClick = () => {
    const teamId = searchParams.get(TEAM_ID_URL_PARAM);
    if (!teamId) return;

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}/:id?/:additionalSetup?`,
      { organizationSlug, id: teamId, additionalSetup: CC_SETUP_URL_PARAM }
    );

    navigate(pathname);
  };

  return (
    <Card border="1px solid gray250">
      <Grid gap="space16">
        <Text fontSize="xlarge">
          {t('teams.createTeam.successModal.creditCardSetup.title')}
        </Text>
        <Grid
          as="ul"
          paddingLeft={0}
          style={{ listStyle: 'none' }}
          gap="space4">
          {bulletList.map((item, index) => (
            <li key={index}>
              <Text fontSize="basic">{item}</Text>
            </li>
          ))}
        </Grid>
        <Button onClick={handleClick} justifySelf="start">
          {t('teams.createTeam.successModal.creditCardSetup.cta')}
        </Button>
      </Grid>
    </Card>
  );
};
