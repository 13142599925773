import { Link } from '@candisio/design-system';
import { noresultsData } from 'components/Lottie/Lottie';
import { Trans } from 'providers/LocaleProvider';
import { EmptyState } from './EmptyState';

interface EmptySearchStateProps {
  searchReset: () => void;
  translation?: string;
}

export const EmptySearchState = ({
  searchReset,
  translation,
}: EmptySearchStateProps) => {
  return (
    <EmptyState animationData={noresultsData}>
      <Trans i18nKey={translation ? translation : 'settings:emptySearchData'}>
        No search data.
        <Link as="button" onClick={searchReset} />
      </Trans>
    </EmptyState>
  );
};
