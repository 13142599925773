import { css } from './themes';
import { DefaultTheme } from './themes/DefaultTheme';

/**
 * @deprecated Please use the design system instead
 * @see https://candisio.github.io/design-system
 */
export const scrollbarsStyles = css`
  //for firefox browser
  @supports (-moz-appearance: none) {
    scrollbar-color: ${DefaultTheme.colors.border};
    scrollbar-width: thin;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${DefaultTheme.colors.border};
  }
`;

/**
 * @deprecated Please use the design system instead
 * @see https://candisio.github.io/design-system
 */
export const hoverScrollbar = css`
  & {
    ::-webkit-scrollbar-track {
      background-color: inherit !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: inherit !important;
    }
    ::-webkit-scrollbar-corner {
      background-color: rgba(0, 0, 0, 0);
    }
    background-color: rgba(0, 0, 0, 0);
    background-clip: text;
    transition: background-color 0.2s;
    // overflow-y: hidden;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      // overflow-y: auto;
    }
  }
`;
