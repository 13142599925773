import { PaymentConditionsTableRow } from './types';

export const useColumns = () => {
  const columns: (keyof Omit<PaymentConditionsTableRow, 'id'>)[] = [
    'status',
    'paymentConditionNumber',
    'value',
  ];

  return columns;
};
