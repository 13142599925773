import { useGetOrganizationLocalQuery } from 'generated-types/graphql.types';
import { Locale, User } from 'generated-types/resolvers-types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { UserProfileFormData } from './types';

export const useInitialFormData = (): UserProfileFormData => {
  const currentUser = useCurrentUser() as User;
  const organizationId = useOrganizationId() as string;
  const { data: org } = useGetOrganizationLocalQuery({
    variables: { id: organizationId },
  });

  return {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser.email,
    locale: currentUser.locale ?? org?.organization?.locale ?? Locale.En,
  };
};
