import {
  Avatar,
  AvatarStack,
  Flex,
  Grid,
  Skeleton,
  Text,
  useTheme,
} from '@candisio/design-system';
import { useNewDashboardFF } from 'components/NewPromotions/hooks/useNewDashboardFF';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { InsightWidgetKebabMenu } from '../KebabMenu/InsightWidgetKebabMenu';
import { SumProps } from '../types';
import { PermissionIndicator } from './PermissionIndicator';

export const Sum = ({
  contextMenu,
  creatorName,
  formattedSum,
  isPreview,
  loading,
  name,
  progressBar,
  readOnly,
  sharedWithUsers,
  source,
  toFilteredDocumentsList,
}: SumProps) => {
  const { colors } = useTheme();
  const { showNewDashboardPromotions } = useNewDashboardFF();

  const {
    isOnDashboard,
    isOwnedByCurrentUser,
    onDeleteWidget,
    onEditWidget,
    onMoveWidget,
    onUnsubscribeWidget,
  } = contextMenu ?? {};

  return (
    <Grid
      gap="space8"
      alignContent="space-between"
      background={showNewDashboardPromotions ? 'gray0' : 'gray50'}
      borderRadius="medium"
      minHeight="200px"
      minWidth={isPreview ? '260px' : undefined}
      padding="space16">
      <Flex gap="space8">
        <Grid flex="1" gap="space4" alignContent="start">
          {sharedWithUsers && sharedWithUsers.length > 0 && (
            <AvatarStack limit={3} size="small">
              {sharedWithUsers.map(({ name, id, avatarUrl }) => (
                <Avatar key={id} name={name} img={avatarUrl ?? undefined} />
              ))}
            </AvatarStack>
          )}
          <Grid>
            {loading ? (
              <Skeleton height="space20" width="100%" />
            ) : (
              <Text
                fontSize="basic"
                fontWeight="regular"
                lineHeight="paragraph"
                color="gray600">
                {source}
              </Text>
            )}
            {loading ? (
              <Skeleton height="space20" width="100%" />
            ) : (
              <Text
                fontSize="basic"
                fontWeight="regular"
                lineHeight="paragraph"
                color="gray800">
                {name}
              </Text>
            )}
          </Grid>
        </Grid>
        {contextMenu && (
          <Grid
            flex="none"
            gap="space8"
            alignContent="start"
            justifyItems="center">
            <InsightWidgetKebabMenu
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              creatorName={creatorName!}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              isOwnedByCurrentUser={isOwnedByCurrentUser!}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              isOnDashboard={isOnDashboard!}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onDeleteWidget={onDeleteWidget!}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onEditWidget={onEditWidget!}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onUnsubscribeWidget={onUnsubscribeWidget!}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onMoveWidget={onMoveWidget!}
            />

            {!isOwnedByCurrentUser && (
              <PermissionIndicator creatorName={creatorName} />
            )}
          </Grid>
        )}
      </Flex>

      {loading ? (
        <Skeleton height="115px" width="100%" />
      ) : (
        <Grid justifyItems="start">
          {!readOnly && toFilteredDocumentsList ? (
            <RouterLink
              to={toFilteredDocumentsList}
              // @TODO custom style override should not be needed.
              // Design system should cover this use case
              style={{ color: colors.gray600 }}>
              {formattedSum}
            </RouterLink>
          ) : (
            formattedSum
          )}
          {progressBar}
        </Grid>
      )}
    </Grid>
  );
};
