import { useDatev } from 'orgConfig/datev';
import { useTranslation } from 'react-i18next';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { DocumentType } from '../../../../types';
import { ExportSummaryTitle } from '../../../components/ExportSummaryTitle';

export const BdsSummary = ({
  readyToExportEntitiesCount,
  loading,
}: {
  readyToExportEntitiesCount: Record<DocumentType, number>;
  loading?: boolean;
}) => {
  const [t] = useTranslation();

  const { exportProvisionsFF } = useDatev(); // BDS-checked

  const totalProvisionsCount =
    (readyToExportEntitiesCount.DOCUMENT_PROVISION ?? 0) +
    (readyToExportEntitiesCount.TRANSACTION_PROVISION ?? 0);

  const totalReversalsCount =
    (readyToExportEntitiesCount.DOCUMENT_REVERSAL ?? 0) +
    (readyToExportEntitiesCount.TRANSACTION_REVERSAL ?? 0);

  const showProvisionsSummary =
    exportProvisionsFF && (totalProvisionsCount > 0 || totalReversalsCount > 0);

  const bdsSummary = [
    ...(totalProvisionsCount
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.provisionsCount', {
                  count: totalProvisionsCount,
                })}
                tooltip={t('export.exportSummary.tooltips.provisionsTooltip')}
              />
            ),
            content: '',
          },
        ]
      : []),
    ...(totalReversalsCount
      ? [
          {
            title: (
              <ExportSummaryTitle
                title={t('export.exportSummary.reversalsCount', {
                  count: totalReversalsCount,
                })}
                tooltip={t('export.exportSummary.tooltips.reversalsTooltip')}
              />
            ),
            content: '',
          },
        ]
      : []),
  ];

  return showProvisionsSummary ? (
    <SummaryAccordionCard
      items={bdsSummary}
      loading={loading}
      collapsable={false}
    />
  ) : null;
};
