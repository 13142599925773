import gql from 'graphql-tag';

export const bulkCreateBookingAccounts = gql`
  mutation bulkCreateBookingAccounts(
    $input: [CreateBookingAccountInput!]!
    $fileId: String
  ) {
    bulkCreateBookingAccounts(input: $input, fileId: $fileId) {
      ... on BookingAccountsBulkImportReport {
        imported {
          id
        }
        errors {
          accountCode
          kind
          message
        }
      }
      ... on BookingAccountError {
        accountCode
        kind
        message
      }
    }
  }
`;

export const bookingAccountImportFileUploadURL = gql`
  mutation bookingAccountImportFileUploadURL(
    $fileName: String!
    $contentType: String!
  ) {
    bookingAccountImportFileUploadURL(
      fileName: $fileName
      contentType: $contentType
    ) {
      id
      url
      postOptions {
        field
        value
      }
    }
  }
`;

export const bookingAccountsDuplicateCheckQuery = gql`
  query bookingAccountsDuplicateCheck($accountCodes: [String!]!) {
    bookingAccountsDuplicateCheck(accountCodes: $accountCodes)
  }
`;
