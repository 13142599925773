import { Box, Button, Card, Grid, Paragraph } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CardLimitRenewFrequency,
  CardStatus,
  CardType,
  UpdateCardLabelInput,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { CardLimitFormHeader } from '../CardLimitFormHeader';
import {
  limitIntervalLabel,
  limitLabel,
  limitPeriodOptions,
} from '../utils/utils';
import { updateVirtualAndPhysicalCardLimitFormErrorMessages } from './updateVirtualAndPhysicalCardLimitFormErrors';
import {
  UpdateVirtualAndPhysicalCardLimitFormValues,
  updateVirtualAndPhysicalCardLimitFormSchema,
} from './updateVirtualAndPhysicalCardLimitFormSchema';

interface UpdateVirtualAndPhysicalCardLimitFormProps {
  isSubmitting: boolean;
  hasPendingRequest: boolean;
  cardType?: CardType;
  cardStatus: CardStatus;
  cardId: string;
  updatingLabel: boolean;
  defaultValues: UpdateVirtualAndPhysicalCardLimitFormValues;
  updateCardLabel: ({ cardId, label }: UpdateCardLabelInput) => Promise<{
    status: 'error' | 'success';
    errors: readonly GraphQLError[] | undefined;
  }>;
  onClose: () => void;
  onSubmit: (values: UpdateVirtualAndPhysicalCardLimitFormValues) => void;
}

export const UpdateVirtualAndPhysicalCardLimitForm = ({
  isSubmitting,
  hasPendingRequest,
  cardType,
  cardStatus,
  defaultValues,
  updatingLabel,
  cardId,
  onClose,
  onSubmit,
  updateCardLabel,
}: UpdateVirtualAndPhysicalCardLimitFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success } = useToastMessage();
  const { isCardManager } = useUserRoles();

  const form = useForm<UpdateVirtualAndPhysicalCardLimitFormValues>({
    mode: 'onTouched',
    defaultValues,
    values: defaultValues,
    context: { isCardManager },
    resolver: zodResolver({
      zodSchema: updateVirtualAndPhysicalCardLimitFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
      errorMessages: updateVirtualAndPhysicalCardLimitFormErrorMessages,
    }),
  });

  const canUserEditFieldValues =
    [
      CardStatus.Active,
      CardStatus.Locked,
      CardStatus.LockedPin,
      CardStatus.Pending,
    ].includes(cardStatus) ||
    (isCardManager && cardStatus === CardStatus.Requested);

  const isReadOnly =
    !canUserEditFieldValues || (!isCardManager && hasPendingRequest);

  const limitIntervalValue = form.watch('limitInterval');

  const dirtyFields = Object.keys(form.formState.dirtyFields);

  const updateLabelOnly = async () => {
    // We want to invoke the mutation for updating label independently if all the other fields are not dirty
    // but there was no way to prevent the validation onSubmit thus we check only if the label is dirty and we clean up the errors
    if (dirtyFields.length === 1 && dirtyFields[0] === 'cardLabel') {
      form.clearErrors();
      await updateCardLabel({
        cardId,
        label: form.getValues().cardLabel ?? '',
      });
      success(t('creditCardFormDataPreview.successMsg'));
    }
  };

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        height="100%"
        paddingY="space16"
        paddingX="space24"
        overflow="hidden"
        onSubmit={form.handleSubmit(
          // The first callback gets invoked only when validation is successfull
          // The second callback gets invoked when there are some form errors
          data => onSubmit(data),
          updateLabelOnly
        )}
        templateRows="auto 1fr auto">
        <CardLimitFormHeader
          hasPendingLimitRequest={hasPendingRequest}
          cardType={cardType}
          onClick={onClose}
          showInfo={hasPendingRequest || !isReadOnly}
        />
        <Box overflowY="auto">
          <Card background="gray100" borderTopRadius="none">
            <Grid gap="space16">
              <Grid templateColumns="1fr 1fr" gap="space16">
                <HookFormTextField
                  name="cardLabel"
                  maxLength={40}
                  readOnly={isReadOnly}
                  optionalHint="optional"
                  label={t('ccRequestForm.fields.name.label')}
                  placeholder={t('ccRequestForm.fields.name.placeholder')}
                />
                <HookFormSelectField
                  name="limitInterval"
                  label={t('ccRequestForm.fields.limitFrequency.label')}
                  readOnly={isReadOnly}
                  items={limitPeriodOptions.map(option => ({
                    ...option,
                    children: t(option.children),
                  }))}
                />
                <HookFormAmountField
                  name="limit"
                  minValue={0}
                  readOnly={isReadOnly}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                  currency="EUR"
                  label={
                    limitIntervalValue
                      ? t(
                          limitLabel[
                            limitIntervalValue as CardLimitRenewFrequency
                          ]
                        )
                      : t('ccRequestForm.fields.limit.label.fallback')
                  }
                  placeholder={t('dashboard.drawer.form.limit.placeHolder')}
                />
                <HookFormAmountField
                  name="transactionLimit"
                  minValue={0}
                  currency="EUR"
                  readOnly={isReadOnly}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                  label={t('dashboard.drawer.form.transactionLimit.label')}
                  placeholder={t(
                    'dashboard.drawer.form.transactionLimit.placeHolder'
                  )}
                />
              </Grid>
              {!isCardManager && (
                <HookFormTextField
                  name="comment"
                  label={t('dashboard.drawer.form.comment.label')}
                  readOnly={isReadOnly}
                  placeholder={t('dashboard.drawer.form.comment.placeHolder')}
                  maxLength={200}
                  optionalHint={t('dashboard.drawer.form.comment.hint')}
                />
              )}
              <Paragraph color="gray600" fontSize="basic">
                {t(
                  limitIntervalLabel[
                    limitIntervalValue as CardLimitRenewFrequency
                  ]
                )}
              </Paragraph>
            </Grid>
          </Card>
        </Box>
        {!isReadOnly && (
          <Box paddingTop="space16">
            <Button
              type="submit"
              justifySelf="start"
              disabled={isSubmitting || updatingLabel}
              loading={isSubmitting || updatingLabel}>
              {t('creditCardFormDataPreview.save')}
            </Button>
          </Box>
        )}
      </Grid>
    </FormProvider>
  );
};
