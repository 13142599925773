import { WorkflowStepConditionOperators } from 'generated-types/graphql.types';
import { ErrorMessages } from 'utils/zodFormValidation';
import { ZodIssueCode, z } from 'zod';

const formSchema = z.object({
  name: z.string(),
  description: z.string().nullish(),
  steps: z.array(
    z.object({
      approvers: z.array(z.string()).min(1),
      conditions: z.array(
        z.object({
          operator: z.enum([
            WorkflowStepConditionOperators.GreaterThan,
            WorkflowStepConditionOperators.GreaterThanEquals,
            WorkflowStepConditionOperators.LessThan,
            WorkflowStepConditionOperators.LessThanEquals,
          ]),
          values: z.number().nonnegative(),
        })
      ),
    })
  ),
});

export const getWorkflowTemplatesSchema = ({
  existingWorkflowTemplates,
}: {
  existingWorkflowTemplates: string[];
}) => {
  return formSchema.superRefine(({ name }, ctx) => {
    if (
      existingWorkflowTemplates
        .map(name => name.toLowerCase())
        .includes(name.toLowerCase())
    ) {
      ctx.addIssue({
        path: ['name'],
        code: ZodIssueCode.custom,
        params: {
          translationKey: 'workflows.errors.duplicate',
        },
      });
    }
  });
};

export const workflowTemplateSchema = (existingWorkflowTemplates: string[]) =>
  formSchema.and(getWorkflowTemplatesSchema({ existingWorkflowTemplates }));

type ExcludedKeys = 'description';

export const getWorkflowTemplatesErrorMessages = (): ErrorMessages<
  ReturnType<typeof workflowTemplateSchema>,
  ExcludedKeys
> => {
  return {
    name: { label: 'workflows.errors.name' },
    'steps.0.approvers': {
      label: 'workflows.details.edit.name.label',
      translationKey: 'workflows.errors.approvers',
    },

    'steps.0.conditions.0.values': {
      label: 'workflows.details.edit.conditions.values.amount.label',
      translationKey: 'workflows.errors.values',
      too_small: {
        translationKey: 'workflows.errors.negativeNumbers',
      },
    },
    'steps.0.conditions.0.operator': {
      label: 'workflows.details.edit.conditions.operator.label',
      translationKey: 'workflows.errors.operator',
    },
  };
};

export type WorkflowTemplateFormValues = z.infer<
  ReturnType<typeof workflowTemplateSchema>
>;
