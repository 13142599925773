import { Grid, Skeleton } from '@candisio/design-system';

export const CostCenterFormSkeleton = () => {
  return (
    <Grid gap="space16">
      <Skeleton height="space40" width="100%" />
      <Skeleton height="space40" width="100%" />
      <Skeleton height="space40" width="100%" />
      <Skeleton height="space40" width="100%" />
    </Grid>
  );
};
