import { useCreateGeneralExpenseItemMutation } from 'generated-types/graphql.types';
import {
  reimbursementCaseByIdQuery,
  reimbursementItemsListQuery,
} from '../toolkit/queries';

export const useCreateGeneralExpense = () => {
  const [createGeneralExpense, { loading }] =
    useCreateGeneralExpenseItemMutation();

  return {
    isCreateGeneralExpensePending: loading,
    createGeneralExpense: async (reimbursementId: string) => {
      const response = await createGeneralExpense({
        variables: { input: { reimbursementCaseId: reimbursementId } },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementItemsListQuery,
            variables: { filters: { reimbursementCaseId: reimbursementId } },
          },
        ],
      });

      if (response.errors?.length) {
        return { status: 'error' };
      }

      return {
        status: 'success',
        generalExpenseId: response.data?.createGeneralExpenseItem.id,
      };
    },
  };
};
