import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  EcmDocumentType,
  useCreateDocumentRelationshipsMutation,
  useDeleteDocumentRelationshipMutation,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const toTargetDocumentIds = (doc: EcmDocumentsTableData): string =>
  doc.documentType === EcmDocumentType.Invoice && doc.invoiceId
    ? doc.invoiceId
    : doc.id;

export const useDocumentRelationshipsActions = (onCompleted: () => void) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { success, error } = useToastMessage();

  const [linkDocuments, { loading: isLinkingDocumentsPending }] =
    useCreateDocumentRelationshipsMutation({ onCompleted });

  const [unlinkDocuments, { loading: isUnlinkingDocumentsPending }] =
    useDeleteDocumentRelationshipMutation({ onCompleted });

  const handleLinkDocuments = useCallback(
    async ({
      documentName,
      selectedDocuments,
      documentId,
    }: {
      documentName: string;
      selectedDocuments: EcmDocumentsTableData[];
      documentId: string;
    }) => {
      const result = await linkDocuments({
        variables: {
          input: {
            sourceDocumentId: documentId,
            targetDocumentIds: selectedDocuments.map(toTargetDocumentIds),
          },
        },
      });

      if (
        result.data?.createDocumentRelationships.__typename ===
        'CreateDocumentRelationshipResponse'
      ) {
        success(
          t('documentRelationship.modal.successMessage', {
            count: selectedDocuments.length,
            documentName,
          })
        );
      } else {
        error(t('documentRelationship.modal.errorMessage'));
      }
    },
    [error, linkDocuments, success, t]
  );

  const handleUnlinkDocuments = useCallback(
    async ({
      documentName,
      relationshipId,
    }: {
      documentName: string;
      relationshipId: string;
    }) => {
      const result = await unlinkDocuments({
        variables: { relationshipId },
      });

      if (
        result.data?.deleteDocumentRelationship.__typename ===
        'DeleteDocumentRelationshipResponse'
      ) {
        success(
          t('documentRelationship.unlink.successMessage', { documentName })
        );
      } else {
        error(t('documentRelationship.unlink.errorMessage'));
      }
    },
    [error, success, t, unlinkDocuments]
  );

  return {
    handleLinkDocuments,
    isLinkingDocumentsPending,
    handleUnlinkDocuments,
    isUnlinkingDocumentsPending,
  };
};
