import { Grid } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';

interface InvoiceDatesProps {
  deliveryDate?: Date | null;
  invoiceDate?: Date | null;
  invoicePostingDate?: Date | null;
  showDeliveryDate?: boolean;
  showPostingDate?: boolean;
}

export const InvoiceDates = ({
  deliveryDate,
  invoiceDate,
  invoicePostingDate,
  showDeliveryDate,
  showPostingDate,
}: InvoiceDatesProps) => {
  const [t] = useTranslation();

  return (
    <Grid autoFlow="column" gap="space8">
      <HookFormTextField
        name="invoiceDate"
        label={t('document.requestApproval.inputs.from.label')}
        inputProps={{
          value: invoiceDate
            ? DateFormatters.compact(new Date(invoiceDate))
            : undefined,
        }}
        readOnly
      />
      {showDeliveryDate && (
        <HookFormTextField
          name="invoiceDeliveryDate"
          label={t('document.requestApproval.inputs.invoiceDeliveryDate.label')}
          inputProps={{
            value: deliveryDate
              ? DateFormatters.compact(new Date(deliveryDate))
              : undefined,
          }}
          readOnly
        />
      )}
      {showPostingDate && (
        <HookFormTextField
          name="invoicePostingDate"
          label={t('document.requestApproval.inputs.invoicePostingDate.label')}
          inputProps={{
            value: invoicePostingDate
              ? DateFormatters.compact(new Date(invoicePostingDate))
              : undefined,
          }}
          readOnly
        />
      )}
    </Grid>
  );
};
