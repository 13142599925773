export const mapDocumentTypeTranslations = (documentType: string) => {
  switch (documentType) {
    case 'Rechnungseingang':
      return 'documents-table:defaultDocumentTypes.Rechnungseingang';
    case 'Rechnungsausgang':
      return 'documents-table:defaultDocumentTypes.Rechnungsausgang';
    case 'Kasse':
      return 'documents-table:defaultDocumentTypes.Kasse';
    case 'Sonstige':
      return 'documents-table:defaultDocumentTypes.Sonstige';
    case 'Rechnungskorrektur':
      return 'documents-table:defaultDocumentTypes.Rechnungskorrektur';
    default:
      return 'documents-table:emptyCellPlaceholder';
  }
};
