import { useDateConverter } from 'hooks/useDateConverter';
import { useMemo } from 'react';
import { ExpensesFormOutput } from '../toolkit/expensesFormSchema';
import { HospitalityExpense } from './useFormattedHospitalityExpense';

export const fallbackDefaultValues = {
  expenseId: '',
  reason: '',
  dayOfExpense: '',
  totalAmount: 0,
  receiptAmount: 0,
  location: '',
  tipAmount: null,
  internalGuests: null,
  externalGuests: null,
  files: [],
};

interface UseInitialHospitalityExpenseFormValuesReturn {
  defaultValues: ExpensesFormOutput['hospitalityExpenses'];
}

interface InitialHospitalityExpenseFormValuesProps {
  hospitalityExpenseItems: HospitalityExpense[];
}

export const createHospitalityDefaultValues = (expenseId: string) => ({
  ...fallbackDefaultValues,
  expenseId,
});

export const useInitialHospitalityExpenseFormValues = ({
  hospitalityExpenseItems,
}: InitialHospitalityExpenseFormValuesProps): UseInitialHospitalityExpenseFormValuesReturn => {
  const { dateTimeStringToDateString } = useDateConverter();

  const defaultValues = useMemo(() => {
    return (hospitalityExpenseItems ?? []).map(expense => ({
      expenseId: expense.id,
      reason: expense.reason ?? '',
      dayOfExpense: expense.expenseDate
        ? dateTimeStringToDateString(expense.expenseDate)
        : '',
      totalAmount: expense.totalAmount ?? 0,
      receiptAmount: expense.receiptAmount ?? 0,
      location: expense.location ?? '',
      tipAmount: expense.tipAmount ?? null,
      internalGuests: expense.internalGuests ?? null,
      externalGuests: expense.externalGuests ?? null,
      files:
        expense.files?.map(file => ({
          id: file.id ?? '',
          name: file.name ?? '',
          url: file.url ?? '',
        })) ?? [],
    }));
  }, [dateTimeStringToDateString, hospitalityExpenseItems]);

  return { defaultValues };
};
