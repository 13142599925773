import { Card, Flex, Grid, Icon, Image, Text } from '@candisio/design-system';
import cardIcon from 'assets/credit-cards/icon-card.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import teamIcon from 'views/Settings/Teams/toolkit/media/team-icon.svg';
import { Step } from 'views/Settings/Teams/toolkit/utils/types';

type Action = {
  id: Step;
  icon: ReactNode;
  label: string;
  description: string;
};

const actions: Array<Action> = [
  {
    id: 'modifyTeamDetails',
    icon: (
      <Image
        src={teamIcon}
        alt="teams.editTeam.actions.teamDetails.altIcon"
        width="space20"
        height="space20"
      />
    ),
    label: 'teams.editTeam.actions.teamDetails.title',
    description: 'teams.editTeam.actions.teamDetails.description',
  },
  {
    id: 'creditCardsSetup',
    icon: (
      <Image
        src={cardIcon}
        alt="teams.editTeam.actions.creditCardsSetup.altIcon"
        width="space20"
        height="space20"
      />
    ),
    label: 'teams.editTeam.actions.creditCardsSetup.title',
    description: 'teams.editTeam.actions.creditCardsSetup.description',
  },
];

interface ActionCardsProps {
  onSetStep: (step: Step) => void;
}

export const ActionCards = ({ onSetStep }: ActionCardsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <Grid>
      {actions.map((action, index) => {
        const isLastItem = actions.length - 1 === index;

        const actionCardStyles = {
          borderBottom: isLastItem ? 'none' : '1px solid gray200',
          borderTopRadius: isLastItem ? 'none' : 'medium',
          borderBottomRadius: isLastItem ? 'medium' : 'none',
        };

        return (
          <Card
            key={index}
            onClick={() => onSetStep(action.id)}
            padding="0px"
            background="gray100"
            {...actionCardStyles}
            style={{ cursor: 'pointer' }}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              padding="space16">
              <Flex alignItems="center" gap="space4">
                {action.icon}
                <Flex gap="space4" alignItems="baseline">
                  <Text fontSize="basic">{t(action.label)}</Text>
                  <Text fontSize="small" color="gray600">
                    {t(action.description)}
                  </Text>
                </Flex>
              </Flex>
              <Icon icon="arrowRight" size="space18" />
            </Flex>
          </Card>
        );
      })}
    </Grid>
  );
};
