import { Text } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import { nocontactData } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface EmptyDataStateProps {
  message?: string;
}

export const EmptyDataState = ({
  message = 'paymentConditions.noData.message',
}: EmptyDataStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <EmptyState animationData={nocontactData}>
      <Text>{t(message)}</Text>
    </EmptyState>
  );
};
