import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';
import { POSTAL_CODE_REGEX } from './consts';
import { isValidVatId } from './isValidVatId';

export const vatIdSchema = z
  .string()
  .min(1)
  .refine(value => isValidVatId(value));

export const tradeNameSchema = z.string().max(20);

export const vatIdAvailableSchema = z.union([
  z.literal('Yes'),
  z.literal('No'),
]);

export const zipCodeSchema = z
  .string()
  .refine(value => Boolean(value.match(new RegExp(POSTAL_CODE_REGEX))));

export const schema = z.object({
  tradeName: tradeNameSchema.nullish(),
  billingEmailAddress: z.string().email(),
  vatIdAvailable: vatIdAvailableSchema,
  vatId: vatIdSchema.optional().nullish(),
  deliveryAddressSameAsBilling: z.literal(false),
  deliveryAddressStreet: z.string(),
  deliveryAddressNumber: z.string(),
  deliveryAddressZipcode: zipCodeSchema,
  deliveryAddressCity: z.string(),
  deliveryAddressCountry: z.string(),
  deliveryAddressAdditionalInfo: z.string().nullish(),
});

export const optionalSchema = z.object({
  tradeName: tradeNameSchema.nullish(),
  billingEmailAddress: z.string().email(),
  vatIdAvailable: vatIdAvailableSchema,
  vatId: vatIdSchema.optional().nullish(),
  deliveryAddressSameAsBilling: z.literal(true),
  deliveryAddressStreet: z.string().nullish(),
  deliveryAddressNumber: z.string().nullish(),
  deliveryAddressZipcode: zipCodeSchema.nullish(),
  deliveryAddressCity: z.string().nullish(),
  deliveryAddressCountry: z.string().nullish(),
  deliveryAddressAdditionalInfo: z.string().nullish(),
});

export const organizationSettingsFormSchema = () => {
  return z.discriminatedUnion('deliveryAddressSameAsBilling', [
    optionalSchema,
    schema,
  ]);
};

export type OrganizationSettingsFormOutupt = z.infer<
  ReturnType<typeof organizationSettingsFormSchema>
>;

export type OrganizationSettingsFormValues =
  Partial<OrganizationSettingsFormOutupt>;

export type OrganizationSettingsFormErrorMessages = ErrorMessages<
  ReturnType<typeof organizationSettingsFormSchema>
>;

export type VatIdAvailable = z.infer<typeof vatIdAvailableSchema>;
