import { Snippets, SnippetsArgs } from './Snippets';

export interface TagManagerArgs {
  /** Choose this based on which semantic element is actually rendered. */
  gtmId: string;
  url: string;
  events?: any;
  dataLayer?: any;
  dataLayerName?: string;
  auth?: string;
  preview?: string;
}

export const TagManager = {
  dataScript: function (dataLayer: string) {
    const script = document.createElement('script');
    script.innerHTML = dataLayer;

    return script;
  },

  gtm: function (args: SnippetsArgs) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;

      return noscript;
    };

    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;

      return script;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },

  initialize: function ({
    gtmId,
    url,
    events = {},
    dataLayer,
    dataLayerName = 'dataLayer',
    auth = '',
    preview = '',
  }: TagManagerArgs) {
    const gtm = this.gtm({
      id: gtmId,
      url: url,
      events: events,
      dataLayer: dataLayer,
      dataLayerName: dataLayerName,
      auth,
      preview,
    });

    if (dataLayer) {
      document.head.appendChild(gtm.dataScript);
    }

    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },

  dataLayer: function ({
    dataLayer,
    dataLayerName = 'dataLayer',
  }: {
    dataLayer: any;
    dataLayerName?: string;
  }) {
    const windowDataLayer = (window as { [key: string]: any })[dataLayerName];

    if (windowDataLayer) {
      return windowDataLayer.push(dataLayer);
    }

    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);
    document.head.insertBefore(dataScript, document.head.childNodes[0]);
  },
};
