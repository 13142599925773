import { isNil } from 'lodash';
import { useCallback } from 'react';

export interface UseInfiniteScrollProps {
  next: () => void;
  hasMore: boolean;
  loading: boolean;
  /**
   * A threshold value defining when to call `next`. Default is 0.8.
   */
  scrollThreshold?: number;
}

export const useInfiniteScroll = ({
  hasMore,
  next,
  loading,
  scrollThreshold = 0.8,
}: UseInfiniteScrollProps) => {
  const onScroll = useCallback(
    (
      element: Pick<HTMLElement, 'scrollTop' | 'offsetHeight' | 'scrollHeight'>
    ) => {
      if (
        isNil(element.scrollTop) ||
        isNil(element.offsetHeight) ||
        isNil(element.scrollHeight)
      ) {
        return;
      }

      const hasReachedScrollBottom =
        element.scrollTop + element.offsetHeight >=
        element.scrollHeight * scrollThreshold;

      if (!loading && hasMore && hasReachedScrollBottom) {
        next();
      }
    },
    [hasMore, loading, next, scrollThreshold]
  );

  return {
    onScroll,
  };
};
