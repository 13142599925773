import { Grid, ScrollBox } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { noop } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { ExpenseSectionContainer } from './components/MiddleSection/ExpenseSectionContainer';
import { GeneralExpensesFormContainer } from './components/MiddleSection/GeneralExpenses/GeneralExpensesFormContainer';
import { HospitalityExpenseFormContainer } from './components/MiddleSection/HospitalityExpenseFormContainer';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { SectionLayout } from './components/SectionLayout';
import { useFormValidationTrigger } from './hooks/useFormValidationTrigger';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';
import { VIEW_URL_PARAM } from './Reimbursement';

const FORM_KEY = 'expenseForms';
interface MiddleSectionContainerProps {
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
}

export const MiddleSectionContainer = ({
  formHelpers,
}: MiddleSectionContainerProps) => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { searchParams } = useMutateSearchParams();
  const view = searchParams.get(VIEW_URL_PARAM);
  const { reimbursementItemsCount } = useReimbursementExpenseItemsList();
  const { reimbursement, loading } = useGetReimbursementById({
    reimbursementId,
  });

  const {
    forms,
    generalExpenseForms,
    onRemoveForm,
    formMethods,
    onRemoveGeneralExpenseForm,
  } = formHelpers;

  useFormValidationTrigger(
    FORM_KEY,
    () => void formMethods.handleSubmit(noop)()
  );

  return (
    <SectionLayout>
      <Grid paddingTop="42.5px" templateRows={!view ? 'auto 1fr' : '85vh'}>
        {view ? (
          <ScrollBox scrollDirection="y" scrollbarGutter="stable">
            <Grid alignContent="start" gap="space24">
              {forms.length > 0 && (
                <HospitalityExpenseFormContainer
                  forms={forms}
                  onRemoveForm={onRemoveForm}
                  formMethods={formMethods}
                />
              )}
              {generalExpenseForms.length > 0 && (
                <GeneralExpensesFormContainer
                  formMethods={formMethods}
                  generalExpensesForms={generalExpenseForms}
                  onRemoveForm={onRemoveGeneralExpenseForm}
                />
              )}
            </Grid>
          </ScrollBox>
        ) : (
          <ExpenseSectionContainer
            loading={loading}
            formHelpers={formHelpers}
            reimbursement={reimbursement}
            expenseCount={reimbursementItemsCount}
          />
        )}
      </Grid>
    </SectionLayout>
  );
};
