import { Box, Grid, Text } from '@candisio/design-system';
import { CardIssuerSettlementStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useGetSettlementsPageBasedData } from 'views/CreditCards/CreditCardsStatements/hooks/useGetSettlementsPageBasedData';
import { SettlementEmptyState } from '../SettlementCard/SettlementEmptyState';
import { SettlementLoadingState } from '../SettlementCard/SettlementLoadingState';
import { SettlementCard } from '../SettlementCard/SettlementmentCard';

export const UpcomingSettlement = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { loading, settlementList } = useGetSettlementsPageBasedData({
    limit: 1,
    status: CardIssuerSettlementStatus.Upcoming,
  });

  const { settlements, totalCount } = settlementList;

  if (loading && totalCount === 0) {
    return (
      <SettlementLoadingState
        title={t('statements.settlementWidget.upcomingSetTitle')}
      />
    );
  }

  if (totalCount === 0) {
    return (
      <SettlementEmptyState
        title={t('statements.settlementWidget.upcomingSetTitle')}
      />
    );
  }

  return (
    <Grid background="gray100" borderRadius="medium" height="fit-content">
      <Box padding="space16" paddingBottom="space8">
        <Text fontSize="large" fontWeight="semibold">
          {t('statements.settlementWidget.upcomingSetTitle')}
        </Text>
      </Box>
      <SettlementCard {...settlements[0]} isLastItem />
    </Grid>
  );
};
