import { Card, Grid, Icon, Paragraph } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CardRequestInfoBox = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background="bluebg">
      <Grid templateColumns="auto 1fr" gap="space8">
        <Icon color="blue700" icon="infoCircle" size="space18" />
        <Paragraph fontSize="basic">
          {t('creditCardFormDataPreview.pendingCardRequest')}
        </Paragraph>
      </Grid>
    </Card>
  );
};
