import { Flex, Icon, IconKey, Link, Text } from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

const StyledLink = styled(Link)`
  right: -0.25rem;
  white-space: nowrap;

  & svg {
    top: 0;
    bottom: 0;
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export const EducationalLink = ({
  icon,
  href,
  label,
}: {
  icon: IconKey;
  href: string;
  label: string;
}) => (
  <StyledLink target="_blank" href={href}>
    <Flex alignItems="center" gap="space8" padding="space4">
      <Icon icon={icon} size="space16" />
      <Text lineHeight="paragraph">{label}</Text>
    </Flex>
  </StyledLink>
);
