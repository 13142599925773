import { Button, Flex, Grid, Text } from '@candisio/design-system';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { CreateDocumentRelationAction } from 'containers/document-relationships/DocumentRelationshipsModal';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { AnimatePresence } from 'framer-motion';
import { EcmDocumentType } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
  isEcmDocumentId,
  useManageRelationshipsData,
} from './useManageRelationshipsData';

type ManageRelationshipsViewProps = RouteComponentProps<{
  organizationSlug: string;
  documentId: string;
}>;

export const ManageRelationshipsView = ({
  match: {
    params: { documentId, organizationSlug },
  },
}: ManageRelationshipsViewProps) => {
  const [t] = useTranslation();
  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    documentId
  );

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };

  const {
    availableDocumentColumnIds,
    canBeSelected,
    configurationsTable,
    customEmptyState,
    filters,
    handleSearchChange,
    handleUpdateConfigurations,
    isLinkingDocumentsPending,
    isLoading,
    linkDocuments,
    onFilter,
    onLoadMore,
    onSort,
    searchQuery,
    selectedDocuments,
    setSelectedDocuments,
    sortBy,
    tableData,
    totalCount,
    isSelectVisible,
  } = useManageRelationshipsData({
    documentId,
    onLinkDocuments: navigateBack,
    previewDocumentId,
  });

  const getTableRowDocumentType = useCallback(
    (documentId: string) => {
      const tableRow = tableData.find(
        row => row.id === documentId || row.invoiceId === documentId
      );

      if (tableRow === undefined) {
        return 'invoice';
      }

      const result =
        tableRow.documentType !== EcmDocumentType.Invoice
          ? 'ecmDocument'
          : 'invoice';

      return result;
    },
    [tableData]
  );

  return (
    <Grid
      autoFlow="column"
      width="100%"
      height="100%"
      templateColumns="1fr auto"
      overflow="hidden">
      <Flex
        direction="column"
        width="100%"
        height="100%"
        padding="space40 space24 space24 space32"
        gap="space16">
        <Grid gap="space24">
          <Grid justifyContent="space-between">
            <Button
              icon="arrowLeft"
              iconPosition="left"
              variant="secondary"
              color="gray"
              onClick={navigateBack}>
              {t('common.back')}
            </Button>
          </Grid>

          <Grid justifyContent="space-between" autoFlow="column">
            <Text fontSize="xxxlarge" lineHeight="paragraph">
              {t('manageRelationships.title')}
            </Text>
          </Grid>
        </Grid>
        <Grid height="100%" width="100%">
          <EcmDocumentsTable
            context="documentRelationships"
            columns={availableDocumentColumnIds}
            data={tableData}
            isLoading={isLoading}
            defaultFilters={filters}
            isTableFiltered={filters.length > 0}
            searchQuery={searchQuery}
            selectedDocumentsCount={totalCount}
            configurationsTable={configurationsTable}
            onUpdateConfigurations={handleUpdateConfigurations}
            defaultSortBy={sortBy}
            onSearchChange={handleSearchChange}
            onFilter={onFilter}
            onEndReached={onLoadMore}
            selectionOptions={{
              selectedRowsIds: selectedDocuments.map(doc => doc.id),
              onSelectionRowChanged: setSelectedDocuments,
              canBeSelected: canBeSelected,
              canBeSelectedTooltipText: t(
                'ecm:documentRelationship.modal.existingRelationshipHint'
              ),
              selectCheckboxProps: row => ({
                isVisible: isSelectVisible(row),
                forceSelected: !canBeSelected(row),
                isReadOnly: !canBeSelected(row),
              }),
            }}
            onSort={onSort}
            customEmptyState={customEmptyState}
            rowOverlay={({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    const isEcmDocument =
                      getTableRowDocumentType(data.id) === 'ecmDocument';

                    setPreviewDocumentId(
                      isEcmDocument ? data.id : data.invoiceId ?? ''
                    );
                  }}
                  isSelected={
                    (data.id === previewDocumentId ||
                      data.invoiceId === previewDocumentId) &&
                    previewDocumentId !== documentId
                  }
                />
              );
            }}
          />
          <AnimatePresence>
            {selectedDocuments.length > 0 && (
              <CreateDocumentRelationAction
                onClick={linkDocuments}
                isLinkingDocuments={isLinkingDocumentsPending}
                selectedDocuments={selectedDocuments}
                totalCount={totalCount}
              />
            )}
          </AnimatePresence>
        </Grid>
      </Flex>
      <DocumentPreviewDrawer
        cursor={
          tableData.find(
            d => d.id === previewDocumentId || d.invoiceId === previewDocumentId
          )?.cursor ?? null
        }
        handlePreviewDocumentIdChange={setPreviewDocumentId}
        onOpen={({ documentId, cursor }) => {
          const path = `/${organizationSlug}${
            Routes.ECM_DOCUMENTS
          }/${documentId}?${cursor ? `cursor=${cursor}` : ''}${
            isEcmDocumentId(documentId) ? undefined : '&isInvoice=true'
          }`;

          window.open(path, '_blank');
        }}
        previewDocumentId={previewDocumentId}
      />
    </Grid>
  );
};
