import { SvgIcon, SvgProps } from '../SvgIcon';

export const UploadFileSuccessIcon = ({ ...props }: SvgProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 23.25C20.5637 23.25 23.25 20.5637 23.25 17.25C23.25 13.9363 20.5637 11.25 17.25 11.25C13.9363 11.25 11.25 13.9363 11.25 17.25C11.25 20.5637 13.9363 23.25 17.25 23.25Z"
        stroke="#00B827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9239 15.505L17.0189 19.379C16.8882 19.5527 16.6887 19.6613 16.4718 19.6768C16.255 19.6923 16.0421 19.6133 15.8879 19.46L14.3879 17.96"
        stroke="#00B827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 20.25H2.25C1.42157 20.25 0.75 19.5784 0.75 18.75V2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H12.879C13.2765 0.750085 13.6578 0.907982 13.939 1.189L16.811 4.061C17.092 4.3422 17.2499 4.72345 17.25 5.121V8.25"
        stroke="#9E9E9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
