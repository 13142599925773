export const DOCUMENT_PROCESSING_SEARCH_PARAMS = {
  CURSOR: 'cursor',
};

export const NONE_PAYMENT_CONDITION_ID = 'none';

export const DOCUMENT_TYPES = {
  RECHNUNGSEINGANG: 'Rechnungseingang',
  RECHNUNGSAUSGANG: 'Rechnungsausgang',
  KASSE: 'Kasse',
  SONSTIGE: 'Sonstige',
  RECHNUNGSKORREKTUR: 'Rechnungskorrektur',
  EINGANGSGUTSCHRIFT: 'Eingangsgutschrift',
} as const;
