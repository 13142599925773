import { Card, Grid, Text, Flex } from '@candisio/design-system';
import { PercentageProgressBar } from 'components/PercentageProgressBar/PercentageProgressBar';
import { determineColorBar } from 'containers/credit-cards/CreditCardCarousel/components/AvailableBalance/utils';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ActiveCardDetailsProps {
  formattedAvailableBalance: string | null;
  formattedLimit: string | null;
  percentage: number;
}

export const ActiveCardDetails = ({
  formattedAvailableBalance,
  formattedLimit,
  percentage,
}: ActiveCardDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background="gray200">
      <Grid gap="space4">
        <Text fontSize="basic" fontWeight="semibold">
          {t('recurringPaymentsModal.linkCard.cardLimits')}
        </Text>
        <Flex direction="column" gap="space8" width="256px">
          <Flex gap="space16">
            <Flex direction="column">
              <Text fontSize="small" color="gray800">
                {t('recurringPaymentsModal.linkCard.available')}
              </Text>
              <Text fontSize="basic" color="gray800" fontWeight="semibold">
                {formattedAvailableBalance}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text fontSize="small" color="gray600">
                {t('recurringPaymentsModal.linkCard.limit')}
              </Text>
              <Text fontSize="basic" color="gray600">
                {formattedLimit}
              </Text>
            </Flex>
          </Flex>
          <PercentageProgressBar
            percentage={percentage}
            color={determineColorBar(percentage)}
          />
          <Text fontSize="basic" color="gray600">
            {t('recurringPaymentsModal.linkCard.text')}
          </Text>
        </Flex>
      </Grid>
    </Card>
  );
};
