import { Grid } from '@candisio/design-system';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { useExportContext } from 'views/Integrations/Export/Context';
import { SelectExportTypeCardList } from 'views/Integrations/Export/toolkit/components/SelectExportTypeCardList';
import { ExportTypeComponent } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/ExportTypeComponent';
import { ExportTypeSelectField } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/ExportTypeSelectField';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';

export const InitNewExportWithCandisAPI = () => {
  const [t] = useTranslation();
  const state = useExportContext();
  const {
    exportDetails,
    isLoading: { loadingExportById },
  } = useExportDataGigaFactory(state);

  const {
    availableExportTypes,
    exportType,
    isReExport,
    timeframe,
    setSelectedProvisions,
  } = state;

  useEffect(() => {
    setSelectedProvisions([]);
  }, [timeframe, setSelectedProvisions]);

  const heading =
    isReExport && exportDetails
      ? t('export.exportForm.exportAgainTitle', {
          date: DateFormatters.compact(exportDetails.createdAt),
        })
      : t('export.exportForm.newExport');

  const isOnlyOneExportTypeAvailable = availableExportTypes.length === 1;

  return (
    <Sidebar isLoading={loadingExportById} heading={heading}>
      {exportType === null ? (
        <SelectExportTypeCardList />
      ) : (
        <>
          <ExportTypeSelectField />
          <Grid
            height="100%"
            paddingTop={!isOnlyOneExportTypeAvailable ? 'space24' : '0'}
            gap="space24">
            <ExportTypeComponent exportType={exportType} />
          </Grid>
        </>
      )}
    </Sidebar>
  );
};
