import { useCallback, useState } from 'react';
import { useTransactionDownloadHistoryData } from './hooks/useTransactionDownloadHistoryData';
import { TransactionDownloadHistoryList } from './TransactionDownloadHistoryList';
import { queryFn } from './utils/queryFn';

export const TransactionDownloadHistoryContainer = () => {
  const [queryString, setQueryString] = useState('');
  const { items, loading, onLoadMore } = useTransactionDownloadHistoryData();

  const dataItems = queryFn(items, queryString);

  const handleSearchFilter = useCallback((filter: string) => {
    setQueryString(filter);
  }, []);

  const handleResetSearch = useCallback(() => {
    setQueryString('');
  }, []);

  return (
    <TransactionDownloadHistoryList
      items={dataItems}
      loading={loading}
      onSearchString={handleSearchFilter}
      onResetSearch={handleResetSearch}
      onLoadMore={onLoadMore}
      queryString={queryString}
    />
  );
};
