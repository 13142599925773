import { Grid, Skeleton } from '@candisio/design-system';

export const LoadingState = () => {
  return (
    <>
      <Skeleton width="12rem" height="space16" />
      <Grid autoFlow="column" gap="space8" alignSelf="end">
        {[...Array(4).keys()].map(n => (
          <Skeleton key={n} width="2rem" height="2rem" borderRadius="medium" />
        ))}
      </Grid>
    </>
  );
};
