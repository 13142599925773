export enum COST_CENTER_SEARCH_PARAM {
  archive = 'archive',
  approvers = 'approvers',
  code = 'code',
  name = 'name',
  sort = 'sort',
  type = 'type',
}

export enum COST_CENTER_ROUTE_HASH {
  import = '#import',
}

export const COST_CENTER_CREATE_ROUTE = 'create';

export const COST_CENTERS_WIDTH = '50rem';
