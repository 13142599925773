import { CashDiscountFieldValue } from 'components/Form/types';
import {
  GetDocumentForDraftQuery,
  GetDocumentQuery,
} from 'generated-types/graphql.types';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';

/**
 * Gets initial `CashDiscountFieldValue` from `Document`.
 *
 * In the first version of the cash discount feature we only show
 * document-level cash discount field to the user. In future we will show
 * booking-level cash discount fields, allowing the user to set discounts per
 * split booking.
 *
 * Since the backend already stores cash discount on booking level we need to
 * map the booking-level fields to the document-level `CashDiscountFieldValue`.
 *
 * @todo handle cash discount at booking level in UI
 */
export const getInitialCashDiscount = (
  document: NonNullable<
    GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument']
  >
): CashDiscountFieldValue => {
  const documentAmount = document.amount?.value ?? undefined;
  const bookings = document.bookings ?? [];

  const firstBookingDiscountAmount =
    document.bookings?.[0]?.discountAmount?.value;

  const firstBookingDiscountPercentage =
    document.bookings?.[0]?.discountPercentage?.value;

  const firstBookingsDiscountPaymentDate =
    document.bookings?.[0]?.discountPaymentDate?.value;

  let amount = firstBookingDiscountAmount ?? undefined;
  const percentage = firstBookingDiscountPercentage ?? undefined;
  const dueDate = firstBookingsDiscountPaymentDate
    ? new Date(firstBookingsDiscountPaymentDate)
    : undefined;

  // We calculate the discount amount for the document based on the discount
  // percentage of the first booking (assumption: percentage is same for all
  // bookings).
  //
  // The sum of the cash discount amounts of the bookings may or may not add up
  // to the document-level cash discount we show to the user. This is also the
  // case in the Datev Unternehmen Online UI so we’re OK with it for now...
  if (
    bookings.length > 1 &&
    typeof documentAmount === 'number' &&
    typeof percentage === 'number'
  ) {
    amount = roundToCurrencyPrecision((documentAmount / 100) * percentage);
  }

  return { amount, percentage, dueDate };
};
