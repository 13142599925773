import { AppRouteParams, Routes } from 'models';
import { generatePath } from 'react-router-dom-v5-compat';
import { NotificationProps } from '../getNotificationInfo';

export const getLinkToCardRequest = (
  notification: NotificationProps<'CardRequestCreatedInAppNotification'>
) => {
  const searchParams = new URLSearchParams();
  searchParams.append('onCreditCardsView', 'true');
  const link = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/:creditCardId`,
      {
        creditCardId: notification.cardId,
        organizationSlug: notification.organizationId,
      }
    ),
    search: searchParams.toString(),
  };

  return { link };
};
