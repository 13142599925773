import { useSubmitReimbursementCaseForReviewMutation } from 'generated-types/graphql.types';

export const useSubmitReimbursementCaseForReview = () => {
  const [submitReimbursmentCaseForReview, { loading }] =
    useSubmitReimbursementCaseForReviewMutation();

  return {
    submitReimbursmentCaseForReview: async (reimbursementId: string) => {
      const response = await submitReimbursmentCaseForReview({
        variables: { id: reimbursementId },
      });

      if (response.data?.submitReimbursementCaseForReview.success === true) {
        return {
          status: 'success',
          numberOfErrorsOnItems: 0,
          numberOfErrorsOnReimbursement: 0,
        };
      }

      const totalNumberOfErrorsInItems =
        response.data?.submitReimbursementCaseForReview.errors.reimbursementItemsErrors?.reduce(
          (acc, value) => {
            return acc + value.errors.length;
          },
          0
        ) ?? 0;

      return {
        status: 'error',
        numberOfErrorsOnReimbursement:
          response.data?.submitReimbursementCaseForReview.errors
            .reimbursementCaseErrors?.errors.length ?? 0,
        numberOfErrorsOnItems: totalNumberOfErrorsInItems,
      };
    },
    isSubmitReimbursmentCaseForReviewPending: loading,
  };
};
