import { Checkbox, Flex, Text } from '@candisio/design-system';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import { useCoreDataExportStatus } from '../hooks/useCoreDataExportStatus';
import { useExportContactsFactory } from '../hooks/useExportContactsFactory';
import { useExportPaymentConditionsFactory } from '../hooks/useExportPaymentConditionsFactory';

type IncludeAllCheckboxProps = {
  isSelected: boolean;
  handleChange: Dispatch<SetStateAction<boolean>>;
  checkboxLabel: string;
};

const IncludeAllCheckbox = ({
  isSelected,
  handleChange,
  checkboxLabel,
}: IncludeAllCheckboxProps) => (
  <Flex background="gray100" alignItems="center" padding="space16">
    <Checkbox isSelected={isSelected} onChange={handleChange}>
      <Text>{checkboxLabel}</Text>
    </Checkbox>
  </Flex>
);

const IncludeAllContacts = () => {
  const [t] = useTranslation();
  const state = useContext(ExportContext);
  const contacts = useExportContactsFactory();

  const {
    includeAllContacts: isSelected,
    setIncludeAllContacts: handleChange,
  } = state;

  const checkboxLabel = t(
    'export.exportDetails.summaryContent.includeAllContacts',
    { count: contacts.count.all }
  );

  return (
    <IncludeAllCheckbox
      isSelected={isSelected}
      handleChange={handleChange}
      checkboxLabel={checkboxLabel}
    />
  );
};

const IncludeAllPaymentConditions = () => {
  const [t] = useTranslation();
  const state = useContext(ExportContext);
  const paymentConditions = useExportPaymentConditionsFactory();

  const checkboxLabel = t(
    'export.exportDetails.summaryContent.includeAllPaymentConditions',
    { count: paymentConditions.count.all }
  );

  const {
    includeAllPaymentConditions: isSelected,
    setIncludeAllPaymentConditions: handleChange,
  } = state;

  return (
    <IncludeAllCheckbox
      isSelected={isSelected}
      handleChange={handleChange}
      checkboxLabel={checkboxLabel}
    />
  );
};

export const IncludeAllCoreDataEntities = () => {
  const { showExportAllContacts, showExportAllPaymentConditions, isLoading } =
    useCoreDataExportStatus();

  if (isLoading) return null;

  if (showExportAllContacts) {
    return <IncludeAllContacts />;
  }

  if (showExportAllPaymentConditions) {
    return <IncludeAllPaymentConditions />;
  }

  return null;
};
