import {
  DocumentDetailsCardQuery,
  useDocumentDetailsCardQuery,
} from 'generated-types/graphql.types';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentDetailsCard } from './DocumentDetailsCard';

export const DocumentDetailsCardContainer = ({
  documentId,
  wrapperStyle,
  organizationSlug,
  header,
  renderActions,
}: {
  documentId: string;
  organizationSlug: string;
  wrapperStyle?: object;
  renderActions?: (
    doc: NonNullable<DocumentDetailsCardQuery['getDocument']>
  ) => ReactElement[];
  header: {
    title: string;
    secondaryTitle?: string;
    color: string;
  };
}) => {
  const [t] = useTranslation();
  const { data, loading } = useDocumentDetailsCardQuery({
    variables: { id: documentId },
  });

  const document = data?.getDocument;

  if (loading) {
    return (
      <DocumentDetailsCard
        t={t}
        organizationSlug={organizationSlug}
        loading={loading}
        wrapperStyle={wrapperStyle}
        header={header}
      />
    );
  }

  if (!document) {
    // Error state
    return null;
  }

  const actions = renderActions ? renderActions(document) : undefined;

  return (
    <DocumentDetailsCard
      wrapperStyle={wrapperStyle}
      t={t}
      organizationSlug={organizationSlug}
      document={document}
      loading={loading}
      actions={actions}
      header={header}
    />
  );
};
