import { UpdateSingleUseCardLimitFormErrorMessages } from './updateSingleUseCardLimitFormSchema';

export const updateSingleUseCardLimitFormErrorMessages: UpdateSingleUseCardLimitFormErrorMessages =
  {
    purpose: {
      label: 'ccRequestForm.fields.purpose.label',
    },
    transactionLimit: {
      label: 'dashboard.drawer.form.oneTimePaymentTxLimit.label',
    },
    comment: {
      label: 'dashboard.drawer.form.comment.label',
    },
  };
