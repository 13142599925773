import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ExportConnection } from 'generated-types/graphql.types';
import { paginationWithRecordConnectionsMerge } from '../pagination-results-merge';

const exportsHistoryPagination: FieldPolicy<ExportConnection> = {
  merge: paginationWithRecordConnectionsMerge('ExportConnection'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      _exports: exportsHistoryPagination,
    },
  },
};
