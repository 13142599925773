import { useQuery } from '@apollo/client';
import { Locale, Organization, Query } from 'generated-types/graphql.types';
import gql from 'graphql-tag';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { organizationDataFragment } from '../queries';

const query = gql`
  query getOrganizations($input: GetOrganizationsInput, $sort: [SortInput!]) {
    getOrganizations(input: $input, sort: $sort) {
      ...OrganizationData
    }
  }
  ${organizationDataFragment}
`;

export interface OrganizationData {
  isLoading: boolean;
  locale: Locale;
  name: string;
  taxNumber: string;
  vatId: string;
  ssoConfigurationId?: string;
  organization?: Organization;
  hasOrgTaxDetails: boolean;
}

export const useOrganizationData = (): OrganizationData => {
  const organizationId = useOrganizationId();
  const { data, loading: isLoading } = useQuery<Query>(query);

  const organization = data?.getOrganizations?.find(
    org => org.slug === organizationId
  );

  const hasOrgTaxDetails = Boolean(
    organization?.organizationContactInformation?.taxNumber ||
      organization?.organizationContactInformation?.vatId
  );

  return {
    isLoading,
    locale: organization?.locale ?? Locale.De,
    name: organization?.name ?? '',
    taxNumber: organization?.organizationContactInformation?.taxNumber ?? '',
    vatId: organization?.organizationContactInformation?.vatId ?? '',
    ssoConfigurationId: organization?.ssoConfigurationId ?? '',
    organization,
    hasOrgTaxDetails,
  };
};
