import { Card } from '@candisio/design-system';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { CreditCardsMissingInvoicesTrackingEvents } from 'providers/AnalyticsProvider/events/CreditCardsMissingInvoicesTrackingEvents';
import { Key, useState } from 'react';
import { useTransactionsInsights } from 'views/CreditCards/hooks/useTransactionsInsights';
import { MissingInvoicesWidget } from './MissingInvoicesWidget';
import {
  DateOptionMissingInvoicesWidget,
  dateFilterManifest,
  mappedDateRangeFilters,
} from './utils';

export const MissingInvoicesWidgetContainer = () => {
  const [dateOption, setDateOption] =
    useState<DateOptionMissingInvoicesWidget>('All');

  const { track } = useAnalytics();

  const currentDate = new Date();

  const dateRange = dateFilterManifest({ currentDate, dateOption });

  const dateRangeFilters = mappedDateRangeFilters(dateRange);

  const { loading, mappedCardholdersCCInsights } = useTransactionsInsights({
    dateRangeFilters,
  });

  const handleDateOptionChange = (key: Key[]) => {
    const option = key.length
      ? (key[0] as DateOptionMissingInvoicesWidget)
      : dateOption;

    track(
      CreditCardsMissingInvoicesTrackingEvents.MISSING_INVOICES_WIDGET
        .FILTER_APPLIED,
      { filterUsed: option }
    );
    setDateOption(option);
  };

  return (
    <Card background="gray50">
      <MissingInvoicesWidget
        loading={loading}
        dateRange={dateRange}
        mappedCardholdersCCInsights={mappedCardholdersCCInsights}
        dateOption={dateOption}
        handleDateOptionChange={handleDateOptionChange}
      />
    </Card>
  );
};
