import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import {
  HookFormPaginatedComboBoxField,
  HookFormPaginatedComboBoxFieldProps,
} from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { useTranslation } from 'react-i18next';

export const AccountingArea = ({
  readOnly,
  ...props
}: Partial<
  HookFormPaginatedComboBoxFieldProps<DocumentApprovalFormValues>
>) => {
  const [t] = useTranslation();

  return (
    <HookFormPaginatedComboBoxField
      {...props}
      name="accountingArea"
      label={t('document.requestApproval.inputs.accountingArea.label')}
      placeholder={t(
        'document.requestApproval.inputs.accountingArea.placeholder'
      )}
      readOnly={readOnly}
    />
  );
};
