import { queryParameter } from 'components/Table/consts';
import { TransactionsTableData } from 'components/Transactions/Table/types';
import {
  availableFilters,
  mapToFilterInput,
} from 'components/Transactions/Table/util';
import { useCreateCsvFileForTransactionsMutation } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCallback } from 'react';
import { downloadFile } from 'utils/downloadFile';

export const useDownloadCSVFile = () => {
  const { filters } = useUrlBasedSortAndFilter<TransactionsTableData>({
    availableFilters,
  });

  const sanitizedFilters = mapToFilterInput(filters);

  const { searchParams } = useMutateSearchParams();
  const searchQueryParam = searchParams.get(queryParameter) ?? '';

  const [createCSVFile, { loading }] = useCreateCsvFileForTransactionsMutation({
    variables: {
      filters: sanitizedFilters,
      queries: {
        query: searchQueryParam,
      },
    },
  });

  const downloadCSV = useCallback(async () => {
    const response = await createCSVFile();

    const fileName = response.data?.createCsvFileForTransactions?.file?.name;
    const fileUrl = response.data?.createCsvFileForTransactions?.file?.url;

    if (!fileUrl || !fileName) {
      return { status: 'error' };
    }

    try {
      downloadFile(fileUrl, fileName);

      return { status: 'success' };
    } catch (error) {
      return { status: 'error' };
    }
  }, [createCSVFile]);

  return {
    downloadCSV,
    isCreateCSVFilePending: loading,
  };
};
