import { useDateConverter } from 'hooks/useDateConverter';
import { useMemo } from 'react';
import { GeneralExpense } from './useFormattedGeneralExpense';

interface UseInitialGeneralExpenseFormValuesParams {
  generalExpenses: GeneralExpense[];
}

const defaultValuesNewForm = {
  expenseDate: '',
  expenseId: '',
  files: [],
  reason: '',
  totalAmount: 0,
};

export const createGeneralDefaultValues = (generalExpenseId: string) => ({
  ...defaultValuesNewForm,
  expenseId: generalExpenseId,
});

export const useInitialGeneralExpenseFormValues = ({
  generalExpenses,
}: UseInitialGeneralExpenseFormValuesParams) => {
  const { dateTimeStringToDateString } = useDateConverter();

  const defaultGeneralExpenses = useMemo(() => {
    return generalExpenses.map(generalExpense => ({
      expenseId: generalExpense.id,
      reason: generalExpense.reason ?? '',
      totalAmount: generalExpense.totalAmount ?? 0,
      expenseDate: generalExpense.expenseDate
        ? dateTimeStringToDateString(generalExpense.expenseDate)
        : '',
      files: (generalExpense.files ?? []).map(file => ({
        id: file.id ?? '',
        name: file.name ?? '',
        url: file.url ?? '',
      })),
    }));
  }, [dateTimeStringToDateString, generalExpenses]);

  return { defaultGeneralExpenses };
};
