import {
  WorkflowStepCondition,
  WorkflowStepSkippedEventDataFragment,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { Collapse } from '../../History/items/Collapse';
import { OrderList } from '../styles';

export const WorkflowStepSkippedComponent = ({
  users,
  workflowStep,
}: WorkflowStepSkippedEventDataFragment) => {
  const [t] = useTranslation();

  const conditions = workflowStep?.conditions;

  return (
    <>
      {conditions?.length
        ? t('document.compact.history.workflowStepSkipped.title') + ' '
        : null}
      {conditions?.length
        ? conditions
            .map((step: WorkflowStepCondition) => {
              // eslint-disable-next-line candis/no-template-strings-inside-translation
              const field = t(
                `document.compact.history.workflowStepSkipped.reason.field.${step.field}`
              );

              // eslint-disable-next-line candis/no-template-strings-inside-translation
              const operator = t(
                `document.compact.history.workflowStepSkipped.reason.operator.${step.operator}`
              );

              return t(
                'document.compact.history.workflowStepSkipped.reason.composed',
                { field, operator, value: step.values[0] }
              );
            })
            .join(t('common.andSpaced'))
        : null}
      <Collapse>
        <OrderList>
          {users &&
            users.map(
              user =>
                user && (
                  <li key={user.id}>
                    {t('document.compact.history.workflowStepSkipped.by', user)}
                  </li>
                )
            )}
        </OrderList>
      </Collapse>
    </>
  );
};
