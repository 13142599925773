import { FieldPolicy, TypePolicies } from '@apollo/client';
import { TransactionConnection } from 'generated-types/graphql.types';
import { paginationWithRecordConnectionsMerge } from '../pagination-results-merge';

const cardIssuerTransactions: FieldPolicy<TransactionConnection> = {
  keyArgs: ['filters', 'sortBy', 'queries'],
  merge: paginationWithRecordConnectionsMerge('TransactionConnection'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      archiveCardIssuerTransactions: cardIssuerTransactions,
      inboxCardIssuerTransactions: cardIssuerTransactions,
      unassociatedCardIssuerTransactions: cardIssuerTransactions,
    },
  },
};
