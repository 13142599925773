import { PaymentConditionsDrawer } from 'views/Settings/PaymentConditions/CoreDataApi/PaymentConditionsDrawer';
import { usePaymentConditionDataCoreDataApi } from 'views/Settings/PaymentConditions/CoreDataApi/usePaymentConditionDataCoreDataApi';

export interface PaymentConditionsDrawerCoreDataApiContainerProps {
  id?: string;
  onClose?: () => void;
}

/** Handles data fetching for PaymentConditionsDrawer component */
export const PaymentConditionsDrawerContainerCoreDataApi = ({
  id,
  onClose,
}: PaymentConditionsDrawerCoreDataApiContainerProps) => {
  const { data, loading } = usePaymentConditionDataCoreDataApi({
    id,
  });

  const isOpen = id !== undefined;

  return (
    <PaymentConditionsDrawer
      isOpen={isOpen}
      onClose={onClose}
      defaultValues={data}
      loading={loading}
    />
  );
};
