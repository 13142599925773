import { FieldContainerProps } from '@candisio/design-system';
import { useSap } from 'orgConfig/sap';
import { FieldPath } from 'react-hook-form';
import { ProcessingFormFieldMetadataMessage } from './ProcessingFormFieldMetadataMessage';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormFieldMetadata } from './useProcessingFormFieldMetadata';

/**
 * Returns `fieldProps` to pass to form fields used in processing form based on
 * field metadata
 */
export const useProcessingFormField = (
  name: FieldPath<ProcessingFormValues>
) => {
  const fieldMetadata = useProcessingFormFieldMetadata(name);
  const { isActiveIntegration: isSapIntegration } = useSap();

  const message = fieldMetadata ? (
    <ProcessingFormFieldMetadataMessage {...fieldMetadata} />
  ) : undefined;

  const variant: FieldContainerProps['variant'] | undefined = fieldMetadata
    ? fieldMetadata.confidence < 0.9
      ? 'warning'
      : 'success'
    : undefined;

  const readOnly =
    fieldMetadata !== undefined &&
    fieldMetadata.source === 'transaction' &&
    // adding this check to enable editing for delivery date field
    // for SAP we are using delivery date field as posting date
    // TODO: remove this logic when posting date has it's own field
    !(name === 'deliveryDate' && isSapIntegration);

  return {
    fieldProps: {
      message,
      variant,
      readOnly,
    },
  };
};
