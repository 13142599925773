import { Grid } from '@candisio/design-system';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { useGetCardById } from 'containers/credit-cards/utils';
import { ReplaceCardLayout } from './Forms/ReplaceCardForm/ReplaceCardLayout';
import { UpdateAccountingInfoContainer } from './Forms/UpdateAccountingInfoContainer/UpdateAccountingInfoContainer';
import { UpdateCardLabelAndLimit } from './Forms/UpdateCardLabelAndLimit/UpdateCardLabelAndLimit';

interface UpdateCreditCardDataOptionsProps {
  mode: DrawerMode;
  cardId: string;
  onSetMode: (mode: DrawerMode) => void;
}

export const UpdateCreditCardDataOptions = ({
  cardId,
  mode,
  onSetMode,
}: UpdateCreditCardDataOptionsProps) => {
  const { card } = useGetCardById({ cardId });

  if (!card) {
    return null;
  }

  const { status, hasAccountingData, type } = card;

  const handleClose = () => {
    onSetMode('cardDetails');
  };

  return (
    <Grid height="100%">
      {mode === 'cardLimits' && (
        <UpdateCardLabelAndLimit
          onClose={handleClose}
          cardType={type}
          cardStatus={status}
          cardId={cardId}
        />
      )}
      {mode === 'accountingSettings' && (
        <UpdateAccountingInfoContainer
          cardId={cardId}
          onClose={handleClose}
          cardStatus={status}
          hasAccountingData={hasAccountingData}
        />
      )}
      {mode === 'replaceCard' && <ReplaceCardLayout onClose={handleClose} />}
    </Grid>
  );
};
