import { Link, Text, useTheme } from '@candisio/design-system';
import { ConnectedInvoicesData } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { TruncatedCellContent } from './TruncatedCellContent';

export const InvoiceListCell = ({
  connectedInvoices,
  columnWidth,
}: {
  columnWidth?: string;
  connectedInvoices?: ConnectedInvoicesData[];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { space } = useTheme();
  const organizationSlug = useOrganizationId();
  if (!connectedInvoices || connectedInvoices?.length === 0) {
    return <Text>-</Text>;
  }

  const connectedInvoicesList = connectedInvoices.map((invoice, index) => {
    return (
      <Link
        key={invoice.invoiceId}
        href={`/${organizationSlug}${Routes.ARCHIVE}/${invoice.invoiceId}`}
        external
        fontSize="small"
        height="space24"
        style={{
          marginRight:
            index !== connectedInvoices.length - 1 ? space.space8 : 0,
        }}>
        {invoice.invoiceNumber || t('columns.connectedInvoices')}
      </Link>
    );
  });

  const handleClick: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
  };

  return (
    <TruncatedCellContent onClick={handleClick} maxWidth={columnWidth}>
      {connectedInvoicesList}
    </TruncatedCellContent>
  );
};
