import { useExportZipMutation } from 'generated-types/graphql.types';
import {
  ExportStatus,
  ExportType as GqlExportType,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { getRefetchExportsQueries } from 'views/Integrations/Export/toolkit/queries';
import { Sidebar, View } from 'views/Integrations/Export/types';

export const useZipExport = () => {
  const state = useExportContext();
  const navigate = useNavigate();
  const { addExport } = useContext(ExportNotificationsContext);
  const counterQueries = useCounterQueries();
  const orgId = useOrganizationId();
  const {
    exportEntities: {
      ready: { all: allEntities },
    },
    exportEntitiesIds,
    exportAll,
  } = useExportDataGigaFactory(state);

  const { isReExport } = useExportDataGigaFactory(state);

  const { documentIds } = exportEntitiesIds;
  const [requestZipExportMutation, { loading: zipExportMutationLoading }] =
    useExportZipMutation({
      refetchQueries: [
        ...getRefetchExportsQueries({ documentIds }),
        ...counterQueries,
      ],
      awaitRefetchQueries: true,
    });

  const hasExportableEntity = !!documentIds.length;

  const onZipExport = async () => {
    const { data: zipExportRes } = await requestZipExportMutation({
      variables: {
        documentIds: exportAll ? [] : documentIds,
        originalExportGuid: state.exportId,
        exportAll,
      },
    });

    if (!zipExportRes) return;

    addExport({
      hash: zipExportRes.exportZip,
      organization: orgId ?? '',
      status: ExportStatus.Exporting,
      documents: allEntities.length,
      type: GqlExportType.Zip,
    });

    const viewDef = defineExportView(
      {
        ...state,
        sidebar: Sidebar.HISTORY,
        view: View.HISTORY,
        exportId: zipExportRes.exportZip,
        shouldTriggerDownload: true,
      },
      { orgId }
    );

    navigate(viewDef.navigate);
  };

  return {
    onZipExport,
    zipExportMutationLoading,
    hasExportableEntity,
    isReExport,
  };
};
