import {
  SetAbsenceForTeamMemberInput,
  SetCurrentUserAbsenceInput,
} from 'generated-types/graphql.types';
import moment from 'moment-timezone';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';
import { AbsenceFormOutput } from './absenceFormSchema';

export const useGetSetAbsenceInput = () => {
  const timezone = useOrganizationTimezone();

  const getSetAbsenceInput = (
    formOutput: AbsenceFormOutput
  ): SetCurrentUserAbsenceInput | SetAbsenceForTeamMemberInput => ({
    fromDate: moment.tz(formOutput.fromDate, timezone).toISOString(),
    toDate: moment
      .tz(formOutput.toDate, timezone)
      .set({
        // set the toDate to end of day
        hour: 23,
        minute: 59,
        second: 59,
      })
      .toISOString(),
    note: formOutput.note,
    substituteMembershipId: formOutput.substitute,
  });

  return {
    getSetAbsenceInput,
  };
};
