import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom-v5-compat';
import { useGetTeamById } from 'views/Settings/Teams/toolkit/hooks/useGetTeamById';
import { useGetTeamSettingsForCreditCards } from 'views/Settings/Teams/toolkit/hooks/useGetTeamSettingsForCreditCards';
import { useSetTeamIsArchived } from 'views/Settings/Teams/toolkit/hooks/useSetTeamIsArchived';
import { Step } from 'views/Settings/Teams/toolkit/utils/types';
import { TeamLoading } from '../../components/TeamLoading';
import { ArchivedTeam } from './ArchivedTeam';
import { Team } from './Team';

interface TeamContainerProps {
  onClose: () => void;
  onSetStep: (step: Step) => void;
}

export const TeamContainer = ({ onClose, onSetStep }: TeamContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const { loading, team } = useGetTeamById({ id });
  const { isHandleSetTeamIsArchivedPending, setTeamIsArchived } =
    useSetTeamIsArchived();

  const { loading: isSettingsForCCsLoading, settingsForCreditCards } =
    useGetTeamSettingsForCreditCards({
      teamId: id,
    });

  const handleArchiveTeam = async () => {
    if (!id) {
      return;
    }

    const isTeamArchived = await setTeamIsArchived({ id, isArchived: true });

    if (isTeamArchived?.status === 'success') {
      success(t('teams.editTeam.archivePopover.success'));
    } else {
      error(t('teams.editTeam.archivePopover.error'));
    }
  };

  const handleRestoreTeam = async () => {
    if (!id) {
      return;
    }

    const isTeamArchived = await setTeamIsArchived({ id, isArchived: false });

    if (isTeamArchived?.status === 'success') {
      success(t('teams.archivedTeam.success'));
    } else {
      error(t('teams.archivedTeam.error'));
    }
  };

  if (loading || isSettingsForCCsLoading) {
    return <TeamLoading />;
  }

  const isTeamArchived = team.isArchived;

  const isTeamAssignedToCCs = settingsForCreditCards.creditCards.length > 0;

  return isTeamArchived ? (
    <ArchivedTeam
      team={team}
      isRestorePending={isHandleSetTeamIsArchivedPending}
      onClose={onClose}
      onRestoreTeam={handleRestoreTeam}
    />
  ) : (
    <Team
      team={team}
      isArchivingPending={isHandleSetTeamIsArchivedPending}
      isTeamAssignedToCCs={isTeamAssignedToCCs}
      onClose={onClose}
      onSetStep={onSetStep}
      onArchiveTeam={handleArchiveTeam}
    />
  );
};
