import { Link } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import { workflowData } from 'components/Lottie/Lottie';
import { Trans } from 'providers/LocaleProvider';
import { useNavigate } from 'react-router-dom-v5-compat';

interface NoWorkflowsFoundProps {
  archiveCount?: number;
  onCreate?: () => void;
}

export const NoWorkflowTemplates = ({
  archiveCount,
  onCreate,
}: NoWorkflowsFoundProps) => {
  const navigate = useNavigate();

  const handleArchive = () => {
    navigate({
      search: 'archive=true',
    });
  };

  const archivedWorkflows =
    archiveCount && archiveCount > 0 ? archiveCount + 1 : undefined;

  return (
    <EmptyState animationData={workflowData}>
      <Trans i18nKey="settings:workflows.nodata" count={archivedWorkflows}>
        There are no workflows yet
        <br />
        Please
        <Link as="button" onClick={onCreate}>
          Create
        </Link>
        or go to
        <Link as="button" onClick={handleArchive}>
          archived
        </Link>
      </Trans>
    </EmptyState>
  );
};
