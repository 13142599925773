import { TruncatedText } from '@candisio/design-system';
import { castArray } from 'lodash';

export const GenericCell = ({
  value,
}: {
  value?: string | number | [string, ...string[]];
}) => {
  if (!value) {
    return <div>-</div>;
  }

  const values = castArray(value);

  return (
    <TruncatedText
      wordBreak="break-word"
      fontStyle={values.length > 1 ? 'italic' : undefined}>
      {String(values[0])}
    </TruncatedText>
  );
};
