import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
} from '@candisio/design-system';
import { AddOnIcon } from 'containers/Entitlements/components/Upsell/components/AddOnIcon';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { UserRole } from 'generated-types/graphql.types';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useTranslation } from 'react-i18next';
import { AbsenceFormSkeleton } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/AbsenceFormSkeleton';
import { AbsenceForm } from './AbsenceForm';

export interface AbsenceFormWrapperProps {
  defaultValuesLoading?: boolean;

  userId: string | undefined;
  userRoles: UserRole[] | undefined | null;
}

export const AbsenceFormWrapper = ({
  defaultValuesLoading,
  userId,
  userRoles,
}: AbsenceFormWrapperProps) => {
  const [t] = useTranslation();
  const { ft, promo } = useFeatureToggle();

  if (promo(ft.ABSENCE_MANAGEMENT)) {
    return (
      <Grid gap="space8">
        <Flex direction="column">
          <Flex alignItems="center" gap="0.5rem">
            <Heading as="h3">
              {t('header.profile.tabs.update.absence.label')}
            </Heading>
            <AddOnIcon />
          </Flex>
          <UpsellPromo feature={FEATURE.ABSENCE_MANAGEMENT} />
        </Flex>
      </Grid>
    );
  }

  return (
    <Grid gap="space8">
      <Grid autoFlow="column" justifyContent="space-between">
        <Heading as="h3">
          {t('header.profile.tabs.update.absence.label')}
        </Heading>
        <Button
          id="substitute-feedback-button"
          icon="megaphone"
          variant="tertiary"
          color="blue">
          {t('common.feedbackButton')}
        </Button>
      </Grid>
      <Box
        as="div"
        borderRadius="medium"
        padding="space16"
        background="gray100">
        {defaultValuesLoading ? (
          <AbsenceFormSkeleton />
        ) : (
          <Grid gap="space8">
            <Text fontSize="basic">
              {t('header.profile.tabs.update.absence.absent')}
            </Text>

            <AbsenceForm userId={userId} userRoles={userRoles} />
          </Grid>
        )}
      </Box>
    </Grid>
  );
};
