import { Card, Grid } from '@candisio/design-system';
import { useShowSensitiveData } from 'containers/credit-cards/CreditCard/useShowSensitiveData';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardThumbnailAndLimit } from './CardThumbnailAndLimit';
import { CreditCardActions } from './CreditCardActions/CreditCardActions';
import { LoadingCardDetails } from './LoadingCardDetails';

interface CreditCardDetailsAndActionsProps {
  cardId: string;
  onClose: () => void;
  onSetMode: (mode: DrawerMode) => void;
}

export const CreditCardDetailsAndActions = ({
  cardId,
  onClose,
  onSetMode,
}: CreditCardDetailsAndActionsProps) => {
  const { card, loading } = useGetCardById({ cardId });

  const { token, uniqueToken, sensitiveData } = useShowSensitiveData();

  if (loading) {
    return <LoadingCardDetails />;
  }

  if (!card) {
    return null;
  }

  const {
    refNum,
    type: cardType,
    status: cardStatus,
    cardholder: { firstName, lastName, membershipId },
  } = card;

  const cardholderName = `${firstName} ${lastName}`;

  const props = {
    onClose,
    cardId,
    refNum,
    cardType,
    cardStatus,
    sensitiveData,
    onSetMode,
    cardholder: {
      cardholderName,
      membershipId,
    },
  };

  return (
    <Card background="gray100">
      <Grid gap="space16">
        <CardThumbnailAndLimit
          cardId={cardId}
          cardType={cardType}
          cardholderName={cardholderName}
          token={token}
          uniqueToken={uniqueToken}
        />
        <CreditCardActions {...props} />
      </Grid>
    </Card>
  );
};
