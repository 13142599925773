import { Box } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ProvisionPromotionVideoThumbnail = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const wistaId = t('provisions.lockedCard.helpCenter.wistiaId');

  return (
    <Box
      className={`provision-promotion-wistia wistia_embed wistia_async_${wistaId} wistia_responsive_wrapper`}
      style={{ width: '657px', height: '369.36px' }}
      width={657}
      height={369.36}
    />
  );
};
