import { Box } from '@candisio/design-system';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

const ACTIONS_HEIGHT_BUFFER_PX = 20;
const ACTIONS_HEIGHT_FALLBACK_VALUE = 140;

const calculateMargin = (scrollMargin?: number) =>
  (scrollMargin || ACTIONS_HEIGHT_FALLBACK_VALUE) + ACTIONS_HEIGHT_BUFFER_PX;

/**
 * Fix focused controls hidden behind the sticky content
 * @link: https://tetralogical.com/blog/2023/06/08/focus-in-view/
 */
export const BoxWrapper = styled(Box)<{
  $scrollMargin?: number;
}>`
  & * {
    scroll-padding: ${({ $scrollMargin }) =>
      `${calculateMargin($scrollMargin)}px`};
  }
`;
