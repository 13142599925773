import gql from 'graphql-tag';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';

export const workflowFragment = gql`
  fragment WorkflowTemplateData on WorkflowTemplate {
    id
    name
    description
    isArchived
  }
`;

export const createWorkflowTemplateMutation = gql`
  mutation createWorkflowTemplate($input: CreateWorkflowTemplateInput!) {
    createWorkflowTemplate(input: $input) {
      ...WorkflowTemplateData
    }
  }
  ${workflowFragment}
`;

export const getWorkflowTemplateQuery = gql`
  query getWorkflowTemplate($id: ID!) {
    workflowTemplate(id: $id) {
      ...WorkflowTemplateData
      steps {
        conditions {
          field
          operator
          values
        }
        resolvers {
          id
          name
          avatarUrl
        }
      }
    }
  }
  ${workflowFragment}
`;

export const updateWorkflowTemplateMutation = gql`
  mutation updateWorkflowTemplate(
    $id: ID!
    $input: UpdateWorkflowTemplateInput!
  ) {
    updateWorkflowTemplate(id: $id, input: $input) {
      ...WorkflowTemplateData
      steps {
        conditions {
          field
          operator
          values
        }
        resolvers {
          id
          name
          avatarUrl
        }
      }
    }
  }
  ${workflowFragment}
`;

export const archiveWorkflowTemplateMutation = gql`
  mutation archiveWorkflowTemplate($id: ID!) {
    archiveWorkflowTemplate(id: $id) {
      ...WorkflowTemplateData
    }
  }
  ${workflowFragment}
`;

export const restoreWorkflowTemplateMutation = gql`
  mutation restoreWorkflowTemplate($id: ID!) {
    restoreWorkflowTemplate(id: $id) {
      ...WorkflowTemplateData
    }
  }
  ${workflowFragment}
`;

export const getWorkflowTemplateCountsQuery = gql`
  query workflowTemplatesCounters {
    workflowTemplatesCounters {
      archived
      active
    }
  }
`;

export const getWorkflowTemplatesQuery = gql`
  query workflowTemplates($input: GetWorkflowTemplatesInput, $sort: SortInput) {
    workflowTemplates(input: $input, sort: $sort) {
      id
      name
      description
      createdAt
      creator {
        name
        avatarUrl
      }
      steps {
        resolvers {
          id
          name
          avatarUrl
        }
      }
    }
  }
`;

export const refetchWorkflowTemplates = (id?: string) => [
  {
    query: getFullOrganizationQuery,
  },
  {
    query: getWorkflowTemplateCountsQuery,
  },
  {
    query: getWorkflowTemplatesQuery,
    variables: {
      input: {
        isArchived: true,
      },
    },
  },
  {
    query: getWorkflowTemplatesQuery,
    variables: {
      input: {
        isArchived: false,
      },
    },
  },
  ...(id
    ? [
        {
          query: getWorkflowTemplateQuery,
          variables: {
            id,
          },
        },
      ]
    : []),
];
