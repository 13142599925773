import { Flex, Grid, Heading, Skeleton, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const SelectMemberStepLoading = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid
      as="form"
      height="100%"
      padding="space24"
      paddingBottom="0px"
      gap="3rem"
      templateRows="auto auto 1fr">
      <Flex direction="column" gap="space16">
        <Heading as="h2">{t('ccRequestForm.teamMemberStep.title')}</Heading>
        <Skeleton width="space256" height="space40" />
      </Flex>
      <Flex direction="column" gap="space16">
        <Text fontSize="basic" color="gray600">
          {t('ccRequestForm.teamMemberStep.desc')}
        </Text>
        <Flex gap="space10">
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton height="space36" width="space128" key={index} />
          ))}
        </Flex>
      </Flex>
    </Grid>
  );
};
