import { useMemo } from 'react';
import { useLocale } from './useLocale';

/**
 * Returns plural rules for ordinal numbers (1st, 2nd, 3rd, etc.) according to
 * the current locale
 *
 * @todo update i18next/Lokalise to use the new JSON format so we don’t need
 * this helper: https://www.i18next.com/translation-function/plurals
 */
export const useOrdinalRules = () => {
  const locale = useLocale();
  const ordinalRules = useMemo(
    () => new Intl.PluralRules(locale, { type: 'ordinal' }),
    [locale]
  );

  return ordinalRules;
};
