import { cloneDeep, get, isEqual, set } from 'lodash';
import { ReactNode, useCallback, useState } from 'react';
import {
  ProcessingFormMetadata,
  ProcessingFormMetadataContext,
} from './ProcessingFormMetadataContext';
import {
  ProcessingFormSetFieldMetadata,
  ProcessingFormSetMetadataContext,
} from './ProcessingFormSetMetadataContext';

export interface ProcessingFormProviderProps {
  children: ReactNode;
  defaultMetadata?: ProcessingFormMetadata;
}

/** Provider for getting and setting processing form metadata */
export const ProcessingFormMetadataProvider = ({
  children,
  defaultMetadata = {},
}: ProcessingFormProviderProps) => {
  const [metadata, setMetadata] = useState(defaultMetadata);

  // useCallback gives us a stable reference to the setter function
  const setFieldMetadata: ProcessingFormSetFieldMetadata = useCallback(
    (path, meta) => {
      setMetadata(prevData => {
        if (isEqual(get(prevData, path), meta)) {
          // performance optimization: don’t update metadata state if new value
          // is equal to old according to Lodash isEqual
          return prevData;
        }

        const newData = cloneDeep(prevData);
        set(newData, path, meta);

        return newData;
      });
    },
    []
  );

  return (
    // We use two separate React contexts: one for the metadata itself and one
    // for the setter.
    // This is a necessary performance optimization. See this guide from the
    // React docs for more info:
    // https://react.dev/learn/scaling-up-with-reducer-and-context
    <ProcessingFormMetadataContext.Provider value={metadata}>
      <ProcessingFormSetMetadataContext.Provider value={setFieldMetadata}>
        {children}
      </ProcessingFormSetMetadataContext.Provider>
    </ProcessingFormMetadataContext.Provider>
  );
};
