import { WatchQueryFetchPolicy } from '@apollo/client';
import { IconKey } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CardholderMembership,
  CardStatus,
  CardType,
  GetCardIssuerCardByIdQuery,
  useGetCardIssuerCardByIdQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { isNil } from 'lodash';
import moment from 'moment';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';

export const DEFAULT_TOOLTIP_DELAY = 500;

interface UseGetCardByIdParams {
  cardId: string | undefined;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export const useGetCardById = ({
  cardId,
  fetchPolicy = 'cache-first',
}: UseGetCardByIdParams) => {
  const isInvalidCardId = !cardId || cardId === 'request';

  const { data, loading } = useGetCardIssuerCardByIdQuery({
    variables: { id: cardId ?? '' },
    skip: isInvalidCardId,
    fetchPolicy,
  });

  const cardNotFound = !loading && !data?.getCardIssuerCardById;

  if (isInvalidCardId || loading || cardNotFound) {
    return { card: null, loading };
  }

  const card = (data?.getCardIssuerCardById ||
    {}) as GetCardIssuerCardByIdQuery['getCardIssuerCardById'];

  return { card, loading };
};

export const useIsCardManagerOnCreditCardsView = () => {
  const history = useHistory();
  const { isCardManager } = useUserRoles();

  const { onCreditCardsView } = qs.parse(history.location.search);

  return !isNil(onCreditCardsView) && isCardManager;
};

export const getCardholderName = (
  cardholder: Pick<
    CardholderMembership,
    'avatarUrl' | 'firstName' | 'lastName' | 'membershipEmail'
  >
) => {
  const firstName = cardholder.firstName;
  const lastName = cardholder.lastName;
  const membershipEmail = cardholder.membershipEmail;

  const cardholderName =
    firstName && lastName ? `${firstName} ${lastName}` : membershipEmail;

  if (!cardholderName) {
    console.error('Failed to determine cardholder name');
  }

  return cardholderName || '';
};

export const getCardholderNameFromCard = (
  card: Pick<GetCardIssuerCardByIdQuery['getCardIssuerCardById'], 'cardholder'>
) => {
  return getCardholderName(card.cardholder);
};

export const extractCardDetails = (
  card: Pick<
    GetCardIssuerCardByIdQuery['getCardIssuerCardById'],
    | 'expiryDate'
    | 'refNum'
    | 'cardholder'
    | 'label'
    | 'pendingRequest'
    | 'status'
    | 'type'
    | 'purpose'
  >
) => {
  let requestedLabel: string | undefined;

  if (
    card.pendingRequest?.__typename === 'VirtualCardRequest' ||
    card.pendingRequest?.__typename === 'PhysicalCardRequest'
  ) {
    requestedLabel = card.pendingRequest.label ?? undefined;
  }

  if (card.pendingRequest?.__typename === 'SingleUseVirtualCardRequest') {
    requestedLabel = card.pendingRequest.purpose;
  }

  const shouldShowData = !Boolean(
    card.type === CardType.Physical && card.status === CardStatus.Pending
  );

  const cardLabel =
    card.type === CardType.Virtual || card.type === CardType.Physical
      ? card.label
      : card.purpose;

  return {
    cvv: '•••',
    expiration:
      shouldShowData && card.expiryDate
        ? moment.utc(card.expiryDate).format('MM/YY')
        : '••/••',
    name: getCardholderNameFromCard(card),
    number: shouldShowData && card.refNum ? `•••• ${card.refNum}` : '',
    label: cardLabel ?? requestedLabel,
  };
};

export const extractCardDetailsPlaceholder = (username: string) => {
  return {
    name: username,
    cvv: '',
    expiration: '',
    number: '',
    label: undefined,
  };
};

export const overlayVariant: Record<string, { icon: IconKey; text: string }> = {
  LOCKED: {
    icon: 'lock',
    text: 'creditCardComponent.locked',
  },
  TERMINATED: {
    icon: 'close',
    text: 'creditCardComponent.terminated',
  },
  PENDING: {
    icon: 'user',
    text: 'creditCardComponent.pending',
  },
  REQUESTED: {
    icon: 'sandclock',
    text: 'creditCardComponent.requested',
  },
  EXPIRED: {
    icon: 'close',
    text: 'creditCardComponent.expired',
  },
  LOCKED_PIN: {
    icon: 'lock',
    text: 'creditCardComponent.lockedByPin',
  },
};

export const statuseWithOverlay = [
  CardStatus.Locked,
  CardStatus.LockedPin,
  CardStatus.Terminated,
  CardStatus.Requested,
  CardStatus.Pending,
  CardStatus.Expired,
];

export const useGenericErrorMessageHandler = () => {
  const { error } = useToastMessage();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const genericErrorMessageHandler = useCallback(() => {
    error(t('dashboard.genericErrorMessage'));
  }, [error, t]);

  return genericErrorMessageHandler;
};
