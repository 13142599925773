import { Box, Button, Grid, Paragraph } from '@candisio/design-system';
import { HelpCenterArticleLink } from 'components/HelpCenterArticleLink/HelpCenterArticleLink';
import { FormFileField } from 'components/HookFormFields/HookFormFileField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CsvParser } from 'services/CSVParser';
import { zodResolver } from 'utils/zodFormValidation';
import { handlePCErrors } from './errors';
import { formConfig, SelectFileFormValues } from './formConfig';
import { PaymentsConditionsParser } from './PaymentsConditionsParser';
import { PaymentConditionsData } from './types';

export interface PaymentConditionsImportFormProps {
  onSubmit: (data: PaymentConditionsData) => void;
}

export const PaymentConditionsImportForm = ({
  onSubmit,
}: PaymentConditionsImportFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const {
    handleSubmit: RHFhandleSubmit,
    control,
    getValues,
    setError,
  } = useForm<SelectFileFormValues>({
    defaultValues: {},
    resolver: zodResolver({
      zodSchema: formConfig.formSchema,
      errorMessages: formConfig.formErrors(t),
      translationNamespace: 'settings',
    }),
    mode: 'onSubmit',
  });

  const handleSubmit = RHFhandleSubmit(async () => {
    const file = getValues('file') as File;
    if (!file) {
      return;
    }

    const paymentConditionParser = new PaymentsConditionsParser(
      new CsvParser()
    );

    try {
      await paymentConditionParser.validateCSVFormat(file);
      const parsedPaymentConditions =
        await paymentConditionParser.parsePaymentConditionCSV(file);

      onSubmit?.({ parsedPaymentConditions, file: file });
    } catch (error) {
      handlePCErrors(error, setError, t);
    }
  });

  return (
    <Grid
      as="form"
      onSubmit={handleSubmit}
      gap="space24"
      alignItems="start"
      maxWidth="37rem"
      paddingX="space32">
      <Grid gap="space12">
        <Paragraph>{t('paymentConditions.import.description')}</Paragraph>
        <HelpCenterArticleLink
          articleUrl={t('paymentConditions.import.importHelpCenterLink')}>
          {t('paymentConditions.import.helpCenterArticleCopy')}
        </HelpCenterArticleLink>
      </Grid>
      <FormFileField
        accept="text/csv"
        buttonLabel={t('paymentConditions.import.steps.selectFile')}
        control={control}
        name="file"
        placeholder={t('paymentConditions.import.input.label')}
      />
      <Box>
        <Button type="submit">
          {t('paymentConditions.import.submitButton')}
        </Button>
      </Box>
    </Grid>
  );
};
