import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export interface CardUsageFormSchemaOptions {
  isSingleUseCard: boolean;
}

export const cardUsageFormSchema = ({
  isSingleUseCard,
}: CardUsageFormSchemaOptions) => {
  const schema = z.object({
    cardLabel: z.string().optional().nullable(),
    validity: z.string(),
    purpose: isSingleUseCard ? z.string() : z.string().optional(),
  });

  return schema;
};

export type CardUsageFormOutput = z.infer<
  ReturnType<typeof cardUsageFormSchema>
>;

export type CardUsageFormFormValues = Partial<CardUsageFormOutput>;

export type CardUsageFormErrorMessages = ErrorMessages<
  ReturnType<typeof cardUsageFormSchema>
>;
