import { Button, Grid, Spinner } from '@candisio/design-system';
import { PdfViewer } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';

interface ExpenseInvoicePreviewContainerProps {
  invoice?: ExpenseInvoice | null;
  createInvoicePending: boolean;
  onReplaceInvoice: () => void;
}

export const ExpenseInvoicePreviewContainer = ({
  invoice,
  createInvoicePending,
  onReplaceInvoice,
}: ExpenseInvoicePreviewContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  if (createInvoicePending) {
    return (
      <Grid
        minHeight="190px"
        background="gray0"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%">
        <Spinner size="space40" />
      </Grid>
    );
  }

  if (!invoice) {
    return null;
  }

  return (
    <Grid
      gap="space24"
      height="100%"
      background="gray0"
      overflow="auto"
      padding="space8 space16"
      borderRadius="medium">
      <PdfViewer
        documentFile={invoice}
        disabledControls={['downloadFiles']}
        attachmentSection={
          <Button
            alignSelf="center"
            variant="tertiary"
            size="small"
            onClick={onReplaceInvoice}>
            {t('reimbursementView.middleSection.form.replaceCta')}
          </Button>
        }
      />
    </Grid>
  );
};
