import {
  Drawer,
  Item,
  TabPanel,
  Tabs,
  useId,
  useTabListState,
} from '@candisio/design-system';
import { ReActivateRow } from 'components/ArchiveWrapper/ArchiveWrapper';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useOtherIntegration } from 'orgConfig/other';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';
import { CONTACT_TABS } from 'views/Contacts/consts';
import { ContactDrawerFooter } from 'views/Contacts/ContactDetails/ContactDrawerFooter';
import { ContactHistoryContainer } from 'views/Contacts/ContactDetails/ContactHistory/ContactHistoryContainer';
import { PaymentConditionsFormOutput } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm';
import { useSap } from '../../../orgConfig/sap';
import { ContactForm, ContactFormProps } from './ContactForm';
import { CheckAccountsNumber } from './ContactForm/accountsNumberSchema';
import { CheckContactName } from './ContactForm/contactNameSchema';
import { PaymentConditionItemProps } from './ContactForm/PaymentConditionItem';
import { ContactFormSkeleton } from './ContactFormSkeleton';

export interface ContactDetailsProps {
  /** Unique id of contact to display contact history, leave undefined for new contact */
  contactId: string | undefined;
  /** Contact form action */
  action: 'create' | 'edit';
  /** Callback to check if contact name exsits */
  checkContactName?: CheckContactName;
  /** Callback to check if accounts payable number is already assigned */
  checkAccountsPayableNumber?: CheckAccountsNumber;
  /** Callback to check if accounts receivable number is already assigned */
  checkAccountsReceivableNumber?: CheckAccountsNumber;
  /** Initial values shown in the contact form */
  defaultValues?: ContactFormProps['defaultValues'];
  /** Is contact details drawer open? */
  isOpen?: boolean;
  /** Legacy due date offset stored on the contact */
  legacyDueDateOffset?: number;
  /** Show as loading */
  isContactDataLoading?: boolean;
  /** Called when contact details drawer should close */
  onClose?: () => void;
  /** Called when new payment condition should be created */
  onCreatePaymentCondition?: (
    values: PaymentConditionsFormOutput
  ) => Promise<string | undefined>;
  /** Called when contact details form is submitted */
  onSubmit: ContactFormProps['onSubmit'];
  /** Items to display in payment conditions field */
  paymentConditionItems?: PaymentConditionItemProps[];
  /** Is contact details form submitting? */
  submitting?: boolean;
  /** Accounts payable number to suggest to the user */
  suggestedAccountsPayableNumber?: string;
  /** Is contact active (not archived)? */
  isActive?: boolean;
  /** Called when contact archiving is confirmed */
  onArchive?: () => void;
  /** Called on contact activation */
  onActivate?: () => void;
  /** Is contact being archived? */
  archiving?: boolean;
  /** Is contact being activated? */
  activating?: boolean;
}

/** Contact details drawer containing form for creating/editing a contact */
export const ContactDetails = ({
  contactId,
  action,
  checkContactName,
  checkAccountsPayableNumber,
  checkAccountsReceivableNumber,
  defaultValues,
  isOpen = false,
  legacyDueDateOffset,
  isContactDataLoading = false,
  onClose,
  onCreatePaymentCondition,
  onSubmit,
  paymentConditionItems,
  submitting = false,
  suggestedAccountsPayableNumber,
  onArchive,
  isActive,
  activating,
  onActivate,
  archiving = false,
}: ContactDetailsProps) => {
  const [t] = useTranslation();
  const formId = useId();
  const { shouldUseSapContacts } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  const [provideContactAuditLogsToUserFF, contactsArchivingFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.provideContactAuditLogsToUser,
      FEATURE_FLAGS.contactsArchiving,
    ]);

  const titleName =
    shouldUseSapContacts || shouldUseCoreDataApi
      ? t('settings.contacts.details.tabs.view')
      : action === 'create'
      ? t('settings.contacts.details.tabs.create')
      : t('settings.contacts.details.tabs.edit');

  const paymentConditionPlaceholder =
    legacyDueDateOffset !== undefined
      ? t(
          'settings.contacts.details.edit.paymentCondition.placeholderWithLegacyDueDateOffset',
          { legacyDueDateOffset }
        )
      : t('settings.contacts.details.edit.paymentCondition.placeholder');

  const archivingFeatureEnabled = contactsArchivingFF && !shouldUseSapContacts;

  const tabsProps = {
    'aria-label': titleName,
    items: [
      {
        key: CONTACT_TABS.details,
        title: titleName,
        children: isContactDataLoading ? (
          <ContactFormSkeleton />
        ) : (
          <>
            {archivingFeatureEnabled && action === 'edit' && !isActive && (
              <ReActivateRow
                onRestore={onActivate}
                isSubmitting={activating}
                disabled={submitting || archiving || activating}
              />
            )}
            <ContactForm
              shouldUseSapContacts={shouldUseSapContacts}
              shouldUseCoreDataApi={shouldUseCoreDataApi}
              action={action}
              checkContactName={checkContactName}
              checkAccountsPayableNumber={checkAccountsPayableNumber}
              checkAccountsReceivableNumber={checkAccountsReceivableNumber}
              defaultValues={defaultValues}
              id={formId}
              onCreatePaymentCondition={onCreatePaymentCondition}
              onSubmit={onSubmit}
              paymentConditionItems={paymentConditionItems}
              paymentConditionPlaceholder={paymentConditionPlaceholder}
              suggestedAccountsPayableNumber={suggestedAccountsPayableNumber}
              onClose={onClose}
              isActive={isActive}
            />
          </>
        ),
      },
      ...(provideContactAuditLogsToUserFF
        ? [
            {
              key: CONTACT_TABS.history,
              title: t('common:settings.contacts.details.tabs.history'),
              children: <ContactHistoryContainer contactId={contactId} />,
            } as const,
          ]
        : []),
    ],
    defaultSelectedKey: CONTACT_TABS.details,
    children: (item: any) => (
      <Item key={item.key} title={item.title} textValue={item.title}>
        {item.children}
      </Item>
    ),
  };

  const tabsState = useTabListState(tabsProps);

  const handleClose = () => {
    tabsState.setSelectedKey(CONTACT_TABS.details);

    if (onClose) {
      onClose();
    }
  };

  const showSaveButton =
    !shouldUseSapContacts &&
    !shouldUseCoreDataApi &&
    tabsState.selectedItem?.key === CONTACT_TABS.details;

  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerLayout
        hasTabs
        footer={
          <ContactDrawerFooter
            formId={formId}
            submitting={submitting}
            isSaveButtonVisible={showSaveButton}
            isActive={isActive}
            archiveButton={
              archivingFeatureEnabled &&
              isActive &&
              action === 'edit' && (
                <ConfirmationButton
                  onConfirm={onArchive}
                  confirmationTitle={t(
                    'settings.contacts.details.edit.confirmArchive',
                    { count: 1 }
                  )}
                  variant="tertiary"
                  color="red"
                  loading={archiving}
                  disabled={submitting || archiving}>
                  {t('settings.contacts.details.edit.archive')}
                </ConfirmationButton>
              )
            }
          />
        }
        header={<Tabs {...tabsProps} state={tabsState} />}
        onClose={handleClose}>
        <TabPanel key={tabsState.selectedItem?.key} state={tabsState} />
      </DrawerLayout>
    </Drawer>
  );
};
