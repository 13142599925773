import {
  Badge,
  Box,
  Button,
  Checkbox,
  CustomEmptyStateProps,
  Flex,
  Grid,
  Heading,
  MenuButton,
  MenuItem,
  SelectionOptions,
  TabPanel,
  Tabs,
} from '@candisio/design-system';
import { ContactKebabMenu } from 'components/Menu/ContactKebabMenu/ContactKebabMenu';
import { queryParameter } from 'components/Table/consts';
import { useTabs } from 'components/Tabs/useTabs';
import { AnimatePresence } from 'framer-motion';
import { ContactRelationshipType } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { usePath } from 'utils/hooks';
import { ContactImportContainer } from 'views/Contacts/ContactImport/ContactImportContainer';
import { ContactImportErrorsPopup } from 'views/Contacts/ContactImport/ContactImportErrorsPopup/ContactImportErrorsPopup';
import { useSap } from '../../orgConfig/sap';
import { ContactDetailsRoute } from './ContactDetails';
import { useActivateContacts } from './ContactDetails/useActivateContacts';
import { useArchiveContacts } from './ContactDetails/useArchiveContacts';
import { useContactImportErrorsPopup } from './ContactImport/ContactImportErrorsPopup/useContactImportErrorsPopup';
import {
  ContactsTableData,
  ContactTableDS,
} from './ContactTable/ContactTableDS/ContactTableDS';
import { useContactColumnsConfigurations } from './ContactTable/ContactTableDS/hooks/useContactColumnsConfigurations';
import {
  TabFilterParamsNew,
  useContactTableDSDataNew,
} from './ContactTable/ContactTableDS/hooks/useContactTableDSDataNew';
import { useAvailableContactsFilters } from './ContactTable/ContactTableDS/utils/const';
import { ContactsEmptyState } from './integrations/components/EmptyState/ContactsEmptyState';
import { SapFeedbackCard } from './integrations/components/SapFeedbackCard';
import { MergeModal } from './MergeModal';
import { ContactsRouteParams } from './models';
import { SelectionCard } from './SelectionCard';
import { CONTACT_ROUTE_HASH, CONTACT_SEARCH_PARAM } from './types';

type Props = {} & RouteComponentProps<ContactsRouteParams>;

type QuickFilters = 'showAll' | 'showSuppliers' | 'showCustomers';

const quickFilterMapping: Record<
  QuickFilters,
  ContactRelationshipType[] | undefined
> = {
  showAll: undefined,
  showSuppliers: [
    ContactRelationshipType.Supplier,
    ContactRelationshipType.SupplierCustomer,
  ],
  showCustomers: [
    ContactRelationshipType.Customer,
    ContactRelationshipType.SupplierCustomer,
  ],
};

const getRelationshipTypeFromQuickFilter = (quickFilter: QuickFilters) => {
  return {
    [CONTACT_SEARCH_PARAM.relationshipType]: quickFilterMapping[quickFilter],
  };
};

const getQuickFilterFromRelationshipType = (
  relationshipType: ContactRelationshipType[]
): QuickFilters => {
  if (!relationshipType || relationshipType.length === 0) {
    return 'showAll';
  }

  if (relationshipType.includes(ContactRelationshipType.Supplier)) {
    return 'showSuppliers';
  }

  if (relationshipType.includes(ContactRelationshipType.Customer)) {
    return 'showCustomers';
  }

  return 'showAll';
};

export const CONTACT_CREATE_ROUTE = 'create';

export const Contacts = ({
  location: { search },

  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const navigate = useNavigate();

  const location = useLocation();
  const availableFilters = useAvailableContactsFilters();
  const { sortBy, onSort, filters, onFilter } =
    useUrlBasedSortAndFilter<ContactsTableData>({
      availableFilters,
    });

  const { archiving, handleArchive } = useArchiveContacts();
  const { activating, handleActivate } = useActivateContacts();

  const [selectedContactRows, setSelectedContactsRows] = useState<
    ContactsTableData[]
  >([]);

  const { updateSearchParam, searchParams } = useMutateSearchParams();

  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const { shouldUseSapContacts } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const [t] = useTranslation();
  const [mergedContactId, setMergedContactId] = useState<string>();

  const { hasError: hasImportError, loading: importErrorLoading } =
    useContactImportErrorsPopup();

  const [isMergeModalVisible, setMergeModalVisible] = useState(false);

  const openMergeModal = useCallback(() => {
    setMergedContactId(undefined);
    setMergeModalVisible(true);
  }, []);

  const {
    pathGenerator,
    queryParams: {
      [CONTACT_SEARCH_PARAM.noAccountsPayableNumber]:
        isActiveTabForNoAccountsPayableNumbers,
    },
  } = usePath<
    ContactsRouteParams,
    {
      [CONTACT_SEARCH_PARAM.noAccountsPayableNumber]?: boolean;
    }
  >();

  const {
    contactsTableData,
    allContactsLoading,
    onLoadMore,
    contactRecords,
    refetchCurrentPage,
    activeTab,
    handleDebounceSearch,
    activeContactsLoading,
    activeCount,
    archivedContactsLoading,
    archivedCount,
    contactCount,
    contactsWithoutAccountsPayableNumberCount,
  } = useContactTableDSDataNew({
    filters,
    sortBy,
    mergedContactId,
  });

  const isTableFiltered = filters.length > 0;

  const isTableEmpty = contactRecords.length === 0;

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return (
      <ContactsEmptyState
        onCreate={onCreate}
        showContactsWithoutAccountsPayableNumber={Boolean(
          isActiveTabForNoAccountsPayableNumbers
        )}
        isTableEmpty={isTableEmpty}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const onCreate = useCallback(() => {
    navigate(
      {
        pathname: pathGenerator.stringify({ contactId: CONTACT_CREATE_ROUTE }),
        search,
      },
      {
        replace: true,
      }
    );
  }, [navigate, pathGenerator, search]);

  const visible = location.hash === CONTACT_ROUTE_HASH.import;

  const selectionOptions: SelectionOptions<ContactsTableData> = useMemo(
    () => ({
      onSelectionRowChanged: setSelectedContactsRows,
      selectedRowsIds: selectedContactRows.map(contact => contact.id),
    }),
    [selectedContactRows]
  );

  const handleOnRowClick = (id: string) => {
    navigate({
      pathname: `/${organizationSlug}${Routes.CONTACTS}/${id}`,
      search: qs.stringify(qs.parse(search)),
    });
  };

  const {
    configurationsTable,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    visibleColumnIds,
    isLoadingConfigs,
  } = useContactColumnsConfigurations();

  const handleSearch = (search: string) => {
    handleDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  const relationshipType = qs.parse(search)[
    CONTACT_SEARCH_PARAM.relationshipType
  ] as ContactRelationshipType[];

  const quickFilter = getQuickFilterFromRelationshipType(relationshipType);

  const menuItems: MenuItem[] = [
    {
      id: 'showAll',
      label: t('settings.contacts.quickFilters.allTypes'),
    },
    {
      id: 'showSuppliers',
      label: t('settings.contacts.quickFilters.suppliers'),
    },
    {
      id: 'showCustomers',
      label: t('settings.contacts.quickFilters.customers'),
    },
  ] as const;

  const selectedQuickFilterLabel =
    menuItems.find(i => i.id === quickFilter)?.label ?? menuItems[0].label;

  const selectedContactIds = selectedContactRows.map(row => row.id);

  const showSelectionCard =
    selectedContactIds.length > 0 && !shouldUseSapContacts;

  const contactName = useMemo(() => {
    if (selectedContactIds.length === 1) {
      return contactRecords.find(
        contact => contact.id === selectedContactIds[0]
      )?.name?.value;
    }
  }, [selectedContactIds, contactRecords]);

  const table = (
    <ContactTableDS
      columns={visibleColumnIds}
      customEmptyState={customEmptyState}
      data={contactsTableData}
      defaultFilters={filters}
      configurationsTable={configurationsTable}
      isLoadingConfigs={isLoadingConfigs}
      onUpdateConfigurations={handleUpdateConfigurations}
      onResetConfigurations={handleResetTableConfigurations}
      defaultSortBy={sortBy}
      onSearch={handleSearch}
      search={queryStringFilter}
      isLoading={allContactsLoading}
      onCreate={onCreate}
      onEndReached={onLoadMore}
      onFilter={onFilter}
      onRowClick={handleOnRowClick}
      onSort={onSort}
      showContactsWithoutAccountsPayableNumber={Boolean(
        isActiveTabForNoAccountsPayableNumbers
      )}
      selectionOptions={
        shouldUseSapContacts || shouldUseCoreDataApi
          ? undefined
          : selectionOptions
      }
      key={`contacts-table-${visibleColumnIds.join('-')}`}
      borderBottomRadius={showSelectionCard ? 'none' : undefined}
      additionalContent={
        <MenuButton
          variant="tertiary"
          items={menuItems}
          onChange={keys => {
            const quickFilter = keys[0] as QuickFilters;
            const quickFilterParam =
              getRelationshipTypeFromQuickFilter(quickFilter);

            navigate({
              search: qs.stringify({
                ...qs.parse(search),
                ...quickFilterParam,
              }),
            });
          }}
          value={[quickFilter]}
          selectionMode="single">
          {selectedQuickFilterLabel}
        </MenuButton>
      }
    />
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: TabFilterParamsNew.ACTIVE,
        title: t('settings.contacts.tabs.showActive'),
        badge: !activeContactsLoading ? String(activeCount) : undefined,
        children: table,
      },
      {
        key: TabFilterParamsNew.ARCHIVED,
        title: t('settings.contacts.tabs.showArchived'),
        badge: !archivedContactsLoading ? String(archivedCount) : undefined,
        children: table,
      },
    ],
    onSelectionChange: key => {
      const isArchived = key === TabFilterParamsNew.ARCHIVED;

      navigate({
        search: qs.stringify({
          ...qs.parse(search),
          [CONTACT_SEARCH_PARAM.isArchived]: isArchived,
        }),
      });
      setSelectedContactsRows([]);
    },
    selectedKey: activeTab,
  });

  const shouldShowSapFeedbackPanel =
    shouldUseSapContacts && !hasImportError && !importErrorLoading;

  const renderButtons = () => {
    switch (true) {
      case shouldUseCoreDataApi:
        return null;
      case shouldUseSapContacts:
        return (
          <>
            <SyncFromSap
              btnProps={{ size: 'small' }}
              type={SAP_SYNC.Contacts}
            />
            <Button
              width="max-content"
              size="small"
              variant="secondary"
              onClick={() =>
                navigate(`/${organizationSlug}${Routes.CONTACT_IMPORT_HISTORY}`)
              }>
              {t('settings.contacts.contextMenu.history')}
            </Button>
          </>
        );
      default:
        return (
          <Button size="small" onClick={onCreate}>
            {t('settings.contacts.actions.new')}
          </Button>
        );
    }
  };

  // TODO: add e2e tests for contact archive/activate
  const handleArchivisation = useCallback(async () => {
    await handleArchive(selectedContactIds, contactName);
    setSelectedContactsRows([]);
  }, [contactName, handleArchive, selectedContactIds]);

  const handleActivation = useCallback(async () => {
    await handleActivate(selectedContactIds, contactName);
    setSelectedContactsRows([]);
  }, [contactName, handleActivate, selectedContactIds]);

  return (
    <Box
      padding="space40 space32 0 space32"
      background="gray200"
      height="100%"
      overflow="hidden">
      <Grid
        gap="space24"
        templateRows={
          hasImportError || shouldShowSapFeedbackPanel
            ? 'auto auto 1fr'
            : 'auto 1fr'
        }
        height="100%">
        <Flex alignItems="center" justifyContent="space-between">
          <Grid autoFlow="column" alignItems="center" gap="space32">
            <Heading as="h1">{t('settings.contacts.title')}</Heading>
            {!shouldUseCoreDataApi && <Tabs {...tabsProps} />}
          </Grid>
          <Grid autoFlow="column" alignItems="center" gap="space16">
            {quickFilter !== 'showCustomers' &&
              !shouldUseSapContacts &&
              !shouldUseCoreDataApi && (
                <Checkbox
                  isSelected={isActiveTabForNoAccountsPayableNumbers || false}
                  onChange={() => {
                    navigate({
                      search: qs.stringify({
                        ...qs.parse(search),
                        [CONTACT_SEARCH_PARAM.noAccountsPayableNumber]:
                          !isActiveTabForNoAccountsPayableNumbers
                            ? true
                            : undefined,
                      }),
                    });
                  }}>
                  <Flex gap="space8" alignItems="center">
                    {t(
                      'settings.contacts.tabs.showWithoutAccountsPayableNumber'
                    )}
                    <Badge color="darkGray">
                      {String(contactsWithoutAccountsPayableNumberCount)}
                    </Badge>
                  </Flex>
                </Checkbox>
              )}
            <Flex gap="space8">
              {renderButtons()}
              <ContactKebabMenu organizationSlug={organizationSlug} />
            </Flex>
          </Grid>
        </Flex>
        <ContactImportErrorsPopup organizationSlug={organizationSlug} />
        {shouldShowSapFeedbackPanel && (
          <SapFeedbackCard localStorageKey="sap_feedback_contacts_view_dismissed" />
        )}
        <Grid templateRows="1fr" paddingBottom="space24" height="100%">
          <TabPanel {...tabPanelProps} height="100%" overflow="hidden" />
          <AnimatePresence>
            {showSelectionCard && (
              <SelectionCard
                count={selectedContactIds.length}
                totalCount={contactCount ?? 0}
                onMerge={openMergeModal}
                onArchive={handleArchivisation}
                isArchiving={archiving}
                mode={
                  activeTab === TabFilterParamsNew.ACTIVE
                    ? 'archiving'
                    : 'activating'
                }
                onActivate={handleActivation}
                isActivating={activating}
              />
            )}
          </AnimatePresence>
        </Grid>
      </Grid>

      <ContactDetailsRoute />

      {visible ? <ContactImportContainer /> : null}

      <MergeModal
        contacts={contactRecords.filter(contact =>
          selectedContactIds.some(id => id === contact.id)
        )}
        resetSelectedContacts={() => {
          setSelectedContactsRows([]);
        }}
        isVisible={isMergeModalVisible}
        setIsVisible={setMergeModalVisible}
        setMergedContactId={id => {
          setMergedContactId(id);
          refetchCurrentPage();
        }}
      />
    </Box>
  );
};
