import { DateRangeFilterFormErrorMessages } from './dateRangeFilterSchema';

export const dateRangeFilterErrorMessages: DateRangeFilterFormErrorMessages = {
  fromDate: {
    label: 'table:dateFrom',
  },
  toDate: {
    label: 'table:dateTo',
  },
};
