import { Box } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Carousel } from '../Carousel/Carousel';
import { usePromotionsContext } from '../context/PromotionsProvider';
import { PromotionCard } from '../PromotionCard/PromotionCard';

export const PromotionsCarousel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { visiblePromotions, goToSlide, currentCardIndex } =
    usePromotionsContext();

  const promotionCards = visiblePromotions.map(promotion => (
    <PromotionCard promotion={promotion} key={promotion.id} />
  ));

  if (!visiblePromotions.length) return null;

  return (
    <Box paddingBottom="space24">
      <Carousel
        cards={promotionCards}
        title={t('title')}
        currentCardIndex={currentCardIndex}
        goToSlide={goToSlide}
      />
    </Box>
  );
};
