import {
  Button,
  Grid,
  Heading,
  Icon,
  Tooltip,
  useTheme,
  useTooltip,
} from '@candisio/design-system';
import { AcknowledgePopover } from 'components/AcknowledgePopover/AcknowledgePopover';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Locale } from 'generated-types/graphql.types';
import { useTeams } from 'orgConfig/teams/useTeams';
import { useCurrentUser } from 'providers/CurrentUserProvider/useCurrentUser';
import { useState } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AbsenceFormOutput } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/absenceFormSchema';
import { AbsenceFormWrapper } from 'views/AppContainer/components/Header/components/UserProfile/AbsenceManagement/AbsenceFormWrapper';
import { UserTeamsWithLinks } from 'views/AppContainer/components/Header/components/UserProfile/UserTeams/UserTeamsWithLinks';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { usePermissionsForTeamMembers } from 'views/Settings/TeamMembers/hooks/usePermissionsForTeamMembers';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { MembershipInvolvementContainer } from '../../components/MembershipInvolvement/MembershipInvolvementContainer';
import { RolesSection } from '../../invite/InviteNew/RolesSection';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
} from '../../teamMemberFormSchema';
import { DeactivateMembershipButton } from './DeactivateMembershipButton';
import { UserAvatar } from './UserAvatar';

export type TeamMemberWithAbsenceFormOutput = TeamMemberFormOutput &
  AbsenceFormOutput;

export type TeamMemberFormDirtyFields = UseFormReturn<
  TeamMemberWithAbsenceFormOutput,
  TeamMemberFormSchemaOptions
>['formState']['dirtyFields'];

export interface ActiveTeamMemberFormProps {
  absenceValuesLoading?: boolean;
  closeDrawer: () => void;
  formId: string;
  involvementErrorMessage?: string;
  isSubmittingDeactivateMembership: boolean;
  isSubmittingUpdateUser: boolean;
  loading: boolean;
  onDeactivateMembership: () => void;
  onForceDeactivateMembership: (membershipId: string) => void;
  onShowRolesStep: () => void;
  onForceUpdate: (data: TeamMemberFormOutput) => void;
  onSubmit: (
    formOutput: TeamMemberWithAbsenceFormOutput,
    modifiedFields?: TeamMemberFormDirtyFields
  ) => void;
  organizationSlug: string;
  pendingApprovalsAcknowledgeMessage?: string;
  readOnly?: {
    firstName?: boolean;
    lastName?: boolean;
    roles?: boolean;
    locale?: boolean;
  };

  selectedUser: User;
  teams: Team[];
}

export const ActiveTeamMemberForm = ({
  absenceValuesLoading,
  closeDrawer,
  formId,
  involvementErrorMessage,
  isSubmittingDeactivateMembership,
  isSubmittingUpdateUser,
  loading,
  onDeactivateMembership,
  onForceDeactivateMembership,
  onForceUpdate,
  onSubmit,
  onShowRolesStep,
  organizationSlug,
  pendingApprovalsAcknowledgeMessage,
  readOnly,
  selectedUser,
  teams,
}: ActiveTeamMemberFormProps) => {
  const { isTeamsFeatureEnabled } = useTeams();

  const form = useFormContext<TeamMemberFormOutput>();

  const [t] = useTranslation();
  const { canDeactivateMember } = usePermissionsForTeamMembers();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const { colors } = useTheme();

  const isSubmitting =
    isSubmittingDeactivateMembership || isSubmittingUpdateUser;

  const currentUser = useCurrentUser();

  const disabled = loading || isSubmitting;

  const [involvedCount, setInvolvedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const locales = Object.values(Locale).map(value => ({
    // eslint-disable-next-line candis/no-template-strings-inside-translation
    children: t(`locales.${value}`),
    key: value,
  }));

  const shouldShowDeactivateActionLink =
    !loading && currentUser?.id !== selectedUser.id && canDeactivateMember;

  const dirtyFields = form.formState.dirtyFields;

  return (
    <DrawerLayout
      onClose={closeDrawer}
      header={
        <Heading as="h3">
          {isTeamsFeatureEnabled
            ? t('settings.users.details.tabs.edit')
            : t('settings.teamMembers.details.tabs.edit')}
        </Heading>
      }
      footer={
        <Grid
          autoFlow="column"
          justifyContent="space-between"
          data-cy="team-member-invite-action">
          {pendingApprovalsAcknowledgeMessage ? (
            <AcknowledgePopover
              onAcknowledged={() => {
                onForceUpdate(form.getValues());
              }}
              title={t(
                'settings.teamMembers.form.involvement.hasPendingApprovalsTitle'
              )}
              acknowledgeText={pendingApprovalsAcknowledgeMessage}
              trigger={({ triggerProps, triggerRef }) => (
                <Button
                  loading={isSubmittingUpdateUser}
                  disabled={disabled}
                  {...triggerProps}
                  ref={triggerRef}>
                  {t('settings.teamMembers.form.actions.update')}
                </Button>
              )}></AcknowledgePopover>
          ) : (
            <Button
              type="submit"
              form={formId}
              loading={isSubmittingUpdateUser}
              disabled={disabled}>
              {t('settings.teamMembers.form.actions.update')}
            </Button>
          )}
          {shouldShowDeactivateActionLink && (
            <DeactivateMembershipButton
              involvedCount={involvedCount}
              onDeactivateMembership={onDeactivateMembership}
              disabled={disabled}
              loading={isSubmittingDeactivateMembership}
              pendingCount={pendingCount}
              onForceDeactivateMembership={onForceDeactivateMembership}
              selectedUser={selectedUser}
            />
          )}
        </Grid>
      }>
      <Grid
        as="form"
        gap="space24"
        templateRows="1fr auto"
        id={formId}
        onSubmit={form.handleSubmit(values => {
          return onSubmit(values, dirtyFields);
        })}>
        <Grid gap="space24">
          <UserAvatar selectedUser={selectedUser} />
          <Grid
            background="gray100"
            borderRadius="8px"
            padding="space16"
            rowGap="space12"
            columnGap="space32"
            templateColumns="1fr 1fr 1fr 1fr"
            templateRows="auto auto auto">
            <Grid gridRow="1" gridColumn="1/3">
              <HookFormTextField
                readOnly={readOnly?.firstName}
                disabled={readOnly?.firstName}
                label={t('settings.teamMembers.form.firstName.label')}
                name="firstName"
                autoFocus
              />
            </Grid>
            <Grid gridRow="1" gridColumn="3/5">
              <HookFormTextField
                readOnly={readOnly?.lastName}
                disabled={readOnly?.lastName}
                name="lastName"
                label={t('settings.teamMembers.form.lastName.label')}
              />
            </Grid>
            <Grid
              gridRow="2"
              gridColumn="1/5"
              templateColumns="1fr auto"
              autoFlow="column"
              gap="space20"
              alignItems="center">
              <HookFormTextField
                name="email"
                label={t('settings.teamMembers.form.email.label')}
                inputProps={{
                  type: 'email',
                  style: {
                    color: colors.gray500, // TODO this should be possible from top lvl prop
                  },
                }}
                disabled={true}
                readOnly={true}
              />
              <Grid paddingRight="space20">
                <Icon
                  icon="infoOutline"
                  size="space18"
                  {...triggerProps}
                  ref={triggerRef}
                />
              </Grid>
              {isOpen && (
                <Tooltip {...tooltipProps} ref={tooltipRef}>
                  {t('settings.teamMembers.form.email.description')}
                </Tooltip>
              )}
            </Grid>
            <Grid gridRow="3" gridColumn="1/3">
              <HookFormSelectField
                name="locale"
                readOnly={readOnly?.locale}
                disabled={readOnly?.locale}
                label={t('settings.teamMembers.form.locale')}
                items={locales}
              />
            </Grid>
          </Grid>
          <AbsenceFormWrapper
            defaultValuesLoading={absenceValuesLoading}
            userId={selectedUser?.id}
            userRoles={selectedUser?.roles}
          />

          <RolesSection
            onShowRolesStep={onShowRolesStep}
            readOnly={readOnly?.roles}
          />

          <UserTeamsWithLinks teams={teams} />

          <Grid gap="space8">
            <Heading as="h3">
              {t('settings.teamMembers.form.involvement.header')}
            </Heading>
            {selectedUser?.id ? (
              <MembershipInvolvementContainer
                selectedUserId={selectedUser.id}
                organizationSlug={organizationSlug}
                setInvolvedCount={setInvolvedCount}
                setPendingCount={setPendingCount}
                errorMessage={involvementErrorMessage}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </DrawerLayout>
  );
};
