import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const MIN_DOCUMENT_TAG_NAME_LENGTH = 3;
export const MAX_DOCUMENT_TAG_NAME_LENGTH = 30;

export const documentTagDetailsFormSchema = z.object({
  name: z
    .string()
    .min(MIN_DOCUMENT_TAG_NAME_LENGTH)
    .max(MAX_DOCUMENT_TAG_NAME_LENGTH),
  description: z.string().max(250).nullish(),
});

export type DocumentTagDetailsFormOutput = z.infer<
  typeof documentTagDetailsFormSchema
>;
export type DocumentTagDetailsFormValues =
  Partial<DocumentTagDetailsFormOutput>;
export type DocumentTagDetailsErrorMessages = ErrorMessages<
  typeof documentTagDetailsFormSchema
>;
