import { WatchQueryFetchPolicy } from '@apollo/client';
import {
  CardIssuerTransactionView,
  InvoiceAssociationStatus,
  TransactionCategory,
  TransactionType,
  useCountCardIssuerTransactionsQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useMemo } from 'react';
import {
  countDocumentsByStatusNew,
  countDocumentsByStatusRejected,
  countDocumentsIRejected,
  getCountExportableEntities,
  countCardIssuerTransactions,
  countOpenApprovals,
  countPayableDocuments,
} from './queries';

export const inboxTransactionCountVariables = {
  input: {
    view: CardIssuerTransactionView.Inbox,
  },
  filters: {
    invoiceAssociationStatus: [InvoiceAssociationStatus.Missing],
  },
};

/**
 * Returns the list of counter queries based on user roles and organization's settings
 */
export const useCounterQueries = () => {
  const { isAdmin, isAccountant, hasRoles } = useUserRoles();
  const organization = useFullOrganization();

  const counterQueries = useMemo(() => {
    if (!hasRoles) {
      return [];
    }

    const queries = [
      countDocumentsByStatusNew,
      countDocumentsByStatusRejected,
      countDocumentsIRejected,
      countOpenApprovals,
      countCardIssuerTransactions,
    ];

    if (organization?.payment.isActive && (isAdmin || isAccountant)) {
      queries.push(countPayableDocuments);
    }

    if (isAdmin || isAccountant) {
      queries.push(getCountExportableEntities);
    }

    return queries.map(query => {
      if (query === countCardIssuerTransactions)
        return {
          query,
          variables: inboxTransactionCountVariables,
        };

      return { query };
    });
  }, [hasRoles, isAccountant, isAdmin, organization?.payment.isActive]);

  return counterQueries;
};

interface InboxTransactionCountProps {
  skip?: boolean;
  cardIds?: string[];
  cardholderIds?: string[];
  categories?: TransactionCategory[];
  type?: TransactionType;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export const useInboxTransactionCount = ({
  skip,
  cardIds,
  cardholderIds,
  fetchPolicy = 'cache-first',
}: InboxTransactionCountProps = {}) => {
  const { data, loading } = useCountCardIssuerTransactionsQuery({
    fetchPolicy,
    variables: {
      input: {
        view: CardIssuerTransactionView.Inbox,
      },
      filters: {
        invoiceAssociationStatus: [InvoiceAssociationStatus.Missing],
        cardholderIds,
        cardIds,
      },
    },
    skip,
  });

  const inboxTransactionCount = data?.countCardIssuerTransactions?.count ?? 0;

  return {
    inboxTransactionCount,
    loading,
  };
};
