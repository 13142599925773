import { Text, Link } from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ASSOCIATION } from 'views/TransactionAssociation/types';

export const ApproverInfo = ({
  associationState,
  organizationSlug,
}: {
  organizationSlug: string | null;
  associationState?: ASSOCIATION;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  if (associationState === ASSOCIATION.DOCUMENT) {
    return (
      <>
        <Text>
          {t(
            'transactionAssociation.rightContent.fileUploadedInfoBox.description'
          )}
        </Text>
        <Text>
          {t(
            'transactionAssociation.rightContent.fileUploadedInfoBox.forApprover.helpText'
          )}
        </Text>
      </>
    );
  }

  return (
    <>
      <Text>
        {t(
          'transactionAssociation.rightContent.noFileInfoBoxForApprover.description'
        )}
      </Text>
      <Trans
        t={t}
        parent={Text}
        i18nKey="transactionAssociation.rightContent.noFileInfoBoxForApprover.helpText">
        You can find the transaction in
        <Link
          href={`/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`}>
          <Text>Archive / Transactions</Text>
        </Link>
      </Trans>
    </>
  );
};
