import { Nullable } from 'utils/extractors';
import {
  GERMAN_COUNTRY_ISO_CODE,
  isValidTaxNumberForGermany,
} from 'utils/forms';
import { ErrorMessages } from 'utils/zodFormValidation';
import { taxNumberSchema } from 'views/Contacts/ContactDetails/ContactForm/taxNumberSchema';
import { z } from 'zod';

export const taxIdNumberSchema = z.object({
  taxNumber: taxNumberSchema.nullish().refine(value => {
    return isValidTaxNumberForGermany(GERMAN_COUNTRY_ISO_CODE, value);
  }),
});

export const taxIdNumberErrorMessages: ErrorMessages<typeof taxIdNumberSchema> =
  {
    taxNumber: { label: 'organizations.contactInformation.taxNumber.label' },
  };

export type taxIdNumberFormDataOutput = z.infer<typeof taxIdNumberSchema>;
export type taxIdNumberFormData = Nullable<taxIdNumberFormDataOutput>;
