import {
  InvoiceAssociationStatus,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadableTransactionCategory } from './useReadableTransactionCategory';

export const useReadableTransactionFilters = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const readableTransactionCategory = useReadableTransactionCategory();

  const transactionFilter = useMemo(() => {
    const invoiceAssociationStatus: Record<InvoiceAssociationStatus, string> = {
      MISSING: t('documentStatus.missing'),
      UPLOADED: t('documentStatus.uploaded'),
      NOT_NEEDED: t('documentStatus.notNeeded'),
      AUTO_MATCHED: t('documentStatus.autoMatched'),
    };

    const type: Record<TransactionType, string> = {
      CASH_WITHDRAWAL: t('transactionDetailsCard.type.cash_withdrawal'),
      PURCHASE: t('transactionDetailsCard.type.purchase'),
      RECHARGE: t('transactionDetailsCard.type.recharge'),
      REFUND: t('transactionDetailsCard.type.refund'),
      STATUS_INQUIRY: t('transactionDetailsCard.type.status_inquiry'),
      CHARGEBACK: t('transactionDetailsCard.type.chargeback'),
    };

    const status: Record<TransactionStatus, string> = {
      CONFIRMED: t('transactionDetailsCard.status.confirmed'),
      DECLINED: t('transactionDetailsCard.status.declined'),
      PENDING: t('transactionDetailsCard.status.pending'),
      REVERSED: t('transactionDetailsCard.status.reversed'),
    };

    return {
      readableTransactionCategory,
      readableTransactionStatus: (stat: TransactionStatus) => status[stat],
      readableTransactionType: (ty: TransactionType) => type[ty],
      readableInvoiceAssociationStatus: (inv: InvoiceAssociationStatus) =>
        invoiceAssociationStatus[inv],
    };
  }, [readableTransactionCategory, t]);

  return transactionFilter;
};
