import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ChangeCardLimitInput,
  useChangeCardLimitMutation,
} from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useChangeCardLimit = () => {
  let status: 'error' | 'success';
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();

  const { refetchQueries } = useCreditCardsRefetchQueries();

  const [changeCardLimit, { loading: changingLimit }] =
    useChangeCardLimitMutation({
      onError: () => error(t('dashboard.genericErrorMessage')),
    });

  return {
    changingLimit,
    changeCardLimit: async ({ cardId, ...input }: ChangeCardLimitInput) => {
      const { errors } = await changeCardLimit({
        variables: {
          input: { cardId, ...input },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          ...refetchQueries.cardIssuerCardsCardManager(),
          ...refetchQueries.cardIssuerCards(cardId),
        ],
      });

      if (errors?.length) {
        status = 'error';
      } else {
        status = 'success';
      }

      return {
        status,
        errors,
      };
    },
  };
};
