import { PurchaseOrderDetailsCard } from 'components/PurchaseOrders/PurchaseOrderDetailsCard/PurchaseOrderDetailsCard';
import { PurchaseOrderUnlinkContextMenu } from 'components/PurchaseOrders/PurchaseOrderDetailsCard/PurchaseOrderUnlinkContextMenu';
import { PurchaseOrderCardData } from 'components/PurchaseOrders/PurchaseOrderDetailsCard/utils';
import { SelectPurchaseOrderContainer } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/SelectPurchaseOrderContainer';
import {
  DocumentStatus,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';

export interface PurchaseOrderSectionProps {
  documentId: string;
  documentAmount?: number | null;
  isPurchaseOrderEditable: boolean;
  purchaseOrder?: PurchaseOrderCardData;
  documentStatus?: DocumentStatus | EcmDocumentStatus;
  contactName?: string;
  accountsPayableNumber?: string | null;
}

export const PurchaseOrderSection = ({
  documentStatus,
  documentAmount,
  documentId,
  purchaseOrder,
  isPurchaseOrderEditable,
  contactName,
  accountsPayableNumber,
}: PurchaseOrderSectionProps) => {
  const canAddPurchaseOrder = isPurchaseOrderEditable && !purchaseOrder;

  if (canAddPurchaseOrder) {
    return (
      <SelectPurchaseOrderContainer
        documentAmount={documentAmount}
        documentId={documentId}
        contactName={contactName}
        accountsPayableNumber={accountsPayableNumber}
      />
    );
  }

  if (purchaseOrder?.id) {
    return (
      <PurchaseOrderDetailsCard
        purchaseOrder={purchaseOrder}
        contextMenu={
          isPurchaseOrderEditable && (
            <PurchaseOrderUnlinkContextMenu
              docStatus={documentStatus}
              documentId={documentId}
              purchaseOrderId={purchaseOrder?.id}
            />
          )
        }
      />
    );
  }

  return null;
};
