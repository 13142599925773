import { Card, Box, Grid, Text, Image } from '@candisio/design-system';
import Blog1 from 'assets/bds/blog-1.svg';
import Blog2 from 'assets/bds/blog-2.svg';
import Blog3 from 'assets/bds/blog-3.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const useBlogsTranslationMap = (): Record<
  string,
  { [key: string]: string }
> => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  return {
    bestInterface: {
      image: Blog1,
      title: t('bdsPromotionModal.blogs.bestInterface.title'),
      name: t('bdsPromotionModal.blogs.bestInterface.name'),
      date: t('bdsPromotionModal.blogs.bestInterface.date'),
      link: t('bdsPromotionModal.blogs.bestInterface.link'),
    },
    optimizeMaintenance: {
      image: Blog2,
      title: t('bdsPromotionModal.blogs.optimizeMaintenance.title'),
      name: t('bdsPromotionModal.blogs.optimizeMaintenance.name'),
      date: t('bdsPromotionModal.blogs.optimizeMaintenance.date'),
      link: t('bdsPromotionModal.blogs.optimizeMaintenance.link'),
    },
    automatedAccurals: {
      image: Blog3,
      title: t('bdsPromotionModal.blogs.automatedAccurals.title'),
      name: t('bdsPromotionModal.blogs.automatedAccurals.name'),
      date: t('bdsPromotionModal.blogs.automatedAccurals.date'),
      link: t('bdsPromotionModal.blogs.automatedAccurals.link'),
    },
  };
};

export const PromotionBlog = ({
  translationKey,
}: {
  translationKey: string;
}) => {
  const blogsTranslationMap = useBlogsTranslationMap();

  return (
    <Card
      background="gray100"
      onClick={() => {
        window.open(
          blogsTranslationMap[translationKey].link,
          '_blank',
          'noopener noreferrer'
        );
      }}
      style={{ cursor: 'pointer' }}>
      <Box
        left={0}
        top={0}
        position="absolute"
        height="100%"
        width="100%"
        borderRadius={8}
        background="rgba(0, 0, 0, 0.25)"
        hover={{ background: 'none' }}
        zIndex={2}
        overflow="hidden"
      />
      <Text fontSize="xxlarge" fontFamily="heading" lineHeight="120%">
        {blogsTranslationMap[translationKey].title}
      </Text>
      <Grid templateColumns="1fr auto" alignItems="end">
        <Grid>
          <Text fontSize="large" fontFamily="heading">
            {blogsTranslationMap[translationKey].name}
          </Text>
          <Text fontSize="large" fontFamily="heading">
            {blogsTranslationMap[translationKey].date}
          </Text>
        </Grid>
        <Image
          src={blogsTranslationMap[translationKey].image}
          alt="blog"
          height={140}
        />
      </Grid>
    </Card>
  );
};
