import {
  BookingAccountDataFragment,
  BookingAccountInfiniteScrollPaginationQuery,
  BookingAccountInfiniteScrollPaginationQueryVariables,
  BookingAccountSortField,
  SortDirection,
  useCountBookingAccountsQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { bookingAccountInfiniteScrollPagination } from 'views/queries/booking-accounts';
import { getBookingAccountPaginationVariables } from '../utils/pagination';

interface BookingAccountsData {
  activeCount: number;
  archivedCount: number;
  bookingAccountsData: BookingAccountDataFragment[];
  handleDebounceSearch: (inputStr: string) => void;
  isCountLoading: boolean;
  isDataLoading: boolean;
  onEndReached: () => void;
}

export const useBookingAccountsData = ({
  archivedTabEnabled,
  search,
  sortBy,
}: {
  archivedTabEnabled: boolean;
  search: string;
  sortBy: BookingAccountSortField;
}): BookingAccountsData => {
  const { data: activeCount, loading: activeCountLoading } =
    useCountBookingAccountsQuery({
      variables: {
        filters: {
          isArchived: false,
        },
      },
    });

  const { data: archivedCount, loading: archivedCountLoading } =
    useCountBookingAccountsQuery({
      variables: {
        filters: {
          isArchived: true,
        },
      },
    });

  const computeVariables = useCallback(
    (
      inputStr?: string
    ): BookingAccountInfiniteScrollPaginationQueryVariables => ({
      queries: {
        readableName: inputStr,
      },
      sortBy: {
        direction:
          sortBy === BookingAccountSortField.CreatedAt
            ? SortDirection.Desc
            : SortDirection.Asc,
        field: sortBy,
      },
      ...getBookingAccountPaginationVariables(search, archivedTabEnabled),
    }),
    [archivedTabEnabled, sortBy, search]
  );

  const {
    data: bookingAccountsData,
    handleDebounceSearch,
    loading: isDataLoading,
    loadMore: onEndReached,
  } = useDebouncedSearchPagination<
    BookingAccountInfiniteScrollPaginationQuery,
    BookingAccountInfiniteScrollPaginationQueryVariables
  >({
    query: bookingAccountInfiniteScrollPagination,
    queryRootKey: 'bookingAccountPagination',
    computeVariables,
  });

  return {
    activeCount: activeCount?.countBookingAccounts ?? 0,
    archivedCount: archivedCount?.countBookingAccounts ?? 0,
    bookingAccountsData:
      bookingAccountsData?.bookingAccountPagination.records ?? [],
    handleDebounceSearch,
    isCountLoading: activeCountLoading || archivedCountLoading,
    isDataLoading: isDataLoading,
    onEndReached,
  };
};
