import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useMemo } from 'react';
import { ContactsTableData } from '../ContactTableDS';

const availableFilters: Array<keyof ContactsTableData> = [
  'contact',
  'accountsPayableNumber',
  'accountsReceivableNumber',
  'relationshipType',
  'iban',
  'paymentInfo',
  'taxNumber',
  'vatId',
  'isArchived',
];

export const useAvailableContactsFilters = () => {
  const [contactsArchivingFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.contactsArchiving,
  ]);

  // Relationship Type has been moved to Quick filters.
  const filters = useMemo(() => {
    return contactsArchivingFF
      ? availableFilters.filter(f => f !== 'relationshipType')
      : availableFilters;
  }, [contactsArchivingFF]);

  return filters;
};
