import { Grid, Text } from '@candisio/design-system';
import memberImage from 'assets/no-member-filled.png';
import { History } from 'components/History/History';
import { Collapse } from 'components/History/items/Collapse';
import { ContactHistoryQuery } from 'generated-types/graphql.types';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { ChangedValue } from 'views/Contacts/ContactDetails/ContactHistory/elements/ChangedValue';

interface ContactHistoryItemProps {
  historyItem: ContactHistoryQuery['entityAuditLogs'][0];
  hideTail?: boolean;
}

export const ContactHistoryItem = memo(
  ({ historyItem, hideTail }: ContactHistoryItemProps) => {
    const {
      uniqueId,
      actionPerformedAt,
      actionPerformedBy,
      operationName,
      changedValues,
      wasImpersonated,
      organizationId,
    } = historyItem;

    const [t] = useTranslation();

    const currentOrganizationId = useOrganizationId();

    const date = new Date(actionPerformedAt ?? '');

    const hasChangedValues = Boolean(changedValues?.length);

    const isUserInfoAvailable = Boolean(actionPerformedBy?.name);

    const isSameOrganization = organizationId === currentOrganizationId;

    const isDifferentOrganizationAndUnknownUser =
      !isSameOrganization && !isUserInfoAvailable;

    const author = isDifferentOrganizationAndUnknownUser
      ? t('common:historyTimeline.createdBy.holdingUser', {
          organizationId,
        })
      : actionPerformedBy?.name;

    const avatarUrl = isDifferentOrganizationAndUnknownUser
      ? memberImage
      : actionPerformedBy?.avatarUrl ?? undefined;

    return (
      <History.User
        key={uniqueId}
        isCollapsible={hasChangedValues}
        size="big"
        hideTail={hideTail}
        wasImpersonated={wasImpersonated}
        absoluteTime={DateFormatters.regularDatetime(date)}
        header={DateFormatters.fromNow(date)}
        author={author}
        avatarUrl={avatarUrl}>
        <Grid gap="space4">
          <Text fontSize="small" lineHeight="paragraph" fontWeight="semibold">
            {operationName}
          </Text>
          <Collapse>
            {changedValues?.map(field => (
              <ChangedValue key={field.propertyName} {...field} />
            ))}
          </Collapse>
        </Grid>
      </History.User>
    );
  }
);
