import {
  Document,
  GetDocumentForDraftQuery,
  GetDocumentQuery,
  Maybe,
  User,
  UserRole,
  WorkflowStepResolutionTypes,
} from 'generated-types/graphql.types';
import { DeepPartial } from 'utility-types';

/**
 * @todo extract to a shared folder since it’s used outside Approvals
 */
export const userIsMonitoring = (
  document: DeepPartial<Document>,
  currentUser?: Maybe<User>
) => {
  return !(
    currentUser?.id &&
    document.approvers &&
    document.approvers.some(user => user.id === currentUser.id)
  );
};

export const userCanApprove = (
  document: NonNullable<
    GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument']
  >,
  currentUser?: Maybe<User>
): boolean => {
  const workflowSteps = document?.workflow?.steps || [];
  const activeStep = workflowSteps.find(
    ({ resolution }) => resolution?.type === WorkflowStepResolutionTypes.Pending
  );

  const isAdmin = currentUser?.roles?.some(role => role === UserRole.Admin);
  const canApproveStep = activeStep?.approvers?.some(
    ({ id }) => id === currentUser?.id
  );

  return Boolean(isAdmin || canApproveStep);
};
