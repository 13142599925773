import { AccountingAreasDrawer } from 'views/Settings/AccountingAreas/elements/Drawer/AccountingAreasDrawer';
import { useMutateAccountingAreas } from 'views/Settings/AccountingAreas/toolkit/hooks/useMutateAccountingAreas';

export const AccountingAreasDrawerContainer = () => {
  const {
    accountingArea,
    accountingAreaLoading,
    handleSubmit,
    updatingAccountingArea,
    creatingAccountingArea,
    archivingAccountingArea,
    activatingAccountingArea,
    handleActivate,
    handleArchive,
    onCloseDrawer,
    accountingAreaId,
    isDrawerOpen,
  } = useMutateAccountingAreas();

  return (
    <AccountingAreasDrawer
      loading={accountingAreaLoading}
      id={accountingAreaId}
      isOpen={isDrawerOpen}
      onCloseDrawer={onCloseDrawer}
      onSubmit={handleSubmit}
      submitting={
        creatingAccountingArea ||
        updatingAccountingArea ||
        archivingAccountingArea ||
        activatingAccountingArea
      }
      updatingStatus={activatingAccountingArea || archivingAccountingArea}
      onArchive={handleArchive}
      onActivate={handleActivate}
      isActive={!accountingArea?.isArchived}
      accountArea={accountingArea}
    />
  );
};
