import { UpdateVirtualAndPhysicalCardLimitFormErrorMessages } from './updateVirtualAndPhysicalCardLimitFormSchema';

export const updateVirtualAndPhysicalCardLimitFormErrorMessages: UpdateVirtualAndPhysicalCardLimitFormErrorMessages =
  {
    cardLabel: {
      label: 'dashboard.drawer.form.cardLabel.label',
    },
    limit: {
      label: 'ccRequestForm.fields.limit.label.errorLabel',
    },
    limitInterval: {
      label: 'dashboard.drawer.form.limitFrequency.label',
    },
    transactionLimit: {
      label: 'dashboard.drawer.form.transactionLimit.label',
      custom: {
        translationKey: 'ccRequestForm.fields.transactionLimit.txLimitTooHigh',
      },
    },
    comment: {
      label: 'dashboard.drawer.form.comment.label',
    },
  };
