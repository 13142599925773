import { Grid, Item, TruncatedText } from '@candisio/design-system';
import { get } from 'lodash';
import { ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { taxCodeExportValidationSchema } from 'views/utils/taxCodeExportValidationSchema';
import { ComboBoxFieldOptions, ProcessingFormProps } from './ProcessingForm';
import { ProcessingFormAccountingDataFieldItem } from './ProcessingFormAccountingFields';
import { ProcessingFormComboBoxField } from './ProcessingFormComboBoxField';
import { ProcessingFormValues } from './processingFormSchema';
import { ProcessingFormTypeFieldItem } from './ProcessingFormTypeField';

export interface ProcessingFormTaxCodeFieldProps
  extends ComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem> {
  /** Field name */
  name: 'bookings.0.taxCode';
  /** Field label */
  label: string;
  /** Shown when dropdown list is empty */
  emptyListPlaceholder?: ReactNode;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Integration that the organization is using */
  integration: ProcessingFormProps['integration'];
  /** List of items for the (document) type field */
  typeFieldItems?: ProcessingFormTypeFieldItem[];
  flex?: string;
}

/**
 * A ProcessingFormComboBoxField for entering tax code
 *
 * Tax codes that are not applicable for the direction of the currently-selected
 * document type are disabled
 */
export const ProcessingFormTaxCodeField = ({
  defaultItems = [],
  integration,
  typeFieldItems = [],
  ...restProps
}: ProcessingFormTaxCodeFieldProps) => {
  const [t] = useTranslation();

  const type = useWatch<ProcessingFormValues, 'type'>({ name: 'type' });
  const typeItem = typeFieldItems.find(item => item.key === type);
  const direction = typeItem?.direction;

  let disabledKeys: string[] = [];
  if (integration === 'DATEV' && direction) {
    defaultItems.forEach(item => {
      if (!item.code) {
        return;
      }

      if (
        get(taxCodeExportValidationSchema, [item.code, direction]) === false
      ) {
        disabledKeys.push(item.key);
      }
    });
  }

  const defaultItemsWithDescription = defaultItems.map(item => {
    if (disabledKeys.includes(item.key)) {
      return {
        ...item,
        description:
          direction === DocumentDirection.invoices_received
            ? t(
                'document.requestApproval.inputs.bookingKey.items.description.invoices_received'
              )
            : t(
                'document.requestApproval.inputs.bookingKey.items.description.outgoing_invoices'
              ),
      };
    }

    return item;
  });

  return (
    <ProcessingFormComboBoxField
      defaultItems={defaultItemsWithDescription}
      disabledKeys={disabledKeys}
      // @TODO figure out a way to make it virtualized with items of varying
      // height
      isVirtualized={false}
      {...restProps}>
      {item => (
        <Item key={item.key} textValue={item.children as string}>
          <Grid>
            <TruncatedText>{item.children}</TruncatedText>
            {item.description && (
              <TruncatedText fontSize="small" fontStyle="italic">
                {item.description}
              </TruncatedText>
            )}
          </Grid>
        </Item>
      )}
    </ProcessingFormComboBoxField>
  );
};
