import {
  useContactHistoryQuery,
  EntityType,
} from 'generated-types/graphql.types';

export const useContactHistoryData = (contactId: string) => {
  const { data, loading } = useContactHistoryQuery({
    variables: {
      entityId: contactId,
      entityType: EntityType.Contacts,
    },
    fetchPolicy: 'network-only',
    skip: !contactId,
  });

  return {
    contactHistoryItems: data?.entityAuditLogs ?? [],
    isContactHistoryLoading: loading,
  };
};
