import { PaginationWithSearchFilterHook } from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import { ReactElement, ReactNode } from 'react';
import { useCardRefNumFilter } from 'views/CreditCards/hooks/useCardRefNumFilter';
import { useCategoryFilter } from 'views/CreditCards/hooks/useCategoryFilter';
import { useMerchantnameFilter } from 'views/CreditCards/hooks/useMerchantNameFilter';

export type CCFilterOption = {
  id: string;
  label: string | ReactNode;
  icon?: ReactElement;
};

export const ccPaginationFiltersHooks: {
  [index: string]: PaginationWithSearchFilterHook;
} = {
  merchantName: useMerchantnameFilter,
  category: useCategoryFilter,
  cardRefNum: useCardRefNumFilter,
};
