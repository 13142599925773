import { Text } from '@candisio/design-system';
import { mapDocumentTypeTranslations } from 'components/DocumentsTable/Cells/mapDocumentTypeTranslations';
import { useAllDocumentTypesQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

type Value = string | undefined;

export const DocumentTypeCell = ({
  value,
}: CellProps<DocumentsTableData, Value>) => {
  const [t] = useTranslation();

  const { data: documentCategoriesData } = useAllDocumentTypesQuery();

  const displayValue = useMemo(() => {
    if (!value) return t('documents-table:emptyCellPlaceholder');

    const documentType =
      documentCategoriesData?.availableDocumentCategories.find(
        documentCategory => documentCategory.documentType === value
      );

    if (documentType?.isDefault) {
      return t(mapDocumentTypeTranslations(documentType.documentType));
    }

    return value;
  }, [documentCategoriesData?.availableDocumentCategories, t, value]);

  return <Text>{displayValue}</Text>;
};
