import { AccountingAreasErrorMessages } from 'views/Settings/AccountingAreas/elements/Drawer/Form/accountingAreasFormSchema';

export const accountingAreasFormErrorMessages: AccountingAreasErrorMessages = {
  name: {
    label: 'accountingAreas.drawer.form.fields.name.label',
  },
  description: {
    label: 'accountingAreas.drawer.form.fields.description.label',
  },
};
