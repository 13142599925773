import { Color } from '@candisio/design-system';
import { mapTagVariantToColors, mapVariantToColors } from './consts';
import { Variant } from './types';

export const threshold = 75;
export const maximum = 100;

export const determineColor: (p: number) => string = (percentage: number) => {
  if (percentage >= 0 && percentage <= threshold) {
    return mapVariantToColors[Variant.WITHIN_THRESHOLD];
  } else if (percentage > threshold && percentage < maximum) {
    return mapVariantToColors[Variant.ALMOST_MAXIMUM];
  } else {
    return mapVariantToColors[Variant.OVER_MAXIMUM];
  }
};

export const determineTagColor: (p: number) => Color = (percentage: number) => {
  if (percentage >= 0 && percentage <= threshold) {
    return mapTagVariantToColors[Variant.WITHIN_THRESHOLD];
  } else if (percentage > threshold && percentage < maximum) {
    return mapTagVariantToColors[Variant.ALMOST_MAXIMUM];
  } else {
    return mapTagVariantToColors[Variant.OVER_MAXIMUM];
  }
};
