import { Card, Image, Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import recieptIcon from '../../media/receipt.svg';
import { CreateExpenseActionMenuButton } from '../CreateExpenseActionMenuButton';

interface EmptyExpenseDetailsCardProps {
  creatingExpense: boolean;
  onGeneralExpenseClick: () => void;
  onHospitalityExpenseClick: () => void;
}

export const EmptyExpenseDetailsCard = ({
  creatingExpense,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: EmptyExpenseDetailsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Card padding="space24">
      <Grid templateColumns="auto 1fr auto" gap="space24" placeItems="center">
        <Card background="pink100">
          <Image
            src={recieptIcon}
            alt="reciept icon"
            height="space32"
            width="space32"
          />
        </Card>
        <Grid gap="space8">
          <Text fontSize="xlarge" fontWeight="semibold">
            {t('reimbursementView.middleSection.emptyDetails.title')}
          </Text>
          <Text fontSize="basic">
            {t('reimbursementView.middleSection.emptyDetails.description')}
          </Text>
        </Grid>
        <CreateExpenseActionMenuButton
          creatingExpense={creatingExpense}
          onGeneralExpenseClick={onGeneralExpenseClick}
          onHospitalityExpenseClick={onHospitalityExpenseClick}
        />
      </Grid>
    </Card>
  );
};
