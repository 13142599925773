import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import {
  DocumentStatus,
  GetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { ProcessingFormContainer } from 'views/Inbox/DocumentProcessing/ProcessingFormContainer';

interface RejectedProps {
  cycleDocument: () => void;
  document: GetDocumentForDraftQuery['getDocument'];
  loading?: boolean;
  purchaseOrderId?: string;
  contactItem?: ProcessingFormContactFieldItem;
  onGetContactItem?: (value?: ProcessingFormContactFieldItem) => void;
}

export const Rejected = ({
  cycleDocument,
  document,
  loading,
  purchaseOrderId,
  contactItem,
  onGetContactItem,
}: RejectedProps) => {
  if (loading) {
    // TODO loading state
    return null;
  }

  if (!document) {
    // TODO Add error state
    return null;
  }

  return (
    <ProcessSidebar
      key={purchaseOrderId}
      documentId={document.id}
      documentStatus={DocumentStatus.Rejected}>
      <ProcessingFormContainer
        documentId={document.id}
        onApprove={cycleDocument}
        onDeleteDocument={cycleDocument}
        onRequestApproval={cycleDocument}
        contactItem={contactItem}
        onGetContactItem={onGetContactItem}
      />
    </ProcessSidebar>
  );
};
