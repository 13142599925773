import { PaginatedComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { getTranslationContext } from 'orgConfig';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { POSTING_TEXT_FIELD_MAX_LENGTH } from 'views/consts';

export const PostingText = ({
  readOnly,
  currentValueLength,
  ...props
}: {
  onChange: (val: Key | null) => void;
  currentValueLength?: number;
} & PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { client } = useDatev();
  const maxLength = client ? POSTING_TEXT_FIELD_MAX_LENGTH : Infinity;

  return (
    <HookFormTextField
      name="postingText"
      label={t('inputs.postingText.label', {
        context: getTranslationContext(),
      })}
      {...props}
      placeholder={
        readOnly
          ? t('inputs.postingText.placeholder.notSet')
          : t('inputs.postingText.placeholder.bds')
      }
      readOnly={readOnly}
      description={
        (currentValueLength ?? 0) > maxLength
          ? {
              text: t('inputs.postingText.maxLengthWarning', {
                maxLength,
              }),
              color: 'warning',
            }
          : undefined
      }
      ignorePasswordManager={false}
    />
  );
};
