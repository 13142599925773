import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const selectMemberStepSchema = () => {
  const schema = z.object({
    teamMember: z.string(),
  });

  return schema;
};

export type SelectMemberStepOutput = z.infer<
  ReturnType<typeof selectMemberStepSchema>
>;

export type SelectMemberStepValues = Partial<SelectMemberStepOutput>;

export type SelectMemberStepErrorMessages = ErrorMessages<
  ReturnType<typeof selectMemberStepSchema>
>;
