import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { GoodsReceiptStatus } from 'generated-types/graphql.types';

export const goodsReceiptSapStatuses: Array<{
  id: GoodsReceiptStatus;
  label: string;
}> = [
  {
    id: GoodsReceiptStatus.Open,
    label: 'statusesInSap.open',
  },
  {
    id: GoodsReceiptStatus.Close,
    label: 'statusesInSap.closed',
  },
];
const GOODS_RECEIPTS_QUERY_PARAMS = {
  contact: 'contact',
  status: 'status',
};

export const availableFilters = [
  GOODS_RECEIPTS_QUERY_PARAMS.contact,
  GOODS_RECEIPTS_QUERY_PARAMS.status,
];

export const goodsReceiptsTableId =
  'archive-goods-receipts-table-configurations';

export const defaultColumnsConfigurations: Configuration[] = [
  {
    id: 'contact',
    isVisible: true,
    label: 'purchase-orders:goodsReceipts.columns.contact',
    isFixed: true,
  },
  {
    id: 'receiptNumber',
    isVisible: true,
    label: 'purchase-orders:goodsReceipts.columns.receiptNumber',
  },
  {
    id: 'deliveryDate',
    isVisible: true,
    label: 'purchase-orders:goodsReceipts.columns.deliveryDate',
  },
  {
    id: 'status',
    isVisible: true,
    label: 'purchase-orders:goodsReceipts.columns.status',
  },
  {
    id: 'orderNumber',
    isVisible: true,
    label: 'purchase-orders:goodsReceipts.columns.orderNumber',
  },
  {
    id: 'connectedInvoices',
    isVisible: true,
    label: 'purchase-orders:goodsReceipts.columns.connectedInvoices',
  },
  {
    id: 'receiptLines',
    isVisible: true,
    label: 'purchase-orders:goodsReceipts.columns.receiptLines',
  },
];
