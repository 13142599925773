import { Flex, Heading, Paragraph } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useAcceptUsageAgreement } from 'containers/credit-cards/CreditCardCarousel/hooks/useAcceptUsageAgreement';
import { useCheckPendingStatus } from 'containers/credit-cards/hooks/useCheckPendingStatus';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../../../providers/LocaleProvider';
import { MissingPhoneNumber } from './MissingPhoneNumber';
import { NotAcceptedUsageAgreement } from './NotAcceptedUsageAgreement';

interface ProcessingProps {
  cardId: string;
}

export const ProcessingContainer = ({ cardId }: ProcessingProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();

  const { availablePendingActions, loading } = useCheckPendingStatus(cardId);
  const { acceptUsageAgreement, isAccepting } = useAcceptUsageAgreement();

  if (loading) {
    return null;
  }

  const handleAcceptUsageAgreement = async () => {
    if (isNil(availablePendingActions.notAcceptedUsageAgreement)) {
      return;
    }

    const response = await acceptUsageAgreement({
      cardId,
      cardPendingActionId:
        availablePendingActions.notAcceptedUsageAgreement?.actionId,
    });

    if (response.status === 'success') {
      success(t('carousel.processing.successToastMessage'));
    } else {
      error(t('carousel.processing.errorToastMessage'));
    }
  };

  if (!isNil(availablePendingActions.missingPhoneNumber)) {
    return (
      <MissingPhoneNumber
        createdAt={availablePendingActions.missingPhoneNumber.createdAt}
      />
    );
  }

  if (!isNil(availablePendingActions.notAcceptedUsageAgreement)) {
    return (
      <NotAcceptedUsageAgreement
        createdAt={availablePendingActions.notAcceptedUsageAgreement.createdAt}
        onAcceptUsageAgreement={handleAcceptUsageAgreement}
        isAccepting={isAccepting}
      />
    );
  }

  return (
    <Flex direction="column" gap="space12">
      <Heading as="h2">{t('carousel.processing.title')}</Heading>
      <Paragraph>{t('carousel.processing.infoText')}</Paragraph>
    </Flex>
  );
};
