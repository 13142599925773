import {
  HookFormAmountField,
  HookFormAmountFieldProps,
} from 'components/HookFormFields/HookFormAmountField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VirtualCardLimitsFormValues } from './utils/virtualCardLimitsFormSchema';

interface CreateCreditCardAmountFieldProps<TFormValues extends FieldValues>
  extends Omit<HookFormAmountFieldProps<TFormValues>, 'name'> {
  suggestion?: number;
  name: keyof VirtualCardLimitsFormValues;
}

export const CreateCreditCardAmountField = <TFormValues extends FieldValues>({
  name,
  label,
  placeholder,
  suggestion,
  maximumFractionDigits,
  minValue,
  minimumFractionDigits,
  autoFocus,
  onChange,
}: CreateCreditCardAmountFieldProps<TFormValues>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { formState, getFieldState } =
    useFormContext<VirtualCardLimitsFormValues>();

  const isDirty = getFieldState(name, formState).isDirty;

  const variant = !isDirty && suggestion ? 'success' : 'default';

  const message =
    !isDirty && suggestion
      ? t('recurringPaymentsModal.createNewCard.suggestionMessage')
      : undefined;

  return (
    <HookFormAmountField
      label={label}
      variant={variant}
      placeholder={placeholder}
      message={message}
      minValue={minValue}
      maximumFractionDigits={maximumFractionDigits}
      minimumFractionDigits={minimumFractionDigits}
      currency="EUR"
      autoFocus={autoFocus}
      onChange={onChange}
      name={name}
    />
  );
};
