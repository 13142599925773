import gql from 'graphql-tag';

export const documentDataFileRecords = gql`
  query documentDataFileRecords {
    documentDataFileRecords {
      id
      recordCount
      fileType
      isExpired
      status
      file {
        id
        name
        url
        size
      }
      creator {
        name
        avatarUrl
      }
      createdAt
      isAcknowledged
      filters {
        costCenters {
          readableName
        }
        costObjects {
          readableName
        }
        extraCostInfos {
          readableName
        }
        generalLedgerAccounts {
          readableName
        }
        contacts {
          id
          name {
            value
          }
        }
        requesters {
          name
          username
        }
        approvers {
          name
          username
        }
        isOverdue
        isDuplicate
        isPayable
        isPaid
        statuses
        hasTransactionLinked
        artistSocialInsuranceCodes
      }
      query
      dateRangeFilters {
        invoiceDate {
          dateFrom
          dateTo
        }
        invoiceDueDate {
          dateFrom
          dateTo
        }
        invoiceDeliveryDate {
          dateFrom
          dateTo
        }
        paidAt {
          dateFrom
          dateTo
        }
        createdAt {
          dateFrom
          dateTo
        }
      }
      ecmFilters {
        contactId {
          id
          name {
            value
          }
        }
        contractStatus
        documentDate {
          dateFrom
          dateTo
        }
        documentStatus
        documentType
        endDate {
          dateFrom
          dateTo
        }
        notifyPerson {
          name
        }
        responsiblePerson {
          name
        }
        startDate {
          dateFrom
          dateTo
        }
        documentSubCategory
        terminationDate {
          dateFrom
          dateTo
        }
        terminationReminderDate {
          dateFrom
          dateTo
        }
      }
    }
  }
`;
