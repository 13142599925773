import countries from 'i18n-iso-countries';
import deCountries from 'i18n-iso-countries/langs/de.json';
import enCountries from 'i18n-iso-countries/langs/en.json';
import i18next from 'i18next';

countries.registerLocale(deCountries);
countries.registerLocale(enCountries);

export const getCountryName = (countryCode: string): string => {
  const language = i18next.language;

  return countries.getName(countryCode, language, { select: 'official' });
};

export const getCountries = () => {
  const language = i18next.language;

  return Object.entries(countries.getNames(language))
    .map(([isoCode, countryName]) => ({
      isoCode,
      countryName,
    }))
    .sort((left, right) => left.countryName.localeCompare(right.countryName));
};
