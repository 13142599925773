import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateReimbursementCaseMutation } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useCreateReimbursement = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const currentUserId = useCurrentUser()?.id ?? '';
  const { error } = useToastMessage();
  const [createReimbursement, { loading: createReimbursementPending, client }] =
    useCreateReimbursementCaseMutation({
      onError: () => error(t('reimbursementView.createErr')),
      refetchQueries: ['reimbursementCasesCount'],
      onCompleted: () => {
        client.cache.evict({ fieldName: 'reimbursementCasesPageBased' });
        client.cache.evict({ fieldName: 'reimbursementCasesCursorBased' });
      },
    });

  const handleCreateReimbursement = async () => {
    const { data, errors } = await createReimbursement({
      variables: {
        input: {
          targetMembershipId: currentUserId,
        },
      },
    });

    if (errors?.length) {
      error(t('reimbursementView.createErr'));

      return;
    }

    if (data?.createReimbursementCase.id) {
      return data.createReimbursementCase.id;
    }
  };

  return {
    createReimbursementPending,
    createReimbursement: handleCreateReimbursement,
  };
};
