export const years = [
  {
    label: 'dashboard.createNewWidget.tabs.yearBudgetForm.budgetField.label',
    placeholder:
      'dashboard.createNewWidget.tabs.yearBudgetForm.budgetField.placeHolder',
    key: 'year2022',
    labelTag: 'dashboard.createNewWidget.tabs.yearBudgetForm.labels.2022',
  },
  {
    label: 'dashboard.createNewWidget.tabs.yearBudgetForm.budgetField.label',
    placeholder:
      'dashboard.createNewWidget.tabs.yearBudgetForm.budgetField.placeHolder',
    key: 'year2023',
    labelTag: 'dashboard.createNewWidget.tabs.yearBudgetForm.labels.2023',
  },
  {
    label: 'dashboard.createNewWidget.tabs.yearBudgetForm.budgetField.label',
    placeholder:
      'dashboard.createNewWidget.tabs.yearBudgetForm.budgetField.placeHolder',
    key: 'year2024',
    labelTag: 'dashboard.createNewWidget.tabs.yearBudgetForm.labels.2024',
  },
];
