import { Grid, Heading } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface NotificationIntegrationSectionProps {
  children: ReactNode;
}

export const NotificationIntegrationSection = ({
  children,
}: NotificationIntegrationSectionProps) => {
  const [t] = useTranslation();

  return (
    <Grid gap="space8">
      <Grid gap="space16" templateColumns="repeat(2, max-content)">
        <Heading as="h3">
          {t('header.profile.tabs.update.notificationIntegration.title')}
        </Heading>
      </Grid>
      {children}
    </Grid>
  );
};
