import {
  Avatar,
  Box,
  Grid,
  Image,
  TruncatedText,
} from '@candisio/design-system';
import { TransactionCategory } from 'generated-types/graphql.types';
import { mappedCategoriesIcons } from '../util';

interface MerchantNameCellProps {
  merchantName: string | undefined;
  merchantLogoUrl: string | undefined;
  category: TransactionCategory | undefined;
}

export const MerchantNameCell = ({
  merchantLogoUrl,
  merchantName,
  category,
}: MerchantNameCellProps) => {
  if (!merchantName) {
    return <TruncatedText>-</TruncatedText>;
  }

  return (
    <Grid gap="space8" templateColumns="auto 1fr" alignItems="center">
      {merchantLogoUrl && (
        <Avatar name={merchantName} img={merchantLogoUrl} size="small" />
      )}
      {!merchantLogoUrl && category && (
        <Box borderRadius="full" border="1px solid gray200">
          <Image src={mappedCategoriesIcons[category]} alt="" />
        </Box>
      )}
      <TruncatedText>{merchantName}</TruncatedText>
    </Grid>
  );
};
