import { Flex, Switch, Text } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';

import {
  Locale,
  useUpdateCurrentUserMutation,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';

export const LanguageToggle = () => {
  const user = useCurrentUser();
  const { success } = useToastMessage();
  const [updateCurrentUser, { loading }] = useUpdateCurrentUserMutation({
    refetchQueries: [currentUserQuery],
    awaitRefetchQueries: true,
  });

  return (
    <Flex
      id="language-toggle"
      direction="column"
      padding="space4 space8"
      alignItems="center"
      borderRadius="large"
      border="space2 solid gray100">
      <Flex alignItems="center" gap="space8">
        <Text fontWeight={user?.locale === Locale.De ? 'bold' : 'normal'}>
          DE
        </Text>
        <Switch
          label=""
          disabled={!!loading}
          checked={user?.locale === Locale.En}
          onChange={async () => {
            const newLocale =
              user?.locale === Locale.En ? Locale.De : Locale.En;

            await updateCurrentUser({
              variables: {
                input: {
                  firstName: user?.firstName ?? '',
                  lastName: user?.lastName ?? '',
                  locale: newLocale,
                },
              },
            });

            success(`Switched to ${newLocale}`);
          }}
        />
        <Text fontWeight={user?.locale === Locale.En ? 'bold' : 'normal'}>
          EN
        </Text>
      </Flex>
    </Flex>
  );
};
