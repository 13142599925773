import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export interface UpdateSingleUseCardLimitFormSchemaProps {
  isCardManager: boolean;
}

export const updateSingleUseCardLimitFormSchema = ({
  isCardManager,
}: UpdateSingleUseCardLimitFormSchemaProps) => {
  const schema = z.object({
    purpose: z.string(),
    transactionLimit: z.number(),
    comment: isCardManager ? z.string().optional() : z.string(),
  });

  return schema;
};

export type UpdateSingleUseCardLimitFormValues = z.infer<
  ReturnType<typeof updateSingleUseCardLimitFormSchema>
>;

export type UpdateSingleUseCardLimitFormErrorMessages = ErrorMessages<
  ReturnType<typeof updateSingleUseCardLimitFormSchema>
>;
