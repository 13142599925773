import { Box, Button, Flex, Tag, Text, Color } from '@candisio/design-system';
import { motion } from 'framer-motion';

type NewsCardProps = {
  imageUrl: string;
  imageAlt: string;
  tag: string;
  tagColor?: Color;
  title: string;
  description?: string;
  buttonText: string;
  onButtonClick: () => void;
  backgroundColor: string;
};

const MotionFlex = motion(Flex);

const animationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const NewsCard = ({
  imageUrl,
  imageAlt,
  tag,
  tagColor = 'gray',
  title,
  description,
  buttonText,
  onButtonClick,
  backgroundColor,
}: NewsCardProps) => {
  return (
    <Flex
      background="gray0"
      borderRadius="medium"
      direction="row"
      width="100%"
      height="180px"
      alignItems="space-between">
      <Box
        width="50%"
        flexGrow={1}
        height="100%"
        borderBottomLeftRadius="medium"
        borderTopLeftRadius="medium"
        background={backgroundColor}>
        <motion.img
          src={imageUrl}
          alt={imageAlt}
          height="100%"
          style={{
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            width: '100%',
            objectFit: 'contain',
          }}
          variants={animationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.75 }}
          draggable={false}
        />
      </Box>
      <MotionFlex
        direction="column"
        height="100%"
        width="60%"
        flexGrow={0}
        padding="space24"
        gap="space4"
        variants={animationVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.75 }}>
        <Tag color={tagColor} variant="primary">
          {tag}
        </Tag>
        <Flex direction="column" height="100%" justifyContent="space-between">
          <Flex direction="column">
            <Text fontSize="medium" color="gray800">
              {title}
            </Text>
            <Text fontSize="basic" color="gray500">
              {description}
            </Text>
          </Flex>
          <Button
            width="fit-content"
            color="gray"
            iconPosition="right"
            icon="arrowRight"
            variant="secondary"
            style={{ marginTop: '10px' }}
            onClick={onButtonClick}>
            {buttonText}
          </Button>
        </Flex>
      </MotionFlex>
    </Flex>
  );
};
