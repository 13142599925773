import {
  CardIssuerAmountInput,
  CardLimitRenewFrequency,
  RequestPhysicalCardInput,
} from 'generated-types/graphql.types';
import { RequestPhysicalCard } from '../hooks/useRequestPhysicalCard';

export const sanitiseRequestPhysicalCard = ({
  cardLabel,
  limit,
  limitInterval,
  transactionLimit,
  comment,
  selectedCategory,
  accountingData,
}: RequestPhysicalCard): RequestPhysicalCardInput => {
  const requestedTransactionLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: transactionLimit ?? 0,
  };

  const requestedLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: limit ?? 0,
  };

  return {
    label: cardLabel ?? null,
    comment: comment ?? '',
    requestedLimit,
    requestedTransactionLimit,
    requestedLimitRenewFrequency: limitInterval as CardLimitRenewFrequency,
    acceptedCardAgreement: true,
    category: selectedCategory,
    accountingData,
  };
};
