import { useInAppNotificationsCountQuery } from 'generated-types/graphql.types';

export const useInAppNotificationsCountData = () => {
  const { data, loading: loadingCount } = useInAppNotificationsCountQuery({
    variables: {
      filters: {
        isRead: false,
      },
    },
  });

  return { count: data?.inAppNotificationsCount ?? 0, loadingCount };
};
