import { Button, Grid, Modal, useTheme, Text } from '@candisio/design-system';
import {
  CREATION_MODAL_MIN_HEIGHT,
  CREATION_MODAL_MIN_WIDTH,
} from 'components/CreationModalMultiStep/CreationModalMultiStep';
import { motion } from 'framer-motion';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { animationVariant, transition } from '../../toolkit/utils/animations';

interface SuccessModalLayoutProps {
  leftSide: ReactNode;
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

const MotionGrid = motion(Grid);

export const SuccessModalLayout = ({
  children,
  leftSide,
  isOpen,
  onClose,
}: SuccessModalLayoutProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { colors } = useTheme();

  return (
    <Modal
      padding="space2"
      minWidth={CREATION_MODAL_MIN_WIDTH}
      minHeight={CREATION_MODAL_MIN_HEIGHT}
      isOpen={isOpen}
      onClose={onClose}
      background={`linear-gradient(to right, ${colors.gray200} 0%, ${colors.gray200} 50%, ${colors.gray50} 50%, ${colors.gray50} 100%)`}>
      <Grid height="100%" templateRows="auto 1fr">
        <Grid padding="space16" paddingBottom={0} zIndex={1}>
          <Button
            icon="close"
            justifySelf="end"
            variant="tertiary"
            onClick={onClose}
          />
        </Grid>
        <Grid templateColumns="1fr 1fr" padding="space16" height="100%">
          <Grid padding="space16" paddingTop={0} placeItems="end">
            {leftSide}
          </Grid>
          <MotionGrid
            padding="space16"
            paddingTop={0}
            templateRows="1fr auto"
            height="100%"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={animationVariant}
            transition={transition}>
            <Grid
              gap="space24"
              bottom="space32"
              alignSelf="center"
              height="fit-content">
              <Text fontSize="xxlarge" textAlign="center">
                {t('teams.createTeam.successModal.header')}
              </Text>
              <Grid paddingLeft="space16">{children}</Grid>
            </Grid>
            <Button variant="secondary" onClick={onClose} justifySelf="end">
              {t('teams.createTeam.successModal.cta')}
            </Button>
          </MotionGrid>
        </Grid>
      </Grid>
    </Modal>
  );
};
