import { Grid } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { contactFormErrorMessages } from 'views/Contacts/ContactDetails/ContactForm/contactFormErrorMessages';
import {
  ContactFormOutput,
  ContactFormSchemaOptions,
  ContactFormValues,
} from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';
import { PaymentConditionReadOnlyField } from 'views/Contacts/ContactDetails/ContactForm/CoreDataApi/PaymentConditionReadOnlyField';
import { FormBlock } from 'views/Contacts/ContactDetails/ContactForm/FormBlock';
import { PaymentConditionItemProps } from 'views/Contacts/ContactDetails/ContactForm/PaymentConditionItem';
import { z } from 'zod';

export interface CoreDataApiContactFormProps {
  /** Initial form values */
  defaultValues?: ContactFormValues;
  paymentConditionItems?: PaymentConditionItemProps[];
}

const coreDataApiContactFormSchema = () => {
  const commonSchemaFields = {
    name: z.string(),
    accountsPayableNumber: z.string(),
    taxNumber: z.string(),
    vatId: z.string(),
    iban: z.string(),
    swiftCode: z.string(),
    email: z.string(),
  };

  return z.object(commonSchemaFields);
};

export const ContactFormCoreDataApi = ({
  defaultValues,
  paymentConditionItems = [],
}: CoreDataApiContactFormProps) => {
  const [t] = useTranslation();

  const form = useForm<ContactFormOutput, ContactFormSchemaOptions>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver({
      zodSchema: coreDataApiContactFormSchema,
      errorMessages: contactFormErrorMessages,
    }),
  });

  const readOnlyProps = {
    readOnly: true,
  };

  return (
    <FormProvider {...form}>
      <Grid as="form" gap="space32">
        <FormBlock headingKey="settings.contacts.details.edit.commonHeader">
          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="name"
            label={t('common:settings.contacts.details.edit.name.label')}
          />

          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="accountsPayableNumber"
            label={t(
              'settings.contacts.details.edit.accountsPayableNumber.label'
            )}
          />
        </FormBlock>

        <FormBlock headingKey="settings.contacts.details.edit.paymentHeader">
          <PaymentConditionReadOnlyField
            defaultValue={defaultValues?.paymentCondition}
            paymentConditionItems={paymentConditionItems}
          />

          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="iban"
            label={t('settings.contacts.details.edit.iban.label')}
          />
        </FormBlock>

        <FormBlock headingKey="settings.contacts.details.edit.taxHeader">
          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="taxNumber"
            label={t('settings.contacts.details.edit.taxNumber.label')}
          />

          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="vatId"
            label={t('settings.contacts.details.edit.vatId.label')}
          />
        </FormBlock>

        <FormBlock headingKey="settings.contacts.details.edit.addressHeader">
          <HookFormTextField<ContactFormValues>
            {...readOnlyProps}
            name="email"
            label={t('settings.contacts.details.edit.email.label')}
          />
        </FormBlock>
      </Grid>
    </FormProvider>
  );
};
