import { Flex, Paragraph } from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import {
  ChartOfAccountCode,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HookFormDayMonthSelectField } from './HookFormDayMonthSelectField';

const supportedChartOfAccounts = [
  ChartOfAccountCode.Skr_03,
  ChartOfAccountCode.Skr_04,
];

function toSelectOption(obj: { [key: string]: string }) {
  return Object.entries(obj).map(entry => ({
    key: !isNaN(Number(entry[0])) ? Number(entry[0]) : entry[0],
    children: entry[1],
  }));
}

export type ExportConfigurationFieldsProps = {};

export const ExportConfigurationFields = () => {
  const [t] = useTranslation('exports');
  const { data: settingsData } = useNew_DatevSettingsQuery({
    fetchPolicy: 'network-only',
  });

  const disabledAccountLengthFieldUpdate =
    settingsData?.new_datevSettings?.datevCoreDataSource
      ?.isAccountLengthSourceDatev;

  const disabledChartOfAccountFieldUpdate =
    settingsData?.new_datevSettings?.datevCoreDataSource
      ?.isChartOfAccountCodeSourceDatev;

  const disableFiscalStartDateFieldUpdate =
    settingsData?.new_datevSettings?.datevCoreDataSource
      ?.isFiscalStartDateSourceDatev;

  const accountLengthOptions = t('accountLength.values', {
    returnObjects: true,
  }) as { [key: string]: string };

  const chartOfAccountsOptions = t('chartOfAccounts.values', {
    returnObjects: true,
  }) as { [key: string]: string };

  const { bdsConnected } = useDatev(); // BDS-checked

  const [chartOfAccountCode, accountLength, fiscalYearStart] = useWatch({
    name: ['chartOfAccountCode', 'accountLength', 'fiscalYearStart'],
  });

  const chartOfAccountMessage = () => {
    if (disabledChartOfAccountFieldUpdate) {
      return t('chartOfAccounts.disabledMessage');
    }

    if (!chartOfAccountCode && !bdsConnected) {
      return t('chartOfAccounts.pleaseSyncFromDatev');
    }

    if (!chartOfAccountCode) {
      return t('chartOfAccounts.emptyState');
    }

    if (
      !supportedChartOfAccounts.includes(
        chartOfAccountCode as ChartOfAccountCode
      )
    ) {
      return t('chartOfAccounts.message');
    }
  };

  const chartOfAccountVariant = (): 'default' | 'warning' | 'error' => {
    if (chartOfAccountCode) {
      return 'default';
    }

    if (!chartOfAccountCode && !bdsConnected) {
      return 'warning';
    }

    return 'error';
  };

  const fiscalYearConfigMissing =
    !fiscalYearStart.day || !fiscalYearStart.month;

  return (
    <>
      <HookFormSelectField
        name="accountLength"
        label={t('accountLength.title')}
        items={toSelectOption(accountLengthOptions)}
        placeholder={t('accountLength.emptyState')}
        message={
          disabledAccountLengthFieldUpdate
            ? t('accountLength.disabledMessage')
            : !accountLength
            ? t('accountLength.emptyState')
            : undefined
        }
        variant={accountLength ? 'default' : 'error'}
        disabled={
          disabledAccountLengthFieldUpdate &&
          !!settingsData?.new_datevSettings?.chartOfAccount?.accountLength
        }
        readOnly={!bdsConnected}
      />
      <HookFormSelectField
        name="chartOfAccountCode"
        label={t('chartOfAccounts.title')}
        items={toSelectOption(chartOfAccountsOptions)}
        placeholder={
          !chartOfAccountCode && !bdsConnected
            ? t('chartOfAccounts.pleaseSyncFromDatevPlaceholder')
            : t('accountLength.emptyState')
        }
        message={chartOfAccountMessage()}
        variant={chartOfAccountVariant()}
        disabled={
          disabledChartOfAccountFieldUpdate &&
          !!settingsData?.new_datevSettings?.chartOfAccount?.code
        }
        readOnly={!bdsConnected}
      />
      <HookFormDayMonthSelectField
        name="fiscalYearStart"
        label={t('fiscalYearStart.label')}
        message={
          disableFiscalStartDateFieldUpdate ? (
            t('fiscalYearStart.disabledMessage')
          ) : fiscalYearConfigMissing ? (
            <Flex direction="column">
              <Paragraph>{t('fiscalYearStart.warningMessage')}</Paragraph>
              <br />
              <Paragraph>{t('fiscalYearStart.warningMessage2')}</Paragraph>
            </Flex>
          ) : null
        }
        variant={fiscalYearConfigMissing ? 'warning' : 'default'}
        disabled={
          disableFiscalStartDateFieldUpdate &&
          !!fiscalYearStart.day &&
          !!fiscalYearStart.month
        }
        readOnly={!bdsConnected}
      />
    </>
  );
};
