import { useGetVendorSuggestionsCountQuery } from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { vendorSuggestionsQueryVariables } from 'views/CreditCards/hooks/useVendorSuggestionsData';

export const useVendorSuggestionsCounters = () => {
  const creditCardsSetup = useCreditCardsSetup();

  const { data, loading } = useGetVendorSuggestionsCountQuery({
    variables: vendorSuggestionsQueryVariables,
    skip:
      !creditCardsSetup.showAllExtraFeatures ||
      !creditCardsSetup.isAddonBought ||
      !creditCardsSetup.isInUse,
  });

  const linkedCount =
    data?.getVendorSuggestionsCountForRecurringPayments.linkedCount ?? 0;

  const totalCount =
    data?.getVendorSuggestionsCountForRecurringPayments.totalCount ?? 0;

  const counters = {
    convertedVendors: linkedCount,
    vendorsToConvert: totalCount,
  };

  return { loading, counters };
};
