import {
  ContactQuery,
  ContactRelationshipType,
  ContactType,
  useContactQuery,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { ContactFormValues } from './ContactForm/contactFormSchema';

interface UseContactFormDefaultValuesReturn {
  defaultValues: ContactFormValues;
  legacyDueDateOffset?: number;
  loading: boolean;
  contact?: ContactQuery['contact'];
}

export interface UseContactFormDefaultValuesOptions {
  contactId?: string;
}

const generateDefaultValues = (
  contact: ContactQuery['contact']
): ContactFormValues => {
  const hasInternalName = Boolean(contact?.name?.value);
  const isOfCompanyType =
    contact?.additionalContactInfo?.contactType === ContactType.Company;

  const hasNoCompanyName = !Boolean(
    contact?.additionalContactInfo?.companyName
  );

  return {
    ...(contact?.name?.value && { name: contact.name.value }),
    ...(contact?.iban?.value && { iban: contact.iban.value }),
    ...(contact?.additionalContactInfo?.paymentMedium && {
      paymentMedium: contact?.additionalContactInfo?.paymentMedium,
    }),
    ...(contact?.paymentCondition?.id
      ? { paymentCondition: contact.paymentCondition.id }
      : isNil(contact?.dueDateOffset?.value) && { paymentCondition: 'none' }),
    ...(contact?.taxNumber?.value && { taxNumber: contact.taxNumber.value }),
    ...(contact?.vatId?.value && { vatId: contact.vatId.value }),
    ...(!isNil(contact?.createTransfer?.value) && {
      createTransfer: contact?.createTransfer?.value,
    }),
    ...(contact?.phoneNumber && {
      phoneNumber: contact.phoneNumber,
    }),
    ...(contact?.email && { email: contact.email }),
    ...(!isNil(contact?.customerNumber) && {
      customerNumber: String(contact?.customerNumber),
    }),
    ...(!isNil(contact?.accountsPayableNumber) && {
      accountsPayableNumber: String(contact?.accountsPayableNumber),
    }),
    ...(!isNil(contact?.accountsReceivableNumber) && {
      accountsReceivableNumber: String(contact?.accountsReceivableNumber),
    }),
    ...(contact?.additionalContactInfo?.companyName && {
      companyName: contact.additionalContactInfo.companyName,
    }),
    ...(hasInternalName &&
      isOfCompanyType &&
      hasNoCompanyName && {
        companyName: contact.name.value,
      }),
    contactType:
      contact?.additionalContactInfo?.contactType ?? ContactType.Company,
    ...(contact?.additionalContactInfo?.individualFirstName && {
      individualFirstName: contact.additionalContactInfo.individualFirstName,
    }),
    ...(contact?.additionalContactInfo?.individualLastName && {
      individualLastName: contact.additionalContactInfo.individualLastName,
    }),
    ...(contact?.additionalContactInfo?.notSpecifiedName && {
      notSpecifiedName: contact.additionalContactInfo.notSpecifiedName,
    }),
    relationshipType:
      contact?.relationshipType ?? ContactRelationshipType.Supplier,
    ...(!isNil(contact?.bankAccountNumber) && {
      bankAccountNumber: String(contact?.bankAccountNumber),
    }),
    ...(!isNil(contact?.swiftCode) && {
      swiftCode: String(contact?.swiftCode),
    }),
    bankInfoType: isNil(contact?.bankAccountNumber) ? 'SEPA' : 'NON_SEPA',
    addressInfoType: isNil(contact?.postOfficeBox)
      ? 'STREET'
      : 'POST_OFFICE_BOX',
    ...(!isNil(contact?.street) && {
      street: String(contact?.street),
    }),
    ...(!isNil(contact?.postOfficeBox) && {
      postOfficeBox: String(contact?.postOfficeBox),
    }),
    ...(!isNil(contact?.city) && {
      city: String(contact?.city),
    }),
    ...(!isNil(contact?.countryISOCode) && {
      country: String(contact?.countryISOCode),
    }),
    ...(!isNil(contact?.postalCode) && {
      postalCode: String(contact?.postalCode),
    }),
    ...(!isNil(contact?.createdAt) && {
      createdAt: new Date(contact.createdAt),
    }),
  };
};

/** Fetches a contact with given id and returns ContactFormValues */
export const useContactFormDefaultValues = ({
  contactId,
}: UseContactFormDefaultValuesOptions): UseContactFormDefaultValuesReturn => {
  const { data, loading } = useContactQuery({
    skip: contactId === undefined,
    fetchPolicy: 'network-only',
    variables: { input: { id: contactId as string } },
  });

  const contact = data?.contact;

  const legacyDueDateOffset = contact?.dueDateOffset?.value;

  return {
    defaultValues: generateDefaultValues(contact),
    legacyDueDateOffset,
    loading,
    contact,
  };
};
