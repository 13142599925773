import {
  FetchCardIssuerSettlementsQuery,
  PaymentFrequencyType,
} from 'generated-types/graphql.types';
import { amountFormatNonNull } from '../../../../utils/format';
import { CreditCardSettlementsTableData } from './types';

export const toTableData = (
  settlements: FetchCardIssuerSettlementsQuery['fetchCardIssuerPaymentSettlements']['edges']
): CreditCardSettlementsTableData[] => {
  return (settlements || []).map(settlement => {
    return {
      status: settlement.node.status,
      actualSettlementDate: settlement.node.actualPaymentDate
        ? new Date(settlement.node.actualPaymentDate)
        : undefined,
      formattedAmount: amountFormatNonNull(
        settlement.node.amount.value,
        settlement.node.amount.currency,
        {
          convertToBasicMonetaryUnit: true,
        }
      ),
    };
  });
};

export const paymentFrequencyTranslation: {
  [key in PaymentFrequencyType | 'UNKNOWN']: string;
} = {
  BI_WEEKLY: 'creditCardPayments.paymentFrequency.biWeekly',
  DAILY: 'creditCardPayments.paymentFrequency.daily',
  MONTHLY: 'creditCardPayments.paymentFrequency.monthly',
  PREFUNDED: 'creditCardPayments.paymentFrequency.prefunded',
  WEEKLY: 'creditCardPayments.paymentFrequency.weekly',
  /*** this key will be @deprecated soon; but for now, we need to map PREFUND to PREFUNDED translation key on the UI till it's removed from PaymentFrequencyType on the BE */
  PREFUND: 'creditCardPayments.paymentFrequency.prefunded',
  UNKNOWN: 'creditCardPayments.paymentFrequency.unknown',
};
