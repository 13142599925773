import { ProcessingFormFieldMetadataSource } from 'components/Form/ProcessingForm/ProcessingFormMetadataContext';
import { createContext, useContext } from 'react';
import { StorageFormValues } from './storageFormSchema';

export interface StorageFormFieldMetadata {
  confidence: number;
  source: ProcessingFormFieldMetadataSource;
}

/**
 * Represents storage form metadata mirroring structure of the form data
 * @todo Create a generic type that is shared between the processing and storage
 * form.
 */
export type StorageFormMetadata<TValues = StorageFormValues> = Partial<{
  [TKey in keyof TValues]: TValues[TKey] extends Record<string, any>
    ? StorageFormFieldMetadata | StorageFormMetadata<TValues[TKey]>
    : StorageFormFieldMetadata;
}>;

export const StorageFormMetadataContext = createContext<StorageFormMetadata>(
  {}
);

/** Returns storage form metadata */
export const useStorageFormMetadata = () => {
  return useContext(StorageFormMetadataContext);
};
