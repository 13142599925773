import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';

export const GoodsReceiptsKebabMenu = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const navigate = useNavigate();

  // TODO impelement goods receipts import history

  return (
    <KebabMenu
      items={[
        {
          id: 'history',
          label: t('history.title'),
          onAction: () => {
            navigate({ pathname: undefined });
          },
        },
      ]}
      label={t('history.tooltip')}
    />
  );
};
