/**
 * Returns true if number is within range.
 *
 * @param {number} numberToCheck Number to check against range
 * @param {number} rangeStart Start of range (inclusive)
 * @param {number} rangeEnd End of range (inclusive)
 * @returns {boolean} True if number is in range, false otherwise
 */
export const isNumberInRange = (
  numberToCheck: number,
  rangeStart: number,
  rangeEnd: number
): boolean => numberToCheck >= rangeStart && numberToCheck <= rangeEnd;
