import { Color } from '@candisio/design-system';
import { amountCellProps } from 'components/Table/Cells/Amount';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import {
  CardIssuerCard,
  CardIssuerCardEdgeDataFragment,
  CardIssuerCardsForCardManagerFiltersInput,
  CardIssuerCardSortField,
  CardIssuerCardsSortInput,
  CardStatus,
  CardType,
  DateFilterInput,
  DateFilterOption,
  SortDirection,
} from 'generated-types/graphql.types';
import { i18n } from 'providers/LocaleProvider';
import { Column, Filters, SortingRule } from 'react-table';
import { CardholderWithStatusCell } from 'views/CreditCards/CardManagerView/components/CreditCardsTable/Cells/CardholderCell/CardHolderWithStatusCell';
import { GenericCell } from '../../../components/Transactions/Table/GenericCell/GenericCell';
import { AmountAgainstLimitCell } from '../CardManagerView/components/CreditCardsTable/Cells/AmounAgainstLimitCell/AmountAgainstLimitCell';
import { CardAccountingCell } from '../CardManagerView/components/CreditCardsTable/Cells/CardAccountingCell';
import { CardNumberCell } from '../CardManagerView/components/CreditCardsTable/Cells/CardNumberCell';
import { CategoryCell } from '../CardManagerView/components/CreditCardsTable/Cells/CategoryCell';
import { DateCell } from '../CardManagerView/components/CreditCardsTable/Cells/DateCell';
import { PendingRequestCell } from '../CardManagerView/components/CreditCardsTable/Cells/PendingRequestCell';
import { StatusCell } from '../CardManagerView/components/CreditCardsTable/Cells/StatusCell';
import { TransactionLimitCell } from '../CardManagerView/components/CreditCardsTable/Cells/TransactionLimitCell';
import { ValidUntilCell } from '../CardManagerView/components/CreditCardsTable/Cells/ValidUntilCell';
import { ToolbarFilter } from '../CardManagerView/components/CreditCardsTable/CreditCardsTableToolbar';
import {
  activeStatuses,
  inactiveStatuses,
} from '../hooks/useCardIssuerCardsForCardManagerData';
import { CreditCardsTableData, PendingRequest } from '../types';

export const mapToCreditCardsTableData = (
  creditCardEdges: CardIssuerCardEdgeDataFragment[]
): CreditCardsTableData[] => {
  return creditCardEdges.map(ccEdge => ({
    id: ccEdge.node.id,
    status: ccEdge.node.status,
    cardType: ccEdge.node.type,
    purpose: ccEdge.node.purpose ?? undefined,
    cardLabel: ccEdge.node.label ?? null,
    category: ccEdge.node.category ?? undefined,
    pendingRequest: ccEdge.node.pendingRequest ?? null,
    limit: ccEdge.node.limit ?? undefined,
    availableBalance: ccEdge.node.availableBalance ?? undefined,
    cardNumber: ccEdge.node.refNum ? `•••• ${ccEdge.node.refNum}` : null,
    issued: ccEdge.node.issuingDate ? new Date(ccEdge.node.issuingDate) : null,
    transactionLimit: ccEdge.node.transactionLimit ?? undefined,
    brand: ccEdge.node.brand ?? undefined,
    validUntil: ccEdge.node.expiryDate
      ? new Date(ccEdge.node.expiryDate)
      : null,
    cardholderName: ccEdge.node.cardholder
      ? {
          membershipEmail: ccEdge.node.cardholder.membershipEmail,
          firstName: ccEdge.node.cardholder.firstName,
          lastName: ccEdge.node.cardholder.lastName,
          avatarUrl: ccEdge.node.cardholder.avatarUrl,
          membershipId: ccEdge.node.cardholder.membershipId,
        }
      : null,
    cardAccounting: ccEdge.node.hasAccountingData ? 'Active' : 'Not_Active',
    lastTransactionCreatedAt: ccEdge.node.lastTransactionCreatedAt
      ? new Date(ccEdge.node.lastTransactionCreatedAt)
      : undefined,
  }));
};

export const creditCardsColumnsDefinition = ({
  t,
  showAllExtraCreditCardFeatures,
}: {
  t: i18n.TFunction;
  showAllExtraCreditCardFeatures: boolean;
}) => {
  const allColumns: Array<Column<CreditCardsTableData>> = [
    {
      accessor: 'cardNumber',
      Cell: CardNumberCell,
      disableSortBy: true,
      width: '145px',
      Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
        <FilterWithSearchAndPagination
          column={column}
          onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
        />
      ),
    },
    {
      accessor: 'cardType',
      id: 'cardType',
      Cell: ({ value }) => {
        const translation = t(mapTypesToTranslations[value]);

        return <GenericCell value={translation} />;
      },
    },
    { accessor: 'status', Cell: StatusCell },
    {
      accessor: 'cardholderName',
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: CardholderWithStatusCell,
    },
    showAllExtraCreditCardFeatures
      ? {
          accessor: 'cardAccounting',
          disableSortBy: true,
          width: '168px',
          Cell: ({ value }) => <CardAccountingCell cardAccounting={value} />,
        }
      : { accessor: 'category', Cell: CategoryCell, disableSortBy: true },
    {
      accessor: 'issued',
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: DateCell,
      sortType: 'datetime',
    },
    {
      accessor: 'validUntil',
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: ValidUntilCell,
      sortType: 'datetime',
    },
    {
      accessor: 'lastTransactionCreatedAt',
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: DateCell,
    },
    {
      accessor: 'transactionLimit',
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: TransactionLimitCell,
      disableFilters: true,
      ...amountCellProps,
    },
    {
      accessor: 'availableBalance',
      Cell: AmountAgainstLimitCell,
      width: '162px',
    },
    {
      accessor: 'pendingRequest',
      Cell: PendingRequestCell,
    },
  ];

  return allColumns;
};

export const mappedStatusesToColor: {
  [key in CardStatus]: { color: Color; translation: string };
} = {
  [CardStatus.Active]: {
    color: 'green',
    translation: 'cardManagerView.status.active',
  },
  [CardStatus.Expired]: {
    color: 'blue',
    translation: 'cardManagerView.status.expired',
  },
  [CardStatus.Locked]: {
    color: 'blue',
    translation: 'cardManagerView.status.locked',
  },
  [CardStatus.Pending]: {
    color: 'yellow',
    translation: 'cardManagerView.status.pending',
  },
  [CardStatus.RequestCancelled]: {
    color: 'gray',
    translation: 'cardManagerView.status.requestCancelled',
  },
  [CardStatus.RequestRejected]: {
    color: 'gray',
    translation: 'cardManagerView.status.requestRejected',
  },
  [CardStatus.Requested]: {
    color: 'yellow',
    translation: 'cardManagerView.status.requested',
  },
  [CardStatus.Terminated]: {
    color: 'gray',
    translation: 'cardManagerView.status.terminated',
  },
  [CardStatus.Processing]: {
    color: 'gray',
    translation: 'cardManagerView.status.processing',
  },
  [CardStatus.LockedPin]: {
    color: 'gray',
    translation: 'cardManagerView.status.lockedPin',
  },
};

const availabeFiltersCreditcardsTable: {
  [key in keyof Pick<
    CreditCardsTableData,
    | 'issued'
    | 'cardNumber'
    | 'status'
    | 'validUntil'
    | 'cardholderName'
    | 'cardType'
    | 'category'
    | 'cardAccounting'
  >]: keyof CreditCardsTableData;
} = {
  issued: 'issued',
  cardNumber: 'cardNumber',
  validUntil: 'validUntil',
  status: 'status',
  cardholderName: 'cardholderName',
  cardType: 'cardType',
  category: 'category',
  cardAccounting: 'cardAccounting',
};

const mapSortIdsToCardIssuerCardsSortFields: {
  [key in keyof Pick<
    CreditCardsTableData,
    | 'status'
    | 'issued'
    | 'cardType'
    | 'pendingRequest'
    | 'transactionLimit'
    | 'validUntil'
    | 'availableBalance'
    | 'cardholderName'
    | 'lastTransactionCreatedAt'
  >]: CardIssuerCardSortField;
} = {
  issued: CardIssuerCardSortField.IssuingDate,
  status: CardIssuerCardSortField.Status,
  cardType: CardIssuerCardSortField.Type,
  pendingRequest: CardIssuerCardSortField.Requests,
  transactionLimit: CardIssuerCardSortField.TransactionLimit,
  validUntil: CardIssuerCardSortField.ExpiryDate,
  availableBalance: CardIssuerCardSortField.AvailableAndRequestedLimitsCombined,
  cardholderName: CardIssuerCardSortField.CardholderFullName,
  lastTransactionCreatedAt: CardIssuerCardSortField.LastTransactionCreatedAt,
};

const mapToDateRangeFilters = (dateFilter: string[]): DateFilterInput => {
  const activeFilterValues = dateFilter?.[0] as string;
  const [from, to] = activeFilterValues?.split('-');

  return {
    dateFrom: from,
    dateTo: to,
    filterOption: DateFilterOption.Custom,
  };
};

export const mapToSortInput = (
  sort: Array<SortingRule<CreditCardsTableData>>
): CardIssuerCardsSortInput | undefined => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  return {
    field:
      mapSortIdsToCardIssuerCardsSortFields[
        firstSort.id as keyof typeof mapSortIdsToCardIssuerCardsSortFields
      ],
    direction: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};

export const mapToFilterInput = (
  filters: Filters<CreditCardsTableData>,
  toolbarFilter: ToolbarFilter
): CardIssuerCardsForCardManagerFiltersInput => {
  return filters.reduce<CardIssuerCardsForCardManagerFiltersInput>(
    (cardIssuerFilterInput, filter) => {
      const existingDateFilter = cardIssuerFilterInput.dateRangeFilters;
      if (filter.id === availabeFiltersCreditcardsTable.status) {
        return {
          ...cardIssuerFilterInput,
          statuses: filter.value,
        };
      }

      if (filter.id === availabeFiltersCreditcardsTable.cardholderName) {
        return { ...cardIssuerFilterInput, cardholderIds: filter.value };
      }

      if (filter.id === availabeFiltersCreditcardsTable.cardType) {
        return { ...cardIssuerFilterInput, types: filter.value };
      }

      if (filter.id === availabeFiltersCreditcardsTable.issued) {
        return {
          ...cardIssuerFilterInput,
          dateRangeFilters: {
            ...existingDateFilter,
            issuingDate: mapToDateRangeFilters(filter.value),
          },
        };
      }

      if (filter.id === availabeFiltersCreditcardsTable.validUntil) {
        return {
          ...cardIssuerFilterInput,
          dateRangeFilters: {
            ...existingDateFilter,
            expiryDate: mapToDateRangeFilters(filter.value),
          },
        };
      }

      if (filter.id === availabeFiltersCreditcardsTable.cardNumber) {
        return { ...cardIssuerFilterInput, ids: filter.value };
      }

      if (filter.id === availabeFiltersCreditcardsTable.category) {
        return { ...cardIssuerFilterInput, categories: filter.value };
      }

      if (filter.id === availabeFiltersCreditcardsTable.cardAccounting) {
        if (
          (filter.value || []).length === 2 ||
          (filter.value || []).length === 0
        ) {
          return {
            ...cardIssuerFilterInput,
            hasAccountingData: null,
          };
        } else if (filter.value?.[0] === 'Active') {
          return {
            ...cardIssuerFilterInput,
            hasAccountingData: true,
          };
        } else {
          return {
            ...cardIssuerFilterInput,
            hasAccountingData: false,
          };
        }
      }

      return cardIssuerFilterInput;
    },
    {
      statuses:
        toolbarFilter === 'Inactive' ? inactiveStatuses : activeStatuses,
    }
  );
};

export enum CreditCardQueryParams {
  status = 'status',
  cardholderName = 'cardholderName',
  issuingDate = 'issued',
  cardNumber = 'cardNumber',
  cardType = 'cardType',
  expiryDate = 'validUntil',
  category = 'category',
  cardAccounting = 'cardAccounting',
}

export type CreditCardsTableQueryParams = {
  [CreditCardQueryParams.cardholderName]?: string[];
  [CreditCardQueryParams.issuingDate]?: string[];
  [CreditCardQueryParams.expiryDate]?: string[];
  [CreditCardQueryParams.status]?: string[];
  [CreditCardQueryParams.cardNumber]?: string[];
  [CreditCardQueryParams.cardType]?: string[];
  [CreditCardQueryParams.category]?: string[];
  [CreditCardQueryParams.cardAccounting]?: string[];
};

export const availableFilters = [
  CreditCardQueryParams.cardholderName,
  CreditCardQueryParams.issuingDate,
  CreditCardQueryParams.expiryDate,
  CreditCardQueryParams.status,
  CreditCardQueryParams.cardNumber,
  CreditCardQueryParams.cardType,
  CreditCardQueryParams.category,
  CreditCardQueryParams.cardAccounting,
];

export enum CellVariant {
  newRequest = 'newRequest',
  limitChange = 'limitChange',
}

export const mapPendingRequestToCellVariant: {
  [key in PendingRequest]: CellVariant;
} = {
  VirtualCardRequest: CellVariant.newRequest,
  CardLimitChangeRequest: CellVariant.limitChange,
  SingleUseVirtualCardRequest: CellVariant.newRequest,
  PhysicalCardRequest: CellVariant.newRequest,
};

export const mapTypesToTranslations: Record<CardType, string> = {
  BLACK: 'cardManagerView.cardType.black',
  PHYSICAL: 'cardManagerView.cardType.physical',
  SINGLE_USE: 'cardManagerView.cardType.virtualx1',
  VIRTUAL: 'cardManagerView.cardType.virtual',
};

export const exportCardId = 'export-card';
export const statementCardId = 'statements-card';

export const scrollToTarget = (hash: string) => {
  setTimeout(() => {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, 5);
};

export const mapRecordsToFilterItem = (
  entries: Pick<CardIssuerCard, 'id' | 'label' | 'refNum' | 'purpose'>[]
) =>
  entries
    .map(cc => ({
      id: cc.id,
      label: cc.label ?? '',
      cardNumber: cc.refNum ?? '',
      purpose: cc.purpose ?? '',
    }))
    .sort((a, b) =>
      `${a.cardNumber} ${a.label}`.localeCompare(`${b.cardNumber} ${b.label}`)
    )
    .filter(cc => cc.cardNumber);
