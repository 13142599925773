import {
  DocumentCurrency,
  ExportableEntityType,
  PaymentMethod,
  PaymentSettlementStatus,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { ExportEntity } from '../../types';
import { Settlement } from './toSettlementExportEntity';

export const mapCardSettlementToExportEntity = (
  settlement: Settlement
): ExportEntity => ({
  id: settlement.id,
  contact: '',
  amount: {
    __typename: 'Money',
    amount: settlement.amount.value,
    currency: DocumentCurrency.Eur,
    precision: settlement.amount.precision,
  },
  invoiceDate: new Date(
    settlement.actualPaymentDate ??
      settlement.plannedPaymentDate ??
      settlement.paymentCreatedAt
  ),
  invoiceNumber: settlement.publicPaymentId || '',
  paymentState: {
    isPaid: settlement.status === PaymentSettlementStatus.Paid,
    method: PaymentMethod.CardSettlementPayment,
  },
  settlementPaymentStatus: settlement.status,
  transactionStatus:
    settlement.status === PaymentSettlementStatus.Paid
      ? TransactionStatus.Confirmed
      : TransactionStatus.Pending,
  hasDocument: !!settlement.statement?.hasFile,
  type: ExportableEntityType.CardSettlement,
  statementId: settlement.statement?.id,
});
