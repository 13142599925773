import {
  Maybe,
  useBookingKeysActiveQuery,
} from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { useCallback } from 'react';
/**
 *returns a function that compares the vat rate with the suggested tax code and  returns the suggested tax code if they match
 */

export const useGetSuggestedTaxCodeIfMatched = () => {
  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery();
  const { shouldUseSapNetAmount } = useSap();

  return useCallback(
    (vatRate: Maybe<number>, suggestedTaxCodeId: Maybe<string>) => {
      if (!shouldUseSapNetAmount) {
        return suggestedTaxCodeId;
      }

      const taxPercentageOfSuggestedBookingKey =
        bookingKeysActiveData?.bookingKeysActive.find(
          bookingKey => bookingKey.id === suggestedTaxCodeId
        )?.taxPercentage;

      if (!vatRate && taxPercentageOfSuggestedBookingKey === 0) {
        return suggestedTaxCodeId;
      }

      // check vat rate with suggestion tax code if they are match then use suggestion:
      return vatRate === taxPercentageOfSuggestedBookingKey
        ? suggestedTaxCodeId
        : undefined;
    },
    [bookingKeysActiveData, shouldUseSapNetAmount]
  );
};
