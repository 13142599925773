import {
  Avatar,
  Flex,
  Grid,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { NotificationProps } from '../getNotificationInfo';

export const DocumentCommentCreatedInAppNotification = (
  props: NotificationProps<'DocumentCommentCreatedInAppNotification'>
) => {
  const [t] = useTranslation();

  const {
    author,
    commentText,
    invoiceContact,
    invoiceAmount,
    commentCreatedAt,
    documentNumberCommentCreated,
  } = props;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const formattedAmount = amountFormat(
    invoiceAmount?.value,
    invoiceAmount?.currencyCode
  );

  const authorName = author.name;

  return (
    <Grid
      templateRows="auto 1fr auto"
      gap="space6"
      height="100%"
      borderBottomRadius="medium">
      <Flex wrap="wrap" fontSize="small" gap="space4">
        <Trans
          i18nKey="notifications:events.commentAdded.notification"
          values={{
            authorName,
            timeAgo: DateFormatters.fromNow(new Date(commentCreatedAt)),
          }}>
          {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
          <TruncatedText color="gray800" maxWidth="15ch">
            {{ authorName }}
          </TruncatedText>
          <Text color="gray500">mentioned you in a comment</Text>
          <Text color="gray500" {...triggerProps} ref={triggerRef}>
            3 minutes ago
          </Text>
        </Trans>
      </Flex>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(commentCreatedAt))}
        </Tooltip>
      )}

      <Grid autoFlow="column" gap="space8" justifyContent="start">
        <TruncatedText color="gray800" fontWeight="regular" fontSize="basic">
          {t('notifications:events.commentText', { commentText })}
        </TruncatedText>
      </Grid>

      <Grid autoFlow="column" gap="space4" justifyContent="start">
        {[invoiceContact, formattedAmount, documentNumberCommentCreated].map(
          (value, index, arr) => {
            const shouldShowSeparator = index !== arr.length - 1 && value;

            return (
              <Fragment key={index}>
                {value && (
                  <TruncatedText fontSize="small" color="gray500">
                    {value}
                  </TruncatedText>
                )}
                {shouldShowSeparator && (
                  <Text fontSize="small" color="gray500">
                    •
                  </Text>
                )}
              </Fragment>
            );
          }
        )}
      </Grid>
    </Grid>
  );
};

export const DocumentCommentCreatedInAppNotificationIcon = ({
  author,
}: NotificationProps<'DocumentCommentCreatedInAppNotification'>) => (
  <Avatar
    name={author.name}
    img={author.avatarUrl ?? undefined}
    hasTooltip={false}
    size="medium"
  />
);

export const DocumentCommentCreatedInAppNotificationToast = (
  props: NotificationProps<'DocumentCommentCreatedInAppNotification'>
) => {
  const { author, commentText } = props;

  const [t] = useTranslation();

  const authorName = author.name;

  return (
    <>
      <Grid templateRows="auto 1fr auto" gap="space6" height="100%">
        <Grid autoFlow="column" alignItems="start" justifyContent="start">
          <Flex wrap="wrap" fontSize="small" gap="space4">
            <Trans
              i18nKey="notifications:events.commentAdded.toast"
              values={{
                authorName,
              }}>
              {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
              <TruncatedText color="gray800" maxWidth="15ch">
                {{ authorName }}
              </TruncatedText>
              <Text color="gray500">mentioned you in a comment</Text>
            </Trans>
          </Flex>
        </Grid>

        <Grid autoFlow="column" gap="space8" justifyContent="start">
          <TruncatedText color="gray800" fontWeight="regular" fontSize="basic">
            {t('notifications:events.commentText', { commentText })}
          </TruncatedText>
        </Grid>
      </Grid>
    </>
  );
};
