import {
  UserDropdownItem,
  UserDropdownItemProps,
} from 'components/UserDropdownItem/UserDropdownItem';
import { useGetUserAbsence } from 'containers/absence/useGetUserAbsence';
import { SelectOptionData } from './types';

export interface UserWithAbsenceProps extends SelectOptionData {}

export const UserWithAbsence = ({
  label,
  image,
  value,
}: UserWithAbsenceProps) => {
  const { getUserAbsence, getUsersSubstitute } = useGetUserAbsence();

  const absence = getUserAbsence(value);

  if (absence) {
    if (!absence.status)
      return <UserDropdownItem name={label} avatarUrl={image ?? undefined} />;

    const substitute = getUsersSubstitute(
      absence.substituteMembershipId ?? undefined
    );

    const userAbsence: UserDropdownItemProps['absence'] = {
      fromDate: absence.fromDate ? new Date(absence.fromDate) : undefined,
      toDate: absence.toDate ? new Date(absence.toDate) : undefined,
      note: absence?.note ?? undefined,
      status: absence.status,
      substitute,
    };

    return (
      <UserDropdownItem
        name={label}
        avatarUrl={image ?? undefined}
        absence={userAbsence}
      />
    );
  }

  return <UserDropdownItem name={label} avatarUrl={image ?? undefined} />;
};
