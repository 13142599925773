import { Button, Heading } from '@candisio/design-system';
import { getMemberships } from 'components/Comment/gql';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { getOrganizationMembershipsQuery } from 'containers/credit-cards/CreateCreditCard/gql';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import {
  Locale,
  useCreateUserMutation,
  useCreateUserSsoMutation,
} from 'generated-types/graphql.types';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useSSO } from 'orgConfig/sso/sso';
import { useTeams } from 'orgConfig/teams/useTeams';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import useRouter from 'use-react-router';
import { useGetTeamFieldOptions } from 'views/Settings/TeamMembers/hooks/useGetTeamFieldOptions';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { getApproversQuery } from 'views/Settings/TeamMembers/queries';
import { LocalMembershipStatus } from 'views/Settings/TeamMembers/UsersListView';
import { generateTeamMembersPath, getUserInfo } from '../../../utils';
import { ToastMessage } from '../components/ToastMessage';
import {
  TeamMemberFormOutput,
  TeamMemberFormValues,
} from '../teamMemberFormSchemaDeprecated';
import { InviteFormDeprecated } from './InviteFormDeprecated';

type Props = {
  allUsers: User[];
  closeDrawer: () => void;
  loading?: boolean;
  organizationLanguage: Locale | null;
  onOpenIssueCardModal: (createdUserId: string) => void;
};

const INVITE_TEAM_MEMBER_FORM_ID = 'invite-team-member';

export const InviteFormContainerDeprecated = ({
  allUsers,
  closeDrawer,
  loading,
  organizationLanguage,
  onOpenIssueCardModal,
}: Props) => {
  const { isTeamsFeatureEnabled } = useTeams();
  const { success, error } = useToastMessage();
  const {
    history,
    match: {
      url,
      path,
      params: { organizationSlug },
    },
  } = useRouter<{ organizationSlug: string }>();

  const sso = useSSO();

  const [t] = useTranslation();
  const { isInUse, showAllExtraFeatures } = useCreditCardsSetup();
  const { canIssueCards } = usePermissionsForCreditCards();

  const {
    handleDebounceSearch,
    loadMore,
    loading: isLoadingTeamOptions,
    teamOptions,
    handleSetSelectedTeams,
    selectedMappedTeams,
  } = useGetTeamFieldOptions();

  const [createUserSSO, { loading: isSubmittingCreateUserSSO }] =
    useCreateUserSsoMutation();

  const [createUser, { loading: isSubmittingCreateUser }] =
    useCreateUserMutation();

  const canInviterIssueCard = isInUse && canIssueCards && showAllExtraFeatures;

  const onSubmitDeprecated = useCallback(
    async (values: TeamMemberFormOutput) => {
      const { issueCreditCard, teams, ...userData } = values;
      //TODO Use optimistic UI updates

      const input = {
        ...userData,
        ...((teams ?? []).length > 0 ? { teamIds: teams } : {}),
      };

      try {
        const result = await createUser({
          // PLEASE REMOVE THIS
          variables: { input },
          refetchQueries: [
            'organizationMemberships',
            {
              query: getOrganizationMembershipsQuery,
              variables: membershipsQueryFilter.ActiveAndPending,
            },
            {
              query: getApproversQuery,
              variables: { name: '' },
            },
            {
              query: getMemberships,
              variables: membershipsQueryFilter.Active,
            },
          ],
        });

        const nameAndEmail = getUserInfo(userData);
        const pathname = generateTeamMembersPath({
          organizationSlug,
          path,
          url,
          userId: result.data?.createUser?.id,
        });

        const content = (
          <ToastMessage
            history={history}
            nameAndEmail={nameAndEmail}
            pathname={pathname}
            status={LocalMembershipStatus.PENDING}
            translationKey="settings.teamMembers.actions.inviteSent"
          />
        );

        success(content);

        closeDrawer();
        const createdUserId = result.data?.createUser?.id;
        const { errors } = result;
        const canNavigateToIssueCardModal =
          !errors?.length &&
          createdUserId &&
          canInviterIssueCard &&
          issueCreditCard;

        if (canNavigateToIssueCardModal) {
          onOpenIssueCardModal(createdUserId);
        }
      } catch (e) {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        error(t(`${(e as Error).message}`));

        return e;
      }

      return;
    },
    [
      canInviterIssueCard,
      closeDrawer,
      createUser,
      error,
      history,
      onOpenIssueCardModal,
      organizationSlug,
      path,
      success,
      t,
      url,
    ]
  );

  const onSubmitSsoUserDeprecated = useCallback(
    async (values: TeamMemberFormOutput) => {
      const { issueCreditCard, teams, ...userData } = values;

      const input = {
        ...userData,
        ...((teams ?? []).length > 0 ? { teamIds: teams } : {}),
      };

      // TODO Use optimistic UI updates
      try {
        const result = await createUserSSO({
          variables: { input },
          refetchQueries: [
            'organizationMemberships',
            {
              query: getOrganizationMembershipsQuery,
              variables: membershipsQueryFilter.ActiveAndPending,
            },
            {
              query: getApproversQuery,
              variables: { name: '' },
            },
            {
              query: getMemberships,
              variables: membershipsQueryFilter.Active,
            },
          ],
        });

        const nameAndEmail = getUserInfo(userData);
        const pathname = generateTeamMembersPath({
          organizationSlug,
          path,
          url,
          userId: result.data?.createUserSSO?.id,
        });

        const content = (
          <ToastMessage
            history={history}
            nameAndEmail={nameAndEmail}
            pathname={pathname}
            status={LocalMembershipStatus.PENDING}
            translationKey="settings.teamMembers.actions.inviteSent"
          />
        );

        success(content);

        closeDrawer();
        const createdUserId = result.data?.createUserSSO?.id;
        const { errors } = result;
        const canNavigateToIssueCardModal =
          !errors?.length &&
          createdUserId &&
          canInviterIssueCard &&
          issueCreditCard;

        if (canNavigateToIssueCardModal) {
          onOpenIssueCardModal(createdUserId);
        }
      } catch (e) {
        // eslint-disable-next-line candis/no-template-strings-inside-translation
        error(t(`${(e as Error).message}`));

        return e;
      }

      return;
    },
    [
      canInviterIssueCard,
      closeDrawer,
      createUserSSO,
      error,
      history,
      onOpenIssueCardModal,
      organizationSlug,
      path,
      success,
      t,
      url,
    ]
  );

  const teamFieldOptions = {
    isLoading: isLoadingTeamOptions,
    onInputChange: handleDebounceSearch,
    onEndReached: loadMore,
    defaultItems: teamOptions,
    onChange: handleSetSelectedTeams,
    selectedOptions: selectedMappedTeams,
  };

  const [initialValues] = useState<TeamMemberFormValues>({
    firstName: '',
    lastName: '',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    locale: organizationLanguage!,
    email: '',
    roles: [],
    issueCreditCard: canInviterIssueCard,
    teams: [],
  });

  const isTeamsFieldHidden = !isTeamsFeatureEnabled;

  return (
    <DrawerLayout
      onClose={closeDrawer}
      header={
        <Heading as="h3">
          {t('settings.teamMembers.details.tabs.invite')}
        </Heading>
      }
      footer={
        <Button
          form={INVITE_TEAM_MEMBER_FORM_ID}
          type="submit"
          loading={isSubmittingCreateUser || isSubmittingCreateUserSSO}
          disabled={
            loading || isSubmittingCreateUser || isSubmittingCreateUserSSO
          }>
          {t('settings.teamMembers.form.actions.invite')}
        </Button>
      }>
      <InviteFormDeprecated
        hide={{
          roles: sso.provides.roles,
          firstName: sso.provides.firstName,
          lastName: sso.provides.lastName,
          teams: isTeamsFieldHidden,
        }}
        initialValues={initialValues}
        onSubmit={sso.isActive ? onSubmitSsoUserDeprecated : onSubmitDeprecated}
        allUserEmails={allUsers.map(({ email }) => email)}
        formId={INVITE_TEAM_MEMBER_FORM_ID}
        canInviterIssueCard={canInviterIssueCard}
        teamFieldOptions={teamFieldOptions}
      />
    </DrawerLayout>
  );
};
