import { TabViewLayout } from 'components/TabViewLayout';
import { ReactNode } from 'react';
import { TabView } from '../models';
import { InboxTabs } from './InboxTabs';

export const InboxViewContainer = ({
  activeTab,
  children,
}: {
  activeTab: TabView;
  children: ReactNode;
}) => {
  return (
    <TabViewLayout tabs={<InboxTabs activeTab={activeTab} />}>
      {children}
    </TabViewLayout>
  );
};
