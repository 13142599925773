import {
  ComboBox,
  ComboBoxProps,
  FieldContainer,
  FieldContainerProps,
  Item,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import {
  FocusEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHookFormField } from './useHookFormField';

export interface HookFormPaginatedComboBoxFieldProps<
  TFormValues extends FieldValues
> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Keep the input value even if not found in list items */
  allowsCustomValue?: boolean;
  /** Show a placeholder when combo box dropdown is empty? */
  allowsEmptyCollection?: boolean;
  /** Should field focus on mount? */
  autoFocus?: boolean;
  /**
   * `<Item>` elements representing combo box options, or custom item render
   * function
   */
  children?: ComboBoxProps['children'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Shown when dropdown list is empty */
  emptyListPlaceholder?: ReactNode;
  /** Combo box options */
  items?: Array<Record<string, any>>;
  /** Field label */
  label: string;
  /** Indicates if the options inside the field are still to be loaded */
  loading?: boolean;
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Called when field value changes */
  onChange?: (value: string | null) => void;
  /** Called when user scrolls to end of dropdown list */
  onEndReached?: (index: number) => void;
  /** Called when user searches in the input field */
  onSearch?: (inputValue: string) => void | Promise<void>;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Custom render function for combo box dropdown */
  renderCustomDropdown?: (listbox: ReactElement) => ReactNode;
  /**
   * Show filtered items on open (by default *all* items will be shown on open)
   */
  persistFilter?: boolean;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** list item height */
  itemHeight?: ComboBoxProps['itemHeight'];
  /** Optional prop that can be passed to keep dropdown open after blur */
  persistDropdown?: ComboBoxProps['persistDropdown'];
  /** Optional prop that can be passed to show separator lines between items */
  showSeparator?: ComboBoxProps['showSeparator'];
  /** Boolean to display loading skeleton */
  isLoading?: boolean;
  /** Register input's ref into the hook form, please note that it can have unintended side effects */
  forceInputFieldRef?: boolean;
  /**
   * Delay to show the message after focusing the field.
   * Required to handle processing animations that need to finish beforehand
   * because the UI might glitch othewise
   */
  delayForShowOnFocusInMS?: number;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  clearable?: boolean;
}

// @TODO Design system ComboBox should already do something similar
const defaultChildren: ComboBoxProps['children'] = item => <Item {...item} />;

/**
 * A controlled combo-box field for React Hook Form that works with paginated
 * items.
 *
 * Form value must be an object with `value` and `inputValue` properties.
 *
 * Internally, this component uses two `useController` hooks to separately
 * control the `value` and `inputValue` properties in the form state.
 *
 * This might seem wrong but after trying just about everything else, this
 * was the only sane way I found to deal with paginated items.
 */
export const HookFormPaginatedComboBoxField = <
  TFormValues extends FieldValues
>({
  allowsCustomValue,
  children = defaultChildren,
  control,
  disabled,
  items = [],
  label,
  loading,
  message,
  name,
  onChange: onChangeProp,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  onSearch,
  persistFilter = false,
  placeholder,
  readOnly,
  variant,
  isLoading,
  forceInputFieldRef,
  delayForShowOnFocusInMS,
  clearable = true,
  ...restProps
}: HookFormPaginatedComboBoxFieldProps<TFormValues>) => {
  const [t] = useTranslation();

  const [showMessageOnFocus, setShowMessageOnFocus] = useState(false);

  const {
    fieldContainerProps,
    inputProps: { onChange, onBlur, value, ...inputProps },
  } = useHookFormField<TFormValues>({
    control,
    disabled,
    message,
    name: `${name}.value` as UseControllerProps<TFormValues>['name'],
    onChange: onChangeProp,
    readOnly,
    variant,
    forceInputFieldRef,
  });

  const {
    field: { onChange: onInputChange, value: inputValue },
  } = useController<TFormValues>({
    name: `${name}.inputValue` as UseControllerProps<TFormValues>['name'],
    control,
  });

  const labelInput = typeof label === 'string' && label ? label : name;

  const { labelProps, fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': labelInput,
  });

  // there can be multiple search requests in flight at the same time
  const [searchCount, setSearchCount] = useState(0);

  const {
    message: fieldMessage,
    variant: fieldVariant,
    ...restFieldContainerProps
  } = fieldContainerProps;

  useEffect(() => {
    setTimeout(() => {
      if (fieldVariant === 'error' && !showMessageOnFocus) {
        setShowMessageOnFocus(true);
      } else if (fieldVariant !== 'error' && showMessageOnFocus) {
        setShowMessageOnFocus(false);
      }
    }, delayForShowOnFocusInMS || 0);
  }, [
    fieldVariant,
    delayForShowOnFocusInMS,
    setShowMessageOnFocus,
    showMessageOnFocus,
  ]);

  const handleFocus: FocusEventHandler<HTMLInputElement> = event => {
    event.target.select();
    onFocusProp?.(event);
  };

  return (
    // remove message prop when FF is archived
    // and these props are removed from the DS
    <FieldContainer
      label={label}
      variant={fieldVariant}
      isLoading={isLoading}
      {...(mergeProps(restFieldContainerProps, labelProps) as Omit<
        FieldContainerProps,
        'color'
      >)}>
      <ComboBox
        allowsCustomValue={allowsCustomValue}
        emptyListPlaceholder={t('common.nothingFound')}
        inputValue={inputValue ?? ''}
        isVirtualized
        items={items}
        loading={loading || searchCount > 0}
        onInputChange={async newInputValue => {
          if (readOnly) {
            return;
          }

          if (newInputValue === '') {
            onChange(null);
          }

          onInputChange(newInputValue);

          setSearchCount(count => count + 1);
          await onSearch?.(newInputValue);
          setSearchCount(count => count - 1);
        }}
        onOpenChange={async (isOpen, menuTrigger) => {
          if (readOnly) {
            return;
          }

          if (!persistFilter && menuTrigger === 'manual' && isOpen) {
            setSearchCount(count => count + 1);
            await onSearch?.('');
            setSearchCount(count => count - 1);
          }
        }}
        onSelectionChange={newValue => {
          if (readOnly) {
            return;
          }

          // When combo box input value is controlled, onSelectionChange is
          // firing on blur (React Aria bug?) so we need to check if value
          // actually changed
          if (newValue === value) {
            return;
          }

          if (newValue === null && !allowsCustomValue) {
            onInputChange('');
          }

          const item = items?.find(item => item.key === newValue);
          const inputValue =
            typeof item?.children !== 'object'
              ? item?.children
              : item?.textValue;

          if (item) {
            onInputChange(inputValue);
          }

          onChange(newValue);
        }}
        placeholder={readOnly ? '–' : placeholder}
        selectedKey={value ?? null}
        message={fieldMessage}
        variant={fieldVariant}
        clearable={clearable}
        clearLabel={t('common.clear')}
        onFocus={handleFocus}
        showMessageOnFocus={showMessageOnFocus}
        onBlur={onBlurProp}
        {...mergeProps(inputProps, restProps, labelFieldProps)}>
        {children}
      </ComboBox>
    </FieldContainer>
  );
};
