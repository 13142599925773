import { useCostCenterFieldItems } from 'components/Form/hooks/useCostCenterFieldItems';
import { ProcessingFormFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import {
  CostCenterTypes,
  useGetCostCenterLazyQuery,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';

export interface UseCostCenterOptionsOptions {
  skip?: boolean;
  type: CostCenterTypes;
  ids?: string[];
}

type UseCostCenterOptionsReturn = ProcessingFormFieldOptions[
  | 'costCenter'
  | 'costObject'
  | 'extraCostInfo'];

/**
 * Returns options for cost-center fields:
 * - cost center
 * - cost object
 * - extra cost info
 */
export const useCostCenterFieldOptions = ({
  skip,
  type,
  ids,
}: UseCostCenterOptionsOptions): UseCostCenterOptionsReturn => {
  const [getCostCenterQuery] = useGetCostCenterLazyQuery();
  const getItem = useCallback(
    async (id: string) => {
      const { data } = await getCostCenterQuery({
        variables: { input: { id } },
      });

      return data?.costCenter
        ? toAccountingDataFieldItem(data.costCenter)
        : undefined;
    },
    [getCostCenterQuery]
  );

  const { fetchMoreItems, items, refetchItems } = useCostCenterFieldItems({
    skip,
    type,
    ids,
  });

  if (skip) {
    if (ids) {
      return {
        hidden: true,
        props: {
          getItem,
          items,
          onEndReached: fetchMoreItems,
          onSearch: async (value: string) => {
            // Workaround for issue with querying by readableName (see TG-2082)
            const [code, name] = value.split(' - ');
            await refetchItems({ readableName: code || name, type });
          },
        },
      };
    }

    return { hidden: true };
  }

  return {
    hidden: false,
    props: {
      getItem,
      items,
      onEndReached: fetchMoreItems,
      onSearch: async (value: string) => {
        // Workaround for issue with querying by readableName (see TG-2082)
        const [code, name] = value.split(' - ');
        await refetchItems({ readableName: code || name, type });
      },
    },
  };
};
