import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  Image,
  Modal,
  ModalProps,
  Text,
} from '@candisio/design-system';
import BookLogo from 'assets/bds/book.svg';
import CalendarLogo from 'assets/bds/calendar.svg';
import PlayIcon from 'assets/bds/play.svg';
import PostingTextLogo from 'assets/bds/posting-text.svg';
import TodoLogo from 'assets/bds/todo.svg';
import BDSPromotionBackgroundDE from 'assets/bds-promotion-bg-de.svg';
import BDSPromotionBackgroundEN from 'assets/bds-promotion-bg-en.svg';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import { motion } from 'framer-motion';
import { Locale } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalSource } from 'views/Integrations/Export/types';
import { PromotionBlog } from './components/PromotionBlog';
import { Testimonials } from './components/Testimonials';

export const wistiaEmbedClass = 'wistia_embed';
const DetailsCard = ({ logo, text }: { logo: string; text: string }) => {
  return (
    <Card background="yellowbg" padding="space8" textAlign="center">
      <Grid justifyItems="center" gap="space8">
        <Image height={40} src={logo} alt="" />
        <Text fontSize="basic" maxWidth={255} lineHeight="140%">
          <Trans>{text}</Trans>
        </Text>
      </Grid>
    </Card>
  );
};

const DetailsContainer = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUser();
  const background = {
    [Locale.De]: BDSPromotionBackgroundDE,
    [Locale.En]: BDSPromotionBackgroundEN,
  };

  return (
    <Grid height="100%" overflow="hidden" borderRadius={8}>
      <Box padding="space32" position="absolute" left={0}>
        <Image src={background[currentUser?.locale || Locale.De]} alt="" />
      </Box>
      <Box background="rgba(127, 85, 204, 0.6)">
        <Grid
          background="linear-gradient(180deg, rgba(45, 28, 47, 0.20) 0%, #2D1C2F 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), rgba(128, 128, 128, 0.25);"
          templateColumns="1fr 1fr"
          padding="space24"
          columnGap="space24"
          height="100%">
          {children}
        </Grid>
      </Box>
    </Grid>
  );
};

const PromotionDetails = ({
  modalSource,
  onClose,
  setShowVideo,
}: {
  modalSource: ModalSource;
  onClose?: () => void;
  setShowVideo: (value: boolean) => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const user = useCurrentUser();
  const organization = useFullOrganization();
  const MotionImage = motion(Image);

  const bookingHref = t('bdsPromotionModal.demoLink', {
    email: user?.email,
    name: user?.firstName,
    company_name: organization?.name,
    company_id: organization?.id,
    utm_source: modalSource,
  });

  // keep it for later when introducing accurals
  const showAccurals = false;

  return (
    <DetailsContainer>
      <Grid alignContent="center" justifyContent="center">
        <Box
          data-key="promotion-video-play-button"
          onClick={() => {
            setShowVideo(true);
          }}
          as="a">
          <MotionImage
            whileHover={{
              filter: 'brightness(75%)',
            }}
            src={PlayIcon}
            alt=""
          />
        </Box>
        <Flex
          position="absolute"
          bottom={0}
          padding="space8"
          direction="column">
          <Text
            fontSize={50}
            color="gray0"
            fontFamily="heading"
            lineHeight="space48">
            {t('bdsPromotionModal.leftSide.title')}
          </Text>
          <Text fontSize={30} color="gray0" fontFamily="heading">
            {t('bdsPromotionModal.leftSide.videoLength')}
          </Text>
        </Flex>
      </Grid>
      <Card background="gray0" padding={0} overflow="hidden">
        <Button
          size="small"
          icon="close"
          variant="tertiary"
          position="absolute"
          right="space12"
          top="space8"
          label={t('bdsPromotionModal.rightSide.closeButton')}
          onClick={onClose}
          zIndex={1}
        />
        <Grid templateRows="auto 1fr" height="100%">
          <Grid padding="space24" rowGap="space20">
            <Grid>
              <Text fontSize="xxxlarge" fontWeight="semibold">
                {t('bdsPromotionModal.rightSide.title')}
              </Text>
              <Text fontSize="basic" color="gray600">
                {t('bdsPromotionModal.rightSide.description')}
              </Text>
            </Grid>
            <Grid rowGap="space12">
              <Text fontSize="large" fontWeight="semibold">
                {t('bdsPromotionModal.rightSide.subTitle')}
              </Text>
              <Grid templateRows="1fr 1fr" gap="space10">
                <Grid templateColumns="1fr 1fr" gap="space10">
                  <DetailsCard
                    text={t('bdsPromotionModal.rightSide.cards.accruals')}
                    logo={TodoLogo}
                  />
                  <DetailsCard
                    text={t('bdsPromotionModal.rightSide.cards.booking')}
                    logo={PostingTextLogo}
                  />
                </Grid>
                <Grid>
                  <DetailsCard
                    text={t('bdsPromotionModal.rightSide.cards.payment')}
                    logo={BookLogo}
                  />
                  {showAccurals && (
                    <DetailsCard
                      text={t('bdsPromotionModal.rightSide.cards.calendar')}
                      logo={CalendarLogo}
                    />
                  )}
                </Grid>
              </Grid>
              <Flex justifyContent="center">
                <PromotionLink href={bookingHref}>
                  {t('bdsPromotionModal.rightSide.button')}
                </PromotionLink>
              </Flex>
            </Grid>
          </Grid>
          <Flex background="gray200" height={170} alignItems="center">
            <Testimonials />
          </Flex>
        </Grid>
      </Card>
    </DetailsContainer>
  );
};

const PromotionVideo = ({
  setShowVideo,
}: {
  setShowVideo: (value: boolean) => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const MotionGrid = motion(Grid);

  return (
    <Grid
      paddingX="space40"
      paddingTop="space12"
      paddingBottom={0}
      rowGap="space4"
      alignItems="start"
      templateRows="auto 1fr">
      <Flex
        onClick={() => setShowVideo(false)}
        gap="space8"
        alignItems="center"
        as="a">
        <Icon icon="arrowLeft" color="gray0" />
        <Text fontSize="basic" color="gray0">
          {t('bdsPromotionModal.video.backButton')}
        </Text>
      </Flex>
      <MotionGrid
        className={`${wistiaEmbedClass} wistia_async_${t(
          'bdsPromotionModal.video.wistiaId'
        )} seo=true videoFoam=true autoPlay=true`}
        height="100%"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.25 }}
      />
    </Grid>
  );
};

interface BDSPromotionModalProps extends ModalProps {
  modalSource: ModalSource;
}
export const BDSPromotionModal = ({
  modalSource,
  ...modalProps
}: BDSPromotionModalProps) => {
  const [showVideo, setShowVideo] = useState(false);
  const { onClose } = modalProps;

  return (
    <Modal
      width="100%"
      overlayPadding={0}
      padding={0}
      background="none"
      {...modalProps}>
      <Grid placeItems="center">
        <Box
          width="100%"
          height="100%"
          position="absolute"
          onClick={onClose}></Box>
        <Grid rowGap="space32" paddingTop="space40">
          <Card
            padding={0}
            background={showVideo ? 'gray800' : 'gray200'}
            minHeight={730}
            width="1248px">
            {showVideo ? (
              <PromotionVideo setShowVideo={setShowVideo} />
            ) : (
              <PromotionDetails
                modalSource={modalSource}
                onClose={onClose}
                setShowVideo={setShowVideo}
              />
            )}
          </Card>
          <Grid rowGap="space12" paddingBottom="space40" width="1248px">
            <Text fontSize="xlarge" fontWeight="semibold" color="gray0">
              Blogbeiträge über die Erweiterung
            </Text>
            <Grid templateColumns="1fr 1fr 1fr" gap="space24">
              <PromotionBlog translationKey="bestInterface" />
              <PromotionBlog translationKey="optimizeMaintenance" />
              <PromotionBlog translationKey="automatedAccurals" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
