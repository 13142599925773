import { TaxCodeForm } from 'components/Form/TaxCodeForm/TaxCodeForm';
import { TaxCodeFormSkeleton } from 'components/Form/TaxCodeForm/TaxCodeFormSkeleton';
import { useGetExistingTaxCodes } from 'components/Form/TaxCodeForm/toolkit/hooks/useGetExistingTaxCodes';
import { BookingKeyDataFragment } from 'generated-types/graphql.types';
import { useTaxCodes } from 'views/Settings/TaxCodes/useTaxCodes';
import { BookingKeyFormData } from './types';

export type BookingKeyChangeProps = {
  bookingKey?: BookingKeyDataFragment;
  onChange: <T extends BookingKeyFormData>(formData: T) => Promise<void>;
  onStatusChange: (isArchived: boolean) => void;
  updateStatusLoading: boolean;
  loading: boolean;
  hasActiveBookingAccounts: boolean;
  goToBookingAccounts: () => void;
};

export const TaxCodeChange = ({
  onChange,
  onStatusChange,
  updateStatusLoading,
  hasActiveBookingAccounts,
  goToBookingAccounts,
}: BookingKeyChangeProps) => {
  const { bookingKeyLoading, bookingKey } = useTaxCodes();

  const { taxCode } = bookingKey || {};

  const { existingTaxCodes, isExistingTaxCodesLoading } =
    useGetExistingTaxCodes(taxCode);

  if (bookingKeyLoading || isExistingTaxCodesLoading) {
    return <TaxCodeFormSkeleton />;
  }

  return (
    <TaxCodeForm
      onStatusChange={onStatusChange}
      updateStatusLoading={updateStatusLoading}
      hasActiveBookingAccounts={hasActiveBookingAccounts}
      goToBookingAccounts={goToBookingAccounts}
      onSubmit={onChange}
      bookingKey={bookingKey}
      existingTaxCodes={existingTaxCodes}
    />
  );
};
