import { Box, useId } from '@candisio/design-system';
import { AnimatePresence, motion, Variant } from 'framer-motion';
import { noop } from 'lodash';
import { createContext, ReactNode, useContext, useState } from 'react';
// @TODO never import directly from 'react-aria'
import { FocusScope } from 'react-aria';
import { HotKeys } from 'react-hotkeys';

const MotionBox = motion(Box);

interface ProcessingFormOverlayContextValue {
  animating?: boolean;
  headingId?: string;
  onClose?: () => void;
}

const ProcessingFormOverlayContext =
  createContext<ProcessingFormOverlayContextValue>({});

export const useProcessingFormOverlayContext = () =>
  useContext(ProcessingFormOverlayContext);

export interface ProcessingFormOverlayProps {
  children?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  autoFocus?: boolean;
}

/**
 * Animated box to overlay content in processing form
 * @todo Replace with design system Drawer
 */
export const ProcessingFormOverlay = ({
  children,
  isOpen = false,
  onClose = noop,
  autoFocus = true,
}: ProcessingFormOverlayProps) => {
  const [animating, setAnimating] = useState(true);
  const headingId = useId();

  return (
    <AnimatePresence>
      {isOpen && (
        <HotKeys keyMap={{ CLOSE: 'Escape' }} handlers={{ CLOSE: onClose }}>
          <MotionBox
            // Box props
            position="absolute"
            inset={0}
            left="space2"
            zIndex={10}
            background="gray0"
            boxShadow="elevatedShadow1"
            role="dialog"
            aria-labelledby={headingId}
            // Motion props
            animate="open"
            exit="closed"
            initial="closed"
            onAnimationComplete={(variant: Variant) => {
              setAnimating(variant !== ('open' as Variant));
            }}
            transition={{ bounce: 0 }}
            variants={{
              open: { x: 0, opacity: 1 },
              closed: { x: '100%', opacity: 0 },
            }}>
            <ProcessingFormOverlayContext.Provider
              value={{ animating, headingId, onClose }}>
              <FocusScope autoFocus={autoFocus}>{children}</FocusScope>
            </ProcessingFormOverlayContext.Provider>
          </MotionBox>
        </HotKeys>
      )}
    </AnimatePresence>
  );
};
