import { useBookingAccountQuery } from 'generated-types/graphql.types';

interface UseGetGeneralLedgerAccountByIdParams {
  id: string | undefined;
}

export const useGetBookingAccountById = ({
  id,
}: UseGetGeneralLedgerAccountByIdParams) => {
  const { data, loading } = useBookingAccountQuery({
    variables: { id: id as string },
    skip: !id,
  });

  const bookingAccount = {
    readableName:
      data?.bookingAccount.__typename === 'BookingAccount'
        ? data.bookingAccount.readableName
        : undefined,
  };

  return { bookingAccount, bookingAccountLoading: loading };
};
