import { Box, CustomEmptyStateProps } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { TransactionsTableEmptyState } from 'components/Transactions/Table/components/TransactionsTableStates/components/TransactionsTableEmptyState';
import { TransactionsTable } from 'components/Transactions/Table/TransactionsTable';
import {
  OnRowClick,
  TransactionsTableData,
  ViewUsingTransactions,
} from 'components/Transactions/Table/types';
import { useCardIssuerTransactionsData } from 'components/Transactions/Table/useCardIssuerTransactionsData';
import {
  availableFilters,
  transactionToTableData,
} from 'components/Transactions/Table/util';
import { CreditCardBannerContainer } from 'containers/credit-cards/components/CreditCardBannerContainer/CreditCardBannerContainer';
import { creditCardInfoHash } from 'containers/credit-cards/components/CreditCardsSection/utils';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TransactionsTrackingEvents } from 'providers/AnalyticsProvider/events/TransactionsTrackingEvents';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Use v5 version of useParams because we’re inside a v5 route
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { appendParamsToQueryString } from 'utils/url-helper';
import { CreditCardInfoModal } from 'views/Dashboard/components/CreditCardInfoModal';
import { TabView } from 'views/Inbox/models';
import {
  TRANSACTION_FILTER,
  useTransactionListFilters,
} from 'views/Inbox/Transactions/useTransactionListFilters';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { useDownloadCSVFile } from './hooks/useDownloadCSVFile';
import { useGetArchiveTransactionsTableConfigs } from './hooks/useGetArchiveTransactionsTableConfigs';

export const Transactions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const history = useHistory();
  const navigate = useNavigate();
  const openModal = location.hash === creditCardInfoHash;
  const { updateSearchParam, searchParams } = useMutateSearchParams();

  const org = useFullOrganization();

  const { track } = useAnalytics();

  const organizationSlug = org?.slug;

  const { filterOptions, isFilterLoading } = useTransactionListFilters({
    filters: [
      TRANSACTION_FILTER.status_archive,
      TRANSACTION_FILTER.invoiceAssociationStatus_archive,
      TRANSACTION_FILTER.cardholderName,
      TRANSACTION_FILTER.type,
      TRANSACTION_FILTER.cardRefNum,
      TRANSACTION_FILTER.category,
    ],
    fetchCardholdersWithTransactions: true,
  });

  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<TransactionsTableData>({
      availableFilters,
    });

  const {
    availableTransactionColumnIds,
    configurationsTable,
    isResetPending,
    isSavingConfigurations,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetArchiveTransactionsTableConfigs({ filters, sortBy });

  const { downloadCSV, isCreateCSVFilePending } = useDownloadCSVFile();

  const {
    transactionList,
    isLoadingTransactionList,
    onLoadMore,
    isTableEmpty,
    isTableFiltered,
    handleDebounceSearch,
    transactionListCount,
  } = useCardIssuerTransactionsData({
    routeType: ViewUsingTransactions.ARCHIVE,
    filters,
    sortBy,
  });

  const handleDownloadCSV = useCallback(async () => {
    if (isLoadingTransactionList) {
      error(t('table.toolbar.downloadMessage.waitForLoading'));
    }

    const response = await downloadCSV();

    if (response.status === 'success') {
      success(t('table.toolbar.downloadMessage.success'));
    } else {
      error(t('table.toolbar.downloadMessage.error'));
    }
  }, [downloadCSV, error, isLoadingTransactionList, success, t]);

  const handleClick = ({ id, cursor }: OnRowClick) => {
    const searchWithCursor = appendParamsToQueryString(
      history.location.search,
      {
        cursor: cursor ?? '',
      }
    );

    history.push(
      `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}/${id}?${searchWithCursor}`
    );
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <TransactionsTableEmptyState
        isTableFiltered={isTableFiltered}
        isTableEmpty={isTableEmpty}
        resetFilters={resetFilters}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<TransactionsTableData>) => {
      onFilter(filterVal);

      const columns = filterVal?.map(({ id }) => id).join(', ');
      const values = filterVal?.map(({ value }) => value);

      track(TransactionsTrackingEvents.TRANSACTIONS_TABLE_FILTERS_APPLIED, {
        orgId: org?.realmName,
        source: 'Archive tx view',
        columns,
        values,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<TransactionsTableData>[]) => {
      onSort(sortVal);

      const columns = sortVal?.map(({ id }) => id).join(', ');
      const isSortedByDescOrder = sortVal?.map(({ desc }) => desc);

      track(TransactionsTrackingEvents.TRANSACTIONS_TABLE_SORTING_APPLIED, {
        orgId: org?.realmName,
        source: 'Archive tx view',
        columns,
        isSortedByDescOrder,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSort]);

  const handleSearch = (search: string) => {
    handleDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_TRANSACTIONS}>
      <CreditCardBannerContainer routeType="Archive">
        <Box height="100%" overflow="hidden">
          <TransactionsTable
            key={`tx-table-${isFilterLoading}-${availableTransactionColumnIds.join(
              '-'
            )}`}
            onEndReached={onLoadMore}
            columns={availableTransactionColumnIds}
            onRowClick={handleClick}
            data={transactionToTableData(transactionList ?? [])}
            isLoading={isLoadingTransactionList}
            filterOptions={filterOptions}
            onSort={handleSort}
            onSearch={handleSearch}
            search={queryStringFilter}
            onFilter={handleFilter}
            defaultFilters={filters}
            customEmptyState={emptyState}
            defaultSortBy={sortBy}
            isSavingConfigurations={isSavingConfigurations || isResetPending}
            configurations={configurationsTable}
            onUpdateConfigurations={handleUpdateConfigurations}
            onResetTableConfigurations={handleResetTableConfigurations}
            onDownloadCSV={handleDownloadCSV}
            isDownloadingCSV={isCreateCSVFilePending}
            downloadableTransactionsCount={transactionListCount}
            borderTopRadius="none"
          />
        </Box>
      </CreditCardBannerContainer>
      <CreditCardInfoModal
        isOpen={openModal}
        onClose={() => {
          navigate(
            {
              pathname: location.pathname,
              search: location.search,
            },
            { replace: true }
          );
        }}
        routeType="Archive"
      />
    </ArchiveViewLayout>
  );
};
