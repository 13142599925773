import {
  Box,
  Button,
  ButtonProps,
  Grid,
  Popover,
  PopoverProps,
} from '@candisio/design-system';
import { ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmationPopoverProps extends PopoverProps {
  /** Additional text to display in the popover */
  children?: ReactNode;
  /** Custom props for close button */
  closeButtonProps?: ButtonProps;
  /** Close button text */
  closeText?: string;
  /** Custom props for confirm button */
  confirmButtonProps?: ButtonProps;
  /** Confirm button text */
  confirmText?: string;
  /** Called when action is confirmed */
  onConfirm?: () => void;
  /** Title text */
  title?: string;
}

/**
 * A Popover component for confirming an action
 *
 * Associate the popover to a trigger using the `usePopover` hook, as you would
 * with the regular design system Popover.
 */
export const ConfirmationPopover = forwardRef<
  HTMLDivElement,
  ConfirmationPopoverProps
>(
  (
    {
      children,
      closeButtonProps,
      closeText,
      confirmButtonProps,
      confirmText,
      onConfirm,
      title,
      onClose,
      ...restProps
    },
    ref
  ) => {
    const [t] = useTranslation();

    return (
      <Popover
        maxWidth="320px"
        onClose={onClose}
        padding="space18"
        {...restProps}
        ref={ref}>
        <Grid gap="space20">
          {title ?? t('areYouSure')}

          {children && <Box>{children}</Box>}

          <Grid
            autoFlow="column"
            gap="space8"
            data-cy="confirmation-popover-buttons">
            <Button onClick={onClose} variant="secondary" {...closeButtonProps}>
              {closeText ?? t('no')}
            </Button>
            <Button
              onClick={() => {
                onClose?.();
                onConfirm?.();
              }}
              color="red"
              {...confirmButtonProps}>
              {confirmText ?? t('yes')}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    );
  }
);
