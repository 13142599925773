import { Drawer } from '@candisio/design-system';
import { DrawerLayoutProps } from 'components/DrawerLayout/DrawerLayout';
import {
  UseUpdateContractCustomSubCategoryOptions,
  useUpdateContractCustomSubCategory,
} from '../../toolkit/useUpdateContractCustomSubCategory';
import { ContractSubCategoryDetailsFormValues } from '../Form/contractSubCategoryDetailsFormSchema';
import { ContractCustomSubCategoryDetails } from './ContractCustomSubCategoryDetails';

export interface ContractCustomSubCategoryDetailsDrawerProps
  extends Pick<DrawerLayoutProps, 'compact'> {
  isOpen: boolean;
  onCloseDrawer: () => void;
  contractSubCategoryId?: string;
  defaultName?: string;
  onSubtypeCreated?: UseUpdateContractCustomSubCategoryOptions['onSubCategoryCreated'];
}

export const ContractCustomSubCategoryDetailsDrawer = ({
  isOpen,
  onCloseDrawer,
  compact,
  contractSubCategoryId,
  defaultName,
  onSubtypeCreated,
}: ContractCustomSubCategoryDetailsDrawerProps) => {
  const {
    contractSubCategory,
    loadingContractSubCategory,
    creatingContractSubCategory,
    updatingContractSubCategory,
    updatingContractSubCategoryStatus,
    archiveContractSubCategory,
    activateContractSubCategory,
    handleSubmit,
  } = useUpdateContractCustomSubCategory({
    onCloseDrawer,
    contractSubCategoryId,
    onSubCategoryCreated: onSubtypeCreated,
  });

  const defaultValues: ContractSubCategoryDetailsFormValues =
    contractSubCategory ?? {
      name: defaultName ?? '',
    };

  return (
    <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
      {!loadingContractSubCategory && (
        <ContractCustomSubCategoryDetails
          onCloseDrawer={onCloseDrawer}
          compact={compact}
          contractSubCategoryId={contractSubCategoryId}
          onArchive={archiveContractSubCategory}
          onActivate={activateContractSubCategory}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          isSubmitPending={
            creatingContractSubCategory || updatingContractSubCategory
          }
          updatingStatus={updatingContractSubCategoryStatus}
          isActive={contractSubCategory?.isActive ?? true}
        />
      )}
    </Drawer>
  );
};
