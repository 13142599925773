import { useBookingAccountReadableNameFilter } from 'components/DocumentsTable/hooks/useBookingAccountReadableNameFilter';
import { useContactNameFilter } from 'components/DocumentsTable/hooks/useContactNameFilter';
import { useCostCenterReadableNameFilter } from 'components/DocumentsTable/hooks/useCostCenterReadableNameFilter';
import { useCostObjectReadableNameFilter } from 'components/DocumentsTable/hooks/useCostObjectReadableNameFilter';
import { useExtraCostInfoReadableNameFilter } from 'components/DocumentsTable/hooks/useExtraCostInfoReadableNameFilter';
import { useTagsFilter } from 'components/DocumentsTable/hooks/useTagsFilter';
import { useEcmPersonFilter } from 'components/EcmDocumentsTable/hooks/useResponsiblePersonFilter';
import { useAccountsPayableNumberFilter } from 'views/Contacts/ContactTable/ContactTableDS/hooks/useAccountsPayableNumberFilter';
import { useAccountsReceivableNumberFilter } from 'views/Contacts/ContactTable/ContactTableDS/hooks/useAccountsReceivableNumberFilter';
import { useIbanFilter } from 'views/Contacts/ContactTable/ContactTableDS/hooks/useIbanFilter';
import { usePaymentInfoFilter } from 'views/Contacts/ContactTable/ContactTableDS/hooks/usePaymentInfoFilter';
import { useTaxNumberFilter } from 'views/Contacts/ContactTable/ContactTableDS/hooks/useTaxNumberFilter';
import { useVatIdFilter } from 'views/Contacts/ContactTable/ContactTableDS/hooks/useVatIdFilter';
import { useCardNumberFilter } from 'views/CreditCards/hooks/useCardNumberFilter';
import { useCardRefNumberWithLabelFilter } from 'views/CreditCards/hooks/useCardRefNumberWithLabelFilter';
import { useMerchantnameFilter } from 'views/CreditCards/hooks/useMerchantNameFilter';
import { PaginationWithSearchFilterHook } from '../utils';
import { useApproverFilter } from './useApproverFilter';
import { useRequesterFilter } from './useRequesterFilter';

export type PaginationFiltersHooksType = {
  [columnName: string]: PaginationWithSearchFilterHook;
};

export const paginationFiltersHooks: PaginationFiltersHooksType = {
  accountsPayableNumber: useAccountsPayableNumberFilter,
  accountsReceivableNumber: useAccountsReceivableNumberFilter,
  approvers: useApproverFilter,
  cardNumber: useCardNumberFilter,
  cardRefNum: useCardRefNumberWithLabelFilter,
  contact: useContactNameFilter,
  costCenter: useCostCenterReadableNameFilter,
  costObject: useCostObjectReadableNameFilter,
  extraCostInfo: useExtraCostInfoReadableNameFilter,
  generalLedgerAccount: useBookingAccountReadableNameFilter,
  iban: useIbanFilter,
  merchantName: useMerchantnameFilter,
  paymentInfo: usePaymentInfoFilter,
  requester: useRequesterFilter,
  responsiblePerson: useEcmPersonFilter,
  notifyPerson: useEcmPersonFilter,
  tags: useTagsFilter,
  taxNumber: useTaxNumberFilter,
  vatId: useVatIdFilter,
};
