import { Button, Grid, Text, useLabel } from '@candisio/design-system';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import moment from 'moment-timezone';
import { useOrganizationTimezone } from 'providers/OrganizationProvider';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AbsenceFormValues } from './absenceFormSchema';

export const DatePickerFields = () => {
  const [t] = useTranslation();
  const timezone = useOrganizationTimezone();

  const toDateMin = moment.tz(timezone).format('YYYY-MM-DD');

  const { setValue } = useFormContext<AbsenceFormValues>();

  const fromDate = useWatch<AbsenceFormValues, 'fromDate'>({
    name: 'fromDate',
  });

  const toDate = useWatch<AbsenceFormValues, 'toDate'>({
    name: 'toDate',
  });

  const fromLabel = useLabel({
    label: t('header.profile.tabs.update.absence.form.from'),
  });

  const toLabel = useLabel({
    label: t('header.profile.tabs.update.absence.form.to'),
  });

  const showTrashButton = fromDate || toDate;

  const toDateMinValue = fromDate
    ? moment.tz(fromDate, timezone).format('YYYY-MM-DD')
    : toDateMin;

  const handleResetDates = () => {
    setValue('fromDate', null, {
      shouldDirty: true,
    });
    setValue('toDate', null, {
      shouldDirty: true,
    });
    setValue('substitute', null, {
      shouldDirty: true,
    });
  };

  return (
    <Grid autoFlow="column" alignItems="center" justifyContent="space-between">
      <Grid autoFlow="column" alignItems="center" gap="space12">
        <Text as="label" fontSize="basic" {...fromLabel.labelProps}>
          {t('header.profile.tabs.update.absence.form.from')}
        </Text>
        <HookFormDatePickerField name="fromDate" {...fromLabel.fieldProps} />
        <Text as="label" fontSize="basic" {...toLabel.labelProps}>
          {t('header.profile.tabs.update.absence.form.to')}
        </Text>
        <HookFormDatePickerField
          name="toDate"
          minValue={toDateMinValue}
          {...toLabel.fieldProps}
        />
      </Grid>
      {showTrashButton && (
        <Button
          icon="trash"
          variant="tertiary"
          color="red"
          onClick={handleResetDates}
          label={t('header.profile.tabs.update.absence.form.removeDates')}
        />
      )}
    </Grid>
  );
};
