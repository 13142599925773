import { Card, Grid, Icon, Paragraph } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CardLimitRequestInfoBox = ({
  hasPendingLimitRequest,
}: {
  hasPendingLimitRequest: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background={hasPendingLimitRequest ? 'yellowbg' : 'bluebg'}>
      <Grid templateColumns="auto 1fr" gap="space8">
        <Icon
          color={hasPendingLimitRequest ? 'yellow700' : 'blue700'}
          icon="infoCircle"
          size="space18"
        />
        <Paragraph fontSize="basic">
          {t(
            hasPendingLimitRequest
              ? 'creditCardFormDataPreview.pendingLimitRequest'
              : 'creditCardFormDataPreview.cardLimitRequestInfoBox'
          )}
        </Paragraph>
      </Grid>
    </Card>
  );
};
