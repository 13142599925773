import {
  Box,
  Grid,
  Item,
  ListView,
  Text,
  useTheme,
} from '@candisio/design-system';
import { CardIssuerSettlementStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useGetSettlementsPageBasedData } from 'views/CreditCards/CreditCardsStatements/hooks/useGetSettlementsPageBasedData';
import { SettlementEmptyState } from '../SettlementCard/SettlementEmptyState';
import { SettlementLoadingState } from '../SettlementCard/SettlementLoadingState';
import { SettlementCard } from '../SettlementCard/SettlementmentCard';

export const PendingSettlements = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { colors, radii } = useTheme();

  const { hasMore, loading, onLoadMore, settlementList } =
    useGetSettlementsPageBasedData({
      status: CardIssuerSettlementStatus.Pending,
    });

  const { settlements, totalCount } = settlementList;

  if (loading && totalCount === 0) {
    return (
      <SettlementLoadingState
        background="gray250"
        title={t('statements.settlementWidget.pendingSetTitle')}
      />
    );
  }

  if (totalCount === 0) {
    return (
      <SettlementEmptyState
        background="gray250"
        title={t('statements.settlementWidget.pendingSetTitle')}
      />
    );
  }

  const indexOfLastSettlement = settlements.length - 1;

  return (
    <Grid background="gray250" borderRadius="medium" templateRows="auto 1fr">
      <Box padding="space16" paddingBottom="space8">
        <Text fontSize="large" fontWeight="semibold">
          {t('statements.settlementWidget.pendingSetTitle')}
        </Text>
      </Box>
      <ListView
        style={{
          borderRadius: radii.medium,
          background: colors.gray250,
        }}
        height="100%"
        onEndReached={hasMore ? onLoadMore : undefined}
        isVirtualized>
        {settlements.map((s, i) => (
          <Item textValue={s.id} key={s.id}>
            <SettlementCard
              {...s}
              background="gray250"
              isLastItem={indexOfLastSettlement === i}
            />
          </Item>
        ))}
      </ListView>
    </Grid>
  );
};
