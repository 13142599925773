import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

export const InvoiceNumber = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="invoiceNumber"
      label={t('document.requestApproval.inputs.invoiceNumber.label')}
      readOnly
    />
  );
};
