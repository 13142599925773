import {
  ExportableEntityType,
  ExportDocument as GqlExportDocument,
} from 'generated-types/graphql.types';
import { DeepPartial } from 'utility-types';
import { ExportEntity } from '../../types';
import { gqlDocumentToExportEntity } from './toExportEntity';

export const toDocumentExportEntity =
  ({
    clientNumber,
    consultantNumber,
  }: {
    clientNumber?: string | null;
    consultantNumber?: string | null;
  }) =>
  (gqlExportDocument: DeepPartial<GqlExportDocument>): ExportEntity => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...gqlDocumentToExportEntity(gqlExportDocument.document!),
      ...(consultantNumber ? { consultantNumber } : null),
      ...(clientNumber ? { clientNumber } : null),
      exportStatus: {
        status: gqlExportDocument.docStatus?.type,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        details: (gqlExportDocument.docStatus?.details || []).map(s => s!),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        warnings: (gqlExportDocument.docStatus?.warnings || []).map(s => s!),
        entityType: ExportableEntityType.Document,
        isDocumentDirectionOthers:
          gqlExportDocument.document?.category?.direction?.value !==
            'invoices_received' &&
          gqlExportDocument.document?.category?.direction?.value !==
            'outgoing_invoices',
        exportMethod: gqlExportDocument.docStatus?.exportMethod,
        ...(gqlExportDocument.docStatus?.exportError
          ? {
              exportError: gqlExportDocument.docStatus.exportError,
            }
          : null),
      },
    };
  };
