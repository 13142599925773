import { MenuButton } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const LANGUAGE = {
  EN: 'en',
  DE: 'de',
} as const;

export const LanguageMenuButton = () => {
  const { t } = useTranslation();
  const { success } = useToastMessage();

  const isEnglish = i18next.language === LANGUAGE.EN;

  const switchLanguage = () => {
    const newLanguage = isEnglish ? LANGUAGE.DE : LANGUAGE.EN;
    void i18next.changeLanguage(newLanguage);
    success(t('common:locales.successMessage'));
  };

  const items = [
    {
      id: LANGUAGE.EN,
      label: t('common:locales.EN'),
      onAction: switchLanguage,
    },
    {
      id: LANGUAGE.DE,
      label: t('common:locales.DE'),
      onAction: switchLanguage,
    },
  ];

  return (
    <MenuButton items={items}>
      {isEnglish ? t('common:locales.EN') : t('common:locales.DE')}
    </MenuButton>
  );
};
