import { Flex, Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useWidgetCards } from './hooks/useWidgetCards';
import { InitialCard } from './InitialCard/InitialCard';

export const NewInitialState = ({
  onAddWidget,
}: {
  onAddWidget: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const { initialWidgetCards } = useWidgetCards();

  return (
    <Flex direction="column" justifyContent="center" gap="space12" width="100%">
      <Text as="h2" textAlign="left" fontSize="xlarge" fontWeight="regular">
        {t('insights.heading')}
      </Text>
      <Grid
        columns={3}
        width="100%"
        gap="space20"
        justifyContent="center"
        alignItems="center">
        {initialWidgetCards.map((initialCard, index) => (
          <InitialCard
            key={index}
            onAddWidget={onAddWidget}
            title={initialCard.title}
            imageUrl={initialCard.imageUrl}
            tag={initialCard.tag}
            linkText={initialCard.linkText}
            openModal={initialCard.openModal}
          />
        ))}
      </Grid>
    </Flex>
  );
};
