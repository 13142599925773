import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormSelectFieldProps } from 'components/HookFormFields/HookFormSelectField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ALPHA2_EUROPEAN_COUNTRIES } from '../utils/consts';
import { OrganizationSettingsFormOutupt } from '../utils/organizationSettingsFormSchema';

export const DeliveryAddressComboBoxField = <TFormValues extends FieldValues>(
  props: Omit<HookFormSelectFieldProps<TFormValues>, 'items'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { watch } = useFormContext<OrganizationSettingsFormOutupt>();

  const isCheckboxForSameAddressChecked = watch('deliveryAddressSameAsBilling');
  const isReadOnly = isCheckboxForSameAddressChecked;

  const europeanCountries = Object.entries(ALPHA2_EUROPEAN_COUNTRIES).map(
    ([isoCode, countryName]) => ({
      key: isoCode,
      children: t(countryName),
    })
  );

  return (
    <HookFormComboBoxField
      {...props}
      label={props.label}
      defaultItems={europeanCountries}
      isVirtualized
      readOnly={isReadOnly}
    />
  );
};
