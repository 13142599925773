import {
  useAccountsPayableNumberSettingsQuery,
  useSetAccountsPayableNumberSettingsMutation,
} from 'generated-types/graphql.types';
import { useState } from 'react';
import { accountsPayableNumberSettingsQuery } from './queries';

export const useApnSuggestionSettings = () => {
  const { data, loading: loadingInitialValues } =
    useAccountsPayableNumberSettingsQuery();

  const enabled = data?.accountsPayableNumberSettings.enabled ?? false;
  const startValue = data?.accountsPayableNumberSettings.startNumber;
  const [setAccountsPayableNumberSettings, { loading: submitting }] =
    useSetAccountsPayableNumberSettingsMutation({
      update: (cache, { data }) => {
        if (
          data?.accountsPayableNumberSettings.__typename ===
          'AccountsPayableNumberSettings'
        ) {
          cache.writeQuery({
            query: accountsPayableNumberSettingsQuery,
            data: {
              accountsPayableNumberSettings: data.accountsPayableNumberSettings,
            },
          });
        }
      },
    });

  const [readOnly, setReadOnly] = useState(true);

  const submitStartValue = async (startValue: string) => {
    const result = await setAccountsPayableNumberSettings({
      variables: {
        settings: {
          startNumber: Number(startValue.replaceAll(' ', '')),
        },
      },
      onCompleted: data => {
        if (
          data?.accountsPayableNumberSettings.__typename ===
          'AccountsPayableNumberSettings'
        ) {
          setReadOnly(true);
        }
      },
    });

    return result;
  };

  const submitEnabled = async (enabled: boolean) => {
    await setAccountsPayableNumberSettings({
      variables: {
        settings: {
          enabled,
        },
      },
    });
  };

  return {
    submitting,
    loadingInitialValues,
    readOnly,
    enabled,
    setReadOnly,
    setStartValue: submitStartValue,
    setEnabled: submitEnabled,
    startValue,
  };
};
