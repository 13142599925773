import { useCancelCardRequestMutation } from 'generated-types/graphql.types';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';

export interface UseCancelRequestParams {
  cardId: string;
}

export const useCancelRequest = ({ cardId }: UseCancelRequestParams) => {
  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  const [cancelRequest, { loading, client }] = useCancelCardRequestMutation();

  return {
    cancel: async (pendingRequestId: string) => {
      const { errors } = await cancelRequest({
        variables: { input: { cardRequestId: pendingRequestId } },
        awaitRefetchQueries: true,
        refetchQueries: [
          ...refetchQueries.cardIssuerCards(cardId),
          ...refetchQueries.cardIssuerCardsCardManager(),
        ],
        onCompleted() {
          evictPaginationResults();
          client.cache.evict({ fieldName: 'getCardIssuerCardsForCardholder' });
        },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    canceling: loading,
  };
};
