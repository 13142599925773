import { ProcessingFormFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { EXTRACTED_CONTACT_ID } from 'components/Form/ProcessingForm/processingFormSchema';
import { useContactLazyQuery } from 'generated-types/graphql.types';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toContactFieldItem } from './toContactFieldItem';
import { useContactFieldItems } from './useContactFieldItems';

/** Returns options for contact field */
export const useContactFieldOptions = (
  defaultName?: string
): ProcessingFormFieldOptions['contact'] => {
  const [t] = useTranslation();

  const [contactQuery] = useContactLazyQuery();
  const getItem = useCallback(
    async (id: string) => {
      if (id === EXTRACTED_CONTACT_ID || id == null) {
        return;
      }

      const { data } = await contactQuery({ variables: { input: { id } } });

      return data?.contact ? toContactFieldItem(data.contact) : undefined;
    },
    [contactQuery]
  );

  const { fetchMoreItems, items, refetchItems } =
    useContactFieldItems(defaultName);

  const sap = useSap();
  const sapTranslations = sap.shouldUseSapContacts
    ? {
        addContactLabel: t('sap:contacts.processing.newContact.buttonCta'),
        newContactDescription: t('sap:contacts.processing.newContact.content'),
        newContactTitle: t('sap:contacts.processing.newContact.title'),
        editLabel: t('common.view'),
      }
    : undefined;

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const coreDataApiTranslations = shouldUseCoreDataApi
    ? {
        addContactLabel: t('core-data-api:contacts.processing.newContact.add'),
        newContactDescription: t(
          'core-data-api:contacts.processing.newContact.description'
        ),
        newContactTitle: t(
          'core-data-api:contacts.processing.newContact.title'
        ),
        editLabel: t('common.view'),
      }
    : undefined;

  const onSearch = useCallback(
    async (value: string) => {
      await refetchItems({ name: value });
    },
    [refetchItems]
  );

  return {
    getItem,
    items,
    onEndReached: fetchMoreItems,
    onSearch,
    persistFilter: true,
    ...sapTranslations,
    ...coreDataApiTranslations,
  };
};
