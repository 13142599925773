import {
  DocumentWorkflowStatusDataFragment,
  WorkflowStepResolutionTypes,
} from 'generated-types/graphql.types';

export const useUserInActiveFirstWFStep = () => {
  const isUserInActiveFirstWFStep = (
    document?: DocumentWorkflowStatusDataFragment | null,
    userId?: string
  ): boolean => {
    const firstWFStep = document?.workflow?.steps?.[0];

    const firstStepActive =
      firstWFStep?.resolution?.type === WorkflowStepResolutionTypes.Pending &&
      firstWFStep.id === document?.currentWorkflowStep?.id;

    const userIsFirstApprover = Boolean(
      firstWFStep?.approvers?.find(approver => {
        return approver.id === userId || approver.substitute?.id === userId;
      })
    );

    return Boolean(firstStepActive && userIsFirstApprover);
  };

  return { isUserInActiveFirstWFStep };
};
