import { Button, Grid, Link, Text, useId } from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OtpForm, OtpFormProps } from './OtpForm';
import { QrCode } from './QrCode';
import { SetupStep } from './SetupStep';
import { SuccessfulSetupMessage } from './SuccessfulSetupMessage';
import { TotpSecretText } from './TotpSecretText';

interface SetupAuthenticatorAppModalProps {
  deviceName: string;
  qrCodeImage: string;
  onSubmit: OtpFormProps['onSubmit'];
  showSuccessScreen: boolean;
  submitting: boolean;
  totpSecret: string;
}

export const SetupAuthenticatorAppModal = ({
  deviceName,
  onSubmit,
  qrCodeImage,
  showSuccessScreen,
  submitting,
  totpSecret,
}: SetupAuthenticatorAppModalProps) => {
  const [t] = useTranslation();
  const formId = useId();
  const [showQrCode, setShowQrCode] = useState(true);

  const handleToggleQrCode = () => setShowQrCode(show => !show);

  if (showSuccessScreen) {
    return <SuccessfulSetupMessage />;
  }

  return (
    <Grid
      templateRows="max-content 1fr repeat(2, max-content)"
      height="100%"
      rowGap="space32"
      fontSize="small">
      <SetupStep stepNumber="1">
        <Grid>
          <Trans
            parent="span"
            i18nKey="header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.downloadAppInstruction"
            components={{
              strong: <Text fontWeight="semibold" fontSize="small" />,
            }}
          />
          <Link
            external
            width="max-content"
            href={t(
              'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.supportedApps.url'
            )}>
            {t(
              'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.supportedApps.text'
            )}
          </Link>
        </Grid>
      </SetupStep>
      <SetupStep stepNumber="2">
        {showQrCode ? (
          <QrCode qrCodeImage={qrCodeImage} toggleShow={handleToggleQrCode} />
        ) : (
          <TotpSecretText
            totpSecret={totpSecret}
            toggleShow={handleToggleQrCode}
          />
        )}
      </SetupStep>
      <SetupStep stepNumber="3">
        <Text fontSize="small">
          {t(
            'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.enterTotpCodeInstruction'
          )}
        </Text>
        <OtpForm deviceName={deviceName} formId={formId} onSubmit={onSubmit} />
      </SetupStep>
      <Grid padding="0 0 space12 space20">
        <Button
          form={formId}
          size="medium"
          type="submit"
          width="max-content"
          loading={submitting}>
          {t('common.confirm')}
        </Button>
      </Grid>
    </Grid>
  );
};
