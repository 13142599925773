import { queryParameter } from 'components/Table/consts';
import {
  Query,
  ReimbursementCaseFiltersInput,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { debounce } from 'lodash';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useMemo, useState } from 'react';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';
import { mapToReimbursementsFilterInput } from 'views/Reimbursement/utils/mapToReimbursementsFilterInput';
import { mapToReimbursementSortInput } from 'views/Reimbursement/utils/mapToReimbursementSortInput';
import { mapToReimbursementsTableData } from 'views/Reimbursement/utils/mapToReimbursementsTableData';
import { inboxReimbursementsQuery } from '../queries';
import { availabeFiltersAndSorts } from '../utils';

const defaultFiltersBE: ReimbursementCaseFiltersInput = {
  statuses: [
    ReimbursementCaseStatus.Review,
    ReimbursementCaseStatus.Rejected,
    ReimbursementCaseStatus.Check,
  ],
};

export const useInboxReimbursementsData = () => {
  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<ReimbursementTableRow>({
      availableFilters: availabeFiltersAndSorts,
    });

  const initialFilters = defaultFiltersBE;
  const { searchParams } = useMutateSearchParams();
  const searchQueryParam = searchParams.get(queryParameter) ?? '';
  const [search, setSearch] = useState(searchQueryParam);

  const computeVariables = (endCursor: string) => ({
    input: {
      limit: 20,
      after: endCursor,
    },
    filters: mapToReimbursementsFilterInput(filters, initialFilters),
    sortBy: sortBy.length ? mapToReimbursementSortInput(sortBy) : undefined,
    queries: {
      query: search.length ? search : undefined,
    },
  });

  const { data, loading, onLoadMore } = usePagination<
    Pick<Query, 'reimbursementCasesCursorBased'>
  >(
    inboxReimbursementsQuery,
    'reimbursementCasesCursorBased',
    {
      variables: {
        input: { limit: 20 },
        sortBy: sortBy.length ? mapToReimbursementSortInput(sortBy) : undefined,
        filters: mapToReimbursementsFilterInput(filters, initialFilters),
        queries: {
          query: search.length ? search : undefined,
        },
      },
    },
    { computeVariables }
  );

  const reimbursementsEdges = data?.reimbursementCasesCursorBased.edges ?? [];
  const pageInfo = data?.reimbursementCasesCursorBased.pageInfo;
  const reimbursementsCount = pageInfo?.totalCount ?? 0;
  const isTableEmpty = reimbursementsEdges.length === 0 && !loading;
  const hasMoreData = pageInfo?.hasNextPage ?? false;
  const isTableFiltered = filters.length > 0;

  const reimbursements = reimbursementsEdges.map(mapToReimbursementsTableData);

  const handleDebounceSearch = useMemo(() => {
    return debounce(setSearch, DEFAULT_DEBOUNCE_TIME, { leading: true });
  }, []);

  return {
    isLoadingReimbursements: loading,
    isTableFiltered,
    isTableEmpty,
    hasMoreData,
    reimbursements,
    reimbursementsCount,
    sortBy,
    filters,
    handleDebounceSearch,
    onFilter,
    onSort,
    onLoadMore,
  };
};
