import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { useTranslation } from 'react-i18next';

export interface MembershipsKebabMenuProps {
  onExport: () => void;
}

export const MembershipsKebabMenu = ({
  onExport,
}: MembershipsKebabMenuProps) => {
  const [t] = useTranslation('settings');

  return (
    <KebabMenu
      items={[
        {
          id: 'download',
          label: t('memberships.contextMenu.download'),
          onAction: onExport,
        },
      ]}
      label={t('memberships.contextMenu.openMenu')}
    />
  );
};
