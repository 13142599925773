import { mergeProps } from '@candisio/design-system';
import {
  HookFormWorkflowField,
  HookFormWorkflowFieldProps,
} from 'components/HookFormFields/HookFormWorkFlowField';
import { useReadOnlyTransactionFormField } from '../hooks/useReadOnlyTransactionFormField';
import { ReadOnlyTransactionFormValues } from '../utils/readOnlyTransactionFormSchema';

export const ReadOnlyTransactionWorkflowField = ({
  name,
  ...restProps
}: HookFormWorkflowFieldProps<ReadOnlyTransactionFormValues>) => {
  const { fieldProps } = useReadOnlyTransactionFormField(name);

  return (
    <HookFormWorkflowField name={name} {...mergeProps(restProps, fieldProps)} />
  );
};
