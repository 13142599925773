import { useContext, useEffect } from 'react';
import { ExportContext } from './Context';
import { ExportLayout } from './Layout';
import { useExportDataGigaFactory } from './toolkit/hooks/useExportDataGigaFactory';

export const ExportViewBuilder = () => {
  const state = useContext(ExportContext);

  const {
    refetch: { all: refetchAll },
  } = useExportDataGigaFactory(state);

  useEffect(() => {
    refetchAll.forEach(refetch => refetch());
  }, [refetchAll]);

  return <ExportLayout />;
};
