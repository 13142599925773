import { ReactNode } from 'react';
import {
  FormProvider,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { DocumentFormContext } from './DocumentFormContext';
import { DocumentFormValues } from './toolkit/schema';

export interface DocumentFormProviderProps {
  bookingsFieldArray: UseFieldArrayReturn<DocumentFormValues, 'bookings'>;
  children: ReactNode;
  form: UseFormReturn<DocumentFormValues>;
}

export const DocumentFormProvider = ({
  children,
  bookingsFieldArray,
  form,
}: DocumentFormProviderProps) => {
  return (
    <DocumentFormContext.Provider value={bookingsFieldArray}>
      <FormProvider {...form}>{children}</FormProvider>
    </DocumentFormContext.Provider>
  );
};
