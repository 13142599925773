import { defaultTheme } from '@candisio/design-system';
import { useProfiler } from '@sentry/react';
import { MouseEventHandler, ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';

export interface AccordionItemProps {
  error?: boolean;
  icon?: ReactNode;
  onOpen?: () => void;
  onClick?: () => void;
  open?: boolean;
  summary: ReactNode;
  id?: string;
  children: ReactNode;
}

/*
 * Currently, implementation happens with details and summary elements which
 * are [wildly supported](https://caniuse.com/mdn-html_elements_summary).
 * There are [some caveats](https://daverupert.com/2019/12/why-details-is-not-an-accordion/)
 * from an accessibility perspective, though in practice they
 * [might less relevant](https://www.hassellinclusion.com/blog/accessible-accordions-part-2-using-details-summary/)
 */
export const DetailsComponent = styled.details`
  border-bottom: 1px solid ${defaultTheme.colors.gray300};
  overflow: hidden;

  &:first-child {
    border-radius: ${defaultTheme.radii.medium} ${defaultTheme.radii.medium} 0 0;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 ${defaultTheme.radii.medium} ${defaultTheme.radii.medium};
  }

  &[open] {
    margin-bottom: 0.25rem;

    &:not(:first-child) {
      margin-top: 0.25rem;
    }

    & button {
      opacity: 1;
      visibility: visible;
    }
  }
`;

type SummaryComponentProps = {
  error?: boolean;
  isOpen?: boolean;
};

export const SummaryComponent = styled.summary<SummaryComponentProps>`
  cursor: pointer;
  padding: ${defaultTheme.space.space8} ${defaultTheme.space.space16};
  transition: 0.25s cubic-bezier(0.87, 0, 0.13, 1);
  list-style: none;

  background-color: ${props =>
    props.isOpen ? defaultTheme.colors.bluebg : defaultTheme.colors.gray0};

  & button {
    opacity: 0;
    visibility: hidden;
    transition: 0.25s cubic-bezier(0.87, 0, 0.13, 1);
  }

  &::marker,
  &::-webkit-details-marker {
    display: none;
  }

  &:focus {
    border-bottom-color: ${defaultTheme.colors.blue500};
    outline: none;
  }

  &:focus-within {
    & button {
      opacity: 1;
      visibility: visible;
    }
  }

  &:hover {
    background-color: ${props =>
      props.isOpen ? defaultTheme.colors.bluebg : defaultTheme.colors.gray100};

    & button {
      opacity: 1;
      visibility: visible;
    }
  }

  ${props =>
    props.error &&
    `background-color: ${defaultTheme.colors.redbg};
    
      &:hover {
        background-color: ${defaultTheme.colors.redbg};
  }`};
`;

export const AccordionItem = ({
  id,
  children,
  error = false,
  icon,
  onOpen,
  open,
  summary,
  onClick,
}: AccordionItemProps) => {
  useProfiler('Accordion Item', { hasRenderSpan: true });
  const handleClickSummary: MouseEventHandler<HTMLElement> = e => {
    if (open !== undefined) {
      e.preventDefault(); // make the open state controlled
      onOpen?.();
    }
  };

  return (
    <DetailsComponent id={id} open={open} onClick={onClick}>
      {icon}
      <SummaryComponent
        error={error}
        isOpen={open}
        onClickCapture={handleClickSummary}
        aria-invalid={error}
        tabIndex={open ? -1 : undefined}>
        {summary}
      </SummaryComponent>
      {open && children}
    </DetailsComponent>
  );
};
