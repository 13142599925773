import { Locale, UserRole } from 'generated-types/graphql.types';
import { ErrorMessages } from 'utils/zodFormValidation';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { z } from 'zod';
import { InviteFormDeprecatedProps } from './invite/InviteFormDeprecated';

export interface TeamMemberFormSchemaOptions {
  allUserEmails?: string[];
  /** Fields that are hidden due to the organization using SSO should not be validated */
  hiddenFields?: InviteFormDeprecatedProps['hide'];
}

export const teamMemberFormSchemaDeprecated = ({
  allUserEmails,
  hiddenFields,
}: TeamMemberFormSchemaOptions = {}) => {
  return z.object({
    firstName: hiddenFields?.firstName ? z.string() : nonEmptyString,
    lastName: hiddenFields?.lastName ? z.string() : nonEmptyString,
    issueCreditCard: z.boolean().optional(),
    teams: z.array(z.string()).optional(),
    email: z
      .string()
      .email()
      .superRefine((value, context) => {
        const isEmailTaken = allUserEmails?.some(userEmail => {
          const emailsEqual = userEmail.localeCompare(value) === 0;

          return emailsEqual;
        });

        if (isEmailTaken) {
          context.addIssue({
            code: 'custom',
            params: {
              translationKey: 'settings.teamMembers.errors.duplicate',
            },
          });
        }
      }),
    locale: z.nativeEnum(Locale),
    roles: z
      .array(z.nativeEnum(UserRole))
      .min(hiddenFields?.roles ? 0 : 1)
      .superRefine((value, context) => {
        const cardManagerWithoutAdmin =
          value.includes(UserRole.CardManager) &&
          !value.includes(UserRole.Admin);

        if (cardManagerWithoutAdmin) {
          context.addIssue({
            code: 'custom',
            params: {
              translationKey: 'settings.teamMembers.errors.rolesCardManager',
            },
          });
        }
      }),
  });
};

export type TeamMemberFormOutput = z.infer<
  ReturnType<typeof teamMemberFormSchemaDeprecated>
>;
export type TeamMemberFormValues = Partial<TeamMemberFormOutput>;
export type TeamMemberErrorMessages = ErrorMessages<
  ReturnType<typeof teamMemberFormSchemaDeprecated>
>;
