import { Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

type Value = number | undefined;

export const FileSizeCell = ({
  value,
}: CellProps<DocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const valueInMb = value / 1024 / 1024; // @TODO - should we always display size in MB?

  return (
    <TruncatedText wordBreak="break-all">
      {valueInMb.toFixed(2) + ' MB'}
    </TruncatedText>
  ); // todo magic string MB
};
