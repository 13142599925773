import {
  Button,
  Grid,
  Text,
  usePopover,
  Popover,
  Link,
} from '@candisio/design-system';
import { LottieAnimation, successData } from 'components/Lottie/Lottie';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { Sidebar as SideBarElement } from 'components/Sidebar/Sidebar';
import {
  ExportStatus,
  useGetInitialBdsExportsQuery,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatters from 'utils/date_formatter';
import { ExportingProgressBar } from 'views/Integrations/Export/toolkit/components/ExportDetails/components';
import { Sidebar } from 'views/Integrations/Export/types';
import { ExportContext } from '../../../Context';
import { ExportHistorySummary } from '../../containers/ExportableEntitiesSummary/History';
import { useDatevSettings } from '../../hooks/useDatevSettings';
import { DatevDcoImage } from '../common';
import { DatevPanel } from '../DatevPanel';
import { ExportInfo } from '../ExportInfo';
import { DatevExportDetailsProps } from './DatevExportDetails';

const INITIAL_BDS_EXPORT_LIMIT = 3;

export const DatevBdsExportDetails = ({
  exportDetails,
}: DatevExportDetailsProps) => {
  const state = useContext(ExportContext);
  const [t] = useTranslation();
  const {
    close,
    open,
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({
    placement: 'top',
  });

  const { datevSettings } = useDatevSettings();

  const datevClient = datevSettings?.client;

  const { bdsBought } = useDatev(); // BDS-checked

  const { data: initialBdsExports } = useGetInitialBdsExportsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: INITIAL_BDS_EXPORT_LIMIT,
    },
    skip: !bdsBought,
  });

  const initialBdsExportIds =
    initialBdsExports?.getInitialBdsExports.map(({ id }) => id) ?? [];

  const isFirstBdsExport = initialBdsExportIds?.find(
    id => exportDetails.id === id
  );

  const displayImportGuide =
    isFirstBdsExport ?? initialBdsExportIds?.length < INITIAL_BDS_EXPORT_LIMIT;

  const howToImportPostings = [
    t('export.exportForm.exportDatevBds.how_to_import_posting_step_one'),
    t('export.exportForm.exportDatevBds.how_to_import_posting_step_two'),
    t('export.exportForm.exportDatevBds.how_to_import_posting_step_three'),
    t('export.exportForm.exportDatevBds.how_to_import_posting_step_four'),
    t('export.exportForm.exportDatevBds.how_to_import_posting_step_five'),
  ];

  const handleReExport = () => {
    state.setSidebar(Sidebar.INIT_NEW_EXPORT);
    state.setExportType(exportDetails.type);
    state.setIsReExport(true);
  };

  return (
    <SideBarElement
      heading={t('export.exportForm.exportDatev.title', {
        date: DateFormatters.compact(exportDetails?.createdAt || new Date()),
      })}>
      <Grid templateRows="auto auto 1fr" height="100%">
        <ExportInfo>
          <DatevDcoImage />
          <Grid>
            <Text fontSize="basic">
              {t('export.exportForm.selectExportType.types.datev_bds')}
            </Text>
            {datevClient && (
              <Text fontSize="small">
                {t('exports:datevClientInfo', {
                  name: datevClient.name,
                  id: datevClient.id,
                })}
              </Text>
            )}
          </Grid>
        </ExportInfo>
        {exportDetails.status !== ExportStatus.Exporting ? (
          <ExportHistorySummary />
        ) : (
          <div />
        )}

        <DatevPanel
          onExport={handleReExport}
          nonIncidentElement={
            <ActionBar>
              {exportDetails.status === ExportStatus.Exporting ? (
                <ExportingProgressBar />
              ) : (
                <>
                  {displayImportGuide && (
                    <Grid
                      gap="space10"
                      padding="space2"
                      paddingBottom="space16">
                      <LottieAnimation
                        options={{ animationData: successData }}
                        width="100px"
                        height="100px"
                      />
                      <Text
                        color="gray800"
                        fontSize="large"
                        fontWeight="semibold">
                        {t(
                          'export.exportForm.exportDatevBds.how_to_import_posting_heading'
                        )}
                      </Text>
                      <Grid as="ol" paddingLeft="space16" fontSize="basic">
                        {howToImportPostings.map(step => (
                          <li key={step}>
                            <Text color="gray800" fontSize="basic">
                              {step}
                            </Text>
                          </li>
                        ))}
                      </Grid>
                      <Text
                        color="blue500"
                        fontSize="basic"
                        fontWeight="semibold">
                        <Link
                          external
                          href={t(
                            'export.exportForm.exportDatevBds.how_to_import_posting_url'
                          )}>
                          {t(
                            'export.exportForm.exportDatevBds.how_to_import_posting_description'
                          )}
                        </Link>
                      </Text>
                    </Grid>
                  )}
                  <Button onClick={open} {...triggerProps} ref={triggerRef}>
                    {t('export.exportForm.exportDatev.exportAgain')}
                  </Button>

                  {isOpen && (
                    <Popover
                      {...popoverProps}
                      ref={popoverRef}
                      padding="space16"
                      width="294px">
                      <Grid gap="space8">
                        <Text>
                          {t(
                            'export.exportForm.exportDatevBds.confirm_bds_re_export'
                          )}
                        </Text>
                        <Grid
                          gap="space8"
                          autoFlow="column"
                          templateColumns="auto 1fr">
                          <Button onClick={handleReExport} color="blue">
                            {t(
                              'export.exportForm.exportDatevBds.bds_re_export_button'
                            )}
                          </Button>
                          <Button
                            variant="secondary"
                            color="blue"
                            onClick={close}>
                            {t('no')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Popover>
                  )}
                </>
              )}
            </ActionBar>
          }
        />
      </Grid>
    </SideBarElement>
  );
};
