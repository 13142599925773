import { ApproverExtractedEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { OrderList } from '../styles';

export const ApproverExtractedComponent = ({
  approvers,
}: ApproverExtractedEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <>
      {t('document.compact.history.extractedApprovers')}
      <OrderList>
        {approvers?.map(
          approver => approver && <li key={approver.id}>{approver.name}</li>
        )}
      </OrderList>
    </>
  );
};
