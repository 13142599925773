import candisPhyLogo from 'assets/credit-cards/candis-physical.svg';
import candisVirtualLogo from 'assets/credit-cards/candis-virtual.svg';
import candisSingleUseLogo from 'assets/credit-cards/candis-virtual1x.svg';
import { CardType } from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useUserRoles } from 'hooks/useUserRoles';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { createVendor } from 'views/CreditCards/CreditCardsInsights/RecurringPayments/RecurringPaymentsList/RecurringPaymentsList';
import { PaginatedFieldProps } from 'views/CreditCards/hooks/useAccountingInfoFormDefaultValues';
import { userIdParamKey } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/invite/IssueCreditCard';
import { ApprovalMode } from '../AccountingInfoStep/FormFields/CreditCardsWorkflowAndApproversFields';

export type FormStep =
  | 'CreateVendor'
  | 'LinkCard'
  | 'TeamMember'
  | 'CardTypeAndLimits'
  | 'CardUsage'
  | 'AccountingInfo'
  | 'Success';

export interface CreateCreditCardModalState {
  formStep: FormStep;
  cardType: CardType.Physical | CardType.Virtual | CardType.SingleUse;
  teamMemberId?: string;
  createdCreditCardId?: string;
  cardDetails?: {
    approvalMode?: ApprovalMode;
    validity?: string;
    limit?: number;
    comment?: string;
    transactionLimit?: number;
    limitInterval?: string;
    cardLabel?: string | null;
    purpose?: string;
    approvers?: {
      approvers: string[];
    }[];
    generalLedgerAccount?: PaginatedFieldProps;
    costCenter?: PaginatedFieldProps;
    costObject?: PaginatedFieldProps;
    workflow?: string | null;
    artistSocialInsuranceCode?: string | null;
    extraCostInfo?: PaginatedFieldProps;
    contact?: PaginatedFieldProps;
    taxCode?: string | null;
    noInvoiceNeeded?: boolean;
  };
}

export const useModalInitialState = () => {
  const { isCardManager } = useUserRoles();
  const { vendorId } = useParams<{ vendorId?: string }>();
  const { searchParams } = useMutateSearchParams();

  const isRecurringPaymentsFlow = vendorId === createVendor;
  const userId = searchParams.get(userIdParamKey) ?? undefined;

  const initialState: CreateCreditCardModalState = {
    formStep: vendorId
      ? isRecurringPaymentsFlow
        ? 'CreateVendor'
        : 'LinkCard'
      : isCardManager
      ? 'TeamMember'
      : 'CardTypeAndLimits',
    cardType: CardType.Virtual,
    teamMemberId: userId,
  };

  return initialState;
};

export const transition = { duration: 0.5, ease: 'easeInOut' };

export const animationVariant = {
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      ...transition,
      delay: 0.5,
    },
  },
  show: {
    height: 'auto',
    opacity: 1,
    padding: '4px',
    transition,
  },
};

type AvailableCardTypes = Exclude<CardType, 'BLACK'>;

const availableCardTypes: AvailableCardTypes[] = [
  CardType.Virtual,
  CardType.SingleUse,
  CardType.Physical,
];

const mapCardTypeToIcons: Record<AvailableCardTypes, string> = {
  VIRTUAL: candisVirtualLogo,
  SINGLE_USE: candisSingleUseLogo,
  PHYSICAL: candisPhyLogo,
};

export const cardTypeList = availableCardTypes.map(cardType => ({
  id: cardType,
  icon: mapCardTypeToIcons[cardType],
  label: `ccRequestForm.categoryPage.${cardType}`,
  message: `ccRequestForm.categoryPage.cardWithCategory.${cardType}`,
}));
