import { noop } from 'lodash';
import { createContext, useContext } from 'react';

export interface FormRegistration {
  trigger?: () => void;
}

interface ReimbursementFormsContextProps {
  registerForm: (formKey: string, registration: FormRegistration) => void;
  triggerAllValidations: () => void;
}

export const ReimbursementFormsContext =
  createContext<ReimbursementFormsContextProps>({
    registerForm: noop,
    triggerAllValidations: noop,
  });

export const useReimbursementFormsContext = () => {
  const context = useContext(ReimbursementFormsContext);
  if (!context) {
    throw new Error(
      'useReimbursementFormsContext must be used within its ReimbursementFormsProvider'
    );
  }

  return context;
};
