import { Flex, Link, Text } from '@candisio/design-system';
import { useEcmDocumentsTableData } from 'containers/document-relationships/useEcmDocumentsTableData';
import { useCountEcmDocumentsWithFullTextSearchQuery } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { AddOnIcon } from './AddOnIcon';

export const FullTextSearch = ({ onClick }: { onClick?: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);
  // XXX as the component unmounts all the time while searching
  // we need to access a controlled debounced value from outside
  const { formattedFilters, searchDebouncedQuery } = useEcmDocumentsTableData({
    filterParamsSource: 'url',
  });

  const { data, loading } = useCountEcmDocumentsWithFullTextSearchQuery({
    variables: {
      search: searchDebouncedQuery,
      filter: formattedFilters,
    },
    skip: searchDebouncedQuery.length === 0,
  });

  const count = data?.countEcmDocumentsWithFullTextSearch.count;

  const content = (
    <>
      <Text color="purple500">
        {t('promo.fullTextSearch.countFound', { count })}
      </Text>
      <Text color="purple500">
        <Flex gap="0.1rem">
          <Trans
            t={t}
            i18nKey="promo.fullTextSearch.upgradeCTA"
            values={{ count }}>
            Jetzt upgraden
            <AddOnIcon />
            für noch genauere Suchergebnisse.
          </Trans>
        </Flex>
      </Text>
    </>
  );

  return (
    <Flex
      onClick={onClick}
      direction="column"
      width="20rem"
      height="40px"
      textAlign="center"
      alignItems="center"
      style={{ marginTop: '1rem', cursor: 'pointer' }}>
      {loading || !count ? null : content}
    </Flex>
  );
};

export const FullTextSearchPromoLink = ({
  onClick,
}: ComponentProps<typeof Link>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  return (
    <Link
      as="button"
      icon="arrowUpCircleFilled"
      color="purple"
      iconPosition="right"
      onClick={onClick}>
      {t('promo.fullTextSearch.searchbarCTA')}
    </Link>
  );
};
