import { Button, Grid, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const NoTagsAvailable = () => {
  const [t] = useTranslation();

  return (
    <Grid padding="space16" gap="space20">
      <Text color="gray450" fontSize="basic" lineHeight="paragraph">
        {t('documentTags.field.empty')}
      </Text>
      <Button color="gray" variant="secondary">
        {t('common.ok')}
      </Button>
    </Grid>
  );
};
