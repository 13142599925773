import { useToggleInAppNotificationsMutation } from 'generated-types/graphql.types';

interface MarkNotificationsAsReadParams {
  ids?: string[];
  organizationIds?: string[];
}

export const useToggleNotificationRead = () => {
  const [toggleInAppNotifications, { client }] =
    useToggleInAppNotificationsMutation({});

  const markNotificationsAsRead = ({
    ids,
    organizationIds,
  }: MarkNotificationsAsReadParams) => {
    void toggleInAppNotifications({
      onCompleted: () => {
        client.cache.evict({ fieldName: 'inAppNotifications' });
      },
      variables: {
        input: {
          isRead: true,
        },
        filters: {
          ids,
          organizationIds,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: ['inAppNotificationsCount'],
    });
  };

  return {
    markNotificationsAsRead,
  } as const;
};
