import { useGenerateCsvFileForStatementMutation } from 'generated-types/graphql.types';
import { useState } from 'react';

export type DownloadCSV = (statementId: string) => Promise<{
  status: 'loading' | 'error' | 'success';
  hasFile?: boolean;
  csvUrl?: string;
}>;

export const useGenerateCSV = () => {
  const [csvFileRecordId, setCsvFileRecordId] = useState<string | undefined>(
    undefined
  );

  const [generateCSVFile, { loading: isGenerateCSVPending }] =
    useGenerateCsvFileForStatementMutation();

  const generateCSV = async (statementId: string) => {
    const response = await generateCSVFile({
      variables: { input: { statementId } },
    });

    const csvFileRecordId = response.data?.generateCsvFileForStatement.id;

    if (!csvFileRecordId) {
      return { status: 'error' };
    }

    setCsvFileRecordId(csvFileRecordId);

    return { status: 'success' };
  };

  return { generateCSV, isGenerateCSVPending, csvFileRecordId };
};
