import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { Maybe } from 'generated-types/graphql.types';
import { isNil } from 'lodash';

/** Maps data to `ProcessingFormAccountingDataFieldItem` */
export const toAccountingDataFieldItem = (data: {
  id: string;
  readableName: string;
  taxCode?: Maybe<string>;
  code?: Maybe<string | number>;
  name?: Maybe<string>;
}): ProcessingFormAccountingDataFieldItem => {
  const code = data.code ?? data.taxCode;

  return {
    key: data.id,
    children: data.readableName,
    code: !isNil(code) ? String(code) : undefined,
    name: data.name ?? undefined,
  };
};
