import { Link } from '@candisio/design-system';

import { History } from 'history';
import { Trans } from 'providers/LocaleProvider';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { LocalMembershipStatus } from 'views/Settings/TeamMembers/UsersListView';

export interface ToastMessageProps {
  history: History;
  nameAndEmail: string;
  pathname: string;
  status: LocalMembershipStatus;
  translationKey: string;
}

export const ToastMessage = ({
  history,
  nameAndEmail,
  pathname,
  status,
  translationKey,
}: ToastMessageProps) => {
  return (
    <Trans i18nKey={translationKey} values={{ nameAndEmail }}>
      <Link
        as="button"
        onClick={() => {
          const search = qs.stringify({ status });
          history.push({ pathname, search });
        }}>
        {{ nameAndEmail } as any}
      </Link>{' '}
      has been successfully invited
    </Trans>
  );
};
