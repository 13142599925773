import { HookFormWorkflowFieldItem } from 'components/HookFormFields/HookFormWorkFlowField';
import { ProcessingFormFieldItemsQuery } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { ValuesType } from 'utility-types';

/**
 * Maps `WorkflowTemplate` and `Absence` to `ProcessingFormWorkflowFieldItem`
 */
export const toWorkflowFieldItem = (
  workflow: ValuesType<ProcessingFormFieldItemsQuery['workflows']>,
  orgMembersAbsence: ProcessingFormFieldItemsQuery['getOrgMembersAbsence'] = []
): HookFormWorkflowFieldItem => {
  const approvers = workflow.steps?.map(step =>
    (step.resolvers ?? []).reduce<string[]>((result, approver) => {
      if (approver?.id) {
        result.push(approver.id);
      }

      return result;
    }, [])
  );

  const hasAbsence = orgMembersAbsence.some(member => {
    if (isNil(member.status)) return false;

    return workflow.steps?.some(step =>
      step.resolvers?.some(resolver => resolver?.id === member.membershipId)
    );
  });

  const hasAbsenceAndNoSubstitute = orgMembersAbsence.some(member => {
    if (isNil(member.status)) return false;

    return workflow.steps?.some(step =>
      step.resolvers?.some(
        resolver =>
          resolver?.id === member.membershipId &&
          isNil(member.substituteMembershipId)
      )
    );
  });

  return {
    key: workflow.id,
    children: workflow.name,
    approvers,
    hasAbsence,
    hasAbsenceAndNoSubstitute,
  };
};
