import { DateFilterOption } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';

export type DateOptionMissingInvoicesWidget =
  | 'All'
  | 'Last14Days'
  | 'Last7Days'
  | 'PreviousMonth'
  | 'CurrentMonth'
  | 'OlderThan2Months';

export type DateRange = { dateFrom: string; dateTo: string } | undefined;

export type DateFilterManifestProps = {
  currentDate: Date;
  dateOption: DateOptionMissingInvoicesWidget;
};

const defaultDateFrom = '01.01.2015';

export const dateFilterManifest = ({
  currentDate,
  dateOption,
}: DateFilterManifestProps): DateRange => {
  const dateFilters: Record<DateOptionMissingInvoicesWidget, DateRange> = {
    All: undefined,
    Last7Days: {
      dateFrom: moment(currentDate).subtract(7, 'd').format(DATE_FORMAT),
      dateTo: moment(currentDate).format(DATE_FORMAT),
    },
    Last14Days: {
      dateFrom: moment(currentDate).subtract(14, 'd').format(DATE_FORMAT),
      dateTo: moment(currentDate).format(DATE_FORMAT),
    },
    CurrentMonth: {
      dateFrom: moment(currentDate).startOf('month').format(DATE_FORMAT),
      dateTo: moment(currentDate).endOf('month').format(DATE_FORMAT),
    },
    PreviousMonth: {
      dateFrom: moment(currentDate)
        .subtract(1, 'month')
        .startOf('month')
        .format(DATE_FORMAT),
      dateTo: moment(currentDate)
        .subtract(1, 'month')
        .endOf('month')
        .format(DATE_FORMAT),
    },
    OlderThan2Months: {
      //BE does not allow this value to be undefined so  we pick a very far away date
      dateFrom: defaultDateFrom,
      dateTo: moment(currentDate)
        .subtract(2, 'month')
        .endOf('month')
        .format(DATE_FORMAT),
    },
  };

  return dateFilters[dateOption];
};

export const mappedDateRangeFilters = (dateRange?: DateRange) => {
  if (isNil(dateRange)) return undefined;

  return {
    transactionCreatedAt: {
      ...dateRange,
      filterOption: DateFilterOption.Custom,
    },
  };
};
