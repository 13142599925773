import { Vendor } from 'views/CreditCards/hooks/useVendorSuggestionsData';
import { VendorListLoader } from './VendorListLoader';
import { VendorListSuggestions } from './VendorListSuggestions';

interface VendorListSuggestionsContainerProps {
  loading: boolean;
  showVendors: boolean;
  vendorDetails: {
    vendors: Vendor[];
    convertedVendors: number;
    vendorsToConvert: number;
  };
  onSetExpandibleCards: (
    toogle: 'vendorSuggestions' | 'recurringPayments'
  ) => void;
  onSetSuggestedAmount: React.Dispatch<React.SetStateAction<number>>;
}

export const VendorListSuggestionsContainer = ({
  loading,
  vendorDetails,
  showVendors,
  onSetExpandibleCards,
  onSetSuggestedAmount,
}: VendorListSuggestionsContainerProps) => {
  if (loading) {
    return <VendorListLoader />;
  }

  return (
    <VendorListSuggestions
      onSetExpandibleCards={onSetExpandibleCards}
      showVendors={showVendors}
      vendorDetails={vendorDetails}
      onSetSuggestedAmount={onSetSuggestedAmount}
    />
  );
};
