import { TruncatedText, HeaderProps } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PaymentConditionsTableData } from './types';

const mapColumnIdToTranslationKey: Record<string, string> = {
  value: 'paymentConditions.table.columns.value',
  status: 'paymentConditions.table.columns.status',
  paymentConditionNumber:
    'paymentConditions.table.columns.paymentConditionNumber',
} as const;

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<PaymentConditionsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
      {t(mapColumnIdToTranslationKey[column.id])}
    </TruncatedText>
  );
};
