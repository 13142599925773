import { Box } from '@candisio/design-system';
import { CardStatus } from 'generated-types/graphql.types';
import { PendingInfoBox } from './PendingInfoBox';
import { ProcessingInfoBox } from './ProcessingInfoBox';

interface CardStatusInfoBoxProps {
  cardId: string;
  cardStatus?: CardStatus;
}

export const CardStatusInfoBox = ({
  cardId,
  cardStatus,
}: CardStatusInfoBoxProps) => {
  const isCardPending = cardStatus === CardStatus.Pending;

  const isCardProcessing = cardStatus === CardStatus.Processing;

  return (
    <Box>
      {isCardProcessing && <ProcessingInfoBox cardId={cardId} />}
      {isCardPending && <PendingInfoBox cardId={cardId} />}
    </Box>
  );
};
