import moment from 'moment';
import DateFormatters from 'utils/date_formatter';
import { z } from 'zod';
import { getAllowedDateRange } from './getAllowedDateRange';

interface DeliveryDateSchemaOptions {
  /** Maximum allowed number of years in past */
  yearsInPast?: number;
}

/** Zod schema to validate delivery date is within expected range */
export const deliveryDateSchema = ({
  yearsInPast,
}: DeliveryDateSchemaOptions) => {
  return z.string().superRefine((value, ctx) => {
    const { minDate } = getAllowedDateRange({ yearsInPast });

    const valueMoment = moment(value, moment.ISO_8601);
    if (minDate && valueMoment.isBefore(minDate)) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey:
            'document.requestApproval.inputs.errors.deliveryDate.min',
          date: DateFormatters.compact(minDate),
        },
      });
    }
  });
};
