import { Flex, Link } from '@candisio/design-system';
import { ErrorIcon, CheckmarkIcon } from 'components/Icons/DefaultIcons';
import { motion } from 'framer-motion';
import { ContactValidationErrorType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useContactImportErrorReports } from 'views/Contacts/ContactImport/contactImportErrorReports';
import {
  FilterState,
  SingleParsedContact,
} from 'views/Contacts/ContactImport/types';
import { ImportSteps } from './contactImportReducer';

export interface InfoListProps {
  animate?: boolean;
  contacts: SingleParsedContact[];
  onUpdateFilter: (filter: FilterState | null) => void;
  importStep?: ImportSteps;
}

const MotionFlex = motion(Flex);

const animationVariants = {
  variants: {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  },
};

const successAnimationVariants = {
  animate: 'visible',
  visible: {
    transition: {
      staggerChildren: 1.2,
      delayChildren: 1.2,
    },
  },
};

export const ContactImportInfoList = ({
  animate = false,
  contacts,
  onUpdateFilter,
  importStep,
}: InfoListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);

  const errorReports = useContactImportErrorReports();

  return (
    <MotionFlex
      initial={animate ? 'hidden' : false}
      variants={successAnimationVariants}
      direction="column"
      maxHeight="150px"
      width="max-content"
      gap="space8"
      paddingRight="space16"
      overflowY="auto"
      fontSize="basic">
      {contacts.length > 0 ? (
        <MotionFlex variants={animationVariants} gap="space4">
          <CheckmarkIcon size="space20" />
          {t('import.preview.foundContacts.showInfo', {
            count: contacts.length,
          })}
          {importStep === ImportSteps.Preview && (
            <Link
              onClick={() => {
                onUpdateFilter(null);
              }}>
              {t('import.preview.foundContacts.showLink')}
            </Link>
          )}
        </MotionFlex>
      ) : (
        <Flex gap="space4" width="100%">
          <ErrorIcon size="space20" />
          <Flex gap="space4">
            {t('import.preview.notFoundContacts.showInfo') + ' '}
            <Link
              external
              href={t('import.preview.notFoundContacts.helpCentreLink.url')}>
              {t('import.preview.notFoundContacts.helpCentreLink.title')}
            </Link>
          </Flex>
        </Flex>
      )}
      {Object.entries(errorReports).map(
        ([errorKey, { infoKey, onLinkClick }]) => {
          const count = contacts.filter(c =>
            c.errors.includes(errorKey as ContactValidationErrorType)
          ).length;

          if (!count) {
            return null;
          }

          return (
            <MotionFlex
              key={errorKey}
              variants={animationVariants}
              gap="space4">
              <ErrorIcon size="space20" />
              {t(infoKey, { count })}
              {count !== contacts.length &&
                importStep === ImportSteps.Preview && (
                  <Link
                    onClick={() => {
                      onUpdateFilter(onLinkClick(contacts));
                    }}>
                    {t('import.preview.errors.actions.showLink', { count })}
                  </Link>
                )}
            </MotionFlex>
          );
        }
      )}
    </MotionFlex>
  );
};
