import { Grid, mergeProps, mergeRefs, useTheme } from '@candisio/design-system';
import { forwardRef } from 'react';
import { GridComponents } from 'react-virtuoso';

/** @ts-expect-error TODO: React upgrade props types mismatch */
export const VirtuosoList: GridComponents['List'] = forwardRef(
  ({ context, ...restProps }, forwardedRef) => {
    const { ref, itemWidth, listGap, ...listProps } = context.listProps;
    const { space } = useTheme();

    return (
      <Grid
        {...mergeProps(listProps, restProps)}
        ref={ref ? mergeRefs([ref, forwardedRef]) : forwardedRef}
        as="ul"
        gap={listGap}
        templateColumns={`repeat(auto-fill, minmax(${itemWidth}, 1fr))`}
        style={{
          ...restProps.style,
          paddingLeft: space.space16,
          paddingRight: space.space16,
        }}
      />
    );
  }
);
