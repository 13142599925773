import {
  CardIssuerAmountInput,
  CardLimitRenewFrequency,
  IssueVirtualCardInput,
} from 'generated-types/graphql.types';
import { IssueVirtualCardParams } from '../hooks/useIssueVirtualCard';

export const sanitiseIssueVirtualCard = ({
  limit,
  limitInterval,
  transactionLimit,
  validity,
  cardLabel,
  teamMember,
  selectedCategory,
  currentUserId,
  vendorId,
  accountingData,
  suggestions,
  noInvoiceNeeded,
}: IssueVirtualCardParams): IssueVirtualCardInput => {
  const sanitisedTxLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: transactionLimit ?? 0,
  };

  const sanitisedLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: limit ?? 0,
  };

  const suggestedAmount = {
    value: suggestions?.suggestedAmount ?? 0,
    currency: 'EUR',
  };

  const suggestedValues =
    suggestions?.suggestedAmount ||
    suggestions?.suggestedCardholder?.membershipId
      ? {
          cardholderId: suggestions?.suggestedCardholder?.membershipId,
          limit: suggestions?.suggestedAmount ? suggestedAmount : undefined,
        }
      : undefined;

  const settingsData = {
    isInvoiceNeeded: false,
  };

  return {
    limit: sanitisedLimit,
    validityPeriod: Number(validity),
    transactionLimit: sanitisedTxLimit,
    label: cardLabel ?? null,
    membershipId: teamMember,
    limitRenewFrequency: limitInterval as CardLimitRenewFrequency,
    category: selectedCategory ?? null,
    // We agreed internally to accept implicitly the agreement if the user is issuing a card for himself
    acceptedCardAgreement: currentUserId === teamMember ? true : false,
    vendorId,
    suggestions: suggestedValues,
    accountingData,
    settingsData: noInvoiceNeeded ? settingsData : undefined,
  };
};
