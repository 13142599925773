import { mergeProps } from '@candisio/design-system';
import {
  HookFormCheckboxField,
  HookFormCheckboxFieldProps,
} from 'components/HookFormFields/HookFormCheckboxField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';

/** A `HookFormCheckboxField` with added metadata */
export const ProcessingFormCheckboxField = ({
  name,
  readOnly,
  ...restProps
}: HookFormCheckboxFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);

  return (
    <HookFormCheckboxField
      name={name}
      {...mergeProps(fieldProps, restProps)}
      readOnly={readOnly || fieldProps?.readOnly}
    />
  );
};
