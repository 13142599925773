import { mergeProps } from '@candisio/design-system';

import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { StorageFormValues } from './storageFormSchema';
import { useStorageFormField } from './useStorageFormField';

export const StorageFormComboBoxField = ({
  name,
  ...restProps
}: HookFormComboBoxFieldProps<StorageFormValues>) => {
  const { fieldProps } = useStorageFormField(name);

  return (
    <HookFormComboBoxField name={name} {...mergeProps(fieldProps, restProps)} />
  );
};
