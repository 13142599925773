import { queryParameter } from 'components/Table/consts';
import {
  ContactRelationshipType,
  ContactsInfiniteScrollPaginationQuery,
  ContactsInfiniteScrollPaginationQueryVariables,
  ContactSortField,
  SortDirection,
  useCountAllContactsQuery,
  useCountWithoutAccountsPayableNumberContactsQuery,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce } from 'lodash';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { useContactsCountByRelationshipType } from 'views/Contacts/ContactTable/ContactTableDS/hooks/useContactsCountByRelationshipType';
import { contactsInfiniteScrollPaginationQuery } from 'views/Contacts/queries';
import { CONTACT_SEARCH_PARAM } from 'views/Contacts/types';
import { ContactsTableData } from '../ContactTableDS';
import { mapToContactsInputFilter } from '../utils/mapToContactsInputFilter';
import { mapToContactsSortInput } from '../utils/mapToContactsSortInput';
import { mapToContactsTableData } from '../utils/mapToContactsTableData';

interface UseContactTableDSDataParams {
  sortBy: SortingRule<ContactsTableData>[];
  filters: Filters<ContactsTableData>;
  mergedContactId?: string;
}

const defaultSort = {
  direction: SortDirection.Asc,
  field: ContactSortField.Name,
};

export enum TabFilterParams {
  ALL = 'ALL',
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER',
}

export const useContactTableDSData = ({
  sortBy,
  filters,
  mergedContactId,
}: UseContactTableDSDataParams) => {
  const [searchParams] = useSearchParams();
  const searchQueryParam = searchParams.get(queryParameter) ?? '';
  const [search, setSearch] = useState(searchQueryParam);
  const [t] = useTranslation();

  const computeFilters = mapToContactsInputFilter(filters);
  const computeSort = mapToContactsSortInput(sortBy);

  const { count: customerCount, loading: customerCountLoading } =
    useContactsCountByRelationshipType([
      ContactRelationshipType.Customer,
      ContactRelationshipType.SupplierCustomer,
    ]);

  const { count: supplierCount, loading: supplierCountLoading } =
    useContactsCountByRelationshipType([
      ContactRelationshipType.Supplier,
      ContactRelationshipType.SupplierCustomer,
    ]);

  const useTabKey = () => {
    const history = useHistory();
    const params: {
      noAccountsPayableNumber?: boolean;
      relationshipType?: ContactRelationshipType[];
    } = qs.parse(history.location.search) || {};

    if (!params || !params.relationshipType) {
      return TabFilterParams.ALL;
    }

    if (params.relationshipType?.includes(ContactRelationshipType.Customer)) {
      return TabFilterParams.CUSTOMER;
    }

    if (params.relationshipType?.includes(ContactRelationshipType.Supplier)) {
      return TabFilterParams.SUPPLIER;
    }

    return TabFilterParams.ALL;
  };

  const activeTab = useTabKey();

  const {
    data: allContacts,
    loading: allContactsLoading,
    onLoadMore,
    refetchCurrentPage,
  } = usePagePagination<
    ContactsInfiniteScrollPaginationQuery,
    ContactsInfiniteScrollPaginationQueryVariables
  >(contactsInfiniteScrollPaginationQuery, 'contactsPagination', {
    variables: {
      sortBy: sortBy?.length ? computeSort : defaultSort,
      filters: {
        ...computeFilters,
        hasAccountsPayableNumber: searchParams.get(
          CONTACT_SEARCH_PARAM.noAccountsPayableNumber
        )
          ? false
          : undefined,
      },
      queries: {
        name: search,
      },
    },
  });

  const contactRecords = useMemo(
    () => allContacts?.contactsPagination.records ?? [],
    [allContacts?.contactsPagination.records]
  );

  const contactsTableData = useMemo(
    () => mapToContactsTableData(contactRecords, t, mergedContactId),
    [contactRecords, t, mergedContactId]
  );

  const contactCount = allContacts?.contactsPagination.pageInfo.totalCount;

  const countAllContactsQuery = useCountAllContactsQuery({});
  const countWithoutAccountsPayableNumberContactsQuery =
    useCountWithoutAccountsPayableNumberContactsQuery({});

  const allContactsCount = countAllContactsQuery.data?.countContacts ?? 0;
  const allContactsCountLoading = countAllContactsQuery.loading;

  const contactsWithoutAccountsPayableNumberCount =
    countWithoutAccountsPayableNumberContactsQuery.data?.countContacts ?? 0;

  const contactsWithoutAccountsPayableNumberCountLoading =
    countWithoutAccountsPayableNumberContactsQuery.loading;

  const handleDebounceSearch = useMemo(() => {
    return debounce(setSearch, DEFAULT_DEBOUNCE_TIME, { leading: true });
  }, []);

  return {
    onLoadMore,
    allContactsLoading,
    activeTab,
    contactsWithoutAccountsPayableNumberCount,
    contactsWithoutAccountsPayableNumberCountLoading,
    allContactsCount,
    allContactsCountLoading,
    contactCount,
    customerCount,
    customerCountLoading,
    supplierCount,
    supplierCountLoading,
    contactsTableData,
    contactRecords,
    search,
    refetchCurrentPage,
    handleDebounceSearch,
  };
};
