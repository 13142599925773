import { useUserRoles } from 'hooks/useUserRoles';
import { useTranslation } from 'react-i18next';

export const useDisabledAttachmentButtonLabelText = (
  canAttachFiles: boolean
): string | undefined => {
  const [t] = useTranslation();
  const { isOnlyApprover } = useUserRoles();

  if (canAttachFiles) {
    return undefined;
  }

  if (isOnlyApprover) {
    return t('document.attachments.disabledAttachLabelForApprover');
  }

  return t('document.attachments.disabledAttachLabelForAdminAndRequester');
};
