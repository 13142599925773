import { useApolloClient } from '@apollo/client';
import {
  BookingAccountsDuplicateCheckQuery,
  BookingAccountsDuplicateCheckQueryVariables,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import {
  contains,
  getPreviousAccounts,
  toAccountCode,
} from 'views/Settings/BookingAccounts/utils/utils';
import { bookingAccountsDuplicateCheckQuery } from '../gql';
import {
  ParsedBookingAccount,
  ValidationErrors,
} from './parseBookingAccountCSV';

export const checkForDuplicate =
  (existingAccountCodes: string[]) =>
  (
    parsedBookingAccount: ParsedBookingAccount,
    index: number,
    allAccounts: ParsedBookingAccount[]
  ): ParsedBookingAccount => {
    if (parsedBookingAccount.errors.length > 0) {
      return parsedBookingAccount;
    }

    const { accountCode } = parsedBookingAccount;
    const existsInOrg = existingAccountCodes.some(contains(accountCode));

    const previousAccounts = getPreviousAccounts(allAccounts, index);
    const existsInFile = previousAccounts
      .filter(a => a.errors.length === 0)
      .map(toAccountCode)
      .some(contains(accountCode));

    if (existsInOrg || existsInFile) {
      parsedBookingAccount.errors.push(ValidationErrors.IS_DUPLICATE);
    }

    return parsedBookingAccount;
  };

export const useCheckDuplicates = () => {
  const client = useApolloClient();

  const markDuplicateBookingAccounts = useCallback(
    async (
      parsedBookingAccounts: ParsedBookingAccount[]
    ): Promise<ParsedBookingAccount[]> => {
      const accountCodes = parsedBookingAccounts
        .filter(({ errors }) => errors.length === 0)
        .map(toAccountCode);

      const { data } = await client.query<
        BookingAccountsDuplicateCheckQuery,
        BookingAccountsDuplicateCheckQueryVariables
      >({
        query: bookingAccountsDuplicateCheckQuery,
        variables: { accountCodes },
      });

      return parsedBookingAccounts.map(
        checkForDuplicate(data.bookingAccountsDuplicateCheck ?? [])
      );
    },
    [client]
  );

  return { markDuplicateBookingAccounts };
};
