import { ProcessingFormPaymentConditionFieldItem } from 'components/Form/ProcessingForm/ProcessingFormPaymentConditionFieldDefault';
import { PaymentCondition } from 'generated-types/graphql.types';

/** Maps `PaymentCondition` to `ProcessingFormPaymentConditionFieldItem` */
export const toPaymentConditionFieldItem = (
  paymentCondition: Pick<
    PaymentCondition,
    | 'id'
    | 'paymentConditionNumber'
    | 'dueDateOffset'
    | 'discountOffset'
    | 'discountPercentage'
  >
): ProcessingFormPaymentConditionFieldItem => {
  return {
    key: paymentCondition.id,
    conditionNumber: paymentCondition.paymentConditionNumber,
    dueDateOffset: paymentCondition.dueDateOffset,
    discountOffset: paymentCondition.discountOffset ?? undefined,
    discountPercentage: paymentCondition.discountPercentage ?? undefined,
  };
};
