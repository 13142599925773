import { CardType } from 'generated-types/graphql.types';
import { CardIssuerCardReplacementReason } from 'generated-types/resolvers-types';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export interface ReplaceCardFormSchemaOptions {
  cardType: CardType;
}

export const replaceCardFormSchema = ({
  cardType,
}: ReplaceCardFormSchemaOptions) =>
  z.object({
    validity:
      cardType === CardType.Virtual ? z.string() : z.string().optional(),
    replaceReason: z.nativeEnum(CardIssuerCardReplacementReason),
  });

export type ReplaceCardFormOutput = z.infer<
  ReturnType<typeof replaceCardFormSchema>
>;

export type ReplaceCardFormValues = Partial<ReplaceCardFormOutput>;

export type ReplaceCardFormErrorMessages = ErrorMessages<
  ReturnType<typeof replaceCardFormSchema>
>;
