import { useMutation } from '@apollo/client';
import { Flex, Grid, Skeleton, useId } from '@candisio/design-system';

import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { useOrganizationData } from '../hooks/useOrganizationData';
import { updateOrganizationMutation } from '../mutations';
import {
  NameFormData,
  nameErrorMessages,
  nameSchema,
} from '../resolvers/nameResolver';
import { OrganizationCard } from './OrganizationCard';

export const OrganizationName = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const { isLoading, name, organization } = useOrganizationData();
  const formId = useId();

  const form = useForm<NameFormData>({
    values: { name: name },
    resolver: zodResolver({
      zodSchema: nameSchema,
      errorMessages: {
        ...nameErrorMessages,
      },
    }),
  });

  const [updateOrganization] = useMutation(updateOrganizationMutation);

  const handleOrganizationDataUpdate = async (formOutput: NameFormData) => {
    const result = await updateOrganization({
      variables: {
        realmName: organization?.realmName,
        value: {
          name: formOutput.name,
        },
      },
      refetchQueries: ['getOrganizations'],
    });

    if (result.errors) {
      error(t('organisation.messages.organisation.error'));
    } else {
      success(t('organisation.messages.organisation.success'));
    }
  };

  const handleSubmit = async (name: NameFormData) => {
    const { isDirty } = form.getFieldState('name');

    if (isDirty) {
      await handleOrganizationDataUpdate(name);
    }
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Grid onSubmit={form.handleSubmit(handleSubmit)} id={formId} as="form">
        <OrganizationCard
          title={t('organisation.name.title')}
          paragraph={t('organisation.name.paragraph')}
          isLoading={isLoading}>
          {isLoading ? (
            <Skeleton height="space48" width="100%" />
          ) : (
            <Flex gap="space16" alignItems="center">
              <>
                <HookFormTextField
                  ariaLabel={t('organisation.inputs.title')}
                  name="name"
                  placeholder={t('organisation.placeholder.typeSomething')}
                  width="space256"
                />
                <QuickSaveButtons
                  isFormDirty={isFormDirty}
                  resetField={() => form.resetField('name')}
                />
              </>
            </Flex>
          )}
        </OrganizationCard>
      </Grid>
    </FormProvider>
  );
};
