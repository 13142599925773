import { Skeleton } from '@candisio/design-system';
import { ProcessingFormSubmitErrors } from 'components/Form/ProcessingForm/ProcessingForm';
import {
  ProcessingFormActions,
  ProcessingFormActionsProps,
} from 'components/Form/ProcessingForm/ProcessingFormActions';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import {
  DocumentStatus,
  useProcessingFormActionsQuery,
} from 'generated-types/graphql.types';
import {
  AppRouteParams,
  InboxTransactionAssociationRouteParams,
  Routes,
  compiledRoutes,
} from 'models';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { DUPLICATE_SEARCH_PARAMS } from 'views/Duplicate/consts';
import { DOCUMENT_PROCESSING_SEARCH_PARAMS } from './consts';
import { useDeleteDocument } from './useDeleteDocument';

export interface ProcessingFormActionsContainerProps {
  /** Document to perform actions on */
  documentId: string;
  /** Called when document should be (fast) approved */
  onApprove: (
    values: ProcessingFormValues
  ) => Promise<ProcessingFormSubmitErrors | void>;
  /** Called when document successfully deleted */
  onDeleteDocument?: () => void;
  /** Called when cancel edit was clicked */
  onCancelEdit?: () => void;
}

/** Handles data fetching and routing for ProcessingFormActions component */
export const ProcessingFormActionsContainer = ({
  documentId,
  onApprove,
  onDeleteDocument,
  onCancelEdit,
}: ProcessingFormActionsContainerProps) => {
  const { organizationSlug, transactionId } = useParams<
    AppRouteParams | InboxTransactionAssociationRouteParams
  >();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data, loading } = useProcessingFormActionsQuery({
    variables: { documentId },
  });

  const originalDocumentId =
    data?.document?.originalDocumentId?.value ?? undefined;

  const isDuplicate = data?.document?.isDuplicate ?? false;
  const status = data?.document?.status ?? DocumentStatus.New;

  const { deleteDocument } = useDeleteDocument(documentId);

  const handleDeleteDocument = async () => {
    if (!documentId) {
      return;
    }

    const { status } = await deleteDocument?.();
    if (status === 'success') {
      onDeleteDocument?.();
    }
  };

  const handleCompareDocument = () => {
    if (!organizationSlug || !documentId || !originalDocumentId) {
      return;
    }

    const pathname = generatePath(
      `/${organizationSlug}/${compiledRoutes.resolveDuplicateRoute}`,
      {
        documentId,
        originalDocumentId,
        transactionId: transactionId ?? null,
      }
    );

    const newSearchParams = new URLSearchParams(searchParams);

    const cursor = newSearchParams.get(
      DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR
    );

    if (cursor) {
      // rename 'cursor' to 'documentCursor', keeping any other params intact
      newSearchParams.append(DUPLICATE_SEARCH_PARAMS.DOCUMENT_CURSOR, cursor);
      newSearchParams.delete(DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR);
    }

    navigate({ pathname, search: newSearchParams.toString() });
  };

  const handleCancelEdit = () => {
    if (!organizationSlug) return;
    if (!documentId) return;

    const pathname = generatePath(
      `/${organizationSlug}/${Routes.APPROVALS}/:documentId?`,
      { documentId }
    );

    navigate({ pathname, search: searchParams.toString() });
    onCancelEdit?.();
  };

  let mode: ProcessingFormActionsProps['mode'];
  switch (status) {
    case DocumentStatus.New: {
      mode = isDuplicate ? 'duplicate' : 'request';
      break;
    }

    case DocumentStatus.Rejected: {
      mode = 're-request';
      break;
    }

    case DocumentStatus.Open: {
      mode = 'edit-request';
      break;
    }
  }

  return !loading ? (
    <ProcessingFormActions
      mode={mode}
      onApprove={onApprove}
      onCancelEdit={handleCancelEdit}
      onCompareWithOriginal={handleCompareDocument}
      onDeleteDocument={handleDeleteDocument}
    />
  ) : (
    // @TODO proper skelly
    <Skeleton width="100%" height="space40" />
  );
};
