import { Avatar, AvatarStack, Box, Grid, Text } from '@candisio/design-system';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import {
  Collaborator,
  useSubscribeDocumentCollabs,
} from 'views/DocumentDetails/hooks/useSubscribeDocumentCollabs';

const StyledGrid = styled(Grid)`
  & > * {
    grid-area: 1 / 1;
  }
`;

const MotionBox = motion(Box);

interface CollaboratorsContainerProps {
  children: ReactNode;
  hasLoaded: boolean;
  documentId?: string;
}

export const CollaboratorsContainer = ({
  children,
  documentId,
  hasLoaded,
}: CollaboratorsContainerProps) => {
  const { mappedViewers } = useSubscribeDocumentCollabs({
    documentId,
  });

  const collaborators = mappedViewers;

  return (
    <StyledGrid borderRadius="medium" position="relative" overflow="hidden">
      <AnimatePresence>
        {hasLoaded && collaborators.length ? (
          <MotionBox
            alignSelf="start"
            justifySelf="center"
            background="green700"
            borderTopRadius="none"
            borderBottomRadius="large"
            padding="space5 space12"
            zIndex={1}
            initial={{
              y: '-100%',
            }}
            animate={{
              y: '0%',
            }}
            exit={{
              y: '-100%',
            }}
            transition={{
              duration: 0.5,
            }}>
            <Collaborators collaborators={collaborators} />
          </MotionBox>
        ) : null}
      </AnimatePresence>
      {children}
    </StyledGrid>
  );
};

interface CollaboratorsProps {
  collaborators: Collaborator[];
}

const Collaborators = ({ collaborators }: CollaboratorsProps) => {
  const [t] = useTranslation();

  return (
    <Grid autoFlow="column" justifyContent="center" alignItems="center">
      <AvatarStack size="small" limit={4}>
        {collaborators.map(({ membership: { avatarUrl, id, name } }) => (
          <Avatar key={id} name={name} size="small" img={avatarUrl} />
        ))}
      </AvatarStack>
      <Text color="white">
        {t('document.collaborators.info', {
          count: collaborators.length,
        })}
      </Text>
    </Grid>
  );
};
