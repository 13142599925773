import { EcmDocumentExtractedContact } from 'generated-types/graphql.types';
import { StorageFormExtractedContactFieldItem } from './StorageFormContactField';

/** Maps `EcmExtractedContact` to `StorageFormExtractedContactFieldItem` */
export const toEcmExtractedContactFieldItem = (
  contact: Partial<EcmDocumentExtractedContact>
): StorageFormExtractedContactFieldItem => {
  return {
    children: contact.name ?? undefined,
    createTransfer: contact.automaticPayment
      ? !contact.automaticPayment
      : undefined,
    iban: contact.iban ?? undefined,
    bankAccountNumber: contact.bankAccountNumber ?? undefined,
    swiftCode: contact.swiftCode ?? undefined,
    taxNumber: contact.taxNumber ?? undefined,
    vatId: contact.vatId ?? undefined,
    email: contact.email ?? undefined,
    phoneNumber: contact.phoneNumber ?? undefined,
    street: contact.street ?? undefined,
    city: contact.city ?? undefined,
    postalCode: contact.postalCode ?? undefined,
    postOfficeBox: contact.postOfficeBox ?? undefined,
    countryISOCode: contact.country ?? undefined,
    customerNumber: contact.customerNumber ?? undefined,
  };
};
