import {
  useDocumentDataExtractionSettingsQuery,
  useUpdateDocumentDataExtractionSettingsMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { documentDataExtractionsSettingsQuery } from 'views/Settings/DocumentDataExtractions/gql';

export const useDocumentDataExtractionSettings = () => {
  const { data: documentDataExtractionSettings } =
    useDocumentDataExtractionSettingsQuery();

  const [updateSettings, { loading }] =
    useUpdateDocumentDataExtractionSettingsMutation();

  const switchOutgoingInvoiceExtraction = useCallback(
    (enable?: boolean) => {
      return updateSettings({
        variables: {
          settings: {
            suggestOutgoingInvoices: Boolean(enable),
          },
        },
        refetchQueries: [{ query: documentDataExtractionsSettingsQuery }],
      });
    },
    [updateSettings]
  );

  return {
    isOutgoingInvoiceExtractionEnabled:
      documentDataExtractionSettings?.documentDataExtractionSettings
        ?.suggestOutgoingInvoices,
    switchOutgoingInvoiceExtraction,
    loading,
  };
};
