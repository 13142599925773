import { Box, Grid, Icon, Link, Text } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId, useUserId } from 'providers/OrganizationProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateCreditCardModalState } from '../CreateCreditCardModalDataProvider';
import { getSlideInVariants } from '../utils';

type Action = 'requesting' | 'issuingToCardholder' | 'issuingToCurrentUser';
type HeadlineContent = Record<
  Action,
  { title: string; description?: string; link: ReactNode }
>;

export const variants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.2,
      delay: 0.5,
      ease: 'easeInOut',
    },
  },
  hidden: { x: 100, opacity: 0, ease: 'easeInOut' },
};

const MotionBox = motion(Box);

export const SuccessStepHeadline = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const orgId = useOrganizationId();
  const membershipId = useUserId();
  const { isCardManager } = useUserRoles();
  const { createdCreditCardId, teamMemberId } = useCreateCreditCardModalState();

  const { animateFromLeft } = getSlideInVariants();

  const isCardOwnedByCardManager =
    isCardManager && membershipId === teamMemberId;

  const dashboardView = `/${orgId}${Routes.DASHBOARD}?cardId=${createdCreditCardId}`;
  const cardManagerView = `/${orgId}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/${createdCreditCardId}?onCreditCardsView=true`;

  const action: Action = !isCardManager
    ? 'requesting'
    : isCardOwnedByCardManager
    ? 'issuingToCurrentUser'
    : 'issuingToCardholder';

  const headlineContent: HeadlineContent = {
    issuingToCurrentUser: {
      title: 'successPage.cardManager.title',
      link: (
        <Link href={dashboardView} fontSize="basic" external>
          {t('successPage.cardManager.linkText')}
        </Link>
      ),
    },
    requesting: {
      title: 'successPage.cardholder.title',
      description: 'successPage.cardholder.description',
      link: (
        <Link href={dashboardView} fontSize="basic" external>
          {t('successPage.cardholder.linkText')}
        </Link>
      ),
    },
    issuingToCardholder: {
      title: 'successPage.cardManagerToCardholder.title',
      description: 'successPage.cardManagerToCardholder.description',
      link: (
        <Link href={cardManagerView} fontSize="basic" external>
          {t('successPage.cardManagerToCardholder.linkText')}
        </Link>
      ),
    },
  };

  const headline = headlineContent[action];

  return (
    <MotionBox
      animate="visible"
      variants={animateFromLeft}
      initial={animateFromLeft.hidden}>
      <Grid placeContent="center" placeItems="center" gap="space32">
        <Grid templateColumns="auto 1fr" gap="space4" width="space256">
          <Icon icon="checkCircle" color="green500" size="space32" />
          <Grid placeItems="center" gap="space20">
            <Grid gap="space16">
              <Text fontSize="xlarge" fontWeight="semibold">
                {t(headline.title)}
              </Text>
              {headline.description && (
                <Text fontSize="large" width="300px">
                  {t(headline.description)}
                </Text>
              )}
            </Grid>
            <MotionBox
              justifySelf="start"
              animate="visible"
              variants={variants}
              initial={variants.hidden}>
              {headline.link}
            </MotionBox>
          </Grid>
        </Grid>
      </Grid>
    </MotionBox>
  );
};
