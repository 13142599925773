import gql from 'graphql-tag';

export const costCentersGetForDuplicationCheck = gql`
  query costCentersGetForDuplicationCheck(
    $costCenterCodes: [String!]!
    $costCenterType: CostCenterTypes!
  ) {
    costCentersGetForDuplicationCheck(
      costCenterCodes: $costCenterCodes
      costCenterType: $costCenterType
    )
  }
`;

export const bulkImportCostCentersMutation = gql`
  mutation bulkImportCostCenters(
    $input: [CreateCostCenterInput!]!
    $fileId: String
  ) {
    bulkImportCostCenters(input: $input, fileId: $fileId) {
      imported
      duplicates
      errors
    }
  }
`;

export const costCenterRequestImportFileUploadURLMutation = gql`
  mutation costCenterRequestImportFileUploadURL(
    $fileName: String!
    $contentType: String!
  ) {
    costCenterRequestImportFileUploadURL(
      fileName: $fileName
      contentType: $contentType
    ) {
      id
      url
      postOptions {
        field
        value
      }
    }
  }
`;
