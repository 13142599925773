import { DocumentsTableData } from 'components/DocumentsTable/types';
import { mapDocumentStatusToStatus } from 'components/DocumentViewer/AttachmentsTable/utils';
import {
  DocumentCurrency,
  DocumentEdge,
  DocumentStatus,
  InvoiceAssociationStatus,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';

export const mapToDocumentsTableData = (edge: DocumentEdge) => {
  const amount = edge.node.amount?.value;
  const currency = edge.node.currency?.value;
  const grossAmount = amount && currency ? { amount, currency } : undefined;
  const documentTableData: DocumentsTableData = {
    id: edge.node.id,
    invoiceNumber: edge.node.invoiceNumber?.value,
    grossAmount,
    fileName: edge.node.documentFile?.name,
    contact:
      edge.node.contact?.value.name.value ??
      edge.node.extractedContact?.name?.value,
    status: {
      status: mapDocumentStatusToStatus(edge.node.status),
      isOverdue: !!edge.node.isOverdue,
      isDuplicate: !!edge.node.isDuplicate,
    },
    invoiceDate: edge.node.invoiceDate?.value
      ? new Date(edge.node.invoiceDate?.value)
      : undefined,
  };

  return documentTableData;
};

export interface SelectionOption {
  selectedDocument: DocumentsTableData | null;
  onSelectionChanged: (transaction: DocumentsTableData) => void;
}

export const generateDocumentWithNoTransactionEdge = (
  id: string,
  documentStatus?: DocumentStatus
) => ({
  amount: {
    value: 210.97,
  },
  contact: {
    value: {
      id: `6262665720486d6139667d30${id}`,
      name: {
        value: `Amazon Services Europe S.à r.l.${id}`,
      },
    },
  },
  createdAt: '2022-08-29T09:01:16.886Z',
  currency: {
    value: DocumentCurrency.Eur,
  },
  extractedContact: {
    cachingKey: `6262665720486d6139667d30${id}`,
    name: {
      value: `Amazon Services Europe S.à r.l.${id}`,
    },
  },
  hasAttachments: false,
  invoiceDeliveryDate: {
    value: '2019-11-29T23:00:00.000Z',
  },
  id,
  invoiceNumber: {
    value: 'LU-ASE-2019-7194211',
  },
  isDuplicate: true,
  isOverdue: true,
  status: documentStatus ?? DocumentStatus.New,
});

export const generateMockTransaction = (id: string) => ({
  cardName: null,
  cardRefNum: '2442',
  transactionAmount: {
    currency: DocumentCurrency.Eur,
    precision: 2,
    value: -5400,
  },
  billingAmount: {
    currency: DocumentCurrency.Eur,
    precision: 2,
    value: -5400,
  },
  documents: [],
  id,
  invoiceAssociationStatus: InvoiceAssociationStatus.Missing,
  localizedDeclineReason: null,
  merchantName: 'Aldi',
  member: {
    avatarUrl: null,
    firstName: 'Francesco',
    lastName: 'S',
  },
  status: TransactionStatus.Confirmed,
  transactionCreatedAt: '2022-07-20T09:37:00.765Z',
  type: TransactionType.Purchase,
  timeline: [],
  invoiceNotNeeded: null,
});
