import { useArchiveOrDeleteReimbursementCaseMutation } from 'generated-types/graphql.types';

export const useDeleteReimbursement = () => {
  const [deleteReimbursement, { loading, client }] =
    useArchiveOrDeleteReimbursementCaseMutation({
      refetchQueries: ['reimbursementCasesCount'],
      onCompleted: () => {
        client.cache.evict({ fieldName: 'reimbursementCasesPageBased' });
        client.cache.evict({ fieldName: 'reimbursementCasesCursorBased' });
      },
    });

  const handleDeleteReimbursement = async (id: string) => {
    const { data, errors } = await deleteReimbursement({
      variables: { id },
    });

    const { deleted } = data?.archiveOrDeleteReimbursementCase ?? {};

    if (errors?.length || !deleted) {
      return 'error';
    }

    return 'success';
  };

  return {
    deletePending: loading,
    deleteReimbursement: handleDeleteReimbursement,
  };
};
