import { Grid, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useExportContext } from 'views/Integrations/Export/Context';
import { SummaryAccordionCard } from 'views/Integrations/Export/toolkit/components/SummaryAccordionCard';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { DocumentType } from 'views/Integrations/Export/types';

export const CandisApiExportSummary = () => {
  const [t] = useTranslation();

  const state = useExportContext();

  const { readyToExportEntitiesCountByType, isLoading, isReExport } =
    useExportDataGigaFactory(state);

  const totalApprovalsCount =
    readyToExportEntitiesCountByType[DocumentType.Document] ?? 0;

  const summaryTitle = isReExport
    ? t('export.exportSummary.exportedViaAPI')
    : t('export.exportSummary.canBeExportedViaAPI');

  const approvalsSummary = [
    {
      title: t('export.exportSummary.approvalsCount', {
        count: totalApprovalsCount,
      }),
      content: '',
    },
  ];

  return (
    <Grid gap="space12">
      <Text>{summaryTitle}</Text>
      <SummaryAccordionCard
        items={approvalsSummary}
        loading={isLoading.loadingCountsExportableEntities}
        collapsable={false}
      />
    </Grid>
  );
};
