import { Flex, Grid } from '@candisio/design-system';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';
import { useShowInvoiceCorrectionField } from 'views/utils/useShouldShowField';
import { useBookingsFieldArrayContext } from './BookingsFieldArrayContext';
import { ProcessingFormFieldOptions } from './ProcessingForm';
import { ProcessingFormAmountField } from './ProcessingFormAmountField';
import { ProcessingFormComboBoxField } from './ProcessingFormComboBoxField';
import { ProcessingFormInvoiceCorrectionField } from './ProcessingFormInvoiceCorrectionField';
import { ProcessingFormPercentField } from './ProcessingFormPercentField';

const FLEX = '1 0 6.1rem';
const FLEX_CURRENCY = '1 0 67px';
const FLEX_VAT = '1 0 68px';

export interface ProcessingFormAmountFieldsProps {
  /** Individual field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /** Display fields as read only */
  readOnly?: boolean;
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
}

/**
 * Displays amount-related fields in the processing form:
 * - invoice correction
 * - gross amount
 * - currency
 * - VAT rate
 *
 * VAT rate is only displayed when there is a single booking.
 */
export const ProcessingFormAmountFields = ({
  fieldOptions,
  readOnly,
  ...restProps
}: ProcessingFormAmountFieldsProps) => {
  const [t] = useTranslation();

  const { shouldUseSapNetAmount } = useSap();
  const bookingsFieldArray = useBookingsFieldArrayContext();
  const splits = bookingsFieldArray?.fields ?? [];

  const showInvoiceCorrectionField = useShowInvoiceCorrectionField();

  return (
    <Grid gap="space16">
      {showInvoiceCorrectionField && (
        <ProcessingFormInvoiceCorrectionField
          name="grossAmount"
          label={t('document.requestApproval.inputs.invoiceCorrection.label')}
          readOnly={readOnly}
          {...restProps}
        />
      )}
      <Flex columnGap="space8" rowGap="space16" wrap="wrap">
        <ProcessingFormAmountField
          flex={FLEX}
          name="grossAmount"
          label={t('document.requestApproval.inputs.amount.label')}
          placeholder={t('document.requestApproval.inputs.amount.placeholder')}
          readOnly={readOnly}
          {...restProps}
        />
        <ProcessingFormComboBoxField
          flex={FLEX_CURRENCY}
          name="currency"
          label={t('document.requestApproval.inputs.currency.label')}
          placeholder={t(
            'document.requestApproval.inputs.currency.placeholder'
          )}
          clearable={false}
          {...fieldOptions?.currency}
          readOnly={readOnly || fieldOptions?.currency?.readOnly}
          {...restProps}
        />
        {splits.length === 1 && (
          <ProcessingFormPercentField
            // key is required by React Hook Form to trigger rerenders
            key={splits[0].id}
            flex={FLEX_VAT}
            name="bookings.0.vatRate"
            label={t('document.requestApproval.inputs.vatRate.label')}
            maxValue={99.99}
            minValue={0}
            placeholder={t(
              'document.requestApproval.inputs.vatRate.placeholder'
            )}
            readOnly={readOnly}
            {...restProps}
          />
        )}
      </Flex>
      {shouldUseSapNetAmount && (
        <ProcessingFormAmountField
          flex={FLEX}
          name="roundingAmount"
          label={t('document.requestApproval.inputs.roundingAmount.label')}
          placeholder={t(
            'document.requestApproval.inputs.roundingAmount.placeholder'
          )}
          readOnly
          {...restProps}
        />
      )}
    </Grid>
  );
};
