export const useCalculateResponsiveFontAmount = () => {
  const baseFontSize = 16;
  const minRemAmount = 1.125;
  const minPxAmount = minRemAmount * baseFontSize;
  const maxBreakPoint = 1920;
  const minBreakPoint = 1350;
  const maxRemAmount = 2.25;
  const maxPxAmount = maxRemAmount * baseFontSize;

  // This calculates the dynamic mean value (preferred value) between min and max
  // See here for further explanation: https://www.aleksandrhovhannisyan.com/blog/fluid-type-scale-with-css-clamp/
  const slope = (maxPxAmount - minPxAmount) / (maxBreakPoint - minBreakPoint);
  const interceptPx = minPxAmount - slope * minBreakPoint;
  const interceptRem = interceptPx / baseFontSize;
  const slopeVw = slope * 100;
  const preferredValue = `${slopeVw}vw + ${interceptRem}rem`;

  return `clamp(${minRemAmount}rem, calc(${preferredValue}), ${maxRemAmount}rem)`;
};
