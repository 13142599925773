import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useCurrentUser } from 'providers/CurrentUserProvider';

interface UseGetTableConfigurationsParams {
  tableId: string;
  defaultColumnsConfigurations: Configuration[];
}

export const useGetTableConfigurations = ({
  tableId,
  defaultColumnsConfigurations,
}: UseGetTableConfigurationsParams) => {
  const user = useCurrentUser();

  const allTablesConfigurations = user?.preferences?.tables ?? [];

  const currentTableConfigurations =
    allTablesConfigurations.filter(config => config.id === tableId) ?? [];

  const defaultColumnIds = new Set(
    defaultColumnsConfigurations.map(config => config.id)
  );

  const mappedConfigurations: Configuration[] = (
    currentTableConfigurations?.[0]?.columns ?? []
  )
    .filter(config => defaultColumnIds.has(config.id))
    .map(config => ({
      id: config.id,
      label: config.label,
      ...(config.isFixed === true
        ? { isFixed: true, isVisible: true }
        : { isVisible: config.isVisible }),
    }));

  const configurations = mappedConfigurations.length
    ? mappedConfigurations
    : defaultColumnsConfigurations;

  return { configurations };
};
