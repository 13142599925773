import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
// eslint-disable-next-line no-restricted-imports
import qs from 'query-string';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useLocation } from 'react-router-dom';
import { usePath } from 'utils/hooks';
import { NoActiveDataOtherIntegration } from 'views/Settings/TaxCodes/components/NoTaxCodes/NoActiveDataOtherIntegration';
import { TAX_CODES_SEARCH_PARAM } from '../../consts';
import { NoActiveData } from './NoActiveData';
import { NoActiveDataSap } from './NoActiveDataSap';
import { NoArchivedData } from './NoArchivedData';
import { NoFilteredData } from './NoFilteredData';

const availableBookingKeysFilters = [
  TAX_CODES_SEARCH_PARAM.bookingKey,
  TAX_CODES_SEARCH_PARAM.description,
  TAX_CODES_SEARCH_PARAM.sort,
];

type Props = {
  onCreate: () => void;
  hasArchivedTaxCodes?: boolean;
  hasActiveTaxCodes?: boolean;
};

export const NoTaxCodes = ({
  onCreate,
  hasArchivedTaxCodes,
  hasActiveTaxCodes,
}: Props) => {
  const { search } = useLocation();
  const { shouldUseSapTaxCodes } = useSap();

  const { shouldUseCoreDataApiTaxCodes } = useOtherIntegration();

  const {
    queryParams: { [TAX_CODES_SEARCH_PARAM.archive]: isArchiveTab },
  } = usePath<
    { bookingKeyId?: string },
    {
      [TAX_CODES_SEARCH_PARAM.archive]?: boolean;
    }
  >();

  const query = qs.parse(search);
  const hasActiveFilter = availableBookingKeysFilters
    .filter(prop => prop !== 'sort')
    .some(prop => !!query[prop]);

  if (hasActiveFilter) {
    return <NoFilteredData />;
  }

  if (isArchiveTab) {
    return <NoArchivedData />;
  }

  const noTaxCodesYet = !hasArchivedTaxCodes && !hasActiveTaxCodes;
  if (noTaxCodesYet && shouldUseSapTaxCodes) {
    return <NoActiveDataSap />;
  }

  if (shouldUseCoreDataApiTaxCodes) {
    return <NoActiveDataOtherIntegration noTaxCodesYet={noTaxCodesYet} />;
  }

  return (
    <NoActiveData
      onCreate={onCreate}
      hasArchivedTaxCodes={hasArchivedTaxCodes}
    />
  );
};
