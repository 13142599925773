import { useCurrentUser } from 'providers/CurrentUserProvider';
import { ComponentType, useMemo } from 'react';
import { ExpenseDetailsCard } from '../components/MiddleSection/ExpenseDetailsCard';
import { ExpenseSectionWithAction } from '../components/MiddleSection/ExpenseSectionWithAction';
import { Reimbursement } from './useFormattedReimbursement';

export const useExpenseSectionComponent = (
  reimbursement?: Reimbursement
): ComponentType<any> => {
  const currentUserId = useCurrentUser()?.id ?? '';
  const { createdByMembership, status } = reimbursement ?? {};
  const canModifyExpenseDetails = currentUserId === createdByMembership?.id;

  const getExpenseSectionForStatus = useMemo(() => {
    switch (status) {
      case 'DRAFT':
        return ExpenseSectionWithAction;
      case 'CHECK':
        return canModifyExpenseDetails
          ? ExpenseSectionWithAction
          : ExpenseDetailsCard;
      default:
        return ExpenseDetailsCard;
    }
  }, [status, canModifyExpenseDetails]);

  return getExpenseSectionForStatus;
};
