import { CostCenterTypes } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { getTranslationContext } from 'orgConfig';

export const defaultTypeOptions = (t: i18next.TFunction) => [
  {
    value: CostCenterTypes.CostCenter,
    children: t(
      'settings.costCenter.wizardModal.fileSelectionStep.costCenter',
      {
        context: getTranslationContext(),
      }
    ),
  },
  {
    value: CostCenterTypes.CostObject,
    children: t(
      'settings.costCenter.wizardModal.fileSelectionStep.costObject',
      {
        context: getTranslationContext(),
      }
    ),
  },
];

export const typeOptionsWithExtraCostInfo = (t: i18next.TFunction) => [
  ...defaultTypeOptions(t),
  {
    value: CostCenterTypes.ExtraCostInfo,
    children: t(
      'settings.costCenter.wizardModal.fileSelectionStep.extraCostInfo'
    ),
  },
];
