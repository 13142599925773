import { Flex, Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ALPHA2_EUROPEAN_COUNTRIES } from './utils/consts';

interface BillingAddressProps {
  streetName: string | undefined;
  streetNumber: string | undefined;
  streetAdditional: string | undefined;
  postalCode: string | undefined;
  city: string | undefined;
  country: string | undefined;
}

export const BillingAddress = ({
  city,
  country,
  postalCode,
  streetAdditional,
  streetName,
  streetNumber,
}: BillingAddressProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid gap="space8" alignItems="start" fontSize="basic" alignContent="start">
      <Text fontWeight="semibold">
        {t(
          'insights.organizationSettingsDrawer.form.readOnlySettings.billingAddress'
        )}
      </Text>

      <Grid>
        <Grid>
          <Flex gap="space4">
            {streetName && <Text>{streetName}</Text>}
            {streetNumber && <Text>{streetNumber}</Text>}
          </Flex>
        </Grid>
        {streetAdditional && <Text>{streetAdditional}</Text>}
        <Flex gap="space4">
          {postalCode && <Text>{postalCode}</Text>}
          {city && <Text>{city}</Text>}
        </Flex>
        {country && <Text>{t(ALPHA2_EUROPEAN_COUNTRIES[country])}</Text>}
      </Grid>
    </Grid>
  );
};
