import gql from 'graphql-tag';

export const cardIssuerStatementsPageBasedQuery = gql`
  query getCreditCardsStatements(
    $input: CardIssuerPageBasedPaginationInput
    $filters: CardIssuerStatementsFilterInput
    $dateRangeFilters: CardIssuerStatementsDateFilterInput
    $sortBy: CardIssuerStatementsSortInput
  ) {
    cardIssuerStatementsPageBased(
      input: $input
      filters: $filters
      dateRangeFilters: $dateRangeFilters
      sortBy: $sortBy
    ) {
      records {
        id
        cardIssuer {
          isClosed
          statementPeriodStart
          statementPeriodEnd
          openingBalance {
            value
            precision
            currency
          }
          closingBalance {
            value
            precision
            currency
          }
          totalTransactionAmount {
            value
            precision
            currency
          }
          totalPaymentAmount {
            value
            precision
            currency
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const getCardIssuerSettlementsPageBasedQuery = gql`
  query cardIssuerSettlementsPageBased(
    $input: CardIssuerPageBasedPaginationInput
    $filters: CardIssuerSettlementsFiltersInput
    $sortBy: CardIssuerSettlementsSortInput
    $dateRangeFilters: CardIssuerSettlementsDateFilterInput
  ) {
    cardIssuerSettlementsPageBased(
      input: $input
      filters: $filters
      sortBy: $sortBy
      dateRangeFilters: $dateRangeFilters
    ) {
      records {
        id
        publicPaymentId
        method
        type
        status
        cutOffDate
        actualPaymentDate
        plannedPaymentDate
        paymentDate
        type
        paymentDefermentInDays
        paymentCreatedAt
        amount {
          value
          currency
          precision
        }
      }
      pageInfo {
        totalCount
        currentPage
        pageCount
        pageSize
      }
    }
  }
`;

export const getCardIssuerStatementQuery = gql`
  query cardIssuerStatement($id: ID!) {
    cardIssuerStatement(id: $id) {
      id
      hasPdfFile
      hasCsvFile
      pdfFile {
        url
        id
      }
      cardIssuer {
        isClosed
        statementPeriodStart
        statementPeriodEnd
        openingBalance {
          value
          precision
          currency
        }
        closingBalance {
          value
          precision
          currency
        }
        totalTransactionAmount {
          value
          precision
          currency
        }
        totalPaymentAmount {
          value
          precision
          currency
        }
      }
    }
  }
`;

export const generateCsvFileForStatementMutation = gql`
  mutation generateCsvFileForStatement(
    $input: GenerateCsvFileForStatementInput!
  ) {
    generateCsvFileForStatement(input: $input) {
      id
    }
  }
`;

export const cardIssuerStatementCsvFileQuery = gql`
  query cardIssuerStatementCsvFile($input: GetCsvFileForStatementInput!) {
    cardIssuerStatementCsvFile(input: $input) {
      id
      file {
        url
        id
        name
      }
      hasFile
    }
  }
`;
