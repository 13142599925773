import { Flex, Text } from '@candisio/design-system';
import { useNewDashboardFF } from 'components/NewPromotions/hooks/useNewDashboardFF';
import { User } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from './DateRangePicker/DateRangePicker';
import { applyDateRange } from './DateRangePicker/util';
import { NetGrossSelectorContainer } from './NetGrossSelector/NetGrossSelectorContainer';

type WidgetsHeaderProps = {
  currentUser: User | undefined;
};

export const WidgetsHeader = ({ currentUser }: WidgetsHeaderProps) => {
  const history = useHistory();
  const [tDashboard] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const { showNewDashboardPromotions } = useNewDashboardFF();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      paddingX={showNewDashboardPromotions ? undefined : 'space14'}>
      <Text fontSize="xlarge" fontWeight="regular">
        {tDashboard('insights.heading')}
      </Text>
      <Flex gap="space4">
        <DateRangePicker
          onApplyDateRange={applyDateRange(history, currentUser?.id)}
        />
        <NetGrossSelectorContainer />
      </Flex>
    </Flex>
  );
};
