import { useToastMessage } from 'components/Toast/useToastMessage';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { useExpenseFormsHelpers } from '../components/MiddleSection/utils/useExpenseFormsHelpers';
import { VIEW_URL_PARAM, VIEWS } from '../Reimbursement';
import { useCreateGeneralExpense } from './useCreateGeneralExpense';
import { useCreateHospitalityExpense } from './useCreateHospitalityExpense';
import { createGeneralDefaultValues } from './useInitialGeneralExpenseFormValues';
import { createHospitalityDefaultValues } from './useInitialHospitalityExpenseFormValues';

interface UseReimbursementMenuButtonActionsProps {
  formHelpers?: ReturnType<typeof useExpenseFormsHelpers>;
}

export const useReimbursementMenuButtonActions = ({
  formHelpers,
}: UseReimbursementMenuButtonActionsProps) => {
  const { t } = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { updateSearchParam } = useMutateSearchParams();

  const { error } = useToastMessage();
  const { createHospitality, createHospitalityPending } =
    useCreateHospitalityExpense();

  const { createGeneralExpense, isCreateGeneralExpensePending } =
    useCreateGeneralExpense();

  const { onAppendForm, onAppendGeneralExpense } = formHelpers ?? {};

  const handleCreateHospitalityExpense = useCallback(async () => {
    const expenseId = await createHospitality();

    if (!expenseId) {
      return;
    }

    const newForm = createHospitalityDefaultValues(expenseId);
    onAppendForm?.(newForm, { shouldFocus: false });
    updateSearchParam(VIEW_URL_PARAM, VIEWS.Expenses);
    scrollToTarget(expenseId);
  }, [createHospitality, onAppendForm, updateSearchParam]);

  const handleCreateGeneralExpense = useCallback(async () => {
    const { status, generalExpenseId } = await createGeneralExpense(
      reimbursementId
    );

    if (status === 'success' && generalExpenseId) {
      const defaultValuesNewForm = createGeneralDefaultValues(generalExpenseId);
      onAppendGeneralExpense?.(defaultValuesNewForm, { shouldFocus: false });
      updateSearchParam(VIEW_URL_PARAM, VIEWS.Expenses);
      scrollToTarget(generalExpenseId);
    } else {
      error(t('reimbursementView.createErr'));
    }
  }, [
    createGeneralExpense,
    error,
    onAppendGeneralExpense,
    reimbursementId,
    t,
    updateSearchParam,
  ]);

  const isCreatingExpenseItem =
    createHospitalityPending || isCreateGeneralExpensePending;

  return {
    handleCreateHospitalityExpense,
    handleCreateGeneralExpense,
    isCreatingExpenseItem,
  };
};
