import {
  Card,
  Flex,
  Grid,
  Text,
  Button,
  Icon,
  Image,
} from '@candisio/design-system';
import { Routes } from 'models';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { CCWalletTrackingEvents } from 'providers/AnalyticsProvider/events/CCWalletTrackingEvents';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useUserRoles } from '../../../../../hooks/useUserRoles';
import creditCards from '../media/mini card.png';

const bulletListItems = [
  'ccRequestState.activeRequest.listItem1',
  'ccRequestState.activeRequest.listItem2',
  'ccRequestState.activeRequest.listItem3',
];

export const ActiveCreditCardRequestBanner = ({
  showTitle,
}: {
  showTitle?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const { track } = useAnalytics();
  const { isCardManager } = useUserRoles();

  const openRequestCardDrawer = () => {
    navigate({
      pathname: `/${organizationId}${Routes.DASHBOARD_CREDIT_CARD}/request`,
    });

    track(CCWalletTrackingEvents.REQUEST_CARD_BTN_CLICKED, {
      organizationId,
      path: 'first time requester opens cc request drawer',
    });
  };

  return (
    <Grid width="fit-content" gap="space8">
      {showTitle && (
        <Text fontSize="xlarge">{t('ccInformationalCard.headerTitle')}</Text>
      )}
      <Card background="gray50">
        <Grid left="-36px" autoFlow="column" alignItems="start" gap="space32">
          <Image src={creditCards} alt="cards" height="300px" />
          <Grid gap="space16">
            <Flex
              direction="column"
              gap="space8"
              justifyContent="space-between">
              <Text fontSize="xxlarge" lineHeight="paragraph">
                {isCardManager
                  ? t('ccRequestState.activeRequest.cardManagerTitle')
                  : t('ccRequestState.activeRequest.mainTitle')}
              </Text>
              <Text fontSize="basic">
                {isCardManager
                  ? t('ccRequestState.activeRequest.cardManagerDescription')
                  : t('ccRequestState.activeRequest.description')}
              </Text>
            </Flex>
            <Flex direction="column" gap="space32">
              <Flex
                as="ul"
                direction="column"
                gap="space8"
                listStyle="none"
                padding="0px">
                {bulletListItems.map((listItem, index) => (
                  <li key={index}>
                    <Grid
                      templateColumns="auto 1fr"
                      gap="space8"
                      alignItems="center">
                      <Icon icon="check" color="blue500" size="space24" />
                      <Text fontSize="basic">{t(listItem)}</Text>
                    </Grid>
                  </li>
                ))}
              </Flex>
              <Button onClick={openRequestCardDrawer} alignSelf="start">
                {isCardManager
                  ? t('ccRequestState.activeRequest.issueCardCtaButton')
                  : t('ccRequestState.activeRequest.requestCardCtaButton')}
              </Button>
            </Flex>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
