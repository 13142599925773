import { Flex, Link, Text } from '@candisio/design-system';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { CandisApiImage } from 'views/Integrations/Export/toolkit/components/common';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';

export const CandisApiExportInfoCard = () => {
  const [t] = useTranslation();

  const orgId = useOrganizationId();

  const settingsUrl = `/${orgId}${Routes.SETTINGS}${Routes.CANDIS_API}`;

  const history = useHistory();

  return (
    <Flex direction="column" justifyContent="space-between" gap="space24">
      <ExportInfo>
        <CandisApiImage />
        <Flex
          padding="space8"
          alignItems="center"
          gap="space4"
          justifyContent="center"
          direction="column">
          <Text as="h4">
            {t('common:export.exportForm.exportType.CANDIS_API')}
          </Text>
          <Link
            external
            variant="primary"
            color="blue"
            width="fit-content"
            onClick={() => {
              history.push(settingsUrl);
            }}>
            {t('settings:candisAPI.settingsLinkLabel')}
          </Link>
        </Flex>
      </ExportInfo>
    </Flex>
  );
};
