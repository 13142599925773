import { useUpdatePaymentConditionMutation } from 'generated-types/graphql.types';
import { paymentConditionsQuery } from '../gql';
import { PaymentConditionsFormOutput } from './PaymentConditionsForm';

export interface UseUpdatePaymentConditionOptions {
  id?: string;
}

type UpdateReturn = {
  status: 'success' | 'error';
};

export interface UseUpdatePaymentConditionReturn {
  update: (values: PaymentConditionsFormOutput) => Promise<UpdateReturn>;
  updating: boolean;
}

/**
 * Returns a callback that updates a payment condition from
 * PaymentConditionsFormOutput
 */
export const useUpdatePaymentCondition = ({
  id,
}: UseUpdatePaymentConditionOptions): UseUpdatePaymentConditionReturn => {
  const [updatePaymentCondition, { loading: updating }] =
    useUpdatePaymentConditionMutation({
      refetchQueries: [
        {
          query: paymentConditionsQuery,
          variables: { filter: { isArchived: false } },
        },
      ],
    });

  return {
    update: async values => {
      if (id === undefined) {
        return { status: 'error' };
      }

      const { data } = await updatePaymentCondition({
        variables: {
          input: {
            id,
            paymentConditionNumber: values.conditionNumber,
            dueDateOffset: values.dueDateOffset,
            discountOffset: values.discountOffset,
            discountPercentage: values.discountPercentage,
          },
        },
      });

      if (data?.updatePaymentCondition.__typename === 'PaymentCondition') {
        return { status: 'success' };
      } else {
        return { status: 'error' };
      }
    },
    updating,
  };
};
