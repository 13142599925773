import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { InfoCard } from './InfoCard';

interface ActiveCardsValue {
  value: number;
}

export const ActiveCards = ({ value }: ActiveCardsValue) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isCardManager } = useUserRoles();
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  const handleClick = () => {
    const linkToDeclinedTransactions = `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`;
    navigate({
      pathname: linkToDeclinedTransactions,
    });
  };

  return (
    <InfoCard
      value={value}
      text={t('insights.requestsOverview.cardsInUse', { count: value })}
      color="gray800"
      onClick={isCardManager ? handleClick : undefined}
      minWidth="120px"
    />
  );
};
