import { Grid, Text, useId } from '@candisio/design-system';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { UserRole } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { isOnlyRequester, isOnlyAccountant } from 'utils/authorization';
import {
  ABSENCE_NOTE_MAX_LENGTH,
  AbsenceFormOutput,
} from './absenceFormSchema';
import { DatePickerFields } from './DatePickerFields';
import { SubstituteSelectorContainer } from './SubstituteSelectorContainer';

export interface AbsenceFormProps {
  userId: string | undefined;
  userRoles: UserRole[] | undefined | null;
}

export const AbsenceForm = ({ userId, userRoles }: AbsenceFormProps) => {
  const [t] = useTranslation();

  const noteId = useId();

  return (
    <Grid gap="space16">
      <DatePickerFields />
      <SubstituteSelectorContainer<AbsenceFormOutput>
        name="substitute"
        readOnly={
          isOnlyRequester({ roles: userRoles }) ||
          isOnlyAccountant({ roles: userRoles })
        }
        userId={userId}
      />
      <Grid gap="space4">
        <Text as="label" htmlFor={noteId} fontSize="basic">
          {t('header.profile.tabs.update.absence.form.note.label')}
        </Text>
        <HookFormTextareaField
          name="note"
          id={noteId}
          placeholder={t(
            'header.profile.tabs.update.absence.form.note.placeholder'
          )}
          maxLength={ABSENCE_NOTE_MAX_LENGTH}
        />
      </Grid>
    </Grid>
  );
};
