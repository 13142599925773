import { Flex } from '@candisio/design-system';
import { usePromotionsContext } from 'components/NewPromotions/context/PromotionsProvider';
import { Promotion } from 'components/NewPromotions/types';
import { motion } from 'framer-motion';

const imageVariants = {
  initial: { opacity: 0.5 },
  animate: { opacity: 1 },
  exit: { opacity: 0.5 },
};

export const PromotionImages = ({ promotion }: { promotion: Promotion }) => {
  const { benefits, contents } = promotion;
  const { currentBenefitIndex } = usePromotionsContext();

  let src;
  if (benefits) src = benefits?.[currentBenefitIndex]?.imageUrl;
  if (contents) src = contents.imageUrl;

  if (src === undefined) return null;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexGrow="1"
      width="50%"
      height="100%">
      <motion.img
        key={src}
        src={src}
        alt="promotion"
        style={{ objectFit: 'cover', inset: 0 }}
        height="100%"
        width="100%"
        variants={imageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.75 }}
        draggable={false}
      />
    </Flex>
  );
};
