import {
  fetchPolicy,
  FilterOption,
  PaginationWithSearchFilterHook,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  AccountsPayableNumberInfiniteScrollPaginationQuery,
  AccountsPayableNumberInfiniteScrollPaginationQueryVariables,
  ContactSortField,
  SortDirection,
  useContactsPaginationQuery,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { isNil, uniqBy } from 'lodash';
import { useCallback } from 'react';
import { accountsPayableNumberInfiniteScrollPaginationQuery } from '../utils/queries';
import { useIsContactsPageWithArchivedTab } from './useIsContactsPageWithArchivedTab';

export const useAccountsPayableNumberFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const { friendlyFormatAccountsNumber } = useAccountingNumberFormatters();
  const isArchivedContactTable = useIsContactsPageWithArchivedTab();

  const computeVariables = useCallback(
    (
      inputString?: string
    ): AccountsPayableNumberInfiniteScrollPaginationQueryVariables => ({
      queries: {
        accountsPayableNumber: inputString?.replaceAll(' ', ''),
      },
      filters: {
        isArchived: isArchivedContactTable,
      },
      sortBy: {
        direction: SortDirection.Asc,
        field: ContactSortField.AccountsPayableNumber,
      },
    }),
    [isArchivedContactTable]
  );

  const {
    data: allContacts,
    loading: allContactsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    AccountsPayableNumberInfiniteScrollPaginationQuery,
    AccountsPayableNumberInfiniteScrollPaginationQueryVariables
  >({
    query: accountsPayableNumberInfiniteScrollPaginationQuery,
    queryRootKey: 'accountsPayableNumberPagination',
    computeVariables,
  });

  const { data: selectedContacts } = useContactsPaginationQuery({
    variables: {
      input: { page: 1, limit: filteredValues.length },
      filters: { accountsPayableNumber: filteredValues },
      sortBy: {
        direction: SortDirection.Asc,
        field: ContactSortField.AccountsPayableNumber,
      },
    },
    fetchPolicy,
    skip: filteredValues.length === 0 || Boolean(searchStr),
  });

  const byIds = selectedContacts?.contactsPagination.records ?? [];
  const byAccountsPayableNumber =
    allContacts?.accountsPayableNumberPagination.records ?? [];

  const filterOptions: FilterOption[] = [...byIds, ...byAccountsPayableNumber]
    .filter(contact => Boolean(contact.accountsPayableNumber))
    .map(contact => ({
      label: !isNil(contact.accountsPayableNumber)
        ? friendlyFormatAccountsNumber(String(contact.accountsPayableNumber))
        : '',
      id: !isNil(contact.accountsPayableNumber)
        ? String(contact.accountsPayableNumber)
        : '',
    }));

  return {
    filterOptions: uniqBy(filterOptions, 'id'),
    handleDebounceSearch,
    loading: allContactsLoading,
    loadMore,
  };
};
