import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useReimbursementPageBasedData } from '../hooks/useReimbursementPageBasedData';
import { OpenReimbursementList } from './OpenReimbursementList';

// Open reimbursement statuses
export const openReimbursementStatuses = [
  ReimbursementCaseStatus.Approving,
  ReimbursementCaseStatus.Check,
  ReimbursementCaseStatus.Draft,
  ReimbursementCaseStatus.Review,
];

export const OpenReimbursementListContainer = () => {
  const currentUserId = useCurrentUser()?.id ?? '';
  const { loading, reimbursementList, totalReimbursements, loadMore } =
    useReimbursementPageBasedData({
      filters: {
        statuses: openReimbursementStatuses,
        targetMembershipIds: [currentUserId],
      },
    });

  return (
    <OpenReimbursementList
      isLoading={loading}
      onEndReached={loadMore}
      reimbursements={reimbursementList}
      reimbursementsCount={totalReimbursements}
    />
  );
};
