import {
  CostCenterInfiniteScrollPaginationQuery,
  CostCenterInfiniteScrollPaginationQueryVariables,
  CostCenterSortField,
  CostCenterTypes,
  SortDirection,
  useCostCenterPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { costCenterInfiniteScrollPagination } from 'views/queries/cost-centers';

export const useCostObjectFieldOptions = ({
  selectedValues,
}: {
  selectedValues: string[];
}) => {
  const computeVariables = useCallback(
    (
      inputString?: string
    ): CostCenterInfiniteScrollPaginationQueryVariables => ({
      filters: {
        type: [CostCenterTypes.CostObject],
      },
      queries: {
        readableName: inputString,
      },
      sortBy: {
        direction: SortDirection.Asc,
        field: CostCenterSortField.Name,
      },
    }),
    []
  );

  const {
    data: allCostObjects,
    loading: allCostObjectsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    CostCenterInfiniteScrollPaginationQuery,
    CostCenterInfiniteScrollPaginationQueryVariables
  >({
    query: costCenterInfiniteScrollPagination,
    queryRootKey: 'costCenterPagination',
    computeVariables,
  });

  const { data: selectedCostCenters, loading: selectedCosterCentersLoading } =
    useCostCenterPaginationQuery({
      variables: {
        input: { page: 1, limit: selectedValues.length },
        filters: {
          id: selectedValues,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: selectedValues.length === 0,
    });

  const mappedCostObjects = allCostObjects?.costCenterPagination.records.map(
    item => ({
      id: item.id,
      name: item.readableName,
    })
  );

  const mappedSelectedCostObjects =
    selectedCostCenters?.costCenterPagination.records.map(item => ({
      id: item.id,
      name: item.readableName,
    }));

  return {
    defaultItems: mappedCostObjects,
    handleDebounceSearch,
    loading: allCostObjectsLoading || selectedCosterCentersLoading,
    loadMore,
    selectedCostObjectItems: mappedSelectedCostObjects,
  };
};
