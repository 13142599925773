import { usePaymentConditionNumbersQuery } from 'generated-types/graphql.types';

export const usePaymentConditions = () => {
  const { data } = usePaymentConditionNumbersQuery({
    variables: {
      filter: {},
    },
  });

  const existingPaymentConditionNumbers = data?.paymentConditions.map(
    ({ isArchived, paymentConditionNumber }) => ({
      isArchived: Boolean(isArchived),
      paymentConditionNumber,
    })
  );

  return {
    existingPaymentConditionNumbers,
  };
};
