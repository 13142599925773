import { getGqlSearchFromUrl } from 'components/DocumentsTable/Filters/filters';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { mapToDocumentDateFilterInput } from 'views/utils/mapToDocumentDateFilterInput';
import { mapToDocumentFilterInput } from 'views/utils/mapToDocumentFilterInput';
import { mapToDocumentSortInput } from 'views/utils/mapToDocumentSortInput';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { availableFilters } from '../consts';

export const useComputeQueryFilters = () => {
  const { filters, sortBy } = useUrlBasedSortAndFilter<DocumentsTableData>({
    availableFilters,
  });

  const pageSize = getApproximateNumberOfRowForTable();
  const gqlQuery = getGqlSearchFromUrl(window.location.search);
  const dateFilters = mapToDocumentDateFilterInput(filters);

  return {
    limit: pageSize,
    filters: mapToDocumentFilterInput(filters),
    dateRangeFilters: dateFilters,
    sort: sortBy.length ? mapToDocumentSortInput(sortBy) : undefined,
    ...(gqlQuery || {}),
  };
};
