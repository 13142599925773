import { Color } from '@candisio/design-system';
import { ButtonColor } from '@candisio/design-system/dist/Atoms/Button/Button';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Promotion } from '../types';

export const usePromotionContent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  const contractManagementPromotion: Promotion = {
    id: 'contractManagement',
    label: {
      text: t('contractManagement.label.text'),
      color: 'purple' as Color,
    },
    title: t('contractManagement.title'),
    benefits: [
      {
        text: t('contractManagement.benefit1.text'),
        imageUrl: t('contractManagement.benefit1.imageUrl'),
      },
      {
        text: t('contractManagement.benefit2.text'),
        imageUrl: t('contractManagement.benefit2.imageUrl'),
      },
      {
        text: t('contractManagement.benefit3.text'),
        imageUrl: t('contractManagement.benefit3.imageUrl'),
      },
      {
        text: t('contractManagement.benefit4.text'),
        imageUrl: t('contractManagement.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('contractManagement.ctaButton.admin.text'),
      color: 'purple' as ButtonColor,
    },
  };

  const documentManagementPromotion: Promotion = {
    id: 'documentManagement',
    label: {
      text: t('documentManagement.label.text'),
      color: 'purple' as Color,
    },
    title: t('documentManagement.title'),
    benefits: [
      {
        text: t('documentManagement.benefit1.text'),
        imageUrl: t('documentManagement.benefit1.imageUrl'),
      },
      {
        text: t('documentManagement.benefit2.text'),
        imageUrl: t('documentManagement.benefit2.imageUrl'),
      },
      {
        text: t('documentManagement.benefit3.text'),
        imageUrl: t('documentManagement.benefit3.imageUrl'),
      },
      {
        text: t('documentManagement.benefit4.text'),
        imageUrl: t('documentManagement.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('documentManagement.ctaButton.admin.text'),
      color: 'purple' as ButtonColor,
    },
  };

  // Datev self booker
  const provisionsPromotion: Promotion = {
    id: 'datevSelfBooker',
    label: {
      text: t('datevSelfBooker.label.text'),
      color: 'purple' as Color,
    },
    title: t('datevSelfBooker.title'),
    benefits: [
      {
        text: t('datevSelfBooker.benefit1.text'),
        imageUrl: t('datevSelfBooker.benefit1.imageUrl'),
      },
      {
        text: t('datevSelfBooker.benefit2.text'),
        imageUrl: t('datevSelfBooker.benefit2.imageUrl'),
      },
      {
        text: t('datevSelfBooker.benefit3.text'),
        imageUrl: t('datevSelfBooker.benefit3.imageUrl'),
      },
      {
        text: t('datevSelfBooker.benefit4.text'),
        imageUrl: t('datevSelfBooker.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('datevSelfBooker.ctaButton.admin.text'),
      color: 'purple' as ButtonColor,
    },
  };

  const creditCardsPromotion: Promotion = {
    id: 'creditCards',
    label: {
      text: t('creditCards.label.text'),
      color: 'blue' as Color,
    },
    title: t('creditCards.title'),
    benefits: [
      {
        text: t('creditCards.benefit1.text'),
        imageUrl: t('creditCards.benefit1.imageUrl'),
      },
      {
        text: t('creditCards.benefit2.text'),
        imageUrl: t('creditCards.benefit2.imageUrl'),
      },
      {
        text: t('creditCards.benefit3.text'),
        imageUrl: t('creditCards.benefit3.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('creditCards.ctaButton.admin.text'),
      color: 'blue' as ButtonColor,
    },
  };

  const upsellPlusPromotion: Promotion = {
    id: 'upsellPlus',
    label: {
      text: t('upsellPlus.label.text'),
      color: 'purple' as Color,
    },
    title: t('upsellPlus.title'),
    benefits: [
      {
        text: t('upsellPlus.benefit1.text'),
        imageUrl: t('upsellPlus.benefit1.imageUrl'),
      },
      {
        text: t('upsellPlus.benefit2.text'),
        imageUrl: t('upsellPlus.benefit2.imageUrl'),
      },
      {
        text: t('upsellPlus.benefit3.text'),
        imageUrl: t('upsellPlus.benefit3.imageUrl'),
      },
      {
        text: t('upsellPlus.benefit4.text'),
        imageUrl: t('upsellPlus.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('upsellMax.ctaButton.admin.text'),
      color: 'purple' as ButtonColor,
    },
  };

  const upsellMaxPromotion: Promotion = {
    id: 'upsellMax',
    label: {
      text: t('upsellMax.label.text'),
      color: 'purple' as Color,
    },
    title: t('upsellMax.title'),
    benefits: [
      {
        text: t('upsellMax.benefit1.text'),
        imageUrl: t('upsellMax.benefit1.imageUrl'),
      },
      {
        text: t('upsellMax.benefit2.text'),
        imageUrl: t('upsellMax.benefit2.imageUrl'),
      },
      {
        text: t('upsellMax.benefit3.text'),
        imageUrl: t('upsellMax.benefit3.imageUrl'),
      },
      {
        text: t('upsellMax.benefit4.text'),
        imageUrl: t('upsellMax.benefit4.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('upsellMax.ctaButton.admin.text'),
      color: 'purple' as ButtonColor,
    },
  };

  const mobileAppPromotion: Promotion = {
    id: 'mobileApp',
    label: {
      text: t('mobileApp.label.text'),
      color: 'blue' as Color,
    },
    title: t('mobileApp.title'),
    benefits: [
      {
        text: t('mobileApp.benefit1.text'),
        imageUrl: t('mobileApp.benefit1.imageUrl'),
      },
      {
        text: t('mobileApp.benefit2.text'),
        imageUrl: t('mobileApp.benefit2.imageUrl'),
      },
      {
        text: t('mobileApp.benefit3.text'),
        imageUrl: t('mobileApp.benefit3.imageUrl'),
      },
    ],
    ctaButton: {
      text: t('mobileApp.ctaButton.text'),
      color: 'blue' as ButtonColor,
    },
  };

  return {
    contractManagementPromotion,
    documentManagementPromotion,
    provisionsPromotion,
    creditCardsPromotion,
    mobileAppPromotion,
    upsellPlusPromotion,
    upsellMaxPromotion,
  };
};
