import { Grid, Spinner } from '@candisio/design-system';
import { useApproverFieldOptions } from './toolkit/hooks/useApproverFieldOptions';
import { useExistingWorkflowTemplates } from './toolkit/hooks/useExistingWorkflowTemplates';
import { useWorkTemplateData } from './toolkit/hooks/useWorkTemplateData';
import { WorkflowTemplatesFormContainerProps } from './toolkit/types';
import { WorkflowTemplatesForm } from './WorkflowTemplatesForm';

export const WorkflowTemplatesFormContainer = ({
  onArchive,
  onRestore,
  onSubmit,
  isLoading,
  isSubmitting,
  template,
}: WorkflowTemplatesFormContainerProps) => {
  const { defaultFormValues } = useWorkTemplateData(template);
  const { approverDropdownOptions } = useApproverFieldOptions();

  const { existingWorkflowTemplates } = useExistingWorkflowTemplates({
    template,
  });

  const canArchive = template ? !!template.id : false;
  const { isArchived } = template ?? {};
  const isDisabled = !!isArchived || isLoading;

  return isLoading ? (
    <Grid alignContent="center" height="100%" justifyContent="center">
      <Spinner size="space64" />
    </Grid>
  ) : (
    <WorkflowTemplatesForm
      onArchive={onArchive}
      onRestore={onRestore}
      onSubmit={onSubmit}
      approverDropdownOptions={approverDropdownOptions}
      canArchive={canArchive}
      defaultFormValues={defaultFormValues}
      existingWorkflowTemplates={existingWorkflowTemplates}
      isArchived={isArchived}
      isDisabled={isDisabled}
      isSubmitting={isSubmitting}
    />
  );
};
