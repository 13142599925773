import moment from 'moment';

interface GetAllowedDateRangeOptions {
  /** Maximum allowed number of years in future */
  yearsInFuture?: number;
  /** Maximum allowed number of years in past */
  yearsInPast?: number;
  /** Timezone to use for calculations */
  timezone?: string;
}

/** Gets allowed date range given number of years in past and/or future */
export const getAllowedDateRange = ({
  yearsInFuture,
  yearsInPast,
  timezone = 'Europe/Berlin',
}: GetAllowedDateRangeOptions) => {
  const now = moment.tz(timezone);
  const endOfYear = now.clone().endOf('year');
  const startOfYear = now.clone().startOf('year');

  const minDate =
    yearsInPast !== undefined
      ? startOfYear.subtract(yearsInPast, 'years').toDate()
      : undefined;

  const maxDate =
    yearsInFuture !== undefined
      ? endOfYear.add(yearsInFuture, 'years').toDate()
      : undefined;

  return { minDate, maxDate };
};
