import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateTransactionCommentMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { getTransactionHistoryQuery } from '../../gql';
import { CommentFieldTransactionContainer } from '../CommentFieldTransactionContainer';
interface CommentTransactionContainerProps {
  transactionId: string;
  ownerTxMembershipId: string | undefined;
}

export const CommentTransactionContainer = ({
  transactionId,
  ownerTxMembershipId,
}: CommentTransactionContainerProps) => {
  const [tTransactions] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const [createTransactionComment, { loading: isSubmitting }] =
    useCreateTransactionCommentMutation({
      refetchQueries: [
        { query: getTransactionHistoryQuery, variables: { id: transactionId } },
      ],
    });

  const handleCreateTransactionComment = async ({
    mention,
    mentionedUserIds,
  }: {
    mention: string | undefined;
    mentionedUserIds: Array<string>;
  }) => {
    try {
      if (!mention) {
        return null;
      }

      await createTransactionComment({
        variables: {
          transactionId,
          text: mention,
          mentionedMembershipIds: mentionedUserIds,
        },
      });
      success(
        tTransactions('transactionAssociation.collaboration.commentAdded')
      );
    } catch (err) {
      error(
        tTransactions('transactionAssociation.collaboration.commentFailed')
      );
      console.log(err);
    }
  };

  return (
    <CommentFieldTransactionContainer
      ownerTxMembershipId={ownerTxMembershipId}
      isSubmitting={isSubmitting}
      onCreateComment={handleCreateTransactionComment}
    />
  );
};
