import { HookFormUserFieldItem } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { useWorkflowTemplatesFieldItemsQuery } from 'generated-types/graphql.types';
import { useToApproverFieldItem } from 'views/Inbox/DocumentProcessing/useToApproverFieldItem';

interface ApproverFieldOptions {
  approverDropdownOptions: HookFormUserFieldItem[];
  isLoading: boolean;
}

export const useApproverFieldOptions = (): ApproverFieldOptions => {
  const { data: fieldItemsData, loading: isLoading } =
    useWorkflowTemplatesFieldItemsQuery();

  const { approvers = [], getOrgMembersAbsence: orgMembersAbsence = [] } =
    fieldItemsData ?? {};

  const toApproverFieldItem = useToApproverFieldItem(
    orgMembersAbsence,
    approvers
  );

  const approverDropdownOptions = approvers.map(toApproverFieldItem);

  return { approverDropdownOptions, isLoading };
};
