import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

export const MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH = 30;

export const contractSubCategoryDetailsFormSchema = z.object({
  name: z.string().max(MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH),
  description: z.string().max(250).nullish(),
});

export type ContractSubCategoryDetailsFormOutput = z.infer<
  typeof contractSubCategoryDetailsFormSchema
>;
export type ContractSubCategoryDetailsFormValues =
  Partial<ContractSubCategoryDetailsFormOutput>;
export type ContractSubCategoryDetailsErrorMessages = ErrorMessages<
  typeof contractSubCategoryDetailsFormSchema
>;
