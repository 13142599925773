import { useTranslation } from 'react-i18next';
import { SubstituteAddedEventDataFragment } from '../../../generated-types/graphql.types';

export const SubstituteAddedComponent = ({
  substitute,
}: SubstituteAddedEventDataFragment) => {
  const [t] = useTranslation();

  return (
    <>
      {t('document.compact.history.workflowSubstituteAdded', {
        approver: substitute?.name,
        substitutedApprover: substitute?.isSubstituting?.name,
      })}
    </>
  );
};
