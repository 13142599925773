import gql from 'graphql-tag';
import {
  purchaseOrderListFragment,
  pageInfoDataFragment,
} from 'views/queries/fragments';

export const purchaseOrdersImportHistoryFragment = gql`
  fragment PurchaseOrdersImportHistoryData on PurchaseOrderImportHistoryEntry {
    id
    createdBy {
      id
      name
    }
    createdAt
    updatedAt
    finishedAt
    status
    importResult {
      successfullyCreatedDocumentsCount
      successfullyUpdatedDocumentsCount
      failedToImportDocumentsCount
    }
  }
`;

export const archivePurchaseOrdersQuery = gql`
  query archivePurchaseOrders(
    $input: PurchaseOrderPaginationInput!
    $query: String
    $queryFields: [PurchaserOrderQueryField!]
    $filters: PurchaseOrderFilterInput
    $sortBy: PurchaseOrderSortInput
  ) {
    inboxPurchaseOrders(
      input: $input
      query: $query
      queryFields: $queryFields
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          ...purchaseOrderListData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${purchaseOrderListFragment}
`;

export const purchaseOrderImportHistoryPaginationQuery = gql`
  query purchaseOrderImportHistoryPagination(
    $input: ListPurchaseOrderImportHistoryEntriesInput
  ) {
    listPurchaseOrderImportHistoryEntries(input: $input)
      @connection(key: "purchaseOrderImportHistoryPagination") {
      records {
        ...PurchaseOrdersImportHistoryData
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
  ${purchaseOrdersImportHistoryFragment}
`;

export const purchaseOrderImportHistoryByIdQuery = gql`
  query purchaseOrderImportHistoryById($id: ID!) {
    getPurchaseOrderImportHistoryEntryById(id: $id) {
      ...PurchaseOrdersImportHistoryData
    }
  }
  ${purchaseOrdersImportHistoryFragment}
`;
