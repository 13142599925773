import { Grid } from '@candisio/design-system';
import { ProvisionPromotionDetails } from './components/ProvisionPromotionDetails/ProvisionPromotionDetails';
import { ProvisionPromotionVideoThumbnail } from './components/ProvisionPromotionVideoThumbnail/ProvisionPromotionVideoThumbnail';

export const ProvisionPromotionCard = () => {
  return (
    <Grid gap="space16">
      <Grid rowGap="space24">
        <ProvisionPromotionDetails />
      </Grid>
      <Grid rowGap="space24">
        <ProvisionPromotionVideoThumbnail />
      </Grid>
    </Grid>
  );
};
