import { NavigationTableParams } from 'components/DocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider/useOrganizationId';
import { useCallback } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import { appendParamsToQueryString } from 'utils/url-helper';
import { TabView } from 'views/Inbox/models';
import { ArchiveDocumentsTable } from '../ArchiveDocumentsTable/ArchiveDocumentsTable';
import { useArchiveDocumentsData } from '../ArchiveDocumentsTable/hooks/useArchiveDocumentsData';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';

type Props = RouteComponentProps<{ organizationSlug: string }>;

export const MAX_DOCUMENTS_FOR_PDF_ZIP_DOWNLOAD = 1000;
export const MIN_DOCUMENTS_FOR_PDF_ZIP_DOWNLOAD = 1;

export const ArchiveDocuments = ({
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const { searchParams, updateSearchParam } = useMutateSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? '';
  const isUsingSearchQuery = Boolean(searchQuery);

  const { search } = useLocation();
  const history = useHistory();

  const orgId = useOrganizationId();

  const onClick = useCallback(
    ({ id, cursor }: NavigationTableParams) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? '',
      });

      const path = `/${orgId}${Routes.ARCHIVE}/${id}?${searchWithCursor}`;
      history.push(path);
    },
    [history, orgId, search]
  );

  const {
    documentsTableData,
    handleDebounceSearch,
    hasMoreData,
    loading,
    onLoadMore,
    selectedDocumentsCount,
  } = useArchiveDocumentsData({
    isUsingSearchQuery,
  });

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_DOCUMENTS}>
      <ArchiveDocumentsTable
        data={documentsTableData}
        isLoading={loading}
        hasMoreData={hasMoreData}
        selectedDocumentsCount={selectedDocumentsCount}
        organizationSlug={organizationSlug}
        isUsingSearchQuery={isUsingSearchQuery}
        searchFieldValue={searchQuery}
        searchFieldOnChange={query => {
          handleDebounceSearch(query);
          updateSearchParam(queryParameter, query);
        }}
        onEndReached={onLoadMore}
        onClick={onClick}
      />
    </ArchiveViewLayout>
  );
};
