import { Flex, Grid } from '@candisio/design-system';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { Promotion } from 'components/NewPromotions/types';
import { CallInActionSection } from './CallInActionSection/CallInActionSection';
import { PromotionBenefitsSection } from './PromotionBenefitsSection/PromotionBenefitsSection';
import { UpgradeInfo } from './UpgradeInfoSection/UpgradeInfo';

export type PromotionDetailsModalContentProps = {
  promotionId: Promotion['id'];
};

export const PromotionDetailsModalContent = ({
  promotionId,
}: PromotionDetailsModalContentProps) => {
  const { promotionDetails } = usePromotionDetailsModalContent(promotionId);
  const { title, benefits, upgradeInfo, imageUrl } = promotionDetails;

  return (
    <Grid templateColumns="1fr 39%">
      <PromotionBenefitsSection
        title={title}
        benefits={benefits}
        imageUrl={imageUrl}
        // wistiaId={wistiaId}
      />

      {upgradeInfo && (
        <Flex
          direction="column"
          background="gray100"
          padding="space32 space32"
          borderRadius="8px">
          <UpgradeInfo upgradeInfo={upgradeInfo} />
          <CallInActionSection promotionId={promotionId} />
        </Flex>
      )}
    </Grid>
  );
};
