import { TransactionCategory } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { WidgetFilter } from '../CreditCardsInsights/SpentOverview/utils';

export const useGetSpentOverviewFilters = () => {
  const { filters, onFilter } = useUrlBasedSortAndFilter({
    availableFilters: [
      WidgetFilter.category,
      WidgetFilter.cardRefNum,
      WidgetFilter.merchantName,
    ],
  });

  const categoryFilter = filters.filter(
    filt => filt.id === WidgetFilter.category
  );

  const cardRefNumFilter = filters.filter(
    filt => filt.id === WidgetFilter.cardRefNum
  );

  const merchantNamesFilter = filters.filter(
    filt => filt.id === WidgetFilter.merchantName
  );

  const appliedCategoryFilters: TransactionCategory[] =
    categoryFilter.length && categoryFilter[0].value
      ? (categoryFilter[0].value as TransactionCategory[])
      : [];

  const appliedCardRefNumFilters =
    cardRefNumFilter.length && cardRefNumFilter[0].value
      ? (cardRefNumFilter[0].value as string[])
      : [];

  const appliedMerchantNamesFilters =
    merchantNamesFilter.length && merchantNamesFilter[0].value
      ? (merchantNamesFilter[0].value as string[])
      : [];

  const allAppliedFilters = {
    category: appliedCategoryFilters,
    cardRefNum: appliedCardRefNumFilters,
    merchantNames: appliedMerchantNamesFilters,
  };

  return {
    filtersFromUrl: filters,
    allAppliedFilters,
    onFilter,
  };
};
