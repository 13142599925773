import {
  PaginationWithSearchFilterHook,
  FilterOption,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import { TagSortOrder, useTagsQuery } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UNTAGGED_ID } from '../constants';

const UNTAGGED_LABEL = 'filters.empty';

export const useTagsFilter: PaginationWithSearchFilterHook = ({
  searchStr,
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const [documentTagsEcmFiltersUntaggedFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.documentTagsEcmFiltersUntagged,
  ]);

  const { data, loading: loadingTags } = useTagsQuery({
    variables: {
      input: {
        isActive: true,
        sort: { name: TagSortOrder.Asc },
      },
    },
  });

  const filterOptions: FilterOption[] = useMemo(() => {
    const options = documentTagsEcmFiltersUntaggedFF
      ? [{ id: UNTAGGED_ID, name: t(UNTAGGED_LABEL) }]
      : [];

    return options
      .concat(data?.tags ?? [])
      .map(tag => ({ id: tag.id, label: tag.name }))
      .filter(byNameSubstring(searchStr));
  }, [documentTagsEcmFiltersUntaggedFF, t, data?.tags, searchStr]);

  return {
    filterOptions,
    loading: loadingTags,
  };
};

const byNameSubstring = (searchStr: string) => (option: FilterOption) =>
  searchStr.length === 0 ||
  (typeof option.label === 'string' &&
    option.label.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase()));
