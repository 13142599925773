import {
  EcmDocumentContractSubCategory,
  EcmDocumentSubCategoryField,
  useContractSubCategoriesQuery,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface StorageFormContractTypeFieldItem {
  key: EcmDocumentSubCategoryField['id'];
  children: string;
  isActive: boolean;
}

interface FilterItem {
  id: EcmDocumentSubCategoryField['id'];
  label: string;
}

export const staticContractSubCategories: {
  id: string;
  translationKey: string;
}[] = [
  {
    id: EcmDocumentContractSubCategory.RentalContract,
    translationKey: 'ecm:storageForm.fields.contractType.options.rental',
  },
  {
    id: EcmDocumentContractSubCategory.LeasingContract,
    translationKey: 'ecm:storageForm.fields.contractType.options.lease',
  },
  {
    id: EcmDocumentContractSubCategory.PurchasingContract,
    translationKey: 'ecm:storageForm.fields.contractType.options.purchase',
  },
  {
    id: EcmDocumentContractSubCategory.CompanyAgreement,

    translationKey:
      'ecm:storageForm.fields.contractType.options.companyAgreements',
  },
  {
    id: EcmDocumentContractSubCategory.ServiceContract,
    translationKey: 'ecm:storageForm.fields.contractType.options.service',
  },
  {
    id: EcmDocumentContractSubCategory.MaintenanceContract,
    translationKey: 'ecm:storageForm.fields.contractType.options.maintenance',
  },
  {
    id: EcmDocumentContractSubCategory.LoanAgreement,
    translationKey: 'ecm:storageForm.fields.contractType.options.loan',
  },
  {
    id: EcmDocumentContractSubCategory.DeliveryContract,
    translationKey:
      'ecm:storageForm.fields.contractType.options.deliveryContract',
  },
  {
    id: EcmDocumentContractSubCategory.Insurance,
    translationKey: 'ecm:storageForm.fields.contractType.options.insurance',
  },
  {
    id: EcmDocumentContractSubCategory.Other,
    translationKey: 'ecm:storageForm.fields.contractType.options.other',
  },
];

/**
 * A custom hook to get the translation map and filter items for the ECM
 * document type based on the EcmContractType enum
 */
export const useEcmContractTypeOptions = (
  initialDocumentSubCategoryId?: string | null
) => {
  const { data: customContractSubCategories } = useContractSubCategoriesQuery({
    variables: {
      input: {
        includeDocumentCount: false,
      },
    },
  });

  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const contractTypeOptions = useMemo(() => {
    const staticSubCategories: {
      id: string;
      name: string;
      isActive: boolean;
    }[] = staticContractSubCategories.flatMap(({ id, translationKey }) => {
      // move Other to the end of list later on
      if (id === EcmDocumentContractSubCategory.Other) {
        return [];
      }

      return [
        {
          id,
          name: t(translationKey),
          isActive: true,
        },
      ];
    });

    const dynamicContractSubCategories =
      customContractSubCategories?.contractSubCategories ?? [];

    const contractSubCategories =
      // First add static values...
      staticSubCategories
        // ...then add custom sub categories...
        .concat(dynamicContractSubCategories)
        // ...then sort all alphabetically...
        .toSorted((a, b) => a.name.localeCompare(b.name))
        // ...then add "Other" static category to the end of the list
        .concat({
          id: EcmDocumentContractSubCategory.Other,
          name: t('storageForm.fields.contractType.options.other'),
          isActive: true,
        });

    // Transform list to match the format of the Select component
    const ecmContractTypeSelectItems: StorageFormContractTypeFieldItem[] =
      contractSubCategories.flatMap(({ id, name, isActive }) => {
        // Keep the initial sub category in list, even if its archived
        if (isActive || id === initialDocumentSubCategoryId)
          return [
            {
              key: id,
              children: name,
              isActive,
            },
          ];
        else return [];
      });

    // Transform list to match the format of the table filter
    const ecmContractTypeFilterItems: FilterItem[] = contractSubCategories.map(
      ({ id, name }) => {
        return {
          id,
          label: name,
        };
      }
    );

    const getContractTypeLabel = (
      id: EcmDocumentSubCategoryField['id'] | undefined
    ) => {
      return ecmContractTypeFilterItems.find(item => item.id === id)?.label;
    };

    return {
      ecmContractTypeFilterItems,
      ecmContractTypeSelectItems,
      getContractTypeLabel,
    };
  }, [
    customContractSubCategories?.contractSubCategories,
    initialDocumentSubCategoryId,
    t,
  ]);

  return contractTypeOptions;
};
