import { Flex, Box } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { useUpdateSapB1Settings } from '../hooks/useUpdateSapB1Settings';
import { creditCardIdSchema, errorMessages } from './schema';

type CreditCardIdData = {
  creditCardId?: number;
};

export const CreditCardIdForm = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isLoading, onUpdateSapB1Settings, sapB1Settings } =
    useUpdateSapB1Settings();

  const form = useForm({
    defaultValues: {
      creditCardId: sapB1Settings.sapB1Settings?.creditCardId ?? undefined,
    },
    context: { t },
    resolver: zodResolver({
      zodSchema: creditCardIdSchema({ t }),
      errorMessages: errorMessages,
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
    mode: 'onSubmit',
  });

  const handleSubmit = async ({ creditCardId: value }: CreditCardIdData) => {
    const creditCardId = !value ? undefined : value;

    const result = await onUpdateSapB1Settings({
      creditCardId,
    });

    if (result?.data) {
      form.resetField('creditCardId', {
        defaultValue: creditCardId,
      });
    }
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end">
        <HookFormTextField
          autoFocus={true}
          label={t('creditCardExportingSap.creditCardId.textInput.ariaLabel')}
          name="creditCardId"
          placeholder={t(
            'creditCardExportingSap.creditCardId.textInput.placeholder'
          )}
          clearable={false}
        />
        <Box paddingTop="space16">
          <QuickSaveButtons
            isLoading={isLoading}
            isFormDirty={isFormDirty}
            resetField={() => form.resetField('creditCardId')}
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};
