import {
  Grid,
  Heading,
  ScrollBox,
  Switch,
  TableElement,
  TableBody,
  TableHead,
  TableRow,
  Skeleton,
  Card,
  Paragraph,
  Flex,
} from '@candisio/design-system';
import { SfsSettingSwitchMethod } from 'generated-types/graphql.types';
import { getTranslationContext } from 'orgConfig';
import { useTranslation } from 'react-i18next';
import {
  fieldNames,
  Role,
  SfsSettingsType,
  useSwitchSfsField,
} from './useSwitchSfsField';
import { checkIfAnyFieldWasSwitchedOfAutomatically } from './utils';

const SWITCH_COL_SIZE = { width: '92px' };

export const SFSSettingsTable = () => {
  const { sfsSettings, switchSfsField, loading } = useSwitchSfsField();
  const [tCommon] = useTranslation();

  const isAnyFieldSwitchedOffAutomatically =
    checkIfAnyFieldWasSwitchedOfAutomatically(sfsSettings as SfsSettingsType);

  if (loading) {
    return (
      <Card>
        <Grid gap="space16">
          <Skeleton height="space40" width="100%" />
          <Skeleton height="space40" width="100%" />
          <Skeleton height="space40" width="100%" />
          <Skeleton height="space40" width="100%" />
        </Grid>
      </Card>
    );
  }

  return (
    <Grid gap="space16">
      <Flex direction="column">
        <Heading as="h3">
          {tCommon('settings.smartFieldSuggestions.tableTitle')}
        </Heading>
        <Paragraph>
          {tCommon('settings.smartFieldSuggestions.tableSubtitle')}
        </Paragraph>
      </Flex>
      <ScrollBox borderRadius="basic">
        <TableElement>
          <colgroup>
            <col />
            {isAnyFieldSwitchedOffAutomatically && <col />}
            <col style={SWITCH_COL_SIZE} />
            <col style={SWITCH_COL_SIZE} />
          </colgroup>
          <TableHead>
            <TableRow>
              <th>{tCommon('settings.smartFieldSuggestions.fieldHeader')}</th>
              {isAnyFieldSwitchedOffAutomatically && <th />}
              <th>
                {tCommon(
                  'settings.smartFieldSuggestions.requester.columnHeading'
                )}
              </th>

              <th>
                {tCommon(
                  'settings.smartFieldSuggestions.approver.columnHeading'
                )}
              </th>
            </TableRow>
          </TableHead>
          <TableBody>
            {fieldNames.map(fieldName => {
              const shouldDisplayHint =
                fieldName === 'costCenter' || fieldName === 'costObject';

              const isFieldSwitchedOffAutomatically =
                sfsSettings.REQUESTER?.[fieldName].method ===
                SfsSettingSwitchMethod.Automatic;

              return (
                <TableRow key={fieldName}>
                  <th>
                    {
                      // eslint-disable-next-line candis/no-template-strings-inside-translation
                      tCommon(
                        `settings.smartFieldSuggestions.fields.${fieldName}.title`,
                        {
                          context: getTranslationContext(),
                        }
                      )
                    }
                  </th>
                  {isAnyFieldSwitchedOffAutomatically && (
                    <td>
                      {shouldDisplayHint && isFieldSwitchedOffAutomatically
                        ? // eslint-disable-next-line candis/no-template-strings-inside-translation
                          tCommon(
                            `settings.smartFieldSuggestions.fields.${fieldName}.turnedOffAutomatically`,
                            {
                              context: getTranslationContext(),
                            }
                          )
                        : null}
                    </td>
                  )}

                  {/* Requester switch */}
                  <td style={{ textAlign: 'center' }}>
                    <Switch
                      checked={sfsSettings.REQUESTER?.[fieldName].on}
                      data-testid={`${fieldName}-toggle-requester`}
                      // eslint-disable-next-line candis/no-template-strings-inside-translation
                      label={tCommon(
                        `settings.smartFieldSuggestions.fields.${fieldName}.title`,
                        {
                          context: getTranslationContext(),
                        }
                      )}
                      name={`${fieldName}-toggle`}
                      onChange={(checked: boolean) => {
                        void switchSfsField(fieldName, checked, Role.REQUESTER);
                      }}
                      verticalAlign="middle"
                    />
                  </td>

                  {/* Approver switch */}
                  <td style={{ textAlign: 'center' }}>
                    <Switch
                      checked={sfsSettings.APPROVER?.[fieldName].on}
                      data-testid={`${fieldName}-toggle-approver`}
                      // eslint-disable-next-line candis/no-template-strings-inside-translation
                      label={tCommon(
                        `settings.smartFieldSuggestions.fields.${fieldName}.title`,
                        {
                          context: getTranslationContext(),
                        }
                      )}
                      name={`${fieldName}-toggle`}
                      onChange={(checked: boolean) => {
                        void switchSfsField(fieldName, checked, Role.APPROVER);
                      }}
                      verticalAlign="middle"
                    />
                  </td>
                </TableRow>
              );
            })}
          </TableBody>
        </TableElement>
      </ScrollBox>
    </Grid>
  );
};
