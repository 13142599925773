import { CommentFieldContainer } from './CommentFieldContainer';

export const ReimbursementCommentContainer = () => {
  //@TODO: reimbursement comment api to be added here in furture PR
  const onCreateComment = async () => await null;

  return (
    <CommentFieldContainer
      isSubmitting={false}
      onCreateComment={onCreateComment}
    />
  );
};
