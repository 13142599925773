import { DocumentNode, useApolloClient } from '@apollo/client';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';

export const useCostCenterRefetchQueries = () => {
  const client = useApolloClient();

  const refetchQueries: Array<{ query: DocumentNode } | string> = [
    {
      query: getFullOrganizationQuery,
    },
    'countCostCenters',
  ];

  const resetPaginationResults = () => {
    client.cache.evict({ fieldName: 'costCenterPagination' });
  };

  return { refetchQueries, resetPaginationResults };
};
