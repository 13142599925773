export enum ApprovalStatus {
  APPROVED = 'approved',
  DECLINED = 'declined',
  PENDING = 'pending',
}

export enum TabView {
  ARCHIVE_DOCUMENTS = 'ARCHIVE_DOCUMENTS',
  ARCHIVE_ECM_CONTRACTS = 'ARCHIVE_ECM_CONTRACTS',
  ARCHIVE_ECM_DOCUMENTS = 'ARCHIVE_ECM_DOCUMENTS',
  ARCHIVE_TRANSACTIONS = 'ARCHIVE_TRANSACTIONS',
  ARCHIVE_REIMBURSEMENTS = 'ARCHIVE_REIMBURSEMENTS',
  INBOX_DOCUMENTS = 'INBOX_DOCUMENTS',
  INBOX_TRANSACTIONS = 'INBOX_TRANSACTIONS',
  INBOX_PURCHASE_ORDERS = 'INBOX_PURCHASE_ORDERS',
  INBOX_ECM_SENSITIVE_DOCUMENTS = 'INBOX_ECM_SENSITIVE_DOCUMENTS',
  ARCHIVE_PURCHASE_ORDERS = 'ARCHIVE_PURCHASE_ORDERS',
  ARCHIVE_GOODS_RECEIPTS = 'ARCHIVE_GOODS_RECEIPTS',
  INBOX_REIMBURSEMENTS = 'INBOX_REIMBURSEMENTS',
}
