import { FilterState } from '../types';
import {
  ParsedBookingAccount,
  ValidationErrors,
} from '../utils/parseBookingAccountCSV';

export const errorReports = {
  [ValidationErrors.NAME_MISSING]: {
    infoKey: 'import.preview.errors.nameMissing.showInfo',
    linkKey: 'import.preview.errors.nameMissing.showLink',
    onLinkClick: (bookingAccounts: ParsedBookingAccount[]): FilterState => ({
      accountCode: null,
      name: bookingAccounts
        .filter(ba => ba.errors.includes(ValidationErrors.NAME_MISSING))
        .map(ba => ba.name),
    }),
  },
  [ValidationErrors.ACCOUNT_NUMBER_MISSING]: {
    infoKey: 'import.preview.errors.accountNumberMissing.showInfo',
    linkKey: 'import.preview.errors.accountNumberMissing.showLink',
    onLinkClick: (bookingAccounts: ParsedBookingAccount[]): FilterState => ({
      name: null,
      accountCode: bookingAccounts
        .filter(ba =>
          ba.errors.includes(ValidationErrors.ACCOUNT_NUMBER_MISSING)
        )
        .map(ba => ba.accountCode),
    }),
  },
  [ValidationErrors.TOO_LONG]: {
    infoKey: 'import.preview.errors.tooLong.showInfo',
    linkKey: 'import.preview.errors.tooLong.showLink',
    onLinkClick: (bookingAccounts: ParsedBookingAccount[]): FilterState => ({
      name: null,
      accountCode: bookingAccounts
        .filter(ba => ba.errors.includes(ValidationErrors.TOO_LONG))
        .map(ba => ba.accountCode),
    }),
  },
  [ValidationErrors.IS_DUPLICATE]: {
    infoKey: 'import.preview.errors.duplicate.showInfo',
    linkKey: 'import.preview.errors.duplicate.showLink',
    onLinkClick: (bookingAccounts: ParsedBookingAccount[]): FilterState => ({
      name: null,
      accountCode: bookingAccounts
        .filter(ba => ba.errors.includes(ValidationErrors.IS_DUPLICATE))
        .map(ba => ba.accountCode),
    }),
  },
};
