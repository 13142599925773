import { EcmDocumentContractStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface FilterOption {
  id: EcmDocumentContractStatus;
  label: string;
}

interface EcmContractStatusReturn {
  /** A map of the EcmContractStatus type to the translated string */
  ecmContractStatusTranslationMap: Record<EcmDocumentContractStatus, string>;
  /** A list of filter options used in the table */
  ecmContractStatusFilterOptions: Array<FilterOption>;
}

/**
 * A custom hook to get the translation map and filter options for the ECM contract status
 * based on the (contract) Status type
 */
export const useEcmContractStatusOptions = (): EcmContractStatusReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const ecmContractStatusTranslationMap: Record<
    EcmDocumentContractStatus | 'Overdue' | 'Sensitive',
    string
  > = {
    [EcmDocumentContractStatus.Active]: t('contractStatus.active'),
    [EcmDocumentContractStatus.Inactive]: t('contractStatus.inactive'),
    Overdue: t('contractStatus.overdue'),
    Sensitive: t('contractStatus.sensitive'),
  };

  const ecmContractStatusFilterOptions = Object.entries(
    ecmContractStatusTranslationMap
  ).map(([id, label]) => {
    return {
      id,
      label,
    } as FilterOption;
  });

  return {
    ecmContractStatusTranslationMap,
    ecmContractStatusFilterOptions,
  };
};
