import {
  Grid,
  ScrollBox,
  Text,
  Tooltip,
  TooltipProps,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentExportStatus } from 'views/Integrations/Export/types';
export interface ExportStatusTooltipProps extends TooltipProps {
  exportStatus: DocumentExportStatus;
}

export const SapB1ExportStatusTooltip = forwardRef<
  HTMLDivElement,
  ExportStatusTooltipProps
>(({ exportStatus, ...restProps }, ref) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  const code = exportStatus.exportError?.errorCode as string;
  const message = exportStatus.exportError?.message;

  const errorMessageMap: Record<string, string> = {
    UNEXPECTED_ERROR: 'exports.errors.UNEXPECTED_ERROR',
    DOCUMENT_FILTERED_OUT: 'exports.errors.DOCUMENT_FILTERED_OUT',
    VALIDATION_FAILED: 'exports.errors.VALIDATION_FAILED',
    SAP_B1_API_ERROR: 'exports.errors.SAP_B1_API_ERROR',
    SAP_B1_ERROR: 'exports.errors.SAP_B1_ERROR',
  };

  return (
    <Tooltip paddingX={0} paddingY={0} {...restProps} ref={ref}>
      <ScrollBox padding="space16" maxHeight="inherit" scrollDirection="y">
        <Grid gap="space8" fontSize="small">
          <Text color="red500">
            {t(errorMessageMap[code] || 'exports.errors.unknown')}
          </Text>
          {message && <Text>{message}</Text>}
        </Grid>
      </ScrollBox>
    </Tooltip>
  );
});
