export function stripSpecialCharactersFromString(
  value: string,
  specialCharacters: string[]
): string {
  specialCharacters.forEach(char => {
    value = value.split(char).join('');
  });

  return value;
}
