import {
  BookingAccountInfiniteScrollPaginationQuery,
  BookingAccountInfiniteScrollPaginationQueryVariables,
  BookingAccountSortField,
  SortDirection,
  useBookingAccountPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { bookingAccountInfiniteScrollPagination } from 'views/queries/booking-accounts';

export const useBookingAccountFieldOptions = ({
  selectedValues,
}: {
  selectedValues: string[];
}) => {
  const computeVariables = useCallback(
    (
      inputString?: string
    ): BookingAccountInfiniteScrollPaginationQueryVariables => ({
      queries: {
        readableName: inputString,
      },
      sortBy: {
        direction: SortDirection.Asc,
        field: BookingAccountSortField.AccountCode,
      },
    }),
    []
  );

  const {
    data: allBookingAccountsData,
    loading: allBookingAccountsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    BookingAccountInfiniteScrollPaginationQuery,
    BookingAccountInfiniteScrollPaginationQueryVariables
  >({
    query: bookingAccountInfiniteScrollPagination,
    queryRootKey: 'bookingAccountPagination',
    computeVariables,
  });

  const { data: selectedBookingAccounts } = useBookingAccountPaginationQuery({
    variables: {
      input: { page: 1, limit: selectedValues.length },
      filters: {
        id: selectedValues,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: selectedValues.length === 0,
  });

  const mappedBookingAccounts =
    allBookingAccountsData?.bookingAccountPagination.records.map(item => ({
      id: item.id,
      name: item.readableName,
    }));

  const mappedSelectedBookingAccounts =
    selectedBookingAccounts?.bookingAccountPagination.records.map(item => ({
      id: item.id,
      name: item.readableName,
    }));

  return {
    defaultItems: mappedBookingAccounts,
    handleDebounceSearch,
    loading: allBookingAccountsLoading,
    loadMore,
    selectedBookingAccountItems: mappedSelectedBookingAccounts,
  };
};
