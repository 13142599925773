import { CashDiscountFieldValue } from 'components/Form/types';
import { BookingInput } from 'generated-types/graphql.types';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';

type CashDiscountProperties = Pick<
  BookingInput,
  'discountAmount' | 'discountPercentage' | 'discountPaymentDate'
>;

interface MapCashDiscountToBookingInputOptions {
  cashDiscount?: CashDiscountFieldValue;
  bookingAmount?: number;
  isSingleBooking?: boolean;
}

/**
 * Maps `CashDiscountFieldValue` to cash discount fields in `BookingInput`.
 *
 * In the first version of the cash discount feature we only show
 * document-level cash discount field to the user. In future we will show
 * booking-level cash discount fields, allowing the user to set discounts per
 * split booking.
 *
 * Since the backend already expects cash discount on booking level we need to
 * map the document-level `CashDiscountFieldValue` from `FormData` to
 * booking-level fields in `BookingInput`.
 *
 * @todo handle cash discount at booking level in UI
 */
export const mapCashDiscountToBookingInput = ({
  cashDiscount,
  bookingAmount,
  isSingleBooking = false,
}: MapCashDiscountToBookingInputOptions): CashDiscountProperties => {
  const discountAmount = cashDiscount?.amount;
  const discountPercentage = cashDiscount?.percentage;
  const discountPaymentDate = cashDiscount?.dueDate?.toISOString();

  if (isSingleBooking) {
    return {
      discountAmount,
      discountPercentage,
      discountPaymentDate,
    };
  }

  if (
    typeof bookingAmount === 'number' &&
    typeof discountPercentage === 'number'
  ) {
    // We calculate the discount amount for individual split bookings based on
    // the document-level cash discount percentage.
    //
    // The sum of the cash discount amounts of the bookings may or may not add up
    // to the document-level cash discount we show to the user. This is also the
    // case in the Datev Unternehmen Online UI so we’re OK with it for now...
    return {
      discountAmount: roundToCurrencyPrecision(
        (bookingAmount / 100) * discountPercentage
      ),
      discountPercentage,
      discountPaymentDate,
    };
  }

  return {};
};
