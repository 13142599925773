import { useEffect, useRef } from 'react';

export interface UsePageVisibilityProps {
  onIntersecting: IntersectionObserverCallback;
  options?: IntersectionObserverInit;
}

export const usePageVisibility = ({
  onIntersecting,
  options,
}: UsePageVisibilityProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(onIntersecting, options);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [onIntersecting, options]);

  return {
    ref,
  };
};
