import gql from 'graphql-tag';

export const ibanInfiniteScrollPaginationQuery = gql`
  query ibanInfiniteScrollPagination(
    $input: ContactsPaginationInput
    $filters: ContactsFilterInput
    $queries: ContactsQueryInput
    $sortBy: ContactsSortInput
  ) {
    ibanPagination: contactsPagination(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    )
      @connection(
        key: "ibanInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        name {
          value
        }
        iban {
          value
        }
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
`;

export const vatIdInfiniteScrollPaginationQuery = gql`
  query vatIdInfiniteScrollPagination(
    $input: ContactsPaginationInput
    $filters: ContactsFilterInput
    $queries: ContactsQueryInput
    $sortBy: ContactsSortInput
  ) {
    vatIdPagination: contactsPagination(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    )
      @connection(
        key: "vatIdInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        name {
          value
        }
        vatId {
          value
        }
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
`;

export const taxNumberInfiniteScrollPaginationQuery = gql`
  query taxNumberInfiniteScrollPagination(
    $input: ContactsPaginationInput
    $filters: ContactsFilterInput
    $queries: ContactsQueryInput
    $sortBy: ContactsSortInput
  ) {
    taxNumberPagination: contactsPagination(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    )
      @connection(
        key: "taxNumberInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        name {
          value
        }
        taxNumber {
          value
        }
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
`;

export const accountsPayableNumberInfiniteScrollPaginationQuery = gql`
  query accountsPayableNumberInfiniteScrollPagination(
    $input: ContactsPaginationInput
    $filters: ContactsFilterInput
    $queries: ContactsQueryInput
    $sortBy: ContactsSortInput
  ) {
    accountsPayableNumberPagination: contactsPagination(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    )
      @connection(
        key: "accountsPayableNumberInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        name {
          value
        }
        accountsPayableNumber
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
`;

export const accountsReceivableNumberInfiniteScrollPaginationQuery = gql`
  query accountsReceivableNumberInfiniteScrollPagination(
    $input: ContactsPaginationInput
    $filters: ContactsFilterInput
    $queries: ContactsQueryInput
    $sortBy: ContactsSortInput
  ) {
    accountsReceivableNumberPagination: contactsPagination(
      input: $input
      filters: $filters
      sortBy: $sortBy
      queries: $queries
    )
      @connection(
        key: "accountsReceivableNumberInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        id
        name {
          value
        }
        accountsReceivableNumber
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
`;

export const contactFilterOptionsInfiniteScrollPaginationQuery = gql`
  query contactFilterOptionsInfiniteScrollPagination(
    $input: ContactsPaginationInput
    $query: ContactFilterOptionsQueryInput!
    $sortBy: ContactsSortInput
  ) {
    contactFilterOptionsPagination(
      input: $input
      query: $query
      sortBy: $sortBy
    )
      @connection(
        key: "contactFilterOptionsInfiniteScrollPagination"
        filter: ["query", "sortBy"]
      ) {
      records {
        id
        name {
          value
        }
        iban {
          value
        }
        bankAccountNumber
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
        exceedsPageLimit
      }
    }
  }
`;
