import { DocumentCurrency } from 'generated-types/graphql.types';
import { createTag } from 'tests/valueObjectFactories/tag';
import { ColumnWidths, DocumentsTableData } from './types';

export const data: DocumentsTableData[] = [
  {
    id: 'B1',
    status: { status: 'rejected' },
    contact: 'B Contact',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-2',
    dueDate: [new Date(2022, 2, 1)],
    deliveryDate: new Date(2022, 2, 0),
    costCenter: ['B Cost center'],
    costObject: ['B Cost object', 'D Cost object'],
    createdAt: new Date(2022, 2, 0),
    requester: {
      name: 'C Requester',
      imageUrl:
        'https://candisio.github.io/design-system/static/media/dancing-link.c5753b7b.webp',
    },
    approvers: [{ name: 'Ban Approver' }, { name: 'Dan Approver' }],
    hasDocument: true,
    tags: [createTag({ id: 'tag-1', name: 'Accounting' })],
  },
  {
    id: 'C2',
    status: { status: 'approved' },
    contact: 'C Contact',
    grossAmount: { amount: 300, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-3',
    dueDate: [new Date(2022, 3, 2)],
    deliveryDate: new Date(2022, 3, 1),
    costCenter: ['C Cost center', 'E Cost center'],
    costObject: ['C Cost object'],
    createdAt: new Date(2022, 3, 1),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'Can Approver' }, { name: 'Ean Approver' }],
    hasDocument: true,
  },
  {
    id: 'A3',
    status: {
      status: 'new',
      isOverdue: true,
      isDuplicate: true,
      hasAttachments: true,
    },
    contact: 'A Contact',
    grossAmount: { amount: 100, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-1',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 2, 2)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'A Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'A4',
    status: { status: 'rejected', isOverdue: true },
    contact: 'A Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-10',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 2)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'B5',
    status: { status: 'rejected', isOverdue: true },
    contact: 'B Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-10',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'C6',
    status: { status: 'exported', isOverdue: true },
    contact: 'C Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-10',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['J Cost center'],
    costObject: ['J Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'Z7',
    status: { status: 'open', isOverdue: true },
    contact: 'Z Contact with overdue',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-11',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['Z Cost center'],
    costObject: ['Z Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },

  {
    id: 'J8',
    status: { status: 'open', isDuplicate: true },
    contact: 'J Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-12',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },

  {
    id: 'K9',
    status: { status: 'open' },
    contact: 'K Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-13',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'L10',
    status: { status: 'open' },
    contact: 'L Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-14',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'R11',
    status: { status: 'open' },
    contact: 'R Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-15',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'P12',
    status: { status: 'new' },
    contact: 'P Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-16',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'J13',
    status: { status: 'open' },
    contact: 'J Contact',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-13',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'KK Approver' }],
    hasDocument: true,
  },
  {
    id: 'F14',
    status: { status: 'open' },
    contact: 'F Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-14',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'N15',
    status: { status: 'rejected' },
    contact: 'N Contact with duplicate',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-15',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['G Cost center'],
    costObject: ['G Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
  {
    id: 'M16',
    status: { status: 'new' },
    contact: 'M Contact',
    grossAmount: { amount: 200, currency: DocumentCurrency.Eur },
    invoiceNumber: 'An Invoice-number-198',
    dueDate: [new Date(2022, 1, 1), new Date(2022, 3, 3)],
    deliveryDate: new Date(2022, 1, 0),
    costCenter: ['A Cost center'],
    costObject: ['A Cost object'],
    createdAt: new Date(2022, 1, 0),
    requester: { name: 'C Requester' },
    approvers: [{ name: 'An Approver' }, { name: 'Fan Approver' }],
    hasDocument: true,
  },
];

export const columns: Array<keyof DocumentsTableData> = [
  'account',
  'approvers',
  'contact',
  'costCenter',
  'costObject',
  'createdAt',
  'createdAt',
  'creditCardPayment',
  'deliveryDate',
  'discountDateWPercentage',
  'dueDate',
  'exportMethod',
  'exportStatus',
  'fileName',
  'fileSize',
  'generalLedgerAccount',
  'grossAmount',
  'hasDocument',
  'iban',
  'invoiceDate',
  'invoiceNumber',
  'isDisabled',
  'isPayable',
  'netAmount',
  'note',
  'openInDatev',
  'paidAt',
  'paymentStatus',
  'period',
  'provisionStatus',
  'requestedAt',
  'requester',
  'settlementPaymentStatus',
  'status',
  'transactionStatus',
];

export const columnWidths: ColumnWidths = columns.reduce((acc, colId) => {
  return {
    ...acc,
    [colId]: '20px',
  };
}, {});

export const UNTAGGED_ID = '-';
