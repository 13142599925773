import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import {
  HookFormPaginatedComboBoxField,
  HookFormPaginatedComboBoxFieldProps,
} from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ExtraCostInfo = ({
  readOnly,
  ...props
}: Partial<
  HookFormPaginatedComboBoxFieldProps<DocumentApprovalFormValues>
>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  return (
    <HookFormPaginatedComboBoxField
      {...props}
      name="bookings.0.extraCostInfo"
      label={t('inputs.extraCostInfo.label')}
      placeholder={
        readOnly
          ? t('inputs.extraCostInfo.placeholder.notSet')
          : t('inputs.extraCostInfo.placeholder.enabled')
      }
      readOnly={readOnly}
    />
  );
};
