import { TruncatedText, TruncatedTextProps } from '@candisio/design-system';

/**
 *  Last 3 letters of filename + dot + 3-letter file extension
 */
const CHARS_AFTER_ELLIPSIS = 7;

export interface FilenameProps
  extends Omit<TruncatedTextProps, 'charsAfterEllipsis'> {
  children: string;
}

/**
 * Displays a filename and cuts off any characters that don’t fit.
 *
 * An ellipsis (…) is displayed before the last 7 characters.
 * The full filename is displayed in a tooltip on hover.
 *
 * @implements TruncatedText
 */
export const Filename = ({ children, ...restProps }: FilenameProps) => {
  return (
    <TruncatedText charsAfterEllipsis={CHARS_AFTER_ELLIPSIS} {...restProps}>
      {children}
    </TruncatedText>
  );
};
