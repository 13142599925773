export const backgroundColor =
  'linear-gradient(213.6deg, rgba(43, 254, 195, 0.9) -2.57%, rgba(24, 143, 247, 0.9) 50.22%), #188FF7;';

export const displayText = [
  {
    text: 'ccInformationalCard.listItem1',
  },
  {
    text: 'ccInformationalCard.listItem2',
  },
  {
    text: 'ccInformationalCard.listItem3',
  },
  {
    text: 'ccInformationalCard.listItem4',
  },
  {
    text: 'ccInformationalCard.listItem5',
  },
];

export const pliantWalletLink = 'https://app.getpliant.com/wallet';
