import { Button } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface AccountsPayableNumberActionsProps {
  /** Called when user wants to find next free APN */
  onFindNext: () => void;
  disabled?: boolean;
}

/** Displays actions for suggested accounts payable number  */
export const AccountsPayableNumberActions = ({
  onFindNext,
  disabled,
}: AccountsPayableNumberActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);

  return (
    <Button
      icon="next"
      variant="tertiary"
      size="small"
      iconSize="space16"
      top="6px"
      label={t(
        'form.accountsPayableNumber.findNextAccountNumber.findNextButton'
      )}
      disabled={disabled}
      onClick={onFindNext}
    />
  );
};
