import i18next from 'i18next';
import { ErrorMessages } from 'utils/zodFormValidation';
import { ZodNullable, ZodNumber, ZodOptional, z } from 'zod';

const generateYearSchema = (startYear: number, endYear: number) => {
  const schema: { [key: string]: ZodOptional<ZodNullable<ZodNumber>> } = {};
  for (let year = startYear; year <= endYear; year++) {
    schema[`year${year}`] = z.number().min(12).nullish();
  }

  return schema;
};

const generateQuarterSchema = (startYear: number, endYear: number) => {
  const schema: { [key: string]: ZodOptional<ZodNullable<ZodNumber>> } = {};
  for (let year = startYear; year <= endYear; year++) {
    for (let quarter = 1; quarter <= 4; quarter++) {
      schema[`q${quarter}_${year}`] = z.number().min(4).nullish();
    }
  }

  return schema;
};

export const widgetFormSchema = z.object({
  contacts: z.string().array(),
  costCenters: z.string().array(),
  costObjects: z.string().array(),
  generalLedgerAccounts: z.string().array(),
  sharedWithUsers: z.any(),
  title: z.string(),
  ...generateYearSchema(2022, 2024),
  ...generateQuarterSchema(2022, 2024),
});

const customTooSmallErrorYear = (t: i18next.TFunction) => ({
  translationKey: t(
    'dashboard.createNewWidget.tabs.yearBudgetForm.budgetField.errors.minimumYearlyBudget'
  ),
});

const customTooSmallErrorQuarter = (t: i18next.TFunction) => ({
  translationKey: t(
    'dashboard.createNewWidget.tabs.quarterBudgetForm.budgetField.errors.minimumQuarterlyBudget'
  ),
});

const generateCustomBudgetErrorMessages = (
  t: i18next.TFunction,
  startYear: number,
  endYear: number
) => {
  const errors = {};
  for (let year = startYear; year <= endYear; year++) {
    // @ts-ignore fix key
    errors[`year${year}`] = {
      too_small: customTooSmallErrorYear(t),
    };

    for (let quarter = 1; quarter <= 4; quarter++) {
      // @ts-ignore fix key
      errors[`q${quarter}_${year}`] = {
        too_small: customTooSmallErrorQuarter(t),
      };
    }
  }

  return errors;
};

export const errorMessages = (t: i18next.TFunction) => {
  return {
    ...generateCustomBudgetErrorMessages(t, 2022, 2024),
    costCenters: {
      label: 'dashboard.createNewWidget.types.costCenter.title',
    },
    costObjects: {
      label: 'dashboard.createNewWidget.types.costObject.title',
    },
    generalLedgerAccounts: {
      label: 'dashboard.createNewWidget.types.generalLedgerAccount.title',
    },
    contacts: {
      label: 'dashboard.createNewWidget.types.contact.title',
    },
    title: {
      label: 'dashboard.createNewWidget.name.title',
    },
  } as ErrorMessages<typeof widgetFormSchema>;
};
