import { noop } from 'lodash';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface PhysicalCreditCardContextProps {
  isPinVisible: boolean;
}

const initialState: PhysicalCreditCardContextProps = {
  isPinVisible: false,
};

export const PhysicalCreditCardContext =
  createContext<PhysicalCreditCardContextProps>(initialState);

export const PhysicalCreditCardDispatchContext =
  createContext<Dispatch<SetStateAction<PhysicalCreditCardContextProps>>>(noop);

export const PhysicalCreditCardProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, setState] = useState(initialState);

  return (
    <PhysicalCreditCardContext.Provider value={state}>
      <PhysicalCreditCardDispatchContext.Provider value={setState}>
        {children}
      </PhysicalCreditCardDispatchContext.Provider>
    </PhysicalCreditCardContext.Provider>
  );
};

export const usePhysicalCreditCardState = () =>
  useContext(PhysicalCreditCardContext);
export const usePhysicalCreditCardDispatch = () =>
  useContext(PhysicalCreditCardDispatchContext);
