import { useCallback } from 'react';
import { useGetUserAbsence } from '../../../../../../../containers/absence/useGetUserAbsence';
import { useCurrentUser } from '../../../../../../../providers/CurrentUserProvider';
import { isAbsenceDateOverlap } from './isAbsenceDateOverlap';

export type CheckCircularSubstitutionDependency = (
  fromDate: string | undefined,
  toDate: string | undefined,
  substituteId: string | undefined,
  userId: string | undefined
) => Promise<{
  isCircular: boolean;
  substituteUserName?: string;
  isCurrentUser?: boolean;
}>;

export const useCheckCircularSubstitutionDependency = () => {
  const { getUserAbsence, getUsersSubstitute } = useGetUserAbsence();
  const currentUser = useCurrentUser();

  const checkCircularDependency =
    useCallback<CheckCircularSubstitutionDependency>(
      async (
        fromDate: string | undefined,
        toDate: string | undefined,
        substituteId: string | undefined,
        userId: string | undefined
      ) => {
        const userAbsence = getUserAbsence(substituteId);
        const substitute = getUsersSubstitute(substituteId);

        const isCurrentUser = currentUser?.id === userId;

        if (
          !userAbsence ||
          !userAbsence.substituteMembershipId ||
          !substituteId ||
          !userId
        )
          return { isCircular: false };

        const datesOverlap = isAbsenceDateOverlap({
          currentUserFrom: fromDate,
          currentUserTo: toDate,
          substituteFrom: userAbsence.fromDate,
          substituteTo: userAbsence.toDate,
        });

        const isSameSubstitute = userId === userAbsence.substituteMembershipId;

        return {
          isCircular: datesOverlap && isSameSubstitute,
          substituteUserName: substitute?.name,
          isCurrentUser,
        };
      },
      [getUserAbsence, getUsersSubstitute, currentUser]
    );

  return checkCircularDependency;
};
