import { DocumentCurrency } from 'generated-types/graphql.types';

/** Maps `DocumentCurrency` to `ProcessingFormCurrencyFieldItem` */
export const toCurrencyFieldItem = (currency: DocumentCurrency) => ({
  key: currency,
  children: currency,
});

/** Move priority currencies (eg. EUR, USD) in the currency list to the front */
export const sortCurrencies = (currencies: DocumentCurrency[]) => {
  const priorityCurrencies = [DocumentCurrency.Eur, DocumentCurrency.Usd];

  return [
    ...priorityCurrencies,
    ...currencies.filter(currency => !priorityCurrencies.includes(currency)),
  ];
};
