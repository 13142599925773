import { useUpdateGeneralExpenseItemMutation } from 'generated-types/graphql.types';
import { debounce } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { BaseGeneralExpenseOutput } from '../toolkit/expensesFormSchema';
import {
  reimbursementCaseByIdQuery,
  reimbursementItemsListQuery,
} from '../toolkit/queries';
import { sanitizeGeneralExpenseFormOutput } from '../utils/sanitizeGeneralExpenseFormOutput';

const UPDATE_EXPENSE_DELAY = 2000;

export const useUpdateGeneralExpense = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();

  const [updateGeneralExpense, { loading: isUpdateGeneralExpensePending }] =
    useUpdateGeneralExpenseItemMutation();

  const handleUpdateGeneralExpense = async (
    formValues: BaseGeneralExpenseOutput
  ) => {
    const input = {
      reimbursementCaseId: reimbursementId,
      ...sanitizeGeneralExpenseFormOutput(formValues),
    };

    const variables = {
      id: formValues.expenseId,
      input,
    };

    const { errors } = await updateGeneralExpense({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: { id: reimbursementId },
        },
        {
          query: reimbursementItemsListQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
      ],
    });

    if (errors?.length) {
      return { status: 'error' };
    }

    return { status: 'success' };
  };

  return {
    isUpdateGeneralExpensePending,
    updateGeneralExpense: debounce(
      handleUpdateGeneralExpense,
      UPDATE_EXPENSE_DELAY
    ),
  };
};
