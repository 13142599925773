import { useToastMessage } from 'components/Toast/useToastMessage';
import { useIsCardManagerOnCreditCardsView } from 'containers/credit-cards/utils';
import {
  CardCategory,
  useIssueSingleUseVirtualCardIssuerCardMutation,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { isNil } from 'lodash';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { AccountingData } from '../types';
import { jumpToCreatedCreditCard } from '../utils/jumpToCreatedCreditCard';
import { sanitiseIssueSingleUseCard } from '../utils/sanitiseIssueSingleUseCard';
import { CreditCardData } from '../utils/types';

export interface UseIssueSingleUseVirtualCardOutput {
  issue: (values: IssueSingleUseVirtualCard) => Promise<{
    status: string;
    errors?: readonly GraphQLError[] | undefined;
  }>;
  issuing: boolean;
}

export interface IssueSingleUseVirtualCard extends CreditCardData {
  selectedCategory?: CardCategory;
  currentUserId?: string;
  accountingData?: AccountingData;
  noInvoiceNeeded?: boolean;
}

export const useIssueSingleUseVirtualCard = ({
  onCardCreatedSuccess,
}: {
  onCardCreatedSuccess: (cardId: string) => void;
}): UseIssueSingleUseVirtualCardOutput => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();
  const isCardManagerOnCreditCardsView = useIsCardManagerOnCreditCardsView();
  const organizationId = useOrganizationId();
  const navigate = useNavigate();

  const [issueSingleUseVirtualCardIssuerCard, { loading: issuing }] =
    useIssueSingleUseVirtualCardIssuerCardMutation({
      onError: () => error(t('dashboard.genericErrorMessage')),
    });

  const { refetchQueries, evictPaginationResults } =
    useCreditCardsRefetchQueries();

  return {
    issue: async (values: IssueSingleUseVirtualCard) => {
      const sanitisedValues = sanitiseIssueSingleUseCard(values);
      const { data, errors } = await issueSingleUseVirtualCardIssuerCard({
        variables: {
          input: sanitisedValues,
        },
        awaitRefetchQueries: true,
        refetchQueries: ({ data }) => {
          return [
            ...refetchQueries.cardIssuerCardsCardManager(),
            ...refetchQueries.cardIssuerCards(
              data?.issueSingleUseVirtualCardIssuerCard.id
            ),
          ];
        },
        onCompleted: data => {
          if (isNil(data)) {
            return;
          }

          const cardId = data.issueSingleUseVirtualCardIssuerCard.id;

          onCardCreatedSuccess(cardId);

          const { creditCardLink } = jumpToCreatedCreditCard({
            cardId,
            organizationId,
          });

          const canNavigateToIssuedCard =
            values.teamMember === values.currentUserId;

          evictPaginationResults();
          if (!isCardManagerOnCreditCardsView && canNavigateToIssuedCard) {
            navigate(creditCardLink);
          }
        },
      });

      if (errors?.length) {
        return { status: 'error', errors };
      }

      if (data) {
        return { status: 'success' };
      }

      return {
        status: 'error',
        errors,
      };
    },
    issuing,
  };
};
