import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Carousel } from '../Carousel/Carousel';
import { useNewsCardContent } from '../hooks/useNewsCardContent';
import { NewsCard } from './NewsCards/NewsCard';

export const NewsCarousel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { newsContentCards } = useNewsCardContent();

  const newsCards = newsContentCards.map((newsContent, index) => (
    <NewsCard
      key={index}
      imageUrl={newsContent.imageUrl}
      imageAlt={newsContent.imageAlt}
      tag={newsContent.tag}
      title={newsContent.title}
      description={newsContent.description}
      buttonText={newsContent.buttonText}
      onButtonClick={() =>
        window.open(newsContent.buttonUrl, '_blank', 'noopener noreferrer')
      }
      backgroundColor={newsContent.backgroundColor}
    />
  ));

  return <Carousel cards={newsCards} title={t('newsCarousel.title')} />;
};
