import { AppRouteParams, Routes } from 'models';
import { generatePath } from 'react-router-dom-v5-compat';
import { NotificationProps } from '../getNotificationInfo';

export const getLinkToWallet = (
  notification: NotificationProps<'CardRequestRejectedInAppNotification'>
) => {
  const link = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.DASHBOARD}`,
      {
        organizationSlug: notification.organizationId,
      }
    ),
  };

  return { link };
};
