import { Flex, Heading, Item, Text } from '@candisio/design-system';
import {
  FilterableList,
  FilterableListProps,
} from 'components/FilterableList/FilterableList';
import { PaymentFrequencyType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Statement } from '../hooks/useGetStatements';
import { EmptyState } from './EmptyState';
import { StatementItem } from './StatementItem';

const paymentFrequencyTranslations: Record<PaymentFrequencyType, string> = {
  BI_WEEKLY: 'creditCardPayments.paymentFrequency.biWeekly',
  DAILY: 'creditCardPayments.paymentFrequency.daily',
  MONTHLY: 'creditCardPayments.paymentFrequency.monthly',
  WEEKLY: 'creditCardPayments.paymentFrequency.weekly',
  PREFUND: 'creditCardPayments.paymentFrequency.prefunded',
  PREFUNDED: 'creditCardPayments.paymentFrequency.prefunded',
};

interface StatementsListProps {
  statements: Statement[];
  isLoading: boolean;
  paymentFrequency?: PaymentFrequencyType;
  menuButtons: FilterableListProps['menuButtons'];
  onEndReached: FilterableListProps['onEndReached'];
}

export const StatementsList = ({
  statements,
  isLoading,
  paymentFrequency,
  menuButtons,
  onEndReached,
}: StatementsListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <FilterableList
      heading={
        <Flex alignItems="baseline" gap="space8">
          <Heading as="h3">{t('statements.list.heading')}</Heading>
          {paymentFrequency ? (
            <Text>
              {t('statements.list.subHeading', {
                paymentFrequency: t(
                  paymentFrequencyTranslations[paymentFrequency]
                ),
              })}
            </Text>
          ) : null}
        </Flex>
      }
      menuButtons={menuButtons}
      onEndReached={onEndReached}
      emptyDataState={!isLoading && <EmptyState />}
      isLoading={isLoading}
      children={statements.map(statement => (
        <Item key={statement.id} textValue={statement.id}>
          <StatementItem {...statement} />
        </Item>
      ))}
    />
  );
};
