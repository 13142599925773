import { useEffect } from 'react';
import { useReimbursementFormsContext } from '../context/ReimbursementFormsContext';

export const useFormValidationTrigger = (
  formKey: string,
  trigger: () => void
) => {
  const { registerForm } = useReimbursementFormsContext();

  useEffect(() => {
    registerForm(formKey, { trigger });

    return () => {
      registerForm(formKey, { trigger: undefined });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
