import { Flex, Image } from '@candisio/design-system';
import { useDatev } from 'orgConfig/datev';
import { useExportContext } from 'views/Integrations/Export/Context';
import { BdsSetupHintPanel } from 'views/Integrations/Export/toolkit/components/BdsSetupHint';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { SapPromotion } from 'views/Integrations/Export/toolkit/components/SapPromotion';
import { InitExportSummary } from 'views/Integrations/Export/toolkit/containers/ExportableEntitiesSummary/Init';
import { InitExportTypeLabel } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/InitExportTypeLabel';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import zipIcon from 'views/Integrations/Export/toolkit/images/icon-zip.svg';
import { useZipExport } from 'views/Integrations/Export/useZipExport';
import { ZipExportButton } from './ZipExportButton';

export const Zip = () => {
  const state = useExportContext();

  const { bdsBoughtButNotConnected, bdsConnected } = useDatev(); //BDS-checked

  const { exportType } = state;

  const { readyToExportEntitiesCountByType } = useExportDataGigaFactory(state);

  const { onZipExport, zipExportMutationLoading, hasExportableEntity } =
    useZipExport();

  return (
    <Flex direction="column" justifyContent="space-between">
      <Flex direction="column" gap="space24">
        <ExportInfo>
          <Image
            src={zipIcon}
            alt={exportType ?? ''}
            height="84px"
            width="84px"
            alignSelf="center"
          />
          <InitExportTypeLabel />
        </ExportInfo>
        <InitExportSummary
          readyToExportEntitiesCount={readyToExportEntitiesCountByType}
        />
        {bdsBoughtButNotConnected && <BdsSetupHintPanel />}
        {!bdsConnected && <SapPromotion />}
      </Flex>
      <ZipExportButton
        hasExportableEntity={hasExportableEntity}
        onExport={onZipExport}
        loading={zipExportMutationLoading}
      />
    </Flex>
  );
};
