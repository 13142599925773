import { TFunction } from 'react-i18next';
import { z } from 'zod';
import { MAX_DIGITS, MAX_FILE_SIZE } from './consts';

export type SelectFileFormValues = z.infer<typeof schema>;

export const schema = z.object({
  file: z.object({
    type: z.string().regex(new RegExp('text/csv')),
    size: z.number().max(MAX_FILE_SIZE),
  }),
  maxDigitsGeneralLedger: z.nativeEnum(MAX_DIGITS),
});

export const errorMessages = (t: TFunction<'settings'>) => ({
  file: {
    label: t('paymentConditions.import.input.label'),
  },
  'file.type': {
    label: t('paymentConditions.import.input.label'),
    invalid_string: {
      translationKey: t('paymentConditions.import.errors.wrongFileType'),
    },
  },
  'file.size': {
    label: t('paymentConditions.import.input.label'),
    too_big: {
      translationKey: t('paymentConditions.import.errors.fileTooLarge'),
    },
  },
  maxDigitsGeneralLedger: {
    label: t('import.fileSelectionStep.chooseMaxDigits'),
  },
});
