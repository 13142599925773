import { CreateCreditCardModalDataProvider } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModalDataProvider';
import { ReactNode } from 'react';
import { PhysicalCreditCardProvider } from './PhysicalCard/PhysicalCreditCardProvider';

export const CreditCardsProvider = ({ children }: { children: ReactNode }) => {
  return (
    <PhysicalCreditCardProvider>
      <CreateCreditCardModalDataProvider>
        {children}
      </CreateCreditCardModalDataProvider>
    </PhysicalCreditCardProvider>
  );
};
