import { Box, Button, Grid, useTheme } from '@candisio/design-system';
import { ImportHistoryItem } from 'components/HistoryList/HistoryList';
import { motion } from 'framer-motion';
import { isNil } from 'lodash';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { ButtonWrapper } from '../ButtonWrapper';
import { FileData } from '../FileData';
import { StatusIcon } from '../StatusIcon';

interface ErrorImportHistoryProps {
  item: ImportHistoryItem;
  queryString: string;
  isSapVariant?: boolean;
}

const MotionButton = motion(Button);

export const ErrorImportHistory = ({
  item,
  queryString,
  isSapVariant,
}: ErrorImportHistoryProps): ReactElement => {
  const [t] = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const { colors, space } = useTheme();
  const hasErrorFile = !isNil(item.errorFileUrl);

  return (
    <Grid
      gap="space16"
      height="57px"
      padding="space8 space20"
      templateColumns={`${space.space18} auto`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <StatusIcon hasErrorFile={hasErrorFile} />

      <Grid
        color="gray800"
        fontSize="basic"
        fontWeight="semibold"
        justifySelf="start"
        lineHeight="paragraph"
        gap="space4">
        <Box
          overflow="hidden"
          whiteSpace="nowrap"
          style={{ textOverflow: 'ellipsis' }}>
          {renderQueryHighlight({
            value: item.file?.name ?? '',
            queryString: queryString,
          })}
        </Box>
        <FileData item={item} isSapVariant={isSapVariant} isImportHistory />
      </Grid>

      {isHovered && (
        <ButtonWrapper>
          {hasErrorFile && (
            <MotionButton
              as="a"
              icon="arrowDown"
              iconPosition="left"
              variant="secondary"
              // TODO: fix this hack that ensures that the button text stays white on hover
              whileHover={{ color: colors.gray800 }}
              href={item.errorFileUrl}>
              {t('importHistoryList.downloadErrorCta.error')}
            </MotionButton>
          )}
          <MotionButton
            as="a"
            icon="arrowDown"
            iconPosition="left"
            variant="primary"
            // TODO: fix this hack that ensures that the button text stays white on hover
            whileHover={{ color: colors.gray0 }}
            href={item.file?.url}>
            {t('importHistoryList.downloadErrorCta.file')}
          </MotionButton>
        </ButtonWrapper>
      )}
    </Grid>
  );
};
