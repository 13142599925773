import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import DateFormatters from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { useGetCardById } from '../../utils';
import { PendingLimitRequest } from './PendingLimitRequest';

interface PendingLimitRequestContainerProps {
  cardId: string;
  onSetMode: (mode: DrawerMode) => void;
}

export const PendingLimitRequestContainer = ({
  cardId,
  onSetMode,
}: PendingLimitRequestContainerProps) => {
  const { card } = useGetCardById({ cardId });

  if (!card) {
    return null;
  }

  const { pendingRequest } = card;

  if (
    !pendingRequest ||
    pendingRequest.__typename !== 'CardLimitChangeRequest'
  ) {
    return null;
  }

  const requestedLimitsDetails = {
    limit: pendingRequest.requestedLimit ?? undefined,
    transactionLimit: pendingRequest.requestedTransactionLimit ?? undefined,
    date: pendingRequest.createdAt,
  };

  const toReadbleMonetaryUnit = (val?: number) =>
    amountFormat(val, requestedLimitsDetails.limit?.currency, {
      minimumFractionDigits: 0,
      convertToBasicMonetaryUnit: true,
    });

  const requestedLimitsToShow = {
    limit: toReadbleMonetaryUnit(requestedLimitsDetails.limit?.value),
    transactionLimit: toReadbleMonetaryUnit(
      requestedLimitsDetails.transactionLimit.value
    ),
    requestedDate: DateFormatters.compactDatetime(
      new Date(requestedLimitsDetails.date)
    ),
    comment: pendingRequest.comment,
    interval: pendingRequest.requestedLimitRenewFrequency ?? undefined,
    cardType: card.type,
  };

  return (
    <PendingLimitRequest {...requestedLimitsToShow} onSetMode={onSetMode} />
  );
};
