import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { useTranslation } from 'react-i18next';

export const InvoiceCorrection = () => {
  const [t] = useTranslation();

  return (
    <HookFormCheckboxField
      name="invoiceCorrection"
      label={t('document.requestApproval.inputs.invoiceCorrection.label')}
      readOnly
    />
  );
};
