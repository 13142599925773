import { useEffect } from 'react';
import { scrollToTarget } from '../utils/utils';

export const useScrollToTarget = (target: string | undefined) => {
  useEffect(() => {
    if (target !== '' && target !== undefined) {
      scrollToTarget(target);
    }
  }, [target]);
};
