import {
  CardIssuerAmountInput,
  IssueSingleUseVirtualCardInput,
} from 'generated-types/graphql.types';
import { IssueSingleUseVirtualCard } from '../hooks/useIssueSingleUseVirtualCard';

export const sanitiseIssueSingleUseCard = ({
  purpose,
  teamMember,
  transactionLimit,
  currentUserId,
  selectedCategory,
  accountingData,
  noInvoiceNeeded,
}: IssueSingleUseVirtualCard): IssueSingleUseVirtualCardInput => {
  const txLimit: CardIssuerAmountInput = {
    currency: 'EUR',
    value: transactionLimit ?? 0,
  };

  const settingsData = {
    isInvoiceNeeded: false,
  };

  return {
    transactionLimit: txLimit,
    purpose: purpose ?? '',
    membershipId: teamMember,
    acceptedCardAgreement: currentUserId === teamMember ? true : false,
    category: selectedCategory,
    accountingData,
    settingsData: noInvoiceNeeded ? settingsData : undefined,
  };
};
