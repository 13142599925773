import { useUpdateCardForRecurringPaymentVendorMutation } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { LinkCardToVendorFormOutput } from '../CreditCardsInsights/RecurringPayments/RecurringPaymentsModal/utils/linkCardToVendorFormSchema';
import { useGetVendorForRecurringPaymentsById } from './useGetVendorForRecurringPaymentsById';

export const useUpdateCardForRecurringPayment = () => {
  const { vendorId } = useParams<{ vendorId: string }>();
  const { vendorDetails, loading: loadingVendor } =
    useGetVendorForRecurringPaymentsById({ vendorId });

  const [updateCard, { loading, client }] =
    useUpdateCardForRecurringPaymentVendorMutation();

  return {
    updateCard: async (values: LinkCardToVendorFormOutput) => {
      if (!vendorId || isNil(vendorDetails)) {
        return { status: 'error' };
      }

      const { errors } = await updateCard({
        variables: {
          input: {
            customVendorName: values.vendorName,
            cardId: values.creditCard,
            vendorId,
            cardLinkId: vendorDetails.linkedCards?.[0].linkageId,
          },
        },
        awaitRefetchQueries: true,
        onCompleted: () => {
          // It is necessary to evict the cache since we do not know at exactly which point we are on the list and we do not know what to refetch
          client.cache.evict({ fieldName: 'getVendorsForRecurringPayments' });
        },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    loading: loading || loadingVendor,
  };
};
