import { Box, Card, Flex, Grid, Text, Image } from '@candisio/design-system';
import virtualCardTile from 'assets/credit-cards/candis-virtual.svg';
import { useTranslation } from 'react-i18next';
import { IssueCreditCardField } from './IssueCreditCardField';

const bulletPoints = [
  'settings.teamMembers.form.issueCreditCard.listItems.item1',
  'settings.teamMembers.form.issueCreditCard.listItems.item2',
  'settings.teamMembers.form.issueCreditCard.listItems.item3',
];

export const userIdParamKey: 'userId' = 'userId';

export const IssueCreditCard = () => {
  const [t] = useTranslation();

  return (
    <Card background="gray100">
      <Grid templateColumns="auto 1fr" gap="space16">
        <Image
          src={virtualCardTile}
          alt={t('settings.teamMembers.form.issueCreditCard.altVirtualCard')}
        />
        <Grid alignContent="start" gap="space16">
          <Text fontSize="medium">
            {t('settings.teamMembers.form.issueCreditCard.heading')}
          </Text>
          <Flex
            as="ul"
            gap="space4"
            fontSize="basic"
            direction="column"
            style={{ padding: '0px' }}>
            {bulletPoints.map((point, index) => (
              <Box key={index} as="li" style={{ listStyle: 'none' }}>
                <Text>{t(point)}</Text>
              </Box>
            ))}
          </Flex>
          <IssueCreditCardField />
        </Grid>
      </Grid>
    </Card>
  );
};
