import { InvoiceAssociationStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

type InvoiceStatusOption = {
  id: InvoiceAssociationStatus;
  label: string;
  showOption?: boolean;
};

export const useFilteredInvoiceStatusOptions = ({
  showOption,
}: {
  showOption: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const invoiceStatusOptions: InvoiceStatusOption[] = [
    {
      id: InvoiceAssociationStatus.Missing,
      label: t('documentStatus.missing'),
    },
    {
      id: InvoiceAssociationStatus.Uploaded,
      label: t('documentStatus.uploaded'),
    },
    {
      id: InvoiceAssociationStatus.NotNeeded,
      label: t('documentStatus.notNeeded'),
    },
    {
      id: InvoiceAssociationStatus.AutoMatched,
      label: t('documentStatus.autoMatched'),
      showOption,
    },
  ];

  const filteredInvoiceStatusOptions = invoiceStatusOptions.filter(option => {
    const hideOption = option.showOption === false;

    if (hideOption) return null;

    return option;
  });

  return { filteredInvoiceStatusOptions };
};
