import { FieldContainer, Grid, Item, Select } from '@candisio/design-system';
import { HookFormSelectFieldProps } from 'components/HookFormFields/HookFormSelectField';
import { useHookFormSelectOptionMonths } from 'components/utils/select-option-months';
import { identity } from 'lodash';
import { FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface HookFormDayMonthSelectFieldProps {}

const getDays = (selectedMonth: number | null) => {
  let days = 31;
  if (selectedMonth === 2) {
    days = 28;
  } else if ([4, 6, 9, 11].includes(selectedMonth ?? 0)) {
    days = 30;
  }

  return [...Array(days).keys()];
};

const shouldDisplayMonth = (selectedDay: number | null) => {
  return ({ key }: { key: string; children: string }) => {
    if (selectedDay === null) {
      return true; // display all months if no day is selected
    } else if (selectedDay === 31) {
      // Do not show April, June, September, November if selected day is 31
      return ![2, 4, 6, 9, 11].includes(Number(key));
    } else if (selectedDay > 28) {
      // Do not show February if selected day is greater than 28
      return Number(key) !== 2;
    } else {
      // Display all months for selected days less than or equal to 28
      return true;
    }
  };
};

export const HookFormDayMonthSelectField = <TFormValues extends FieldValues>(
  props: Omit<HookFormSelectFieldProps<TFormValues>, 'items'>
) => {
  const {
    label,
    name,
    control,
    disabled,
    message,
    variant,
    readOnly: readOnlyProp,
    transform = { input: identity, output: identity },
    defaultSelectedKey,
  } = props;

  const { field, fieldState, formState } = useController({ control, name });
  const { onChange, value, name: fieldName, ...fieldProps } = field;

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  const optionMonths = useHookFormSelectOptionMonths();

  const [t] = useTranslation('exports');

  return (
    <FieldContainer
      disabled={disabled}
      label={label}
      message={errorMessage ?? message}
      readOnly={readOnly}
      variant={hasError ? 'error' : variant}>
      <Grid columns={2} templateColumns="30% 70%">
        <Select
          name="fiscalYearStart.day"
          placeholder={t('fiscalYearStart.day')}
          onSelectionChange={newValue => {
            const transformedValue = transform.output?.(newValue) ?? newValue;
            onChange({
              ...value,
              day: transformedValue,
            });
          }}
          items={getDays(Number(value.month)).map(i => ({
            key: (i + 1).toString(),
            children: `${i + 1 < 10 ? '0' : ''}${i + 1}`,
          }))}
          children={item => <Item>{item.children}</Item>}
          selectedKey={transform.input?.(value.day) ?? value.day}
          defaultSelectedKey={defaultSelectedKey}
          {...fieldProps}
        />
        <Select
          name="fiscalYearStart.month"
          placeholder={t('fiscalYearStart.month')}
          onSelectionChange={newValue => {
            const transformedValue = transform.output?.(newValue) ?? newValue;
            onChange({
              ...value,
              month: transformedValue,
            });
          }}
          items={optionMonths.filter(shouldDisplayMonth(Number(value.day)))}
          children={item => <Item>{item.children}</Item>}
          selectedKey={
            transform.input?.(value.month) && value.month >= 10
              ? value.month
              : `0${value.month}`
          }
          defaultSelectedKey={defaultSelectedKey}
          {...fieldProps}
        />
      </Grid>
    </FieldContainer>
  );
};
