import { History } from 'components/History/History';
import { HistoryLayout } from 'components/History/styles';
import moment from 'moment';
import {
  activityTypeToTransactionHistoryComponent,
  SupportedTransactionEvent,
} from '../../types';

interface TransactionHistoryProps {
  events: Array<SupportedTransactionEvent>;
}

export const TransactionHistory = ({ events }: TransactionHistoryProps) => {
  if (events.length === 0) {
    return null;
  }

  return (
    <HistoryLayout>
      <History>
        {events.map((event, idx) => {
          const typename = event.__typename;
          if (!typename) {
            return null;
          }

          const user = event.user;
          const author = user?.name;
          const avatarUrl =
            event.__typename === 'TransactionCommentEvent'
              ? event.comment?.author?.avatarUrl || undefined
              : undefined;

          const {
            item: HistoryItem,
            content: HistoryContent,
            ...others
          } = activityTypeToTransactionHistoryComponent[typename];

          const createdAt = moment(event.createdAt);

          return (
            <HistoryItem
              size="big"
              type={typename}
              key={event.id || idx}
              author={author}
              avatarUrl={avatarUrl}
              absoluteTime={createdAt.format('LLL')}
              header={createdAt.fromNow()}
              isCollapsible={others.collapsible}
              defaultCollapse={false}>
              <HistoryContent {...(event as any)} />
            </HistoryItem>
          );
        })}
      </History>
    </HistoryLayout>
  );
};
