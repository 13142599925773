import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { MAX_TITLE_LENGTH } from '../../WidgetForm.helper';

export const WidgetTitle = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return (
    <HookFormTextField
      name="title"
      label={t('dashboard.createNewWidget.name.title')}
      placeholder={t('dashboard.createNewWidget.name.placeholder')}
      maxLength={MAX_TITLE_LENGTH}
    />
  );
};
