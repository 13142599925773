import { useAcceptCardUsageAgreementMutation } from 'generated-types/graphql.types';
import { cardQueryArgs } from 'providers/EntityLoader/EntityLoader';
import { getCardIssuerCardsForCardholderQuery } from 'providers/EntityLoader/queries';

interface AcceptUsageAgreementProps {
  cardId: string;
  cardPendingActionId: string;
}

interface UseAcceptUsageAgreementReturn {
  acceptUsageAgreement: ({
    cardId,
    cardPendingActionId,
  }: AcceptUsageAgreementProps) => Promise<{
    status?: 'success' | 'error';
  }>;
  isAccepting: boolean;
}

export const useAcceptUsageAgreement = (): UseAcceptUsageAgreementReturn => {
  const [acceptUsageAgreement, { loading }] =
    useAcceptCardUsageAgreementMutation();

  return {
    acceptUsageAgreement: async ({
      cardId,
      cardPendingActionId,
    }: AcceptUsageAgreementProps) => {
      const { data } = await acceptUsageAgreement({
        variables: { input: { cardId, cardActionId: cardPendingActionId } },
        refetchQueries: [
          {
            query: getCardIssuerCardsForCardholderQuery,
            ...cardQueryArgs,
          },
        ],
      });

      if (data?.acceptCardUsageAgreement.__typename === 'CardReference') {
        return { status: 'success' };
      }

      return { status: 'error' };
    },
    isAccepting: loading,
  };
};
