import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateCardSettingsDataMutation } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface UpdateCardsSettingsInput {
  cardId: string;
  noInvoiceNeeded: boolean;
}

export const useUpdateCardSettings = () => {
  let status: 'error' | 'success';
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();
  const { isCardManager } = useUserRoles();
  const [creditCardsInvoiceNotNeededOnCard] = useCandisFeatureFlags([
    FEATURE_FLAGS.creditCardsInvoiceNotNeededOnCard,
  ]);

  const [updateCardSettingsData, { loading }] =
    useUpdateCardSettingsDataMutation({
      onError: () => error(t('dashboard.genericErrorMessage')),
    });

  const { refetchQueries } = useCreditCardsRefetchQueries();

  if (creditCardsInvoiceNotNeededOnCard && isCardManager) {
    return {
      loading,
      updateCardSettings: async (values: UpdateCardsSettingsInput) => {
        const { errors } = await updateCardSettingsData({
          variables: {
            input: {
              cardId: values.cardId,
              settingsData: {
                isInvoiceNeeded: values.noInvoiceNeeded ? false : true,
              },
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: refetchQueries.cardIssuerCards(values.cardId),
        });

        if (errors?.length) {
          status = 'error';
        } else {
          status = 'success';
        }

        return {
          status,
        };
      },
    };
  }
};
