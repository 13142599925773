import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateCurrentUserMutation } from 'generated-types/graphql.types';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeEmail } from './useChangeEmail';
import { useGetUserProfileUpdateInput } from './useGetUserProfileUpdateInput';
import { useInitialFormData } from './useInitialFormData';
import { UserProfileFormOutput } from './userProfileFormSchema';

export const useUpdateCurrentUser = () => {
  const [t] = useTranslation();
  const { success } = useToastMessage();

  const [updateCurrentUser, { loading: updateUserLoading }] =
    useUpdateCurrentUserMutation({
      refetchQueries: [currentUserQuery],
      awaitRefetchQueries: true,
    });

  const { requestChangeEmail } = useChangeEmail();

  const {
    getChangeEmailInput,
    getUserProfileUpdateInput,
    hasChangedEmail,
    hasChangedUserInfo,
  } = useGetUserProfileUpdateInput();

  const initialValues = useInitialFormData();

  const handleUpdateCurrentUser = useCallback(
    async (formValues: UserProfileFormOutput) => {
      if (hasChangedUserInfo(formValues)) {
        await updateCurrentUser({
          variables: {
            input: getUserProfileUpdateInput(formValues),
          },
        });

        success(t('header.profile.tabs.update.form.messages.success'));
      }

      if (hasChangedEmail(formValues)) {
        const { data: requestData } = await requestChangeEmail({
          variables: { input: getChangeEmailInput(formValues) },
        });

        if (
          requestData?.requestChangeUserEmail.__typename ===
          'RequestChangeUserEmailConflict'
        ) {
          return {
            emailIsADuplicate: true,
          };
        }
      }
    },
    [
      getChangeEmailInput,
      getUserProfileUpdateInput,
      hasChangedEmail,
      hasChangedUserInfo,
      requestChangeEmail,
      success,
      t,
      updateCurrentUser,
    ]
  );

  return {
    initialValues,
    handleUpdateCurrentUser,
    updateCurrentUserLoading: updateUserLoading,
  };
};
