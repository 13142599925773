import {
  FilterState,
  SingleParsedContact,
} from 'views/Contacts/ContactImport/types';

export enum ImportSteps {
  SelectFile,
  Preview,
}

export const contactImportInitialState: ContactImportState = {
  importStep: ImportSteps.SelectFile,
  parsedContacts: [],
  previewFilters: null,
  selectedFile: null,
  showSummary: false,
  showConfirmation: false,
  importing: false,
  startTimestamp: null,
  importRecordId: null,
};

export enum CONTACT_IMPORT_ACTION {
  SET_CONTACTS = 'SET_CONTACTS',
  SHOW_PREVIEW = 'SHOW_PREVIEW',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  SHOW_CONFIRMATION = 'SHOW_CONFIRMATION',
  START_IMPORT = 'START_IMPORT',
  START_POLLING = 'START_POLLING',
  RESET = 'RESET',
}

export interface ContactImportState {
  importStep: ImportSteps;
  parsedContacts: SingleParsedContact[];
  previewFilters: FilterState | null;
  selectedFile: File | null;
  showSummary: boolean;
  showConfirmation: boolean;
  importing: boolean;
  startTimestamp: number | null;
  importRecordId: string | null;
}

export type SetContacts = {
  type: CONTACT_IMPORT_ACTION.SET_CONTACTS;
  payload: {
    parsedContacts: SingleParsedContact[];
    selectedFile: File;
  };
};

export type ShowPreview = {
  type: CONTACT_IMPORT_ACTION.SHOW_PREVIEW;
};

export type UpdateFilters = {
  type: CONTACT_IMPORT_ACTION.UPDATE_FILTERS;
  payload: { filters: FilterState | null };
};

export type ShowConfirmation = {
  type: CONTACT_IMPORT_ACTION.SHOW_CONFIRMATION;
};

export type StartImport = {
  type: CONTACT_IMPORT_ACTION.START_IMPORT;
  payload: { startTimestamp: number };
};

export type StartPolling = {
  type: CONTACT_IMPORT_ACTION.START_POLLING;
  payload: { importRecordId: string };
};

export type Reset = {
  type: CONTACT_IMPORT_ACTION.RESET;
};

export type ContactsImportAction =
  | SetContacts
  | ShowPreview
  | UpdateFilters
  | ShowConfirmation
  | StartImport
  | StartPolling
  | Reset;

export const initialState: ContactImportState = {
  importStep: ImportSteps.SelectFile,
  parsedContacts: [],
  previewFilters: null,
  selectedFile: null,
  showSummary: false,
  showConfirmation: false,
  importing: false,
  startTimestamp: null,
  importRecordId: null,
};

export const contactImportReducer = (
  currentState: ContactImportState,
  action: ContactsImportAction
): ContactImportState => {
  switch (action.type) {
    case CONTACT_IMPORT_ACTION.SET_CONTACTS:
      return {
        ...currentState,
        parsedContacts: action.payload.parsedContacts,
        selectedFile: action.payload.selectedFile,
        showSummary: true,
      };
    case CONTACT_IMPORT_ACTION.SHOW_PREVIEW:
      return {
        ...currentState,
        importStep: ImportSteps.Preview,
      };

    case CONTACT_IMPORT_ACTION.UPDATE_FILTERS:
      return {
        ...currentState,
        previewFilters: action.payload.filters,
      };
    case CONTACT_IMPORT_ACTION.SHOW_CONFIRMATION:
      return {
        ...currentState,
        showConfirmation: true,
      };
    case CONTACT_IMPORT_ACTION.START_IMPORT:
      return {
        ...currentState,
        importing: true,
        startTimestamp: action.payload.startTimestamp,
      };
    case CONTACT_IMPORT_ACTION.START_POLLING:
      return {
        ...currentState,
        importRecordId: action.payload.importRecordId,
      };
    case CONTACT_IMPORT_ACTION.RESET:
      return initialState;
    default:
      return currentState;
  }
};
