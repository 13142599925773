import {
  Button,
  Checkbox,
  Flex,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { User } from 'generated-types/graphql.types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendMobileAppInvitationEmail } from '../useSendMobileAppInvitationEmail';

interface SendEmailActionCardProps {
  user: Pick<User, 'avatarUrl' | 'email' | 'name' | 'id'>;
  onClose?: () => void;
}

export const SendEmailActionCard = ({
  user,
  onClose,
}: SendEmailActionCardProps) => {
  const [t] = useTranslation();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (isSelected: boolean) => {
    setIsCheckboxChecked(isSelected);
  };

  const {
    handleSendEmail,
    isMobileAppInvitationEmailLoading,
    activeTeamMemberCount,
  } = useSendMobileAppInvitationEmail({ user, onClose, isCheckboxChecked });

  return (
    <Flex
      direction="column"
      gap="space12"
      padding="space36"
      justifyContent="space-between"
      paddingTop="space64">
      <Flex direction="column" gap="space12">
        <Text fontSize="xxxlarge">
          {t('settings:teamMembers.inviteTeamMember.actionCard.title')}
        </Text>
        <Text>
          {t('settings:teamMembers.inviteTeamMember.actionCard.description')}
        </Text>
        <Flex
          gap="space16"
          border="0px 0px 1 px 0px"
          alignItems="center"
          background="white"
          borderRadius="8px" // TODO space8 is not a valid value for borderRadius - fix this on design system side
        >
          <Flex paddingLeft="space8">
            <AvatarWithStatusContainer
              name={user.name}
              img={user.avatarUrl ?? undefined}
              hasTooltip={false}
              userId={user.id}
            />
          </Flex>
          <Flex direction="column" paddingY="space8">
            <TruncatedText
              fontWeight="semibold"
              fontSize="basic"
              overflowWrap="anywhere">
              {user.name}
            </TruncatedText>
            <Text color="gray500" overflowWrap="anywhere">
              {user.email}
            </Text>
          </Flex>
        </Flex>
        <Checkbox
          name="sendReminderEmailToMissingMembers"
          onChange={handleCheckboxChange}>
          <Text fontSize="basic" color="gray600">
            {t(
              'settings:teamMembers.inviteTeamMember.actionCard.checkboxLabel'
            )}
          </Text>
        </Checkbox>
      </Flex>
      <Button
        loading={isMobileAppInvitationEmailLoading}
        onClick={handleSendEmail}
        disabled={isMobileAppInvitationEmailLoading}>
        <TruncatedText>
          {isCheckboxChecked
            ? t(
                'settings:teamMembers.inviteTeamMember.actionCard.buttonLabel',
                {
                  memberCount: activeTeamMemberCount,
                }
              )
            : t(
                'settings:teamMembers.inviteTeamMember.actionCard.buttonLabel_singular',
                {
                  memberName: user.name,
                }
              )}
        </TruncatedText>
      </Button>
    </Flex>
  );
};
