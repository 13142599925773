import { Button, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';

export const NoFile = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <>
      <Paragraph color="gray500" fontWeight="semibold">
        {t('transactionAssociation.rightContent.actionBox.title')}
      </Paragraph>
      <Button disabled width="100%">
        {t('transactionAssociation.rightContent.actionBox.ctaButton')}
      </Button>
    </>
  );
};
