import { Button, Grid, Skeleton, Text } from '@candisio/design-system';

import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { collectErrorMessages } from 'components/Form/utils';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DocumentCurrency,
  IbanErrorKind,
  Money,
  useCreateIbanMutation,
  useGenerateSepaTransferXmlMutation,
  useListIbansQuery,
} from 'generated-types/graphql.types';
import { countPayableDocuments } from 'hooks/useCounterQueries';
import { sum } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { listIbansQuery } from 'views/Settings/PaymentsSettings/queries';
import { toIbanItem } from 'views/Settings/PaymentsSettings/toIbanItem';
import { SimplifiedPayableDocument } from '../../types';
import { IbanCTA } from '../IbanCTA';
import { IbanSelectField } from '../IbanSelectField';
import { PaymentSummary } from '../PaymentSummary';
import { SepaImage } from '../SepaImage';
import { sumCashDiscounts } from '../utils/sumCashDiscounts';
import { createPaymentSchema, errorMessages } from './schema';

export interface CreatePaymentProps {
  onSepaXmlExported: () => void;
  selectedDocuments: SimplifiedPayableDocument[];
  exportAll: boolean;
  totalPaymentsCount: number;
  allDocumentsSelected: boolean;
  payableTotalAmount?: Money;
  discountTotalAmount?: Money;
}

export const CreatePayment = ({
  onSepaXmlExported,
  selectedDocuments,
  exportAll,
  totalPaymentsCount,
  allDocumentsSelected,
  payableTotalAmount,
  discountTotalAmount,
}: CreatePaymentProps) => {
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const { success, error } = useToastMessage();

  // @TODO Start using dinero for these calculations

  /** Total invoice amount before discount */
  const selectedDocumentsGrossTotal = sum(
    selectedDocuments.map(document => document.amount.amount ?? 0)
  );

  const grossAmountFromDocs = allDocumentsSelected
    ? payableTotalAmount?.amount ?? 0
    : selectedDocumentsGrossTotal;

  const grossAmount: Money = {
    ...(selectedDocuments[0]?.amount ?? {
      currency: DocumentCurrency.Eur,
      precision: 2,
    }),
    amount: selectedDocuments.length > 0 ? grossAmountFromDocs : 0,
  };

  /** Total cash discount amount */
  const selectedDocsCashDiscount = sumCashDiscounts(selectedDocuments);

  const discountAmountFromDocs = allDocumentsSelected
    ? discountTotalAmount?.amount ?? 0
    : selectedDocsCashDiscount;

  const discountAmount: Money = {
    ...(selectedDocuments[0]?.discountAmount ?? {
      currency: DocumentCurrency.Eur,
      precision: 2,
    }),
    amount: selectedDocuments.length > 0 ? discountAmountFromDocs : 0,
  };

  /** Total payment amount after discount */

  const paymentAmount: Money = {
    ...grossAmount,
    amount: exportAll
      ? payableTotalAmount?.amount ?? 0
      : grossAmount.amount - discountAmount.amount,
  };

  const [generateSepaTransferXml] = useGenerateSepaTransferXmlMutation({
    onCompleted: ({ generateSepaTransferXml }) => {
      // currently not displaying the error to the user
      // but eventually we will (tcp-395)
      if (generateSepaTransferXml?.__typename === 'SepaXmlValidationError') {
        console.error(generateSepaTransferXml.message);
      } else if (
        generateSepaTransferXml?.__typename === 'SepaXml' &&
        downloadLinkRef.current
      ) {
        const file = new Blob([generateSepaTransferXml.content], {
          type: 'text/xml',
        });

        const downloadLinkElement = downloadLinkRef.current;
        downloadLinkElement.href = URL.createObjectURL(file);
        downloadLinkElement.download = generateSepaTransferXml.fileName;
        downloadLinkElement.click();

        onSepaXmlExported();
      }
    },
    /**
     * remove the paid documents from the cache, so further requests
     * get the most up to date state from back-end
     */
    update: cache => {
      selectedDocuments.forEach(({ id }) => {
        cache.modify({
          id: cache.identify({ __typename: 'Document', id }),
          fields: (_, { DELETE }) => DELETE,
        });
      });
    },
    refetchQueries: [{ query: countPayableDocuments }],
  });

  const { data: listIbansData, loading: loadingIbans } = useListIbansQuery();

  const items = listIbansData?.listIbans.map(toIbanItem) ?? [];
  const defaultIban = items.find(item => item.isDefault)?.key;

  const [createIbanMutation] = useCreateIbanMutation({
    refetchQueries: [{ query: listIbansQuery }],
  });

  const form = useForm({
    defaultValues: { iban: defaultIban },
    mode: 'onTouched',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.PAYMENTS,
      zodSchema: createPaymentSchema,
      errorMessages: errorMessages,
    }),
  });

  const onSubmit = async (values: { iban: string | undefined }) => {
    if (!values.iban) return;

    if (selectedDocuments.length < 1) {
      return form.setError('root', {
        message: t('details.noInvoicesSelected'),
      });
    }

    const debtorIban = items.find(item => item.key === values.iban)?.iban;
    if (!debtorIban) {
      return;
    }

    await generateSepaTransferXml({
      variables: {
        input: {
          documentIds: exportAll ? [] : selectedDocuments.map(({ id }) => id),
          debtorIban,
          exportAll,
        },
      },
    });

    form.reset();
  };

  const { isValid, isSubmitting, isSubmitSuccessful, errors } = form.formState;

  const errorTexts = collectErrorMessages(errors);

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        alignContent="space-between"
        gap="space48"
        height="100%"
        onSubmit={form.handleSubmit(onSubmit)}>
        <Grid gap="space24">
          {loadingIbans ? (
            <Skeleton width="100%" height="80px" />
          ) : (
            <Grid>
              <IbanSelectField
                name="iban"
                aria-labelledby={t('details.ibanHeading')}
                aria-label={t('details.ibanHeading')}
                label={t('details.ibanHeading')}
                placeholder={t('details.ibanPlaceholder')}
                items={items}
                readOnly={items.length < 1}
                onCreateIban={async values => {
                  const result = await createIbanMutation({
                    variables: { name: values.name, iban: values.iban },
                  });

                  if (result.data?.createIban.__typename === 'Iban') {
                    form.setValue('iban', result.data.createIban.id);
                    success(t('bankAccounts.successfullyAddedIban'));
                  } else if (
                    result.data?.createIban.__typename === 'IbanError'
                  ) {
                    if (
                      result.data?.createIban.kind === IbanErrorKind.Duplicate
                    ) {
                      error(t('bankAccounts.ibanAlreadyExists'));
                    } else {
                      error(t('bankAccounts.failedToAddIban'));
                    }
                  }
                }}
              />
              {items.length < 1 && <IbanCTA />}
            </Grid>
          )}

          <SepaImage />

          <PaymentSummary
            discountAmount={discountAmount}
            grossAmount={grossAmount}
            numDocuments={
              exportAll ? totalPaymentsCount : selectedDocuments.length
            }
            paymentAmount={paymentAmount}
          />
        </Grid>

        <ActionBar>
          <Grid gap="space12">
            {!isValid &&
              !isSubmitSuccessful &&
              (errorTexts.length > 0 ? (
                errorTexts.map(error => (
                  <Text color="red500" fontSize="xsmall" key={error}>
                    {error}
                  </Text>
                ))
              ) : (
                <Text color="red500" fontSize="xsmall">
                  {t('details.noValidIban')}
                </Text>
              ))}

            {!isValid ? (
              <Button
                loading={isSubmitting}
                data-cy="submit-payment-form"
                type="submit">
                {t('details.action')}
              </Button>
            ) : (
              <ConfirmationButton
                type="submit"
                confirmationTitle={t('details.exportConfirmation.question')}
                onConfirm={() => onSubmit({ iban: form.watch('iban') })}
                disabled={isSubmitting}
                closeText={t('details.exportConfirmation.cancellation')}
                confirmText={t('details.exportConfirmation.confirmation')}>
                {t('details.action')}
              </ConfirmationButton>
            )}
          </Grid>
        </ActionBar>
        <a ref={downloadLinkRef} style={{ display: 'none' }} href="/#">
          hidden download link
        </a>
      </Grid>
    </FormProvider>
  );
};
