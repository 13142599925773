import { AccountingInfoFormSchemaFormValues } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/utils/accountingInfoFormSchema';
import { CreateCreditCardModalState } from 'containers/credit-cards/CreateCreditCard/utils/utils';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';

import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useGetContactById } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/hooks/useGetContactById';
import { useGetCostCenterById } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/hooks/useGetCostCenterById';
import { useGetBookingAccountById } from 'views/TransactionAssociation/RightInformationContent/ReadOnlyAccountingDataForm/hooks/useGetGeneralLedgerAccountById';
import { useGetApprovers } from './useGetApprovers';

export interface PaginatedFieldProps {
  inputValue?: string | null;
  value?: string | null;
}
interface AccountingInfoFormDefaultValuesProps {
  values: CreateCreditCardModalState;
}

export const useAccountingInfoFormDefaultValues = ({
  values,
}: AccountingInfoFormDefaultValuesProps) => {
  const fullOrg = useFullOrganization();

  const artistSocialInsuranceAndExtraCostInfoFF = useCandisFeatureFlags(
    FEATURE_FLAGS.artistSocialInsuranceAndExtraCostInfo
  );

  const { approvers, approversLoading } = useGetApprovers();

  const { cardDetails } = values;

  const { contact, contactLoading } = useGetContactById({
    id: cardDetails?.contact?.value ?? undefined,
  });

  const { costCenter, costCenterLoading } = useGetCostCenterById({
    id: cardDetails?.costCenter?.value ?? undefined,
  });

  const { costCenter: extraCostInfo, costCenterLoading: extraCostInfoLoading } =
    useGetCostCenterById({
      id: cardDetails?.extraCostInfo?.value ?? undefined,
      skip:
        !artistSocialInsuranceAndExtraCostInfoFF || !fullOrg?.hasExtraCostInfos,
    });

  const { costCenter: costObject, costCenterLoading: costObjectLoading } =
    useGetCostCenterById({
      id: cardDetails?.costObject?.value ?? undefined,
    });

  const { bookingAccount, bookingAccountLoading } = useGetBookingAccountById({
    id: cardDetails?.generalLedgerAccount?.value ?? undefined,
  });

  const defaultValues: AccountingInfoFormSchemaFormValues = {
    approvalMode: cardDetails?.approvalMode ?? 'approvers',
    contact: {
      inputValue: contact.name,
      value: cardDetails?.contact?.value,
    },
    costCenter: {
      inputValue: costCenter.readableName,
      value: cardDetails?.costCenter?.value,
    },
    costObject: {
      inputValue: costObject.readableName,
      value: cardDetails?.costObject?.value,
    },
    generalLedgerAccount: {
      inputValue: bookingAccount.readableName,
      value: cardDetails?.generalLedgerAccount?.value,
    },
    extraCostInfo: {
      inputValue: extraCostInfo.readableName,
      value: cardDetails?.extraCostInfo?.value,
    },
    taxCode: cardDetails?.taxCode,
    workflow: cardDetails?.workflow,
    artistSocialInsuranceCode: cardDetails?.artistSocialInsuranceCode,
    approvers,
    noInvoiceNeeded: cardDetails?.noInvoiceNeeded ?? false,
  };

  return {
    defaultValues,
    loadingDefaultValues:
      contactLoading ||
      costCenterLoading ||
      costObjectLoading ||
      bookingAccountLoading ||
      approversLoading ||
      extraCostInfoLoading,
  };
};
