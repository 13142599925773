import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetCardById } from 'containers/credit-cards/utils';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CancelLimitRequest } from './CancelLimitRequest';
import { HandleRequest } from './HandleRequest';
import { useApproveLimitRequest } from './hooks/useApproveLimitRequest';
import { useCancelRequest } from './hooks/useCancelRequest';
import { useDeclineRequest } from './hooks/useDeclineLimitRequest';

export interface HandleLimitRequestContainerProps {
  cardId: string;
}

export const HandleLimitRequestContainer = ({
  cardId,
}: HandleLimitRequestContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();
  const { card } = useGetCardById({ cardId });
  const { decline, declining } = useDeclineRequest({ cardId });
  const { approve, approving } = useApproveLimitRequest({ cardId });
  const { cancel, canceling } = useCancelRequest({ cardId });
  const { isCardManager } = useUserRoles();

  if (!card) {
    return null;
  }

  const { pendingRequest } = card;

  const cardholder = {
    firstName: card.cardholder.firstName ?? undefined,
    lastName: card.cardholder.lastName ?? undefined,
  };

  if (
    !pendingRequest ||
    pendingRequest.__typename !== 'CardLimitChangeRequest'
  ) {
    return null;
  }

  const handleDeclineRequest = async (declineReason: string | undefined) => {
    const { status } = await decline(pendingRequest.id, declineReason);

    if (status === 'success') {
      success(t('drawer.cancelRequestSuccess'));
    } else {
      error(t('dashboard.genericErrorMessage'));
    }
  };

  const handleApproveRequest = async () => {
    const { status } = await approve(pendingRequest.id);

    if (status === 'success') {
      success(t('drawer.approveRequestSuccess'));
    } else {
      error(t('dashboard.genericErrorMessage'));
    }
  };

  const handleCancelRequest = async () => {
    const { status } = await cancel(pendingRequest.id);

    if (status === 'success') {
      success(t('drawer.cancelRequestSuccess'));
    } else {
      error(t('dashboard.genericErrorMessage'));
    }
  };

  return isCardManager ? (
    <HandleRequest
      approving={approving}
      declining={declining}
      onApprove={handleApproveRequest}
      onDecline={handleDeclineRequest}
      cardholder={cardholder}
      requestType="limitRequest"
    />
  ) : (
    <CancelLimitRequest canceling={canceling} onCancel={handleCancelRequest} />
  );
};
