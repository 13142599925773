import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useTranslation } from 'react-i18next';

export const AccountsReceivable = () => {
  const [t] = useTranslation();

  return (
    <HookFormTextField
      name="accountsReceivableNumber"
      label={t('settings.contacts.details.edit.accountsReceivableNumber.label')}
      readOnly
    />
  );
};
