import { useExportPaymentConditionsMutation } from 'generated-types/graphql.types';
import { isNil } from 'lodash';

type GetExportUrlResult =
  | {
      status: 'success';
      file: { url: string };
    }
  | { status: 'error' };

type GetExportUrl = () => Promise<GetExportUrlResult>;

interface UsePaymentConditionsExport {
  /** Get file url for export CSV */
  getExportFileUrl: GetExportUrl;
  /** Loading state */
  loading: boolean;
}

export const usePaymentConditionsExport = (): UsePaymentConditionsExport => {
  const [mutation, { loading }] = useExportPaymentConditionsMutation({});

  const getExportFileUrl: GetExportUrl = async () => {
    const { data } = await mutation();

    if (
      data?.exportPaymentConditions.__typename === 'PaymentConditionsExport' &&
      !isNil(data.exportPaymentConditions.file) &&
      !isNil(data.exportPaymentConditions.file.url)
    ) {
      const url = data.exportPaymentConditions.file.url;

      return { status: 'success', file: { url } };
    } else {
      return { status: 'error' };
    }
  };

  return { getExportFileUrl, loading };
};
