import {
  BookingKeyCommandResult,
  BookingKeyErrorType,
} from '../../../../generated-types/graphql.types';
import { getFullOrganizationQuery } from '../../../../providers/OrganizationProvider/queries';
import {
  bookingKeyActiveQuery,
  bookingKeyArchivedQuery,
  bookingKeysQuery,
} from '../gql';

export const refetchBookingKeyQueries = () => [
  {
    query: getFullOrganizationQuery,
  },
  {
    query: bookingKeysQuery,
  },
  {
    query: bookingKeyActiveQuery,
  },
  {
    query: bookingKeyArchivedQuery,
  },
];

export const isCreated = (result?: BookingKeyCommandResult): boolean =>
  !!result?.__typename && result.__typename === 'BookingKeySuccess';

export const isUpdated = (result?: BookingKeyCommandResult): boolean =>
  !!result?.__typename && result.__typename === 'BookingKeySuccess';

export const isDuplicate = (result?: BookingKeyCommandResult): boolean =>
  !!result?.__typename &&
  result.__typename === 'BookingKeyError' &&
  result.kind === BookingKeyErrorType.Duplicate;

export interface BookingKeyRouteParams {
  bookingKeyId?: string;
  organizationSlug: string;
}
