import { Flex, TruncatedText, Text } from '@candisio/design-system';
import { CreditCardDetailsProps } from 'containers/credit-cards/CreditCard/types';
import { CardType } from 'generated-types/resolvers-types';
import { cardVariantPliant } from '../../utils';

interface DetailsProps {
  cardDetails: CreditCardDetailsProps;
  cardType: CardType;
}

export const Details = ({ cardDetails, cardType }: DetailsProps) => {
  const { cvv, expiration, name, number, label } = cardDetails;

  const color = cardVariantPliant[cardType].color;

  return (
    <Flex direction="column" gap="space20" color={color} justifyContent="end">
      <Flex direction="column" gap="space8">
        {label ? (
          <TruncatedText
            lineHeight="paragraph"
            fontSize="xsmall"
            fontWeight="semibold"
            maxWidth="144px">
            {label}
          </TruncatedText>
        ) : null}
        <TruncatedText
          lineHeight="paragraph"
          fontWeight="semibold"
          fontSize="small"
          maxWidth="144px">
          {name || ''}
        </TruncatedText>
      </Flex>
      <Flex direction="column" fontSize="small">
        <Text>{number}</Text>
        <Flex alignSelf="start" gap="space8">
          <Text>{expiration}</Text>
          <Text>CVV {cvv}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
